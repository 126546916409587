/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row,  Select,  Space, Table, Tag, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IProjectBrand, IProjectType } from "interfaces";
import { useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import Moment from 'moment';

export const ProjectBrandList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Project Brands");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { tableProps,searchFormProps } = useTable<IProjectBrand,HttpError,IProjectBrand>({
        metaData: {
            populate: '*'
        },

        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { brand_name } = params;
    
                filters.push(
                    {
                        field       : "brand_name",
                        operator    : "contains",
                        value       : brand_name ? brand_name : null
                    },
                );
    
            return filters;
        },
    });
    

    const QueryResult = useOne<IProjectBrand>({
        resource: "project-brands",
        id: ''
    });
    let paginationLength:any = [];
    paginationLength = QueryResult.data?.data ?  QueryResult.data?.data : [];
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IProjectBrand>();
    const ed_point                          = "project-brands";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IProjectType>({
        mapData: (item) => {
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                        
                };
            }
        },
    });

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    // const vCurDelStatus                     = {deleteStatus : 'Y'}
    let vCurDelStatus                       = {active_status : false}

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'project-brands',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.active_status = false
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.active_status = true
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    }

    return <>
            
            <List 
                title={t("project-brand.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project-brand.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("project-brand.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="brand_name" >
                                <Input 
                                    style={{borderRadius: 4,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} 
                                    placeholder="Search project brand" 
                                    prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
    
                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:paginationLength.length, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                >

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    <Table.Column width={300} title="PROJECT BRAND" 
                        sorter={(a:any, b:any) => {
                            if (a?.brand_name < b?.brand_name) {return -1}
                            if (a?.brand_name > b?.brand_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                    {
                                        value? 
                                            <TextField value={record? record.brand_name:"-"} />
                                        :<>
                                            <TextField value={"(ไม่ระบุ)"} />
                                        </>
                                    }
                                </>
                        }}
                    />

                    <Table.Column title="ADDRESS" 
                        dataIndex="address"
                        sorter={(a:any, b:any) => {
                            if (a?.address < b?.address) {return -1}
                            if (a?.address > b?.address) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.address ? 
                                        <TextField value={value} />
                                    :<>
                                        <TextField value={"(ไม่ระบุ)"} />
                                    </>
                                }
                            </>
                        }}
                    />

                    <Table.Column title="TELEPHONE" 
                        sorter={(a:any, b:any) => {
                            if (a?.tel < b?.tel) {return -1}
                            if (a?.tel > b?.tel) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record.tel? 
                                        <TextField value={record.tel} />
                                    :<>
                                        <TextField value={"(ไม่ระบุ)"} />
                                    </>
                                }
                            </>
                        }}
                    />

                    <Table.Column align="center" width={150} dataIndex="active_status" title="STATUS" 
                        sorter={(a:any, b:any) => {
                            if (a?.active_status < b?.active_status) {return -1}
                            if (a?.active_status > b?.active_status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            let c = ""
                            let s = ""
                            c = (value == true) ?'green':(value == false) ?'red':''
                            s = (value == true) ?'ACTIVE':(value == false) ?'IN ACTIVE':''
                            return <>
                                {value == true || value == false ?
                                    <Tag style={{borderRadius: "10px"}} color={c}>
                                        {s}
                                    </Tag> 
                                :<></>
                                }
                            </>
                        }}
                    />

                    <Table.Column title="CREATED BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATED DATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //console.log("abc",record)
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'(ไม่ระบุ)'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'(ไม่ระบุ)'}  />
                                }
                            </>
                        }}
                    />
                    
                    <Table.Column<IProjectBrand>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                {/* <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton> */}

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}
                                {
                                    record.active_status === true ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}