/* eslint-disable */
import { Card, Checkbox, Col, Create, Form, Input, RadioChangeEvent, Row, useForm, useTable } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useCreateMany, useGetIdentity, useList, useNavigation, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IModuleAccess, IModuleMaster, IRManage } from "interfaces";
import { useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import "react-mde/lib/styles/css/react-mde-all.css";
import type { ColumnsType } from "antd/es/table";

export const RoleCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    // const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: creatModule } = useCreateMany<IModuleAccess>();
    // const log_endpoint = "even-logs";
    const userQuery = useGetIdentity(); // Get User Profile
    const { replace } = useNavigation();
    const {data : user_data ,isLoading}     = userQuery;

    // console.log('user_data', user_data);

    const [moduleAccState, setModuleAccState] = useState<any>([]);
    let moduleAccess: IModuleAccess[] = [];
    let moduleMaster: IModuleMaster[] = [];
    let moduleK:any = [];
    // const { tableProps } = useTable<IModuleAccess,HttpError, IModuleAccess>({ 
    //     resource: "module-accesses",
    //     metaData: {
    //         populate: '*'
    //     },
    //     initialSorter: [
    //         {
    //             field: "id",
    //             order: "asc",
    //         },
    //     ]
    // });

    const { tableProps } = useTable<IModuleMaster,HttpError, IModuleMaster>({ 
        resource: "module-masters",
        metaData: {
            populate: '*'
        },
        initialSorter: [
            {
                field: "id",
                order: "asc",
            },
        ]
    });

    const { formProps, saveButtonProps } = useForm<IRManage,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'pp_type',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });

                // funcSetModuleAcc(recordData.data.id);

                // if(moduleAccState.length > 0){
                //     // console.log(vTimeSlotTEST);
                //     for(let i=0 ; i <= moduleAccState.length -1; i++){
                //         console.log('checkkkk ',moduleAccState[i]);
                //         // creatModule({
                //         //     resource: "module-accesses",
                //         //     values: moduleAccState[i],
                //         //     // successNotification: false,
                //         //   });
                //     }
                // }

                // creatModule({
                //     resource: "module-accesses",
                //     values: moduleAccState,
                //     // successNotification: false,
                //   });

                // if(moduleAccState.length > 0){
                //     // console.log(vTimeSlotTEST);
                //     for(let i=0 ; i <= moduleAccState.length -1; i++){
                //         // console.log('checkkkk ',moduleAccState[i]);
                //         creatModule({
                //             resource: "module-accesses",
                //             values: [{
                //                 approve : moduleAccState[i].approve,
                //                 assign : moduleAccState[i].approve,
                //                 create : moduleAccState[i].create,
                //                 delete : moduleAccState[i].delete,
                //                 update : moduleAccState[i].update,
                //                 view : moduleAccState[i].view,
                //                 module_master : moduleAccState[i].module_master.id,
                //                 role : 1
                //             }],
                //             successNotification: false,
                //         });
                //     }
                // }

            }, redirect:"edit"
        }
    );

    const [value, setValue] = useState();
    // const [ vStateChk, setStateChk] = useState<any>([]);
    // const [ vStateChk2, setStateChk2] = useState<any>([]);
    //

    function funcSetModuleAcc(roleID:any){
        // console.log('roleID', roleID);

        setModuleAccState(
            moduleMaster.map((val, idx)=> (
            {
                key: idx.toString(),
                view: false,
                create: false,
                update: false,
                delete: false,
                assign: false,
                approve: false,
                module_master: val,
                role: roleID + "",
                // use_workflow: false,
            }))
        )

        moduleK = moduleMaster.map((val, idx)=> ({
            key: idx.toString(),
            view: false,
            create: false,
            update: false,
            delete: false,
            assign: false,
            approve: false,
            module_master: val,
            role: roleID + "",
            // use_workflow: false,
        }))
        // setModuleAccState(vModule)

        // if(moduleAccState.length > 0){
        //     // console.log(vTimeSlotTEST);
        //     for(let i=0 ; i <= moduleAccState.length -1; i++){
        //         // console.log('checkkkk ',moduleAccState[i]);
        //         creatModule({
        //             resource: "module-accesses",
        //             values: [{
        //                 approve : moduleAccState[i].approve,
        //                 assign : moduleAccState[i].approve,
        //                 create : moduleAccState[i].create,
        //                 delete : moduleAccState[i].delete,
        //                 update : moduleAccState[i].update,
        //                 view : moduleAccState[i].view,
        //                 module_master : moduleAccState[i].module_master.id,
        //                 role : 1
        //             }],
        //             successNotification: false,
        //         });
        //     } 
        // }

        if(moduleK.length > 0){
            for(let i=0 ; i <= moduleK.length -1; i++){
                console.log('moduleK',moduleK[i])
                creatModule({
                    resource: "module-accesses",
                    values: [{
                        approve : moduleK[i].approve,
                        assign : moduleK[i].approve,
                        create : moduleK[i].create,
                        delete : moduleK[i].delete,
                        update : moduleK[i].update,
                        view : moduleK[i].view,
                        module_master : moduleK[i].module_master.id,
                        role : moduleK[i].role
                    }],
                    successNotification: false,
                });
            }
        }
        replace("edit/" + roleID);
        
    }

    const onChangeNoti = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const onChangePermissions2 = (record: any, key: string, e: CheckboxChangeEvent) => {
        console.log('record', record);
        console.log('key', key);
        console.log('event', e.target.checked);

        // if(vStateChk.length == 0){
        //     setStateChk(
        //         moduleAccState.map((obj: { module_master: { id: any; }; }) => {
        //             if (obj.module_master.id === record.id) {
        //                 return { ...obj, [key]: e.target.checked };
        //             }
        //             return obj;
        //         })
        //     )
        // }else{
        //     setStateChk2(
        //         vStateChk.map((obj: { module_master: { id: any; }; }) => {
        //             if (obj.module_master.id === record.id) {
        //                 return { ...obj, [key]: e.target.checked };
        //             }
        //             return obj;
        //         })
        //     )
        // }
        // console.log('vStateChk',vStateChk)
        // console.log('vStateChk2',vStateChk2)

        // setStateChk(
        //     moduleAccState.map((obj: { module_master: { id: any; }; }) => {
        //         if (obj.module_master.id === record.id) {
        //             return { ...obj, [key]: e.target.checked };
        //         }
        //         return obj;
        //     })
        // )

        const stateCheck = moduleAccState.map((obj: { module_master: { id: any; }; }) => {
            if (obj.module_master.id === record.id) {
                return { ...obj, [key]: e.target.checked };
            }
            return obj;
        });

        // console.log('stateCheck', stateCheck);

        // setModuleAccState(stateCheck);
        // const result = stateCheck.filter((f: IModuleAccess) => f.id === record.id);
        // console.log('result',result)

        // updateModule({resource: "module-accesses",id: record.id,values: {view: ,
        //         create: ,
        //         delete: ,
        //         update: ,
        //         assign: ,
        //         approve: 
        //     },
        //     successNotification: false,
        // });

        // switch(key) {
        //     case 'view':
        //         updateModule({resource: "module-accesses",id: record.id,values: {view: e.target.checked},successNotification: false});
        //         break;
        //     case 'create':
        //         updateModule({resource: "module-accesses",id: record.id,values: {create: e.target.checked},successNotification: false});
        //         break;
        //     case 'update':
        //         updateModule({resource: "module-accesses",id: record.id,values: {update: e.target.checked},successNotification: false});
        //         break;
        //     case 'delete':
        //         updateModule({resource: "module-accesses",id: record.id,values: {delete: e.target.checked},successNotification: false});
        //         break;
        //     case 'assign':
        //         updateModule({resource: "module-accesses",id: record.id,values: {assign: e.target.checked},successNotification: false});
        //         break;
        //     case 'approve':
        //         updateModule({resource: "module-accesses",id: record.id,values: {approve: e.target.checked},successNotification: false});
        //         break;
        //     default:
        //       return 'foo';
        // }
    }

    const moduleAccessRes = useList<IModuleAccess>({
        resource: "module-accesses",
        metaData: {
          populate: ["role", "role.role_master", "module_master"],
        },
        config: {
          sort: [{ order: "asc", field: "module_master.module_code" }],
        //   filters: [{ field: "role][id]", operator: "in", value: '2' }],
        //   filters: [{ field: "role][name]", operator: "in", value: '2' }],
        //   filters: [{ field: "role][id]", operator: "in", value: recordData ? recordData.id : 1 }],
        },
    });
    
    const { data: moduleAccessData, isFetching } = moduleAccessRes;
    moduleAccess = moduleAccessData?.data ? moduleAccessData?.data : [];

    const moduleMasterRes = useList<IModuleMaster>({
        resource: "module-masters",
        config: {
          sort: [{ order: "asc", field: "module_code" }],
          pagination:{current: 1, pageSize: 200},
        },
    });
    
    const { data: moduleMasterData } = moduleMasterRes;
    moduleMaster = moduleMasterData?.data ? moduleMasterData?.data : [];

    console.log('moduleMaster',moduleMaster)
    // let k:any;
    // useEffect(() => {
    //     // console.log('moduleAccess', moduleAccess);

    //     setModuleAccState(
    //         moduleMaster.map((val, idx)=> ({
    //             key: idx.toString(),
    //             view: false,
    //             create: false,
    //             update: false,
    //             delete: false,
    //             assign: false,
    //             approve: false,
    //             module_master: val,
    //             role: "",
    //             // use_workflow: false,
    //         }))
    //     )

    //   }, [moduleMaster]);

      const columns: ColumnsType<IModuleAccess> = [
        {
          title: "Menu",
          dataIndex: "menu",
          key: "menu",
          render: (_, record) => <>{record.module_master?.module_name}</>,
        },
        {
            title: "View",
            key: "view",
            dataIndex: "view",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.view} 
                  onChange={(e) => onChangePermissions2(record, "view", e)}
                />
              </>
            ),
          },
        {
          title: "Create",
          key: "create",
          dataIndex: "create",
          align: "center",
          render: (_, record) => (
            <>
              <Checkbox
                checked={record.create}
                onChange={(e) => onChangePermissions2(record, "create", e)}
              />
            </>
          ),
        },
        {
            title: "Update",
            key: "update",
            dataIndex: "update",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.update}
                  onChange={(e) => onChangePermissions2(record, "update", e)}
                />
              </>
            ),
          },
        {
          title: "Delete",
          key: "delete",
          dataIndex: "delete",
          align: "center",
          render: (_, record) => (
            <>
              <Checkbox
                checked={record.delete}
                onChange={(e) => onChangePermissions2(record, "delete", e)}
              />
            </>
          ),
        },
        {
            title: "Assign",
            key: "assign",
            dataIndex: "assign",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.assign}
                  onChange={(e) => onChangePermissions2(record, "assign", e)}
                />
              </>
            ),
          },
          {
            title: "Approve",
            key: "approve",
            dataIndex: "approve",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.approve}
                  onChange={(e) => onChangePermissions2(record, "approve", e)}
                />
              </>
            ),
          },
    ];

    // console.log('moduleAccState', moduleAccState);

    function createAndRedirect(v:any){
        v.createBy = user_data.user_profile.id
        v.role_master = 1 // Admin
        // CreateModuleAcc()
        return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(v))
        );
    }

    function CreateModuleAcc(){

        // funcSetModuleAcc(recordData.data.id);

        // if(moduleAccState.length > 0){
        //     // console.log(vTimeSlotTEST);
        //     for(let i=0 ; i <= moduleAccState.length -1; i++){
        //         // console.log('checkkkk ',moduleAccState[i]);
        //         creatModule({
        //             resource: "module-accesses",
        //             values: [{
        //                 approve : moduleAccState[i].approve,
        //                 assign : moduleAccState[i].approve,
        //                 create : moduleAccState[i].create,
        //                 delete : moduleAccState[i].delete,
        //                 update : moduleAccState[i].update,
        //                 view : moduleAccState[i].view,
        //                 module_master : moduleAccState[i].module_master.id,
        //                 role : 1
        //             }],
        //             successNotification: false,
        //         });
        //     }
        // }
    }

    // console.log('moduleK', moduleMaster)

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {

                    createAndRedirect(values);
                    // values.createBy = user_data.user_profile.id

                    // values.role_master = 1 // Admin
                    // // values.company = 2 //
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(mediaUploadMapper(values))
                    // );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={12}>
                        <Card style={{ borderRadius: 5,boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("role.name")} name="custom_role_name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter role name.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col span={24}>
                                    <Form.Item name="status" 
                                        // label={t("notice-ch")}
                                        label="Status"
                                    >
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "Activated",
                                                    value: true,
                                                },
                                                {
                                                    label: "InActive",
                                                    value: false,
                                                }
                                            ]}
                                            onChange={onChangeNoti}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </Card>
                    </Col>

                    {/* <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "2%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                            <Divider></Divider>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={16}>
                                    <div style={{marginBottom: "5%"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("lupdate")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("by")}</div>
                                </Col>                    
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
            </Form>
            <div style={{marginBottom:"2%"}}></div>
            {/* <Row gutter={[16, 24]}>
                <Col span={24}>
                    <Table {...tableProps} 
                        bordered
                        size="middle"
                        rowKey = "id"
                        // pagination={{ 
                        //     defaultPageSize:10, 
                        //     showSizeChanger: true, 
                        //     locale:{ items_per_page: "/ Pages"},
                        //     pageSizeOptions: pageSizeState, 
                        //     onShowSizeChange , 
                        //     total:paginationLength.length, 
                        //     onChange(Current){ setPage(Current)}}}  
                    >

                        <Table.Column dataIndex="permission" title="Menu" align="center" width={100}
                            render={(value:any,record:any)=>{
                                // console.log('record xx', record)
                                return <>
                                    <TextField style={{fontWeight: 600}} value={record ? record?.module_name : "-"}  />
                                </>
                            }}
                        />

                        <Table.Column dataIndex="act_view" title="View" align="center" width={100}
                            render={(value:any,record:any)=>{
                                return <>
                                    <Checkbox
                                        onChange={(e) => onChangePermissions2(record, "view", e)}
                                    />
                                </>
                            }}
                        />

                        <Table.Column dataIndex="act_create" title="Create" align="center" width={100}
                            render={(value:any,record:any)=>{
                                return <>
                                    <Checkbox
                                        // checked={record.create}
                                        onChange={(e) => onChangePermissions2(record, "create", e)}
                                    />
                                </>
                            }}
                        />

                        <Table.Column dataIndex="act_update" title="Update" align="center" width={100}
                            render={(value:any,record:any)=>{
                                return <>
                                    <Checkbox
                                        // checked={record.update}
                                        onChange={(e) => onChangePermissions2(record, "update", e)}
                                    />
                                </>
                            }}
                        />

                        <Table.Column dataIndex="act_delete" title="Delete" align="center" width={100}
                            render={(value:any,record:any)=>{
                                return <>
                                    <Checkbox
                                        // checked={record.delete}
                                        onChange={(e) => onChangePermissions2(record, "delete", e)}
                                    />
                                </>
                            }}
                        />

                        <Table.Column dataIndex="act_assign" title="Assign" align="center" width={100}
                            render={(value:any,record:any)=>{
                                return <>
                                    <Checkbox
                                        // checked={record.assign}
                                        onChange={(e) => onChangePermissions2(record, "assign", e)}
                                    />
                                </>
                            }}
                        />

                        <Table.Column dataIndex="act_approve" title="Approve" align="center" width={100}
                            render={(value:any,record:any)=>{
                                return <>
                                    <Checkbox
                                        // checked={record.approve}
                                        onChange={(e) => onChangePermissions2(record, "approve", e)}
                                    />
                                </>
                            }}
                        />
                    </Table>
                </Col>
            </Row> */}
        </Create>
    );
};