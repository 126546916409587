/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, Row, useForm, useSelect,Select, Upload, Modal, Radio, RadioChangeEvent, message } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper} from "@pankod/refine-strapi-v4";
import { IEventlog, IProperties, IUnit, Ivehicle, IvehicleBrand, IvehicleColor, IvehicleModel, IvehicleType } from "interfaces";

// import { PlusOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/es/upload';
// import type { UploadFile } from 'antd/es/upload/interface';
import { useState } from "react";

import { TOKEN_KEY } from "../../constants";
import axios from "axios";

export const VehicleCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint          = "even-logs";
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form, formProps, saveButtonProps } = useForm<Ivehicle,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : []; 
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'vehicles',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );
    const [vProj, setProj]      = useState("");
    // const [vUnit, setUnit]      = useState("");
    const [vAppstatus, setAppstatus] = useState<boolean>();
    
    const [seltype, setseltype] = useState<any>();
    const [selbrand, setselbrand] = useState<any>();
    
    const API_URL = useApiUrl();
    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
        }
    }

    const { selectProps: SelPropertyType } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [ 
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       : 'N',
            }
        ]
    });

    const { selectProps: SelUnit} = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       : 'N',
            },
            {
                field   : "property][id]",
                operator: "eq",
                value: vProj
            }
        ]
    });
    
    const { selectProps: SelTypeV} = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const { selectProps: SelBrandV} = useSelect<IvehicleBrand>({
        resource: "vehicle-brands",
        optionLabel: "brand_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'vehicle_type][id]',
                operator    : 'eq',
                value       : seltype,
            },
        ] 
    });

    const { selectProps: SelModelV} = useSelect<IvehicleModel>({
        resource: "vehicle-models",
        optionLabel: "model_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'vehicle_type][id]',
                operator    : 'eq',
                value       : seltype,
            },
            {
                field       : 'vehicle_brand][id]',
                operator    : 'eq',
                value       : selbrand,
            },
        ] 
    });

    const { selectProps: SelColorV} = useSelect<IvehicleColor>({
        resource: "vehicle-colors",
        optionLabel: "color_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [ vHaveSticker, setHaveSticker] = useState<boolean>();
    const [ vParkingRight, setParkingRight] = useState<any>('');
    const [ vParkingAlreadyHas, setParkingAlreadyHas] = useState<any>('');

    

    const handleCancel = () => setPreviewVisible(false);

    const onChangeApprove = ({ target: { value } }: RadioChangeEvent) => {
        setAppstatus(value);
    };

    let resultQuery1:any = []
    let resultQuery2:any = []

    // async function callAPI(v:any){
    //     const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    //     resultQuery1 = await axios.get(API_URL + "/appointment-time-slots/slot_chk/?date=" + v , { headers: { Authorization: AuthStr } });
    // } 

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const onChangeRadio = (e: RadioChangeEvent) => {
        console.log('onChangeRadio3', e.target.value);
        setHaveSticker(e.target.value);
    };

    async function getUnitParking(id:any){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        resultQuery1 = await axios.get(API_URL + "/units?populate=unitParking&filters[id][$eq]=" + id ,{ headers: { Authorization: AuthStr } });
        
        console.log('resultQuery1', resultQuery1?.data.data);

        // setParkingRight(resultQuery1?.data.data[0].attributes.unitParking.data.attributes.parkingCode)

        let getParkingCode = parseInt(resultQuery1?.data?.data[0]?.attributes?.unitParking?.data?.attributes?.parkingCode);
        console.log('getParkingCode', getParkingCode)
        // setParkingRight(parseInt(resultQuery1?.data?.data[0]?.attributes?.unitParking?.data?.attributes?.parkingCode))

        if(getParkingCode){
            console.log('have parking code')
            setParkingRight(getParkingCode)
        }else{
            console.log('no parking code')
            setParkingRight(0)
        }
        // const arrayID = resultQuery1.data.map((item:any) => {
        //     return item
        // })
        // setdatavmu(arrayID)

        // parseInt("10");


        // find Vehicle of this unit
        resultQuery2 = await axios.get(API_URL + "/vehicles?populate=*&filters[unit][id]=" + id ,{ headers: { Authorization: AuthStr } });
        console.log('resultQuery2', resultQuery2?.data.data);

        if(resultQuery2?.data.data.length > 0){
            setParkingAlreadyHas(resultQuery2?.data.data.length);
        }else{
            setParkingAlreadyHas(0);
        }
    }

    const warning = () => {
        
        // for(let i=0; i<= vInputUnitType.length -1; i++){
        //     let field = "kSelectRound_" + i
        //     form.resetFields([field])
        // }

        Modal.warning({
          title: <span>{t("msg-vehicles-create.title-warning")}</span>,
          content: <span>{t("msg-vehicles-create.content-warning")}</span>,
        });
    };

    // console.log('vParkingRight',vParkingRight)
    // console.log('vParkingAlreadyHas',vParkingAlreadyHas)
    // console.log('vUnit', vUnit)


    function updateOnFinish(values:any){
        return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(values))
        );
    }

    console.log('user_data.user_profile.id : ',user_data?.user_profile?.id)

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("vehicle.create")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            values.createBy = user_data.user_profile.id
            values.approve_status = vAppstatus + ""

            if(vParkingAlreadyHas >= vParkingRight){
                warning();
            }else{
                updateOnFinish(values)
            }

            // return ( 
            //     formProps.onFinish &&
            //     formProps.onFinish(mediaUploadMapper(values))
            // );
         }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={[16, 24]}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.license")} name="license_plate"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter license plate.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.year")} name="year"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter year.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>


                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.sticstatus")} name="sticker_status"
                                    rules={[
                                        {
                                            required: true, message: 'Please select.'
                                        },
                                    ]}
                                >
                                    <Radio.Group 
                                        style={{verticalAlign: 'middle'}} 
                                        onChange={onChangeRadio}
                                    >
                                        <Radio.Button value={true}>{t("vehicle.sttrue")}</Radio.Button>
                                        <Radio.Button value={false}>{t("vehicle.stfalse")}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            {
                                vHaveSticker ?
                                    <>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item  label={t("vehicle.sticid")} name="sticker_id"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter sticker id.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>
                                    </>
                                :
                                    <></>
                            }

                            <Col className="gutter-row" span={12}>
                                <Form.Item name="pix" label={t("vehicle.pix")} valuePropName="fileList" getValueProps={(data) => getValueProps(data, API_URL)}>
                                    <Upload.Dragger
                                        style={{borderRadius: 10}}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        multiple
                                        maxCount={1}
                                        onChange={fileChange}
                                        beforeUpload={beforeUpload}
                                    >
                                        <p className="ant-upload-text">{t("upload")}</p>
                                    </Upload.Dragger>
                                </Form.Item>
                                <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*PNG, JPG Maximum size 5MB</p>
                                
                                <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
                                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                                </Modal>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("vehicle.type")} name="vehicle_type"
                                            rules={[
                                                {
                                                    required: true, message: 'Please select.'
                                                },
                                            ]}
                                        >
                                                <Select placeholder={t("select.sel")} 
                                                    {...SelTypeV}
                                                    onChange={(e) => {
                                                        setseltype(e);
                                                        form.resetFields(["vehicle_brand"]);
                                                        form.resetFields(["vehicle_model"]);
                                                    }}
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("vehicle.brand")} name="vehicle_brand"
                                            rules={[
                                                {
                                                    required: true, message: 'Please select.'
                                                },
                                            ]}
                                        >
                                                <Select placeholder={t("select.sel")} 
                                                    {...SelBrandV}
                                                    onChange={(e) => {
                                                        setselbrand(e);
                                                    }}
                                                />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("vehicle.model")} name="vehicle_model"
                                            rules={[
                                                {
                                                    required: true, message: 'Please select.'
                                                },
                                            ]}
                                        >
                                                <Select placeholder={t("select.sel")} {...SelModelV}/>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("vehicle.color")} name="vehicle_color"
                                            rules={[
                                                {
                                                    required: true, message: 'Please select.'
                                                },
                                            ]}
                                        >
                                                <Select placeholder={t("select.sel")} {...SelColorV}  />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Form.Item  label={t("property.title")} name="property"
                                rules={[
                                    {
                                        required: true, message: 'Please select Property.'
                                    },
                                ]}
                            >
                                    <Select placeholder={t("select.sel")} {...SelPropertyType} 
                                        onSelect={(value: any) => {
                                            setProj(value);
                                        }}
                                    />
                            </Form.Item>

                            <Form.Item  label={t("unit.title")} 
                                name="unit"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit code.'
                                    },
                                ]}
                            >
                                    <Select placeholder={t("select.sel")} {...SelUnit} onSelect={(value: any) => {
                                            // setUnit(value);
                                            getUnitParking(value)
                                        }}/>
                            </Form.Item>

                            <Form.Item  label={t("vehicle.appstatus")} name="approve_status"
                                rules={[
                                    {
                                        required: true, message: 'Please select.'
                                    },
                                ]}
                                >
                                <Radio.Group style={{verticalAlign: 'middle'}} onChange={onChangeApprove}>
                                    <Radio.Button value={true}>{t("vehicle.true")}</Radio.Button>
                                    <Radio.Button value={false}>{t("vehicle.false")}</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Create>   
    );
};