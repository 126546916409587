/* eslint-disable */
import { Breadcrumb, Button, Col, Create, Form, Icons, Image, List, Modal, Row, Select, notification, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog } from "interfaces";
import { useState } from "react";
import { TOKEN_KEY } from "../../../constants";

import axios from "axios";

export const HcuAssignCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    // console.log('data_property', data_property)
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    // const { TextArea } = Input;
    // const { Text } = Typography;
    // const format = 'HH:mm';
    const [vPropID, setPropID] = useState<any>();


    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const log_endpoint = "even-logs";
    const { replace, list} = useNavigation();
    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    // console.log('checkedSms', checkedSms)

    const [Inspector, SetInspector] = useState<any>([]);
    const [InspectorId, SetInspectorId] = useState<any>([]);
    const [IdFilter, SetIdFilter] = useState<any>([]);

    const [checked2lan, setchecked2lan] = useState<boolean>(false)

    const { form, formProps, saveButtonProps } = useForm<any,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource : log_endpoint,
                values : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(),
                    log_event               : "Create",
                    menu_event              : 'hcu-settings',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
        errorNotification(error:any, values:any, resource:any) {
            return {
                message: error.toString(),
                type: "error" ,
                undoableTimeout: 1000
            }
        },
        onMutationError(error, variables, context) {
            console.log('error', error)
            console.log('variables', variables)
            console.log('context', context)
        },
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property !== null ? "eq" : "ne",
            //     value : data_property !== null ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "role_management][id]",
                operator: "eq",
                value: 19 // role Inspector
            },
            {
                field : "user_profile][deleteStatus]",
                operator : "eq",
                value : "N"
            },
            // {
            //     field : "property][id]",
            //     operator : data_property !== null ? "eq" : "ne",
            //     value : data_property !== null ? data_property : null
            // },
            {
                field : "property][id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
            {
                field : 'property][id]',
                operator : 'contains',
                value :  vPropID,
            },
            {
                field: "id",
                operator: "nin",
                value: IdFilter.length > 0 ? IdFilter : 0
            }
        ]
    });

    const AddInspectorToArr = async (e:any) => {
        // console.log('e', e)
        IdFilter.push(e)
        SetIdFilter(IdFilter);
        // let k = await axios.get(API_URL + "/user-type-mappings?populate=*&filters[id][$eq]="+ e, {
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        // console.log('k', k)
        let inspector_profile = k?.data?.data
        // console.log('inspector_profile', inspector_profile[0].attributes.user_profile?.data.attributes)

        Inspector.push(inspector_profile[0].attributes.user_profile?.data.attributes);
        InspectorId.push(inspector_profile[0].attributes.user_profile?.data.id);
        SetInspector(Inspector)

        setTimeout(() => {
            form.resetFields(["Inspector"]);
        }, 500);

        // console.log('Inspector', Inspector)
        // console.log('IdFilter', IdFilter)
    }

    const RemoveItemFromArr = async (e:any) => {
        // console.log('e', e)
        Inspector.splice(e, 1); // 2nd parameter means remove one item only
        InspectorId.splice(e,1)
        IdFilter.splice(e, 1);
    }
    console.log('Inspector', Inspector)
    console.log('InspectorId', InspectorId)
    console.log('IdFilter', IdFilter)
    async function createAssignInspec(values:any) {

        console.log('Inspector', Inspector)
        console.log('InspectorId', InspectorId)
        console.log('IdFilter', IdFilter)
        console.log('values', values)

        let data = { 
            "data":{
                "property": values?.property,
                inspec_team: InspectorId,
                "createBy" : Identity_data ? Identity_data.user_profile.id : null
            }
        }

        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        successNoti('200', 'success');

        replace("/majorcare/setup-hcu")
    }

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully edit term and condition',
        });
    };


    return (
    <Create 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                {...formProps}
                layout="vertical"
                onFinish={(values:any) => {
                    // if(checked2lan == true){
                    //     createNoti(values)
                    // }else if(checked2lan == false && (checkedInApp == true || checkedSms == true || checkedEmail == true)){
                    //     setswlocal('en')
                    //     setchecked2lan(true)
                    // }else if(checkedInApp == false && checkedSms == false && checkedEmail == false){
                    //     error();
                    // }
                    
                    values.property = values.property.id;
                    // values.type = values.type.value;

                    createAssignInspec(values);
                    // values.msg = values.msg;
                    // values.msg_en = values.msg;
                    // values.createBy = user_data.user_profile.id;
                    // values.deleteStatus = "N";

                    // values.sms = values.sms == undefined ? false : true;
                    // values.email = values.email == undefined ? false : true;
                    // values.inApp = values.inApp == undefined ? false : true;

                    // console.log(values)

                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={6} className="gutter-row" style={{borderRight: "1px solid #dedede",paddingTop: "2.5rem"}}>
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty}
                                    onChange={(value) => {
                                        setPropID(value);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                name="Inspector"
                                label="Inspector"
                                // rules={[
                                //     {
                                //         required: true, message: "Please select type"
                                //     },
                                // ]}
                            >
                                {/* <Select placeholder={t("select.sel")}>
                                    <Option value="1">{"แจ้งเตือนรอบที่ 1"}</Option>
                                    <Option value="2">{"แจ้งเตือนรอบที่ 2"}</Option>
                                </Select> */}
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selInspector}
                                    onSelect={(value: any, record:any) => {
                                        AddInspectorToArr(value);
                                        refetch();
                                    }}
                                >
                                    {/* <Option value="1">{"แจ้งเตือนรอบที่ 1"}</Option>
                                    <Option value="2">{"แจ้งเตือนรอบที่ 2"}</Option> */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18} className="gutter-row" style={{paddingTop: "2.5rem"}}>
                            <Row gutter={82}>
                                <Col span={24} className="gutter-row">

                                <div style={{fontSize:"1vw"}}>{t("mdpc-assign-technician.list-of-technician")}</div>
                                   <div> 
                                    {
                                        Inspector.length > 0 ?
                                            Inspector.map((item: any, index: any) => {
                                                // console.log('item', item)
                                                // console.log('index', index)
                                                return <>
                                                <Row gutter={8} style={{marginTop: "1rem"}}>
                                                    <Col span={22} className="gutter-row">
                                                        <Row gutter={8}>
                                                            <Col span={4} className="gutter-row">
                                                                <Image 
                                                                    alt="Avatar" 
                                                                    src={item?.avatar?.data !== null ? item?.avatar?.data?.attributes.url : "https://lscmain.s3.ap-southeast-1.amazonaws.com/toppng_com_user_account_management_logo_user_icon_561x561_66926db800.png"}
                                                                    style={{borderRadius : "50px",width: "100%", height: "50px"}}
                                                                />
                                                            </Col>
                                                            <Col span={20} className="gutter-row">
                                                                <div>{item.first_name} {item.last_name}</div>
                                                                <div><Icons.PhoneOutlined />{" "}{item.tel ? item.tel : "no phone number"}</div>
                                                            </Col>
                                                        </Row> 
                                                    </Col>
                                                    <Col span={2} className="gutter-row">
                                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} 
                                                            onClick={() => { RemoveItemFromArr(index); refetch(); }} 
                                                            danger 
                                                        />
                                                    </Col>

                                                </Row>
                                                <div style={{marginBottom:"20px"}}></div> 
 
                                                    {/* <div style={{marginBottom:"20px"}}></div> 
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div key={key} style={{ display: "flex", alignItems: "center" }}>
                                                            <div className="circular_image">
                                                                <img
                                                                    src="https://lscmain.s3.ap-southeast-1.amazonaws.com/Karaoke_c5f17869b3.jpg"
                                                                    alt="Avatar"
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                                                                <div>
                                                                    {item.first_name} {item.last_name}
                                                                </div>
                                                                <div>
                                                                    <Icons.PhoneOutlined />{" "}{item.tel}
                                                                </div>
                                                            </div>
                                                            <div style={{ marginLeft: "100px" }}>
                                                                <Icons.DeleteOutlined />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{marginBottom:"20px"}}></div> */}

                                                </>
                                            })
                                        :
                                            <>
                                                <div className="bgimg" style={{width:"100%", height:"700px", backgroundPosition:"center"}}></div>
                                            </>
                                    }
                                   </div>
                                </Col>
                                {/* <Col span={12} className="gutter-row">
                                    
                                </Col>
                                <Col span={12} className="gutter-row">
                                    
                                </Col>
                                <Col span={24} className="gutter-row" style={{marginTop: "3rem"}}>
                                    
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Create>   
    );
};