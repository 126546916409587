import { Avatar, Breadcrumb, Button, Card, Col, Create, Divider, Form, Icons, Input, List, Modal, Radio, RcFile, Row, Select, Space, Upload, message, notification, useForm, useSelect, InputNumber, Spin } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { getValueProps } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY, petURL } from "../../constants";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { NumericFormat } from "react-number-format";
import { EditNewModalContent } from "./component/edit_modal_content";

let dataProp: any = undefined;
let dataBank: any = undefined;
let optionpettype: any = undefined;
let edit_new_data_detail: any = []

let keyKom:any
let valKom:any

let newDataProp:any = [];
export const ConfigpropCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { Option } = Select;
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();

    const refuploadTH = useRef<any>();
    const refuploadEN = useRef<any>();

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [formModal] = Form.useForm();
    const [formModal_EDIT] = Form.useForm();
    const { formProps, saveButtonProps } = useForm<any,HttpError,{}>();

    const [Prop, setProp] = useState<any>();
    const [Bank, setBank] = useState<any>();
    const [canPet, setcanPet] = useState<boolean>(false);
    const [isCharge, setisCharge] = useState<boolean>(false);
    const [paymentPF, setpaymentPF] = useState<boolean>(true);
    const [nameFileTH, setnameFileTH] = useState<any>();
    const [nameFileEN, setnameFileEN] = useState<any>();
    const [valFileTH, setvalFileTH] = useState<any>();
    const [valFileEN, setvalFileEN] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [showReqExpense, setShowReqExpense] = useState<boolean>(false);

    const [keyEDIT, setkeyEDIT] = useState<any>();
    const [mdopen, setmdopen] = useState<boolean>(false);
    const [mdopen_EDIT, setmdopen_EDIT] = useState<boolean>(false);
    const [tickerFlag, settickerFlag] = useState<boolean>(false);

    const [expenGroup, setexpenGroup] = useState<any>([]);

    const { data: dataLoad } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllConfigProperty`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log('dataLoad', dataLoad)
                for (let index = 0; index < dataLoad?.length; index++) {
                    // newDataProp?.push({
                    //     id: dataLoad[index]?.property_obj?.id,
                    //     property_name: dataLoad[index]?.property_obj?.property_name
                    // })

                    newDataProp?.push(dataLoad[index]?.property_obj?.id)
                }

                console.log('newDataProp', newDataProp)
            },
        },
    });

    const { selectProps: propertySelectProps } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
            {
                field : "id]",
                operator : "nin",
                value : newDataProp
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : "nin",
                value : newDataProp
            },
        ],
        pagination:{
            pageSize: 100
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        value: dataLoad[index]?.id,
                        label: dataLoad[index]?.property_name
                    })
                }

                if(newData?.length > 0){
                    dataProp = newData;
                }

                setTimeout(() => {
                    setLoading(false);
                }, 500);

            },
        }
    });

    const { selectProps: bankSelectProps } = useSelect<any>({
        resource: "banks",
        optionLabel: "bank_name",
        optionValue: "id",
        metaData:{
            populate: ['logo']
        },
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
        ],
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        value: dataLoad[index]?.id,
                        label: dataLoad[index]?.bank_name,
                        logo: dataLoad[index]?.logo[0]?.url
                    })
                }

                if(newData?.length > 0){
                    dataBank = newData;
                }
            },
        }
    });

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findActivePetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log('dataLoad pet type', dataLoad)
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {

                    // map data pet type
                    newDT?.push({
                        _id: dataLoad[index]?._id,
                        key: index + 1,
                        pet_type_id: dataLoad[index]?._id,
                        pet_type_name: dataLoad[index]?.pet_type_name,
                        pet_type_name_en: dataLoad[index]?.pet_type_name_en,
                        pet_limit: [
                            {
                                "size_id": "656fdd7347a1bba6e8a9b15d",
                                "size": "S",
                                "max": 0
                            },
                            {
                                "size_id": "656fdd7847a1bba6e8a9b15f",
                                "size": "M",
                                "max": 0
                            },
                            {
                                "size_id": "656fdd7d47a1bba6e8a9b161",
                                "size": "L",
                                "max": 0
                            },
                            {
                                "size_id": "656fdd8147a1bba6e8a9b163",
                                "size": "XL",
                                "max": 0
                            }
                        ],
                        is_charge: false
                    })
                }

                // console.log('newDT', newDT)
                if(newDT?.length > 0){
                    optionpettype = newDT;
                }

            },
        },
    });

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload PDF/JPEG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function fileChange(e: any, lan: any) {
        let res: any = e?.file;

        if(lan == "TH"){
            if(res?.status == "done"){
                setnameFileTH(res.response[0]?.name);
                setvalFileTH({
                    id: res.response[0]?.id,
                    url: res.response[0]?.url,
                    name: res.response[0]?.name
                });
            }
        }else if(lan == "EN"){
            if(res?.status == "done"){
                setnameFileEN(res.response[0]?.name);
                setvalFileEN({
                    id: res.response[0]?.id,
                    url: res.response[0]?.url,
                    name: res.response[0]?.name
                });
            }
        }
    }

    function removefile(lan: any) {
        if(lan == "TH"){
            setnameFileTH(undefined);
            setvalFileTH(undefined);
            formProps?.form?.resetFields(["term_th"]); 
        }else if(lan == "EN"){
            setnameFileEN(undefined);
            setvalFileEN(undefined);
            formProps?.form?.resetFields(["term_en"]);
        }
    }

    function onsetvalEdit(values: any, key: any){

        valKom = values
        keyKom = key


        // **************** map form ****************
        const mappedFormIsCharge = values?.pet_maximum_by_type?.flatMap((item:any) =>
            ({
                name: `is_charge_${item.pet_type_id}`,
                value: item.is_charge,
            })
        );

        const mappedForm = values?.pet_maximum_by_type?.flatMap((item:any) =>
            item.pet_limit.map((limit:any) => ({
                name: `pet_size_${limit.size}_${item.pet_type_id}`,
                value: limit.max,
            }))
        );
        const fieldsValues:any = {};

        mappedForm.forEach((item:any) => {
            const { name, value } = item;
            fieldsValues[name] = value;
        });

        mappedFormIsCharge.forEach((item:any) => {
            const { name, value } = item;
            fieldsValues[name] = value;
        });

        formModal_EDIT?.setFieldsValue(fieldsValues);
        formModal_EDIT?.setFieldsValue(mappedFormIsCharge);
        // ************************************************



        formModal_EDIT?.setFieldsValue({
            area_size_from: values?.area_size_from,
            area_size_to: values?.area_size_to,
            maximum_pet: values?.maximum_pet,
        });
        
        setkeyEDIT(key);
        setmdopen_EDIT(true);
    }

    function onremoveexpen(key: any) {
        let newData: any = [];

        for (let i = 0; i < expenGroup?.length; i++) {
            if(i !== key){ newData?.push(expenGroup[i]) }
        }

        setexpenGroup(newData);
        settickerFlag((pre)=> !pre);
    }

    async function onfinishexpen(values: any) {
        let new_k:any



        await axios.get(`${petURL}/api/lscpet/findActivePetType`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            let newDT: any = [];

            for (let index = 0; index < dataLoad?.length; index++) {

                // map data pet type
                newDT?.push({
                    _id: dataLoad[index]?._id,
                    key: index + 1,
                    pet_type_id: dataLoad[index]?._id,
                    pet_type_name: dataLoad[index]?.pet_type_name,
                    pet_type_name_en: dataLoad[index]?.pet_type_name_en,
                    pet_limit: [
                        {
                            "size_id": "656fdd7347a1bba6e8a9b15d",
                            "size": "S",
                            "max": 0
                        },
                        {
                            "size_id": "656fdd7847a1bba6e8a9b15f",
                            "size": "M",
                            "max": 0
                        },
                        {
                            "size_id": "656fdd7d47a1bba6e8a9b161",
                            "size": "L",
                            "max": 0
                        },
                        {
                            "size_id": "656fdd8147a1bba6e8a9b163",
                            "size": "XL",
                            "max": 0
                        }
                    ],
                    is_charge: false
                })
            }

            // console.log('newDT', newDT)
            if(newDT?.length > 0){
                new_k = newDT;
            }
        })

        edit_new_data_detail = []
        edit_new_data_detail = new_k

        edit_new_data_detail.forEach((petType:any) => {
            const typeId = petType._id;

            Object.keys(values).forEach((key) => {
                const parts = key.split('_');
                if (
                    parts.length === 3 &&
                    parts[0] === 'is' &&
                    parts[1] === 'charge' &&
                    parts[2] === typeId
                ) {
                    const inputValue = values[key];
                    if (inputValue !== undefined) {
                        petType.is_charge = inputValue;
                    }
                }
            });
          
            petType.pet_limit.forEach((limit:any) => {
              const size = limit.size;
          
              Object.keys(values).forEach((key) => {
                const parts = key.split('_');
                if (
                  parts.length === 4 &&
                  parts[0] === 'pet' &&
                  parts[1] === 'size' &&
                  parts[3] === typeId &&
                  parts[2].toUpperCase() === size
                ) {
                  const inputValue = values[key];
                  if (inputValue !== undefined) {
                    limit.max = inputValue;
                  }
                }
              });
            });
        });



        // ===========================================================
        let newData: any = [{
            "area_size_from": values?.area_size_from,
            "area_size_to": values?.area_size_to,
            "maximum_pet": values?.maximum_pet,
            "pet_maximum_by_type": edit_new_data_detail
        }]

        setexpenGroup((pre: any) => [...pre, ...newData]);
        settickerFlag((pre)=> !pre);
        setmdopen(false);
    }

    async function onfinishexpenEDIT(values: any) {
        let dataLoad: any = expenGroup;


        // =============================================
        // new method

        dataLoad[keyEDIT]?.pet_maximum_by_type.forEach((petType:any) => {
            const typeId = petType._id;

            Object.keys(values).forEach((key) => {
                const parts = key.split('_');
                if (
                    parts.length === 3 &&
                    parts[0] === 'is' &&
                    parts[1] === 'charge' &&
                    parts[2] === typeId
                ) {
                    const inputValue = values[key];
                    if (inputValue !== undefined) {
                        petType.is_charge = inputValue;
                    }
                }
            });
          
            petType.pet_limit.forEach((limit:any) => {
              const size = limit.size;
          
                Object.keys(values).forEach((key) => {
                    const parts = key.split('_');
                    if (
                        parts.length === 4 &&
                        parts[0] === 'pet' &&
                        parts[1] === 'size' &&
                        parts[3] === typeId &&
                        parts[2].toUpperCase() === size
                    ) {
                        const inputValue = values[key];
                        if (inputValue !== undefined) {
                            limit.max = inputValue;
                        }
                    }
                });
            });
        });
        // =============================================

        let newData: any = {
            "area_size_from": values?.area_size_from,
            "area_size_to": values?.area_size_to,
            "maximum_pet": values?.maximum_pet,
            "pet_maximum_by_type": dataLoad[keyEDIT]?.pet_maximum_by_type,
        }

        dataLoad[keyEDIT] = newData;
        setexpenGroup(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT(false);
    }

    async function submitDT(values:any){
        // let body: any = {
        //     "property_obj": Prop,
        //     "can_pet": canPet,
        //     "term_th": valFileTH,
        //     "term_en": valFileEN,
        //     "is_charge": isCharge,
        //     "payment_platform": paymentPF,
        //     "bank_obj": Bank,
        //     "bank_account": values?.bank_account,
        //     "bank_account_no": values?.bank_account_no,
        //     "create_by": {
        //         "user_id": user_dt?.user_profile?.id,
        //         "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
        //     },
        // }
        let body: any = {
            "property_obj": Prop,
            "can_pet": canPet,
            "term_th": valFileTH,
            "term_en": valFileEN,
            "is_charge": isCharge,
            "payment_platform": isCharge ? paymentPF : null,
            "bank_obj": Bank,
            "bank_account": values?.bank_account,
            "bank_account_no": values?.bank_account_no,
            "create_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
        }
        


        if(expenGroup?.length <= 0 && isCharge){
            setShowReqExpense(true)
        }else{
            // console.log('finish')
            let submit = await axios.post(petURL + "/api/lscpet/createConfigProperty", body, { headers: { } })
            .then(async response => {
                let res: any = response?.data;
                let dataUP: any = expenGroup;
    
                for (let i = 0; i < dataUP?.length; i++) {
                    let body: any = {
                        "config_property": res?._id,
                        "area_size_from": dataUP[i]?.area_size_from,
                        "area_size_to": dataUP[i]?.area_size_to,
                        // "maximum_pet": dataUP[i]?.maximum_pet,
                        "pet_maximum_by_type": dataUP[i]?.pet_maximum_by_type,
                        "create_by": {
                            "user_id": user_dt?.user_profile?.id,
                            "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
                        },
                    }
                    
                    let submit = await axios.post(petURL + "/api/lscpet/createConfigPropDetail", body, { headers: { } })
                }
    
                successNoti('Create is Success');
                replace("/pets/config-property");
            })
            .catch(error => {
                console.error('error ',error);
                faildNoti('Create is Faild');
            });
        }
    }

    const successNoti = (msg:any) => {
        notification.success({
            message: msg,
            duration: 3,
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: msg,
            duration: 3,
        });
    };

    useEffect(() => {
       setShowReqExpense(false)
    }, [expenGroup]);
    
    console.log('expenGroup', expenGroup)
    
    return (
        <Create title={t("config-property.title-create")} saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/config-property")}}}>
            <List 
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-service.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("config-property.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("config-property.title-create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Spin size="large" spinning={isLoading}>
                <div style={{width: "40%"}}>
                    <Form 
                        {...formProps} 
                        layout="vertical"
                        onFinish={(values:any) => {
                            submitDT(values);
                        }}
                    >
                        <Row gutter={[6, 16]}>
                            <Col className="gutter-row" span={8}>
                                <div style={{marginTop: "8px"}}>
                                    <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("property.title")}</span>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                                <Form.Item 
                                    name="property"
                                    className="visible-label"
                                    style={{marginBottom: 0}}
                                    rules={[
                                        {
                                            required: true, message: 'please select property.'
                                        },
                                    ]}
                                >
                                    <Select
                                        options={dataProp}
                                        placeholder={t("select.sel")}
                                        showSearch
                                        allowClear
                                        onChange={(val: any, e: any)=> setProp({
                                            id: e?.value,
                                            property_name: e?.label,
                                        })}
                                        onClear={()=> setProp(undefined)}
                                        style={{width: "100%"}}
                                        filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div style={{marginTop: "8px"}}>
                                    <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.activate-pet")}</span>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                                <Form.Item 
                                    name="can_pet"
                                    className="visible-label"
                                    style={{marginBottom: 0}}
                                    rules={[
                                        {
                                            required: true, message: 'please select can pet.'
                                        },
                                    ]}
                                    initialValue={false}
                                >
                                    <Radio.Group defaultValue={canPet} value={canPet} onChange={(e: any) => setcanPet(e?.target?.value)}>
                                        <Radio value={false}>{t("config-property.close")}</Radio>
                                        <Radio value={true}>{t("config-property.open")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        {canPet == true && <Divider />}

                        {/* {canPet == true && <span>Term &amp; Condition</span>} */}

                        {canPet == true &&
                            <Row gutter={[6, 16]}>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("T&C TH")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item
                                        name="term_th"
                                        className="visible-label"
                                        valuePropName="fileList"
                                        getValueProps={(data) => getValueProps(data, API_URL)}
                                        rules={[
                                            {
                                                required: true, message: 'Please upload Document.'
                                            },
                                        ]}
                                        style={{marginBottom: 0}}
                                    >
                                        <Upload
                                            style={{ borderRadius: 10, width:'100%', border: "1px solid #dedede" }}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}

                                            listType="picture"
                                            maxCount={1}
                                            onChange={(e: any) => fileChange(e, "TH")}
                                            beforeUpload={beforeUpload}
                                            showUploadList={false}
                                            openFileDialogOnClick={false}
                                            ref={refuploadTH}
                                        >
                                            <Space style={{ width: '100%' }}>
                                                <Input
                                                    placeholder="Browse File"
                                                    value={nameFileTH}
                                                    disabled
                                                />
                                                <Button
                                                    icon={valFileTH ?
                                                        <Icons.DeleteOutlined />
                                                        :
                                                        <Icons.UploadOutlined />
                                                    }
                                                    type="primary"
                                                    onClick={() => {valFileTH ?
                                                        removefile("TH")
                                                        :
                                                        refuploadTH?.current?.upload.uploader.onClick()
                                                    }}
                                                />
                                            </Space>
                                        </Upload>
                                    </Form.Item>
                                    <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677"}}>{`${t("pet-type.size-img")}`}</p>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("T&C EN")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item
                                        name="term_en"
                                        className="visible-label"
                                        valuePropName="fileList" 
                                        getValueProps={(data) => getValueProps(data, API_URL)}
                                        rules={[
                                            {
                                                required: true, message: 'Please upload Document.'
                                            },
                                        ]}
                                        style={{marginBottom: 0}}
                                    >
                                        <Upload
                                            style={{ borderRadius: 10, width:'100%', border: "1px solid #dedede" }}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}

                                            listType="picture"
                                            maxCount={1}
                                            onChange={(e: any) => fileChange(e, "EN")}
                                            beforeUpload={beforeUpload}
                                            showUploadList={false}
                                            openFileDialogOnClick={false}
                                            ref={refuploadEN}
                                        >
                                            <Space style={{ width: '100%' }}>
                                                <Input
                                                    placeholder="Browse File"
                                                    value={nameFileEN}
                                                    disabled
                                                />
                                                <Button
                                                    icon={valFileEN ?
                                                        <Icons.DeleteOutlined />
                                                        :
                                                        <Icons.UploadOutlined />
                                                    }
                                                    type="primary"
                                                    onClick={() => {valFileEN ?
                                                        removefile("EN")
                                                        :
                                                        refuploadEN?.current?.upload.uploader.onClick()
                                                    }}
                                                />
                                            </Space>
                                        </Upload>
                                    </Form.Item>
                                    <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677"}}>{`${t("pet-type.size-img")}`}</p>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.charge")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="is_charge"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please select can pet.'
                                            },
                                        ]}
                                        initialValue={false}
                                    >
                                        <Radio.Group defaultValue={isCharge} value={isCharge} onChange={(e: any) => setisCharge(e?.target?.value)}>
                                            <Radio value={false}>{t("config-property.not-have")}</Radio>
                                            <Radio value={true}>{t("config-property.have")}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>

                                {
                                    isCharge && <span>
                                        <Col className="gutter-row" span={24}>
                                            <div style={{marginTop: "8px"}}>
                                                <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.pay-ch")}</span>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={24}>
                                            <Form.Item 
                                                name="payment_platform"
                                                className="visible-label"
                                                style={{marginBottom: 0}}
                                                rules={[
                                                    {
                                                        required: true, message: 'please select can pet.'
                                                    },
                                                ]}
                                                initialValue={true}
                                            >
                                                <Radio.Group defaultValue={paymentPF} value={paymentPF} onChange={(e: any) => setpaymentPF(e?.target?.value)}>
                                                    <Radio value={true}>{t("config-property.pay-bill")}</Radio>
                                                    <Radio value={false}>{t("config-property.pay-no-bill")}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </span>
                                }
                                
                            </Row>
                        }

                        {paymentPF == false && isCharge && canPet && <Divider />}

                        {paymentPF == false && isCharge && canPet &&
                            <Row gutter={[6, 16]}>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.bank-name")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="bank_obj"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please select property.'
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={dataBank}
                                            placeholder={t("select.sel")}
                                            showSearch
                                            allowClear
                                            onChange={(val: any, e: any)=> setBank({
                                                id: e?.value,
                                                bank_name: e?.label,
                                                logo: e?.logo,
                                            })}
                                            onClear={()=> setBank(undefined)}
                                            style={{width: "100%"}}
                                            filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.bank-acc-no")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="bank_account_no"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please input bank account no.'
                                            },
                                        ]}
                                    >
                                        <Input type="number" allowClear/>
                                        {/* <InputNumber style={{width:'100%'}} min={0}/> */}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.bank-acc-name")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="bank_account"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please input bank account.'
                                            },
                                        ]}
                                    >
                                        <Input allowClear/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        {isCharge == true && canPet && <Divider />}

                        {isCharge == true && canPet && 
                            <div className="layout_size_custom">
                                <div className="layout_size_custom_header" style={{display: "flex"}}>
                                    <div style={{marginRight: "1rem"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        {/* <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.expenses")}</span> */}
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.pet-unit")}</span>
                                    </div>
                                    <div style={{marginTop: "-0.4rem"}}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setmdopen(true);
                                            }}
                                            icon={<Icons.PlusOutlined />}
                                            size="small"
                                            style={{borderRadius: "5px"}}
                                        >{t("buttons.add")}</Button>
                                    </div>
                                </div>

                                {
                                    isCharge && showReqExpense &&
                                    <div>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#ff4d4f"}}>{t("config-property.please-add-pet")}</span>
                                    </div>
                                }

                            </div>
                        }

                        {isCharge == true && canPet && 
                            <div style={{marginTop: "10px"}}>
                                <div style={{ display:"none"}}>{JSON.stringify(tickerFlag)}</div>{/* ห้ามลบ ห้ามแก้ */}
                                {expenGroup?.length > 0 && expenGroup?.map((item:any,index:any) => {
                                    return (
                                        <Card
                                            key={index}
                                            className="card_custom_pet_species"
                                            style={{
                                                width: "100%", 
                                                marginBottom: "10px", 
                                                border: "1px solid #dedede"
                                            }}
                                        >
                                            <Row gutter={[6,8]}>
                                                <Col className="gutter-row" span={20}>
                                                    <Row gutter={6}>
                                                        <Col className="gutter-row" span={12} style={{borderRight: "1px solid #dedede"}}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("config-property.size-room")} :</span>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.area_size_from}</span>
                                                        </Col>
                                                        <Col className="gutter-row" span={12} style={{paddingLeft: "10px"}}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("config-property.to")} :</span>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.area_size_to}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "end"}}>
                                                    <Button icon={<Icons.EditOutlined />} size="small" style={{marginRight: "5px"}} onClick={()=> onsetvalEdit(expenGroup[index], index)}/>
                                                    <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremoveexpen(index)}/>
                                                </Col>
                                                {/* <Col className="gutter-row" span={22}>
                                                    <Row gutter={6}>
                                                        <Col className="gutter-row" span={8}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.num-pet")} : </span>
                                                        </Col>
                                                        <Col className="gutter-row" span={16}>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.maximum_pet}</span>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                            </Row>
                                        </Card>
                                    )})
                                }
                            </div>
                        }

                        <Modal
                            title={t("config-property.add-expen")}
                            visible={mdopen}
                            onCancel={() => setmdopen(false)}
                            onOk={() => formModal?.submit()}
                            afterClose={()=> {
                                formModal?.resetFields();
                            }}
                        >
                            <Form 
                                form={formModal}
                                layout="vertical"
                                onFinish={(values:any) => {
                                    onfinishexpen(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.size-room")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_from"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size from.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_to"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size to.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    {/* <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.num-pet")}</span>
                                        </div>
                                    </Col> */}

                                    {/* <Col span={18}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="maximum_pet"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size.'
                                                },
                                            ]}
                                            style={{marginBottom: 0}}
                                        >
                                            <InputNumber style={{width:'100%'}} min={0} />
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={8}>
                                        <div style={{marginTop: "5px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("กำหนดจำนวนสัตว์เลี้ยง/ยูนิต")}</span>
                                        </div>
                                    </Col>
                                </Row>

                                {optionpettype?.length > 0 && optionpettype?.map((item:any,index:any) => {
                                    return (
                                        <>
                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{item?.key}{". "}{item?.pet_type_name}</span>
                                                        {/* <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{index}{". "}{item?.pet_type_name}</span> */}
                                                    </div>
                                                </Col>

                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("S")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_s"
                                                        // name={`pet_size_s_${item?.key}`}
                                                        name={`pet_size_s_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("M")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_m"
                                                        // name={`pet_size_m_${item?.key}`}
                                                        name={`pet_size_m_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} 
                                                            // onChange={(e: any) => onChangePetType(e, item, "m")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("L")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_l"
                                                        // name={`pet_size_l_${item?.key}`}
                                                        name={`pet_size_l_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} 
                                                            // onChange={(e: any) => onChangePetType(e, item, "l")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("XL")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_xl"
                                                        // name={`pet_size_xl_${item?.key}`}
                                                        name={`pet_size_xl_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} 
                                                            // onChange={(e: any) => onChangePetType(e, item, "xl")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={6}></Col>

                                                <Col span={3}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("มีค่าใช้จ่าย")}</span>
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" span={10}>
                                                    <Form.Item 
                                                        // name="is_charge"
                                                        name={`is_charge_${item?.pet_type_id}`}
                                                        className="visible-label"
                                                        style={{marginBottom: 0}}
                                                        rules={[
                                                            {
                                                                required: true, message: 'please select can pet.'
                                                            },
                                                        ]}
                                                        initialValue={false}
                                                    >
                                                        <Radio.Group 
                                                            defaultValue={false}
                                                            // onChange={(e: any) => setisCharge(e?.target?.value)}
                                                        >
                                                            <Radio value={false}>{t("ไม่มี")}</Radio>
                                                            <Radio value={true}>{t("มี")}</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Divider />
                                        </>
                                    )})
                                }
                            </Form>
                        </Modal>

                        <Modal
                            title={t("config-property.edit-expen")}
                            visible={mdopen_EDIT}
                            onCancel={() => setmdopen_EDIT(false)}
                            onOk={() => formModal_EDIT?.submit()}
                            afterClose={()=> {
                                formModal_EDIT?.resetFields();
                            }}
                        >
                            <Form 
                                form={formModal_EDIT}
                                layout="vertical"
                                onFinish={(values:any) => {
                                    onfinishexpenEDIT(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.size-room")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_from"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size from.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_to"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size to.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <EditNewModalContent 
                                    // dataLoad={valK}
                                    // key={keyK} 
                                    dataLoad={valKom}
                                    key={keyKom} 
                                />
                            </Form>
                        </Modal>
                    </Form>
                </div>
            </Spin>

        </Create>
    )
}