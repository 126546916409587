import { Avatar, Badge, Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, Input, List, Modal,  Row,  Select,  ShowButton,  Space, Spin, Table, Tabs, Tag, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IContenPostAnnoucement, IEventlog, IFacilityBan, IProperties} from "interfaces";
// import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

const { TabPane } = Tabs;

let dataContent: any = undefined;
export const ContentPostAnnounceList: React.FC<IResourceComponentsProps> = () => {

    const tabAnnounce = localStorage.getItem("keyTabStatusAnnounce") || 'N';
    const [keepDataStatus, setKeepDataStatus] = useState<any>(tabAnnounce || 'N')

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Announcement");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IFacilityBan>();
    const ed_point                          = "content-announcements";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    // const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading , refetch}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'DRAFT'}

    const [vInputTitle, setInputTitle]      = useState<any>();
    const [vInputTitleTest, setInputTitleTest]      = useState<any>();
    const [vStatus, setStatus ] = useState<any>(undefined);
    const [vlocale, setlocale ] = useState<any>('th');
    const [ dlStatus, setdlStatus] = useState("Y");
    const [ vProp, setvProp ] = useState<any>();

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()
    const [Loading, setLoading] = useState<boolean>(true)

    const [keepUnder, setKeepUnder] = useState<any>()
    const [activeBadgeNumber, setActiveBadgeNumber] = useState<any>(null)
    const [trashBadgeNumber, setTrashBadgeNumber] = useState<any>(null)

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IContenPostAnnoucement,HttpError, IContenPostAnnoucement>({
        metaData: {
            populate: '*',
            locale:['th']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { properties } = params;
                filters.push(
                    {
                        field       : "properties][id]",
                        operator    : "eq",
                        value       : vProp ? vProp : null,
                    },
                    {
                        field       : "name",
                        operator    : "contains",
                        value       : vInputTitle !== undefined ? vInputTitle : null
                    },
                    {
                        field       : "localizations][name]",
                        operator    : "contains",
                        value       : vInputTitleTest !== undefined ? vInputTitleTest : null
                    },
                    {
                        field       : "status",
                        operator    : "eq",
                        // value       : vStatus !== undefined ? vStatus : 'PUBLIC'
                        value       : vStatus !== undefined ? vStatus : null
                    },
                    // {
                    //     field       : "property_dev",
                    //     operator    : "eq",
                    //     value       : !!keepUnder ? keepUnder : null
                    // },
                    {
                        field       : "deleteStatus",
                        operator    : "eq",
                        value       : !!keepDataStatus ? keepDataStatus : tabAnnounce
                    },
                );
            return filters;
        },
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
            {
                field: 'id',
                order: 'desc'
            }
        ],
        initialFilter: [
            {
                field : "properties][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        initialSorter:[
            {
                field: 'id',
                order: "desc",
            }
        ],
        initialPageSize: 1000,
        queryOptions:{
            onSuccess(data: any) {
                console.log(">>> data", data)
                setLoading(false);
                let dataLoad: any = data?.data;
                //dataContent = dataLoad;
            }
        }
    });
    
    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    useSelect<any>({ 
        resource: "properties" ,
        metaData: {
            populate: '*',
            locale:['th']
        },
        filters: [
            {
                field       : "id]",
                operator    : "eq",
                value       : !!vProp && vProp !== undefined ? vProp : null
            },
        ],queryOptions:{onSuccess(data) {
            //console.log("dataPro",data?.data)
            let newData = data?.data[0]?.property_under
            //console.log("dataPronewData",newData)
            setKeepUnder(newData)
        }}
    });

    const onReset = () => {
        setKeepUnder(null)
        setInputTitle(undefined);
        setInputTitleTest(undefined);
        setStatus(undefined);
        setvProp(undefined);
        setdlStatus('N');
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    function onShowSizeChange(current:any, pageSize:any) {
            // console.log('onShowSizeChange : ', current, pageSize);
            setPageSizeChange(pageSize)
    }

    const { mutate: UpdateLocale } = useUpdate<any>();

    function updateStatus(id:any, localizeId:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            // values      : vCurDelStatus,
            values      : {
                updateBy : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
                // status : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        });

        // CreateLogDelete({
        //     resource    : log_endpoint,
        //     values      : {
        //         user_profile    : user_data.user_profile.id,
        //         log_time_stamp  : Date.now(), 
        //         log_event       : event,
        //         menu_event      : 'facility-bans',
        //         SysPkID         : id+""
        //     }
        // });

        if(localizeId && localizeId !== null || localizeId !== undefined){
            UpdateLocale({
                resource : ed_point,
                id: localizeId,
                values : {
                    updateBy        : user_data.user_profile.id + "",
                    deleteStatus    : vCurDelStatus.deleteStatus
                    // status    : vCurDelStatus.deleteStatus
                },errorNotification:false,
                successNotification:false,
                },{
                    onSuccess(data, variables, context) {
                        // close();
                    },
                }
            )
        }
    }

    function showDelConfirm(id:any, localizeId: any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                // updateStatus(id, "DRAFT");
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, localizeId: any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                // updateStatus(id, "PUBLIC");
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        setdlStatus(v);
        searchFormProps.form?.submit();
        setKeepDataStatus(v);
        localStorage.setItem("keyTabStatusAnnounce",v)
        
    }

    const API_URL = useApiUrl();
    const getData = async ()=>{
        // setLoadingTable(true)
        try {
            // let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            let apiEndPoint = API_URL + `/${ed_point}?locale=th&pagination[pageSize]=${1}&pagination[page]=${1}`
            if(dlStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${dlStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${dlStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            // if(tableSorter?.title && tableSorter?.order){
            //     apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            // }
            // else{
            //     apiEndPoint += `&sort[id]=desc`
            // }
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data
                    if(dataLoad?.meta?.pagination?.total){
                        const total = dataLoad?.meta?.pagination?.total > 0 ? dataLoad?.meta?.pagination?.total : null
                        if(dlStatus.toLowerCase() == 'y'){
                            setTrashBadgeNumber(total)
                        }
                        else{
                            setActiveBadgeNumber(total)
                        }
                    }
                    // const resultList = dataLoad?.data?.map((item: any) => {
                    //     const attributes = item?.attributes
                    //     let createBy = null
                    //     let updateBy = null
                    //     if(attributes?.createBy?.data){
                    //         createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                    //     }
                    //     if(attributes?.updateBy?.data){
                    //         updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                    //     }
                    //     const result = {id: item?.id , ...attributes}
                    //     result.createBy = createBy
                    //     result.updateBy = updateBy
                    //     return result
                    // })
                    // setListdata(resultList || [])
                    // setMetaPagination(dataLoad?.meta?.pagination)
                    // setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    // setLoadingTable(false)
                });
        } catch (error) {
            // setLoadingTable(false)
        }
    }
    
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    // }, [page,pageSizeChange,tableSorter,dlStatus,Identity_data])
    }, [dlStatus,Identity_data])

    
    useEffect(() => {
        if(Identity_data){
            setdlStatus('N')
        }
    }, [Identity_data])

    return <>
            
            <List 
                title={t("content-post-announcement.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("content-post-announcement.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                
                <Spin size="large" spinning={Loading}>
                    <Row>
                        <Col span={22}>
                            <Form
                                {...searchFormProps}
                                layout = "inline"
                                style={{marginBottom:"2%"}}
                            >
                                <Row gutter={8} style={{width: "100%"}}>
                                    <Col className="gutter-row" xxl={4} xl={4}>
                                        <Form.Item name="properties" style={{width: "100%", marginBottom: 0}}>
                                            <Select 
                                                {...propertySelectProps}
                                                placeholder="Select property" 
                                                size="middle" 
                                                style={{borderRadius: 20, position:'relative'}} 
                                                allowClear 
                                                onClear={() => setvProp(undefined)}
                                                onSelect={(e : any) => setvProp(e)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xxl={4} xl={4}>
                                        <Form.Item name="input_title" style={{width: "100%", marginBottom: 0}}>
                                            <Input placeholder="Title TH" 
                                                value={vInputTitle} 
                                                onChange={e => {
                                                    if(e.target.value.length > 0){
                                                        setInputTitle(e.target.value)
                                                    }else{
                                                        setInputTitle(undefined)
                                                    }
                                                }}
                                                allowClear
                                                style={{borderRadius: 5}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xxl={4} xl={4}>
                                        <Form.Item name="name_en" style={{width: "100%", marginBottom: 0}}>
                                            <Input placeholder="Title EN" 
                                                value={vInputTitleTest} 
                                                onChange={e => {
                                                    if(e.target.value.length > 0){
                                                        setInputTitleTest(e.target.value)
                                                    }else{
                                                        setInputTitleTest(undefined)
                                                    }
                                                }}
                                                allowClear
                                                style={{borderRadius: 5}}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xxl={4} xl={4}>
                                        <Form.Item name="in" style={{width: "100%", marginBottom: 0}}>
                                            <Select placeholder={t("select.sel")}
                                                style={{ width: '100%' }}
                                                onSelect={(value: any) => {
                                                    // console.log("status",value);
                                                    setStatus(value);
                                                }}
                                                options={[
                                                    {
                                                        label: "Public",
                                                        value: "PUBLIC",
                                                    },
                                                    {
                                                        label: "Draft",
                                                        value: "DRAFT",
                                                    }
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xxl={8} xl={8}>
                                        <Row gutter={8}>
                                            <Col className="gutter-row">
                                                <Form.Item style={{width: "100px", marginRight: "0"}}>
                                                    <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                                        <Icons.SearchOutlined /> Search
                                                    </Button>
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row">
                                                <Form.Item style={{width: "100px", marginRight: "0"}}>
                                                    <Button  onClick={()=>onReset()} htmlType="submit" style={{borderRadius: "5px"}}>
                                                        <Icons.UndoOutlined /> Reset
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                            
                        <Col span={2}>
                            <Form.Item style={{marginRight: "0", float: "right"}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form {...searchFormProps}>
                        <Form.Item name="deleteStatus" style={{marginBottom: 0}}>
                            <Tabs 
                                defaultActiveKey={!tabAnnounce ? keepDataStatus : `${tabAnnounce}`}
                                style={{ marginTop: 10, marginBottom: 10 }} 
                                onChange={onStatusChange} 
                            >
                                <TabPane
                                    key={"N"}
                                    tab={
                                        <span>
                                            {"ACTIVE"}
                                            <Badge count={activeBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                        </span>
                                    }
                                />

                                <TabPane
                                    key={"Y"}
                                    tab={
                                        <span>
                                            {"TRASH"}
                                            <Badge count={trashBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                        </span>
                                    }
                                />
                            </Tabs>
                        </Form.Item>
                    </Form>

                    <Table 
                        //{...tableProps}
                        dataSource={tableProps?.dataSource?.filter((e:any)=> e.deleteStatus == keepDataStatus)}
                        bordered
                        size="middle"
                        rowKey      = "id"
                        pagination={{ 
                            defaultPageSize:10, 
                            showSizeChanger: true, 
                            locale:{ items_per_page: "/ Pages"},
                            pageSizeOptions: pageSizeState, 
                            onShowSizeChange , 
                            // total:tableQueryResult.data?.total,
                            total:tableProps?.dataSource?.length,
                            onChange(Current){ setPage(Current)},
                            hideOnSinglePage: false
                        }}  
                    >
                        {/* <Table.Column width={30} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                        {/* <Table.Column align="center" width={30} dataIndex="pic" title="  " 
                            render={(value:any,record:any)=>{
                                // console.log('record', record)
                                return <>
                                    {
                                        value ?
                                            <Avatar.Group>
                                                { 
                                                    value.map((item:any,index:any)=> {
                                                        // console.log('item', item)
                                                        return <>
                                                            <Avatar src={item.url}/>
                                                        </>
                                                    })
                                                }
                                            </Avatar.Group>
                                        :
                                            <>
                                                <Avatar size={32} icon={<Icons.UserOutlined />} />
                                            </>
                                    }
                                </>
                            }}
                        /> */}

                        {/* <Table.Column dataIndex="seq" title="SEQUENCES" align="center" width={30}
                            render={(value:any,record:any)=>{
                                console.log('record', record)
                                return <>
                                    <TextField value={record?.seq ? record?.seq : "-"} />
                                </>
                            }}
                        /> */}

                        {/* <Table.Column dataIndex="properties" title={t("content-post-announcement.col-properties")}  width={120}
                            render={(value:any,record:any)=>{
                                return <>
                                    {
                                        record?.properties.length !== 0 ?
                                            record?.properties.map((data:any,index:any) => {
                                                // console.log('data : ', data);
                                                return <>
                                                    <Tag className="tag_post"><TextField value={data? data.property_name : <></> }  /></Tag>
                                                </> 
                                            })
                                        : <>-</>
                                    }
                                </>
                            }}
                        /> */}

                        {/* <Table.Column dataIndex="properties" title={t("content-post-promotion.col-properties")}  width={150}
                            render={(value:any,record:any)=>{
                                return <>
                                    {
                                        record?.property_dev == "All" ?
                                            <Tag className="tag_post"><TextField value={'All Properties'}  /></Tag>
                                        :   record?.property_dev == "Major" ?
                                                <Tag className="tag_post"><TextField value={'Only Major'}  /></Tag>
                                            : record?.property_dev == "Non Major" ?
                                                <Tag className="tag_post"><TextField value={'Non Major'}  /></Tag>
                                                :
                                                    record?.properties.length !== 0 ?
                                                        record?.properties.map((data:any,index:any) => {
                                                            // console.log('data : ', data);
                                                            return <>
                                                                <Tag className="tag_post"><TextField value={data? data.property_name : <></> }  /></Tag>
                                                            </>
                                                        })
                                                    : <>-</>
                                    }
                                </>
                            }}
                        /> */}

                        {/* <Table.Column dataIndex="name" title={t("content-post-announcement.col-content-title")} width={250} align="center"
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField value={record ? record?.name : "-"} />
                                </>
                            }}
                        /> */}

                        <Table.Column dataIndex="name" 
                            // title={t("content-post-news.col-content-title")} 
                            title="CONTENT TITLE (TH)" 
                            width={300} align="center"
                            className="table_colmn_custom_left_value"
                            sorter={(a:any, b:any) => {
                                if (a?.localizations[0]?.name < b?.localizations[0]?.name) {return -1}
                                if (a?.localizations[0]?.name > b?.localizations[0]?.name) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                // console.log('record', record)
                                return <>
                                    {
                                        record ? 
                                            record.locale == "th" ?
                                                <TextField value={record ? record?.name : "-"} />
                                            :record ?
                                                record.localizations.length > 0 ?
                                                    record.localizations[0].locale = "th" ?
                                                        <TextField value={record ? record.localizations[0].name : "-"} />     
                                                    : <>-</>   
                                                :<>-</>
                                            :<>-</>
                                        :<>-</>
                                    }                            
                                </>
                            }}
                        />

                        <Table.Column title="CONTENT TITLE (EN)" width={300} align="center" className="table_colmn_custom_left_value"
                            sorter={(a:any, b:any) => {
                                if (a?.localizations[0]?.name < b?.localizations[0]?.name) {return -1}
                                if (a?.localizations[0]?.name > b?.localizations[0]?.name) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                // console.log('record', record)
                                return <>
                                    {
                                        record ? 
                                            record.locale == "en" ?
                                                <TextField value={record ? record?.name : "-"} />
                                            :record ?
                                                record.localizations.length > 0 ?
                                                    record.localizations[0].locale = "en" ?
                                                        <TextField value={record ? record.localizations[0].name : "-"} />     
                                                    : <>-</>   
                                                :<>-</>
                                            :<>-</>
                                        :<>-</>
                                    }
                                    
                                </>
                            }}
                        />

                        <Table.Column title={t("content-post-announcement.col-public-date")} align="center" width={120}
                            sorter={(a:any, b:any) => {
                                if (a?.start_date < b?.start_date) {return -1}
                                if (a?.start_date > b?.start_date) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    {/* <TextField value={record?.user_profile? record?.user_profile.last_name :"-"}  /> */}
                                    {/* {console.log(">>>> user_profile",record.user_profile)} */}
                                    <TextField value={record?.start_date? <>{dayjs(record?.start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.end_date).format("DD/MM/YYYY")}</> :"-"} />
                                </>
                            }}
                        />

                        {/* <Table.Column title="SEQUENCES" align="center" width={30}
                        sorter={(a:any, b:any) => {
                            const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                            setDatafield("seq")
                            setDataorder(sorttest)
                            return (0)
                        }}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField value={record?.seq ? record?.seq : "-"} />
                                </>
                            }}
                        /> */}

                        <Table.Column title={t("content-post-announcement.col-type")} align="center"  width={120} className="table_colmn_custom_left_value"
                            sorter={(a:any, b:any) => {
                                if (a?.type < b?.type) {return -1}
                                if (a?.type > b?.type) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    {/* <TextField value={record?.user_profile? record?.user_profile.last_name :"-"}  /> */}
                                    {/* {console.log(">>>> user_profile",record.user_profile)} */}
                                    {
                                        record.type ?
                                            <>
                                                <TextField value="มี Event" />
                                            </>
                                        :
                                            <>
                                                <TextField value="ไม่ใช่ Event"  />
                                            </>
                                    }
                                </>
                            }}
                        />

                        <Table.Column title={t("content-post-announcement.col-event-date")} align="center" width={120}
                            sorter={(a:any, b:any) => {
                                if (a?.even_start_date < b?.even_start_date) {return -1}
                                if (a?.even_start_date > b?.even_start_date) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    {/* <TextField value={record?.user_profile? record?.user_profile.last_name :"-"}  /> */}
                                    {/* {console.log(">>>> user_profile",record.user_profile)} */}
                                    <TextField value={record?.even_start_date? <>{dayjs(record?.even_start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.even_end_date).format("DD/MM/YYYY")}</> :"-"} />
                                </>
                            }}
                        />

                        <Table.Column title={t("content-post-announcement.col-stat")}  align="center" width={100}
                            sorter={(a:any, b:any) => {
                                if (a?.status < b?.status) {return -1}
                                if (a?.status > b?.status) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    {/* {
                                        record?.createdAt ?
                                            <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                        :
                                            <TextField value={'-'}  />
                                    } */}
                                    <TextField value={record?.status? record?.status :"-"} />
                                </>
                            }}
                        />

                        <Table.Column title="CREATE BY" align="center" width={120} className="table_colmn_custom_left_value"
                            sorter={(a:any, b:any) => a?.createBy?.first_name.localeCompare(b?.createBy?.first_name)}
                                sortDirections={['ascend', 'descend']}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField value={record?.createBy ? record?.createBy.first_name :"-"}  />
                                </>
                            }}
                        />

                        <Table.Column title={"CREATED DATE"}  align="center" width={120}
                            sorter={(a:any, b:any) => {
                                if (a?.createdAt < b?.createdAt) {return -1}
                                if (a?.createdAt > b?.createdAt) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField style={{fontWeight: 600}} value={record?.createdAt? dayjs(record?.createdAt).format("DD-MM-YYYY HH:mm") :"-"} />
                                </>
                            }}
                        />

                        <Table.Column title="UPDATE BY" align="center" width={120} className="table_colmn_custom_left_value"
                            sorter={(a:any, b:any) => a?.updateBy?.first_name.localeCompare(b?.updateBy?.first_name)}
                                sortDirections={['ascend', 'descend']}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                                </>
                            }}
                        />

                        <Table.Column title={t("content-post-announcement.col-lastupdate")}  align="center" width={120}
                            sorter={(a:any, b:any) => {
                                if (a?.updatedAt < b?.updatedAt) {return -1}
                                if (a?.updatedAt > b?.updatedAt) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    {/* <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  /> */}
                                    <TextField style={{fontWeight: 600}} value={record?.updatedAt? dayjs(record?.updatedAt).format("DD-MM-YYYY HH:mm") :"-"} />
                                </>
                            }}
                        />

                        {/* <Table.Column dataIndex="updateAt" title="LAST UPDATE" align="center" width={100}
                            render={(value:any,record:any)=>{
                                return <>
                                    {
                                        record?.updatedAt ?
                                            <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                        :
                                            <TextField value={'-'}  />
                                    }
                                </>
                            }}
                            
                        /> */}
                    
                        <Table.Column<any>
                            width={50}
                            title={t("content-post-announcement.col-action")} 
                            align    = "center"
                            dataIndex= "actions"
                            fixed="right"
                            render={(_, record) => (
                                <Space>

                                    <ShowButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    ></ShowButton>

                                    {/* <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />

                                    <DeleteButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    /> */}
                                    {
                                        record.deleteStatus === "N" ? 
                                            <>
                                                <EditButton
                                                    hideText
                                                    size="small"
                                                    recordItemId={record.id}
                                                    disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                                />
                                                <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id, record?.localizations[0].id) }} danger></Button>
                                            </>
                                        :
                                            <>
                                                <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id, record?.localizations[0].id) }} ></Button>
                                            </>
                                    }
                                </Space>
                            )}
                        />
                    </Table>
                </Spin>
            </List>
        </>
}