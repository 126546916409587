import { Card, Col, Edit, Divider, Form, Input, Row, useForm, List, Breadcrumb, Radio, Upload, UploadFile, Button, RcFile, message } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICompany, IDepartment, IEventlog } from "interfaces";
// import Moment from 'moment';
import { PlusOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import type { RadioChangeEvent } from 'antd';
import { useEffect, useState } from "react";

import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
// import { object } from "prop-types";

export const CompanyEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { TextArea } = Input;

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form,formProps, saveButtonProps }    = useForm<ICompany>({redirect:"list"});
    const { queryResult } = useShow<ICompany>({metaData: {
        populate: '*'
        }, });
    const { data } = queryResult;
    const record = data?.data;

    let recordData  :   any;
    recordData = queryResult?.data ? queryResult?.data : [];
    recordData = data?.data? data?.data : [];

    // console.log('recordData',recordData)

    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const { mutate: CreateDepartment} = useCreate<IDepartment>();
    const log_endpoint             = "even-logs";

    const [vsetTel, setTel] = useState<CountryPhoneInputValue>();

    const [count, setCount] = useState(0)
    // const [Status, setStatus] = useState<any>();
    const [ vDepartment, setDepartment] = useState<any>([]);
    // const [ vDep, setDep] = useState<any>([]);
    const [ vDepName, setDepName ] = useState<any>("");
    const [disabled, setDisabled] = useState(false);
    const [vChkAddClick, setChkAddClick] = useState(false);
    const [vImgLogo, setImgLogo] = useState<any>();
    const { Dragger } = Upload;

    const { mutate: updateDeptK} = useUpdate<ICompany>();

    const onChange = (e: RadioChangeEvent) => {
    //     setStatus(e.target.value);
    };
    
    const [ vDepNameArr, setDepNameArr ] = useState<any>([]);
    // const [ vDepNameArr2, setDepNameArr2 ] = useState<any>([]);

    const [count3, setCount3] = useState(4);

    useEffect(() => {
        if (count < 2) {
            const interval = setInterval(() => {
                setCount(prev => prev + 1);

                setTel({phone: recordData? recordData.tel : null ,short: recordData? recordData.short_tel: null})

                setDepartment(recordData ? recordData.departments : '' );
                // setDep(recordData ? recordData.departments[0].filter((item:any)=>item.dept_name) : '' )
                //setDep(vDepNameArr)
                
                const fileList: UploadFile[] = [
                    {
                        uid: recordData.logo.id,
                        name: recordData.logo.name,
                        status: "done",
                        url:
                            recordData.logo.url,
                        thumbUrl:
                            recordData.logo.url
                    }
                    ];
    
                    setImgLogo({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList],
                })
    
    
                form.resetFields(["logo"]);

            }, 1000)
            return () => clearInterval(interval)
        }
    })

    useEffect(() => {
        if (count3 < 3) {
            const interval = setInterval(() => {
                setCount3(prev => prev + 1);
                // form.setFieldsValue({
                //     property:
                //         recordData?.property ?
                //             recordData.property.property_name
                //         : null,   
                //     facility_type:
                //         recordData?.facility_type ?
                //             recordData.facility_type.name
                //         : null,
                // });
                console.log('runnnnn')
            }, 1000)
            return () => clearInterval(interval)
        }
    })

   
    let vv = Object.values(vDepartment)
    // console.log('vDepartment', vv)
    // console.log('vDepartment length',vDepartment.length)
    let ArrLoad = vv.map((item:any)=>item.dept_name)
    // console.log("HH",ArrLoad)

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'companies',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    } 

    function fSetDepName(depName:any, i:any){
        console.log('dep name', depName.target.value);
        setDepName(depName.target.value);
    }

    function addDepNameToArr(){
        // console.log('call addDepNameToArr');
        if(vDepName.length > 0){
            let comp = vDepNameArr.some((r:any) => vDepName.includes(r))
            if(comp == false){
                vDepNameArr.push(vDepName);
                setDepNameArr(vDepNameArr);
            }
        }

        if(vChkAddClick === false){
            console.log('here bro')
            setChkAddClick(true)
        }
        console.log('vDepNameArr', vDepNameArr);
    }
    // console.log('vChkAddClick',vChkAddClick);
    // console.log('vDepNameArr on load', vDepNameArr);

    function delarr(v:any){
        console.log("LLLLLLLL",v)
        //let comp = vDepNameArr.some((r:any) => v.includes(r))
        // if(comp == true){
            // vDepNameArr.splice(v,1);
            // ArrLoad.splice(v,1);
            // console.log(ArrLoad)
        // }

        let index = vDepartment.findIndex((i: { id: any; }) => i.id === v.id);
        vDepartment.splice(index, 1)

        console.log('vDepartment',vDepartment)
        // vDepartment.push(
        //     {
        //         id: kid, // ID ของ record ใน privilege ut log
        //         type_name: type_name, // ID ของ unit_type ใน privilege ut log
        //         amount: amount, // จำนวนสิทธิ
        //         priv_id: recordData.id // ID ของเอกสารหน้านี้
        //     }
        // )
        updateDept(vDepartment);
    }

    function updateDept(v:any){
        console.log('inside updateDept',v);


        updateDeptK({
            resource    : 'companies',
            id          : recordData.id + "",
            values      : {
                departments  : v,
                // facility_time_slots  : vFacTimeSlot
            },
            successNotification : false,
        })

        // for(let i = 0; i<= v.length + 1; i++){
        //     updateDeptK({
        //         resource    : 'companies',
        //         id          : recordData.id + "",
        //         values      : {
        //             departments  : v,
        //             // facility_time_slots  : vFacTimeSlot
        //         },
        //         successNotification : false,
        //     })
        // }


        // run useEffect
        // setCount3(0);
    }
    

    function delNewArr(v:any){
        // console.log("delNewArr",v)
        // let comp = vDepNameArr.some((r:any) => v.includes(r))
        // if(comp == true){

            vDepNameArr.splice(v, 1);

            // vDepNameArr2.push(v);
            // setDepNameArr2(vDepNameArr2);

            // ArrLoad.splice(v,1);
            // console.log(ArrLoad)
        // }
        // let index = vDepartment.findIndex((i: { id: any; }) => i.id === v.id);
        // vDepartment.splice(index, 1)
        console.log('delNewArr',vDepNameArr)
        // console.log(ArrLoad)
    }

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
        }
    }

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };
    
    function createNewDept(){
        addDepNameToArr(); // add department ตัวสุดท้ายเข้า arr
        for(let i=0 ; i <= vDepNameArr.length -1; i++){
            CreateDepartment({
                resource    : 'departments',
                values      : {
                    dept_code: i + "",
                    dept_name : vDepNameArr[i],
                    company : recordData.id + "",
                },
                successNotification : false,
            });
        }
    }

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title=""
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("company.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("company.edit")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
          onFinish={(values:any) => {
            updateUserUpdatedBy();
            //values.photo_list   = vphoto_list;
            values.updateBy = user_data.user_profile.id

            values.short_tel = vsetTel?.short
            values.tel = vsetTel?.phone

            createNewDept();
            return (
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
               
            );
        }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("company.com_code")} name="company_code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter company code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("company.com_name")} name="company_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter company name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("company.com_address")} name="address"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter company address.'
                                        },
                                    ]}>
                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Row gutter={[16, 24]}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("company.com_tel")}>
                                            <ConfigProvider locale={en}>
                                                <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{height: "32px",borderRadius: "5px",padding: "0"}} value={vsetTel}
                                                    onChange={(v) => {
                                                        setTel(v);
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                        }
                                                    }}
                                                    minLength={9}
                                                    maxLength={12}
                                                />
                                            </ConfigProvider>
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("company.status")} name="status">
                                            <Radio.Group onChange={onChange} value={recordData.status}>
                                                    <Radio value={true}>Active</Radio>
                                                    <Radio value={false}>In Active</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Divider />

                        <Row gutter={[16, 24]}>

                        </Row>

                        <Col span={24}>
                                <div style={{marginBottom: "1vh"}}>Department</div>
                                    <Form.List name="fields">
                                        {(fields, { add, remove }) => {
                                            return (
                                            <div>
                                                
                                            {
                                                vDepartment !== null && vDepartment !== undefined ?
                                                    vDepartment.length > 0 ?
                                                        vDepartment.map((el:any, index:any) =>{
                                                            // console.log('el', el)
                                                            return <>
                                                            <div key={el.id}>
                                                                <Row>
                                                                    <Col span={1}> <div style={{float:'right'}}></div> {index+1} </Col>
                                                                    <Col span={14}>
                                                                        {/* <Input showCount maxLength={60}
                                                                            //value={el.dept_name}
                                                                            defaultValue={el}
                                                                            onChange={(e) => fSetDepName(e, index+1)}
                                                                        /> */}
                                                                        {
                                                                            vDepartment.length == index +1 ?
                                                                                vChkAddClick ?
                                                                                    <Input showCount maxLength={60}
                                                                                        defaultValue={el.dept_name}
                                                                                        // onChange={(e) => setDepName(e.target.value)}
                                                                                        onChange={(e) => fSetDepName(e, index+1)}
                                                                                        disabled
                                                                                    />
                                                                                :
                                                                                    <Input showCount maxLength={60}
                                                                                        defaultValue={el.dept_name}
                                                                                        // onChange={(e) => setDepName(e.target.value)}
                                                                                        onChange={(e) => fSetDepName(e, index+1)}
                                                                                    />
                                                                            :
                                                                                <Input showCount maxLength={60}
                                                                                    defaultValue={el.dept_name}
                                                                                    // onChange={(e) => setDepName(e.target.value)}
                                                                                    onChange={(e) => fSetDepName(e, index+1)}
                                                                                    disabled
                                                                                />
                                                                        }
                                                                    </Col>
                                                                    <Col span={1}></Col>
                                                                    {/* <Col span={3}>
                                                                    {
                                                                        index == vDepartment.length - 1 ?
                                                                            <Button
                                                                                size="small"
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => { addDepNameToArr(); toggleDisabled();}}
                                                                                style={{marginRight: "3%", color: "green"}}
                                                                            >
                                                                                <CheckOutlined/>
                                                                            </Button>:null
                                                                    }
                                                                    </Col> */}

                                                                    <Col span={3}>
                                                                        {/* {
                                                                        index == vDepartment.length - 1 ? (
                                                                            <Button
                                                                                size="small"
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => {delarr(el); remove(el);}}
                                                                                danger
                                                                            >
                                                                                <CloseOutlined />
                                                                            </Button>
                                                                        ) : null
                                                                        } */}
                                                                        <Button
                                                                            size="small"
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => {delarr(el); remove(el);}}
                                                                            danger
                                                                        >
                                                                            <CloseOutlined />
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                            </>
                                                        })
                                                        
                                                    :
                                                    <></>
                                                
                                                :
                                                    <></>
                                            }
                                             
                                                {/* <div style={{marginBottom: "1vh"}}></div> */}
                                                {fields.map((field, index) => (
                                                    <div key={field.key}>
                                                        <Row>
                                                            {/* <Col span={3}> รอบที่ {index + 1} </Col> */}
                                                            <Col span={1}> <div style={{float:'right'}}></div> {vDepartment.length + index+1} </Col>
                                                            {/* <Col span={16}>
                                                                <TimePicker.RangePicker 
                                                                    style={{width:'95%'}}
                                                                    onChange={(date, dateString) => selectTime(date, dateString, index+1)}
                                                                    format={format} 
                                                                />
                                                            </Col> */}
                                                            <Col span={14}>
                                                                {/* <Input showCount maxLength={60}
                                                                    // onChange={(e) => setDepName(e.target.value)}
                                                                    onChange={(e) => fSetDepName(e, index+1)}
                                                                /> */}
                                                                {
                                                                    fields.length == index+1 ?
                                                                        <Input showCount maxLength={60}
                                                                            // onChange={(e) => setDepName(e.target.value)}
                                                                            onChange={(e) => fSetDepName(e, index+1)}
                                                                            
                                                                        />
                                                                    :
                                                                        <Input showCount maxLength={60}
                                                                            // onChange={(e) => setDepName(e.target.value)}
                                                                            onChange={(e) => fSetDepName(e, index+1)}
                                                                            disabled
                                                                        />

                                                                }
                                                            </Col>

                                                            <Col span={1}></Col>

                                                            {/* <Col span={3}>
                                                            {
                                                                fields.length ==  index + 1 ?
                                                                    <Button
                                                                        size="small"
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => { addDepNameToArr(); toggleDisabled();}}
                                                                        style={{marginRight: "3%", color: "green"}}
                                                                    >
                                                                        <CheckOutlined/>
                                                                    </Button>:null
                                                            }
                                                            </Col> */}

                                                            <Col span={3}>
                                                            {
                                                                fields.length > 1 ? (
                                                                    <Button
                                                                        size="small"
                                                                        className="dynamic-delete-button"
                                                                        // onClick={() => { delarr(field.name); remove(field.name)}}
                                                                        onClick={() => { delNewArr(field.name); remove(field.name); }}
                                                                        danger
                                                                    >
                                                                        <CloseOutlined />
                                                                    </Button>
                                                                ) : null
                                                            }
                                                            </Col>
                                                        </Row>
                                                        {/* <div style={{marginBottom: "1vh"}}></div> */}
                                                    </div>
                                                ))}

                                                {/* <Divider /> */}
                                                <div style={{marginBottom: "1vh"}}></div>
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        // onClick={() => add()}
                                                        onClick={() => { add(); addDepNameToArr();}}
                                                        // onClick={() => { add(); toggleDisabled();}}
                                                        style={{ width: "20%",backgroundColor: "#d6c6a0",borderRadius: "10px"}}
                                                        // disabled={disabled}
                                                    >
                                                        <PlusOutlined style={{color: "#fff"}} /> <span style={{color: "#fff"}}>Add</span>
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                            );
                                        }}
                                    </Form.List>
                            </Col>


                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>

                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "2%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Form.Item
                            name="logo"
                            label={t("company.logo")}
                            rules={[
                                {
                                    
                                    required: recordData.logo ? false : true, message: 'Please upload image.'
                                },
                            ]}
                            >
                            <Dragger style={{borderRadius: "10px"}} 
                                {...vImgLogo}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                maxCount={5}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Dragger>
                            {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                    </Card>

                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={14}>
                                {/* <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div> */}
                                <div style={{marginBottom: "5%"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                {/* <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div> */}
                                <div style={{marginBottom: "5%"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>
    );
};