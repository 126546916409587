import React from 'react';
import CSVReader from 'react-csv-reader';

function CsvImport({ onCsvData }) {
  const handleFileUpload = (data) => {
    // Pass the CSV data to a parent component or process it as needed
    onCsvData(data);
  };

  return (
    <div>
      {/* <h2>CSV File Import</h2> */}
      <CSVReader 
        onFileLoaded={handleFileUpload} 
        // label="Select CSV with secret Death Star statistics"
        // inputStyle={{color: 'red'}}
      />
    </div>
  );
}

export default CsvImport;
