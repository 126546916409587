/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Divider, Switch, Input, Typography, useSelect, InputNumber, Edit, Button, Icons, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL, ROCKET_URL, TOKEN_KEY } from "../../../constants";

import axios from "axios";

export const HcuDaysEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    const API_URL = useApiUrl();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);

    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    const token = localStorage.getItem(TOKEN_KEY);
    const { replace, list} = useNavigation();
    const [res_data, set_res_data] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);
    const [WhatDate, SetWhatDate] = useState<any>(0);
    const [Date, setDate] = useState<any>();

    const [form] = Form.useForm()


    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let property_id = id[0]
    let setting_id = id[1]

    const getDataK = async ()=>{
        // http://192.168.100.129:1337/api/home-check-up-settings/find_setting_by_id?property=2&type=Notification&get_all_setting=false&setting_id=10
        // let res_data = await axios.get(API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=Notification&get_all_setting=false&setting_id=${setting_id}`, { headers: { Authorization: AuthStr } });
        let res_data = await axios.get(
            API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=DateSetup&get_all_setting=false&setting_id=${setting_id}`, 
            { headers: { Authorization: AuthStr } }).then((res) => {
                console.log('res data', res?.data)
                set_res_data(res?.data)
                // SetPropertyName(res?.data.property.property_name)
                SetWhatDate(res?.data.hcu_setting[0].seq)
                SetProperty(res?.data.property.id)
                setDate(res?.data.hcu_setting[0].date)
            });

        form.setFieldsValue({
            first_day: Date ? Date : 0,
            second_day: Date ? Date : 0,
            last_day: Date ? Date : 0,
        })
    }

    // console.log('WhatDate', WhatDate)
    // console.log('Date', Date)

    // useEffect(() => {
    //     if (countInterval < 3) {
    //       const interval = setInterval(() => {
    //         setCountInterval(prev => prev + 1)
    //         getDataK();
    //         console.log('res_data', res_data);
    //         // console.log('property', Property);
    //       }, 1000)
    //       return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);
    

    // const userQuery = useGetIdentity(); // Get User Profile
    // const {data : user_data ,isLoading} = userQuery;
    // const { mutate: CreateLog } = useCreate<IEventlog>();
    // const { mutate: create } = useCreate<any>();
    // const { TextArea } = Input;
    // const { Text } = Typography;
    const t = useTranslate();
    // const { Option } = Select;
    // const { confirm } = Modal;
    // const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    // const log_endpoint = "even-logs";
    const [text, setText] = useState('');
    // const [vFirstDate, setFirstDate] = useState<any>();
    // const [vLastDate, setLastDate] = useState<any>();


    const handleChange = (e:any) => {
        setText(e.target.value);
    };

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    async function createDaysSetup(values:any) {
        // console.log('values',values)
        let data = { 
            "data":{
                "property": Number(Property),
                "hcu_setting": [
                    {
                        "id": Number(setting_id),
                        "seq": WhatDate, // 1 = ครั้งแรกหลังจากวันโอน, 2 = ครั้งสุดท้าย หลังจากวันโอน, 3 =  การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน
                        "date": Number(Date),
                        // "home_check_up_type":{
                        //     "name":"DateSetup"
                        // }
                        "home_check_up_type": 4, // 4 = DateSetup
                        "updateBy" : Identity_data ? Identity_data.user_profile.id : null
                    }
                ]
            }
        }

        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        successNoti('200', 'success');

        replace("/majorcare/setup-hcu")
    }

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully edit days setup',
        });
    };

    const checkResetField = (val:any) => {
        console.log('val', val)
        if(val == ""){
            // console.log('resetfield')
            form?.resetFields(["first_day"]);
            form?.resetFields(["second_day"]);
            form?.resetFields(["last_day"]);
        }else{
            form.setFieldsValue({
                first_day: Date ? Date : 0,
                second_day: Date ? Date : 0,
                last_day: Date ? Date : 0,
            })
        }
    };

    return (
    <Edit 
        // saveButtonProps={saveButtonProps}
        // actionButtons
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-setup-day.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    // values.first_day = parseInt(vFirstDate);
                    // values.last_day = parseInt(vLastDate);
                    // console.log(values)
                    createDaysSetup(values)
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >
                <div className="divider-container">
                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                // name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty}
                                    defaultValue={Property} 
                                    value={Property} 
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={4} className="gutter-row">
                            <div>
                                {
                                    console.log('WhatDate', WhatDate)
                                }
                                {
                                    WhatDate == 1 ?
                                        <>
                                            <span>แสดงปุ่มครั้งแรก หลังจากวันโอน</span>
                                        </>
                                        : WhatDate == 2 ?
                                            <>
                                                <span>แสดงปุ่มครั้งสุดท้าย หลังจากวันโอน</span>
                                            </>
                                        :
                                            <>
                                                <span>การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน</span>
                                            </>
                                }
                            </div>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <div>
                                {
                                    WhatDate == 1 ?
                                    <>
                                        <Form.Item  
                                            label=""
                                            name="first_day"
                                            rules={[
                                                {
                                                    required: true, message: "Please input day"
                                                },
                                            ]}
                                        >
                                            <Input
                                                // name="first_day" 
                                                type={"number"}
                                                style={{width:"40%"}} 
                                                min={0}
                                                placeholder="number"
                                                // onChange={(e:any) => setFirstDate(e.target.value)}
                                                onChange={(e:any) => (setDate(e.target.value), checkResetField(e.target.value))}
                                                value={Date}
                                            ></Input>
                                            <span style={{marginLeft:"20px"}}>วัน</span>
                                        </Form.Item>
                                    </>
                                    : WhatDate == 2 ?
                                    <>
                                        <Form.Item  
                                            label=""
                                            name="second_day"
                                            rules={[
                                                {
                                                    required: true, message: "Please input day"
                                                },
                                            ]}
                                        >
                                            <Input
                                                type={"number"}
                                                style={{width:"40%"}} 
                                                min={0}
                                                placeholder="number"
                                                // onChange={(e:any) => setLastDate(e.target.value)}
                                                // onChange={(e:any) => setDate(e.target.value) }
                                                onChange={(e:any) => (setDate(e.target.value), checkResetField(e.target.value))}

                                                value={Date}
                                            ></Input>
                                            <span style={{marginLeft:"20px"}}>วัน</span>
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item  
                                            label=""
                                            name="last_day"
                                            rules={[
                                                {
                                                    required: true, message: "Please input day"
                                                },
                                            ]}
                                        >
                                            <Input
                                                type={"number"}
                                                style={{width:"40%"}} 
                                                min={0}
                                                placeholder="number"
                                                // onChange={(e:any) => setLastDate(e.target.value)}
                                                // onChange={(e:any) => setDate(e.target.value)}
                                                onChange={(e:any) => (setDate(e.target.value), checkResetField(e.target.value))}

                                                value={Date}
                                            ></Input>
                                            <span style={{marginLeft:"20px"}}>วัน</span>
                                        </Form.Item>
                                    </>
                                }
                                
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                        </Col>

                        <Col span={2}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={4} className="gutter-row">
                            {/* <span>แสดงปุ่มครั้งสุดท้าย หลังจากวันโอน</span> */}
                        </Col>

                        <Col span={8} className="gutter-row">
                            {/* <div>
                                <Form.Item  
                                    label=""
                                    name="last_day"
                                >
                                    <Input
                                        type={"number"}
                                        style={{width:"40%"}} 
                                        min={0}
                                        placeholder="number"
                                        onChange={(e:any) => setLastDate(e.target.value)}
                                    ></Input>
                                    <span style={{marginLeft:"20px"}}>วัน</span>
                                </Form.Item>
                            </div> */}
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6} className="gutter-row"></Col>
                        <Col span={2} className="gutter-row">
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={16}></Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        <Button 
                            type="primary" 
                            onClick={() => form.submit()} 
                            icon={<Icons.SaveOutlined />}
                        >Save</Button>
                    </Row>
                </div>

            </Form>
    </Edit>   
    );
};