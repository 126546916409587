/* eslint-disable */
import { Breadcrumb, Button, Col, Divider, Edit, Form, Icons, Row, TextField, useSelect, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../../constants";

import { notification, Table, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { majorCareUrl } from "../../../constants";

export const RepairSendToCustApprove: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const [RemarkDetail, SetRemarkDetail] = useState<any>(undefined); 
    

    // ******** MAIN STATUS ********
    const option_main_status:any = [
        // {
        //     label: "รอดำเนินการ",
        //     value: "649859ca515b80b78fe13b84",
        // },
        // {
        //     label: "รับเรื่องแจ้งซ่อม",
        //     value: "649859e7515b80b78fe13b86",
        // },
        {
            label: "นัด วัน/เวลา ประเมินหน้างาน",
            value: "649859f8515b80b78fe13b88",
        },
        // {
        //     label: "เข้าประเมิน",
        //     value: "64985a01515b80b78fe13b8a",
        // },
        // {
        //     label: "เข้าประเมิน-เกินกำหนด",
        //     value: "64985a0f515b80b78fe13b8c",
        // },
        // {
        //     label: "ประเมินเสร็จ",
        //     value: "64985a29515b80b78fe13b8e",
        // },
        {
            label: "นัด วัน/เวลา เข้าซ่อม",
            value: "64985a35515b80b78fe13b90",
        },
        // {
        //     label: "เข้าซ่อม",
        //     value: "64985a3c515b80b78fe13b92",
        // },
        // {
        //     label: "เข้าซ่อม-เกินกำหนด",
        //     value: "64985a47515b80b78fe13b94",
        // },
        // {
        //     label: "เลื่อนนัดจากลูกค้า",
        //     value: "64985a67515b80b78fe13b96",
        // },
        // {
        //     label: "เลื่อนนัดจากนิติ",
        //     value: "64985ae5515b80b78fe13ba2",
        // },
        // {
        //     label: "อุปกรณ์อยู่ระหว่างการผลิต",
        //     value: "64985b3b515b80b78fe13bac",
        // },
        // {
        //     label: "รออนุมัติงานซ่อม",
        //     value: "64985bbe515b80b78fe13bb6",
        // },
        // {
        //     label: "อนุมัติงานซ่อม",
        //     value: "64985bc6515b80b78fe13bb8",
        // },
        // {
        //     label: "ปิดงาน",
        //     value: "64985bcf515b80b78fe13bba",
        // },
    ];

    // ******** WORKING SUB STATUS ***********
    const option_cust_postpone:any = [
        {
            label: "ลูกค้าติดธุระส่วนตัว",
            value: "64985a95515b80b78fe13b98",
        },
        {
            label: "ลูกค้าเป็นโควิด/กักตัว",
            value: "64985a9c515b80b78fe13b9a",
        },
        {
            label: "ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน",
            value: "64985aaa515b80b78fe13b9c",
        },
        {
            label: "ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน",
            value: "64985ab9515b80b78fe13b9e",
        },
        {
            label: "ไม่สามารถติดต่อลูกค้าได้",
            value: "64985ac3515b80b78fe13ba0",
        },
    ];

    const option_juristic_postpone:any = [
        {
            label: "ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด",
            value: "64985b04515b80b78fe13ba4",
        },
        {
            label: "ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้",
            value: "64985b0d515b80b78fe13ba6",
        },
        {
            label: "ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง",
            value: "64985b17515b80b78fe13ba8",
        },
        {
            label: "รอ Major อนุมัติ PO/PR",
            value: "64985b21515b80b78fe13baa",
        },
    ];

    const option_matt_in_manufacturing:any = [
        {
            label: "อุปกรณ์ สุขภัณฑ์",
            value: "64985b5e515b80b78fe13bae",
        },
        {
            label: "วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์",
            value: "64985b68515b80b78fe13bb0",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์ที่แจ้ง",
            value: "64985b74515b80b78fe13bb2",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า",
            value: "64985b80515b80b78fe13bb4",
        },
    ];

    const option_close_job:any = [
        {
            label: "ส่งงานเสร็จสมบูรณ์",
            value: "64985be1515b80b78fe13bbc",
        },
        {
            label: "Customer เปิดงานซ้ำ",
            value: "64985bec515b80b78fe13bbe",
        },
        {
            label: "Customer เปิดงานผิด",
            value: "64985bf6515b80b78fe13bc0",
        },
        {
            label: "Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า",
            value: "64985c0c515b80b78fe13bc2",
        },
        {
            label: "ให้คำแนะนำ เพื่อจัดจ้าง",
            value: "64985c15515b80b78fe13bc4",
        },
        {
            label: "งานที่ไม่อยู่ในเงื่อนไขรับประกัน",
            value: "64985c22515b80b78fe13bc6",
        },
        {
            label: "รายละเอียดไม่ตรงกับรายการรับประกัน",
            value: "64985c2d515b80b78fe13bc8",
        },
        {
            label: "ลูกค้าเลื่อนซ่อม เกิน SLA",
            value: "64985c38515b80b78fe13bca",
        },
        {
            label: "รอสั่งของ",
            value: "64985c42515b80b78fe13bcc",
        },
        {
            label: "งานซ่อมที่เกิดจากการใช้งาน",
            value: "64985c4a515b80b78fe13bce",
        },
        {
            label: "งานเคลมประกันภัยอาคาร",
            value: "64985c56515b80b78fe13bd0",
        },
    ];


    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const [countInterval, setCountInterval] = useState(0);
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    const token = localStorage.getItem(TOKEN_KEY);
    const dataBlank:any = []



    let work_id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    // id = arr_id.split("_")
    work_id = arr_id.split("%2C")
    let item_id = work_id[0]
    let job_id = work_id[1]

    const id_to_query = work_id.join(',');

    // Data ส่วนของ รายละเอียดการแจ้ง
    const [Inspector, SetInspector] = useState<any>();

    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    const [InspectorProfile, SetInspectorProfile] = useState<any>();

    const [textcount, settextcount] = useState('');
    const [dataDetailList, setDataDetailList] = useState<any>([]);

    const getDataK = async ()=>{
        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjob?id=648ab8ca326b1b890c5cf5ec
        // GET JOB DETAIL DATA
        let res_detail_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId`, 
            majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId?stat=${id_to_query}&page=1&limit=20`, 

            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad Detail', dataLoad)
                // console.log('prop_id', prop_id)

                SetPropertyIdJob(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.property_id : '-' : '-')

                // if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.property_obj[0]?.property_id == Number(prop_id))
                // }
                // console.log('work_id', work_id)
                const xx = dataLoad.filter((item:any) => work_id.includes(item.job_id));

                // console.log('xx', xx)
                for (let index = 0; index < xx.length; index++) {
                    dataDetailList.push(xx[index])
                }
            });
    }

    console.log('dataDetailList', dataDetailList)

    const handleChange = (e:any) => {
        settextcount(e.target.value);
    };

    const saveData = async ()=>{
        updateRepairJob();
        // replace(`/mjc-inspec-today-job/edit/${job_id}_${JobIDHM}`)
        // replace(`/mjc-inspec-inprogress-job/edit/${job_id}_${JobIDHM}`)
    }

    // console.log('vImgEn', vImgEn)

    const updateRepairJob = async () => {

        // เปลี่ยน stat เป็นส่งให้ลูกค้าอนุมัติ

        {/* 
            MAIN STATUS

            649859ca515b80b78fe13b84 = รอดำเนินการ
            649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
            649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
            64985a01515b80b78fe13b8a = เข้าประเมิน
            64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
            64985a29515b80b78fe13b8e = ประเมินเสร็จ
            64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
            64985a3c515b80b78fe13b92 = เข้าซ่อม
            64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
            64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
            64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
            64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
            64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
            64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
            64985bcf515b80b78fe13bba = ปิดงาน *
        */}

        for (let index = 0; index < work_id.length; index++) {
            // const element = work_id[index];
            
            // ****************** 1. UPDATE JOB with Selected stat ******************
            // **********************************************************************
            let data = {
                // "status_id": Stat.value,
                // "status_name": Stat.label, 
                "status_id": "64985bbe515b80b78fe13bb6",
                "status_name": "รออนุมัติงานซ่อม",  
                // "assign": InspectorProfile ? InspectorProfile[0] : null,
                // "appoint_start": AppointStart ? AppointStart : null,
                // "appoint_end": AppointEnd ? AppointEnd : null ,
                "inspec_to_cust_approve_date": dayjs(),
            };
            let res = await axios.patch(majorCareUrl + "/majorcare/repairJob/updateRepairjob?id=" + work_id[index], data, { headers: { } });


            // ****************** 2. CREATE TRAIL ******************
            // *****************************************************

            let dataTrail = {
                "repair_job": work_id[index],
                // "status": Stat.value,
                // "status_name": Stat.label,
                "status": "64985bbe515b80b78fe13bb6",
                "status_name": "รออนุมัติงานซ่อม",  
                // "sub_status": SubStat !== undefined ? SubStat.value : null,
                // "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                "remark": RemarkDetail !== undefined ? RemarkDetail : "",
                "create_by": Identity_data?.user_profile,
                "inspec_to_cust_approve_date": dayjs(),
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            }

            let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });
        }

        successNoti();
        list("repair-inspec")
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    const columns:any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            // sorter: (a:any, b:any) => a.job_id.localeCompare(b.job_id),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by.attributes !== undefined ? record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YYYY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <div>
                            <Tag className="tag_mjc_stat_wait_inspect">
                                <TextField value={record.job_obj[0].status_name}  />
                            </Tag>
                        </div>
                        {/* <div>
                            {
                                record ? 
                                    record.job_obj[0]?.status_id !== null ?
                                        record.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" ?
                                            <Tag className="tag_mjc_stat_wait_inspect">
                                                <TextField value={'รับเรื่องแจ้งซ่อม'}  />
                                            </Tag>
                                        :
                                            record.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" ?
                                                <Tag className="tag_mjc_stat_inspect">
                                                    <TextField value={'เลื่อนนัดจากลูกค้า'}  />
                                                </Tag> 
                                            :
                                                record.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" ?
                                                    <Tag className="tag_mjc_stat_inspect">
                                                        <TextField value={'เลื่อนนัดจากนิติบุคคล'}  />
                                                    </Tag>
                                                :
                                                    <Tag className="tag_mjc_stat_wait_apprv">
                                                        <TextField value={'error 404'}  />
                                                    </Tag>
                                    : '-'
                                : '-'
                            }
                        </div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    useEffect(() => {
        if (countInterval <= 1) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                    getDataK();

                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        // resource: "home-check-up-settings",
        resource: "repair-settings",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "role_management][id]",
                operator: "eq",
                value: 21 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            {
                field: "property][id]",
                operator: "eq",
                value: PropertyIdJob
            },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleClick = () => {
        setButtonDisabled(true);
        form.submit()
    };

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        title="ยืนยันรายการส่งให้ลูกค้าอนุมัติ"
        // pageHeaderProps={{
        //     extra : <></>
        // }}
        pageHeaderProps={{
            breadcrumb: <Breadcrumb>
                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                <Breadcrumb.Item>Major แจ้งซ่อม (สำหรับช่าง)</Breadcrumb.Item>
                {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><span className="last_breadcrumb">ส่งให้ลูกค้าอนุมัติ</span></Breadcrumb.Item>
            </Breadcrumb>,
            extra : <></>,
            onBack(e?) {
                list("repair-inspec")
            },
        }}
    >
        <Form  
            form = {form} 
            // layout="horizontal"
            layout="vertical"
            onFinish={(values:any) => {
                // createAssignInspec(values);
                // updateRepairJob()
                saveData();
            }}
        >
            <Table  
                dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank}
                columns={columns} 
                // onChange={onChange} 
                // pagination={{ size: "small", position: [ bottom] }} 
                pagination={{ size: "small", position: [ 'bottomRight'] }} 
            />

            <Row>
                <Col span={18}>
                    <Form.Item
                        label="หมายเหตุ" 
                        name="remark"
                    >
                        <TextArea style={{ height: "90px", maxHeight: "80px" }}
                            onChange={(e) => SetRemarkDetail(e.target.value)}
                            // value={RemarkDetail}
                            // disabled
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Divider/>

            <Row style={{float: 'right'}}>
                <Button type="ghost" onClick={() => (list("repair-inspec"))} icon={<Icons.SaveOutlined />} >ยกเลิก</Button>
                {/* <Button type="primary" onClick={() => saveData()} icon={<Icons.SaveOutlined />} style={{marginLeft:'6px'}} >ยืนยัน</Button> */}
                <Button type="primary" onClick={() => handleClick()} icon={<Icons.SaveOutlined />} disabled={buttonDisabled}>ยืนยัน</Button>

            </Row>
        </Form>
    </Edit>   
    );
};