/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, TableProps, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { TextArea } from "react-mde";
import axios from "axios";
import { TOKEN_KEY } from "../../../constants";
import { majorCareUrl } from "../../../constants";
import dayjs from "dayjs";


export const RepairDocumentCustomerList: React.FC<IResourceComponentsProps> = () => {
    const [ Tab, SetTab] = useState<any>();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const tabTermActive = localStorage.getItem("keyTabTermRepair");
    const { mutate } = useUpdate<any>();
    const [form] = Form.useForm();
    
    const API_URL = useApiUrl();
    const { create, edit } = useNavigation();
    const { confirm } = Modal;
    const token = localStorage.getItem(TOKEN_KEY);

    const [dataList, setDataList] = useState<any>([]);
    const [dataBlank, setDataBlank] = useState<any>([]);
    // console.log('currentLocale', currentLocale)
    const [dataSource, SetDataSource] = useState<any>([]);
    const [dataSource2, SetDataSource2] = useState<any>([]);
    const [ count, setCount] = useState(0);

    const prop_id = localStorage.getItem("PROPERTY_ID");

    const getDataK = async ()=>{

        // status mdpc
        // ปิดงาน = 64985bcf515b80b78fe13bba
        // ส่งงานเสร็จสมบูรณ์ = 64985be1515b80b78fe13bbc

        // status majorcare 
        // ปิดงาน = 64985bcf515b80b78fe13bba
        // ส่งงานเสร็จสมบูรณ์ = 64985be1515b80b78fe13bbc

        // MAJORCARE
        let res_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`, 
            majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad majorcare', dataLoad)

                if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0].request_by?.unitDetail?.property_id == Number(prop_id))
                }

                // dataSource.push(dataLoad)
                // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id !== "64b50609f3440e02a3156fb4" &&  )
                // for (let index = 0; index < xx.length; index++) {
                //     dataSource.push(xx[index]);
                // }
                let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_trails_obj[e.job_trails_obj.length - 1].status == "64985bcf515b80b78fe13bba" && e.job_trails_obj[e.job_trails_obj.length - 1].sub_status == "64985be1515b80b78fe13bbc" )
                let newDataList = []
                for (let index = 0; index < xx.length; index++) {
                    //dataSource.push(xx[index]);
                    newDataList.push(xx[index]);
                }
                if (!!newDataList && newDataList !== null && !newDataList) {
                    SetDataSource(newDataList)
                }else{
                    SetDataSource(xx)
                }

            });

        // MDPC
        let res_data_mdpc = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`, 
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad mdpc', dataLoad) 
                // dataSource.push(dataLoad)

                let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_trails_obj[e.job_trails_obj.length - 1].status == "64985bcf515b80b78fe13bba" && e.job_trails_obj[e.job_trails_obj.length - 1].sub_status == "64985be1515b80b78fe13bbc" )
                for (let index = 0; index < xx.length; index++) {
                    dataSource.push(xx[index]);
                }
            });

            // dataSource.flat();
            // dataSource.concat(...dataSource)
            // console.log('dataSource before merge', dataSource)
            // const array_merge = {
            //     data: [...dataSource[0], ...dataSource[1]],
            // };

            // const array_merge = [...dataSource[0], ...dataSource[1]];
            // console.log('array_merge', array_merge)

            // setTimeout(() => {
            // }, 1000);
            // dataSource2.push(array_merge)
            dataSource.reverse()
    }

    // console.log('dataSource', dataSource)
    // console.log('dataSource2', dataSource2)
    // console.log('dataList', dataList)

    useEffect(() => {
        getDataK();
    }, []);

    useEffect(() => {
        if (count < 2) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                // console.log('asd')
                // getPropCreated();
                getDataK();

            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    const clearDataSource = () => {
        SetDataSource([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        // setCountInterval(0)
        setCount(0)
        // refetch();
    };

    const columns:any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'JOB NO.',
            dataIndex: 'job_no',
            key: 'job_no',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.job_obj[0].job_id.localeCompare(b.job_obj[0].job_id),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.job_obj[0].request_by.unitDetail.property_name.localeCompare(b.job_obj[0].request_by.unitDetail.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>

                    </>
                );
            },
        },
        {
            title: 'UNIT',
            dataIndex: 'unit',
            key: 'unit',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.job_obj[0].request_by.unitDetail.unit_name.localeCompare(b.job_obj[0].request_by.unitDetail.unit_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'CUSTOMER NAME',
            dataIndex: 'cust_name',
            key: 'cust_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.job_obj[0].request_by.attributes.first_name.localeCompare(b.job_obj[0].request_by.attributes.first_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by.attributes !== undefined ? record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ไฟล์เอกสาร',
            dataIndex: 'doc_file',
            key: 'doc_file',
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => a.tac_th.localeCompare(b.tac_th),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            record?.job_obj[0] ?
                                record?.job_obj[0]?.report_job ?
                                    record?.job_obj[0]?.report_job?.url ?
                                        <a href={record?.job_obj[0]?.report_job?.url} download={"report_" + record?.job_obj[0]?.report_job?.name} target='_blank'>
                                            <Button style={{marginLeft:'5px', color:'green'}} icon={<Icons.DownloadOutlined />}></Button>
                                        </a>
                                    : <>-</>
                                : <>-</>
                            : <>-</>
                        }
                    </>
                );
            },
        },
        {
            title: 'ใบเสนอราคา',
            dataIndex: 'quotation_file',
            key: 'quotation_file',
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => a.tac_th.localeCompare(b.tac_th),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            record?.job_obj[0] ?
                                record?.job_obj[0]?.quotation?.file?.length > 0 ?
                                    record?.job_obj[0]?.quotation?.file?.map((item: any, key: any) => {
                                        return <>
                                                <a href={item.url} download={"quotation_" + key} target='_blank'>
                                                    <Button style={{marginLeft:'5px', color:'green'}} icon={<Icons.DownloadOutlined />}></Button>
                                                </a>
                                            </>
                                    })
                                :
                                    <>-</>
                            : <>-</>
                        }
                    </>
                );
            },
        },
        {
            title: 'CREATED BY',
            dataIndex: 'create_by',
            key: 'create_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_by?.first_name < b?.job_obj[0]?.create_by?.first_name) {return -1}
                if (a?.job_obj[0]?.create_by?.first_name > b?.job_obj[0]?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.create_by ? record?.job_obj[0]?.create_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.create_by?.last_name ? record?.job_obj[0]?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) {return -1}
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'UPDATE BY',
            dataIndex: 'update_by',
            key: 'update_by',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.update_by?.first_name < b?.job_obj[0]?.update_by?.first_name) {return -1}
                if (a?.job_obj[0]?.update_by?.first_name > b?.job_obj[0]?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (updateBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.update_by ? record?.job_obj[0]?.update_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.update_by?.last_name ? record?.job_obj[0]?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'Last Update',
            dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.update_date < b?.job_obj[0]?.update_date) {return -1}
                if (a?.job_obj[0]?.update_date > b?.job_obj[0]?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].update_date !== null ? dayjs(record.job_obj[0].update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const { selectProps: selProperty } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // filters: [
        //     {
        //         field: "deleteStatus",
        //         operator: "eq",
        //         value : "N" 
        //     },
        //     {
        //         field : "id]",
        //         // operator : data_property !== null ? "eq" : "ne",
        //         operator : data_property !== null ? "eq" : "ne",
        //         value : data_property !== null ? data_property : null
        //     },
        // ],
    });

    const [selUnit, setUnit] = useState<any>(undefined);
    const [selProp, setselProp] = useState<any>(undefined);
    const [nameSearch, setnameSearch] = useState<any>("");
    const [jobNoSearch, setJobNoSearch] = useState<any>("");

    const { selectProps: selUniT } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                // operator    : selProp !== undefined ? "eq":"nin",
                // value       : selProp !== undefined ? selProp.id : "xxxxxxxx"
                operator    : "eq",
                value       : !!selProp ? selProp : null
            },
        ]
    });

    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];
        if(mode == "S"){

            newData = selProp !== undefined ? dataSource?.filter((e:any)=> {return e?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id}) : newData;
            newData = selUnit !== undefined ? dataSource?.filter((e:any)=> {return e?.job_obj[0].request_by?.unitDetail?.unit_id == selUnit?.id}) : newData;
            newData = jobNoSearch !== "" ? dataSource?.filter((e:any)=> e?.job_obj[0].job_id?.toLowerCase().includes(String(jobNoSearch)?.toLowerCase())) : newData;
            newData = nameSearch !== "" ? dataSource?.filter((e:any)=> e?.job_obj[0].request_by?.first_name?.toLowerCase().includes(String(nameSearch)?.toLowerCase())) : newData;

            SetDataSource(newData);
        }else if(mode == "R"){
            form?.resetFields();
            setselProp(undefined);
            setUnit(undefined);
            setnameSearch("");
            setJobNoSearch("");

            refreshDataSource();

        }
    }
    
    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
    
    return <>
        <List 
            // title={t("mjctermandcon.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjctermandcon.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={24}>
                    <Form layout="vertical" 
                        // {...formProps} 
                        onFinish={(values:any) => {}}
                        form = {form} 
                    >
                        <Row gutter={24} style={{width: "100%"}}>
                            <Col span={3} className="gutter-row">
                                <Form.Item name="property" label="Property">

                                    <Select {...selProperty} 
                                        placeholder="Select property" 
                                        size="middle" 
                                        allowClear
                                        // onClear={()=> setselProp(undefined)}
                                        // onChange={(value: any, label: any) => {
                                        //     if(value!== undefined){
                                        //         setselProp({id:value, label: label?.children})
                                        //     }else{
                                        //         setselProp(undefined)
                                        //     }
                                        // }} 
                                        onChange={(e) => setselProp(e)}
                                    />

                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="unit" label="Unit">
                                    <Select {...selUniT} 
                                        placeholder="Select unit" 
                                        size="middle" 
                                        allowClear
                                        onClear={()=> setUnit(undefined)}
                                        // onChange={(e) => setUnit(e)}
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setUnit({id:value, label: label?.children})
                                            }else{
                                                setUnit(undefined)
                                            }
                                        }} 
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="job_no" label="Job No." >
                                    <Input 
                                        placeholder="Job number" 
                                        onChange={(e) => { if(e.target.value.length > 0){ setJobNoSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="cust_name" label="Customer Name" >
                                    <Input 
                                        placeholder="Job number" 
                                        onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={4} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S", "xxxxx")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R", "xxxx")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            <div>
                <Table 
                    // dataSource={filteredDataList.length > 0 ? filteredDataList : DataInfor} 
                    dataSource={dataSource?.length > 0 ? dataSource : dataBlank} 
                    // dataSource={dataSource2?.length > 0 ? dataSource2[0] : dataBlank} 
                    columns={columns} 
                    onChange={onChange} 
                    // pagination={{ size: "small", position: [ bottom] }} 
                    // pagination={{ size: "small", position: [ "bottomRight"] }} 
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        // showTotal: showTotal, 
                        pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                        showSizeChanger: true, // Optional - Show page size changer dropdown
                        // showQuickJumper: true, // Optional - Show quick jumper input
                        // pageSize: 10, // Optional - Default page size
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 1500 }}
                />
            </div>
        </List>
    </>
}