/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useSimpleList, useForm } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import type { ColumnsType } from 'antd/es/table';
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
import MDEditor from '@uiw/react-md-editor';


let swLocal : boolean = true;
let dataonFilter: any = [];

export const HcuDaysList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { TextArea } = Input;
    const { Option } = Select;
    const API_URL = useApiUrl();
    const { Text } = Typography;

    const { formProps } = useForm<any,HttpError,{}>()

    const { create, edit } = useNavigation();
    const { TabPane } = Tabs;

    const [DataCus, setDataCus] = useState<any>();
    const [vTacType, setTacType] = useState<any>();
    const [vSms, setSms] = useState<any>();
    const [vEmail, setEmail] = useState<any>();
    const [vInApp, setInApp] = useState<any>();
    const { confirm } = Modal;
    const { mutate } = useUpdate<ICoupon>();
    const ed_point = "home-check-up-settings";
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    const [dataList, setDataList] = useState<any>([]);

    const [nameSearch, setnameSearch] = useState<any>("");
    const [selProp, setselProp] = useState<any>();

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "home-check-up-settings",
        metaData: {
            populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
        },
        permanentSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        permanentFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        pagination:{
            pageSize: 100,
        },
        queryOptions: {
          onSuccess: (data) => {
            // console.log('data xxxx : ', data.data); 
            let dataLoad: any = data?.data;
            // console.log('prop_id', prop_id)

            // filter เอาแต่ data ของ property นั้น ๆ
            // if(prop_id !== null){
            //     dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            // }

            if(prop_list !== undefined){
                console.log('case 1')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property?.id));
            }else if(prop_id !== null){
                console.log('case 2')
                dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            }
            
            for (let index = 0; index < dataLoad?.length; index++) {
                // console.log('dataload loop', dataLoad[index])
                dataonFilter = dataLoad[index]?.hcu_setting?.filter((e:any)=> e.home_check_up_type?.name == "DateSetup")
                dataonFilter.forEach((obj:any) => {
                    obj.property_name = dataLoad[index].property.property_name;
                    obj.property_id = dataLoad[index].property.id;
                    obj.record_id = dataLoad[index].id;
                });
                dataList?.push(dataonFilter)
            }

            // sorting task LSC-696
            // const statusOrder = ["แสดงปุ่มครั้งแรก หลังจากวันโอน", "การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน", "แสดงปุ่มครั้งสุดท้าย หลังจากวันโอน"];
            // const statusOrder = [1, 3, 2];
            // dataonFilter.sort((d1:any, d2:any) => statusOrder.indexOf(d1.seq) - statusOrder.indexOf(d2.seq));


            // let kvar = [
            //     {
            //       "id": 1,
            //       "seq": 1,
            //     },
            //     {
            //       "id": 2,
            //       "seq": 2,
            //     },
            //     {
            //       "id": 3,
            //       "seq": 3,
            //     },
            //   ];
              
                // const customOrder = [1, 3, 2];
                // dataonFilter.sort((a:any, b:any) => customOrder.indexOf(a.seq) - customOrder.indexOf(b.seq));
                // console.log('dataonFilter', dataonFilter)
          },
        },
    });

    if(dataList?.length > 0){
        // const flattenedArray = dataList[0].concat(dataList[1]);
        const flattenedArray = dataList.reduce((accumulator:any, currentArray:any) => {
            return accumulator.concat(currentArray);
        }, []);

        dataonFilter = flattenedArray
        // console.log('flattenedArray', flattenedArray)

        // dataonFilter.sort((a: { id: number; }, b: { id: number; }) => a.id - b.id);
        const customOrderSeq = [1, 3, 2];
        // dataonFilter.sort((a:any, b:any) => customOrder.indexOf(a.seq) - customOrder.indexOf(b.seq));

        dataonFilter.sort((a:any, b:any) => {
            if (a.property_name !== b.property_name) {
              return a.property_name.localeCompare(b.property_name);
            }
          
            // If property_name is the same, sort by seq in the custom order
            const seqA = customOrderSeq.indexOf(a.seq);
            const seqB = customOrderSeq.indexOf(b.seq);
          
            return seqA - seqB;
          });
        // console.log('dataonFilter', dataonFilter)

        // dataonFilter.reverse();

    }

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                deleteStatus    : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'home-check-up-settings',
                SysPkID         : id+""
            },
            errorNotification:false,
            successNotification:false
        });
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
      
    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    const columns: ColumnsType<any> = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.home-checkup-day.property")}</span>,
            dataIndex: 'property',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            sorter: (a:any, b:any) => a?.property_name?.toString().localeCompare(b.property_name.toString()),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.property_name !== null ? record.property_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.home-checkup-day.type")}</span>,
            dataIndex: 'seq',
            key: 'seq',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.seq?.toString().localeCompare(b.seq.toString()),
            render: (value:any) => {
                // console.log('value', value)
                return (
                    <>
                        <div>
                            {
                                value !== null ? 
                                    value == 1 ? 
                                        <>แสดงปุ่มครั้งแรก หลังจากวันโอน</>
                                    : value == 2 ? 
                                        <>แสดงปุ่มครั้งสุดท้าย หลังจากวันโอน</>
                                    : <>การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน</>
                                : '-'
                            }
                        </div>
                    </>
                );
            },
        },
        // {
        //     title: "xxxx",
        //     dataIndex: 'seq',
        //     key: 'seq',
        //     sorter: (a:any, b:any) => a.date.toString().localeCompare(b.date.toString()),
        //     render: (value:any,record:any) => {
        //         // console.log('record', record)
        //         return (
        //             <>
        //                 <div>
        //                     {value}
        //                 </div>
        //             </>
        //         );
        //     },
        // },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.home-checkup-day.days")}</span>,
            // dataIndex: 'date',
            key: 'date',
            sorter: (a:any, b:any) => a.date.toString().localeCompare(b.date.toString()),
            render: (value:any,record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>
                            {/* {value ? value !== null ? value.toString() : '-': '-'} */}
                            {record ? record.date !== null ? record.date.toString() : '-': '-'}
                        </div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.home-checkup-day.create-by")}</span>,
            // dataIndex: 'createBy',
            key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.createBy?.first_name.toString().localeCompare(b.createBy?.first_name.toString()),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                    {/* <div >{record.createBy !== null ? 'xxxx' : "-"} {record.createBy !== null ? 'yyy' : ""}</div> */}
                </>
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.home-checkup-day.created-date")}</span>,
            dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.home-checkup-day.update-by")}</span>,
            // dataIndex: 'updateBy',
            key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.updateBy?.first_name.toString().localeCompare(b.updateBy?.first_name.toString()),
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.home-checkup-day.last-update")}</span>,
            dataIndex: 'update_date',
            key: 'update_date',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            render: (value:any, record:any) => {
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            onClick={() => edit("hcusetupdays", record.property_id + "_" + record.id )}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false } 
                        />

                    </>
                );
            },
        },
    ];

    function onSearchtable(data: any, mode:any){
        let newData:any = dataonFilter;
        if(mode == "S"){
            const textToFilter = nameSearch ? String(nameSearch).toLowerCase() : ''
            const seqToFilter = 'แสดงปุ่มครั้งแรก หลังจากวันโอน'.includes(textToFilter) ? 1 :
            'แสดงปุ่มครั้งสุดท้าย หลังจากวันโอน'.includes(textToFilter) ? 2 :
            'การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน'.includes(textToFilter) ? 3 : undefined
            newData = textToFilter ? newData?.filter((e:any)=> {
                return e?.property_name?.toLowerCase().includes(textToFilter) || 
                (seqToFilter && e?.seq ==seqToFilter ) ||
                e?.date?.toString().includes(textToFilter) ||
                e?.createBy?.first_name?.includes(textToFilter) ||
                Moment(e?.create_date).format('D/MM/yyyy HH:mm').includes(textToFilter) ||
                e?.updateBy?.first_name?.includes(textToFilter) ||
                Moment(e?.update_date).format('D/MM/yyyy HH:mm').includes(textToFilter)
            }):newData;
            // newData = nameSearch !== "" ? dataonFilter?.filter((e:any)=> e?.email?.includes(String(nameSearch))):newData;
            // newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property_id.includes(String(selProp?.id))}):newData;
            newData = selProp !== undefined ? newData?.filter((e:any)=> {return e?.property_id == selProp?.id}) : newData;
            dataonFilter = newData

            setDataList((pre: any) => newData);
        }else if(mode == "R"){
            // setDataList((pre: any) => newData);

            formProps?.form?.resetFields();
            setDataList([])
            refetch();
            setselProp(undefined);
            // setselUnitType(undefined);
            setnameSearch("");
        }
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    return <>
        <List 
            // title={t("mjcnotimsg.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjcnotimsg.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={22}>
                    <Form
                        // {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%"}}
                    >

                        {/* <Form.Item name="property">
                            <Select placeholder="Property" {...selProperty} allowClear style={{width: "220px"}}/>
                        </Form.Item>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button 
                                onClick={() => {
                                    // searchFormProps.form?.submit()
                                    // searchFormProps.form?.resetFields()
                                    // searchFormProps.form?.submit()
                                }}
                                htmlType="submit" 
                                style={{borderRadius: "5px"}}
                                icon={<Icons.UndoOutlined />}
                            >
                                Reset
                            </Button>
                        </Form.Item> */}
                    </Form>
                </Col>
                <Col span={2}>
                    <Form
                        // {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                            {/* <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true } /> */}
                            {/* <Button onClick={()=> create("notification")} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }>Create</Button> */}
                            {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} /> */}
                            {/* <CreateButton id="create_btn" onClick={()=>create("hcusetupdays")} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }></CreateButton> */}

                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" style={{marginBottom:"1%"}} {...formProps} onFinish={(values:any) => {}}>
                        <Row gutter={20} style={{width: "100%"}}>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label="Search">
                                    <Input placeholder="Search" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} suffix={<Icons.SearchOutlined/>} allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select placeholder={t("select.sel")} 
                                        {...selProperty} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                        {/* {
                                            dataProperty?.map((item:any,key:any) => {
                                                return <Option value={item?.id} key={item.id}>{item?.property_name}</Option>      
                                            })
                                        } */}
                                    </Select>

                                    {/* <Form.Item name="property">
                            <Select placeholder="Property" {...selProperty} allowClear style={{width: "220px"}}/>
                        </Form.Item> */}
                                </Form.Item>
                            </Col>
                            {/* <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label="Search">
                                    <Input placeholder="Search" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} suffix={<Icons.SearchOutlined/>} allowClear/>
                                </Form.Item>
                            </Col> */}
                            {/* <Col span={4} className="gutter-row">
                                <Form.Item name="unit_type" label="Unit Type">
                                    <Select placeholder={t("select.sel")} onChange={(value: any, label: any) => {if(value!== undefined){setselUnitType({id:value, label: label?.children})}else{setselUnitType(undefined)}}} allowClear>
                                        {
                                            dataUnitType?.map((item:any,key:any) => {
                                                return <Option value={item?.id} key={item.id}>{item?.unit_type_name_en}</Option>      
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form layout="vertical" style={{marginBottom:"1%",float: "right"}} {...formProps} onFinish={(values:any) => {}}>
                        <Form.Item style={{marginRight: "0",}} label=" ">
                            {/* <CreateButton icon={<Icons.PlusCircleOutlined/>} style={{backgroundColor: "#1D304C", color: "#fff", borderRadius: "5px"}} onClick={()=>create("template")}/> */}
                            <CreateButton id="create_btn" onClick={()=>create("hcusetupdays")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Table 
                dataSource={dataonFilter} 
                columns={columns} 
                // columns={columns.map(column => {
                //     if (column.dataIndex === 'seq') {
                //       return {
                //         ...column,
                //         sortOrder: 'descend',
                //       };
                //     }
                //     return column;
                //   })}
                onChange={onChange} 
                // pagination={{ size: "small", position: [ bottom] }} 
                // pagination={{ size: "small", position: [ "bottomRight"] }} 
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    // showTotal: showTotal, 
                    pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                    showSizeChanger: true, // Optional - Show page size changer dropdown
                    // showQuickJumper: true, // Optional - Show quick jumper input
                    // pageSize: 10, // Optional - Default page size
                    pageSize: pageSize,
                    onChange: handlePageSizeChange,
                }}

            />

        </List>

    </>
}