/* eslint-disable */
import { Breadcrumb, Button, Col, Form, Icons, List, Modal,  Row, ShowButton, Space, Table, TextField, useTable, Tag, useModalForm, Empty, Input } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useTranslate } from "@pankod/refine-core";
import { ICoupon, IFacilityCheckinQR } from "interfaces";
import { QRCodeCanvas } from "qrcode.react";
import React from "react";
import { useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Moment from 'moment';

import "./css/style.css";

export const FaciiltyGenCheckInList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const userQuery = useGetIdentity();

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [DataCus, setDataCus] = useState<any>();
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    
    const { tableProps,searchFormProps,tableQueryResult } = useTable<IFacilityCheckinQR,HttpError, IFacilityCheckinQR>({
        metaData: {
            populate: '*'
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { title } = params;

                filters.push(
                    {
                        field       : "title",
                        operator    : "contains",
                        value       : title
                    },

                );

            return filters;
        },  
        permanentSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        initialFilter: [
            {
                field : "facility][property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ]
    });

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    const {
        modalProps: showModalProps,
        formProps: showProps,
        formLoading : showformLoading ,
        show: showModalShow,
        close : showClose
    } = useModalForm<any>({
        resource: "facility-gen-checkins",
        metaData: {
            populate: ["facility","facility.property"],
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;
            setDataCus(dataLoad);
        }},
    });

    class ComponentToPrint extends React.Component {
        render() {
          return (
            
            <div style={{marginTop: "1rem"}}>
              <Row gutter={8}>
                <Col className="gutter-row" span={24}>
                    <div style={{textAlign: "center"}}>
                        <div style={{fontSize: "18px"}}>{DataCus ? "( " + "โครงการ " +DataCus?.facility?.property?.property_name + " )" : "ไม่มีเนื้อหา"}</div>
                        <div style={{fontSize: "15px"}}>{DataCus ? "Facility  : " + DataCus?.facility?.name : "ไม่มีเนื้อหา"} </div>
                    </div>
                </Col>
                <Col className="gutter-row" span={24} style={{paddingTop: "1rem"}}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {DataCus?.code ? 
                            <QRCodeCanvas 
                                value={DataCus?.code !== null ? DataCus?.code : '-'} 
                                style={{width: "250px",height: "250px",padding: "20px",border: "2px solid #dedede",borderRadius: "10px"}} 
                            />
                        :
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        }
                    </div>
                </Col>
                <Col className="gutter-row" span={24} style={{paddingTop: "1rem"}}>
                    <div style={{textAlign: "center"}}>
                        <div style={{fontSize: "15px"}}>{DataCus ? DataCus?.title : "ไม่มีเนื้อหา"} </div>
                    </div>
                </Col>
              </Row>
            </div>
          );
        }
      }

    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    }

    //{console.log(DataCus)}

    return <>
            <List 
                title={t("fclt-gen-qr.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-gen-qr.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                    // extra: <ImportButton {...importProps} />,
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="title">
                                <Input style={{borderRadius: 4,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search Title (code)" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        
                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            {/* <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                                 <ImportButton {...importProps} />
                            </Form.Item> */}

                            {/* <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" />
                            </Form.Item> */}
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableQueryResult?.data?.total, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                >
                    {/* <Table.Column width={30} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    <Table.Column dataIndex="title" title="TITLE (CODE)" 
                        sorter={(a:any, b:any) => {
                            if (a?.title < b?.title) {return -1}
                            if (a?.title > b?.title) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    <Table.Column title="FACILITY" 
                        sorter={(a:any, b:any) => {
                            if (a?.facility?.name < b?.facility?.name) {return -1}
                            if (a?.facility?.name > b?.facility?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.facility ? record?.facility?.name : 'ไม่ระบุ'}  /> 
                            </>
                        }}
                    />

                    <Table.Column dataIndex="deleteStatus" title="STATUS"  align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.deleteStatus < b?.deleteStatus) {return -1}
                            if (a?.deleteStatus > b?.deleteStatus) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={value}  /> */}
                                <TextField 
                                    style={{fontWeight: 600}} 
                                    value={
                                        value === "Y" ? 
                                            <Tag style={{borderRadius: "30px",color: "#AC1616",padding: "1% 7% 1%"}} color={"#FDD4D4"}>Inactive</Tag>
                                        :
                                            <><Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#00BB66"}>Active</Tag> </>
                                    }
                                />
                            </>
                        }}
                    />  

                    <Table.Column title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.createdAt < b.createdAt) {return -1}
                        if (a.createdAt > b.createdAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.updatedAt < b.updatedAt) {return -1}
                        if (a.updatedAt > b.updatedAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />
                  
                    <Table.Column<ICoupon>
                        width={100}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    onClick={() => showModalShow(record.id)}
                                >
                                    รายละเอียด
                                </ShowButton>
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...showModalProps}
                title={<>
                    <span>QR CHECK-IN : {DataCus?.title}</span>
                </>}
                footer={
                    <Button type="primary" onClick={()=> showClose()}>Close</Button>
                }
                maskClosable={false}
                width={450}
                afterClose={()=> {
                    showProps?.form?.resetFields();
                }} 
            >
                <Form 
                    {...showProps} 
                    layout="vertical"
                >
                    <Row gutter={8} style={{marginBottom: "1rem"}}>
                        <Col className="gutter-row" span={24}>
                            <ComponentToPrint ref={componentRef} />
                        </Col>

                        <Col className="gutter-row" span={24} style={{paddingTop: "1rem"}}>
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                {/* <div className="btn_custom_i_print" onClick={handlePrint} style={{paddingTop: "0.8rem"}}>
                                    <Icons.PrinterOutlined /><span>Export</span>
                                </div> */}

                                <Button 
                                    icon={<Icons.PrinterOutlined />} 
                                    onClick={()=> handlePrint()}
                                    style={{borderRadius : 5, width: 220}}
                                >
                                    Export
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}