/* eslint-disable */
import { Breadcrumb, Button, Col, Form, Icons, ImportButton, Input, List, Modal, Row, Table, TextField, notification, useImport, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IPhoneBooks, IProperties } from "interfaces";
// import Moment from 'moment';
import { DownloadOutlined, WarningOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useState } from "react";

export const ReceiptCancelList: React.FC<IResourceComponentsProps> = () => {

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()
    const [searchCustName, setSearchCustName] = useState<any>(undefined);
    const [searchUnitName, setSearchUnitName] = useState<any>(undefined);

    const t = useTranslate();
    const { tableProps, searchFormProps } = useTable<any,HttpError, any>({
        metaData: {
            populate: '*'
        },  
        
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, property } = params;
            filters.push(
                // {
                //     field: "q",
                //     operator: "eq",
                //     value: q,
                // },
                // {
                //     field       : "property][property_name]",
                //     operator    : "contains",
                //     value       : property
                // },
                {
                    field: "customer_name",
                    operator: "contains",
                    value : searchCustName
                },
                {
                    field: "unit_no",
                    operator: "contains",
                    value : searchUnitName
                },
            );
            return filters;
        },
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
    });

    const onReset = () => {
        setSearchUnitName(undefined);
        setSearchCustName(undefined);
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    const successNotiImport = (stat:any, msg:any) => {
        notification.success({
            message: 'Success',
            description: msg,
            duration: 2,
        });
    };

    const categoryQueryResult = useOne<any>({
        metaData: {
            populate: '*'
        },
        resource: "receipt-cancels",
        id: ''
    });

    const { mutate: createReceiptCancel } = useCreate<any>();

    const importProps = useImport<any>({
        batchSize: 1000,
        resourceName: '',
        mapData: async (item) => {
            console.log('item', item)

            if(item.receipt_no === ""){
                console.log('receipt_no is empty')
            }else if(item.unit_no === ""){
                console.log('unit_no is empty')
            }else{
                let dateStr = item.receipt_date;
                let dateObj = new Date(dateStr.replace(/(\d{2})\/(\d{1})\/(\d{4})/, "$3-$2-$1"));
                let isoDateStr = dateObj.toISOString();

                createReceiptCancel({
                    resource : 'like-systems/createReceiptCancel',
                    values : {
                        receipt_date    : isoDateStr,
                        receipt_no      : item.receipt_no,
                        unit_no         : item.unit_no,
                        unit_address    : item.unit_address,
                        customer_name   : item.customer_name,
                        inv_ref_no      : item.inv_ref_no,
                        expense_type_code   : item.expense_type_code,
                        description     : item.description,
                        // amount          : item.amount,
                        amount          : item.price,
                        payment_type    : item.payment_type,
                        remark          : item.remark,
                        // create_date     : item.create_date,
                        create_by       : item.create_by,
                    },successNotification:false,errorNotification:false,
                },{
                    onSuccess: async(data: any, link: any) =>{
                        console.log('data onSuccess', data)
                        // console.log('parcel_in id >>> ', data.data.data.id) // id ของ parcel in
                    },
                    onError(error, variables, context) {
                        console.log('error', error)
                        // setDupStat(true)
                        
                    },
                }
                
                )
            }
            
        },
        onProgress(onProgressParams) {
            // console.log('onProgressParams', onProgressParams)
            // setTotal(onProgressParams.totalAmount);
        },
        async onFinish(results) {

            successNotiImport(200, "Data Imported!")
            await sleep(2000);
            window.location.reload()
            
            // console.log('results', results)
            // results.succeeded.forEach((item :any) => {
            //     // console.log(item.inv_ref_no);
            //     if(item?.unit_no !== "" || item?.unit_address !== "" || item?.receipt_no !== ""){
            //         console.log('success', item);
            //     } 
            // });

            // // failed requests response
            // results.errored.forEach((item) => {
            //     console.log('errored', item);
            // });
        },
        
    });

    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];

    const { confirm } = Modal;
    const { mutate } = useUpdate<any>();
    const ed_point = "receipt-cancels";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IPhoneBooks>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,
                };
            }
        },
    });

    let initF : any;
    initF = [{
            field       : 'deleteStatus',
            operator    : 'contains',
            value       :  'N',
        },
    ];
    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "property_name",
        // optionLabel: "projectNameTH",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            }
        ],
    });
    
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : ed_point,
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // function showUnlockConfirm(id:any) {
    //     confirm({
    //         title   : 'Activate Confirm ?',
    //         icon    : <QuestionCircleOutlined />,
    //         content : 'Click Confirm to activate this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             vCurDelStatus.deleteStatus = 'N'
    //             updateStatus(id, "Active");
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }



    return <>
            <List 
                title={t("receipt-cancel-list.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("billing.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("receipt-cancel-list.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="cust_name" >
                                <Input placeholder="customer's name" 
                                    // value={vInputTitle} 
                                    onChange={e => {
                                        if(e.target.value.length > 0){
                                            setSearchCustName(e.target.value)
                                        }else{
                                            setSearchCustName(undefined)
                                        }
                                    }}
                                    allowClear
                                    style={{borderRadius: 5}}
                                />
                            </Form.Item>

                            <Form.Item name="unit_name" >
                                <Input placeholder="unit no." 
                                    // value={vInputTitle} 
                                    onChange={e => {
                                        if(e.target.value.length > 0){
                                            setSearchUnitName(e.target.value)
                                        }else{
                                            setSearchUnitName(undefined)
                                        }
                                    }}
                                    allowClear
                                    style={{borderRadius: 5}}
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            {/* <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" />
                            </Form.Item> */}

                            <Form.Item>
                                <Button style={{borderRadius:5}} 
                                    icon={<DownloadOutlined />}
                                >
                                    <a style={{color:'#595959'}} href="https://lscmain.s3.ap-southeast-1.amazonaws.com/receipt_cancel_template_4aaa65a52d.xlsx?updated_at=2023-02-23T10:04:50.620Z"> Download Template</a>
                                </Button> 
                            </Form.Item>

                            <Form.Item>
                                <ImportButton {...importProps} />
                                {/* <Button {...importProps} style={{borderRadius:5}} icon={<ImportOutlined />}><a> Import</a></Button> */}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps}
                    bordered
                    size="middle"
                    rowKey = "id"
                    pagination={{
                        defaultPageSize:10,
                        showSizeChanger: true,
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState,
                        onShowSizeChange,
                        total:paginationLength.length,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column dataIndex="property" title="PROPERTY" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.property? record?.property.property_name :"-"}  />
                            </>
                        }}
                    /> */}

                    <Table.Column dataIndex="receipt_date" title="RECEIPT DATE" 
                        sorter={(a:any, b:any) => {
                            if (a?.receipt_date < b?.receipt_date) {return -1}
                            if (a?.receipt_date > b?.receipt_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                {/* <TextField value={value? value :"-"}  /> */}
                                <TextField value={value? dayjs(value).format("YYYY-MM-DD HH:mm") :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="receipt_no" title="RECEIPT NO" 
                        sorter={(a:any, b:any) => {
                            if (a?.receipt_no < b?.receipt_no) {return -1}
                            if (a?.receipt_no > b?.receipt_no) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />

                            </>
                        }}
                    />

                    <Table.Column dataIndex="unit_no" title="UNIT NO" 
                        sorter={(a:any, b:any) => {
                            if (a?.unit_no < b?.unit_no) {return -1}
                            if (a?.unit_no > b?.unit_no) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="unit_address" title="UNIT ADDRESS" 
                        sorter={(a:any, b:any) => {
                            if (a?.unit_address < b?.unit_address) {return -1}
                            if (a?.unit_address > b?.unit_address) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="customer_name" title="CUSTOMER NAME" 
                        sorter={(a:any, b:any) => {
                            if (a?.customer_name < b?.customer_name) {return -1}
                            if (a?.customer_name > b?.customer_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="inv_ref_no" title="INVOICE NO" 
                        sorter={(a:any, b:any) => {
                            if (a?.inv_ref_no < b?.inv_ref_no) {return -1}
                            if (a?.inv_ref_no > b?.inv_ref_no) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="expense_type_code" title="EXPENSE TYPE" 
                        sorter={(a:any, b:any) => {
                            if (a?.expense_type_code < b?.expense_type_code) {return -1}
                            if (a?.expense_type_code > b?.expense_type_code) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="description" title="DESCRIPTION" 
                        sorter={(a:any, b:any) => {
                            if (a?.description < b?.description) {return -1}
                            if (a?.description > b?.description) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="remark" title="REMARK" 
                        sorter={(a:any, b:any) => {
                            if (a?.remark < b?.remark) {return -1}
                            if (a?.remark > b?.remark) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record ? record?.remark : "-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="createBy" title="CREATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="createdAt" title="CREATE DATE" align="center" width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? dayjs(value).format("YYYY-MM-DD HH:mm") :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="updateBy" title="UPDATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="updatedAt" title="LAST UPDATE" align="center" width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? dayjs(value).format("YYYY-MM-DD HH:mm") :"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="name" title="NAME" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />
                    <Table.Column dataIndex="phone" title="PHONE" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="phone_book_type" title="PHONE BOOK TYPE" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.phone_book_type? record?.phone_book_type.name :"-"}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="updatedBy" title="UPDATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="updateAt" title="LAST UPDATE" align="center" width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    /> */}

                    {/* <Table.Column<any>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        render={(_, record) => (
                            <Space>
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                ></ShowButton>
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger></Button>
                                        </>
                                    :
                                        <>
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    /> */}
                </Table>
            </List>
        </>
}