/* eslint-disable */
import { Badge, Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, Select, Space, Table, Tabs, TextField, useModalForm, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { checkedListCommand } from "@uiw/react-md-editor";
import axios from "axios";
import e from "express";
import { IEventlog , Ivehicle, IvehicleBrand, IvehicleModel, IvehicleType } from "interfaces";
import Moment from 'moment';
import { useState } from "react";

let dataVHC: any = [];

export const VehicleMList: React.FC<IResourceComponentsProps> = () => {

    const tabVehiModel = localStorage.getItem("keyTabStatusVehiModel");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Vehicle Models");
    const { TabPane } = Tabs;
    const [selvhcType, setvhcType] = useState<any>();
    const [scvhcType, setscvhcType] = useState<any>();
    const [selvhcBrand, setvhcBrand] = useState<any>();
    const [scvhcBrand, setscvhcBrand] = useState<any>();
    const [inputvm, setinputvm] = useState<any>();
    const [scinputvm, setscinputvm] = useState<any>();

    const [seltype, setseltype] = useState<any>();

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<Ivehicle>();
    const ed_point                          = "vehicle-models";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const [keepDataStatus, setKeepDataStatus] = useState<any>()

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IvehicleModel,HttpError, IvehicleModel>({
        metaData: {
            populate: '*'
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { deleteStatus } = params;
            filters.push(
                {
                    field       : "vehicle_type][id]",
                    operator    : "eq",
                    value       : selvhcType !== undefined ? selvhcType: null
                },
                {
                    field       : "vehicle_brand][id]",
                    operator    : "eq",
                    value       : selvhcBrand !== undefined ? selvhcBrand: null
                },
                {
                    field       : "model_nameEN",
                    operator    : "contains",
                    value       : inputvm !== undefined ? inputvm : null
                },
                {
                    field       : "deleteStatus",
                    operator    : "eq",
                    value       : !!keepDataStatus ? keepDataStatus : tabVehiModel
                },
            );
            return filters;
        },
        initialFilter: [
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : !!keepDataStatus ? keepDataStatus : tabVehiModel
            },
        ],
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
    });


    const datavhc = useList<Ivehicle>({ 
        resource: "vehicle-models" ,
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        queryOptions:{onSuccess(data) {
            dataVHC = data?.data;
        },}
    });

    const { selectProps: selvehicleType } = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            }
        ],
        fetchSize: 1000
    });

    const { selectProps: selvehicleBrand } = useSelect<IvehicleBrand>({
        resource: "vehicle-brands",
        optionLabel: "brand_nameEN",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : 'vehicle_type][id]',
                operator    : selvhcType !== undefined ? "eq":"nin",
                value       : selvhcType !== undefined ? selvhcType:null,
            }
        ],
        fetchSize: 1000
    });

    const { selectProps: selvhicBrand } = useSelect<IvehicleBrand>({
        resource: "vehicle-brands",
        optionLabel: "brand_nameEN",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : 'vehicle_type][id]',
                operator    : 'eq',
                value       : seltype,
            },
        ],
        fetchSize: 1000
    });

    const { triggerExport, isLoading: exportLoading } = useExport<Ivehicle>({
        mapData: (item) => {
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,
                };
            }
        },
    });

    const onSearchClick = () => {
        if(selvhcType !== undefined){
            setscvhcType(selvhcType)
        }else if(selvhcType == undefined && selvhcType == null){
            setscvhcType(undefined)
        }

        if(selvhcBrand !== undefined){
            setscvhcBrand(selvhcBrand)
        }else if(selvhcBrand == undefined && selvhcBrand == null){
            setscvhcBrand(undefined)
        }

        if(inputvm !== undefined){
            setscinputvm(inputvm)
        }else if(inputvm == undefined){
            setscinputvm(undefined)
        }
    };

    const onReset = () => {
        setvhcType(undefined);
        setscvhcType(undefined);
        setvhcBrand(undefined);
        setscvhcBrand(undefined);

        setinputvm(undefined);
        setscinputvm(undefined);

        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()

    };

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'vehicle-models',
                SysPkID         : id+""
            },
            successNotification: false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        setKeepDataStatus(v)
        localStorage.setItem("keyTabStatusVehiModel",v)
        searchFormProps.form?.submit();
        // localStorage.setItem("keyU",v)
        // localStorage.setItem("keyTabUserPortal", v)
        
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose
    } = useModalForm<any>({
        resource: "vehicle-models",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "vehicle-models",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            
            setseltype(dataLoad?.vehicle_type ? dataLoad?.vehicle_type.id : undefined)
            console.log(dataLoad)
        },}
    });

    return <>
            <List 
                title={t("vehicle.bc_title_m")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title_m")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                           <Form.Item name="vehicle_type" >
                                <Select {...selvehicleType} 
                                    placeholder="Select vehicle type" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                    allowClear
                                    onClear={()=> {
                                        setvhcType(undefined);
                                        searchFormProps.form?.resetFields(["vehicle_brand"])
                                    }}
                                    onChange={(e) => {
                                        setvhcType(e);
                                        searchFormProps.form?.resetFields(["vehicle_brand"])
                                    }}
                                />
                            </Form.Item>

                           <Form.Item name="vehicle_brand" >
                                <Select {...selvehicleBrand} 
                                    placeholder="Select vehicle brand" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                    allowClear
                                    onClear={()=> setvhcBrand(undefined)}
                                    onChange={(e) => setvhcBrand(e)}
                                />
                            </Form.Item>

                            <Form.Item name="inputmn">
                                <Input 
                                    placeholder="input vehicle models" 
                                    value={inputvm}
                                    allowClear
                                    onChange={e => {
                                        if(e.target.value.length > 0){
                                            setinputvm(e.target.value)
                                        }else{
                                            setinputvm(undefined)
                                        }
                                    }}
                                    style={{borderRadius: 5}}
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}} onClick={onSearchClick}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>

                        </Form>
                    </Col>
                        
                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                {/* <CreateButton id="create_btn"  onClick={() => createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Form {...searchFormProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            //defaultActiveKey={"N"}
                            defaultActiveKey={!tabVehiModel ? keepDataStatus : `${tabVehiModel}`}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={onStatusChange} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={dataVHC?.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={dataVHC?.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                        
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableQueryResult.data?.total,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    <Table.Column title={t("vehicle.Ltype")} align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.vehicle_type?.type_nameEN < b?.vehicle_type?.type_nameEN) {return -1}
                            if (a?.vehicle_type?.type_nameEN > b?.vehicle_type?.type_nameEN) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.vehicle_type? record?.vehicle_type.type_nameEN :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={t("vehicle.Lbrand")} align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.vehicle_brand?.brand_nameEN < b?.vehicle_brand?.brand_nameEN) {return -1}
                            if (a?.vehicle_brand?.brand_nameEN > b?.vehicle_brand?.brand_nameEN) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.vehicle_brand? record?.vehicle_brand.brand_nameEN :"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="medel_code" title={t("vehicle.Lmodel-code")} width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title={t("vehicle.Lmodel-name_TH")} align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.vehicle_brand?.brand_nameTH < b?.vehicle_brand?.brand_nameTH) {return -1}
                            if (a?.vehicle_brand?.brand_nameTH > b?.vehicle_brand?.brand_nameTH) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{

                            return <>
                                <TextField value={record?.vehicle_brand? record?.vehicle_brand.brand_nameTH :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={t("vehicle.Lmodel-name_EN")} align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.model_nameEN < b?.model_nameEN) {return -1}
                            if (a?.model_nameEN > b?.model_nameEN) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //console.log("TEST",record)
                            return <>
                                <TextField value={record? record.model_nameEN:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record && !!record.createdAt? Moment(record.createdAt).format('D/MM/yyyy HH:mm') : "-"}  />
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record && !!record.updatedAt? Moment(record.updatedAt).format('D/MM/yyyy HH:mm') : "-"}  />
                            </>
                        }}
                        
                    />
                  
                    <Table.Column<Ivehicle>
                        width={100}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                // onClick={() => editModalShow(record.id)}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                    <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...createModalProps}
                title={"Vehicle Model Create"}
                okText = {"Create"}
                maskClosable={false}
                width={300}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                    setseltype(undefined);
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.medel_code = values.medel_code;
                        values.model_nameTH = values.model_nameTH;
                        values.model_nameEN = values.model_nameEN;
                        values.vehicle_brand = values.vehicle_brand.id;
                        values.vehicle_type = values.vehicle_type.id;
                        
                        values.deleteStatus = "N";
                        values.createBy = user_data.user_profile.id;
                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type")} name="vehicle_type"
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")}
                                        {...selvehicleType}
                                        onChange={(e) => {
                                            setseltype(e);
                                            createProps?.form?.resetFields(["vehicle_brand"]);
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand")} name="vehicle_brand"
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")}
                                    {...selvhicBrand}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.model-code")} name="medel_code"
                                rules={[
                                    {
                                        required: true, message: 'Please enter model code.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.model-name_en")} name="model_nameEN"
                                rules={[
                                    {
                                        required: true, message: 'Please enter model name(en).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.model-name_th")} name="model_nameTH"
                                rules={[
                                    {
                                        required: true, message: 'Please enter model name(th).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Vehicle Model Edit"}
                okText = {"Save"}
                maskClosable={false}
                width={300}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                    setseltype(undefined);
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.medel_code = values.medel_code;
                        values.model_nameTH = values.model_nameTH;
                        values.model_nameEN = values.model_nameEN;
                        values.vehicle_brand = values.vehicle_brand.id;
                        values.vehicle_type = values.vehicle_type.id;
                        
                        values.deleteStatus = "N";
                        values.updateBy = user_data.user_profile.id;
                        return (
                            editProps.onFinish &&
                            editProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type")} name={["vehicle_type", "id"]}
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")}
                                        {...selvehicleType}
                                        onChange={(e) => {
                                            setseltype(e);
                                            editProps?.form?.resetFields(["vehicle_brand"]);
                                        }}
                                    />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand")} name={["vehicle_brand", "id"]}
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")}
                                    {...selvhicBrand}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.model-code")} name="medel_code"
                                rules={[
                                    {
                                        required: true, message: 'Please enter model code.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.model-name_en")} name="model_nameEN"
                                rules={[
                                    {
                                        required: true, message: 'Please enter model name(en).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.model-name_th")} name="model_nameTH"
                                rules={[
                                    {
                                        required: true, message: 'Please enter model name(th).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}