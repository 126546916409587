/* eslint-disable */
import { Card, Col, Divider, Form, Input, Row, useForm, List, Breadcrumb, Edit, Select, useSelect, TextField } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IPhoneBooks, IPhoneBookType, IProperties } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";

import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

export const PhoneBooksEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form,formProps, saveButtonProps } = useForm<IPhoneBooks>({redirect:"list"});
    const { queryResult } = useShow<IPhoneBooks>({
        metaData: {
            populate: '*'
        }, 
    });
    const { data } = queryResult;
    const record = data?.data;
    const { mutate: CreateLog }      = useCreate<IEventlog>();
    const log_endpoint               = "even-logs";
    const { selectProps: SelPropert} = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
    });

    let recordData:any = []
    recordData = data?.data? data?.data : [];

    // console.log('recordData', recordData)

    const [count, setCount] = useState(0)
    const [vPropert, setpropert] = useState(recordData.property ? recordData.property.id : '-');
    const [vPhonebook, setphonebook] = useState(recordData.phone_book_type ? recordData.phone_book_type.id : '-');

    const [vsetTel, setTel] = useState<CountryPhoneInputValue>();

    useEffect(() => {
        if (count < 3) {
          const interval = setInterval(() => {
            setCount(prev => prev + 1)

            setpropert(recordData.property ? recordData.property.id : null);
            setphonebook(recordData.phone_book_type ? recordData.phone_book_type.id : null);

            setTel({phone: recordData? recordData.phone : null ,short: recordData? recordData.country_code: null})

            form.setFieldsValue({
                property: 
                    recordData.property ? 
                        recordData.property.property_name
                    : null,
                phone_book_type:
                    recordData.phone_book_type ? 
                        recordData.phone_book_type.name
                    : null,
                phone:
                    recordData.phone ? 
                        recordData.phone
                    : null,
            })
   
          }, 1000)
          return () => clearInterval(interval)
        }
    }, [count])

    console.log('vsetTel', vsetTel)

    const { selectProps: SelPhoneBType } = useSelect<IPhoneBookType>({
        resource: "phone-book-types",
        optionLabel: "name",
        optionValue: "id",
    });

    // console.log(recordData)

    function updateFunction(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'phonebook',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
        // replace("");
    }

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("phonebook.edit")}

            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("phonebook.edit")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
          onFinish={(values:any) => {
            updateFunction();
            console.log('values',values)
            // values.name = values.name
            // values.name_en = values.name_en

            values.updateBy = user_data.user_profile.id
            values.updatedBy = user_data.user_profile.id
            values.property = vPropert
            values.phone_book_type = vPhonebook
            
            values.country_code = vsetTel?.short
            values.phone = vsetTel?.phone
            return (
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
               
            );
        }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>


                            <Col className="gutter-row" span={24}>
                                <Input.Group compact>
                                    <Form.Item  label={t("name")} name="name" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter name.'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item  label="Name eng" name="name_en" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter name eng.'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item  
                                        label={t("phonebook.tel")} 
                                        name="phone" 
                                        style={{width: "40%"}}
                                    >
                                        <ConfigProvider locale={en} >
                                            <CountryPhoneInput 
                                                // defaultValue={{ short: 'TH', phone: vsetTel?.phone }}
                                                style={{height: "32px",borderRadius: "5px", padding: "0"}}
                                                value={{ short: vsetTel?.short, phone: vsetTel?.phone }}
                                                onChange={(v) => {
                                                    setTel(v);
                                                }}
                                            />
                                        </ConfigProvider>
                                    </Form.Item>
                                </Input.Group>
                            </Col>

                            {/* <Col className="gutter-row" span={12}>
                                <Input.Group compact>
                                    <Form.Item  label={t("lat")} name="lat" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter latitude.'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item  label={t("lng")} name="lng" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter longtitude.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Input.Group>
                            </Col> */}
                        </Row>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                                <Input.Group compact>
                                    <Form.Item  label={t("lat")} name="lat" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter latitude.'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item  label={t("lng")} name="lng" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter longitude.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Input.Group>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "5%"}}>
                        {/* <div style={{ marginBottom: '5%'}}><span className="title_panel">{t("relation.txt")}</span></div> */}

                        <Form.Item  label={t("property.title")} name="property">
                            <Select placeholder={t("select.sel")} {...SelPropert}
                                onChange={value => {
                                    setpropert(value);
                                }}
                            />
                        </Form.Item>
                        
                        <Form.Item  label={t("phonebook-type.title")} name="phone_book_type">
                            <Select placeholder={t("select.sel")} {...SelPhoneBType}
                                onChange={value => {
                                    setphonebook(value);
                                }}
                            />
                        </Form.Item>
                    </Card>

                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: '5%' }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={14}>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createdBy? record?.createdBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.updatedBy? record?.updatedBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>
    );
};