import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { useState } from "react";
import { petURL } from "../../../constants";
import { Breadcrumb, Button, Card, Col, Divider, Edit, Form, Icons, Input, InputNumber, List, Modal, Row, Select, Spin, notification } from "@pankod/refine-antd";
import axios from "axios";

let optionpettype: any = undefined;
let optionpetsize: any = undefined;
export const PetSizeEdit: React.FC<IResourceComponentsProps> = () => {
    
    const t = useTranslate();
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();

    const [keepMin, setKeepMin] = useState<any>()
    const [keepMax, setKeepMax] = useState<any>()

    const [formCustom] = Form.useForm();
    const [formModal] = Form.useForm();
    const [formModal_EDIT] = Form.useForm();
    const [formModal_EDIT_OLD] = Form.useForm();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [valuepetType, setpetType] = useState<any>();
    const [valuepetSize, setpetSize] = useState<any>();


    const [sizeGroup, setsizeGroup] = useState<any>([]);
    const [sizeGroup_OLD, setsizeGroup_OLD] = useState<any>([]);

    const [ID, setID] = useState<any>();
    const [keyEDIT, setkeyEDIT] = useState<any>();
    const [keyEDIT_OLD, setkeyEDIT_OLD] = useState<any>();
    const [mdopen, setmdopen] = useState<boolean>(false);
    const [mdopen_EDIT, setmdopen_EDIT] = useState<boolean>(false);
    const [mdopen_EDIT_OLD, setmdopen_EDIT_OLD] = useState<boolean>(false);
    const [tickerFlag, settickerFlag] = useState<boolean>(false);

    let getID = window?.location?.href.split("/");
    let pageID: any = getID[getID?.length -1];

    const { data: dataLoad } = useCustom<any>({
        // url: `${petURL}/api/lscpet/findOnePetSpecies`,
        url: `${petURL}/api/lscpet/findOnePetSize`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                setpetType(dataLoad?.pet_type)

                formCustom?.setFieldsValue({
                    name_th: dataLoad?.pet_size_name,
                    name_en: dataLoad?.pet_size_name_en,
                    // pet_type: dataLoad?.pet_type
                })

                setLoading(false)
            }
        }
    })

 

    async function submitDT(values:any){
        let body: any = {
            "pet_size_name": values?.name_th,
            "pet_size_name_en": values?.name_en,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }
        
        let submit = await axios.put(petURL + "/api/lscpet/updatePetSize?id=" + pageID, body, { headers: { } })
        .then(async response => {
        
            successNoti('Successfully edit pet species');
            replace("/pets/pet-masters")
        })
        .catch(error => {
            console.error('error ',error);
            faildNoti('Edit is Faild');
        });
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    function onsetvalEdit(values: any, key: any){
        formModal_EDIT?.setFieldsValue({
            size_th: values?.size_name,
            size_en: values?.size_name_en,
            pet_size: values?.pet_size,
            size_from: values?.size_from,
            size_to: values?.size_to,
        });
        
        setkeyEDIT(key);
        setmdopen_EDIT(true);
    }

    function onremovesize(key: any) {
        let newData: any = [];

        for (let i = 0; i < sizeGroup?.length; i++) {
            if(i !== key){ newData?.push(sizeGroup[i]) }
        }

        setsizeGroup(newData);
        settickerFlag((pre)=> !pre);
    }

    function onfinishsize(values: any) {
        let newData: any = [{
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
        }]

        setsizeGroup((pre: any) => [...pre, ...newData]);
        settickerFlag((pre)=> !pre);
        setmdopen(false);
    }

    function onfinishsizeEDIT(values: any) {
        let dataLoad: any = sizeGroup;
        let newData: any = {
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
        }

        dataLoad[keyEDIT] = newData;

        setsizeGroup(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT(false);
    }

    ////////////////////////////////////////////////////////////////////// OLD

    function onsetvalEdit_OLD(values: any, key: any){
        formModal_EDIT_OLD?.setFieldsValue({
            size_th: values?.size_name,
            size_en: values?.size_name_en,
            pet_size: values?.pet_size,
            size_from: values?.size_from,
            size_to: values?.size_to,
        });
        //console.log("vaaa",values)
        setKeepMax(values?.size_from)
        setKeepMin(values?.size_to)
        setID(values?.id);
        setkeyEDIT_OLD(key);
        setmdopen_EDIT_OLD(true);
    }

    function onremovesize_OLD(key: any) {
        let newData: any = sizeGroup_OLD;
        newData[key].status = "removed"

        setsizeGroup_OLD(newData);
        settickerFlag((pre)=> !pre);
    }

    function onfinishsizeEDIT_OLD(values: any) {
        let dataLoad: any = sizeGroup_OLD;
        let newData: any = {
            "id": ID,
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
            "status": "active"
        }

        dataLoad[keyEDIT_OLD] = newData;

        setsizeGroup_OLD(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT_OLD(false);
    }
      
    const findSizeLabel = (value:any) => {
        const size = optionpetsize?.find((s:any) => s.value === value);
        return size ? size.label : 'Size Not Found'; // Default message if size is not found
    };

    return (
        <Spin size="large" spinning={isLoading}>
            <Edit pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/pet-masters")}}} saveButtonProps={{hidden: true}}>
                <List 
                    title=""
                    pageHeaderProps={{
                        breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-size.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-size.title-edit")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                        extra : <></>
                    }}
                />

                <div style={{width: "25%"}}>
                    <Form 
                        form={formCustom}
                        layout="vertical"
                        onFinish={(values:any) => {
                            submitDT(values);
                        }}
                    >

                        <Form.Item 
                            label={t("pet-size.name-th")}
                            name="name_th"
                            rules={[
                                {
                                    required: true, message: 'please input name th.'
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item 
                            label={t("pet-size.name-en")}
                            name="name_en"
                            rules={[
                                {
                                    required: true, message: 'please input name en.'
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                    </Form>
                </div>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button type="primary" onClick={() => formCustom?.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
                </Row>
            </Edit>
        </Spin>
    )
}