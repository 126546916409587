/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Divider, Switch, Input, Typography, useSelect, InputNumber, notification, Button, Icons } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate, useShow, useList } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking, IUserProfile, IUserTypeMap} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL, ROCKET_URL, TOKEN_KEY } from "../../../constants";

import axios from "axios";

export const HcuDaysCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null
    
    // console.log('data_property',data_property)
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_name:any = localStorage.getItem("PROPERTY_NAME");

    // console.log('Identity_data', Identity_data)
    const token = localStorage.getItem(TOKEN_KEY);
    const { replace, list} = useNavigation();

    // console.log("Identity_data",Identity_data)

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    // const { mutate: create } = useCreate<any>();
    const { TextArea } = Input;
    const { Text } = Typography;
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { confirm } = Modal;
    // const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    const log_endpoint = "even-logs";
    const [text, setText] = useState('');
    
    const [onSave, setonSave] = useState<boolean>(false)

    const [vFirstDate, setFirstDate] = useState<any>();
    const [vLastDate, setLastDate] = useState<any>();
    const [SecondNotiAfterTransfer, Set2ndNotiAfterTransfer] = useState<any>();
    const [zeep, setZeep] = useState<any>();

    // const checkProperty = async () => {
        
    //     let keep = []

    //     if (!!Identity_data) {
    //         let resAllProperty: any = await axios.request({
    //             method: 'get',
    //             maxBodyLength: Infinity,
    //             url: `https://lsc-service.techscape.app/api/user-type-mappings?populate=*&filters[user_profile][id][$eq]=${Identity_data.user_profile.id}`,
    //             headers: { 
    //               'Authorization': 'Bearer 25a0f718093ae4d9dc3ecf59f173a54571898253193de8373ae37acadb4e19aa56aaee7b61dbff3b249215f3798402052c1c5949ee1d0a91dea5979d12a3b90471639a97c59c3a29fd774e8c573d2e22f89cd77bade6f4403560165790a09f5d83781f0f19d38474f4ba76a073ece38f414d1012522379d14c0d8d9d223c03a0'
    //             }
    //           });

    //         //console.log("Identity_data",Identity_data.user_profile.id)
    //         console.log("resAllProperty",resAllProperty.data)

    //         // for (let index = 0; index < resAllProperty.data.data.length; index++) {
    //         //     keep.push(resAllProperty.data.data[index].attributes.property.data.attributes.property_name)
    //         //   }
    //     }

    // }
    // useEffect(() => {
    //     checkProperty();
    //   }, [checkProperty()]);

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                        method: "post",
                        body: body
                        // mode: "no-cors"
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                            default: res[0].url
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const { form, formProps, saveButtonProps } = useForm<IFacilityBooking,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                
                CreateLog({
                    resource : log_endpoint,
                    values : {
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(),
                        log_event               : "Create",
                        menu_event              : 'hcu-settings',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
        },
        errorNotification(error:any, values:any, resource:any) {
            return {
                message: error.toString(),
                type: "error" ,
                undoableTimeout: 1000
            }
        },
        onMutationError(error, variables, context) {
            console.log('error', error)
            console.log('variables', variables)
            console.log('context', context)
        },
    });

    const handleChange = (e:any) => {
        setText(e.target.value);
    };

    // console.log('prop_id', prop_id)
    
    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : prop_id !== null ? "eq" : "ne",
            //     value : prop_id !== null ? prop_id : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    

    useEffect(() => {
        form.setFieldsValue({
            // property: {
            //   id: prop_id,
            // },
            property: data_property !== undefined ? data_property : null
            // properties:
            // recordData?.properties !== undefined ?
            //     recordData?.properties.length > 0 ?
    
            //         recordData?.properties.map((v: any) => {
            //             // console.log(v)
            //             return { value: v.id, label: v.property_name }
            //         })
            //         : form.resetFields(["properties"])
            //     : form.resetFields(["properties"]),
        });
    }, []);
    

    async function createDaysSetup(values:any) {

        if(vLastDate < vFirstDate){
            // console.log('error')
            notification.error({
                // message: stat + ' : ' + msg.TH,
                message: "การแสดงปุ่มครั้งสุดท้าย หลังจากวันโอนน้อยกว่าวันแรกไม่ได้",
                // description: msg,
                duration: 3,
            });
            setButtonDisabled(false);

        }else{
            console.log('values',values)
            let data = { 
                "data":{
                    "property": values.property,
                    "hcu_setting": [
                        {
                            "seq": 1, // 1 = ครั้งแรกหลังจากวันโอน, 2 = ครั้งสุดท้าย หลังจากวันโอน , 3 = การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน
                            "date": values.first_day,
                            "home_check_up_type":{
                                "name":"DateSetup"
                            },
                            "createBy" : Identity_data ? Identity_data.user_profile.id : null
                        },
                        {
                            "seq": 2, // 1 = ครั้งแรกหลังจากวันโอน, 2 = ครั้งสุดท้าย หลังจากวันโอน , 3 = การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน
                            "date": values.last_day,
                            "home_check_up_type":{
                                "name":"DateSetup"
                            },
                            "createBy" : Identity_data ? Identity_data.user_profile.id : null
                        },
                        {
                            "seq": 3, // 1 = ครั้งแรกหลังจากวันโอน, 2 = ครั้งสุดท้าย หลังจากวันโอน , 3 = การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน
                            "date": values.second_day,
                            "home_check_up_type":{
                                "name":"DateSetup"
                            },
                            "createBy" : Identity_data ? Identity_data.user_profile.id : null
                        }
                    ]
                }
            }
    
            console.log('data onFin', data)
            let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
            console.log('res', res)
    
            successNoti('200', 'success');
    
            replace("/majorcare/setup-hcu")
        }
        
    }

    async function updateDaySetup(values:any) {
        console.log('values', values)

        if(vLastDate < vFirstDate){
            notification.error({
                message: "การแสดงปุ่มครั้งสุดท้าย หลังจากวันโอนน้อยกว่าวันแรกไม่ได้",
                duration: 3,
            });
            setButtonDisabled(false);
        }else{
            let data_p = await axios.get(API_URL + "/home-check-up-settings?populate=*&pagination[pageSize]=100&filters[property][id][$eq]=" + values?.property, {
                headers: { Authorization: "Bearer "+token }
            });
            console.log('1.day settings all', data_p?.data.data)
            const res2 = data_p?.data?.data.filter((e:any)=> e.property.id == values?.property)
            console.log('2.only prop selected', res2)
            const res3 = res2[0]?.hcu_setting?.filter((e:any)=> e.home_check_up_type.name == "DateSetup")
            console.log('3.only day setup', res3) 

            let data = { 
                "data":{
                    "property": Number(values?.property),
                    "hcu_setting": [
                        {
                            "id": Number(res3[0]?.id),
                            "seq": 1, // 1 = ครั้งแรกหลังจากวันโอน, 2 = ครั้งสุดท้าย หลังจากวันโอน , 3 = การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน
                            "date": Number(values.first_day),
                            // "home_check_up_type":{
                            //     "name":"DateSetup"
                            // },
                            "home_check_up_type": 4, // 4 = DateSetup
                            "updateBy" : Identity_data ? Identity_data.user_profile.id : null
                        },
                        {
                            "id": Number(res3[1]?.id),
                            "seq": 2, // 1 = ครั้งแรกหลังจากวันโอน, 2 = ครั้งสุดท้าย หลังจากวันโอน , 3 = การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน
                            "date": Number(values.last_day),
                            // "home_check_up_type":{
                            //     "name":"DateSetup"
                            // },
                            "home_check_up_type": 4, // 4 = DateSetup
                            "updateBy" : Identity_data ? Identity_data.user_profile.id : null
                        },
                        {
                            "id": Number(res3[2]?.id),
                            "seq": 3, // 1 = ครั้งแรกหลังจากวันโอน, 2 = ครั้งสุดท้าย หลังจากวันโอน , 3 = การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน
                            "date": Number(values.second_day),
                            // "home_check_up_type":{
                            //     "name":"DateSetup"
                            // },
                            "home_check_up_type": 4, // 4 = DateSetup
                            "updateBy" : Identity_data ? Identity_data.user_profile.id : null
                        }
                    ]
                }
            }

            let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
            console.log('res', res)
            successNoti('200', 'success');
            replace("/majorcare/setup-hcu")
        }
    }

    const checkCreateProp = async (val:any) => {
        // console.log('val', val)

        // console.log('API_URL',API_URL)
        // let k = await axios.get(API_URL + "/properties?populate=*&filters[id][$eq]=" + prop_id, {
        //     headers: { Authorization: "Bearer "+token }
        // });
        let data_p = await axios.get(API_URL + "/home-check-up-settings?populate=*&pagination[pageSize]=100&filters[property][id][$eq]=" + val, {
            headers: { Authorization: "Bearer "+token }
        });
        // console.log('1.day settings all', data_p?.data.data)
        const res2 = data_p?.data?.data.filter((e:any)=> e.property.id == val)
        // console.log('2.only prop selected', res2)
        // const res3 = res2.filter((e:any)=> e.hcu_setting.home_check_up_type.name == "DateSetup")
        // const res3 = res2?.hcu_setting?.filter((e:any)=> e.home_check_up_type.name == "DateSetup")
        const res3 = res2[0]?.hcu_setting?.filter((e:any)=> e.home_check_up_type.name == "DateSetup")
        // const res3:any = res2.hcu_setting.find((item:any) => item?.home_check_up_type.name == "DateSetup");
        // console.log('3.only day setup', res3)

        if(res3 && res3.length > 0){
            return false
        }else{
            return true
        }

    };

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully create days setup',
        });
    };
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleClick = () => {
        // Perform your button click logic here
        setButtonDisabled(true);
        form.submit()
        // Disable the button after it's clicked
    };

    return (
    <Create  
        // saveButtonProps={saveButtonProps} 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-setup-day.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                // {...createProps} 
                {...formProps}
                layout="vertical"
                onFinish={async (values:any) => {
                    // console.log('values onFin', values)
                    // console.log(vFirstDate)
                    // console.log(vLastDate)
                    values.property = values.property.id ? values.property.id : data_property;
                    values.first_day = parseInt(vFirstDate);
                    values.last_day = parseInt(vLastDate);
                    values.second_day = parseInt(SecondNotiAfterTransfer);
                    // values.type = values.type.value;
                    // values.msg = values.msg;
                    // values.msg_en = values.msg;
                    // values.createBy = user_data.user_profile.id; 
                    // values.deleteStatus = "N";

                    // values.sms = values.sms == undefined ? false : true;
                    // values.email = values.email == undefined ? false : true;
                    // values.inApp = values.inApp == undefined ? false : true;

                    // check if selected property already create
                    let IsCreatedProp = await checkCreateProp(values.property);

                    console.log('IsCreatedProp', IsCreatedProp)
                    // createDaysSetup(values)

                    if(IsCreatedProp){
                        console.log('create')
                        createDaysSetup(values)
                    }else{
                        console.log('update')
                        updateDaySetup(values);
                        // notification.error({
                        //     // message: stat + ' : ' + msg.TH,
                        //     message: "setup of this property already created",
                        //     // description: msg,
                        //     duration: 3,
                        // });
                        setButtonDisabled(false);
                    }

                    // console.log(values)
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >   
                <div className="divider-container">
                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                // name="property"
                                label="Property"
                                rules={[
                                    {
                                        required: data_property !== undefined ? false : true, message: "Please select property"
                                    },
                                ]}
                            >
                                {/* <Select 
                                    // defaultValue={{ value: data_property, label: prop_name?.toString }}
                                    // value={prop_id}
                                    value={prop_name}
                                    defaultActiveFirstOption
                                    defaultOpen
                                    placeholder={t("select.sel")}  
                                    {...selProperty}
                                /> */}
                                {/* <Select
                                    defaultActiveFirstOption
                                    defaultOpen
                                    placeholder={t("select.sel")}
                                    {...selProperty}
                                /> */}
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty} 
                                    // value={res_data? res_data?.property?.property_name : "xxxx"} 
                                    defaultValue={data_property !== undefined ? data_property : null}
                                    value={data_property !== undefined ? data_property : null}
                                    // disabled
                                />

                             </Form.Item>
                        </Col>

                        <Col span={1}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={5} className="gutter-row">
                            <span> แสดงปุ่มครั้งแรก หลังจากวันโอน</span>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <div>
                                <Form.Item  
                                    name="first_day"
                                    label=""
                                    rules={[
                                        {
                                            required: true, message: "Please input day"
                                        },
                                    ]}
                                >
                                    <Input
                                        type={"number"}
                                        style={{width:"40%"}} 
                                        min={0}
                                        placeholder="number"
                                        // onChange={(e:any) => setFirstDate(e.target.value)}

                                        onChange={(e:any) => {
                                            setFirstDate(e.target.value)
                                            Set2ndNotiAfterTransfer(parseInt(e.target.value) +1)
                                        }}
                                        suffix="วัน"
                                    ></Input>
                                    {/* <span style={{marginLeft:"20px" , display:"none"}}>{vFirstDate}</span> */}
                                </Form.Item> 
                                <span style={{marginLeft:"20px" , display:"none"}}>{vFirstDate}</span>
                            </div>                       
                        </Col>

                        {/* <Col className="gutter-row" span={1}>
                            <Form.Item  label="" >
                                <span style={{marginLeft:"20px"}}>วัน</span>      
                            </Form.Item>
                        </Col> */}
                    </Row>


                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                        </Col>

                        <Col span={1}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={5} className="gutter-row">
                            <span>การแจ้งเตือนครั้งที่ 2 หลังจากวันโอน</span>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <div>
                                <Form.Item  
                                    label=""
                                    name="second_day"
                                    rules={[
                                        {
                                            required: true, message: "Please input day"
                                        },
                                    ]}
                                >
                                    <Input
                                        type={"number"}
                                        style={{width:"40%"}} 
                                        min={parseInt(vFirstDate)+1}
                                        placeholder="number"
                                        onChange={(e:any) => {
                                            Set2ndNotiAfterTransfer(e.target.value)
                                            setLastDate(parseInt(e.target.value) +1)
                                        }}
                                        value={SecondNotiAfterTransfer}
                                        suffix="วัน"
                                    ></Input>
                                    {/* <span style={{marginLeft:"20px", display:"none"}}>{SecondNotiAfterTransfer}</span> */}
                                </Form.Item>
                                <span style={{marginLeft:"20px", display:"none"}}>{SecondNotiAfterTransfer}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                        </Col>

                        <Col span={1}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={5} className="gutter-row">
                            <span>แสดงปุ่มครั้งสุดท้าย หลังจากวันโอน</span>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <div>
                                <Form.Item  
                                    label=""
                                    name="last_day"
                                    rules={[
                                        {
                                            required: true, message: "Please input day"
                                        },
                                    ]}
                                >
                                    <Input
                                        type={"number"}
                                        style={{width:"40%"}} 
                                        min={parseInt(SecondNotiAfterTransfer)+1}
                                        placeholder="number"
                                        onChange={(e:any) => setLastDate(e.target.value)}
                                        value={vLastDate}
                                        suffix="วัน"
                                    ></Input>
                                    {/* <span style={{marginLeft:"20px" , display:"none"}}>{vLastDate}</span> */}
                                </Form.Item>
                                <span style={{marginLeft:"20px" , display:"none"}}>{vLastDate}</span>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6} className="gutter-row"></Col>
                        <Col span={2} className="gutter-row">
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={16}></Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />} disabled={buttonDisabled}>Save</Button> */}
                        <Button type="primary" onClick={() => handleClick()} icon={<Icons.SaveOutlined />} disabled={buttonDisabled}>Save</Button>
                    </Row>
                </div>

            </Form>
    </Create>   
    );
};