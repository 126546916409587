import { Breadcrumb, Button, Col, EditButton, Form, Icons, List, Row, Select, Spin, Table, Tabs, useSelect } from "@pankod/refine-antd"
import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import type { TabsProps } from 'antd';
import { useState } from "react";
import { petURL } from "../../constants";
import dayjs from "dayjs";

let dataTBLOCAL: any = undefined;
let dataProp: any = undefined;
export const ConfigpropList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TabPane } = Tabs;
    const { create, edit, replace, list } = useNavigation();

    const [formSearch] = Form.useForm();
    const {data: user_dt} = useGetIdentity<any>();

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [tabm, settabm] = useState<any>("type");
    const [dataTB, setdataTB] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [pageSize, setPageSize] = useState<any>(10);
    const [Prop, setProp] = useState<any>();

    const { data: dataLoad } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllConfigProperty`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item?.property_obj?.id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e?.property_obj?.id == Number(prop_id))
                }

                setdataTB(dataLoad);
                dataTBLOCAL = dataLoad;

                // setLoading(false);
            },
        },
    });

    const { selectProps: propertySelectProps } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        value: dataLoad[index]?.id,
                        label: dataLoad[index]?.property_name
                    })
                }

                if(newData?.length > 0){
                    dataProp = newData;
                }
                setLoading(false);

            },
        }
    });

    const columns:any = [
        {
            title: `${t("pet-vacc-list.properties")}`,
            dataIndex: 'property_obj',
            key: 'property_obj',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.property_obj?.property_name < b?.property_obj?.property_name) {return -1}
                if (a?.property_obj?.property_name > b?.property_obj?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // return (<span>{record?.pet_type_name ? record?.pet_type_name : '-'}</span>)
                return (<span>{record?.property_obj ? record?.property_obj?.property_name : '-'}</span>)
            },
        },
        {
            // title: 'Pet Feature',
            title: `${t("config-property.feature")}`,
            // dataIndex: '',
            // key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.can_pet < b?.can_pet) {return -1}
                if (a?.can_pet > b?.can_pet) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.can_pet == true ? "เปิด" : 'ปิด'}</span>)
            },
        },
        {
            title: `${t("config-property.service")}`,
            dataIndex: 'is_charge',
            key: 'is_charge',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.is_charge < b?.is_charge) {return -1}
                if (a?.is_charge > b?.is_charge) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.is_charge == true ? "เสียเงิน" : 'ไม่เสีย'}</span>)
            },
        },
        // {
        //     title: 'Status',
        //     // dataIndex: '',
        //     // key: '',
        //     width: 140,
        //     sorter: (a:any, b:any) => {
        //         // if (a?.is_charge < b?.is_charge) {return -1}
        //         // if (a?.is_charge > b?.is_charge) {return 1}
        //         return (1)
        //     },
        //     render: (value:any, record:any) => {
        //         return (<span>{'-'}</span>)
        //     },
        // },
        {
            title: `${t("config-property.create")}`,
            // dataIndex: '',
            // key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.create_by?.user_name.localeCompare(b?.create_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.create_by ? record?.create_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("config-property.create-date")}`,
            // dataIndex: '',
            // key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.create_date < b?.create_date) {return -1}
                if (a?.create_date > b?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.create_date ? dayjs(record?.create_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("config-property.edit")}`,
            // dataIndex: '',
            // key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("config-property.last-update")}`,
            // dataIndex: '',
            // key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("config-property.action")}`,
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                return (<EditButton hideText size="small" onClick={()=> replace("/pets/config-property/edit/" + record?._id)}/>);
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onsearchTB (data: any, mode: any){
        console.log(">>> Prop", Prop)
        if(mode == "S"){
            let newDT: any = data;    

            if(Prop){
                let dtFilter: any = newDT?.filter((f: any)=> f?.property_obj?.id == String(Prop));
                setdataTB(dtFilter);
            }else{ //กรณีกดลบ select แล้วกดปุ่ม search แทนปุ่ม reset
                setdataTB(data)
            }
        }else if(mode == "R"){
            let newDT: any = data;
            
            setdataTB(newDT);
            setProp(undefined);
            formSearch?.resetFields(['pet-type']);
        }
    }
    
    return (
        <List 
            title={t("config-property.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("pet-service.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("config-property.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Spin size="large" spinning={isLoading}>

                <Form 
                    form={formSearch}
                    layout="vertical"
                    style={{marginBottom: "1rem"}}
                >
                    <div className="layout_custom_search">
                        <Row gutter={6}>
                            <Col className="gutter-row" span={20}>
                                <Form.Item 
                                    label=" "
                                    name="property"
                                    className="visible-label"
                                    style={{marginBottom: 0, display: "inline-block"}}
                                >
                                    <Select
                                        options={dataProp}
                                        placeholder={t("Property")}
                                        showSearch
                                        allowClear
                                        onChange={(e: any)=> setProp(e)}
                                        onClear={()=> setProp(undefined)}
                                        style={{width: "250px", marginRight: "15px"}}
                                        filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                    />
                                </Form.Item>

                                <Button 
                                    icon={<Icons.SearchOutlined />} 
                                    style={{borderRadius: "5px", marginRight: "15px"}} 
                                    type="primary" 
                                    onClick={()=> {
                                        onsearchTB(dataTBLOCAL, "S")
                                    }}
                                >
                                    {t("buttons.search")}
                                </Button>

                                <Button 
                                    icon={<Icons.ReloadOutlined />} 
                                    style={{borderRadius: "5px"}} 
                                    onClick={()=> {
                                        onsearchTB(dataTBLOCAL, "R")
                                    }}
                                >
                                    {t("buttons.reset")}
                                </Button>
                            </Col>
                            <Col className="gutter-row" span={4} style={{textAlign: "right"}}>
                                <Button 
                                    type="primary" 
                                    icon={<Icons.PlusOutlined />} 
                                    style={{borderRadius: "5px"}}
                                    onClick={()=> create("config-property")} 
                                >
                                    {t("buttons.create")}
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>

                <Table 
                    dataSource={dataTB ? dataTB : []} 
                    columns={columns}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        pageSizeOptions: ['10', '30', '50', '100'], 
                        showSizeChanger: true, 
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 1300 }}
                />
            </Spin>
        </List>
    )
}