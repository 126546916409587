/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useForm } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { majorCareUrl } from "../../../constants";
import dayjs from "dayjs";
let swLocal : boolean = true;
let dataonFilter: any = [];
export const MjcWaiting: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const { formProps } = useForm<any,HttpError,{}>()
    // console.log('majorCareUrl', majorCareUrl)
    const { edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(0);
    const [dataList, setDataList] = useState<any>([]);
    const [keepJobNo, setKeepJobNo] = useState<any>()
    const dataBlank:any = []
    const getDataK = async ()=>{
        // 6485bcda049e59df2850eba0 = รับเรื่อง
        // 6485bd8b681aef755a4f279f = รอนัดหมาย

        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findHomecheckjobByStat`,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad', dataLoad)
                // console.log('prop_id', prop_id)
                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.request_by?.unitDetail?.property_id == Number(prop_id))
                }

                // status ==  "6485bd96681aef755a4f27a1" เฉพาะกิจสำหรับแก้บัค
                let xx = dataLoad.filter((e:any)=>  e.status == "6485bd96681aef755a4f27a1" || e.status == "6485bcda049e59df2850eba0" || e.status == "6485bd8b681aef755a4f279f" )
                let newDataList = []
                for (let index = 0; index < xx.length; index++) {
                    // dataList.push(xx[index]);
                    newDataList.push(xx[index]);
                }
                if (!!newDataList && newDataList !== null) {
                    setDataList(newDataList.reverse())
                }else {
                    setDataList(xx)
                }
            });
    }
    //console.log("dataList",dataList)
    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 0){
                    getDataK();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    // useEffect(() => {
    //     getDataK();
    // }, []);

    async function updateStatus(id:any, val:any){
        let data = {
            "is_active": val,
        }
        let res_update = await axios.put(
            majorCareUrl + `/majorcare/updateHomecheckjob?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);
    };

    const { confirm } = Modal;

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.job-no")}</span>,
            // dataIndex: 'item_name',
            //key: 'item_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.job_id.localeCompare(b?.job_id),
            render: (value:any, record:any) => {
                //console.log("recoed",record)
                return (
                    <>
                        <div>{record?.job_id ? record?.job_id : '--'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.property")}</span>,
            //dataIndex: 'name',
            //key: 'name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.property_obj[0]?.property_name.localeCompare(b?.property_obj[0]?.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record?.property_obj[0]?.property_name ? record?.property_obj[0]?.property_name:"-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.unit-no")}</span>,
            // dataIndex: 'createBy',
            //key: 'unit_no',
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => a?.unit_obj?.length > 0 &&  a?.unit_obj[0]?.unit_name.localeCompare(b?.unit_obj?.length > 0 &&  b?.unit_obj[0]?.unit_name),
            // sorter: (a:any, b:any) => a?.unit_obj[0]?.unit_name.localeCompare(a?.unit_obj[0]?.unit_name),
            sorter: (a:any, b:any) => {
                if (a?.unit_obj[0]?.unit_name < b?.unit_obj[0]?.unit_name) {return -1}
                if (a?.unit_obj[0]?.unit_name > b?.unit_obj[0]?.unit_name) {return 1}
                return (0)
            },
            render: (val:any, record:any) => {
            
                return (
                    <>
                        <div>{record?.unit_obj[0]?.unit_name ? record?.unit_obj[0]?.unit_name:"-"}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.customer-name")}</span>,
            // dataIndex: 'cus_name',
            //key: 'cus_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.request_by?.attributes?.first_name.localeCompare(b?.request_by?.attributes?.first_name),
            render: (value:any, record:any) => {
                // console.log('test', record)
                return (
                    <>
                        {/* <div>-</div> */}
                        <div>{record.request_by?.attributes?.first_name ? record.request_by?.attributes?.first_name:"-"}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.trans-date")}</span>,
            //dataIndex: 'transfer_date',
            key: 'transfer_date',
            align: 'center',
            sorter: (a:any, b:any) =>  dayjs(a?.request_by?.unitDetail?.transfer_date).format("DD/MM/YY").localeCompare(dayjs(b?.request_by?.unitDetail?.transfer_date).format("DD/MM/YY")),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.request_by ? record.request_by.unitDetail ? dayjs(record.request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.create-by")}</span>,
            // dataIndex: 'createBy',
            // key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.create_by?.first_name < b?.create_by?.first_name) {return -1}
                if (a?.create_by?.first_name > b?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.create_by?.first_name : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : "-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.created-date")}</span>,
            // dataIndex: 'updateBy',
            key: 'createDate',
            align: 'center',
            width: 150,
            sorter: (a:any, b:any) =>  dayjs(a?.create_date).format("DD/MM/YY").localeCompare(dayjs(b?.create_date).format("DD/MM/YY")),
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>-</div> */}
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.update-by")}</span>,
            // dataIndex: 'updateBy',
            // key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.update_by?.first_name < b?.update_by?.first_name) {return -1}
                if (a?.update_by?.first_name > b?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : "-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.last-update")}</span>,
            // dataIndex: 'update_date',
            // key: 'update_date',
            align: 'center',
            width: 150,
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.status")}</span>,
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.status < b?.status) {return -1}
                if (a?.status > b?.status) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* // 6485bcda049e59df2850eba0 = รับเรื่อง
                        // 6485bd8b681aef755a4f279f = รอนัดหมาย */}
                        <div>
                            {
                                record ? 
                                    record.status !== null ?
                                        record.status == "6485bcda049e59df2850eba0" ?
                                            <Tag className="tag_mjc_stat_accept"><TextField value={t("tag_mjc_stat.accept")}  /></Tag>
                                        :
                                            record.status == "6485bd96681aef755a4f27a1" ?
                                                <Tag className="tag_mjc_stat_wait_appointment"><TextField value={t("tag_mjc_stat.expire")}  /></Tag>
                                            :
                                                <Tag className="tag_mjc_stat_wait_appointment"><TextField value={t("tag_mjc_stat.wait")}  /></Tag>
                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 85,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record.is_active === true ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        onClick={() => edit("mjc-job-waiting", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        style={{marginRight:'4px'}}
                                    />
                                    {/* <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record._id) }} 
                                        danger
                                    ></Button> */}
                                    
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record._id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';

    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomRight');
    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const searchData = () => {
        let newData = dataList
        //console.log("keepJobNoNewData",newData)
        let filter = newData.filter((e:any) => e?.job_id.includes(keepJobNo.toUpperCase()));
        //console.log("filter",filter)
        setDataList(filter)
    }

    const onReset = () => {
        setKeepJobNo(null)
        formProps?.form?.resetFields();
        refreshDataSource()
    }

    return <>
        <List 
            title=""
        >
            <Row>
                <Col span={18}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                        {...formProps}
                    >
                        <Form.Item name="Job_no" >
                            <Input
                                onChange={(e) => setKeepJobNo(e.target.value)}
                                placeholder="Search job" 
                                prefix={<Icons.SearchOutlined />} 
                                allowClear
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => searchData()} type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => onReset()} style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <div style={{ width: '100%' }}>
                {
                    dataList.length > 0 ?
                        <Table 
                            dataSource={dataList.length > 0 ? dataList : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 2000 }}
                        />
                    :
                        <Table  
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{x: 2000}}
                        />
                }
            </div>
            <Row style={{float:'right', paddingTop:'10px'}}>
                <Col span={24} >
                    {t("repair-stat.title")} {dataList ? dataList?.length : 0}
                </Col>
            </Row>
        </List>
    </>
}