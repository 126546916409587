/* eslint-disable */
import { Badge, Breadcrumb, Button, Col, CreateButton,  EditButton, ExportButton, Form, Icons, Input, List, Modal,  Row,  Select,  Space, Table,  Tabs,  TextField,  useModalForm,  useSelect,  useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useOne, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog , Ivehicle, IvehicleBrand, IvehicleType } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

let dataVHC: any = [];

export const VehicleBList: React.FC<IResourceComponentsProps> = () => {

    const tabStatus = localStorage.getItem("keyTabStatusBrandList");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Vehicle Brands");
    const { TabPane } = Tabs;
    const { confirm } = Modal;
    const { mutate } = useUpdate<Ivehicle>();
    const ed_point = "vehicle-brands";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
        
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()
    const [keepDataStatus, setKeepDataStatus] = useState<any>('N')
    const [keepData, setKeepData] = useState<any>()
    const [keepSelect, setKeepSelect] = useState<any>()
    const [brand, setBrand] = useState<any>('')
    const [dataList, setDataList] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(null);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);


    const { tableProps,searchFormProps,tableQueryResult } = useTable<IvehicleBrand,HttpError, IvehicleBrand>({
        metaData: {
            populate: '*'
        }, 
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { vehicle_type, brand_nameTH, deleteStatus } = params;
            console.log("vehicle_type",vehicle_type)
                filters.push(
                    {
                        field       : "vehicle_type][id]",
                        operator    : "eq",
                        value       : vehicle_type ? vehicle_type: null
                    },
                    {
                        field       : "brand_nameEN",
                        operator    : "contains",
                        value       : brand_nameTH ? brand_nameTH : null
                    },
                    {
                        field       : "deleteStatus",
                        operator    : "eq",
                        value       : !!keepDataStatus ? keepDataStatus : tabStatus
                    },
                );
                return filters;
        },
        initialPageSize: 10000,
        initialFilter: [
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : tabStatus
            },
        ],
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
        queryOptions:{onSuccess(data) {
                //console.log("data",data?.data)
                //console.log("keepSelect",keepSelect)
                let dataLoad = data?.data
                let datafilter = dataLoad.filter((e:any) => e?.vehicle_type?.id == keepSelect)
                //console.log("datafilter",datafilter)
                
                if (!!keepSelect && datafilter.length > 0) {
                    setKeepData(datafilter)
                }
                if(!!keepSelect && datafilter.length == 0){
                    setKeepData(datafilter)
                }
                if (!keepSelect && datafilter.length == 0) {
                    setKeepData(dataLoad)
                }
        },}
    });
    
    const datavhc = useList<Ivehicle>({ 
        resource: "vehicle-brands" ,
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        queryOptions:{onSuccess(data) {
            dataVHC = data?.data;
        },}
    });

    const { selectProps: selvehicleType } = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            }
        ],
    });

    const { triggerExport, isLoading: exportLoading } = useExport<Ivehicle>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,
                };
            }
        },
    });
    
    const onReset = () => {
        setKeepSelect(undefined)
        setBrand(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }
    
    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });
        
        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'vehicle_brand',
                SysPkID         : id+""
            },
            successNotification: false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        setKeepDataStatus(v)
        searchFormProps.form?.submit();
        localStorage.setItem("keyTabStatusBrandList",v)
        localStorage.setItem("keyU",v)
        localStorage.setItem("keyTabUserPortal", v)
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose
    } = useModalForm<any>({
        resource: "vehicle-brands",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "vehicle-brands",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data) {
            
        },}
    });

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : sorterOrder
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }

        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSizeChange(pagination?.pageSize)
        }
    };
    
    const API_URL = useApiUrl();
    const getData = async ()=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/vehicle-brands?populate=*&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            let deleteStatus = !!keepDataStatus ? keepDataStatus : tabStatus
            if(brand){
                apiEndPoint += `&filters[brand_nameEN][$contains]=${brand}`
                // apiEndPoint += `&filters[brand_nameTH][$contains]=${brand}`
            }
            if(keepSelect){
                apiEndPoint += `&filters[vehicle_type][id][$eq]=${keepSelect}`
            }
            if(deleteStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${deleteStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${deleteStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const resultList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let vehicle_type = null
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.vehicle_type?.data){
                            vehicle_type = {id: attributes?.vehicle_type?.data?.id , ...attributes?.vehicle_type.data.attributes}
                        }
                        const result = {id: item?.id , ...attributes}
                        result.createBy = createBy
                        result.updateBy = updateBy
                        result.vehicle_type = vehicle_type
                        return result
                    })
                    setDataList(resultList || [])
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    setLoadingTable(false)
                });
        } catch (error) {
            setLoadingTable(false)
        }
    }

    useEffect(() => {
        if (!keepSelect && !brand && Identity_data) {
            getData()
        }
      }, [keepSelect, brand]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,keepDataStatus,Identity_data])

    return <>
            <List 
                title={t("vehicle.bc_title_b")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title_b")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="vehicle_type" >
                                <Select {...selvehicleType} 
                                    placeholder="Select vehicle type" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                    allowClear
                                    onChange={(e) => setKeepSelect(e)}
                                />
                            </Form.Item>

                            <Form.Item name="brand_nameTH">
                                <Input 
                                    onChange={(e) => setBrand(e.target.value)}
                                    placeholder="Vehicle Brands" 
                                    allowClear
                                    style={{borderRadius: 5}}
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={getData} htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        
                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                {/* <CreateButton id="create_btn"  onClick={() => createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Form {...searchFormProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={!tabStatus ? keepDataStatus : `${tabStatus}`}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={onStatusChange} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={dataVHC?.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={dataVHC?.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table 
                    //{...tableProps} 
                    dataSource={dataList}
                    onChange={handleTableChange}
                    loading = {loadingTable}
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        // onShowSizeChange , 
                        // onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false,
                        current: metaPagination?.page || page,
                        pageSize: metaPagination?.pageSize || pageSizeChange,
                        total: metaPagination?.total,
                        // metaPagination?.pageCount
                        position:["bottomRight"]
                    }} 
                >
                    {/* <Table.Column width={70} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    <Table.Column key={'[vehicle_type][type_nameEN]'} title={t("vehicle.Ltype")} align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.vehicle_type?.type_nameEN < b?.vehicle_type?.type_nameEN) {return -1}
                            if (a?.vehicle_type?.type_nameEN > b?.vehicle_type?.type_nameEN) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.vehicle_type? record?.vehicle_type.type_nameEN :"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="brand_code" title={t("vehicle.Lbrand-code")} width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    <Table.Column key={'[brand_nameTH]'} title={t("vehicle.Lbrand-name_TH")} align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.brand_nameTH < b?.brand_nameTH) {return -1}
                            if (a?.brand_nameTH > b?.brand_nameTH) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log("xxxxxaaee",record)
                            return <>
                                <TextField value={record? record.brand_nameTH:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[brand_nameEN]'} title={t("vehicle.Lbrand-name_EN")} align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.brand_nameEN < b?.brand_nameEN) {return -1}
                            if (a?.brand_nameEN > b?.brand_nameEN) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.brand_nameEN:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[createBy][first_name]'} title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[createdAt]'} title="CREATE DATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record && !!record.createdAt ? Moment(record.createdAt).format('D/MM/yyyy HH:mm') : "-"}  />
                            </>
                        }}   
                    />

                    <Table.Column key={'[updateBy][first_name]'} title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[updatedAt]'} title="LAST UPDATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record && !!record.updatedAt ? Moment(record.updatedAt).format('D/MM/yyyy HH:mm') : "-"}  />
                            </>
                        }}   
                    />
                  
                    <Table.Column<Ivehicle>
                        width={100}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                // onClick={() => editModalShow(record.id)}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...createModalProps}
                title={"Vehicle Brand Create"}
                okText = {"Create"}
                maskClosable={false}
                width={300}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.brand_code = values.brand_code;
                        values.brand_nameTH = values.brand_nameTH;
                        values.brand_nameEN = values.brand_nameEN;
                        values.vehicle_type = values.vehicle_type.id;
                        
                        values.deleteStatus = "N";
                        values.createBy = user_data.user_profile.id;
                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type")} name={["vehicle_type", "id"]}
                                rules={[
                                    {
                                        required: true, message: 'Please select vehicle type'
                                    },
                                ]}>
                                <Select placeholder={t("select.sel")}
                                    {...selvehicleType}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand-code")} name="brand_code"
                                rules={[
                                    {
                                        required: true, message: 'Please enter brand code.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand-name_en")} name="brand_nameEN"
                                rules={[
                                    {
                                        required: true, message: 'Please enter brand name(en).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand-name_th")} name="brand_nameTH"
                                rules={[
                                    {
                                        required: true, message: 'Please enter brand name(th).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Vehicle Brand edit"}
                okText = {"Save"}
                maskClosable={false}
                width={300}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.brand_code = values.brand_code;
                        values.brand_nameTH = values.brand_nameTH;
                        values.brand_nameEN = values.brand_nameEN;
                        values.vehicle_type = values.vehicle_type.id;
                        
                        values.deleteStatus = "N";
                        values.updateBy = user_data.user_profile.id;
                        return (
                            editProps.onFinish &&
                            editProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type")} name={["vehicle_type", "id"]}
                                rules={[
                                    {
                                        required: true, message: 'Please select vehicle type'
                                    },
                                ]}>
                                <Select placeholder={t("select.sel")}
                                    {...selvehicleType}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand-code")} name="brand_code"
                                rules={[
                                    {
                                        required: true, message: 'Please enter brand code.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand-name_en")} name="brand_nameEN"
                                rules={[
                                    {
                                        required: true, message: 'Please enter brand name(en).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.brand-name_th")} name="brand_nameTH"
                                rules={[
                                    {
                                        required: true, message: 'Please enter brand name(th).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}