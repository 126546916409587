/* eslint-disable */
import { Breadcrumb, Button, Col, Form, Icons, List, message, Modal,  Row,  Select, Table, Tag, TextField, Tooltip, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useGetLocale, useNavigation, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IFacilityWaitlist} from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import { WarningOutlined, QuestionCircleOutlined, QuestionOutlined } from "@ant-design/icons";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";
import dayjs from "dayjs";

export const FacilityWaitlist: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility Waitlist");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    
    // const todayDate = dayjs().format("YYYY-MM-DD");
    const { tableProps,searchFormProps } = useTable<IFacilityWaitlist,HttpError, IFacilityWaitlist>({
        metaData: {
            // populate: '*'
            // populate:  ['user_profile'],
            populate: ['user_profile','user_profile.user_prefix','facility','facility_time_slot','property','unit','facility_type','updateBy'],
        },
        permanentSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        permanentFilter:[
            {
                field : "property][id]",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            filters.push(
                {
                    field       : "property][id]",
                    operator    : "eq",
                    value       : vProperty !== undefined ? vProperty : null,
                },
                {
                    field       : "unit][id]",
                    operator    : "eq",
                    value       : vUnitID !== undefined ? vUnitID : null,
                },
                {
                    field       : "facility][id]",
                    operator    : "eq",
                    value       : vFacilityID !== undefined ? vFacilityID : null,
                },
            );
            return filters;
        },
    });

    const categoryQueryResult = useOne<IFacilityWaitlist>({
        resource: "facility-wait-lists",
        id: ''
    });
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm }   = Modal;
    const { mutate }    = useUpdate<IFacilityWaitlist>();
    const ed_point  = "facility-wait-lists";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    // const { triggerExport, isLoading: exportLoading } = useExport<IFacilityBooking>({
    //     mapData: (item) => {
    //         //console.log(item)
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id, 

    //             };
    //         }
    //     },
    // });
    
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    const { replace }       = useNavigation();
    const API_URL           = useApiUrl();
    let resultQuery1:any    = [];
    const locale            = useGetLocale();
    const currentLocale     = locale();
    const token             = localStorage.getItem(TOKEN_KEY);
    const [vProperty, setProperty] = useState<any>();
    const [vFacilityID, setFacilityID] = useState<any>();
    const [vUnitID, setUnitID] = useState<any>();

    const { selectProps: propertySelectProps } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        // filters: initF,
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ],
    });

    const { selectProps: facilitySelectProps } = useSelect<any>({
        resource: "facilities",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "property][id]",
                operator: "eq",
                value: vProperty
            }
        ]
    });

    const { selectProps: unitSelectProps } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "property][id]",
                operator: "eq",
                value: vProperty
            }
        ]
    });

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            // values      : vCurDelStatus,
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
            }
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'facility-bookings',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showAcknowledge(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Acknowledge Confirm', 
            icon    : <QuestionOutlined />,
            // content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateAcknowledge(id, "Acknowledged");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function updateAcknowledge(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                updateBy        : user_data.user_profile.id + "",
                facility_status_admin : event
            },
            successNotification: false
        });
    }
    
    // old school
    function unAcknowledge(id:any){
        // confirm({
        //     // title   : 'Deactivate Confirm ?',
        //     title   : 'unAcknowledged Confirm',
        //     icon    : <QuestionOutlined />,
        //     // content : 'Click Confirm to deactivate this data.',
        //     okText  : 'Confirm',
        //     onOk() {
        //         // vCurDelStatus.deleteStatus = 'Y'
        //         updateunAcknowledge(id, null);
        //         return new Promise((resolve, reject) => {
        //             setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
        //         }).catch(() => console.log('something wrong.'));
        //     },
        //     onCancel() { },
        // });
    }

    function updateunAcknowledge(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                updateBy : user_data.user_profile.id + "",
                facility_status_admin : event
            },
            successNotification: false
        });
    }
    

    function CancelBooking(id:any) {
        confirm({
            title   : 'Cancel Booking', 
            icon    : <QuestionOutlined />,
            // content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateStatBooking(id, "Cancelled");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    async function updateStatBooking(id:any, event:any){
        console.log(id);
        // console.log('status : ', vCurDelStatus)
        // mutate({
        //     resource    : ed_point,
        //     id          : id+"",
        //     values      : {
        //         updateBy : user_data.user_profile.id + "",
        //         facility_status_booking : event
        //     },
        //     successNotification: false
        // });

        // http://192.168.100.151:1337/api/facility-bookings/booking_cancel?facility_booking=57
        resultQuery1 = await axios.get(API_URL + "/facility-bookings/booking_cancel?facility_booking=" + id  , { headers: { Authorization: 'Bearer ' + token } });

        console.log(resultQuery1);
        // return ({"TH" : "เกินกำหนดการยกเลิก กรุณาติดต่อส่วนกลาง","EN" : "Time is up for cancelling this booking. Please contact juristic person."})
        // return ({"TH" : "ยกเลิกการจองสำเร็จ","EN" : "This booking has been cancelled."})
        
        if(resultQuery1.data.EN === "Time is up for cancelling this booking. Please contact juristic person."){
            currentLocale === 'en' ?
                message.error ({
                    content: resultQuery1.data.EN,
                },5)
            :
                message.error ({
                    content: resultQuery1.data.TH,
                },5);
        }else{
            currentLocale === 'en' ?
                message.success ({
                    content: resultQuery1.data.EN,
                },3)
            :
                message.success ({
                    content: resultQuery1.data.TH,
                },3);

            // replace("");
            window.location.reload();
        }

    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const onReset = () => {
        setProperty(undefined);
        setUnitID(undefined);
        setFacilityID(undefined);
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };
    // console.log(tableProps)
    // console.log('todayDate', todayDate)

    return <>
            
            <List 
                title={t("fclt-waitlist.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-waitlist.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        > 
                           {/* <Form.Item name={["property", "property_name"]}>
                                <Input style={{borderRadius: 20,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item> */}
                            {/* <Form.Item name={["property", "property_name"]} >
                                <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear
                                />
                            </Form.Item> */}

                            <Form.Item name="property" >
                                <Select {...propertySelectProps} 
                                    placeholder="Select property" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '200px', position:'relative'}} 
                                    allowClear
                                    onClear={()=> setProperty(undefined)}
                                    onChange={(e) => setProperty(e)}
                                />
                            </Form.Item>

                            <Form.Item name="facility">
                                <Select {...facilitySelectProps}
                                    placeholder="Select facility"
                                    size="middle"
                                    style={{borderRadius: 20, width: '200px', position:'relative'}}
                                    allowClear
                                    onClear={()=> setFacilityID(undefined)}
                                    onChange={(e) => setFacilityID(e)}
                                />
                            </Form.Item>

                            <Form.Item name="unit">
                                <Select {...unitSelectProps}
                                    placeholder="Select unit"
                                    size="middle"
                                    style={{borderRadius: 20, width: '200px', position:'relative'}}
                                    allowClear
                                    onClear={()=> setUnitID(undefined)}
                                    onChange={(e) => setUnitID(e)}
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            {/* <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item> */}

                            {/* <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" />
                            </Form.Item> */}
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:paginationLength.length, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >

                    <Table.Column width={40} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/>

                    <Table.Column dataIndex="user_prefix" title={t("user-profiles.lprefix")}  width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.user_profile.user_prefix? record?.user_profile.user_prefix.prefix_name :"-"}  /> */}
                                <TextField value={record?.user_profile ? record?.user_profile.user_prefix !== null && record.user_profile.user_prefix !== undefined ? record?.user_profile.user_prefix.prefix_name : "-" : "-" } />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="user_profile" title={t("user-profiles.Lname")}  width={200}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                <TextField value={record?.user_profile? record?.user_profile.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="user_profile" title={t("user-profiles.Llastname")}  width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile? record?.user_profile.last_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="property" title={t("property.Ltitle")} width={1}
                        render={(value:any,record:any)=>{
                            return <>
                                <Tooltip placement="top" title={record?.property? record?.property.property_name :"NO DATA"}>
                                    <div style={{width: 150}}><div className="descCut"><TextField value={record?.property? record?.property.property_name :"-"}  /></div></div>
                                </Tooltip>
                            </>
                        }}
                    />
                    
                    <Table.Column dataIndex="unit" title={t("Lunit.title")} width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.unit? record?.unit.unit_name :"-"}  />
                            </>
                        }}
                    />
                     
                    <Table.Column dataIndex="facility" title={t("fclt.Ltitle")} width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.facility? record?.facility.name :"-"}  />
                            </>
                        }}
                    />
                    
                    {/* <Table.Column dataIndex="facility_type" title={t("fclt-type.Ltitle")} width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.facility_type? record?.facility_type.name :"-"}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="unit" title={t("Lunit.title")} width={150} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 10% 1%"}} color={"blue"}>Unit</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 10% 1%"}} color={"orange"}>Person</Tag> : <></> }/>
                            </>
                        }}
                    /> */}


                    {/* <Table.Column 
                        dataIndex="facility_status_booking" 
                        title={t("fclt-booking.bookingstat")}  
                        width={50} align="center"
                        render={(value:any,record:any)=>{
                            console.log('record x', record)
                            return <>
                                {
                                    record?.facility_status_booking === "Booking" ?
                                        <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#DCFDD4"}>
                                            <TextField style={{color:"#1D1929"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                        </Tag>
                                    :
                                        record?.facility_status_booking === "Waitlist" ?
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FFB800"}>
                                                <TextField style={{color:"#1D1D1D"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                            </Tag>
                                        :
                                            record?.facility_status_booking === "Cancelled" ?
                                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FDD4D4"}>
                                                    <TextField style={{color:"#AC1616"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                                </Tag>
                                            :
                                                record?.facility_status_booking === "Reschedule" ?
                                                    <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#Reschedule"}>
                                                        <TextField style={{color:"#1D1D1D"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                                    </Tag>
                                                :
                                                <TextField value={'-'}  />

                                }
                            </>
                        }}
                    /> */}

                    {/* <Table.Column 
                        dataIndex="facility_status_checkin" 
                        title={t("fclt-booking.checkinstat")}  
                        width={50} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record.facility.checkin ?
                                        record?.facility_status_checkin === "Checked in" ?
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#DCFDD4"}>
                                                <TextField style={{color:"#1D1929"}} value={record?.facility_status_checkin? record?.facility_status_checkin :"-"}  />
                                            </Tag>
                                        :
                                                record?.facility_status_checkin === "Late Check in" ?
                                                    <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FFF8B7"}>
                                                        <TextField style={{color:"#FF0000"}} value={record?.facility_status_checkin? record?.facility_status_checkin :"-"}  />
                                                    </Tag>
                                                :
                                                    todayDate > record.date ?
                                                        <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FDD4D4"}>
                                                            <TextField style={{color:"#AC1616"}} value={"No check in"}  />
                                                        </Tag>
                                                    :
                                                        <TextField value={'-'}  />
                                    : <>-</>
                                }
                            </>
                        }}
                    /> */}


                    <Table.Column dataIndex="max_user" title={t("fclt-booking.bookingcount")}  width={50} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.max_user? record?.max_user :"NO DATA"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="time" title={t("fclt-booking.usetime")} width={150} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.time? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>{record.time}</Tag>:<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>NO DATA</Tag> }/> */}
                                <TextField style={{fontWeight: 600}} 
                                    value={ 
                                        record.facility_time_slot? 
                                            <>
                                                <TextField style={{fontWeight: 600}} value={record?.date? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>{Moment(record.date).format('DD MMMM YYYY')}</Tag>:<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>NO DATA</Tag> }/> <br/> 
                                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%",marginTop: "5%"}} color="#D1D1D1">{Moment(record.facility_time_slot.time_start, "HH:mm").format('HH:mm') + ' - ' + Moment(record.facility_time_slot.time_end, "HH:mm").format('HH:mm')}</Tag>
                                            </>                                            
                                             
                                        :
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>NO DATA</Tag> 
                                    }
                                />
                            </>
                        }}
                    />

                    {/* <Table.Column 
                        dataIndex="facility_status_admin" 
                        title={t("fclt-booking.recordstat")}  
                        width={50} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record.facility_status_admin === "Acknowledged" ? 
                                    <>
                                        <CheckCircleOutlined style={{color:'#00CD15',fontSize:24}}  />
                                    </>
                                    :
                                    <>
                                        <Tooltip placement="rightTop" title="Acknowledge">
                                            <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { showAcknowledge(record.id) }} />
                                        </Tooltip>
                                    </>
                                }
                            </>
                        }}
                    /> */}

                    <Table.Column dataIndex="createdAt" title="CREATE AT" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />


                    {/* <Table.Column dataIndex="updatedBy" title="UPDATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                {console.log(record)}
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column<IFacilityBooking>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        render={(_, record) => (
                            <Space>

                            {
                                record.facility_status_booking === "Cancelled" ? 
                                <>
                                    <CloseCircleOutlined style={{color:'#FF0000',fontSize:24}}/>
                                </>
                                :
                                <>
                                    <Tooltip placement="rightTop" title="Cancel Booking">
                                        <CloseCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { CancelBooking(record.id) }} />
                                    </Tooltip>
                                </>
                            }

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                ></ShowButton>

                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger></Button>
                                        </>
                                    :
                                        <>
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    /> */}
                </Table>
            </List>
        </>
}