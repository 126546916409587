/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useForm } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import type { ColumnsType } from 'antd/es/table';
import axios from "axios";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
// import MDEditor from '@uiw/react-md-editor';
import { majorCareUrl } from "../../../constants";



let swLocal : boolean = true;
let dataonFilter: any = [];
export const HcuZoneList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TextArea } = Input;
    const { Option } = Select;
    const API_URL = useApiUrl();
    const { Text } = Typography;
    // console.log('majorCareUrl', majorCareUrl)
    const { create, edit } = useNavigation();
    const { confirm } = Modal;
    const [TabKey, SetTabKey] = useState<any>(true);

    const { TabPane } = Tabs;
    const [countInterval, setCountInterval] = useState(4);
    
    const [dataList, setDataList] = useState<any>([]);
    const [dataListInActive, setDataListInActive] = useState<any>([]);
    
    // const { confirm } = Modal;
    // const { mutate } = useUpdate<ICoupon>();
    // const ed_point = "home-check-up-msgs";
    // const [page, setPage] = useState(1);
    // const [pageSizeChange, setPageSizeChange] = useState(10);
    // const pageSizeState = [ '10','30', '50', '100'];

    // const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    // const userQuery                         = useGetIdentity();
    // const {data : user_data , isLoading}    = userQuery;
    // const vCurDelStatus                     = {deleteStatus : 'Y'}
    
    // const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
    //     resource:'home-check-up-settings',
    //     metaData: {
    //         populate: '*',
    //     },
    //     onSearch: (params) => {
    //         const filters: CrudFilters = [];
    //         const {deleteStatus, property} = params;
    //             filters.push(
    //             );
    //         return filters;
    //     },
    //     permanentSorter: [
    //         {
    //             field: "id",
    //             order: "desc",
    //         },
    //     ],
    //     queryOptions:{onSuccess(data) {
    //         let dataLoad: any = data?.data;
    //         // console.log(">>> dataLoad",dataLoad)
    //         setDataList(dataLoad)

    //     },}
    // });

    // console.log(">>> tableProps",tableProps)
    // console.log('dataonFilter', dataonFilter)
    // console.log('dataList', dataList)

    // if(dataList.length > 0){
    //     // const flattenedArray = dataList[0].concat(dataList[1]);
    //     const flattenedArray = dataList.reduce((accumulator:any, currentArray:any) => {
    //         return accumulator.concat(currentArray);
    //     }, []);

    //     dataonFilter = flattenedArray
    //     console.log('flattenedArray', flattenedArray)
    // }

    const getDataK = async ()=>{
        // http://192.168.100.129:1337/api/home-check-up-settings/find_setting_by_id?property=2&type=Notification&get_all_setting=false&setting_id=10
        // let res_data = await axios.get(API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=Notification&get_all_setting=false&setting_id=${setting_id}`, { headers: { Authorization: AuthStr } });
        let res_data = await axios.get(
                majorCareUrl + `/majorcare/getItemByType?tid=64813acf678934ea3e0edff1`,  // 64813acf678934ea3e0edff1 = zone
                { headers: {  } }).then((res) => {
                    let dataLoad: any = res?.data;

                    // for (let index = 0; index < dataLoad?.length; index++) {
                    //     dataonFilter.forEach((obj:any) => {
                    //         obj._id = dataLoad[index]._id;
                    //         obj.item_name = dataLoad[index].item_name;
                    //         obj.item_desc = dataLoad[index].item_desc;
                    //         obj.item_type = dataLoad[index].item_type;
                    //         obj.item_type_detail = dataLoad[index].item_type_detail;
                    //         obj.is_active = dataLoad[index].is_active;
                    //     });
                    // }
                    // console.log('dataonFilter', dataonFilter)
                    // let xx = dataLoad.filter((e:any)=> e.is_active == true);
                    // for (let index = 0; index < xx.length; index++) {
                    //     dataList.push(xx[index]);
                    // }
                    // for (let index = 0; index < dataLoad.length; index++) {
                    //     dataList.push(dataLoad[index]);
                    // }
                    let newList = []
                    let xx = dataLoad.filter((e:any)=> e.is_active == true);
                    for (let index = 0; index < xx.length; index++) {
                        // dataList.push(xx[index]);
                        newList.push(xx[index]);
                    }
                    if (!!newList && newList !== null) {
                        setDataList(newList)
                    }else{
                        setDataList(xx)
                    }
                    
                    let newListInActive = []
                    let yy = dataLoad.filter((e:any)=> e.is_active == false);
                    for (let index = 0; index < yy.length; index++) {
                        // dataListInActive.push(yy[index]);
                        newListInActive.push(yy[index]);
                    }
                    if (!!newListInActive && newListInActive !== null) {
                        setDataListInActive(newListInActive)
                    }else{
                        setDataListInActive(yy)
                    }
                    
                });
    }
    // console.log('dataList', dataList)

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // console.log('call')
                    getDataK();
                }
                // console.log('res_data', res_data);
                // console.log('property', Property);
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    async function updateStatus(id:any, val:any){
        // http://localhost:10000/api/majorcare/updateItemList?id=6482bc0c3d60f8d6c23e30df

        console.log('id', id);
        let body = {
            "is_active": val
        }
        // let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + id, body, { headers: { } });
        let res = await axios.put(majorCareUrl + "/majorcare/updateItemList?id=" + id, body, { headers: { } });
        // console.log('first') 

        // setCountInterval(0)
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
        setDataListInActive([])
    }
    const refreshDataSource = () => {
        // console.log('kkkkkkkkk')
        clearDataSource()
        setCountInterval(0)

        // getDataK();
        setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns: ColumnsType<any> = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.home-checkup.zone.name-th")}</span>,
            dataIndex: 'item_name',
            key: 'item_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 250,
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            sorter: (a:any, b:any) => a.item_name.localeCompare(b.item_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record ? record.item_name !== null ? record.item_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.home-checkup.zone.name-en")}</span>,
            // dataIndex: 'name',
            key: 'name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 250,
            sorter: (a:any, b:any) => a.item_desc.localeCompare(b.item_desc),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        {
                            record?.item_name_en ?
                            <div>
                                {record?.item_name_en}
                            </div>
                            :
                            <div>
                                <p>-</p>
                            </div>
                        }
                        {/* <div>{record ? record.item_desc !== null ? record.item_desc : '-': '-'}</div> */}
                        {/* <div>{record ? record.item_name_en !== null ? record.item_name_en : '-': '-'}</div> */}
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.home-checkup.zone.description")}</span>,
            // dataIndex: 'name',
            key: 'name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 250,
            sorter: (a:any, b:any) => {
                if (a?.item_desc < b?.item_desc) {return -1}
                if (a?.item_desc > b?.item_desc) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        {
                            record?.item_desc ?
                            <div>
                                {record?.item_desc}
                            </div>
                            :
                            <div>
                                <p>-</p>
                            </div>
                        }
                        {/* <div>{record ? record.item_desc !== null ? record.item_desc : '-': '-'}</div> */}
                        {/* <div>{record ? record.item_name_en !== null ? record.item_name_en : '-': '-'}</div> */}
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.home-checkup.zone.create-by")}</span>,
            // dataIndex: 'createBy',
            key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.create_by?.first_name < b?.create_by?.first_name) {return -1}
                if (a?.create_by?.first_name > b?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div >{record ? record?.create_by?.first_name : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : "-"}</div>
                        {/* <div>-</div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.home-checkup.zone.created-date")}</span>,
            // dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            width: 150,
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.home-checkup.zone.update-by")}</span>,
            // dataIndex: 'updateBy',
            key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.update_by?.first_name < b?.update_by?.first_name) {return -1}
                if (a?.update_by?.first_name > b?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : "-"}</div>
                        {/* <div >{value ? value.first_name : "-"} {value !== null ? value.last_name : ""}</div> */}
                        {/* <div>-</div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.home-checkup.zone.last-update")}</span>,
            // dataIndex: 'update_date',
            key: 'update_date',
            align: 'center',
            width: 150,
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 120,
            fixed: 'right',
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {/* <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            onClick={() => edit("mjc-zone", record._id + "_" + record.item_name )}
                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                        /> */}
                        {/* <CreateButton id="create_btn" onClick={()=>edit("notification", )} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }></CreateButton> */}

                        {
                            record.is_active === true ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        // onClick={()=> editModalShow(record.id)}
                                        onClick={() => edit("mjc-zone", record._id + "_" + record.item_name )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        // disabled={false }
                                        style={{marginRight:'2px'}}
                                    />
                                    <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record._id) }} danger></Button>
                                </>
                            :
                                <>
                                    <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record._id) }} ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    // console.log('filelist', FileList)
    const { selectProps: selDocType } = useSelect<any>({
        resource    : "document-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
      
    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    function onStatusChange(v:any){
        console.log('val',v)
        if(v == "true"){
            SetTabKey(true)
        }else{
            SetTabKey(false)
        }
        // form?.submit();
        // localStorage.setItem("keyU",v)
        // localStorage.setItem("keyTabUserPortal", v)
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const { formProps } = useForm<any,HttpError,{}>()
    const [keepName, setKeepName] = useState<any>()
    const [keepTH, setKeepTH] = useState<any>('TH')
    //const [keepDes, setKeepDes] = useState<any>()
    const searchData = () => {
        console.log("keepName",keepName)
        console.log("keepTH",keepTH)
        let filter:any = []
        // if (TabKey == true && !!keepDes) {
        //     let newData:any = dataList
        //     filter = newData.filter((e:any) => e?.item_desc.toUpperCase().includes(keepDes.toUpperCase()))
        //     setDataList(filter)
        //     //console.log("filter1",filter)
        // }
        if (TabKey == true && !!keepName && !!keepTH) {
            let newData:any = dataList
            filter = keepTH == 'TH' ? newData.filter((e:any) => e?.item_name?.toLowerCase().includes(keepName?.toLowerCase())) :
            newData.filter((e:any) => !!e?.item_name_en ? e?.item_name_en?.toLowerCase().includes(keepName?.toLowerCase()) : null)
            setDataList(filter)
            //console.log("filter1",filter)
        }else if (TabKey == false && !!keepName) {
            let newData:any = dataListInActive
            filter = keepTH == 'TH' ? newData.filter((e:any) => e?.item_name?.toLowerCase().includes(keepName?.toLowerCase())) :
            newData.filter((e:any) => !!e?.item_name_en ? e?.item_name_en?.toLowerCase().includes(keepName?.toLowerCase()) : null)
            setDataListInActive(filter)
            //console.log("filter1",filter)
        }
    }

    const onReset = () => {
        //setKeepName("")
        refreshDataSource();
    }

    return <>
        <List 
            // title={t("mjcnotimsg.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjcnotimsg.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={22}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"1%"}}
                    >
                        <Form.Item name="Zone name">
                            <Space className="space_on_res">
                                <Select 
                                    defaultValue="TH" 
                                    options={[
                                        {label: 'TH', value: 'TH'},
                                        {label: 'EN', value: 'EN'},
                                    ]}
                                    onChange={(e) => setKeepTH(e)}
                                />
                                <Input
                                    onChange={(e) => {setKeepName(e.target.value)}}
                                    style={{marginLeft: '-15px'}}
                                    placeholder="Search name" 
                                    prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                />
                            </Space>
                        </Form.Item>
                        
                        {/* <Form.Item name="property">
                            <Input
                                onChange={(e) => setKeepDes(e.target.value)}
                                placeholder="Search Description" 
                                prefix={<Icons.SearchOutlined />} 
                                allowClear
                            />
                        </Form.Item> */}

                        {/* <Form.Item name="type">
                            <Select placeholder="Type" {...selDocType} allowClear style={{width: "220px"}}/>
                        </Form.Item> */}

                        <Form.Item >
                            <Button onClick={() => searchData()} htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button 
                                onClick={() => onReset()}
                                htmlType="submit" 
                                style={{borderRadius: "5px"}}
                                icon={<Icons.UndoOutlined />}
                            >
                                Reset
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
                <Col span={2}>
                    <Form
                        // {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                            {/* <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true } /> */}
                            {/* <Button onClick={()=> create("notification")} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }>Create</Button> */}
                            {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} /> */}
                            <CreateButton id="create_btn" onClick={()=>create("mjc-zone")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>

                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Form.Item name="deleteStatus" >
                <Tabs 
                    defaultActiveKey={"true"}
                    style={{ marginTop: 10, marginBottom: 10 }} 
                    onChange={onStatusChange} 
                >
                    <TabPane
                        key={"true"}
                        tab={
                            <span>
                                {"ACTIVE"}
                                <Badge count={dataList.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />

                    <TabPane
                        key={"false"}
                        tab={
                            <span>
                                {"TRASH"}
                                <Badge count={dataListInActive.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />
                </Tabs>
            </Form.Item>

            <div style={{marginTop:'-3%'}}>
                {
                    dataList.length > 0 ?
                        <Table 
                            // dataSource={dataList} 
                            dataSource={TabKey ? dataList : dataListInActive} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{x: 1300}}
                        />
                    :
                        <Table 
                            columns={columns} 
                            onChange={onChange} 
                            pagination={{ size: "small", position: [ bottom] }} 
                            scroll={{x: 1300}}
                        />
                }

            </div>
            
            {/* <Table columns={columns} dataSource={data} onChange={onChange} /> */}
        </List>
    </>
}