/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, List, Modal,  Row,  Select,  ShowButton,  Space, Table, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useNavigation, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IFacility, IFacilityType, IProperties } from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import dayjs from "dayjs";

export const FacilitiesList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { create, edit } = useNavigation();
    
    const { tableProps,searchFormProps,tableQueryResult } = useTable<IFacility,HttpError, IFacility>({
        metaData: {
            populate: '*'
        },  
    // });
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property, facility_type } = params;
                filters.push(
                    {
                        field       : "property][id]",
                        // operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                        operator : "eq",
                        value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                    },
                    {
                        field       : "facility_type][name]",
                        operator    : "contains",
                        value       : facility_type
                    },
                );

            return filters;
        },
        permanentSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
        initialFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ]
        // permanentFilter:[
        //     {
        //         field : "property][id]",
        //         operator: data_property ? "eq" : "nin",
        //         value: data_property ? data_property : null
        //     }
        // ]
    });

    const categoryQueryResult = useOne<IFacility>({
        resource: "facilities",
        id: ''
    });
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IFacility>();
    const ed_point                          = "facilities";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    // const { triggerExport, isLoading: exportLoading } = useExport<IFacility>({
    //     mapData: (item) => {
    //         //console.log(item)
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id, 
                        
    //             };
    //         }
    //     },
    // });
    
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, event:any){
        
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            // values      : vCurDelStatus,
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
            }
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'facilities',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }
    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
    });

    const { selectProps: facilityTypeSelectProps } = useSelect<IFacilityType>({
        resource: "facility-types",
        optionLabel: "name",
        optionValue: "name",
        // optionLabel: "projectNameTH",
        // filters: initF,
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       : 'N',
            },
        ]
    });
    
    
    // console.log(tableProps)

    // const resultUserTypeMap = useOne<IUserTypeMap>({
    //     resource: "user-type-mappings",
    //     id: ''
    // });

    // let k_data:any = [];
    // k_data = resultUserTypeMap.data?.data ?  resultUserTypeMap.data?.data : [];

    // console.log('k_data',k_data)

    return <>
            
            <List 
                title={t("fclt.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                           {/* <Form.Item name={["property", "property_name"]}>
                                <Input style={{borderRadius: 20,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item> */}
                            {/* <Form.Item name={["property", "property_name"]} >
                                <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear
                                />
                            </Form.Item> */}

                            <Form.Item name="property" >
                                <Select {...propertySelectProps} 
                                    placeholder="Select property" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '200px', position:'relative'}} 
                                    allowClear
                                />
                            </Form.Item>

                            <Form.Item name="facility_type">
                                <Select {...facilityTypeSelectProps}
                                    placeholder="Select facility type"
                                    size="middle"
                                    style={{borderRadius: 20, width: '200px', position:'relative'}}
                                    allowClear
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            {/* <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item> */}

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" 
                                    disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableQueryResult?.data?.total, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                >

                    {/* <Table.Column width={40} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column align="center" width={80} dataIndex="cover" title="  " 
                    render={(value:any,record:any)=>{
                        return <>
                                {
                                    value? 
                                        <Avatar size={32} src={value.url} />
                                    :<>
                                        <Avatar size={32} icon={<Icons.EnvironmentOutlined />} />
                                    </>
                                }
                            </>
                    }}
                    /> */}

                    <Table.Column title={t("fclt.Lproperty")} align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.property?.property_name < b?.property?.property_name) {return -1}
                            if (a?.property?.property_name > b?.property?.property_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record ? record?.property?.property_name : "-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={t("fclt.Ltype")} align="center" width={200} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.facility_type?.name < b?.facility_type?.name) {return -1}
                            if (a?.facility_type?.name > b?.facility_type?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                <TextField 
                                    value={
                                        record?.facility_type ? record.facility_type.name  : '-'
                                    
                                    }  />
                            </>
                        }}
                    />
                    
                    <Table.Column 
                        dataIndex="name" 
                        title={t("fclt.Lname")}
                        width={200}
                        align="center"
                        className="table_colmn_custom_left_value"
                        sorter={(a, b) => a.name.localeCompare(b.name)}
                        sortDirections={['ascend', 'descend']}

                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    <Table.Column title={t("fclt.booking-type-2")} width={200} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.private_room < b?.private_room) {return -1}
                            if (a?.private_room > b?.private_room) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    // record?.no_booking == true ?
                                    record?.booking == false && record?.private_room == false?
                                        <TextField value={"No Booking"}  />
                                    :
                                        record?.private_room == true ?
                                            <TextField value={"Private Room"}  />
                                        :
                                            record?.capacity > 0 ?
                                                <TextField value={"Capacity"}  />
                                            :
                                                <TextField value={"No Data"}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column dataIndex="checkin" title={t("fclt.Checkin")} width={200} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.checkin < b?.checkin) {return -1}
                            if (a?.checkin > b?.checkin) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.checkin == true ?
                                        <TextField value={"Yes"}  />
                                    :
                                        <TextField value={"No"}  />
                                }
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="description" title={t("fclt.Ldesc")}  width={500}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value} className="descCut"/>
                            </>
                        }}
                    /> */}

                    <Table.Column 
                        dataIndex="open_time" 
                        title={t("fclt.TOPN")}  
                        width={300} align="center"
                        
                        sorter={(a, b) => a.open_time.localeCompare(b.open_time)}
                        sortDirections={['ascend', 'descend']}

                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={dayjs(record?.open_time, "HH:mm:ss").format('HH:mm')}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="close_time" title={t("fclt.TCLS")}  width={300} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.close_time < b?.close_time) {return -1}
                            if (a?.close_time > b?.close_time) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={dayjs(record?.close_time, "HH:mm:ss").format('HH:mm')}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="updatedBy" title="UPDATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    /> */}

                    <Table.Column dataIndex="status" title={t("fclt.booking-type")}  width={200} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.status < b?.status) {return -1}
                            if (a?.status > b?.status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record ? record.status : '-'}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.createBy?.first_name.localeCompare(b?.createBy?.first_name)}
                            sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.createBy ? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />


                    <Table.Column title="CREATE DATE" align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.updateBy?.first_name.localeCompare(b?.updateBy?.first_name)}
                            sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />


                    <Table.Column title="LAST UPDATE" align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />
                  
                    <Table.Column<IFacilityType>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton>

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */}
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                // onClick={() => edit("repair-mdpc-inprogress-job-admin", record.id)}
                                            
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}