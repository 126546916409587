import { IResourceComponentsProps, useCustom, useList, useNavigation, useTranslate } from "@pankod/refine-core"
import axios from "axios";
import { petURL } from "../../../constants";
import { Button, Col, EditButton, Form, Icons, Row, Select, Spin, Table, Tag, useSelect } from "@pankod/refine-antd";
import { useState } from "react";
import dayjs from "dayjs";

// let dataTB: any = undefined;
let data_pet_type: any = undefined;
let optionpettype: any = undefined;
export const PetVaccAllList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { replace, list} = useNavigation();
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const [pageSize, setPageSize] = useState<any>(10);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [dataTB, setdataTB] = useState<any>([]);
    const [valuepetType, setpetType] = useState<any>();
    const [dataOriginal, setdataOriginal] = useState<any>([]);
    const [selProp, setselProp] = useState<any>(undefined);
    const [Unit, setUnit] = useState<any>(undefined);

    const [formSearch] = Form.useForm();

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        // value: dataLoad[index]?._id
                        value: dataLoad[index]?.pet_type_name,
                        key: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }
            },
        },
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 10000,
        sort: [{ order: "asc", field: "id" }],
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "in",
                value       : selProp?.id
            },
        ]
    });

    const { data } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetVacList`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad", dataLoad)

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item?.pet_regis_obj[0]?.property.id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e?.pet_regis_obj[0]?.property.id == Number(prop_id))
                }
                setdataTB(dataLoad)
                setdataOriginal(dataLoad)
                // setLoading(false);
            },
        },
    });

    const { data: pet_type } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad pet_type", dataLoad)
                data_pet_type = dataLoad;

                setTimeout(() => {
                    setLoading(false);
                }, 200);

            },
        },
    });

    const columns:any = [
        {
            title: `${t("pet-vacc-list.properties")}`,
            dataIndex: 'property',
            key: 'property',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_regis_obj[0]?.property?.property_name < b?.pet_regis_obj[0]?.property?.property_name) {return -1}
                if (a?.pet_regis_obj[0]?.property?.property_name > b?.pet_regis_obj[0]?.property?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.pet_regis_obj ? record?.pet_regis_obj[0]?.property ? record?.pet_regis_obj[0]?.property?.property_name : '-' : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-vacc-list.unit")}`,
            dataIndex: 'unit',
            key: 'unit',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_regis_obj[0]?.unit?.unit_name < b?.pet_regis_obj[0]?.unit?.unit_name) {return -1}
                if (a?.pet_regis_obj[0]?.unit?.unit_name > b?.pet_regis_obj[0]?.unit?.unit_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <span>
                        {
                            record?.pet_regis_obj ? record?.pet_regis_obj[0]?.unit ? record?.pet_regis_obj[0]?.unit?.unit_name : '-' : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-vacc-list.owner")}`,
            dataIndex: 'owner',
            key: 'owner',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_regis_obj[0]?.owner_of_pet?.first_name < b?.pet_regis_obj[0]?.owner_of_pet?.first_name) {return -1}
                if (a?.pet_regis_obj[0]?.owner_of_pet?.first_name > b?.pet_regis_obj[0]?.owner_of_pet?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.pet_regis_obj ? record?.pet_regis_obj[0]?.owner_of_pet ? record?.pet_regis_obj[0]?.owner_of_pet?.first_name + ' ' + record?.pet_regis_obj[0]?.owner_of_pet?.last_name : '-' : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-vacc-list.pet-type")}`,
            dataIndex: 'pet-type',
            key: 'pet-type',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_regis_obj[0]?.pet_type < b?.pet_regis_obj[0]?.pet_type) {return -1}
                if (a?.pet_regis_obj[0]?.pet_type > b?.pet_regis_obj[0]?.pet_type) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                let filteredData = data_pet_type?.filter((item:any) => item._id == record?.pet_regis_obj[0]?.pet_type);
                // console.log('filteredData', filteredData)
                return (
                    <span>{filteredData?.length > 0 ? filteredData[0]?.pet_type_name : "-"}</span>
                )
            },
        },
        {
            title: `${t("pet-vacc-list.name")}`,
            dataIndex: 'pet_name',
            key: 'pet_name',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_regis_obj[0]?.pet_name < b?.pet_regis_obj[0]?.pet_name) {return -1}
                if (a?.pet_regis_obj[0]?.pet_name > b?.pet_regis_obj[0]?.pet_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.pet_regis_obj ? record?.pet_regis_obj[0]?.pet_name : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-vaccine.age")}`,
            dataIndex: 'pet_age',
            key: 'pet_age',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_regis_obj[0]?.pet_age < b?.pet_regis_obj[0]?.pet_age) {return -1}
                if (a?.pet_regis_obj[0]?.pet_age > b?.pet_regis_obj[0]?.pet_age) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                const dob:any = new Date(record?.pet_regis_obj[0]?.pet_birthday);
                const today:any = new Date();
                
                // Calculate the difference in milliseconds between the two dates
                let ageDiff = today - dob;
                
                // Convert the age difference from milliseconds to years
                let ageDate = new Date(ageDiff);
                let years = ageDate.getUTCFullYear() - 1970;
                
                // Calculate the remaining months and days
                let months = ageDate.getUTCMonth();
                let days = ageDate.getUTCDate();
                return (
                    <span>
                        {
                            // record?.pet_regis_obj ? record?.pet_regis_obj[0]?.pet_age : '-'
                            record?.pet_regis_obj ?  
                                years > 0 ?
                                    `${years} years ${months} months ${days} days` 
                                        : years == 0 && months == 0 ? 
                                            `${days} days`
                                        : years == 0 && months > 0 ?  
                                            `${months} months ${days} days` 
                                    : '-'
                                : '-'
                        }
                    </span>
                )
            },
        },
        {
            // ข้อมูล vac ที่กรอกมาจากการ add เองหรือเอาในระบบ true == ในระบบ, false == add เอง
            title: `${t("pet-vacc-list.data-from")}`,
            dataIndex: 'pet_vacc_from',
            key: 'pet_vacc_from',
            // width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_vac_data_from < b?.pet_vac_data_from) {return -1}
                if (a?.pet_vac_data_from > b?.pet_vac_data_from) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.pet_vac_data_from ? "ในระบบ" : "ลูกค้าเพิ่มเอง"}</span>)
            },
        },
        {
            // true == core_vaccine, false == optional_vaccine
            title: `${t("pet-vacc-list.type-vacc")}`,
            dataIndex: 'vaccine-type',
            key: 'vaccine-type',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_vac_obj[0]?.vaccine_type < b?.pet_vac_obj[0]?.vaccine_type) {return -1}
                if (a?.pet_vac_obj[0]?.vaccine_type > b?.pet_vac_obj[0]?.vaccine_type) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (<span>
                    {
                        record?.pet_vaccine == null ? "OPTIONAL" 
                        : record?.pet_vac_obj.length > 0 ? 
                            record?.pet_vac_obj[0].vaccine_type ? "CORE" : "OPTIONAL" : 'OPTIONAL' 
                    }
                </span>)
            },
        },
        {
            title: `${t("pet-vacc-list.place")}`,
            dataIndex: 'eject-place',
            key: 'eject-place',
            // width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_vac_eject_place < b?.pet_vac_eject_place) {return -1}
                if (a?.pet_vac_eject_place > b?.pet_vac_eject_place) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.pet_vac_eject_place ? record?.pet_vac_eject_place : "-" }</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.date")}`,
            dataIndex: 'eject-date',
            key: 'eject-date',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.pet_vac_eject_date < b?.pet_vac_eject_date) {return -1}
                if (a?.pet_vac_eject_date > b?.pet_vac_eject_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                const expireDate = record?.pet_vac_eject_date && dayjs.tz(record?.pet_vac_eject_date, 'Asia/Bangkok').toISOString()

                // return (<span>{record?.pet_vac_eject_date ? dayjs(record?.pet_vac_eject_date).format("DD-MM-YYYY HH:mm") : "-" }</span>)
                return (<span>{record?.pet_vac_eject_date ? dayjs(expireDate).format("DD-MM-YYYY HH:mm") : "-" }</span>)
            },
        },
        {
             // '1' == ก่อน 1 วัน, '2' == ก่อน 1 วีค, '3' == ก่อน 1 เดือน
            title: `${t("pet-vacc-list.remind")}`,
            dataIndex: 'vaccine-type',
            key: 'vaccine-type',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.remind_in < b?.remind_in) {return -1}
                if (a?.remind_in > b?.remind_in) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.is_remind ? record?.remind_in ? record?.remind_in == "1" ? "แจ้งเตือนล่วงหน้า 1 วัน" : record?.remind_in == "2" ? "แจ้งเตือนล่วงหน้า 1 สัปดาห์" : record?.remind_in == "3" ? "แจ้งเตือนล่วงหน้า 1 เดือน" : '-' : '-' : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: 'Created By',
            dataIndex: '',
            key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.create_by?.user_name.localeCompare(b?.create_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.create_by ? record?.create_by?.user_name : '-'}</span>)
            },
        },
        {
            title: 'Created Date',
            dataIndex: '',
            key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.c < b?.create_date) {return -1}
                if (a?.create_date > b?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.create_date ? dayjs(record?.create_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: 'Update By',
            dataIndex: '',
            key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
            },
        },
        {
            title: 'Last Update',
            dataIndex: '',
            key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            // สถานะการฉัดวัคซีน true == ฉีดแล้ว, false == ยังไม่ฉีด
            title: `${t("pet-vacc-list.status")}`,
            dataIndex: 'eject-status',
            key: 'eject-status',
            width: 90,
            fixed: 'right',
            sorter: (a:any, b:any) => {
                if (a?.eject_status < b?.eject_status) {return -1}
                if (a?.eject_status > b?.eject_status) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {

                // <Tag className="tag_post"><TextField value={'Only Major'}  /></Tag>


                // return (<span>{record?.eject_status == true ? "ฉีดแล้ว" : "ยังไม่ได้ฉีด"}</span>)   
                return (<span>{record?.eject_status == true ? <Tag color="green">ฉีดแล้ว</Tag> :<Tag color="red">ยังไม่ได้ฉีด</Tag>}</span>)
            },
        },
        // {
        //     title: 'Update By',
        //     dataIndex: '',
        //     key: '',
        //     width: 140,
        //     render: (value:any, record:any) => {
        //         return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
        //     },
        // },
        // {
        //     title: 'Last Update',
        //     dataIndex: '',
        //     key: '',
        //     width: 140,
        //     render: (value:any, record:any) => {
        //         return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
        //     },
        // },
        // {
        //     title: 'ACTION',
        //     dataIndex: '',
        //     key: 'action',
        //     fixed: 'right',
        //     width: 90,
        //     align: 'center',
        //     render: (value:any, record:any) => {
        //         return (<EditButton hideText size="small" onClick={()=> replace("/pet-types/edit/" + record?._id)}/>);
        //     },
        // },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onsearchTB (data: any, mode: any){
        if(mode == "S"){
            // let newDT: any = data;
            let newDT: any = dataOriginal;

            if(selProp?.id){
                newDT = newDT?.filter((item:any)=> item?.pet_regis_obj[0]?.property?.id == selProp?.id);
            }

            if(valuepetType){
                // newDT = newDT?.filter((f: any)=> f?.pet_regis_obj[0]?.pet_type == String(valuepetType))
                newDT = newDT?.filter((f: any)=> f?.pet_regis_obj[0]?.pet_type == String(valuepetType?.key));
            }

            if(Unit){
                newDT = newDT?.filter((f: any)=> f?.pet_regis_obj[0]?.unit?.id == Unit?.id)
            }

            setdataTB(newDT)

            // if(valuepetType){
            //     let dtFilter: any = newDT?.filter((f: any)=> f?.pet_regis_obj[0]?.pet_type == String(valuepetType));
            //     setdataTB(dtFilter)
            // }else{ //กรณีกดลบ select แล้วกดปุ่ม search แทนปุ่ม reset
            //     setdataTB(data)
            // }
        }else if(mode == "R"){
            let newDT: any = data;
            setdataTB(newDT);
            setpetType(undefined);
            setselProp(undefined);
            setUnit(undefined);
            formSearch?.resetFields();
        }
    }

    return (
        <>
            <div className="layout_panel">
                <Spin size="large" spinning={isLoading}>
                    <Form 
                        form={formSearch}
                        layout="vertical"
                        style={{marginBottom: "1rem"}}
                    >
                        <div className="layout_custom_search">
                            <Form.Item
                                name="property"
                                label=""
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select 
                                    placeholder={t("pet-vacc-list.select-properties")} 
                                    {...selProperty} allowClear 
                                    onChange={(value:any, record:any) => { 
                                        console.log('record sel prop', record)
                                        setselProp({id:value, label: record?.label})
                                    }}
                                    style={{width: "200px", marginRight: "15px"}}
                                /> 
                            </Form.Item>

                            <Form.Item
                                label=" "
                                name="unit"
                                style={{marginBottom: 0, display: "inline-block"}}
                                className="visible-label"
                            >
                                <Select {...SelUnit}
                                    placeholder={t("pet-vacc-list.select-unit")} 
                                    size="middle" 
                                    onChange={(value:any, record:any) => { 
                                        setUnit({id:value, label: record?.label})
                                    }}
                                    onClear={()=> {
                                        setUnit(undefined);
                                        formSearch.resetFields(["unit"]);
                                    }}
                                    allowClear
                                    showSearch
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Form.Item 
                                label=""
                                name="pet-type"
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select
                                    options={optionpettype}
                                    // placeholder={t("select.sel")}
                                    placeholder={t("pet-vacc-list.pet-type")} 
                                    labelInValue
                                    showSearch
                                    allowClear
                                    onChange={(e: any)=> setpetType(e)}
                                    onClear={()=> setpetType(undefined)}
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Button 
                                icon={<Icons.SearchOutlined />} 
                                style={{borderRadius: "5px", marginRight: "15px"}} 
                                type="primary" 
                                onClick={()=> {
                                    onsearchTB(dataTB, "S")
                                }}
                            >
                                {t("buttons.search")}
                            </Button>

                            <Button 
                                icon={<Icons.ReloadOutlined />} 
                                style={{borderRadius: "5px"}} 
                                onClick={()=> {
                                    onsearchTB(dataOriginal, "R")
                                }}
                            >
                                {t("buttons.reset")}
                            </Button>
                        </div>
                    </Form>
                    <Table 
                        dataSource={dataTB ? dataTB : []} 
                        columns={columns}
                        pagination={{
                            size: "small",
                            position: ['bottomRight'],
                            pageSizeOptions: ['10', '30', '50', '100'], 
                            showSizeChanger: true, 
                            pageSize: pageSize,
                            onChange: handlePageSizeChange,
                        }}
                        scroll={{ x: 2300 }}
                    />
                </Spin>
            </div>
        </>
    )
}