import { Breadcrumb, Col, Create, Form, Input, InputNumber, List, Radio, RcFile, Row, Select, Upload, message, notification, useForm } from "@pankod/refine-antd"
import { HttpError, IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import axios from "axios";
import { TOKEN_KEY, petURL } from "../../../constants";
import { useEffect, useState } from "react";

let optionpettype: any = undefined;
export const PetvcCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { TextArea } = Input;
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();
    const { formProps, saveButtonProps } = useForm<any,HttpError,{}>();

    const [valuepetType, setpetType] = useState<any>();
    const [vacinject, setvacinject] = useState<any>(0);
    const [injectRound, setInjectRound] = useState<boolean>(true);
    const [keepMin, setKeepMin] = useState<any>(0)
    const [keepMax, setKeepMax] = useState<any>(0)

    const { data: datapettype } = useCustom<any>({
        // url: `${petURL}/api/lscpet/findAllPetType`,
        url: `${petURL}/api/lscpet/findActivePetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }
            },
        },
    });

    async function submitDT(values:any){
        let body: any = {
            "pet_type": String(valuepetType),
            "vaccine_name": values?.vaccine_name,
            "vaccine_name_en": values?.vaccine_name_en,
            "vaccine_type": values?.vaccine_type,
            "age_type": values?.age_type,
            "age_from": values?.age_from,
            "age_to": values?.age_to,
            "vac_inject_round": values?.vac_inject_round,
            "vac_inject_yearly": String(vacinject),
            "recommend_th": values?.recommend_th,
            "recommend_en": values?.recommend_en,
            "create_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
        }
        
        let submit = await axios.post(petURL + "/api/lscpet/createPetVaccine", body, { headers: { } })
        .then(response => {
            successNoti('Create is Success');
            replace("/pets/pet-masters")
        })
        .catch(error => {
            console.error('error ',error);
            faildNoti('Create is Faild');
        });
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    useEffect(() => {
      console.log("keepMax",keepMax)
      console.log("keepMin",keepMin)
    },)

    return (
        <Create saveButtonProps={keepMax < keepMin ? { disabled:true} : saveButtonProps} pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/pet-masters")}}}>
            <List 
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-vaccine.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-vaccine.title-create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />

            <div style={{width: "50%"}}>
                <Form 
                    {...formProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        submitDT(values);
                    }}
                >   
                    <Row gutter={[24, 16]}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item 
                                label={t("pet-type.title")}
                                name="pet_type"
                                rules={[
                                    {
                                        required: true, message: 'please select pet type.'
                                    },
                                ]}
                            >
                                <Select
                                    options={optionpettype}
                                    showSearch
                                    allowClear
                                    onChange={(e: any)=> setpetType(e)}
                                    onClear={()=> setpetType(undefined)}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}/>
                        <Col className="gutter-row" span={12}>
                            <Form.Item 
                                label={t("pet-vaccine.type")}
                                name="vaccine_type"
                                // className="disable_req"
                                rules={[
                                    {
                                        required: true, message: 'please select charge'
                                    },
                                ]}
                                initialValue={true}
                            >
                                <Radio.Group name="radiogroup" defaultValue={true}>
                                    <Radio value={true}>{t("pet-vaccine.vaccine")}</Radio>
                                    <Radio value={false}>{t("pet-vaccine.vaccine-op")}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={12}/>
                        <Col className="gutter-row" span={12}>
                            <Form.Item 
                                label={t("pet-vaccine.name-th")}
                                name="vaccine_name"
                                rules={[
                                    {
                                        required: true, message: 'please input vaccine name th.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>






                            <Form.Item 
                                label={t("pet-vaccine.number-of-injections")}
                                name="vac_inject_round"
                                // className="disable_req"
                                rules={[
                                    {
                                        required: true, message: 'please select vac inject round'
                                    },
                                ]}
                                initialValue={true}
                                style={{marginBottom: "80px"}}
                            >
                                <Radio.Group name="radiogroup" defaultValue={true} onChange={(e: any) => setInjectRound(e?.target?.value)}>
                                    <Radio value={true}>{t("pet-vaccine.once")}</Radio>
                                    <Radio value={false}>
                                        <span>{t("pet-vaccine.every")}</span>
                                        <Input 
                                            type="number" 
                                            min={0} 
                                            defaultValue={0} 
                                            style={{width: "80px", margin: "0 10px"}}
                                            onChange={(e: any) => setvacinject(e?.target?.value)}
                                        />
                                        <span>ปี</span>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>

                           


                            <Form.Item  label={t("pet-vaccine.guidelines-th")} name="recommend_th">
                                <TextArea style={{height: "60px", maxHeight: "80px"}}/>
                            </Form.Item>
                        </Col>


                        <Col className="gutter-row" span={12}>
                            <Form.Item 
                                label={t("pet-vaccine.name-en")}
                                name="vaccine_name_en"
                                rules={[
                                    {
                                        required: true, message: 'please input vaccine name en.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>


                            {
                                injectRound == true && <span>
                                    <Form.Item 
                                        label={t("pet-vaccine.age")}
                                        name="age_type"
                                        // className="disable_req"
                                        rules={[
                                            {
                                                required: true, message: 'please select age type'
                                            },
                                        ]}
                                        initialValue={true}
                                    >
                                        <Radio.Group name="radiogroup" defaultValue={true}>
                                            <Radio value={true}>{t("pet-vaccine.week")}</Radio>
                                            <Radio value={false}>{t("pet-vaccine.year")}</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Row gutter={6}>
                                        <Col className="gutter-row" span={4}>
                                            <div style={{marginTop: "7px"}}>
                                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-vaccine.specify")}</span>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item 
                                                className="disable_req visible-label"
                                                name="age_from"
                                                rules={[
                                                    {
                                                        required: true, message: 'please input age form'
                                                    },
                                                ]}
                                            >
                                                {/* <Input type="number" min={0} defaultValue={0}/> */}
                                                <InputNumber type="number" min={0} defaultValue={0} onChange={(e) => setKeepMin(e)} style={{width:'100%', border: keepMax < keepMin ? '1px solid red' : '1px solid'}}/>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={4}>
                                            <div style={{marginTop: "7px", textAlign: "center"}}>
                                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-vaccine.up-to")}</span>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item 
                                                className="disable_req visible-label"
                                                name="age_to"
                                                rules={[
                                                    {
                                                        required: true, message: 'please input age to'
                                                    },
                                                ]}
                                            >
                                                {/* <Input type="number" min={0} defaultValue={0}/> */}
                                                <InputNumber type="number" min={0} defaultValue={0} onChange={(e) => setKeepMax(e)} style={{width:'100%', border: keepMax < keepMin ? '1px solid red' : '1px solid'}}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </span>
                            }
                           

                            <Form.Item  label={t("pet-vaccine.guidelines-en")} name="recommend_en">
                                <TextArea style={{height: "60px", maxHeight: "80px"}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Create>
    )
}