/* eslint-disable */
import { DownloadOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Form, Icons, ImportButton, Input, List, Modal, Row, Select, Table, TextField, notification, useImport, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { IEventlog, IProperties, IUnit } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";

let unitdata: any = undefined;
export const ReceiptList: React.FC<IResourceComponentsProps> = () => {

    const [PageSize, setPageSize] = useState<any>()

    const {data: Identity_data} = useGetIdentity<any>();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [keepProperty, setKeepProperty] = useState<any>()

    useEffect(() => {
      console.log("PageSize",PageSize)
    },)
    

    const t = useTranslate();
    const { tableProps, searchFormProps} = useTable<any,HttpError, any>({
        metaData: {
            populate: '*'
        },
        // queryOptions:{},
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { unit, customer_name } = params; 
            filters.push(
                // {
                //     field: "q",
                //     operator: "eq",
                //     value: q,
                // },
                {
                    field       : "unit_no",
                    operator    : "eq",
                    value       : unit
                },
                {
                    field : "customer_name",
                    operator: "contains",
                    value: customer_name
                }
            );
            return filters;
        },
        initialPageSize: 10000,
        permanentSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        queryOptions:{
            onSuccess(data){
                let dataLoad = data?.data;
                console.log('dataLoad', dataLoad)
                // setdataCus(dataLoad);
                setPageSize(dataLoad?.length)
            },
            refetchOnWindowFocus:true,
            refetchInterval: 2000,
        },
        
    });
    const { mutate: createReceipt } = useCreate<any>();

    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    const parcelListData = useList<IUnit>({
        resource: "units",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "property][id]",
                    operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
                }
            ],
            pagination: { current: 1, pageSize: 100 }
        },
        queryOptions: {
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.unit_name,
                            "value": dataLoad[i]?.unit_name
                        }
                    )
                }

                if(newData?.length > 0){
                    unitdata = newData;
                }
            }
        }
    })

    // const importProps = useImport<any>({
    //     mapData: async (item) => {
    //         // console.log('data in csv : ', item)

    //         // let coupon_name:any;
    //         // let coupon_code:any;
    //         // let coupon_active:any;

    //         // if(item.coupon_active !== ""){
    //         //     // 1 = true, 2 = false
    //         //     coupon_active = item.coupon_active.split("-");
    //         //     // console.log('coupon_active', coupon_active[0])
    //         // }

    //         // if(item.coupon_name === ""){
    //         //     console.log('coupon_name data is empty')
    //         // }else if(item.coupon_code === ""){
    //         //     console.log('coupon_code data is empty')
    //         // }else{
    //         //     // coupon_data.push
    //         //     coupon_data.push(
    //         //         {
    //         //             coupon_name : item.coupon_name,
    //         //             coupon_code : item.coupon_code,
    //         //             coupon_active : coupon_active[0] == "1" ? true : false,
    //         //             coupon_group : null
    //         //         }
    //         //     );
    //         //     setArrCoupon(coupon_data)
    //         //     vArrCoupon.push(vArrCoupon)
    //         //     // setArrCoupon(coupon_data)
    //         // }
    //         // await sleep(2000);
    //     },
    //     onProgress(onProgressParams) {
    //         // setTotal(onProgressParams.totalAmount)
    //         // form.setFieldsValue(
    //         //     {
    //         //         quantity: onProgressParams.totalAmount
    //         //     }
    //         // )
    //     },
    //     onFinish(results) {
    //         // console.log('coupon_data', coupon_data)
    //         // console.log('results', results)
    //         // setStatUpload(true)
    //         // form.setFieldsValue(
    //         //     {
    //         //         quantity: vTotal
    //         //     }
    //         // )
    //     },
    //     resourceName: 'coupons'
    // });
    // const [vArrReceipt, setArrReceipt] = useState<any>([]);
    // let dataReceipt:any = []

    // const importProps = useImport<IReceipt>({
    //     resourceName: "receipts",
    //     batchSize: 1000,
    //     mapData: (item) => {
            
    //         // let dateStr = "18/1/2023 00:00";
    //         let dateStr = item.receipt_date;
    //         let dateObj = new Date(dateStr.replace(/(\d{2})\/(\d{1})\/(\d{4})/, "$3-$2-$1"));
    //         let isoDateStr = dateObj.toISOString();

    //         // let dateStr2 = item.create_date;
    //         // let dateObj2 = new Date(dateStr2.replace(/(\d{2})\/(\d{1})\/(\d{4})/, "$3-$2-$1"));
    //         // let isoDateStr2 = dateObj2.toISOString();

    //         return {
    //             receipt_date    : isoDateStr,
    //             // receipt_date    : dayjs(item.receipt_date).format('YYYY-MM-DDTHH:mm:ss'),
    //             receipt_no      : item.receipt_no,
    //             unit_no         : item.unit_no,
    //             unit_address    : item.unit_address,
    //             customer_name   : item.customer_name,
    //             inv_ref_no      : item.inv_ref_no,
    //             expense_type_code   : item.expense_type_code,
    //             description     : item.description,
    //             amount          : item.amount,
    //             payment_type    : item.payment_type,
    //             bank            : item.bank,
    //             // create_date     : item.create_date,
    //             // create_date     : item.create_date !== "" ? isoDateStr2 : null,
    //             create_by       : item.create_by,
    //         };

    //     },
    //     onFinish(results) {
    //         results.succeeded.forEach((item) => {
    //             console.log(item);
    //         });
    
    //     },
    // });

    const importProps = useImport<any>({
        batchSize: 1000,
        resourceName: '',
        mapData: async (item) => {
            console.log('item', item)

            if(item.receipt_no === ""){
                console.log('receipt_no is empty')
            }else if(item.unit_no === ""){
                console.log('unit_no is empty')
            }else{
                let dateStr = item.receipt_date;
                let dateObj = new Date(dateStr.replace(/(\d{2})\/(\d{1})\/(\d{4})/, "$3-$2-$1"));
                let isoDateStr = dateObj.toISOString();

                createReceipt({
                    resource : 'like-systems/createReceipt',
                    values : {
                        receipt_date    : isoDateStr,
                        receipt_no      : item.receipt_no,
                        // unit_no         : item.unit_no,
                        // unit_address    : item.unit_address,

                        unit_no         : item.unit_address,
                        unit_address    : item.unit_no,

                        customer_name   : item.customer_name,
                        inv_ref_no      : item.inv_ref_no,
                        expense_type_code   : item.expense_type_code,
                        description     : item.description,
                        amount          : item.amount,
                        payment_type    : item.payment_type,
                        bank            : item.bank,
                        create_by       : item.create_by,
                    },successNotification:false,errorNotification:false,
                },{
                    onSuccess: async(data: any, link: any) =>{
                        console.log('data onSuccess', data)
                    },
                    onError(error, variables, context) {
                        console.log('error', error)
                        // setDupStat(true)
                    },
                }
                )
            }
        },
        onProgress(onProgressParams) {
            // console.log('onProgressParams', onProgressParams)
            // setTotal(onProgressParams.totalAmount);
        },
        async onFinish(results) {
            successNotiImport(200, "Data Imported!")
            await sleep(2000);
            // tableProps
            setCount1(0)
            // window.location.reload()
            
        },
        
    });
    // console.log('vArrReceipt', vArrReceipt)
    const [count1, setCount1] = useState(3)

    // useEffect(() => {
    //     if (count1 < 3) {
    //         const interval = setInterval(() => {
    //             setCount1(prev => prev + 1);
    //         }, 1000)

    //         return () => clearInterval(interval)
    //     }

    // }, [count1])

    const successNotiImport = (stat:any, msg:any) => {
        notification.success({
            message: 'Success',
            description: msg,
            duration: 2,
        });
    };

    const { confirm } = Modal;
    const { mutate } = useUpdate<any>();
    const ed_point = "receipts";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = ['10','100','200', '500', '1000'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    // const { triggerExport, isLoading: exportLoading } = useExport<IPhoneBooks>({
    //     mapData: (item) => {
    //         //console.log(item)
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id,
    //             };
    //         }
    //     },
    // });

    let initF : any;
    initF = [{
            field       : 'deleteStatus',
            operator    : 'contains',
            value       :  'N',
        },
    ];
    
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // function updateStatus(id:any, event:any){
    //     // console.log('status : ', vCurDelStatus)
    //     mutate({
    //         resource    : ed_point,
    //         id          : id+"",
    //         values      : vCurDelStatus,
    //     });

    //     CreateLogDelete({
    //         resource    : log_endpoint,
    //         values      : {
    //             user_profile    : user_data.user_profile.id,
    //             log_time_stamp  : Date.now(), 
    //             log_event       : event,
    //             menu_event      : ed_point,
    //             SysPkID         : id+""
    //         }
    //     });
    // }

    // function showDelConfirm(id:any) {
    //     confirm({
    //         // title   : 'Deactivate Confirm ?',
    //         title   : 'Inactive Confirm ?', 
    //         icon    : <WarningOutlined />,
    //         content : 'Click Confirm to deactivate this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             vCurDelStatus.deleteStatus = 'Y'
    //             updateStatus(id, "Inactive");
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }

    // function showUnlockConfirm(id:any) {
    //     confirm({
    //         title   : 'Activate Confirm ?',
    //         icon    : <QuestionCircleOutlined />,
    //         content : 'Click Confirm to activate this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             vCurDelStatus.deleteStatus = 'N'
    //             updateStatus(id, "Active");
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data;
            if(dataSet?.length == 1){
                searchFormProps?.form?.setFieldsValue({
                    property: dataSet[0]?.id
                })
            }
        },}
    });

    return <>
            <List 
                title={t("receipt-list.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("billing.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("receipt-list.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            {/* <Form.Item name="property" >
                                <Select {...propertySelectProps} onChange={(e:any) => setKeepProperty(e)} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear/>
                            </Form.Item> */}
                            <Form.Item name="unit" >
                                <Select 
                                // {...propertySelectProps} 
                                    options={unitdata}
                                    placeholder="Select unit" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '250px', position:'relative'}}
                                    allowClear
                                    showSearch
                                    filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                />
                            </Form.Item>

                            <Form.Item name="customer_name" >
                                <Input
                                    placeholder="Search customer name" 
                                    prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            {/* <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" />
                            </Form.Item> */}
                            <Form.Item>
                                <Button style={{borderRadius:5}} 
                                    icon={<DownloadOutlined />}
                                >
                                    <a style={{color:'#595959'}} href="https://lscmain.s3.ap-southeast-1.amazonaws.com/receipt_template_4949ab5a37.xlsx?updated_at=2023-02-23T10:04:50.615Z"> Download Template</a>
                                </Button> 
                            </Form.Item>
                            
                            <Form.Item>
                                <ImportButton {...importProps} />
                                {/* <Button {...importProps} style={{borderRadius:5}} icon={<ImportOutlined />}><a> Import</a></Button> */}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps}
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{
                        defaultPageSize:10,
                        showSizeChanger: true,
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState,
                        onShowSizeChange,
                        total:PageSize,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column dataIndex="property" title="PROPERTY" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.property? record?.property.property_name :"-"}  />
                            </>
                        }}
                    /> */}

                    <Table.Column dataIndex="receipt_date" title="RECEIPT DATE" 
                        sorter={(a:any, b:any) => {
                            if (a?.receipt_date < b?.receipt_date) {return -1}
                            if (a?.receipt_date > b?.receipt_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                {/* <TextField value={value? value :"-"}  /> */}
                                <TextField value={value? dayjs(value).format("YYYY-MM-DD HH:mm") :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="receipt_no" title="RECEIPT NO" 
                        sorter={(a:any, b:any) => {
                            if (a?.receipt_no < b?.receipt_no) {return -1}
                            if (a?.receipt_no > b?.receipt_no) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />

                            </>
                        }}
                    />

                    <Table.Column dataIndex="unit_no" title="UNIT NO" 
                        sorter={(a:any, b:any) => {
                            if (a?.unit_no < b?.unit_no) {return -1}
                            if (a?.unit_no > b?.unit_no) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="unit_address" title="UNIT ADDRESS" 
                        sorter={(a:any, b:any) => {
                            if (a?.unit_address < b?.unit_address) {return -1}
                            if (a?.unit_address > b?.unit_address) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="customer_name" title="CUSTOMER NAME" 
                        sorter={(a:any, b:any) => {
                            if (a?.customer_name < b?.customer_name) {return -1}
                            if (a?.customer_name > b?.customer_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="inv_ref_no" title="INVOICE NO" 
                        sorter={(a:any, b:any) => {
                            if (a?.inv_ref_no < b?.inv_ref_no) {return -1}
                            if (a?.inv_ref_no > b?.inv_ref_no) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="expense_type_code" title="EXPENSE TYPE" 
                        sorter={(a:any, b:any) => {
                            if (a?.expense_type_code < b?.expense_type_code) {return -1}
                            if (a?.expense_type_code > b?.expense_type_code) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="description" title="DESCRIPTION" 
                        sorter={(a:any, b:any) => {
                            if (a?.description < b?.description) {return -1}
                            if (a?.description > b?.description) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.property? record?.property.property_name :"-"}  /> */}
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="name" title="NAME" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />
                    <Table.Column dataIndex="phone" title="PHONE" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="phone_book_type" title="PHONE BOOK TYPE" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.phone_book_type? record?.phone_book_type.name :"-"}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    {/* <Table.Column<any>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        render={(_, record) => (
                            <Space>
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                ></ShowButton>
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger></Button>
                                        </>
                                    :
                                        <>
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    /> */}
                </Table>
            </List>
        </>
}