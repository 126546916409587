import { InfoCircleOutlined } from "@ant-design/icons";
import {
  AntdLayout,
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  Icons,
  Menu,
  Popover,
  Row,
  Space,
  Typography
} from "@pankod/refine-antd";
import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
} from "@pankod/refine-core";
import { List } from "antd";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";


const { DownOutlined, HomeOutlined, QuestionCircleOutlined, MenuOutlined } = Icons;
const { Text } = Typography;


export const Header: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();
  // const { data: user } = useGetIdentity();

  // --- Get User Profile //
  const userQuery = useGetIdentity(); // Get User Profile
  const { data: Identity_data } = useGetIdentity<any>();
  const { data: user_data, isLoading } = userQuery;

  // console.log('Identity_data', Identity_data?.prop_list)
  // let data_property = Identity_data?.property !== null ? Identity_data?.property.attributes.property_name : null
  let data_property = Identity_data?.property !== null ? Identity_data?.property.property_name : null
  //console.log('user_data', user_data)
  //console.log('data_property', data_property)
  //console.log('Identity_data', Identity_data)
  const currentLocale = locale();
  // console.log('currentLocale',currentLocale);

  localStorage.setItem("CURRENT_LOCALE", currentLocale ? currentLocale : 'th');

  const menu = (
    // <Menu selectedKeys={[currentLocale]}>
    <Menu selectedKeys={[currentLocale ? 'en' : 'th']}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (

        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
          }
        >
          {lang === "en" ? "English" : "Thai"}
        </Menu.Item>
      ))}
    </Menu>
  );


  // const [ vTimeSlot1, setTimeSlot1 ] = useState<any>("");

  // const content = (
  //   <>
  //     {
  //       Identity_data?.prop_list?.length > 1 ?
  //         Identity_data?.prop_list.map((item:any,key:any) => {
  //           return <>
  //               <Tag color="green" style={{ borderRadius: '10px', marginRight: '5px', marginBottom: '5px' }}>
  //                 {item.property_name}
  //               </Tag>
  //           </>
  //         })
  //       :
  //         <Tag color="green" style={{ borderRadius: '10px', marginRight: '5px', marginBottom: '5px' }}>
  //           {data_property ? data_property : 'ALL PROPERTIES'}
  //         </Tag>
  //     }
  //   </>
  // );

  const tooltipStyle = {
    // backgroundColor: 'transparent',
    color: 'white',
    border: '1px solid black',
    borderRadius: '4px',
    fontSize: '8px',
    width: '2000px',
  };

  let data_prop = [{
    "id": "xx",
    "property_name": "ALL PROPERTIES",
  }]

  let data_OneProperty = [{
    "id": Identity_data?.property?.id,
    "property_name": Identity_data?.property?.property_name,
  }]

  //console.log("data_OneProperty",data_OneProperty)

  const content = (
    <div style={{ maxHeight: '600px', maxWidth: '500px', width: '500px', overflowY: 'auto' }}>
      <List
        size="small"
        // bordered data_property
        itemLayout="horizontal"
        //dataSource={Identity_data?.prop_list ? Identity_data?.prop_list : data_prop}
        dataSource={Identity_data?.prop_list !== null ? Identity_data?.prop_list : data_OneProperty[0]?.id !== undefined ? data_OneProperty : data_prop}
        renderItem={(item: any) => (
          <List.Item key={item.id}>
            <List.Item.Meta
              key={item.id}
              // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
              avatar={<Avatar src={item.logo ? item?.logo?.url : "https://lscmain.s3.ap-southeast-1.amazonaws.com/info_prop_94684bb647.png?updated_at=2023-11-15T09:01:26.809Z"} />}
              title={item?.property_name}
            //title={item?.property_name}
            // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
            />
          </List.Item>
        )}
      />
    </div>
  );

  return (
    <AntdLayout.Header className={"header_custom"}>

      <Row align="middle">
        <Col>
          <Space size="middle">
            {/* {isMobile && <MenuOutlined style={{ color: "black", fontSize: "16px" }} />} */}


            {/* <Button type="link" style={{borderRadius: "0px",border: "0px solid #40495F"}}>
                <Space>{data_property !== null ? data_property : 'ALL PROPERTIES'}</Space>
              </Button> */}

            {/* <Tooltip title="List Items">
                <List
                  size="small"
                  bordered
                  dataSource={data}
                  renderItem={(item) => (
                    <List.Item>
                      <span>{item?.title}</span>
                    </List.Item>
                  )}
                />
              </Tooltip> */}

            <Dropdown overlay={menu}>
              <Button type="link" style={{ borderRadius: "10px", border: "1px solid #40495F", background: "#F2F3F5" }}>
                <Space>
                  <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
                  {currentLocale === "en" ? "English" : "Thai"}
                  <DownOutlined />
                 
                </Space>

              </Button>

            </Dropdown>

            {/* <Tooltip
                overlayStyle={tooltipStyle} // Adjust the width of the tooltip
                title={
                  <List
                    size="small"
                    bordered
                    itemLayout="horizontal"
                    dataSource={Identity_data?.prop_list}
                    renderItem={(item:any) => (
                      <List.Item key={item.id}>
                        <List.Item.Meta
                          key={item.id}
                          // avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                          avatar={<Avatar src={item.logo ? item?.logo?.url : "https://joeschmoe.io/api/v1/random"} />}
                          title={item?.property_name}
                          // description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                        />
                      </List.Item>
                    )}
                  />
                }
              >
                <span><InfoCircleOutlined style={{display: "block", color: '#0c59a6', fontSize: '30px'  }}/></span>
              </Tooltip> */}

<Popover content={content} title="Property">
                    <InfoCircleOutlined style={{ display: "block", color: '#0c59a6', fontSize: '30px' }} />
                  </Popover> 

            {/* <Popover content={content}>
                <Avatar 
                  style={{
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    width: 40, 
                    height: 40,
                    display: "block", 
                    marginLeft: "auto", 
                    marginRight: "auto",
                    // marginBottom: "0%", 
                    // marginTop: "20%",
                    boxShadow: "rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;",
                    color:'limegreen'
                  }}
                  src={"https://lscmain.s3.ap-southeast-1.amazonaws.com/info_FILL_0_wght200_GRAD_25_opsz48_3893d2e318.png?updated_at=2023-08-17T09:08:07.280Z"}
                /> 
              </Popover> */}


            <Card.Meta style={{ textAlign: "center" }}
              // title={user_data?.user_profile.attributes? user_data?.user_profile.attributes.first_name+" "+ user_data?.user_profile.attributes.last_name:"-"}
              title={user_data?.user_profile ? user_data?.user_profile.first_name + " " + user_data?.user_profile.last_name : "-"}
            />
            <Avatar
              style={{ border: "1px solid rgba(255, 255, 255, 0.2)", width: 40, height: 40, display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: "10%", marginTop: "10%", boxShadow: "rgba(255, 255, 255, 0.2) 0px 7px 29px 0px;" }}
              // src={user_data?.user_profile.attributes.avatar.data === null ? "https://joeschmoe.io/api/v1/random" :user_data?.user_profile.attributes.avatar.data.attributes.url} 
              // src={user_data?.user_profile.avatar === null ? "https://api.multiavatar.com/Binx Bond.png" :user_data?.user_profile.avatar.url} 
              src={user_data?.user_profile ? user_data?.user_profile.avatar === null ? "https://robohash.org/avatar.png" : user_data?.user_profile.avatar.url : "https://robohash.org/avatar.png"}
            />
          </Space>
        </Col>
      </Row>
    </AntdLayout.Header>

    // <></>
  );
};
