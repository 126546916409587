/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
// import MDEditor from '@uiw/react-md-editor';
import { majorCareUrl } from "../../../constants";

let swLocal : boolean = true;
let dataonFilter: any = [];
export const RepairCategoryAndDetailList: React.FC<IResourceComponentsProps> = () => {
    const tabActive = localStorage.getItem("keyTabCateAndDetail");

    console.log('tabActive onLoad', tabActive)
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TextArea } = Input;
    const { Option } = Select;
    const API_URL = useApiUrl();
    const { Text } = Typography;
    // console.log('majorCareUrl', majorCareUrl)
    const { create, edit } = useNavigation();
    const { confirm } = Modal;
    const [TabKey, SetTabKey] = useState<any>("category");
    const [ count, setCount] = useState(0);

    const [form] = Form.useForm();

    // if(TabKey == "category"){
    //     tabActive = "category"
    // }

    console.log('TabKey', TabKey)

    const { TabPane } = Tabs;
    const [countInterval, setCountInterval] = useState(0);
    
    const [dataList, setDataList] = useState<any>([]);
    const [DataBlank, SetDataBlank] = useState<any>([]);
    const [dataListInActive, setDataListInActive] = useState<any>([]);
    const [dataListDetail, SetDataListDetail] = useState<any>([]);
    const [DataProperty, SetDataProperty] = useState<any>();
    
    const getDataK = async ()=>{
        SetTabKey(tabActive ? tabActive : "category")

        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairCategory`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('Data repairCategory', dataLoad)
                
                // let xx = dataLoad.filter((e:any)=> e.is_active == true);
                // for (let index = 0; index < xx.length; index++) {
                //     dataList.push(xx[index]);
                // }
                for (let index = 0; index < dataLoad.length; index++) {
                    dataList.push(dataLoad[index]);
                }

                // setDataList(dataList)

                // let yy = dataLoad.filter((e:any)=> e.is_active == false);
                // for (let index = 0; index < yy.length; index++) {
                //     dataListInActive.push(yy[index]);
                // }

            });

        let res_data_detail = await axios.get(
            majorCareUrl + `/majorcare/repairCategoryDetail`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad repairCategoryDetail', dataLoad)
                
                for (let index = 0; index < dataLoad.length; index++) {
                    dataListDetail.push(dataLoad[index]);
                    // console.log('kk')
                }
                // SetDataListDetail(dataListDetail)
            });

        let res = await axios.get(majorCareUrl + "/majorcare/findAllPropertyType" , { headers: { } });
        let Data = res?.data
        SetDataProperty(Data ? Data : null)
    }

    // console.log('TabKey >>>>>>> ', TabKey)
    // console.log('dataList',dataList)
    // console.log('dataListDetail',dataListDetail)

    // useEffect(() => {
    //     getDataK();
    //     setTimeout(() => {
    //         // getDataK();
    //         setCount(0)
    //     }, 1000);
    //     // console.log('TabKey', TabKey)
    // }, []);

    // const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
    //     resource: "pp-types",
    //     metaData: {
    //         populate: [
    //             '*',
    //         ],
    //     },
    //     pagination: {
    //         pageSize: 10,
    //     },
    //     queryOptions: {
    //         onSuccess: async (data:any) => {
    //             // console.log('data xxxx : ', data.data)
    //             // console.log('kkkkkk')
    //             // getDataK();
    //             // console.log('TabKey', TabKey)

    //             SetTabKey(tabActive ? tabActive : "category")

    //             getDataK();
    //         },
    //     },
    // });

    useEffect(() => {
        if (count < 2) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                // console.log('asd')
                // getPropCreated();
                if(count === 0){
                    // console.log('here')
                    getDataK();
                    // setCount(20)
                }
                // getDataK();

            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    // useEffect(() => {
    //     getDataK();
    // }, [count])

    async function updateStatus(id:any, val:any){
        // console.log('id', id);
        // console.log('TabKey', TabKey);
        let body = {
            "is_active": val
        }
        if(TabKey == "category"){
            let res = await axios.patch(majorCareUrl + "/majorcare/repairCategory/" + id, body, { headers: { } });
            // console.log('res category', res) 
            refreshDataSource()

        }else{
            let res = await axios.patch(majorCareUrl + "/majorcare/repairCategoryDetail/" + id, body, { headers: { } });
            // console.log('res detail', res)
            refreshDataSource()
        }
    }

    const clearDataSource = () => {
        setDataList([])
        SetDataListDetail([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        // setCountInterval(0)
        // refetch();
        // getDataK();
        setCount(0);
        // setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns_category: any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'หมวด (TH)',
            dataIndex: 'item_name_1',
            key: 'item_name_1',
            width: 450,
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            sorter: (a:any, b:any) => a.category_name.localeCompare(b.category_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record ? record.category_name !== null ? record.category_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: 'หมวด (EN)',
            // dataIndex: 'name',
            key: 'category_name_en_1',
            width: 450,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.category_name_en.localeCompare(b.category_name_en),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        {/* <div>{record ? record.item_desc !== null ? record.item_desc : '-': '-'}</div> */}
                        <div>{record ? record.category_name_en !== null ? record.category_name_en : '-': '-'}</div>

                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: 'CREATED BY',
            // dataIndex: 'createBy',
            key: 'create_by_1',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.create_by?.first_name.localeCompare(b.create_by?.first_name),
            render: (createBy:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div >{record ? record?.create_by ? record?.create_by?.first_name  : "-" : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : ""}</div>
                        {/* <div>-</div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CREATED DATE',
            // dataIndex: 'create_date',
            key: 'create_date_1',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'update_by_1',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.update_by?.first_name.localeCompare(b.update_by?.first_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record?.update_by?.first_name ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : ""}</div>
                        {/* <div >{value ? value.first_name : "-"} {value !== null ? value.last_name : ""}</div> */}
                        {/* <div>-</div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'LAST UPDATE',
            // dataIndex: 'update_date',
            key: 'update_date_1',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            // dataIndex: '',
            key: 'action_2',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                // console.log('record xxxxxxxx', record)
                return (
                    <>
                        {/* <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            onClick={() => edit("mjc-zone", record._id + "_" + record.item_name )}
                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                        /> */}
                        {/* <CreateButton id="create_btn" onClick={()=>edit("notification", )} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }></CreateButton> */}

                        {
                            record.is_active === true ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        // onClick={()=> editModalShow(record.id)}
                                        onClick={() => edit("repair-category", record._id + "_" + record.item_name )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        // disabled={false }
                                        style={{marginRight:'2px'}}
                                    />
                                    <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record._id) }} danger></Button>
                                </>
                            :
                                <>
                                    <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record._id) }} ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];
    const columns_detail: any = [
        {
            title: 'หมวด (TH)',
            // dataIndex: 'item_name',
            key: 'item_name',
            width: 400,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (aa:any, bb:any) => aa.category_detail_name.localeCompare(bb.category_detail_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.category_detail_name !== null ? record.category_detail_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'หมวด (EN)',
            // dataIndex: 'name',
            key: 'category_detail_name_en',
            width: 400,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (aa:any, bb:any) => aa?.category_detail_name_en?.localeCompare(bb?.category_detail_name_en),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.category_detail_name_en !== null ? record.category_detail_name_en : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY TYPE',
            // dataIndex: 'name',
            key: 'prop_type',
            width: 250,
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => a.item_desc.localeCompare(b.item_desc),
            render: (value:any, record:any) => {
                // console.log('record details', record)
                return (
                    <>
                        <div>
                            {                                
                                record.property_type ?
                                    record?.property_type.map((data:any,index:any) => {
                                        // console.log('data : ', data);
                                        return <>
                                            <Tag className="tag_post"><TextField value={data ? data.property_type_name : <></> }  /></Tag>
                                        </>
                                    })
                                :
                                    <>-</>
                            }
                        </div>
                    </>
                );
            },
        },
        {
            title: 'ระยะประกัน',
            // dataIndex: 'name',
            key: 'waranty',
            width: 80,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (aa:any, bb:any) => aa.warantee ? aa.warantee : "".localeCompare(bb.warantee ? bb.warantee : ""),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.warantee !== null ? record.warantee + " วัน" : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'CREATE BY',
            // dataIndex: 'createBy',
            key: 'createBy',
            sorter:(aa:any, bb:any) => aa.create_by ? aa.create_by?.first_name : "".localeCompare(bb.create_by ? bb.create_by?.first_name : ""),
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            render: (createBy:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div >{record ? record?.create_by ? record?.create_by?.first_name  : "-" : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : ""}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CREATED DATE',
            // dataIndex: 'create_date',
            key: 'create_date',
            width: 150,
            align: 'center',
            sorter:(aa:any, bb:any) => {
                if (aa.create_date < bb.create_date) {return -1}
                if (aa.create_date > bb.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'updateBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter:(aa:any, bb:any) => aa.update_by ? aa.update_by?.first_name : "".localeCompare(bb.update_by ? bb.update_by?.first_name : ""),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record?.update_by?.first_name ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'LAST UPDATE',
            // dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter:(aa:any, bb:any) => {
                if (aa.update_date < bb.update_date) {return -1}
                if (aa.update_date > bb.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            // dataIndex: '',
            key: 'action',
            width: 120,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.category_obj[0]?.is_active ?
                                record.is_active === true ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small"
                                            // onClick={()=> editModalShow(record.id)}
                                            onClick={() => edit("repair-category-detail", record._id + "_" + record.item_name )}
                                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            // disabled={false }
                                            style={{marginRight:'2px'}}
                                        />
                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record._id) }} danger></Button>
                                    </>
                                :
                                    <>
                                        <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record._id) }} ></Button>
                                    </>
                            :
                                <>
                                    <EditButton hideText size="small" style={{marginRight:'2px'}} disabled />
                                    <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} disabled danger></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
      
    // const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    function onStatusChange(v:any){
        console.log('val',v)
        if(v == "category"){
            SetTabKey("category")
            localStorage.setItem("keyTabCateAndDetail", v)

        }else{
            SetTabKey("detail")
            localStorage.setItem("keyTabCateAndDetail", v)
        }
    }

    const [selProp, setselProp] = useState<any>();
    const [nameSearch, setnameSearch] = useState<any>("");
    const [nameSearchEn, setnameSearchEn] = useState<any>("");

    // console.log('selProp',selProp?.id)
    console.log('dataList', dataList)
    console.log('dataListDetail', dataListDetail)

    function onSearchtable(data: any, mode:any, type:any){
        console.log('type', type)
        if(type == "category"){
            console.log('search cate')
            let newData:any = [];
            if(mode == "S"){
                newData = nameSearch !== "" ? dataList?.filter((e:any)=> e?.category_name?.toLowerCase().includes(String(nameSearch?.toLowerCase()))) : newData;
                newData = nameSearchEn !== "" ? dataList?.filter((e:any)=> e?.category_name_en?.toLowerCase().includes(String(nameSearchEn?.toLowerCase()))) : newData;
                // console.log('newData', newData)
                setDataList(newData);
            }else if(mode == "R"){
                form?.resetFields();
                setselProp(undefined);
                refreshDataSource();
                setnameSearch("");
                setnameSearchEn("");
            }
        }else{
            console.log('search detail')
            let newData:any = [];
            if(mode == "S"){
                newData = nameSearch !== "" ? dataListDetail?.filter((e:any)=> e?.category_detail_name?.toLowerCase().includes(String(nameSearch?.toLowerCase()))) : newData;
                newData = nameSearchEn !== "" ? dataListDetail?.filter((e:any)=> e?.category_detail_name_en?.toLowerCase().includes(String(nameSearchEn?.toLowerCase()))) : newData;
                newData = selProp !== undefined ?  dataListDetail?.filter((item:any) => item?.property_type?.some((prop:any) => prop.property_type_id === selProp?.id)) : newData;
                // console.log('newData', newData)
                SetDataListDetail(newData);
            }else if(mode == "R"){
                form?.resetFields();
                setselProp(undefined);
                refreshDataSource();
                setnameSearch("");
                setnameSearchEn("");
            }
        }
      
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    return <>
        <List 
            // title={t("mjcnotimsg.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjcnotimsg.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" 
                        // {...formProps} 
                        onFinish={(values:any) => {}}
                        form = {form} 
                    >
                        <Row gutter={20} style={{width: "100%"}}>
                            {
                                TabKey !== "category" && <>
                                    <Col span={4} className="gutter-row">
                                        <Form.Item
                                            name="property_type"
                                            label="Property Type"
                                        >
                                            <Select placeholder={t("select.sel")} 
                                                // onSelect={(value: any, label: any) => {onselcus(value)}}
                                                onChange={(value: any, label: any) => {
                                                    if(value!== undefined){
                                                        setselProp({id:value, label: label?.children})
                                                    }else{
                                                        setselProp(undefined)
                                                    }
                                                }}
                                            >
                                                {
                                                    DataProperty && DataProperty?.length > 0 && DataProperty?.map((item:any,key:any) => {
                                                        // if(item?.user_profile){
                                                        //     if(item?.user_profile?.first_name == '' || item?.user_profile?.last_name == ''){
                                                        //         return null
                                                        //     }
                                                        //     else{
                                                        //         return <>
                                                        //             <Option value={item?.user_profile?.id} key={item?.id}>
                                                        //                 {item?.user_profile ? item?.user_profile?.first_name : ''}{' '}{item?.user_profile ? item?.user_profile?.last_name : ''}
                                                        //             </Option>
                                                        //         </>
                                                        //     }
                                                        // }
                                                        return <>
                                                            <Option value={item.property_type_id} key={item.property_type_id}>
                                                                {item.property_type_name}
                                                            </Option>
                                                        </>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                            <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label="Search">
                                    <Input 
                                        placeholder="Search Th" 
                                        onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="template_name_en" label=" ">
                                    <Input 
                                        placeholder="Search EN" 
                                        onChange={(e) => { if(e.target.value.length > 0){ setnameSearchEn(e?.target?.value)}else{setnameSearchEn("")}}} 
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(TabKey == "category" ? dataList : dataListDetail, "S", TabKey)}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    onSearchtable(TabKey == "category" ? dataList : dataListDetail, "R", TabKey)
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form.Item style={{marginRight: "0",}}>
                        {
                            TabKey == "category" ?
                                <CreateButton 
                                    id="create_btn" 
                                    onClick={()=>create("repair-category")} 
                                ></CreateButton>
                            :
                                <CreateButton 
                                    id="create_btn" 
                                    onClick={()=>create("repair-category-detail")} 
                                ></CreateButton>
                        }
                    </Form.Item>
                </Col>
            </Row>

            {/* <Row gutter={12}>
                <Col span={22}>
                
                </Col>
                <Col span={2}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>

                            {
                                TabKey == "category" ?
                                    <CreateButton 
                                        id="create_btn" 
                                        onClick={()=>create("repair-category")} 
                                        // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                                    ></CreateButton>
                                :
                                    <CreateButton 
                                        id="create_btn" 
                                        onClick={()=>create("repair-category-detail")} 
                                        // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                                    ></CreateButton>
                            }
                        </Form.Item>
                    </Form>
                </Col>
            </Row> */}

            <Form.Item name="" >
                <Tabs 
                    defaultActiveKey={tabActive ? tabActive : "category"}
                    style={{ marginTop: 10, marginBottom: 10 }} 
                    onChange={onStatusChange} 
                >
                    <TabPane
                        key={"category"}
                        tab={
                            <span>
                                {"หมวด"}
                                <Badge count={dataList.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />

                    <TabPane
                        key={"detail"}
                        tab={
                            <span>
                                {"รายละเอียด"}
                                <Badge count={dataListDetail.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />
                </Tabs>
            </Form.Item>

            <div style={{marginTop:'-3%'}}>
                {
                    // dataList.length > 0 ?
                    //     <Table 
                    //         // dataSource={dataList} 
                    //         dataSource={TabKey == "category" ? dataList : dataListInActive} 
                    //         columns={columns_category} 
                    //         onChange={onChange} 
                    //         pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                    //     />
                    // :
                    //     <Table 
                    //         dataSource={TabKey == "detail" ? dataListDetail : dataListInActive} 
                    //         columns={columns_detail} 
                    //         onChange={onChange} 
                    //         pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                    //     />

                    // tabActive == "category" && TabKey == "category" ?
                    //     <Table 
                    //         // dataSource={dataList} 
                    //         dataSource={dataList.length > 0 ? dataList : DataBlank} 
                    //         // dataSource={TabKey == "category" ? dataList : dataListInActive} 
                    //         columns={columns_category} 
                    //         onChange={onChange} 
                    //         pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                    //     />
                    // :
                    //     <Table 
                    //         dataSource={dataListDetail.length > 0 ? dataListDetail : DataBlank}
                    //         columns={columns_detail}  
                    //         onChange={onChange} 
                    //         pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                    //     />
                }
                {
                   TabKey == "category" ?
                        <Table 
                            // dataSource={dataList} 
                            dataSource={dataList.length > 0 ? dataList : DataBlank} 
                            // dataSource={TabKey == "category" ? dataList : dataListInActive} 
                            columns={columns_category} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 900 }}
                        />
                    :
                        <></>
                }
                {
                    tabActive === "detail" &&
                    <Table 
                        // dataSource={dataList} 
                        dataSource={dataListDetail.length > 0 ? dataListDetail : DataBlank} 
                        // dataSource={TabKey == "category" ? dataList : dataListInActive} 
                        columns={columns_detail} 
                        onChange={onChange} 
                        // pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                        pagination={{
                            size: "small",
                            position: ['bottomRight'],
                            // showTotal: showTotal, 
                            pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                            showSizeChanger: true, // Optional - Show page size changer dropdown
                            // showQuickJumper: true, // Optional - Show quick jumper input
                            // pageSize: 10, // Optional - Default page size
                            pageSize: pageSize,
                            onChange: handlePageSizeChange,
                        }}
                        scroll={{ x: 900 }}
                    />
                }

            </div>
        </List>
    </>
}