/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
// import MDEditor from '@uiw/react-md-editor';
import { majorCareUrl } from "../../../constants";

let swLocal : boolean = true;
let dataonFilter: any = [];
let listProps: any = [];
export const RepairFacilityCategorylList: React.FC<IResourceComponentsProps> = () => {
    const tabActive = localStorage.getItem("keyTabCateAndDetail");
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TextArea } = Input;
    const { Option } = Select;
    const API_URL = useApiUrl();
    const { Text } = Typography;
    // console.log('majorCareUrl', majorCareUrl)
    const { create, edit } = useNavigation();
    const { confirm } = Modal;
    const [TabKey, SetTabKey] = useState<any>("true");
    const [ count, setCount] = useState(0);
    const [tabKey, settabKey] = useState<boolean>(true);
    const [Listdata, setListdata] = useState<any>([]);
    const [Zonedata, setZonedata] = useState<any>([]);

    const { TabPane } = Tabs;
    
    const [dataList, setDataList] = useState<any>([]);
    const [DataBlank, SetDataBlank] = useState<any>([]);
    const [dataListInActive, setDataListInActive] = useState<any>([]);
    const [dataListDetail, SetDataListDetail] = useState<any>([]);

    const [searchZone, setsearchZone] = useState<any>();
    const [searchcatName, setsearchcatName] = useState<any>();
    
    const getDataK = async ()=>{
        // SetTabKey(tabActive ? tabActive : "true")

        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairCategoryFacility`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;

                listProps = dataLoad;
                setListdata(dataLoad)
                // console.log('Data repairCategory', dataLoad)

                let xx = dataLoad.filter((e:any)=> e.is_active == true);
                for (let index = 0; index < xx.length; index++) {
                    dataList.push(xx[index]);
                }
                // dataList?.reverse();
                // setDataList(dataList)

                let yy = dataLoad.filter((e:any)=> e.is_active == false);
                for (let index = 0; index < yy.length; index++) {
                    dataListInActive.push(yy[index]);
                }
                dataList?.reverse();
                dataListInActive?.reverse();

            });
    }

    const getDataZone = async ()=>{
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairZoneFacility`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;

                let onFilter = dataLoad?.filter((e:any)=> e.is_active == true)

                setZonedata(onFilter)
            });
    }

    useEffect(() => {
        if (count < 2) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                // console.log('asd')
                // getPropCreated();
                if(count === 0){
                    // console.log('here ')
                    getDataK();
                    getDataZone();
                    // setCount(20)
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    console.log(">>> listProps",listProps)

    async function updateStatus(id:any, val:any){
        // console.log('id', id);
        let body = {
            "is_active": val
        }
        let res = await axios.patch(majorCareUrl + "/majorcare/repairCategoryFacility/" + id, body, { headers: { } });
            // console.log('res category', res) 
        refreshDataSource()
    }

    const clearDataSource = () => {
        setDataList([])
        SetDataListDetail([])
        setDataListInActive([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        // setCountInterval(0)
        // refetch();
        // getDataK();
        setCount(0);
        // setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        // console.log('val',v)
        if(v == "true" || v == true){
            SetTabKey("true")
        }else{
            SetTabKey("false")
        }
    }

    const columns_category: any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'หมวด (TH)',
            dataIndex: 'item_name_1',
            key: 'item_name_1',
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            sorter: (a:any, b:any) => a.category_name.localeCompare(b.category_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record ? record.category_name !== null ? record.category_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'หมวด (EN)',
            // dataIndex: 'name',
            key: 'category_name_en_1',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.category_name_en.localeCompare(b.category_name_en),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record ? record.category_name_en !== null ? record.category_name_en : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'CREATED BY',
            // dataIndex: 'create_by',
            key: 'create_by_1',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.create_by?.first_name < b?.create_by?.first_name) {return -1}
                if (a?.create_by?.first_name > b?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record ',record)
                return (
                    <>
                        <div >{record?.create_by?.first_name ? record?.create_by?.first_name : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            width: 150,
            key: 'create_date',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'update_by',
            key: 'update_by_1',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.update_by?.first_name < b?.update_by?.first_name) {return -1}
                if (a?.update_by?.first_name > b?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record?.update_by?.first_name ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'Last Update',
            dataIndex: 'update_date',
            width: 150,
            key: 'update_date',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            // dataIndex: '',
            key: 'action_2',
            width: 100,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.zone_obj[0]?.is_active ?
                                record.is_active === true ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small"
                                            // onClick={()=> editModalShow(record.id)}
                                            onClick={() => edit("repair-facility-category", record._id + "_" + record.item_name )}
                                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            // disabled={false }
                                            style={{marginRight:'2px'}}
                                        />
                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record._id) }} danger></Button>
                                    </>
                                :
                                    <>
                                        <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record._id) }} ></Button>
                                    </>
                            :
                                <>
                                    <EditButton hideText size="small" style={{marginRight:'2px'}} disabled />
                                    <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} disabled danger></Button>

                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    function onsetZone(value: any){
        setsearchZone(value);
        if(value){
            onSearchtable(listProps, searchcatName, value);
        }else{
            onSearchtable(listProps, searchcatName, value);
        }
    }

    function onsetCat(value: any){
        setsearchcatName(value);
        if(value){
            onSearchtable(listProps, value, searchZone);
        }else{
            onSearchtable(listProps, value, searchZone);
        }
    }

    function onSearchtable(data: any, category: any, zone:any){
        let newData:any = data;
        if(category || zone){
            newData = newData?.filter((e:any)=> e?.is_active == tabKey);

            // console.log(">>> zone",zone)
            // console.log(">>> searchZone",searchZone)
            // console.log(">>> category",category)
            // console.log(">>> searchcatName",searchcatName)


            newData = zone ? newData?.filter((e:any)=> {return ( e?.zone_obj[0]?._id === String(zone))}): newData;
            newData = category ? newData?.filter((e:any)=> {return ( e?.category_name?.includes(category?.toLowerCase().toLowerCase()) || e?.category_name_en?.toLowerCase().includes(category?.toLowerCase()))}): newData;

            // console.log(">>> newData",newData)

            setListdata((pre: any) => newData);
        }else{
            console.log(">>> else")
            setListdata((pre: any) => newData);
        }
    }

    // const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
    
    return <>
        <List 
            // title={t("mjcnotimsg.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjcnotimsg.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout = "inline" style={{marginBottom:"1%"}}>
                        <Form.Item>
                            <Select 
                                placeholder="ZONE" 
                                allowClear 
                                style={{width: "220px"}}
                                onChange={(e:any)=> {
                                    if(e){
                                        // setsearchZone(e);
                                        // onSearchtable(listProps, searchcatName, e);
                                        onsetZone(e)
                                    }else{
                                        onsetZone(undefined)
                                        // setsearchZone(undefined)
                                        // onSearchtable(listProps, searchcatName , undefined);
                                    }
                                }}
                            >
                                {
                                    Zonedata?.length > 0 && Zonedata.map((item:any,key:any) => { return <Option key={key} value={item._id} >{item.zone_name}</Option>})
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Input 
                                placeholder="CATEGORY NAME EN /TH"
                                allowClear
                                style={{borderRadius: 5}}
                                onChange={e => {
                                    if(e.target.value.length > 0){
                                        onsetCat(e.target.value);
                                        // setsearchcatName(e.target.value);
                                        // onSearchtable(listProps, e.target.value, searchZone);
                                    }else{
                                        onsetCat(undefined)
                                        // setsearchcatName(undefined);
                                        // onSearchtable(listProps, undefined , searchZone);
                                    }

                                //     setisLoading(true)
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton 
                                id="create_btn" 
                                onClick={()=>create("repair-facility-category")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Form.Item name="deleteStatus" >
                <Tabs 
                    defaultActiveKey={"true"}
                    style={{ marginTop: 10, marginBottom: 10 }} 
                    onChange={(e: any)=> e == "true" ? settabKey(true) : e == "false" && settabKey(false)} 
                >
                    <TabPane
                        key={"true"}
                        tab={
                            <span>
                                {"ACTIVE"}
                                <Badge count={Listdata?.filter((e:any)=> e.is_active == true).length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />

                    <TabPane
                        key={"false"}
                        tab={
                            <span>
                                {"TRASH"}
                                <Badge count={Listdata?.filter((e:any)=> e.is_active == false).length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />
                </Tabs>
            </Form.Item>

            <div style={{marginTop:'-3%'}}>
                <Table 
                    dataSource={Listdata?.filter((e:any)=> e.is_active == tabKey).reverse()} 
                    columns={columns_category} 
                    // onChange={onChange} 
                    // pagination={{ size: "small", position: [ 'bottomRight' ] }}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        // showTotal: showTotal, 
                        pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                        showSizeChanger: true, // Optional - Show page size changer dropdown
                        // showQuickJumper: true, // Optional - Show quick jumper input
                        // pageSize: 10, // Optional - Default page size
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                />
            </div>
        </List>
    </>
}