import { Breadcrumb, Button, Card, Col, Create, Form, Input, List, message, Radio, RcFile, Row, Upload, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate  } from "@pankod/refine-core";
import { IEventlog, ICompany, IDepartment } from "interfaces";
import { useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
 
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import { PlusOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import type { RadioChangeEvent } from 'antd';

export const CompanyCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: CreateDepartment} = useCreate<IDepartment>();
    const [disabled, setDisabled] = useState(false);
    const log_endpoint = "even-logs";
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { ...uploadProps} = useStrapiUpload({ maxCount: 1, });
    const { formProps, saveButtonProps } = useForm<ICompany,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'pp_type',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });


                if(vDepNameArr.length > 0){
                    for(let i=0 ; i <= vDepNameArr.length -1; i++){
                        CreateDepartment({
                            resource    : 'departments',
                            values      : {
                                dept_code: i + "",
                                dept_name : vDepNameArr[i],
                                company : recordData.data.id + "",
                            },
                            successNotification : false,
                        });
                    }
                }
            },
        }
    );

    const [ vDepName, setDepName ] = useState<any>("");
    const [ vDepNameArr, setDepNameArr ] = useState<any>([]);

    const [vsetTel, setTel] = useState<CountryPhoneInputValue>();
    // const [vsetTel, setTel] = useState<any>();
    // const [Status, setStatus] = useState(1);
    const [Status, setStatus] = useState(true);

    const onChange = (e: RadioChangeEvent) => {
        setStatus(e.target.value);
    };

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
        }
    }

    // function toggleDisabled() {
    //     setDisabled(!disabled);
    // }


    function fSetDepName(depName:any, i:any){
        console.log('dep name', depName.target.value);
        console.log('index', i)
        setDepName(depName.target.value);
    }

    function addDepNameToArr(){
        console.log('call addDepNameToArr');

        if(vDepName.length > 0){
            let comp = vDepNameArr.some((r:any) => vDepName.includes(r))
            if(comp == false){
                vDepNameArr.push(vDepName);
                setDepNameArr(vDepNameArr);
            }

        }

        console.log('vDepNameArr', vDepNameArr);
    }
    function delarr(v:any){
        console.log(v)
        //let comp = vDepNameArr.some((r:any) => v.includes(r))
        // if(comp == true){
            vDepNameArr.splice(v,1);
            console.log('vDepNameArr', vDepNameArr);
        // }
    }

    // console.log('vsetTel', vsetTel);
    const { TextArea } = Input;

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    
    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title=""
                    
                pageHeaderProps={{ 
                    breadcrumb: <Breadcrumb>
                                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("company.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><span className="last_breadcrumb">{t("company.create")}</span></Breadcrumb.Item>
                                </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id
                    // values.short_tel = vsetTel

                    values.short_tel = vsetTel?.short
                    values.tel = vsetTel?.phone
                    values.status = Status

                    addDepNameToArr();
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ borderRadius: 5,boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("company.com_code")} name="company_code"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter company code.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("company.com_name")} name="company_name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter company name.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("company.com_address")} name="address"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter company address.'
                                            },
                                        ]}>
                                            {/* <Input /> */}
                                            <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                    </Form.Item>
                                </Col>


                                
                                <Col className="gutter-row" span={24}>
                                    <Row gutter={[16, 24]}>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item  label={t("company.com_tel")}>
                                                <ConfigProvider locale={en}>
                                                    <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{height: "32px",borderRadius: "5px",padding: "0"}}
                                                        onChange={(v) => {
                                                            setTel(v);
                                                        }}
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                            }
                                                        }}
                                                        minLength={9}
                                                        maxLength={12}
                                                    />
                                                </ConfigProvider>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={12}>
                                            <Form.Item  label={t("company.status")} name="status"
                                                // rules={[
                                                //     {
                                                //         required: true, message: 'please select status.'
                                                //     },
                                                // ]}
                                            >
                                                <Radio.Group onChange={onChange} value={Status} defaultValue={Status}>
                                                        <Radio value={true}>Active</Radio>
                                                        <Radio value={false}>In Active</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            {/* <Divider /> */}

                            <Row gutter={[16, 24]}>

                            </Row>
 
                            <Col span={24}>
                                <div style={{marginBottom: "1vh"}}>Department</div>
                                    <Form.List name="fields">
                                        {(fields, { add, remove }) => {
                                            return (
                                            <div>
                                                {fields.map((field, index) => (
                                                    <div key={field.key}>
                                                        <Row>
                                                            {/* <Col span={3}> รอบที่ {index + 1} </Col> */}
                                                            <Col span={1}> <div style={{float:'right'}}></div> {index+1} </Col>
                                                            {/* <Col span={16}>
                                                                <TimePicker.RangePicker 
                                                                    style={{width:'95%'}}
                                                                    onChange={(date, dateString) => selectTime(date, dateString, index+1)}
                                                                    format={format} 
                                                                />
                                                            </Col> */}
                                                            <Col span={14}>
                                                                {
                                                                    fields.length == index+1 ?
                                                                        <Input showCount maxLength={60}
                                                                            // onChange={(e) => setDepName(e.target.value)}
                                                                            onChange={(e) => fSetDepName(e, index+1)}
                                                                            
                                                                        />
                                                                    :
                                                                        <Input showCount maxLength={60}
                                                                            // onChange={(e) => setDepName(e.target.value)}
                                                                            onChange={(e) => fSetDepName(e, index+1)}
                                                                            disabled
                                                                        />

                                                                }
                                                                {/* <Input showCount maxLength={60}
                                                                    // onChange={(e) => setDepName(e.target.value)}
                                                                    onChange={(e) => fSetDepName(e, index+1)}
                                                                /> */}
                                                            </Col>

                                                            <Col span={1}></Col>

                                                            {/* <Col span={3}>
                                                            {
                                                                fields.length == index+1 ?
                                                                    <Button
                                                                        size="small"
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => { addDepNameToArr(); toggleDisabled();}}
                                                                        style={{marginRight: "3%", color: "green"}}
                                                                    >
                                                                        <CheckOutlined/>
                                                                    </Button>:null
                                                            }
                                                            </Col> */}

                                                            <Col span={3}>
                                                            {
                                                                fields.length > 1 ? (
                                                                    <Button
                                                                        size="small"
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => {delarr(field.name); remove(field.name)}}
                                                                        danger
                                                                    >
                                                                        <CloseOutlined />
                                                                    </Button>
                                                                ) : null
                                                            }
                                                            </Col>
                                                        </Row>
                                                        <div style={{marginBottom: "1vh"}}></div>
                                                    </div>
                                                ))}

                                                {/* <Divider /> */}

                                                <Form.Item>

                                                    {/* {
                                                        disabled ?
                                                            <Button
                                                                type="dashed"  
                                                                onClick={() => { add(); toggleDisabled();}}
                                                                style={{ width: "20%",backgroundColor: "#a3a3a3",borderRadius: "10px"}}
                                                                disabled
                                                            >
                                                                <PlusOutlined style={{color: "#fff"}} /> <span style={{color: "#fff"}}>Add</span>
                                                            </Button>
                                                        :
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => { add(); toggleDisabled();}}
                                                                style={{ width: "20%",backgroundColor: "#d6c6a0",borderRadius: "10px"}}
                                                            >
                                                                <PlusOutlined style={{color: "#fff"}} /> <span style={{color: "#fff"}}>Add</span>
                                                            </Button>
                                                    } */}

                                                    {/* ORIGINAL */}
                                                    <Button
                                                        type="dashed"
                                                        // onClick={() => add()}
                                                        onClick={() => { add(); addDepNameToArr();}}
                                                        // onClick={() => { add(); toggleDisabled();}}
                                                        style={{ width: "20%",backgroundColor: "#d6c6a0",borderRadius: "10px"}}
                                                        // disabled={disabled}
                                                    >
                                                        <PlusOutlined style={{color: "#fff"}} /> <span style={{color: "#fff"}}>Add</span>
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                            );
                                        }}
                                    </Form.List>
                            </Col>
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "2%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Form.Item
                                name="logo"
                                label={t("company.logo")}
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload image.'
                                    },
                                ]}>
 
                                <Upload.Dragger
                                    style={{borderRadius: 10}}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    {...uploadProps}
                                    // multiple
                                    // maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Upload.Dragger>
                                    {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                                    {/* <p style={{textAlign: "left",color: "#DF6677"}}>Only Size is 50 x 50 px</p> */}
                                    {/* <p style={{fontSize:"10px"}}>Maximum size 5MB</p> */}
                            </Form.Item>
                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};