/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, Divider, EditButton, RcFile, UploadFile, TextField, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY } from "../../../constants";
import { ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import axios from "axios";
import { DatePicker, DatePickerProps, message, notification, Table, Tag, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { majorCareUrl } from "../../../constants";
import { RangePickerProps } from "antd/lib/date-picker";

export const RepairMakeAppointment: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const { RangePicker } = DatePicker;

    // ******** MAIN STATUS ********
    const option_main_status:any = [
        // {
        //     label: "รอดำเนินการ",
        //     value: "649859ca515b80b78fe13b84",
        // },
        // {
        //     label: "รับเรื่องแจ้งซ่อม",
        //     value: "649859e7515b80b78fe13b86",
        // },
        {
            label: "นัด วัน/เวลา ประเมินหน้างาน",
            value: "649859f8515b80b78fe13b88",
        },
        // {
        //     label: "เข้าประเมิน",
        //     value: "64985a01515b80b78fe13b8a",
        // },
        // {
        //     label: "เข้าประเมิน-เกินกำหนด",
        //     value: "64985a0f515b80b78fe13b8c",
        // },
        // {
        //     label: "ประเมินเสร็จ",
        //     value: "64985a29515b80b78fe13b8e",
        // },
        {
            label: "นัด วัน/เวลา เข้าซ่อม",
            value: "64985a35515b80b78fe13b90",
        },
        // {
        //     label: "เข้าซ่อม",
        //     value: "64985a3c515b80b78fe13b92",
        // },
        // {
        //     label: "เข้าซ่อม-เกินกำหนด",
        //     value: "64985a47515b80b78fe13b94",
        // },
        // {
        //     label: "เลื่อนนัดจากลูกค้า",
        //     value: "64985a67515b80b78fe13b96",
        // },
        // {
        //     label: "เลื่อนนัดจากนิติ",
        //     value: "64985ae5515b80b78fe13ba2",
        // },
        // {
        //     label: "อุปกรณ์อยู่ระหว่างการผลิต",
        //     value: "64985b3b515b80b78fe13bac",
        // },
        // {
        //     label: "รออนุมัติงานซ่อม",
        //     value: "64985bbe515b80b78fe13bb6",
        // },
        // {
        //     label: "อนุมัติงานซ่อม",
        //     value: "64985bc6515b80b78fe13bb8",
        // },
        // {
        //     label: "ปิดงาน",
        //     value: "64985bcf515b80b78fe13bba",
        // },
    ];

    // ******** WORKING SUB STATUS ***********
    const option_cust_postpone:any = [
        {
            label: "ลูกค้าติดธุระส่วนตัว",
            value: "64985a95515b80b78fe13b98",
        },
        {
            label: "ลูกค้าเป็นโควิด/กักตัว",
            value: "64985a9c515b80b78fe13b9a",
        },
        {
            label: "ลูกค้า ไม่สะดวกนัดวันซ่อม เนื่องจากทำงาน",
            value: "64985aaa515b80b78fe13b9c",
        },
        {
            label: "ลูกค้าไม่มีคนเฝ้าห้องให้ กรณีนัดซ่อมงาน ต่อเนื่องหลายวัน",
            value: "64985ab9515b80b78fe13b9e",
        },
        {
            label: "ไม่สามารถติดต่อลูกค้าได้",
            value: "64985ac3515b80b78fe13ba0",
        },
    ];

    const option_juristic_postpone:any = [
        {
            label: "ผรม. แจ้งว่าไม่สามารถเข้าได้ตามกำหนด",
            value: "64985b04515b80b78fe13ba4",
        },
        {
            label: "ผลิตภัณฑ์ที่อยู่ระหว่างผลิต ไม่สามารถเข้าติดตั้งได้",
            value: "64985b0d515b80b78fe13ba6",
        },
        {
            label: "ทางโครงการไม่ให้เข้าแก้ไข ตามวันเวลา ที่แจ้ง",
            value: "64985b17515b80b78fe13ba8",
        },
        {
            label: "รอ Major อนุมัติ PO/PR",
            value: "64985b21515b80b78fe13baa",
        },
    ];

    const option_matt_in_manufacturing:any = [
        {
            label: "อุปกรณ์ สุขภัณฑ์",
            value: "64985b5e515b80b78fe13bae",
        },
        {
            label: "วัสดุตกแต่งห้องชุด เช่น กระเบื้อง, พื้นไม้, วอเปเปอร์",
            value: "64985b68515b80b78fe13bb0",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เฟอร์นิเจอร์ที่แจ้ง",
            value: "64985b74515b80b78fe13bb2",
        },
        {
            label: "อุปกรณ์ที่ต้องสั่งผลิต เช่น เครื่องใช้ไฟฟ้า",
            value: "64985b80515b80b78fe13bb4",
        },
    ];

    const option_close_job:any = [
        {
            label: "ส่งงานเสร็จสมบูรณ์",
            value: "64985be1515b80b78fe13bbc",
        },
        {
            label: "Customer เปิดงานซ้ำ",
            value: "64985bec515b80b78fe13bbe",
        },
        {
            label: "Customer เปิดงานผิด",
            value: "64985bf6515b80b78fe13bc0",
        },
        {
            label: "Customer เปิดงานผิด-เจ้าหน้าที่เปิดงานใหม่ให้ลูกค้า",
            value: "64985c0c515b80b78fe13bc2",
        },
        {
            label: "ให้คำแนะนำ เพื่อจัดจ้าง",
            value: "64985c15515b80b78fe13bc4",
        },
        {
            label: "งานที่ไม่อยู่ในเงื่อนไขรับประกัน",
            value: "64985c22515b80b78fe13bc6",
        },
        {
            label: "รายละเอียดไม่ตรงกับรายการรับประกัน",
            value: "64985c2d515b80b78fe13bc8",
        },
        {
            label: "ลูกค้าเลื่อนซ่อม เกิน SLA",
            value: "64985c38515b80b78fe13bca",
        },
        {
            label: "รอสั่งของ",
            value: "64985c42515b80b78fe13bcc",
        },
        {
            label: "งานซ่อมที่เกิดจากการใช้งาน",
            value: "64985c4a515b80b78fe13bce",
        },
        {
            label: "งานเคลมประกันภัยอาคาร",
            value: "64985c56515b80b78fe13bd0",
        },
    ];

    const format = 'YYYY-MM-DD HH:mm';

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);
    const { create, edit } = useNavigation();
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    const token = localStorage.getItem(TOKEN_KEY);
    const dataBlank:any = []

    const { Text } = Typography;

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);

    let work_id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    // id = arr_id.split("_")
    work_id = arr_id.split("%2C")
    let item_id = work_id[0]
    let job_id = work_id[1]

    // console.log('arr_id', arr_id)
    // console.log('job id', work_id)

    // console.log('id kkkkkkkkkkkkkkk', id)

    // console.log('asdasdasdas',decodeURIComponent((id[2]+'').replace(/\+/g, '%20')));
    // console.log('zxczxczxczx',decodeURIComponent((id[3]+'').replace(/\+/g, '%20')));

    // let structureName = decodeURIComponent((id[2]+'').replace(/\+/g, '%20'))
    // let zoneName = decodeURIComponent((id[3]+'').replace(/\+/g, '%20'))

    // Data ส่วนของ รายละเอียดการแจ้ง
    const [InpectorName, SetInpectorName] = useState<any>();

    const [dataChecklist, setDataCheckList] = useState<any>([]);
    const [StatOfJob, SetStatOfJob] = useState<any>();
    const [StatOfChecklist, SetStatOfChecklist] = useState<any>();
    const [JobIDHM, SetJobIDHM] = useState<any>();

    const [Inspector, SetInspector] = useState<any>();

    const [vImgEn, setImgEn] = useState<any>();
    const [vVid, setVid] = useState<any>();
    const [vVidId, setVidId] = useState<any>([]);
    const [ImgId, SetImgId] = useState<any>([]);
    const [Remark, SetRemark] = useState<any>();
    const [ChecklistName, SetChecklistName] = useState<any>();
    const [ZoneName, SetZoneName] = useState<any>();
    const [StructureName, SetStructureName] = useState<any>();
    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    const [InspectorProfile, SetInspectorProfile] = useState<any>();

    const [Stat, setStat] = useState<any>(undefined);
    const [SubStat, setSubStat] = useState<any>(undefined);

    const [textcount, settextcount] = useState('');
    const [dataDetailList, setDataDetailList] = useState<any>([]);

    const id_to_query = work_id.join(',');
    

    const handleDatePickerChange = (date: Dayjs | null, dateString: string, type: any) => {
        // console.log('dateString', dateString)
        if(type == "start"){
            setAppointStart(dateString);
        }else{
            setAppointEnd(dateString);
        }
    };

    const getDataK = async ()=>{
        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjob?id=648ab8ca326b1b890c5cf5ec
        // GET JOB DETAIL DATA
        let res_detail_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId`, 
            majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId?stat=${id_to_query}&page=1&limit=20`, 

            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad Detail', dataLoad)
                // console.log('prop_id', prop_id)

                // if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.property_obj[0]?.property_id == Number(prop_id))
                // }
                // console.log('work_id', work_id)
                // const xx = dataLoad.filter((item:any) => work_id.includes(item.job_id));

                // console.log('xx', xx)
                for (let index = 0; index < dataLoad.length; index++) {
                    dataDetailList.push(dataLoad[index])
                }

                // // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" || e.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" || e.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" ) 
                // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86") 
                // for (let index = 0; index < xx.length; index++) {
                //     dataDetailList.push(xx[index]);
                // }

            });
    }

    console.log('dataDetailList', dataDetailList)

    const handleChange = (e:any) => {
        settextcount(e.target.value);
    };

    const saveData = async ()=>{
        updateRepairJob();
        // replace(`/mjc-inspec-today-job/edit/${job_id}_${JobIDHM}`)
        // replace(`/mjc-inspec-inprogress-job/edit/${job_id}_${JobIDHM}`)
    }

    // console.log('vImgEn', vImgEn)

    const updateRepairJob = async () => {
    //    console.log('Stat', Stat)
    //    console.log('AppointStart', AppointStart)
    //    console.log('AppointEnd', AppointEnd)
    //    console.log('InspectorProfile', InspectorProfile)
    //    console.log('Remark', Remark)
    //    console.log('Identity_data', Identity_data)

        for (let index = 0; index < work_id.length; index++) {
            // const element = work_id[index];
            
            // ****************** 1. UPDATE JOB with Selected stat ******************
            // **********************************************************************
            let data = {
                "status_id": Stat.value,  // รับเรื่องแจ้งซ่อม
                "status_name": Stat.label,  // รับเรื่องแจ้งซ่อม
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
            };
            let res = await axios.patch(majorCareUrl + "/majorcare/repairJob/updateRepairjob?id=" + work_id[index], data, { headers: { } });


            // ****************** 2. CREATE TRAIL ******************
            // *****************************************************

            let dataTrail = {
                "repair_job": work_id[index],
                "status": Stat.value,
                "status_name": Stat.label,
                "sub_status": SubStat !== undefined ? SubStat.value : null,
                "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                "remark": Remark !== undefined ? Remark : "",
                "create_by": Identity_data?.user_profile,
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            }

            let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });
        }

        successNoti();
        replace("/repair/repair-job")
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    const columns:any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            // sorter: (a:any, b:any) => a.job_id.localeCompare(b.job_id),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by.attributes !== undefined ? record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>
                            {
                                // record ? 
                                //     record.job_obj[0]?.status_id !== null ?
                                //         record.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" ?
                                //             <Tag className="tag_mjc_stat_wait_inspect">
                                //                 <TextField value={'รับเรื่องแจ้งซ่อม'}  />
                                //             </Tag>
                                //         :
                                //             record.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" ?
                                //                 <Tag className="tag_mjc_stat_inspect">
                                //                     <TextField value={'เลื่อนนัดจากลูกค้า'}  />
                                //                 </Tag> 
                                //             :
                                //                 record.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" ?
                                //                     <Tag className="tag_mjc_stat_inspect">
                                //                         <TextField value={'เลื่อนนัดจากนิติบุคคล'}  />
                                //                     </Tag>
                                //                 :
                                //                     <Tag className="tag_mjc_stat_wait_apprv">
                                //                         <TextField value={'error 404'}  />
                                //                     </Tag>
                                //     : '-'
                                // : '-'
                                record ? 
                                    record.job_obj[0]?.status_id !== null ?
                                        <Tag className="tag_mjc_stat_wait_inspect">
                                            <TextField value={record.job_obj[0].status_name}  />
                                        </Tag>
                                    : '-'
                                : '-'

                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    useEffect(() => {
        if (countInterval <= 1) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                    getDataK();

                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        // resource: "home-check-up-settings",
        resource: "repair-settings",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            // {
            //     field: "role_management][id]",
            //     operator: "eq",
            //     value: 19 // role Inspector
            // },
            // {
            //     field: "property][id]",
            //     operator: "in",
            //     // value: PropertyId ? PropertyId : null
            //     value: PropertyIdJob ? PropertyIdJob : null 

            // },
            // {
            //     field: "user_profile][id]",
            //     operator: "in",
            //     value: InspectorSettingId ? InspectorSettingId : null // role Inspector
            // }
            {
                field: "role_management][id]",
                operator: "eq",
                value: 21 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            // {
            //     field: "property][id]",
            //     operator: "eq",
            //     value: PropertyIdJob
            // },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const FindInspectorUserId = async (e:any) => {    
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        let inspector_profile = k?.data?.data;
        SetInspector(inspector_profile[0].attributes.user_profile?.data.id)
        SetInspectorProfile(inspector_profile)
    }

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleClick = () => {
        // Perform your button click logic here
        setButtonDisabled(true);
        form.submit()
        // Disable the button after it's clicked
    };


    const onChange = (
        value: DatePickerProps['value'] | RangePickerProps['value'],
        dateString: [string, string] | string,
    ) => {
        // console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
        setAppointStart(dateString[0])
        setAppointEnd(dateString[1])
    };

    const onOk = (value: DatePickerProps['value'] | RangePickerProps['value']) => {
        console.log('onOk: ', value);
    };

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        title="ทำรายการนัดหมาย"
        // pageHeaderProps={{
        //     extra : <></>
        // }}
        pageHeaderProps={{
            breadcrumb: <Breadcrumb>
                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                <Breadcrumb.Item>รายการแจ้งซ่อม</Breadcrumb.Item>
                {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><span className="last_breadcrumb">ทำรายการนัดหมาย</span></Breadcrumb.Item>
            </Breadcrumb>,
            extra : <></>,
            onBack(e?) {
                replace("/repair/repair-job")
            },
        }}
    >
        <Form  
            form = {form} 
            // layout="horizontal"
            layout="vertical"
            onFinish={(values:any) => {
                // createAssignInspec(values);
                // updateRepairJob()
                saveData();
            }}
        >
            <Table  
                dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank}
                columns={columns} 
                // onChange={onChange} 
                // pagination={{ size: "small", position: [ bottom] }} 
                pagination={{ size: "small", position: [ 'bottomRight'] }} 
            />

            <Divider/>

            <Row gutter={82} >
                <Col span={24}>
                    <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                        สถานะการทำงาน
                    </div>
                </Col>
            </Row>

            <Row>
                <Col span={8}>
                    <Form.Item 
                        label="สถานะ" 
                        name="status"
                        style={{width:"90%"}}
                        rules={[
                            {
                                required: true, message: "Please select status"
                            },
                        ]}
                    >
                        <Select 
                            placeholder={t("select.sel")}
                            style={{ width: '100%' }}
                            onSelect={(value: any, record:any) => {
                                // console.log(value);
                                // console.log('record', record);
                                // setStat(value);
                                setStat(record);
                            }}
                            options={option_main_status}
                        />
                    </Form.Item>
                </Col>

                <Col span={16}>
                    {
                        Stat !== undefined ?
                        Stat.value == "649859f8515b80b78fe13b88" ||  Stat.value == "64985a35515b80b78fe13b90" ?
                            <Form.Item 
                                label="วันที่นัด" 
                                name="appointment_date"
                                style={{
                                    width:"100%",
                                }}
                                rules={[
                                    {
                                        required: true, message: "Please select appointment date"
                                    },
                                ]}
                            >
                                <RangePicker
                                    style={{width: "100%"}}
                                    showTime={{ format: 'HH:mm' }}
                                    format="YYYY-MM-DD HH:mm"
                                    onChange={onChange}
                                    onOk={onOk}
                                />
                            </Form.Item>
                        : <></>
                        : <></>
                    }
                </Col>

                {/* <Col span={8}>
                    {
                        Stat !== undefined ?
                        Stat.value == "649859f8515b80b78fe13b88" ||  Stat.value == "64985a35515b80b78fe13b90" ?
                            <Form.Item 
                                label="วันที่นัด" 
                                name="appointment_start"
                                style={{
                                    width:"95%",
                                    // marginTop:'-3%'
                                }}
                                rules={[
                                    {
                                        required: true, message: "Please select appointment date"
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{width: "100%"}}
                                    className="ant-picker-mjc" 
                                    onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "start")} 
                                    format={format}
                                    showTime
                                />
                            </Form.Item>
                        : <></>
                        : <></>
                    }
                </Col>

                <Col span={8}>
                    {
                        Stat !== undefined ?
                        Stat.value == "649859f8515b80b78fe13b88" ||  Stat.value == "64985a35515b80b78fe13b90" ?
                            <Form.Item 
                                label="ถึงวันที่" 
                                name="appointment_end"
                                style={{
                                    width:"100%",
                                    // marginTop:'27%', 
                                }}
                                rules={[
                                    {
                                        required: true, message: "Please select appointment date"
                                    },
                                ]}
                            >
                                <DatePicker
                                    style={{width: "100%"}}
                                    className="ant-picker-mjc" 
                                    onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "end")} 
                                    showTime
                                    format={format}
                                />
                            </Form.Item>
                        : <></>
                        : <></>
                    }
                </Col> */}
            </Row>

            <Row>
                <Col span={8}>
                    {
                        Stat !== undefined ?
                        Stat.value == "649859f8515b80b78fe13b88" ||  Stat.value == "64985a35515b80b78fe13b90" ?
                            <Form.Item 
                                label="ช่างผู้รับผิดชอบ" 
                                name="inspector"
                                style={{
                                    width:"90%", 
                                    // marginTop:'-3%'
                                }}
                                rules={[
                                    {
                                        required: true, message: "Please select inspector"
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selInspector}
                                    onSelect={(value: any, record:any) => {
                                        FindInspectorUserId(value)
                                        setButtonDisabled(false)
                                    }}
                                >
                                </Select>
                            </Form.Item>
                        : <></>
                        : <></>
                    }
                </Col>

                <Col span={16}>
                    {
                        Stat !== undefined ?
                        Stat.value == "649859f8515b80b78fe13b88" ||  Stat.value == "64985a35515b80b78fe13b90" ?
                            <Form.Item
                                name="remark"
                                label="หมายเหตุ"
                                style={{
                                    width:"100%", 
                                    // marginTop:'-3%'
                                }}
                                // rules={[
                                //     {
                                //         required: checkedSms ? true : false, message: "Please input sms"
                                //     },
                                // ]}
                            >
                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}
                                    maxLength={255}
                                    onChange={(e) => {
                                        SetRemark(e?.target?.value)
                                        handleChange(e)
                                    }} 
                                />
                                <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount?.length} / 255`}</Text>
                            </Form.Item>
                        : <></>
                        : <></>
                    }                    
                </Col>
            </Row>

            <Divider/>

            <Row style={{float: 'right'}}>
                {/* <Button type="primary" onClick={() => saveData()} icon={<Icons.SaveOutlined />}>Save</Button> */}
                {/* <Button type="primary" onClick={() => handleClick()} icon={<Icons.SaveOutlined />} disabled={buttonDisabled} >Save</Button> */}
                <Button 
                    type="primary" 
                    onClick={() => handleClick()} 
                    icon={<Icons.SaveOutlined />} 
                    disabled={buttonDisabled} >Save</Button>
            </Row>
        </Form>
    </Edit>   
    );
};