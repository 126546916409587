/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Divider, Form, Input, List, Row, useForm, TextField, Select, useSelect, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useNavigation, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IProperties, IvehicleBrand } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";

export const UnitTypeEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    // const { formProps, saveButtonProps } = useForm<IvehicleBrand,HttpError,{}>();
    const { form, formProps, saveButtonProps }    = useForm<IvehicleBrand>({redirect:"list"});
    const { queryResult } = useShow<IvehicleBrand>({metaData: {
        populate: '*'
        }, });
    const { data } = queryResult;
    const record = data?.data;
    const { replace} = useNavigation();
    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";

    // const [count, setCount] = useState(0)

    let recordData  :   any;
    recordData = queryResult?.data ? queryResult?.data : [];
    recordData = data?.data? data?.data : [];

    const [ vSetProp, setProp ] = useState<any>();
    
    const { selectProps: SelProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       : 'N',
            }
        ]
    });

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "units",
        metaData: {
            populate: [
                '*',
                'property',
                'updateBy'
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();
          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        form.setFieldsValue({
            property: 
                recordData?.property ?
                recordData.property.property_name
                : null,
        })
        setProp(recordData?.property ?recordData.property.id: null)
    }
    
    // useEffect(() => {
    //     if (count < 2) {
    //         const interval = setInterval(() => {
    //         setCount(prev => prev + 1);

    //         form.setFieldsValue({
    //             property: 
    //                 recordData?.property ?
    //                 recordData.property.property_name
    //                 : null,
    //         })

    //         setProp(recordData?.property ?recordData.property.id: null)

    //       }, 1000)
  
    //       return () => clearInterval(interval)
    //     }else{
  
    //     }
    // }, [count])

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'unit-type',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }

    function onChangProp(va:any){
        setProp(va);
    };
    

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("unit-type.edit")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("unit.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("unit-type.edit")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            updateUserUpdatedBy();
            // console.log(user_data.user_profile.id)
            values.updateBy = user_data.user_profile.id
            values.updatedBy = user_data.user_profile.id

            values.property = vSetProp;
             return ( 
                 formProps.onFinish &&
                 formProps.onFinish(mediaUploadMapper(values))
             );
         }}>
            <Row gutter={[16, 24]}>
            <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("name")} name="name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("unit-type.limit")} name="vehicle_limit"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter vehicle limit.'
                                        },
                                    ]}>
                                        <Input type={"number"} minLength={1} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>

                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "5%"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_panel">{t("relation.txt")}</span></div>
                        <Form.Item  label={t("properties.title")} name="property">
                            <Select {...SelProp} placeholder={t("select.selSubType")}
                            onChange={onChangProp}
                            />
                        </Form.Item>   
                    </Card>

                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={16}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={8}>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>   
    );
};