/* eslint-disable */
import { Breadcrumb, Button, Card, Col, Divider, Edit, Form, Icons, Input, List, message, RcFile, Row, Select, Upload, useForm, useSelect, DatePicker, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import dayjs from "dayjs";
import { IDeliveryComp, IParcelBox, IParcelIn, IParcelList, IParcelType, IProperties, IUnit, IUserhunit } from "interfaces";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../constants";
import { WebcamComponent } from "./WebcamComponent";
import { useMediaQuery } from 'react-responsive';

import './edit.css';


let count_Arr: any = 0;

export const ParcelInEdit: React.FC<IResourceComponentsProps> = () => {

    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });
    const t = useTranslate();
    const userQuery = useGetIdentity(); // Get User Profile
    const format = 'YYYY-MM-DD HH:mm';

    const { data: user_data, isLoading } = userQuery;
    const API_URL = useApiUrl();
    const { Option } = Select;

    const { mutate: createParcelList } = useCreate<IParcelList>();
    const { mutate: updateParcelList } = useUpdate<IParcelList>();

    const { form, formProps, saveButtonProps } = useForm<IParcelIn, HttpError, {}>();
    const [showCamera, setShowCamera] = useState(false);
    const [checked, setChecked] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [json, setjson] = useState<any[] | null>(null);

    const handleDelete = () => {
        setImageUrl(null);
        setChecked(false);

        // ตั้งค่า imageUrl ให้เป็น null เพื่อลบภาพ
    };

    const handleCapture = () => {
        console.log(1);
        console.log(vPixdata, 'vPixdata');

        setShowCamera(true);
        setChecked(false);
    };
    let uploadlist: any[] = [];

    const { queryResult } = useShow<IParcelIn>({
        // resource: "parcel-ins",
        metaData: {
            populate: ['user_profile', 'unit', 'unit.property', 'parcel_lists', 'parcel_lists.pic', 'property'],
        },
        queryOptions: {
            onSuccess(data) {
                console.log(">>> data", data)
            },
        }
    });

    const { data } = queryResult;
    let recordData: any = data?.data ? data?.data : [];

    console.log(">>> recordData", recordData)

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "parcel-ins",
        metaData: {
            populate: [
                '*',
                'unit',
                'user_profile',
                'parcel_lists',
                'property',
                'createBy',
                'updateBy',
            ]
        },
        pagination: {
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
    });

    const [formList] = Form.useForm<any>();


    // const [disabled, setDisabled] = useState(false);

    // ปรับเป็นกดสร้าง parcel list ได้แค่ 1
    // เพราะงั้นตอนกด add list แล้วปิดปุ่ม add เลยคับ
    const [disabled, setDisabled] = useState(false);
    const [vUnitID, setUnitID] = useState<any>(0);

    const [vDeliverID, setDeliverID] = useState<any>(0);
    const [vBoxSizeID, setBoxSizeID] = useState<any>(0);
    const [vParcelTypeID, setParcelTypeID] = useState<any>(0);
    const [vShipingType, setShipingType] = useState<any>();

    const [vNote_NEW, setNote_NEW] = useState<any>('');
    const [vRfidcode_NEW, setRfidcode_NEW] = useState<any>('');
    const [vTrackingNo_NEW, setTrackingNo_NEW] = useState<any>('');

    const [vPixdata, setPixdata] = useState<any>([]);

    const [vBoxSizeArrC, setBoxSizeArrC] = useState<any>([]);
    const [vBoxSizeArrO, setBoxSizeArrO] = useState<any>([]);

    const [vDeliveryCompArrC, setDeliveryCompArrC] = useState<any>([]);
    const [vDeliveryCompArrO, setDeliveryCompArrO] = useState<any>([]);

    const [vParcelTypeArrC, setParcelTypeArrC] = useState<any>([]);
    const [vParcelTypeArrO, setParcelTypeArrO] = useState<any>([]);

    const [vTrackingNoArrC, setTrackingNoArrC] = useState<any>([]);
    const [vTrackingNoArrO, setTrackingNoArrO] = useState<any>([]);

    const [vNoteArrC, setNoteArrC] = useState<any>([]);
    const [vNoteArrO, setNoteArrO] = useState<any>([]);
    const [vRfidcodeArrC, setRfidcodeArrC] = useState<any>([]);
    const [vRfidcodeArrO, setRfidcodeArrO] = useState<any>([]);



    const [vShipingTypeArrC, setShipingTypeArrC] = useState<any>([]);
    const [vShipingTypeArrO, setShipingTypeArrO] = useState<any>([]);

    const [vMappingID, setMappingID] = useState<any>();

    const [vPic, setPic] = useState<any>('');

    const [vAnotherName, setAnotherName] = useState<any>('');
    const [vProfileID, setProfileID] = useState<any>();

    const [vPicArray, setPicArray] = useState<any>([]);

    const { selectProps: SelPropert } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selDeliver } = useSelect<IDeliveryComp>({
        resource: "delivery-companies",
        optionLabel: "title",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selBoxSize } = useSelect<IParcelBox>({
        resource: "parcel-box-sizes",
        optionLabel: "title",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selParcelType } = useSelect<IParcelType>({
        resource: "parcel-types",
        optionLabel: "title",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: SelUnit } = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: selShipingType } = useSelect<any>({
        resource: "parcel-shipping-types",
        optionLabel: "title",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ],
    });

    const UserHasUnit = useList<IUserhunit>({
        resource: "user-has-units",
        metaData: {
            populate: ['user_profile', 'user_sub_type', 'unit', 'unit.property'],
        },
        config: {
            filters: [
                {
                    field: "unit][id]",
                    operator: "eq",
                    value: vUnitID
                },
                {
                    field: "isOwnerConfirmed",
                    operator: "eq",
                    value: true
                },
                {
                    field: "isAdminConfirmed",
                    operator: "eq",
                    value: true
                },
                {
                    field: "user_profile][id]",
                    operator: "ne",
                    value: null
                },
            ],
            pagination: { current: 1, pageSize: 100 },
        }
    });

    let vUserData: any = UserHasUnit?.data ? UserHasUnit?.data : [];

    if (vUserData.data !== null || vUserData.data !== undefined) {
        if (vUserData.total > 0) {
            const findRenter = vUserData.data.find((e: { user_sub_type: { sub_type_name: string; }; }) => e.user_sub_type.sub_type_name == "Renter");
            const findResidentRenter = vUserData.data.find((e: { user_sub_type: { sub_type_name: string; }; }) => e.user_sub_type.sub_type_name == "Resident Renter");
            if (findRenter !== undefined || findResidentRenter !== undefined) {
                let index = vUserData.data.findIndex((i: { user_sub_type: any; }) => i.user_sub_type.id === 1); // id 1 = OWNER
                if (index !== -1) {
                    vUserData.data.splice(index, 1)
                }
            }
        }
    }

    const parcelListData = useList<IParcelList>({
        resource: "parcel-lists",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "parcel_in][id]",
                    operator: "in",
                    value: recordData.id
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        },
        queryOptions: {
            onSuccess(data) {
                console.log('data', data?.data)
                // let arr: any[] = []
                // for (let i = 0; i < data.data.length; i++) {
                //     if(data.data[i].pic){
                //         arr.push(data.data[i].pic)
                //     }else{
                //         arr.push([])
                //     }
                // }

                if (data?.data?.length > 0) {
                    // ปรับเป็นกดสร้าง parcel list ได้แค่ 1
                    // เพราะงั้นตอนกด add list แล้วปิดปุ่ม add เลยคับ
                    setDisabled(true)
                }

                // setFileList(arr)
                form.setFieldsValue({
                    k_tracking_no: data?.data ? data?.data?.length > 0 ? data?.data[0]?.tracking_no : null : null
                    // k_tracking_no: "xxxxx"
                })
                form.setFieldsValue({
                    fieldsxx: {
                        k_tracking_no: data?.data ? data?.data?.length > 0 ? data?.data[0]?.tracking_no : null : null
                    }
                });

            }
        }
    });

    const { data: parcelListDataMap } = parcelListData;
    let parcelListQuery: any = parcelListDataMap?.data ? parcelListDataMap?.data : [];

    // console.log('parcelListQuery', parcelListQuery)
    useEffect(() => {
        const data: any = parcelListData.data?.data ?? []
        const filePic = data.map((val: any) => val.pic ? val.pic.map((pic: any) => ({
            id: pic.id,
            uid: String(pic.id),
            name: pic.name,
            status: "done",
            url: pic.url,
            thumbUrl: pic.url
        })) : [])
        setPixdata(filePic)
        callFetchData()
    }, [parcelListData.data?.data])

    function callFetchData() {
        form.resetFields();
        count_Arr = parcelListQuery && parcelListQuery?.length;
        setUnitID(recordData?.unit ? recordData?.unit?.id : undefined);

        setAnotherName(recordData?.customer_name ? recordData?.customer_name : '');
        setProfileID(recordData?.user_profile ? recordData?.user_profile?.id : null)

        form.setFieldsValue({
            property:
                recordData?.unit ?
                    recordData?.unit?.property.property_name
                    : '-',
            unit:
                recordData?.unit ?
                    recordData?.unit?.unit_name
                    : '-',
            user_profiles:
                recordData?.user_profile ?
                    recordData?.user_profile?.first_name + ' ' + recordData?.user_profile?.last_name
                    : '-',
            note: recordData?.note ? recordData?.note : '-',
            customer_name: recordData?.customer_name ? recordData?.customer_name : null,
            create_date: recordData?.create_date ? recordData?.create_date : null,
            k_tracking_no: parcelListQuery ? parcelListQuery?.length > 0 ? parcelListQuery[0]?.tracking_no : null : null
            // k_tracking_no: "xxxxx"
        })

        form.setFieldsValue({
            fieldsxx: {
                k_tracking_no: parcelListQuery ? parcelListQuery?.length > 0 ? parcelListQuery[0]?.tracking_no : null : null
            }
        });

        setParcelTypeArrO(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    // console.log('val', v)
                    // return v.parcel_type.id
                    return v.parcel_type ? v.parcel_type.id : null
                })
                : []
            : []
        )

        setBoxSizeArrO(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    // return v.parcel_box_size.id
                    return v.parcel_box_size ? v.parcel_box_size.id : null
                })
                : []
            : []
        )

        setDeliveryCompArrO(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    // return v.delivery_company.id
                    return v.delivery_company ? v.delivery_company.id : null
                })
                : []
            : []
        )

        setTrackingNoArrO(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    return v.tracking_no ? v.tracking_no : null
                })
                : []
            : []
        )

        setNoteArrO(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    return v.note ? v.note : null
                })
                : []
            : []
        )
        setRfidcodeArrO(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    return v.rfidcode ? v.rfidcode : null
                })
                : []
            : []
        )

        setShipingTypeArrO(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    return v?.parcel_shipping_type ? v?.parcel_shipping_type?.id : null
                })
                : []
            : []
        )

        setMappingID(parcelListQuery !== undefined ?
            parcelListQuery?.length > 0 ?
                parcelListQuery?.map((v: any) => {
                    return v.id ? v.id : null
                })
                : []
            : []
        )
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function toggleDisabled() {
        // setDisabled(!disabled);

        // ปรับเป็นกดสร้าง parcel list ได้แค่ 1
        // เพราะงั้นตอนกด add list แล้วปิดปุ่ม add เลยคับ
        setDisabled(true)
    }

    function fileChange(e: any, i: any) {
        if (e.file.response) {
            let res = e.file.response;
            setPic(res[0].id)
        }
    }

    function onChangeParcelType(v: any, i: any) {
        // setBoxSizeID(value);
        setParcelTypeArrO((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    function onChangeParcelType_NEW(value: any, index: any) {
        setParcelTypeID(value);
        onSetParcelTypeCreate(value, index)
    }

    function onSetParcelTypeCreate(v: any, i: any) { // on new
        setParcelTypeArrC((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    // ************* BOX SIZE *************
    function onChangeBoxSize(v: any, i: any) {
        // setBoxSizeID(value);
        setBoxSizeArrO((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    function onChangeBoxSize_NEW(value: any, index: any) {
        setBoxSizeID(value);
        onSetBoxSizeCreate(value, index)
    }

    function onSetBoxSizeCreate(v: any, i: any) { // on new
        setBoxSizeArrC((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    // ************* DELIVERY COMP *************
    function onChangeDeliver(v: any, i: any) {
        setDeliveryCompArrO((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    function onChangeDeliver_NEW(value: any, index: any) {
        setDeliverID(value);
        onSetDeliverCreate(value, index)
    }

    function onSetDeliverCreate(v: any, i: any) { // on new
        setDeliveryCompArrC((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }


    // ************** TRACKING NO **************
    function onChangeTrackingNo(v: any, i: any) {
        setTrackingNoArrO((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    // ************** NOTE **************
    function onChangeNote(v: any, i: any) {
        setNoteArrO((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }
    // ************** RFIDCODE **************
    function onChangeRfidcode(v: any, i: any) {
        setRfidcodeArrO((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }




    function fileChangeOld(e: any, i: any) {
        console.log(e, 'e');
        console.log(i, 'i');


        if (e.file != undefined) {
            if (e.file.response) {
                let res = e.file.response;
                const aa = vPixdata[i].filter((e: any) => e.id == res[0].id)

                if (aa.length == 0) {
                    setPic(res[0].id)
                } else {
                    const Newdata = vPixdata[i].filter((e: any) => e.id !== res[0].id)
                    setPixdata((pre: any) => ([...Newdata]))
                }

                if (vPixdata.length > 0) {
                    let fill = vPixdata[i].filter((f: any) => { return f.id !== res[0].id })
                    fill.push(res[0])
                    vPixdata[i] = fill
                }
            }
        }
        else if (e.uid) {
            let res = e.uid;
            if (vPixdata[i].length > 0) {

                const aa = vPixdata[i].filter((e: any) => e.id !== Number(res))
                vPixdata[i] = aa
                setPixdata(vPixdata)

                for (let i = 0; i < vPixdata.length; i++) {
                    console.log(vPixdata[i])
                    if (vPixdata[i].length > 0) {
                        // fileList(i)
                    }
                }
            }
        }
    }

    // ************** SHIPPINGTYPE **************
    function onChangeShipingType(v: any, i: any) {
        setShipingTypeArrO((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    function onChangeShipingType_NEW(value: any, index: any) {
        setShipingType(value);
        onSetShipingTypeCreate(value, index)
    }

    function onSetShipingTypeCreate(v: any, i: any) { // on new
        setShipingTypeArrC((pre: any) => pre.map((val: any, index: any) => {
            if (index === i) {
                return v
            }
            return val
        }))
    }

    // ********* set ของลง Array ********* 
    function setTrackingNoArr() {
        vTrackingNoArrC.push(vTrackingNo_NEW);
        setTrackingNoArrC(vTrackingNoArrC);
    }

    function setParcelTypeArr() {
        vParcelTypeArrC.push(vParcelTypeID);
        setParcelTypeArrC(vParcelTypeArrC);
    }

    function setBoxSizeArr() {
        vBoxSizeArrC.push(vBoxSizeID);
        setBoxSizeArrC(vBoxSizeArrC);
    }

    function setDeliveryArr() {
        vDeliveryCompArrC.push(vDeliverID);
        setDeliveryCompArrC(vDeliveryCompArrC);
    }

    function setNoteArr() {
        vNoteArrC.push(vNote_NEW);
        setNoteArrC(vNoteArrC);
    }
    function setRfidArr() {
        vRfidcodeArrC.push(vRfidcode_NEW);
        setRfidcodeArrC(vRfidcodeArrC);
    }

    function setShippingTypeArr() {
        vShipingTypeArrC.push(vShipingType);
        setShipingTypeArrC(vShipingTypeArrC);
    }

    function setPicArr() {
        vPicArray.push(vPic);
        setPicArray(vPicArray);
    }

    function clearVal() {
        setDeliverID(undefined)
        setBoxSizeID(undefined)
        setParcelTypeID(undefined)
        setPic(undefined)
        setShipingType(undefined)
    }

    function toggleDisabledX() {
        if (disabled == true) {
            setDisabled(!disabled);
        }
    }

    function mutateParcelList(valueID: any) {
        console.log(vPicArray, 'vPicArray');

        if (vTrackingNoArrC.length > 0) {
            for (let i = 0; i <= vTrackingNoArrC.length - 1; i++) {
                createParcelList({
                    resource: "parcel-lists",
                    values: {
                        tracking_no: vTrackingNoArrC[i],
                        parcel_type: vParcelTypeArrC[i],
                        parcel_box_size: vBoxSizeArrC[i],
                        parcel_shipping_type: vShipingTypeArrC[i],
                        delivery_company: vDeliveryCompArrC[i],
                        note: vNoteArrC[i],
                        rfidcode: vRfidcodeArrC[i],
                        parcel_status: "WAITING",
                        pic: vPicArray[i],
                        parcel_in: valueID
                    },
                    successNotification: false, errorNotification: false
                });

                refetch();
            }
        }
    }

    function mutateUpdateParcelList(valueID: any) {
        console.log(vPixdata, 'vPixdata');
        if (json) {
            console.log(json);
            let data: any = null;
            json.forEach((el: any) => {
                data = {
                    id: el.id,
                    name: el.name,
                    status: 'done',
                    thumbUrl: el.url,
                    uid: '' + el.id,
                    url: el.url
                }
            })

            // let a = {
            //     id
            // }
            console.log(vPixdata);
            vPixdata[0].push(data);
            console.log(vPixdata);

        }

        if (vTrackingNoArrO.length > 0) {
            for (let i = 0; i <= vTrackingNoArrO.length - 1; i++) {
                // console.log('xx')
                updateParcelList({
                    resource: "parcel-lists",
                    id: vMappingID[i],
                    values: {
                        tracking_no: vTrackingNoArrO[i],
                        parcel_type: vParcelTypeArrO[i],
                        parcel_box_size: vBoxSizeArrO[i],
                        parcel_shipping_type: vShipingTypeArrO[i],
                        delivery_company: vDeliveryCompArrO[i],
                        note: vNoteArrO[i],
                        rfidcode: vRfidcodeArrO[i],
                        pic: vPixdata[i],
                        parcel_status: "WAITING",
                        parcel_in: valueID
                    },
                    successNotification: false, errorNotification: false
                });

                refetch();
            }
        }
    }
    useEffect(() => {
        console.log('1');
        console.log(json);

        if (imageUrl) {
            let d = {};
            json?.forEach((el: any) => {
                console.log(el);

                d = el
            })
            console.log(d);

            uploadlist.push(d)
            let result: any = {
                file: d,
                fileList: uploadlist
            }
            fileChangeOld(result, 0); // เรียกใช้ฟังก์ชันหาก imageUrl มีค่า
        }
    }, [json]); // ระบุ imageUrl เป็น dependency

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{ extra: <></> }}>
            <List
                title={t("parcel-in.title-edit")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item ><span style={{ color: "#cdba8c" }}>{t("parcel-in.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />

            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    values.updateBy = user_data.user_profile.id
                    values.user_profile = vProfileID
                    values.note = values.note
                    values.rfidcode = values.rfidcode
                    values.customer_name = vAnotherName

                    values.property = recordData.property.id;
                    values.unit = recordData.unit.id;

                    mutateParcelList(recordData.id);
                    mutateUpdateParcelList(recordData.id);

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={24}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={12}>

                                <Col className="gutter-row col" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                    <Form.Item
                                        // label={t("policy.policy_code")}
                                        label="Property"
                                        name="property"
                                        rules={[{ required: true, message: 'Please select property' },]}
                                    >
                                        <Select
                                            {...SelPropert}
                                            placeholder="Select property"
                                            size="middle"
                                            style={{ borderRadius: 20, width: '100%', position: 'relative' }}
                                            allowClear
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                    <Form.Item
                                        label="Unit"
                                        name="unit"
                                        rules={[{ required: true, message: 'Please select unit' },]}
                                    >
                                        <Select {...SelUnit}
                                            placeholder="Select unit"
                                            size="middle"
                                            style={{ borderRadius: 20, width: '100%', position: 'relative' }}
                                            value={vUnitID}
                                            allowClear
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                    <Form.Item
                                        label="Receiver Name "
                                        name="user_profiles"
                                    >
                                        <Select placeholder={t("select.sel")}
                                            style={{ width: "100%" }}
                                            onSelect={(value: any, label: any) => {
                                                setProfileID(value)
                                            }}
                                        // defaultValue={vShowUser}
                                        >
                                            {
                                                vUserData !== undefined && vUserData.data !== undefined ?
                                                    vUserData.data.map((item: any, key: any) => {
                                                        return <>
                                                            <Option
                                                                value={item.user_profile.id}
                                                            >
                                                                {item.user_profile ? item.user_profile.first_name : ''}{' '}{item.user_profile ? item.user_profile.last_name : ''}
                                                            </Option>
                                                        </>
                                                    })
                                                    : null
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                    <Form.Item
                                        label="Another name"
                                        name="customer_name"
                                    >
                                        <Input onChange={(e) => setAnotherName(e.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 14}>
                                    <Form.Item
                                        label="Note"
                                        name="note"
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={isMobile ? 24 : isTablet ? 12 : 10}>
                                    <Form.Item label="Received Date"
                                        name="create_date"
                                        getValueProps={(value) => ({
                                            value: value ? dayjs(value) : "",
                                        })}
                                    >
                                        <DatePicker
                                            showTime
                                            format={format}
                                            style={{ width: "100%" }}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Divider />

                <Form.List
                    // name="fields"
                    name="fieldsxx"
                // form={formList}
                >
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                <Row gutter={12}>
                                    <Col className="gutter-row" span={8}>
                                        <Form.Item>
                                            <span style={{ fontSize: "19px", color: "#000", paddingLeft: isMobile ? "0px" : "24px" }}>Parcel list</span>
                                        </Form.Item>
                                    </Col>

                                    {/* <Col className="gutter-row" span={2}>
                                        <Form.Item>
                                            {
                                                disabled ?
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => { add(); toggleDisabled(); }}
                                                        style={{ width: "100%", borderRadius: "10px", border: "1px solid #ddd", fontSize: "0.7vw" }}
                                                        disabled
                                                    >
                                                        <Icons.PlusOutlined style={{ color: "#FFFFFF" }} /> <span style={{ color: "#FFFFFF" }}>Add</span>
                                                    </Button>
                                                    :
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => { add(); toggleDisabled(); }}
                                                        style={{ backgroundColor: "#40495F", width: "100%", borderRadius: "10px", border: "1px solid #ddd", fontSize: "0.7vw" }}
                                                    >
                                                        <Icons.PlusOutlined style={{ color: "#FFFFFF" }} /> <span style={{ color: "#FFFFFF" }}>Add</span>
                                                    </Button>
                                            }
                                        </Form.Item>
                                    </Col> */}

                                    <Col className="gutter-row" span={2} />
                                </Row>

                                {parcelListQuery !== null && parcelListQuery !== undefined && parcelListQuery &&
                                    parcelListQuery.length > 0 && parcelListQuery?.map((el: any, index: any) => {
                                        return <>
                                            <div key={index} style={{ borderRadius: 20, border: "1px solid #ddd", marginBottom: '1%', paddingTop: isMobile || isTablet ? 0 : "1.5rem", paddingLeft: "24px", paddingRight: "24px" }}>
                                                <Row gutter={12}>
                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 24 : 3}><div style={{ float: 'left', marginTop: "2rem", fontSize: isTablet || isMobile ? "19px" : "1.0vw", marginBottom: isTablet || isMobile ? '10px' : 0 }}><span> รายการที่ {index + 1}.</span></div> </Col>

                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 5}>
                                                        <Form.Item
                                                            name="k_tracking_no"
                                                            // name={[index, 'tracking_no']}
                                                            label="Tracking Number"
                                                            rules={[{ required: true, message: 'Please enter tracking no.' },]}
                                                        >
                                                            <Input
                                                                // value={el.tracking_no !== null ? el.tracking_no : null}
                                                                defaultValue={el.tracking_no !== null ? el.tracking_no : null}
                                                                onChange={(e) => onChangeTrackingNo(e.target.value, index)}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                                                        <Form.Item
                                                            label="Parcel Type"
                                                        >
                                                            <Select
                                                                defaultValue={el.parcel_type !== null ? el.parcel_type.title : null}
                                                                {...selParcelType}
                                                                onChange={(val) => onChangeParcelType(val, index)}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                                                        <Form.Item
                                                            label="Box Size"
                                                        >
                                                            <Select
                                                                defaultValue={el.parcel_box_size !== null ? el.parcel_box_size.title : null}
                                                                {...selBoxSize}
                                                                onChange={(val) => onChangeBoxSize(val, index)}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                                                        <Form.Item
                                                            label="Shiping Type"
                                                        // rules={[{required: true, message: 'Please select parcel type'},]}
                                                        >
                                                            <Select
                                                                defaultValue={el.parcel_shipping_type ? el.parcel_shipping_type.title : null}
                                                                {...selShipingType}
                                                                onChange={(val) => onChangeShipingType(val, index)}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                                                        <Form.Item
                                                            label="Delivery Company"
                                                        >
                                                            <Select
                                                                defaultValue={el.delivery_company !== null ? el.delivery_company.title : '-'}
                                                                {...selDeliver}
                                                                onChange={(val) => onChangeDeliver(val, index)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={12}>
                                                    {/* <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 2 : 2} /> */}
                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                                        <Form.Item
                                                            name="pic"
                                                            label="Parcel Images"
                                                        >
                                                            <Upload.Dragger
                                                                style={{ borderRadius: 10 }}
                                                                name="files"
                                                                action={`${API_URL}/upload`}
                                                                headers={{
                                                                    Authorization: `Bearer ${localStorage.getItem(
                                                                        TOKEN_KEY,
                                                                    )}`,
                                                                }}
                                                                defaultFileList={vPixdata[index]}
                                                                listType="picture"
                                                                multiple
                                                                onChange={(e) => { fileChangeOld(e, index) }}
                                                                onRemove={(e) => { fileChangeOld(e, index) }}
                                                                //   disabled={checked} 
                                                                beforeUpload={beforeUpload}
                                                            >
                                                                <p>Upload</p>
                                                            </Upload.Dragger>
                                                        </Form.Item>
                                                        {!isMobile && !isTablet &&
                                                            <Form.Item label='Parcel images for snapshot'>
                                                                <Col className="gutter-row" span={24}>
                                                                    <Button type="primary" style={{ borderRadius: 10, height: 50, width: "100%", textAlign: "center" }} onClick={handleCapture}>
                                                                        Open Webcam
                                                                    </Button>
                                                                    {showCamera && <WebcamComponent setShowCamera={setShowCamera} setImageUrl={setImageUrl} setchecked={setChecked} setjson={setjson} />}

                                                                    {imageUrl && (
                                                                        <Card
                                                                            hoverable
                                                                            style={{ width: 240, marginTop: 6 }}
                                                                            cover={<img alt="Captured" src={imageUrl} />}
                                                                            actions={[
                                                                                <Button key="delete" type="primary" onClick={handleDelete}>
                                                                                    Delete
                                                                                </Button>
                                                                            ]}
                                                                        />
                                                                    )}
                                                                </Col>
                                                            </Form.Item>
                                                        }

                                                    </Col>

                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 7}>
                                                        <Form.Item
                                                            label="Note"
                                                        >
                                                            <Input
                                                                defaultValue={el.note !== null ? el.note : '-'}
                                                                onChange={(e) => onChangeNote(e.target.value, index)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                                        <Form.Item
                                                            label="Rfid"
                                                        >
                                                            <Input
                                                                defaultValue={el.rfidcode !== null ? el.rfidcode : '-'}
                                                                onChange={(e) => onChangeRfidcode(e.target.value, index)}
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 3}>
                                                        <div style={{ marginTop: '20%' }}>
                                                            <Button
                                                                size="small"
                                                                className="dynamic-delete-button"
                                                                disabled
                                                                danger
                                                            >
                                                                <Icons.CloseOutlined />
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div style={{ marginBottom: "1vh", }}></div>
                                            </div>
                                        </>
                                    })
                                }

                                {/* ของที่กด add มาใหม่ */}
                                {/* function ใช้ชื่อตามด้วย _NEW นะ */}
                                {fields.map((field, index: any) => (


                                    <Card key={field.key} style={{ borderRadius: 20, border: "1px solid #ddd", marginBottom: '1%', paddingTop: "1.5rem" }}>

                                        <Row gutter={12}>

                                            <Col className="gutter-row" span={2}> <div style={{ float: 'left', marginTop: "2rem", fontSize: "0.7vw", paddingLeft: "1.5rem" }}><span> รายการที่ {parseInt(index + 1) + parseInt(count_Arr)}.</span></div> </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    label="Tracking Number"
                                                    name={[index, 'tracking_no']}
                                                // rules={[{ required: true, message: 'Please enter tracking no.' },]}
                                                >
                                                    <Input
                                                        onChange={(e) => setTrackingNo_NEW(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <Form.Item
                                                    label="Parcel Type"
                                                    name={[index, 'parcel_type']}
                                                // rules={[{ required: true, message: 'Please select parcel type' },]}
                                                >
                                                    <Select {...selParcelType} onChange={onChangeParcelType_NEW} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <Form.Item
                                                    label="Box Size"
                                                    name={[index, 'parcel_box_size']}
                                                // rules={[{ required: true, message: 'Please select parcel box size' },]}
                                                >
                                                    <Select {...selBoxSize} onChange={onChangeBoxSize_NEW} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={4}>
                                                <Form.Item
                                                    label="Shiping Type"
                                                    name={[index, 'parcel_shipping_type']}
                                                // rules={[{required: true, message: 'Please select parcel type'},]}
                                                >
                                                    <Select {...selShipingType} onChange={onChangeShipingType_NEW} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={4}>
                                                <Form.Item
                                                    label="Delivery Company"
                                                    name={[index, 'delivery_company']}
                                                // rules={[{ required: true, message: 'Please select delivery company' },]}
                                                >
                                                    <Select {...selDeliver} onChange={onChangeDeliver_NEW} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={12}>
                                            <Col span={2} />

                                            <Col span={6}>
                                                <Form.Item
                                                    name={[index, 'pic']}
                                                    label="Parcel Image"
                                                >
                                                    <Upload.Dragger
                                                        style={{ borderRadius: 10 }}
                                                        name="files"
                                                        action={`${API_URL}/upload`}
                                                        headers={{
                                                            Authorization: `Bearer ${localStorage.getItem(
                                                                TOKEN_KEY,
                                                            )}`,
                                                        }}
                                                        listType="picture"
                                                        multiple
                                                        onChange={(e) => fileChange(e, index)}
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        <p>Upload</p>
                                                    </Upload.Dragger>
                                                </Form.Item>
                                            </Col>

                                            <Col span={7}>
                                                <Form.Item
                                                    label="Note"
                                                    name={[index, 'note']}
                                                >
                                                    <Input onChange={(e) => setNote_NEW(e.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item
                                                    label="Rfid"
                                                    name={[index, 'rfidcode']}
                                                >
                                                    <Input onChange={(e) => setRfidcode_NEW(e.target.value)} />
                                                </Form.Item>
                                            </Col>


                                            <Col span={3}>
                                                <div style={{ marginTop: '20%' }}>
                                                    {
                                                        vDeliverID !== undefined && vBoxSizeID !== undefined && vParcelTypeID !== undefined && vTrackingNo_NEW !== '' && vPic !== undefined &&
                                                        // vTrackingNo_NEW !== '' &&
                                                        <>
                                                            {
                                                                fields.length == index + 1 ?
                                                                    <Button
                                                                        size="small"
                                                                        className="dynamic-delete-button"
                                                                        // onClick={() => { onsetPropArr(); onsetRoleArr(); onsetPositionArr(); clearArr(); toggleDisabled();}}
                                                                        onClick={() => { setDeliveryArr(); setBoxSizeArr(); setParcelTypeArr(); setTrackingNoArr(); setNoteArr(); setRfidArr(); setPicArr(); setShippingTypeArr(); clearVal(); toggleDisabled(); }}
                                                                        danger
                                                                        style={{ marginRight: "3%" }}
                                                                    >
                                                                        <Icons.CheckOutlined />
                                                                    </Button>
                                                                    : null
                                                            }
                                                        </>
                                                    }

                                                    {
                                                        fields.length > 0 && (
                                                            <Button
                                                                size="small"
                                                                className="dynamic-delete-button"
                                                                onClick={() => { remove(field.name); clearVal(); toggleDisabledX(); }}
                                                                danger
                                                            >
                                                                <Icons.CloseOutlined />
                                                            </Button>
                                                        )
                                                    }
                                                </div>
                                            </Col>
                                        </Row>

                                        <div style={{ marginBottom: "1vh" }}></div>
                                    </Card>
                                ))}
                            </div>
                        );
                    }}
                </Form.List>
            </Form>
        </Edit>
    );
};