/* eslint-disable */
import { Avatar, Breadcrumb, Card, Col, Divider, List, Row, Show, Space, Table, TextField, Tooltip, Typography } from "@pankod/refine-antd";

import { IUserhunit, IUserProfile, IUserTypeMap } from "interfaces";

import { useList, useOne, useShow, useTranslate } from "@pankod/refine-core";

import { useEffect, useState } from "react";
import "./css/style.css";

import { AuditOutlined, IdcardOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";

export const UserManageShow = () => {
    const { queryResult } = useShow<IUserProfile>({
        metaData: {
            populate: '*'
        }, 
    });

    const { data, isLoading } = queryResult;
    const record = data?.data;

    let recordData  :   any;
    recordData = queryResult?.data ? queryResult?.data.data : [];

    const { Text } = Typography;
    const t = useTranslate();

    const [count, setCount] = useState(0)
    const [vCompany, setCompany] = useState<any>();
    const [vDepartment, setDepartment] = useState<any>();

    const typeMap = useList<IUserTypeMap>({
        resource: "user-type-mappings",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "user_profile][id]",
                    operator: "in",
                    value: recordData.id
                },
                {
                    field: "status",
                    operator: "in",
                    value: true
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        }
    });

    const { data: typemap } = typeMap;
    let TypeMapData: any = []
    TypeMapData = typemap?.data ? typemap?.data : [];

    //console.log(">>> recordData",recordData)

    useEffect(() => {
        if (count < 3) {
            const interval = setInterval(() => {
                setCount(prev => prev + 1);

                if (recordData?.user_type?.type_name == "Admin") {

                    let GetCompanyNAME
                    let GetDepartmentNAME

                    TypeMapData !== undefined ?
                        TypeMapData?.length > 0 ?
                            TypeMapData?.map((v: any) => {
                                GetCompanyNAME = v.company.company_name
                                GetDepartmentNAME = v.department.dept_name
                            })
                            : <></>
                        : <></>

                    if (GetCompanyNAME !== undefined && GetCompanyNAME !== null) {
                        setCompany(GetCompanyNAME)
                    }

                    if (GetDepartmentNAME !== undefined && GetDepartmentNAME !== null) {
                        setDepartment(GetDepartmentNAME)
                    }
                }

                if (recordData?.user_type?.type_name == "Staff") {

                    let GetCompanyNAME
                    let GetDepartmentNAME

                    TypeMapData !== undefined ?
                        TypeMapData?.length > 0 ?
                            TypeMapData?.map((v: any) => {
                                GetCompanyNAME = v.company.company_name
                                GetDepartmentNAME = v.department.dept_name
                            })
                            : <></>
                        : <></>

                    if (GetCompanyNAME !== undefined && GetCompanyNAME !== null) {
                        setCompany(GetCompanyNAME)
                    }

                    if (GetDepartmentNAME !== undefined && GetDepartmentNAME !== null) {
                        setDepartment(GetDepartmentNAME)
                    }
                }

            }, 1000)
            return () => clearInterval(interval)
        }
    },)

    //console.log(">>> TypeMapData",TypeMapData)

    const dataSource = 
    TypeMapData?.length > 0 ?
        TypeMapData.map((el:any,index:any) => (
            {
                key: String(el.id),
                prop: el.property !== null && el.property !== undefined ? el.property.property_name : "ไม่ระบุ",
                role: el.role_management !== null && el.role_management !== undefined ? el.role_management.custom_role_name : "ไม่ระบุ",
                position: el.user_sub_type !== null  && el.user_sub_type !== undefined ? el.user_sub_type.sub_type_name : "ไม่ระบุ",
            }
        ))
    :<></>;


    const columns = [
        {
            title: 'Properties',
            dataIndex: 'prop',
            key: 'prop_k',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role_k',
        },
        {
            title: 'Position',
            dataIndex: 'position',
            key: 'position_k',
        },
      ];
    
    const categoryQueryResult = useOne<IUserhunit>({
        metaData: {
            populate: '*'
        },
        resource: "user-has-units",
        id: '',
    });
    let ll:any = categoryQueryResult?.data?.data ? categoryQueryResult?.data?.data : [];

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>
                <List 
                    title={t("")}
                    pageHeaderProps={{ 
                        extra : <></>
                    }}
                />

                <Space style={{marginBottom: "3%"}}>
                    <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("user-management.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("user-profiles-show.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={13}>
                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset"}}>
                            <Row>
                                <Col span={5}>
                                    {
                                        record?.user !== undefined ?
                                            <>
                                                {/* <Avatar 
                                                    style={{border:"2px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)", borderRadius : "unset"}}
                                                    src={record?.avatar === null ? "https://joeschmoe.io/api/v1/random" :record?.avatar.url} 
                                                /> */}
                                                <Avatar 
                                                    style={{border:"2px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)", borderRadius : "unset"}}
                                                    src={record?.avatar === null ? "https://lscmain.s3.ap-southeast-1.amazonaws.com/default_user_2762d82fb7.png?updated_at=2023-01-11T10:58:02.629Z" :record?.avatar.url} 
                                                />
                                            </>
                                        :    
                                            <>
                                                <Avatar 
                                                    style={{border:"2px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)", borderRadius : "unset"}}
                                                    src={"https://lscmain.s3.ap-southeast-1.amazonaws.com/default_user_2762d82fb7.png?updated_at=2023-01-11T10:58:02.629Z"} 
                                                />
                                            </>
                                            
                                    }
                                </Col>
                                    
                                <Col span={19}>
                                    <div className="userprofile_mleft">
                                        <div id="userprofile_n">
                                            <TextField value={record?.first_name ? record?.first_name : '-'} style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",marginRight: "3%"}} />
                                            <TextField value={record?.last_name ? record?.last_name : '-'} style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px"}} />
                                        </div>

                                        <div style={{marginBottom: "5%",marginTop: "2%",borderRadius: "10px"}}>
                                            <span className="id-userprofile"><Text style={{borderRadius: "50px 0px 0px 50px",paddingLeft: "3%",boxShadow: "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 0px 10px -10px"}}>ID</Text>
                                                <TextField style={{borderRadius: "0px 50px 50px 0px", paddingLeft: "2%", paddingRight: "3%", boxShadow: "rgb(50 50 93 / 25%) -2px 6px 10px -2px, rgb(0 0 0 / 30%) 0px 0px 20px -10px"}} value={record?.id ? record?.id : '-'} />
                                            </span>

                                            <span style={{marginLeft: "2%"}}>
                                                {
                                                    record?.user_type.type_name == "Staff" ?
                                                        <>
                                                        <TextField 
                                                        style={{
                                                            fontSize: "0.8vw",
                                                            color: "#fff",
                                                            marginTop: "10%",

                                                            borderRadius: "50px", 
                                                            paddingLeft: "3%", 
                                                            paddingRight: "3%",
                                                            paddingBottom: "1%",
                                                            paddingTop: "1%",
                                                            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                            background: "#B29C65"
                                                        }} 
                                                            value={record?.user_type!==undefined ? record?.user_type.type_name : '-'} />
                                                        </>       
                                                        :
                                                        <>
                                                            <TextField 
                                                            style={{
                                                                fontSize: "0.8vw",
                                                                color: "#fff",
                                                                marginTop: "10%",

                                                                borderRadius: "50px", 
                                                                paddingLeft: "2%", 
                                                                paddingRight: "2%",
                                                                paddingBottom: "1%",
                                                                paddingTop: "1%",
                                                                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                                background: "#ACACAC"
                                                            }} 
                                                                value={record?.user_type!==undefined ? record?.user_type.type_name : '-'}/>
                                                        </>
                                                }
                                            </span>

                                            {/* <span style={{marginLeft: "2%"}}>
                                                {
                                                    record?.status == null ? <></>:

                                                    record?.status == "ACTIVE" ?

                                                        <span className="status_user_show"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={record?.status ? record?.status : '-'} /></span>
                                                        :
                                                        <span className="status_user_show_n"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={record?.status ? record?.status : '-'} /></span>
                                                }
                                            </span> */}

                                            <span style={{marginLeft: "2%"}}>
                                                {
                                                record?.deleteStatus == "N" ?
                                                    <span className="status_user_show"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={"ACTIVE"} /></span>
                                                    :
                                                    <span className="status_user_show_n"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={"INACTIVE"} /></span>
                                                }
                                            </span>

                                           
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    
                    <Col span={11} className="gutter-row">
                        <Card id="userprofile_top" style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                            <div style={{fontSize: "0.8vw"}}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={12} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                        <MailOutlined style={{fontSize: "0.7vw", marginRight: "5%",background: "#40495F",padding: "3% 3% 3%",borderRadius: "30px",color: "#fff"}} /><TextField style={{fontSize: "0.7vw"}} value={record?.user ? record?.user.email : '-'} />
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <PhoneOutlined style={{fontSize: "0.7vw",marginRight: "5%",background: "#40495F",padding: "3% 3% 3%",borderRadius: "30px",color: "#fff"}}/><TextField style={{fontSize: "0.7vw"}} value={record?.tel ? record?.tel : '-'}/>
                                    </Col>

                                    <Divider />

                                    <Col className="gutter-row" span={12}>
                                        <Tooltip placement="top" title={"Company"}>
                                            <IdcardOutlined style={{fontSize: "0.7vw",marginRight: "5%",background: "#40495F",padding: "3% 3% 3%",borderRadius: "30px",color: "#fff"}}/><TextField style={{fontSize: "0.7vw"}} value={vCompany}/>
                                        </Tooltip>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Tooltip placement="top" title={"Department"}>
                                            <AuditOutlined style={{fontSize: "0.7vw",marginRight: "5%",background: "#40495F",padding: "3% 3% 3%",borderRadius: "30px",color: "#fff"}}/><TextField style={{fontSize: "0.7vw"}} value={vDepartment}/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>

                    {/* {re} */}

                    <Col span={24} className="gutter-row">
                        {
                            record?.user_type.type_name == "Staff" ?
                            <>

                                {TypeMapData !== undefined ? TypeMapData.length > 0 ? 
                                    <Table dataSource={dataSource} columns={columns} bordered pagination={{hideOnSinglePage: false}}/>:<></>
                                :<></>}
                            </>
                            :
                            <>{record?.user_type.type_name == "Admin" ? 
                            <>
                                {TypeMapData !== undefined ? TypeMapData.length > 0 ? 
                                    <Table dataSource={dataSource} columns={columns} bordered pagination={{hideOnSinglePage: false}}/>:<></>
                                :<></>}
                            </>
                            :<></>}</>
                        }
                    </Col>
                </Row>

                <Row 
                    gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                    style={{ marginTop: "1%"}}
                ></Row>
        </Show>
    );
};