import { Breadcrumb, Card, Col, List, Row, Show, Space, TextField, Image, Avatar, Divider, Descriptions, Badge } from "@pankod/refine-antd";

import { IComment, ICoupon, ILikeSystem } from "interfaces";
// import { useTranslate, useShow } from "@pankod/refine-core";
import { useList, useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
// import {QRCodeCanvas} from 'qrcode.react';
// import { PhoneOutlined,ScheduleOutlined } from "@ant-design/icons";
// import { useState } from "react";
import ReactHtmlParser from 'react-html-parser';
import {  LikeOutlined, CommentOutlined } from "@ant-design/icons";



import Moment from 'moment';
import { useState } from "react";

export const ContentPostPrivilegeShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { queryResult } = useShow<ICoupon>({  
        metaData: {
        // populate: '*'
        populate: ['*','localizations','localizations.pic','properties','pic','user_sub_types','notice_channel_types','notice_customer_type','createBy','coupons','privilege_category','privilege_properties','privilege_sub_category', 'privilege_user_sub_type','updateBy','user_condition_sub_types' ]

    }, 
    });
    // const [vLikeCount, setLikeCount] = useState<any>("0");
    // const [count, setCount] = useState(1);

    const { data, isLoading } = queryResult;
    const record = data?.data;
    let recordData:any = []
    recordData = data?.data? data?.data : [];

    // console.log(">>> recordData",recordData)
    // console.log("recordData?.id", recordData?.id)
    // console.log("recordData?.localizations[0].id", recordData?.localizations[0].id)

    const LikeData = useList<ILikeSystem>({ 
        resource: "like-systems",
        metaData: {
        // populate: ['content_post_types'],
            populate: '*',
        },
        config: {
            pagination: { current: 1, pageSize: 100 },
            filters: [
                {
                    field: "privilege][id]",
                    // operator: "ncontains",
                    operator: "eq",
                    // value: recordData?.localizations ? recordData?.localizations[0].id + '' : ''
                    value: recordData ? recordData?.id + '' : ''
                },
                {
                    field: "status",
                    // operator: "ncontains",
                    operator: "eq",
                    value: "Like"
                }
            ],
        },
    });

    const CommentData = useList<IComment>({ 
        resource: "comments",
        metaData: {
            // populate: ['content_post_types'],
            populate: '*',
        },  
        config: {
            pagination: { current: 1, pageSize: 100 },
            filters: [
                {
                    field: "privilege][id]",
                    // operator: "ncontains",
                    operator: "eq",
                    // value: recordData?.localizations ? recordData?.localizations[0].id + '' : ''
                    value: recordData?.id ? recordData?.id + '' : ''
                }
            ],
        },
    });
    // console.log('CommentData', CommentData?.data ? CommentData?.data.total : 'xxxx')


    // console.log('LikeData', LikeData?.data)
    // console.log('LikeData 2', LikeData?.data ? LikeData?.data.total : 'xxxx')

    // useEffect(() => {
    //     if (count3 < 3) {
    //         const interval = setInterval(() => {
    //             setCount3(prev => prev + 1);
    //             // setLikeCount(LikeData?.data ? LikeData?.data.total : '0');
    //             // console.log('runnnnn')
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count3])


    // useEffect(() => {
    //     if (count < 3) { 
    //         const interval = setInterval(() => { 
    //             setCount(prev => prev + 1)
    //             setLikeCount(LikeData?.data ? LikeData?.data.total : '0');
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count])
    
    // const { Text } = Typography;

    // if(LikeData?.data !== undefined){
    //     // setLikeCount(LikeData?.data ? LikeData?.data.total : '0');
    //     setLikeCount(LikeData?.data.total);
    // }

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("")}
                pageHeaderProps={{ 
                    extra : <></>
                }}
            />

            <Space style={{marginBottom: "3%"}}>
                <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("coupon.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("coupon-show.title")}</span></Breadcrumb.Item>
                </Breadcrumb>
            </Space>
 
            <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={24}>
                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",border: "unset",marginBottom: "1%"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={18} style={{borderRight: "1px solid #ddd"}}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4} style={{borderRight: "1px solid #ddd"}}>
                                        {
                                            // recordData?.pic !== undefined || recordData?.pic !== null  ?
                                            recordData?.pic !== null ?
                                                recordData?.pic !== undefined ?
                                                    recordData.pic.length > 0 ?
                                                        recordData.pic.map((data:any,index:any) => {
                                                            return <>
                                                                <Image 
                                                                    style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                                                    marginLeft: "auto", marginRight: "auto",
                                                                    borderRadius: "10px"}}
                                                                    src={data.url} 
                                                                />
                                                            </>
                                                        }):<></>
                                                :
                                                <Avatar 
                                                    style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                                    marginLeft: "auto", marginRight: "auto",
                                                    background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",
                                                    borderRadius: "10px"}}
                                                    src={"https://joeschmoe.io/api/v1/random"} 
                                                />
                                            :
                                            <Avatar 
                                                style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                                marginLeft: "auto", marginRight: "auto",
                                                background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",
                                                borderRadius: "10px"}}
                                                src={"https://joeschmoe.io/api/v1/random"} 
                                            />
                                        }
                                        
                                    </Col>

                                    <Col className="gutter-row" span={20}>
                                        <TextField value={recordData.short_detail !== null ? recordData.short_detail:"ไม่มีเนื้อหา"} style={{fontSize: "1.2vw"}}/>

                                        <div style={{marginTop: "1%"}}>
                                            <span 
                                                style={{
                                                    fontSize: "0.8vw",

                                                    marginRight: "2%",
                                                    borderRadius: "50px", 
                                                    paddingLeft: "2%", 
                                                    paddingRight: "2%",
                                                    paddingBottom: "0.4",
                                                    paddingTop: "0.3%",
                                                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                    background: "rgb(125, 232, 101)",
                                                }} >
                                                    <TextField style={{color: "#fff"}} value={recordData.privilege_category !== null && recordData.privilege_category !== undefined ? 
                                                        recordData.privilege_category.pc_name !== null ? 
                                                            recordData.privilege_category.pc_name
                                                        :
                                                            "ไม่ระบุ"
                                                    :"ไม่ระบุ"} />
                                            </span>

                                            <span 
                                                style={{
                                                    fontSize: "0.8vw",

                                                    marginRight: "2%",
                                                    borderRadius: "50px", 
                                                    paddingLeft: "2%", 
                                                    paddingRight: "2%",
                                                    paddingBottom: "0.4",
                                                    paddingTop: "0.3%",
                                                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                    backgroundColor: "#EA973A",
                                                }} >
                                                    <TextField style={{color: "#fff"}} value={recordData.privilege_sub_category !== null && recordData.privilege_sub_category !== undefined ? 
                                                        recordData.privilege_sub_category.psc_name !== null ? 
                                                            recordData.privilege_sub_category.psc_name
                                                        :
                                                            "ไม่ระบุ"
                                                    :"ไม่ระบุ"} />
                                            </span>

                                            <span style={{
                                                fontSize: "0.8vw",

                                                marginRight: "2%",
                                                borderRadius: "50px", 
                                                paddingLeft: "2%", 
                                                paddingRight: "2%",
                                                paddingBottom: "0.4",
                                                paddingTop: "0.3%",
                                                border: "1px solid #b7eb8f",
                                                background: "#f6ffed",
                                                color: "#389e0d"
                                            }} >
                                                {recordData.status !== null && recordData.status !== undefined  ? 
                                                    recordData.status
                                                    :"ไม่ระบุ"
                                                }
                                            </span>

                                            {/* LIKE */}
                                            <span  
                                                style={{
                                                    fontSize: "0.8vw",
                                                    marginRight: "2%",
                                                    borderRadius: "50px", 
                                                    paddingLeft: "2%", 
                                                    paddingRight: "2%",
                                                    paddingBottom: "0.4",
                                                    paddingTop: "0.3%",
                                                    // boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                    // background: "rgb(216, 216, 216)",
                                                }} >
                                                    <LikeOutlined style={{color:'blue', fontSize:'18px'}}/>
                                                    {' '}
                                                    <Badge 
                                                        // count={vLikeCount} 
                                                        // count={'0'}
                                                        count={LikeData?.data ? LikeData?.data.total : 0} 
                                                        // style={{ backgroundColor: '#dddddd' }}
                                                        showZero
                                                    >
                                                    </Badge>
                                            </span>

                                            {/* COMMENT */}
                                            <span  
                                                style={{
                                                    fontSize: "0.8vw",
                                                    marginRight: "2%",
                                                    borderRadius: "50px", 
                                                    paddingLeft: "2%", 
                                                    paddingRight: "2%",
                                                    paddingBottom: "0.4",
                                                    paddingTop: "0.3%",
                                                    // boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                    // background: "rgb(216, 216, 216)",
                                                }} >
                                                    <CommentOutlined style={{color:'blue', fontSize:'18px'}}/>
                                                    {' '}
                                                    <Badge 
                                                        // count={vLikeCount} 
                                                        // count={'0'}
                                                        count={CommentData?.data ? CommentData?.data.total : 0} 
                                                        // style={{ backgroundColor: '#dddddd' }}
                                                        showZero
                                                    >
                                                    </Badge>
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                {/* <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div> */}
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={10}>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                        <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                    </Col>
                                                            
                                    <Col className="gutter-row" span={14}>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                        <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                                    </Col>
                                </Row>
                            </Col>

                            <Divider />

                            <Col className="gutter-row" span={12} style={{borderRight: "1px solid #ddd"}}>
                                <Descriptions layout="vertical" bordered>
                                    {recordData.privilege_event == true ? 
                                        <>
                                            <Descriptions.Item label="Privilege กิจกรรม">
                                            {recordData.limit_user == true ? 
                                            <>
                                                <div><TextField value={recordData.event_clout !== null ? recordData.event_clout:"ไม่ระบุ"} style={{fontSize: "0.7vw"}}/></div>
                                            </>
                                            :<>
                                                <div><TextField value={"- ไม่จำกัดจำนวนผู้เข้าร่วม"} style={{fontSize: "0.7vw"}}/></div>
                                            </>}

                                            {recordData.privilege_comment == true ? 
                                            <>
                                                <div><TextField value={"- เปิดการแสดงความคิดเห็น"} style={{fontSize: "0.7vw"}}/></div>
                                            </>
                                            :<>
                                                <div><TextField value={"- ปิดการแสดงความคิดเห็น"} style={{fontSize: "0.7vw"}}/></div>
                                            </>}
                                            </Descriptions.Item>
                                        </>
                                    :<></>}

                                    {recordData.privilege_coupon == true ? 
                                        <>
                                            <Descriptions.Item label="Privilege ที่สามารถกดรับ ตามโค้ดคูปอง / สิทธิ">
                                                {recordData.privilege_coupon == true ?
                                                <>
                                                    <div>
                                                        <TextField value={"Privilege แบบคูปอง"}/>
                                                        <span> จำนวน </span>
                                                        <TextField value={recordData.coupon_amout !== null && recordData.coupon_amout !== undefined ? recordData.coupon_amout:"0"}/>
                                                        <span> สิทธิ</span>
                                                    </div>

                                                        {
                                                            recordData?.coupons !== undefined ?
                                                                recordData.coupons.length > 0 ?
                                                                    recordData.coupons.map((data:any,index:any) => {
                                                                        console.log(">>> data",data)
                                                                        return <><div><TextField value={"- " + data.coupon_name}/></div></>
                                                                    })
                                                                :<></>
                                                            :<></>
                                                        }
                                                </>
                                                :<></>}

                                                {recordData.privilege_amount == true ? 
                                                <>
                                                    <div>
                                                        <TextField value={"Privilege แบบสิทธิ"}/>
                                                        <span> จำนวน </span>
                                                        <TextField value={recordData.coupon_amout !== null && recordData.coupon_amout !== undefined ? recordData.coupon_amout:"0"}/>
                                                        <span> สิทธิ</span>
                                                    </div>
                                                </>
                                                :<></>}

                                                <Divider />

                                                <div style={{fontWeight: "bold"}}>วิธีการคำนวนสิทธิ</div>
                                                
                                                {recordData.coupon_condition == true ? 
                                                <>
                                                    <div>
                                                        <TextField value={"- โควต้ารวมทุกโครงการ (First come first serve)"}/>
                                                    </div>
                                                </>
                                                :<>
                                                    {recordData.coupon_condition == false ? 
                                                    <>
                                                        <div>
                                                            <TextField value={"- แบ่งเท่า ๆ กัน แต่ละโครงการ"}/>
                                                        </div>
                                                    </>
                                                    :<></>}
                                                </>}

                                                <Divider />

                                                <div style={{fontWeight: "bold"}}>ระยะเวลา</div>

                                                <div>
                                                    <span>- เริ่มวันที่ : </span>
                                                    {/* <span>- Period การกดรับโค้ดคูปอง / สิทธิ : </span> */}
                                                    {recordData?.keep_coupon_start_date !== null && recordData?.keep_coupon_start_date !== undefined ? 
                                                    <><TextField value={Moment(recordData?.keep_coupon_start_date).format('D/MM/yyyy HH:mm')}/></>
                                                    :"ไม่ระบุ"}
                                                </div>
                                                <div>
                                                    <span>- ถึงวันที่ : </span>
                                                    {/* <span>- Period การกดใช้โค้ดคูปอง / สิทธิ : </span> */}
                                                    {recordData?.keep_coupon_end_date !== null && recordData?.keep_coupon_end_date !== undefined ? 
                                                    <><TextField value={Moment(recordData?.keep_coupon_end_date).format('D/MM/yyyy HH:mm')}/></>
                                                    :"ไม่ระบุ"}
                                                </div>
                                            </Descriptions.Item>
                                        </>
                                    :<></>}
                                    
                                    {console.log(">>> recordData",recordData)}
                                    
                                    <Descriptions.Item label="สิทธิการใช้งาน">
                                        {
                                            recordData?.privilege_properties && recordData?.privilege_properties?.length > 0 && recordData?.privilege_properties?.map((data:any,index:any) => {
                                                return <div key={index}><span>- </span><TextField value={data && data.property_name} style={{fontSize: "0.7vw"}}/></div>
                                            })
                                        }

{/* <><span className="tag_post">{" / "}</span><TextField value={data && data.property_name} /> <br/></> */}

                                        <Divider />

                                        {
                                            recordData?.privilege_user_sub_type && recordData?.privilege_user_sub_type?.length > 0 && recordData?.privilege_user_sub_type?.map((data:any,index:any) => {
                                                return <div key={index}><span>- </span><TextField value={data && data.sub_type_name} style={{fontSize: "0.7vw"}}/></div>
                                            })
                                        }

                                        {/* <Divider /> */}

                                        {/* {recordData.property_under !== null && recordData.property_under !== undefined ? 
                                            <>
                                                {recordData.property_under == "1" ? "- ทุกโครงการ":<></>}
                                                {recordData.property_under == "2" ? "- เฉพาะโครงการของ Major เท่านั้น":<></>}
                                                {recordData.property_under == "3" ? "- เฉพาะโครงการของ Non Major เท่านั้น":<></>}
                                                {recordData.property_under == "4" ? 
                                                    <>{
                                                        recordData?.privilege_properties !== undefined ?
                                                            recordData.privilege_properties.length > 0 ?
                                                                recordData.privilege_properties.map((data:any,index:any) => {
                                                                    return <><div><TextField value={"- " + data.property_name} style={{fontSize: "0.7vw"}}/></div></>
                                                                })
                                                            :<></>
                                                        :<></>
                                                    }</>
                                                :<></>}
                                            </>
                                        :<></>} */}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Descriptions layout="vertical" bordered>
                                    <Descriptions.Item label="เงื่อนไขการรับสิทธิ">
                                        {recordData.user_condition == true ? 
                                            <>
                                                {recordData.user_condition_value == "1" ? 
                                                    <>
                                                        <span>- 1 Unit ได้รับ</span><TextField value={recordData.user_condition_amount !== null ? recordData.user_condition_amount:"0"}/><span> สิทธิ</span>
                                                    </>
                                                    :<></>
                                                }

                                                {recordData.user_condition_value == "2" ? 
                                                    <>
                                                    <span>- 1 Thai ID / Passport ได้รับ</span><TextField value={recordData.user_condition_amount !== null ? recordData.user_condition_amount:"0"}/><span> สิทธิ</span>
                                                    </>
                                                    :<></>
                                                }

                                                {recordData.user_condition_value == "3" ?
                                                    <>
                                                        <div># ให้สิทธิเฉพาะ</div>
                                                        {
                                                            recordData?.user_condition_sub_types !== undefined ?
                                                                recordData.user_condition_sub_types.length > 0 ?
                                                                    recordData.user_condition_sub_types.map((data:any,index:any) => {
                                                                        return <><div><TextField value={"- " + data.sub_type_name} style={{fontSize: "0.7vw"}}/></div></>
                                                                    })
                                                                :<></>
                                                            :<></>
                                                        }
                                                    </>
                                                    :<></>
                                                }
                                                
                                                {recordData.user_condition_value == "4" ?
                                                    <>
                                                        <div># Account Type</div>
                                                        {
                                                            recordData?.user_condition_sub_types !== undefined ?
                                                                recordData.user_condition_sub_types.length > 0 ?
                                                                    recordData.user_condition_sub_types.map((data:any,index:any) => {
                                                                        return <><div><TextField value={"- " + data.sub_type_name} style={{fontSize: "0.7vw"}}/></div></>
                                                                    })
                                                                :<></>
                                                            :<></>
                                                        }
                                                    </>
                                                    :<></>
                                                }


                                                {/* ติดไว้ก่อน 10/10/2022 juuso.dev */}
                                                {recordData.user_condition_value == "5" ? "- สิทธิของแต่ละ Room Type":<></>} 

                                                {/* {recordData.user_condition_value == "6" ? "- สิทธิตามมูลค่า":<></>} */}
                                                {recordData.user_condition_value == "6" ? 
                                                    <>
                                                        {recordData.unit_value_3m !== null && recordData.unit_value_3m !== undefined ?
                                                            <>
                                                                <span>- ราคาน้อยกว่า 3 ล้าน</span>
                                                                <TextField value={recordData.unit_value_3m !== null ? recordData.unit_value_3m:"0"}/>
                                                                <span> สิทธิ</span>
                                                            </>
                                                        :<></>}

                                                        {recordData.unit_value_4m !== null && recordData.unit_value_4m !== undefined ?
                                                            <>
                                                                <span>- ราคา 3 - 5.49 ล้าน</span>
                                                                <TextField value={recordData.unit_value_4m !== null ? recordData.unit_value_4m:"0"}/>
                                                                <span> สิทธิ</span>
                                                            </>
                                                        :<></>}

                                                        {recordData.unit_value_9m !== null && recordData.unit_value_9m !== undefined ?
                                                            <>
                                                                <span>- ราคา 5.5 - 9.9 ล้าน</span>
                                                                <TextField value={recordData.unit_value_9m !== null ? recordData.unit_value_9m:"0"}/>
                                                                <span> สิทธิ</span>
                                                            </>
                                                        :<></>}

                                                        {recordData.unit_value_29m !== null && recordData.unit_value_29m !== undefined ?
                                                            <>
                                                                <span>- ราคา 10 - 29.9 ล้าน</span>
                                                                <TextField value={recordData.unit_value_29m !== null ? recordData.unit_value_29m:"0"}/>
                                                                <span> สิทธิ</span>
                                                            </>
                                                        :<></>}

                                                        {recordData.unit_value_59m !== null && recordData.unit_value_59m !== undefined ?
                                                            <>
                                                                <span>- ราคา 30 - 59.9 ล้าน</span>
                                                                <TextField value={recordData.unit_value_59m !== null ? recordData.unit_value_59m:"0"}/>
                                                                <span> สิทธิ</span>
                                                            </>
                                                        :<></>}

                                                        {recordData.unit_value_99m !== null && recordData.unit_value_99m !== undefined ?
                                                            <>
                                                                <span>- ราคา 60 - 99.9 ล้าน</span>
                                                                <TextField value={recordData.unit_value_99m !== null ? recordData.unit_value_99m:"0"}/>
                                                                <span> สิทธิ</span>
                                                            </>
                                                        :<></>}

                                                        {recordData.unit_value_100m !== null && recordData.unit_value_100m !== undefined ?
                                                            <>
                                                                <span>- ราคามากกว่า 100 ล้าน</span>
                                                                <TextField value={recordData.unit_value_100m !== null ? recordData.unit_value_100m:"0"}/>
                                                                <span> สิทธิ</span>
                                                            </>
                                                        :<></>}

                                                    </>
                                                    :<></>
                                                }

                                                {recordData.user_condition_value == "7" ? 
                                                    <>
                                                        <span>- สิทธิเฉพาะจำนวน ตัว ที่ลงทะเบียนสัตว์เลี้ยง</span><TextField value={recordData.user_condition_amount !== null ? recordData.user_condition_amount:"0"}/><span> สิทธิ</span>
                                                    </>
                                                    :<></>
                                                }
                                            </>
                                            :"ไม่พบข้อมูล"
                                        }
                                    </Descriptions.Item>

                                    <Descriptions.Item label="Content public date">

                                        {recordData.start_date !== null && recordData.start_date !== undefined && recordData.end_date !== null && recordData.end_date !== undefined ?
                                        <>
                                            <div>
                                                <span>- เริ่มวันที่ </span>
                                                <TextField value={Moment(recordData?.start_date).format('D/MM/yyyy HH:mm')}/>
                                            </div>
                                            <div>
                                                <span>- ถึงวันที่ </span>
                                                <TextField value={Moment(recordData?.end_date).format('D/MM/yyyy HH:mm')}/>
                                            </div>

                                            <Divider />

                                            {recordData.notice_channel == true ? 
                                                <TextField value={"* เปิดการแจ้งเตือนสำหรับผู้ที่มีสิทธิเข้าร่วม"} style={{fontSize: "0.7vw",color : "red"}}/>
                                                :
                                                <TextField value={"* ปิดการแจ้งเตือน"} style={{fontSize: "0.7vw",color : "red"}}/>
                                            }
                                        </>
                                        :<></>}
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>

                            <Divider />

                            <Col className="gutter-row" span={24}>
                                <div style={{fontSize: "0.7vw",marginBottom: "1%",fontWeight: "bold"}}>Detail :</div>
                                {/* <TextField value={recordData.detail !== null ? recordData.detail:"ไม่มีเนื้อหา"} /> */}
                                {ReactHtmlParser(`${recordData.detail !== null ? recordData.detail:"ไม่มีเนื้อหา"}`)}

                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Show>
    );
};