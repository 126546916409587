/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, message, RcFile, Row, Select, Upload, useForm,useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate  } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IEventlog, IProjectinfo, IProjectType } from "interfaces";
import { useState } from "react";
import { TOKEN_KEY } from "../../constants";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-mde/lib/styles/css/react-mde-all.css";

export const ProjectinfoCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const [ vprojdesc, setprojdesc] = useState<any>();

    const { formProps, saveButtonProps } = useForm<IProjectinfo,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'project_info',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );
    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
        "write",
    );
    const API_URL = useApiUrl();
    const { ...uploadProps_coverpix} = useStrapiUpload({ maxCount: 5, });

    // const [valueB, setValueB] = useState<IProjectinfo>();

    // const { selectProps: NoticeChTypeProps } = useSelect<INoticeChannelType>({
    //     resource: "notice-channel-types",
    //     optionLabel: "channel_name",
    //     optionValue: "id",
    //     // filters: [initF]
    // });
//     const { queryResult } = useShow<IProjectinfo>({  
//         metaData: {
//         populate: '*'
//     }, 
// });
    // const { data } = queryResult;
    // const record = data?.data;

    const { Option } = Select;

    // const postListQueryResult = useList<IProjectinfo>({
    //     resource: "project-infososts",
    //     config: {
    //         filters: [
    //             {
    //                 field: "project-type",
    //                 operator: "eq",
    //                 value: "1",
    //             },
    //         ],
    //     },
    // });
    // const { selectProps: Project_Type } = useSelect<IProjectinfo>({
    //     resource: "project-infos",
    //     optionLabel: "Project_Type",
    //     // optionValue: "id",
    //     // filters: [initF]
    // });
    
    // const { selectProps: proTypeProps} = useSelect<IProjectinfo>({
    //     resource: "project-infos",
    //     // optionLabel: "project_type",
    // });

//     const postListQueryResult = useList<IProjectinfo>({
//         resource: "project-infos",
//         config: {
//                     filters: [
//                         {
//                             field: "project-type",
//                             operator: "eq",
//                             value: "1",
//                         },
//                     ],
//                 },
//             });

//    console.log(">>>>>>>>>>>",postListQueryResult)

    const { selectProps: SelProjectType } = useSelect<IProjectType>({
        resource: "project-types",
        optionLabel: "pt_name",
        optionValue: "id",
    });


    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
            
        }
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              const body = new FormData();
              loader.file.then((file: string | Blob) => {
                // console.log('fileeeeeeeeeeeee', file)
                body.append("files", file);
                // let headers = new Headers();
                // headers.append("Origin", "http://localhost:3000");
                fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                  method: "post",
                  body: body
                  // mode: "no-cors"
                })
                  .then((res) => res.json())
                  .then((res) => {
                    // console.log('resssssssssssssssss', res)
                    resolve({
                    //   default: `${API_URL}/${res.filename}`
                      default: res[0].url
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            });
          }
        };
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
      };
      

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("projects-info-create.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project-info.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("projects-info-create.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.createBy = user_data.user_profile.id
                values.Project_Description = vprojdesc
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
            <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={18}>
                                    <Form.Item  label={t("project-info.name")} name="Project_Name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project name.'
                                            },
                                        ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Form.Item label={t("projects-type.title")} name="project_type"
                                        rules={[
                                            {
                                                required: true, message: 'Please select project types.'
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")} {...SelProjectType} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Form.Item label={t("project-info.des")} name="Project_Description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}>

                                <ReactMde
                                    selectedTab={selectedTab}
                                    onTabChange={setSelectedTab}
                                    generateMarkdownPreview={(markdown: string) =>
                                        Promise.resolve(
                                            <ReactMarkdown>{markdown}</ReactMarkdown>,
                                        )
                                    }
                                />
                            </Form.Item> */}

                            <Form.Item label={t("project-info.des")} name="Project_Description" rules={[{required: true,},]}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        alignment: {
                                            options: [ 'left', 'right' ]
                                        },
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "link",
                                            "bulletedList",
                                            "numberedList",
                                            "blockQuote",
                                            'alignment',
                                            "|",
                                            "imageTextAlternative",
                                            "imageUpload",
                                            "imageStyle:full",
                                            "imageStyle:side",
                                        ],
                                        extraPlugins: [uploadPlugin],
                                        mediaEmbed: {
                                            // configuration...
                                            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                        },
                                        image: {
                                            toolbar: ['']
                                        }
                                    }}
                                    
                                    onInit={(editor:any) => {
                                        // console.log('editor',editor)
                                    }}
                                    onChange={(event:any, editor:any) => {
                                        const data = editor.getData();
                                        setprojdesc(data);
                                    }}
                                    onBlur={(editor:any) => {
                                        // console.log("Blur.", editor);
                                    }}
                                    onFocus={(editor:any) => {
                                        // console.log("Focus.", editor);
                                    }}
                                />
                            </Form.Item>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("project-info.status")} name="Project_Tag"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter project status.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")}>
                                            <Option value="New Project">New Project</Option>
                                            <Option value="In Progress">In Progress</Option>
                                            <Option value="Ready to Move In">Ready to Move In</Option>
                                            <Option value="Sold out">Sold Out</Option>
                                            {/* <Option value="Hot Location">Hot Location</Option> */}
                                            {/* <Option value="Wallness & Green">Wallness & Green</Option> */}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Input.Group compact>
                                        <Form.Item  label={t("project-info.phone")} name="project_phone" style={{width: "50%"}}
                                        
                                            rules={[
                                                {
                                                    // required: true, message: 'please enter project tel.'
                                                },
                                            ]}>
                                            <Input style={{width: "95%"}}
                                                minLength={9}
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}/>
                                        </Form.Item>

                                        <Form.Item  label={t("project-info.line")} name="project_line" style={{width: "50%"}}
                                        
                                            rules={[
                                                {
                                                    // required: true, message: 'please enter project line.'
                                                },
                                            ]}>
                                            <Input />
                                        </Form.Item>
                                    </Input.Group>
                                </Col>
                            </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("project-info.link")} name="Project_Link"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project link.'
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                            </Col>

                            {/* <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-info.local")} name="project_location"
                                    rules={[
                                        {
                                            required: true, message: 'please enter project location.'
                                         },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col> */}

                            <Col className="gutter-row" span={12}>
                                <Input.Group compact>
                                    <Form.Item  label={t("project-info.lat")} name="lat" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project location (latitude)'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}} 
                                            onKeyPress={(event) => {
                                                if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }}  
                                            />
                                    </Form.Item>

                                    <Form.Item  label={t("project-info.lng")} name="lng" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project location (longitude)'
                                            },
                                        ]}>
                                            <Input  
                                            onKeyPress={(event) => {
                                                if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            />
                                    </Form.Item>
                                </Input.Group>
                            </Col>

                            {/* <Col className="gutter-row" span={6}>
                                
                            </Col> */}
                        </Row>
                        {/* <Row>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label={t("staff-profiles.status")} name="deleteStatus">
                                <Select placeholder={t("select.sel")}
                                    style={{ width: '94%' }}
                                    onSelect={(value: any) => {
                                        console.log(value);
                                            // setStatus(value);
                                        }}
                                    options={[
                                        {
                                            label: "ACTIVE",
                                            value: "N",
                                        },
                                        {
                                            label: "INACTIVE",
                                            value: "Y",
                                        }
                                    ]}
                                />
                                </Form.Item>
                            </Col>
                        </Row> */}

                        <Row>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label={t("staff-profiles.status")} name="status"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter project status.'
                                        },
                                    ]}>
                                <Select placeholder={t("select.sel")}
                                    style={{ width: '97%' }}
                                    onSelect={(value: any) => {
                                        console.log(value);
                                            // setStatus(value);
                                        }}
                                    options={[
                                        {
                                            label: "Public",
                                            value: "ACTIVE",
                                        },
                                        {
                                            label: "Draft",
                                            value: "INACTIVE",
                                        }
                                    ]}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Form.Item
                            name="Project_Image"
                            label={t("project-info.img")}
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                        >

                            <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                // {...uploadProps_coverpix}
                                // maxCount={5}
                                maxCount={1}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Upload.Dragger>
                            {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 1340*783 PNG, JPG Maximum size 5MB</p>
                    </Card>

                    {/* <Card style={{backgroundColor: "#fff", marginBottom: "2%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: "8%" }}><span className="title_panel">{t("projects-type.title")}</span></div>
                        <Form.Item name="project_type"
                            rules={[
                                {
                                    required: true, message: 'please select project types.'
                                },
                            ]}
                        >
                            <Select placeholder={t("select.sel")} {...SelProjectType} />
                        </Form.Item>
                    </Card> */}
                </Col>

                {/* <Col className="gutter-row" span={6}>
                    <Form.Item label={t("staff-profiles.status")} name="status">
                        <Select placeholder={t("select.sel")}
                            style={{ width: '94%' }}
                            onSelect={(value: any) => {
                                console.log(value);
                                    // setStatus(value);
                                }}
                            options={[
                                {
                                    label: "ACTIVE",
                                    value: "N",
                                },
                                {
                                    label: "INACTIVE",
                                    value: "Y",
                                }
                            ]}
                        />
                    </Form.Item>
                </Col> */}
            </Row>
        </Form>
    </Create>
    );
};
