/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton,  EditButton, Form, Icons, Input, List, Modal,  Row,  Space, Table,  Tag,  TextField, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IParcelBox } from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export const ParcelBoxList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Parcel Box Size");

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()
    const [nameTH, setNameTH] = useState<any>()
    const { tableProps,searchFormProps } = useTable<IParcelBox,HttpError, IParcelBox>({
        metaData: {
            populate: '*',
            locale:['th']
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            filters.push(
                {
                    field       : "title",
                    operator    : "contains",
                    value       : !!nameTH ? nameTH : null
                },
            );
            return filters;
        },
        initialSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
    });

    const categoryQueryResult = useOne<IParcelBox>({
        metaData: {
            populate: '*'
        },
        resource: "parcel-box-sizes",
        id: ''
    });

    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm } = Modal;
    const { mutate } = useUpdate<IParcelBox>();
    const ed_point = "parcel-box-sizes";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    // const { triggerExport, isLoading: exportLoading } = useExport<IParcelType>({
    //     mapData: (item) => {
    //         //console.log(item)
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id,
    //             };
    //         }
    //     },
    // });

    // function onStatusChange(){
    //     searchFormProps.form?.submit();
    // }
    
    // const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, localizeID:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            // values      : vCurDelStatus,
            values : {
                updateBy : user_data.user_profile.id + "",
                deleteStatus : vCurDelStatus.deleteStatus
            },errorNotification:false,
            successNotification:false
        });

        mutate({
            resource    : ed_point,
            id          : localizeID+"",
            values : {
                updateBy : user_data.user_profile.id + "",
                deleteStatus : vCurDelStatus.deleteStatus
            },errorNotification:false,
            successNotification:false
        });
        
        // CreateLogDelete({
        //     resource    : log_endpoint,
        //     values      : {
        //         user_profile    : user_data.user_profile.id,
        //         log_time_stamp  : Date.now(), 
        //         log_event       : event,
        //         menu_event      : 'policy',
        //         SysPkID         : id+""
        //     }
        // });
    }

    function showDelConfirm(id:any, localizeID:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, localizeID, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, localizeID:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, localizeID, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const onReset = () => {
        setNameTH(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    return <>
        <List 
            title={t("parcel-box.list")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("parcel-box.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        <Form.Item name="TH">
                                <Input onChange={(e) => setNameTH(e.target.value)} style={{border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search Size Name TH" prefix={<Icons.SearchOutlined />} allowClear/>
                        </Form.Item>

                        <Col className="gutter-row">
                                <Form.Item style={{width: "100px", marginRight: "15px"}}>
                                    <Button htmlType="submit" type="primary" style={{borderRadius: "5px", width: "100%"}}>
                                        <Icons.SearchOutlined /> Search
                                    </Button>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row">
                                <Form.Item style={{width: "100px", marginRight: "0"}}>
                                    <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px", width: "100%"}}>
                                        <Icons.UndoOutlined /> Reset
                                    </Button>
                                </Form.Item>
                            </Col>
                    </Form>
                </Col>

                <Col span={6}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right"}}
                    >
                        {/* <Form.Item>
                            <ExportButton style={{borderRadius:5}}
                                onClick={triggerExport}
                                loading={exportLoading}
                            />
                        </Form.Item> */}

                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:paginationLength.length, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/>      */}

                    <Table.Column dataIndex="title" title="PARCEL BOX SIZE NAME (TH)" 
                        sorter={(a:any, b:any) => {
                            if (a?.title < b?.title) {return -1}
                            if (a?.title > b?.title) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    <Table.Column title="PARCEL BOX SIZE NAME (EN)" 
                        // width={300} 
                        align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.localizations[0].title < b?.localizations[0].title) {return -1}
                            if (a?.localizations[0].title > b?.localizations[0].title) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {
                                    record ? 
                                        record.locale == "en" ?
                                            <TextField value={record ? record?.title : "-"} />
                                        :record ?
                                            record.localizations.length > 0 ?
                                                record.localizations[0].locale = "en" ?
                                                    <TextField value={record ? record.localizations[0].title : "-"} />     
                                                : <>-</>   
                                            :<>-</>
                                        :<>-</>
                                    :<>-</>
                                }
                                
                            </>
                        }}
                    />

                    <Table.Column title="STATUS"  align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.deleteStatus < b?.deleteStatus) {return -1}
                            if (a?.deleteStatus > b?.deleteStatus) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            console.log('record', record)
                            return <>
                                {/* <TextField value={value}  /> */}
                                <TextField 
                                    style={{fontWeight: 600}} 
                                    value={
                                        record.deleteStatus === "Y" ? 
                                            <Tag style={{borderRadius: "30px",color: "#AC1616",padding: "1% 7% 1%"}} color={"#FDD4D4"}>Inactive</Tag>
                                        :
                                            <><Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#00BB66"}>Active</Tag> </>
                                        }
                                />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.createdAt < b.createdAt) {return -1}
                        if (a.createdAt > b.createdAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField style={{fontWeight: 600}} value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField style={{fontWeight: 600}} value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.updatedAt < b.updatedAt) {return -1}
                        if (a.updatedAt > b.updatedAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField style={{fontWeight: 600}} value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField style={{fontWeight: 600}} value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column
                        // <IParcelBox>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(value:any,record:any) => {

                            return <>
                                <Space>

                                    {/* <ShowButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}

                                    >รายละเอียด</ShowButton> */}

                                    {/* <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    />

                                    <DeleteButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                    /> */}

                                    {
                                        record.deleteStatus === "N" ? 
                                            <>
                                                <EditButton
                                                    hideText
                                                    size="small"
                                                    recordItemId={record.id}
                                                    disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                                />
                                                <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id, record.localizations[0].id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                            </>
                                        :
                                            <>
                                                {/* <EditButton
                                                    hideText
                                                    size="small"
                                                    recordItemId={record.id}
                                                    disabled
                                                /> */}
                                                <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id, record.localizations[0].id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                            </>
                                    }
                                </Space>
                            </>
                        }}
                    />
                </Table>
            </List>
        </>
}