/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useCustom, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { RepairMdpcInspecDoneJobList } from "./done_job";
import { RepairMdpcInspecInprogressJobList } from "./inprogress_job";
import { RepairMdpcInspecJobList } from "./job_list";
import { RepairMdpcInspecTodayJobList } from "./today_job";
import { RepairMdpcInspecWaitApproveJobList } from "./wait_approve_job";
import { majorCareUrl } from "../../constants";
import dayjs from "dayjs";


export const RepairMdpcInspec: React.FC<IResourceComponentsProps> = () => {
    // const tabActive = localStorage.getItem("keyTabHCUMaster");
    const [ Tab, SetTab] = useState<any>();
    const profile_id = localStorage.getItem("PROFILE_ID");
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TabPane } = Tabs;
    const tabJobActive = localStorage.getItem("keyTabJob");

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabJob", v)
    }

    const [countTodayJob, setCountTodayJob] = useState<any>(undefined);
    const [countJob, setCountJob] = useState<any>(undefined);
    const [countInprogressJob, setCountInProgressJob] = useState<any>(undefined);
    const [countWaitAppJob, setCountWaitAppJob] = useState<any>(undefined);
    const [countDoneJob, setCountDoneJob] = useState<any>(undefined);
    const today = new Date().toISOString().split('T')[0];

    // TODAY
    const { data } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount`,
        method: "get",
        config: {
            query: {
                stat: '649859f8515b80b78fe13b88,64985a35515b80b78fe13b90,64985a3c515b80b78fe13b92,64985a01515b80b78fe13b8a',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                const today = dayjs().format("YYYY-MM-DD");
                // if(prop_list !== undefined){
                //     dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                // }else if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                // }
                dataLoad = dataLoad.filter((e:any) => (e.job_obj[0]?.assign?.attributes?.user_profile?.data.id == profile_id))
                const xx = dataLoad.filter((e:any) => e.job_obj[0]?.appoint_start?.startsWith(today));
                setCountTodayJob(xx?.length)
            },
        },
    });


    // JOB LIST
    const { data: data2 } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount`,
        method: "get",
        config: {
            query: {
                stat: '649859f8515b80b78fe13b88,64985a35515b80b78fe13b90',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // if(prop_list !== undefined){
                //     dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                // }else if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                // }
                dataLoad = dataLoad.filter((e:any) => (e.job_obj[0]?.assign?.attributes?.user_profile?.data.id == profile_id))
                
                setTimeout(() => {
                    setCountJob(dataLoad?.length)
                }, 1000);
            },
        },
    });

    // INPROGRESS JOB
    const { data: data3 } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount`,
        method: "get",
        config: {
            query: {
                stat: '64985a3c515b80b78fe13b92,64985a01515b80b78fe13b8a',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log('xacsa', dataLoad) 
                // if(prop_list !== undefined){
                //     dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                // }else if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                // }
                dataLoad = dataLoad.filter((e:any) => (e.job_obj[0]?.assign?.attributes?.user_profile?.data.id == profile_id))
                setCountInProgressJob(dataLoad?.length)
            },
        },
    });

    const getDataK = async ()=>{

        // WAIT APPROVE
        await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=64985bbe515b80b78fe13bb6&page=1&limit=500&mode=2`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            // if(prop_list !== undefined){
            //     dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            // }else if(prop_id !== null){
            //     dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            // }
            dataLoad = dataLoad.filter((e:any) => (e.job_obj[0]?.assign?.attributes?.user_profile?.data.id == profile_id))
            setCountWaitAppJob(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });


        // DONE
        await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=64985bc6515b80b78fe13bb8,64985bcf515b80b78fe13bba&page=1&limit=500&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            // if(prop_list !== undefined){
            //     dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            // }else if(prop_id !== null){
            //     dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            // }
            dataLoad = dataLoad.filter((e:any) => (e.job_obj[0]?.assign?.attributes?.user_profile?.data.id == profile_id))
            setCountDoneJob(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });
    
    }

    useEffect(() => {
        getDataK();
    }, []);

    return <>
        <List 
            // title={t("hcujob.title")}
            title="MDPC แจ้งซ่อม (สำหรับช่าง)"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>MDPC แจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcujob.title")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">MDPC แจ้งซ่อม (สำหรับช่าง)</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabJobActive ? tabJobActive + "" : "Today"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >
                        <TabPane
                            key={"Today"}
                            tab={
                                <span>
                                    {"ตารางงานวันนี้"}
                                    <Badge showZero count={countTodayJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairMdpcInspecTodayJobList />
                        </TabPane>

                        <TabPane
                            key={"JobList"}
                            tab={
                                <span>
                                    {"รายการแจ้งซ่อม"}
                                    <Badge showZero count={countJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairMdpcInspecJobList />
                        </TabPane>


                        <TabPane
                            key={"InProgress"}
                            tab={
                                <span>
                                    {"ระหว่างดำเนินการ"}
                                    <Badge showZero count={countInprogressJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairMdpcInspecInprogressJobList />
                        </TabPane>

                        <TabPane
                            key={"WaitApprove"}
                            tab={
                                <span>
                                    {"รออนุมัติงานซ่อม"}
                                    <Badge showZero count={countWaitAppJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairMdpcInspecWaitApproveJobList />
                        </TabPane>

                        <TabPane
                            key={"Done"}
                            tab={
                                <span>
                                    {"เสร็จสิ้น"}
                                    <Badge showZero count={countDoneJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairMdpcInspecDoneJobList />
                        </TabPane>

                    </Tabs>
                </Form.Item>
            </Form>
        </List>
    </>
}