import { Breadcrumb, Card, Col, Create, DatePicker, Divider, Form, Input, List, message, Radio, RadioChangeEvent, RcFile, Row, Select, Space, Upload, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IBannerMgn, IContenPostAnnoucement, IContenPostBlogs, IContentPostActivityEvent, IContentPostPromotion, IContentPostType, IEventlog, IPrivillege, IProperties, IUserSubType } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import {  TimePicker } from "antd";
import { CCreateLog } from "../../components/create_log/index";
import dayjs from "dayjs";

export const BannerMgnCreate: React.FC<IResourceComponentsProps> = () => {
    // const { ...uploadProps_pixlist} = useStrapiUpload({ maxCount: 5, });
    const t = useTranslate();
    const { TextArea } = Input;
    // const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";
    const format = 'YYYY-MM-DD HH:mm';
    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;
    const [vsetdetail, setdetail] = useState<any>();
    // const [ vsetdetail_en, setdetail_en] = useState<any>();
    // const [ vStatus, setStatus ] = useState<any>("");
    const [vPicID, setPicID] = useState<any>("");
    const [vPropertyID, setPropertyID] = useState<any>([]);
    const [vInputSeq, setInputSeq] = useState<any>(0);
    const [vIsNewOrOld, setIsNewOrOld] = useState<boolean>(true);
    const { Option } = Select;
    // const [ vContentDisplay, setContentDisplay]   = useState<any>("Promotion");
    const { form, formProps, saveButtonProps } = useForm<IBannerMgn, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data); 
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                <CCreateLog
                    endpoint={log_endpoint}
                    data={{
                        user_profile: user_data.user_profile.id,
                        log_time_stamp: Date.now(),
                        log_event: "Create",
                        menu_event: 'coupon',
                        SysPkID: recordData.data.id + ""
                    }}
                />
            },
        }
    );

    let initF: any;
    initF = {
        field: 'deleteStatus',
        operator: 'contains',
        value: 'N',
    };


    const { selectProps: PropertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [initF]
    });

    const API_URL = useApiUrl();
    const { ...uploadProps } = useStrapiUpload(
        {
            maxCount: 1,
        }
    );
    // const { queryResult } = useShow<ICoupon>({  
    //         metaData: {
    //         populate: '*'
    //     }, 
    // });
    // const { data } = queryResult;
    // const record = data?.data;

    const [value, setValue] = useState();
    const [vSTYPEINPUT, setSTYPEINPUT] = useState<any>("1px solid #d9d9d9");

    const onChangeNoti = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };
    // console.log("testttt",queryResult)

    // const [switchToggle, setSwitchToggle] = useState(queryResult.data?.data.coupon_active);
    // const onChange_sw = (checked: any) => {
    //     setSwitchToggle(checked);
    // };

    // const { selectProps: Selpptype } = useSelect<IPPType>({
    //     resource: "pp-types",
    //     optionLabel: "pp_name",
    //     optionValue: "id",
    //     // filters: [initF]
    // });

    function fileChange(e: any) {
        console.log(e);
        if (e.file.response) {
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
            setPicID(res[0].id)
            // setPicID(res[0])
        }
    }

    // const [ vTimeSlotTempTEST, setTimeSlotTempTEST ]    = useState<any>([]);
    // const [ vTimeSlotTEST, setTimeSlotTEST ]    = useState<any>([]);
    const [vDateStart, setDateStart] = useState<any>("");
    const [vDateEnd, setDateEnd] = useState<any>("");
    const [vContent, setContent] = useState<any>("");
    const [vContentName, setContentName] = useState<any>("");
    const [vContentType, setContentType] = useState<any>("");
    const [vTag, setTag] = useState<any>("");
    const [vOnlyRightMulti, setOnlyRightMulti] = useState<any>([]);

    const [vRaSpecificProperty, setRaSpecificProperty] = useState<any>(0);

    function selectTime(time: any, timeString: any) {
        // console.log('time_start',timeString)

        setDateStart(dayjs(timeString[0]));
        setDateEnd(dayjs(timeString[1]));
        // vTimeSlotTempTEST.push({time_start: timeString[0], time_end: timeString[1]});
        // setTimeSlotTEST(vTimeSlotTempTEST);
        // removeAndSortArr();
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const onChangeRadio5 = (e: RadioChangeEvent) => {
        // console.log('onChangeRadio5', e.target.value);
        setRaSpecificProperty(e.target.value);
        // console.log('vRaPrivCoupon', vRaPrivCoupon)
    };
    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        // console.log('fileeeeeeeeeeeee', file)
                        body.append("files", file);
                        // let headers = new Headers();
                        // headers.append("Origin", "http://localhost:3000");
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                            method: "post",
                            body: body
                            // mode: "no-cors"
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                // console.log('resssssssssssssssss', res)
                                resolve({
                                    //   default: `${API_URL}/${res.filename}`
                                    default: res[0].url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    const onChangeRadioNew = (e: RadioChangeEvent) => {
        // console.log('onChangeRadioNew', e.target.value);
        setIsNewOrOld(e.target.value);
        // console.log('vRaPrivCoupon', vRaPrivCoupon)
        form?.resetFields()
    };
    const [ vKeep, setVKeep ] = useState<any>([]);
    function onChangePropertyID(va: any) {
        let keepNeww:any = []
        for (let index = 0; index < va.length; index++) {
            keepNeww.push(va[index])
        }
        setVKeep(keepNeww)
        
        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
        // console.log('selected',vPropertyID[0]);
        // console.log('all', unitTypeData.data?.data);
        // console.log('diff', difference);
    };

    function onChangeOnlyRightMulti(va: any) {
        vOnlyRightMulti.pop();
        vOnlyRightMulti.push(va);
        setOnlyRightMulti(vOnlyRightMulti);
        // console.log('vOnlyRight',vOnlyRightMulti);
    }

    function onChangeContent(value: any, key: any) {
        // console.log('onChangeContent 1', value)
        // console.log('onChangeContent 2', key)
        setContent(value);
        setContentName(key.key)
    }

    // console.log('vContentName', vContentName)

    function onChangeContentType(value: any, label: any) {
        // console.log('content name insert to tag', label);
        // console.log('content id', value);
        setContentType(label.label);
    }

    const { selectProps: PrivUserSubTypeOnlyCus } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    const { selectProps: selContentPostType } = useSelect<IContentPostType>({
        resource: "content-post-types",
        optionLabel: "type_name",
        optionValue: "type_name",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N',
            },
            // {
            //     field       : 'local',
            //     operator    : 'eq',
            //     value       : 'th',
            // },
        ]
    });

    // console.log('selContentPostType', selContentPostType)

    // const { selectProps: ContentTypeSel } = useSelect<IContentPostPromotion>({
    //     resource    : "content-promotions",
    //     optionLabel : "name",
    //     optionValue : "id",
    //     // filters: [
    //     //     {
    //     //         field       : 'sub_type_name',
    //     //         operator    : 'contains',
    //     //         value       :  ['Owner','Renter','Resident Renter','Resident Owner','Guest'],
    //     //     },
    //     // ]
    // });

    // *endpoint content post*
    // content-promotions
    // content-news
    // content-blogs
    // content-announcements
    // content-aes
    // privileges

    const qContentPromotionData = useList<IContentPostPromotion>({
        resource: "content-promotions",
        // metaData: {
        //     // populate: ["users"]
        //     populate: ['user'],
        //     locale:['th']
        // },
        // config:{
        //     // sort: [{ order: "asc", field: "id" }],
        //     filters: [
        //         {
        //             field       : 'locale',
        //             operator    : 'contains',
        //             value       : 'th',
        //         },
        //     ]
        // }
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    // console.log('qContentPromotionData >>>>>>>>>>>> ', qContentPromotionData)
    const { data: promotionData } = qContentPromotionData;
    let vPromotionData: any = []
    vPromotionData = promotionData?.data ? promotionData?.data : [];

    // *endpoint content post*
    // content-promotions
    // content-news
    // content-blogs
    // content-announcements
    // content-aes
    // privileges
    const qContentNewsData = useList<IContentPostPromotion>({
        resource: "content-news",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: newsData } = qContentNewsData;
    let vNewsData: any = []
    vNewsData = newsData?.data ? newsData?.data : [];

    const qContentBlogData = useList<IContenPostBlogs>({
        resource: "content-blogs",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: blogData } = qContentBlogData;
    let vBlogData: any = []
    vBlogData = blogData?.data ? blogData?.data : [];

    const qContentAnnData = useList<IContenPostAnnoucement>({
        resource: "content-announcements",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: annData } = qContentAnnData;
    let vAnnData: any = []
    vAnnData = annData?.data ? annData?.data : [];

    const qContentAeData = useList<IContentPostActivityEvent>({
        resource: "content-aes",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: aeData } = qContentAeData;
    let vAeData: any = []
    vAeData = aeData?.data ? aeData?.data : [];

    const qContentPrivData = useList<IPrivillege>({
        resource: "privileges",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: privData } = qContentPrivData;
    let vPrivData: any = []
    vPrivData = privData?.data ? privData?.data : [];

    function onChangeTag(va: any) {
        console.log('va', va)
        setTag(va)
    };

    const [vValueLocale, setValueLocale] = useState(true);
    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setValueLocale(value);
        if (value) {
            console.log('th')
        } else {
            console.log('en')
        }
    };

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const [vTitle, setTitle] = useState('');
    const [vShortDetails, setShortDetails] = useState('');

    const onChangeShortDetail = (e: any) => {
        setShortDetails(e.target.value);
    }

    // vNewsData
    // vBlogData
    // vAnnData
    // vAeData
    // vPrivData
    // console.log('vPromotionData', vPromotionData)
    // console.log('vIsNewOrOld', vIsNewOrOld)
    // console.log('vPicID', vPicID)
    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List
                // title={t("banner-mgn.title-create")}
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("banner-mgn.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("banner-mgn.title-create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    // console.log('vsetdetail', vsetdetail)
                    // console.log('vTitle', vTitle)

                    values.createBy = user_data.user_profile.id
                    values.start_date = vDateStart
                    values.end_date = vDateEnd
                    values.detail_2 = vsetdetail

                    // values.detail_en = vsetdetail_en
                    // #1281 Banner / Popup : ในกรณีที่เลือกเป็นการสร้างใหม่ จะต้องทำเป็น 2 ภาษา เหมือน Content Post อันอื่น
                    values.detail_en = vsetdetail // fix ตอน create ให้ auto ไทยไปก่อน
                    values.short_detail_en = vShortDetails
                    values.title_en = vTitle

                    values.seq = vInputSeq
                    values.property_dev = vRaSpecificProperty
                    values.banner_type = vIsNewOrOld
                    values.properties = vPropertyID[0]

                    if (vIsNewOrOld == false) { // true = สร้างใหม่, false = เลือก content ที่มีอยู่แล้ว
                        // console.log('ffff', vContentName)
                        values.title = vContentName

                        vContentType == "Promotion" ? values.content_promotion = vContent : <></>
                        vContentType == "Privilege" ? values.privilege = vContent : <></>
                        vContentType == "News" ? values.content_new = vContent : <></>
                        vContentType == "Blog" ? values.content_blog = vContent : <></>
                        vContentType == "Announcement" ? values.content_announcement = vContent : <></>
                        vContentType == "Activity & Event" ? values.content_ae = vContent : <></>
                    } else if (vIsNewOrOld == true) { // true
                        values.tag = vTag
                        values.pic = vPicID
                        // console.log('vPicID', vPicID)
                    }


                    // vContentType == "Promotion" ? values.content_promotion = vContent : <></> 
                    // vContentType == "Privilege" ? values.privilege = vContent : <></>
                    // vContentType == "News" ? values.content_new = vContent : <></> 
                    // vContentType == "Blog" ? values.content_blog = vContent : <></> 
                    // vContentType == "Announcement" ? values.content_announcement = vContent : <></> 
                    // vContentType == "Activity & Event" ? values.content_ae = vContent : <></>
                    values.locale = "th"

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
                onFinishFailed={() => {
                    if (formProps.form?.getFieldError("start_date") !== undefined && formProps.form?.getFieldError("start_date").length > 0) {
                        setSTYPEINPUT("1px solid #f5222d")
                    } else {
                        setSTYPEINPUT("1px solid #d9d9d9")
                    }
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>

                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            {/* <div style={{marginBottom: "1%"}}>
                                <span style={{marginRight: "3%",fontSize: ".70vw",fontWeight: "bold",color: "#000"}}>Display Content :</span> 
                                <span style={{fontSize: "13px",color: "#000"}}>{vContentDisplay}</span>
                            </div> */}

                            {/* <Form.Item label="Banner type" name="banner_typexxx"
                                rules={[{required: true, message: 'Please Select Type.'},]}
                            > */}
                            <Radio.Group onChange={onChangeRadioNew}
                                name="radiogroup"
                                // rules={[{required: true, message: 'Please Select Type.'},]}
                                value={vIsNewOrOld}
                                defaultValue={vIsNewOrOld}
                            >
                                <Radio value={true}>สร้างใหม่</Radio>
                                <Radio value={false}>เลือกจากข้อมูลในระบบ</Radio>
                            </Radio.Group>
                            {/* </Form.Item> */}

                            {/* <Radio.Group onChange={onChangeRadioNew}>
                                <Radio value={true}>สร้างใหม่</Radio>
                                <Radio value={false}>เลือกจากข้อมูลในระบบ</Radio>
                            </Radio.Group> */}

                            <div style={{ marginBottom: '2%' }}></div>

                            {
                                vIsNewOrOld ? // สร้างใหม่
                                    <>
                                        <Row>
                                            <Col span={12}>
                                                <div style={{ marginBottom: '2%' }}>
                                                    <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" disabled />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={12}>
                                                <Form.Item label="Title" name="title"
                                                    style={{ width: "95%" }}
                                                    rules={[{ required: true, message: 'Please enter title.' },]}
                                                >
                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                        onChange={(e) => setTitle(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {/* <Col span={1}></Col> */}
                                            <Col span={12}>
                                                {/* <Form.Item  label="Title (EN)" name="title_en"
                                                    rules={[{required: true, message: 'Please enter title (EN).'},]}
                                                >
                                                    <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                                </Form.Item> */}
                                                <Form.Item label="Short Detail" name="short_detail"
                                                    rules={[{ required: true, message: 'Please enter short detail.' },]}
                                                >
                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                        // defaultValue={vShortDetails}
                                                        onChange={(e) => onChangeShortDetail(e)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>


                                        {/* <Form.Item label="Detail" name="detail">
                                            <TextArea style={{height: "250px", maxHeight: "80px"}}/>
                                        </Form.Item> */}
                                        <Form.Item label="Detail"
                                            // name="detail"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <CKEditor
                                                // name="detail"
                                                editor={ClassicEditor}
                                                //data={recordData.detail}
                                                config={{
                                                    toolbar: [
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "link",
                                                        "bulletedList",
                                                        "numberedList",
                                                        "blockQuote",
                                                        // "ckfinder",
                                                        "|",
                                                        "imageTextAlternative",
                                                        "imageUpload",
                                                        "imageStyle:full",
                                                        "imageStyle:side",
                                                        // "|",
                                                        // "mediaEmbed",
                                                        // "insertTable",
                                                        // "tableColumn",
                                                        // "tableRow",
                                                        // "mergeTableCells",
                                                        // "|",
                                                        // "undo",
                                                        // "redo"
                                                    ],
                                                    extraPlugins: [uploadPlugin],
                                                    mediaEmbed: {
                                                        // configuration...
                                                        removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
                                                    },
                                                    image: {
                                                        toolbar: ['']
                                                    }
                                                }}

                                                onInit={(editor: any) => { }}
                                                onChange={(event: any, editor: any) => {
                                                    const data = editor.getData();
                                                    setdetail(data);
                                                }}
                                                onBlur={(editor: any) => { }}
                                                onFocus={(editor: any) => { }}
                                            />
                                        </Form.Item>

                                        {/* <Form.Item label="Detail (EN)" 
                                            // name="detail"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <CKEditor
                                                editor={ClassicEditor}
                                                config={{
                                                    toolbar: [
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "link",
                                                        "bulletedList",
                                                        "numberedList",
                                                        "blockQuote",
                                                        "|",
                                                        "imageTextAlternative",
                                                        "imageUpload",
                                                        "imageStyle:full",
                                                        "imageStyle:side",
                                                    ],
                                                    extraPlugins: [uploadPlugin],
                                                    mediaEmbed: {
                                                        removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                    },
                                                    image: {
                                                        toolbar: ['']
                                                    }
                                                }}
                                                
                                                onInit={(editor:any) => {}}
                                                onChange={(event:any, editor:any) => {
                                                    const data = editor.getData();
                                                    setdetail_en(data);
                                                }}
                                                onBlur={(editor:any) => {}}
                                                onFocus={(editor:any) => {}}
                                            />
                                        </Form.Item> */}

                                        <Row>
                                            <Col span={11}>
                                                <Form.Item
                                                    name="tag"
                                                    label="tag"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select tag.'
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder={t("select.sel")}
                                                        // mode="multiple"
                                                        {...selContentPostType}
                                                        onChange={onChangeTag}
                                                    // tokenSeparators={[',']}
                                                    // options={[
                                                    //     {
                                                    //         label: "News",
                                                    //         value: "News",
                                                    //     },
                                                    //     {
                                                    //         label: "Announcement",
                                                    //         value: "Announcement",
                                                    //     },
                                                    //     {
                                                    //         label: "Promotion",
                                                    //         value: "Promotion",
                                                    //     },
                                                    //     {
                                                    //         label: "Activity & Event",
                                                    //         value: "Activity & Event",
                                                    //     },
                                                    //     {
                                                    //         label: "Blog",
                                                    //         value: "Blog",
                                                    //     },
                                                    //     {
                                                    //         label: "Privilege",
                                                    //         value: "Privilege",
                                                    //     }
                                                    // ]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    vIsNewOrOld == false ? // เลือกจากของเดิม
                                        <>
                                            <Row>
                                                <Col span={5}>
                                                    <span>
                                                        <b><div style={{ fontSize: "12px" }}>Content Type : </div><>&nbsp;</></b>
                                                    </span>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="tag">
                                                        <Select placeholder={t("select.sel")}
                                                            onChange={onChangeContentType}
                                                            {...selContentPostType}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={5}>
                                                    <span>
                                                        <b><div style={{ fontSize: "12px" }}>Title : </div><>&nbsp;</></b>
                                                    </span>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="title"
                                                        rules={[
                                                            {
                                                                required: true, message: 'Please select content title.'
                                                            },
                                                        ]}
                                                    >
                                                        <Select showSearch
                                                            allowClear
                                                            placeholder="Type to search"
                                                            // {...SelFaciliType} 

                                                            onChange={onChangeContent}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA!.children as unknown as string)
                                                                    .toLowerCase()
                                                                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                                                            }
                                                        >
                                                            {
                                                                vContentType == "Promotion" ?
                                                                    vPromotionData !== undefined ?
                                                                        vPromotionData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            return <>
                                                                                {/* <Option value={item.id} key={item.short_detail} >{item.short_detail}</Option> */}
                                                                                <Option value={item.id} key={item.name} >{item.name}</Option>
                                                                                {/* {item.status !== null && item.status !== undefined  && item.status !== false ?  */}
                                                                                {/* {
                                                                                    item !== null? 
                                                                                        <>
                                                                                            <Option key={key} value={item.id}>{moment(item.time_start, "HH:mm").format('HH:mm') + ' - ' + moment(item.time_end, "HH:mm").format('HH:mm')}</Option>
                                                                                            <Option value="user only">{t("reservation.usero")}</Option>
                                                                                        </>
                                                                                    :
                                                                                        <></>
                                                                                } */}
                                                                            </>
                                                                        })
                                                                        :
                                                                        <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "News" ?
                                                                    vNewsData !== undefined ?
                                                                        vNewsData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Blog" ?
                                                                    vBlogData !== undefined ?
                                                                        vBlogData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Announcement" ?
                                                                    vAnnData !== undefined ?
                                                                        vAnnData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Activity & Event" ?
                                                                    vAeData !== undefined ?
                                                                        vAeData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Privilege" ?
                                                                    vPrivData !== undefined ?
                                                                        vPrivData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}></Col>
                                            </Row>
                                        </>
                                        :
                                        // โหลดหน้ามา boolean ยังไม่มีค่า ไม่แสดงอะไรเลย
                                        <></>
                            }

                            <div style={{ marginBottom: '2%' }}></div>
                            {/* <Form.Item  label="Title" name="title"
                                rules={[
                                    {
                                        required: true, message: 'please enter title.'
                                    },
                                    ]}>
                                <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                            </Form.Item>

                            <Form.Item label="Detail" name="detail">
                                <TextArea style={{height: "250px", maxHeight: "80px"}}/>
                            </Form.Item>
                            
                            <Row>
                                <Col span={11}>
                                    <Form.Item name="tag" label="tag">
                                        <Select placeholder={t("select.sel")}
                                            // mode="multiple"
                                            tokenSeparators={[',']}
                                            options={[
                                                {
                                                    label: "News",
                                                    value: "News",
                                                },
                                                {
                                                    label: "Announcement",
                                                    value: "Announcement",
                                                },
                                                {
                                                    label: "Promotion",
                                                    value: "Promotion",
                                                },
                                                {
                                                    label: "Activity & Event",
                                                    value: "Activity & Event",
                                                },
                                                {
                                                    label: "Blog",
                                                    value: "Blog",
                                                },
                                                {
                                                    label: "Privilege",
                                                    value: "Privilege",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col> */}


                            <Row>
                                {/* <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Properties : </div><>&nbsp;</></b>
                                    </span>
                                </Col> */}

                                <Col span={18}>

                                    {/* <Form.Item 
                                        // name="user_types"
                                        name="properties"
                                        // label="User Type"
                                        // rules={[
                                        //     {
                                        //         required: true, message: 'please select user type.'
                                        //     },
                                        // ]}
                                    ></Form.Item> */}
                                    <Form.Item label="Properties" name="prop"
                                        rules={[{ required: true, message: 'Please Select Properties.' },]}
                                    >
                                        <Radio.Group onChange={onChangeRadio5}>
                                            <Space direction="vertical">
                                                <div>
                                                    <Radio value="All" style={{ marginBottom: "0%" }}> ทุกโครงการ </Radio>
                                                </div>
                                                <div>
                                                    <Radio value="Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Major เท่านั้น </Radio>
                                                </div>

                                                <div>
                                                    <Radio value="Non Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Non Major เท่านั้น </Radio>
                                                </div>
                                                <div style={{ width: '250px' }}>
                                                    <Radio value="Properties" style={{ marginBottom: "0%" }}> ระบุโครงการ </Radio>
                                                    {
                                                        vRaSpecificProperty == "Properties" ?
                                                            <>
                                                                <div style={{ marginBottom: '2%' }}></div>
                                                                <Form.Item
                                                                    // name="privilege_properties"
                                                                    name="properties"
                                                                    rules={[
                                                                        {
                                                                            required: vKeep.length == 0 ? true : false, message: 'Please select property.'
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        size="small"
                                                                        style={{ width: "95%" }}
                                                                        mode="multiple"
                                                                        placeholder={t("select.sel")}
                                                                        {...PropertySelectProps}
                                                                        onChange={onChangePropertyID}
                                                                    />
                                                                </Form.Item>
                                                            </>
                                                            :
                                                            <></>
                                                    }
                                                </div>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                </Col>
                            </Row>
                            <div style={{ marginBottom: '1%' }}></div>

                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        // name="user_types"
                                        name="user_sub_types"
                                        label="User Type"
                                        rules={[
                                            {
                                                required: true, message: 'Please select user type.'
                                            },
                                        ]}
                                    >
                                        <Select
                                            // size="small"
                                            style={{ width: "95%" }}
                                            mode="multiple"
                                            placeholder={t("select.sel")}
                                            onChange={onChangeOnlyRightMulti}
                                            {...PrivUserSubTypeOnlyCus}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={1}></Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="start_date"
                                        label="Content public date"
                                        rules={[
                                            {
                                                required: true, message: 'Please select public date.'
                                            },
                                        ]}
                                    >
                                        <DatePicker.RangePicker
                                            showTime
                                            style={{ width: "100%", border: vSTYPEINPUT }}
                                            onChange={(date, dateString) => {
                                                selectTime(date, dateString)
                                                if (vSTYPEINPUT == "1px solid #f5222d") {
                                                    setSTYPEINPUT("1px solid #d9d9d9")
                                                }
                                            }}
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col span={1}></Col>
                                <Col span={12}>
                                </Col>
                                <Col span={11}></Col>
                            </Row> */}

                            {/* <Row>
                                <Col span={11}>
                                    <Form.Item name="seq"
                                        // label={t("notice-ch")} 
                                        // label="Content Promotion Sequences"
                                        label="Sequences"
                                    >
                                        <Input
                                            type={"number"}
                                            placeholder="Sequences"
                                            // defaultValue={0}
                                            min={0}
                                            // size="small"
                                            style={{ width: "100%", borderRadius: "5px" }}
                                            // value={vInputSeq}
                                            onChange={e => setInputSeq(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={1}></Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[
                                            {
                                                required: true, message: 'Please select status.'
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")}
                                            style={{ width: '100%' }}
                                            onSelect={(value: any) => {
                                                // console.log(value);
                                                // setStatus(value);
                                            }}
                                            options={[
                                                {
                                                    label: "Public",
                                                    value: "PUBLIC",
                                                },
                                                {
                                                    label: "Draft",
                                                    value: "DRAFT",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Row>
                                <Col span={11}>
                                    <Form.Item
                                        // label={t("notice-ch")} 
                                        // label="Content Promotion Sequences"
                                        label="External link"
                                        name="link_external"
                                    >
                                        <Input
                                            // type={"number"}
                                            placeholder="external link"
                                            // defaultValue={0}
                                            min={0}
                                            // size="small"
                                            style={{ width: "100%", borderRadius: "5px" }}
                                        // value={vInputSeq}
                                        // onChange={e => setInputSeq(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col span={12}>
                                    <Form.Item name="seq" 
                                        // label={t("notice-ch")} 
                                        // label="Content Promotion Sequences"
                                        label="Sequences"
                                    >
                                        <Input 
                                            type={"number"}
                                            placeholder="Sequences"
                                            // defaultValue={0}
                                            min={0}
                                            // size="small"
                                            style={{width: "100%", borderRadius: "10px"}}
                                            // value={vInputSeq}
                                            onChange={e => setInputSeq(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}></Col>
                            </Row> */}
                        </Card>
                    </Col>




                    {
                        vIsNewOrOld ?
                            <>
                                <Col id="Col_w" className="gutter-row" span={6}>
                                    <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                        <div style={{ marginBottom: 20 }}><span className="title_panel"><span style={{ color: "#f5222d", fontWeight: "300", fontSize: "16px", marginRight: "5px" }}>*</span>Thumbnail</span></div>
                                        <Divider></Divider>
                                        <Form.Item
                                            name="pic"
                                            valuePropName="fileList"
                                            getValueProps={(data) => getValueProps(data, API_URL)}

                                            rules={[
                                                {
                                                    required: true, message: 'Please select image.'
                                                },
                                            ]}
                                        >

                                            <Upload.Dragger
                                                style={{ borderRadius: 10 }}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}

                                                listType="picture"
                                                // multiple
                                                {...uploadProps}
                                                onChange={fileChange}
                                                beforeUpload={beforeUpload}
                                            >
                                                <p>Upload</p>
                                            </Upload.Dragger>
                                        </Form.Item>
                                        <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Picture 750*500 PNG, JPG Maximum size 5MB</p>
                                    </Card>
                                </Col>
                            </>
                            :
                            <></>
                    }

                    {/* <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel">Thumbnail</span></div>
                            <Divider></Divider>


                            <Form.Item
                                name="pic"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                            >

                                <Upload.Dragger
                                    style={{borderRadius: 10}}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}

                                    listType="picture"
                                    // multiple
                                    {...uploadProps}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                        </Card>
                    </Col> */}
                </Row>
            </Form>
        </Create>
    );
};