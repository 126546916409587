/* eslint-disable */
import {  Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, ShowButton, Space, Table, TextField, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IPostFilterVariables, IProject } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export const ProjectList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Projects");

    const [inputBrand, setInputBrand] = useState<any>();
    const [inputName, setInputName] = useState<any>();
    const [inputType, setInputType] = useState<any>();

    const { tableProps,searchFormProps } = useTable<IProject,HttpError,IPostFilterVariables>({
        metaData: {
            populate: ['*', 'project_brand', 'pp_type', 'createBy', 'updateBy']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const  {q}  = params;
                filters.push(
                    // {
                    //     field       : "q",
                    //     operator    : "contains",
                    //     value       : q,
                    // },
                    {
                        field       : "project_brand][brand_name]",
                        operator    : "contains",
                        value       : !!inputBrand ? inputBrand : null ,
                    },
                    {
                        field       : "project_name",
                        operator    : "contains",
                        value       : !!inputName ? inputName : null,
                    },
                    {
                        field       : "pp_type][pp_name]",
                        operator    : "contains",
                        value       : !!inputType ? inputType : null,
                    },
                );
    
            return filters;
        },
    });

    const categoryQueryResult = useOne<IProject>({
        resource: "projects",
        id: ''
    });
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IProject>();
    const ed_point                          = "projects";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    // function onStatusChange(){
    //     searchFormProps.form?.submit();
    // }

    const { triggerExport, isLoading: exportLoading } = useExport<IProject>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                        
                };
            }
        },
    });

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'project',
                SysPkID         : id+""
            },
            successNotification : false,
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const onReset = () => {
        setInputBrand(undefined)
        setInputName(undefined)
        setInputType(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    }
    useEffect(() => {
        console.log('tableProps ',tableProps)
    }, [tableProps])


    return <>
            
            <List 
                title={t("projects.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("bc_projects.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            
                            <Form.Item name="Search brand" >
                                <Input
                                    onChange={(e) => setInputBrand(e.target.value)}
                                    prefix={<Icons.SearchOutlined />}
                                    style={{borderRadius: 4}} 
                                    placeholder="Search brand" size="middle" allowClear
                                />
                            </Form.Item>

                            <Form.Item name="Search name" >
                                <Input
                                    onChange={(e) => setInputName(e.target.value)}
                                    prefix={<Icons.SearchOutlined />}
                                    style={{borderRadius: 4}} 
                                    placeholder="Search name" size="middle" allowClear
                                />
                            </Form.Item>

                            <Form.Item name="Search type" >
                                <Input
                                    onChange={(e) => setInputType(e.target.value)}
                                    prefix={<Icons.SearchOutlined />}
                                    style={{borderRadius: 4}} 
                                    placeholder="Search type" size="middle" allowClear
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={() => onReset()} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />

                                {/* <ImportButton></ImportButton> */}
                                
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:paginationLength.length, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}
                    {/* <Table.Column dataIndex="id" title={t("Lid")} align="center" width={150} sorter
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="property" title={t("property.Ltitle")}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.property? record?.property.property_name :"-"}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column align="center" width={35} dataIndex="Project_Image" title="  " 
                        render={(value:any,record:any)=>{
                            return <>
                                    {
                                        value? 
                                            <Avatar size={32} src={value.url} />
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} />
                                        </>
                                    }
                                </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="project_id" title="PROJECT ID" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={value}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="project_code" title="PROJECT CODE" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title="PROJECT BRAND" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.project_brand?.brand_name.localeCompare(b?.project_brand?.brand_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            //{console.log("value",value?.brand_name.length)}
                            //{console.log("record112",record)}
                            return <>
                                {/* <TextField value={record?.project_brand? record?.project_brand.brand_name:"-"}  /> */}
                                <TextField value={record?.project_brand ? record?.project_brand?.brand_name:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="project_name" title="PROJECT NAME" align="center" width={150} className="table_colmn_custom_left_value"
                     sorter={(a, b) => a.project_name.localeCompare(b.project_name)}
                     sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    />

                    <Table.Column title="PROPERTIES TYPE" align="center" width={200} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => a?.pp_type?.pp_name.localeCompare(b?.pp_type?.pp_name)}
                    sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            //{console.log("valuepp_type",value)}
                            //{console.log("record",record)}
                            return <>
                                <TextField value={record?.pp_type ? record?.pp_type?.pp_name:"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => !!a.createBy ? a.createBy.localeCompare(b.createBy) : null }
                        render={(value:any,record:any)=>{
                            //console.log("testxxx",record)
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => !!a.updateBy ? a.updateBy.localeCompare(b.updateBy) : null }
                        render={(value:any,record:any)=>{
                            //console.log("testxxx",record)
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //  {console.log("value",value?.updatedAt)}
                            // {console.log("record",record)}
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column<IProject>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton>

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */} 
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}