/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Form, Input, List, Row, useForm, TextField, Select, useSelect, Button, Icons, useModal, Modal, Avatar } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IvehicleBrand, IvehicleModel, IvehicleType } from "interfaces";
import { useEffect, useState } from "react";
import moment, { isMoment } from 'moment';

export const VehicleMEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const [ count, setCount] = useState(0);

    const { form, formProps, saveButtonProps }    = useForm<IvehicleModel>({redirect:"list"});
    const { queryResult } = useShow<IvehicleModel>({metaData: {
        populate: ['createBy.avatar','updateBy.avatar','vehicle_type','vehicle_brand']
        }, });
    const { data } = queryResult;
    // const record = data?.data;
    let recordData:any
    recordData = data?.data? data?.data : [];
    // console.log('recordData', recordData)

    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";

    const [seltype, setseltype] = useState<any>();
    const [selbrand, setselbrand] = useState<any>();
    const { modalProps, show, close } = useModal();

    if(recordData !== undefined && form.getFieldValue(["vehicle_type"]) == undefined && form.getFieldValue(["vehicle_type"]) == undefined){
        form.setFieldsValue({
            vehicle_type:
                recordData.vehicle_type !== null && recordData.vehicle_type !== undefined  ?
                    {
                        label: recordData?.vehicle_type.type_nameEN,
                        value: recordData?.vehicle_type.id,
                    }
                : form.resetFields(["vehicle_type"]),
            vehicle_brand:
                recordData.vehicle_brand !== null && recordData.vehicle_brand !== undefined  ?
                    {
                        label: recordData?.vehicle_brand.brand_nameEN,
                        value: recordData?.vehicle_brand.id,
                    }
                : form.resetFields(["vehicle_brand"]),
        })
    }

    useEffect(() => {
        if (count < 2) { 
            const interval = setInterval(() => { 
                setCount(prev => prev + 1)
                setseltype(recordData.vehicle_type !== null ? recordData.vehicle_type.id : '');
                setselbrand(recordData ? recordData.vehicle_brand.id : '');
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    // console.log('seltype', seltype)
    // console.log('selbrand', selbrand)

    const { selectProps: selvehicleType } = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        fetchSize: 1000,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const { selectProps: selvehicleBrand } = useSelect<IvehicleBrand>({
        resource: "vehicle-brands",
        optionLabel: "brand_nameEN",
        optionValue: "id",
        fetchSize: 1000,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'vehicle_type][id]',
                operator    : 'eq',
                value       : seltype,
            },
        ] 
    });

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'vehicle-models',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("vehicle.bc_title_m_edit")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title_m_edit")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            updateUserUpdatedBy();
            values.updateBy = user_data.user_profile.id
            values.vehicle_type = seltype
            values.vehicle_brand = selbrand
            return ( 
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
            );
         }}>
            <Row gutter={[16,24]}>
                <Col className="gutter-row" span={24} style={{marginTop: "-60px",marginBottom: "1%"}}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={22} />
                        <Col className="gutter-row" span={2}>
                            <Button onClick={show} style={{width: "100%",borderRadius: "5px"}} icon={<Icons.InfoCircleOutlined/>}>Info</Button>    
                        </Col>
                    </Row>
                </Col>
                <Col id="Col_w" className="gutter-row" span={24}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.type")} name="vehicle_type"
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")}
                                        {...selvehicleType}
                                        onChange={(e) => {
                                            setseltype(e);
                                            form.resetFields(["vehicle_brand"]);
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.brand")} name="vehicle_brand"
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")}
                                        {...selvehicleBrand}
                                        onChange={(e) => setselbrand(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.model-code")} name="medel_code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter model code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.model-name_en")} name="model_nameEN"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter model name(en).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.model-name_th")} name="model_nameTH"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter model name(th).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Modal onOk={close} {...modalProps} footer={[<Button key="submit" type="primary" onClick={close}>OK</Button>]}>
                <div><Icons.InfoCircleOutlined style={{marginRight: "3%"}}/><TextField value={"Data Create & Update"}/></div>
                <Row gutter={16} style={{marginTop: "6%"}}>
                    <Col className="gutter-row" span={24}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={15}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4}>
                                    {
                                        recordData?.createBy?
                                            recordData.createBy.avatar !== null ?

                                            <Avatar size={32} src={recordData.createBy.avatar.url} style={{marginTop: "-7px"}}/>
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                    }
                                    </Col>
                                    <Col className="gutter-row" span={8}><span>{t("created")}</span></Col>
                                    <Col className="gutter-row" span={12}><TextField value={moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}/></Col>
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                    {/* <Col className="gutter-row" span={18}><TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null? recordData.createBy.first_name !== null && recordData.createBy.last_name !== null ? recordData.updateBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}/></Col> */}
                                    <Col className="gutter-row" span={18}>
                                        <TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null && recordData?.createBy !== undefined? 
                                            recordData?.createBy.first_name !== null && recordData.createBy.last_name !== null ? 
                                            recordData.createBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="gutter-row" span={24} style={{marginTop: "3%"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={15}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4}>
                                    {
                                        recordData?.updateBy?
                                            recordData.updateBy.avatar !== null ?

                                            <Avatar size={32} src={recordData.updateBy.avatar.url} style={{marginTop: "-7px"}}/>
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                    }
                                    </Col>
                                    <Col className="gutter-row" span={8}><span>{t("lupdate")}</span></Col>
                                    <Col className="gutter-row" span={12}><TextField value={moment(recordData?.updatedAt).format('D/MM/yyyy HH:mm')}/></Col>
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                    <Col className="gutter-row" span={18}>
                                        <TextField style={{marginLeft: "3%"}} value={recordData?.updateBy !== null && recordData?.updateBy !== undefined? 
                                            recordData?.updateBy.first_name !== null && recordData.updateBy.last_name !== null ? 
                                            recordData.updateBy.first_name + " " + recordData.updateBy.last_name : null :"ไม่ระบุ"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </Form>
    </Edit>   
    );
};