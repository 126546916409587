import { Avatar, Badge, Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, Input, List, Modal,  Row,  Select,  ShowButton,  Space, Table, Tabs, Tag, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IContentPostPromotion, IEventlog, IProperties} from "interfaces";
// import Moment from 'moment';
import { useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const { TabPane } = Tabs;

let dataContent:any;
export const ContentPostPromotionList: React.FC<IResourceComponentsProps> = () => {

    const tabPromotion = localStorage.getItem("keyTabStatusPromotion");
    const [keepDataStatus, setKeepDataStatus] = useState<any>()

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Promotion");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const [ vInputTitle, setInputTitle ] = useState<any>(undefined);
    const [ vProp, setvProp ] = useState<any>();
    const [ vStatus, setStatus ] = useState<any>(undefined);
    const [ dlStatus, setdlStatus] = useState("N");

    const { confirm } = Modal;
    const { mutate } = useUpdate<IContentPostPromotion>();
    const ed_point = "content-promotions";
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const userQuery = useGetIdentity();
    const {data : user_data , isLoading} = userQuery;
    const vCurDelStatus = {deleteStatus : 'N'}

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IContentPostPromotion,HttpError, IContentPostPromotion>({
        metaData: {
            // populate: '*',
            populate: ['*', 'localizations','localizations.updateBy', 'pic','properties','updateBy','createBy', 'locale'],
            locale:['th']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const {deleteStatus} = params;
            // console.log(">>> vProp", vProp);
            // console.log(">>> vInputTitle", vInputTitle);
            // console.log(">>> vStatus", vStatus);
                filters.push(
                    {
                        field       : "properties][id]",
                        operator    : "eq",
                        value       : vProp !== undefined ? vProp : null,
                    },
                    {
                        field       : "name",
                        operator    : "contains",
                        value       : vInputTitle !== undefined ? vInputTitle : null
                    },
                    {
                        field       : "status",
                        operator    : "eq",
                        value       : vStatus !== undefined ? vStatus : null
                    },
                    {
                        field       : "deleteStatus",
                        operator    : "eq",
                        value       : !!keepDataStatus ? keepDataStatus : tabPromotion
                    },
                );
            return filters;
        },
        initialFilter: [
            // {
            //     field       : "deleteStatus",
            //     operator    : "eq",
            //     value       : !!keepDataStatus ? keepDataStatus : tabPromotion
            // },
            {
                field : "properties][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : "",
            }
        ],
        initialSorter:[
            {
                field: 'id',
                order: "desc",
            }
        ],
        initialPageSize: 1000,
        queryOptions: {
            onSuccess(data:any) {
                let dataLoad: any = data?.data;
                //console.log("dataLoad",dataLoad)
                
            },
        }
    });

    const dataPromotion = useList<IContentPostPromotion>({ 
        resource: "content-promotions" ,
        metaData: {
            populate: '*',
            locale:['th']
        },
        config:{ 
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
                {
                    field : "properties][id]",
                    operator: (prop_list || prop_id) ? "eq" : "nin",
                    value: prop_list ? prop_list: prop_id ? prop_id : "",
                }
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        queryOptions: {
            onSuccess(data:any) {
                let dataLoad: any = data?.data;
                //console.log("dataLoad",dataLoad)
                dataContent = dataLoad;
            },
        }
        
    });

    const datacus = useList<IContentPostPromotion>({ 
        resource: "content-promotions" ,
        metaData: {
            populate: '*',
            locale:['th']
        },
        config:{ 
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        
    });
    const {data:dataCUSTOM} = datacus

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
    });
    
    // const { triggerExport, isLoading: exportLoading } = useExport<IContentPostPromotion>({
    //     mapData: (item) => {
    //         //console.log(item)
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id, 
                        
    //             };
    //         }
    //     },
    // });

    const onReset = () => {
        setvProp(undefined)
        setInputTitle(undefined);
        setStatus(undefined);
        setdlStatus("N");
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    function onShowSizeChange(current:any, pageSize:any) {
            // console.log('onShowSizeChange : ', current, pageSize);
            setPageSizeChange(pageSize)
    }

    const { mutate: UpdateLocale } = useUpdate<any>();

    function updateStatus(id:any, localizeId:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
                // status : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        });

        if(localizeId && localizeId !== null || localizeId !== undefined){
            UpdateLocale({
                resource : ed_point,
                id: localizeId,
                values : {
                    updateBy        : user_data.user_profile.id + "",
                    deleteStatus    : vCurDelStatus.deleteStatus
                    // status    : vCurDelStatus.deleteStatus
                },errorNotification:false,
                successNotification:false,
                },{
                    onSuccess(data, variables, context) {
                        // close();
                    },
                }
            )
        }
    }

    function showDelConfirm(id:any, localizeId: any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'DRAFT'
                vCurDelStatus.deleteStatus = 'Y'
                // updateStatus(id, "DRAFT");
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, localizeId: any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'PUBLIC'
                vCurDelStatus.deleteStatus = 'N'
                // updateStatus(id, "PUBLIC");
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        setdlStatus(v)
        setKeepDataStatus(v)
        localStorage.setItem("keyTabStatusPromotion",v)
        searchFormProps.form?.submit();
        // localStorage.setItem("keyU",v)
        // localStorage.setItem("keyTabUserPortal", v)
    }

    return <>
            
            <List 
                title={t("content-post-promotion.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("content-post-promotion.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row>
                    <Col span={22}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                           <Row gutter={8} style={{width: "100%"}}>
                                <Col className="gutter-row">
                                    <Form.Item name="properties" style={{width: "200px", marginRight: "0"}}>
                                        <Select 
                                            {...propertySelectProps} 
                                            placeholder="Select property" 
                                            size="middle" 
                                            style={{borderRadius: 20, position:'relative'}} 
                                            allowClear
                                            onClear={() => setvProp(undefined)}
                                            onChange={(e) => setvProp(e)}
                                            />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item name="vInputTitle" style={{width: "200px", marginRight: "0"}}>
                                        <Input placeholder="Title" 
                                            onChange={e => {
                                                setInputTitle(e.target.value)
                                            }}
                                            allowClear
                                            style={{borderRadius: 5}}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item name="in" style={{width: "200px", marginRight: "0"}}>
                                        <Select placeholder={t("select.sel")}
                                            style={{ width: '100%' }}
                                            onSelect={(value: any) => {
                                                // console.log(value);
                                                setStatus(value);
                                            }}
                                            options={[
                                                {
                                                    label: "Public",
                                                    value: "PUBLIC",
                                                },
                                                {
                                                    label: "Draft",
                                                    value: "DRAFT",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item style={{width: "100px", marginRight: "0"}}>
                                        <Button htmlType="submit" type="primary" style={{borderRadius: "5px", width: "100%"}}>
                                            <Icons.SearchOutlined /> Search
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item style={{width: "100px", marginRight: "0"}}>
                                        <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px", width: "100%"}}>
                                            <Icons.UndoOutlined /> Reset
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                        

                    <Col span={2}>
                        <Form.Item style={{marginRight: "0", float: "right"}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form {...searchFormProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={!tabPromotion ? keepDataStatus : `${tabPromotion}`}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={onStatusChange} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={dataContent?.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={dataContent?.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table 
                    {...tableProps}
                    //dataSource={tableProps?.dataSource?.filter((f: any)=> f?.deleteStatus == dlStatus)}
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        // total:tableProps?.dataSource?.length,
                        total:tableQueryResult?.data?.total,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >

                    {/* <Table.Column width={30} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column align="center" width={30} dataIndex="pic" title="  " 
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                    {
                                        value ?
                                            <Avatar.Group>
                                                { 
                                                    value.map((item:any,index:any)=> {
                                                        // console.log('item', item)
                                                        return <>
                                                            <Avatar src={item.url}/>
                                                        </>
                                                    })
                                                }
                                            </Avatar.Group>
                                        :
                                            <>
                                                <Avatar size={32} icon={<Icons.UserOutlined />} />
                                            </>
                                    }
                                </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="seq" width={35} title="SEQUENCES" align="center"
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                <TextField value={record?.seq ? record?.seq : "-"} />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="properties" title={t("content-post-promotion.col-properties")}  width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.properties.length !== 0 ?
                                        record?.properties.map((data:any,index:any) => {
                                            // console.log('data : ', data);
                                            return <>
                                                <Tag className="tag_post"><TextField value={data? data.property_name : <></> }  /></Tag>
                                            </>
                                        })
                                    : <>-</>
                                }
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="properties" title={t("content-post-promotion.col-properties")}  width={150}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.property_dev == "All" ?
                                        <Tag className="tag_post"><TextField value={'All Properties'}  /></Tag>
                                    :   record?.property_dev == "Major" ?
                                            <Tag className="tag_post"><TextField value={'Only Major'}  /></Tag>
                                        : record?.property_dev == "Non Major" ?
                                            <Tag className="tag_post"><TextField value={'Non Major'}  /></Tag>
                                            :
                                                record?.properties.length !== 0 ?
                                                    record?.properties.map((data:any,index:any) => {
                                                        // console.log('data : ', data);
                                                        return <>
                                                            <Tag className="tag_post"><TextField value={data? data.property_name : <></> }  /></Tag>
                                                        </>
                                                    })
                                                : <>-</>
                                }
                            </>
                        }}
                    /> */}

                    <Table.Column 
                        // title={t("content-post-news.col-content-title")} 
                        title="CONTENT TITLE (TH)" 
                        width={300} align="center"
                        className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.name < b?.name) {return -1}
                            if (a?.name > b?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {
                                    record ? 
                                        record.locale == "th" ?
                                            <TextField value={record ? record?.name : "-"} />
                                        :record ?
                                            record.localizations.length > 0 ?
                                                record.localizations[0].locale = "th" ?
                                                    <TextField value={record ? record.localizations[0].name : "-"} />     
                                                : <>-</>   
                                            :<>-</>
                                        :<>-</>
                                    :<>-</>
                                }                            
                            </>
                        }}
                    />

                    <Table.Column title="CONTENT TITLE (EN)" width={300} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.name < b?.name) {return -1}
                            if (a?.name > b?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {
                                    record ? 
                                        record.locale == "en" ?
                                            <TextField value={record ? record?.name : "-"} />
                                        :record ?
                                            record.localizations.length > 0 ?
                                                record.localizations[0].locale = "en" ?
                                                    <TextField value={record ? record.localizations[0].name : "-"} />
                                                : <>-</>
                                            :<>-</>
                                        :<>-</>
                                    :<>-</>
                                }

                            </>
                        }}
                    />

                    <Table.Column title={t("content-post-promotion.col-public-date")}  width={120}
                        sorter={(a:any, b:any) => {
                            if (a?.start_date < b?.start_date) {return -1}
                            if (a?.start_date > b?.start_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.start_date? dayjs(record?.start_date).format("DD-MM-YYYY") :"-"} />
                            </>
                        }}
                    />

                    <Table.Column title={"END DATE"}  width={120}
                        sorter={(a:any, b:any) => {
                            if (a?.start_date < b?.start_date) {return -1}
                            if (a?.start_date > b?.start_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.end_date? dayjs(record?.end_date).format("DD-MM-YYYY") :"-"} />
                            </>
                        }}
                    />

                    <Table.Column title={t("content-post-promotion.col-stat")}  align="center" width={80}
                        sorter={(a:any, b:any) => {
                            if (a?.status < b?.status) {return -1}
                            if (a?.status > b?.status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.status : "-"} />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="updatedBy" title={t("content-post-promotion.col-updateby")}  align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.updateBy ? <>{record?.updateBy.first_name}{' '}{record?.updateBy.last_name}</> : "-"} /> 

                                {
                                    record ? 
                                        record?.updateBy ?
                                            <TextField value={<>{record?.updateBy.first_name}{' '}{record?.updateBy.last_name}</> } />
                                        :record ?
                                            record.localizations.length > 0 ?
                                                record.localizations[0].updateBy ?
                                                    <TextField 
                                                        // value={record.localizations[0].updateBy.first_name} 
                                                        value={<>{record.localizations[0].updateBy.first_name}{' '}{record.localizations[0].updateBy.last_name}</> } 
                                                    />     
                                                : <>-</>   
                                            :<>-</>
                                        :<>-</>
                                    :<>-</>
                                }

                            </> 
                        }}
                    /> */}

                    <Table.Column title="CREATE BY" align="center" width={120} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.createBy?.first_name.localeCompare(b?.createBy?.first_name)}
                            sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.createBy ? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={"CREATED DATE"}  align="center" width={120}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record? dayjs(record?.createdAt).format("DD-MM-YYYY HH:mm") :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={120} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.updateBy?.first_name.localeCompare(b?.updateBy?.first_name)}
                            sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={t("content-post-promotion.col-lastupdate")}  align="center" width={120}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updatedAt? dayjs(record?.updatedAt).format("DD-MM-YYYY HH:mm") :"-"}  />
                            </>
                        }}
                    />
                  
                    <Table.Column<any>
                        width={50}
                        title={t("content-post-promotion.col-action")} 
                        align    = "center"
                        dataIndex= "actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                 {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />

                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id, record?.localizations[0].id) }} danger></Button>
                                        </>
                                    :
                                        <> 
                                            <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id, record?.localizations[0].id) }} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}