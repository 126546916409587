/* eslint-disable */
import { Breadcrumb, Button, Card, Col, CreateButton, EditButton, Form, Icons, Input, List, Modal,  Row,  Select,  Space, Table, Tag, TextField, useModalForm, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IContentPostNews, IContentPostPromotion, IProperties} from "interfaces";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
let dataf:any = []
let limit_old_length : any = 0;
export const MgnNewsEvent: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { mutate: mutateNewsEntSeq} = useCreate<any>();

    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Manage New & Event");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const [ vProp, setProp ] = useState<any>();

    const [ vContentType, setContentType ] = useState<any>();
    const [ vContent, setContent ] = useState<any>();
    const [ vContentSeq, setContentSeq] = useState<any>();

    const [disabled, setDisabled] = useState(false);

    const [ vContentTypeArr, setContentTypeArr] = useState<any>([]);
    const [ vContentArr, setContentArr] = useState<any>([]);
    const [ vninContentNEW, setninContentNEW] = useState<any>([]);
    const [ vninContentAE, setninContentAE] = useState<any>([]);
    const [ vninContent, setninContent] = useState<any>([]);
    const [ vIndexArr, setIndexArr] = useState<any>([]);

    //===========================Edit===============================//
    const [ vContentArr_old, setContentArr_old] = useState<any>([]);

    const { confirm } = Modal;
    const { mutate } = useUpdate<IContentPostPromotion>();
    const ed_point = "manage-news-events";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const [CreatedProp, setCreatedProp] = useState(undefined);
    const pageSizeState = [ '10','30', '50', '100'];
    const userQuery = useGetIdentity();
    const {data : user_data} = userQuery;
    const vCurDelStatus = {deleteStatus : 'N'}

    const [ stateReload, setStateReload] = useState<any>();

    const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
        metaData: {
            populate: [
                '*',
                'property',
                'updateBy',
                'createBy',
                'news_event_seqs',
                'news_event_seqs.content_new',
                ],
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const {properties} = params;
                filters.push(
                    {
                        field       : "property][id]",
                        // operator    : (vProp || prop_list || prop_id) ? "eq" : "nin",
                        // value       : vProp ? vProp: prop_list ? prop_list: prop_id ? prop_id : null,
                        operator    : "eq",
                        value       : properties ? properties : null,
                    },
                    {
                        field       : "deleteStatus",
                        operator    : "eq",
                        value       : !!stateReload ? stateReload : null,
                        
                    },
                    // {
                    //     //field       : "title",
                    //     field       : "news_event_seqs][content_new]][short_detail]",
                    //     operator    : "contains",
                    //     value       : title ? title : null
                    // },
                );
            return filters;
        },
        initialFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],queryOptions: {
            onSuccess(data:any) {
                //console.log("see",data?.data)
                setStateReload(null)
            },
        },
    });
    
    // manage-news-event.manage-news-event
    const contentPostType = useList<any>({
        resource: "manage-news-events",
        metaData: {
            populate: '*'
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad:any = data?.data;
                let kk = dataLoad.map((item:any,key:any) => { 
                    return item.property.id
                })
                setCreatedProp(kk.filter((item: any, index: any, array: string | any[]) => array.indexOf(item) === index)) 
            },
        }
    });

    //console.log('CreatedProp', CreatedProp)

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        fetchSize: 200,
        optionValue: "id",
        filters: !prop_list && !prop_id || !prop_list || !prop_id  ? 
        [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
        ] :
        [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const { selectProps: propertySelectPropsSearch } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        fetchSize: 200,
        optionValue: "id",
        filters: !prop_list && !prop_id || !prop_list || !prop_id  ? 
        [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
        ] :
        [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const { selectProps: contentType } = useSelect<IProperties>({
        resource    : "content-post-types",
        optionLabel : "type_name",
        optionValue : "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       : 'N',
            },
            {
                field : 'type_name',
                operator : 'contains',
                value :  ["Activity","News"],
            },
        ]
    });

    //console.log('vProp', vProp)

    const { selectProps: content_news } = useSelect<IContentPostNews>({
        resource    : "content-news",
        optionLabel : "name",
        optionValue : "id",
        metaData: {
            populate: '*',
            locale:['th']
        },
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       : 'N',
            },
            // {
            //     field : 'properties][id]',
            //     operator : 'contains',
            //     value :  vProp,
            // },
            {
                field       : "properties][id]",
                operator    : (vProp || prop_list || prop_id) ? "eq" : "nin",
                value       : vProp ? vProp: prop_list ? prop_list: prop_id ? prop_id : null,
            },
            // {
            //     field : 'properties][id]',
            //     operator : vProp ? 'contains' : 'ne',
            //     value :  vProp ? vProp : 'xxxxx',
            // },
            {
                field : 'id',
                operator : 'nin',
                value :  vninContent.filter((f:any) => {return f?.type == "News"})?.map((f:any) => {return f?.data}),
            },
        ]
    });

    const { selectProps: content_aes } = useSelect<IContentPostNews>({
        resource    : "content-aes",
        optionLabel : "name",
        optionValue : "id",
        metaData: {
            populate: '*',
            locale:['th']
        },
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       : 'N',
            },
            // {
            //     field : 'properties][id]',
            //     operator : 'contains',
            //     value :  vProp,
            // },
            {
                field       : "properties][id]",
                operator    : (vProp || prop_list || prop_id) ? "eq" : "nin",
                value       : vProp ? vProp: prop_list ? prop_list: prop_id ? prop_id : null,
            },
            // {
            //     field : 'properties][id]',
            //     operator : vProp ? 'contains' : 'ne',
            //     value :  vProp ? vProp : 'xxxxx',
            // },
            {
                field : 'id',
                operator : 'nin',
                value :  vninContent.filter((f:any) => {return f?.type == "Activity & Event"})?.map((f:any) => {return f?.data}),
                // value :  vninContentAE,
            },
        ]
    });

    function createNewEventSeq(valueID: any){
        if(vContentArr?.length > 0){
            for(let i=0 ; i <= vContentArr.length -1; i++){
                mutateNewsEntSeq({
                    resource    : "news-event-seqs",
                    values      : {
                        content_ae : vContentTypeArr[i] == "Activity & Event" ? vContentArr[i] : null,
                        content_new : vContentTypeArr[i] == "News" ? vContentArr[i] : null,
                        seq : vIndexArr[i],
                        manage_news_event: valueID,
                    },
                    successNotification : false,
                },{onSuccess(data, variables, context) {
                    setTimeout(() => {
                        searchFormProps.form?.resetFields()
                        searchFormProps.form?.submit()
                    }, 300);
                },});
            }
        }
        else{
            setTimeout(() => {
                searchFormProps.form?.resetFields()
                searchFormProps.form?.submit()
            }, 300);
        }
    }

    function onShowSizeChange(current:any, pageSize:any) {
            setPageSizeChange(pageSize)
    }

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                updateBy        : user_data.user_profile.id + "",
                // deleteStatus    : vCurDelStatus.deleteStatus
                deleteStatus : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function toggleDisabled(mode: any) {
        if(mode == "add"){
            setDisabled(!disabled);
        }else if(mode == "del"){
            if(disabled == true){
                setDisabled(!disabled);
            }
        }
    }

    function onsetConfirmArr(index :any ,type:any ,content:any ,seq: any){
        if(vContentArr[index]){
            vContentTypeArr[index] = type;
            vContentArr[index] = content;
            vIndexArr[index] = seq;
            dataf?.splice(index, 1, {type: type, data: content});
            setninContent(dataf)
        }else if(vContentArr[index] == undefined) {
            vContentTypeArr.push(type);
            setContentTypeArr(vContentTypeArr);
    
            vContentArr.push(content);
            setContentArr(vContentArr);
    
            vIndexArr.push(seq);
            setIndexArr(vIndexArr);

            setninContent((pre:any) => [...pre, {type: type, data: content}]);
            dataf = [...dataf, {type: type, data: content}]
        }
    }
    
    function onsetRemoveArr(index:any ,type:any ,content:any ,seq: any, field: any){
        if(vContentArr[index]){
            vContentTypeArr?.splice(index, 1);
            setContentTypeArr((pre:any)=>[...vContentTypeArr])
            vContentArr?.splice(index, 1);
            setContentArr((pre:any)=>[...vContentArr])
            vIndexArr?.splice(index, 1);
            setIndexArr((pre:any)=>[...vIndexArr])

            dataf?.splice(limit_old_length + index, 1);
            setninContent(dataf)
        }
    }

    function clearState(){
        setContent(undefined)
        setContentType(undefined)
        setContentSeq(undefined)
    }

    function delarr(v: any, index: any){
        let index_find = vContentArr_old?.findIndex((i: { id: any; }) => i.id === v.id);
        vContentArr_old?.splice(index_find, 1);
        setContentArr_old((pre:any)=>[...vContentArr_old])
        dataf?.splice(index, 1);
        setninContent(dataf)
        limit_old_length = limit_old_length - 1;
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose,
    } = useModalForm<any>({
        resource: "manage-news-events",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false,
        onMutationSuccess(data, variables, context) {
            let recordData:any = data?.data? data?.data : undefined;
            if(recordData){
                searchFormProps.form?.setFieldsValue({title: " "})
                searchFormProps.form?.submit()
                createNewEventSeq(recordData?.data?.id);
            }
        },
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "manage-news-events",
        metaData: {
            populate: ["property","news_event_seqs","news_event_seqs.content_ae","news_event_seqs.content_new","news_event_seqs.manage_news_event"],
        },
        action: "edit",
        redirect: false,
        onMutationSuccess(data, variables, context) {
            let recordData:any = data?.data? data?.data : undefined;
            if(recordData){
                searchFormProps.form?.setFieldsValue({title: " "})
                searchFormProps.form?.submit()
                createNewEventSeq(recordData?.data?.id);
            }
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            //console.log('xxx dataLoad > ', dataLoad)
            setProp(dataLoad?.property?.id);
            setContentArr_old((pre:any)=>[...pre, ...dataLoad?.news_event_seqs]);
            limit_old_length = dataLoad?.news_event_seqs?.length;
            setContentSeq(dataLoad?.news_event_seqs?.length);

            if(dataLoad?.news_event_seqs?.length > 0){
                dataLoad?.news_event_seqs?.filter((item:any) => item?.content_new !== null)?.map((el:any, index:any) =>{
                    return el?.content_new &&
                        dataf.push({type: "News", data: el?.content_new?.id}),
                        setninContent(dataf);
                })
            }

            if(dataLoad?.news_event_seqs?.length > 0){
                dataLoad?.news_event_seqs?.filter((item:any) => item?.content_ae !== null)?.map((el:any, index:any) =>{
                    return el?.content_ae &&
                        dataf.push({type: "Activity & Event", data: el?.content_ae?.id}),
                        setninContent(dataf);
                })
            }

        },}
    });

    return <>      
            <List 
                title={t("mgn-news-event.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("content-post-promotion.list")}</span></Breadcrumb.Item> */}
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("mgn-news-event.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={22}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                           <Row gutter={8} style={{width: "100%"}}>
                                <Col className="gutter-row">
                                    <Form.Item name="properties" style={{width: "200px", marginRight: "0"}}>
                                        <Select 
                                            {...propertySelectPropsSearch} 
                                            placeholder="Select property" 
                                            size="middle" 
                                            style={{borderRadius: 20, position:'relative'}} 
                                            allowClear
                                            // onSelect={(e : any) => setvProp(e)}
                                        />
                                    </Form.Item>
                                </Col>

                                {/* <Col className="gutter-row">
                                    <Form.Item name="title" style={{width: "200px", marginRight: "0"}}>
                                        <Input placeholder="Title" 
                                            // onChange={e => {
                                            //     setInputTitle(e.target.value)
                                            // }}
                                            allowClear
                                            style={{borderRadius: 5}}
                                        />
                                    </Form.Item>
                                </Col> */}


                                <Col className="gutter-row">
                                    <Form.Item style={{width: "100px", marginRight: "0"}}>
                                        <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                            <Icons.SearchOutlined /> Search
                                        </Button>
                                    </Form.Item> 
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item style={{width: "100px", marginRight: "0"}}>
                                        <Button 
                                            onClick={() => {
                                                setStateReload(null)
                                                searchFormProps.form?.resetFields()
                                                searchFormProps.form?.submit()
                                            }} 
                                            htmlType="submit" style={{borderRadius: "5px"}}
                                        >
                                            <Icons.UndoOutlined /> Reset
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    <Col span={2}>
                        <Form.Item style={{marginRight: "0", float: "right"}}>
                            <CreateButton 
                                id="create_btn" 
                                disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                                onClick={() => createModalShow()}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Table 
                    {...tableProps}
                    bordered
                    size ="middle"
                    rowKey = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        // total:tableProps?.dataSource?.length,
                        total:tableQueryResult?.data?.total,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >
                    {/* <Table.Column width={70} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column dataIndex="title" width={200} title="TITLE" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value ? value : "ไม่ระบุ"} />
                            </>
                        }}
                    /> */}

                    <Table.Column title={t("content-post-promotion.col-properties")}  width={50} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.property?.property_name < b?.property?.property_name) {return -1}
                            if (a?.property?.property_name > b?.property?.property_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log("ttttttt",record)
                            return <>           
                                <Tag style={{borderRadius: 10, background: "#8FEF66",color: "#fff",fontWeight: "500",letterSpacing: "1px"}}>{record.property ? record?.property?.property_name : "ไม่ระบุ"}</Tag>
                            </>
                        }}
                    />

                    <Table.Column 
                        title="CONTENT TITLE (TH)" 
                        width={300}
                        align="center"
                        className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.news_event_seqs[0]?.content_new < b?.news_event_seqs[0]?.content_new) {return -1}
                            if (a?.news_event_seqs[0]?.content_new > b?.news_event_seqs[0]?.content_new) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            // return <TextField value={record?.news_event_seqs[0].content_new.name} />
                            // return <TextField value={record?.news_event_seqs ? record?.news_event_seqs[0]?.content_new?.name : "-"} />
                            return <TextField 
                            value={ record?.news_event_seqs[0]?.content_new ? record?.news_event_seqs[0]?.content_new?.name : record?.news_event_seqs[0]?.content_ae ? record?.news_event_seqs[0]?.content_ae?.name : "-"} 
                            />
                        }}
                    />

                    <Table.Column
                        title="CONTENT TITLE (EN)" 
                        width={300} 
                        align="center"
                        className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.news_event_seqs[0]?.content_new?.name < b?.news_event_seqs[0]?.content_new?.name) {return -1}
                            if (a?.news_event_seqs[0]?.content_new?.name > b?.news_event_seqs[0]?.content_new?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            // return <TextField value={record?.news_event_seqs[0].content_new.name} />
                            return <TextField value={record?.news_event_seqs ? record?.news_event_seqs[0]?.content_new ? record?.news_event_seqs[0]?.content_new?.name : record?.news_event_seqs[0]?.content_ae ? record?.news_event_seqs[0]?.content_ae?.name : "-" : "-" } />
                        }}
                    />

                    {/* <Table.Column width={50} title="CONTENT" dataIndex="news_event_seqs" align="center"
                        render={(value:any,record:any)=>{
                            return <TextField value={value?.length} />
                        }}
                    /> */}

                     <Table.Column width={150} title="PUBLIC DATE" align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.news_event_seqs < b?.news_event_seqs) {return -1}
                            if (a?.news_event_seqs > b?.news_event_seqs) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //{console.log("first",record)}
                            // return <TextField value={record? <>{dayjs(record?.news_event_seqs[0].content_new.start_date).format("DD/MM/YYYY")}</> :"-"} />
                            return <TextField value={record?.news_event_seqs ? <>{dayjs(record?.news_event_seqs[0]?.content_new?.start_date).format("DD/MM/YYYY")}</> :"-"} />
                        }}
                    />

                    <Table.Column<any>
                        width={50}
                        title={t("content-post-promotion.col-action")} 
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record?.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                                onClick={() => editModalShow(record?.id)}
                                            />
                                            <Button size="small" title="Inactive" icon={<Icons.LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <Button size="small" title="Active" icon={<Icons.UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                }
                            </Space>
                        )}
                    />
                </Table>

            </List>

            <Modal 
                {...createModalProps}
                title={"Manage News and Event Create"}
                okText = {"Create"}
                maskClosable={false}
                width={700}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                    clearState();
                    setProp(undefined);
                    setContentArr([]);
                    setContentType([]);
                    setContent(undefined);
                    setContentType(undefined);
                    setDisabled(false);
                    setninContentAE([])
                    setninContentNEW([])
                    setContentSeq(undefined);
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.title = values.title;
                        values.property = values.property;
                        values.deleteStatus = "N";
                        values.createBy = user_data.user_profile.id;
                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={12} className="gutter-row">
                            <Form.Item  label={t("property.title")} name="property"
                                rules={[
                                    {
                                        required: true, message: 'Please select property.'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")} onChange={(e)=> setProp(e)} {...propertySelectProps}/>
                            </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Form.Item  
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true, message: 'Please enter title.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.List name="fields">
                                {(fields, { add, remove }) => {
                                    return (
                                    <div>
                                        <Row gutter={8}>
                                            <Col className="gutter-row" span={6}>
                                                <Form.Item>
                                                    <span style={{fontSize: "0.7vw",color: "#000"}}>Add Sequences</span>
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" span={10}>
                                                <Form.Item>
                                                    <Button
                                                        type="dashed"
                                                        onClick={() => { add(); toggleDisabled("add");}}
                                                        style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                        disabled={disabled}
                                                    >
                                                        <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                    </Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {fields.map((field, index) => (
                                            <div key={field.key}>
                                                <Card style={{marginBottom: "1rem",border: "1px solid #dedede"}}>
                                                    <Row gutter={8}>
                                                        {setContentSeq(index+1)}

                                                        <Col className="gutter-row" span={2}>
                                                            <Form.Item label=" ">
                                                                <Tag color="green">{index+1} .</Tag>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col className="gutter-row" span={6}>
                                                            <Form.Item
                                                                name={[index, 'content_type']}
                                                                label="Content Type"
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select content type'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select {...contentType} onChange={(e: any , value: any)=> {
                                                                    setContentType(value?.label);
                                                                }}disabled={fields?.length == index+1 ? false: true }/>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col className="gutter-row" span={12}>
                                                            {vContentType == "News" ? 
                                                                <Form.Item
                                                                    label="Content"
                                                                    rules={[{required: true, message: 'Please select content'},]}
                                                                >
                                                                    <Select
                                                                        {...content_news}
                                                                        onChange={(e) => setContent(e)}
                                                                        disabled={fields?.length == index+1 ? false: true }
                                                                    />
                                                                </Form.Item>
                                                            :vContentType == "Activity & Event" ?
                                                                <Form.Item
                                                                    label="Content"
                                                                    rules={[{required: true, message: 'Please select content'},]}
                                                                >
                                                                    <Select
                                                                        {...content_aes}
                                                                        onChange={(e) => setContent(e)}
                                                                        disabled={fields?.length == index+1 ? false: true }
                                                                    />
                                                                </Form.Item>
                                                            :
                                                                <Form.Item
                                                                    label="Content"
                                                                    rules={[{required: true, message: 'Please select content'},]}
                                                                >
                                                                    <Select
                                                                        // {...content_aes}
                                                                        onChange={(e) => setContent(e)}
                                                                        disabled={fields?.length == index+1 ? false: true }
                                                                    />
                                                                </Form.Item>
                                                            }
                                                        </Col>

                                                        {
                                                            vContentType && vContent && fields.length == index+1 &&
                                                                <Col span={1} style={{marginRight: "0.5rem"}}>
                                                                    {
                                                                        vContentType && vContent && fields.length == index+1 &&
                                                                            <Form.Item label=" ">
                                                                                <Button
                                                                                    size="small"
                                                                                    onClick={() => {onsetConfirmArr(index ,vContentType ,vContent ,limit_old_length + vContentSeq); toggleDisabled("add"); setContentSeq(limit_old_length + vContentSeq); clearState();}}
                                                                                    style={{marginRight: "0.5rem"}}
                                                                                >
                                                                                    <Icons.CheckOutlined/>
                                                                                </Button>
                                                                            </Form.Item>
                                                                    }
                                                                </Col>
                                                        }
                                                        
                                                        <Col span={1}>
                                                            {
                                                                fields.length > 0 && (
                                                                    <Form.Item label=" ">
                                                                        <Button
                                                                            size="small"
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => { onsetRemoveArr(index ,vContentType ,vContent ,vContentSeq ,fields); remove(field.name); toggleDisabled("del"); clearState();}}
                                                                            danger
                                                                        >
                                                                            <Icons.CloseOutlined />
                                                                        </Button>
                                                                    </Form.Item>
                                                                )
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                        ))}
                                    </div>
                                    );
                                }}
                            </Form.List>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Manage News and Event Edit"}
                okText = {"Save"}
                maskClosable={false}
                width={700}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                    clearState();
                    setProp(undefined);
                    setContent(undefined);
                    setContentType(undefined);
                    setDisabled(false);
                    setContentSeq(undefined);

                    setContentArr([]);
                    setContentType([]);
                    setContentTypeArr([]);
                    setIndexArr([])
                    setninContentAE([])
                    setninContentNEW([])
                    setninContent([])
                    setContentArr_old([]);
                    limit_old_length = 0;
                    dataf= [];

                    setStateReload(["N","Y"])
                    searchFormProps.form?.submit()
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.title = values.title;
                        values.property = values.property;
                        values.news_event_seqs = vContentArr_old
                        values.updateBy = user_data?.user_profile?.id;
                        return (
                            editProps.onFinish &&
                            editProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={12} className="gutter-row">
                            <Form.Item  label={t("property.title")} name={["property","id"]}
                                rules={[
                                    {
                                        required: true, message: 'Please select property.'
                                    },
                                ]}>
                                    <Select 
                                        placeholder={t("select.sel")}
                                        onChange={(e) => setProp(e)}
                                        {...propertySelectProps}
                                        disabled={true}
                                    />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Form.Item  
                                label="Title"
                                name="title"
                                rules={[
                                    {
                                        required: true, message: 'Please enter title.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={24}>
                            <Form.List name="fields">
                                {(fields, { add, remove }) => {
                                    return (
                                        <div>
                                            <Row gutter={8}>
                                                <Col className="gutter-row" span={6}>
                                                    <Form.Item>
                                                        <span style={{fontSize: "0.7vw",color: "#000"}}>Add Sequences</span>
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row" span={10}>
                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => { add(); toggleDisabled("add");}}
                                                            style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                            disabled={disabled}
                                                        >
                                                            <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            
                                            {
                                                vContentArr_old && vContentArr_old?.length > 0 && vContentArr_old?.map((el:any, index:any) =>{
                                                    return <div key={el.id}>
                                                        <Card style={{marginBottom: "1rem",border: "1px solid #dedede"}}>
                                                            <Row gutter={8}>
                                                                {/* {setContentSeq(index+1)} */}

                                                                <Col className="gutter-row" span={2}>
                                                                    <Form.Item label=" ">
                                                                        <Tag color="orange">{index+1} .</Tag>
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col className="gutter-row" span={6}>
                                                                    <Form.Item
                                                                        name={[el?.id + index, 'content_type']}
                                                                        label="Content Type"
                                                                        rules={[
                                                                            {
                                                                                required: false, message: 'Please select content type'
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select 
                                                                            {...contentType}
                                                                            defaultValue={
                                                                                el.content_new ? contentType?.options?.[0]?.value: el.content_ae && contentType?.options?.[1]?.value
                                                                            }
                                                                            disabled={true}
                                                                        />
                                                                    </Form.Item>
                                                                </Col>

                                                                <Col className="gutter-row" span={12}>
                                                                    {el.content_new ? 
                                                                        <Form.Item
                                                                            label="Content"
                                                                            name={[el?.id + index, 'content_news']}
                                                                            rules={[{required: false, message: 'Please select content'},]}
                                                                        >
                                                                            <Select
                                                                                {...content_news}
                                                                                defaultValue={el?.content_new && el?.content_new?.name}
                                                                                disabled={true}
                                                                            />
                                                                        </Form.Item>
                                                                    :el.content_ae &&
                                                                        <Form.Item
                                                                            label="Content"
                                                                            name={[el?.id + index, 'content_ae']}
                                                                            rules={[{required: false, message: 'Please select content'},]}
                                                                        >
                                                                            <Select
                                                                                {...content_aes}
                                                                                defaultValue={el?.content_ae && el?.content_ae?.name}
                                                                                disabled={true}
                                                                            />
                                                                        </Form.Item>
                                                                    }
                                                                </Col>
                                                                
                                                                <Col span={1}>
                                                                    {
                                                                        vContentArr_old?.length > 0 && (
                                                                            <Form.Item label=" ">
                                                                                <Button
                                                                                    size="small"
                                                                                    className="dynamic-delete-button"
                                                                                    // onClick={() => { remove(field.name); clearState(); toggleDisabledX(); }}
                                                                                    onClick={() => {delarr(el, index); remove(el);}}
                                                                                    danger
                                                                                >
                                                                                    <Icons.CloseOutlined />
                                                                                </Button>
                                                                            </Form.Item>
                                                                        )
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </Card>
                                                    </div>
                                                })
                                            }
                                            
                                            {fields.map((field, index) => (
                                                <div key={field.key}>
                                                    <Card style={{marginBottom: "1rem",border: "1px solid #dedede"}}>
                                                        <Row gutter={8}>
                                                            <Col className="gutter-row" span={2}>
                                                                <Form.Item label=" ">
                                                                    <Tag color="green">{limit_old_length + index + 1} .</Tag>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col className="gutter-row" span={6}>
                                                                <Form.Item
                                                                    name={[index, 'content_type']}
                                                                    label="Content Type"
                                                                    rules={[
                                                                        {
                                                                            required: true, message: 'Please select content type'
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select {...contentType} onSelect={(e: any , value: any)=> {
                                                                        editProps?.form?.resetFields([field.key,"content_custom_by_i"])
                                                                        setContentType(value?.label)
                                                                    }} disabled={fields?.length == index+1 ? false: true }/>
                                                                </Form.Item>
                                                            </Col>

                                                            <Col className="gutter-row" span={12}>
                                                                {vContentType == "News" ? 
                                                                    <Form.Item
                                                                        label="Content"
                                                                        // name={[field.key,"content_custom_by_i"]}
                                                                        rules={[{required: true, message: 'Please select content'},]}
                                                                    >
                                                                        <Select
                                                                            {...content_news}
                                                                            onChange={(e) => setContent(e)}
                                                                            disabled={fields.length == index+1 ? false: true }
                                                                        />
                                                                    </Form.Item>
                                                                :vContentType == "Activity & Event" ?
                                                                    <Form.Item
                                                                        label="Content"
                                                                        // name={[index,"content"]}
                                                                        // name={[field.key,"content_custom_by_i"]}
                                                                        rules={[{required: true, message: 'Please select content'},]}
                                                                    >
                                                                        <Select
                                                                            {...content_aes}
                                                                            onChange={(e) => setContent(e)}
                                                                            disabled={fields.length == index+1 ? false: true }
                                                                        />
                                                                    </Form.Item>
                                                                :
                                                                    <Form.Item
                                                                        label="Content"
                                                                        rules={[{required: true, message: 'Please select content'},]}
                                                                    >
                                                                        <Select
                                                                            // {...content_aes}
                                                                            onChange={(e) => setContent(e)}
                                                                            disabled={fields.length == index+1 ? false: true }
                                                                        />
                                                                    </Form.Item>
                                                                }
                                                            </Col>

                                                            {
                                                                vContentType && vContent && fields.length == index+1 &&
                                                                    <Col span={1} style={{marginRight: "0.5rem"}}>
                                                                        {
                                                                            vContentType && vContent && fields.length == index+1 &&
                                                                                <Form.Item label=" ">
                                                                                    <Button
                                                                                        size="small"
                                                                                        onClick={() => {onsetConfirmArr(index ,vContentType ,vContent ,limit_old_length + vContentSeq); toggleDisabled("add"); setContentSeq(limit_old_length + vContentSeq); clearState();}}
                                                                                        style={{marginRight: "0.5rem"}}
                                                                                    >
                                                                                        <Icons.CheckOutlined/>
                                                                                    </Button>
                                                                                </Form.Item>
                                                                        }
                                                                    </Col>
                                                            }
                                                            
                                                            <Col span={1}>
                                                                {
                                                                    fields.length > 0 && (
                                                                        <Form.Item label=" ">
                                                                            <Button
                                                                                size="small"
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => { onsetRemoveArr(index ,vContentType ,vContent ,vContentSeq ,fields); remove(field.name); toggleDisabled("del"); clearState();}}
                                                                                danger
                                                                            >
                                                                                <Icons.CloseOutlined />
                                                                            </Button>
                                                                        </Form.Item>
                                                                    )
                                                                }
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    );
                                }}
                            </Form.List>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}