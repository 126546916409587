/* eslint-disable */
import { Card, Col, Edit, Form, Input, Row, useForm,Divider, TextField,List, Breadcrumb } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useShow, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IPPType } from "interfaces";
// import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";

import Moment from 'moment';

export const PPtypesEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    // const { formProps, saveButtonProps }    = useForm<IPPType,HttpError,{}>();
    const { formProps, saveButtonProps }    = useForm<IPPType>({redirect:"list"});
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const { queryResult } = useShow<IPPType>({
        metaData: {
            populate: '*'
        }, 
    });
    const { data }      = queryResult;
    const record        = data?.data;
    // const { replace}    = useNavigation();
    // const API_URL       = useApiUrl();

    // const { ...uploadProps_coverpix} = useStrapiUpload({ maxCount: 1, });

    // function fileChange(e:any){
    //     console.log(e);
    //     if(e.file.response){
    //         let res = e.file.response;
    //         console.log(res[0]);
    //     }
    // }

    function updateUserUpdatedBy(){
        // mutate({
        //     resource    : end_point,
        //     id          : queryResult.data?.data.id+"",
        //     // values      : vCurUserUpdatedBy,
        //     values: { user_updated_by: user?.id, deleteStatus: 'N' },
        // });

        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'pp_type',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
        // replace("")
        // console.log("test event");
        // window.history.back()
        // window.location.reload(history.back()); 
    }

    // console.log('edit_pptype',record)

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("pp-type-create.title")}
                
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pp-type.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("pp-type-create.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    //values.photo_list   = vphoto_list;
                    updateUserUpdatedBy()
                    values.updateBy = user_data.user_profile.id
                    values.updatedBy = user_data.user_profile.id
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                       
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ borderRadius: 5,boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("properties.code")} name="pp_code"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter properties code.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("properties.name")} name="pp_name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter properties name.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "2%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                            <Divider></Divider>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "5%"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("lupdate")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("by")}</div>
                                </Col>
                                                        
                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};