import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, Tabs, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, CreateButton } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
// import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
// import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { getValueProps } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import axios from "axios";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";


let swLocal : boolean = true;

export const ConfigPaymentList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Config Payment");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()

    const { confirm } = Modal;
    const { mutate: updateStat } = useUpdate<any>();
    
    const { TabPane } = Tabs;
    const API_URL = useApiUrl();
    const { Dragger } = Upload;
    const { Option } = Select;

    const [DataCus, setDataCus] = useState<any>();
    const [swBankType, setswBankType] = useState<any>();
    const [keep, setKeep] = useState<any>();

    useEffect(() => {
    if(!!DataCus?.bank_type){
        setKeep(DataCus?.bank_type)
    }
    }, [DataCus])
    useEffect(() => {
        //console.log("keep",keep)
    }, [keep])
    
    const [vswLocal, setswLocal] = useState<boolean>(true);
    const { mutate: UpdateLocale } = useUpdate<any>();
    const [vFile, setFile] = useState<any>([]);
    const [vPropID, setPropID] = useState<any>();

    // console.log('vPropID', vPropID)
    const [FileList, setFileList] = useState<any>([]);
    const [vExpenseFilter, setExpenseFilter] = useState<any>();

    const [ vExpenseType, setExpenseType] = useState<any>([]);
    // const [ vExpenseTypeName, setExpenseTypeName] = useState<any>([]);
    function onChangeExpense(va: any) {
        vExpenseType.pop();
        vExpenseType.push(va);
        setExpenseType(vExpenseType);
      }

    const [countl, setcountl] = useState<any>(0)
    
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const pageSizeState = [ '10','30', '50', '100'];

    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;

    const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
        resource: "config-payments",
        metaData: {
            populate: [
                'locale',
                'bank',
                'property',
                'createBy',
                'updateBy',
                'expense_types',
                'localizations',
                'localizations.bank',
                'localizations.property',
                'localizations.createBy',
                'localizations.updateBy',
                'localizations.expense_types',
            ],
            locale:['th']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property, expense_type, bank_type } = params;
            filters.push(
                {
                    field       : "property][id]",
                    // operator    : "eq",
                    // value       : property !== undefined ? property : null
                    // operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                    operator    : "eq",
                    value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                },
                {
                    field       : "expense_types][id]",
                    operator    : "eq",
                    value       : expense_type !== undefined ? expense_type : null
                },
                {
                    field       : "bank_type",
                    operator    : "eq",
                    value       : bank_type !== undefined ? bank_type : null
                },
            );
            return filters;
        },
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
        initialFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        // permanentFilter:[
        //     {
        //         field : "property][id]",
        //         operator: data_property ? "eq" : "nin",
        //         value: data_property ? data_property : null
        //     }
        // ],
        queryOptions:{onSuccess(data) {
            // console.log('data', data)
            setTotalData(data?.total)
        },}
    });

    // console.log('tableProps', tableProps)

    const datacus = useList<any>({ 
        resource: "documents" ,
        metaData: {
            populate: '*',
            locale:['th']
        },
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        }
    });
    const {data:dataCUSTOM} = datacus

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
    } = useModalForm<any>({
        resource: "config-payments",
        metaData: {
            populate: '*',
            locale:['th']
        },
        action: "create",
        redirect: false
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading ,
        show: editModalShow,
        close
    } = useModalForm<any>({
        resource: "config-payments",
        metaData: {
            populate: [
                'locale',
                'bank',
                'property',
                'createBy',
                'updateBy',
                'expense_types',
                'expense_types.type_name',
                'pic',
                'localizations',
                'localizations.bank',
                'localizations.property',
                'localizations.createBy',
                'localizations.updateBy',
                'localizations.expense_types',
                'localizations.pic',
            ],
            locale:['th']
        },
        action: "edit",
        redirect: false,
        queryOptions:{
            onSuccess(data){
            // console.log('xxxxxx', data?.data)
            setDataCus(data?.data)

            vExpenseType.pop();
            vExpenseType.push(data?.data?.expense_types !== undefined ?
                data?.data.expense_types.length > 0 ? 
                    data?.data?.expense_types.map((v:any)=>{
                        return v.id
                    }) 
                : <></>
            : <></>)
            setExpenseType(vExpenseType);


            // console.log('vExpenseType', vExpenseType)

            if(data?.data?.bank_type == "Cross Bank"){
                setswBankType("Cross Bank")
            }else if(data?.data?.bank_type == "Non Cross Bank"){
                setswBankType("Non Cross Bank")
            }
            // console.log("first",data?.data?.bank_type)
            if(data?.data?.pic !== null){
                let sortFileListData = data?.data?.pic.sort(function (a: { id: number; }, b: { id: number; }) {
                    return b.id - a.id;
                });
                setFileList((pre:any)=> ([...pre , ...sortFileListData]));
            }else{
                setFileList([])
            }
        },
    },
    });

    // console.log('vExpenseFilter', vExpenseFilter)


    const { selectProps: selEx } = useSelect<any>({
        resource    : "expense-types",
        optionLabel : "type_name",
        optionValue : "id",
        metaData: {
            populate: '*',
        },
        filters: [
            {
                field: "id]", 
                operator: "nin",
                value: vExpenseFilter ? vExpenseFilter : null
            },
            {
                field: "id]",
                operator: "in",
                value: ['8','9']
            },
        ],
        fetchSize: 1000,
    });

    const { selectProps: selBank } = useSelect<any>({
        resource    : "banks",
        optionLabel : "bank_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    useEffect(() => {
        if(DataCus && countl == 0){
            const fileList: UploadFile[] = 
                DataCus?.pic ? DataCus?.pic.map((v:any)=>(
                    {
                        uid: String(v.id),
                        name: v.name,
                        status: "done",
                        url: v.url,
                        thumbUrl: v.url
                    }
                )):[];

            setFile({
                name: 'files',
                multiple: true,
                defaultFileList: [...fileList],
            })

            editProps?.form?.resetFields(["file"]);
            // editProps?.form?.setFieldsValue({
            //     ...editProps?.form ,
            //     bank_type: "Cross Bank",
                
                
            // })

            setcountl(1);
        }

       
    }, [editProps])
    
    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };


    const checkUsedExpense = async (bank_type:any) => {
        // console.log('data_property', data_property)
        // console.log('vPropID', vPropID)
        // console.log('bank_type', bank_type)
        if(bank_type == "Cross Bank"){
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            let resultQuery = await axios.get(API_URL + `/like-systems/findUsedExpense?property_id=${data_property !== null? data_property : vPropID}` , { headers: { Authorization: AuthStr } });
            // console.log('resultQuery', resultQuery)
            if(resultQuery?.data == "200"){
                setExpenseFilter([])
            }else{
                setExpenseFilter(resultQuery?.data)
            }
        }else{
            setExpenseFilter([])
        }
    }

    // console.log('vExpenseFilter', vExpenseFilter)

    function loadLocal(){
        if(swLocal == true){
            editProps?.form?.setFieldsValue({
                property: DataCus ? DataCus.property : null,
                expense_type: DataCus ? DataCus.expense_type : null,
                bank_type: keep ? keep : DataCus ? DataCus.bank_type : null, 
                account_name: DataCus ? DataCus.account_name : null,
                account_no: DataCus ? DataCus.account_no : null
            })
                

            
            if(DataCus.bank_type){
                setswBankType(DataCus.bank_type);
                if(DataCus.bank_type == "Cross Bank"){
                    editProps?.form?.setFieldsValue({
                        ref1: DataCus ? DataCus.ref1 : null,
                        ref2: DataCus ? DataCus.ref2 : null,
                    })
                }else if(DataCus.bank_type == "Non Cross Bank"){
                    const fileList: UploadFile[] = 
                    DataCus && DataCus?.pic.map((v:any)=>(
                        {
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url:
                                v.url,
                            thumbUrl:
                                v.url
                        }
                    ));

                    setFile({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList],
                    })

                    if(DataCus && DataCus.pic !== null){
                        setFileList((pre:any)=> ([...DataCus.pic]));
                    }else{
                        setFileList([])
                    }
                }
            }
        }else{
            if(DataCus.localizations.length > 0){
                editProps?.form?.setFieldsValue({
                    property: DataCus.localizations[0]?.property ? DataCus.localizations[0]?.property : null,
                    expense_type: DataCus.localizations[0]?.expense_type ? DataCus.localizations[0]?.expense_type : null,
                    // bank_type: DataCus.localizations[0]?.bank_type ? DataCus.localizations[0]?.bank_type : null,
                    bank_type: keep ? keep : DataCus.localizations[0]?.bank_type ? DataCus.localizations[0]?.bank_type : null,
                    account_name: DataCus.localizations[0]?.account_name ? DataCus.localizations[0]?.account_name : null,
                    account_no: DataCus.localizations[0]?.account_no ? DataCus.localizations[0]?.account_no : null,
                })

                if(DataCus.bank_type){
                    setswBankType(DataCus.localizations[0]?.bank_type);
                    if(DataCus.localizations[0]?.bank_type == "Cross Bank"){
                        editProps?.form?.setFieldsValue({
                            ref1: DataCus.localizations[0]?.ref1 ? DataCus.localizations[0]?.ref1 : null,
                            ref2: DataCus.localizations[0]?.ref2 ? DataCus.localizations[0]?.ref2 : null,
                        })
                    }else if(DataCus.localizations[0]?.bank_type == "Non Cross Bank"){
                        const fileList: UploadFile[] = DataCus.localizations[0]?.pic?.map((v:any)=>(
                            {
                                uid: String(v.id),
                                name: v.name,
                                status: "done",
                                url:
                                    v.url,
                                thumbUrl:
                                    v.url
                            }
                        ));
    
                        setFile({
                            name: 'files',
                            multiple: true,
                            defaultFileList: [...fileList],
                        })
    
                        if(DataCus && DataCus.localizations[0]?.pic !== null){
                            setFileList((pre:any)=> ([...DataCus.localizations[0]?.pic]));
                        }else{
                            setFileList([])
                        }
                    }
                }
            }
        }

        editProps?.form?.resetFields(["pic"]);
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function updateLocaleEn(value: any){

        // console.log('value', value)
        const data:any = {
            // property : value?.property.id,
            // expense_types : value?.expense_type.id,
            // bank_type : value?.bank_type,
            account_name: value?.account_name,
            account_no: value?.account_no,
            updateBy : user_data.user_profile.id,
            locale : "en"
        }
        if(value?.bank_type == "Cross Bank") {
            data.ref1 = value.ref1
            data.ref2 = value.ref2
        }else if(value?.bank_type == "Non Cross Bank"){
            // data.pic = FileList;
        }

        UpdateLocale({
            resource : 'config-payments',
            id: DataCus.localizations[0].id,
            values: data,
            successNotification:false,
            },{
                onSuccess(data, variables, context) {
                    close();
                },
            }
        )
    }

    function updateStatus(id:any, event:any){
        console.log('id : ', id)
        console.log('event : ', event)
        updateStat({
            resource    : 'config-payments',
            id          : id+"",
            values      : {
                deleteStatus: event
            }},{
                onSuccess(data, variables, context) {
                    console.log('data', data?.data)
                },
            }
        );

        // CreateLogDelete({
        //     resource    : log_endpoint,
        //     values      : {
        //         user_profile    : user_data.user_profile.id,
        //         log_time_stamp  : Date.now(), 
        //         log_event       : event,
        //         menu_event      : 'pp_type',
        //         SysPkID         : id+""
        //     }
        // });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function fileChange(e:any){
        let res = e.file;
        if(res.status == "removed"){
            const NewFileList = FileList.filter((e:any)=> e.name !== res.name)
            setFileList((pre:any)=> ([...NewFileList]))
        }else if(res.status == "done"){
            if(e.file.response){
                let res = e.file.response;
                const ListFile = FileList.filter((e:any)=> e.id == res[0].id)
                if(ListFile.length == 0){
                    setFileList((pre:any)=> ([...pre , res[0]]));
                }else{
                    const NewFileList = FileList.filter((e:any)=> e.id !== res[0].id)
                    setFileList((pre:any)=> ([...NewFileList]))
                }
            }
        }
    }
  
    return <>
            <List 
                title={t("configpayment.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>Billing</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("configpayment.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row gutter={12}>
                    <Col span={22}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >

                            <Form.Item name="property">
                                <Select placeholder="Property" {...selProperty} allowClear style={{width: "220px"}}/>
                            </Form.Item>

                            <Form.Item name="expense_type">
                                <Select placeholder="ประเภทค่าใช้จ่าย" {...selEx} allowClear style={{width: "220px"}}/>
                            </Form.Item>

                            <Form.Item name="bank_type">
                                <Select placeholder="ประเภทธนาคาร" allowClear style={{width: "220px"}}>
                                    <Option value="Cross Bank">Cross Bank</Option>
                                    <Option value="Non Cross Bank">Non Cross Bank</Option>
                                </Select>
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button 
                                    onClick={() => {
                                        // searchFormProps.form?.submit()
                                        searchFormProps.form?.resetFields()
                                        searchFormProps.form?.submit()
                                    }} 
                                    htmlType="submit" 
                                    style={{borderRadius: "5px"}}
                                    icon={<Icons.UndoOutlined />}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                        
                    </Col>
                    <Col span={2}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                                {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={false}/> */}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState,  
                        onShowSizeChange , 
                        // total:tableProps?.dataSource?.length,
                        // total:totalData,
                        total:tableQueryResult?.data?.total,
                        // total:tableProps?.pagination.,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                    scroll={{x: 1300}}
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1} /> */}

                    <Table.Column title="PROPERTY" width={400}
                        sorter={(a:any, b:any) => {
                            if (a?.property?.property_name < b?.property?.property_name) {return -1}
                            if (a?.property?.property_name > b?.property?.property_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record ? record?.property?.property_name :"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column 
                        // dataIndex="expense_type" 
                        title="EXPENSE TYPE" 
                        width={300}
                        sorter={(a:any, b:any) => {
                            if (a?.expense_types[0]?.type_name < b?.expense_types[0]?.type_name) {return -1}
                            if (a?.expense_types[0]?.type_name > b?.expense_types[0]?.type_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //console.log('recordsort', record)
                            return <>
                                {
                                    record?.expense_types.length > 0  ?
                                        record?.expense_types
                                        .map((data:any,index:any) => {
                                            //console.log('data : ', data.type_name);
                                            return <>
                                                <Tag className="tag_post">
                                                    <TextField value={ data? data.type_name : <></> }/>
                                                </Tag>
                                            </>
                                        })
                                    :
                                        <>-</>
                                }
                            </>
                        }}
                    />

                    <Table.Column dataIndex="bank_type" 
                        title="BANK TYPE" 
                        width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.bank_type < b?.bank_type) {return -1}
                            if (a?.bank_type > b?.bank_type) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value ? value :"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column title="BANK" width={300}
                        sorter={(a:any, b:any) => {
                            if (a?.bank?.bank_code < b?.bank?.bank_code) {return -1}
                            if (a?.bank?.bank_code > b?.bank?.bank_code) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <Tag color={"#E8CE26"} style={{border: "1px solid #DAC32F"}}>{record ? record.bank.bank_code :"ไม่ระบุ"}</Tag>
                                <TextField value={record ? record?.bank.bank_name:"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="biller_id" title="BILLER ID" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.biller_id < b?.biller_id) {return -1}
                            if (a?.biller_id > b?.biller_id) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value ? value:"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.createdAt < b.createdAt) {return -1}
                        if (a.createdAt > b.createdAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField style={{fontWeight: 600}} value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField style={{fontWeight: 600}} value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.updatedAt < b.updatedAt) {return -1}
                        if (a.updatedAt > b.updatedAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField style={{fontWeight: 600}} value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField style={{fontWeight: 600}} value={'-'}  />
                                }
                            </>
                        }}
                    />
                  
                    <Table.Column<any>
                        width={80}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right" 
                        render={(_, record) => (
                            <Space>
                                {/* <EditButton
                                    hideText
                                    size="small"
                                    onClick={()=> editModalShow(record.id)}
                                    // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    disabled={false}
                                /> */}

                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                onClick={()=> editModalShow(record.id)}
                                                recordItemId={record.id}
                                                // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button 
                                                size="small" 
                                                title="Inactive" 
                                                icon={<LockOutlined />} 
                                                onClick={() => { showDelConfirm(record.id) }} 
                                                danger 
                                                // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                        </>
                                    :
                                        <>
                                            <Button 
                                                size="small" 
                                                title="Active" 
                                                icon={<UnlockOutlined />}
                                                onClick={() => { showUnlockConfirm(record.id) }} 
                                                // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...createModalProps}
                title={"Config Payment Create"}
                okText = {"Create"}
                width={450}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                    setswBankType(undefined)
                    setFileList([])
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.property = values?.property.id;
                        values.expense_types = values?.expense_types.id;
                        values.bank_type = values?.bank_type;
                        values.bank = values?.bank.id;
                        values.account_no = values?.account_no;
                        values.biller_id = values?.biller_id;

                        values.createBy = user_data.user_profile.id

                        // console.log('values', values)

                        if(values?.bank_type == "Cross Bank"){
                            // values.ref1 = values?.ref1;
                            // values.ref2 = values?.ref2;
                        }else if(values?.bank_type == "Non Cross Bank"){
                            // console.log('pic', FileList)
                            // console.log('pic', FileList[0].id)
                            // values.pic = FileList[0].id;
                        }

                        values.locale = "th";

                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty} 
                                    // onChange={}
                                    // onChange={(e) => setPropID(e)}
                                    onChange={(value) => {
                                        setPropID(value);
                                    }}

                                />
                            </Form.Item>

                            

                            <Form.Item
                                name="bank_type"
                                label="Bank Type"
                                rules={[
                                    {
                                        required: true, message: "Please select bank type"
                                    },
                                ]}
                            >
                                {/* <Radio.Group onChange={(e: any) => setswBankType(e.target.value)}> */}
                                <Radio.Group onChange={(e: any) => ( setswBankType(e.target.value), checkUsedExpense(e.target.value))}>
                                    <Space  direction="horizontal">
                                        <Radio value="Cross Bank">Cross Bank</Radio>
                                        <Radio value="Non Cross Bank">Non Cross Bank</Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item
                                name={["expense_types", "id"]}
                                label="Expense Type"
                                rules={[
                                    {
                                        required: true, message: "Please select expense type"
                                    },
                                ]}
                            >
                                {/* {console.log("selEx",selEx)} */}
                                <Select 
                                    // mode="multiple" 
                                    placeholder={t("select.sel")} 
                                    {...selEx}
                                />
                            </Form.Item>

                            {swBankType == "Cross Bank" && 
                            <>
                                <Form.Item  label="Biller ID" name="biller_id"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter biller ID.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                                <Row gutter={8}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label="ชื่อบัญชี" name="account_name"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter account name.'
                                                },
                                            ]}>
                                                <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label="เลขบัญชี" name="account_no"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter bank name.'
                                                },
                                            ]}>
                                                <Input />
                                        </Form.Item>
                                    </Col>

                                    {/* <Col className="gutter-row" span={24}>
                                        <Form.Item  label="Ref 1" name="ref1"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter ref 1.'
                                                },
                                            ]}>
                                                <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={24}>
                                        <Form.Item  label="Ref 2" name="ref2"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter ref 2.'
                                                },
                                            ]}>
                                                <Input />
                                        </Form.Item>
                                    </Col> */}
                                </Row>
                            </>
                            }

                            {swBankType == "Non Cross Bank" && 
                            <>
                                <Row gutter={8}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label="ชื่อบัญชี" name="account_name"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter account name.'
                                                },
                                            ]}>
                                                <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label="เลขบัญชี" name="account_no"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter bank name.'
                                                },
                                            ]}>
                                                <Input />
                                        </Form.Item>
                                    </Col>

                                    {/* <Col className="gutter-row" span={24}>
                                    <Form.Item
                                        name="pic"
                                        label="QR Code"
                                        valuePropName="fileList"
                                        getValueProps={(data) => getValueProps(data, API_URL)}
                                        rules={[
                                            {
                                                required: true, message: 'Please select image.'
                                            },
                                        ]}
                                    >
                                        <Upload.Dragger
                                            style={{borderRadius: 10}}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}
                                            listType="picture"
                                            maxCount={1}
                                            multiple={false}
                                            onChange={fileChange}
                                            beforeUpload={beforeUpload}
                                        >
                                            <p className="ant-upload-text">{t("upload")}</p>
                                        </Upload.Dragger>
                                    </Form.Item>
                                    </Col> */}
                                </Row>
                            </>
                            }

                            

                            <Form.Item
                                name={["bank", "id"]}
                                label="Bank"
                                rules={[
                                    {
                                        required: true, message: "Please select bank"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selBank}/>
                            </Form.Item>                            
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Config Payment Edit"}
                okText = {"Save"}
                width={450}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                    setswLocal(true);
                    setswBankType(undefined) 
                    setKeep(null)
                    editProps?.form?.resetFields(["pic"]);
                    setDataCus(undefined)
                    setcountl(0)
                    setFileList([])
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                   
                    onFinish={(values:any) => {   
                        // console.log('values', values)
                        // console.log('onFin', vExpenseType[0])
                        if(swLocal == true){
                            values.property = values?.property.id;
                            // values.expense_types = values?.expense_types.id;
                            values.expense_types = vExpenseType[0];
                            values.bank_type = values?.bank_type;
                            values.bank = values?.bank.id;
                            values.account_no = values?.account_no;
                            values.updateBy = user_data.user_profile.id

                            if(values?.bank_type == "Cross Bank"){
                                // values.ref1 = values?.ref1;
                                // values.ref2 = values?.ref2;
                            }else if(values?.bank_type == "Non Cross Bank"){
                                // values.pic = FileList;
                            }

                            values.updateBy = user_data.user_profile.id;
                            values.locale = "th";

                            return (
                                editProps.onFinish &&
                                editProps.onFinish(values)
                            );
                            
                        }else if(swLocal == false){
                            updateLocaleEn(values);
                        }
                    }}
                >
                    <Row gutter={16}>
                        <Radio.Group 
                            options={optionsRdioLocale} 
                            onChange={(e)=> {
                                swLocal = e.target.value
                                setswLocal(e.target.value)
                                loadLocal();
                            }}
                            defaultValue={true}
                            optionType="button"
                            style={{marginBottom: "2rem"}}
                        />

                        <Col span={24} className="gutter-row">
                            <Row gutter={16}>
                                <Col span={24} className="gutter-row">
                                    <Form.Item
                                        name={["property", "id"]}
                                        label="Property"
                                        rules={[
                                            {
                                                // required: true, message: "Please select property"
                                                required: swLocal == true ? true : false, message: "Please select property"
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")} {...selProperty} disabled={vswLocal == false ? true : false}/>
                                    </Form.Item>

                                    

                                    <Form.Item
                                        name="bank_type"
                                        label="Bank Type"
                                        rules={[
                                            {
                                                // required: true, message: "Please select bank type"
                                                required: swLocal == true ? true : false, message: "Please select bank type"
                                            },
                                        ]}
                                    >
                                        <Radio.Group onChange={(e: any) => ( setswBankType(e.target.value), checkUsedExpense(e.target.value), setKeep(e.target.value))} disabled={vswLocal == false ? true : false}>
                                            <Space direction="horizontal">
                                                <Radio value="Cross Bank">Cross Bank</Radio>
                                                <Radio value="Non Cross Bank">Non Cross Bank</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item
                                        name={["expense_types", "id"]}
                                        label="Expense Type"
                                        rules={[
                                            {
                                                // required: true, message: "Please select expense type"
                                                required: swLocal == true ? !vExpenseType[0] ? true : false : false, message: "Please select expense type"
                                            },
                                        ]}
                                    >
                                        <Select 
                                            
                                            // mode={"multiple"} 
                                            placeholder={t("select.sel")} 
                                            {...selEx}
                                            value={vExpenseType[0]}
                                            defaultValue={vExpenseType[0]}
                                            onChange={(value) => {
                                                onChangeExpense(value);
                                            }}
                                            disabled={vswLocal == false ? true : false}
                                        />
                                    </Form.Item>
                                    
                                    

                                    {keep == "Cross Bank" && 
                                    <>

                                        <Form.Item  label="Biller ID" name="biller_id"
                                            rules={[
                                                {
                                                    required: swLocal == true ? true : false, message: 'Please enter biller ID.'
                                                },
                                            ]}>
                                                <Input disabled={vswLocal == false ? true : false}/>
                                        </Form.Item>

                                        <Row gutter={8}>
                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label="ชื่อบัญชี" name="account_name"
                                                    rules={[
                                                        {
                                                            // required: true, message: 'Please enter account name.'
                                                            required: swLocal == true ? true : false, message: 'Please enter account name.'
                                                        },
                                                    ]}>
                                                        <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label="เลขบัญชี" name="account_no"
                                                    rules={[
                                                        {
                                                            // required: true, message: 'Please enter bank name.'
                                                            required: swLocal == true ? true : false, message: 'Please enter account no.'
                                                        },
                                                    ]}>
                                                        <Input disabled={vswLocal == false ? true : false}/>
                                                </Form.Item>
                                            </Col>

                                            {/* <Col className="gutter-row" span={24}>
                                                <Form.Item  label="Ref 1" name="ref1"
                                                    rules={[
                                                        {
                                                            // required: true, message: 'Please enter ref 1.'
                                                            required: swLocal == true ? true : false, message: 'Please enter ref 1.'
                                                        },
                                                    ]}>
                                                        <Input disabled={vswLocal == false ? true : false}/>
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={24}>
                                                <Form.Item  label="Ref 2" name="ref2"
                                                    rules={[
                                                        {
                                                            // required: true, message: 'Please enter ref 2.'
                                                            required: swLocal == true ? true : false, message: 'Please enter ref 2.'
                                                        },
                                                    ]}>
                                                        <Input disabled={vswLocal == false ? true : false}/>
                                                </Form.Item>
                                            </Col> */}
                                        </Row>
                                    </>
                                    }

                                    {keep == "Non Cross Bank" && 
                                    <>
                                        <Row gutter={8}>
                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label="ชื่อบัญชี" name="account_name"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please enter account name.'
                                                        },
                                                    ]}>
                                                        <Input disabled={vswLocal == false ? true : false}/>
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label="เลขบัญชี" name="account_no"
                                                    rules={[
                                                        {
                                                            // required: true, message: 'Please enter bank name.'
                                                            required: swLocal == true ? true : false, message: 'Please enter account no.'
                                                        },
                                                    ]}>
                                                        <Input disabled={vswLocal == false ? true : false}/>
                                                </Form.Item>
                                            </Col>

                                        </Row>
                                    </>
                                    }

                                    
                                    <Form.Item
                                        name={["bank", "id"]}
                                        label="Bank"
                                        rules={[
                                            {
                                                // required: true, message: "Please select bank"
                                                required: swLocal == true ? true : false, message: "Please select bank"
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")} {...selBank} disabled={vswLocal == false ? true : false}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}