/* eslint-disable */
import { Avatar, Breadcrumb, Card, Col, List, Row, Show, Space, TextField, Typography } from "@pankod/refine-antd";

import { IUnit } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";

import { PhoneOutlined } from "@ant-design/icons";

import Moment from 'moment';

// import Moment from 'moment';

export const UnitShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IUnit>({  
        metaData: {
        populate: '*'
    }, 
});

    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { Text } = Typography;

    // console.log("mmmm",queryResult)

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>

            <List 
                title={t("")}
                pageHeaderProps={{ 
                    extra : <></>
                }}
            />

                <Space style={{marginBottom: "3%"}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("unit.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("unit-show.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={13}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset",marginBottom: "2%"}}>
                                    <Row>
                                        <Col span={5}>
                                                {
                                                    record?.pix !== undefined ?
                                                    <>
                                                        <Avatar 
                                                            style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",borderRadius: "10px"}}
                                                            src={record?.pix === null ? "https://joeschmoe.io/api/v1/random" :record?.pix.url} 
                                                        />
                                                    </>:<>-</>
                                                }
                                        </Col>
                                            
                                        <Col className="gutter-row" span={19}>
                                            <div style={{marginLeft: "5%"}}>
                                                <TextField  style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#fff"}} value={record?.unit_name ? record?.unit_name : '-'} />
                                                <Text style={{color: "#fff",fontSize: "2vw",fontWeight: "600",marginLeft: "2%"}}>(</Text>
                                                <TextField style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#fff"}} value={record?.unit_code ? record?.unit_code : '-'} />
                                                <Text style={{color: "#fff",fontSize: "2vw",fontWeight: "600"}}>)</Text>
                                            </div>

                                            <div style={{marginLeft: "5%", marginBottom: "0", marginTop: "2%", borderRadius: "10px"}}>
                                                <span className="id-userprofile"><Text style={{borderRadius: "50px 0px 0px 50px",paddingLeft: "3%",boxShadow: "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 0px 10px -10px"}}>ID</Text>
                                                    <TextField style={{borderRadius: "0px 50px 50px 0px", paddingLeft: "2%", paddingRight: "3%", boxShadow: "rgb(50 50 93 / 25%) -2px 6px 10px -2px, rgb(0 0 0 / 30%) 0px 0px 20px -10px"}} value={record?.id ? record?.id : '-'} />
                                                </span>

                                                <span 
                                                    style={{
                                                        fontSize: "0.8vw",
                                                        marginTop: "10%",

                                                        marginLeft: "2%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "rgb(172, 172, 172)",
                                                    }} >
                                                        <Text style={{color: "#fff"}}>{t("unit.id")} </Text>
                                                        <TextField style={{color: "#fff"}} value={record?.unit_id ? record?.unit_id : '-'} />
                                                </span>

                                                {/* <span 
                                                    style={{
                                                        fontSize: "0.8vw",
                                                        marginTop: "10%",

                                                        marginLeft: "2%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "rgb(172, 172, 172)",
                                                    }} >
                                                        <Text style={{color: "#fff"}}>{t("project.title")} </Text>
                                                        <TextField style={{color: "#fff"}} value={record?.project ? record?.project.project_name : '-'} />
                                                        <Text style={{color: "#fff"}}> ( <TextField style={{color: "#fff"}} value={record?.project ? record?.project.project_code : '-'} /> ) </Text>
                                                </span> */}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={12} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                            <span className="title_panel" style={{fontSize: "0.7vw"}}>{t("unit-type.title")}</span><TextField style={{padding: "2%",marginLeft: "3%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.unit_type ? record?.unit_type.name : 'NO DATA'} />
                                        </Col>

                                        <Col className="gutter-row" span={12}>
                                            <span className="title_panel" style={{fontSize: "0.7vw"}}>{t("unit-type.limit")}</span><TextField style={{padding: "2%",marginLeft: "3%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.unit_type ? record?.unit_type.vehicle_limit : 'NO DATA'} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                                    <span style={{fontSize: "1vw",fontWeight: "600"}}>{t("property.title")} : <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.property ? record?.property.property_name : <></>} /></span>

                                    <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw"}}>{t("phonebook.address")}</span></div>

                                    <TextField value={record?.property ? record?.property.address : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.city")} </Text>
                                    <TextField value={record?.property ? record?.property.city : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.province")} </Text>
                                    <TextField value={record?.property ? record?.property.province : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.post_code")} </Text>
                                    <TextField value={record?.property ? record?.property.postcode : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.fax")} </Text>
                                    <TextField value={record?.property ? record?.property.fax : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}>{t("properties.about")}</div><TextField style={{fontSize: "0.7vw"}} value={record?.property ? record?.property.about : <></>} />

                                    <div style={{marginTop: "3%"}}><span style={{background: "#40495F",borderRadius: "20px",padding: "1% 3% 1%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={record?.property ? record?.property.tel : '-'} /></span></div>     
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    
                    <Col className="gutter-row" span={5}>

                    </Col>

                    <Col className="gutter-row" span={6}>
                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>
                                                        
                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createdBy? record?.createdBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{fontSize: "0.7vw"}}>{record?.updatedBy? record?.updatedBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
        </Show>
    );
};