import { IResourceComponentsProps, useTranslate } from "@pankod/refine-core"
import { Col, Row, List, Breadcrumb, Tabs } from "@pankod/refine-antd";
import { useState } from "react";
import { PetDataInAppList } from "./inapp";
import { PetDataGuestList } from "./guest";

export const PetDataList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { TabPane } = Tabs;
    const [tabm, settabm] = useState<any>("inapp");
    const tabActive = localStorage.getItem("keyTabPetData");

    function onChangeTabTerm(v:any){
        settabm(v)
        localStorage.setItem("keyTabPetData", v)
    }

    return (
        <>
        <List 
            title={t("pet-vacc-list.pet-regis")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("pet-service.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-vacc-list.pet-regis")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row gutter={6}>
                <Col className="gutter-row" span={24}>
                    <Tabs 
                        // defaultActiveKey={"inapp"} 
                        defaultActiveKey={tabActive ? tabActive + "" : "inapp"}
                        // onChange={(e)=> settabm(e)}
                        onChange={(e)=> onChangeTabTerm(e)}
                    >
                        <TabPane
                            key={"inapp"}
                            tab={<span>{t("pet-vacc-list.inapp")}</span>}
                        ><PetDataInAppList /></TabPane>
                        {/* <TabPane
                            key={"guest"}
                            tab={<span>{t("pet-data-list.guest")}</span>}
                        >
                            <PetDataGuestList />
                            Coming soon
                        </TabPane> */}
                    </Tabs>
                </Col>
            </Row>
            {
                // tabm == "inapp" ? <PetDataInAppList /> : <PetDataGuestList />
            }
        </List>
        </>
    )
}