/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, notification, Space, Edit, InputNumber } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";

import axios from "axios";
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import TextArea from "@uiw/react-md-editor/lib/components/TextArea";

export const RepairDetailEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [form] = Form.useForm();

    // const userQuery = useGetIdentity(); // Get User Profile

    // console.log('Identity_data', Identity_data?.user_profile)

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]

    console.log('item_id', item_id)

    const user_data:any = {
        id: Identity_data ? Identity_data?.user_profile.id : null,
        first_name: Identity_data ? Identity_data?.user_profile.first_name : null,
        last_name: Identity_data ? Identity_data?.user_profile.last_name : null,
        tel: Identity_data ? Identity_data?.user_profile.tel : null,
        email: Identity_data ? Identity_data?.user_profile.email : null,
        avatar: Identity_data ? Identity_data?.user_profile.avatar : null,
    }

    // console.log('user_data', user_data)

    const t = useTranslate();
    const { replace, list} = useNavigation();

    const [ZoneTh, SetZoneTh] = useState<any>("");
    const [ZoneEn, SetZoneEn] = useState<any>("");
    const [ZoneDesc, SetZoneDesc] = useState<any>("");
    const [ZoneStat, SetZoneStat] = useState<any>(true);
    // const [ZoneStat, SetZoneStat] = useState<any>(true);
    const [PropertyType, SetPropertyType] = useState<any>();
    const [DataProperty, SetDataProperty] = useState<any>();

    const [Waranty, SetWaranty] = useState<any>(0);
    const [Sla, SetSla] = useState<any>(0);
    const [FixingTime, SetFixingTime] = useState<any>(0);
    const [CategoryData, SetCategoryData] = useState<any>();
    const [CategorySelected, setCategorySelected] = useState<any>();


    async function createData(values:any) {

        let dataProp: any = [];
        for (let index = 0; index < PropertyType?.length; index++) {
            let findProp = DataProperty?.find((item:any) => item?._id == PropertyType[index])
            if(findProp){
                dataProp?.push(findProp)
            }
        }

        let data = {
            "category_name": CategorySelected ? CategorySelected?.value : null,
            "category_detail_name": ZoneTh,
            "category_detail_name_en": ZoneEn,
            // "item_desc": ZoneDesc,
            "warantee": Waranty ? Waranty : 0,
            "property_type": dataProp,
            "sla": Sla,
            "repair_time": FixingTime,
            "is_default" : true,
            "is_active" : ZoneStat,
            "update_by": user_data ? user_data : null
        }

        console.log('data onFin', data)

        // let res = await axios.patch(majorCareUrl + "/majorcare/repairCategoryDetail", data , { headers: { } })
        let res = await axios.patch(majorCareUrl + "/majorcare/repairCategoryDetail/" + item_id, data , { headers: { } })
        .then(response => {
            // Handle successful response
            console.log(response.data);
            successNoti();
            replace("/repair/repair-master")
        })
        .catch(error => {
            // Handle error
            console.error('error ',error);
            errorNoti('200', 'success');
        });
    }

    const getCategory:any = async ()=>{

        let res = await axios.get(majorCareUrl + "/majorcare/repairCategory" , { headers: { } }).then(response => {
            // Handle successful response
            // console.log(response.data);
            let dataLoad:any
            dataLoad = response.data
            // console.log('getCategory', dataLoad)
            let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            SetCategoryData(dataLoad ? filteredDataCate : null)
        })
        // console.log('res cate', res)
    }

    // const successNoti = (stat:any, msg:any) => {
    //     notification.success({
    //         // message: stat + ' : ' + msg.TH,
    //         message: msg,
    //         // description: msg,
    //         duration: 3,
    //     });
    // };

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    const errorNoti = (stat:any, msg:any) => {
        notification.warning({
            message: "This name already exists in the system.",
            duration: 3,
        });
    };

   const getDataK = async ()=>{

        // ======================== FIND PROPERTY TYPE ========================================
        // https://api-dev-majorcare.techscape.app/api/majorcare/findAllPropertyType
        let res = await axios.get(majorCareUrl + "/majorcare/findAllPropertyType" , { headers: { } });
        console.log('res', res)
        let Data = res?.data

        SetDataProperty(Data ? Data : null)

        // ======================== FIND DATA ZONE ========================================
        // https://api-dev-majorcare.techscape.app/api/majorcare/repairZone/649577113b5aa8c99502b24a
        let resDataCatDetail = await axios.get(majorCareUrl + "/majorcare/repairCategoryDetail/findOne/" + item_id , { headers: { } });
        console.log('resDataZone', resDataCatDetail?.data)

        // console.log('resDataZone?.data.property_type', resDataCatDetail?.data.property_type)

        let newData: any = [];

        if(resDataCatDetail?.data?.property_type){
            for (let index = 0; index < resDataCatDetail?.data?.property_type.length; index++) {
                newData?.push(resDataCatDetail?.data?.property_type[index]?._id)
            }
        }

        SetPropertyType((pre:any)=> newData)

        SetWaranty(resDataCatDetail?.data.warantee)
        SetSla(resDataCatDetail?.data.sla)
        SetFixingTime(resDataCatDetail?.data.repair_time)
        SetZoneTh(resDataCatDetail?.data.category_detail_name);
        SetZoneEn(resDataCatDetail?.data.category_detail_name_en);

        setCategorySelected(resDataCatDetail?.data.category_name);

        form.setFieldsValue({
            item_name: resDataCatDetail?.data.category_detail_name,
            item_name_en: resDataCatDetail?.data.category_detail_name_en,
            status: resDataCatDetail?.data.is_active,
            repair_time: resDataCatDetail?.data.repair_time,
            sla: resDataCatDetail?.data.sla,
            waranty: resDataCatDetail?.data.warantee,
            category: resDataCatDetail?.data.category_name
        })
    }

    // console.log('CategorySelected', CategorySelected)
    // console.log('DataProperty', DataProperty)

    useEffect(() => {
        getDataK();
        getCategory();
    }, []);

    const AddPropTypeToArr = (checkedValues: CheckboxValueType[]) => {
        let newData: any = [];
        for (let index = 0; index < checkedValues.length; index++) {
            newData?.push(checkedValues[index])
        }

        SetPropertyType((pre:any)=> newData)
    }

    // useEffect(() => {
    //   console.log(">>> PropertyType",PropertyType)
    // }, [PropertyType])
    
    // console.log('Waranty', Waranty)

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-zone.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Category Detail Master Create</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    createData(values);
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>

                        

                        <Col span={18} 
                            className="gutter-row" 
                            style={{
                                // borderRight: "1px solid #dedede",
                                paddingTop: "2.5rem"
                            }}
                        >
                            <Row>
                                <Col span={8}>
                                    <Form.Item
                                        name="category"
                                        label="หมวดการซ่อม"
                                        rules={[
                                            {
                                                required: true, message: "Please select category"
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")}
                                            value={CategorySelected ? CategorySelected : null}
                                            defaultValue={CategorySelected ? CategorySelected : null}
                                            options={
                                                CategoryData && 
                                                CategoryData?.map((value:any) => ( 
                                                    { 
                                                        label: value?.category_name, 
                                                        value: value?._id,
                                                        data: value
                                                    }))
                                            }
                                            onSelect={(value: any, record:any) => {
                                                setCategorySelected(record);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                name="item_name"
                                label="รายละเอียด (TH)"
                                rules={[
                                    {
                                        required: true, message: "Please enter category detail name"
                                    },
                                ]}
                            >
                                <Input placeholder="category detail name th" onChange={(e) => SetZoneTh(e.target.value)} />
                            </Form.Item>

                            <Form.Item
                                name="item_name_en"
                                label="รายละเอียด (EN)"
                                rules={[
                                    {
                                        required: true, message: "Please enter category detail name (en)"
                                    },
                                ]}
                            >
                                <Input placeholder="category detail name en" onChange={(e) => SetZoneEn(e.target.value)} />
                            </Form.Item>

                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        // name="property_type"
                                        label="Property Type"
                                        rules={[
                                            {
                                                required: true, message: "Please select property type"
                                            },
                                        ]}
                                    >
                                        <Checkbox.Group 
                                            style={{ width: '100%', marginTop:'10px' }}
                                            // onChange={AddPropTypeToArr()}
                                            onChange={AddPropTypeToArr}
                                            value={PropertyType}
                                        >
                                            {DataProperty?.map((item:any) => (
                                                <Col span={24} style={{ marginBottom: "2%" }}>
                                                <Checkbox 
                                                    key={item.property_type_id}
                                                    value={item._id}
                                                >
                                                    {item.property_type_name}
                                                </Checkbox>
                                                </Col>
                                            ))}
                                        </Checkbox.Group>

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={3}>
                                    <div style={{marginRight:'2px'}}>ระยะเวลารับประกัน: </div>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="waranty"
                                        rules={[
                                            {
                                                required: true, message: "Please enter waranty"
                                            },
                                        ]}
                                    >
                                        {/* <Input  value={Waranty}  style={{width:'80%'}} min={0} type="number" onChange={(e) => SetWaranty(e.target.value)}></Input> วัน */}
                                        <InputNumber 
                                            onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                                            // stringMode={false} 
                                            value={Waranty} 
                                            defaultValue={Waranty}
                                            style={{width:'80%'}} 
                                            min={0} type="number" 
                                            onChange={(e:any) => {
                                                    // console.log('e', e)
                                                    SetWaranty(e !== null ? e : undefined)
                                                    form.setFieldsValue({waranty : e}) 

                                                    if(e == null){
                                                        // console.log('resetField')
                                                        form?.resetFields(["waranty"]);
                                                    }
                                                }
                                            }
                                        ></InputNumber> วัน
                                    </Form.Item>
                               </Col>

                                <Col span={1}></Col>

                                <Col span={1}>
                                    <div style={{marginRight:'2px'}}>SLA: </div>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="sla"
                                        rules={[
                                            {
                                                required: true, message: "Please enter SLA"
                                            },
                                        ]}
                                    >
                                        {/* <Input  value={Sla} style={{width:'80%'}} min={0} type="number" onChange={(e) => SetSla(e.target.value)}></Input> วัน */}
                                        <InputNumber  
                                            onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                                            value={Sla} 
                                            style={{width:'80%'}} 
                                            min={0} type="number" 
                                            // onChange={(e:any) => SetSla(e)}
                                            // onChange={(e:any) => ( SetSla(e), form.setFieldsValue({sla : Sla}) )}

                                            onChange={(e:any) => {
                                                // console.log('e', e)
                                                SetSla(e !== null ? e : undefined)
                                                form.setFieldsValue({sla : e}) 

                                                if(e == null){
                                                    // console.log('resetField')
                                                    form?.resetFields(["sla"]);
                                                }
                                            }
                                        }
                                        >
                                        </InputNumber> วัน
                                    </Form.Item>
                                </Col>

                                <Col span={1}></Col>

                                <Col span={3}>
                                    <div style={{marginRight:'2px'}}>ระยะเวลาการซ่อม: </div>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        name="repair_time"
                                        rules={[
                                            {
                                                required: true, message: "Please enter repair time"
                                            },
                                        ]}
                                    >
                                        {/* <Input value={FixingTime} style={{width:'80%'}} min={0} type="number" onChange={(e) => SetFixingTime(e.target.value)}></Input> วัน */}
                                        <InputNumber 
                                            onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}
                                            value={FixingTime} 
                                            style={{width:'80%'}}
                                            min={0} 
                                            type="number" 
                                            // onChange={(e:any) => SetFixingTime(e)}
                                            // onChange={(e:any) => ( SetFixingTime(e), form.setFieldsValue({repair_time : FixingTime}) )}
                                            onChange={(e:any) => {
                                                // console.log('e', e)
                                                SetFixingTime(e !== null ? e : undefined)
                                                form.setFieldsValue({repair_time : e}) 

                                                if(e == null){
                                                    // console.log('resetField')
                                                    form?.resetFields(["repair_time"]);
                                                }
                                            }}
                                        ></InputNumber> วัน
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        name="status"
                                        label="สถานะ"
                                        rules={[
                                            {
                                                required: true, message: "Please select"
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            onChange={(e:any) => SetZoneStat(e.target.value)}
                                            // defaultValue={recordData.coupon_condition ? 1 : 2}
                                            // value={vRaCalRight}
                                        >
                                            <Radio value={true} style={{ marginBottom: "0%" }}> ใช้งาน </Radio>
                                            <Radio value={false} style={{ marginBottom: "0%" }}> ไม่ใช้งาน </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                           
                        </Col>
                    </Row>

                  

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Edit>   
    );
};