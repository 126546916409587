/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { RepairFacilityEmailGroupList } from "./email_group";
import { RepairFacilityTermConditionList } from "./term_and_condition";


export const RepairSetupFacility: React.FC<IResourceComponentsProps> = () => {
    const tabActive = localStorage.getItem("keyTabRepairSetup");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    function onStatusChange(v:any){
        localStorage.setItem("keyTabRepairSetup", v)
    }

    return <>
        <List 
            // title={t("hcumaster.title")}
            title="Setup แจ้งซ่อมส่วนกลาง"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>แจ้งซ่อมส่วนกลาง</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">Setup แจ้งซ่อมส่วนกลาง</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></> 
            }}
        >
            <Tabs
                defaultActiveKey={tabActive? tabActive + "" : "1"}
                onChange={onStatusChange}
            >
                <Tabs.TabPane tab="Term &amp; Conditions" key="1">
                    <RepairFacilityTermConditionList />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Email Group" key="3">
                    <RepairFacilityEmailGroupList />
                </Tabs.TabPane>
            </Tabs>
        </List>

        
    </>
}