/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, message, RcFile, Row, Select, Upload, useForm , useSelect} from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate  } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IEventlog, IPPType, IProject, IProjectBrand } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";

export const ProjectCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps } = useForm<IProject,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'projects',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false,
                });
            },
        }
    );
    const API_URL = useApiUrl();
    const { ...uploadProps_coverpix} = useStrapiUpload({ maxCount: 1, });
//     const { queryResult } = useShow<IProject>({  
//         metaData: {
//         populate: '*'
//     }, 
// });
    // const { data } = queryResult;
    // const record = data?.data;

    const { selectProps: Selpptype } = useSelect<IPPType>({
        resource: "pp-types",
        optionLabel: "pp_name",
        optionValue: "id",
        // fetchSize: 10000,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const { selectProps: selPJBRAND } = useSelect<IProjectBrand>({
        resource: "project-brands",
        optionLabel: "brand_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'active_status',
                operator    : 'eq',
                value       : true
            },
        ] 
    });

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
            
        }
    }
    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
      };

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>

        <List 
            title={t("projects.title")}
            pageHeaderProps={{
            breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("project.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("projects-create.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
            extra : <></>
                            }}
            />
        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.createBy = user_data.user_profile.id
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
    >
       <Row gutter={[16, 24]}>
            <Col id="Col_w" className="gutter-row" span={24}>
                <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={12}>
                            <Row gutter={16}>
                                <Col id="Col_w" className="gutter-row" span={24}>   

                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item  label={t("pp-type.title")} name="pp_type">
                                                <Select placeholder={t("select.sel")}
                                                    {...Selpptype}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item  label={t("project-brand.title")} name="project_brand">
                                                <Select placeholder={t("select.sel")}
                                                    {...selPJBRAND}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>       
                                </Col>

                                {/* <Col className="gutter-row" span={4}>
                                    <Form.Item  label={t("projects.id")} name="project_id"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project id.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col> */}

                                <Col className="gutter-row" span={8}>
                                    <Form.Item  label={t("projects.code")} name="project_code"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project code.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={16}>
                                    <Form.Item  label={t("projects.name")} name="project_name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project name.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="gutter-row" span={12}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}></Col>
                                <Col className="gutter-row" span={12} style={{borderLeft: "1px solid #ddd"}}>
                                    <Form.Item
                                        name="pix"
                                        label={t("projects.pic")}
                                        valuePropName="fileList"
                                        getValueProps={(data) => getValueProps(data, API_URL)}
                                    >
                                        <Upload.Dragger
                                            style={{borderRadius: 10}}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}
                                            listType="picture"
                                            multiple
                                            {...uploadProps_coverpix}
                                            onChange={fileChange}
                                            beforeUpload={beforeUpload}
                                        >
                                            <p className="ant-upload-text">{t("upload")}</p>
                                        </Upload.Dragger>
                                        {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                                    </Form.Item>
                                    <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*PNG, JPG Maximum size 5MB</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Card>
            </Col>

        </Row>
    </Form>
</Create>
    );
};
