import { Breadcrumb, Button, Col, Icons, List, Row, Tabs } from "@pankod/refine-antd"
import { IResourceComponentsProps, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import type { TabsProps } from 'antd';
import { useEffect, useState } from "react";
import { PettypeList } from "./pet-types";
import { PetvcList } from "./vaccine";
import { PetspeciesList } from "./pet-species";
import { PetSizeList } from "./pet-size";

export const PetmasterList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TabPane } = Tabs;
    const { create, edit } = useNavigation();
    const [ Tab, SetTab] = useState<any>();

    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility");

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const tabJobActive = localStorage.getItem("keyTabPetMaster");
    const [tabm, settabm] = useState<any>("type");

    useEffect(() => {
        console.log('tabJobActive', tabJobActive)
        tabJobActive && settabm(tabJobActive)
    }, [tabJobActive])
    

    function onChangeTabTerm(v:any){
        settabm(v)
        localStorage.setItem("keyTabPetMaster", v)
    }

    return (
        <List 
            title={t("pet-master.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("pet-service.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-master.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            {/* <Row gutter={6}>
                <Col className="gutter-row" span={22}>
                    <Tabs 
                        // defaultActiveKey={"type"}
                        defaultActiveKey={tabJobActive ? tabJobActive + "" : "type"}

                        // onChange={(e)=> settabm(e)}
                        onChange={(e)=> onChangeTabTerm(e)}
                    >
                        <TabPane
                            key={"type"}
                            tab={<span>{t("pet-type.title")}</span>}
                        /> 
                        <TabPane
                            key={"specie"}
                            tab={<span>{t("pet-species.title")}</span>}
                        />  
                        <TabPane
                            key={"vaccine"}
                            tab={<span>{t("pet-vaccine.title")}</span>}
                        /> 
                    </Tabs>
                </Col>

                <Col className="gutter-row" span={2} style={{textAlign: "right"}}>
                    <Button 
                        type="primary" 
                        icon={<Icons.PlusOutlined />} 
                        style={{borderRadius: "5px"}}
                        onClick={()=>
                            tabm == "type" ? create("pet-types") : 
                            tabm == "specie" ? create("pet-species") : 
                            tabm == "vaccine" && create("pet-vaccine")
                        } 
                    >
                        {t("buttons.create")}
                    </Button>
                </Col>
            </Row> */}

            <Row style={{float:'right'}}>
                <Col span={24}>
                    <Button 
                        type="primary" 
                        icon={<Icons.PlusOutlined />} 
                        style={{borderRadius: "5px"}}
                        onClick={()=>
                            tabm == "type" ? create("pet-types") : 
                            tabm == "specie" ? create("pet-species") : 
                            tabm == "size" ? create("pet-size") : 
                            tabm == "vaccine" && create("pet-vaccine")
                        }
                        disabled={tabm == "size" ? true : false}
                    >
                        {t("buttons.create")}
                    </Button>
                </Col>
            </Row>
            
            <Tabs 
                style={{width:'100%'}}
                defaultActiveKey={tabJobActive ? tabJobActive + "" : "type"}
                onChange={(e)=> onChangeTabTerm(e)}
            >
                <TabPane
                    key={"type"}
                    tab={<span>{t("pet-type.title")}</span>}
                ><PettypeList /></TabPane> 
                <TabPane
                    key={"specie"}
                    tab={<span>{t("pet-species.title")}</span>}
                ><PetspeciesList /> </TabPane>  
                <TabPane
                    key={"vaccine"}
                    tab={<span>{t("pet-vaccine.title")}</span>}
                ><PetvcList /></TabPane> 
                <TabPane
                    key={"size"}
                    tab={<span>{t("pet-size.title")}</span>}
                ><PetSizeList /></TabPane> 
            </Tabs>

            {/* {
                tabJobActive &&
                tabJobActive == "type" ? <PettypeList />:
                tabJobActive == "specie" ? <PetspeciesList /> :
                tabJobActive == "vaccine" && <PetvcList />
            } */}
        </List>
    )
}