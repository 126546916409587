import { Show, TextField, Typography, List, Space, Breadcrumb, Row, Col, Card, Avatar, Divider, Image, Tooltip, Button, Icons } from "@pankod/refine-antd";

import { IContentPostPromotion } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
import ReactHtmlParser from 'react-html-parser';

import Moment from 'moment';

export const ContentPostPromotionShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IContentPostPromotion>({  
        metaData: {
            populate: '*'
    }, 
});
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const { Text } = Typography;

    let recordData:any = []
    recordData = data?.data? data?.data : [];

    // console.log(">>> recordData",recordData)

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>

        <List 
            title={t("")}
            pageHeaderProps={{ 
                extra : <></>
            }}
        />
            <Space style={{marginBottom: "3%"}}>
                <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("content-post-promotion.title-show")}</span></Breadcrumb.Item>
                </Breadcrumb>
            </Space>
 
                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={24}>
                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",border: "unset",marginBottom: "1%"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={18} style={{borderRight: "1px solid #ddd"}}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={4} style={{borderRight: "1px solid #ddd"}}>
                                            {
                                                // recordData?.pic !== undefined || recordData?.pic !== null ?
                                                recordData?.pic !== null ?
                                                    recordData?.pic !== undefined ?
                                                        recordData.pic.length > 0 ?
                                                            recordData.pic.map((data:any,index:any) => {
                                                                return <>
                                                                    <Image 
                                                                        style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                                                        marginLeft: "auto", marginRight: "auto",
                                                                        background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",
                                                                        borderRadius: "10px"}}
                                                                        src={data.url} 
                                                                    />
                                                                </>
                                                            }):<></>
                                                    :
                                                        <Avatar 
                                                            style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                                            marginLeft: "auto", marginRight: "auto",
                                                            background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",
                                                            borderRadius: "10px"}}
                                                            src={"https://joeschmoe.io/api/v1/random"} 
                                                        />
                                                :
                                                <Avatar 
                                                    style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                                    marginLeft: "auto", marginRight: "auto",
                                                    background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",
                                                    borderRadius: "10px"}}
                                                    src={"https://joeschmoe.io/api/v1/random"} 
                                                />
                                            }
                                        </Col>

                                        <Col className="gutter-row" span={20}>
                                            <TextField value={recordData.name !== null ? recordData.name:"ไม่มีเนื้อหา"} style={{fontSize: "1.2vw"}}/>

                                            <div style={{marginTop: "1%"}}>
                                                <span 
                                                    style={{
                                                        fontSize: "0.8vw",

                                                        marginRight: "2%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "0.4",
                                                        paddingTop: "0.3%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "rgb(125, 232, 101)",
                                                    }} >
                                                        <TextField style={{color: "#fff"}} value={recordData.status !== null && recordData.status !== undefined ? recordData.status:"ไม่ระบุ"} />
                                                </span>
                                            


                                            <Tooltip placement="right" title={
                                                recordData?.properties !== undefined ?
                                                    recordData.properties.length > 0 ?
                                                        recordData.properties.map((data:any,index:any) => {
                                                            return <>
                                                                    {recordData.properties.length > 1 ? 
                                                                        <><span style={{fontSize: "0.5vw"}}>{data.property_name !== null ? data.property_name + " (" + data.property_code + ") ":"ไม่ระบุ"}</span><br /></>
                                                                    :
                                                                        data.property_name !== null ? data.property_name + " (" + data.property_code + ") ":"ไม่ระบุ"
                                                                    }
                                                                </>
                                                        }):<></>
                                                :
                                                <></>
                                            }>
                                                <Button style={{fontSize: "0.8vw", borderRadius: "50px",marginTop: "1%"}} icon={<Icons.PlusOutlined />}>
                                                    PROPERTY LIST
                                                </Button>
                                            </Tooltip>
                                            </div>

                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    {/* <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div> */}
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={10}>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                            <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                        </Col>
                                                                
                                        <Col className="gutter-row" span={14}>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                            <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Divider />

                                <Col className="gutter-row" span={24}>
                                        <div style={{fontSize: "0.9vw",marginBottom: "1%"}}>Detail :</div>
                                        {/* <TextField value={recordData.detail !== null ? recordData.detail:"ไม่มีเนื้อหา"} /> */}
                                        {ReactHtmlParser(`${recordData.detail !== null ? recordData.detail:"ไม่มีเนื้อหา"}`)}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
        </Show>
    );
};