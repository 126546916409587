/* eslint-disable */
import { Card, Col, Edit, Form, Input, Row, Upload, useForm, Select,useSelect, List, Breadcrumb, UploadFile, TextField, Button, Modal, useModal, Icons, Avatar, RcFile, message, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IProject , IPPType, IEventlog, IProjectBrand } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";

import { useEffect, useState } from "react";
import Moment from 'moment';

export const ProjectEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form,formProps, saveButtonProps }    = useForm<IProject>({redirect:"list"});
    const { queryResult } = useShow<IProject>({metaData: {
        populate: ['*','pp_type','project_brand','createBy.avatar','updateBy.avatar','pix']
        }, });
    const { data } = queryResult;
    let recordData:any = []
    recordData = data?.data? data?.data : [];

    const API_URL = useApiUrl();
    const { Dragger } = Upload;
    
    // const [count, setCount] = useState(0)
    const [vPPType, setPPType] = useState<any>();
    const [vPJBRAND, setPJBRAND] = useState<any>();
    const [vPix, setvPix] = useState<any>([]);

    // const [vModalInfo, setModalInfo] = useState(false);
    
    const { modalProps, show, close } = useModal();

    const { selectProps: Selpptype } = useSelect<IPPType>({
        resource: "pp-types",
        optionLabel: "pp_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const { selectProps: selPJBRAND } = useSelect<IProjectBrand>({
        resource: "project-brands",
        optionLabel: "brand_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'active_status',
                operator    : 'eq',
                value       : true
            },
        ] 
    });

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "projects",
        metaData: {
            populate: [
                '*',
                'pp_type',
                'createBy',
                'updateBy',
                'company',
                'project_brand'
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();

          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        setPPType(recordData.pp_type ? recordData.pp_type.id : null);
        setPJBRAND(recordData.project_brand ? recordData.project_brand.id : null);

        form.setFieldsValue({
            pp_type:
                recordData?.pp_type ?
                    recordData.pp_type.pp_name
                : null,
            project_brand:
                recordData?.project_brand ?
                    recordData.project_brand.brand_name
                : null,
        })

        const pixList: UploadFile[] = [
            {
                uid: recordData.pix.id,
                name: recordData.pix.name,
                status: "done",
                url:
                    recordData.pix.url,
                thumbUrl:
                    recordData.pix.url
            }
            ];

        setvPix({
            name: 'files',
            multiple: true,
            defaultFileList: [...pixList],
        })

        form.resetFields(["pix"]);
    }

    // useEffect(() => {
    //     if (count < 3) {
    //       const interval = setInterval(() => {
    //         setCount(prev => prev + 1)
    //         setPPType(recordData.pp_type ? recordData.pp_type.id : null);
    //         setPJBRAND(recordData.project_brand ? recordData.project_brand.id : null);

    //         form.setFieldsValue({
    //             pp_type:
    //                 recordData?.pp_type ?
    //                     recordData.pp_type.pp_name
    //                 : null,
    //             project_brand:
    //                 recordData?.project_brand ?
    //                     recordData.project_brand.brand_name
    //                 : null,
    //         })

    //         const pixList: UploadFile[] = [
    //             {
    //                 uid: recordData.pix.id,
    //                 name: recordData.pix.name,
    //                 status: "done",
    //                 url:
    //                     recordData.pix.url,
    //                 thumbUrl:
    //                     recordData.pix.url
    //             }
    //             ];

    //         setvPix({
    //             name: 'files',
    //             multiple: true,
    //             defaultFileList: [...pixList],
    //         })

    //         form.resetFields(["pix"]);

    //       }, 1000)
    //       return () => clearInterval(interval)
    //     }
    // }, [count])

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
        }
    }

    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'projects',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };


    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("projects.edit")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("project.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><span className="last_breadcrumb">{t("projects.edit")}</span></Breadcrumb.Item>
                                </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    updateUserUpdatedBy()
                    values.updateBy = user_data.user_profile.id;
                    values.updatedBy = user_data.user_profile.id;

                    values.pp_type = vPPType;
                    values.project_brand = vPJBRAND;

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >

                <Row gutter={16}>
                    <Col id="Col_w" className="gutter-row" span={24} style={{marginTop: "-60px",marginBottom: "1%"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={22} />
                            <Col className="gutter-row" span={2}>
                                <Button onClick={show} style={{width: "100%",borderRadius: "5px"}} icon={<Icons.InfoCircleOutlined/>}>Info</Button>    
                            </Col>
                        </Row>
                    </Col>
                    <Col id="Col_w" className="gutter-row" span={24}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item  label={t("pp-type.title")} name="pp_type" >
                                                <Select
                                                    placeholder={t("select.sel")}
                                                    {...Selpptype}
                                                    onChange={value => {
                                                        setPPType(value);
                                                    }}
                                                />
                                            </Form.Item> 
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item  label={t("project-brand.title")} name="project_brand">
                                                <Select 
                                                    placeholder={t("select.sel")}
                                                    {...selPJBRAND}
                                                    onChange={value => {
                                                        setPJBRAND(value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        {/* <Col className="gutter-row" span={4}>
                                            <Form.Item  label={t("projects.id")} name="project_id"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter project id.'
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col> */}
                                        <Col className="gutter-row" span={8}>
                                            <Form.Item  label={t("projects.code")} name="project_code"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter project code.'
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={16}>
                                            <Form.Item  label={t("projects.name")} name="project_name"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter project name.'
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={12}></Col>
                                        <Col className="gutter-row" span={12} style={{borderLeft: "1px solid #ddd"}}>
                                            <Form.Item
                                                name="pix"
                                                label={t("projects.pic")}
                                            >
                                                <Dragger style={{borderRadius: "10px"}} 
                                                    {...vPix}
                                                    name="files"
                                                    action={`${API_URL}/upload`}
                                                    headers={{
                                                        Authorization: `Bearer ${localStorage.getItem(
                                                            TOKEN_KEY,
                                                        )}`,
                                                    }}
                                                    listType="picture"
                                                    multiple
                                                    maxCount={1}
                                                    onChange={fileChange}
                                                    beforeUpload={beforeUpload}
                                                >
                                                    <p className="ant-upload-text">{t("upload")}</p>
                                                </Dragger>
                                                {/* <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p> */}
                                            </Form.Item>
                                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*PNG, JPG Maximum size 5MB</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>           
                </Row>

                <Modal onOk={close} {...modalProps} footer={[<Button key="submit" type="primary" onClick={close}>OK</Button>]}>
                    <div><Icons.InfoCircleOutlined style={{marginRight: "3%"}}/><TextField value={"Data Create & Update"}/></div>
                    <Row gutter={16} style={{marginTop: "6%"}}>
                        <Col className="gutter-row" span={24}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={15}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={4}>
                                        {
                                            recordData?.createBy?
                                                recordData.createBy.avatar !== null ?

                                                <Avatar size={32} src={recordData.createBy.avatar.url} style={{marginTop: "-7px"}}/>
                                            :<>
                                                <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                            </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        }
                                        </Col>
                                        <Col className="gutter-row" span={8}><span>{t("created")}</span></Col>
                                        <Col className="gutter-row" span={12}><TextField value={Moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}/></Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={7}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                        {/* <Col className="gutter-row" span={18}><TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null? recordData.createBy.first_name !== null && recordData.createBy.last_name !== null ? recordData.updateBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}/></Col> */}
                                        <Col className="gutter-row" span={18}>
                                            <TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null && recordData?.createBy !== undefined? 
                                                recordData?.createBy.first_name !== null && recordData.createBy.last_name !== null ? 
                                                recordData.createBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="gutter-row" span={24} style={{marginTop: "3%"}}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={15}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={4}>
                                        {
                                            recordData?.createBy?
                                                recordData.createBy.avatar !== null ?

                                                <Avatar size={32} src={recordData.createBy.avatar.url} style={{marginTop: "-7px"}}/>
                                            :<>
                                                <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                            </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        }
                                        </Col>
                                        <Col className="gutter-row" span={8}><span>{t("lupdate")}</span></Col>
                                        <Col className="gutter-row" span={12}><TextField value={Moment(recordData?.updatedAt).format('D/MM/yyyy HH:mm')}/></Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={7}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                        <Col className="gutter-row" span={18}>
                                            <TextField style={{marginLeft: "3%"}} value={recordData?.updateBy !== null && recordData?.updateBy !== undefined? 
                                                recordData?.updateBy.first_name !== null && recordData.updateBy.last_name !== null ? 
                                                recordData.updateBy.first_name + " " + recordData.updateBy.last_name : null :"ไม่ระบุ"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal>
            </Form>
        </Edit>
    );
};