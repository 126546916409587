import { Breadcrumb, Button, Col, Collapse, Descriptions, Divider, Form, Icons, Image, Input, List, Modal, Row,  Select,  ShowButton,  Space, Table, Tag, TextField, useModalForm, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { useState } from "react";

import Moment from 'moment';
import { IBillingInvoices, IProperties } from "interfaces";

export const BillingInvoiceList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { Panel } = Collapse;

    const [dataCus, setdataCus] = useState<any>();

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    
    const {data: Identity_data} = useGetIdentity<any>();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { tableProps, searchFormProps, tableQueryResult } = useTable<IBillingInvoices,HttpError,IBillingInvoices>({
        metaData: {
            populate: ['*', 'project_name']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { project_name, customer_name, address_number } = params;
                filters.push(
                    {
                        field: "project_name",
                        operator: "contains",
                        value: project_name,
                    },
                    {
                        field: "customer_name",
                        operator: "contains",
                        value: customer_name,
                    },
                    {
                        field: "address_number",
                        operator: "eq",
                        value: address_number,
                    },
                    // {
                    //     field       : "property][id]",
                    //     // operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                    //     operator    : "eq",
                    //     value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                    // },
    
                );
            return filters;
        },
        initialFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        queryOptions:{
            onSuccess(data){
                let dataLoad = data?.data;
                console.log('dataLoad', dataLoad)

            },
        },
    });

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data;
            if(dataSet?.length == 1){
                searchFormProps?.form?.setFieldsValue({
                    property_name: dataSet[0]?.property_name
                })
            }
        },}
    });

    const {
        modalProps: showModalProps,
        formProps: showProps,
        formLoading ,
        show: showModalShow,
        close
    } = useModalForm<any>({
        resource: "billing-invoices",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;
            //console.log('dataLoad', dataLoad)
            setdataCus(dataLoad);
        }},
    });

    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }
    
    return <>
            <List 
                title={t("billinginvoice.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>Billing</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("billinginvoice.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            {/* <Form.Item name="property" >
                                <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear/>
                            </Form.Item> */}

                            <Form.Item label="Search" name="project_name">
                                <Input
                                    placeholder="Search Project Name"
                                />
                            </Form.Item>

                            <Form.Item name="customer_name">
                                <Input
                                    placeholder="Search customer name"
                                />
                            </Form.Item>

                            <Form.Item name="address_number">
                                <Input
                                    placeholder="Search address"
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
    
                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableQueryResult?.data?.total, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                    scroll={{x: 1300}}
                >

                    {/* <Table.Column width={80} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    <Table.Column dataIndex="project_code" title="Project Code" width={120}
                        sorter={(a:any, b:any) => {
                            if (a?.project_code < b?.project_code) {return -1}
                            if (a?.project_code > b?.project_code) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? value :"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="project_name" title="Project Name" width={300} 
                        sorter={(a:any, b:any) => {
                            if (a?.project_name < b?.project_name) {return -1}
                            if (a?.project_name > b?.project_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? value :"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="customer_name" title="Customer Name" width={400} 
                        sorter={(a:any, b:any) => {
                            if (a?.customer_name < b?.customer_name) {return -1}
                            if (a?.customer_name > b?.customer_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? value :"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="address_number" title="Address Number" width={200} 
                        sorter={(a:any, b:any) => {
                            if (a?.address_number < b?.address_number) {return -1}
                            if (a?.address_number > b?.address_number) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? value :"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="invoice_code" title="Invoice Code" width={120} 
                        sorter={(a:any, b:any) => {
                            if (a?.invoice_code < b?.invoice_code) {return -1}
                            if (a?.invoice_code > b?.invoice_code) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? value :"ไม่ระบุ"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="invoice_date" title="Invoice Date" align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.invoice_date < b?.invoice_date) {return -1}
                            if (a?.invoice_date > b?.invoice_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? Moment(value).format('D/MM/yyyy HH:mm') : 'ไม่ระบุ'}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="invoice_due_date" title="Invoice due Date" align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.invoice_due_date < b?.invoice_due_date) {return -1}
                            if (a?.invoice_due_date > b?.invoice_due_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? Moment(value).format('D/MM/yyyy HH:mm') : 'ไม่ระบุ'}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="net_paid" title="Net Paid" width={100} 
                        sorter={(a:any, b:any) => {
                            if (a?.net_paid < b?.net_paid) {return -1}
                            if (a?.net_paid > b?.net_paid) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? value :"-"}  />
                            </>
                        }}
                    />
                    
                    <Table.Column title="CREATE DATE" align="center" width={150}
                     sorter={(a:any, b:any) => {
                        if (a.createdAt < b.createdAt) {return -1}
                        if (a.createdAt > b.createdAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={150}
                     sorter={(a:any, b:any) => {
                        if (a.updatedAt < b.updatedAt) {return -1}
                        if (a.updatedAt > b.updatedAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column dataIndex="pay_status" title="Net Paid" width={120} fixed="right"
                        render={(value:any,record:any)=>{
                            let colorCode = 
                                value == "0" ? "#C3C3C3" :
                                value == "1" ? "#5B7CE1" :
                                value == "2" ? "#9AD36C" :
                                value == "3" ? "#D53737" :
                                value == "4" ? "#E15BB2" :
                                value == "5" ? "#CBA050" : 
                                "#C3C3C3"
                            
                            return <>
                                <Tag color={colorCode} style={{width: "100%", textAlign: "center"}}>
                                    { value ? 
                                        value == "0" ? "ยังไม่ได้ชำระ" :
                                        value == "1" ? "กำลังตรวจสอบ" :
                                        value == "2" ? "ชำระแล้ว" :
                                        value == "3" ? "ยกเลิก" :
                                        value == "4" ? "ลดหนี้" :
                                        value == "5" && "ชำระบางส่วน"
                                        : "-"
                                    }
                                </Tag>
                                {/* <TextField 
                                    value={ value ? 
                                        value == "0" ? "ยังไม่ได้ชำระ" :
                                        value == "1" ? "กำลังตรวจสอบ" :
                                        value == "2" ? "ชำระแล้ว" :
                                        value == "3" ? "ยกเลิก" :
                                        value == "4" ? "ลดหนี้" :
                                        value == "5" && "ชำระบางส่วน"
                                        : "-"
                                    }
                                    style={{
                                        color:`${colorCode}`
                                    }}
                                /> */}
                            </>
                        }}
                    />
                    
                    <Table.Column<IBillingInvoices>
                        width={50}
                        title    = " "
                        align    = "center"
                        dataIndex= "status"
                        fixed = "right"
                        render={(value:any,record:any) => (
                            
                            <Space>
                                <ShowButton hideText size="small" recordItemId={record.id} onClick={() => showModalShow(record.id)}/>
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...showModalProps}
                title={<>
                    <Tag 
                        color={
                                dataCus?.pay_status == "0" ? "#C3C3C3" :
                                dataCus?.pay_status == "1" ? "#5B7CE1" :
                                dataCus?.pay_status == "2" ? "#9AD36C" :
                                dataCus?.pay_status == "3" ? "#D53737" :
                                dataCus?.pay_status == "4" ? "#E15BB2" :
                                dataCus?.pay_status == "5" ? "#CBA050" : 
                                "#C3C3C3"
                        }
                    >
                        { dataCus?.pay_status ? 
                            dataCus?.pay_status == "0" ? "ยังไม่ได้ชำระ" :
                            dataCus?.pay_status == "1" ? "กำลังตรวจสอบ" :
                            dataCus?.pay_status == "2" ? "ชำระแล้ว" :
                            dataCus?.pay_status == "3" ? "ยกเลิก" :
                            dataCus?.pay_status == "4" ? "ลดหนี้" :
                            dataCus?.pay_status == "5" && "ชำระบางส่วน"
                            : "-"
                        }
                    </Tag>
                    <span style={{marginLeft: "0.5rem"}}>INVOICE CODE : {dataCus?.invoice_code}</span>
                </>}
                footer={
                    <Button type="primary" onClick={()=> close()}>Close</Button>
                }
                width={1000}
                maskClosable={false}
                afterClose={()=> {
                    showProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...showProps} 
                    layout="vertical"
                >
                    <Row gutter={8}>
                        {/* <Col span={6} className="gutter-row">
                            <Image src={dataCus?.img_url}/>
                        </Col> */}

                        <Col span={24} className="gutter-row">
                            <Row gutter={8}>
                                <Col span={10} className="gutter-row">
                                    <Form.Item 
                                        label={"Project Code"} 
                                        name="project_code"
                                        className="input_disable_onshow"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    <Form.Item 
                                        label={"Project Name"} 
                                        name="project_name"
                                        className="input_disable_onshow"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8} className="gutter-row">
                                    <Form.Item 
                                        label={"Customer Name"} 
                                        name="customer_name"
                                        className="input_disable_onshow"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8} className="gutter-row">
                                    <Form.Item 
                                        label={"Address Number"} 
                                        name="address_number"
                                        className="input_disable_onshow"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8} className="gutter-row">
                                    <Form.Item 
                                        label={"Grand Total"} 
                                        name="grand_total"
                                        className="input_disable_onshow"
                                    >
                                        <Input disabled={true}/>
                                    </Form.Item>
                                </Col>
                            </Row>  
                        </Col>
                    </Row>

                    <Row gutter={8}>
                        <Col span={8} className="gutter-row">
                            <Form.Item 
                                label={"Invoice Code"} 
                                name="invoice_code"
                                className="input_disable_onshow"
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item 
                                label={"Invoice Date"} 
                                name="invoice_date"
                                className="input_disable_onshow"
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item 
                                label={"Invoice due Date"} 
                                name="invoice_due_date"
                                className="input_disable_onshow"
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item 
                                label={"Net Paid"} 
                                name="net_paid"
                                className="input_disable_onshow"
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item 
                                label={"Net Balance"} 
                                name="net_balance"
                                className="input_disable_onshow"
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                        <Col span={8} className="gutter-row">
                            <Form.Item 
                                label={"Net Total"} 
                                name="net_total"
                                className="input_disable_onshow"
                            >
                                <Input disabled={true}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Divider orientation="left" >INVOICE ITEM</Divider> */}
                    <Divider />
                    
                    {dataCus?.items && dataCus?.items?.length > 0 &&
                        <Collapse className="collapse_custom_view" style={{marginBottom: dataCus.receipt ? "0.5rem" : 0}}>
                            <Panel header="Billing Invoice Item" key="1">
                                {dataCus.items && dataCus.items.length > 0 && dataCus.items.map((item:any,index:any) => {
                                    return <>
                                        <div style={{marginBottom: dataCus?.length - 1 === index ? 0 : "1rem"}}>
                                            <Descriptions
                                                bordered
                                                size="small"
                                                column={2}
                                                title={<span style={{color: ""}}>Item No : {item.item_no ? item.item_no : "ไม่ระบุ"}</span>}
                                                style={{marginBottom: "0.5rem"}}
                                            >
                                                <Descriptions.Item label="Product Code">{item.product_code ? item.product_code : "ไม่ระบุ"}</Descriptions.Item>
                                                <Descriptions.Item label="Product Name">{item.product_name ? item.product_name : "ไม่ระบุ"}</Descriptions.Item>
                                            </Descriptions>

                                            <Descriptions
                                                bordered
                                                size="small"
                                                column={3}
                                            >
                                                <Descriptions.Item label="Price" style={{width: "179px"}}>{item.price ? item.price : 0}</Descriptions.Item>
                                                <Descriptions.Item label="Paid">{item.paid ? item.paid : "ไม่ระบุ"}</Descriptions.Item>
                                                <Descriptions.Item label="Period">{item.period ? item.period : "ไม่ระบุ"}</Descriptions.Item>
                                                <Descriptions.Item label="Quantity">{item.quantity ? item.quantity : "ไม่ระบุ"}</Descriptions.Item>
                                                <Descriptions.Item label="Description">{item.description ? item.description : "ไม่ระบุ"}</Descriptions.Item>
                                            </Descriptions>
                                        </div>
                                    </>
                                })}
                            </Panel>
                        </Collapse>
                    }

                    {dataCus?.receipt &&
                        <Collapse className="collapse_custom_view">
                            <Panel header="Billing Invoice Receipt" key="1">

                            {dataCus.receipt && dataCus.receipt.length > 0 && dataCus.receipt.map((item:any,index:any) => {
                                    return <>
                                       <div>
                                            <Descriptions
                                                bordered
                                                size="small"
                                                column={3} 
                                                title={<span style={{color: ""}}>Receipt No : {item?.receipt_no ? item?.receipt_no : "ไม่ระบุ"}</span>}
                                            >
                                                <Descriptions.Item label="Pay Type">{item?.pay_type ? item?.pay_type : "ไม่ระบุ"}</Descriptions.Item>
                                                <Descriptions.Item label="Pay Date">{item?.pay_date ? item?.pay_date : "ไม่ระบุ"}</Descriptions.Item>
                                                <Descriptions.Item label="Amount">{item?.amount ? item?.amount : "ไม่ระบุ"}</Descriptions.Item>
                                            </Descriptions>
                                        </div>
                                    </>
                                })}
                                {/* <div>
                                    <Descriptions
                                        bordered
                                        size="small"
                                        column={3}
                                        title={<span style={{color: ""}}>Receipt No : {dataCus?.receipt?.receipt_no ? dataCus?.receipt?.receipt_no : "ไม่ระบุ"}</span>}
                                    >
                                        <Descriptions.Item label="Pay Type">{dataCus?.receipt?.pay_type ? dataCus?.receipt?.pay_type : "ไม่ระบุ"}</Descriptions.Item>
                                        <Descriptions.Item label="Pay Date">{dataCus?.receipt?.pay_date ? dataCus?.receipt?.pay_date : "ไม่ระบุ"}</Descriptions.Item>
                                        <Descriptions.Item label="Amount">{dataCus?.receipt?.amount ? dataCus?.receipt?.amount : "ไม่ระบุ"}</Descriptions.Item>
                                    </Descriptions>
                                </div> */}
                            </Panel>
                        </Collapse>
                    }
                </Form>
            </Modal>
        </>
}