/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, Radio, RadioChangeEvent, Row, Select, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate, useUpdate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IContactCenter, IProperties } from "interfaces";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
// import { CCreateLog } from "../../components/create_log/index";
import axios from "axios";
import { TOKEN_KEY } from "../../../constants";

export const RepairMdpcEmailGroupCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null
    
    const token = localStorage.getItem(TOKEN_KEY);
    const t = useTranslate();
    const log_endpoint = "even-logs";
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { TextArea } = Input;
    const [ vStat, setStat ] = useState(false);
    const API_URL = useApiUrl();

    const [ vPropertyID, setPropertyID ] = useState<any>([]);
    // const [ vStaffs, setStaffs ] = useState<any>([]);
    const { replace, list} = useNavigation();
    const [ vPropCreate, setPropCreate ] = useState<any>([]);
    const [ count, setCount] = useState(0);
    const { mutate: updateEmailGroup } = useUpdate<any>();
    
    // const [ vIsNewOrOld, setIsNewOrOld] = useState<boolean>();
    const [ vEmail, setEmail] = useState<any>("");
    const { formProps, saveButtonProps } = useForm<IContactCenter,HttpError,{}>(
        {
            resource: 'email-groups',
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // console.log('kkkkkkkkkkk')
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                // <CCreateLog
                //     endpoint={log_endpoint}
                //     data={{
                //         user_profile : user_data.user_profile.id,
                //         log_time_stamp : Date.now(),
                //         log_event : "Create",
                //         menu_event : 'coupon',
                //         SysPkID : recordData.data.id + ""
                //     }}
                // />
                // replace("majorcare/setup-hcu")
                // list('mjc-email-group')
                // replace("/majorcare/setup-hcu")
                window.history.back()
            },
        }
    );

    async function getPropCreated(){
        
        resultQuery2 = await axios.get(API_URL + "/email-groups?populate=*&filters[type][$eq]=Repair-mdpc" , { headers: { Authorization: 'Bearer ' + token } });
        console.log('resultQuery2', resultQuery2)

        // var result = resultQuery2?.data.data.filter((obj:any) => {
        //     // console.log('obj', obj)
        //     return obj.attributes.deleteStatus === "N"
        // })

        let dataLoad = resultQuery2?.data.data
        console.log('dataLoad',dataLoad)
        if(dataLoad.length > 0){
            vPropCreate.pop();
            vPropCreate.push(dataLoad !== undefined ?
                    dataLoad.length > 0 ? 
                        dataLoad.map((v:any)=>{
                            // return {value:v.id}
                            return v.attributes.property.data.id + ""
                        })
                    : <></>
                : <></>)
                setPropCreate(vPropCreate);
        }

        // setPropCreate(vPropCreate.filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i))

    }

    // console.log('vPropCreate', vPropCreate)

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "pp-types",
        metaData: {
            populate: [
                '*',
            ],
        },
        pagination: {
            pageSize: 10,
        },
        queryOptions: {
            onSuccess: (data:any) => {
                console.log('kkkkkkkk')
                getPropCreated();
            },
        },
    });

    useEffect(() => {
        if (count < 3) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                // console.log('asd')
                getPropCreated();
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    useEffect(() => {
        getPropCreated();
    }, [])

    let resultQuery2:any = [];

    console.log('vPropCreate',vPropCreate[0])

    let initF : any;
    initF = {
        field       : 'deleteStatus',
        operator    : 'contains',
        value       :  'N',
    };

    const { selectProps: PropertySelectProps } = useSelect<IProperties>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        filters: [
            initF,
            // {
            //     field : 'id',
            //     operator : 'nin',
            //     // value : 1
            //     // value :  ["1","2","3","7"],
            //     value :  vPropCreate,
            // },
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    function onChangePropertyID(va:any){
        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
    };

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
    }

    async function saveData(prop_id:any){

        // find id
        let res = await axios.get(API_URL + "/email-groups?populate=*&filters[type][$eq]=Repair-mdpc&filters[property][id][$eq]=" + prop_id , { headers: { Authorization: 'Bearer ' + token } });
        // console.log('res', res?.data)
        let result = res?.data?.data
        // console.log('result', result[0]?.id)
        updateEmailGroup(
            {
                resource : 'email-groups',
                id : result[0]?.id,
                values : {
                    property : vPropertyID[0],
                    email : vEmail, 
                    updateBy : Identity_data ? Identity_data.user_profile.id : null
                },
            },{
                onSuccess: async(data: any, link: any) =>{
                    console.log('data', data)
                    replace("/repair-mdpc/repair-mdpc-setup")
                    // window.history.back();
                },onError(error, variables, context) {
                    // errornoti(error.response.status, error.response.data);
                },
            },
        )
    }

    return (
    <Create 
        saveButtonProps={saveButtonProps}
        pageHeaderProps={{
            extra : <></>,
            onBack(e?) {
                replace("/repair-mdpc/repair-mdpc-setup")
            },
        }}
    >
        <List 
            // title={t("banner-mgn.title-create")}
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("contact-center.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>MDPC แจ้งซ่อม</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">Email Group Create</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id
                    values.property = vPropertyID[0];
                    // values.deleteStatus = vStat == true ? "N" : "Y";
                    values.email = vEmail
                    values.type = "Repair-mdpc"

                    let matchingIndices:any = [];

                    console.log('vPropCreate', vPropCreate)


                    if(vPropCreate.length > 0){
                        for (let i = 0; i < vPropCreate[0].length; i++) {
                            if (Number(vPropertyID[0]) == Number(vPropCreate[0][i])) {
                                // matchingIndices.push(i);
                                matchingIndices.push(vPropCreate[0][i]);
                            }
                        }
                    }

                    // console.log("Indices where vPropertyID[0] matches vPropCreate:", matchingIndices);

                    if(matchingIndices.length > 0){
                        // console.log('update')
                        saveData(matchingIndices);
                    }else{
                        return (
                            formProps.onFinish &&
                            formProps.onFinish(mediaUploadMapper(values))
                        );
                    }

                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(mediaUploadMapper(values))
                    // );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{marginBottom:'2%'}}></div>
                            {/* <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Subject : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item 
                                        name="subject"
                                    >
                                        <Input value={"Appointment"} defaultValue="Appointment" disabled/>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Property : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        // name="privilege_properties"
                                        name="property"
                                        rules={[
                                            {
                                                required: true, message: 'Please select property.'
                                            },
                                        ]}
                                    >
                                        <Select 
                                            // size="small"
                                            style={{width:"100%"}}
                                            // mode="multiple" 
                                            placeholder={t("select.sel")}
                                            {...PropertySelectProps}
                                            onChange={onChangePropertyID}
                                            disabled={ count > 0 ? false : true}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Email : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="email"
                                     rules={[
                                        {
                                            required: true, message: 'Please enter email.'
                                        },
                                    ]}
                                    >
                                        <TextArea style={{height: "100px", maxHeight: "80px"}} placeholder="seperate email by comma ex. testmail@mail.com,testmail2@mail.com" onChange={(e) => onChangeEmail(e)} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Status : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="active_status" style={{width: "100%"}}
                                        // label={t("menumgn-create.status")}
                                        label=""
                                        rules={[
                                            {
                                                required: true, message: 'Please select status.'
                                            },
                                        ]}>
                                            <Radio.Group
                                                options={optionsRadioStat}
                                                onChange={onChangeStat}
                                                // value={vValueNoti}
                                                value={vStat}
                                            />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};