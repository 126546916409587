/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Divider, Form, Input, List, Row, Select, Upload, useForm, TextField, UploadFile, RcFile, message } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import { IEventlog, IFacilityType } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";

let recordData: any = undefined;
export const FacilitiesTypeEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Dragger } = Upload;
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";

    const [vImgList, setImgList] = useState<any>([]);
    const [vLogo, setLogo] = useState<any>([]);

    const { form, formProps, saveButtonProps } = useForm<IFacilityType>({redirect:"list"});

    const { queryResult } = useShow<IFacilityType>({
        metaData: {
            populate: ['main_pix', 'icon_pix', '*']
        },
        queryOptions:{
            onSuccess(data) {
                console.log(">>> data?.data",data?.data)

                let dataLoad: any = data?.data;
                recordData = dataLoad;

                const fileMainPix: UploadFile[] = 
                    dataLoad?.main_pix ? dataLoad?.main_pix?.length > 0 && dataLoad?.main_pix.map((e:any)=>(
                        {
                            uid: String(e?.id),
                            name: e?.name,
                            status: "done",
                            url: e?.url,
                            thumbUrl: e?.url
                        }
                    )) 
                : [];

                setImgList({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [...fileMainPix],
                });

                const fileIconPix: UploadFile[] = 
                    dataLoad?.icon_pix ? [
                        {
                            uid: dataLoad?.icon_pix.id,
                            name: dataLoad?.icon_pix.name,
                            status: "done",
                            url: dataLoad?.icon_pix.url,
                            thumbUrl: dataLoad?.icon_pix.url
                        }
                    ]
                : [];

                setLogo({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [...fileIconPix],
                });

                form?.resetFields(["main_pix"]);
                form?.resetFields(["icon_pix"]);
            },
        }
    });

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };
    
    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(),
                log_event               : "Edit",
                menu_event              : 'facility-types',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
        }
    }

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            // title={t("fclt-type.edit")}
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-type.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
        {/* fix bro */}
        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.updateBy = user_data.user_profile.id
                values.updatedBy = user_data.user_profile.id
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                ); 
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("fclt-type.code")} name="code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("fclt-type.name")} name="name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("fclt-type.name-en")} name="name_en"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name (ENG).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div className="div_icon_pix">
                            <Form.Item
                            name="icon_pix"
                            label={t("fclt-type.icnpix")}>
                                <Dragger style={{borderRadius: "10px"}} 
                                    {...vLogo}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Dragger>
                            </Form.Item>

                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 50*50 PNG, JPG Maximum size 5MB</p>
                        </div>

                        <div className="div_main_pix">
                            <Form.Item
                                name="main_pix"
                                label={t("fclt-type.imglist")}
                            >
                                <Dragger style={{borderRadius: "10px", width: '100%'}} 
                                    {...vImgList}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    // {...uploadProps_coverpix}
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Dragger>
                            </Form.Item>
                            
                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 338*190 PNG, JPG Maximum size 5MB</p>
                        </div>
                    </Card>

                    <Card style={{backgroundColor: "#fff", marginBottom: "2%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>

                            <Col className="gutter-row" span={14}>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{recordData?.createBy? recordData?.createBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(recordData?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{fontSize: "0.7vw"}}>{recordData?.updateBy? recordData?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>   
    );
};