import { Card, Col, Edit, Form, Input, Row, useForm, TextField, List, Breadcrumb, Select, Checkbox, Radio, RadioChangeEvent, Empty, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useList, useShow, useTranslate } from "@pankod/refine-core";
import { IAppointmentType, IEventlog } from "interfaces";
import Moment from 'moment';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useEffect, useState } from "react";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";

export const AppointmTypeEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data }     = userQuery;
    // const { formProps, saveButtonProps } = useForm<IAppointmentType,HttpError,{}>();
    const { form, formProps, saveButtonProps }    = useForm<IAppointmentType>({redirect:"list"});
    const { queryResult } = useShow<IAppointmentType>({metaData: {
        populate: '*'
        }, });
    const { data }              = queryResult;
    const record                = data?.data;
    let recordData:any = []
    recordData = data?.data ? data?.data : [];


    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "appointment-types",
        metaData: {
            populate: [
                '*',
                'user_sub_types',
            ],
            locale:['th']
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();
          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
    }, []);

    function callFetchData(){
        setValueNoti(recordData ? recordData.noti: false);
        setDaySendNoti(recordData ? recordData.noti_time: "0");
        setStatus(recordData? recordData.status: null)
        setRemark(recordData ? recordData.remark: "")
        setRemarkEn(recordData ? recordData.remark_en: "")

        setCheckedPermiss(recordData.user_sub_types.length > 0 ? recordData? 
            recordData.user_sub_types !== undefined ?
                recordData.user_sub_types.length > 0 ? 
                    recordData.user_sub_types.map((v:any)=>{
                        return v.id
                    }) 
                : <></>
            : <></>
        : <></>: []);

        if(count >= 1){
            setDisable(false)
        }
    }

    const seePermission = useList<IAppointmentType>({ 
        resource: "user-sub-types", 
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "sub_type_name",
                    operator: "eq",
                    // value   : ["Guest", "Pre Owner", "Owner", "Renter", "Resident Owner", "Resident Renter"]
                    value   : ["Guest", "Owner Contract", "Owner", "Renter", "Resident Owner", "Resident Renter", "Owner-Contract"]
                },
            ]
        }
    });
 
    const { data: xdata }       = seePermission;
    let permissionData:any      = []
    permissionData              = xdata?.data? xdata?.data : [];
    
    const { TextArea }          = Input;
    // const { replace}            = useNavigation();
    //const API_URL = useApiUrl();
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const { Option }                        = Select;
    const [count, setCount]                 = useState(0)

    const [ vValueNoti, setValueNoti ]      = useState(recordData ? recordData.noti: false);
    const [ vDaySendNoti, setDaySendNoti ]  = useState<any>(recordData ? recordData.noti_time: "0");
    const [ vRemark, setRemark ]  = useState<any>(recordData ? recordData.remark: "");
    const [ vRemarkEn, setRemarkEn ]  = useState<any>(recordData ? recordData.remark_en: "");
    const [ vStatus, setStatus ] = useState<any>();
    const [ vDisable, setDisable ] = useState<any>(true);

    const [ vPermiss, setvPermiss ] = useState<any>([]);

    const [checkedPermiss, setCheckedPermiss] = useState<CheckboxValueType[]>();

    const optionsRadioNoti = [
        { label: t("appointm-type-create.noti-false"), value: false },
        { label: t("appointm-type-create.noti-true"), value: true },
    ];
    
    const onChangeNoti = ({ target: { value } }: RadioChangeEvent) => {
        console.log('radio noti checked', value);
        setValueNoti(value);
    };

    const onChangepermiss = (checkedValues: CheckboxValueType[]) => {
        vPermiss.pop();
        vPermiss.push(checkedValues);
        setvPermiss(vPermiss);

        setCheckedPermiss(checkedValues)
        console.log('vCheckbox = ', vPermiss);
    };

    const onChangeStatus = (e: RadioChangeEvent) => {
        // console.log('onChangeStatus',e.target.value);
        setStatus(e.target.value);
    };

    // useEffect(() => {
    //     if (count < 3) {
    //       const interval = setInterval(() => {
    //         setCount(prev => prev + 1)

    //         setValueNoti(recordData ? recordData.noti: false);
    //         setDaySendNoti(recordData ? recordData.noti_time: "0");
    //         setStatus(recordData? recordData.status: null)
    //         setRemark(recordData ? recordData.remark: "")

    //         setCheckedPermiss(recordData.user_sub_types.length > 0 ? recordData? 
    //             recordData.user_sub_types !== undefined ?
    //                 recordData.user_sub_types.length > 0 ? 
    //                     recordData.user_sub_types.map((v:any)=>{
    //                         return v.id
    //                     }) 
    //                 : <></>
    //             : <></>
    //         : <></>: []);

    //         if(count >= 1){
    //             setDisable(false)
    //         }

    //       }, 1000)
    //       return () => clearInterval(interval)
    //     }
    // }, [count])
    
    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(),
                log_event               : "Edit",
                menu_event              : 'appointments_type',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("appointm-type-edit.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("appointm-type.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("appointm-type-edit.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    updateUserUpdatedBy();
                    values.updateBy = user_data.user_profile.id
                    values.updatedBy = user_data.user_profile.id
                    values.noti_time = vDaySendNoti
                    values.remark = vRemark
                    values.remark_en = vRemarkEn
                    values.user_sub_types = checkedPermiss
                    values.status = vStatus;
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }} 
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={12}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("appointm-type-create.apt_name")} name="name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter appointment name.'
                                            },
                                        ]}>
                                            <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("appointm-type-create.apt_name_en")} name="name_en"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter appointment name EN.'
                                            },
                                        ]}>
                                            <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("appointm-type-create.condi")} name="appointment_option"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter.'
                                            },
                                        ]}>
                                            <Select placeholder={t("select.sel")}>
                                                <Option value="Complete">การนัดสมบูรณ์ทันที</Option>
                                                <Option value="Again">รอเจ้าหน้าที่ยืนยันอีกครั้ง</Option>
                                            </Select>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("appointm-type-create.note")} name="note"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter message.'
                                            },
                                        ]}>
                                            <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("appointm-type-create.stat")} name="status">
                                        <Radio.Group 
                                            style={{verticalAlign: 'middle'}} 
                                            onChange={onChangeStatus}
                                            value={vStatus}
                                            disabled={vDisable}
                                        >
                                            <Radio.Button value={true}>{t("appointm-type.public")}</Radio.Button>
                                            <Radio.Button value={false}>{t("appointm-type.draft")}</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",borderRadius: 5,boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Form.Item  label={t("appointm-type-create.noti")} name="noti">
                                <Radio.Group options={optionsRadioNoti} onChange={onChangeNoti} value={vValueNoti} optionType="button"/>
                            </Form.Item>
                            {
                                vValueNoti || vValueNoti == null  ?
                                    <>
                                        <Input type="number" placeholder="set day" style={{width: "100%",borderRadius: "5px"}} onChange={(e) => setDaySendNoti(e.target.value)} value={vDaySendNoti} suffix="days"/>
                                    </>
                                    :
                                    <></>
                            }
                            <div style={{marginBottom:'20px'}}></div>
                            <Form.Item  label="Remark" name="remark">
                                {/* <Input placeholder="Remark" style={{width: "100%",borderRadius: "5px"}} onChange={(e) => setRemark(e.target.value)} value={vRemark} /> */}
                                <TextArea style={{height: "100px", maxHeight: "80px"}} placeholder="Remark" onChange={(e) => setRemark(e.target.value)} value={vRemark}/>

                            </Form.Item>

                            <div style={{marginBottom:'20px'}}></div>
                            <Form.Item  label="Remark EN" name="remark_en">
                                {/* <Input placeholder="Remark" style={{width: "100%",borderRadius: "5px"}} onChange={(e) => setRemark(e.target.value)} value={vRemark} /> */}
                                <TextArea style={{height: "100px", maxHeight: "80px"}} placeholder="Remark" onChange={(e) => setRemarkEn(e.target.value)} value={vRemark}/>

                            </Form.Item>
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Form.Item  label={t("appointm-type-create.permis")}>
                                {permissionData !== null && permissionData.length > 0 ?
                                    <>
                                        <Checkbox.Group style={{ width: '100%' }} onChange={onChangepermiss} value={checkedPermiss}>
                                            <Row>
                                                {
                                                    permissionData?.map((el:any,index:any) => {
                                                        return <>
                                                            <Col span={24} style={{marginBottom: "2%"}}>
                                                                <Checkbox key={el.id} value={el.id} >{el.sub_type_name}</Checkbox>
                                                            </Col>
                                                            </>
                                                        })
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                    </>
                                    :
                                    <>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </>}
                            </Form.Item>
                        </Card>

                        <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={10}>
                                        <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                        <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                        <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                        <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                    </Col>
                                                            
                                    <Col className="gutter-row" span={14}>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                        <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                                    </Col>
                                </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};