import { Breadcrumb, Button, Col, Icons, List, Row, Tabs } from "@pankod/refine-antd"
import { IResourceComponentsProps, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import type { TabsProps } from 'antd';
import { useEffect, useState } from "react";
import { RSLocationList } from "./location";
import { RentPriceRangeList } from "./price_range";
import { RentPropertyTypeList } from "./property_type";
import { RentRoomTypeList } from "./room_type";
import { RentSaleSubLocationList } from "./sub_location";
import { RentSubTransportList } from "./sub_transport";
import { RentTransportList } from "./transport";


export const RentSaleMasterList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TabPane } = Tabs;
    const { create, edit } = useNavigation();
    const [ Tab, SetTab] = useState<any>();

    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility");

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const tabJobActive = localStorage.getItem("keyTabRentSaleMaster");
    console.log('tabJobActive', tabJobActive)
    const [tabm, settabm] = useState<any>("location");

    function onChangeTabTerm(v:any){
        settabm(v)
        localStorage.setItem("keyTabRentSaleMaster", v)
    }

    useEffect(() => {
        console.log('tabJobActive', tabJobActive)
        settabm(tabJobActive)
    }, [tabJobActive])
    
    return (
        <List 
            title={t("rent-sale.title-master")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("rent-sale.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("rent-sale.title")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("Master")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >

            <Row style={{float:'right'}}>
                <Col span={24}>
                    <Button 
                        type="primary" 
                        icon={<Icons.PlusOutlined />} 
                        style={{borderRadius: "5px"}}
                        // onClick={()=>
                        //     tabJobActive !== undefined?
                        //         tabJobActive == "type" ? create("pet-types") : 
                        //         tabJobActive == "specie" ? create("pet-species") : 
                        //         tabJobActive == "vaccine" && create("pet-vaccine")
                        //     : create("pet-types")
                        // }
                        onClick={()=>
                            tabm == "location" ? create("master-location") : 
                            tabm == "sublocation" ? create("master-sub-location") : 
                            tabm == "proptype" ? create("master-prop-type") : 
                            tabm == "roomtype" ? create("master-bedroom") : 
                            tabm == "transport" ? create("master-bts-mrt") : 
                            tabm == "subtransport" ? create("master-sub-transport") : 
                            tabm == "pricerange" && create("master-price-range")
                        } 
                    >
                        {t("buttons.create")}
                    </Button>
                </Col>
            </Row>
            
            <Tabs 
                style={{width:'100%'}}
                defaultActiveKey={tabJobActive ? tabJobActive + "" : "type"}
                onChange={(e)=> onChangeTabTerm(e)}
            >
                <TabPane
                    key={"location"}
                    tab={<span>{t("rent-sale.tab-location")}</span>}
                >
                   <RSLocationList/>
                </TabPane> 
                <TabPane
                    key={"sublocation"}
                    tab={<span>{t("rent-sale.tab-sublocation")}</span>}
                >
                   <RentSaleSubLocationList/>
                </TabPane> 
                <TabPane
                    key={"proptype"}
                    tab={<span>{t("rent-sale.tab-proptype")}</span>}
                >
                    <RentPropertyTypeList />
                </TabPane>  
                <TabPane
                    key={"roomtype"}
                    tab={<span>{t("rent-sale.tab-roomtype")}</span>}
                >
                    <RentRoomTypeList />
                </TabPane> 
                <TabPane
                    key={"transport"}
                    tab={<span>{t("rent-sale.tab-transport")}</span>}
                >
                    <RentTransportList />
                </TabPane> 
                <TabPane
                    key={"subtransport"}
                    tab={<span>{t("rent-sale.tab-sub-transport")}</span>}
                >
                    <RentSubTransportList />
                </TabPane> 
                <TabPane
                    key={"pricerange"}
                    tab={<span>{t("rent-sale.tab-pricerange")}</span>}
                >
                    <RentPriceRangeList />
                </TabPane> 
            </Tabs>

            {/* {
                tabJobActive &&
                tabJobActive == "type" ? <PettypeList />:
                tabJobActive == "specie" ? <PetspeciesList /> :
                tabJobActive == "vaccine" && <PetvcList />
            } */}
        </List>
    )
}