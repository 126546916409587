/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton,  EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, Space, Table, Tag, TextField, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useList, useUpdate } from "@pankod/refine-core";
import { IContentPostType, IEventlog, IPPType } from "interfaces";
import { useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import Moment from 'moment';

export const PPtypesList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Properties type");

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IPPType,HttpError,IPPType>({
        metaData: {
            populate: '*'
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { pp_name } = params;
                filters.push(
                    {
                        field       : "pp_name",
                        operator    : "contains",
                        value       : pp_name
                    },
                );
            return filters;
        },
    });

    const dataProperty = useList<any>({ 
        resource: "properties", 
        metaData: {
            populate: ['*', 'pp_type', 'property_name'],
        },
        config:{
            // filters:[
            //     {
            //         field: "id",
            //         operator: (prop_list || prop_id) ? "eq" : "nin",
            //         value: prop_list ? prop_list: prop_id ? prop_id : null,
            //     }
            // ],
            pagination: { current: 1, pageSize: 1000 
        }},
    });

    const categoryQueryResult = useOne<IPPType>({
        resource: "pp-types",
        id: ''
    });
    
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    // console.log(paginationLength);
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IPPType>();
    const ed_point                          = "pp-types";

    const [searchPropertyText, setSearchPropertyText] = useState('');
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const onReset = () => {
        setSearchPropertyText('')
        searchFormProps?.form?.resetFields();
        searchFormProps?.form?.submit();
    }

    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IPPType>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                        
                };
            }
        },
    });

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'pp_type',
                SysPkID         : id+""
            },
            successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }
    
    return <>
        <List 
            title={t("pp-type.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("property.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("bc_pp-type.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        <Form.Item name="Search" >
                            <Input 
                                onChange={(e) => setSearchPropertyText(e.target.value)}
                                placeholder="Search property" size="middle" style={{width: '250px', position:'relative'}} allowClear
                            />
                        </Form.Item>
                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>

                <Col span={6}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
                
            <Table {...tableProps} 
                dataSource={tableProps?.dataSource?.filter(record => {
                    const propertyList = dataProperty?.data?.data?.filter((item: any) => {
                        if(item.pp_type && item.pp_type.id == record.id){
                            return item
                        }
                    })
                    if(searchPropertyText){
                        return propertyList?.some(property => property.property_name.toLowerCase().includes(searchPropertyText.toLowerCase()))
                    }
                    return true
                }) || []}
                bordered
                size="middle"
                rowKey      = "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    total:paginationLength.length, 
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false
                }}  
            >
                <Table.Column dataIndex="pp_code" title="TYPE CODE" align="center" width={200}
                    sorter={(a:any, b:any) => {
                        if (a?.pp_code < b?.pp_code) {return -1}
                        if (a?.pp_code > b?.pp_code) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return (<TextField value={value} />)
                    }}
                />

                <Table.Column dataIndex="pp_type" title="PROPERTIES NAME" width={100} align="center" className="table_colmn_custom_left_value"
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                dataProperty?.data?.data?.map((item: any, key: any) => {
                                    if(item.pp_type && item.pp_type.id == record.id){
                                        return <>
                                            <Tag>{item.property_name}</Tag>
                                            </>

                                    }
                                })
                            }
                        </>
                    }}
                />

                <Table.Column dataIndex="pp_name" title="PROPERTIES TYPE" align="center" width={200}
                    sorter={(a:any, b:any) => {
                        if (a?.pp_name < b?.pp_name) {return -1}
                        if (a?.pp_name > b?.pp_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return (<TextField value={value} />)
                    }}
                />

                <Table.Column title="CREATED BY" align="center" width={100} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                        if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return (<TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"} />)
                    }}
                />

                <Table.Column title="CREATED DATE" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.createdAt < b?.createdAt) {return -1}
                        if (a?.createdAt > b?.createdAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return (<TextField value={record?.createdAt ? Moment(record?.createdAt).format('D/MM/yyyy HH:mm') : '-'}  />)
                    }}
                />

                <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                        if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return (<TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"} />)
                    }}
                />

                <Table.Column title="LAST UPDATE" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.updatedAt < b?.updatedAt) {return -1}
                        if (a?.updatedAt > b?.updatedAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return (<TextField value={record?.updatedAt ? Moment(record?.updatedAt).format('D/MM/yyyy HH:mm') : '-'}  />)
                    }}
                />
                
                <Table.Column<IContentPostType>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed="right"
                    render={(_, record) => (
                        <Space>
                            {/* <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            >รายละเอียด</ShowButton> */}

                            {/* <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />

                            <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}
                            {
                                record.deleteStatus === "N" ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        />
                                        <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                                :
                                    <>
                                        {/* <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id} 
                                            disabled
                                        /> */}
                                        <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                            }
                        </Space>
                    )}
                />
            </Table>
        </List>
    </>
}