import { CSSProperties } from "react";

export const antLayoutSider: CSSProperties = {
  position: "relative",
  minWidth : "245px",
  maxWidth:"245px",
  width: "245px",
};

export const antLayoutSiderMobileHide: CSSProperties = {
  position: "relative",
  minWidth : "80px",
  maxWidth:"80px",
  width: "80px",
  zIndex: 999,
};


export const antLayoutSiderMobile: CSSProperties = {
  position: "fixed",
  //height: "100vh",
  zIndex: 999,
};

