import { Breadcrumb, Col, EditButton, Form, Input, List, Modal, Row, Space, Table, Tag, TextField, useModalForm, useTable, Image, Radio, Icons, Collapse, useSelect, Select, Button } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { IBillingPayment, IProperties } from "interfaces";
import { useState } from "react";
import Moment from 'moment';

let nf = new Intl.NumberFormat('en-US');
export const BillingpaymentList: React.FC<IResourceComponentsProps> = () => {

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Billing Payment");

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { Panel } = Collapse;
    // const userQuery = useGetIdentity();
    // const {data : user_data , isLoading} = userQuery;
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const [imgurl, setimgurl] = useState<any>()
    const [dataCus, setdataCus] = useState<any>()

    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const { tableProps,searchFormProps,tableQueryResult } = useTable<any,HttpError, any>({
        metaData: {
            // populate: '*',
            populate: [
                '*',
                'biiling_payment_items',
                'biiling_payment_items.billing_payment_types',
                'config_payment',
                'config_payment.account_name',
                'unit',
                'unit.property',

            ],
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property } = params;
            console.log(">>> property", property)
            filters.push(
                {
                    field       : "unit][property][id]",
                    // operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                    operator    : "eq",
                    value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                },
            );
            return filters;
        },
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
        initialFilter:[
            {
                field: "unit][property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ]
    });

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        pagination:{
            pageSize: 200
        },
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data;
            if(dataSet?.length == 1){
                searchFormProps?.form?.setFieldsValue({
                    property: dataSet[0]?.id
                })
            }
        },}
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "billing-payments",
        metaData: {
            populate: ["biiling_payment_items","biiling_payment_items.billing_payment_types","biiling_payment_items.billing_payment","config_payment","slip"],
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data
            console.log(dataLoad)
            setdataCus(dataLoad)
            if(dataLoad?.slip){
                setimgurl(dataLoad?.slip?.url)
            }
        },}
    });

    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }
    
    return <>
        <List 
            title={t("billing-payment.title-list")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("billing.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("billing-payment.title-list")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        <Form.Item name="property" >
                            <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear/>
                        </Form.Item>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Table {...tableProps} 
                bordered
                size="middle"
                rowKey= "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    total: (tableProps?.pagination as any)?.total || tableProps?.dataSource?.length,
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false,
                }} 
            >
                {/* <Table.Column width={70} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                <Table.Column 
                    title="PROPERTY"
                    sorter={(a:any, b:any) => {
                        if (a?.unit?.property?.property_name < b?.unit?.property?.property_name) {return -1}
                        if (a?.unit?.property?.property_name > b?.unit?.property?.property_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={record?.unit ? record?.unit?.property?.property_name : "-"} />

                    }}
                />

                <Table.Column 
                    //dataIndex="unit" 
                    title="UNIT"
                    sorter={(a:any, b:any) => {
                        if (a?.unit?.unit_name < b?.unit?.unit_name) {return -1}
                        if (a?.unit?.unit_name > b?.unit?.unit_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={record?.unit ? record?.unit?.unit_name : "-"} />
                    }}
                />

                <Table.Column dataIndex="timestamp" title={t("billing-payment.stamp-date")}
                    sorter={(a:any, b:any) => {
                        if (a?.timestamp < b?.timestamp) {return -1}
                        if (a?.timestamp > b?.timestamp) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={value ? Moment(value).format('D/MM/yyyy HH:mm') : "-"} />
                    }}
                />

                <Table.Column dataIndex="amount" title={t("billing-payment.amount")}
                    sorter={(a:any, b:any) => {
                        if (a?.amount < b?.amount) {return -1}
                        if (a?.amount > b?.amount) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={value ? nf.format(value) : "-"} />
                    }}
                />

                <Table.Column dataIndex="biiling_payment_items " title={t("billing-payment.bll-payment-item")}
                    render={(value:any,record:any)=>{
                        // console.log('record', record)
                        return <>
                            {
                                record?.biiling_payment_items?.map((data:any,index:any) => {
                                    // console.log('data : ', data);
                                    return <>
                                        <Tag className="tag_post"><TextField value={data?.billing_payment_types ? data?.billing_payment_types[0]?.name : <></> }  /></Tag>
                                    </>
                                })
                            }
                        </>
                        // return <TextField value={value?.biiling_payment_items ? value?.amount :"-"}  />
                    }}
                />

                <Table.Column title={t("billing-payment.con-payment")}
                    sorter={(a:any, b:any) => {
                        if (a?.config_payment?.account_name < b?.config_payment?.account_name) {return -1}
                        if (a?.config_payment?.account_name > b?.config_payment?.account_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={record?.config_payment ? record?.config_payment?.account_name : "-"}  />
                    }}
                />

                <Table.Column dataIndex="status" title={t("billing-payment.payment-status")}
                    sorter={(a:any, b:any) => {
                        if (a?.status < b?.status) {return -1}
                        if (a?.status > b?.status) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        let color = (value == true) ?'green':(value === false) ?'red':'gray'
                        // let txt = (value == true) ?'ชำระเงินเรียบร้อย':(value == false) ?'แจ้งชำระเงินไม่ถูกต้อง':'ยังไม่มีการตรวจสอบ'
                        let txt = (value == true) ?'การฝากชำระถูกต้อง':(value == false) ?'การฝากชำระไม่ถูกต้อง':'ยังไม่มีการตรวจสอบ'
                        return <Tag style={{borderRadius: "10px"}} color={color}>{txt}</Tag> 
                    }}
                />

                <Table.Column title="CREATE DATE" align="center"
                 sorter={(a:any, b:any) => {
                    if (a.createdAt < b.createdAt) {return -1}
                    if (a.createdAt > b.createdAt) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.createdAt ?
                                    <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />

                <Table.Column title="LAST UPDATE" align="center"
                 sorter={(a:any, b:any) => {
                    if (a.updatedAt < b.updatedAt) {return -1}
                    if (a.updatedAt > b.updatedAt) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.updatedAt ?
                                    <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />

                <Table.Column<IBillingPayment>
                    width={100}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => editModalShow(record.id)}
                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>

        <Modal 
            {...editModalProps}
            title={"Deposit Edit"}
            okText = {"Save"}
            maskClosable={false}
            width={400}
            afterClose={()=> {
                setimgurl(undefined);
                setdataCus(undefined);
                editProps?.form?.resetFields();
            }}
        >
            <Form 
                {...editProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    values.status = values.status;
                    values.config_payment = dataCus?.config_payment?.id;

                    // values.updateBy = user_data.user_profile.id;
                    return (
                        editProps.onFinish &&
                        editProps.onFinish(values)
                    );
                }}
            >
                <Row gutter={8}>
                    <Col span={16} className="gutter-row">
                        <Form.Item label={t("billing-payment.stamp-date")} name="timestamp"
                            getValueProps={(value) => ({
                                value: value ? Moment(value).format('D/MM/yyyy') : "-",
                            })}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item label={t("billing-payment.stamp-time")} name="timestamp"
                            getValueProps={(value) => ({
                                value: value ? Moment(value).format('HH:mm') : "-",
                            })}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item label={t("billing-payment.amount")} name="amount"
                            getValueProps={(value) => ({
                                value: value ? nf.format(value): "-",
                            })}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item label={t("billing-payment.con-payment")} name={["config_payment","account_name"]}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row" style={{ display: "flex", justifyContent: "center"}}>
                        <Image src={imgurl} style={{width: "11rem",height: "auto",border: "1px solid #ddd"}}/>
                    </Col>

                    <Col span={24} className="gutter-row" style={{marginTop: "1rem"}}>
                        <div style={{color: "#000",marginBottom: "0.5rem"}}>{t("billing-payment.title-list")}</div>
                        <Collapse className="custom_collapse_i">
                            {dataCus && dataCus?.biiling_payment_items.length > 0 && dataCus?.biiling_payment_items?.map((item:any,index:any) => {
                                return item?.billing_payment_types?.length > 0 && item?.billing_payment_types?.map((item_type:any,index:any) => {
                                    return <Panel header={item_type?.name} key="1"><p>จำนวนเงิน : {item?.amount ? nf.format(item?.amount) : "-"} บาท</p></Panel>
                                })
                            })}
                        </Collapse>
                    </Col>

                    <Col span={24} className="gutter-row" style={{ display: "flex", justifyContent: "center",marginTop: "1rem"}}>
                        <Form.Item label=" " name="status" className="disable_req_c" rules={[{required: true,message: "Please select"}]}>
                            <Radio.Group 
                                style={{verticalAlign: 'middle'}} 
                                onChange={(e) => ""}
                            >
                                <Radio.Button value={true}><Icons.CheckOutlined style={{marginRight: "0.5rem"}}/>{t("payment-noti.true")}</Radio.Button>
                                <Radio.Button value={false}><Icons.CloseOutlined style={{marginRight: "0.5rem"}}/>{t("payment-noti.false")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}