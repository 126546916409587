import { Button, Card, Col, DatePicker, Descriptions, Divider, Form, Icons, Input, InputNumber, Radio, RadioChangeEvent, Row, Select, SelectProps, Table, TextField, Tooltip } from "@pankod/refine-antd";
import { useTranslate } from "@pankod/refine-core";

import React from 'react';

interface IPANEL {
    dataLoad : any
    key: any
}

export const EditOldModalContent: React.FC<IPANEL> = ({ dataLoad, key }) => {
    const t = useTranslate();

    console.log('dataLoad in component : ', dataLoad)

    let dataLoad2 = dataLoad?.pet_maximum_by_type;

    let updatedPetComponents = dataLoad2?.map((data:any, index:any) => (
        <div>
            <Row>
                <Col span={6}>
                    <div style={{marginTop: "5px"}}>
                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{data?.key}{". "}{data?.pet_type_name}</span>
                    </div>
                </Col>
            </Row>
            {
                data?.pet_limit?.map((data_limit:any, limitIndex:any) => (
                    <span>
                    <Row>
                        <Col span={6}></Col>
                        <Col span={1}>
                            <div style={{marginTop: "5px"}}>
                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t(data_limit?.size)}</span>
                            </div>
                        </Col>
                        <Col span={4}>
                            <Form.Item 
                                className="visible-label"
                                name={`pet_size_${data_limit?.size.toLowerCase()}_${data?.pet_type_id}`}
                                style={{marginBottom: 2}}
                                initialValue={data_limit?.max}
                                // initialValue={data_limit?.max > 0 ? data_limit?.max : 0}
                                // value={data_limit?.max}
                            >
                                <InputNumber style={{width:'90%'}} min={0} defaultValue={data_limit?.max} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <div style={{marginTop: "5px"}}>
                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                            </div>
                        </Col>
                    </Row>
                    </span>
                ))
            }
            <Row>
                <Col span={6}></Col>

                <Col span={3}>
                    <div style={{marginTop: "5px"}}>
                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("มีค่าใช้จ่าย")}</span>
                    </div>
                </Col>
                <Col className="gutter-row" span={10}>
                    <Form.Item 
                        name={`is_charge_${data?.pet_type_id}`}
                        className="visible-label"
                        style={{marginBottom: 0}}
                        initialValue={data?.is_charge}
                        // initialValue={false}
                    >
                        <Radio.Group 
                            // defaultValue={data?.is_charge}
                            // value={data?.is_charge}
                            // onChange={(e: any) => setisCharge(e?.target?.value)}
                        >
                            <Radio value={false}>{t("ไม่มี")}</Radio>
                            <Radio value={true}>{t("มี")}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Divider />
        </div>
    ))

    return <>
        {
            dataLoad2?.map((data:any, index:any) => (
                <div>
                    <Row>
                        <Col span={6}>
                            <div style={{marginTop: "5px"}}>
                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{data?.key}{". "}{data?.pet_type_name}</span>
                            </div>
                        </Col>
                    </Row>
                    {
                        data?.pet_limit?.map((data_limit:any, limitIndex:any) => (
                            <span>
                            <Row>
                                <Col span={6}></Col>
                                <Col span={1}>
                                    <div style={{marginTop: "5px"}}>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t(data_limit?.size)}</span>
                                    </div>
                                </Col>
                                <Col span={4}>
                                    <Form.Item 
                                        className="visible-label"
                                        name={`pet_size_${data_limit?.size.toLowerCase()}_${data?.pet_type_id}`}
                                        style={{marginBottom: 2}}
                                        // initialValue={data_limit?.max}
                                        // initialValue={data_limit?.max > 0 ? data_limit?.max : 0}
                                        // value={data_limit?.max}
                                    >
                                        <InputNumber style={{width:'90%'}} min={0} defaultValue={data_limit?.max} />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <div style={{marginTop: "5px"}}>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                    </div>
                                </Col>
                            </Row>
                            </span>
                        ))
                    }
                    <Row>
                        <Col span={6}></Col>
        
                        <Col span={3}>
                            <div style={{marginTop: "5px"}}>
                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("มีค่าใช้จ่าย")}</span>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={10}>
                            <Form.Item 
                                name={`is_charge_${data?.pet_type_id}`}
                                className="visible-label"
                                style={{marginBottom: 0}}
                                // initialValue={data?.is_charge}
                                // initialValue={false}
                            >
                                <Radio.Group 
                                    // defaultValue={data?.is_charge}
                                    // value={data?.is_charge}
                                    // onChange={(e: any) => setisCharge(e?.target?.value)}
                                >
                                    <Radio value={false}>{t("ไม่มี")}</Radio>
                                    <Radio value={true}>{t("มี")}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider />
                </div>
            ))
        }
    </>

    return ( updatedPetComponents )
}