/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, message, Radio, RadioChangeEvent, RcFile, Row, Upload, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import { IEventlog, IFacilityCondition } from "interfaces";
import { useState } from "react";

export const FacilitiesCondiCreate: React.FC<IResourceComponentsProps> = () => {

    const userQuery                         = useGetIdentity();
    const {data : user_data ,isLoading}     = userQuery;
    // const { mutate: CreateLog }             = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    const API_URL                           = useApiUrl();
    // const { Option }                        = Select;
    const { TextArea }                      = Input;
    const t                                 = useTranslate();
    // const [ vReasonRejectOwner, setReasonRejectOwner ] = useState<any>(queryResult.data?.data.rejection_owner ? queryResult.data?.data.rejection_owner : "");
    const [vStat, setStat]                  = useState('N');
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const { formProps, saveButtonProps }    = useForm<IFacilityCondition,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            // console.log(data?.data);
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            // recordData.data.id + ""
            // CreateLog({
            //     resource    : log_endpoint,
            //     values      : {
            //         // user_profile            : user.id,
            //         user_profile            : user_data.user_profile.id,
            //         log_time_stamp          : Date.now(), 
            //         log_event               : "Create",
            //         menu_event              : 'facility-conditions',
            //         SysPkID                 : recordData.data.id + ""
            //     },
            //     successNotification : false

            // });
        },
    });

    function fileChange(e:any){
        // console.log(e);
        if(e.file.response){
            let res = e.file.response;
            // console.log(res[0]);
            //setPhotoList(res[0]);
        }
    }

    const optionsRdioOwner = [
        { label: 'ACTIVE', value: 'N' },
        { label: 'INACTIVE', value: 'Y' },
    ];

    const onChangeOwner = ({ target: { value } }: RadioChangeEvent) => {
        console.log('status', value);
        setStat(value);
        // setvalueRadio(value)
    };

    // const [valueRadio, setvalueRadio] = useState<any>();

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
      };

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                // title={t("facility-condition.create")}
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("facility-condition.create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />

            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id;
                    values.deleteStatus = vStat;
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                {/* <Col className="gutter-row" span={6}>
                                    <Form.Item  label={t("facility-condition.code")} name="code"
                                        rules={[
                                            {
                                                required: true, message: 'please enter code.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={18}>
                                    <Form.Item  label={t("fclt-type.name")} name="name"
                                        rules={[
                                            {
                                                required: true, message: 'please enter name.'
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col> */}

                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("facility-condition.desc")} name="description"
                                        rules={[
                                            {
                                                required: true, message: 'please enter description.'
                                            },
                                        ]}
                                    >
                                        <TextArea style={{height: "30px", maxHeight: "150px"}}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("facility-condition.desc_en")} name="description_en"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter description eng.'
                                            },
                                        ]}
                                    >
                                        <TextArea style={{height: "30px", maxHeight: "150px"}}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item  label={t("facility-condition.status")} name="deleteStatus">
                                        <Radio.Group 
                                            options={optionsRdioOwner}
                                            onChange={onChangeOwner}
                                            
                                            defaultValue={vStat}
                                            
                                            value={vStat}
                                            optionType="button"
                                            style={{width: "35%"}}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style=
                            {
                                {
                                    backgroundColor: "#fff",
                                    borderRadius: 5, 
                                    marginBottom: "5%",
                                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" 
                                }
                            }
                        >
                            <Form.Item
                                name="condition_pix"
                                label={t("facility-condition.icnpix")}
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                // rules={[
                                //     {
                                //         required: true, message: 'please upload image.'
                                //     },
                                // ]}
                            >
                                <Upload.Dragger
                                    style={{borderRadius: 10}}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Upload.Dragger>
                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB 100 x 100 px</p> */}
                            </Form.Item>
                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB 100 x 100 px</p>

                            {/* <p style={{textAlign: "left",color: "#DF6677"}}>Only Size is 100 x 100 px</p> */}
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>   
    );
};