/* eslint-disable */
import { Show, TextField, Typography, Image, List, Space, Breadcrumb, Row, Col, Card, Collapse, Divider, Tag } from "@pankod/refine-antd";

import { IFacilityBan, IUserhunit } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useList, useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";

import { PhoneOutlined } from "@ant-design/icons";

import Moment from 'moment';
// import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";

// import Moment from 'moment';

export const FacilitiesBanShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IFacilityBan>({  
        metaData: {
            populate: ['property','unit','user_profile','user_profile.address1_amphure','user_profile.address1_district','user_profile.address1_provice'
            ,'user_profile.address2_amphure','user_profile.address2_district','user_profile.address2_provice','user_profile.gender','user_profile.avatar','createBy','updateBy']
    }, 
});
    const { data, isLoading } = queryResult;
    const record = data?.data;
    //console.log(record);
    // const llat = String(record?.lat)
    // const llng =String(record?.lng)
    const { Text } = Typography;

    let recordData:any = []
    recordData = data?.data? data?.data : [];

    const UserHasUnit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile','user_profile.address1_amphure','user_profile.address1_district','user_profile.address1_provice'
            ,'user_profile.address2_amphure','user_profile.address2_district','user_profile.address2_provice',,'user_profile.avatar'],
        },
        config:{
            // sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : recordData ? recordData.unit ?.id + "" : <></>
                },
            ]
        }
    });
    const { data: Datauserhunit } = UserHasUnit;
    let userhunitData:any = []
    userhunitData = Datauserhunit?.data? Datauserhunit?.data : [];

    // console.log("userhunitData",userhunitData)

    // console.log(">>>>> faciUnit",faciUnitTypeData)
    const { Panel } = Collapse;

    const [count, setCount] = useState(0)
    const [Add1, setAdd1] = useState<any>();
    const [Add2, setAdd2] = useState<any>();
    const [vBanby, setBanby] = useState<any>();
    const [vGender, setGender] = useState<any>();
    // console.log('vGender', vGender)
    useEffect(() => {
        // hlat == record?.lat
        // hlng == record?.lng
        if (count < 3) {
          const interval = setInterval(() => {
            setCount(prev => prev + 1)

            if(recordData?.user_profile !== null){
                setGender( recordData?.user_profile.gender == "M" ? "MALE":"FEMALE" )
            }
            
            setBanby(recordData?.createBy !== null && recordData?.createBy !== undefined? 
                recordData?.createBy.first_name !== null && recordData.createBy.last_name !== null ? 
                recordData.createBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ")
            setAdd1(recordData.user_profile.address1_addr_no !== null && recordData.user_profile.address1_addr_no !== undefined ? true:false)
            setAdd2(recordData.user_profile.address2_addr_no !== null && recordData.user_profile.address2_addr_no !== undefined ? true:false)
          }, 1000)
          return () => clearInterval(interval)
        }
    }, [count])

    // console.log(">>>>> recordData",recordData)
    // console.log(">>>>> banby",vBanby)

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>

        <List 
            title={t("")}
            pageHeaderProps={{ 
                extra : <></>
            }}
        />
            <Space style={{marginBottom: "3%"}}>
                <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-ban.show")}</span></Breadcrumb.Item>
                </Breadcrumb>
            </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={18}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset",marginBottom: "1%"}}>
                                    <Row>
                                        <Col className="gutter-row" span={24}>
                                            {recordData.ban_type == true ? 
                                            <>
                                                <span
                                                    style={{
                                                        marginTop: "10%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "0.1%",
                                                        paddingTop: "0.1%",
                                                        background: "#fff1f0",
                                                        fontSize: "1.5vw",
                                                        border: "2px solid #ffa39e"
                                                    }}
                                                >
                                                    <span style={{fontWeight: "600",color: "#cf1322",marginRight: "1%"}}>BAN UNIT</span>
                                                </span>
                                            </>
                                            :
                                            <>
                                                {recordData.ban_type == false ? 
                                                <>
                                                    <span
                                                        style={{
                                                            marginTop: "10%",
                                                            borderRadius: "50px", 
                                                            paddingLeft: "2%", 
                                                            paddingRight: "2%",
                                                            paddingBottom: "0.1%",
                                                            paddingTop: "0.1%",
                                                            background: "#fff1f0",
                                                            fontSize: "1.5vw",
                                                            border: "2px solid #ffa39e"
                                                        }}
                                                    >
                                                        <span style={{fontWeight: "600",color: "#cf1322",marginRight: "1%"}}>BAN USER</span>
                                                    </span>
                                                </>
                                                :
                                                <></>
                                                }
                                            </>}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            {recordData.ban_type == true ? 
                                <>
                                    <Col className="gutter-row" span={24}>
                                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "1%"}}>
                                            <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>{t("user-profiles.title")}</span>
                                            {userhunitData ?
                                                userhunitData !== undefined && userhunitData !== null ?
                                                    userhunitData.length !== 0 ? 
                                                        <>
                                                            <Collapse style={{marginTop: 10}} defaultActiveKey={[1]}>
                                                                {
                                                                    userhunitData.length !== 0 ?
                                                                        userhunitData?.map((el:any,index:any) => {
                                                                            {
                                                                                console.log(el);
                                                                            }
                                                                            return <>
                                                                                <Panel 
                                                                                    header={
                                                                                        <>
                                                                                            <TextField style={{fontSize: "0.7vw",fontWeight: "600",letterSpacing: "1px"}} value={(index+1)+" ) " + el.user_profile ? el.user_profile.first_name + " " + el.user_profile.last_name  : '-'} />
                                                                                            <span
                                                                                                style={{
                                                                                                    marginLeft: "2%",
                                                                                                    borderRadius: "50px", 
                                                                                                    paddingLeft: "1%", 
                                                                                                    paddingRight: "1%",
                                                                                                    background: "#d6c6a0",
                                                                                                }}
                                                                                            >
                                                                                                <span style={{fontSize: "0.7vw",fontWeight: "600",color: "#fff"}}>ID : <TextField style={{fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px",color: "#fff"}} value={el?.user_profile ? el?.user_profile.id : <></>} /></span>
                                                                                            </span>
                                                                                        </>
                                                                                    }
                                                                                    key={(index+1)}
                                                                                >
                                                                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                                                        <Col className="gutter-row" span={21}>
                                                                                            <div style={{ marginBottom: "1%", marginTop: "1%", fontSize: "0.7vw",color: "#000"}}>{t("properties.about")}</div>

                                                                                            {el.identity_no !== null && el.identity_no !== null  ? 
                                                                                                <>
                                                                                                    <Text style={{marginRight: "1%",fontSize: "0.7vw",color : "#000"}}>{t("user-profiles.nid")}</Text><TextField  style={{fontSize: "0.7vw",color : "#000"}} value={el?.user_profile ? el?.user_profile.identity_no : null} />
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {el.identity_passport !== null && el.identity_passport !== undefined ? 
                                                                                                    <>
                                                                                                        <Text style={{marginRight: "1%",fontSize: "0.7vw",color : "#000"}}>{t("user-profiles.pid")}</Text><TextField  style={{fontSize: "0.7vw",color : "#000"}} value={el?.user_profile ? el?.user_profile.identity_passport : null} />
                                                                                                    </>
                                                                                                    :
                                                                                                    <></>}
                                                                                                </>
                                                                                            }

                                                                                            <div>
                                                                                            <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.gender")} </Text>
                                                                                            <TextField style={{fontSize: "0.7vw",color: "#000"}} 
                                                                                                value={ 
                                                                                                    el ?
                                                                                                        el?.user_profile.gender == "M" ? "MALE":"FEMALE"
                                                                                                    : <>-</>
                                                                                                } 
                                                                                            />
                                                                                            </div>

                                                                                            <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw",color: "#000"}}>{t("user-profiles.address1")}</span></div>

                                                                                            {el.user_profile.address1_addr_no !== null && el.user_profile.address1_addr_no !== undefined ? 
                                                                                                <>
                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.address_no")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address1_addr_no : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.road")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address1_road : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.soi")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address1_soi : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.provice")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address1_provice?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.amphure")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address1_amphure?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.district")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address1_district?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                                                                    
                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.post-code")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address1_district?.zip_code : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                                                                </>
                                                                                                :
                                                                                                <><Text style={{fontSize: "0.7vw",color: "#000"}}>NO DATA</Text></>}

                                                                                            <Divider />
                                                                                            <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw",color: "#000"}}>{t("user-profiles.address2")}</span></div>

                                                                                            {el.user_profile.address2_addr_no !== null && el.user_profile.address2_addr_no !== undefined ? 
                                                                                                <>
                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.address_no")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address2_addr_no : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.road")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address2_road : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.soi")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address2_soi : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.provice")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address2_provice?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.amphure")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address2_amphure?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.district")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address2_district?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                                                                    
                                                                                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.post-code")} </Text>
                                                                                                    <TextField value={el?.user_profile ? el?.user_profile.address2_district?.zip_code : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                                                                </>
                                                                                                :
                                                                                                <><Text style={{fontSize: "0.7vw",color: "#000"}}>NO DATA</Text></>}

                                                                                            <div style={{marginTop: "3%",marginBottom: "1%"}}><span style={{background: "#40495F",borderRadius: "20px",padding: "1% 3% 1%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={el?.user_profile ? el?.user_profile.tel : '-'} /></span></div>
                                                                                         </Col>

                                                                                         <Col className="gutter-row" span={3}>
                                                                                            {
                                                                                                el ?.user_profile ?
                                                                                                <>
                                                                                                    <Image 
                                                                                                        style={{borderRadius: "10px",border:"3px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)"}}
                                                                                                        src={el?.avatar == null ? "https://joeschmoe.io/api/v1/random" :el?.avatar.url} 
                                                                                                    />
                                                                                                </>:<>-</>
                                                                                            }
                                                                                         </Col>
                                                                                    </Row>
                                                                                </Panel>
                                                                            </>
                                                                        })
                                                                    :
                                                                        <></>
                                                                }
                                                            </Collapse>
                                                            </>
                                                        :
                                                        <></>:null:null
                                            }
                                        </Card>
                                    </Col>
                                </>
                                :
                                <>
                                    {recordData.ban_type == false ? 
                                    <>
                                        <Col className="gutter-row" span={24}>
                                            <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "1%"}}>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    <Col className="gutter-row" span={21}>
                                                        <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>{t("user-profiles.title")} : <TextField style={{fontSize: "0.9vw",fontWeight: "600",letterSpacing: "0.5px",color: "#000"}} value={record?.user_profile ? record?.user_profile.first_name : null} />
                                                            <Text> </Text>
                                                            <TextField style={{fontSize: "0.9vw",fontWeight: "600",letterSpacing: "0.5px",color: "#000"}} value={record?.user_profile ? record?.user_profile.last_name : null} />
                                                        </span>

                                                        {/* id zone */}
                                                        <span
                                                            style={{
                                                                marginTop: "10%",
                                                                marginLeft: "2%",
                                                                borderRadius: "50px", 
                                                                paddingLeft: "1%", 
                                                                paddingRight: "1%",
                                                                paddingBottom: "0.5%",
                                                                paddingTop: "0.5%",
                                                                background: "#d6c6a0",
                                                            }}
                                                        >
                                                            <span style={{fontSize: "0.7vw",fontWeight: "600",color: "#fff"}}>ID : <TextField style={{fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px",color: "#fff"}} value={record?.user_profile ? record?.user_profile.id : <></>} /></span>
                                                        </span>

                                                        <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw",color: "#000"}}>{t("properties.about")}</div>

                                                        {recordData.identity_no !== null && recordData.identity_no !== null  ? 
                                                            <>
                                                                <Text style={{marginRight: "1%",fontSize: "0.7vw",color : "#000"}}>{t("user-profiles.nid")}</Text><TextField  style={{fontSize: "0.7vw",color : "#000"}} value={record?.user_profile ? record?.user_profile.identity_no : null} />
                                                            </>
                                                            :
                                                            <>
                                                                {recordData.identity_passport !== null && recordData.identity_passport !== undefined ? 
                                                                <>
                                                                    <Text style={{marginRight: "1%",fontSize: "0.7vw",color : "#000"}}>{t("user-profiles.pid")}</Text><TextField  style={{fontSize: "0.7vw",color : "#000"}} value={record?.user_profile ? record?.user_profile.identity_passport : null} />
                                                                </>
                                                                :
                                                                <></>}
                                                            </>
                                                        }

                                                        <div>
                                                        <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.gender")} </Text>
                                                        <TextField style={{fontSize: "0.7vw",color: "#000"}} 
                                                            // value={record?.user_profile.gender == "M" ? "MALE":"FEMALE" }
                                                            // value={
                                                            //     recordData?.user_profile ?
                                                            //         recordData?.user_profile.gender == "M" ? "MALE":"FEMALE"
                                                            //     : <>-</>
                                                            // } 
                                                            value={vGender}
                                                        />
                                                        </div>

                                                        <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw",color: "#000"}}>{t("user-profiles.address1")}</span></div>

                                                        {Add1 == true ? 
                                                            <>
                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.address_no")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address1_addr_no : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.road")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address1_road : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.soi")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address1_soi : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.provice")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address1_provice?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.amphure")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address1_amphure?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.district")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address1_district?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                                
                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.post-code")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address1_district?.zip_code : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                            </>
                                                            :
                                                            <><Text style={{fontSize: "0.7vw",color: "#000"}}>NO DATA</Text></>}

                                                        <Divider />
                                                        <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw",color: "#000"}}>{t("user-profiles.address2")}</span></div>

                                                        {Add2 == true ? 
                                                            <>
                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.address_no")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address2_addr_no : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.road")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address2_road : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.soi")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address2_soi : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.provice")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address2_provice?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.amphure")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address2_amphure?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.district")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address2_district?.name_th : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                                
                                                                <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.post-code")} </Text>
                                                                <TextField value={record?.user_profile ? record?.user_profile.address2_district?.zip_code : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>
                                                            </>
                                                            :
                                                            <><Text style={{fontSize: "0.7vw",color: "#000"}}>NO DATA</Text></>}

                                                        <div style={{marginTop: "3%"}}><span style={{background: "#40495F",borderRadius: "20px",padding: "1% 3% 1%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={record?.user_profile ? record?.user_profile.tel : '-'} /></span></div>
                                                    </Col>

                                                    <Col className="gutter-row" span={3}>
                                                        {
                                                            recordData ?.user_profile ?
                                                            <>
                                                                <Image 
                                                                    style={{borderRadius: "10px",border:"3px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)"}}
                                                                    src={recordData?.avatar == null ? "https://joeschmoe.io/api/v1/random" :recordData?.avatar.url} 
                                                                />
                                                            </>:<>-</>
                                                        }
                                                    </Col>
                                                </Row>                    
                                            </Card> 
                                        </Col>
                                    </>
                                    :
                                    <></>
                                    }
                                </>}

                                

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "1%"}}>
                                    <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>ข้อมูลการแบน</span>
                                    <br /><br />

                                    <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>แบนวันที่ : </span>
                                    <TextField value={Moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}/>
                                    <br />

                                    <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>แบนโดย : </span>
                                    <TextField value={vBanby}/>
                                    <br />

                                    <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>ประเภทของการปลดแบน : </span>
                                    <Tag color="volcano">
                                        {recordData?.date_time == null ? "Manual":"Auto Unban"}
                                    </Tag>
                                     
                                    <br /><br />
                                    <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>สาเหตุการแบน :</span><br />
                                    <TextField value={recordData?.ban_note !== null ? recordData?.ban_note: "ไม่มีข้อมูล"} style={{fontSize: "0.9vw",color: "#000"}}/>
                                </Card>
                            </Col>

                            {/* Property zone */}
                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                                    <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>{t("property.title")} : <TextField style={{fontSize: "0.9vw",fontWeight: "600",letterSpacing: "0.5px",color: "#000"}} value={record?.property ? record?.property.property_name : <></>} /></span>

                                    {/* Unit zone */}
                                    <span
                                        style={{
                                            marginTop: "10%",
                                            marginLeft: "2%",
                                            borderRadius: "50px", 
                                            paddingLeft: "1%", 
                                            paddingRight: "1%",
                                            paddingBottom: "0.5%",
                                            paddingTop: "0.5%",
                                            background: "#d6c6a0",
                                        }}
                                    >
                                        <span style={{fontSize: "0.7vw",fontWeight: "600",color: "#fff",marginRight: "1%"}}>{t("unit.title")} : <TextField style={{fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px",color: "#fff"}} value={record?.unit ? record?.unit.unit_name : <></>} /></span>

                                        <Text style={{fontSize: "0.7vw",fontWeight: "600",color: "#fff",marginRight: "0.5%"}}>(</Text>
                                        <TextField value={record?.unit ? record?.unit.unit_code : <></>} style={{fontSize: "0.7vw",color: "#fff",fontWeight: "600"}}/>
                                        <Text style={{fontSize: "0.7vw",fontWeight: "600",color: "#fff",marginLeft: "0.5%"}}>)</Text>
                                    </span>

                                    <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw",color: "#000"}}>{t("phonebook.address")}</span></div>

                                    <TextField value={record?.property ? record?.property.address : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("phonebook.city")} </Text>
                                    <TextField value={record?.property ? record?.property.city : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("phonebook.province")} </Text>
                                    <TextField value={record?.property ? record?.property.province : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("phonebook.post_code")} </Text>
                                    <TextField value={record?.property ? record?.property.postcode : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                    <Text style={{fontSize: "0.7vw",color: "#000"}}>{t("phonebook.fax")} </Text>
                                    <TextField value={record?.property ? record?.property.fax : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                    <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw",color: "#000"}}>{t("properties.about")}</div><TextField style={{fontSize: "0.7vw",color: "#000"}} value={record?.property ? record?.property.about : <></>} />

                                    <div style={{marginTop: "3%"}}><span style={{background: "#40495F",borderRadius: "20px",padding: "1% 3% 1%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={record?.property ? record?.property.tel : '-'} /></span></div>     
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="gutter-row" span={6}>
                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>
                                                        
                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
        </Show>
    );
};