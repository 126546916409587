/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, Divider, EditButton, RcFile, UploadFile } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY } from "../../../constants";
import { ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

import axios from "axios";
import { DatePicker, message, Spin, Table, Tag, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { majorCareUrl } from "../../../constants";
import Dragger from "antd/lib/upload/Dragger";

{/* 
    6485ba66049e59df2850eb9e = รอดำเนินการ
    6485bcda049e59df2850eba0 = รับเรื่อง
    6485bcf9049e59df2850eba2 = รอตรวจ
    6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
    6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
    6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
    6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
    6485bd8b681aef755a4f279f = รอนัดหมาย
    6485bd96681aef755a4f27a1 = หมดอายุ
*/}

export const MjcInspecEditChecklistt: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data } = userQuery;

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);
    const { create, edit } = useNavigation();

    const token = localStorage.getItem(TOKEN_KEY);


    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let job_id = id[1]

    // console.log('id kkkkkkkkkkkkkkk', id)

    // console.log('asdasdasdas',decodeURIComponent((id[2]+'').replace(/\+/g, '%20')));
    // console.log('zxczxczxczx',decodeURIComponent((id[3]+'').replace(/\+/g, '%20')));

    let structureName = decodeURIComponent((id[2]+'').replace(/\+/g, '%20'))
    let zoneName = decodeURIComponent((id[3]+'').replace(/\+/g, '%20'))


    const [StatOfJob, SetStatOfJob] = useState<any>();
    const [StatOfChecklist, SetStatOfChecklist] = useState<any>();
    const [JobIDHM, SetJobIDHM] = useState<any>();

    const [vImgEn, setImgEn] = useState<any>();
    const [vVid, setVid] = useState<any>();
    const [vVidId, setVidId] = useState<any>([]);
    const [ImgId, SetImgId] = useState<any>([]);
    const [Remark, SetRemark] = useState<any>();
    const [ChecklistName, SetChecklistName] = useState<any>();
    const [isLoading, setIsLoading] = useState<any>(true);
   


    const getDataK = async ()=>{
        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjob?id=648ab8ca326b1b890c5cf5ec
        let res_data_job = await axios.get(
            majorCareUrl + `/majorcare/findOneHomecheckjob?id=` + job_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad', dataLoad[0])
                // console.log('dataLoad[0]', dataLoad[0])
                SetStatOfJob(dataLoad[0].status)

                SetJobIDHM(dataLoad[0].job_id) 
                // console.log('Type', dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes)
            });


        // let res_data_trail = await axios.get(
        //     majorCareUrl + `/majorcare/findTrailByJob?jid=` + item_id ,
        //     { headers: {  } }).then((res) => {
        //         let dataTrail: any = res?.data;
        //         console.log('dataTrail', dataTrail[0])
        //         // console.log('Type', dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes)

        //         SetInpectorName(dataTrail[0].assign ? dataTrail[0].assign.attributes.user_profile ? dataTrail[0].assign.attributes.user_profile.data.attributes.first_name + ' ' + dataTrail[0].assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )
        //     });


        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjobChecklist?id=648eb1ba57868f3044b6aeb3
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findOneHomecheckjobChecklist?id=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad checklist ', dataLoad[0])
                // console.log('dataLoad status ', dataLoad[0].is_status)
                SetStatOfChecklist(dataLoad[0].is_status);

                SetChecklistName(dataLoad[0].item_detail[0].item_name)
                // console.log('dataLoad[0]', dataLoad[0])
                // console.log('Type', dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes)
                SetRemark(dataLoad[0].remark)
                // console.log('dataLoad[0].remark', dataLoad[0].remark)
                // SetImgId((pre:any) => ([
                //     // ...pre,
                //     {
                //         id: dataLoad[0]?.pic.id,
                //         url: dataLoad[0]?.pic.url,
                //     }
                // ]))

                for (let index = 0; index < dataLoad[0]?.pic?.length; index++) {
                    ImgId.push(
                        {
                            id: dataLoad[0]?.pic[index].id,
                            url: dataLoad[0]?.pic[index].url,
                        }
                    )
                }

                const fileList_EN: UploadFile[] =
                    dataLoad?.length > 0 && dataLoad[0]?.pic?.length > 0 ? dataLoad[0]?.pic.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: 'picture',
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];
                    
                setImgEn({
                    name: 'files',
                    multiple: true,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.pic?.length > 0 ? [...fileList_EN] : [],
                })
                form.resetFields(["pic2"]);


                // ==================== VID ====================
                // const fileListVid: UploadFile[] =
                // dataLoad?.length > 0 && dataLoad[0]?.vdo ? 
                //     // dataLoad[0]?.vdo.map((v: any) => (
                //         [
                //             {
                //                 id: dataLoad[0]?.vdo?.id,
                //                 uid: String(dataLoad[0]?.vdo?.id),
                //                 name: 'video',
                //                 status: "done",
                //                 url: dataLoad[0]?.vdo?.url,
                //                 thumbUrl: dataLoad[0]?.vdo?.url
                //             }
                //         ]
                       
                //     // ))
                // : 
                //     [];
                const fileListVid:any= []

                if (dataLoad[0]?.vdo?.id && dataLoad[0]?.vdo?.url) {
                    fileListVid.push({
                      id: dataLoad[0]?.vdo?.id,
                      uid: String(dataLoad[0]?.vdo?.id),
                      name: 'video',
                      status: "done",
                      url: dataLoad[0]?.vdo?.url,
                      thumbUrl: dataLoad[0]?.vdo?.url
                    });
                  }

                
                setVid({
                    name: 'files',
                    multiple: false,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.vdo  ? [...fileListVid] : [],
                })
                form.resetFields(["pic"]);

                setIsLoading(false)

            });
    }

    // -  กรณีงานเกินกำหนดตามวันที่ สิ้นสุด ต้องมี job สำหรับอัพเดต trail เป็น เข้าซ่อม-เกินกำหนด

    const saveData = async ()=>{
        {/* 
            6485ba66049e59df2850eb9e = รอดำเนินการ
            6485bcda049e59df2850eba0 = รับเรื่อง
            6485bcf9049e59df2850eba2 = รอตรวจ
            6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
            6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
            6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
            6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
            6485bd8b681aef755a4f279f = รอนัดหมาย
            6485bd96681aef755a4f27a1 = หมดอายุ
        */}

        updateChecklist();

        // console.log('StatOfJob', StatOfJob)
        // console.log('job_id', job_id)

        // ถ้า StatOfJob !== 6485bd3a681aef755a4f2797 ให้เปลี่ยน stat เป็นเข้าตรวจหน้างาน แล้วสร้าง trail
        // if( StatOfJob !== "6485bcf9049e59df2850eba2" || StatOfJob == "6485bd7d681aef755a4f279d" ){ // รอตรวจ / เสร็จสมบูรณ์
            // if(StatOfJob !== "6485bd3a681aef755a4f2797" && StatOfJob !== "6485bd7d681aef755a4f279d"){ // เข้าตรวจหน้างาน
            if(StatOfJob == "6485bcf9049e59df2850eba2"){ // รอตรวจ
                console.log('here')

                let data = {
                    "status": "6485bd3a681aef755a4f2797",  // เข้าตรวจหน้างาน
                };
                let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + job_id, data, { headers: { } });
                console.log('res', res)
    
                let dataTrail = {
                    "home_check_job" : job_id,
                    "status": "6485bd3a681aef755a4f2797", // เข้าตรวจหน้างาน
                    "appoint_start": "",
                    "appoint_end": "",
                    "assign": {
                        "name":"",
                        "lastname":""
                    },
                    "sla": {
                        "sla": "",
                        "sla_time": ""
                    },
                    "remark": "",
                    // "remark": Remark !== undefined ? Remark : "",
                    "create_by": Identity_data?.user_profile,
                    // "create_date": dayjs()
                    // "create_date": dayjs().format("DD/MM/YY HH:mm")
                }
                let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
                console.log('res_trail', res_trail)
            }
        // }
        
        // replace(`/mjc-inspec-today-job/edit/${job_id}_${JobIDHM}`)
        replace(`/mjc-inspec-inprogress-job/edit/${job_id}_${JobIDHM}`)
    }

    // console.log('vImgEn', vImgEn)

    const updateChecklist = async () => {
        // http://localhost:10000/api/majorcare/updateHomecheckjobChecklist?id=xxxxx

        // "pic": [],
        // "vdo": {"id":1,"url":"https:///xxxxx"},

        // let data = {
        //     "pic": ImgId,
        //     "vdo": {
        //         "id": "k_test",
        //         "url": "k_test"
        //     },
        //     "remark": Remark
        // }
        let data = {
            "pic": ImgId,
            "vdo": vVidId[0],
            "remark": Remark,
            "update_date": dayjs()
        }
        let res_data_img = await axios.put(
            majorCareUrl + `/majorcare/updateHomecheckjobChecklist?id=` + item_id , data ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                console.log('res', res)
                // let dataLoad: any = res?.data;
                // //console.log('dataLoad Check', dataLoad)
            }
        );

    }


    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                    getDataK();

                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    // useEffect(() => {
    //     getDataK();
    // }, []);

    const [form] = Form.useForm();
    // console.log('checkedSms', checkedSms);


    async function sendToCusApprove(){
        // console.log('id : ', id)

        let data = {
            "status": "6485bd6e681aef755a4f279b",  // รออนุมัติผลการตรวจ
        };
        let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + id, data, { headers: { } });

        console.log('res', res)


        let dataTrail = {
            "home_check_job" : item_id,
            "status": "6485bd6e681aef755a4f279b", // รออนุมัติผลการตรวจ
            "appoint_start": "",
            "appoint_end": "",
            "assign": {
                "name":"",
                "lastname":""
            },
            "sla": {
                "sla": "",
                "sla_time": ""
            },
            "remark": "",
            "create_by": Identity_data?.user_profile,
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
        console.log('res_trail', res_trail)

        setCountInterval(0)
        // setDataList([])

        // successNoti('200', 'success');
    }

    function fileChange_EN(e: any) {
        console.log('event', e)
        if(e.file.status === "removed") {
            // setImgEn((pre:any) => ({
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: [],
            // }));

            SetImgId((prev:any) => prev.filter((item:any) => item.id !== e.file.id));

            if(vImgEn.defaultFileList.length == 1){
                setImgEn((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: []
                }));
            }else{
                setImgEn((prev: any) => ({
                    ...prev,
                    name: 'files',
                    multiple: true,
                    defaultFileList: prev.defaultFileList.filter((file: any) => file.id !== e.file.id)
                }));
            }
            // setImgEn((prev: any) => ({
            //     ...prev,
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: prev.defaultFileList.filter((file: any) => file.id !== e.file.id)
            // }));

            form?.resetFields(["pic2"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                SetImgId((pre:any) => ([
                    ...pre,
                    {
                        id: res?.id,
                        url: res?.url,
                    }
                ]))
                setImgEn((pre:any) => ([
                    // ...pre,
                    {
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }]
                ))
            }
        }
    }

    function fileChange_vid(e: any) {
        console.log('event vid', e)
        if(e.file.status === "removed") {
            // setImgEn((pre:any) => ({
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: [],
            // }));

            // setVidId((prev:any) => prev.filter((item:any) => item.id !== e.file.id));
            setVidId([]);

            setVid((prev: any) => ({
                ...prev,
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));

            form?.resetFields(["pic"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setVidId((pre:any) => ([
                    {
                        id: res?.id,
                        url: res?.url,
                    }
                ]))
                setVid((pre:any) => ([{
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }]
                ))
            }
        }
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }

        console.log('isJpgOrPng', isJpgOrPng)
        console.log('isLt2M', isLt2M)

        // if(isJpgOrPng && isLt2M){
        //     return Dragger.ignore
        // }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const beforeUploadVid = (file: RcFile) => {
        const isJpgOrPng = file.type === 'video/mp4' || file.type === 'video/quicktime';
        if (!isJpgOrPng) {
            message.error('You can only upload MP4 file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 25;
        if (!isLt2M) {
            message.error('Image must smaller than 25MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    // console.log('StatOfJob', StatOfJob)
    return (
    <Spin size="large" spinning={isLoading}>

    <Edit 
        saveButtonProps={{ hidden: true }}
        title="Edit Checklist"
        pageHeaderProps={{
            extra : <></>
        }}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Edit Checklist</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    updateChecklist()
                }}
            >
                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="Zone"
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Input value="ห้องนอน" disabled/> */}
                            <Input value={zoneName} defaultValue={zoneName} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {/* Structure */}
                        <Form.Item 
                            label="Structure"
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Input value="ประตูทางเข้า" disabled/> */}
                            <Input value={structureName} defaultValue={structureName} disabled/>

                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label="Checklist"
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Input value="ความปกติของอุปกรณ์" disabled/> */}
                            <Input value={ChecklistName} defaultValue={ChecklistName} disabled/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        {/* สถานะการตรวจ: */}
                        <Form.Item 
                            label="สถานะการตรวจ"
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Radio.Group 
                                // onChange={onChangeRadioNew}
                                name="radiogroup"
                                // rules={[{required: true, message: 'Please Select Type.'},]}
                                value={StatOfChecklist}
                                defaultValue={StatOfChecklist}
                                disabled
                            >
                                <Radio value={true}>ปกติ</Radio>
                                <Radio value={false}>ไม่ปกติ</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        {/* เงื่อนไขการรับประกัน: */}
                        <Form.Item 
                            label="เงื่อนไขการรับประกัน"
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Radio.Group 
                                // onChange={onChangeRadioNew}
                                name="radiogroup"
                                // rules={[{required: true, message: 'Please Select Type.'},]}
                                value={true}
                                defaultValue={true}
                                disabled
                            >
                                <Radio value={true}>อยู่ในประกัน</Radio>
                                <Radio value={false}>ไม่ปกติ</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        
                    </Col>
                </Row>

                {
                    !StatOfChecklist &&
                    <>
                        <Row>
                            <Col span={8}>
                                {/* รูปประกอบ: */}
                                <Form.Item
                                    label="รูปประกอบ"
                                    name="pic2"
                                    // rules={[
                                    //     {
                                    //         required: true, message: 'Please upload PDF.'
                                    //     },
                                    // ]}
                                >
                                    <Upload.Dragger 
                                        style={{ borderRadius: "10px" }}
                                        {...vImgEn}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        maxCount={5}
                                        beforeUpload={beforeUpload}
                                        onChange={fileChange_EN}
                                    >
                                        <p>Upload</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                            </Col>
                            <Col span={8}>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    label="ไฟล์วิดีโอ"
                                    name="pic"
                                >
                                    <Upload.Dragger 
                                        style={{ borderRadius: "10px" }}
                                        {...vVid}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        maxCount={1}
                                        multiple={false}
                                        beforeUpload={beforeUploadVid}
                                        onChange={fileChange_vid}
                                    >
                                        <p>Upload</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                            </Col>
                            <Col span={8}>
                            </Col>
                        </Row>
                    </>
                }
                

                <Row>
                    <Col span={12}>
                        {/* หมายเหตุ: */}
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{ width: "100%" }}
                            // rules={[{ required: true, message: 'Please enter title.' },]}
                        >
                            <TextArea style={{ height: "130px", maxHeight: "80px" }}
                                value={Remark}
                                defaultValue={Remark}
                                onChange={(e) => SetRemark(e.target.value)}
                                disabled={StatOfJob == "6485bd7d681aef755a4f279d" ? true : false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                    </Col>
                    {/* <Col span={8}>
                    </Col> */}
                </Row>

                <Row style={{float: 'right'}}>
                    <Button type="primary" onClick={() => saveData()} icon={<Icons.SaveOutlined />}>Save</Button>
                </Row>
            </Form>
    </Edit>   
    </Spin>

    );
};