/* eslint-disable */
import { List, Table, TextField, EditButton, Tag, TableProps, Button, Row, Col, Form, Select, useSelect, Icons, useForm, Input } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useGetIdentity, useList, useNavigation, useTranslate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import { majorCareUrl } from "../../../constants";
import dayjs from "dayjs";
import { IUnit } from "interfaces";

 {/* 
    6485ba66049e59df2850eb9e = รอดำเนินการ
    6485bcda049e59df2850eba0 = รับเรื่อง
    6485bcf9049e59df2850eba2 = รอตรวจ
    6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
    6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
    6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
    6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
    6485bd8b681aef755a4f279f = รอนัดหมาย
    6485bd96681aef755a4f27a1 = หมดอายุ
*/}

let unitdata:any = undefined;
export const MjcInspecTodayJobList: React.FC<IResourceComponentsProps> = () => {

    // const t = useTranslate();
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const { formProps } = useForm<any,HttpError,{}>()
    const { Option } = Select;
    const { edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(0);
    const [dataList, setDataList] = useState<any>([]);
    const [valueProp, setProp] = useState<any>();
    const [valuePropLocal, setPropLocal] = useState<any>();
    const [valueUnit, setUnit] = useState<any>();
    const dataBlank:any = [];

    const [dataProperty, setdataProperty] = useState<any>([]);
    const [dataTemplate, setdataTemplate] = useState<any>();
    const [dataTemplateLocal, setdataTemplateLocal] = useState<any>();

    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomRight');
    const callfetchDT = useList<any>({ 
        resource: "user-profiles", 
        metaData: { populate: '*' },
        queryOptions:{
            async onSuccess(data) {
                const today = dayjs().format("YYYY-MM-DD")
                // const today = new Date().toISOString().split('T')[0];
                let loaddataProperty = await axios.get(majorCareUrl + "/majorcare/findAllProperty" , { headers: { } });
                let loaddataTemplate = await axios.get(majorCareUrl + "/majorcare/findHomecheckjobByStat" , { headers: { } });

                // console.log('loaddataTemplate', loaddataTemplate)
                console.log('today', today)

                if(prop_list){
                    let newProp: any = [];
                    let dataTemplateActive = loaddataTemplate?.data?.filter((f: any) => (
                        (f?.status == "6485bcf9049e59df2850eba2" || f?.status == "6485bd3a681aef755a4f2797") &&
                        f?.appoint_start?.startsWith(today)
                    ));
                    let newDataforTemplate:any = [];

                    console.log(">>> dataTemplateActive", dataTemplateActive)
        
                    for (let i = 0; i < prop_list?.length; i++) {
                        let findProp = loaddataProperty?.data?.findIndex((f: any)=> f?.property_id == prop_list[i]);
        
                        if(findProp !== -1){
                            newProp.push({
                                id: loaddataProperty?.data[findProp]?._id,
                                property_id: loaddataProperty?.data[findProp]?.property_id,
                                property_name: loaddataProperty?.data[findProp]?.property_name,
                                property_name_en: loaddataProperty?.data[findProp]?.property_name_en,
                                is_active: loaddataProperty?.data[findProp]?.is_active,
                            });
                        }
                    }
        
                    if(dataTemplateActive?.length > 0){
                        for (let index = 0; index < dataTemplateActive?.length; index++) {
                            for (let i = 0; i < newProp?.length; i++) {
                                if(dataTemplateActive[index]?.property == newProp[i]?.id){
                                    let findC = newDataforTemplate?.find((f: any) => f?.id == dataTemplateActive[index]?._id);
                                    if(findC == undefined){
                                        newDataforTemplate.push({
                                            id: dataTemplateActive[index]?._id,
                                            job_id: dataTemplateActive[index]?.job_id,
                                            property: 
                                            {
                                                cid: dataTemplateActive[index]?.property,
                                                id: dataTemplateActive[index]?.request_by?.unitDetail?.property_id,
                                                property_name: dataTemplateActive[index]?.request_by?.unitDetail?.property_name,
                                                property_name_en: dataTemplateActive[index]?.request_by?.unitDetail?.property_name_en,
                                            },
                                            unit: dataTemplateActive[index]?.unit_obj?.length > 0 ? 
                                            {
                                                unit_no: dataTemplateActive[index]?.unit_obj[0]?.unit_name,
                                                id: dataTemplateActive[index]?.unit_obj[0]?.unit_id
                                            }
                                            : null,
                                            customer: dataTemplateActive[index]?.request_by?.attributes,
                                            trans_date: dataTemplateActive[index]?.request_by?.unitDetail?.transfer_date,
                                            create_date: dataTemplateActive[index]?.create_date,
                                            status: dataTemplateActive[index]?.status == "6485bcf9049e59df2850eba2" ? "รอตรวจ" : "เข้าตรวจหน้างาน",
                                        })
                                    }
                                }
                            }
                        }
                    }
        
                    setdataProperty((pre: any) => newProp);
                    setdataTemplate((pre: any) => newDataforTemplate);
                    setdataTemplateLocal((pre: any) => newDataforTemplate);
        
                }else if(prop_list == null && prop_id){
                    let newProp: any = [];
                    let dataTemplateActive = loaddataTemplate?.data?.filter((f: any) => (
                        f?.status == "6485bcf9049e59df2850eba2" || f?.status == "6485bd3a681aef755a4f2797" &&
                        f?.appoint_start.startsWith(today)
                    ));
                    let newDataforTemplate:any = [];
        
                    let findProp = loaddataProperty?.data?.findIndex((f: any)=> f?.property_id == prop_id);
        
                    if(findProp !== -1){
                        newProp?.push({
                            id: loaddataProperty?.data[findProp]?._id,
                            property_id: loaddataProperty?.data[findProp]?.property_id,
                            property_name: loaddataProperty?.data[findProp]?.property_name,
                            property_name_en: loaddataProperty?.data[findProp]?.property_name_en,
                            is_active: loaddataProperty?.data[findProp]?.is_active,
                        });
                    }
        
                    if(dataTemplateActive?.length > 0){
                        let findData = dataTemplateActive?.filter((f: any) => f?.property == newProp[0]?.id);
                        for (let index = 0; index < findData?.length; index++) {
                            newDataforTemplate.push({
                                id: findData[index]?._id,
                                job_id: findData[index]?.job_id,
                                property: 
                                {
                                    cid: findData[index]?.property,
                                    id: findData[index]?.request_by?.unitDetail?.property_id,
                                    property_name: findData[index]?.request_by?.unitDetail?.property_name,
                                    property_name_en: findData[index]?.request_by?.unitDetail?.property_name_en,
                                },
                                unit: findData[index]?.unit_obj?.length > 0 ? 
                                {
                                    unit_no: findData[index]?.unit_obj[0]?.unit_name,
                                    id: findData[index]?.unit_obj[0]?.unit_id
                                }
                                : null,
                                customer: findData[index]?.request_by?.attributes,
                                trans_date: findData[index]?.request_by?.unitDetail?.transfer_date,
                                create_date: findData[index]?.create_date,
                                status: findData[index]?.status == "6485bcf9049e59df2850eba2" ? "รอตรวจ" : "เข้าตรวจหน้างาน",
                            })
                        }
                    }
        
                    setdataProperty((pre: any) => newProp)
                    setdataTemplate((pre: any) => newDataforTemplate)
                    setdataTemplateLocal((pre: any) => newDataforTemplate);
                }else{
                    let newProp: any = [];
                    let dataTemplateActive = loaddataTemplate?.data?.filter((f: any) => (
                        f?.status == "6485bcf9049e59df2850eba2" || f?.status == "6485bd3a681aef755a4f2797" &&
                        f?.appoint_start?.startsWith(today)
                    ));
                    let newDataforTemplate:any = [];
        
                    if(loaddataProperty?.data?.length > 0){
                        for (let index = 0; index < loaddataProperty?.data?.length; index++) {
                            newProp.push({
                                id: loaddataProperty?.data[index]?._id,
                                property_id: loaddataProperty?.data[index]?.property_id,
                                property_name: loaddataProperty?.data[index]?.property_name,
                                property_name_en: loaddataProperty?.data[index]?.property_name_en,
                                is_active: loaddataProperty?.data[index]?.is_active,
                            });
                        }
                    }
        
                    if(dataTemplateActive?.length > 0){
                        for (let index = 0; index < dataTemplateActive?.length; index++) {
                            newDataforTemplate.push({
                                id: dataTemplateActive[index]?._id,
                                job_id: dataTemplateActive[index]?.job_id,
                                property: 
                                {
                                    cid: dataTemplateActive[index]?.property,
                                    id: dataTemplateActive[index]?.request_by?.unitDetail?.property_id,
                                    property_name: dataTemplateActive[index]?.request_by?.unitDetail?.property_name,
                                    property_name_en: dataTemplateActive[index]?.request_by?.unitDetail?.property_name_en,
                                },
                                unit: dataTemplateActive[index]?.unit_obj?.length > 0 ? 
                                {
                                    unit_no: dataTemplateActive[index]?.unit_obj[0]?.unit_name,
                                    id: dataTemplateActive[index]?.unit_obj[0]?.unit_id
                                }
                                : null,
                                customer: dataTemplateActive[index]?.request_by?.attributes,
                                trans_date: dataTemplateActive[index]?.request_by?.unitDetail?.transfer_date,
                                create_date: dataTemplateActive[index]?.create_date,
                                status: dataTemplateActive[index]?.status == "6485bcf9049e59df2850eba2" ? "รอตรวจ" : "เข้าตรวจหน้างาน",
                            })
                        }
                    }
        
                    setdataProperty((pre: any) => newProp);
                    setdataTemplate((pre: any) => newDataforTemplate);
                    setdataTemplateLocal((pre: any) => newDataforTemplate);
                }
            },
        }
    });

    const { selectProps: selUnit } = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 20000,
        metaData: {
            populate: '*'
        },
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field: "property][id]",
                operator: "in",
                value: valueProp
            },
        ],
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                if(valueProp !== undefined){
                    let newProp: any = [];
                    for (let index = 0; index < dataLoad?.length; index++) {
                        newProp?.push({
                            id: dataLoad[index]?.unit_name,
                            label: dataLoad[index]?.id,
                        })
                    }

                    unitdata = newProp;
                }
            },
        }
    });

    const columns:any = [
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.job-no")}</span>,
            dataIndex: 'item_name',
            key: 'item_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.job_id.localeCompare(b?.job_id),
            render: (value:any, record:any) => {
                console.log(">>> record", record)
                return (<div>{record?.job_id}</div>);
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.property")}</span>,
            key: 'name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.property_obj[0]?.property_name.localeCompare(b?.property_obj[0]?.property_name),
            render: (value:any, record:any) => {
                return (<div>{record?.property ? record?.property?.property_name: '-'}</div>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.unit-no")}</span>,
            dataIndex: 'createBy',
            key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.unit_obj[0]?.unit_name?.localeCompare(b?.unit_obj[0]?.unit_name),
            render: (val:any, record:any) => {
                return (<div>{record?.unit ? record?.unit?.unit_no : '-'}</div>);
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.customer-name")}</span>,
            // dataIndex: 'cus_name',
            key: 'cus_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.request_by?.attributes?.first_name.localeCompare(b?.request_by?.attributes?.first_name),
            render: (value:any, record:any) => {
                return (<div>{record?.customer ? record?.customer?.first_name + " " + record?.customer?.last_name : '-'}</div>);
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.trans-date")}</span>,
            dataIndex: 'transfer_date',
            key: 'transfer_date',
            align: 'center',
            sorter: (a:any, b:any) => a?.trans_date?.localeCompare(b?.trans_date),
            render: (value:any, record:any) => {
                return (<div>{record?.trans_date ? dayjs(record?.trans_date).format("DD/MM/YY") : '-'}</div>);
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.create-by")}</span>,
            dataIndex: 'create_by',
            key: 'create_by',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.create_by?.first_name < b?.create_by?.first_name) {return -1}
                if (a?.create_by?.first_name > b?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.create_by?.first_name : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : "-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.created-date")}</span>,
            dataIndex: 'createDate',
            key: 'createDate',
            align: 'center',
            sorter: (a:any, b:any) => a?.create_date.localeCompare(b?.create_date),
            render: (value:any, record:any) => {
                return (<div>{record?.create_date ? dayjs(record?.create_date).format("DD/MM/YY") : '-'}</div>);
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.update-by")}</span>,
            dataIndex: 'update_by',
            key: 'update_by',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.update_by?.first_name < b?.update_by?.first_name) {return -1}
                if (a?.update_by?.first_name > b?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : "-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.last-update")}</span>,
            dataIndex: 'update_date',
            key: 'update_date',
            align: 'center',
            sorter: (a:any, b:any) => a?.update_date.localeCompare(b?.update_date),
            render: (value:any, record:any) => {
                return (<div>{record?.update_date ? dayjs(record?.update_date).format("DD/MM/YY") : '-'}</div>);
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.mjc-inspec.status")}</span>,
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            align: 'center',
            sorter: (a:any, b:any) => a?.status.localeCompare(b?.status),
            render: (value:any, record:any) => {
                return ((record.status == "6485bcf9049e59df2850eba2" || record.status == "รอตรวจ") ?
                    <Tag className="tag_mjc_stat_wait_inspect"><span>{'รอตรวจ'}</span></Tag>
                    :
                    <Tag className="tag_mjc_stat_inspect"><span>{'เข้าตรวจหน้างาน'}</span></Tag>
                );
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 85,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            onClick={() => {
                                
                                return (edit("mjc-inspec-today-job", record?.id + "_" + record.job_id ))
                            }}
                        />
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';

    function onSearchtable(data: any, mode:any){
        let newData:any = data;
        if(mode == "S"){
            // newData = newData?.filter((e:any)=> e?.is_active == (status == "N" ? true : status == "Y" && false));
            // newData = nameSearch !== "" ? newData?.filter((e:any)=> e?.template_name?.toLowerCase().includes(String(nameSearch).toLowerCase())):newData;
            // newData = valuePropLocal !== undefined ? newData?.filter((e:any)=> {return e?.property.includes(String(selProp?.id))}):newData;
            // newData = selUnitType !== undefined ? newData?.filter((e:any)=> {return e?.unit_type.includes(String(selUnitType?.id))}):newData;
            // if(selUnitType){
            //     let filterUt: any = [];
            //     for (let f = 0; f < newData?.length; f++) {
            //         if(newData[f]?.unit_type?.length > 0){
            //             let ofilter:any = newData[f]?.unit_type?.filter((e:any)=> {return e == String(selUnitType?.id)});

            //             if(ofilter?.length > 0){
            //                 filterUt.push(newData[f])
            //             }
            //         }
            //     }

            //     newData = filterUt;
            // }

            setdataTemplate((pre: any) => newData);
        }else if(mode == "R"){
            newData = newData?.filter((e:any)=> e?.is_active == (status == "N" ? true : status == "Y" && false));
            
            // setdataTemplate((pre: any) => newData);
            // setdataUnitType((pre: any) => dataUnitTypeBASE)

            // formProps?.form?.resetFields();
            // setselProp(undefined);
            // setselUnitType(undefined);
            // setnameSearch("");
        }
    }

    function onselProp(val: any){
        let valuesplit = val?.split('_');
        setProp(Number(valuesplit[0]))
        setPropLocal(String(valuesplit[1]))
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setdataTemplate([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);
    };

    const [keepUnit, setKeepUnit] = useState<any>();
    const [keepJobNo, setKeepJobNo] = useState<any>();
    const searchData = () => {
        let filter:any = []
        let newData = dataTemplate
        console.log("keepJobNoNewData",newData)

        if (!!keepJobNo && !keepUnit) {
            filter = newData.filter((e:any) => e?.job_id.includes(String(keepJobNo).toUpperCase()))
            setdataTemplate(filter)
            //console.log("filter1",filter)
        }else if (!keepJobNo && !!keepUnit) {
            filter = newData.filter((e:any) => e?.unit_obj[0]?.unit_name.includes(keepUnit))
            setdataTemplate(filter)
            //console.log("filter2",filter)
        }else if (!!keepJobNo && !!keepUnit) {
            filter = newData.filter((e:any) => e?.job_id.includes(String(keepJobNo).toUpperCase()) && e?.unit_obj[0]?.unit_name.includes(keepUnit))
            setdataTemplate(filter)
            //console.log("filter3",filter)
        }
    }

    const onReset = () => {
        setKeepJobNo(undefined)
        setKeepUnit(undefined)
        formProps?.form?.resetFields();
        refreshDataSource()
    }

    return (
        <List title="">
            <Row>
                <Col span={18}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                        {...formProps}
                    >
                        <Form.Item name="Job_no" >
                            <Input
                                onChange={(e) => setKeepJobNo(e.target.value)}
                                placeholder="Search job" 
                                prefix={<Icons.SearchOutlined />} 
                                allowClear
                            />
                        </Form.Item>

                        <Form.Item name="Search unit" >
                            <Input
                               onChange={(e) => setKeepUnit(e.target.value)}
                               placeholder="Search unit" 
                               prefix={<Icons.SearchOutlined />} 
                               allowClear
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => searchData()} type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => onReset()} style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            {/* <Row gutter={12} style={{marginBottom: "15px"}}>
                <Col span={24}>
                    <Row gutter={6} style={{width: "100%"}}>
                        <Col className="gutter-row" style={{width: "200px"}}>
                            <Select 
                                placeholder={t("select.sel")} 
                                // onSelect={(value: any) => {setProp(value)}}
                                onSelect={(value: any) => {onselProp(value)}}
                                onClear={() => setProp(undefined)}
                                allowClear
                            >
                                {
                                    dataProperty?.map((item:any,key:any) => {
                                        return <Option value={item?.property_id+"_"+item?.id} key={item.property_id}>{item?.property_name}</Option>      
                                    })
                                }
                            </Select>
                        </Col>
                        <Col className="gutter-row" style={{width: "200px"}}>
                            <Select {...selUnit}
                                placeholder="Select unit" 
                                onSelect={(value: any) => {setUnit(value)}}
                                onClear={() => setUnit(undefined)}
                                allowClear
                                showSearch
                                // onSelect={(e: any)=>setUnitID(e)}
                                // onChange={(value:any, record:any) => { 
                                //     setUnit({id:value, label: record?.label})
                                // }}
                                // onClear={()=> {
                                //     setUnit(undefined);
                                //     form.resetFields(["unit"]);
                                // }}
                            />
                        </Col>
                        <Col className="gutter-row" style={{width: "100px"}}>
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Col>
                        <Col className="gutter-row" style={{width: "100px"}}>
                            <Button onClick={() => ""} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row> */}

            <Table 
                dataSource={dataTemplate?.length > 0 ? dataTemplate : []} 
                columns={columns} 
                onChange={onChange} 
                pagination={{ size: "small", position: [bottom], showSizeChanger: true, }} 
                scroll={{ x: 2000 }}
            />
            <Row style={{float:'right', paddingTop:'10px'}}>
                <Col span={24} >
                    {t("repair-stat.title")} {dataTemplate?.length}
                </Col>
            </Row>
        </List>
    )
}