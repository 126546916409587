/* eslint-disable */
import { Card, Col, Form, Input, Row, useForm, List, Breadcrumb, Create, Select, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IPhoneBookSubType, IPhoneBookType, IProperties } from "interfaces";
import { useState } from "react";
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import 'antd-country-phone-input/dist/index.css';


export const PhoneBooksCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const userQuery                         = useGetIdentity();         // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const { formProps, saveButtonProps }    = useForm<IPhoneBookSubType,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'phonebook',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );
    // const { queryResult } = useShow<IPhoneBookSubType>({metaData: {
    //     populate: '*'
    //     }, 
    // });
    // const { data } = queryResult;

    const [vsetTel, setTel] = useState<CountryPhoneInputValue>();

    let initF : any;
    initF = {
            field       : 'deleteStatus',
            operator    : 'contains',
            value       :  'N',
        };

    const { selectProps: SelPropert} = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ]
    });

    const { selectProps: SelPhoneBType } = useSelect<IPhoneBookType>({
        resource: "phone-book-types",
        optionLabel: "name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ]
    });

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("phonebook.create")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("phonebook.create")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
          onFinish={(values:any) => {
            values.createBy = user_data.user_profile.id
            
            values.country_code = vsetTel?.short
            values.phone = vsetTel?.phone
            return (
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
               
            );
        }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                            <Col className="gutter-row" span={24}>
                                <Input.Group compact>
                                    <Form.Item  label={t("name")} name="name" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter name.'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item  label="Name eng" name="name_en" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter name eng.'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item  label={t("phonebook.tel")} name="country_code" style={{width: "40%"}}>
                                        <ConfigProvider locale={en} >
                                                <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{height: "32px",borderRadius: "5px",padding: "0"}}
                                                onChange={(v) => {
                                                    setTel(v);
                                                }}
                                                />
                                        </ConfigProvider>
                                    </Form.Item>
                                </Input.Group>
                            </Col>

                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                                <Input.Group compact>
                                    <Form.Item  label={t("lat")} name="lat" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter latitude.'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item  label={t("lng")} name="lng" style={{width: "30%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter longitude.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Input.Group>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        {/* <div style={{ marginBottom: 20 }}><span className="title_panel">{t("relation.txt")}</span></div>
                        <Divider></Divider> */}
                        <Form.Item  label={t("property.title")} name="property"
                            rules={[
                                {
                                    required: true, message: 'Please select property.'
                                },
                            ]}>
                            <Select placeholder={t("select.sel")} {...SelPropert}/>
                        </Form.Item>
                        
                        <Form.Item  label={t("phonebook-type.title")} name="phone_book_type"
                            rules={[
                                {
                                    required: true, message: 'Please select phone book type.'
                                },
                            ]}>      
                            <Select placeholder={t("select.sel")} {...SelPhoneBType} />
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Create>
    );
};