/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, notification, Space } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";

import axios from "axios";
import TextArea from "@uiw/react-md-editor/lib/components/TextArea";

export const RepairZoneCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [form] = Form.useForm();

    // const userQuery = useGetIdentity(); // Get User Profile

    // console.log('Identity_data', Identity_data?.user_profile)

    const user_data:any = {
        id: Identity_data ? Identity_data?.user_profile.id : null,
        first_name: Identity_data ? Identity_data?.user_profile.first_name : null,
        last_name: Identity_data ? Identity_data?.user_profile.last_name : null,
        tel: Identity_data ? Identity_data?.user_profile.tel : null,
        email: Identity_data ? Identity_data?.user_profile.email : null,
        avatar: Identity_data ? Identity_data?.user_profile.avatar : null,
    }

    // console.log('user_data', user_data)



    const t = useTranslate();
    const { replace, list} = useNavigation();

    const [ZoneTh, SetZoneTh] = useState<any>("");
    const [ZoneEn, SetZoneEn] = useState<any>("");
    const [ZoneDesc, SetZoneDesc] = useState<any>("");
    const [ZoneStat, SetZoneStat] = useState<any>(true);
    const [DataProperty, SetDataProperty] = useState<any>();
    const [PropertyType, SetPropertyType] = useState<any>();


    const getDataK = async ()=>{

        // https://api-dev-majorcare.techscape.app/api/majorcare/findAllPropertyType
        let res = await axios.get(majorCareUrl + "/majorcare/findAllPropertyType" , { headers: { } });
        // console.log('res', res)
        let Data = res?.data

        SetDataProperty(Data ? Data : null)
        // SetZoneTh(Data.item_name);
        // SetZoneEn(Data.item_name_en);
        // SetZoneDesc(Data.item_desc);
        // // console.log('Data', Data)
        // form.setFieldsValue({
        //     name: Data.item_name,
        //     name_en: Data.item_name_en,
        //     desc: Data.item_desc
        // })

        form.setFieldsValue({
            status: ZoneStat
        })
    }

    useEffect(() => {
        getDataK();
    }, []);

    console.log('DataProperty',DataProperty)

    // const dataProperty = [
    //     { id: 1, label: 'บ้าน' },
    //     { id: 2, label: 'คอนโด' },
    //     { id: 3, label: 'ทาวน์โฮม' },
    //     // Add more items as needed
    // ];

    async function createZone(values:any) {


        // console.log('PropertyType', PropertyType)
        // Filter DataProperty where PropertyType == DataProperty._id
        let dataProp = DataProperty.filter((item:any) =>
            PropertyType.includes(item._id)
        );
        // console.log('xxxxx', dataProp)

        console.log('dataProp', dataProp[0])
        let data = {
            "zone_name": ZoneTh,
            "zone_name_en": ZoneEn,
            // "item_desc": ZoneDesc,
            "property_type": dataProp,
            "is_default" : true,
            "is_active" : ZoneStat,
            "create_by": user_data ? user_data : null
        }

        console.log('data onFin', data)

        // // http://localhost:10000/api/majorcare/repairZone

        let res = await axios.post(majorCareUrl + "/majorcare/repairZone", data , { headers: { } })
        .then(response => {
            // Handle successful response
            console.log(response.data);
            successNoti();
            replace("/repair/repair-master")
        })
        .catch(error => {
            // Handle error
            console.error('error ',error);
            errorNoti('200', 'success');
        });

    }

    const AddPropTypeToArr = (val:any) =>{
        console.log('val', val)
        SetPropertyType(val)
    }

    console.log('PropertyType', PropertyType)

    // const successNoti = (stat:any, msg:any) => {
    //     notification.success({
    //         // message: stat + ' : ' + msg.TH,
    //         message: msg,
    //         // description: msg,
    //         duration: 3,
    //     });
    // };

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully created',
        });
    };

    const errorNoti = (stat:any, msg:any) => {
        notification.warning({
            message: "This name already exists in the system.",
            duration: 3,
        });
    };

    return (
    <Create 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-zone.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Zone Master Create</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    createZone(values);
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={12} 
                            className="gutter-row" 
                            style={{
                                // borderRight: "1px solid #dedede",
                                paddingTop: "2.5rem"
                            }}
                        >
                            <Form.Item
                                name="item_name"
                                label="Zone Name (TH)"
                                rules={[
                                    {
                                        required: true, message: "Please enter zone name"
                                    },
                                ]}
                            >
                                <Input placeholder="zone name th" onChange={(e) => SetZoneTh(e.target.value)} />

                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                // name="Inspector"
                                name="item_name_en"
                                label="Zone Name (EN)"
                                rules={[
                                    {
                                        required: true, message: "Please enter zone name"
                                    },
                                ]}
                            >
                                <Input placeholder="zone name en" onChange={(e) => SetZoneEn(e.target.value)} />

                            </Form.Item>

                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        // name={["type", "value"]}
                                        // name="Inspector"
                                        name="property_type"
                                        label="Property Type"
                                        rules={[
                                            {
                                                required: true, message: "Please enter zone name"
                                            },
                                        ]}
                                    >
                                        {/* <Radio.Group
                                            onChange={(e:any) => SetPropertyType(e.target.value)}
                                        >
                                            <Space direction="vertical">
                                                {DataProperty?.map((item:any) => (
                                                    <Radio key={item.property_type_id} value={item.property_type_id}>
                                                    {item.property_type_name}
                                                    </Radio>
                                                ))}
                                            </Space>
                                        </Radio.Group> */}

                                        <Checkbox.Group 
                                            style={{ width: '100%' }}
                                            // onChange={(e:any) => SetPropertyType(e.target.value)}
                                            onChange={(e:any) => AddPropTypeToArr(e)}
                                            // value={checkedPermiss}
                                        >
                                            <Row>
                                                  {DataProperty?.map((item:any) => (
                                                    // <Radio key={item.property_type_id} value={item.property_type_id}>
                                                    // {item.property_type_name}
                                                    // </Radio>
                                                    <Col span={24} style={{marginBottom: "2%"}}>
                                                        <Checkbox 
                                                            key={item.property_type_id}
                                                            // value={item.property_type_id}
                                                            value={item._id}
                                                        >{item.property_type_name}</Checkbox>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        // name={["type", "value"]}
                                        // name="Inspector"
                                        name="status"
                                        label="สถานะ"
                                        rules={[
                                            {
                                                required: true, message: "Please select"
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            onChange={(e:any) => SetZoneStat(e.target.value)}
                                            defaultValue={ZoneStat}
                                            value={ZoneStat}
                                        >
                                            <Radio value={true} style={{ marginBottom: "0%" }}> ใช้งาน </Radio>
                                            <Radio value={false} style={{ marginBottom: "0%" }}> ไม่ใช้งาน </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                           
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Create>   
    );
};