import { Breadcrumb, Card, Checkbox, Col, Create, Form, Input, List, Radio, RadioChangeEvent, Row, Select, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useList, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IAppointmentType, IEventlog } from "interfaces";

// import Moment from 'moment';
import { useState } from "react";
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

export const AppointmTypeCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const { formProps, saveButtonProps } = useForm<IAppointmentType,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            // console.log(data?.data);
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            // recordData.data.id + ""
            CreateLog({
                resource    : log_endpoint,
                values      : {
                    // user_profile            : user.id,
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(), 
                    log_event               : "Create",
                    menu_event              : 'appointments_type',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
    });
    const { Option } = Select;

    const onChangeNoti = ({ target: { value } }: RadioChangeEvent) => {
        console.log('radio noti checked', value);
        setValueNoti(value);
    };
    const [vValueNoti, setValueNoti]        = useState(false);
    const [ vDaySendNoti, setDaySendNoti ]  = useState<any>();
    // const [ vSubtype, setSubtype ]          = useState<any>([]);
    const [ vCheckbox, setCheckbox ]        = useState<any>([]);
    const [ vStatus, setStatus ] = useState<any>(true);

    const seePermission = useList<IAppointmentType>({ 
        resource: "user-sub-types", 
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "sub_type_name",
                    operator: "eq",
                    // value   : ["Guest", "Pre Owner", "Owner", "Renter", "Resident Owner", "Resident Renter"]
                    value   : ["Guest", "Owner Contract", "Owner", "Renter", "Resident Owner", "Resident Renter"]
                },
            ]
        }
    });

    const { data } = seePermission;
    let permissionData:any = []
    permissionData = data?.data? data?.data : [];

    const optionsRadioNoti = [
        { label: t("appointm-type-create.noti-false"), value: false },
        { label: t("appointm-type-create.noti-true"), value: true },
    ];

    const onChangecb = (checkedValues: CheckboxValueType[]) => {
        vCheckbox.pop();
        vCheckbox.push(checkedValues);
        setCheckbox(vCheckbox);
        // console.log('vCheckbox = ', checkedValues);
        console.log('vCheckbox = ', vCheckbox);
    };

    const onChangeStatus = (e: RadioChangeEvent) => {
        setStatus(e.target.value);
    };

    const { TextArea } = Input;

    // console.log(permissionData);
    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("appointm-type-create.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("appointm-type.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("appointm-type-create.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />

        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.noti_time = vDaySendNoti
                values.user_sub_type = vCheckbox
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={12}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("appointm-type-create.apt_name")} name="name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter appointment name.'
                                        },
                                    ]}>
                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("appointm-type-create.condi")} name="appointment_option"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter.'
                                        },
                                    ]}>
                                        {/* <Input /> */}
                                        <Select placeholder={t("select.sel")}>
                                            <Option value="Complete">การนัดสมบูรณ์ทันที</Option>
                                            <Option value="Again">รอเจ้าหน้าที่ยืนยันอีกครั้ง</Option>
                                        </Select>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("appointm-type-create.note")} name="note"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter message.'
                                        },
                                    ]}>
                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>
                            
                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("appointm-type-create.stat")} name="status">
                                    <Radio.Group 
                                        style={{verticalAlign: 'middle'}} 
                                        onChange={onChangeStatus}
                                        defaultValue={vStatus}
                                    >
                                        <Radio.Button value={true}>{t("appointm-type.public")}</Radio.Button>
                                        <Radio.Button value={false}>{t("appointm-type.draft")}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5,boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item  label={t("appointm-type-create.noti")} name="noti">
                            <Radio.Group options={optionsRadioNoti} onChange={onChangeNoti} value={vValueNoti} optionType="button"/>
                        </Form.Item>
                        {
                            vValueNoti || vValueNoti == null  ?
                                <><Input type="number" placeholder="set day" style={{width: "78%",borderRadius: "5px"}} onChange={(e) => setDaySendNoti(e.target.value)} value={vDaySendNoti} suffix="days"/></>
                                :
                                <></>
                        }
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item  label={t("appointm-type-create.permis")} name="user_sub_types"
                            rules={[
                                {
                                    required: true, message: 'Please enter.'
                                },
                            ]}>

                            <Checkbox.Group style={{ width: '100%' }} onChange={onChangecb} >
                                <Row>
                                    <Col span={20}>
                                        {
                                            permissionData?.map((el:any,index:any) => {
                                                return <>
                                                    <Checkbox key={el.id} value={el.id} >{el.sub_type_name}</Checkbox>
                                                    <br/>
                                                    </>
                                                })
                                        }
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Create>   
    );
};