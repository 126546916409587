/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, Select, Space, Table, TextField, useModalForm, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog , IProperties, IUnitType } from "interfaces";
import Moment from 'moment';
import { useState } from "react";

export const UnitTypeList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Unit Type");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null 

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { confirm } = Modal;
    const { mutate } = useUpdate<IUnitType>();
    const ed_point = "unit-types";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const { mutate: CreateLogDelete } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";
    const userQuery = useGetIdentity();
    const {data : user_data , isLoading} = userQuery;
    const vCurDelStatus = {deleteStatus : 'Y'}

    const {
        tableProps,
        searchFormProps,
        tableQueryResult
    } = useTable<IUnitType,HttpError, IUnitType>({
        metaData: {
            populate: '*'
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property } = params;
            console.log(">>> property", property)
            filters.push(
                {
                    field: "property][id]",
                    // operator: (property || prop_list || prop_id) ? "eq" : "nin",
                    operator: "eq",
                    value: property ? property : prop_list ? prop_list: prop_id ? prop_id : null,
                },
            )
            return filters;
        },
        initialFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        permanentSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
    });

    const { selectProps: selProperty } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
    });

    const { triggerExport, isLoading: exportLoading } = useExport<IUnitType>({
        mapData: (item) => {
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,
                };
            }
        },
    });

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'unit-types',
                SysPkID         : id+""
            },
            successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
    } = useModalForm<any>({
        resource: "unit-types",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading ,
        show: editModalShow,
        close
    } = useModalForm<any>({
        resource: "unit-types",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data
        }},
    });

    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    return <>
            <List 
                title={t("unit-type.bc_title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("unit.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("unit-type.bc_title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="property" >
                                <Select {...selProperty} 
                                    placeholder="Select property" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                    allowClear
                                    // onClear={()=> setProp(undefined)}
                                    // onChange={() => searchFormProps?.form?.submit()}
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                {/* <CreateButton id="create_btn"  onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
 
                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableQueryResult?.data?.total, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}
                    {/* <Table.Column dataIndex="id" title={t("Lid")} align="center" width={150} sorter
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title={t("property.Ltitle")} width={300} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.property?.property_name < b?.property?.property_name) {return -1}
                            if (a?.property?.property_name > b?.property?.property_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                                return <>
                                        <TextField value={record?.property? record?.property.property_name :"-"}  />
                                    </>
                            }}
                    />

                    <Table.Column title={t("Lname")} width={200} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.name < b?.name) {return -1}
                            if (a?.name > b?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.name:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={t("unit-type.Llimit")} align="center" width={140} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.vehicle_limit < b?.vehicle_limit) {return -1}
                            if (a?.vehicle_limit > b?.vehicle_limit) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.vehicle_limit:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log(record)
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                                {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                                {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />
                  
                    <Table.Column<IUnitType>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>

                                {/* <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton> */}

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */}

                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                // onClick={() => editModalShow(record.id)}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            /> 
                                            <Button size="small" title="Inactive" icon={<Icons.LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<Icons.UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...createModalProps}
                title={"Unit Type Create"}
                okText = {"Create"}
                maskClosable={false}
                width={400}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.property = values.property;
                        values.name = values.name;
                        values.vehicle_limit = values.vehicle_limit;
                        values.deleteStatus = "N"
                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("property.title")} name={["property", "id"]}
                                rules={[
                                    {
                                        required: true, message: 'Please select property.'
                                    },
                                ]}
                            >
                                <Select
                                    {...selProperty}
                                    placeholder={t("select.sel")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Form.Item  label={t("name")} name="name"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit type name.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Form.Item  label={t("unit-type.limit")} name="vehicle_limit"
                                rules={[
                                    {
                                        required: true, message: 'Please enter vehicle limit.'
                                    },
                                ]}>
                                    <Input type={"number"} minLength={1} min={0} defaultValue={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Unit Type Edit"}
                okText = {"Save"}
                maskClosable={false}
                width={400}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.property = values.property;
                        values.name = values.name;
                        values.vehicle_limit = values.vehicle_limit;
                        values.deleteStatus = "N"

                        values.updateBy = user_data.user_profile.id;
                        return (
                            editProps.onFinish &&
                            editProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("property.title")} name={["property", "id"]}
                                rules={[
                                    {
                                        required: true, message: 'Please select property.'
                                    },
                                ]}
                            >
                                <Select
                                    {...selProperty}
                                    placeholder={t("select.sel")}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Form.Item  label={t("name")} name="name"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit type name.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12} className="gutter-row">
                            <Form.Item  label={t("unit-type.limit")} name="vehicle_limit"
                                rules={[
                                    {
                                        required: true, message: 'Please enter vehicle limit.'
                                    },
                                ]}>
                                    <Input type={"number"} minLength={1} min={0} defaultValue={1} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}