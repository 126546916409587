//import { env } from "process";

export const consoleLog = true  // false = close clg.log

// SIT
// export const VERSION = "SIT.build.181";
// export const PORTAL_URL = "https://sit-lsc2.techscape.app"; // SIT
// export const majorCareUrl = "https://api-sit-majorcare.techscape.app/api";   // SIT;
// export const petURL = "https://pet-service-sit.techscape.app"; // PET SIT;
// export const rentSaleURL = "http://192.168.100.129:10071"; // RENT AND SALE SIT;
// export const chatURL = "https://xchatv2.techscape.app"; // CHAT SIT;

// UAT
// export const VERSION = "UAT.build.122";
// export const PORTAL_URL = "https://uat-lsc.techscape.app"; // UAT
// export const majorCareUrl = "https://api-uat-majorcare.techscape.app/api";   // UAT;
// export const petURL = "https://pet-service-uat.techscape.app"; // PET SIT;
// export const rentSaleURL = "http://192.168.100.129:10071"; // RENT AND SALE SIT;
// export const chatURL = "https://xchat.techscape.app"; // CHAT UAT;

// PROD
export const VERSION = "PROD.2310008";
// export const PORTAL_URL = "http://172.19.11.142:3000"; // PRODUCTION
// export const majorCareUrl = "http://172.19.11.143:10031/api"; // PROD;
// export const chatURL = "https://chat.lifescape.co.th"; // CHAT PROD;
// export const rentSaleURL = "http://192.168.100.129:10071"; // RENT AND SALE SIT;
// export const petURL = "http://172.19.11.143:10081"; // PET SIT;
export const PORTAL_URL = "https://portal.lifescape.co.th"; // PRODUCTION
//export const PORTAL_URL = "http://localhost:3000"; // PRODUCTION
//export const majorCareUrl = "http://localhost:10031/api"; // PROD;
export const majorCareUrl = "https://majorcare.lifescape.co.th/api";
 // PROD;
//export const majorCareUrl = "https://a0f8-171-100-245-73.ngrok-free.app/api";


//export const chatURL = "http://localhost:2000"; // CHAT PROD;

export const chatURL = "https://chat.lifescape.co.th"; // CHAT PROD;
export const rentSaleURL = "http://192.168.100.129:10071"; // RENT AND SALE SIT;
export const petURL = "https://pet.lifescape.co.th"; // PET SIT;/ PET SIT;
 

// LOCAL K.
// export const VERSION = "DEV.XXXXX"; 
// export const majorCareUrl = "http://192.168.100.129:10031/api"; // LOCAL;
// export const PORTAL_URL = "http://192.168.100.129:3000"; // Local
// export const petURL = "http://192.168.100.129:10091";             // PET_DEV;
// export const petURL = "http://192.168.1.37:10091";             // KOM HOME PET;
// export const petURL = "http://192.168.55.186:10091";             // KOM HOME PET;

// export const VERSION = "1.3.2-beta";
// export const VERSION = "dev";
// export const PORTAL_URL = "http://203.156.30.152:3000"; // PRODUCTION
// export const PORTAL_URL = "https://qas.lifescape.co.th"; // QAS handover
// export const PORTAL_URL = "https://datamigration-portal.lifescape.co.th"; // Migration
// export const PORTAL_URL = "http://192.168.1.37:3000"; // Local
export const ROCKET_URL = "event.techscape.app"; // Local

export const API_URL = process.env.REACT_APP_API_URL;///api_url_temp;
export const TOKEN_KEY = process.env.REACT_APP_TOKEN?.toString() ? process.env.REACT_APP_TOKEN?.toString(): '';//"25a0f718093ae4d9dc3ecf59f173a54571898253193de8373ae37acadb4e19aa56aaee7b61dbff3b249215f3798402052c1c5949ee1d0a91dea5979d12a3b90471639a97c59c3a29fd774e8c573d2e22f89cd77bade6f4403560165790a09f5d83781f0f19d38474f4ba76a073ece38f414d1012522379d14c0d8d9d223c03a0"

// export const majorCareUrl = "https://api-dev-majorcare.techscape.app/api";   // DEV;
// export const majorCareUrl = "http://192.168.100.129:10031/api";              // Local;
// export const majorCareUrl = "https://beemdev.techscape.app/api";             // Beam;