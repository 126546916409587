import {  List, ShowButton, Table, Tag, TextField } from "@pankod/refine-antd";
import {  IResourceComponentsProps, useApiUrl, useList, useNavigation } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";


export const ApiLogList: React.FC<IResourceComponentsProps> = () => {
    const API_URL = useApiUrl();
    // const API_URL = "https://lsc-service-uat.techscape.app/api"


    const [dataDetailList, setDataDetailList] = useState<any>([]);
    const [pageSize, setPageSize] = useState(100); // Default page size
    const [countInterval, setCountInterval] = useState(0);
    const { show } = useNavigation();

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
    const dataBlank:any = []

    const getDataK = async ()=>{
        let res_data = await axios.get(
            API_URL + `/api-logs?populate=*&pagination[pageSize]=10000`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad ', dataLoad?.data)
                // ====================================================================================================================================
                for (let index = 0; index < dataLoad?.data.length; index++) {
                    dataDetailList.push(dataLoad?.data[index]);
                }
                dataDetailList.reverse();
            });
    }
    // const UserHasUnit = useList<any>({ 
    //     resource: "api-logs", 
    //     metaData: {
    //         populate: '*',
    //     },
    //     config:{
    //         pagination: { current: 1, pageSize: 10000 },
    //     },queryOptions: {
    //         onSuccess: (data) => {
    //           console.log('data xxxx : ', data)
    //         //   callFetchData();
    //         },
    //     },
    // });
    console.log('dataDetailList', dataDetailList)

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    getDataK();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])


    useEffect(() => {
        getDataK();
    }, []);

    // async function callAPI(v:any){
    //     const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    //     let resultQuery1 :any = await axios.get(API_URL + "/appointment-time-slots/slot_chk/?date=" + v , { headers: { Authorization: AuthStr } });

    //     let arrayID :any = resultQuery1?.data?.find_master_dateto?.map((item:any) => {
    //         return item.id
    //     })
    // }

    const columns:any = [
        {
            title: 'ID',
            dataIndex: 'record_id',
            key: 'record_id',
            width: 140,
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.attributes.return_status !== undefined ? record.attributes.return_status : '-': '-'}</div> */}
                        {
                            record ? 
                                record?.id
                            : '-'
                            }
                    </>
                );
            },
        },
        {
            title: 'API NAME',
            // dataIndex: 'updateBy',
            key: 'api_name',
            width: 140,
            render: (value:any, record:any) => {
                {
                    let splitApiName = record.attributes.api_name.split("/");
                    return <>
                         {
                            splitApiName.length !== 0 ?
                                splitApiName[0] == "properties" ?
                                    <Tag color="magenta">{splitApiName ? splitApiName[0] : <></> }</Tag>
                                : splitApiName[0] == "user-profiles" ?
                                    <Tag color="geekblue">{splitApiName ? splitApiName[0] : <></> }</Tag>
                                : splitApiName[0] == "unit" ?
                                    <Tag color="purple">{splitApiName ? splitApiName[0] : <></> }</Tag>
                                : splitApiName[0] == "down-payments" ?
                                    <Tag color="gold">{splitApiName ? splitApiName[0] : <></> }</Tag>
                                : <>-</>
                            : <>-</>
                        }
                    </>
                }
                return (
                    
                    <>
                        
                        <div>
                            {
                                record ? 
                                    record.attributes.api_name !== undefined ? 
                                        <>
                                            {
                                                record.attributes.api_name 
                                            }
                                        </>
                                        : '-'
                                    : '-'
                            }
                            
                        </div>
                    </>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'return_status',
            key: 'return_status',
            width: 140,
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.attributes.return_status !== undefined ? record.attributes.return_status : '-': '-'}</div> */}
                        {
                            record ? 
                                record.attributes.return_status !== undefined ? 
                                    record.attributes.return_status == "400" ? 
                                        <Tag color="red">{record.attributes.return_status }</Tag> :
                                            record.attributes.return_status == "200" ? <Tag color="green">{record.attributes.return_status }</Tag> 
                                    : '-'
                                    : '-'
                                : '-'
                            }
                    </>
                );
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            width: 140,
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.attributes.create_at !== undefined ? dayjs(record.attributes.create_at).format("DD-MM-YYYY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'RETURN DATA',
            dataIndex: 'return_data',
            key: 'return_data',
            width: 450,
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.attributes.return_data !== undefined ? record.attributes.return_data : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                             <ShowButton
                                hideText
                                size="small"
                                style={{marginRight:'5px'}}
                                onClick={() => show("api-log-dev", record.id )}
                            />
                        }
                    </>
                );
            },
        },
    ];

    return <>
        <List title="">
            <div>
                {
                    dataDetailList.length > 0 ?
                        <Table 
                            dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank} 
                            columns={columns} 
                            // onChange={onChange} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                pageSizeOptions: ['100', '500', '1000'], 
                                showSizeChanger: true, 
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 1300 }}
                        />
                    :
                        <Table  
                            columns={columns} 
                            // onChange={onChange} 
                            pagination={{ size: "small", position: [ 'bottomRight'] }} 
                        />
                }
            </div>
        </List>
    </>
}