import { Breadcrumb, Button, Col, Edit, Form, Icons, Input, InputNumber, List, Radio, Row, Select, Spin, notification } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import { petURL } from "../../../constants";
import axios from "axios";

let optionpettype: any = undefined;
export const PetvcEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { TextArea } = Input;
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();

    const [formCustom] = Form.useForm();
    const [keepMin, setKeepMin] = useState<any>(0)
    const [keepMax, setKeepMax] = useState<any>(0)
    
    const [valuepetType, setpetType] = useState<any>();
    const [vacinject, setvacinject] = useState<any>(null);
    const [vactype, setvactype] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [injectRound, setInjectRound] = useState<boolean>(true);

    let getID = window?.location?.href.split("/");
    let pageID: any = getID[getID?.length -1];

    const { data: dataLoad } = useCustom<any>({
        url: `${petURL}/api/lscpet/findOnePetVaccine`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log('dataLoad', dataLoad)
                setpetType(dataLoad?.pet_type);
                setvacinject(dataLoad?.vac_inject_yearly);
                setvactype(dataLoad?.vaccine_type)

                // vac_inject_round: boolean; // true == ครั้งเดียว, false == ทุก ๆ xxx ปี
                setInjectRound(dataLoad?.vac_inject_round)
                let maxData = Number(dataLoad?.age_to)
                let minData = Number(dataLoad?.age_from)
                setKeepMax(maxData)
                setKeepMin(minData)

                formCustom?.setFieldsValue({
                    pet_type: dataLoad?.pet_type,
                    vaccine_type: dataLoad?.vaccine_type,
                    vaccine_name: dataLoad?.vaccine_name,
                    vaccine_name_en: dataLoad?.vaccine_name_en,
                    vac_inject_round: dataLoad?.vac_inject_round,
                    age_type: dataLoad?.age_type,
                    age_from: Number(dataLoad?.age_from),
                    age_to: Number(dataLoad?.age_to),
                    recommend_th: dataLoad?.recommend_th,
                    recommend_en: dataLoad?.recommend_en,
                    // pet_type: dataLoad?.pet_type
                })
            }
        }
    }) 

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findActivePetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }

                setLoading(false);
            },
        },
    });

    async function submitDT(values:any){
        let body: any = {
            "pet_type": String(valuepetType),
            "vaccine_name": values?.vaccine_name,
            "vaccine_name_en": values?.vaccine_name_en,
            "vaccine_type": values?.vaccine_type,
            "age_type": values?.age_type,
            "age_from": String(values?.age_from),
            "age_to": String(values?.age_to),
            // "vac_inject_round": values?.vac_inject_round,
            "vac_inject_round": injectRound,
            "vac_inject_yearly": values?.vac_inject_round == false ? String(vacinject) : null,
            "recommend_th": values?.recommend_th,
            "recommend_en": values?.recommend_en,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }
        
        let submit = await axios.put(petURL + "/api/lscpet/updatePetVaccine?id=" + pageID, body, { headers: { } })
        .then(response => {
            successNoti('Successfully edit pet vaccine');
            replace("/pets/pet-masters")
        })
        .catch(error => {
            console.error('error ',error);
            faildNoti('Edit is Failed');
        });
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    const handleOnChange = (e: any) => {
        setInjectRound(e?.target?.value)
        if (e?.target?.value === true) {
          setvacinject(null);
        }
    };

    return(
        <Spin size="large" spinning={isLoading}>
            <Edit pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/pet-masters")}}} saveButtonProps={{hidden: true}}>
                <List 
                    title={t("pet-vaccine.title-edit")}
                    pageHeaderProps={{
                        breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-vaccine.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-vaccine.title-edit")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                        extra : <></>
                    }}
                />

                <div style={{width: "50%"}}>
                    <Form 
                        form={formCustom}
                        layout="vertical"
                        onFinish={(values:any) => {
                            submitDT(values);
                        }}
                    >
                        <Row gutter={[24, 16]}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item 
                                    label={t("pet-type.title")}
                                    name="pet_type"
                                    rules={[
                                        {
                                            required: true, message: 'please select pet type.'
                                        },
                                    ]}
                                >
                                    <Select
                                        options={optionpettype}
                                        showSearch
                                        allowClear
                                        onChange={(e: any)=> setpetType(e)}
                                        onClear={()=> setpetType(undefined)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}/>
                            <Col className="gutter-row" span={12}>
                                <Form.Item 
                                    label={t("pet-vaccine.type")}
                                    name="vaccine_type"
                                    // className="disable_req"
                                    rules={[
                                        {
                                            required: true, message: 'please select charge'
                                        },
                                    ]}
                                >
                                    <Radio.Group name="radiogroup">
                                        <Radio value={true}>{t("pet-vaccine.vaccine")}</Radio>
                                        <Radio value={false}>{t("pet-vaccine.vaccine-op")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}/>
                            <Col className="gutter-row" span={12}>
                                <Form.Item 
                                    label={t("pet-vaccine.name-th")}
                                    name="vaccine_name"
                                    rules={[
                                        {
                                            required: true, message: 'please input vaccine name th.'
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>


                                <Form.Item 
                                    label={t("pet-vaccine.number-of-injections")}
                                    name="vac_inject_round"
                                    // className="disable_req"
                                    rules={[
                                        {
                                            required: true, message: 'please select vac inject round'
                                        },
                                    ]}
                                    style={{marginBottom: "80px"}}
                                >
                                    <Radio.Group name="radiogroup" onChange={(e: any) => {handleOnChange(e)} }>
                                        <Radio value={true}>{t("ครั้งเดียว")}</Radio>
                                        <Radio value={false}>
                                            <span>ทุกๆ</span>
                                            <Input 
                                                type="number" 
                                                min={0} 
                                                value={vacinject}
                                                style={{width: "80px", margin: "0 10px"}}
                                                onChange={(e: any) => setvacinject(e?.target?.value)}
                                            />
                                            <span>ปี</span>
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item  label={t("pet-vaccine.guidelines-th")} name="recommend_th">
                                    <TextArea style={{height: "60px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={12}>
                                <Form.Item 
                                    label={t("pet-vaccine.name-en")}
                                    name="vaccine_name_en"
                                    rules={[
                                        {
                                            required: true, message: 'please input vaccine name en.'
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>

                                {
                                    injectRound == true && <span>
                                        <Form.Item 
                                            label="อายุ"
                                            name="age_type"
                                            // className="disable_req"
                                            rules={[
                                                {
                                                    required: true, message: 'please select age type'
                                                },
                                            ]}
                                        >
                                            <Radio.Group name="radiogroup">
                                                <Radio value={true}>{t("สัปดาห์")}</Radio>
                                                <Radio value={false}>{t("ปี")}</Radio>
                                            </Radio.Group>
                                        </Form.Item>

                                        <Row gutter={6}>
                                            <Col className="gutter-row" span={4}>
                                                <div style={{marginTop: "7px"}}>
                                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ระบุ")}</span>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={6}>
                                                <Form.Item 
                                                    className="disable_req visible-label"
                                                    name="age_from"
                                                    rules={[
                                                        {
                                                            required: true, message: 'please input age form'
                                                        },
                                                    ]}
                                                >
                                                    {/* <Input type="number" min={0} defaultValue={0}/> */}
                                                    <InputNumber type="number" min={0} defaultValue={0} onChange={(e) => setKeepMin(e)} style={{width:'100%', border: keepMax < keepMin ? '1px solid red' : '1px solid'}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col className="gutter-row" span={4}>
                                                <div style={{marginTop: "7px", textAlign: "center"}}>
                                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ถึง")}</span>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={6}>
                                                <Form.Item 
                                                    className="disable_req visible-label"
                                                    name="age_to"
                                                    rules={[
                                                        {
                                                            required: true, message: 'please input age form'
                                                        },
                                                    ]}
                                                >
                                                    {/* <Input type="number" min={0} defaultValue={0}/> */}
                                                    <InputNumber type="number" min={0} defaultValue={0} onChange={(e) => setKeepMax(e)} style={{width:'100%', border: keepMax < keepMin ? '1px solid red' : '1px solid'}}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </span>
                                }

                                <Form.Item  label={t("pet-vaccine.guidelines-en")} name="recommend_en">
                                    <TextArea style={{height: "60px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button disabled={keepMax < keepMin ? true : false} type="primary" onClick={() => formCustom?.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
                </Row>
            </Edit>
        </Spin>
    )
}