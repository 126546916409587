import { IResourceComponentsProps, useCustom, useList, useNavigation, useTranslate } from "@pankod/refine-core"
import axios from "axios";
import { petURL } from "../../../constants";
import { Button, Col, EditButton, Image, Form, Icons, Row, List, Select, Spin, Table, Breadcrumb, Modal, useSelect, Tabs, Tag, TextField, Input } from "@pankod/refine-antd";
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";


// let dataTB: any = undefined;
let data_pet_type: any = undefined;
let data_pet_stat: any = undefined;
let optionpettype: any = undefined;
let optionstatus: any = undefined;

export const PetDataInAppList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { TabPane } = Tabs;
    const { replace, list, edit} = useNavigation();
    const [tabm, settabm] = useState<any>("inapp");
    const [pageSize, setPageSize] = useState<any>(10);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [dataTB, setdataTB] = useState<any>([]);
    const [dataOriginal, setdataOriginal] = useState<any>([]);
    const [selProp, setselProp] = useState<any>(undefined);
    const [petName, setPetName] = useState<any>(undefined);
    const [selUserType, setSelUserType] = useState<any>(undefined);
    const [Unit, setUnit] = useState<any>(undefined);
    const [valuepetType, setpetType] = useState<any>();
    const [searchStat, setSearchStat] = useState<any>();

    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const prop_id = localStorage.getItem("PROPERTY_ID");

    const [formSearch] = Form.useForm();

    const { confirm } = Modal;

    const { data, refetch } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetRegis`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad", dataLoad)
                // dataTB = dataLoad;

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item?.property?.id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e?.property?.id == Number(prop_id))
                }

                setdataTB(dataLoad)
                setdataOriginal(dataLoad)
                // setLoading(false);
            },
        },
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 10000,
        sort: [{ order: "asc", field: "id" }],
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "in",
                value       : selProp?.id
            },
        ]
    });

    const { selectProps: selUseType } = useSelect<any>({
        resource    : "user-sub-types",
        optionLabel : "sub_type_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
        // filters: !!prop_list || !!prop_id ? [
        //     {
        //         field: "deleteStatus",
        //         operator: "eq",
        //         value: "N"
        //     },
        //     {
        //         field : "id]",
        //         operator : prop_list ? "in" : "eq",
        //         value : prop_list ? prop_list : prop_id
        //     },
        // ]:[
        //     {
        //         field: "deleteStatus",
        //         operator: "eq",
        //         value: "N"
        //     },
        // ]
    });

    const { data: pet_type } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad pet_type", dataLoad)
                data_pet_type = dataLoad;
                // setLoading(false);
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        // value: dataLoad[index]?._id
                        value: dataLoad[index]?.pet_type_name,
                        key: dataLoad[index]?._id

                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }
            },
        },
    }); 

    const { data: pet_stat } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetApproveStat`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad pet_stat", dataLoad)
                data_pet_stat = dataLoad;

                dataLoad.reverse();
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.status_name,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionstatus = newDT;
                }

                setTimeout(() => {
                    setLoading(false);
                }, 200);
            },
        },
    });

    async function updateStatus(id:any, val:any){
        console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.put(
            `${petURL}/api/lscpet/updatePetCommu?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        // refreshDataSource()
        refetch();
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                // }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                // }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: `${t("pet-vacc-list.properties")}`,
            dataIndex: 'property',
            key: 'property',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.property?.property_name < b?.property?.property_name) {return -1}
                if (a?.property?.property_name > b?.property?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.property ? record?.property?.property_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.unit")}`,
            dataIndex: 'unit',
            key: 'unit',
            width: 80,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.unit?.unit_name < b?.unit?.unit_name) {return -1}
                if (a?.unit?.unit_name > b?.unit?.unit_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.unit ? record?.unit?.unit_name : '-'}</span>)
            },
        },
        {
            // title: 'OWNER',
            title: `${t("pet-vacc-list.user-type")}`,
            dataIndex: 'age',
            key: 'age',
            width: 220,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.owner_of_pet?.first_name < b?.owner_of_pet?.first_name) {return -1}
                if (a?.owner_of_pet?.first_name > b?.owner_of_pet?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // return (<span>{record?.owner_of_pet ? record?.owner_of_pet?.first_name + ' ' + record?.owner_of_pet?.last_name : '-'}</span>)
                return (<span>{record?.user_type ? record?.user_type?.user_sub_type : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.pet-type")}`,
            dataIndex: 'vaccine-type',
            key: 'vaccine-type',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_type < b?.pet_type) {return -1}
                if (a?.pet_type > b?.pet_type) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                let filteredData = data_pet_type?.filter((item:any) => item._id == record?.pet_type);
                return (<span>{filteredData?.length > 0 ? filteredData[0]?.pet_type_name : "-"}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.name")}`,
            dataIndex: 'pet_name',
            key: 'pet_name',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_name < b?.pet_name) {return -1}
                if (a?.pet_name > b?.pet_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record ? record?.pet_name : '-'}</span>)
            },
        },

        {
            title: `${t("pet-vacc-list.regis")}`,
            dataIndex: 'regis_date',
            key: 'regis_date',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.register_date < b?.register_date) {return -1}
                if (a?.register_date > b?.register_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record ? dayjs(record?.register_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.exd")}`,
            dataIndex: 'expire_date',
            key: 'expire_date',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.expire_date === null) {
                    return 1;
                  }
                
                  if (b?.expire_date === null) {
                    return -1;
                  }
                
                  if (a?.expire_date === b?.expire_date) {
                    return 0;
                  }
                
                  return a?.expire_date < b?.expire_date ? -1 : 1;
            },
            render: (value:any, record:any) => {
                const expireDate = record?.expire_date && dayjs.tz(record?.expire_date, 'Asia/Bangkok').toISOString()

                // console.log('record', record)
                // return (<span>{record?.expire_date ? dayjs(record?.expire_date).format("DD-MM-YYYY HH:mm") : 'ตลอดชีพ'}</span>)
                // return (<span>{record?.is_lifetime ? 'ตลอดชีพ' : record?.expire_date ? dayjs(record?.expire_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
                
                return (<span>{record?.is_lifetime ? 'ตลอดชีพ' : record?.expire_date ? dayjs(expireDate).format("YYYY-MM-DD HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.create-by")}`,
            dataIndex: 'create_by',
            key: 'create_by',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.create_by?.user_name.localeCompare(b?.create_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.create_by ? record?.create_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.create-date")}`,
            dataIndex: 'create_date',
            key: 'create_date',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.c < b?.create_date) {return -1}
                if (a?.create_date > b?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.create_date ? dayjs(record?.create_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.update-by")}`,
            dataIndex: 'update_by',
            key: 'update_by',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.last-update")}`,
            dataIndex: 'update_date',
            key: 'update_date',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-vacc-list.status")}`,
            dataIndex: 'status',
            key: 'status',
            width: 200,
            fixed: 'right',
            sorter: (a:any, b:any) => {
                if (a?.pet_approve_stat < b?.pet_approve_stat) {return -1}
                if (a?.pet_approve_stat > b?.pet_approve_stat) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                let filteredData = data_pet_stat?.filter((item:any) => item._id == record?.pet_approve_stat);
                // console.log('filteredData', filteredData)
                // return (<span>{filteredData?.length > 0 ? filteredData[0]?.status_name : "-"}</span>)

                return (
                    filteredData?.length > 0 ?
                        filteredData[0]?._id == "6539f59959e64523844d078f" ? // รอดำเนินการ
                            <Tag className="tag_pet_blue"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : filteredData[0]?._id == "6539f5c659e64523844d0791" ? // ตรวจสอบเสร็จสิ้น รอชำระเงิน
                            <Tag className="tag_pet_pink"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : filteredData[0]?._id == "6539f5e659e64523844d0793" ? // รอตรวจสอบสถานะชำระค่าบริการ
                            <Tag className="tag_pet_orange"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : filteredData[0]?._id == "6539f5f359e64523844d0795" ? // หมดอายุ
                            <Tag className="tag_pet_black"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : filteredData[0]?._id == "6539f5fe59e64523844d0797" ? // อนุมัติ
                            <Tag className="tag_pet_green"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : filteredData[0]?._id == "6539f60859e64523844d0799" ? // ไม่อนุมัติ
                            <Tag className="tag_pet_red"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : filteredData[0]?._id == "6539f61a59e64523844d079b" ? // ยกเลิกการลงทะเบียน
                            <Tag className="tag_pet_red"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : filteredData[0]?._id == "6557382ca209821eb93c7492" ? // ลบบัญชีโดยผู้ใช้
                            <Tag className="tag_pet_black"><TextField value={filteredData[0]?.status_name}  /></Tag>
                        : <></>

                    : '-'
                )

            },
        },
        {
            title: `${t("pet-vacc-list.action")}`,
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                return (
                    <>
                    {
                        <EditButton
                            hideText
                            size="small"
                            style={{marginRight:'5px'}}
                            onClick={() => edit("pet-data", record._id )}
                        />
                        // record.is_active ? 
                        // <>
                        //     <EditButton
                        //         hideText
                        //         size="small"
                        //         style={{marginRight:'5px'}}
                        //         onClick={() => edit("pet-data", record._id )}
                        //     />
                        //     <Button 
                        //         size="small" 
                        //         title="Delete" 
                        //         icon={<Icons.DeleteOutlined />} 
                        //         onClick={() => { showDelConfirm(record._id) }} 
                        //         danger
                        //     ></Button>
                        // </>
                        // :
                        //     <Button 
                        //         size="small" 
                        //         title="Active" 
                        //         icon={<Icons.RetweetOutlined />} 
                        //         onClick={() => { showUnlockConfirm(record._id) }} 
                        //     ></Button>
                    }
                    </>
                );
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onsearchTB (data: any, mode: any){
        if(mode == "S"){
            // let newDT: any = data;    
            let newDT: any = dataOriginal;
            //console.log('newDT', newDT)
            console.log('valuepetType', valuepetType)
            console.log('searchStat', searchStat)

            if(selProp?.id){
                newDT = newDT?.filter((item:any)=> item?.property?.id == selProp?.id || item?.property?.property_name == selProp?.label);
            }

            if(valuepetType){
                // newDT = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType))
                newDT = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType?.key));
            }

            if(searchStat){
                newDT = newDT?.filter((f: any)=> f?.pet_approve_stat == String(searchStat))
            }

            if(Unit){
                newDT = newDT?.filter((f: any)=> f?.unit?.id == Unit?.id || f?.unit?.unit_name == Unit?.label)
            }
            
            if(selUserType?.label){
                newDT = newDT?.filter((item:any)=> item?.user_type?.user_sub_type?.toLowerCase() == selUserType?.label?.toLowerCase());
            }

            if (!!petName) {
                newDT = newDT?.filter((f: any)=> f?.pet_name.includes(petName))
            }

            setdataTB(newDT)

            // if(valuepetType){
            //     let dtFilter: any = newDT?.filter((f: any)=> f?.pet_regis_obj[0]?.pet_type == String(valuepetType));
            //     setdataTB(dtFilter)
            // }else{ //กรณีกดลบ select แล้วกดปุ่ม search แทนปุ่ม reset
            //     setdataTB(data)
            // }
        }else if(mode == "R"){
            let newDT: any = data;
            setdataTB(newDT);
            setpetType(undefined);
            setselProp(undefined);
            setSearchStat(undefined)
            setUnit(undefined);
            setPetName(undefined);
            setSelUserType(undefined);
            formSearch?.resetFields();
        }
    }

    return (
        <>
      
            <div className="layout_panel">
                <Spin size="large" spinning={isLoading}>
                    <Form 
                        form={formSearch}
                        layout="vertical"
                        style={{marginBottom: "1rem"}}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                onsearchTB(dataTB, "S")
                            }
                          }}
                    >
                        <div className="layout_custom_search">
                            <Form.Item
                                name="property"
                                label=""
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select 
                                    placeholder={t("pet-vacc-list.select-properties")}
                                    {...selProperty} allowClear 
                                    onChange={(value:any, record:any) => { 
                                        setselProp({id:value, label: record?.label})
                                    }}
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Form.Item
                                label=" "
                                name="unit"
                                style={{marginBottom: 0, display: "inline-block"}}
                                className="visible-label"
                            >
                                <Select {...SelUnit}
                                   placeholder={t("pet-vacc-list.select-unit")} 
                                    size="middle" 
                                    onChange={(value:any, record:any) => { 
                                        setUnit({id:value, label: record?.label})
                                    }}
                                    onClear={()=> {
                                        setUnit(undefined);
                                        formSearch.resetFields(["unit"]);
                                    }}
                                    allowClear
                                    showSearch
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Form.Item
                                name="user-sub-type"
                                label=""
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select 
                                    placeholder={t("pet-vacc-list.select-user-type")}
                                    {...selUseType} allowClear 
                                    onChange={(value:any, record:any) => { 
                                        setSelUserType({id:value, label: record?.label})
                                    }}
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Form.Item 
                                label=""
                                name="pet-type"
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select
                                    options={optionpettype}
                                    // placeholder={t("select.sel")}
                                    placeholder={t("pet-vacc-list.pet-type")}
                                    labelInValue
                                    showSearch
                                    allowClear
                                    onChange={(e: any)=> setpetType(e)}
                                    onClear={()=> setpetType(undefined)}
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Form.Item 
                                label=""
                                name="pet-name"
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block", marginRight: "15px"}}
                            >
                                <Input placeholder={t("pet-vacc-list.name")} onChange={(e)=>setPetName(e.target.value)} />
                            </Form.Item>

                            <Form.Item 
                                label=""
                                name="status"
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select
                                    options={optionstatus}
                                    placeholder={t("pet-vacc-list.status")}
                                    showSearch
                                    allowClear
                                    onChange={(e: any)=> setSearchStat(e)}
                                    onClear={()=> setSearchStat(undefined)}
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Button 
                                icon={<Icons.SearchOutlined />} 
                                style={{borderRadius: "5px", marginRight: "15px"}} 
                                type="primary" 
                                onClick={()=> {
                                    onsearchTB(dataTB, "S")
                                }}
                            >
                                {t("buttons.search")}
                            </Button>

                            <Button 
                                icon={<Icons.ReloadOutlined />} 
                                style={{borderRadius: "5px"}} 
                                onClick={()=> {
                                    onsearchTB(dataOriginal, "R")
                                }}
                            >{t("buttons.reset")}</Button>
                        </div>
                    </Form>


                    <Table 
                        dataSource={dataTB ? dataTB : []} 
                        columns={columns}
                        pagination={{
                            size: "small",
                            position: ['bottomRight'],
                            pageSizeOptions: ['10', '30', '50', '100'], 
                            showSizeChanger: true, 
                            pageSize: pageSize,
                            onChange: handlePageSizeChange,
                        }}
                        scroll={{ x: 1300 }}
                    />
                </Spin>
            </div>
        </>
    )
}