import { IResourceComponentsProps, useCustom, useGetIdentity, useList, useNavigation, useTranslate } from "@pankod/refine-core"
import axios from "axios";
import { petURL } from "../../../constants";
import { Button, Col, EditButton, Form, Icons, Modal, Row, Select, Spin, Table, Tag } from "@pankod/refine-antd";
import { useState } from "react";
import dayjs from "dayjs";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

let dataTBLOCAL: any = undefined;
let dataTB: any = undefined;
let optionpettype: any = undefined;
export const PettypeList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { replace, list} = useNavigation();
    const [formSearch] = Form.useForm();
    const { confirm } = Modal;
    const [pageSize, setPageSize] = useState<any>(10);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [valuepetType, setpetType] = useState<any>(undefined);
    const [IsChargeSearch, setIsChargeSearch] = useState<any>(undefined);
    const [dataTB, setdataTB] = useState<any>([]);

    const option_is_charge:any = [
        {
            label: "เสียเงิน",
            value: true,
        },
        {
            label: "ไม่เสียเงิน",
            value: false,
        },
    ]

    const { data, refetch } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataTB(dataLoad);
                dataTBLOCAL = dataLoad;

            },
        },
    });

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        value: dataLoad[index]?.pet_type_name,
                        // value: dataLoad[index]?._id,
                        key: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }

                setLoading(false);

            },
        },
    });

    const {data: user_dt} = useGetIdentity<any>();
    async function updateStatus(id:any, val:any){
        console.log('id', id)
        let data = {
            "is_active": val,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }
        let res_update = await axios.put(
            `${petURL}/api/lscpet/updatePetType?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        refetch();
    }
    
    function showDelConfirm(id:any) {
        confirm({
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
            },
            onCancel() { },
        });
    }


    const columns:any = [
        {
            title: `${t("pet-type.id")}`,
            dataIndex: 'pet_type_code',
            key: 'pet_type_code',
            width: 140,
            sorter: (a:any, b:any) => {
                if (a?.pet_type_code < b?.pet_type_code) {return -1}
                if (a?.pet_type_code > b?.pet_type_code) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.pet_type_code ? record?.pet_type_code : '-'}</span>)
            },
        },
        {
            title: `${t("pet-type.pet-types")}`,
            dataIndex: 'pet_type_name',
            key: 'pet_type_name',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_type_name < b?.pet_type_name) {return -1}
                if (a?.pet_type_name > b?.pet_type_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.pet_type_name ? record?.pet_type_name : '-'}</span>)
            },
        },
        // {
        //     title: `${t("pet-type.charge")}`,
        //     //dataIndex: '',
        //     //key: '',
        //     width: 140,
        //     sorter: (a:any, b:any) => {
        //         if (a?.is_charge < b?.is_charge) {return -1}
        //         if (a?.is_charge > b?.is_charge) {return 1}
        //         return (0)
        //     },
        //     render: (value:any, record:any) => {
        //         return (<span>{record?.is_charge == true ? "เสียเงิน" : 'ไม่เสีย'}</span>)
        //     },
        // },
        {
            title: `${t("pet-type.status")}`,
            dataIndex: '',
            key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.is_active < b?.is_active) {return -1}
                if (a?.is_active > b?.is_active) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>{record?.is_active ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Active</Tag> : <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Inactive</Tag>}</span>
                )
            },
        },
        {
            title: `${t("pet-type.create")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.create_by?.user_name.localeCompare(b?.create_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.create_by ? record?.create_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-type.created-date")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.c < b?.create_date) {return -1}
                if (a?.create_date > b?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.create_date ? dayjs(record?.create_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-type.edit")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-type.last-update")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-type.action")}`,
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                // return (<EditButton hideText size="small" onClick={()=> replace("/pet-types/edit/" + record?._id)}/>);
                return (
                    <>
                    {
                        record.is_active ? 
                            <span>
                                <EditButton style={{marginRight: '2px'}} hideText size="small" onClick={()=> replace("/pet-types/edit/" + record?._id)}/>
                                <Button 
                                    size="small" 
                                    title="Delete" 
                                    icon={<Icons.DeleteOutlined />} 
                                    onClick={() => { showDelConfirm(record._id) }} 
                                    danger
                                ></Button>
                            </span>
                        :
                            <Button 
                                size="small" 
                                title="Active" 
                                icon={<Icons.RetweetOutlined />} 
                                onClick={() => { showUnlockConfirm(record._id) }} 
                            ></Button>
                    }
                    </>
                );
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onsearchTB (data: any, mode: any){
        if(mode == "S"){
            // let newDT: any = data;    
            let newDT: any = dataTBLOCAL;    
            console.log('newDT', newDT)
            if(IsChargeSearch !== undefined){
                newDT = newDT?.filter((f: any)=> f?.is_charge == IsChargeSearch);
                setdataTB(newDT);
            }

            if(valuepetType){
                // newDT = newDT?.filter((f: any)=> f?._id == valuepetType);
                newDT = newDT?.filter((f: any)=> f?._id == String(valuepetType?.key));
                
                setdataTB(newDT);
            }

            setdataTB(newDT)
        }else if(mode == "R"){
            let newDT: any = data;
            
            setdataTB(newDT);
            setpetType(undefined);
            setIsChargeSearch(undefined)
            // formSearch?.resetFields(['pet-type']);
            formSearch?.resetFields();
        }
    }
    
    return (
        <div className="layout_panel">
            <Spin size="large" spinning={isLoading}>
                <Form 
                    form={formSearch}
                    layout="vertical"
                    style={{marginBottom: "1rem"}}
                >
                    <div className="layout_custom_search">
                        <Form.Item 
                            label=" "
                            name="pet-type"
                            className="visible-label"
                            style={{marginBottom: 0, display: "inline-block"}}
                        >
                            <Select
                                options={optionpettype}
                                placeholder={t("pet-type.pet-types")}
                                labelInValue
                                showSearch
                                allowClear
                                onChange={(e: any)=> setpetType(e)}
                                onClear={()=> setpetType(undefined)}
                                style={{width: "250px", marginRight: "15px"}}
                            />
                        </Form.Item>

                        {/* <Form.Item 
                            label=" "
                            name="is-charge"
                            className="visible-label"
                            style={{marginBottom: 0, display: "inline-block"}}
                        >
                            <Select
                                options={option_is_charge}
                                placeholder={t("pet-type.charge")}
                                showSearch
                                allowClear
                                onChange={(e: any)=> setIsChargeSearch(e)}
                                onClear={()=> setIsChargeSearch(undefined)}
                                style={{width: "250px", marginRight: "15px"}}
                            />
                        </Form.Item> */}


                        <Button 
                            icon={<Icons.SearchOutlined />} 
                            style={{borderRadius: "5px", marginRight: "15px"}} 
                            type="primary" 
                            onClick={()=> {
                                onsearchTB(dataTBLOCAL, "S")
                            }}
                        >
                            {t("buttons.search")}
                        </Button>

                        <Button 
                            icon={<Icons.ReloadOutlined />} 
                            style={{borderRadius: "5px"}} 
                            onClick={()=> {
                                onsearchTB(dataTBLOCAL, "R")
                            }}
                        >
                            {t("buttons.reset")}
                        </Button>
                    </div>
                </Form>
                <Table 
                    dataSource={dataTB ? dataTB : []} 
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        pageSizeOptions: ['10', '30', '50', '100'], 
                        showSizeChanger: true, 
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 900 }}
                />
            </Spin>
        </div>
    )
}