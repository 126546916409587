/* eslint-disable */
import { Breadcrumb, Button, Card, Col, Create, Divider, Form, Icons, ImportButton, Input, InputNumber, List, message, Radio, RadioChangeEvent, RcFile, Row, Select, Switch, Upload, useForm, useImport, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useShow, useTranslate  } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { ICoupon, ICouponGroup, IEventlog } from "interfaces";
import { useEffect, useState } from "react";
// import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
// import { useState } from "react";

// import {TinyMceEditor} from "../../components/ck";
let vNewsData:any = [];
let vAeData:any = [];

export const MgnNewsEventCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint          = "even-logs";
    const { Option } = Select;

    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const [vPropID, setPropID] = useState<any>();
    const [disabled, setDisabled] = useState(false);
    const [ vContentType, setContentType ] = useState<any>(undefined);
    const [ vContent, setContent ] = useState<any>(undefined);
    const [ vContentN, setContentN ] = useState<any>(undefined);

    const [ vContentTypeArr, setContentTypeArr] = useState<any>([]);
    const [ vContentNArr, setContentNArr] = useState<any>([]);
    const [ vContentArr, setContentArr] = useState<any>([]);
    const [ vContentSeq, setContentSeq] = useState<any>([]);
    const [ vIndexArr, setIndexArr] = useState<any>([]);

    const qContentNewsData = useList<any>({ 
        resource: "content-news" ,
        metaData: {
            populate: '*',
            locale:['th']
        },
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : "N"
                },
                {
                    field : 'properties][id]',
                    operator : 'contains',
                    value :  vPropID,
                },
                {
                    field : 'short_detail',
                    operator : 'nin',
                    value :  vContentNArr,
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            vNewsData = dataLoad;
        },}
    });

    const qContentAeData = useList<any>({ 
        resource: "content-aes" ,
        metaData: {
            populate: '*',
            locale:['th']
        },
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : "N"
                },
                {
                    field : 'properties][id]',
                    operator : 'contains',
                    value :  vPropID,
                },
                {
                    field : 'short_detail',
                    operator : 'nin',
                    value :  vContentNArr,
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            vAeData = dataLoad;
        },}
    });

    const { form, formProps, saveButtonProps } = useForm<ICouponGroup,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                createNewEventSeq(recordData.data.id);
            },
        }
    );

    const { selectProps: SelProp } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field: "id",
            //     operator: "eq",
            //     value: PropListData
            // }
        ]   
    });


    const { selectProps: selContentPostType } = useSelect<any>({
        resource    : "content-post-types",
        optionLabel : "type_name",
        optionValue : "type_name",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       : 'N',
            },
            {
                field : 'type_name',
                operator : 'contains',
                value :  ["Activity","News"],
            },
        ]
    });

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    function toggleDisabledX() {
        if(disabled == true){
            setDisabled(!disabled);
        }
    }

    function onChangePostType(value:any, label:any) {
        setContentType(label.label);
    }

    function onChangeContent(value:any, label:any, key:any) {
        setContent(value);
        setContentN(label?.children);
    }

    function onsetContentTypeArr(){
        vContentTypeArr.push(vContentType);
        setContentTypeArr(vContentTypeArr);
    }

    function onsetContentArr(){
        vContentArr.push(vContent);
        vContentNArr.push(vContentN);
        setContentArr(vContentArr);
        setContentNArr(vContentNArr);
    }

    function onsetIndexArr(){
        vIndexArr.push(vContentSeq);
        setIndexArr(vIndexArr);
    }

    function clearState(){
        setContent(undefined)
        setContentType(undefined)
        setContentSeq(undefined)
    }
    const { mutate: mutateNewsEntSeq} = useCreate<any>();

    function createNewEventSeq(valueID: any){
        if(vContentArr.length > 0){
            for(let i=0 ; i <= vContentArr.length -1; i++){
                mutateNewsEntSeq({
                    resource    : "news-event-seqs",
                    values      : {
                        content_ae : vContentTypeArr[i] == "Activity & Event" ? vContentArr[i] : null,
                        content_new : vContentTypeArr[i] == "News" ? vContentArr[i] : null,
                        seq : vIndexArr[i],
                        manage_news_event: valueID
                    },
                    // successNotification : false,
                });
            }
        }
        
    }

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                // title={t("coupon-create.title")}
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        {/* <Breadcrumb.Item>{t("coupon-group.title")}</Breadcrumb.Item> */}
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("coupon-group.t-group-name")}</span></Breadcrumb.Item> */}
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("mgn-news-event.create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id
                    values.property = vPropID
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ borderRadius: 5, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={24}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={16}>
                                            <Form.Item  
                                                // label={t("coupon-group.f-group-name")}
                                                label="Title"
                                                name="title"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter title.'
                                                    },
                                                ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={8}>
                                            <Form.Item  label={t("property.title")} name="property"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select property.'
                                                    },
                                                ]}>
                                                    <Select placeholder={t("select.sel")} onChange={(e)=> setPropID(e)} {...SelProp}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>

                        <div style={{marginBottom:'25px'}}></div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.List name="fields">
                            {(fields, { add, remove }) => {
                                return (
                                <div>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={4}>
                                            <Form.Item>
                                                <span style={{fontSize: "0.7vw",color: "#000"}}>Add Sequences</span>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={3}>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => { add(); toggleDisabled();}}
                                                    style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                    disabled={disabled}
                                                >
                                                    <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    {fields.map((field, index) => (
                                        
                                        <div key={field.key}>
                                            <Row gutter={24}>
                                                {setContentSeq(index+1)}

                                                <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "20%"}}><span>{index+1} .</span></div> </Col>
                                                <Col className="gutter-row" span={10}>
                                                    <Row gutter={12}>
                                                        <Col className="gutter-row" span={8}>
                                                            <Form.Item>
                                                                <span style={{fontSize: "0.7vw",color: "#fc0303"}}>*</span>
                                                                <span style={{fontSize: "0.7vw",color: "#000"}}>Content Type</span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className="gutter-row" span={16}>
                                                            <Form.Item
                                                                name={[index, 'content_type']}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select content type'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select {...selContentPostType} onChange={onChangePostType}/>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col className="gutter-row" span={10}>
                                                    <Row gutter={12}>
                                                        <Col className="gutter-row" span={4}>
                                                            <Form.Item>
                                                                <span style={{fontSize: "0.7vw",color: "#fc0303"}}>*</span>
                                                                <span style={{fontSize: "0.7vw",color: "#000"}}>Content</span>
                                                            </Form.Item>
                                                        </Col>
                                                        
                                                        <Col className="gutter-row" span={18}>

                                                        {
                                                            vContentType == "Activity & Event" ?
                                                                <>
                                                                    <Form.Item 
                                                                        // name={[index, 'content']} 
                                                                        rules={[{required: true, message: 'Please select content'},]}
                                                                    >
                                                                        <Select onChange={(e ,value) => onChangeContent(e,value,index)} >
                                                                            {
                                                                                vAeData !== undefined?
                                                                                    vAeData.map((item:any,key:any) => {
                                                                                        // console.log("promotion data",item);
                                                                                        return <><Option value={item.id} key={item.short_detail}>{item.short_detail ? item.short_detail : item.id}</Option></>
                                                                                    })
                                                                                :<></>
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                
                                                                </>
                                                            :
                                                                <>
                                                                    <Form.Item 
                                                                        // name={[index, 'content']} 
                                                                        rules={[{required: true, message: 'Please select content'},]}
                                                                    >
                                                                        <Select onChange={(e ,value) => onChangeContent(e,value,index)} >
                                                                            {
                                                                                vNewsData !== undefined?
                                                                                    vNewsData.map((item:any,key:any) => {
                                                                                        // console.log("promotion data",item);
                                                                                        return <><Option value={item.id} key={item.short_detail}>{item.short_detail ? item.short_detail : item.id}</Option></>
                                                                                    })
                                                                                :<></>
                                                                            }
                                                                        </Select>
                                                                    </Form.Item>
                                                                </>
                                                        }
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col span={2}>
                                                    {
                                                        vContentType !== undefined && vContent !== undefined ?
                                                            <>{fields.length == index+1 ?
                                                                <Button
                                                                    size="small"
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => { onsetContentTypeArr(); onsetContentArr(); onsetIndexArr(); clearState(); toggleDisabled();}}
                                                                    danger
                                                                    style={{marginRight: "3%"}}
                                                                >
                                                                    <Icons.CheckOutlined/>
                                                                </Button>:null
                                                            }
                                                            </>
                                                        :null
                                                    }

                                                    {
                                                        fields.length > 0 ? (
                                                            <Button
                                                                size="small"
                                                                className="dynamic-delete-button"
                                                                onClick={() => { remove(field.name); clearState(); toggleDisabledX(); }}
                                                                danger
                                                            >
                                                                <Icons.CloseOutlined />
                                                            </Button>
                                                        ) : null
                                                    }
                                                </Col>
                                            </Row>
                                            <div style={{marginBottom: "1vh"}}></div>
                                        </div>
                                    ))}
                                </div>
                                );
                            }}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};