import { Breadcrumb, Card, Col, DatePicker, Divider, Edit, Form, Input, List, message, Radio, RadioChangeEvent, RcFile, Row, Select, Space, Upload, UploadFile, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IBannerMgn, IContenPostAnnoucement, IContenPostBlogs, IContentPostActivityEvent, IContentPostPromotion, IContentPostType, IEventlog, IPrivillege, IProperties, IUserSubType } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
// import {  TimePicker } from "antd";
import { CCreateLog } from "../../components/create_log/index";
import dayjs from "dayjs";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export const BannerMgnEdit: React.FC<IResourceComponentsProps> = () => {
    // const { ...uploadProps_pixlist} = useStrapiUpload({ maxCount: 5, });
    const t = useTranslate();
    const { TextArea } = Input;
    // const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";
    const format = 'YYYY-MM-DD HH:mm';
    const { Dragger } = Upload;
    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;
    const [vsetdetail, setdetail] = useState<any>();
    const [vsetdetail_en, setdetail_en] = useState<any>();
    const { mutate: UpdateLocale } = useUpdate<any>();
    // const [ vStatus, setStatus ] = useState<any>("");
    const [vPropertyID, setPropertyID] = useState<any>([]);
    const [vImg, setImg] = useState<any>([]);
    const [vImgEn, setImgEN] = useState<any>([]);
    const [vInputSeq, setInputSeq] = useState<any>(0);
    const [vIsNewOrOld, setIsNewOrOld] = useState<boolean>();
    const { Option } = Select;

    const [reqImgTh, setReqImgTh] = useState<any>(false);
    const [reqImgEn, setReqImgEn] = useState<any>(false);
    
    // const [ vContentDisplay, setContentDisplay]   = useState<any>("Promotion");
    // const [ count, setCount] = useState(0);
    const { form, formProps, saveButtonProps } = useForm<IBannerMgn, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                <CCreateLog
                    endpoint={log_endpoint}
                    data={{
                        user_profile: user_data.user_profile.id,
                        log_time_stamp: Date.now(),
                        log_event: "Create",
                        menu_event: 'coupon',
                        SysPkID: recordData.data.id + ""
                    }}
                />
            },
        }
    );

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        // console.log('fileeeeeeeeeeeee', file)
                        body.append("files", file);
                        // let headers = new Headers();
                        // headers.append("Origin", "http://localhost:3000");
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                            method: "post",
                            body: body
                            // mode: "no-cors"
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                // console.log('resssssssssssssssss', res)
                                resolve({
                                    //   default: `${API_URL}/${res.filename}`
                                    default: res[0].url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    let CkConfig: any;
    CkConfig = {
        toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            // "ckfinder",
            "|",
            "imageTextAlternative",
            "imageUpload",
            "imageStyle:full",
            "imageStyle:side",
            // "|",
            // "mediaEmbed",
            // "insertTable",
            // "tableColumn",
            // "tableRow",
            // "mergeTableCells",
            // "|",
            // "undo",
            // "redo"
        ],
        extraPlugins: [uploadPlugin],
        mediaEmbed: {
            // configuration...
            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
        },
        image: {
            toolbar: ['']
        }
    };

    const { queryResult } = useShow<IBannerMgn>({
        metaData: {
            // populate: '*',
            // populate: ['*','localizations', 'localizations.pic'],
            populate: [
                'properties',
                'user_types',
                'content_ae',
                'content_announcement',
                'content_blog',
                'content_promotion',
                'privilege',
                'user_sub_types',
                'content_new',
                'updateBy',
                'createBy',
                'pic',
                'localizations',
                'localizations.pic'
            ],
            localizations: {
                // populate: ['pic']
                populate: [
                    'properties',
                    'user_types',
                    'content_ae',
                    'content_announcement',
                    'content_blog',
                    'content_promotion',
                    'privilege',
                    'user_sub_types',
                    'content_new',
                    'updateBy',
                    'createBy',
                    'pic',
                ],
            }
        },
    });
    const { data } = queryResult;
    let recordData: any = []
    recordData = data?.data ? data?.data : [];
    console.log('recordData kom xxxx', recordData)
    // const [ vTimeSlotTempTEST, setTimeSlotTempTEST ]    = useState<any>([]);
    // const [ vTimeSlotTEST, setTimeSlotTEST ]    = useState<any>([]);
    const [vDateStart, setDateStart] = useState<any>("");
    const [vDateEnd, setDateEnd] = useState<any>("");
    const [vPublicDate, setPublicDate] = useState<any>(null);

    const [vContent, setContent] = useState<any>("");
    const [vContentName, setContentName] = useState<any>("");
    const [vContentType, setContentType] = useState<any>("");
    const [vOnlyRightMulti, setOnlyRightMulti] = useState<any>([]);
    const [vRaSpecificProperty, setRaSpecificProperty] = useState<any>(0);

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "banner-managements",
        metaData: {
            populate: [
                '*',
                'properties',
                'user_types',
                'content_ae',
                'content_announcement',
                'content_blog',
                'content_promotion',
                'privilege',
                'user_sub_types',
                'content_new',
                'updateBy',
                'createBy',
            ],
            locale: ['th']
        },
        pagination: {
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
            onSuccess: (data) => {
                console.log('data xxxx : ', data.data)
                callFetchData();

            },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
    }, []);

    function callFetchData() {
        setDateStart(recordData.start_date ? recordData.start_date : '');
        setDateEnd(recordData.end_date ? recordData.end_date : '');
        setPublicDate([recordData.start_date ? dayjs(recordData.start_date) : dayjs(), recordData.end_date ? dayjs(recordData.end_date) : dayjs()])

        // หา content post ที่เลือกจากของเดิม ว่าเป็น content post แบบไหน
        setContentType(recordData.tag ? recordData.tag : '')

        // set content
        // if(recordData?.content_ae !== null){
        //     setContent(recordData?.content_ae.id)
        //     setContentName(recordData?.content_ae.short_detail)
        // }else if(recordData?.content_announcement !== null){
        //     setContent(recordData?.content_announcement.id)
        //     setContentName(recordData?.content_announcement.short_detail)
        // }else if(recordData?.content_blog !== null){
        //     setContent(recordData?.content_blog.id)
        //     setContentName(recordData?.content_blog.short_detail)
        // }else if(recordData?.content_new !== null){
        //     setContent(recordData?.content_new.id)
        //     setContentName(recordData?.content_new.short_detail)
        // }else if(recordData?.content_promotion !== null){
        //     setContent(recordData?.content_promotion.id)
        //     setContentName(recordData?.content_promotion.short_detail)
        // }else if(recordData?.privilege !== null){
        //     setContent(recordData?.privilege.id)
        //     setContentName(recordData?.privilege.short_detail)
        // }

        if (!!recordData?.content_ae) {
            setContent(recordData?.content_ae.id)
            setContentName(recordData?.content_ae.short_detail)
        } else if (!!recordData?.content_announcement) {
            setContent(recordData?.content_announcement.id)
            setContentName(recordData?.content_announcement.short_detail)
        } else if (!!recordData?.content_blog) {
            setContent(recordData?.content_blog.id)
            setContentName(recordData?.content_blog.short_detail)
        } else if (!!recordData?.content_new) {
            setContent(recordData?.content_new.id)
            setContentName(recordData?.content_new.short_detail)
        } else if (!!recordData?.content_promotion) {
            setContent(recordData?.content_promotion.id)
            setContentName(recordData?.content_promotion.short_detail)
        } else if (!!recordData?.privilege) {
            setContent(recordData?.privilege.id)
            setContentName(recordData?.privilege.short_detail)
        }

        if (recordData?.properties?.length > 0) {
            vPropertyID.pop();
            vPropertyID.push(
                recordData?.properties.map((v: any) => {
                    return v.id
                })
            )
            setPropertyID(vPropertyID);
        }

        setRaSpecificProperty(recordData?.property_dev ? recordData?.property_dev : "")
        // setIsNewOrOld(recordData?.banner_type ? recordData?.banner_type : "")
        setIsNewOrOld(recordData?.banner_type)

        if (recordData?.user_sub_types?.length > 0) {
            vOnlyRightMulti.pop();
            vOnlyRightMulti.push(
                recordData?.user_sub_types.map((v: any) => {
                    return v.id
                })
            )
            setOnlyRightMulti(vOnlyRightMulti);
        }

        form.setFieldsValue({
            properties:
                recordData?.properties !== undefined ?
                    recordData?.properties.length > 0 ?
                        recordData?.properties.map((v: any) => {
                            // console.log(v)
                            return { value: v.id, label: v.property_name }
                        })
                        : form.resetFields(["properties"])
                    : form.resetFields(["properties"]),

            user_sub_types:
                recordData?.user_sub_types !== undefined ?
                    recordData?.user_sub_types.length > 0 ?
                        recordData?.user_sub_types.map((v: any) => {
                            // console.log(v)
                            return { value: v.id, label: v.sub_type_name }
                        })
                        : form.resetFields(["user_sub_types"])
                    : form.resetFields(["user_sub_types"]),
        })

        setInputSeq(recordData.seq ? recordData.seq : 0);

        if(recordData?.pic){
            setReqImgTh(false)

            const fileList: UploadFile[] =
                recordData.pic ?
                    recordData.pic.map((v: any) => (
                        {
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url:
                                v.url,
                            thumbUrl:
                                v.url
                        }
                    ))
                    : null
                ;
    
            setImg({
                name: 'files',
                multiple: true,
                defaultFileList: [...fileList],
            })
            form.resetFields(["pic"]);
        }else{
            setReqImgTh(true)
        }


        // const fileListEN: UploadFile[] = 
        //     recordData.localizations.pic ?
        //         recordData.localizations.pic.map((v:any)=>(
        //             {
        //                 uid: String(v.id),
        //                 name: v.name,
        //                 status: "done",
        //                 url:
        //                     v.url,
        //                 thumbUrl:
        //                     v.url
        //             }
        //         )) 
        //     : null
        // ;

        console.log('recordData', recordData)
        console.log('recordData?.pic', recordData?.pic)
        console.log('recordData?.pic_en', recordData?.pic_en)

        if(recordData?.localizations[0]?.pic){
            console.log('recordData?.pic_en', recordData?.localizations?.pic)
            setReqImgEn(false)
            const fileListEN: UploadFile[] =
                // recordData.pic_en ?
                //     recordData.pic_en.map((v: any) => (
                //         {
                //             uid: String(v.id),
                //             name: v.name,
                //             status: "done",
                //             url:
                //                 v.url,
                //             thumbUrl:
                //                 v.url
                //         }
                //     ))
                //     : null
                // ;
                recordData?.localizations[0]?.pic ?
                    recordData?.localizations[0]?.pic.map((v: any) => (
                        {
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url:
                                v.url,
                            thumbUrl:
                                v.url
                        }
                    ))
                    : null
                ;
    
            setImgEN({
                name: 'files',
                multiple: true,
                defaultFileList: [...fileListEN],
            })
            form.resetFields(["pic_en"]);

        }else{
            setReqImgEn(true)
        }
    }

    // useEffect(() => {
    //     if (count < 3) { 
    //         const interval = setInterval(() => { 
    //             setCount(prev => prev + 1)
    //             setDateStart(recordData.start_date ? recordData.start_date : '');
    //             setDateEnd(recordData.end_date ? recordData.end_date : '');
    //             setPublicDate([recordData.start_date ? dayjs(recordData.start_date) : dayjs(), recordData.end_date ? dayjs(recordData.end_date) : dayjs()])


    //             // หา content post ที่เลือกจากของเดิม ว่าเป็น content post แบบไหน
    //             setContentType(recordData.tag ? recordData.tag : '')

    //             // set content
    //             if(recordData?.content_ae !== null){
    //                 setContent(recordData?.content_ae.id)
    //                 setContentName(recordData?.content_ae.short_detail)
    //             }else if(recordData?.content_announcement !== null){
    //                 setContent(recordData?.content_announcement.id)
    //                 setContentName(recordData?.content_announcement.short_detail)
    //             }else if(recordData?.content_blog !== null){
    //                 setContent(recordData?.content_blog.id)
    //                 setContentName(recordData?.content_blog.short_detail)
    //             }else if(recordData?.content_new !== null){
    //                 setContent(recordData?.content_new.id)
    //                 setContentName(recordData?.content_new.short_detail)
    //             }else if(recordData?.content_promotion !== null){
    //                 setContent(recordData?.content_promotion.id)
    //                 setContentName(recordData?.content_promotion.short_detail)
    //             }else if(recordData?.privilege !== null){
    //                 setContent(recordData?.privilege.id)
    //                 setContentName(recordData?.privilege.short_detail)
    //             }

    //             // vPropertyID.pop();
    //             // vPropertyID.push(recordData?.properties !== undefined ?
    //             //     recordData.properties.length > 0 ? 
    //             //         recordData?.properties.map((v:any)=>{
    //             //             // return {value:v.id}
    //             //             return v.id
    //             //         }) 
    //             //     : <></>
    //             // : <></>)
    //             // setPropertyID(vPropertyID);


    //             if(recordData.properties.length > 0){
    //                 vPropertyID.pop();
    //                 vPropertyID.push(
    //                     recordData?.properties.map((v:any)=>{
    //                         return v.id
    //                     }) 
    //                 )
    //                 setPropertyID(vPropertyID);
    //             }


    //             setRaSpecificProperty(recordData?.property_dev ? recordData?.property_dev : "")
    //             // setIsNewOrOld(recordData?.banner_type ? recordData?.banner_type : "")
    //             setIsNewOrOld(recordData?.banner_type)

    //             // setOnlyRightMulti(recordData?.user_sub_types ? recordData?.user_sub_types : "");

    //             if(recordData.user_sub_types.length > 0){
    //                 vOnlyRightMulti.pop();
    //                 vOnlyRightMulti.push(
    //                     recordData?.user_sub_types.map((v:any)=>{
    //                         return v.id
    //                     }) 
    //                 )
    //                 setOnlyRightMulti(vOnlyRightMulti);
    //             }

    //             form.setFieldsValue({
    //                 properties: 
    //                     recordData?.properties !== undefined ?
    //                         recordData?.properties.length > 0 ? 
    //                             recordData?.properties.map((v:any)=>{
    //                                 // console.log(v)
    //                                 return {value:v.id, label:v.property_name}
    //                             }) 
    //                         : form.resetFields(["properties"])
    //                     : form.resetFields(["properties"]),

    //                 user_sub_types: 
    //                     recordData?.user_sub_types !== undefined ?
    //                         recordData?.user_sub_types.length > 0 ? 
    //                             recordData?.user_sub_types.map((v:any)=>{
    //                                 // console.log(v)
    //                                 return {value:v.id, label:v.sub_type_name}
    //                             }) 
    //                         : form.resetFields(["user_sub_types"])
    //                     : form.resetFields(["user_sub_types"]),
    //             })


    //             setInputSeq(recordData.seq ? recordData.seq : 0);

    //             // const fileList2: UploadFile[] = [
    //             //     {
    //             //         uid: recordData.pic.id,
    //             //         name: recordData.pic.name,
    //             //         status: "done",
    //             //         url:
    //             //             recordData.pic.url,
    //             //         thumbUrl:
    //             //             recordData.pic.url
    //             //     }
    //             // ];

    //             // setImg({
    //             //     name: 'files',
    //             //     multiple: true,
    //             //     defaultFileList: [...fileList2],
    //             // })

    //             const fileList: UploadFile[] = 
    //                 recordData.pic ?
    //                     recordData.pic.map((v:any)=>(
    //                         {
    //                             uid: String(v.id),
    //                             name: v.name,
    //                             status: "done",
    //                             url:
    //                                 v.url,
    //                             thumbUrl:
    //                                 v.url
    //                         }
    //                     )) 
    //                 : null
    //             ;

    //             setImg({
    //                 name: 'files',
    //                 multiple: true,
    //                 defaultFileList: [...fileList],
    //             })
    //             form.resetFields(["pic"]);


    //             const fileListEN: UploadFile[] = 
    //                 recordData.pic_en ?
    //                     recordData.pic_en.map((v:any)=>(
    //                         {
    //                             uid: String(v.id),
    //                             name: v.name,
    //                             status: "done",
    //                             url:
    //                                 v.url,
    //                             thumbUrl:
    //                                 v.url
    //                         }
    //                     )) 
    //                 : null
    //             ;

    //             setImgEN({
    //                 name: 'files',
    //                 multiple: true,
    //                 defaultFileList: [...fileListEN],
    //             })
    //             form.resetFields(["pic_en"]);

    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count])

    // console.log('vOnlyRightMulti', vOnlyRightMulti)

    let initF: any;
    initF = {
        field: 'deleteStatus',
        operator: 'contains',
        value: 'N',
    };

    const { selectProps: PropertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [initF]
    });

    const API_URL = useApiUrl();
    const { ...uploadProps } = useStrapiUpload(
        {
            maxCount: 1,
        }
    );

    function fileChange(e: any) {
        setKeepStatusIMG(e?.fileList.length)
        console.log(e);
        if (e.file.response) {
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
        }
    }

    function selectTime(time: any, timeString: any) {
        // console.log('time_start',timeString)
        // setDateStart(dayjs(timeString[0]));
        // setDateEnd(dayjs(timeString[1]));
        // vTimeSlotTempTEST.push({time_start: timeString[0], time_end: timeString[1]});
        // setTimeSlotTEST(vTimeSlotTempTEST);
        // removeAndSortArr();

        if (timeString[0] == '' || timeString[1] == '') {
            setPublicDate(null)
        } else {
            setPublicDate(returnDayJsDateRange(timeString[0], timeString[1]))
        }
    }

    const returnDayJsDateRange = (start: string | number | Date | dayjs.Dayjs | null | undefined, finish: string | number | Date | dayjs.Dayjs | null | undefined) => {
        // console.log("start", start);
        // console.log("finish", finish);
        // return [dayjs(start, "YYYY-MM-DD HH:mm"), dayjs(finish, "YYYY-MM-DD HH:mm")];
        return [dayjs(start), dayjs(finish)];
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const onChangeRadio5 = (e: RadioChangeEvent) => {
        // console.log('onChangeRadio5', e.target.value);
        setRaSpecificProperty(e.target.value);
        // console.log('vRaPrivCoupon', vRaPrivCoupon)
    };

    const onChangeRadioNew = (e: RadioChangeEvent) => {
        // console.log('onChangeRadioNew', e.target.value);
        setIsNewOrOld(e.target.value);
        // console.log('vRaPrivCoupon', vRaPrivCoupon)
        form?.resetFields()
    };
    const [ vKeep, setVKeep ] = useState<any>([]);
    const [ keepStatusIMG, setKeepStatusIMG ] = useState<any>(undefined);
    function onChangePropertyID(va: any) {
        let keepNeww:any = []
        for (let index = 0; index < va.length; index++) {
            keepNeww.push(va[index])
        }
        setVKeep(keepNeww)
        
        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
        // console.log('selected',vPropertyID[0]);
        // console.log('all', unitTypeData.data?.data);
        // console.log('diff', difference);
    };

    function onChangeOnlyRightMulti(va: any) {
        vOnlyRightMulti.pop();
        vOnlyRightMulti.push(va);
        setOnlyRightMulti(vOnlyRightMulti);
        console.log('vOnlyRightMulti', vOnlyRightMulti);
    }

    // console.log('vOnlyRightMulti', vOnlyRightMulti)

    function onChangeContent(value: any, key: any) {
        console.log('onChangeContent 1', value)
        // console.log('onChangeContent 2', key)
        setContent(value);
        setContentName(key.key)
    }

    // console.log('vContentName', vContentName)
    function onChangeContentType(value: any, label: any) {
        // console.log('content name insert to tag', label);
        // console.log('content id', value);
        setContentType(label.label);
    }

    const { selectProps: PrivUserSubTypeOnlyCus } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    const { selectProps: selContentPostType } = useSelect<IContentPostType>({
        resource: "content-post-types",
        optionLabel: "type_name",
        optionValue: "type_name",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N',
            },
        ]
    });

    // *endpoint content post*
    // content-promotions
    // content-news
    // content-blogs
    // content-announcements
    // content-aes
    // privileges
    const qContentPromotionData = useList<IContentPostPromotion>({
        resource: "content-promotions",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: promotionData } = qContentPromotionData;
    let vPromotionData: any = []
    vPromotionData = promotionData?.data ? promotionData?.data : [];

    const qContentNewsData = useList<IContentPostPromotion>({
        resource: "content-news",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: newsData } = qContentNewsData;
    let vNewsData: any = []
    vNewsData = newsData?.data ? newsData?.data : [];

    const qContentBlogData = useList<IContenPostBlogs>({
        resource: "content-blogs",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: blogData } = qContentBlogData;
    let vBlogData: any = []
    vBlogData = blogData?.data ? blogData?.data : [];

    const qContentAnnData = useList<IContenPostAnnoucement>({
        resource: "content-announcements",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: annData } = qContentAnnData;
    let vAnnData: any = []
    vAnnData = annData?.data ? annData?.data : [];

    const qContentAeData = useList<IContentPostActivityEvent>({
        resource: "content-aes",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: aeData } = qContentAeData;
    let vAeData: any = []
    vAeData = aeData?.data ? aeData?.data : [];

    const qContentPrivData = useList<IPrivillege>({
        resource: "privileges",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: 'deleteStatus',
                    operator: 'contains',
                    value: 'N',
                },
            ]
        },
    });
    const { data: privData } = qContentPrivData;
    let vPrivData: any = []
    vPrivData = privData?.data ? privData?.data : [];

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];
    const [vValueLocale, setValueLocale] = useState(true);
    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setValueLocale(value);
        if (value) {
            console.log('th')
        } else {
            console.log('en')
        }
    };

    const [vShortDetails, setShortDetails] = useState('');
    const onChangeShortDetail = (e: any) => {
        setShortDetails(e.target.value);
    }

    function updateLocaleEn(value: any) {
        UpdateLocale({
            resource: 'banner-managements',
            id: recordData.localizations[0].id,
            values: {
                news_event_seqs: value,
            },
            successNotification: false,
        }, {
            onSuccess(data, variables, context) {
                // close();
            },
        }
        )
    }

    // console.log('recordData', recordData)

    // console.log('vOnlyRightMulti', vOnlyRightMulti)
    return (
        <Edit saveButtonProps={keepStatusIMG == 0 ? { disabled:true} : saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List
                // title={t("banner-mgn.title-create")}
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("banner-mgn.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("banner-mgn.title-edit")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    values.createBy = user_data.user_profile.id
                    // values.start_date = vDateStart
                    // values.end_date = vDateEnd

                    values.start_date = vPublicDate[0]
                    values.end_date = vPublicDate[1]
                    // values.detail = vsetdetail
                    values.detail_2 = vsetdetail
                    values.detail_en = vsetdetail_en
                    values.seq = vInputSeq
                    values.property_dev = vRaSpecificProperty

                    values.banner_type = vIsNewOrOld

                    // values.properties = vPropertyID[0]
                    if (vPropertyID.length > 0) {
                        values.properties = vPropertyID[0]
                    }

                    values.user_sub_types = vOnlyRightMulti[0]

                    values.locale = "th"

                    if (vIsNewOrOld == false) { // เลือก content จากของที่มีอยู่
                        // console.log('ffff', vContentName)
                        values.title = vContentName

                        vContentType == "Promotion" ? values.content_promotion = vContent : <></>
                        vContentType == "Privilege" ? values.privilege = vContent : <></>
                        vContentType == "News" ? values.content_new = vContent : <></>
                        vContentType == "Blog" ? values.content_blog = vContent : <></>
                        vContentType == "Announcement" ? values.content_announcement = vContent : <></>
                        vContentType == "Activity & Event" ? values.content_ae = vContent : <></>
                    }

                    // if(vIsNewOrOld){
                    //     if(vValueLocale == false){ // EN
                    //         values.detail_en = vsetdetail_en
                    //         values.pic_en = values?.pic_en.id
                    //         updateLocaleEn(values);
                    //     }                    
                    // }


                    // vContentType == "Promotion" ? values.content_promotion = vContent : <></> 
                    // vContentType == "Privilege" ? values.privilege = vContent : <></>
                    // vContentType == "News" ? values.content_new = vContent : <></> 
                    // vContentType == "Blog" ? values.content_blog = vContent : <></>
                    // vContentType == "Announcement" ? values.content_announcement = vContent : <></> 
                    // vContentType == "Activity & Event" ? values.content_ae = vContent : <></>

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>

                            <Radio.Group onChange={onChangeRadioNew} value={vIsNewOrOld}>
                                <Radio value={true}>สร้างใหม่</Radio>
                                <Radio value={false}>เลือกจากข้อมูลในระบบ</Radio>
                            </Radio.Group>

                            <div style={{ marginBottom: '2%' }}></div>

                            {
                                vIsNewOrOld ? // สร้างใหม่
                                    <>
                                        {/* <Form.Item  label="Title" name="title"
                                            rules={[{required: true, message: 'Please enter title.'},]}
                                        >
                                            <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                        </Form.Item> */}
                                        <Row>
                                            <Col span={12}>
                                                <div style={{ marginBottom: '2%' }}>
                                                    <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>

                                            {
                                                vValueLocale ? // TH
                                                    <>
                                                        <Col span={12}>
                                                            <Form.Item label="Title" name="title"
                                                                style={{ width: "95%" }}
                                                                rules={[{ required: true, message: 'Please enter title.' },]}
                                                            >
                                                                <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item label="Short Detail" name="short_detail"
                                                                rules={[{ required: true, message: 'Please enter short detail.' },]}
                                                            >
                                                                <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                                // defaultValue={vShortDetails}
                                                                // onChange={(e) => onChangeShortDetail(e)} 
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                                    :
                                                    <>
                                                        <Col span={12}>
                                                            <Form.Item label="Title (EN)" name="title_en"
                                                                style={{ width: "95%" }}
                                                                rules={[{ required: true, message: 'Please enter title (EN).' },]}
                                                            >
                                                                <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item label="Short Detail (EN)" name="short_detail_en"
                                                                rules={[{ required: true, message: 'Please enter short detail.' },]}
                                                            >
                                                                <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                                    onChange={(e) => onChangeShortDetail(e)}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </>
                                            }
                                            {/* <Col span={12}>
                                                <Form.Item  label="Title" name="title"
                                                    style={{width:"95%"}}
                                                    rules={[{required: true, message: 'Please enter title.'},]}
                                                >
                                                    <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                            
                                                <Form.Item  label="Short Detail" name="short_detail"
                                                    rules={[{required: true, message: 'Please enter short detail.'},]}
                                                >
                                                    <TextArea style={{height: "30px", maxHeight: "80px"}}  
                                                        // defaultValue={vShortDetails}
                                                        onChange={(e) => onChangeShortDetail(e)} 
                                                    />
                                                </Form.Item>
                                            </Col> */}
                                        </Row>


                                        {
                                            vValueLocale ? // TH
                                                <>
                                                    <Form.Item label="Detail"
                                                    // name="detail"
                                                    // rules={[
                                                    //     {
                                                    //         required: true,
                                                    //     },
                                                    // ]}
                                                    >
                                                        <CKEditor
                                                            // name="detail"
                                                            editor={ClassicEditor}
                                                            data={recordData.detail_2 ? recordData.detail_2 : ''}
                                                            config={CkConfig}
                                                            onInit={(editor: any) => { }}
                                                            onChange={(event: any, editor: any) => {
                                                                const data = editor.getData();
                                                                setdetail(data);
                                                            }}
                                                            onBlur={(editor: any) => { }}
                                                            onFocus={(editor: any) => { }}
                                                        />
                                                    </Form.Item>

                                                    <Row>
                                                        <Col span={11}>
                                                            <Form.Item
                                                                name="tag"
                                                                label="tag"
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select tag.'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    placeholder={t("select.sel")}
                                                                    // mode="multiple"
                                                                    {...selContentPostType}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    <Form.Item label="Detail (EN)">
                                                        <CKEditor
                                                            // name="detail"
                                                            editor={ClassicEditor}
                                                            data={recordData.detail_en ? recordData.detail_en : ''}
                                                            config={CkConfig}
                                                            onInit={(editor: any) => { }}
                                                            onChange={(event: any, editor: any) => {
                                                                const data = editor.getData();
                                                                setdetail_en(data);
                                                            }}
                                                            onBlur={(editor: any) => { }}
                                                            onFocus={(editor: any) => { }}
                                                        />
                                                    </Form.Item>
                                                </>
                                        }

                                    </>
                                    :
                                    vIsNewOrOld == false ? // เลือกจากของเดิม
                                        <>
                                            <Row>
                                                <Col span={5}>
                                                    <span>
                                                        <b><div style={{ fontSize: "12px" }}>Content Type : </div><>&nbsp;</></b>
                                                    </span>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="tag">
                                                        <Select placeholder={t("select.sel")}
                                                            onChange={onChangeContentType}
                                                            {...selContentPostType}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={5}>
                                                    <span>
                                                        <b><div style={{ fontSize: "12px" }}>Title : </div><>&nbsp;</></b>
                                                    </span>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="title"
                                                        rules={[
                                                            {
                                                                required: true, message: 'Please select content title.'
                                                            },
                                                        ]}
                                                    >
                                                        <Select showSearch
                                                            allowClear
                                                            placeholder="Type to search"
                                                            // {...SelFaciliType} 
                                                            onChange={onChangeContent}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA!.children as unknown as string)
                                                                    .toLowerCase()
                                                                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                                                            }
                                                        >
                                                            {
                                                                vContentType == "Promotion" ?
                                                                    vPromotionData !== undefined ?
                                                                        vPromotionData.map((item: any, key: any) => {
                                                                            console.log("promotion data", item);
                                                                            // return <><Option value={item.id} key={item.short_detail} >{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name} >{item.name}</Option></>
                                                                        })
                                                                        :
                                                                        <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "News" ?
                                                                    vNewsData !== undefined ?
                                                                        vNewsData.map((item: any, key: any) => {
                                                                            console.log("promotion data", item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Blog" ?
                                                                    vBlogData !== undefined ?
                                                                        vBlogData.map((item: any, key: any) => {
                                                                            console.log("promotion data", item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Announcement" ?
                                                                    vAnnData !== undefined ?
                                                                        vAnnData.map((item: any, key: any) => {
                                                                            console.log("promotion data", item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Activity & Event" ?
                                                                    vAeData !== undefined ?
                                                                        vAeData.map((item: any, key: any) => {
                                                                            console.log("promotion data", item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Privilege" ?
                                                                    vPrivData !== undefined ?
                                                                        vPrivData.map((item: any, key: any) => {
                                                                            console.log("promotion data", item);
                                                                            // return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                            return <><Option value={item.id} key={item.name}>{item.name}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}></Col>
                                            </Row>
                                        </>
                                        :
                                        // โหลดหน้ามา boolean ยังไม่มีค่า ไม่แสดงอะไรเลย
                                        <></>
                            }

                            <div style={{ marginBottom: '2%' }}></div>
                            {
                                vValueLocale ? // TH
                                    <>
                                        <Row>
                                            <Col span={3}>
                                                {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                                <span>
                                                    <b><div style={{ fontSize: "12px" }}>Properties : </div><>&nbsp;</></b>
                                                </span>
                                            </Col>

                                            <Col span={15}>
                                                <Radio.Group onChange={onChangeRadio5} value={vRaSpecificProperty}>
                                                    <Space direction="vertical">
                                                        <div>
                                                            <Radio value="All" style={{ marginBottom: "0%" }}> ทุกโครงการ </Radio>
                                                        </div>
                                                        <div>
                                                            <Radio value="Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Major เท่านั้น </Radio>
                                                        </div>

                                                        <div>
                                                            <Radio value="Non Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Non Major เท่านั้น </Radio>
                                                        </div>
                                                        <div style={{ width: '250px' }}>
                                                            <Radio value="Properties" style={{ marginBottom: "0%" }}> ระบุโครงการ </Radio>
                                                            {
                                                                vRaSpecificProperty == "Properties" ?
                                                                    <>
                                                                        <div style={{ marginBottom: '2%' }}></div>
                                                                        <Form.Item
                                                                            // name="privilege_properties"
                                                                            name="properties"
                                                                            rules={[
                                                                                {
                                                                                    required: vKeep.length == 0 ? true : false, message: 'Please select property.'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                size="small"
                                                                                style={{ width: "95%" }}
                                                                                mode="multiple"
                                                                                placeholder={t("select.sel")}
                                                                                {...PropertySelectProps}
                                                                                onChange={onChangePropertyID}
                                                                            />
                                                                        </Form.Item>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </Space>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <div style={{ marginBottom: '1%' }}></div>

                                        <Row>
                                            <Col span={11}>
                                                <Form.Item
                                                    // name="user_types"
                                                    name="user_sub_types"
                                                    label="User Type"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select user type.'
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        // size="small"
                                                        style={{ width: "95%" }}
                                                        mode="multiple"
                                                        placeholder={t("select.sel")}
                                                        onChange={onChangeOnlyRightMulti}
                                                        {...PrivUserSubTypeOnlyCus}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    // name="start_date"
                                                    label="Content public date"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select public date.'
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker.RangePicker
                                                        // defaultValue={[ dayjs(recordData.start_date), dayjs(recordData.end_date)]}
                                                        // value={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                        value={vPublicDate}
                                                        // defaultValue={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                        showTime
                                                        style={{ width: "100%" }}
                                                        onChange={(date, dateString) => selectTime(date, dateString)}
                                                        format={format}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col span={11}>
                                                <Form.Item name="seq"
                                                    // label={t("notice-ch")} 
                                                    // label="Content Promotion Sequences"
                                                    label="Sequences"
                                                >
                                                    <Input
                                                        type={"number"}
                                                        placeholder="Sequences"
                                                        // defaultValue={0}
                                                        min={0}
                                                        // size="small"
                                                        style={{ width: "100%", borderRadius: "5px" }}
                                                        // value={vInputSeq}
                                                        onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}></Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="status"
                                                    label="Status"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select status.'
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder={t("select.sel")}
                                                        style={{ width: '100%' }}
                                                        onSelect={(value: any) => {
                                                            // console.log(value);
                                                            // setStatus(value);
                                                        }}
                                                        options={[
                                                            {
                                                                label: "Public",
                                                                value: "PUBLIC",
                                                            },
                                                            {
                                                                label: "Draft",
                                                                value: "DRAFT",
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row> */}

                                        <Row>
                                            <Col span={11}>
                                                <Form.Item
                                                    // label={t("notice-ch")} 
                                                    // label="Content Promotion Sequences"
                                                    label="External link"
                                                    name="link_external"
                                                >
                                                    <Input
                                                        // type={"number"}
                                                        placeholder="external link"
                                                        // defaultValue={0}
                                                        min={0}
                                                        // size="small"
                                                        style={{ width: "100%", borderRadius: "5px" }}
                                                    // value={vInputSeq}
                                                    // onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    <></>
                            }
                        </Card>
                    </Col>
                    {console.log(">>> vImg",vImg)}

                    {
                        vIsNewOrOld ?
                            <>
                                <Col id="Col_w" className="gutter-row" span={6}>
                                    <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                        <div style={{ marginBottom: 20 }}><span className="title_panel"><span style={{ color: "#f5222d", fontWeight: "300", fontSize: "16px", marginRight: "5px" }}>*</span>Thumbnail</span></div>
                                        <Divider></Divider>

                                        {
                                            vValueLocale ? // TH
                                                <>
                                                    <Form.Item
                                                        name="pic"
                                                        rules={[
                                                            {
                                                                //required: reqImgTh, message: 'Please upload image.'
                                                                required: vImgEn !== [] ? false : true
                                                            },
                                                        ]}
                                                    // valuePropName="fileList"
                                                    // getValueProps={(data) => getValueProps(data, API_URL)}
                                                    >
                                                        <Dragger
                                                            {...vImg}
                                                            style={{ borderRadius: 10 }}
                                                            name="files"
                                                            action={`${API_URL}/upload`}
                                                            headers={{
                                                                Authorization: `Bearer ${localStorage.getItem(
                                                                    TOKEN_KEY,
                                                                )}`,
                                                            }}
                                                            listType="picture"
                                                            value={vImg?.defaultFileList}
                                                            // multiple
                                                            // {...uploadProps}
                                                            onChange={fileChange}
                                                            beforeUpload={beforeUpload}
                                                        >
                                                            <p>Upload</p>
                                                        </Dragger>
                                                    </Form.Item>
                                                    <p style={{display: keepStatusIMG == 0 && vImgEn !== [] ? 'flex' : 'none', color: 'red'}}>Please upload image.</p>
                                                    <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Picture 750*500 PNG, JPG Maximum size 5MB</p>
                                                </>
                                                :
                                                <>
                                                    <Form.Item
                                                        name="pic_en"
                                                    // name={["pic_en", "id"]}
                                                        rules={[
                                                            {
                                                                //required: reqImgEn, message: 'Please upload image.'
                                                                required: vImgEn !== [] ? false : true
                                                            },
                                                        ]}
                                                    >
                                                        <Dragger
                                                            {...vImgEn}
                                                            style={{ borderRadius: 10 }}
                                                            name="files"
                                                            action={`${API_URL}/upload`}
                                                            headers={{
                                                                Authorization: `Bearer ${localStorage.getItem(
                                                                    TOKEN_KEY,
                                                                )}`,
                                                            }}
                                                            listType="picture"
                                                            // multiple
                                                            // {...uploadProps}
                                                            onChange={fileChange}
                                                            beforeUpload={beforeUpload}
                                                        >
                                                            <p>Upload</p>
                                                        </Dragger>
                                                    </Form.Item>
                                                    <p style={{display: keepStatusIMG == 0 && vImgEn !== [] ? 'flex' : 'none', color: 'red'}}>Please upload image.</p>
                                                    <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Picture 750*500 PNG, JPG Maximum size 5MB</p>
                                                </>
                                        }
                                            
                                    </Card>
                                </Col>
                            </>
                            :
                            <></>
                    }
                </Row>
            </Form>
        </Edit>
    );
};