import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Divider, Edit, Form, Input, List, message, Radio, RadioChangeEvent, RcFile, Row, Select, Space, Upload, UploadFile, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { ICouponGroup, IEventlog, IPrivillege, IPrivillegeCate, IPrivillegeUTLog, IProperties, IUnitTypeBedroom, IUserSubType } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import {  TimePicker } from "antd";
import dayjs from "dayjs";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { PNCONDITION_EDIT } from "./components/conditions_edit";
import axios from "axios";
import { SaveOutlined } from "@ant-design/icons";

let dataAmountPlace:any = [];
let chk1: boolean = false;
let chk2: boolean = false;
let countLoop: any = 0;
export const ContentPostPrivilegeEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TextArea } = Input;
    const { Dragger } = Upload;
    const API_URL = useApiUrl();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: UpdatePrivUTLog } = useUpdate<IPrivillegeUTLog>();
    const log_endpoint = "even-logs";

    const { Option } = Select;
    const format = 'YYYY-MM-DD HH:mm';
    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;
    const [vsetdetail, setdetail] = useState<any>();
    const [vContentDisplay, setContentDisplay] = useState<any>("Privilege");
    const [vDateStart, setDateStart] = useState<any>("");
    const [vDateEnd, setDateEnd] = useState<any>("");

    const [vUserConditionAmount, setUserConditionAmount] = useState<any>(0);
    const [vIsTypeCoupon, setIsTypeCoupon] = useState(false);
    const [vIsTypeEvent, setIsTypeEvent] = useState(false);
    const [vRaPrivCoupon, setRaPrivCoupon] = useState<any>(0);
    const [vRaCalRight, setRaCalRight] = useState<any>(0);
    const [vRaLimitUser, setRaLimitUser] = useState<any>(0);
    const [vRaRightCondition, setRaRightCondition] = useState<any>(0);
    const [vCondition, setCondition] = useState(false);
    const [vCanComment, setCanComment] = useState(false);
    const [vInputCouponAmount, setInputCouponAmount] = useState<any>(0);
    const [vInputEventCLout, setInputEventCLout] = useState<any>(0);
    const [vPrivID, setPrivID] = useState<any>("1");
    const [vPrivSubCate, setPrivSubCate] = useState<any>("1");
    const [vUserConditionName, setUserConditionName] = useState<any>("");

    const [vImg, setImg] = useState<any>([]);
    const [vPrivUserSubType, setPrivUserSubType] = useState<any>([]);
    const [vPrivProps, setPrivProps] = useState<any>([]);
    const [vOnlyRight, setOnlyRight] = useState<any>();
    const [vOnlyRightMulti, setOnlyRightMulti] = useState<any>([]);
    const [vCoupon, setCoupon] = useState<any>(0);
    const [vCouponName, setCouponName] = useState<any>(0);

    const [vUnitValue3m, setUnitValue3m] = useState<any>(0);
    const [vUnitValue4m, setUnitValue4m] = useState<any>(0);
    const [vUnitValue9m, setUnitValue9m] = useState<any>(0);
    const [vUnitValue29m, setUnitValue29m] = useState<any>(0);
    const [vUnitValue59m, setUnitValue59m] = useState<any>(0);
    const [vUnitValue99m, setUnitValue99m] = useState<any>(0);
    const [vUnitValue100m, setUnitValue100m] = useState<any>(0);

    const [vCouponLeft, setCouponLeft] = useState<any>(0);

    const [vSitUnitType, setSitUnitType] = useState<any>([]);
    const [vSitPriceType, setSitPriceType] = useState<any>([]);
    const [vRaSpecificProperty, setRaSpecificProperty] = useState<any>(0);

    const [count, setCount] = useState(0)

    const [vIsBirthDay, setIsBirthDay] = useState(false);

    const [checkedApp, setCheckedApp] = useState(false);
    const [checkedSMS, setCheckedSMS] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(false);
    const [vAllNoticeChan, setAllNoticeChan] = useState<any>([]);
    const [vCusType, setCusType] = useState<any>([]);

    const [vImgEn, setImgEn] = useState<any>([]);

    const [vOpt, setOpt] = useState<any>();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);

    const [tickerFlag, settickerFlag] = useState<boolean>(false)

    // ******************* EN/TH **************************
    // ******************* EN/TH **************************
    const { mutate: UpdateLocale } = useUpdate<IPrivillege>();
    const [vDetailEN, setDetailEN] = useState<any>();
    const [vDataCKEN, setDataCKEN] = useState<any>();
    const [vValueLocale, setValueLocale] = useState(true);
    const [vIsLocalizeEN, setIsLocalizeEN] = useState<any>(false);
    const [vLocalizeID, setLocalizeID] = useState('');
    const [vTitleEn, setTitleEn] = useState('');
    const [vShortDetails, setShortDetails] = useState('');
    const [vDateUseCou, setDateUseCou] = useState<any>(null);
    const [vDataPrivUserSubType, setDataPrivUserSubType] = useState<any>([]);
    const [vDateKeepCou, setDateKeepCou] = useState<any>(null);
    const [vDateEvent, setDateEvent] = useState<any>(null);
    const [vContentPublicDate, setContentPublicDate] = useState<any>(null);
    const [vUTLength, setUTLength] = useState<any>();

    const [ vKeep, setVKeep ] = useState<any>([]);
    const [ keepStatusIMG, setKeepStatusIMG ] = useState<any>(undefined);

    function fileChange_TH(e: any) {
        setKeepStatusIMG(e?.fileList.length)
        if(e.file.status === "removed") {
            setImg((pre:any) => ({
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));
            form?.resetFields(["pic"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setImg((pre:any) => ({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    function fileChange_EN(e: any) {
        setKeepStatusIMG(e?.fileList.length)
        if(e.file.status === "removed") {
            setImgEn((pre:any) => ({
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));
            form?.resetFields(["pic2"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setImgEn((pre:any) => ({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        // console.log('fileeeeeeeeeeeee', file)
                        body.append("files", file);
                        // let headers = new Headers();
                        // headers.append("Origin", "http://localhost:3000");
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                            method: "post",
                            body: body
                            // mode: "no-cors"
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                // console.log('resssssssssssssssss', res)
                                resolve({
                                    //   default: `${API_URL}/${res.filename}`
                                    default: res[0].url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    let CkConfig: any;
    CkConfig = {
        toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "|",
            "imageTextAlternative",
            "imageUpload",
            "imageStyle:full",
            "imageStyle:side",
        ],
        extraPlugins: [uploadPlugin],
        mediaEmbed: {
            // configuration...
            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
        },
        image: {
            toolbar: ['']
        }
    };

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setValueLocale(value);

        if (value) {
            // console.log('th')
        } else {
            // console.log('en')
            setIsLocalizeEN(true)
        }
    };

    function updateLocaleEn() {
        UpdateLocale({
            resource: 'privileges',
            id: vLocalizeID,
            values: {
                detail: vDetailEN,
                name: vTitleEn,
                short_detail: vShortDetails,
                start_date: vDateStart,
                end_date: vDateEnd,
                pic: vImgEn?.defaultFileList?.length > 0 ?  vImgEn?.defaultFileList[0] : null
            }, successNotification: false
        })
    }

    function updateLocaleTH() {
        UpdateLocale({
            resource: 'privileges',
            id: recordData?.id,
            values: {
                detail: vsetdetail,
            }, successNotification: false
        })
    }

    const onChangeTitleEn = (e: any) => {
        setTitleEn(e.target.value);
    }

    const onChangeShortDetailEN = (e: any) => {
        setShortDetails(e.target.value);
    }
    // ***************************************************

    // ref https://refine.dev/docs/packages/documentation/react-hook-form/useForm/#usage
    const { form, formProps, saveButtonProps } = useForm<IPrivillege, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource: log_endpoint,
                    values: {
                        // user_profile : user_data.id,
                        user_profile: user_data.user_profile.id,
                        log_time_stamp: Date.now(),
                        log_event: "Create",
                        menu_event: 'coupon',
                        SysPkID: recordData.data.id + ""
                    },
                    successNotification: false
                });
            },
        },
        
    );

    async function callCountCouponLeft(couponGroupID: any) {

        if(couponGroupID !== 0){
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            let resultK:any = await axios.get(API_URL + "/like-systems/countCouponLeft?CPGID=" + couponGroupID, { headers: { Authorization: AuthStr } });
            setCouponLeft(resultK.data);
        }
        
    }
    // console.log('CouponLeft', vCouponLeft)
    const { selectProps: PrivilegeCategorySelectProps } = useSelect<IPrivillegeCate>({
        resource: "privilege-categories",
        optionLabel: "pc_name",
        optionValue: "id",
    });

    const { selectProps: PrivilegeSubCategorySelectProps } = useSelect<IPrivillegeCate>({
        resource: "privilege-sub-categories",
        optionLabel: "psc_name",
        optionValue: "id",
        filters: [
            {
                field: 'privilege_category_id',
                operator: 'eq',
                value: vPrivID,
            }
        ]
    });

    const { selectProps: PrivUserSubType } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    const { selectProps: PrivProperties } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'contains',
                value: 'N',
            },
        ]
    });

    const { selectProps: CouponCodeSelProps } = useSelect<ICouponGroup>({
        resource: "coupon-groups",
        optionLabel: "group_name",
        optionValue: "id",
        filters:[
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       :  "N",
            }
        ]
    });

    let res_coupongroup: any
    let options_coupon:any

    useEffect(() => {
        kk();
    }, [res_coupongroup])

    async function kk(){
        res_coupongroup = await axios.get(API_URL + "/like-systems/findCouponLeft", { headers: { Authorization: AuthStr } });
        options_coupon = res_coupongroup?.data?.map((item:any) => ({
            label: item.group_name,
            value: item.id
        }));
        setOpt(options_coupon)
    }

    const { queryResult } = useShow<any>({
        metaData: {
            populate: '*',
            // populate: ['*', 'localizations', 'localizations.pic', 'properties', 'pic', 'user_sub_types', 'notice_channel_types', 'notice_customer_type', 'createBy', 'coupons', 'privilege_category', 'privilege_properties', 'privilege_sub_category', 'privilege_user_sub_type', 'privilege_user_sub_type.id', 'updateBy', 'user_condition_sub_types', 'coupon_group'],
            locale: ['th']
        },
        queryOptions:{
            onSuccess(data:any) {
                let dataLoad = data?.data;
            },
        }
    });

    const { data } = queryResult;
    let recordData: any = data?.data ? data?.data : [];

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "privileges",
        metaData: {
            populate: [
                '*', 
                'localizations', 
                'localizations.pic', 
                'properties', 
                'pic', 
                'user_sub_types', 
                'notice_channel_types', 
                'notice_customer_type', 
                'createBy', 
                'coupons', 
                'privilege_category', 
                'privilege_properties', 
                'privilege_sub_category', 
                'privilege_user_sub_type', 
                'privilege_user_sub_type.id', 
                'updateBy', 
                'user_condition_sub_types', 
                'coupon_group'
            ],
            locale: ['th']
        },
        pagination: {
            pageSize: 10,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
            onSuccess: (data) => {
                let dataLoad = data?.data[0];
                chk1 = true;

                setDataPrivUserSubType(dataLoad)

                // กำหนดเงื่อนไขแบบสิทธิ
                setUserConditionAmount(dataLoad?.user_condition_amount) // จำนวนสิทธิ
                setRaRightCondition(dataLoad ? parseInt(dataLoad.user_condition_value) : 0) // radio เงื่อนไขแบบต่าง ๆ

                // checkbox Privilege ที่สามารถกดรับ ตามโค้ดคูปอง / สิทธิ และ privilege กิจกรรม
                // ถ้า privilege_coupon เป็น true == Privilege แบบคูปอง
                // ถ้า privilege_coupon เป็น false == Privilege แบบสิทธิ
                setRaPrivCoupon(dataLoad.privilege_coupon ? 1 : 2); 

                // จำนวน Privilege แบบสิทธิ หรือ คูปอง
                if(dataLoad?.coupon_amout !== 0){
                    setInputCouponAmount(dataLoad.coupon_amout ? dataLoad.coupon_amout : 0)
                }else if(dataLoad?.event_clout !== 0){
                    setInputCouponAmount(dataLoad.event_clout ? dataLoad.event_clout : 0)
                }

                // limit คนเข้าร่วม
                // false == จำกัดจำนวน
                // true == ไม่จำกัดจำนวน
                setRaLimitUser(dataLoad.limit_user ? 1 : 2);
                // จำนวนคนเข้าร่วม
                setInputEventCLout(dataLoad.event_clout ? dataLoad.event_clout : 0);


                // ย้ายมาจาก callFetchData ยังไม่ได้เขียนกำกับว่าอะไรคืออะไร
                if (vValueLocale) { // true == ไทย
                    setdetail(dataLoad.detail ? dataLoad.detail : '');
                }
                form.setFieldsValue(
                    {
                        privilege_user_sub_type:
                            vDataPrivUserSubType !== undefined ?
                                vDataPrivUserSubType.length > 0 ?
                                    vDataPrivUserSubType?.privilege_user_sub_type.map((v: any) => {
                                        return { value: v.id, label: v.sub_type_name }
                                    })
                                    : form.resetFields(["privilege_user_sub_type"])
                                : form.resetFields(["privilege_user_sub_type"]),
                    }
                )
                setUTLength(unitTypeData.data?.data.length);

                setDateKeepCou([dataLoad.keep_coupon_start_date ? dayjs(dataLoad.keep_coupon_start_date) : dayjs(), dataLoad.keep_coupon_end_date ? dayjs(dataLoad.keep_coupon_end_date) : dayjs()])

                setDateUseCou([dataLoad.use_coupon_start_date ? dayjs(dataLoad.use_coupon_start_date) : dayjs(), dataLoad.use_coupon_end_date ? dayjs(dataLoad.use_coupon_end_date) : dayjs()])
                setDateEvent([dataLoad.event_start_date ? dayjs(dataLoad.event_start_date) : dayjs(), dataLoad.event_end_date ? dayjs(dataLoad.event_end_date) : dayjs()])

                setPrivID(dataLoad.privilege_category ? dataLoad.privilege_category.id : '');
                setPrivSubCate(dataLoad.privilege_sub_category ? dataLoad.privilege_sub_category.id : null);

                setCoupon(dataLoad.coupon_group ? dataLoad.coupon_group.id : 0)
                setCouponName(dataLoad.coupon_group ? dataLoad.coupon_group.group_name : 0)

                callCountCouponLeft(dataLoad.coupon_group ? dataLoad.coupon_group.id : 0);


                if (dataLoad?.user_condition_value === "3") { // เงื่อนไขการรับสิทธิ 3.ให้สิทธิเฉพาะ
                    vOnlyRightMulti.pop();
                    vOnlyRightMulti.push(dataLoad?.user_condition_sub_types !== undefined ?
                        dataLoad.user_condition_sub_types.length > 0 ?
                            dataLoad?.user_condition_sub_types.map((v: any) => {
                                return v.id
                            })
                            : <></>
                        : <></>)
                    setOnlyRightMulti(vOnlyRightMulti);
                } else if (dataLoad?.user_condition_value === "4") { // เงื่อนไขการรับสิทธิ 4.Account Type
                    setOnlyRight(dataLoad?.user_condition_sub_types.length === 1 ? dataLoad?.user_condition_sub_types : null)
                }

                vPrivUserSubType.pop();
                vPrivUserSubType.push(dataLoad?.privilege_user_sub_type !== undefined ?
                    dataLoad.privilege_user_sub_type.length > 0 ?
                        dataLoad?.privilege_user_sub_type.map((v: any) => {
                            return v.id
                        })
                        : <></>
                    : <></>)
                setPrivUserSubType(vPrivUserSubType);

                vPrivProps.pop();
                vPrivProps.push(dataLoad?.privilege_properties !== undefined ?
                    dataLoad.privilege_properties.length > 0 ?
                        dataLoad?.privilege_properties.map((v: any) => {
                            return v.id
                        })
                        : <></>
                    : <></>)
                setPrivProps(vPrivProps);

                setValue(dataLoad ? dataLoad.notice_channel : false)

                vCusType.pop();
                vCusType.push(dataLoad?.notice_customer_type !== undefined ?
                    dataLoad.notice_customer_type.length > 0 ?
                        dataLoad?.notice_customer_type.map((v: any) => {
                            return v.id
                        })
                        : <></>
                    : <></>)
                setCusType(vCusType);

                dataLoad?.notice_channel_types !== undefined ?
                    dataLoad?.notice_channel_types.length > 0 ?
                        dataLoad?.notice_channel_types.map((v: any) => {
                            v.id == 3 ? setCheckedApp(true) : <></>
                            v.id == 2 ? setCheckedEmail(true) : <></>
                            v.id == 1 ? setCheckedSMS(true) : <></>
                        })
                        : <></>
                    : <></>

                setDateStart(dataLoad.start_date ? dataLoad.start_date : '');
                setDateEnd(dataLoad.end_date ? dataLoad.end_date : '');


                setContentPublicDate([dataLoad.start_date ? dayjs(dataLoad.start_date) : dayjs(), dataLoad.end_date ? dayjs(dataLoad.end_date) : dayjs()])
                setCanComment(dataLoad.privilege_comment ? dataLoad.privilege_comment : false);

                setIsTypeCoupon(dataLoad.privilege_promotion ? dataLoad.privilege_promotion : false);
                setIsTypeEvent(dataLoad.privilege_event ? dataLoad.privilege_event : false);

                // setIsPrivCouponChk(dataLoad.privilege_coupon ? dataLoad.privilege_coupon : false);

                setCondition(dataLoad.user_condition ? dataLoad.user_condition : false);


                setRaCalRight(dataLoad.coupon_condition ? 1 : 2)
                setUnitValue3m(dataLoad.unit_value_3m ? dataLoad.unit_value_3m : 0)
                setUnitValue4m(dataLoad.unit_value_4m ? dataLoad.unit_value_4m : 0)
                setUnitValue9m(dataLoad.unit_value_9m ? dataLoad.unit_value_9m : 0)
                setUnitValue29m(dataLoad.unit_value_29m ? dataLoad.unit_value_29m : 0)
                setUnitValue59m(dataLoad.unit_value_59m ? dataLoad.unit_value_59m : 0)
                setUnitValue99m(dataLoad.unit_value_99m ? dataLoad.unit_value_99m : 0)
                setUnitValue100m(dataLoad.unit_value_100m ? dataLoad.unit_value_100m : 0)


                setIsBirthDay(dataLoad.birthday ? dataLoad.birthday : false)
                // setPropertyUnder(dataLoad.property_under ? parseInt(dataLoad.property_under) : 0)

                setRaSpecificProperty(dataLoad.property_under ? parseInt(dataLoad.property_under) : 0)

                // *********** Content EN ****************
                setDataCKEN(dataLoad.localizations ? dataLoad.localizations.length > 0 ? dataLoad.localizations[0].detail + "" : '' : '')
                setLocalizeID(dataLoad.localizations ? dataLoad.localizations.length > 0 ? dataLoad.localizations[0].id : '' : '');
                setTitleEn(dataLoad.localizations ? dataLoad.localizations.length > 0 ? dataLoad.localizations[0].name : '' : '');
                setShortDetails(dataLoad.localizations ? dataLoad.localizations.length > 0 ? dataLoad.localizations[0].short_detail : '' : '')
                setDetailEN(dataLoad.localizations ? dataLoad.localizations.length > 0 ? dataLoad.localizations[0].detail : '' : '')
                // *********** Content EN ****************


                form.setFieldsValue({
                    user_condition_sub_types:
                        dataLoad?.user_condition_sub_types !== undefined ?
                            dataLoad?.user_condition_sub_types.length > 0 ?
                                dataLoad?.user_condition_sub_types.map((v: any) => {
                                    return { value: v.id, label: v.sub_type_name }
                                })
                                : form.resetFields(["user_condition_sub_types"])
                            : form.resetFields(["user_condition_sub_types"]),

                    privilege_properties:
                        dataLoad?.privilege_properties !== undefined ?
                            dataLoad?.privilege_properties.length > 0 ?

                                dataLoad?.privilege_properties.map((v: any) => {
                                    // console.log(v)
                                    return { value: v.id, label: v.property_name }
                                })
                                : form.resetFields(["privilege_properties"])
                            : form.resetFields(["privilege_properties"]),

                    privilege_category:
                        dataLoad?.privilege_category !== undefined ?
                            dataLoad?.privilege_category.pc_name
                            :
                            <></>,

                    privilege_sub_category:
                        dataLoad?.privilege_sub_category !== undefined && dataLoad.privilege_sub_category !== null ?
                            dataLoad?.privilege_sub_category.psc_name
                            :
                            <></>,

                    notice_customer_type:
                        dataLoad?.notice_customer_type !== undefined ?
                            dataLoad?.notice_customer_type.length > 0 ?
                                dataLoad?.notice_customer_type.map((v: any) => {
                                    // console.log(v)
                                    return { value: v.id, label: v.sub_type_name }
                                })
                                : form.resetFields(["notice_customer_type"])
                            : form.resetFields(["notice_customer_type"]),

                    name_en: dataLoad.localizations ? dataLoad.localizations.length > 0 ? dataLoad.localizations[0].name : '' : '',
                    short_detail_en: dataLoad.localizations ? dataLoad.localizations.length > 0 ? dataLoad.localizations[0].short_detail : '' : ''
                })

                if(dataLoad){
                    const fileList_TH: UploadFile[] =
                    dataLoad?.pic ? dataLoad?.pic?.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url:
                                v.url,
                            thumbUrl:
                                v.url
                        }
                    )): [];

                    setImg({
                        name: 'files',
                        multiple: true,
                        defaultFileList: dataLoad?.pic ? [...fileList_TH] : [],
                    })
                    form.resetFields(["pic"]);
                }
                
                let dataload_EN = data?.data[0]?.localizations;

                if(dataload_EN){
                    const fileList_EN: UploadFile[] =
                    dataload_EN?.length > 0 && dataload_EN[0]?.pic?.length > 0 ? dataload_EN[0]?.pic.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];

                    setImgEn({
                        name: 'files',
                        multiple: true,
                        // defaultFileList: [...fileList3],
                        defaultFileList: dataload_EN[0]?.pic?.length > 0 ? [...fileList_EN] : [],

                    })
                    form.resetFields(["pic2"]);
                }

                form.setFieldsValue(
                    {
                        privilege_user_sub_type:
                            dataLoad.privilege_user_sub_type.map((v: any) => {
                                return { value: v.id, label: v.sub_type_name }
                            })
                    }
                );

                dataAmountPlace = ([
                    {
                        id: 1,
                        type_name: "น้อยกว่า 3 ล้าน",
                    },
                    {
                        id: 2,
                        type_name: "3 - 5.49 ล้าน",
                    },
                    {
                        id: 3,
                        type_name: "5.5 - 9.9 ล้าน",
                    },
                    {
                        id: 4,
                        type_name: "10 - 29.9 ล้าน",
                    },
                    {
                        id: 5,
                        type_name: "30 - 59.9 ล้าน",
                    },
                    {
                        id: 6,
                        type_name: "60 - 99.9 ล้าน",
                    },
                    {
                        id: 7,
                        type_name: "มากกว่า 100 ล้าน",
                    },
                ]);
                
                let aa :any

                if(dataLoad?.coupon_amout !== 0){
                    aa = (Number(dataLoad?.coupon_amout) || 0) - ((Number(dataLoad?.unit_value_3m) || 0) + (Number(dataLoad?.unit_value_4m) || 0) + (Number(dataLoad?.unit_value_9m) || 0) + (Number(dataLoad?.unit_value_29m) || 0) + (Number(dataLoad?.unit_value_59m) || 0) + (Number(dataLoad?.unit_value_99m) || 0) + (Number(dataLoad?.unit_value_100m) || 0))
                }else if(dataLoad?.event_clout !== 0){
                    aa = (Number(dataLoad?.event_clout) || 0) - ((Number(dataLoad?.unit_value_3m) || 0) + (Number(dataLoad?.unit_value_4m) || 0) + (Number(dataLoad?.unit_value_9m) || 0) + (Number(dataLoad?.unit_value_29m) || 0) + (Number(dataLoad?.unit_value_59m) || 0) + (Number(dataLoad?.unit_value_99m) || 0) + (Number(dataLoad?.unit_value_100m) || 0))
                }

                setSitPriceType([
                    {
                        id: 1,
                        type_name: "น้อยกว่า 3 ล้าน",
                        amount: dataLoad?.unit_value_3m,
                        valueMax: (Number(vInputCouponAmount)) + aa
                    },
                    {
                        id: 2,
                        type_name: "3 - 5.49 ล้าน",
                        amount: dataLoad?.unit_value_4m,
                        valueMax: (Number(vInputCouponAmount)) + aa
                    },
                    {
                        id: 3,
                        type_name: "5.5 - 9.9 ล้าน",
                        amount: dataLoad?.unit_value_9m,
                        valueMax: (Number(vInputCouponAmount)) + aa
                    },
                    {
                        id: 4,
                        type_name: "10 - 29.9 ล้าน",
                        amount: dataLoad?.unit_value_29m,
                        valueMax: (Number(vInputCouponAmount)) + aa
                    },
                    {
                        id: 5,
                        type_name: "30 - 59.9 ล้าน",
                        amount: dataLoad?.unit_value_59m,
                        valueMax: (Number(vInputCouponAmount)) + aa
                    },
                    {
                        id: 6,
                        type_name: "60 - 99.9 ล้าน",
                        amount: dataLoad?.unit_value_99m,
                        valueMax: (Number(vInputCouponAmount)) + aa
                    },
                    {
                        id: 7,
                        type_name: "มากกว่า 100 ล้าน",
                        amount: dataLoad?.unit_value_100m,
                        valueMax: (Number(vInputCouponAmount)) + aa
                    },
                ])
            },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');

    }, []);

    const unitTypeData = useList<IPrivillegeUTLog>({
        resource: "privilege-ut-logs",
        metaData: {
            populate: ['privilege', 'privilege.id', 'unit_type', 'unit_type.id', 'unit_bedroom', 'unit_bedroom.id'],
        },
        config: {
            sort: [{ order: "asc", field: "id" }],
            pagination: { current: 1, pageSize: 100 },
            filters: [
                {
                    field: "privilege][id]",
                    operator: "eq",
                    value: recordData.id + ""
                },
            ],
        },
        queryOptions:{
            onSuccess(data:any) {
            let dataLoad = data?.data;

            chk2 = true;

            // console.log(">>> dataLoad", dataLoad)
            // console.log(">>> vInputCouponAmount", vInputCouponAmount)
            
            if(!!dataLoad && dataLoad.length > 0){
                let aa = Number(vInputCouponAmount) - (dataLoad?.map((p:any) => Number(p.user_condition_amount)).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))

                console.log(">>> aa", aa)

                chk2 = true;

                setSitUnitType(dataLoad?.map((e:any,index:any) => ({
                    id: e?.unit_bedroom?.id,
                    type_name: e?.unit_bedroom?.bedroomName,
                    amount: Number(e?.user_condition_amount),
                    priv_id: e?.privilege?.id,
                    valueMax: Number(e?.user_condition_amount) + aa,
                    realID:e?.id
                })))
            }
        },
    },
    })
    
    // if(chk1 == true && chk2 == true && countLoop == 0 && vInputCouponAmount !== 0 && vSitUnitType == undefined){
    //     countLoop = 1;
    //     let dataLoad: any = unitTypeData?.data?.data;
    //     let newData: any = [];
    //     console.log(">>> vRaRightCondition", vRaRightCondition)
    //     console.log(">>> vInputCouponAmount", vInputCouponAmount)
    //     if(vRaRightCondition == 5){
    //         let aa: any = Number(vInputCouponAmount) - (dataLoad?.map((p:any) => Number(p.user_condition_amount)).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))
        
    //         if(dataLoad?.length > 0){
    //             dataLoad?.map((e:any,index:any) => {
    //                 return newData?.push({
    //                     id: e?.unit_bedroom?.id,
    //                     type_name: e?.unit_bedroom?.bedroomName,
    //                     amount: Number(e?.user_condition_amount),
    //                     priv_id: e?.privilege?.id,
    //                     valueMax: Number(e?.user_condition_amount) + aa,
    //                     realID:e?.id
    //                 })
    //             })
    //         }
    //     }else{
    //         if(dataLoad?.length > 0){
    //             dataLoad?.map((e:any,index:any) => {
    //                 return newData?.push({
    //                     id: e?.unit_bedroom?.id,
    //                     type_name: e?.unit_bedroom?.bedroomName,
    //                     amount: Number(e?.user_condition_amount),
    //                     priv_id: e?.privilege?.id,
    //                     valueMax: vInputCouponAmount,
    //                     realID:e?.id
    //                 })
    //             })
    //         }
    //     }

    //     console.log(">>> newData", newData)

    //     if(newData?.length > 0){
    //         setSitUnitType((pre: any) => newData);
    //     }

    //     // console.log(">>> newData", newData)
    //     // setSitUnitType(dataLoad?.map((e:any,index:any) => ({
    //     //     id: e?.unit_bedroom?.id,
    //     //     type_name: e?.unit_bedroom?.bedroomName,
    //     //     amount: Number(e?.user_condition_amount),
    //     //     priv_id: e?.privilege?.id,
    //     //     valueMax: Number(e?.user_condition_amount) + aa,
    //     //     realID:e?.id
    //     // })))
    // }

    // console.log('vUTLength', vUTLength)

    if (unitTypeData !== undefined) {
        // console.log('sorting.')
        // unitTypeData.data?.data.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(
        //     b.name,
        //     undefined,
        //     { numeric: true, sensitivity: 'base' }
        // ));

        unitTypeData.data?.data.sort((a: any, b: any) => a.unit_bedroom.id - b.unit_bedroom.id); // b - a for reverse sort
    }

    // console.log('unitTypeData',unitTypeData.data?.data)

    const [value, setValue] = useState();

    const onChangeNoti = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const onChangeCheckTypeCoupon = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        // setCheckedApp(e.target.checked);
        e.target.checked ? setIsTypeCoupon(true) : setIsTypeCoupon(false)
        // setIsFlowMgn(true);
        // vIsTypeCoupon ? setIsTypeEvent(false) : <></>
        if (e.target.checked) { setIsTypeEvent(false) }
    };

    const onChangeCheckCondition = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        // setCheckedApp(e.target.checked);
        e.target.checked ? setCondition(true) : setCondition(false)
        // setIsFlowMgn(true);
    };

    const onChangeCheckTypeEvent = (e: CheckboxChangeEvent) => {
        console.log('type event', e.target.checked);
        e.target.checked ? setIsTypeEvent(true) : setIsTypeEvent(false)

        // vIsTypeEvent ? setIsTypeCoupon(false) : <></>
        if (e.target.checked) { setIsTypeCoupon(false) }
    }

    const onChangeRadio1 = (e: RadioChangeEvent) => {
        console.log('onChangeRadio1', e.target.value);
        setRaPrivCoupon(e.target.value);
    };

    const onChangeRadio2 = (e: RadioChangeEvent) => {
        console.log('onChangeRadio2', e.target.value);
        setRaCalRight(e.target.value);
    };

    const onChangeRadio3 = (e: RadioChangeEvent) => {
        console.log('onChangeRadio3', e.target.value);
        setRaLimitUser(e.target.value);
    };

    const onChangeRadio4 = (e: RadioChangeEvent) => {
        // console.log('onChangeRadio4', e.target.value);
        setRaRightCondition(e.target.value);
        // setRaRightCondition((pre:any) => [...pre, e.target.value]);
        switch (e.target.value) {
            case 1:
                setUserConditionName("สิทธิ 1 Unit");
                break;
            case 2:
                setUserConditionName("สิทธิ 1 Thai ID / Passport");
                break;
            case 3:
                setUserConditionName("ให้สิทธิเฉพาะตามประเภทผู้้ใช้");
                break;
            case 4:
                setUserConditionName("ให้สิทธิเฉพาะตาม Account Type");
                break;
            case 5:
                setUserConditionName("สิทธิตามมูลค่า");
                break;
            case 6:
                setUserConditionName("สิทธิเฉพาะจำนวนสัตว์เลี้ยง");
                break;
            default:
                return 'foo';
        }

        setUserConditionAmount(0);
    };

    const onChangeRadio5 = (e: RadioChangeEvent) => {
        console.log('onChangeRadio5', e.target.value);
        setRaSpecificProperty(e.target.value);
        // console.log('vRaPrivCoupon', vRaPrivCoupon)
    };

    function selectTime(time: any, timeString: any) {

        if (timeString[0] == '' || timeString[1] == '') {
            setContentPublicDate(null)
        } else {
            setContentPublicDate(returnDayJsDateRange(timeString[0], timeString[1]))
        }

    }

    function selectTimeEvent(time: any, timeString: any) {
        if (timeString[0] == '' || timeString[1] == '') {
            setDateEvent(null)
        } else {
            setDateEvent(returnDayJsDateRange(timeString[0], timeString[1]))
        }
    }

    function selectTimeKeepCouponDate(time: any, timeString: any) {
        if (timeString[0] == '' || timeString[1] == '') {
            setDateKeepCou(null)
        } else {
            setDateKeepCou(returnDayJsDateRange(timeString[0], timeString[1]))
        }
    }

    function selectTimeUseCouponDate(time: any, timeString: any) {
        if (timeString[0] == '' || timeString[1] == '') {
            setDateUseCou(null)
        } else {
            setDateUseCou(returnDayJsDateRange(timeString[0], timeString[1]))
        }
    }

    const returnDayJsDateRange = (start: string | number | Date | dayjs.Dayjs | null | undefined, finish: string | number | Date | dayjs.Dayjs | null | undefined) => {
        // console.log("start", start);
        // console.log("finish", finish);
        // return [dayjs(start, "YYYY-MM-DD HH:mm"), dayjs(finish, "YYYY-MM-DD HH:mm")];
        return [dayjs(start), dayjs(finish)];
    };

    function onChangePrivUserSubType(va: any) {
        vPrivUserSubType.pop();
        vPrivUserSubType.push(va);
        setPrivUserSubType(vPrivUserSubType);
        // console.log('vPrivUserSubType',vPrivUserSubType);
    };
    // console.log('vPrivUserSubType', vPrivUserSubType)
    function onChangePrivProps(va: any) {
        let keepNeww:any = []
        for (let index = 0; index < va.length; index++) {
            keepNeww.push(va[index])
        }
        setVKeep(keepNeww)

        vPrivProps.pop();
        vPrivProps.push(va);
        setPrivProps(vPrivProps);
        console.log('vPrivProps', vPrivProps);
    }

    function onChangeOnlyRight(va: any) {
        // vOnlyRight.pop();
        // vOnlyRight.push(va);
        // setOnlyRight(vOnlyRight);
        setOnlyRight(va);
        // console.log('vOnlyRight',vOnlyRight);
    }

    function onChangeOnlyRightMulti(va: any) {
        vOnlyRightMulti.pop();
        vOnlyRightMulti.push(va);
        setOnlyRightMulti(vOnlyRightMulti);
        // console.log('vOnlyRight',vOnlyRightMulti);
    }

    function chkRoomType(id: any, type_name: any, amount: any) {
        let newData:any = [];

        for (let i = 0; i < vSitUnitType?.length; i++) {
            console.log(">>> vSitUnitType", vSitUnitType[i])
            console.log(">>> id", id)
            if(vSitUnitType[i]?.realID === id){
                let val: any = Math?.max(Number(0), Math?.min(Number(vInputCouponAmount), Number(amount)));
                vSitUnitType[i].amount = Number(val)
                newData?.push(vSitUnitType[i])

                for (let is = 0; is < vSitUnitType.length; is++) {
                    if(vSitUnitType[is].realID !== id){
                        let aa = Number(vInputCouponAmount) -  (vSitUnitType.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))
                        vSitUnitType[is].valueMax = aa
                        if(vSitUnitType[is].amount !== 0){
                            vSitUnitType[is].valueMax = Number(vSitUnitType[is].amount) + 1
                        }
                        newData.push(vSitUnitType[is])
                    }
                }
            }
        }
        if(Number(Number(vInputCouponAmount) -  (newData?.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))) === 0){
            for (let i = 0; i < vSitUnitType.length; i++) {
                vSitUnitType[i].valueMax = vSitUnitType[i].amount
            }
        }

        console.log(">>> newData", newData)
        if(newData?.length > 0){
            setSitUnitType((pre: any) => newData);
            settickerFlag((pre)=> !pre);
        }
    }

    function chkPriceType(id: any, type_name: any, amount: any) {
        let newData:any = [];
        for (let i = 0; i < vSitPriceType?.length; i++) {
            if(vSitPriceType[i].id === id){
                vSitPriceType[i].amount = Number(amount)
                newData.push(vSitPriceType[i])
                for (let is = 0; is < vSitPriceType.length; is++) {
                    if(vSitPriceType[is].id !== id){
                        // valueMax
                        // vSitPriceType[is]
                        let aa = Number(vInputCouponAmount) -  (vSitPriceType.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))
                        console.log(aa)
                        vSitPriceType[is].valueMax = aa
                        console.log('vSitPriceType[is].amount : ', vSitPriceType[is].amount)
                        if(vSitPriceType[is].amount !== 0){
                                vSitPriceType[is].valueMax = Number(vSitPriceType[is].amount) + 1
                        }

                        newData.push(vSitPriceType[is])
                    }
                }

            }
        }
        if(Number(Number(vInputCouponAmount) -  (newData.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))) === 0){
            console.log('1111')
            for (let i = 0; i < vSitPriceType.length; i++) {
                vSitPriceType[i].valueMax = vSitPriceType[i].amount
            }
        }
        setSitPriceType((pre: any) => newData)
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function updateInPrivUtLog() {
        // console.log('GOOOOO')
        for (let i = 0; i <= vSitUnitType?.length - 1; i++) {
            // console.log('update priv ut log')
            UpdatePrivUTLog({
                resource: 'privilege-ut-logs',
                // id: recordData.id ,
                id: vSitUnitType[i].realID,
                values: {
                    user_condition_amount: String(vSitUnitType[i].amount),
                    // unit_type : vSitUnitType[0][i].type_name,
                    // unit_type: vSitUnitType[0][i].unit_id,
                    unit_bedroom: vSitUnitType[i]?.id,
                    privilege: vSitUnitType[i]?.priv_id
                },
                successNotification: false,
                errorNotification: false
            });
        }
    }

    const onChangeCheckApp = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        setCheckedApp(e.target.checked);
    };

    const onChangeCheckSMS = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        setCheckedSMS(e.target.checked);
    };

    const onChangeCheckEmail = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        setCheckedEmail(e.target.checked);
    };

    const { selectProps: PrivUserSubTypeOnlyCus } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    function chkNoticeChan() {
        if (checkedSMS) {
            vAllNoticeChan.push(1);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedEmail) {
            vAllNoticeChan.push(2);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedApp) {
            vAllNoticeChan.push(3);
            setAllNoticeChan(vAllNoticeChan);
        }

        // console.log('vAllNoticeChan', vAllNoticeChan);
    }

    function onChangeSubCusType(va: any) {
        // vProperties.pop();
        // vProperties.push(va);
        // setProperties(vProperties);
        // console.log('vProperties',vProperties);

        vCusType.pop();
        vCusType.push(va);
        setCusType(vCusType);
    };

    // 1. ถ้ามีข้อมูลของ privilege นี้อยู่ใน privilege_ut_log อยู่แล้ว จะไม่ใช้ข้อมูล unitTypeData มา map
    // 2. 

    const unitBedroom = useList<IUnitTypeBedroom>({
        resource: "unit-bedrooms",
        metaData: {
            // populate: ['property','property.id'],
            // populate: ['property','property.id'],
            populate: '*',
        },
        config: {
            pagination: { current: 1, pageSize: 100 },
        },
    })

    console.log(">>> vSitUnitType", vSitUnitType)

    return (
        <Edit
            // {...saveButtonProps}
            // saveButtonProps={saveButtonProps}
            // saveButtonProps={saveButtonProps}
            saveButtonProps={{ hidden: true }}
            // footerButtons={}
            // footerButtons={() => <></>}
            pageHeaderProps={{extra: <></>}}
        >
            <List
                title={t("content-post-privilege.title-edit")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span style={{ color: "#cdba8c" }}>{t("content-post-privilege.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    values.updateBy = user_data.user_profile.id

                    values.privilege_category = vPrivID
                    values.privilege_sub_category = vPrivSubCate
                    values.start_date = vContentPublicDate[0]
                    values.end_date = vContentPublicDate[1]

                    // Privilege กดรับตามคูปอง สิทธิ
                    values.privilege_promotion = vIsTypeCoupon
                    values.privilege_event = vIsTypeEvent
                    values.privilege_comment = vCanComment // เปิดให้ comment

                    if(vIsTypeCoupon == true){
                        values.coupon_amout = Number(vInputCouponAmount)
                        values.event_clout = Number(0)
                    }else if(vIsTypeEvent){
                        values.event_clout = Number(vInputEventCLout)
                        values.coupon_amout = Number(0)
                    }

                    // กำหนดเงื่อนไขการรับสิทธิ
                    values.user_condition = vCondition
                    values.user_condition_value = vRaRightCondition + "" // 

                    // values.user_condition_amount = vUserConditionAmount
                    values.user_condition_amount = parseInt(vUserConditionAmount)

                    values.privilege_user_sub_type = vPrivUserSubType[0]
                    values.pic = vImg?.defaultFileList?.length > 0 ?  vImg?.defaultFileList[0] : null;

                    if (vRaPrivCoupon == 1) { // Privilege แบบคูปอง
                        values.privilege_coupon = true
                        values.privilege_amount = false
                        values.coupon_group = vCoupon
                    } else if (vRaPrivCoupon == 2) { // Privilege แบบสิทธิ
                        values.privilege_coupon = false
                        values.privilege_amount = true
                    }

                    if (vIsTypeCoupon) { // checkbox Privilege ที่สามารถกดรับ ตามโค้ดคูปอง / สิทธิ
                        // Period การกดรับคูปอง
                        values.keep_coupon_start_date = vDateKeepCou[0]
                        values.keep_coupon_end_date = vDateKeepCou[1]

                        // Period การใช้คูปอง
                        values.use_coupon_start_date = vDateUseCou[0]
                        values.use_coupon_end_date = vDateUseCou[1]
                    } else if (vIsTypeEvent) { // radio box 1 privilege แบบกิจกรรม

                        // if(vDateEventStart !== ""){
                        if (vDateEvent[0] !== "" && vDateEvent[1] !== "") {
                            // values.event_start_date = vDateEventStart
                            // values.event_end_date = vDateEventEnd
                            values.event_start_date = vDateEvent[0]
                            values.event_end_date = vDateEvent[1]
                        }
                    } else {
                        values.privilege_coupon = false
                        values.privilege_amount = false
                    }

                    // radio box 2 วิธีการคำนวนสิทธิ
                    if (vRaCalRight == 1) {
                        values.coupon_condition = true
                    } else if (vRaCalRight == 2) {
                        values.coupon_condition = false
                    }

                    // radio box 3 Limit คนเข้าร่วม
                    if (vRaLimitUser == 1) { // ไม่จำกัด
                        values.limit_user = true
                    } else if (vRaLimitUser == 2) {  // จำกัดจำนวน
                        values.limit_user = false
                    }

                    if (vRaRightCondition === 3) { // ให้สิทธิเฉพาะ เก็บ user_condition_sub_types แบบ multi
                        values.user_condition_sub_types = vOnlyRightMulti[0].length > 0 ? vOnlyRightMulti[0] : null
                    }

                    if (vRaRightCondition === 4) { // Account Type เก็บ user_condition_sub_type แบบ 1 ตัว
                        values.user_condition_sub_types = vOnlyRight !== undefined ? vOnlyRight : null
                    }

                    // โครงการที่ได้รับสิทธิ radiobox 5 
                    // value 1 ทุกโครงการ
                    // value 2 เฉพาะโครงการของ Major เท่านั้น
                    // value 3 Non Major
                    // value 4 ระบุโครงการ
                    if (vRaSpecificProperty == 4) {
                        values.privilege_properties = vPrivProps[0]
                    }

                    // values.seq = vInputSeq

                    if (vIsBirthDay == false) {
                        // values.unit_value_3m = vUnitValue3m
                        // values.unit_value_4m = vUnitValue4m
                        // values.unit_value_9m = vUnitValue9m
                        // values.unit_value_29m = vUnitValue29m
                        // values.unit_value_59m = vUnitValue59m
                        // values.unit_value_99m = vUnitValue99m
                        // values.unit_value_100m = vUnitValue100m
                        let value_m_1 = vSitPriceType.find((item:any) => item?.type_name == "น้อยกว่า 3 ล้าน");
                        let value_m_2 = vSitPriceType.find((item:any) => item?.type_name == "3 - 5.49 ล้าน");
                        let value_m_3 = vSitPriceType.find((item:any) => item?.type_name == "5.5 - 9.9 ล้าน");
                        let value_m_4 = vSitPriceType.find((item:any) => item?.type_name == "10 - 29.9 ล้าน");
                        let value_m_5 = vSitPriceType.find((item:any) => item?.type_name == "30 - 59.9 ล้าน");
                        let value_m_6 = vSitPriceType.find((item:any) => item?.type_name == "60 - 99.9 ล้าน");
                        let value_m_7 = vSitPriceType.find((item:any) => item?.type_name == "มากกว่า 100 ล้าน");

                        if(value_m_1 && value_m_2 && value_m_3 && value_m_4 && value_m_5 && value_m_6 && value_m_7){
                            values.unit_value_3m = value_m_1?.amount;
                            values.unit_value_4m =  value_m_2?.amount;
                            values.unit_value_9m =  value_m_3?.amount;
                            values.unit_value_29m =  value_m_4?.amount;
                            values.unit_value_59m =  value_m_5?.amount;
                            values.unit_value_99m =  value_m_6?.amount;
                            values.unit_value_100m =  value_m_7?.amount;
                        }
                    }

                    if (vRaSpecificProperty !== 0) {
                        values.property_under = vRaSpecificProperty + ""
                    }

                    if (vIsBirthDay) {
                        values.birthday = vIsBirthDay
                        // values.privilege_user_sub_type = 5 // ID ของ Guest
                        values.privilege_user_sub_type = 1 // ID ของ Guest
                    }

                    if (vValueLocale == true) {
                        // console.log('th th th th')
                        values.locale = "th"
                        // values.detail = vsetdetail
                        updateLocaleTH();

                    } else {
                        // console.log('create en')
                        values.locale = "en"
                        if (vIsLocalizeEN) {
                            // console.log('update en en en en en en en 2')
                            updateLocaleEn();
                        }
                    }

                    // ถ้าเลือก noti
                    if (value) {
                        // console.log('vCusType', vCusType[0]);
                        values.notice_customer_type = vCusType[0]
                    }
                
                    if (vAllNoticeChan.length > 0) {
                        values.notice_channel_types = vAllNoticeChan
                    }

                    // if(vSitUnitType > 0){
                    if (vSitUnitType.length > 0) {
                        updateInPrivUtLog();
                    }

                    chkNoticeChan();
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        {
                            // count >= 4 ?
                            count == 0 ?
                                <>
                                    <div style={{ marginBottom: '2%' }}>
                                        <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" />
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ marginBottom: '2%' }}>
                                        <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" disabled />
                                    </div>
                                </>
                        }

                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: "1%" }}>
                                <span style={{ marginRight: "3%", fontSize: ".70vw", fontWeight: "bold", color: "#000" }}>Display Content :</span>
                                <span style={{ fontSize: "13px", color: "#000" }}>{vContentDisplay}</span>
                            </div>

                            {
                                vValueLocale ? // TH
                                    <Row>
                                        <Col span={12}>
                                            <Form.Item
                                                name="privilege_category"
                                                label="Privilege category"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select category.'
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{ width: "95%" }}
                                                    // mode="multiple"
                                                    placeholder={t("select.sel")}
                                                    {...PrivilegeCategorySelectProps}
                                                    onSelect={(value: any, record: any) => {

                                                        if (record.label.indexOf("Birthday") >= 0) {
                                                            // console.log('yahoooooo', record)
                                                            setIsBirthDay(true)
                                                        } else {
                                                            setIsBirthDay(false)
                                                        }

                                                        setPrivID(value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>

                                            <Form.Item
                                                name="privilege_sub_category"
                                                label="Privilege sub category"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'please select sub category.'
                                            //     },
                                            // ]}
                                            >
                                                <Select
                                                    style={{ width: "100%" }}
                                                    // mode="multiple"
                                                    placeholder={t("select.sel")}
                                                    {...PrivilegeSubCategorySelectProps}
                                                    onSelect={(value: any) => {
                                                        setPrivSubCate(value);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            }


                            <Row>
                                <Col span={24}>

                                    {
                                        vValueLocale ?
                                            <>
                                                <Form.Item label="Title" name="name"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please enter title.'
                                                        },
                                                    ]}
                                                >
                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                                </Form.Item>
                                            </>
                                            :
                                            <>
                                                <Form.Item label="Title (EN)"
                                                    name="name_en"
                                                // rules={[
                                                //     {
                                                //         required: true, message: 'please enter title.'
                                                //     },
                                                // ]}
                                                >

                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                        defaultValue={vTitleEn}
                                                        onChange={(e) => onChangeTitleEn(e)}
                                                    />
                                                </Form.Item>
                                            </>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>

                                    {
                                        vValueLocale ?
                                            <>
                                                <Form.Item label="Short Detail" name="short_detail"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please enter Sub Title.'
                                                        },
                                                    ]}>
                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                                </Form.Item>
                                            </>
                                            :
                                            <>
                                                <Form.Item label="Short Detail (EN)"
                                                    name="short_detail_en"
                                                // rules={[
                                                //     {
                                                //         required: true, message: 'please enter Sub Title.'
                                                //     },
                                                // ]}
                                                >
                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                        defaultValue={vShortDetails}
                                                        onChange={(e) => onChangeShortDetailEN(e)}
                                                    />
                                                </Form.Item>
                                            </>
                                    }
                                </Col>
                            </Row>

                            {vValueLocale ?
                                <Form.Item label="เนื้อหา"
                                    // name="detail"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <CKEditor
                                        // name="detailTH"
                                        // id="detailTH"
                                        editor={ClassicEditor}
                                        // data={recordData.detail}
                                        // data={recordData.detail ? recordData.detail + "" : ''}
                                        data={vDataPrivUserSubType.detail ? vDataPrivUserSubType.detail + "" : recordData.detail ? recordData.detail + "" : ''}
                                        // data={vsetdetail ? vsetdetail : ''}
                                        config={CkConfig}
                                        onInit={(editor: any) => {
                                            // console.log('editor',editor)
                                            // You can store the "editor" and use when it is needed.
                                            // console.log("Editor is ready to use!", editor);
                                            // console.log(
                                            //     "toolbar: ",
                                            //     Array.from(editor.ui.componentFactory.names())
                                            // );
                                            // console.log(
                                            //     "plugins: ",
                                            //     ClassicEditor.builtinPlugins.map((plugin:any) => plugin.pluginName)
                                            // );
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            setdetail(data);
                                            // if(vValueLocale){ // true == ไทย
                                            //     setdetail(data);
                                            // }
                                            // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
                                        }}
                                        onBlur={(editor: any) => {
                                            // console.log("Blur.", editor);
                                        }}
                                        onFocus={(editor: any) => {
                                            // console.log("Focus.", editor);
                                        }}
                                    />
                                </Form.Item>
                                :
                                <Form.Item label="Content (EN)"
                                    // name="detail"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <CKEditor
                                        // name="detailEN"s
                                        // id="detailEN"
                                        editor={ClassicEditor}
                                        // data={recordData.detail}
                                        // data={recordData.detail? recordData.detail : ''}
                                        // data={recordData.localizations.length > 0 ? recordData.localizations[0].detail + "": ''}
                                        // data={vDataCKEN ? vDataCKEN : ''}
                                        data={vDataCKEN}
                                        // data={"kkkkkkk"}
                                        config={CkConfig}
                                        onInit={(editor: any) => {
                                            // You can store the "editor" and use when it is needed.
                                            // console.log("Editor is ready to use!", editor);
                                            // console.log(
                                            //     "toolbar: ",
                                            //     Array.from(editor.ui.componentFactory.names())
                                            // );
                                            // console.log(
                                            //     "plugins: ",
                                            //     ClassicEditor.builtinPlugins.map((plugin:any) => plugin.pluginName)
                                            // );
                                        }}
                                        onChange={(event: any, editor: any) => {
                                            const data = editor.getData();
                                            setDetailEN(data);
                                            // if(vValueLocale == false){ // true == ไทย
                                            //     setDetailEN(data);
                                            // }
                                            // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
                                        }}
                                        onBlur={(editor: any) => {
                                            // console.log("Blur.", editor);
                                        }}
                                        onFocus={(editor: any) => {
                                            // console.log("Focus.", editor);
                                        }}
                                    />
                                </Form.Item>
                            }

                            {
                                vValueLocale ? // TH
                                    <>
                                        <Divider></Divider>
                                        {/* ---------------------------------------------------------------------------------------------------------------- */}
                                        {/* ---------------------------------------------------------------------------------------------------------------- */}
                                        {/* ---------------------------------------------------------------------------------------------------------------- */}
                                        <Col span={24}>
                                            <div><Checkbox onChange={onChangeCheckTypeCoupon} value={vIsTypeCoupon} checked={vIsTypeCoupon}></Checkbox> Privilege ที่สามารถกดรับ ตามโค้ดคูปอง / สิทธิ</div>
                                            <div style={{ marginBottom: "1%" }}></div>
                                        </Col>

                                        {
                                            vIsTypeCoupon ?
                                                <>
                                                    <Row>
                                                        <Col span={2} />
                                                        <Col span={22} >
                                                            <Radio.Group
                                                                onChange={onChangeRadio1}
                                                                // value={vIsPrivCouponChk ? 1 : 0}
                                                                // defaultValue={vRaPrivCoupon == 1 ? 1 : 0}
                                                                value={vRaPrivCoupon == 1 ? 1 : 2}
                                                            >
                                                                <Space direction="vertical">
                                                                    <div style={{ width: "800px" }}>
                                                                        <Radio value={1} style={{ marginBottom: "0%" }} >Privilege แบบคูปอง </Radio>
                                                                        {/* <Input placeholder="รหัสคูปอง" type={"number"} min={0} size="small" style={{width: "25%", borderRadius: "10px"}} /> */}
                                                                        {
                                                                            // vRaPrivCoupon == 1 || vIsPrivCouponChk == true?
                                                                            vRaPrivCoupon == 1 ? //  Privilege แบบคูปอง
                                                                                <>
                                                                                    <Select
                                                                                        style={{ width: "22%" }}
                                                                                        size="small"
                                                                                        // mode="multiple"
                                                                                        placeholder="รหัสคูปอง"
                                                                                        // defaultValue={vCoupon}
                                                                                        defaultValue={vCouponName}
                                                                                        onSelect={(value: any) => {
                                                                                            // console.log(value);
                                                                                            // setStatus(value);
                                                                                            setCoupon(value);
                                                                                        }}
                                                                                        onChange={(value: any) => {
                                                                                            callCountCouponLeft(value);
                                                                                            // setCoupon(value);
                                                                                        }}
                                                                                        {...CouponCodeSelProps}
                                                                                        options={vOpt}


                                                                                    />
                                                                                    <span style={{ marginLeft: "2%" }}></span>
                                                                                    {/* จำนวนสิทธิ :  */}
                                                                                    <Input
                                                                                        type={"number"}
                                                                                        placeholder="จำนวนสิทธิคงเหลือ"
                                                                                        // defaultValue={0}
                                                                                        min={0}
                                                                                        max={vCouponLeft}
                                                                                        size="small"
                                                                                        style={{ width: "22%" }}
                                                                                        value={vInputCouponAmount}
                                                                                        // onKeyPress={(e) => e.preventDefault()}
                                                                                        // onChange={e => setInputCouponAmount(e.target.value)}
                                                                                        onChange={e => {
                                                                                            let value = null
                                                                                            try {
                                                                                                value = Number(e.target.value)
                                                                                                if(value > vCouponLeft){
                                                                                                    value = vCouponLeft
                                                                                                }
                                                                                            } catch (error) {
                                                                                                value = vCouponLeft
                                                                                            }
                                                                                            return setInputCouponAmount(value)
                                                                                        }}
                                                                                    />

                                                                                    <span style={{ fontSize: ".7vw", fontWeight: "600" }}> จำนวนสิทธิคงเหลือ {vCouponLeft} </span>
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                    </div>
                                                                    <div>
                                                                        <Radio value={2} style={{ marginBottom: "0%" }}>Privilege แบบสิทธิ </Radio>
                                                                        {
                                                                            vRaPrivCoupon == 2 ?
                                                                                <>
                                                                                    {/* <Input type={"number"} defaultValue={0} min={0} size="small" style={{width: "25%", borderRadius: "10px"}} value={vInputCouponAmount} onChange={e => setInputCouponAmount(e.target.value)}/> */}
                                                                                    <Input
                                                                                        type={"number"}
                                                                                        placeholder="จำนวนสิทธิคงเหลือ"
                                                                                        // defaultValue={0}
                                                                                        min={0}
                                                                                        size="small"
                                                                                        style={{ width: "22%", borderRadius: "10px" }}
                                                                                        value={vInputCouponAmount}
                                                                                        onChange={e => setInputCouponAmount(e.target.value)}
                                                                                    />
                                                                                </>
                                                                                :
                                                                                <></>
                                                                        }

                                                                    </div>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Col>
                                                    </Row>


                                                    <div style={{ marginBottom: "1%" }}></div>
                                                    <Row>
                                                        <Col span={1} />
                                                        <Col span={12} >
                                                            <div>วิธีการคำนวนสิทธิ</div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ marginBottom: "1%" }}></div>
                                                    <Row>
                                                        <Col span={2} />
                                                        <Col span={12} >
                                                            <Radio.Group
                                                                onChange={onChangeRadio2}
                                                                defaultValue={recordData.coupon_condition ? 1 : 2}
                                                                value={vRaCalRight}
                                                            >
                                                                <Space direction="vertical">
                                                                    <Radio value={1} style={{ marginBottom: "0%" }}>โควต้ารวมทุกโครงการ {"(First come first serve)"} </Radio>
                                                                    <Radio value={2} style={{ marginBottom: "0%" }}>แบ่งเท่า ๆ กัน แต่ละโครงการ </Radio>
                                                                </Space>
                                                            </Radio.Group>
                                                        </Col>
                                                    </Row>


                                                    <div style={{ marginBottom: "1%" }}></div>
                                                    <Row>
                                                        <Col span={1} />
                                                        <Col span={12} >
                                                            <div>ระยะเวลา</div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ marginBottom: "1%" }}></div>

                                                    <Row>
                                                        <Col span={2}></Col>
                                                        <Col span={4}>
                                                            {/* <span><b><div style={{fontSize: "12px"}}>Period การกดรับโค้ดคูปอง / สิทธิ : </div><>&nbsp;</></b></span> */}
                                                            <span><b><div style={{ fontSize: "12px" }}>Period การกดรับ : </div><>&nbsp;</></b></span>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                            // name="" 
                                                            >
                                                                <DatePicker.RangePicker
                                                                    // defaultValue={[ dayjs(recordData.keep_coupon_start_date), dayjs(recordData.keep_coupon_end_date)]}
                                                                    // value={[ dayjs(recordData.keep_coupon_start_date), dayjs(recordData.keep_coupon_end_date)]}
                                                                    // value={[ dayjs(vDateKeepCouponStart), dayjs(vDateKeepCouponEnd)]}
                                                                    value={vDateKeepCou}
                                                                    // value={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                                    size="small"
                                                                    showTime
                                                                    style={{ width: "100%" }}
                                                                    onChange={(date, dateString) => selectTimeKeepCouponDate(date, dateString)}
                                                                    format={format}
                                                                    allowClear={true}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    {/* <div style={{marginBottom:"1%"}}></div> */}

                                                    <Row style={{ marginTop: "-2%" }} >
                                                        <Col span={2}></Col>
                                                        <Col span={4}>
                                                            {/* <span><b><div style={{fontSize: "12px"}}>Period การกดใช้โค้ดคูปอง / สิทธิ : </div><>&nbsp;</></b></span> */}
                                                            <span><b><div style={{ fontSize: "12px" }}>Period การกดใช้ : </div><>&nbsp;</></b></span>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                            // name="" 
                                                            >

                                                                {/* example */}
                                                                {/* <DatePicker.RangePicker
                                                                // defaultValue={[ dayjs(recordData.start_date), dayjs(recordData.end_date)]}
                                                                value={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                                showTime
                                                                style={{width:"100%"}}
                                                                onChange={(date, dateString) => selectTime(date, dateString)}
                                                                format={format}
                                                            /> */}

                                                                <DatePicker.RangePicker
                                                                    // defaultValue={[ dayjs(recordData.use_coupon_start_date), dayjs(recordData.use_coupon_end_date)]}
                                                                    // value={[ dayjs(vDateUseCouponStart), dayjs(vDateUseCouponEnd)]}
                                                                    value={vDateUseCou}
                                                                    size="small"
                                                                    showTime
                                                                    style={{ width: "100%" }}
                                                                    onChange={(date, dateString) => selectTimeUseCouponDate(date, dateString)}
                                                                    format={format}
                                                                    allowClear={true}
                                                                />

                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <></>
                                        }
                                        {/* ---------------------------------------------------------------------------------------------------------------- */}

                                        <Col span={24}>
                                            <div><Checkbox onChange={onChangeCheckTypeEvent} value={vIsTypeEvent} checked={vIsTypeEvent}></Checkbox> Privilege กิจกรรม</div>
                                        </Col>
                                        {
                                            vIsTypeEvent ?
                                                <>
                                                    <Row style={{ marginTop: "2%" }} >
                                                        <Col span={2}></Col>
                                                        <Col span={7}>
                                                            {/* <span style={{floaevent_end_datet:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                                            <span><b><div style={{ fontSize: "12px" }}>Event date : </div><>&nbsp;</></b></span>
                                                        </Col>
                                                        <Col span={9}>
                                                            <Form.Item
                                                            // name="" 
                                                            >
                                                                <DatePicker.RangePicker
                                                                    // defaultValue={[ dayjs(recordData.event_start_date), dayjs(recordData.event_end_date)]}
                                                                    // value={[ dayjs(vDateEventStart), dayjs(vDateEventEnd)]}
                                                                    value={vDateEvent}
                                                                    // size="small"
                                                                    showTime
                                                                    style={{ width: "100%" }}
                                                                    onChange={(date, dateString) => selectTimeEvent(date, dateString)}
                                                                    format={format}
                                                                    // format="YYYY/MM/DD HH:mm:ss"
                                                                    allowClear={true}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <div style={{ marginBottom: "-2%" }}></div>
                                                    <Row>
                                                        <Col span={1} />
                                                        <Col span={12} >
                                                            <div style={{ marginBottom: "0%" }}>Limit คนเข้าร่วม</div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col span={2} />
                                                        <Col span={12} >
                                                            <Radio.Group
                                                                onChange={onChangeRadio3}
                                                                // value={vRaLimitUser == true ? 2 : 1}
                                                                value={vRaLimitUser}
                                                            // value={recordData.limit_user ? 1 : recordData.limit_user == null ? 0 : 2}
                                                            >
                                                                <Space direction="vertical">
                                                                    <Radio value={1} style={{ marginBottom: "0%" }}>ไม่จำกัดจำนวน</Radio>
                                                                    <div>
                                                                        <Radio value={2} style={{ marginBottom: "0%" }}>จำกัดจำนวน </Radio>
                                                                        {/* <Input type={"number"} min={0} size="small" style={{width: "50%", borderRadius: "10px"}} />  */}
                                                                        {
                                                                            vRaLimitUser == 2 ?
                                                                                <Input
                                                                                    type={"number"}
                                                                                    placeholder="จำนวนคนเข้าร่วม"
                                                                                    // defaultValue={0}
                                                                                    min={0}
                                                                                    size="small"
                                                                                    style={{ width: "50%", borderRadius: "10px" }}
                                                                                    // value={vInputCouponAmount}
                                                                                    value={vInputEventCLout}
                                                                                    onChange={e => setInputEventCLout(e.target.value)}
                                                                                />
                                                                                :
                                                                                <></>
                                                                        }


                                                                        {/* <Input type={"number"} defaultValue={0} min={0} size="small" style={{width: "50%", borderRadius: "10px"}} value={vInputCouponAmount} onChange={e => setInputCouponAmount(e.target.value)}/> */}
                                                                    </div>

                                                                </Space>
                                                            </Radio.Group>
                                                        </Col>
                                                        {/* <Col span={10}>
                                                    <Input type={"number"} min={0} size="small" style={{width: "50%", borderRadius: "10px"}} />
                                                    </Col> */}
                                                    </Row>
                                                    <div style={{ marginBottom: "2%" }}></div>
                                                    <Row>
                                                        <Col span={1}></Col>
                                                        <Col span={4}>
                                                            <div style={{ marginBottom: "0%" }}>เปิดให้ Comment</div>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Select
                                                                // placeholder={t("select.sel")}
                                                                size="small"
                                                                placeholder="เปิดให้ Comment"
                                                                style={{ width: '40%' }}
                                                                defaultValue={vCanComment}
                                                                onSelect={(value: any) => {
                                                                    // console.log(value);
                                                                    // setStatus(value);
                                                                    setCanComment(value);
                                                                }}
                                                                options={[
                                                                    {
                                                                        label: "On",
                                                                        value: true,
                                                                    },
                                                                    {
                                                                        label: "Off",
                                                                        value: false,
                                                                    }
                                                                ]}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <></>
                                        }
                                    </>

                                    :
                                    <></>
                            }

                            {
                                vValueLocale ? // TH
                                    <>
                                        <div style={{ marginTop: "5%" }}></div>
                                        <Row>
                                            {/* <Col span={2} /> */}
                                            <Col span={12} >
                                                <div>Set up สิทธิการใช้งาน</div>
                                            </Col>
                                        </Row>

                                        <Row style={{ marginTop: "1%" }} >
                                            <Col span={1}></Col>
                                            <Col span={5}>
                                                {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                                {/* <span><b><div style={{fontSize: "12px"}}>User ที่ได้รับสิทธิ : </div><>&nbsp;</></b></span> */}
                                                <span><b><div style={{ color: "#f5222d", fontWeight: "300", display: "inline-block", fontSize: "16px", marginRight: "5px" }}>*</div><div style={{ fontSize: "12px", display: "inline-block" }}>User ที่มองเห็น : </div><>&nbsp;</></b></span>
                                            </Col>
                                            <Col span={9}>
                                                {/* <Form.Item 
                                                    name="privilege_user_sub_type" 
                                                >
                                                    <Select 
                                                        size="small"
                                                        style={{width:"95%"}}
                                                        mode="multiple"
                                                        placeholder={t("select.sel")}
                                                        onChange={onChangePrivUserSubType}
                                                        {...PrivUserSubType}
                                                    />
                                                </Form.Item> */}
                                                {
                                                    vIsBirthDay ?
                                                        <Form.Item
                                                        // name="privilege_user_sub_type" 
                                                        >
                                                            <Select
                                                                size="small"
                                                                style={{ width: "95%" }}
                                                                // mode="multiple"
                                                                placeholder={t("select.sel")}
                                                                // {...PrivUserSubType}
                                                                defaultValue={1}
                                                                disabled
                                                            >
                                                                <Option value={1}>Owner</Option>
                                                            </Select>
                                                        </Form.Item>
                                                        :
                                                        <Form.Item
                                                            name="privilege_user_sub_type"
                                                            // name={["privilege_user_sub_type", "sub_type_name"]}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please select.'
                                                                },
                                                            ]}
                                                        >
                                                            <Select
                                                                size="small"
                                                                style={{ width: "95%" }}
                                                                mode="multiple"
                                                                placeholder={t("select.sel")}
                                                                onChange={onChangePrivUserSubType}

                                                                {...PrivUserSubType}
                                                            />
                                                        </Form.Item>
                                                }
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: "-1%" }} >
                                            <Col span={1}></Col>
                                            <Col span={5}>
                                                {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                                <span>
                                                    <b><div style={{ fontSize: "12px" }}>โครงการที่ได้รับสิทธิ : </div><>&nbsp;</></b>
                                                </span>
                                            </Col>
                                            <Col span={15}>

                                                <Radio.Group onChange={onChangeRadio5}
                                                    // value={vPropertyUnder}
                                                    value={vRaSpecificProperty}
                                                >
                                                    <Space direction="vertical">

                                                        <div>
                                                            <Radio value={1} style={{ marginBottom: "0%" }}> ทุกโครงการ </Radio>
                                                        </div>
                                                        <div>
                                                            <Radio value={2} style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Major เท่านั้น </Radio>
                                                        </div>

                                                        <div>
                                                            <Radio value={3} style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Non Major เท่านั้น </Radio>
                                                        </div>
                                                        <div style={{ width: '250px' }}>
                                                            <Radio value={4} style={{ marginBottom: "0%" }}> ระบุโครงการ </Radio>
                                                            {
                                                                vRaSpecificProperty == 4 ?
                                                                    <>
                                                                        <Form.Item
                                                                            name="privilege_properties"
                                                                            rules={[
                                                                                {
                                                                                    required: vKeep.length == 0 ? true : false, message: 'Please select property.'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                size="small"
                                                                                style={{ width: "95%" }}
                                                                                mode="multiple"
                                                                                placeholder={t("select.sel")}
                                                                                {...PrivProperties}
                                                                                onChange={onChangePrivProps}
                                                                            />
                                                                        </Form.Item>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </Space>
                                                </Radio.Group>

                                                {/* <Form.Item 
                                                    name="privilege_properties"
                                                >
                                                    <Select 
                                                        size="small"
                                                        style={{width:"95%"}}
                                                        mode="multiple"
                                                        placeholder={t("select.sel")}
                                                        onChange={onChangePrivProps}
                                                        {...PrivProperties}
                                                    />
                                                </Form.Item> */}
                                            </Col>
                                        </Row>


                                    </>
                                    :
                                    <></>
                            }

                            {
                                // console.log('vIsBirthDay', vIsBirthDay)
                                console.log('vSitUnitType', vSitUnitType)
                            }
                            {
                                vValueLocale && // TH
                                    <>
                                        <div style={{ marginBottom: "3%" }}></div>
                                        {
                                            vIsBirthDay ? <></> :
                                            <>
                                                {/* {vSitUnitType?.length > 0 && */}
                                                    <PNCONDITION_EDIT
                                                        onChangeCheckCondition={onChangeCheckCondition}
                                                        vCondition={vCondition}
                                                        vUTLength={vUTLength}
                                                        vUserConditionAmount={vUserConditionAmount}
                                                        setUserConditionAmount={setUserConditionAmount}
                                                        setUserConditionName={setUserConditionName}
                                                        PrivUserSubType={PrivUserSubTypeOnlyCus}
                                                        onChangeRadio4={onChangeRadio4}
                                                        // unitTypeData={unitTypeData.data?.data}
                                                        unitTypeDataFromLog={unitTypeData?.data?.data}
                                                        unitTypeData={unitBedroom.data?.data}
                                                        onChangeOnlyRight={onChangeOnlyRight}
                                                        onChangeOnlyRightMulti={onChangeOnlyRightMulti}
                                                        chkRoomType={chkRoomType}
                                                        tickerFlag={tickerFlag}
                                                        vRaRightCondition={vRaRightCondition}
                                                        vRaPrivCoupon={vRaPrivCoupon}
                                                        vCouponLeft={vCouponLeft}
                                                        vInputCouponAmount={vIsTypeCoupon ? vInputCouponAmount : vIsTypeEvent ? vRaLimitUser == 1 ? 0 : vInputEventCLout : 0}
                                                        
                                                        setUnitValue3m={setUnitValue3m}
                                                        setUnitValue4m={setUnitValue4m}
                                                        setUnitValue9m={setUnitValue9m}
                                                        setUnitValue29m={setUnitValue29m}
                                                        setUnitValue59m={setUnitValue59m}
                                                        setUnitValue99m={setUnitValue99m}
                                                        setUnitValue100m={setUnitValue100m}

                                                        vUnitValue3m={vUnitValue3m}
                                                        vUnitValue4m={vUnitValue4m}
                                                        vUnitValue9m={vUnitValue9m}
                                                        vUnitValue29m={vUnitValue29m}
                                                        vUnitValue59m={vUnitValue59m}
                                                        vUnitValue99m={vUnitValue99m}
                                                        vUnitValue100m={vUnitValue100m}
                                                        vSitUnitType={vSitUnitType}
                                                        dataAmountPlace={dataAmountPlace}
                                                        chkPriceType={chkPriceType}
                                                        vSitPriceType={vSitPriceType}
                                                    />
                                                {/* } */}
                                            </>
                                        }
                                    </>
                            }


                            {
                                vValueLocale && // TH
                                    <>
                                        <Divider></Divider>

                                        <Row>
                                            <Col span={12}>
                                                <Form.Item
                                                    // name="date_start"
                                                    // name="start_date"
                                                    label="Content public date"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select public date.'
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker.RangePicker
                                                        // defaultValue={[ dayjs(recordData.start_date), dayjs(recordData.end_date)]}
                                                        // value={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                        value={vContentPublicDate}
                                                        showTime
                                                        // size="small"
                                                        style={{ width: "95%" }}
                                                        onChange={(date, dateString) => selectTime(date, dateString)}
                                                        format={format}
                                                        allowClear={true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                {/* <Form.Item name="notice_channel" label={t("notice-ch")}>
                                                    <Radio.Group
                                                        options={[
                                                            {
                                                                label: "แจ้งเตือน",
                                                                value: true,
                                                            },
                                                            {
                                                                label: "ไม่แจ้งเตือน",
                                                                value: false,
                                                            }
                                                        ]}
                                                        onChange={onChangeNoti}
                                                    />
                                                </Form.Item> */}
                                                {/* <Form.Item name="seq" 
                                                    // label={t("notice-ch")}
                                                    // label="Content Privilege Sequences"
                                                    label="Content Sequences"
                                                >
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="Sequences"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "100%", borderRadius: "5px"}}
                                                        // value={vInputSeq}
                                                        onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item> */}

                                                <Form.Item
                                                    name="status"
                                                    label="Status"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select status.'
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder={t("select.sel")}
                                                        // size="small"
                                                        style={{ width: '95%' }}
                                                        onSelect={(value: any) => {
                                                            // console.log(value);
                                                            // setStatus(value);
                                                        }}
                                                        options={[
                                                            {
                                                                label: "Public",
                                                                value: "PUBLIC",
                                                            },
                                                            {
                                                                label: "Draft",
                                                                value: "DRAFT",
                                                            },
                                                            {
                                                                label: "End",
                                                                value: "END",
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>




                                            </Col>
                                        </Row>

                                        {/* <Row>
                                            <Col span={12}>
                                                <Form.Item name="seq"
                                                    // label={t("notice-ch")}
                                                    // label="Content Activity &amp; Event Sequences"
                                                    label="Content Sequences"
                                                >
                                                    <Input
                                                        type={"number"}
                                                        placeholder="Sequences"
                                                        // defaultValue={0}
                                                        min={0}
                                                        // size="small"
                                                        style={{ width: "95%", borderRadius: "5px" }}
                                                    // value={vInputSeq}
                                                    // onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}></Col>
                                        </Row> */}


                                        <Row>
                                            {/* <Col span={12}>
                                                <Form.Item 
                                                    name="status"
                                                    label="Status"
                                                    rules={[
                                                        {
                                                            required: true, message: 'please select status.'
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder={t("select.sel")}
                                                        size="small"
                                                        style={{ width: '95%' }}
                                                        onSelect={(value: any) => {
                                                            console.log(value);
                                                            setStatus(value);
                                                        }}
                                                        options={[
                                                            {
                                                                label: "Public",
                                                                value: "PUBLIC",
                                                            },
                                                            {
                                                                label: "Draft",
                                                                value: "DRAFT",
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col> */}

                                            {/* <Col span={12}>
                                                <Form.Item name="seq" 
                                                    // label={t("notice-ch")}
                                                    // label="Content Privilege Sequences"
                                                    label="Content Sequences"
                                                >
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="Sequences"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "100%", borderRadius: "10px"}}
                                                        // value={vInputSeq}
                                                        onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col> */}
                                        </Row>

                                    </>
                            }
                        </Card>
                    </Col>
                    {/* for merge */}
                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel"><span style={{ color: "#f5222d", fontWeight: "300", fontSize: "16px", marginRight: "5px" }}>*</span>Thumbnail</span></div>
                            <Divider></Divider>

                            {/* <Form.Item
                        name="pic"
                        // valuePropName="fileList"
                        // getValueProps={(data) => getValueProps(data, API_URL)}
                    >

                        <Dragger style={{borderRadius: "10px"}} 
                            {...vImg}
                            name="files"
                            action={`${API_URL}/upload`}
                            headers={{
                                Authorization: `Bearer ${localStorage.getItem(
                                    TOKEN_KEY,
                                )}`,
                            }}
                            listType="picture"
                            // multiple
                            // {...uploadProps_coverpix}
                            maxCount={1}
                            // onChange={fileChange}
                            beforeUpload={beforeUpload}
                        >
                            <p>Upload </p>
                        </Dragger>
                    </Form.Item> */}

                            {
                                vValueLocale ?
                                    <>
                                        <Form.Item
                                            name="pic"
                                            rules={[
                                                {
                                                    required: vImgEn !== [] ? false : true
                                                },
                                            ]}
                                        >
                                            <Dragger style={{ borderRadius: "10px" }}
                                                {...vImg}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                beforeUpload={beforeUpload}
                                                onChange={fileChange_TH}
                                            >
                                                <p>Upload</p>
                                            </Dragger>
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item
                                            name="pic2"
                                            rules={[
                                                {
                                                    required: vImgEn !== [] ? false : true
                                                },
                                            ]}
                                        >
                                            <Dragger style={{ borderRadius: "10px" }}
                                                {...vImgEn}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                beforeUpload={beforeUpload}
                                                onChange={fileChange_EN}
                                            >
                                                <p>Upload</p>
                                            </Dragger>
                                        </Form.Item>
                                    </>
                            }

                            <p style={{display: keepStatusIMG == 0 && vImgEn !== [] ? 'flex' : 'none', color: 'red'}}>Please upload image.</p>
                            <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Picture 750*500 PNG, JPG Maximum size 5MB</p>
                        </Card>

                        {
                            vValueLocale ? // TH
                                <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <div style={{ marginBottom: 20 }}><span className="title_panel">Notifications</span></div>
                                    <Divider></Divider>
                                    <Form.Item name="notice_channel" label={t("notice-ch")}>
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "แจ้งเตือน",
                                                    value: true,
                                                },
                                                {
                                                    label: "ไม่แจ้งเตือน",
                                                    value: false,
                                                }
                                            ]}
                                            onChange={onChangeNoti}
                                            value={value}
                                        />
                                    </Form.Item>

                                    {value === true ?
                                        <>
                                            <Form.Item label="Notice Date"
                                                name="notice_date"
                                                rules={[
                                                    { required: true, message: 'Please select date and time.' },
                                                ]}
                                                getValueProps={(value) => ({
                                                    value: value ? dayjs(value) : "",
                                                })}
                                            >
                                                <DatePicker
                                                    showTime
                                                    style={{ width: "100%" }}
                                                    format={format}
                                                />
                                            </Form.Item>

                                            <Form.Item label="Customer Type"
                                                name="notice_customer_type"
                                                rules={[
                                                    { required: true, message: 'Please select customer type.' },
                                                ]}
                                            >
                                                <Select
                                                    // size="small"
                                                    style={{ width: "100%" }}
                                                    mode="multiple"
                                                    placeholder={t("select.sel")}
                                                    onChange={onChangeSubCusType}
                                                    {...PrivUserSubTypeOnlyCus}
                                                />
                                            </Form.Item>
                                            <Form.Item style={{ marginBottom: "7%" }}>
                                                <Checkbox checked={checkedApp} onChange={onChangeCheckApp}>
                                                    In App
                                                </Checkbox>

                                                {
                                                    checkedApp ?
                                                        <>
                                                            <Form.Item name="notice_inApp"
                                                                rules={[
                                                                    { required: true, message: 'Please Type Message.' },
                                                                ]}
                                                            >
                                                                <Input placeholder="In app message" maxLength={85} />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <div style={{ marginBottom: '1%' }}></div>
                                                <Checkbox checked={checkedSMS} onChange={onChangeCheckSMS}>
                                                    SMS
                                                </Checkbox>

                                                {
                                                    checkedSMS ?
                                                        <>
                                                            <Form.Item name="notice_sms"
                                                                rules={[
                                                                    { required: true, message: 'Please Type Message.' },
                                                                ]}
                                                            >
                                                                <Input placeholder="SMS message" maxLength={85} />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <div style={{ marginBottom: '1%' }}></div>
                                                <Checkbox checked={checkedEmail} onChange={onChangeCheckEmail}>
                                                    Email
                                                </Checkbox>
                                                {
                                                    checkedEmail ?
                                                        <>
                                                            <Form.Item name="notice_email"
                                                                rules={[
                                                                    { required: true, message: 'Please Type Message.' },
                                                                ]}
                                                            >
                                                                <TextArea placeholder="Message" maxLength={255} style={{ height: "30px", maxHeight: "80px" }} />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </Form.Item>
                                        </>
                                        :
                                        <></>
                                    }
                                </Card>
                                :
                                <></>
                        }
                    </Col>
                </Row>
                <div style={{ marginBottom: 20 }}></div>

                <Row style={{ float: 'right' }}>
                    {/* <Button onClick={()=> form.submit()} style={{backgroundColor:'black', color:'white'}}><SaveOutlined />Save</Button> */}
                    <Button
                        onClick={() => form.submit()}
                        type="primary"
                        disabled={keepStatusIMG == 0 ? true : false}
                        // style={{borderRadius: "5px"}}
                    >
                        <SaveOutlined />Save
                    </Button>
                </Row>

            </Form>
        </Edit>
    );
};