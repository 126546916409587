/* eslint-disable */
import { Card, Col, Edit, Divider, Form, Input, Row, useForm, TextField, List, Breadcrumb } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useNavigation, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IPhoneBookType } from "interfaces";
import Moment from 'moment';

export const PhoneBooksTypeEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps } = useForm<IPhoneBookType,HttpError,{}>();
    const { replace } = useNavigation();
    const { mutate: CreateLog }     = useCreate<IEventlog>();
    const log_endpoint              = "even-logs";
    const { queryResult } = useShow<IPhoneBookType>({metaData: {
        populate: '*'
        }, });
    const { data } = queryResult;
    const record = data?.data;

    function updateFunction(){
        // mutate({
        //     resource    : end_point,
        //     id          : queryResult.data?.data.id+"", // มาจาก useShow
        //     values: { 
        //         // isOwnerConfirmed: switchToggle_owner, 
        //         // isAdminConfirmed: switchToggle_admin,
        //         // vReasonRejectOwner
        //         // vReasonRejectAdmin
        //         rejection_owner: vReasonRejectOwner,
        //         rejection_admin: vReasonRejectAdmin,
        //         isOwnerConfirmed: vValueOwner, 
        //         isAdminConfirmed: vValueAdmin,
        //         user_profile: queryResult.data?.data.user_profile.id + "",
        //         unit: queryResult.data?.data.unit.id + "",
        //         user_sub_type: queryResult.data?.data.user_sub_type.id + ""
        //     },
        // });

        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'phonebook_type',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
        // replace("");
    }


    console.log("edit posts-type",record)
    //const API_URL = useApiUrl();
    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("phonebook-type-edit.title")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("phonebook-type-edit.title")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
          onFinish={(values:any) => {
            //values.photo_list   = vphoto_list;
            updateFunction();
            values.updateBy = user_data.user_profile.id
            values.updatedBy = user_data.user_profile.id
            return (
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
               
            );
        }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("name")} name="name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={14}>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createdBy? record?.createdBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.updatedBy? record?.updatedBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>
    );
};