import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Radio, Row, Select, SelectProps, Space } from "@pankod/refine-antd";

import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
// import en from 'world_countries_lists/data/countries/en/world.json';

import { useTranslate } from "@pankod/refine-core";

// import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

interface IPANEL {
    onChangeCheckCondition: any
    vCondition: any
    setUserConditionAmount: any
    PrivUserSubType: SelectProps
    onChangeRadio4: (value: any) => void
    vUserConditionAmount: any
    onChangeOnlyRight: (value: any) => void
    onChangeOnlyRightMulti: (value: any) => void
    vRaRightCondition : any
    setUserConditionName: any
    unitTypeData: any
    chkRoomType : (id:any, type_name:any, amount:any) => void
    chkPriceType : (id:any, type_name:any, amount:any) => void
    setUnitValue3m : any
    setUnitValue4m : any
    setUnitValue9m : any
    setUnitValue29m : any
    setUnitValue59m : any
    setUnitValue99m : any
    setUnitValue100m : any
    vUTLength : any
    unitTypeDataFromLog : any
    tickerFlag: boolean

    vRaPrivCoupon : any
    vCouponLeft : any
    vInputCouponAmount: any

    vUnitValue3m : any
    vUnitValue4m : any
    vUnitValue9m : any
    vUnitValue29m : any
    vUnitValue59m : any
    vUnitValue99m : any
    vUnitValue100m : any
    vSitUnitType: any
    vSitPriceType:any
    dataAmountPlace:any
}

export const PNCONDITION_EDIT: React.FC<IPANEL> = (
    {
        // setTel, 
        // setStatus, 
        // SelCompany, 
        // onchangeCompany, 
        // SelDepartment, 
        // PositionData, 
        // onChangePosition, 
        // SelRole,
        // onchangeDepartment, 
        // onchangeRole
        onChangeCheckCondition,
        vCondition,
        setUserConditionAmount,
        PrivUserSubType,
        onChangeRadio4,
        vUserConditionAmount,
        onChangeOnlyRight,
        onChangeOnlyRightMulti,
        vRaRightCondition,
        unitTypeData,
        unitTypeDataFromLog,
        vUTLength,
        setUserConditionName,
        chkRoomType,
        chkPriceType,
        vRaPrivCoupon,
        tickerFlag,
        vCouponLeft,
        vInputCouponAmount,
        setUnitValue3m,
        setUnitValue4m,
        setUnitValue9m,
        setUnitValue29m,
        setUnitValue59m,
        setUnitValue99m,
        setUnitValue100m,
        vUnitValue3m,
        vUnitValue4m,
        vUnitValue9m,
        vUnitValue29m,
        vUnitValue59m,
        vUnitValue99m,
        vUnitValue100m,
        vSitUnitType,
        vSitPriceType,
        dataAmountPlace

    }) => {

        // console.log('unitTypeData', unitTypeData)
    // console.log('testUserConditionAmount', testUserConditionAmount)

    const t = useTranslate();
    const [keepE, setKeepE] = useState<any>()
    function handlechange(e: any){
        let val: any = Math?.max(Number(0), Math?.min(Number(vInputCouponAmount), Number(e)));
        setUserConditionAmount(val)

        console.log("E",e)
        if (e > vInputCouponAmount) {
            setKeepE(vInputCouponAmount)
        }else{
            setKeepE(e)
        }
    }

    return (
        <>
            <Row>
                <Col span={12}><div>เงื่อนไขการรับสิทธิ</div></Col>
            </Row>

            <Row>
                <Col span={1}/>
                <Col span={22}>
                    <div style={{marginTop:"2%"}}>
                        <Checkbox onChange={onChangeCheckCondition} checked={vCondition} /> 
                        <span>กำหนดเงื่อนไขการรับสิทธิ</span> 
                    </div>
                </Col>
            </Row>
            
            {vCondition &&
                <Row>
                    <Col span={2}></Col>
                    <Col span={20} >
                        <Radio.Group onChange={onChangeRadio4} value={parseInt(vRaRightCondition)} style={{width: "100%"}}>
                            <Space direction="vertical" style={{width: "100%"}}>
                                <div> {/*1 Unit ได้รับ*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={1} style={{marginBottom:"0%"}}> 1 Unit ได้รับ </Radio></Col>
                                        <Col span={12}>
                                            {vRaRightCondition == 1 && vRaPrivCoupon == 1 ?
                                                <Input
                                                    size="small"
                                                    type={"number"}
                                                    placeholder="จำนวนสิทธิ"
                                                    //defaultValue={0}
                                                    min={0}
                                                    max={vInputCouponAmount}
                                                    value={vUserConditionAmount}
                                                    style={{width: "30%", borderRadius: "10px"}}
                                                    onChange={e => handlechange(e.target.value)}
                                                />
                                                : vRaRightCondition == 1 &&
                                                <Input
                                                    size="small"
                                                    type={"number"}
                                                    placeholder="จำนวนสิทธิ"
                                                    //defaultValue={0}
                                                    min={0}
                                                    max={vInputCouponAmount}
                                                    value={vUserConditionAmount}
                                                    style={{width: "30%", borderRadius: "10px"}}
                                                    onChange={e => handlechange(e.target.value)}
                                                />
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div> {/*1 Thai ID / Passport ได้รับ*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={2} style={{marginBottom:"0%"}}> 1 Thai ID / Passport ได้รับ </Radio></Col>
                                        <Col span={12}>
                                            {vRaRightCondition == 2 &&
                                                <Input 
                                                    type={"number"}
                                                    placeholder="จำนวนสิทธิ"
                                                    //defaultValue={0}
                                                    max={vInputCouponAmount}
                                                    min={0}
                                                    size="small"
                                                    style={{width: "30%", borderRadius: "10px"}}
                                                    value={vUserConditionAmount}
                                                    onChange={e => handlechange(e.target.value)}
                                                />
                                            }
                                        </Col>
                                    </Row>    
                                </div>

                                <div> {/*ให้สิทธิเฉพาะ*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={3} style={{marginBottom:"0%"}}> ให้สิทธิเฉพาะ </Radio> </Col>
                                        <Col span={12}>
                                            {vRaRightCondition == 3 &&
                                                <Form.Item name="user_condition_sub_types">
                                                    <Select 
                                                        size="small"
                                                        style={{width:"50%"}}
                                                        mode="multiple"
                                                        placeholder={t("select.sel")}
                                                        onChange={onChangeOnlyRightMulti}
                                                        {...PrivUserSubType}
                                                    />
                                                </Form.Item>
                                            }
                                        </Col>
                                    </Row>
                                </div>

                                <div> {/*Account Type*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={4} style={{marginBottom:"0%"}}> Account Type </Radio></Col>
                                        <Col span={12}>
                                            {vRaRightCondition == 4 &&
                                                <>
                                                    <Form.Item 
                                                        name="user_condition_sub_types"
                                                    >
                                                        <Select
                                                            size="small"
                                                            style={{width:"40%"}}
                                                            placeholder={t("select.sel")}
                                                            onChange={onChangeOnlyRight}
                                                            {...PrivUserSubType}
                                                        />
                                                    </Form.Item>
                                                    <span style={{marginLeft:"1%"}}>ได้รับ</span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        value={vUserConditionAmount}
                                                        onChange={e => handlechange(e.target.value)}
                                                    />
                                                </>
                                        }
                                        </Col>
                                    </Row>
                                </div>

                                {console.log(">>> vSitUnitType", vSitUnitType)}

                                <div> {/*สิทธิของแต่ละ Room Type*/}
                                    <Radio value={5} style={{marginBottom:"0%"}} className="radio_custom_i_x_2"> สิทธิของแต่ละ Room Type 
                                        {vRaRightCondition == 5 && vUTLength > 0 ?
                                            // มีของอยู่ใน privilege_ut_log
                                            <>{unitTypeDataFromLog !== undefined && unitTypeDataFromLog?.map((el:any, idx:any) => {
                                                return(
                                                    <Row gutter={16} style={{marginBottom: "0.5rem",marginTop: unitTypeData?.length - 1 == idx ? "0":"0.5rem"}} key={idx}>
                                                        <Col span={6}>{el?.unit_bedroom?.bedroomName}</Col>
                                                        <Col span={12}>
                                                            <div style={{ display:"none"}}>{JSON.stringify(tickerFlag)}</div>
                                                            {console.log("XXXXXXXXXXX")}
                                                            <Input 
                                                                type={"number"}
                                                                placeholder="จำนวนสิทธิ"
                                                                min={0}
                                                                size="small"
                                                                // defaultValue={0}
                                                                style={{width: "30%", borderRadius: "10px"}}
                                                                onChange={e => chkRoomType(el?.id, el?.bedroomName, e?.target?.value)}
                                                                defaultValue={vSitUnitType?.length && vSitUnitType?.find((f:any) => { return f.realID === el.id })?.amount}
                                                                // value={vSitUnitType?.find((f:any) => { return f.realID === el.id })?.amount}
                                                                max={(vSitUnitType?.length && vSitUnitType?.find((f:any) => { return f.realID === el.id })?.valueMax)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                )
                                            })}</>
                                            :
                                            <>{vRaRightCondition == 5 && unitTypeData !== undefined && unitTypeData.map((el:any, idx:any) => {
                                                return <>
                                                    <Row gutter={16} style={{marginBottom: "0.5rem",marginTop: unitTypeData?.length - 1 == idx ? "0":"0.5rem"}}>
                                                        <Col span={6}>{el.bedroomName}</Col>
                                                        <Col span={12}>
                                                            <div style={{ display:"none"}}>{JSON.stringify(tickerFlag)}</div>
                                                            <Input 
                                                                type={"number"}
                                                                placeholder="จำนวนสิทธิ"
                                                                min={0}
                                                                size="small"
                                                                defaultValue={vSitUnitType?.find((f:any) => { return f.realID === el.id })?.amount}
                                                                // value={vSitUnitType?.find((f:any) => { return f.realID === el.id })?.amount}
                                                                style={{width: "30%", borderRadius: "10px"}}
                                                                onChange={e => chkRoomType(el.id, el.bedroomName, e.target.value)}
                                                                max={(vSitUnitType?.find((f:any) => { return f.realID === el.id })?.valueMax)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                                })}
                                            </> 
                                        }
                                    </Radio>
                                </div>

                                <div> {/*สิทธิตามมูลค่า*/}
                                    <Radio value={6} style={{marginBottom:"0%"}} className="radio_custom_i_x_2"> สิทธิตามมูลค่า
                                        {vRaRightCondition == 6 && dataAmountPlace !== undefined && dataAmountPlace.map((el:any, idx:any) => {
                                            return (
                                                <Row gutter={16} style={{marginBottom: "0.5rem",marginTop: unitTypeData?.length - 1 == idx ? "0":"0.5rem"}}>
                                                    <Col span={6}>{el.type_name}</Col>
                                                    <Col span={12}>
                                                        <Input 
                                                            type={"number"}
                                                            placeholder="จำนวนสิทธิ"
                                                            min={0}
                                                            size="small"
                                                            defaultValue={0}
                                                            style={{width: "30%", borderRadius: "10px"}}
                                                            onChange={e => chkPriceType(el.id, el.bedroomName, e.target.value)}
                                                            max={(vSitPriceType.find((f:any) => { return f.id === el.id }).valueMax)}
                                                        />
                                                    </Col>
                                                </Row>
                                            )
                                        })}
                                    </Radio>  
                                </div>
                                
                                <div> {/*สิทธิเฉพาะจำนวน ตัว ที่ลงทะเบียนสัตว์เลี้ยง*/}
                                    <Radio value={7} style={{marginBottom:"0%", }} > สิทธิเฉพาะจำนวน ตัว ที่ลงทะเบียนสัตว์เลี้ยง </Radio> 
                                    {
                                        vRaRightCondition == 7 ?
                                        <>
                                            <span style={{marginLeft:"5px"}}></span>
                                            <Input 
                                                type={"number"}
                                                placeholder="จำนวนสิทธิ"
                                                // defaultValue={0}
                                                min={0}
                                                size="small"
                                                style={{width: "45%", borderRadius: "10px"}}
                                                value={vUserConditionAmount}
                                                onChange={e => handlechange(e.target.value)}
                                            />
                                        </>
                                        :
                                        <></>
                                    }
                                    
                                </div>
                            </Space>
                        </Radio.Group>
                    </Col>
                </Row>
            }
          


        </>
    )
}