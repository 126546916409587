/* eslint-disable */
import { Breadcrumb, Button, Col, Divider, Edit, Form, Icons, Input, Modal, Row, Select, Typography, UploadFile } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { Table, Tag, Upload, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";


export const MjcRepairFacilityNewJobEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { Text } = Typography;

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const format = 'YYYY-MM-DD HH:mm';

    // ******** MAIN STATUS ********
    const option_main_status:any = [
        {
            label: "เปิดงาน",
            value: "64b505fef3440e02a3156fb3",
        },
        {
            label: "ปิดงาน",
            value: "64b50609f3440e02a3156fb4",
        },
    ];

    // ******** WORKING SUB STATUS ***********
    const option_open_job:any = [
        {
            label: "รอดำเนินการ",
            value: "64b50671f3440e02a3156fb5",
        },
        {
            label: "กำลังดำเนินการ",
            value: "64b5067ef3440e02a3156fb6",
        },
    ];

    const option_close_job:any = [
        {
            label: "งานซ้ำ",
            value: "64b5069bf3440e02a3156fb7",
        },
        {
            label: "ซ่อมเสร็จแล้ว",
            value: "64b506abf3440e02a3156fb8",
        },
        {
            label: "เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ",
            value: "64b506c4f3440e02a3156fb9",
        },
        {
            label: "เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)",
            value: "64b506dcf3440e02a3156fba",
        },
        {
            label: "นิติลงมติยังไม่ดำเนินการ",
            value: "64b506ebf3440e02a3156fbb",
        },
    ];

    const { Option } = Select;

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [res_data, set_res_data] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);

    const [InpectorName, SetInpectorName] = useState<any>();

    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let job_id = id[1]

    // console.log('item_id', item_id)
    // console.log('job_id', job_id)

    // const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    // Data ส่วนของ รายละเอียด
    const [JobNo, SetJobNo] = useState<any>();
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [Unit, SetUnit] = useState<any>();
    const [Customer, SetCustomer] = useState<any>();
    const [Type, SetType] = useState<any>();


    // Data ส่วนของ รายละเอียดการแจ้งซ่อม
    const [ZoneId, SetZoneId] = useState<any>(undefined); 
    const [ZoneName, SetZoneName] = useState<any>(undefined); 
    const [CateId, SetCateId] = useState<any>(undefined); 
    const [CateName, SetCateName] = useState<any>(undefined); 
    const [CateDetailId, SetCateDetailId] = useState<any>(undefined); 
    const [CateDetailName, SetCateDetailName] = useState<any>(undefined); 
    const [WarantyDate, SetWarantyDate] = useState<any>(undefined); 
    const [IsWaranty, SetIsWaranty] = useState<any>(undefined); 
    const [RemarkDetail, SetRemarkDetail] = useState<any>(undefined); 
    const [vImgEn, setImgEn] = useState<any>();
    const [vVid, setVid] = useState<any>();


     // MASTER REPAIR DATA
     const [ DataZone, SetDataZone] = useState<any>([]);
     const [ DataCategory, SetDataCategory] = useState<any>([]);
     const [ DataCatDetail, SetDataCatDetail] = useState<any>([]);


    // SELECTED DATA
    const [ SelectedCateDetail, SetSelectedCateDetail] = useState<any>([]);
    const [ SelectedCategory, SetSelectedCategory] = useState<any>();
    const [ SelectedZone, SetSelectedZone] = useState<any>();
    const [ RemarkStat, SetRemarkStat] = useState<any>("");
    const [SubStat, setSubStat] = useState<any>(undefined);


    const [ TechnicianName, SetTechnicianName] = useState<any>("");
    const [ TechnicianEmail, SetTechnicianEmail] = useState<any>(undefined);


    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);
    const [LastTrail, setLastTrail] = useState<any>(undefined);
    const [LastTrailDate, setLastTrailDate] = useState<any>(undefined);
    const [dataSourceTrail, SetDataSourceTrail] = useState<any>([]);


    // Data แก้ไขสถานะรายการ ทำวันนัด
    const [Stat, setStat] = useState<any>();
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [Inspector, SetInspector] = useState<any>();
    const [InspectorProfile, SetInspectorProfile] = useState<any>();
    const [PropertyId, SetPropertyId] = useState<any>();
    const [Remark, SetRemark] = useState<any>("");

    const [CurrentStat, SetCurrentStat] = useState<any>(undefined);


    // Data HOD Approve
    const [dataSource, SetDataSource] = useState<any>([]);
    const [SlipImg, SetSlipImg] = useState<any>();

    // Data Job
    const [ DataJob, SetJob] = useState<any>([]);
    const [ SelectRedundantJob, SetSelectRedundantJob] = useState<any>(undefined);


    // console.log('SelectRedundantJob', SelectRedundantJob)

    let sla_days:any
    let sla_hours:any

    const [textcount2, settextcount2] = useState('');

    function isISOString(dateString:any) {
        const parsedDate = dayjs(dateString);
        if(parsedDate.isValid() && parsedDate.toISOString() === dateString){
            return dateString
        }else{
            return  dayjs(dateString, 'DD/MM/YY').format("YYYY-MM-DD");
        }
        // return parsedDate.isValid() && parsedDate.toISOString() === dateString;
    }

    const getDataK = async ()=>{
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailFacility/findRepairJobDetailByJobId?stat=${job_id}`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad', dataLoad)
                dataSource.push(dataLoad[0])

                // Set รายละเอียด
                SetJobNo(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].job_id : '-' : '-')
                SetCreateDate(dataLoad ? dataLoad[0].job_obj[0].create_date ? dataLoad[0].job_obj[0].create_date : '-' : '-')
                // SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by ? dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-')
                // SetTransferDate(isISOString(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date))
                SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by ? dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YYYY") : '-' : '-')

                SetProperty(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.property_name : '-' : '-')
                SetUnit(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.unit_name : '-' : '-')
                SetCustomer(dataLoad[0].job_obj[0].request_by.attributes !== undefined ? dataLoad[0].job_obj[0].request_by.attributes.first_name + ' ' + dataLoad[0].job_obj[0].request_by.attributes.last_name : '-')
                SetType(dataLoad[0] ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.attributes.user_sub_types !== undefined ? dataLoad[0].job_obj[0].request_by.attributes.user_sub_types.data.length > 0 ? dataLoad[0].job_obj[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-' : '-' : '-' : '-')
                
                // Set รายละเอียดการแจ้งซ่อม
                SetZoneId(dataLoad ? dataLoad[0].zone_obj.length > 0 ? dataLoad[0].zone_obj[0]._id : '-' : '-')
                SetZoneName(dataLoad ? dataLoad[0].zone_obj.length > 0 ? dataLoad[0].zone_obj[0].zone_name : '-' : '-')
                SetCateId(dataLoad ? dataLoad[0].category_obj.length > 0 ? dataLoad[0].category_obj[0]._id : '-' : '-')
                SetCateName(dataLoad ? dataLoad[0].category_obj.length > 0 ? dataLoad[0].category_obj[0].category_name : '-' : '-')
                // SetCateDetailId(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0]._id : '-' : '-')
                // SetCateDetailName(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0].category_detail_name : '-' : '-')
                // SetWarantyDate(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0]?.category_detail_obj[0]?.warantee : '-' : '-')
                // SetIsWaranty(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0].status : '-' : '-')
                SetRemarkDetail(dataLoad ? dataLoad[0].detail : '-')

                const fileList_EN: UploadFile[] =
                    dataLoad && dataLoad[0]?.pic?.length > 0 ? dataLoad[0]?.pic.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: 'detail_' + v.id,
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];
                    
                setImgEn({
                    name: 'files',
                    multiple: true,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.pic?.length > 0 ? [...fileList_EN] : [],
                })
                form.resetFields(["pic2"]);

                const fileListVid:any= []
                if(dataLoad[0]?.vdo){
                    if (dataLoad[0]?.vdo?.id && dataLoad[0]?.vdo?.url) {
                        fileListVid.push({
                          id: dataLoad[0]?.vdo?.id,
                          uid: String(dataLoad[0]?.vdo?.id),
                          name: 'video',
                          status: "done",
                          url: dataLoad[0]?.vdo?.url,
                          thumbUrl: dataLoad[0]?.vdo?.url
                        });
                      }
    
                    setVid({
                        name: 'files',
                        multiple: false,
                        // defaultFileList: [...fileList3],
                        defaultFileList: dataLoad[0]?.vdo  ? [...fileListVid] : [],
                    })
                    form.resetFields(["pic"]);
                }

                // SetSlipImg(dataLoad[0] ? dataLoad[0]?.job_obj[0].slip : undefined)
                SetSlipImg(dataLoad[0] ? dataLoad[0].job_obj.length > 0 ? dataLoad[0]?.job_obj[0].slip : undefined : undefined)


            });
    }

    console.log('dataSource', dataSource)

    const getDataTrail = async ()=>{
        // http://localhost:10000/api/majorcare/repairJobTrail/findRepairTrailByJob?jid=64a57929c65706886187b7ec
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairJobTrailFacility/findRepairTrailByJob?jid=${job_id}`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad trail', dataLoad.reverse())

                let dataTrail:any = dataLoad.reverse()
                // dataSource.push(dataLoad[0])
                console.log('dataTrail xxxxx', dataTrail)

                for (let index = 0; index < dataTrail.length; index++) {
                    dataSourceTrail.push(dataTrail[index])   
                }
                
                // Set สถานะการทำงาน
                setLastTrail(dataTrail ? dataTrail[0].status_name : '-')
                setLastTrailDate(dataTrail ? dayjs(dataTrail[0].create_date).format("YYYY-MM-DD HH:mm") : '-')
                // Set ประวัติ
            });
    }

    console.log('dataSourceTrail', dataSourceTrail)

    const getJob = async () => {
        // let res = await axios.get(majorCareUrl + "/majorcare/repairJobFacility" , { headers: { } }).then(response => {
        //     // console.log('kkkk',response.data);
        //     let dataLoad:any
        //     dataLoad = response.data
        //     let filteredData = dataLoad.filter((e:any)=> e.is_active == true)
        //     SetJob(dataLoad ? filteredData : null)
        // })

        let res = await axios.get(majorCareUrl + "/majorcare/repairJobDetailFacility/findRepairJobDetailByJobId" , { headers: { } }).then(response => {
            // console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            // let filteredData = dataLoad.filter((e:any)=> e.is_active == true)
            SetJob(dataLoad ? dataLoad : null)
        })

    }

    console.log('DataJob', DataJob)

    const getRepairZone = async () => {
        let res = await axios.get(majorCareUrl + "/majorcare/repairZoneFacility" , { headers: { } }).then(response => {
            // console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            SetDataZone(dataLoad ? filteredDataCate : null)
        })
    }

    const getRepairCategory = async () => {
        let res = await axios.get(majorCareUrl + "/majorcare/repairCategoryFacility" , { headers: { } }).then(response => {
            // console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            SetDataCategory(dataLoad ? filteredDataCate : null)
        })
    }

    const getRepairCateDetail = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairCategoryDetail`,
        { headers: {  } }).then((res) => {
            let dataCateDetail: any = res?.data;
            // console.log('dataCateDetail', dataCateDetail)
            SetDataCatDetail(dataCateDetail);
        });
    }

    const colTrail:any = [
        {
            title: 'วันที่ทำรายการ',
            dataIndex: 'item_name',
            key: 'item_name',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                // console.log('record',record)
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ผู้ทำรายการ',
            // dataIndex: 'name',
            key: 'name',
            // sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_by ? record.create_by.first_name + ' ' + record.create_by.last_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'สถานะ',
            dataIndex: 'createBy',
            key: 'createBy',
            render: (val:any, record:any) => {
                return (
                    <>
                        <Tag>{record.status_name}</Tag>
                    </>
                );
            },
        },
        {
            title: 'สถานะย่อย',
            dataIndex: 'sub_stat',
            key: 'sub_stat',
            render: (val:any, record:any) => {
                return (
                    <>
                        <Tag>{record.sub_status_name}</Tag>
                    </>
                );
            },
        },
        // {
        //     title: 'SLA',
        //     dataIndex: 'sla',
        //     key: 'sla',
        //     render: (value:any, record:any) => {

        //         {
        //             if(record.sla && record.sla.sla_time > 60){
        //                 // console.log('over 60')
        //                 const hours = Math.floor(record.sla.sla_time / 60);
        //                 const days = Math.floor(hours / 24);
        //                 // console.log(`${record.sla.sla_time} minutes is equal to ${days} days and ${hours % 24} hours`);
        //                 sla_days = days
        //                 sla_hours = hours

        //             }
        //         }
        //         return (
                    
        //             <>
        //                 {
        //                     record.sla && record.sla.sla_time > 60 ?
        //                         <div>
        //                             {
        //                                 sla_days > 0 ?
        //                                     <div>{sla_days} วัน {sla_hours} ชั่วโมง</div>
        //                                 :
        //                                     <div> {sla_hours} ชั่วโมง</div>
        //                             }
        //                         </div>
        //                     :
        //                         <div>{record ? record.sla ? record.sla.sla_time !== '-' && record.sla.sla_time !== '' ? record.sla.sla_time  + ' นาที' : '-' : '-': '-'}</div>
        //                 }
        //             </>
        //         );
        //     },
        // },
        {
            title: 'หมายเหตุ',
            dataIndex: 'remark',
            key: 'remark',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.remark ? record.remark : '-': '-'}
                        {
                            record?.quotation ?
                                record?.quotation?.file?.length > 0 ?
                                    record?.quotation?.file?.map((item: any, key: any) => {
                                        return <>
                                                <a href={item.url} download={"quotation_" + key} target='_blank'>
                                                    <Button style={{marginLeft:'5px', color:'green'}} icon={<Icons.DownloadOutlined />}></Button>
                                                </a>
                                            </>
                                    })
                                :
                                    <></>
                            : <></>
                        }
                        </div>
                    </>
                );
            },
        },
    ];
   
    // useEffect(() => {
    //     if (countInterval < 3) {
    //         const interval = setInterval(() => {
    //             setCountInterval(prev => prev + 1);
    //             if(countInterval === 1){
    //                 getDataTrail();
    //             }
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    useEffect(() => {
        getDataK();
        getRepairZone();
        getRepairCategory();
        getJob();
        getDataTrail();
        // getRepairCateDetail();
    }, []);

    const [form] = Form.useForm();

    const handleChangeRemark2 = (e:any) => {
        settextcount2(e.target.value);
    };

    async function updateJobAndTrail(){
            
        // ****************** 1. UPDATE JOB with Selected stat ******************
        // **********************************************************************
        let data:any
       
        data = {
            "status_id": Stat.value,
            "status_name": Stat.label,
            "sub_status_id": SubStat !== undefined ? SubStat.value : '',
            "sub_status_name": SubStat !== undefined ? SubStat.label : '',
            "assign": {
                "email": TechnicianEmail ? TechnicianEmail : null,
                "name": TechnicianName ? TechnicianName : null
            },
            "redundant_job" : SelectRedundantJob !== undefined ? SelectRedundantJob : null
        };

        // console.log('data', data)
        let res = await axios.patch(majorCareUrl + "/majorcare/repairJobFacility/updateRepairjob?id=" + job_id, data, { headers: { } });

        // ****************** 2. CREATE TRAIL ******************
        // *****************************************************
        let dataTrail:any
        
        dataTrail = {
            "repair_job": job_id,
            "status": Stat.value,
            "status_name": Stat.label,
            "sub_status": SubStat !== undefined ? SubStat.value : null,
            "sub_status_name": SubStat !== undefined ? SubStat.label : null,
            "remark": RemarkStat !== undefined ? RemarkStat : "",
            "create_by": Identity_data?.user_profile,
            "assign": {
                "email": TechnicianEmail ? TechnicianEmail : null,
                "name": TechnicianName ? TechnicianName : null
            },
        }

        // console.log('dataTrail', dataTrail)

        let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrailFacility", dataTrail, { headers: { } });






        // ====== SEND EMAIL GROUP ========


        // เนื้อหาเมล์
        // Property Info:
        // Property Name
        // Unit Number

        // Contact:
        // Owner Name
        // Contact Name
        // Status
        // Contact Phone
        // Contact Email

        // Repairing Detail:
        // Job No
        // Type
        // Zone
        // Category
        // Description
        // Detail
        // วันที่แจ้ง
        // วันที่นัดหมาย
        // เบอร์ติดต่อ
        // ช่างผู้รับผิดชอบ


        if(TechnicianEmail !== undefined){
            let data_send_mail = {
                "job_no": dataSource[0].job_obj[0].job_id + '',
                "property_name": dataSource[0].job_obj[0].request_by.unitDetail.property_name +'', 
                "zone": dataSource[0].zone_obj[0].zone_name + '',
                "category": dataSource[0].category_obj[0].category_name + '',
                "description": RemarkStat + '',
                "inspector_name": TechnicianName + '',
                "email": TechnicianEmail
            }

            console.log('data_send_mail', data_send_mail)
            // let res_email = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });
            let res_email = await axios.post(API_URL + `/like-systems/sendEmailRepairJobFacility`, data_send_mail , {
                headers: { Authorization: "Bearer " + TOKEN_KEY }
            });

            console.log('res_email', res_email)
        }


        successNoti();
        replace("/repair-facility/repair-facility-job");
    }

    // console.log('SlipImg', SlipImg)

    const onChangeEmail = (e: any) => {
        SetTechnicianEmail(e.target.value);
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    return (
    <Edit 
        title="แก้ไข"
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{
            breadcrumb: <Breadcrumb>
                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                <Breadcrumb.Item>แจ้งซ่อมส่วนกลาง</Breadcrumb.Item>
                <Breadcrumb.Item>รายการแจ้งซ่อม</Breadcrumb.Item>
                {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><span className="last_breadcrumb">แก้ไข</span></Breadcrumb.Item>
            </Breadcrumb>,
            extra : <></>,
            onBack(e?) {
                replace("/repair-facility/repair-facility-job");
            },
        }}
    >
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    updateJobAndTrail();
                }}
            >
                {/* ====================== รายละเอียด ====================== */}
                {/* ======================================================= */}
                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียด
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                    label="Property"
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Input value={Property} defaultValue={Property} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Job no."
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={JobNo} defaultValue={JobNo} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="วันที่แจ้ง"
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(CreateDate).format("DD/MM/YY HH:mm")} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="ผู้แจ้ง"
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Customer} defaultValue={Customer} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Unit" 
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Unit} defaultValue={Unit} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่โอน" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>   
                                </Form.Item>

                                <Form.Item 
                                    label="ประเภท" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Type} defaultValue={Type} disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                {/* ====================== รายละเอียดการแจ้งซ่อม ====================== */}
                {/* ================================================================ */}
                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียดการแจ้งซ่อม 
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="บริเวณ" 
                            // name="zone"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input disabled value={ZoneName} defaultValue={ZoneName} />
                        </Form.Item>

                    </Col>

                    <Col span={8}>
                        <Form.Item 
                            label="หมวด" 
                            // name="category"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input disabled value={CateName} defaultValue={CateName} />
                        </Form.Item>
                    </Col>

                </Row>

                <Row>
                    <Col span={16}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"95%"}}
                        >
                            <TextArea style={{ height: "90px", maxHeight: "80px" }}
                                // onChange={(e) => SetRemarkDetail(e.target.value)}
                                value={RemarkDetail}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="รูปประกอบ (สูงสุด 5 รูป)"
                            name="pic2"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vImgEn}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={5}
                                // beforeUpload={beforeUpload}
                                // onChange={fileChange_EN}
                                disabled
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="ไฟล์วิดีโอ (1 คลิป)"
                            name="pic"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vVid}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={1}
                                multiple={false}
                                // beforeUpload={beforeUploadVid}
                                // onChange={fileChange_vid}
                                disabled
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                {/* ====================== สถานะการทำงาน ====================== */}
                {/* =========================================================== */}
                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            สถานะการทำงาน
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={2}>
                        <Tag color="#108ee9">สถานะล่าสุด</Tag>
                    </Col>

                    <Col span={8}>
                        {LastTrail} วันที่ {LastTrailDate}
                    </Col>

                </Row>

                {
                    LastTrail ? 
                    LastTrail !== "ปิดงาน" && 
                    <>
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                    label="สถานะ" 
                                    name="status"
                                    style={{width:"90%"}}
                                    rules={[
                                        {
                                            required: true, message: "Please select status"
                                        },
                                    ]}
                                >
                                    {/* 
                                        MAIN STATUS
                                        64b505fef3440e02a3156fb3 = เปิดงาน
                                        64b50609f3440e02a3156fb4 = ปิดงาน
                                    */}

                                    {/* 
                                        SUB STATUS
                                        ******* main = เปิดงาน ******* option_open_job
                                        64b50671f3440e02a3156fb5 = รอดำเนินการ
                                        64b5067ef3440e02a3156fb6 = กำลังดำเนินการ

                                        ******* main = เลื่อนนัดจากนิติ ******* option_close_job
                                        64b5069bf3440e02a3156fb7 = งานซ้ำ
                                        64b506abf3440e02a3156fb8 = ซ่อมเสร็จแล้ว
                                        64b506c4f3440e02a3156fb9 = เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ
                                        64b506dcf3440e02a3156fba = เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)
                                        64b506ebf3440e02a3156fbb = นิติลงมติยังไม่ดำเนินการ
                                    */}
                                    <Select 
                                        placeholder={t("select.sel")}
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            console.log('record', record)
                                            // setStat(value);
                                            setStat(record);
                                        }}
                                        options={option_main_status}
                                    />
                                </Form.Item>
                            </Col>


                            {
                                Stat !== undefined ?
                                Stat?.value == "64b505fef3440e02a3156fb3" ||  Stat?.value == "64b50609f3440e02a3156fb4" ||  Stat?.value == "64985b3b515b80b78fe13bac" ||  Stat?.value == "64985bcf515b80b78fe13bba" ?
                                    <Col span={8}>
                                        <Form.Item 
                                            label="สถานะย่อย" 
                                            name="sub_status"
                                            style={{width:"90%"}}
                                            rules={[
                                                {
                                                    required: true, message: "Please select sub status"
                                                },
                                            ]}
                                        >
                                            <Select 
                                                placeholder={t("select.sel")}
                                                style={{ width: '100%' }}
                                                onSelect={(value: any, record:any) => {
                                                    // setSubStat(value);
                                                    setSubStat(record);
                                                }}
                                                options={
                                                    Stat?.value == "64b505fef3440e02a3156fb3" ? option_open_job
                                                    : Stat?.value == "64b50609f3440e02a3156fb4" ? option_close_job
                                                    : null
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                : <></>
                                : <></>
                            }

                            {
                                SubStat !== undefined ?
                                SubStat?.value == "64b5069bf3440e02a3156fb7"  ?
                                    <Col span={8}>
                                        <Form.Item 
                                            label="เลขที่เอกสารงานซ้ำ" 
                                            name="redundat_job"
                                            style={{width:"90%"}}
                                            rules={[
                                                {
                                                    required: true, message: "Please select job"
                                                },
                                            ]}
                                        >
                                            <Select 
                                                placeholder="ค้นหาจากหมายเลขใบงาน"
                                                style={{ width: '100%' }}
                                                onSelect={(value: any, record:any) => {
                                                    console.log('record', record.data)
                                                    // setSubStat(value);
                                                    // setSubStat(record);
                                                    SetSelectRedundantJob(record.data)
                                                }}
                                                showSearch
                                                // options={
                                                //     Stat.value == "64b505fef3440e02a3156fb3" ? option_open_job
                                                //     : Stat.value == "64b50609f3440e02a3156fb4" ? option_close_job
                                                //     : null
                                                // }
                                                options={DataJob?.map((item:any) => (
                                                    { 
                                                        label: item?.job_obj[0]?.job_id + " - " + item?.zone_obj[0]?.zone_name + " - " + item?.category_obj[0]?.category_name, 
                                                        value: item?.job_obj[0]?.job_id,
                                                        data: item?.job_obj[0]
                                                    }
                                                ))}
                                            />
                                        </Form.Item>
                                    </Col>
                                : <></>
                                : <></>
                            }
                            
                        </Row>

                        {
                            Stat ?
                                Stat?.value == "64b505fef3440e02a3156fb3" && SubStat?.value == "64b5067ef3440e02a3156fb6" ?
                                // Stat.value == "64b505fef3440e02a3156fb3" ?
                                    <>
                                        <Row>
                                            <Col span={8}>
                                                <Form.Item 
                                                    label="ชื่อช่าง" 
                                                    name="technician"
                                                    style={{width:"90%"}}
                                                    rules={[
                                                        {
                                                            required:true , message: "Please enter technician's name"
                                                        },
                                                    ]}
                                                >
                                                    <Input placeholder="Techician Name" onChange={(e) => SetTechnicianName(e.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item 
                                                    label="email" 
                                                    name="email"
                                                    style={{width:"90%"}}
                                                    rules={[
                                                        {
                                                            required:true , message: "Please enter email"
                                                        },
                                                    ]}
                                                >
                                                    <TextArea 
                                                        style={{height: "100px", maxHeight: "80px"}} 
                                                        placeholder="seperate email by comma ex. testmail@mail.com,testmail2@mail.com" 
                                                        onChange={(e) => onChangeEmail(e)} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                                : <></>
                            : <></>
                        }


                        <Row>
                            <Col span={18}>
                                <Form.Item 
                                    label="หมายเหตุ" 
                                    // name="remark"
                                    style={{width:"90%"}}
                                >
                                    <TextArea 
                                        style={{ height: "90px", maxHeight: "80px" }}
                                        maxLength={255}
                                        onChange={(e) => {
                                            SetRemarkStat(e.target.value)
                                            handleChangeRemark2(e)
                                        }}
                                    />
                                    <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount2?.length} / 255`}</Text>
                                </Form.Item>
                            </Col>
                        </Row> 
                        <Row style={{float: 'right',marginTop: "1.2rem",marginBottom: "1.2rem"}}>
                            <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                            {/* <Button type="primary" onClick={() => updateJobAndTrail()} icon={<Icons.SaveOutlined />}>Save</Button> */}
                        </Row>
                    </>
                    : <></>
                }
                

                <Divider />

                {/* ====================== ประวัติการทำรายการ ====================== */}
                {/* ============================================================== */}
                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            ประวัติการทำรายการ
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Table 
                            columns={colTrail} 
                            dataSource={dataSourceTrail.length > 0 ? dataSourceTrail : null}
                        />
                    </Col>
                </Row>
            </Form>
    </Edit>   
    );
};