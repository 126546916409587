import { Descriptions,   PageHeader, TextField,Card  } from "@pankod/refine-antd";

import { IAppointmentType } from "interfaces";

import { useShow, useTranslate } from "@pankod/refine-core";

import Moment from 'moment';
// import "./css/style.css";


export const AppointmTypeShow = () => {
    const t = useTranslate();

    const { queryResult } = useShow<IAppointmentType>();
    const { data, isLoading } = queryResult;
    const record = data?.data;

    return <Card
    style={{ borderRadius: 5 }}
    loading={isLoading}
>
    <PageHeader
        style={{ padding: 0 }}
        onBack={() => window.history.back()}
        title="Appointment Type Information"
    >

        <Card style={{backgroundColor: "#fff"}}>
        <Descriptions
            size="small"
            // title={<Tag color="blue"> test </Tag>}
            column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
            bordered>
            <Descriptions.Item span={1} label={t("code")}>
                <TextField value={record?.code ? record?.code : '-'} />
            </Descriptions.Item>
            <Descriptions.Item span={1} label={t("name")}>
                <TextField value={record?.name ? record?.name : '-'} />
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Created At" >
                <TextField value={Moment().format('D/MM/YYYY HH:mm a')}  />
            </Descriptions.Item>
        </Descriptions>
        </Card>
    </PageHeader>
</Card>
}