import { Breadcrumb, Button, Card, Col, Create, Divider, Form, Icons, Input, InputNumber, List, Modal, Row, Select, Tag, notification, useForm, useModalForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { petURL } from "../../../constants";
import { useEffect, useState } from "react";
import axios from "axios";

let optionpettype: any = undefined;
let optionpetsize: any = undefined;
export const PetspeciesCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { Option } = Select;
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();
    const [formModal] = Form.useForm();
    const [formModal_EDIT] = Form.useForm();
    const { formProps, saveButtonProps } = useForm<any,HttpError,{}>();

    const [keepMin, setKeepMin] = useState<any>()
    const [keepMax, setKeepMax] = useState<any>()

    const [valuepetType, setpetType] = useState<any>();
    const [valuepetSize, setpetSize] = useState<any>();
    const [sizeGroup, setsizeGroup] = useState<any>([]);

    const [keyEDIT, setkeyEDIT] = useState<any>();
    const [mdopen, setmdopen] = useState<boolean>(false);
    const [mdopen_EDIT, setmdopen_EDIT] = useState<boolean>(false);
    const [tickerFlag, settickerFlag] = useState<boolean>(false);

    const [showReqSize, setShowReqSize] = useState<boolean>(false);

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findActivePetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }
            },
        },
    });

    const { data: datapetsize } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetSize`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_size_name_en,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpetsize = newDT;
                }
            },
        },
    });


    const { data: datasize } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetSizeDetail`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                //console.log(">>> dataLoad", dataLoad)
                // let newDT: any = [];

                // for (let index = 0; index < dataLoad?.length; index++) {
                //     newDT?.push({
                //         label: dataLoad[index]?.pet_type_name,
                //         value: dataLoad[index]?._id
                //     })
                // }

                // if(newDT?.length > 0){
                //     optionpettype = newDT;
                // }
            },
        },
    });

    async function submitDT(values:any){
        let body: any = {
            "pet_species_name": values?.name_th,
            "pet_species_name_en": values?.name_en,
            "pet_type": valuepetType,
            "create_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
        }
        
        if(sizeGroup?.length <= 0){
            // console.log('req expense')
            setShowReqSize(true)
        }else{

            let submit = await axios.post(petURL + "/api/lscpet/createPetSpecies", body, { headers: { } })
            .then(async response => {
                let res: any = response?.data;
                let dataUP: any = sizeGroup;

                for (let i = 0; i < dataUP?.length; i++) {
                    let body: any = {
                        "pet_species": res?._id,
                        // "size_name": dataUP[i]?.size_name,
                        // "size_name_en": dataUP[i]?.size_name_en,
                        "pet_size": dataUP[i]?.pet_size,
                        "size_from": dataUP[i]?.size_from,
                        "size_to": dataUP[i]?.size_to,
                        "create_by": {
                            "user_id": user_dt?.user_profile?.id,
                            "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
                        },
                    }
                    
                    let submit = await axios.post(petURL + "/api/lscpet/createPetSizeDetail", body, { headers: { } })
                }

                successNoti('Create is Success');
                replace("/pets/pet-masters");
            })
            .catch(error => {
                console.error('error ',error);
                faildNoti('Create is Failed');
            });
        }
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    function onsetvalEdit(values: any, key: any){

        formModal_EDIT?.setFieldsValue({
            size_th: values?.size_name,
            size_en: values?.size_name_en,
            pet_size: values?.pet_size,
            size_from: values?.size_from,
            size_to: values?.size_to,
        });
        
        setKeepMax(values?.size_to)
        setKeepMin(values?.size_from)
        setkeyEDIT(key);
        setmdopen_EDIT(true);
    }

    function onremovesize(key: any) {
        let newData: any = [];

        for (let i = 0; i < sizeGroup?.length; i++) {
            if(i !== key){ newData?.push(sizeGroup[i]) }
        }

        setsizeGroup(newData);
        settickerFlag((pre)=> !pre);
    }

    function onfinishsize(values: any) {
        let newData: any = [{
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
        }]

        setsizeGroup((pre: any) => [...pre, ...newData]);
        settickerFlag((pre)=> !pre);
        setmdopen(false);
    }

    function onfinishsizeEDIT(values: any) {
        let dataLoad: any = sizeGroup;
        let newData: any = {
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
        }

        dataLoad[keyEDIT] = newData;

        console.log(">>> dataLoad", dataLoad)

        setsizeGroup(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT(false);
    }

    // useEffect(() => {
    //     console.log("keepMax",keepMax)
    //     console.log("keepMin",keepMin)
    //   },)

    useEffect(() => {
        setShowReqSize(false)
        console.log('sizeGroup', sizeGroup)

    }, [sizeGroup]);
      
    const findSizeLabel = (value:any) => {
        const size = optionpetsize.find((s:any) => s.value === value);
        return size ? size.label : 'Size Not Found'; // Default message if size is not found
    };

    return (
        <Create title={t("pet-species.title-create")} saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/pet-masters")}}}>
            <List 
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-species.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-species.title-create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />

            <div style={{width: "25%"}}>
                <Form 
                    {...formProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        submitDT(values);
                    }}
                >
                    <Form.Item 
                        label={t("pet-type.title")}
                        name="pet-type"
                        rules={[
                            {
                                required: true, message: 'please select pet type.'
                            },
                        ]}
                    >
                        <Select
                            options={optionpettype}
                            showSearch
                            allowClear
                            onChange={(e: any)=> setpetType(e)}
                            onClear={()=> setpetType(undefined)}
                        />
                    </Form.Item>

                    <Form.Item 
                        label={t("pet-species.name-spc-th")}
                        name="name_th"
                        rules={[
                            {
                                required: true, message: 'please input name th.'
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item 
                        label={t("pet-species.name-spc-en")}
                        name="name_en"
                        rules={[
                            {
                                required: true, message: 'please input name en.'
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Divider />

                    <div className="layout_size_custom">
                        <div className="layout_size_custom_header" style={{display: "flex"}}>
                            <div style={{marginRight: "1rem"}}>
                                <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size")}</span>
                            </div>
                            <div style={{marginTop: "-0.4rem"}}>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setmdopen(true);
                                        // if(disable == false){
                                        //     // setdisable(true);
                                        //     // add();
                                        // }
                                    }}
                                    icon={<Icons.PlusOutlined />}
                                    size="small"
                                    style={{borderRadius: "5px"}}
                                >{t("buttons.add")}</Button>
                            </div>
                        </div>
                        {
                            showReqSize &&
                            <div>
                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#ff4d4f"}}>{t("config-property.please-add-size")}</span>
                            </div>
                        }
                    </div>

                    <div style={{marginTop: "10px"}}>
                        <div style={{ display:"none"}}>{JSON.stringify(tickerFlag)}</div>{/* ห้ามลบ ห้ามแก้ */}
                        {sizeGroup?.length > 0 && sizeGroup?.map((item:any,index:any) => {
                            return (
                                <Card
                                    key={index}
                                    className="card_custom_pet_species"
                                    style={{
                                        width: "100%", 
                                        marginBottom: "10px", 
                                        border: "1px solid #dedede"
                                    }}
                                >
                                    <Row gutter={[6,8]}>
                                        <Col className="gutter-row" span={20}>
                                           
                                            <Row gutter={6}>
                                                <Col className="gutter-row" span={6}>
                                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("Size")} : </span>
                                                </Col>
                                                <Col className="gutter-row" span={18}>
                                                    <span style={{fontSize: "0.7vw"}}>
                                                        {/* {item?.pet_size} */}
                                                        {findSizeLabel(item?.pet_size)}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "end"}}>
                                            <Button icon={<Icons.EditOutlined />} size="small" style={{marginRight: "5px"}} onClick={()=> onsetvalEdit(sizeGroup[index], index)}/>
                                            <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremovesize(index)}/>
                                        </Col>
                                        
                                        <Col className="gutter-row" span={22}>
                                            <Row gutter={6}>
                                                <Col className="gutter-row" span={12} style={{borderRight: "1px solid #dedede"}}>
                                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("pet-species.weight")} :</span>
                                                    <span style={{fontSize: "0.7vw"}}>{item?.size_from}</span>
                                                </Col>
                                                <Col className="gutter-row" span={12} style={{paddingLeft: "10px"}}>
                                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("pet-species.size-th")} :</span>
                                                    <span style={{fontSize: "0.7vw"}}>{item?.size_to}</span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            )})
                        }
                    </div>

                    <Modal
                        title={t("pet-species.add-size")}
                        visible={mdopen}
                        onCancel={() => setmdopen(false)}
                        //onOk={() => formModal?.submit()}
                        onOk={() => keepMin > keepMax ? null : formModal?.submit()}
                        afterClose={()=> {
                            setKeepMax(undefined);
                            setKeepMin(undefined);
                            formModal?.resetFields();
                        }}
                    >
                        <Form 
                            form={formModal}
                            layout="vertical"
                            onFinish={(values:any) => {
                                console.log('values onFinish', values)
                                onfinishsize(values);
                            }}
                        >
                            <Row gutter={6}>
                                {/* <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-th")}</span>
                                    </div>
                                </Col>

                                <Col span={18}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_th"
                                        rules={[
                                            {
                                                required: true, message: 'please input size.'
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("pet-species.size")}/>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")}</span>
                                    </div>
                                </Col>

                                <Col span={18}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_en"
                                        rules={[
                                            {
                                                required: true, message: 'please input size.'
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("pet-species.size")}/>
                                    </Form.Item>
                                </Col> */}

                                <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")}</span>
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <Form.Item 
                                        name="pet_size"
                                        rules={[
                                            {
                                                required: true, message: 'please select pet size.'
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={optionpetsize}
                                            showSearch
                                            allowClear
                                            onChange={(e: any)=> setpetSize(e)}
                                            onClear={()=> setpetSize(undefined)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        {/* <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span> */}
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.weight")}</span>
                                    </div>
                                </Col>


                                <Col span={8}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_from"
                                        // rules={[
                                        //     {
                                        //         required: true, message: 'please input size from.'
                                        //     },
                                        // ]}
                                        style={{marginBottom: 0}}
                                    >
                                        <InputNumber onChange={(e) => setKeepMin(e)} style={{width:'100%'}} min={0}/>
                                    </Form.Item>
                                </Col>

                                <Col span={2}>
                                    <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.to")}</span>
                                    </div>
                                </Col>

                                <Col span={8}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_to"
                                        // rules={[
                                        //     {
                                        //         required: true, message: 'please input size to.'
                                        //     },
                                        // ]}
                                        style={{marginBottom: 0}}
                                    >
                                        <InputNumber style={{width:'100%'}} onChange={(e) => setKeepMax(e)} min={keepMin}/>
                                    </Form.Item>
                                </Col>
                                <span className="req_custom" style={{display: keepMin > keepMax ? 'flex' : 'none', width: '100%', marginLeft: '310px', marginTop: '10px' , color: "#ff4d4f", fontSize: "13px"}}>{t("pet-species.alert")}</span>
                            </Row>
                        </Form>
                    </Modal>

                    <Modal
                        title={t("pet-species.edit-size")}
                        visible={mdopen_EDIT}
                        onCancel={() => setmdopen_EDIT(false)}
                        // onOk={() => formModal_EDIT?.submit()}
                        onOk={() => keepMin > keepMax ? null : formModal_EDIT?.submit()}
                        afterClose={()=> {
                            setKeepMax(undefined);
                            setKeepMin(undefined);
                            formModal_EDIT?.resetFields();
                        }}
                    >
                        <Form 
                            form={formModal_EDIT}
                            layout="vertical"
                            onFinish={(values:any) => {
                                onfinishsizeEDIT(values);
                            }}
                        >
                            <Row gutter={6}>
                                {/* <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-th")}</span>
                                    </div>
                                </Col>

                                <Col span={18}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_th"
                                        rules={[
                                            {
                                                required: true, message: 'please input size.'
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("pet-species.size")}/>
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")}</span>
                                    </div>
                                </Col>

                                <Col span={18}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_en"
                                        rules={[
                                            {
                                                required: true, message: 'please input size.'
                                            },
                                        ]}
                                    >
                                        <Input placeholder={t("pet-species.size")}/>
                                    </Form.Item>
                                </Col> */}

                                <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")}</span>
                                    </div>
                                </Col>
                                <Col span={18}>
                                    <Form.Item 
                                        name="pet_size"
                                    >
                                        <Select
                                            options={optionpetsize}
                                            showSearch
                                            allowClear
                                            onChange={(e: any)=> setpetSize(e)}
                                            onClear={()=> setpetSize(undefined)}
                                        />
                                    </Form.Item>
                                </Col>


                                <Col span={6}>
                                    <div style={{marginTop: "5px"}}>
                                        {/* <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span> */}
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.weight")}</span>
                                    </div>
                                </Col>

                                <Col span={8}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_from"
                                        // rules={[
                                        //     {
                                        //         required: true, message: 'please input size from.'
                                        //     },
                                        // ]}
                                        style={{marginBottom: 0}}
                                    >
                                        {/* <Input /> */}
                                        <InputNumber onChange={(e) => setKeepMin(e)} style={{width:'100%'}} min={0}/>
                                    </Form.Item>
                                </Col>

                                <Col span={2}>
                                    <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.to")}</span>
                                    </div>
                                </Col>

                                <Col span={8}>
                                    <Form.Item 
                                        className="visible-label"
                                        name="size_to"
                                        // rules={[
                                        //     {
                                        //         required: true, message: 'please input size to.'
                                        //     },
                                        // ]}
                                        style={{marginBottom: 0}}
                                    >
                                        {/* <Input /> */}
                                        <InputNumber style={{width:'100%'}} onChange={(e) => setKeepMax(e)} min={keepMin}/>
                                    </Form.Item>
                                </Col>
                                <span className="req_custom" style={{display: keepMin > keepMax ? 'flex' : 'none', width: '100%', marginLeft: '310px', marginTop: '10px' , color: "#ff4d4f", fontSize: "13px"}}>{t("pet-species.alert")}</span>
                            </Row>
                        </Form>
                    </Modal>
                </Form>
            </div>
        </Create>
    )
}