import { IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { useCallback, useEffect, useRef, useState } from "react";
import { TOKEN_KEY, petURL } from "../../constants";
import { Breadcrumb, Button, Card, Col, Divider, Edit, Form, Icons, Input, List, Modal, Radio, RcFile, Row, Select, Space, Spin, Upload, message, notification, useSelect, InputNumber } from "@pankod/refine-antd";
import axios from "axios";
import { EditNewModalContent } from "./component/edit_modal_content";
import { EditOldModalContent } from "./component/edit_modal_old_content";

let dataProp: any = undefined;
let dataBank: any = undefined;
let optionpettype: any = undefined;
let edit_new_data_detail: any = []

let keyKom:any
let valKom:any

let keyOldKom:any
let valOldKom:any

export const ConfigpropEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();
    
    const refuploadTH = useRef<any>();
    const refuploadEN = useRef<any>();

    // const [petComponentsKom, setPetComponentsKom] = useState(null);

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [formCustom] = Form.useForm();
    const [formModal] = Form.useForm();
    const [formModal_EDIT] = Form.useForm();
    const [formModal_EDIT_OLD] = Form.useForm();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [Prop, setProp] = useState<any>();
    const [Bank, setBank] = useState<any>();
    const [canPet, setcanPet] = useState<boolean>(false);
    const [isCharge, setisCharge] = useState<boolean>(false);
    const [paymentPF, setpaymentPF] = useState<boolean>(true);
    const [nameFileTH, setnameFileTH] = useState<any>();
    const [nameFileEN, setnameFileEN] = useState<any>();
    const [valFileTH, setvalFileTH] = useState<any>();
    const [valFileEN, setvalFileEN] = useState<any>();

    const [ID, setID] = useState<any>();
    const [keyEDIT, setkeyEDIT] = useState<any>();
    const [keyEDIT_OLD, setkeyEDIT_OLD] = useState<any>();
    const [mdopen, setmdopen] = useState<boolean>(false);
    const [mdopen_EDIT, setmdopen_EDIT] = useState<boolean>(false);
    const [mdopen_EDIT_OLD, setmdopen_EDIT_OLD] = useState<boolean>(false);
    const [tickerFlag, settickerFlag] = useState<boolean>(false);
    const [showReqExpense, setShowReqExpense] = useState<boolean>(false);

    const [expenGroup, setexpenGroup] = useState<any>([]);
    const [expenGroup_OLD, setexpenGroup_OLD] = useState<any>([]);

    let getID = window?.location?.href.split("/");
    let pageID: any = getID[getID?.length -1];

    const { data: dataLoad } = useCustom<any>({
        url: `${petURL}/api/lscpet/findOneConfigProperty`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log(">>> dataLoad1", dataLoad)

                setProp({
                    id: dataLoad?.property_obj?.id,
                    property_name: dataLoad?.property_obj?.property_name,
                });
                setBank({
                    id: dataLoad?.bank_obj?.id,
                    bank_name: dataLoad?.bank_obj?.bank_name,
                    logo: dataLoad?.bank_obj?.logo
                })
                setcanPet(dataLoad?.can_pet);
                setisCharge(dataLoad?.is_charge);
                setpaymentPF(dataLoad?.payment_platform);
                setvalFileTH({
                    id: dataLoad?.term_th?.id,
                    url: dataLoad?.term_th?.url,
                    name: dataLoad?.term_th?.name
                })
                setvalFileEN({
                    id: dataLoad?.term_en?.id,
                    url: dataLoad?.term_en?.url,
                    name: dataLoad?.term_en?.name
                })

                setnameFileTH(dataLoad?.term_th?.name);
                setnameFileEN(dataLoad?.term_en?.name);

                formCustom?.setFieldsValue({
                    property: dataLoad?.property_obj?.id,
                    can_pet: dataLoad?.can_pet,
                    is_charge: dataLoad?.is_charge,
                    payment_platform: dataLoad?.payment_platform,
                    bank_obj: dataLoad?.bank_obj?.id,
                    bank_account_no: dataLoad?.bank_account_no,
                    bank_account: dataLoad?.bank_account,
                    // name_th: dataLoad?.pet_species_name,
                    // name_en: dataLoad?.pet_species_name_en,
                    // pet_type: dataLoad?.pet_type
                });

                formCustom?.resetFields(["term_th"]);
                formCustom?.resetFields(["term_en"]);

                setLoading(false);
            }
        }
    })

    const { data: datadetail } = useCustom<any>({
        url: `${petURL}/api/lscpet/findDetailById`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];
                // console.log('dataLoad expen old', dataLoad)

                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        "id": dataLoad[index]?._id,
                        "area_size_from": dataLoad[index]?.area_size_from,
                        "area_size_to": dataLoad[index]?.area_size_to,
                        "maximum_pet": dataLoad[index]?.maximum_pet,
                        "pet_maximum_by_type": dataLoad[index]?.pet_maximum_by_type,
                        "status": "active"
                    })
                }

                setexpenGroup_OLD(newData)
            }
        }
    })

    const { selectProps: propertySelectProps } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
        pagination:{
            pageSize: 100
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        value: dataLoad[index]?.id,
                        label: dataLoad[index]?.property_name
                    })
                }

                if(newData?.length > 0){
                    dataProp = newData;
                }
            },
        }
    });

    const { selectProps: bankSelectProps } = useSelect<any>({
        resource: "banks",
        optionLabel: "bank_name",
        optionValue: "id",
        metaData:{
            populate: ['logo']
        },
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
        ],
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        value: dataLoad[index]?.id,
                        label: dataLoad[index]?.bank_name,
                        logo: dataLoad[index]?.logo[0]?.url
                    })
                }

                if(newData?.length > 0){
                    dataBank = newData;
                }
            },
        }
    });

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findActivePetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log('dataLoad pet type', dataLoad)
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {

                    // map data pet type
                    newDT?.push({
                        _id: dataLoad[index]?._id,
                        key: index + 1,
                        pet_type_id: dataLoad[index]?._id,
                        pet_type_name: dataLoad[index]?.pet_type_name,
                        pet_type_name_en: dataLoad[index]?.pet_type_name_en,
                        pet_limit: [
                            {
                                "size_id": "656fdd7347a1bba6e8a9b15d",
                                "size": "S",
                                "max": 0
                            },
                            {
                                "size_id": "656fdd7847a1bba6e8a9b15f",
                                "size": "M",
                                "max": 0
                            },
                            {
                                "size_id": "656fdd7d47a1bba6e8a9b161",
                                "size": "L",
                                "max": 0
                            },
                            {
                                "size_id": "656fdd8147a1bba6e8a9b163",
                                "size": "XL",
                                "max": 0
                            }
                        ],
                        is_charge: false
                    })
                }

                // console.log('newDT', newDT)
                if(newDT?.length > 0){
                    optionpettype = newDT;
                }

            },
        },
    });


    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload PDF/JPEG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function fileChange(e: any, lan: any) {
        let res: any = e?.file;

        if(lan == "TH"){
            if(res?.status == "done"){
                setnameFileTH(res.response[0]?.name);
                setvalFileTH({
                    id: res.response[0]?.id,
                    url: res.response[0]?.url,
                    name: res.response[0]?.name
                });
            }
        }else if(lan == "EN"){
            if(res?.status == "done"){
                setnameFileEN(res.response[0]?.name);
                setvalFileEN({
                    id: res.response[0]?.id,
                    url: res.response[0]?.url,
                    name: res.response[0]?.name
                });
            }
        }
    }

    function removefile(lan: any) {
        if(lan == "TH"){
            setnameFileTH(undefined);
            setvalFileTH(undefined);
            formCustom?.resetFields(["term_th"]); 
        }else if(lan == "EN"){
            setnameFileEN(undefined);
            setvalFileEN(undefined);
            formCustom?.resetFields(["term_en"]);
        }
    }

    function onsetvalEdit(values: any, key: any){
        console.log('click edit pet/unit new')
        console.log('values edit new', values)
        // console.log('key edit', key)
        // console.log('expenGroup', expenGroup)

        // setValK(values);
        // setKeyK(key);

        valKom = values
        keyKom = key


        // **************** map form ****************
        const mappedFormIsCharge = values?.pet_maximum_by_type?.flatMap((item:any) =>
            ({
                name: `is_charge_${item.pet_type_id}`,
                value: item.is_charge,
            })
        );

        const mappedForm = values?.pet_maximum_by_type?.flatMap((item:any) =>
            item.pet_limit.map((limit:any) => ({
                name: `pet_size_${limit.size}_${item.pet_type_id}`,
                value: limit.max,
            }))
        );
        const fieldsValues:any = {};

        mappedForm.forEach((item:any) => {
            const { name, value } = item;
            fieldsValues[name] = value;
        });

        mappedFormIsCharge.forEach((item:any) => {
            const { name, value } = item;
            fieldsValues[name] = value;
        });

        formModal_EDIT?.setFieldsValue(fieldsValues);
        formModal_EDIT?.setFieldsValue(mappedFormIsCharge);
        // ************************************************


        formModal_EDIT?.setFieldsValue({
            area_size_from: values?.area_size_from,
            area_size_to: values?.area_size_to,
            maximum_pet: values?.maximum_pet,
        });


        // setOnClickEditModal(false)
        setkeyEDIT(key);
        setmdopen_EDIT(true);
    }

    const handleEditClick = useCallback(
        (expenGroupItem, index) => {
            onsetvalEdit(expenGroupItem, index);
        },
        [onsetvalEdit]
    );

    const handleEditOldClick = useCallback(
        (expenGroupItem, index) => {
            onsetvalEdit_OLD(expenGroupItem, index);

        },
        [onsetvalEdit_OLD]
    );

    const generatePetComponents = (values:any, key:any) => {
        let dataLoad = values?.pet_maximum_by_type;

        // console.log('generatePetComponents data >', dataLoad)

        const updatedPetComponents = dataLoad?.map((data:any, index:any) => (
            <div>
                <Row>
                    <Col span={6}>
                        <div style={{marginTop: "5px"}}>
                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{data?.key}{". "}{data?.pet_type_name}</span>
                        </div>
                    </Col>
                </Row>
                {
                    data?.pet_limit?.map((data_limit:any, limitIndex:any) => (
                        <span>
                        <Row>
                            <Col span={6}></Col>
                            <Col span={1}>
                                <div style={{marginTop: "5px"}}>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t(data_limit?.size)}</span>
                                </div>
                            </Col>
                            <Col span={4}>
                                <Form.Item 
                                    className="visible-label"
                                    name={`pet_size_${data_limit?.size.toLowerCase()}_${data?.pet_type_id}`}
                                    style={{marginBottom: 2}}
                                    initialValue={data_limit?.max}
                                    // initialValue={data_limit?.max > 0 ? data_limit?.max : 0}
                                    // value={data_limit?.max}
                                >
                                    <InputNumber style={{width:'90%'}} min={0} defaultValue={data_limit?.max} />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <div style={{marginTop: "5px"}}>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                </div>
                            </Col>
                        </Row>
                        </span>
                    ))
                }
                <Row>
                    <Col span={6}></Col>

                    <Col span={3}>
                        <div style={{marginTop: "5px"}}>
                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("มีค่าใช้จ่าย")}</span>
                        </div>
                    </Col>
                    <Col className="gutter-row" span={10}>
                        <Form.Item 
                            name={`is_charge_${data?.pet_type_id}`}
                            className="visible-label"
                            style={{marginBottom: 0}}
                            initialValue={data?.is_charge}
                            // initialValue={false}
                        >
                            <Radio.Group 
                                // defaultValue={data?.is_charge}
                                // value={data?.is_charge}
                                // onChange={(e: any) => setisCharge(e?.target?.value)}
                            >
                                <Radio value={false}>{t("ไม่มี")}</Radio>
                                <Radio value={true}>{t("มี")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider />
            </div>
        ))

        // setCountInterval(0)

        // if(dataLoad !== undefined){
        //     // console.log('update signal')
        //     // petComponentsTest.value = updatedPetComponents
        // }
        return updatedPetComponents
    };

    function onremoveexpen(key: any) {
        let newData: any = [];

        for (let i = 0; i < expenGroup?.length; i++) {
            if(i !== key){ newData?.push(expenGroup[i]) }
        }

        setexpenGroup(newData);
        settickerFlag((pre)=> !pre);
    }

    // ADD NEW
    async function onfinishexpen(values: any) {

        console.log('values onfinishexpen', values)
        // console.log('optionpettype', optionpettype)
        let new_k:any

        await axios.get(`${petURL}/api/lscpet/findActivePetType`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            let newDT: any = [];

            for (let index = 0; index < dataLoad?.length; index++) {

                // map data pet type
                newDT?.push({
                    _id: dataLoad[index]?._id,
                    key: index + 1,
                    pet_type_id: dataLoad[index]?._id,
                    pet_type_name: dataLoad[index]?.pet_type_name,
                    pet_type_name_en: dataLoad[index]?.pet_type_name_en,
                    pet_limit: [
                        {
                            "size_id": "656fdd7347a1bba6e8a9b15d",
                            "size": "S",
                            "max": 0
                        },
                        {
                            "size_id": "656fdd7847a1bba6e8a9b15f",
                            "size": "M",
                            "max": 0
                        },
                        {
                            "size_id": "656fdd7d47a1bba6e8a9b161",
                            "size": "L",
                            "max": 0
                        },
                        {
                            "size_id": "656fdd8147a1bba6e8a9b163",
                            "size": "XL",
                            "max": 0
                        }
                    ],
                    is_charge: false
                })
            }

            // console.log('newDT', newDT)
            if(newDT?.length > 0){
                new_k = newDT;
            }
        })

        edit_new_data_detail = []
        edit_new_data_detail = new_k

        edit_new_data_detail.forEach((petType:any) => {
            const typeId = petType._id;

            Object.keys(values).forEach((key) => {
                const parts = key.split('_');
                if (
                    parts.length === 3 &&
                    parts[0] === 'is' &&
                    parts[1] === 'charge' &&
                    parts[2] === typeId
                ) {
                    const inputValue = values[key];
                    if (inputValue !== undefined) {
                        petType.is_charge = inputValue;
                    }
                }
            });
          
            petType.pet_limit.forEach((limit:any) => {
              const size = limit.size;
          
              Object.keys(values).forEach((key) => {
                const parts = key.split('_');
                if (
                  parts.length === 4 &&
                  parts[0] === 'pet' &&
                  parts[1] === 'size' &&
                  parts[3] === typeId &&
                  parts[2].toUpperCase() === size
                ) {
                  const inputValue = values[key];
                  if (inputValue !== undefined) {
                    limit.max = inputValue;
                  }
                }
              });
            });
        });

        let newData: any = {
            "area_size_from": values?.area_size_from,
            "area_size_to": values?.area_size_to,
            "maximum_pet": values?.maximum_pet,
            "pet_maximum_by_type": edit_new_data_detail,
        }

        // setexpenGroup((pre: any) => [...pre, ...newData]);
        setexpenGroup((pre: any) => [...pre, newData]);
        settickerFlag((pre)=> !pre);
        setmdopen(false);
    }
    
    // EDIT NEW
    async function onfinishexpenEDIT(values: any) {
        let dataLoad: any = expenGroup;
        
        // console.log('edit new', dataLoad)
        // console.log('keyEDIT', keyEDIT)
        // console.log('values edit', values)
        // console.log('values edit', dataLoad[keyEDIT])

        // =============================================
        // new method
       
        dataLoad[keyEDIT]?.pet_maximum_by_type.forEach((petType:any) => {
            const typeId = petType._id;

            Object.keys(values).forEach((key) => {
                const parts = key.split('_');
                if (
                    parts.length === 3 &&
                    parts[0] === 'is' &&
                    parts[1] === 'charge' &&
                    parts[2] === typeId
                ) {
                    const inputValue = values[key];
                    if (inputValue !== undefined) {
                        petType.is_charge = inputValue;
                    }
                }
            });
          
            petType.pet_limit.forEach((limit:any) => {
              const size = limit.size;
                Object.keys(values).forEach((key) => {
                    const parts = key.split('_');
                    if (
                        parts.length === 4 &&
                        parts[0] === 'pet' &&
                        parts[1] === 'size' &&
                        parts[3] === typeId &&
                        parts[2].toUpperCase() === size
                    ) {
                        const inputValue = values[key];
                        if (inputValue !== undefined) {
                            limit.max = inputValue;
                        }
                    }
                });
            });
        });
        // =============================================

        let newData: any = {
            "area_size_from": values?.area_size_from,
            "area_size_to": values?.area_size_to,
            "maximum_pet": values?.maximum_pet,
            "pet_maximum_by_type": dataLoad[keyEDIT]?.pet_maximum_by_type
        }

        dataLoad[keyEDIT] = newData;
        setexpenGroup(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT(false);
    }

    async function submitDT(values:any){
        // console.log(">>> isCharge", isCharge)
        console.log(">>> expenGroup new", expenGroup)
        console.log(">>> expenGroup_OLD", expenGroup_OLD)

        // let body: any = {
        //     "property_obj": Prop,
        //     "can_pet": canPet,
        //     "term_th": valFileTH,
        //     "term_en": valFileEN,
        //     "is_charge": isCharge,
        //     "payment_platform": paymentPF,
        //     "bank_obj": Bank,
        //     "bank_account": values?.bank_account,
        //     "bank_account_no": values?.bank_account_no,
        //     "update_by": {
        //         "user_id": user_dt?.user_profile?.id,
        //         "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
        //     },
        //     "update_date": new Date().toISOString()
        // }
        let body: any = {
            "property_obj": Prop,
            "can_pet": canPet,
            "term_th": valFileTH,
            "term_en": valFileEN,
            "is_charge": isCharge,
            // "payment_platform": paymentPF,
            "payment_platform": isCharge ? paymentPF : null,
            "bank_obj": Bank,
            "bank_account": values?.bank_account,
            "bank_account_no": values?.bank_account_no,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }

        const countExpenOld = expenGroup_OLD.filter((item:any) => item?.status !== "removed");

        if(expenGroup?.length <= 0 && countExpenOld?.length <= 0 && isCharge){
            // console.log('req expense')
            setShowReqExpense(true)
        }else{
            let submit = await axios.put(petURL + "/api/lscpet/updateConfigProperty?id=" + pageID, body, { headers: { } })
            .then(async response => {
                let res: any = response?.data;
                let data_CREATE: any = expenGroup;
                let data_UPDATE: any = expenGroup_OLD?.filter((f: any) => f?.status == "active");
                let data_REMOVE: any = expenGroup_OLD?.filter((f: any) => f?.status == "removed");
    
                if(data_CREATE?.length > 0){
                    for (let i = 0; i < data_CREATE?.length; i++) {
                        let body: any = {
                            "config_property": pageID,
                            "area_size_from": data_CREATE[i]?.area_size_from,
                            "area_size_to": data_CREATE[i]?.area_size_to,
                            // "maximum_pet": data_CREATE[i]?.maximum_pet,
                            "pet_maximum_by_type": data_CREATE[i]?.pet_maximum_by_type,
                            "create_by": {
                                "user_id": user_dt?.user_profile?.id,
                                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
                            },
                        }
                        let submit = await axios.post(petURL + "/api/lscpet/createConfigPropDetail", body, { headers: { } })
                    }
                }
    
                if(data_UPDATE?.length > 0){
                    for (let i = 0; i < data_UPDATE?.length; i++) {
                        let idSIZE: any = data_UPDATE[i]?.id;
                        let body: any = {
                            "config_property": pageID,
                            "area_size_from": data_UPDATE[i]?.area_size_from,
                            "area_size_to": data_UPDATE[i]?.area_size_to,
                            // "maximum_pet": data_UPDATE[i]?.maximum_pet,
                            "pet_maximum_by_type": data_UPDATE[i]?.pet_maximum_by_type,
                            "update_by": {
                                "user_id": user_dt?.user_profile?.id,
                                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
                            },
                        }
                        
                        let submit = await axios.put(petURL + "/api/lscpet/updateConfigPropDetail?id=" + idSIZE, body, { headers: { } })
                    }
                }
    
                if(data_REMOVE?.length > 0){
                    for (let i = 0; i < data_REMOVE?.length; i++) {
                        let idSIZE: any = data_REMOVE[i]?.id;
                        let submit = await axios.delete(petURL + "/api/lscpet/deletePropDetail?id=" + idSIZE, { headers: { } })
                    }
                }

                successNoti('Edit is Success');
                replace("/pets/config-property")
            })
            .catch(error => {
                console.error('error ',error);
                faildNoti('Edit is Faild');
            });
        }
    }

    const successNoti = (msg:any) => {
        notification.success({
            message: msg,
            duration: 3,
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: msg,
            duration: 3,
        });
    };

    ////////////////////////////////////////////////////////////////////// OLD

    function onsetvalEdit_OLD(values: any, key: any){
        console.log('click edit pet/unit old')
        console.log('values edit old', values)
        console.log('values edit old key', key)
        console.log('============================')

        // setValK(values);
        // setKeyK(key);

        valOldKom = values
        keyOldKom = key

        // **************** map form ****************
        const mappedFormIsChargeOld = values?.pet_maximum_by_type?.flatMap((item:any) =>
            ({
                name: `is_charge_${item.pet_type_id}`,
                value: item?.is_charge,
            })
        );

        const mappedForm = values?.pet_maximum_by_type?.flatMap((item:any) =>
            item.pet_limit.map((limit:any) => ({
                name: `pet_size_${limit.size}_${item.pet_type_id}`,
                value: limit.max,
                // value: Number(limit?.max),
            }))
        );

        const fieldsValuesOld:any = {};

        mappedForm.forEach((item:any) => {
            const { name, value } = item;
            fieldsValuesOld[name] = value;
        });

        mappedFormIsChargeOld.forEach((item:any) => {
            const { name, value } = item;
            fieldsValuesOld[name] = value;
        });

        // formModal_EDIT_OLD?.resetFields();

        console.log('fieldsValuesOld', fieldsValuesOld)
        console.log('mappedFormIsChargeOld', mappedFormIsChargeOld)

        // formModal_EDIT_OLD?.setFieldsValue(fieldsValuesOld);
        // formModal_EDIT_OLD?.setFieldsValue(mappedFormIsChargeOld);
        // ************************************************

        formModal_EDIT_OLD?.setFieldsValue({
            area_size_from: values?.area_size_from,
            area_size_to: values?.area_size_to,
            maximum_pet: values?.maximum_pet,
            ...fieldsValuesOld,
            ...mappedFormIsChargeOld
        });
        
        setID(values?.id);
        setkeyEDIT_OLD(key);
        setmdopen_EDIT_OLD(true);
    }

    function onremoveexpen_OLD(key: any) {
        // console.log('key',key)
        let newData: any = expenGroup_OLD;
        const index = newData.findIndex((item:any) => item.id === key);

        // newData[key].status = "removed"
        newData[index].status = "removed"

        setexpenGroup_OLD(newData);
        settickerFlag((pre)=> !pre);
    }

    async function onfinishsizeEDIT_OLD(values: any) {
        let dataLoad: any = expenGroup_OLD;

        // console.log('edit old onfin dataLoadOld',  dataLoad[keyEDIT_OLD])
        // console.log('edit old onfin values', values)
        // console.log('keyEDIT', keyEDIT)

        // =============================================
        // new method
        // let new_k:any = []
        // new_k = []
        // await axios.get(`${petURL}/api/lscpet/findActivePetType`, { headers: {  } })
        // .then((res) => {
        //     let dataLoadRes: any = res?.data;
        //     let newDT: any = [];

        //     for (let index = 0; index < dataLoadRes?.length; index++) {

        //         // map data pet type
        //         newDT?.push({
        //             _id: dataLoadRes[index]?._id,
        //             key: index + 1,
        //             pet_type_id: dataLoadRes[index]?._id,
        //             pet_type_name: dataLoadRes[index]?.pet_type_name,
        //             pet_type_name_en: dataLoadRes[index]?.pet_type_name_en,
        //             pet_limit: [
        //                 {
        //                     "size_id": "656fdd7347a1bba6e8a9b15d",
        //                     "size": "S",
        //                     "max": 0,
        //                 },
        //                 {
        //                     "size_id": "656fdd7847a1bba6e8a9b15f",
        //                     "size": "M",
        //                     "max": 0,
        //                 },
        //                 {
        //                     "size_id": "656fdd7d47a1bba6e8a9b161",
        //                     "size": "L",
        //                     "max": 0,
        //                 },
        //                 {
        //                     "size_id": "656fdd8147a1bba6e8a9b163",
        //                     "size": "XL",
        //                     "max": 0,
        //                 }
        //             ],
        //             is_charge: false
        //         })
        //     }

        //     // console.log('newDT', newDT)
        //     if(newDT?.length > 0){
        //         new_k = newDT;
        //     }
        // })

        // edit_new_data_detail_2 = []
        // edit_new_data_detail_2 = new_k

        // console.log('edit_new_data_detail_2', edit_new_data_detail_2)

        dataLoad[keyEDIT_OLD]?.pet_maximum_by_type.forEach((petType:any) => {
            const typeId = petType._id;

            Object.keys(values).forEach((key) => {
                const parts = key.split('_');
                if (
                    parts.length === 3 &&
                    parts[0] === 'is' &&
                    parts[1] === 'charge' &&
                    parts[2] === typeId
                ) {
                    const inputValue = values[key];
                    if (inputValue !== undefined) {
                        petType.is_charge = inputValue;
                    }
                }
            });
          
            petType.pet_limit.forEach((limit:any) => {
              const size = limit.size;
          
                Object.keys(values).forEach((key) => {
                    const parts = key.split('_');
                    if (
                        parts.length === 4 &&
                        parts[0] === 'pet' &&
                        parts[1] === 'size' &&
                        parts[3] === typeId &&
                        parts[2].toUpperCase() === size
                    ) {
                        // console.log('values[key]', values[key])
                        const inputValue = values[key];
                        if (inputValue !== undefined) {
                            limit.max = inputValue;
                        }
                    }
                });
            });
        });
        // =============================================

        let newData: any = {
            "id": ID,
            "area_size_from": values?.area_size_from,
            "area_size_to": values?.area_size_to,
            // "maximum_pet": values?.maximum_pet,
            "pet_maximum_by_type": dataLoad[keyEDIT_OLD]?.pet_maximum_by_type,
            "status": "active"
        }

        dataLoad[keyEDIT_OLD] = newData;

        setexpenGroup_OLD(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT_OLD(false);
    }

    useEffect(() => {
        setShowReqExpense(false)
    }, [expenGroup]);

    // useEffect(() => {
    //     // console.log('expenGroup', expenGroup)
    //     // console.log('expenGroup old', expenGroup_OLD)
    //     // console.log('tickerFlag', tickerFlag)
    // }, [expenGroup]);

    useEffect(() => {
        console.log('expenGroup old', expenGroup_OLD)
    }, [expenGroup_OLD]);

    return(
        <Spin size="large" spinning={isLoading}>
            <Edit pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/config-property")}}} saveButtonProps={{hidden: true}}>
                <List 
                    title=""
                    pageHeaderProps={{
                        breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("config-property.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("config-property.title-edit")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                        extra : <></>
                    }}
                />

                <div style={{width: "30%"}}>
                    <Form 
                        form={formCustom}
                        layout="vertical"
                        onFinish={(values:any) => {
                            submitDT(values);
                        }}
                    >
                        <Row gutter={[6, 16]}>
                            <Col className="gutter-row" span={8}>
                                <div style={{marginTop: "8px"}}>
                                    <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("property.title")}</span>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                                <Form.Item 
                                    name="property"
                                    className="visible-label"
                                    style={{marginBottom: 0}}
                                    rules={[
                                        {
                                            required: true, message: 'please select property.'
                                        },
                                    ]}
                                >
                                    <Select
                                        options={dataProp}
                                        placeholder={t("select.sel")}
                                        showSearch
                                        allowClear
                                        onChange={(val: any, e: any)=> setProp({
                                            id: e?.value,
                                            property_name: e?.label,
                                        })}
                                        onClear={()=> setProp(undefined)}
                                        style={{width: "100%"}}
                                        filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" span={8}>
                                <div style={{marginTop: "8px"}}>
                                    <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("เปิดใช้งาน Pet")}</span>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                                <Form.Item 
                                    name="can_pet"
                                    className="visible-label"
                                    style={{marginBottom: 0}}
                                    rules={[
                                        {
                                            required: true, message: 'please select can pet.'
                                        },
                                    ]}
                                >
                                    <Radio.Group defaultValue={canPet} value={canPet} onChange={(e: any) => setcanPet(e?.target?.value)}>
                                        <Radio value={false}>{t("config-property.close")}</Radio>
                                        <Radio value={true}>{t("config-property.open")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>

                        {canPet == true && <Divider />}

                        {canPet == true &&
                            <Row gutter={[6, 16]}>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("T&C TH")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item
                                        name="term_th"
                                        className="visible-label"
                                        rules={[
                                            {
                                                required: nameFileTH ? false : true, message: 'Please upload Document.'
                                            },
                                        ]}
                                        style={{marginBottom: 0}}
                                    >
                                        <Upload
                                            style={{ borderRadius: 10, width:'100%', border: "1px solid #dedede" }}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}

                                            listType="picture"
                                            maxCount={1}
                                            onChange={(e: any) => fileChange(e, "TH")}
                                            beforeUpload={beforeUpload}
                                            showUploadList={false}
                                            openFileDialogOnClick={false}
                                            ref={refuploadTH}
                                        >
                                            <Space style={{ width: '100%' }}>
                                                <Input
                                                    placeholder="Browse File"
                                                    value={nameFileTH}
                                                    disabled
                                                />
                                                <Button
                                                    icon={valFileTH ?
                                                        <Icons.DeleteOutlined />
                                                        :
                                                        <Icons.UploadOutlined />
                                                    }
                                                    type="primary"
                                                    onClick={() => {valFileTH ?
                                                        removefile("TH")
                                                        :
                                                        refuploadTH?.current?.upload.uploader.onClick()
                                                    }}
                                                />
                                            </Space>
                                        </Upload>
                                    </Form.Item>
                                    <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677"}}>{`${t("pet-type.size-img")}`}</p>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("T&C EN")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item
                                        name="term_en"
                                        className="visible-label"
                                        rules={[
                                            {
                                                required: nameFileEN ? false : true, message: 'Please upload Document.'
                                            },
                                        ]}
                                        style={{marginBottom: 0}}
                                    >
                                        <Upload
                                            style={{ borderRadius: 10, width:'100%', border: "1px solid #dedede" }}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}

                                            listType="picture"
                                            maxCount={1}
                                            onChange={(e: any) => fileChange(e, "EN")}
                                            beforeUpload={beforeUpload}
                                            showUploadList={false}
                                            openFileDialogOnClick={false}
                                            ref={refuploadEN}
                                        >
                                            <Space style={{ width: '100%' }}>
                                                <Input
                                                    placeholder="Browse File"
                                                    value={nameFileEN}
                                                    disabled
                                                />
                                                <Button
                                                    icon={valFileEN ?
                                                        <Icons.DeleteOutlined />
                                                        :
                                                        <Icons.UploadOutlined />
                                                    }
                                                    type="primary"
                                                    onClick={() => {valFileEN ?
                                                        removefile("EN")
                                                        :
                                                        refuploadEN?.current?.upload.uploader.onClick()
                                                    }}
                                                />
                                            </Space>
                                        </Upload>
                                    </Form.Item>
                                    <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677"}}>{`${t("pet-type.size-img")}`}</p>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.charge")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="is_charge"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please select can pet.'
                                            },
                                        ]}
                                        initialValue={false}
                                    >
                                        <Radio.Group defaultValue={isCharge} value={isCharge} onChange={(e: any) => setisCharge(e?.target?.value)}>
                                            <Radio value={false}>{t("config-property.not-have")}</Radio>
                                            <Radio value={true}>{t("config-property.have")}</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>

                                {
                                    isCharge && <span>
                                        <Col className="gutter-row" span={24}>
                                            <div style={{marginTop: "8px"}}>
                                                <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.pay-ch")}</span>
                                            </div>
                                        </Col>
                                        <Col className="gutter-row" span={24}>
                                            <Form.Item 
                                                name="payment_platform"
                                                className="visible-label"
                                                style={{marginBottom: 0}}
                                                rules={[
                                                    {
                                                        required: true, message: 'please select can pet.'
                                                    },
                                                ]}
                                                initialValue={true}
                                            >
                                                <Radio.Group defaultValue={paymentPF} value={paymentPF} onChange={(e: any) => setpaymentPF(e?.target?.value)}>
                                                    <Radio value={true}>{t("config-property.pay-bill")}</Radio>
                                                    <Radio value={false}>{t("config-property.pay-no-bill")}</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </span>
                                }
                            </Row>
                        }

                        {paymentPF == false && isCharge && canPet && <Divider />}

                        {paymentPF == false && isCharge && canPet &&
                            <Row gutter={[6, 16]}>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.bank-name")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="bank_obj"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please select property.'
                                            },
                                        ]}
                                    >
                                        <Select
                                            options={dataBank}
                                            placeholder={t("select.sel")}
                                            showSearch
                                            allowClear
                                            onChange={(val: any, e: any)=> setBank({
                                                id: e?.value,
                                                bank_name: e?.label,
                                                logo: e?.logo
                                            })}
                                            onClear={()=> setBank(undefined)}
                                            style={{width: "100%"}}
                                            filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.bank-acc-no")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="bank_account_no"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please input bank account no.'
                                            },
                                        ]}
                                    >
                                        <Input allowClear type="number"/>
                                        {/* <InputNumber style={{width:'100%'}} min={0} /> */}
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginTop: "8px"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.bank-acc-name")}</span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={16}>
                                    <Form.Item 
                                        name="bank_account"
                                        className="visible-label"
                                        style={{marginBottom: 0}}
                                        rules={[
                                            {
                                                required: true, message: 'please input bank account.'
                                            },
                                        ]}
                                    >
                                        <Input allowClear/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        {isCharge == true && canPet && <Divider />}

                        {isCharge == true && canPet && 
                            <div className="layout_size_custom">
                                <div className="layout_size_custom_header" style={{display: "flex"}}>
                                    <div style={{marginRight: "1rem"}}>
                                        <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                        {/* <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.expenses")}</span> */}
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.pet-unit")}</span>
                                    </div>
                                    <div style={{marginTop: "-0.4rem"}}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                setmdopen(true);
                                            }}
                                            icon={<Icons.PlusOutlined />}
                                            size="small"
                                            style={{borderRadius: "5px"}}
                                        >{t("buttons.add")}</Button>
                                    </div>
                                </div>
                                {
                                    isCharge && showReqExpense &&
                                    <div>
                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#ff4d4f"}}>{t("config-property.please-add-pet")}</span>
                                    </div>
                                }
                            </div>
                        }

                        {isCharge == true && canPet &&
                            <div style={{marginTop: "10px"}}>
                                <div style={{ display:"none"}}>{JSON.stringify(tickerFlag)}</div>{/* ห้ามลบ ห้ามแก้ */}

                                {/* EDIT OLD PET/UNIT AREA */}
                                {expenGroup_OLD?.length > 0 && expenGroup_OLD?.filter((f: any) => f?.status == "active")?.map((item:any,index:any) => {
                                    return (
                                        <Card
                                            key={index}
                                            className="card_custom_pet_species"
                                            style={{
                                                width: "100%", 
                                                marginBottom: "10px", 
                                                border: "1px solid #dedede"
                                            }}
                                        >
                                            <Row gutter={[6,8]}>
                                                <Col className="gutter-row" span={20}>
                                                    <Row gutter={6}>
                                                        <Col className="gutter-row" span={12} style={{borderRight: "1px solid #dedede"}}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("config-property.size-room")} :</span>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.area_size_from}</span>
                                                        </Col>
                                                        <Col className="gutter-row" span={12} style={{paddingLeft: "10px"}}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("config-property.to")} :</span>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.area_size_to}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "end"}}>
                                                    <Button 
                                                        icon={<Icons.EditOutlined />} 
                                                        size="small" style={{marginRight: "5px"}} 
                                                        // onClick={()=> onsetvalEdit_OLD(expenGroup_OLD[index], index)}
                                                        onClick={() => handleEditOldClick(expenGroup_OLD[index], index)}
                                                    />
                                                    {/* <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremoveexpen_OLD(index)}/> */}
                                                    <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremoveexpen_OLD(item?.id)}/>
                                                </Col>
                                                {/* <Col className="gutter-row" span={22}>
                                                    <Row gutter={6}>
                                                        <Col className="gutter-row" span={8}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.num-pet")} : </span>
                                                        </Col>
                                                        <Col className="gutter-row" span={16}>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.maximum_pet}</span>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                            </Row>
                                        </Card>
                                    )})
                                }

                                {/* EDIT NEW PET/UNIT AREA */}
                                {expenGroup?.length > 0 && expenGroup?.map((item:any,index:any) => {
                                    return (
                                        <Card
                                            key={index}
                                            className="card_custom_pet_species"
                                            style={{
                                                width: "100%", 
                                                marginBottom: "10px", 
                                                border: "1px solid #dedede"
                                            }}
                                        >
                                            <Row gutter={[6,8]}>
                                                <Col className="gutter-row" span={20}>
                                                    <Row gutter={6}>
                                                        <Col className="gutter-row" span={12} style={{borderRight: "1px solid #dedede"}}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("config-property.size-room")} :</span>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.area_size_from}</span>
                                                        </Col>
                                                        <Col className="gutter-row" span={12} style={{paddingLeft: "10px"}}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("config-property.to")} :</span>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.area_size_to}</span>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "end"}}>
                                                    <Button icon={<Icons.EditOutlined />} size="small" style={{marginRight: "5px"}} onClick={()=> onsetvalEdit(expenGroup[index], index)}/>
                                                    <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremoveexpen(index)}/>
                                                </Col>
                                                {/* <Col className="gutter-row" span={22}>
                                                    <Row gutter={6}>
                                                        <Col className="gutter-row" span={8}>
                                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.num-pet")} : </span>
                                                        </Col>
                                                        <Col className="gutter-row" span={16}>
                                                            <span style={{fontSize: "0.7vw"}}>{item?.maximum_pet}</span>
                                                        </Col>
                                                    </Row>
                                                </Col> */}
                                            </Row>
                                        </Card>
                                    )})
                                }
                            </div>
                        }

                        <Modal
                            title={t("config-property.add-expen")}
                            visible={mdopen}
                            onCancel={() => setmdopen(false)}
                            onOk={() => formModal?.submit()}
                            afterClose={()=> {
                                formModal?.resetFields();
                            }}
                        >
                            <Form 
                                form={formModal}
                                layout="vertical"
                                onFinish={(values:any) => {
                                    onfinishexpen(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.size-room")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_from"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size from.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_to"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size to.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <div style={{marginTop: "5px"}}>
                                            {/* <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span> */}
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("กำหนดจำนวนสัตว์เลี้ยง/ยูนิต")}</span>
                                        </div>
                                    </Col>
                                </Row>

                                {/* {optionpettype?.length > 0 && optionpettype?.filter((f: any) => f?.status == "active")?.map((item:any,index:any) => { */}
                                {optionpettype?.length > 0 && optionpettype?.map((item:any,index:any) => {
                                    return (
                                        <>
                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{item?.key}{". "}{item?.pet_type_name}</span>
                                                        {/* <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{index}{". "}{item?.pet_type_name}</span> */}
                                                    </div>
                                                </Col>

                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("S")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_s"
                                                        // name={`pet_size_s_${item?.key}`}
                                                        name={`pet_size_s_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("M")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_m"
                                                        // name={`pet_size_m_${item?.key}`}
                                                        name={`pet_size_m_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} 
                                                            // onChange={(e: any) => onChangePetType(e, item, "m")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("L")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_l"
                                                        // name={`pet_size_l_${item?.key}`}
                                                        name={`pet_size_l_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} 
                                                            // onChange={(e: any) => onChangePetType(e, item, "l")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                            <Row>
                                                <Col span={6}>
                                                    <div style={{marginTop: "5px"}}>
                                                    </div>
                                                </Col>
                                                <Col span={1}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("XL")}</span>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Form.Item 
                                                        className="visible-label"
                                                        // name="pet_size_xl"
                                                        // name={`pet_size_xl_${item?.key}`}
                                                        name={`pet_size_xl_${item?.pet_type_id}`}
                                                        style={{marginBottom: 2}}
                                                    >
                                                        <InputNumber style={{width:'90%'}} min={0}  defaultValue={0} 
                                                            // onChange={(e: any) => onChangePetType(e, item, "xl")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={5}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("ตัว")}</span>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col span={6}></Col>

                                                <Col span={3}>
                                                    <div style={{marginTop: "5px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("มีค่าใช้จ่าย")}</span>
                                                    </div>
                                                </Col>
                                                <Col className="gutter-row" span={10}>
                                                    <Form.Item 
                                                        // name="is_charge"
                                                        name={`is_charge_${item?.pet_type_id}`}
                                                        className="visible-label"
                                                        style={{marginBottom: 0}}
                                                        rules={[
                                                            {
                                                                required: true, message: 'please select can pet.'
                                                            },
                                                        ]}
                                                        initialValue={false}
                                                    >
                                                        <Radio.Group 
                                                            defaultValue={false}
                                                            // onChange={(e: any) => setisCharge(e?.target?.value)}
                                                        >
                                                            <Radio value={false}>{t("ไม่มี")}</Radio>
                                                            <Radio value={true}>{t("มี")}</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Divider />
                                        </>
                                    )})
                                }
                            </Form>
                        </Modal>

                        <Modal
                            title={t("config-property.edit-expen")}
                            visible={mdopen_EDIT}
                            // onCancel={() => setmdopen_EDIT(false)}
                            onCancel={() => (setmdopen_EDIT(false))}
                            onOk={() => formModal_EDIT?.submit()}
                            afterClose={()=> {
                                formModal_EDIT?.resetFields();
                            }}
                        >
                            <Form 
                                form={formModal_EDIT}
                                layout="vertical"
                                // initialValues={initialFormValues}
                                onFinish={(values:any) => {
                                    onfinishexpenEDIT(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.size-room")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_from"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size from.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_to"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size to.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {/* { modalContentK } */}
                                {/* { modal_content_map } */}
                                {/* { myValueRef.current } */}
                                {/* { petComponents } */}
                                {/* { petComponentsKom } */}

                                {/* { 
                                    <EditNewModalContent 
                                        // dataLoad={valK}
                                        // key={keyK} 
                                        dataLoad={valKom}
                                        key={keyKom} 
                                    />
                                } */}
                                {/* <div style={{visibility: "hidden",height: "0px"}}>{keyKom}</div> */}
                                <EditNewModalContent 
                                    // dataLoad={valK}
                                    // key={keyK} 
                                    dataLoad={valKom}
                                    key={keyKom} 
                                />
                            </Form>
                        </Modal>

                        <Modal
                            title={t("config-property.edit-expen")}
                            visible={mdopen_EDIT_OLD}
                            // onCancel={() => setmdopen_EDIT_OLD(false)}
                            onCancel={() => (setmdopen_EDIT_OLD(false))}
                            onOk={() => formModal_EDIT_OLD?.submit()}
                            afterClose={()=> {
                                formModal_EDIT_OLD?.resetFields();
                            }}
                        >
                            <Form 
                                form={formModal_EDIT_OLD}
                                layout="vertical"
                                onFinish={(values:any) => {
                                    // console.log('values', values)
                                    onfinishsizeEDIT_OLD(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.size-room")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_from"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size from.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("config-property.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="area_size_to"
                                            rules={[
                                                {
                                                    required: true, message: 'please input size to.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <EditOldModalContent 
                                    // dataLoad={valK}
                                    // key={keyK} 
                                    dataLoad={valOldKom}
                                    key={keyOldKom} 
                                />
                            </Form>
                        </Modal>
                    </Form>
                </div>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button type="primary" onClick={() => formCustom?.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
                </Row>
            </Edit>
        </Spin>
    )
}