/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, DatePicker, Form, List, Radio, RadioChangeEvent, Row, Select, Space, TextField, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useGetIdentity, useList, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { TOKEN_KEY } from "../../constants";
import { IFacilityBan, IProperties, IUnit, IUserhunit } from "interfaces";
import { useEffect, useState } from "react";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import moment from 'moment';
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

export const FacilitiesBanEdit: React.FC<IResourceComponentsProps> = () => {

    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    // const { mutate: CreateLog }             = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    const { form,formProps, saveButtonProps } = useForm<IFacilityBan,HttpError,{}>({
        // onMutationSuccess(data, variables, context) {
        //     let recordData:any = []
        //     recordData = data?.data? data?.data : [];
        //     CreateLog({
        //         resource    : log_endpoint,
        //         values      : {
        //             // user_profile            : user.id,
        //             user_profile            : user_data.user_profile.id,
        //             log_time_stamp          : Date.now(), 
        //             log_event               : "Create",
        //             menu_event              : 'facility-types',
        //             SysPkID                 : recordData.data.id + ""
        //         }
        //     });
        // },
    });

    const { queryResult } = useShow<IFacilityBan>({metaData: {
        populate: '*'
        }, });
    const { data }              = queryResult;
    const record                = data?.data;
    let recordData:any = []
    recordData = data?.data ? data?.data : [];

    const t = useTranslate();
    // const [count, setCount] = useState(0)
    const { Option } = Select;

    const [vsetBanType, setBanType] = useState<any>();
    const [vsetBanStatus, setBanStatus] = useState<any>();

    const [vProperty, setProp] = useState<any>();
    const [vsetUnit, setUnit] = useState<any>();
    const [vsetProfile, setProfile] = useState<any>();

    const [vsetnote, setnote]   = useState<any>();
    const [vBandate, setBandate ] = useState<any>("");

    // const [ vUserHasUnitID, setUserHasUnitID] = useState<any>();


    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "facility-bans",
        metaData: {
            populate: [
                '*',
                'property',
                'unit',
                'user_profile',
                'createBy',
                'updateBy'
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();

          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        form.setFieldsValue({
            property:
                recordData?.property ?
                    recordData.property.property_name
                : null,   
            unit:
                recordData?.unit ?
                    recordData.unit.unit_name
                : null,       
            user_profile:
                recordData?.user_profile ?
                    recordData.user_profile.first_name+ ' ' + recordData.user_profile.last_name
                : null,       
        });

        setProp(recordData?.property ?recordData.property.id: null)
        setUnit(recordData?.unit ?recordData.unit.id: null)
        setProfile(recordData?.user_profile ?recordData.user_profile.id: null)

        if(recordData.ban_status !== null && recordData.ban_status !== undefined){
            {recordData.ban_status == true ? setBanStatus(true):setBanStatus(false)}
        }

        if(recordData.ban_type !== null && recordData.ban_type !== undefined){
            {recordData.ban_type == true ? setBanType(true):setBanType(false)}
        }
    }

    // useEffect(() => {
    //     if (count < 2) {
    //       const interval = setInterval(() => {
    //         setCount(prev => prev + 1)

    //         form.setFieldsValue({
    //             property:
    //                 recordData?.property ?
    //                     recordData.property.property_name
    //                 : null,   
    //             unit:
    //                 recordData?.unit ?
    //                     recordData.unit.unit_name
    //                 : null,       
    //             user_profile:
    //                 recordData?.user_profile ?
    //                     recordData.user_profile.first_name+ ' ' + recordData.user_profile.last_name
    //                 : null,       
    //         });

    //         setProp(recordData?.property ?recordData.property.id: null)
    //         setUnit(recordData?.unit ?recordData.unit.id: null)
    //         setProfile(recordData?.user_profile ?recordData.user_profile.id: null)

    //         if(recordData.ban_status !== null && recordData.ban_status !== undefined){
    //             {recordData.ban_status == true ? setBanStatus(true):setBanStatus(false)}
    //         }

    //         if(recordData.ban_type !== null && recordData.ban_type !== undefined){
    //             {recordData.ban_type == true ? setBanType(true):setBanType(false)}
    //         }
    //       }, 1000)
    //       return () => clearInterval(interval)
          
    //     }
    // }, [count,record])

    const Property = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['unit','unit.property'],
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            pagination:{current: 1, pageSize: 100}   // K create
        }
    });
    const { data: Dataprop } = Property;
    let PropData:any = []
    PropData = Dataprop?.data? Dataprop?.data : [];  
    const PropListData = Array.from(new Set(PropData.map((item: any) => item.unit.property.id)));     

    const Unit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: '*',
        },
        config:{
            filters: [
                {
                    field: "unit][property][id]",
                    operator: "eq",
                    value: vProperty + ""
                }
            ],
            pagination:{current: 1, pageSize: 100}   // K create
        }
    });
    const { data: DataUnit } = Unit;
    let UnitData:any = []
    UnitData = DataUnit?.data? DataUnit?.data : [];

    const UnitListData = Array.from(new Set(UnitData.map((item: any) => item.unit.id))); 

    const { selectProps: SelProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "id",
                operator: "eq",
                value: PropListData
            }
        ]   
    });

    const { selectProps: SelUnit } = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "id",
                operator: "eq",
                value: UnitListData.length > 0 ? UnitListData:"NULL"
            }
        ]
    });

    const UserHasUnit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile'],
        },
        config:{
            filters: [
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : vsetUnit + ""
                },
            ]
        }
    });
    const { data: Datauserhunit } = UserHasUnit;
    let userhunitData:any = []
    userhunitData = Datauserhunit?.data? Datauserhunit?.data : [];

    const onChangeBantype = ({ target: { value } }: RadioChangeEvent) => {
        setBanType(value);
    };

    const onChangeBanStatus = ({ target: { value } }: RadioChangeEvent) => {
        // true = ban, false = unban
        setBanStatus(value);
    };

    const BandateChange = (date: Dayjs | null, dateString: string, id: number) => {
        // setBandate(dateString);
        // console.log('dateString', dateString) 
        // console.log('dayjs(dateString)', dayjs(dateString).format("DD/MM/YYYY"))
        // setBandate(dayjs(dateString));
        setBandate(dateString);
        // setBandate(dayjs(dateString).format("DD-MM-YYYY"));
    };

    function onchangeProp(value:any) {
        setProp(value);
        form.resetFields(["unit"]);
        form.resetFields(["user_profile"]);
    }

    function onchangeUnit(value:any) {
        setUnit(value);
        form.resetFields(["user_profile"]);
    }

    function onchangeUserHasUnit(value:any) {
        // setUserHasUnitID(value);
        setUser(value);
    }

    function setUser(v:any){
        setProfile(v);
    }

    // console.log('vsetBanStatus', vsetBanStatus)

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-ban.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />

        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.updateBy = user_data.user_profile.id;

                values.property = vProperty;
                values.unit = vsetUnit;
                {vsetBanType == false ? values.user_profile = vsetProfile: values.user_profile = null}
                // values.user_profile = vsetProfile;

                values.ban_note = vsetnote;
                values.ban_type = values.ban_type;

                vsetBanType ? values.banType = "unit" : values.banType = "user"

                
                if(vsetBanStatus){ // ถ้าเลือก ban 
                    values.ban_check = null
                }else if(vsetBanStatus == false){ // เลือก unBan
                    console.log('ban_check')
                    values.date_time = dayjs().format("YYYY-MM-DD");
                    values.ban_status = vsetBanStatus;
                    values.ban_check = false // กด unban mannual
                }
                
                // if(vsetBanStatus == false){
                //     console.log('ban_check')
                //     values.date_time = dayjs().format("YYYY-MM-DD");
                //     values.ban_status = vsetBanStatus;
                //     values.ban_check = false // กด unban mannual
                // }

                if(vBandate !== ""){
                    values.date_time = vBandate;
                }
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("property.title")} name="property"
                                    rules={[
                                        {
                                            required: true, message: 'Please select property.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeProp} {...SelProp}/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("unit.title")} name="unit"
                                    rules={[
                                        {
                                            required: true, message: 'Please select unit.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeUnit} {...SelUnit} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={5}>
                                <Form.Item  label={t("fclt-ban.bstatus")} name="ban_status"
                                    rules={[
                                        {
                                            required: true, message: 'Please select ban type.'
                                        },
                                    ]}
                                    >
                                    <Radio.Group onChange={onChangeBanStatus} style={{verticalAlign: 'middle'}}>
                                        <Radio.Button value={true}>{t("fclt-ban.true")}</Radio.Button>
                                        <Radio.Button value={false}>{t("fclt-ban.false")}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={5}>
                                {vsetBanStatus == true ? 
                                <>
                                    <Form.Item label={t("fclt-ban.bdate")} name="date_time" 
                                        // getValueProps={(value) => ( 
                                        //     {value: value ? dayjs(value) : "",}
                                        // )}

                                        getValueProps={(value) => ( 
                                            {value: value ? dayjs(value): "",}
                                        )}

                                        rules={[
                                            {
                                                required: true, message: 'Please select unban date.'
                                            },
                                        ]}

                                        // getValueProps={(value) => ({value: value ? dayjs(value).format('YYYY-MM-DD HH:mm') : "",})}
                                        // getValueProps={(value) => ({value: value ? moment(value).format("YYYY-MM-DD HH:mm A") : "",})}
                                    >
                                        <DatePicker 
                                            // showTime
                                            // format="YYYY-MM-DD HH:mm" 
                                            format="YYYY-MM-DD" 
                                            style={{width: "95%"}}
                                            onChange={(date, dateString) => BandateChange(date, dateString, 1)} 
                                            disabledDate={(current) => {
                                                // let customDate = moment().format("YYYY-MM-DD HH:mm");
                                                // return current && current < moment(customDate, "YYYY-MM-DD HH:mm");
                                                // let customDate = moment().format("YYYY-MM-DD");
                                                // return current && current < moment(customDate, "YYYY-MM-DD");

                                                let customDate = dayjs().format("YYYY-MM-DD");
                                                return current && current.isBefore(customDate, 'day');
                                            
                                            }}
                                        />

                                    </Form.Item>
                                </>
                                :<></>}
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("fclt-ban.note")} name="ban_note"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter ban note.'
                                        },
                                    ]}>
                                        <CKEditor
                                                name="ban_note"
                                                editor={ClassicEditor}
                                                data={recordData.ban_note}
                                                config={{
                                                    toolbar: [
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "link",
                                                        "bulletedList",
                                                        "numberedList",
                                                        "blockQuote",
                                                        "ckfinder",
                                                        "|",
                                                        "imageTextAlternative",
                                                        "imageUpload",
                                                        "imageStyle:full",
                                                        "imageStyle:side",
                                                        "|",
                                                        "mediaEmbed",
                                                        "insertTable",
                                                        "tableColumn",
                                                        "tableRow",
                                                        "mergeTableCells",
                                                        "|",
                                                        "undo",
                                                        "redo"
                                                    ],
                                                    mediaEmbed: {
                                                        // configuration...
                                                        removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                    },
                                                    image: {
                                                        toolbar: ['']
                                                    }
                                                }}
                                                
                                                onInit={(editor:any) => {
                                                }}
                                                onChange={(event:any, editor:any) => {
                                                const data = editor.getData();
                                                setnote(data);
                                                }}
                                                onBlur={(editor:any) => {
                                                }}
                                                onFocus={(editor:any) => {
                                                }}  
                                        />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item name="ban_type">
                            <Radio.Group onChange={onChangeBantype}>
                                <Space direction="vertical">
                                    <Radio value={true}>{t("fclt-ban.btype-unit")}</Radio>
                                    <Radio value={false}>{t("fclt-ban.btype-user")}</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        {vsetBanType == false ? 
                        <>
                            <Form.Item  label={t("user-profiles.user-profile")} name="user_profile"
                                rules={[
                                    {
                                        required: true, message: 'Please select user profile.'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")} onChange={onchangeUserHasUnit}>
                                        {
                                            userhunitData !== undefined?
                                                userhunitData.map((item:any,key:any) => {
                                                    return <>
                                                        {item.user_profile !== null? 
                                                            <>
                                                                <Option key={key} value={item.user_profile.id}>{item.user_profile.first_name + ' ' + item.user_profile.last_name}</Option>
                                                            </>
                                                        :
                                                            <></>}
                                                    </>
                                                })
                                            :<></>
                                        }
                                    </Select>
                            </Form.Item>
                        </>
                        :<></>}
                    </Card>

                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>

                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>   
    );
};