/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Form, Input, List, Row, useForm, TextField, Select, useSelect, Button, Icons, useModal, Modal, Avatar } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IvehicleBrand, IvehicleType } from "interfaces";
import moment from 'moment';
import { useEffect, useState } from "react";

export const VehicleBEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form, formProps, saveButtonProps } = useForm<IvehicleBrand>({redirect:"list"});
    const { queryResult } = useShow<IvehicleBrand>({metaData: {
        populate: ['createBy.avatar','updateBy.avatar','vehicle_type']
        }, });
    const { data } = queryResult;
    const record = data?.data;
    let recordData:any = []
    recordData = data?.data? data?.data : [];

    // console.log('recordData', recordData)
    const [ count, setCount] = useState(0);
    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";
    const [ vVehicleTypeID, setVehicleTypeID ] = useState<any>();
    const { modalProps, show, close } = useModal();

    const { selectProps: selvehicleType } = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    // useEffect(() => {
    //     // setVehicleTypeID(recordData ? recordData.vehicle_type.id : '')
    // },[recordData])

    // useEffect(() => {
    //     console.log(formProps);
    //     if (formProps.initialValues?.vehicle_type !== undefined) {
    //         setVehicleTypeID(formProps.initialValues?.vehicle_type);
    //         // setVehicleTypeID(recordData ? recordData.vehicle_type.id : '');
    //     }
    // }, [formProps]);

    useEffect(() => {
        if (count < 2) { 
            const interval = setInterval(() => { 
                setCount(prev => prev + 1)
                setVehicleTypeID(recordData ? recordData.vehicle_type.id : '');
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    // console.log('vVehicleTypeID', vVehicleTypeID)

    if(recordData !== undefined && form.getFieldValue(["vehicle_type"]) == undefined){
        form.setFieldsValue({
            vehicle_type:
                recordData.vehicle_type !== null && recordData.vehicle_type !== undefined  ?
                    recordData.vehicle_type.type_nameEN
                : null
        })
    }
    
    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'vehicle-brand',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }
    
    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("vehicle.bc_title_b_edit")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title_b_edit")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            updateUserUpdatedBy();
            values.vehicle_type = vVehicleTypeID
            values.updateBy = user_data.user_profile.id
            return ( 
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
            );
         }}>
            <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={24} style={{marginTop: "-60px",marginBottom: "1%"}}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={22} />
                        <Col className="gutter-row" span={2}>
                            <Button onClick={show} style={{width: "100%",borderRadius: "5px"}} icon={<Icons.InfoCircleOutlined/>}>Info</Button>    
                        </Col>
                    </Row>
                </Col>
                <Col id="Col_w" className="gutter-row" span={24}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.type")} 
                                    name="vehicle_type"
                                    // name={["vehicle_type", "type_nameEN"]}
                                    rules={[
                                        {
                                            required: true, message: 'Please select'
                                        },
                                    ]}
                                >
                                    <Select placeholder={t("select.sel")}
                                        {...selvehicleType}
                                        onSelect={(value: any) => {
                                            setVehicleTypeID(value);
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.brand-code")} name="brand_code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter brand code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.brand-name_en")} name="brand_nameEN"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter brand name(en).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.brand-name_th")} name="brand_nameTH"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter brand name(th).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Modal onOk={close} {...modalProps} footer={[<Button key="submit" type="primary" onClick={close}>OK</Button>]}>
                <div><Icons.InfoCircleOutlined style={{marginRight: "3%"}}/><TextField value={"Data Create & Update"}/></div>
                <Row gutter={16} style={{marginTop: "6%"}}>
                    <Col className="gutter-row" span={24}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={15}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4}>
                                    {
                                        recordData?.createBy?
                                            recordData.createBy.avatar !== null ?

                                            <Avatar size={32} src={recordData.createBy.avatar.url} style={{marginTop: "-7px"}}/>
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                    }
                                    </Col>
                                    <Col className="gutter-row" span={8}><span>{t("created")}</span></Col>
                                    <Col className="gutter-row" span={12}><TextField value={moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}/></Col>
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                    {/* <Col className="gutter-row" span={18}><TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null? recordData.createBy.first_name !== null && recordData.createBy.last_name !== null ? recordData.updateBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}/></Col> */}
                                    <Col className="gutter-row" span={18}>
                                        <TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null && recordData?.createBy !== undefined? 
                                            recordData?.createBy.first_name !== null && recordData.createBy.last_name !== null ? 
                                            recordData.createBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="gutter-row" span={24} style={{marginTop: "3%"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={15}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4}>
                                    {
                                        recordData?.updateBy?
                                            recordData.updateBy.avatar !== null ?

                                            <Avatar size={32} src={recordData.updateBy.avatar.url} style={{marginTop: "-7px"}}/>
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                    }
                                    </Col>
                                    <Col className="gutter-row" span={8}><span>{t("lupdate")}</span></Col>
                                    <Col className="gutter-row" span={12}><TextField value={moment(recordData?.updatedAt).format('D/MM/yyyy HH:mm')}/></Col>
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                    <Col className="gutter-row" span={18}>
                                        <TextField style={{marginLeft: "3%"}} value={recordData?.updateBy !== null && recordData?.updateBy !== undefined? 
                                            recordData?.updateBy.first_name !== null && recordData.updateBy.last_name !== null ? 
                                            recordData.updateBy.first_name + " " + recordData.updateBy.last_name : null :"ไม่ระบุ"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </Form>
    </Edit>   
    );
};