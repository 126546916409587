/* eslint-disable */
import {  Col,  Row, Show, TextField,  Avatar, Typography, Card, List, Space, Breadcrumb, Collapse} from "@pankod/refine-antd";

import { IUserhunit, IUserProfile } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useApiUrl, useList, useOne, useShow, useTranslate } from "@pankod/refine-core";
// import { TOKEN_KEY } from "../../constants";


import "./css/style.css";
import { useEffect, useState } from "react";

import {  CalendarOutlined, PhoneOutlined, MailOutlined,TeamOutlined } from "@ant-design/icons";
// import axios from "axios";

const { Text } = Typography;
// const { Panel } = Collapse;

// import {
//     Row,
//     Col,
//     Divider,
//     DatePicker,
//     Space,
//     Avatar,
//     Upload,
//     Grid,
//     getValueFromEvent,
//     Card,
// } from "@pankod/refine-antd";

export const UserProfileShow = () => {

    const API_URL = useApiUrl();

    let arr = window.location.href.split("/");
    // let arr_id = arr[arr.length-1]
    // id = arr_id.split("_")
    // let item_id = id[0]
    // let job_id = id[1]

    console.log('arr', arr[arr.length - 1])

    // console.log(API_URL)
    const { queryResult } = useShow<IUserProfile>({
        resource: 'user-profiles',
        id: arr[arr.length - 1],
        metaData: {
            populate: '*'
        }, 
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;
            console.log(">>> dataLoad",dataLoad)
        }}
    });

    console.log('queryResult', queryResult?.data)

    // const { queryResult } = useList<any>({
    //     resource: 'user-profiles',
    //     metaData: {
    //         populate: '*'
    //     }, 
    // });

    const [ vDataHasUnit, setDataHasUnit ]  = useState<any>([]);
    const { data, isLoading } = queryResult;
    const record = data?.data;
    let dataUserHasUnit: any[] = [];

    let recordData  :   any;
    recordData = queryResult?.data ? queryResult?.data.data : [];

    console.log("xxxxxx", recordData)

    useEffect(() => {
        // dataEmp      = queryResult.data?.data  ? queryResult.data?.data : [];
        // console.log("user show",dataEmp);
        let userID = record?.id
        dataUserHasUnit = ll.filter((item:any,index:any)=> {
            if (item.user_profile !== null){
                // console.log(item.user_profile.id);
                if(item.unit !== null){
                    return  item.user_profile.id === userID
                }
            }
            // return  item.user_profile.id === userID
        })
        setDataHasUnit(dataUserHasUnit);
        // console.log('xxxxxxxxxxxxxxx',queryResult);

        // console.log(">>>>>>>>>>", userID);
    }, [record])

    const categoryQueryResult = useOne<IUserhunit>({
        metaData: {
            populate: '*'
        },
        resource: "user-has-units",
        id: '',
    });
    let ll:any = categoryQueryResult?.data?.data ? categoryQueryResult?.data?.data : [];

    const t = useTranslate();

    return (
        <Show 
            isLoading={isLoading} 
            pageHeaderProps={{extra: <></>}}
        >
                <List 
                    title={t("")}
                    pageHeaderProps={{ 
                        extra : <></>
                    }}
                />

                <Space style={{marginBottom: "3%"}}>
                    <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("user-management.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("user-profiles-show.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Space>

                {/* <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}> */}
                    <Row gutter={[16, 24]}>
                    {/* <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}> */}
                        <Col className="gutter-row" span={13}>
                            <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset"}}>
                                <Row>
                                    <Col span={5}>
                                        {
                                            record?.user !== undefined ?
                                            <>
                                                <Avatar 
                                                    style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)"}}
                                                    src={record?.avatar === null ? "https://joeschmoe.io/api/v1/random" :record?.avatar.url} 
                                                />
                                            </>:<>-</>
                                        }
                                    </Col>
                                        
                                    <Col span={19}>
                                        <div className="userprofile_mleft">
                                            <div id="userprofile_n">
                                                <TextField value={record?.first_name ? record?.first_name : '-'} style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",marginRight: "3%"}} />
                                                <TextField value={record?.last_name ? record?.last_name : '-'} style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px"}} />
                                                {/* <Text style={{marginLeft: "2%",fontSize: "2vw"}}>(</Text>
                                                    <TextField style={{fontSize: "2vw"}} value={record?.nick_name ? record?.nick_name : '-'} />
                                                <Text style={{fontSize: "2vw"}}>)</Text> */}
                                            </div>

                                            <div style={{marginBottom: "5%",marginTop: "2%",borderRadius: "10px"}}>
                                                <span className="id-userprofile"><Text style={{borderRadius: "50px 0px 0px 50px",paddingLeft: "3%",boxShadow: "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 0px 10px -10px"}}>ID</Text>
                                                    <TextField style={{borderRadius: "0px 50px 50px 0px", paddingLeft: "2%", paddingRight: "3%", boxShadow: "rgb(50 50 93 / 25%) -2px 6px 10px -2px, rgb(0 0 0 / 30%) 0px 0px 20px -10px"}} value={record?.id ? record?.id : '-'} />
                                                </span>

                                                

                                                <span style={{marginLeft: "2%"}}>
                                                    {
                                                        record?.user_type.type_name == "Staff" ?
                                                            <>
                                                            <TextField 
                                                            style={{
                                                                fontSize: "0.8vw",
                                                                color: "#fff",
                                                                marginTop: "10%",

                                                                borderRadius: "50px", 
                                                                paddingLeft: "2%", 
                                                                paddingRight: "2%",
                                                                paddingBottom: "1%",
                                                                paddingTop: "1%",
                                                                boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                                background: "#B29C65"
                                                            }} 
                                                                value={record?.user_type!==undefined ? record?.user_type.type_name : '-'} />

                                                                <span style={{marginLeft: "2%"}}>
                                                                    {
                                                                        recordData?.user_sub_types ? 
                                                                            recordData.user_sub_types.map((data:any,index:any) => {
                                                                                console.log('data : ', data);
                                                                                return <>
                                                                                    <span
                                                                                    style={{
                                                                                        fontSize: "0.8vw",
                                                                                        color: "#40495F",
                                                                                        marginTop: "10%",
                        
                                                                                        borderRadius: "50px", 
                                                                                        paddingLeft: "2%", 
                                                                                        paddingRight: "2%",
                                                                                        paddingBottom: "1%",
                                                                                        paddingTop: "1%",
                                                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                                                        background: "#FFFAF3"
                                                                                    }} 
                                                                                    >
                                                                                        {data? data.sub_type_name : <></> }
                                                                                    </span>
                                                                                </>
                                                                            })
                                                                        :
                                                                            <></>
                                                                    }
                                                                </span> 
                                                            </>       
                                                            :
                                                            <>
                                                                <TextField 
                                                                style={{
                                                                    fontSize: "0.8vw",
                                                                    color: "#fff",
                                                                    marginTop: "10%",

                                                                    borderRadius: "50px", 
                                                                    paddingLeft: "2%", 
                                                                    paddingRight: "2%",
                                                                    paddingBottom: "1%",
                                                                    paddingTop: "1%",
                                                                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                                    background: "#ACACAC"
                                                                }} 
                                                                    value={record?.user_type!==undefined ? record?.user_type.type_name : '-'}/>

                                                                <span style={{marginLeft: "2%"}}>
                                                                    {
                                                                        recordData?.user_sub_types ? 
                                                                            recordData.user_sub_types.map((data:any,index:any) => {
                                                                                console.log('data : ', data);
                                                                                return <>
                                                                                    <span
                                                                                    style={{
                                                                                        fontSize: "0.8vw",
                                                                                        color: "#40495F",
                                                                                        marginTop: "10%",
                        
                                                                                        borderRadius: "50px", 
                                                                                        paddingLeft: "2%", 
                                                                                        paddingRight: "2%",
                                                                                        paddingBottom: "1%",
                                                                                        paddingTop: "1%",
                                                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                                                        background: "#FFFAF3"
                                                                                    }} 
                                                                                    >
                                                                                        {data? data.sub_type_name : <></> }
                                                                                    </span>
                                                                                </>
                                                                            })
                                                                        :
                                                                            <></>
                                                                    }
                                                                </span>      
                                                            </>
                                                    }
                                                </span>

                                                {/* <span style={{marginLeft: "2%"}}>
                                                    {
                                                        record?.status == null ? <></>:
                                                        record?.status == "ACTIVE" ?
                                                            <span className="status_user_show"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={record?.status ? record?.status : '-'} /></span>
                                                        :
                                                            <span className="status_user_show_n"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={record?.status ? record?.status : '-'} /></span>
                                                    }
                                                </span> */}

                                                <span style={{marginLeft: "2%"}}>
                                                    {
                                                        record?.deleteStatus == null ? <></>:
                                                        record?.deleteStatus == "N" ?
                                                            <span className="status_user_show"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={"ACTIVE"} /></span>
                                                        :
                                                            <span className="status_user_show_n"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={"INACTIVE"} /></span>
                                                    }
                                                </span>

                                                <div style={{marginTop: "5%"}}>
                                                    <Text style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}>{t("user-profiles-show.nid")} :</Text><TextField style={{fontSize: "0.7vw",color: "#fff",marginRight: "4%"}} value={record?.identity_no ? record?.identity_no : '-'} />
                                                    <PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{fontSize: "0.7vw",color: "#fff",marginRight: "2%"}} value={record?.tel ? record?.tel : '-'}/>
                                                </div>
                                            </div>

                                            {/* <div>
                                                <Form.Item>
                                                    <span  style={{backgroundColor: "rgb(64, 73, 95)",padding: "1% 3% 1%",borderRadius: "10px",boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"}}>
                                                        <Text style={{fontSize: "1vw",color: "#fff"}}>{t("user-profiles-show.tel")} : </Text>
                                                        <TextField value={record?.tel ? record?.tel : '-'} style={{fontSize: "1vw",color: "#fff"}} />
                                                    </span>
                                                </Form.Item>
                                            </div> */}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        
                            <Col span={11} className="gutter-row">
                                <Card id="userprofile_top" style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>

                                    <div style={{fontSize: "0.8vw"}}>
                                        {/* <div style={{marginBottom: "3%"}}>
                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={12}>
                                                    <Text>{t("user-profiles-show.nid")} : </Text><TextField value={record?.identity_no ? record?.identity_no : '-'} />
                                                </Col>

                                                <Col className="gutter-row" span={12}>
                                                    <Text>{t("user-profiles-show.nickname")} : </Text><TextField value={record?.nick_name ? record?.nick_name : '-'} />
                                                </Col>
                                            </Row>
                                        </div> */}

                                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                <Col className="gutter-row" span={9} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                                    <MailOutlined style={{fontSize: "0.7vw", marginRight: "5%",background: "#40495F",padding: "3% 3% 3%",borderRadius: "30px",color: "#fff"}} /><TextField style={{fontSize: "0.7vw"}} value={record?.user ? record?.user.email : '-'} />
                                                </Col>

                                                <Col className="gutter-row" span={6}>
                                                    <Text style={{fontSize: "0.7vw", marginRight: "5%",background: "#40495F",padding: "2.5% 6% 2.5%",borderRadius: "30px",color: "#fff"}} >{t("user-profiles-show.gender")} : </Text><TextField style={{fontSize: "0.7vw"}} value={record?.gender ? record?.gender == "M" ? "Male" : 'Female' : '-'} />
                                                </Col>

                                                <Col className="gutter-row" span={4}>
                                                    <TeamOutlined style={{fontSize: "0.7vw", marginRight: "5%",background: "#40495F",padding: "6% 6% 6%",borderRadius: "30px",color: "#fff"}} /><TextField style={{fontSize: "0.7vw"}} value={record?.nationality ? record?.nationality.name : '-'} />
                                                </Col>

                                                <Col className="gutter-row" span={5}>
                                                    <CalendarOutlined style={{fontSize: "0.7vw", marginRight: "5%",background: "#40495F",padding: "6% 6% 6%",borderRadius: "30px",color: "#fff"}} /><TextField style={{fontSize: "0.7vw"}} value={record ? record?.birthday: '-'} />
                                                </Col>
                                            </Row>
                                    </div>
                                </Card>

                                        
                                        

                                <Row gutter={[16, 24]}>
                                    <Col className="gutter-row" span={12}>
                                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "1%"}}>
                                            <div style={{marginBottom: "3%"}}><span style={{borderRadius: "50px", 
                                                        paddingLeft: "2.5%", 
                                                        paddingRight: "2.5%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "#ACACAC",
                                                        color: "#fff",
                                                        fontSize: "0.7vw"}}>
                                                {t("user-profiles-show.address1")}</span>
                                            </div>
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address1_addr_no ? record?.address1_addr_no : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address1_soi ? record?.address1_soi : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address1_road ? record?.address1_road : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address1_amphure ? record?.address1_amphure.name_th : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address1_provice ? record?.address1_provice.name_th : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address1_district ? record?.address1_district.name_th : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address1_district ? record?.address1_district.zip_code : <></>} />
                                        </Card>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "1%"}}>
                                            <div style={{marginBottom: "3%"}}><span style={{borderRadius: "50px", 
                                                        paddingLeft: "2.5%", 
                                                        paddingRight: "2.5%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "#ACACAC",
                                                        color: "#fff",
                                                        fontSize: "0.7vw"}}>
                                                {t("user-profiles-show.address2")}</span>
                                            </div>
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address2_addr_no ? record?.address2_addr_no : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address2_soi ? record?.address2_soi : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address2_road ? record?.address2_road : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address2_amphure ? record?.address2_amphure.name_th : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address2_provice ? record?.address2_provice.name_th : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address2_district ? record?.address2_district.name_th : <></>} />
                                            <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.address2_district ? record?.address2_district.zip_code : <></>} />
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                    </Row>
                {/* </Card> */}

                <Row 
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginTop: "1%"}}
                >
                    {/* <Col className="gutter-row" span={12}>
                        <Card style={{ height: "auto",borderRadius: "10px",border: "1px soLid #f0f0f0",background: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel">{t("project-member.title")}</span></div>
                            <Divider />
                            
                            {
                                vDataHasUnit.length !== 0 ? 
                                    <>
                                    <Collapse style={{marginTop: 10}}>
                                        {
                                            vDataHasUnit.length !== 0 ?
                                                vDataHasUnit?.map((el:any,index:any) => {
                                                    {
                                                        console.log(el);
                                                    }
                                                    return <>
                                                        <Panel 
                                                            header={
                                                                <>
                                                                    <TextField value={(index+1)+" ) " + el.unit ? el.unit.unit_name : '-'} />
                                                                </>
                                                            }
                                                            key={(index+1)}
                                                        >
                                                            <p>Unit code : {el.unit.unit_code}</p>
                                                        </Panel>
                                                    </>
                                                })
                                            :
                                                <></>
                                        }
                                    </Collapse>
                                    </>
                                :
                                <>
                                <div>NO DATA</div>
                                </> }
                        </Card>
                    </Col> */}
                    
                    {/* <Col className="gutter-row" span={12}>
                        <Card style={{ height: "350px",borderRadius: "10px",border: "1px soLid #f0f0f0",background: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>

                            <div id="block_container">
                                <div id="block1" className="content_title">Emergency Contact</div>
                                <div id="block2"><button type="button">Edit</button></div>
                            </div>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <div className="content">
                                        <div>Primary</div>
                                        <div>Name</div>
                                        <div>Relationship</div>
                                        <div>Phone</div>
                                    </div>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <span>-</span>
                                </Col>
                            </Row>

                            <Divider plain></Divider>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <div className="content">
                                        <div>Secondary</div>
                                        <div>Name</div>
                                        <div>Relationship</div>
                                        <div>Phone</div>
                                    </div>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <span>-</span>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>

                {/* <Row 
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginTop: "2%"}}
                >
                    <Col className="gutter-row" span={12}>
                        <Card style={{ height: "250px" ,borderRadius: "10px",border: "1px soLid #f0f0f0",background: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>

                            <div id="block_container">
                                <div id="block1" className="content_title">Bank Information</div>
                                <div id="block2"><button type="button">Edit</button></div>
                            </div>
                            
                            <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <div className="content">
                                        <div>Bank name</div>
                                        <div>Bank account No.</div>
                                        <div>IFSC Code</div>
                                        <div>PAN No</div>
                                    </div>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <span>-</span>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    
                    <Col className="gutter-row" span={12}>
                        <Card style={{ height: "250px",borderRadius: "10px",border: "1px soLid #f0f0f0",background: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>

                        <div id="block_container">
                                <div id="block1" className="content_title">Family Informations</div>
                                <div id="block2"><button type="button">Edit</button></div>
                        </div>

                            <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <div className="content">
                                    </div>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <span>-</span>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Row 
                gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                style={{ marginTop: "2%"}}
                >
                    <Col className="gutter-row" span={12}>
                        <Card style={{ height: "250px" ,borderRadius: "10px",border: "1px soLid #f0f0f0",background: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>

                            <div id="block_container">
                                <div id="block1" className="content_title">Education Informations</div>
                                <div id="block2"><button type="button">Edit</button></div>
                            </div>

                            <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <div className="content">
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    
                    <Col className="gutter-row" span={12}>
                        <Card style={{ height: "250px",borderRadius: "10px",border: "1px soLid #f0f0f0",background: "#fff"}}>

                            <div id="block_container">
                                <div id="block1" className="content_title">Experience</div>
                                <div id="block2"><button type="button">Edit</button></div>
                            </div>

                            <Row  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <div className="content">
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row> */}

            {/* </Form> */}
        </Show>
    );
};