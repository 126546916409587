/* eslint-disable */
import { Card, Col, Edit, Form, Input, Row, useForm,Divider, Select, useSelect, List, Breadcrumb, useSimpleList, InputNumber } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useShow, useTranslate} from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IProperties, IUnit, IUnitType } from "interfaces";
// import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";

// import { PlusOutlined } from '@ant-design/icons';
// import type { RcFile, UploadProps } from 'antd/es/upload';
// import type { UploadFile } from 'antd/es/upload/interface';
import { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

// import Moment from 'moment';

export const UnitEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const [vProp, setProp] = useState<any>();
    const [vUnitType, setUnitType] = useState<any>();
    const [vUnitValue, setUnitValue] = useState<any>();

    const { form,formProps, saveButtonProps } = useForm<IUnit>({redirect:"list"});

    const { 
        queryResult 
    } = useShow<IUnit>({
        metaData: {
            populate: '*'
        }, 
    });

    const { data } = queryResult;
    let recordData:any = []
    recordData = data?.data? data?.data : [];

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "units",
        metaData: {
            populate: [
                '*',
                'project',
                'property',
                'unit_type',
                'createBy',
                'updateBy',
                'unitBathroom',
                'unitBedroom',
                'unitParking',
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            setUnitValue(String(data.data[0].unit_value))
            callFetchData();

          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        setProp(recordData.property ? recordData.property.id : null);
        setUnitType(recordData.unit_type ? recordData.unit_type.id : null);

        form.setFieldsValue({
            project:
                recordData?.project ?
                    recordData.project.project_name
                : null,
            property:
                recordData?.property ?
                    recordData.property.property_name
                : null,
            unit_type:
                recordData?.unit_type ?
                    recordData.unit_type.name
                : null,
        })
    }
    
    // useEffect(() => {
    //     if (count < 3) {
    //       const interval = setInterval(() => {
    //         setCount(prev => prev + 1)
    //         setProp(recordData.property ? recordData.property.id : null);
    //         setUnitType(recordData.unit_type ? recordData.unit_type.id : null);

    //         form.setFieldsValue({
    //             project:
    //                 recordData?.project ?
    //                     recordData.project.project_name
    //                 : null,
    //             property:
    //                 recordData?.property ?
    //                     recordData.property.property_name
    //                 : null,
    //             unit_type:
    //                 recordData?.unit_type ?
    //                     recordData.unit_type.name
    //                 : null,
    //         })
    //       }, 1000)
    //       return () => clearInterval(interval)
    //     }
    // }, [count])

    const { 
        selectProps: SelPropertyType 
    } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       : 'N',
            },
        ]
    });

    const { 
        selectProps: SelUnitType 
    } = useSelect<IUnitType>({
        resource: "unit-types",
        optionLabel: "name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       : 'N',
            },
            {
                field       : 'property][id]',
                operator    : 'eq',
                value       : vProp
            }
        ]
    });

    const { mutate: CreateLog }       = useCreate<IEventlog>();
    const log_endpoint                = "even-logs";

    function updateUserUpdatedBy(){
        CreateLog({
            resource : log_endpoint,
            values : {
                user_profile : user_data.user_profile.id,
                log_time_stamp : Date.now(), 
                log_event : "Edit",
                menu_event : 'unit',
                SysPkID : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }
    // console.log('vUnitValue', vUnitValue)

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("unit-edit.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("unit.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><span className="last_breadcrumb">{t("unit-edit.title")}</span></Breadcrumb.Item>
                                </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    updateUserUpdatedBy()
                    values.updateBy = user_data.user_profile.id
                    values.property = vProp
                    values.unit_type = vUnitType
                    values.unit_area = values.unit_area.toString()
                    values.unit_value = parseInt(vUnitValue.replace(/,/g, ''), 10);

                    // console.log('k', parseInt(vUnitValue))
                    // console.log('k', parseInt(vUnitValue.replace(/,/g, ''), 10))
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                       
                    );
                }}
            >
        <Row gutter={[16, 24]}>
            <Col id="Col_w" className="gutter-row" span={18}>
                <Card style={{ borderRadius: 5, marginBottom: "2%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                    <Row gutter={[16, 24]}>
                        {/* <Col id="Col_w" className="gutter-row" span={3}>
                            <Form.Item  label={t("unit.id")} name="unit_id"
                                // rules={[
                                //     {
                                //         required: true, message: 'Please enter unit id.'
                                //     },
                                // ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col> */}

                        <Col id="Col_w" className="gutter-row" span={3}>
                            <Form.Item  label={t("unit.code")} name="unit_code"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit code.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col id="Col_w" className="gutter-row" span={9}>
                            <Form.Item  label={t("unit.name")} name="unit_name"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit name.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col id="Col_w" className="gutter-row" span={6}>
                            <Form.Item  label={t("Unit Area")} name="unit_area"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit area size.'
                                    },
                                ]}
                            >
                                <InputNumber 
                                    style={{width:'100%'}} 
                                    min={0} 
                                    // onChange={(e) => setUnitArea(e.target.value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col id="Col_w" className="gutter-row" span={6}>
                            <Form.Item  label={t("unit.value")} name="unit_value"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit value.'
                                    },
                                ]}
                            >
                                <NumericFormat
                                    type="text"
                                    thousandSeparator=","
                                    maxLength={15}
                                    style={{border: "1px solid #d9d9d9",borderRadius: "5px",height: "33px",width: "100%"}}
                                    // onChange={value => {
                                    //     setUnitValue(value);
                                    // }}
                                    onChange={e => setUnitValue(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Row gutter={[16, 24]}>

                        <Col id="Col_w" className="gutter-row" span={6}>
                            <Form.Item  label={t("Unit Status")} name="unit_value"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit value.'
                                    },
                                ]}
                            >
                                <NumericFormat
                                    type="text"
                                    thousandSeparator=","
                                    maxLength={15}
                                    style={{border: "1px solid #d9d9d9",borderRadius: "5px",height: "33px",width: "100%"}}
                                    // onChange={value => {
                                    //     setUnitValue(value);
                                    // }}
                                    onChange={e => setUnitValue(e.target.value)}
                                />
                            </Form.Item>
                        </Col>

                        <Col id="Col_w" className="gutter-row" span={6}>
                            <Form.Item  label={t("Transfer Date")} name="unit_value"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit value.'
                                    },
                                ]}
                            >
                                <NumericFormat
                                    type="text"
                                    thousandSeparator=","
                                    maxLength={15}
                                    style={{border: "1px solid #d9d9d9",borderRadius: "5px",height: "33px",width: "100%"}}
                                    // onChange={value => {
                                    //     setUnitValue(value);
                                    // }}
                                    onChange={e => setUnitValue(e.target.value)}
                                />
                            </Form.Item>
                        </Col>

                    </Row> */}
                </Card>
            </Col>

            <Col id="Col_w" className="gutter-row" span={6}>
                <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                    <div style={{ marginBottom: 20 }}><span className="title_panel">{t("relation.txt")}</span></div>
                    <Divider></Divider>

                    {/* <Form.Item  label={t("projects.title")} name="project">
                        <Select placeholder={t("select.sel")} {...SelProjectType} value={vProject}
                            onChange={value => {
                                setProject(value);
                            }}
                        />
                    </Form.Item> */}

                    <Form.Item  label={t("property.title")} name="property" 
                       rules={[
                            {
                                required: true, message: 'Please select property.'
                            },
                        ]}
                    >
                        <Select placeholder={t("select.sel")} {...SelPropertyType}
                            onChange={value => {
                                setProp(value);
                            }}
                        />
                    </Form.Item> 

                    <Form.Item  label={t("unit-type.title")} name="unit_type"
                        rules={[
                            {
                                required: true, message: 'Please select unit type.'
                            },
                        ]}
                    >
                        <Select placeholder={t("select.sel")} {...SelUnitType}
                            onChange={value => {
                                setUnitType(value);
                            }}
                        />
                    </Form.Item>       
                </Card>
            </Col>
        </Row>
    </Form>
</Edit>
    );
};
