/* eslint-disable */
import {  Badge, Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row,  Select,  Space, Table, Tabs, Tag, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IProject, IProjectBrand, IProperties, IUnit, IUserhunit, IUserSubType } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

import { TOKEN_KEY } from "../../constants";
import axios from "axios";

// const { TabPane } = Tabs;
const { Option } = Select;

export const UserhunitList: React.FC<IResourceComponentsProps> = () => {

    const API_URL = useApiUrl();
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Project Members");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null;

    const [keepMemberof, setKeepMemberof] = useState<any>()
    // const [dataorder, setDataorder] = useState<any>()

    useEffect(() => {
        callAPI();
        // console.log('asdadsads')
    },[])

    let resultQuery1:any = []
    const [vdatavmu, setdatavmu] = useState<any>();

    async function callAPI(){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        resultQuery1 = await axios.get(API_URL + "/user-has-units/project_member", { headers: { Authorization: AuthStr } });
        const arrayID = resultQuery1.data.map((item:any) => {
            return item
        })
        setdatavmu(arrayID)
    }

    const [inputID, setinputID] = useState<any>();
    const [inputTEL, setinputTEL] = useState<any>();
    const [inputMAIL, setinputMAIL] = useState<any>();
    const [inputNAME, setinputNAME] = useState<any>();
    const [selID, setID] = useState<any>();
    const [selDevelop, setDevelop] = useState<any>("All");
    const [selProjectB, setProjectB] = useState<any>();
    const [selProjectN, setProjectN] = useState<any>();
    const [selUsersubT, setUsersubT] = useState<any>();
    const [selStatus, setStatus] = useState<any>();
    const [selunit, setunit] = useState<any>();


    const { tableProps,searchFormProps,tableQueryResult } = useTable<IUserhunit,HttpError,IUserhunit>({
        metaData: {
            populate: [
                'unit',
                'unit.property',
                'user_sub_type',
                'unit.property.project',
                'unit.property.project_brand',
                'user_profile',
                'user_profile.user',
                'requestBy',
                'contract',
                'poa',
                'createBy',
                'updateBy'
            ]
        },  

        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property, project_brand, develop, unit, } = params; 
            console.log(">>>> property", property)
            console.log(">>>> project_brand", project_brand)
            filters.push(
                {
                    field       : "unit][property][property_under]",
                    operator    : "eq",
                    value       : selDevelop == "All" ? ['Major','Non Major'] : selDevelop !== undefined ? selDevelop : "NDT"
                },

                {
                    field       : "unit][property][project_brand][brand_name]",
                    operator    : "contains",
                    value       : project_brand !== undefined ? project_brand : null,
                },

                {
                    field       : "unit][property][id]",
                    operator    : "eq",
                    value       : property !== undefined ? property : null,
                },

                {
                    field       : "unit][id]",
                    operator    : "eq",
                    value       : selunit !== undefined ? selunit : null,
                },

                {
                    field       : "user_sub_type][id]",
                    operator    : "eq",
                    value       : selUsersubT !== undefined ? selUsersubT : null,
                },
                
                {
                    field       : "deleteStatus",
                    operator    : "eq",
                    value       : selStatus !== undefined ? selStatus : "N"
                },

                {
                    field       : 'user_profile][identity_no]',
                    operator    : selID == "TID" && inputID !== undefined ? "contains" : "eq",
                    value       : selID == "TID" && inputID !== undefined ? inputID : null
                },

                {
                    field       : 'user_profile][identity_passport]',
                    operator    : selID == "PID" && inputID !== undefined ? "contains" : "eq",
                    value       : selID == "PID" && inputID !== undefined ? inputID : null
                },
                
                {
                    field       : "user_profile][tel]",
                    operator    : "contains",
                    value       : inputTEL !== undefined ? inputTEL : null
                },
                
                {
                    field       : "user_profile][user][email]",
                    operator    : "contains",
                    value       : inputMAIL !== undefined ? inputMAIL : null
                },

                {
                    field       : "user_profile][first_name]",
                    operator    : "contains",
                    value       : inputNAME !== undefined ? inputNAME : null
                },
            );
            return filters;
        },
        permanentSorter: [
            // {
            //     field: datafield,
            //     order: dataorder,
            // },
            {
                field: 'id',
                order: 'desc'
            },
        ],
        permanentFilter: [
            {
                field: "unit][property][id]",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ],
        initialFilter: [
            {
                field       : "user_profile][id]",
                operator    : "nin",
                value       : [null,undefined]
            },
            {
                field: "unit][property][id]",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ]
    });

    // console.log('tableQueryResult', tableQueryResult)

    const { selectProps: selectProjectB } = useSelect<IProjectBrand>({
        resource    : "project-brands",
        optionLabel : "brand_name",
        optionValue : "brand_name",
        fetchSize: 1000,
        filters: [
            {
                field: "active_status",
                operator: "eq",
                value: true
            }
        ]
    });

    const { selectProps: selectProperty } = useSelect<IProject>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });

    const [ PropOption, setPropOption ] = useState<any>(undefined);
    const [ count, setCount] = useState(0);

    useEffect(() => {
        if (count < 3) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                setPropOption(selectProperty?.options);
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])
    // console.log('selectProperty', selectProperty)

    const { selectProps: selectUnit } = useSelect<IUnit>({
        resource    : "units",
        optionLabel : "unit_name",
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "property][id]",
                operator: "eq",
                value: selProjectN
            }
        ]
    });

    // console.log('selProjectN', selProjectN)

    const { selectProps: selectUsersubT } = useSelect<IUserSubType>({
        resource    : "user-sub-types",
        optionLabel : "sub_type_name",
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "sub_type_name",
                operator: "in",
                // value: ["Pre Owner","Owner","Resident Owner","Renter","Resident Renter"]
                value: ["Owner-Contract","Owner","Resident Owner","Renter","Resident Renter"]
            }
        ]
    });

    const onReset = () => {
        setDevelop('All');
        setProjectB(undefined);
        setProjectN(undefined);
        setUsersubT(undefined);
        setStatus(undefined);

        setID(undefined);
        setinputID(undefined);
        setinputTEL(undefined);
        setinputMAIL(undefined);
        setinputNAME(undefined);
        setunit(undefined)

        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    // console.log(">>> tableProps",tableProps.dataSource)

    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IUserhunit>();
    const ed_point                          = "user-has-units";
    const [page, setPage]                   = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    // const { triggerExport, isLoading: exportLoading } = useExport<IUserhunit>({
    //     metaData: {
    //         populate: ['unit','unit.property','user_profile','user_sub_type','unit.property.project','unit.property.project_brand']
    //     },
    //     resourceName:"user-has-units",
    //     mapData: (item) => {
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id, 
    //                 unit_id : item.unit.unit_id,
    //                 unit_name : item.unit.unit_name,
    //                 property : item.unit.property.property_name,
    //                 member_name : item.user_profile.first_name + ' ' + item.user_profile.last_name,
    //             };
    //         }
    //     },
    // });

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'user-has-unit',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    // console.log('first')

    return <>
            <List 
                title={t("bc_project-member.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project-member.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("bc_project-member.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={24}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",display: "block"}}
                        >
                            <Row gutter={16}>
                                <Col className="gutter-row" span={3}>
                                    <Form.Item name="selDevelop" style={{marginBottom: "unset",width: "100%"}}>
                                        <Select 
                                            placeholder="Developer"
                                            size="middle"
                                            allowClear
                                            onClear={()=> setDevelop(undefined)}
                                            onChange={(e) => setDevelop(e)}
                                            defaultValue={"All"}
                                        >
                                            <Option value="All">All</Option>
                                            <Option value="Major">Major</Option>
                                            <Option value="Non Major">Non Major</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Form.Item name="project_brand" style={{marginBottom: "unset",width: "100%"}}>
                                        <Select 
                                            {...selectProjectB}
                                            placeholder="Project Brand"
                                            size="middle"
                                            allowClear
                                            // onClear={()=> setProjectB(undefined)}
                                            // onChange={(e) => setProjectB(e)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Form.Item name="property" style={{marginBottom: "unset",width: "100%"}}>
                                        <Select 
                                            placeholder={t("select.sel")} 
                                            onClear={()=> {setProjectN(undefined), searchFormProps.form?.resetFields()}}
                                            // onChange={(e) => setProjectN(e)}
                                            onSelect={(e:any) => setProjectN(e)}
                                            size="middle"
                                            allowClear
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                            filterSort={(optionA, optionB) =>
                                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                            }
                                            options={PropOption}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={3}>
                                    <Form.Item name="selUnit" style={{marginBottom: "unset",width: "100%"}}>
                                        <Select 
                                            {...selectUnit}
                                            placeholder="Unit name"
                                            size="middle"
                                            allowClear
                                            onClear={()=> setunit(undefined)}
                                            onChange={(e) => setunit(e)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Form.Item name="selUserST" style={{marginBottom: "unset",width: "100%"}}>
                                        <Select 
                                            {...selectUsersubT}
                                            placeholder="User Sub Type"
                                            size="middle"
                                            allowClear
                                            onClear={()=> setUsersubT(undefined)}
                                            onChange={(e) => setUsersubT(e)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Form.Item name="selStatus" style={{marginBottom: "unset",width: "100%"}}>
                                        <Select 
                                            placeholder="Status"
                                            size="middle"
                                            allowClear
                                            onClear={()=> setStatus(undefined)}
                                            onChange={(e) => setStatus(e)}
                                        >
                                            <Option value="N">Active</Option>
                                            <Option value="Y">In Active</Option>
                                        </Select>
                                    </Form.Item>
                                </Col> 
                            </Row>

                            <Row gutter={16} style={{marginTop: "1%"}}>
                                <Col className="gutter-row" span={6}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={10}>
                                            <Form.Item name="selID" style={{width: "100%"}}>
                                                <Select 
                                                    placeholder="ID" 
                                                    size="middle"  
                                                    allowClear
                                                    onClear={() => {
                                                        setID(undefined)
                                                    }}
                                                    onChange={(e) => setID(e)}
                                                >
                                                    <Option value="TID">THAI ID</Option>
                                                    <Option value="PID">PASSPORT</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={14}>
                                            <Form.Item name="inputID" style={{width: "100%"}}>
                                                <Input 
                                                    placeholder="Thai ID/Passport" 
                                                    value={inputID}
                                                    allowClear
                                                    onChange={e => {
                                                        if(e.target.value.length > 0){
                                                            setinputID(e.target.value)
                                                        }else{
                                                            setinputID(undefined)
                                                        }
                                                    }}
                                                    style={{borderRadius: 5}}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Form.Item name="inputTEL" style={{width: "100%"}}>
                                        <Input placeholder="Phone number"
                                            value={inputTEL} 
                                            onChange={e => {
                                                if(e.target.value.length > 0){
                                                    setinputTEL(e.target.value)
                                                }else{
                                                    setinputTEL(undefined)
                                                }
                                            }}
                                            allowClear
                                            style={{borderRadius: 5}}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Form.Item name="inputEMAIL" style={{width: "100%"}}>
                                        <Input placeholder="Email"
                                            value={inputMAIL} 
                                            onChange={e => {
                                               if(e.target.value.length > 0){
                                                setinputMAIL(e.target.value)
                                               }else{
                                                setinputMAIL(undefined)
                                               }
                                            }}
                                            allowClear
                                            style={{borderRadius: 5}}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={5}>
                                    <Form.Item name="inputNAME" style={{width: "100%"}}>
                                        <Input placeholder="First Name" 
                                            value={inputNAME} 
                                            onChange={e => {
                                                if(e.target.value.length > 0){
                                                    setinputNAME(e.target.value)
                                                }else{
                                                    setinputNAME(undefined)
                                                }
                                            }}
                                            allowClear
                                            style={{borderRadius: 5}}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={5}>
                                    <Row gutter={16}>
                                        {/* <Col className="gutter-row" span={12}>
                                            <Form.Item >
                                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                                    <Icons.SearchOutlined /> Search
                                                </Button>
                                            </Form.Item>
                                            
                                        </Col>
                                        <Col className="gutter-row" span={12}>
                                            <Form.Item >
                                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                                    <Icons.UndoOutlined /> Reset
                                                </Button>
                                            </Form.Item>
                                        </Col> */}


                                        <Col className="gutter-row" span={24}>
                                            <Row>
                                                <Form.Item >
                                                    <Button htmlType="submit" type="primary" style={{borderRadius: "5px", marginRight:"5%"}}>
                                                        <Icons.SearchOutlined /> Search
                                                    </Button>
                                                </Form.Item>
                                                <Form.Item>
                                                    <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                                        <Icons.UndoOutlined /> Reset
                                                    </Button>
                                                </Form.Item>
                                            </Row>
                                            
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        // total:tableProps?.dataSource?.length,  
                        total:tableQueryResult.data?.total,
                        onChange(Current){ setPage(Current)}
                    }}
                    scroll={{x: 1300}}
                >

                    {/* <Table.Column width={60} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    <Table.Column title={t("project-brand.Ltitle")} align="center" width={150} className="table_colmn_custom_left_value"
                    //   sorter={(a:any, b:any) => {
                    //     const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                    //     setDatafield("unit.property.project_brand.brand_name")
                    //     setDataorder(sorttest)
                    //     return (0)
                    //  }}
                        sorter={(a:any, b:any) => a?.unit?.property?.project_brand?.brand_name.localeCompare(b?.unit?.property?.project_brand?.brand_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                                return <>
                                        <TextField value={
                                            record?.unit !== null ? 
                                                record?.unit.property !== null ? 
                                                    record?.unit.property !== null ?  
                                                        record?.unit.property.project_brand !== null && record?.unit.property.project_brand !== undefined ?
                                                            record?.unit.property.project_brand.brand_name
                                                        :"ไม่ระบุ"
                                                    :<></>
                                                :<></> 
                                            :<></>
                                            }  
                                        />
                                    </>
                            }}
                    />
                    
                    <Table.Column title={t("property.Ltitle")} align="center" width={300} className="table_colmn_custom_left_value"
                        // sorter={(a:any, b:any) => {
                        // const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                        // setDatafield("unit.property.property_name")
                        // setDataorder(sorttest)
                        // return (0)
                        // }}
                        sorter={(a:any, b:any) => a?.unit?.property?.property_name.localeCompare(b?.unit?.property?.property_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                                return <>
                                        <TextField value={record?.unit? record?.unit.property.property_name :"-"}  />
                                    </>
                            }}
                    />

                    <Table.Column title="UNIT" align="center" width={150} className="table_colmn_custom_left_value"
                        // sorter={(a:any, b:any) => {
                        // const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                        // setDatafield("unit.unit_name")
                        // setDataorder(sorttest)
                        // return (0)
                        // }}
                        sorter={(a:any, b:any) => a?.unit?.unit_name.localeCompare(b?.unit?.unit_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.unit ? record?.unit.unit_name : '-'}  />
                            </>
                        }}
                    />

                    <Table.Column title="MEMBER OF UNIT" align="center" width={150}
                       sorter={(a:any, b:any) => {
                            // if (a?.memberOf < b?.memberOf) {return -1}
                            // if (a?.memberOf > b?.memberOf) {return 1}
                            return (1)
                        }}
                        render={(value:any,record:any)=>{
                            //console.log("firstVVVVV",value)
                            return <>
                                {
                                    record.unit !== null && record.unit !== undefined ?
                                        vdatavmu !== null && vdatavmu  !== undefined ?

                                            vdatavmu.length > 0 ?
                                                vdatavmu.map((item:any,index:any)=> {
                                                    //console.log("items",item)
                                                    return <>
                                                        {
                                                            record.unit.unit_name !== null && item.unit !== null && record.user_profile !== null && record.user_profile !== undefined ? 
                                                                record.unit.unit_name == item.unit ? 
                                                                    record.user_profile.first_name == item.first_name ?
                                                                        record.user_profile.last_name == item.last_name ?
                                                                            item.memberOf !== null ?
                                                                            <>{item.memberOf}</>
                                                                            :"ไม่ระบุ"
                                                                        :<></>
                                                                    :<></>
                                                                :<></>
                                                            :<></>
                                                        }
                                                    </>
                                                })
                                            :<></>
                                        :<></>
                                    :<>-</>
                                }
                            </>
                        }}
                    />

                    <Table.Column title="USER TYPE" align="center" width={200} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user_sub_type?.sub_type_name.localeCompare(b?.user_sub_type?.sub_type_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_sub_type ? record?.user_sub_type.sub_type_name : '-'}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="userhunit" title="USER ACTION" width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.actionBy !== null && record?.actionBy !== undefined ? record?.actionBy :"ไม่ระบุ"}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title="FIRST NAME" align="center" width={200} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user_profile?.first_name.localeCompare(b?.user_profile?.first_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile ? record?.user_profile.first_name : '-'}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST NAME" align="center" width={200} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user_profile?.last_name.localeCompare(b?.user_profile?.last_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile ? record?.user_profile.last_name : '-'}  />
                            </>
                        }}
                    />

                    <Table.Column title="USERNAME" align="center" width={200} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user_profile?.user?.username.localeCompare(b?.user_profile?.user?.username)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            // {console.log("recordfirst",record)}
                            return  <TextField value={record?.user_profile?.user ? record?.user_profile.user.username :"-"} />
                            // return  <TextField value={value.username} />
                        }}
                    />

                    {/* <Table.Column dataIndex="user_profile.user" title="EMAIL" width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile !== null ? 
                                    record?.user_profile.user !== null ?  
                                        record?.user_profile.user.username !== null ?   
                                            record?.user_profile.user.username
                                        :"ไม่ระบุ"
                                    :<></>
                                :"-"}/>
                            </>
                        }}
                    /> */}

                    <Table.Column title="TEL" align="center" width={200}
                        sorter={(a:any, b:any) => a?.user_profile?.tel.localeCompare(b?.user_profile?.tel)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile ? record?.user_profile.tel : 'ไม่ระบุ'}  />
                            </>
                        }}
                    />

                    <Table.Column title="STATUS" align="center" width={200}
                        sorter={(a:any, b:any) => a?.deleteStatus.localeCompare(b?.deleteStatus)}
                        sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.deleteStatus !== null && record?.deleteStatus !== undefined ?
                                    record?.deleteStatus == "N" ? "Active":"In Active":<></>}
                                />
                            </>
                        }}
                    />

                    <Table.Column title="OWNER CONFIRMED" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a.isOwnerConfirmed < b.isOwnerConfirmed) {return -1}
                            if (a.isOwnerConfirmed > b.isOwnerConfirmed) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.isOwnerConfirmed == true ? <Tag style={{borderRadius: "30px"}} color="green">Approved</Tag>:"-" && record?.isOwnerConfirmed == false ?  <Tag style={{borderRadius: "30px"}} color="red">Not Approved</Tag>:"-" && record?.isOwnerConfirmed == null ? <Tag style={{borderRadius: "30px"}} color="orange">Pending</Tag>:"-"}  />
                            </>
                        }}
                    />
                    
                    <Table.Column title="ADMIN CONFIRMED" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.isAdminConfirmed < b?.isAdminConfirmed) {return -1}
                            if (a?.isAdminConfirmed > b?.isAdminConfirmed) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.isAdminConfirmed == true ? <Tag style={{borderRadius: "30px"}} color="green">Approved</Tag> :"-" && record?.isAdminConfirmed == false? <Tag style={{borderRadius: "30px"}} color="red">Not Approved</Tag>:"-" && record?.isAdminConfirmed == null ? <Tag style={{borderRadius: "30px"}} color="orange">Pending</Tag>:"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="updateBy" title="UPDATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title="CREATE BY" align="center" width={110} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.createBy ? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={110}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={110} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={110}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column<IUserhunit>
                        width={100}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed="right"

                        render={(_text, record): React.ReactNode => {
                            return (
                                <>
                                    <Space>
                                        {
                                            record.deleteStatus === "N" ? 
                                                <>
                                                    <EditButton
                                                        hideText
                                                        size="small"
                                                        recordItemId={record.id}
                                                        disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                                    />
                                                    <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                                </>
                                            :
                                                <>
                                                    {/* <EditButton
                                                        hideText
                                                        size="small"
                                                        recordItemId={record.id}
                                                        disabled
                                                    /> */}
                                                    <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                                </>
                                        }
                                    </Space>
                                </>
                            )
                        }}
                    />
                </Table>
            </List>
        </>
}