
import React, { useState } from "react";

import { AntdLayout, AntdList, Button, Card, Col, Drawer, Grid, Icons, Image, Menu, Modal, Row, Tag, useDrawerForm, useMenu, useModalForm } from "@pankod/refine-antd";
import {
  CanAccess,
  ITreeMenu,
  useGetIdentity,
  // useTranslate,
  useLogout,
  useNavigation,
  useTitle,
  useTranslate
} from "@pankod/refine-core";
import { IProperties } from "interfaces";
import { VERSION } from "../../../constants";
import { ChatMain } from "./Chat/ChatMain";
import './style.less';
import { antLayoutSiderMobile, antLayoutSiderMobileHide } from "./styles";


let example_avatar: any = "https://camo.githubusercontent.com/748a24091d4874eb86ab14b728b085527a9b96872f566bc1987a04079e4f72d0/68747470733a2f2f6170692e7a616877617a65696e2e78797a2f75706c6f61642f69636f6e2e706e67"
let data_permission: any = null;

let recordData: any = undefined;
let keepFilter: any = undefined
export const Sider: React.FC = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const { mutate: logout } = useLogout();
  const translate = useTranslate();
  const Title = useTitle();
  const { SubMenu } = Menu;

  const { info: loginModal } = Modal;


  const { menuItems, selectedKey } = useMenu();
  const { push, replace } = useNavigation();
  const breakpoint = Grid.useBreakpoint();
  const isMobile = !breakpoint.lg;
  const { data: Identity_data } = useGetIdentity<any>();
  //console.log("Identity_data",Identity_data)
  //console.log('userQuery', userQuery?.data)
  const [count, setCount] = useState(0);
  const [select_chat_data, setselect_chat_data] = useState<any>();

  let ParentMenu = Identity_data?.parent_menu
  let ChildMenu = Identity_data
  console.log('====================================');
  console.log(Identity_data,'Identity_data');
  console.log('====================================');
  //console.log(">>> ParentMenu",ParentMenu)

  const ttt = (e: any) => {
    push(e)
  }

  const MENU_CUSTOM = (data: any, selectedKey: string) => {
    return data?.map((item: any) => {
      const { id, module_name, icon, child, link, parent } = item?.module_master;
    })
  }
  //////////////////////////////////////////////////////////////////////

  const renderTreeView = (tree: ITreeMenu[], selectedKey: string) => {

    if (!!ParentMenu && !!menuItems) {

      // console.log('tree : ', tree)
      const newMenu = menuItems.map((val) => ({ parentName: val.parentName, children: val.children, route: val.route, icon: val.icon, name: val.name, label: val.label }))
      const parent = ParentMenu
      const child = ChildMenu

      //  console.log('newMenu : ', newMenu)
      //  console.log('ParentMenu : ', parent)
      const customRole = child.role.custom_role_name;

      const menu = newMenu.map(val => {
        // console.log('new menu', val);
        let ch: any = []
        const fn = parent.filter((f: any) => f.name === val.name)
        // console.log('fn menu', fn);
        for (let index = 0; index < child.child_menu.length; index++) {
          // console.log('child menu', child.child_menu[index]);
          const aa = val.children.find((f: any) => f.name === child.child_menu[index].name)
          if (!!aa) {
            ch.push(aa)
          }
        }

        if (!!val.children && fn.length !== 0) {
          val.children = ch
          // console.log("ch",ch)
          return val
        }


      }).filter(f => f !== undefined)
      // console.log("menu",menu);
      return menu?.map((item: any) => {
        const { icon, label, route, name, children, parentName } = item;
        // console.log('0000 : ', name, children)
        // console.log(route);

        if (children.length > 0) {
          return (
            <SubMenu
              key={name}
              icon={icon ?? icon}
              title={label}
            // style={{
            //   width: '100%'
            // }}
            >
              {/* {renderTreeView(children, selectedKey)} */}
              {
                children.map((e: any, i: any) => {
                  return (
                    <CanAccess key={e.key} resource={name.toLowerCase()} action="list">
                      <Menu.Item
                        key={i}
                        style={{
                          width: '100%'
                        }}
                      >
                        {e.label}
                      </Menu.Item>
                    </CanAccess>
                  )
                })
              }
            </SubMenu>
          );
        }
        const isSelected = route === selectedKey;
        const isRoute = !(parentName !== undefined && children.length === 0);
        return (
          <CanAccess key={route} resource={name.toLowerCase()} action="list">
            <Menu.Item
              key={selectedKey}
              onClick={() => {
                // console.log('---------------------------------- : ', route)
                push(route ?? "");
              }}
              // style={{
              //   fontWeight: isSelected ? "bold" : "normal",
              // }}
              icon={icon ?? (isRoute && icon)}
            >
              {label}
              {!collapsed && isSelected && (
                <div className="ant-menu-tree-arrow" />
              )}

            </Menu.Item>
          </CanAccess>

        );
      });

      // {Identity_data && Identity_data?.user_type?.type_name === "Staff" && Identity_data?.role?.id === 29 &&
      //   <>
      //     {
      //       count > 0 ?
      //         <div className="dot_noti" style={{ position: 'fixed', bottom: 85, right: 8, zIndex: 100, overflow:'hidden'}} ></div>
      //       :
      //         <></>
      //     }

      //     {

      //       Identity_data?.prop_list?.length > 1 ? 
      //         <div id="float_btn_login" onClick={() => {
      //           // chatAPI();
      //           // setCount(0);
      //           loginModalShow();
      //         }}>
      //             <div style={{marginTop: "1.5%"}}>
      //               <Row gutter={16}>
      //                 <Col className="gutter-row" span={24}>
      //                   <Icons.LoginOutlined />
      //                 </Col>
      //               </Row>
      //             </div>
      //         </div>

      //       :
      //         <div id="float_btn" onClick={() => {

      //           chatAPI();
      //           setCount(0);
      //           // chatModalShow();
      //           // chatDrawerShow();
      //         }}>
      //             <div style={{marginTop: "1.5%"}}>
      //               <Row gutter={16}>
      //                 <Col className="gutter-row" span={24}>
      //                   <Icons.MessageOutlined />
      //                 </Col>
      //               </Row>
      //             </div>
      //         </div>

      //     }

      //   </>
      // }
    }
  };

  /////////////////////////////////////////////////////////////////////

  const {
    drawerProps: DrawerProps,
    formProps: Props,
    show: DrawerShow,
    close: DrawerClose
    // saveButtonProps
  } = useDrawerForm<any>({
    resource: "",
    metaData: {
      populate: '*',
    },
    action: "create",
    redirect: false,
    // queryOptions:{onSuccess(data){
    //     let dataLoad = data?.data;

    //     console.log(">>> dataLoad",dataLoad)

    //     // setCustomname(dataLoad?.custom_role_name)
    // }}
  });

  const {
    drawerProps: chatFromProps,
    show: chatDrawerShow,
  } = useDrawerForm<any>({
    action: "create",
    redirect: false
  });

  const {
    modalProps: loginModalProps,
    formProps: loginModalFormProps,
    show: loginModalShow,
    close: loginModalClose,
  } = useModalForm<any>({
    resource: "appointments",
    metaData: {
      populate: '*',
    },
    action: "create",
    redirect: false,
  });

  // {console.log(">>> userQuery",userQuery?.data?.user_profile?.id)}


  async function chatAPI() {
    // const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    // let resultQuery = await axios.get(`${API_URL}/like-systems/rocketChatServiceStaff?user_id=${userQuery?.data?.user_profile?.id}&property_id=${Identity_data?.property?.id}` , { headers: { Authorization: AuthStr } });
    // console.log(">>> resultQuery?.data",resultQuery)
    // // console.log('resultQuery?.data.status', resultQuery?.data.status)
    // if(resultQuery?.data?.status == "200" ){
    //   // chatModalShow();
    //   setmember_chat(resultQuery?.data);
    // // if(resultQuery?.data?.code == "200" || resultQuery?.status == 200 ){
    //   chatDrawerShow();
    // }
    chatDrawerShow();
    // setmember_chat(resultQuery?.data);
  }

  const getMessageFromFrame = (data: any) => {
    console.log('getMessageFromFrame data', data)
    setCount(data);
    if (data === 1) {
      var audio = new Audio('https://lscmain.s3.ap-southeast-1.amazonaws.com/notification_3e7bf30630.wav');
      audio.autoplay = true;
      audio.play();
      console.log('audio play')
    }

    // console.log('count', data);
  }

  const selectprop = localStorage.getItem("SELECT_CHAT_PROP");
  const [selectedCards, setSelectedCards] = useState<any>(selectprop);

  // console.log(">>> selectprop",selectprop)

  const onselectProp = (id: any, oldselect: any) => {
    const el = document.getElementById(id);
    console.log(">>> id", id)
    console.log(">>> oldselect", oldselect)
    console.log(">>> el", el)
    if (el !== null) {
      if (selectedCards) {
        const oldid = document.getElementById(oldselect);
        if (oldid?.classList.contains("card-custom-selected")) {
          oldid?.classList.remove("card-custom-selected");
        }

        el.classList.add("card-custom-selected");
        setSelectedCards(id);
      } else {
        el.classList.add("card-custom-selected");
        setSelectedCards(id);
      }
    }
  };

  const renderItem = (item: IProperties) => {
    const {
      id,
      logo,
      property_name
    }: any = item;

    return (
      <AntdList.Item key={id}>
        <AntdList.Item onClick={() => onselectProp(id, selectedCards)}>
          <Card
            id={id}
            style={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
            bordered={true}
            hoverable={true}
            className={`card_custom_andlist ${selectprop && selectprop == id ? "card-custom-selected" : ""}`}
            cover={
              <div
                style={{
                  borderRadius: "10px 10px 0 0",
                  backgroundImage: `url("${logo?.url}")`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: "center",
                  height: "150px"
                }}
              />
            }
          >
            <div style={{ width: "98%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}><span>{property_name}</span></div>
          </Card>
        </AntdList.Item>
      </AntdList.Item>
    );
  };

  if (!!Identity_data) {
    keepFilter = Identity_data?.parent_menu.filter((e: any) => e?.name == "chat")
    //console.log("keepFilter",keepFilter)
  }


  return (<>
    <AntdLayout.Sider
      // trigger= {null}
      collapsible
      collapsedWidth={isMobile ? 0 : 80}
      collapsed={collapsed}
      breakpoint="lg"
      onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
      style={isMobile ? antLayoutSiderMobile : antLayoutSiderMobileHide}>
      <div className={"sider_custom"}>
        <div id="logo_sider">{Title && <Title collapsed={collapsed} />}</div>
        {/* <div className="sider_custom"> testtttt</div> */}
        <Menu
          selectedKeys={[selectedKey]}
          mode="vertical"
          theme="light"
          onClick={({ key }) => {
            if (key === "logout") {
              logout();
              return;
            }

            if (!breakpoint.lg) {
              setCollapsed(true);
            }
            push(key as string);
          }}
        >
          {renderTreeView(menuItems, selectedKey)}
          {/* {MENU_CUSTOM(data_permission,selectedKey)} */}

          <Menu.Item key="logout" icon={<Icons.LogoutOutlined />}>
            {translate("buttons.logout", "Logout")}
          </Menu.Item>

          <div style={{ marginTop: "2rem", textAlign: "center", marginBottom: "3rem" }}>
            <Tag color="#00FF32" style={{
              borderRadius: "10px", boxShadow: "rgba(0, 255, 50, 0.6) 0px 0px 40px", width: "60%", color: "#1D304C",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}>v.{VERSION}</Tag>
            {/* <Tag className="animated-tag" color="transparent">
                v.{VERSION}
              </Tag> */}
          </div>
        </Menu>
      </div>
    </AntdLayout.Sider>

    {/* {Identity_data && Identity_data?.user_type?.attributes?.type_name === "Staff" && member_chat?.code == "200" && */}
    {/* {Identity_data && Identity_data?.user_type?.attributes?.type_name === "Staff" && userQuery?.data && */}
    {!!Identity_data && keepFilter.length > 0 ? // role 29 == juristic
      <>
        {
          count > 0 ?
            // <Badge count={1} style={{ position: 'fixed', bottom: 85, right: 8, zIndex: 100, overflow:'hidden'}} />
            // <div className="dot" style={{ position: 'fixed', bottom: 85, right: 8, zIndex: 100, overflow:'hidden'}} ></div>
            <div className="dot_noti" style={{ position: 'fixed', bottom: 85, right: 8, zIndex: 100, overflow: 'hidden' }} ></div>
            :
            <></>
        }

        {

          Identity_data?.prop_list?.length > 1 ?
            <div id="float_btn_login" onClick={() => {
              // chatAPI();
              // setCount(0);
              loginModalShow();
            }}>
              <div style={{ marginTop: "1.5%" }}>
                <Row gutter={16}>
                  <Col className="gutter-row" span={24}>
                    <Icons.LoginOutlined />
                  </Col>
                </Row>
              </div>
            </div>

            :
            <div id="float_btn" onClick={() => {

              chatAPI();
              setCount(0);
              // chatModalShow();
              // chatDrawerShow();
            }}>
              <div style={{ marginTop: "1.5%" }}>
                <Row gutter={16}>
                  <Col className="gutter-row" span={24}>
                    <Icons.MessageOutlined />
                  </Col>
                </Row>
              </div>
            </div>

        }
      </>
      : ""
    }


    <Drawer
      {...DrawerProps}
      title={<Tag color="#108ee9">User Info</Tag>}
      width={300}
      maskClosable={false}
      onClose={() => {
        recordData = undefined;
        DrawerClose();
      }}
      getContainer={false}
      placement="right"
    >
      <Row>
        <Col span={24}>
          <Image src={select_chat_data?.profile?.avatar ? select_chat_data?.profile?.avatar : example_avatar} width={250} height={250} style={{ borderRadius: "10px" }} />
          {/* <Avatar src={select_chat_data?.profile?.avatar ? select_chat_data?.profile?.avatar : example_avatar}/> */}
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ border: "1px solid #dedede", borderRadius: "10px", marginTop: "1rem", padding: "1rem" }}>
        <Col span={24}>
          <Tag color="#2db7f5">User Type</Tag><span>: {select_chat_data?.profile?.user_type ? select_chat_data?.profile?.user_type : "ไม่ระบุ"}</span>
        </Col>
        <Col span={24}>
          <Tag color="#2db7f5">First Name</Tag><span>: {select_chat_data?.profile?.first_name ? select_chat_data?.profile?.first_name : "ไม่ระบุ"}</span>
        </Col>
        <Col span={24}>
          <Tag color="#2db7f5">Last Name</Tag><span>: {select_chat_data?.profile?.last_name ? select_chat_data?.profile?.last_name : "ไม่ระบุ"}</span>
        </Col>
        <Col span={24}>
          <Tag color="#2db7f5">Email</Tag><span>: {select_chat_data?.profile?.email ? select_chat_data?.profile?.email : "ไม่ระบุ"}</span>
        </Col>
        <Col span={24}>
          <Tag color="#2db7f5">Tel.</Tag><span>: {select_chat_data?.profile?.tel_no ? select_chat_data?.profile?.tel_no : "ไม่ระบุ"}</span>
        </Col>
        <Col span={24}>
          <Tag color="#2db7f5">In Project</Tag><span>: {select_chat_data?.profile?.project_name ? select_chat_data?.profile?.project_name : "ไม่ระบุ"}</span>
        </Col>
        <Col span={24}>
          <Tag color="#2db7f5">In Unit</Tag><span>: {select_chat_data?.profile?.unit ? select_chat_data?.profile?.unit : "ไม่ระบุ"}</span>
        </Col>
      </Row>
    </Drawer>

    <Drawer
      {...chatFromProps}
      width={1150}
      className="draw_cus"
      title="Chat"
      style={{ zIndex: 999999 }}
    >
      {/* {Identity_data && Identity_data?.user_type?.attributes?.type_name === "Staff" && */}
      {Identity_data && Identity_data?.user_type?.type_name === "Staff" && Identity_data?.role?.id === 29 &&
        // <ChatMain c_user="niti@test.com" pass="xChat@123456789"/> 
        // <ChatMain getMessageFromFrame={getMessageFromFrame} c_user={Identity_data?.chat_username ? Identity_data?.chat_username : "niti@test.com"} pass={Identity_data?.chat_username ? "xChat@123456789" : "123456789"}/>
        <ChatMain getMessageFromFrame={getMessageFromFrame} c_user={Identity_data && Identity_data?.chat_username} pass={"xChat@123456789"} />
        // <ChatMain getMessageFromFrame={getMessageFromFrame} c_user={"juristic@metrisrama9ramkumhang.com"} pass={"xChat@123456789"}/>
      }
    </Drawer>

    <Modal
      {...loginModalProps}
      title="Select Chat Property"
      okText={"Select"}
      maskClosable={false}
      width={700}
      onOk={() => {
        loginModalClose();
      }}
      destroyOnClose={true}
      afterClose={() => {
        setSelectedCards(selectprop)
      }}
      footer={
        <div>
          <Button onClick={() => loginModalClose()}>{"Cancle"}</Button>
          <Button
            type="primary"
            onClick={() => {
              console.log("X")
              localStorage.setItem("SELECT_CHAT_PROP", selectedCards)
              loginModalClose()
            }}
          >{"Select"}</Button>
        </div>
      }
    >
      <AntdList
        rowKey={"id"}
        grid={{ gutter: 12, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3 }}
        dataSource={Identity_data?.prop_list}
        renderItem={renderItem}
        style={{ position: "relative" }}
        pagination={Identity_data?.prop_list?.length > 12 ? {
          pageSize: 12,
        } : false}
      />
    </Modal>
  </>
  );

};