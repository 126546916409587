/* eslint-disable */
import { Breadcrumb, Button, Card, Col, Create, DatePicker, Form, Icons, ImportButton, Input, InputNumber, List, Modal, Radio, RadioChangeEvent, Row, useForm, useImport, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useNavigation, useShow, useTranslate  } from "@pankod/refine-core";
import { ICouponGroup, IEventlog } from "interfaces";
import { useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import { Dayjs } from "dayjs";
let coupon_data:any = []
export const CouponGroupCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: createCoupon } = useCreate<any>();
    // const log_endpoint          = "even-logs";
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const [vStartDate, setStartDate] = useState<any>(undefined);
    const [vExpireDate, setExpireDate] = useState<any>(undefined);
    const [vArrCoupon, setArrCoupon] = useState<any>([]);
    const [vStatUpload, setStatUpload] = useState<any>(false);
    const [vTotal, setTotal] = useState<any>(0);
    const [disabled, setdisabled] = useState<boolean>(true);
    // const { replace } = useNavigation();

    const { 
        form, 
        formProps, 
        saveButtonProps 
    } = useForm<ICouponGroup,HttpError,{}>({
            async onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];

                // for(let i=0; i<= vArrCoupon.length; i++){
                for(let i=0; i<= coupon_data.length; i++){
                    createCoupon({
                        resource    : 'coupons',
                        values      : {
                            // coupon_code     : vArrCoupon[i].coupon_code,
                            // coupon_name     : vArrCoupon[i].coupon_name, 
                            // coupon_active   : vArrCoupon[i].coupon_active,
                            coupon_code     : coupon_data[i].coupon_code,
                            coupon_name     : coupon_data[i].coupon_name, 
                            coupon_active   : coupon_data[i].coupon_active,
                            coupon_group    : recordData.data.id + "",
                            createBy        : user_data.user_profile.id
                        },
                        successNotification : false
                    });
                    // await sleep(2000);
                }

                // setTimeout(() => {
                //     console.log('wait redirect')
                //     replace("");
                // }, 4000);
            },
        
        }
    );

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const importProps = useImport<any>({
        mapData: async (item) => {
            // let coupon_name:any;
            // let coupon_code:any;
            let coupon_active:any;

            if(item.coupon_active !== ""){
                coupon_active = item.coupon_active.split("-");
            }

            if(item.coupon_name === ""){
                console.log('coupon_name data is empty')
            }else if(item.coupon_code === ""){
                console.log('coupon_code data is empty')
            }else{
                coupon_data.push(
                    {
                        coupon_name : item.coupon_name,
                        coupon_code : item.coupon_code,
                        coupon_active : coupon_active[0] == "1" ? true : false,
                        coupon_group : null
                    }
                );
                setArrCoupon(coupon_data)
                vArrCoupon.push(vArrCoupon)
            }
            await sleep(200);
        },
        onProgress(onProgressParams) {
            setTotal(onProgressParams.totalAmount);
            formProps?.form?.setFieldsValue({quantity: onProgressParams.totalAmount});
        },
        onFinish(results) {
            setStatUpload(true);
            setdisabled(false);
        },
        resourceName: 'coupons'
    });

    console.log('coupon_data', coupon_data)

    const warning = () => {
        Modal.warning({
          title: <span>Warning</span>,
          content: <span>Please import coupon file</span>,
        });
    };

    return (
        <Create saveButtonProps={{hidden: true}} pageHeaderProps={{extra: <></>}}>
            <List 
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("coupon-group.t-group-name")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form 
                {...formProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    values.group_name = values?.group_name;
                    values.quantity = values?.quantity;
                    values.shortDetail = values?.shortDetail;

                    if(values?.couponType == 1){
                        values.couponType = "FIXED"
                    }else if(values?.couponType == 2){
                        values.couponType = "RUNNING"
                    }else if(values?.couponType == 3){
                        values.couponType = "THIRDPARTY"
                    }else{
                        values.couponType = null
                    }

                    values.start_date = vStartDate
                    values.expire_date = vExpireDate

                    values.deleteStatus = "N"
                    values.createBy = user_data.user_profile.id

                    if(vStatUpload){
                        return (
                            setTimeout(() => {
                                formProps?.onFinish &&
                                formProps?.onFinish(values)
                            }, 2000)
                        );
                    }else if(vStatUpload == false){
                        warning();
                    }
                }}
            >
                <Row gutter={8}>
                    {disabled == true &&
                        <Col span={24} className="gutter-row">
                            <Form.Item>
                                <ImportButton
                                    {...importProps}
                                />
                            </Form.Item>
                        </Col>
                    }

                    <Col span={18} className="gutter-row">
                        <Form.Item  
                            label={t("coupon-group.f-group-name")}
                            name="group_name"
                            rules={[
                                {
                                    required: true, message: 'Please enter coupon group name.'
                                },
                            ]}
                        >
                            <Input disabled={disabled}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item  
                            label="Quantity"
                            name="quantity"
                            className={disabled == false ? "input_disable_onshow" : ""}
                        >
                            <InputNumber 
                                defaultValue={vTotal}
                                placeholder="จำนวนสิทธิ"
                                min={0}
                                style={{width: "100%"}}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label="Short Detail"
                            name="shortDetail"
                        >
                            <Input disabled={disabled}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Start Date"
                            name="start_date"
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setStartDate(dateString)}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Expire Date"
                            name="expire_date"
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setExpireDate(dateString)}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item label="Coupon Type" name="couponType">
                            {/* <Radio.Group options={optRadioCouponType} onChange={onChangeCouponType} value={vCouponType} />   */}
                            <Radio.Group disabled={disabled}>
                                <Radio value={1}>{t("coupon.ra-fixed")}</Radio>
                                <Radio value={2}>{t("coupon.ra-running")}</Radio>
                                <Radio value={3}>{t("coupon.ra-third")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button disabled={disabled} type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.create")}</Button>
                </Row>
            </Form>
        </Create>
    );
};