import { Card, Checkbox, Col, Divider, Form, Input, InputNumber, Radio, Row, Select, SelectProps, Space } from "@pankod/refine-antd";

import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
// import en from 'world_countries_lists/data/countries/en/world.json';
import { useTranslate } from "@pankod/refine-core";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

interface IPANEL {
//     setTel: Dispatch<SetStateAction<CountryPhoneInputValue | undefined>>
    // setStatus: Dispatch<any>
//     SelCompany: SelectProps;
//     onchangeCompany: (value: any) => void
//     onchangeDepartment: (value: any) => void
//     onchangeRole: (value: any) => void
//     SelDepartment: SelectProps;
//     PositionData: any
    // onChangePosition: (value: any) => void
//     setPosition: any
//     SelRole: SelectProps;
    onChangeCheckCondition: any
    vCondition: any
    vIsTypeCoupon: any
    setUserConditionAmount: any
    PrivUserSubType: SelectProps
    onChangeRadio4: (value: any) => void
    vRaRightCondition : any
    onChangeOnlyRightMulti: (value: any) => void
    onChangeOnlyRight: (value: any) => void
    setUserConditionName: any
    unitTypeData: any
    vPropertyID: any
    chkRoomType : (id:any, type_name:any, amount:any) => void
    chkPriceType : (id:any, type_name:any, amount:any) => void

    vRaPrivCoupon : any
    vCouponLeft : any

    vInputCouponAmount: any

    valueMax: any
    vSitUnitType: any
    dataAmountPlace: any
    vSitPriceType: any
}

export const PNCONDITION: React.FC<IPANEL> = (
    {
        // setTel, 
        // setStatus, 
        // SelCompany, 
        // onchangeCompany, 
        // SelDepartment, 
        // PositionData, 
        // onChangePosition, 
        // SelRole,
        // onchangeDepartment, 
        // onchangeRole
        onChangeCheckCondition,
        vCondition,
        vIsTypeCoupon,
        setUserConditionAmount,
        PrivUserSubType,
        onChangeRadio4,
        vRaRightCondition,
        onChangeOnlyRightMulti,
        onChangeOnlyRight,
        setUserConditionName,
        unitTypeData,
        vPropertyID,
        chkRoomType,
        chkPriceType,
        vRaPrivCoupon,
        vCouponLeft,
        vInputCouponAmount,
        valueMax,
        vSitUnitType,
        dataAmountPlace,
        vSitPriceType,
    }) => {

        const t = useTranslate();

        const [keepE, setKeepE] = useState<any>()

        function handlechange(e: any){
            let val: any = Math?.max(Number(0), Math?.min(Number(vInputCouponAmount), Number(e)));
            setUserConditionAmount(val)

            if (e > vInputCouponAmount) {
                setKeepE(vInputCouponAmount)
            }else{
                setKeepE(e)
            }
        }
        

    return (
        <>
            <Row>
                {/* <Col span={2} /> */}
                <Col>
                    <div style={{ color: "#f5222d", fontWeight: "300", display: !!vCondition ? "none" : "inline-block", fontSize: "16px", marginRight: "5px" }}>*</div>
                </Col>
                <Col span={12} >
                    <div>เงื่อนไขการรับสิทธิ</div>
                </Col>
            </Row>

            <Row>
                <Col span={1}></Col>
                <Col span={22}>
                    <div style={{marginTop:"2%"}}></div>
                    <div><Checkbox onChange={onChangeCheckCondition}></Checkbox> กำหนดเงื่อนไขการรับสิทธิ </div>
                </Col>
            </Row>

            {vCondition &&
                <Row>
                    <Col span={2}></Col>
                    <Col span={20} >
                        <Radio.Group onChange={onChangeRadio4} style={{width: "100%"}}>
                            <Space direction="vertical" style={{width: "100%"}}>
                                <div> {/*1 Unit ได้รับ*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={1} onClick={() => setKeepE(null)} style={{marginBottom:"0%"}}> 1 Unit ได้รับ </Radio></Col>
                                        <Col span={12}>
                                            {
                                            // #1160 Create Privilege : ตอนกำหนดเงื่อนไขการรับสิทธิ ต้องห้ามใส่เกินจำนวนสิทธิ์ด้านบนที่ใส่ไว้ เช่น ถ้าใส่คูปองไว้10 แต่จะมาใส่สิทธิ์ด้านล่าง 100 ไม่ได้

                                            // vRaPrivCoupon == 1 ?
                                            vRaRightCondition == 1 &&
                                                <Input
                                                    type={"number"}
                                                    size="small" 
                                                    placeholder="จำนวนสิทธิ"
                                                    min={0}
                                                    value={keepE}
                                                    max={parseInt(vInputCouponAmount)} 
                                                    style={{width: "30%", borderRadius: "10px"}}
                                                    onChange={e => {
                                                        handlechange(e.target.value);
                                                    }}
                                                />
                                                // : 
                                                // <Input 
                                                //     type={"number"}
                                                //     placeholder="จำนวนสิทธิ"
                                                //     // defaultValue={0}
                                                //     min={0}
                                                //     size="small"
                                                //     style={{width: "40%", borderRadius: "10px"}}
                                                //     onChange={e => handlechange(e.target.value)}
                                                //     max={vInputCouponAmount}
                                                // />
                                                // null
                                                // :null
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <div> {/*1 Thai ID / Passport ได้รับ*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={2} onClick={() => setKeepE(null)} style={{marginBottom:"0%"}}> 1 Thai ID / Passport ได้รับ </Radio></Col>
                                        <Col span={12}>
                                            {
                                                vRaRightCondition == 2 &&
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        value={keepE}
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        onChange={e => handlechange(e.target.value)}
                                                        max={parseInt(vInputCouponAmount)} 
                                                    />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <div> {/*ให้สิทธิเฉพาะ*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={3} style={{marginBottom:"0%"}}> ให้สิทธิเฉพาะ </Radio> </Col>
                                        <Col span={12}>
                                            {
                                                vRaRightCondition == 3 &&
                                                    <Select 
                                                        size="small"
                                                        style={{width:"50%"}}
                                                        mode="multiple"
                                                        placeholder={t("select.sel")}
                                                        onChange={onChangeOnlyRightMulti}
                                                        {...PrivUserSubType}
                                                    />
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <div> {/*Account Type*/}
                                    <Row gutter={16}>
                                        <Col span={6}><Radio value={4} style={{marginBottom:"0%"}}> Account Type </Radio></Col>
                                        <Col span={12}>
                                            {
                                                vRaRightCondition == 4 &&
                                                <>
                                                    <Select 
                                                        size="small"
                                                        style={{width:"40%"}}
                                                        // mode="multiple"
                                                        placeholder={t("select.sel")}
                                                        onChange={onChangeOnlyRight}
                                                        {...PrivUserSubType}
                                                    />
                                                    <span style={{marginLeft:"1%"}}>ได้รับ</span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        max={parseInt(vInputCouponAmount)} 
                                                        onChange={e => handlechange(e.target.value)}
                                                    />
                                                </>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                                <div> {/*สิทธิของแต่ละ Room Type*/}
                                    <Radio value={5} style={{marginBottom:"0%"}} className="radio_custom_i_x_2">สิทธิของแต่ละ Room Type
                                        {
                                            vRaRightCondition == 5 && 
                                                <>
                                                    {unitTypeData !== undefined && unitTypeData.map((el:any, idx:any) => {
                                                        return <>
                                                            <Row gutter={16} style={{marginBottom: "0.5rem",marginTop: unitTypeData?.length - 1 == idx ? "0":"0.5rem"}}>
                                                                <Col span={6}>{el.bedroomName}</Col>
                                                                <Col span={12}>
                                                                    <Input 
                                                                        type={"number"}
                                                                        placeholder="จำนวนสิทธิ"
                                                                        min={0}
                                                                        size="small"
                                                                        defaultValue={0}
                                                                        style={{width: "30%", borderRadius: "10px"}}
                                                                        onChange={e => chkRoomType(el.id, el.bedroomName, e.target.value)}
                                                                        max={(vSitUnitType?.length > 0 ? vSitUnitType?.find((f:any) => { return f.id === el.id }).valueMax : 0)}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            
                                                            {/* <div style={{marginBottom:"1%"}}>
                                                                {el.bedroomName}
                                                                <span style={{marginLeft:"5px"}}></span>
                                                                <Input 
                                                                    type={"number"}
                                                                    placeholder="จำนวนสิทธิ"
                                                                    min={0}
                                                                    size="small"
                                                                    style={{width: "45%", borderRadius: "10px"}}
                                                                    onChange={e => chkRoomType(el.id, el.bedroomName, e.target.value)}
                                                                    max={parseInt(vInputCouponAmount)}
                                                                />
                                                            </div>
                                                            <div style={{marginBottom:"5px"}}></div> */}
                                                        </>
                                                    })}
                                                </> 
                                        }
                                    </Radio>  
                                </div>
                                <div> {/*สิทธิตามมูลค่า*/}
                                    <Radio value={6} style={{marginBottom:"0%"}} className="radio_custom_i_x_2"> สิทธิตามมูลค่า
                                        {
                                            vRaRightCondition == 6 &&
                                            <>
                                            {dataAmountPlace !== undefined && dataAmountPlace.map((el:any, idx:any) => {
                                                return <>
                                                    <Row gutter={16} style={{marginBottom: "0.5rem",marginTop: unitTypeData?.length - 1 == idx ? "0":"0.5rem"}}>
                                                        <Col span={6}>{el.type_name}</Col>
                                                        <Col span={12}>
                                                            <Input 
                                                                type={"number"}
                                                                placeholder="จำนวนสิทธิ"
                                                                min={0}
                                                                size="small"
                                                                defaultValue={0}
                                                                style={{width: "30%", borderRadius: "10px"}}
                                                                onChange={e => chkPriceType(el.id, el.bedroomName, e.target.value)}
                                                                max={(vSitUnitType?.length > 0 ? vSitPriceType?.find((f:any) => { return f.id === el.id }).valueMax: 0)}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </>
                                            })}
                                            {/* <Row gutter={[16,8]} style={{marginTop: "0.7rem"}}>
                                                <Col span={6}>น้อยกว่า 3 ล้าน</Col>
                                                <Col span={12}>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue3m(e.target.value)}
                                                    />
                                                </Col>
                                                <Col span={6}/>
                                                <Col span={6}>3 - 5.49 ล้าน</Col>
                                                <Col span={12}>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue4m(e.target.value)}
                                                    />
                                                </Col>
                                                <Col span={6}/>
                                                <Col span={6}>5.5 - 9.9 ล้าน</Col>
                                                <Col span={12}>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue9m(e.target.value)}
                                                    />
                                                </Col>
                                                <Col span={6}/>
                                                <Col span={6}>10 - 29.9 ล้าน</Col>
                                                <Col span={12}>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue29m(e.target.value)}
                                                    />
                                                </Col>
                                                <Col span={6}/>
                                                <Col span={6}>30 - 59.9 ล้าน</Col>
                                                <Col span={12}>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue59m(e.target.value)}
                                                    />
                                                </Col>
                                                <Col span={6}/>
                                                <Col span={6}>60 - 99.9 ล้าน</Col>
                                                <Col span={12}>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue99m(e.target.value)}
                                                    />
                                                </Col>
                                                <Col span={6}/>
                                                <Col span={6}>มากกว่า 100 ล้าน</Col>
                                                <Col span={12}>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "30%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue100m(e.target.value)}
                                                    />
                                                </Col>
                                                <Col span={6}/>
                                            </Row> */}
                                                {/* <div style={{marginBottom:"1%"}}>
                                                    น้อยกว่า 3 ล้าน 
                                                    <span style={{marginLeft:"5px"}}></span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "45%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue3m(e.target.value)}
                                                    />
                                                </div> */}
                                                {/* <div style={{marginBottom:"1%"}}>
                                                    3 - 5.49 ล้าน 
                                                    <span style={{marginLeft:"5px"}}></span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "45%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue4m(e.target.value)}
                                                    />
                                                </div> */}
                                                {/* <div style={{marginBottom:"1%"}}>
                                                    5.5 - 9.9 ล้าน  
                                                    <span style={{marginLeft:"5px"}}></span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "45%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue9m(e.target.value)}
                                                    />
                                                </div> */}
                                                {/* <div style={{marginBottom:"1%"}}>
                                                    10 - 29.9 ล้าน 
                                                    <span style={{marginLeft:"5px"}}></span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "45%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue29m(e.target.value)}
                                                    />
                                                </div> */}
                                                {/* <div style={{marginBottom:"1%"}}>
                                                    30 - 59.9 ล้าน 
                                                    <span style={{marginLeft:"5px"}}></span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "45%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue59m(e.target.value)}
                                                    />
                                                </div> */}
                                                {/* <div style={{marginBottom:"1%"}}>
                                                    60 - 99.9 ล้าน 
                                                    <span style={{marginLeft:"5px"}}></span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "45%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue99m(e.target.value)}
                                                    />
                                                </div> */}
                                                {/* <div style={{marginBottom:"1%"}}>
                                                    มากกว่า 100 ล้าน 
                                                    <span style={{marginLeft:"5px"}}></span>
                                                    <Input 
                                                        type={"number"}
                                                        placeholder="จำนวนสิทธิ"
                                                        // defaultValue={0}
                                                        min={0}
                                                        size="small"
                                                        style={{width: "45%", borderRadius: "10px"}}
                                                        // value={vUserConditionAmount}
                                                        onChange={e => setUnitValue100m(e.target.value)}
                                                    />
                                                </div> */}
                                            </>
                                        }
                                    </Radio>  
                                </div>
                                <div> {/*สิทธิเฉพาะจำนวน ตัว ที่ลงทะเบียนสัตว์เลี้ยง*/}
                                    <Radio value={7} style={{marginBottom:"0%", }} > สิทธิเฉพาะจำนวน ตัว ที่ลงทะเบียนสัตว์เลี้ยง </Radio> 
                                        {
                                        vRaRightCondition == 7 &&
                                        <>
                                            <span style={{marginLeft:"5px"}}></span>
                                            <Input 
                                                type={"number"}
                                                placeholder="จำนวนสิทธิ"
                                                // defaultValue={0}
                                                min={0}
                                                size="small"
                                                style={{width: "15%", borderRadius: "10px"}}
                                                // value={vUserConditionAmount}
                                                onChange={e => handlechange(e.target.value)}
                                            />
                                        </>
                                    }
                                </div>
                            </Space>
                        </Radio.Group>
                    </Col>
                </Row>
            }
          


        </>
    )
}