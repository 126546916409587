import { Breadcrumb, Card, Checkbox, Col, DatePicker, Divider, Edit, Form, Input, List, message, Radio, RadioChangeEvent, RcFile, Row, Select, Space, Upload, UploadFile, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IContentPostNews, IEventlog, IProperties, IUserSubType } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import {  TimePicker } from "antd";
import dayjs from "dayjs";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
// import { updateLocale } from "moment";


export const ContentPostNewsEdit: React.FC<IResourceComponentsProps> = () => {
    // const { ...uploadProps_pixlist} = useStrapiUpload({ maxCount: 5, });
    const t = useTranslate();
    const { TextArea } = Input;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    // const { mutate: CreateLocale } = useCreate<IContentPostNews>();
    const { mutate: UpdateLocale } = useUpdate<IContentPostNews>();
    // const { mutate: UpdateOriginal} = useUpdate<IContentPostNews>();
    const log_endpoint = "even-logs";
    const { Dragger } = Upload;
    const format = 'YYYY-MM-DD HH:mm';
    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;
    const [vsetdetail, setdetail] = useState<any>();
    const [vDetailEN, setDetailEN] = useState<any>();
    // const [ vStatus, setStatus ] = useState<any>("");
    // const [ vInputSeq, setInputSeq] = useState<any>(0);
    const [vContentDisplay, setContentDisplay] = useState<any>("News");
    const [checkedApp, setCheckedApp] = useState(false);
    const [checkedSMS, setCheckedSMS] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(false);
    const [vAllNoticeChan, setAllNoticeChan] = useState<any>([]);
    const { replace } = useNavigation();

    const [count, setCount] = useState(0);
    const [vImg, setImg] = useState<any>([]);
    const [vImgEn, setImgEn] = useState<any>([]);
    const [vProperties, setProperties] = useState<any>([]);
    // const [ vStat, setStat ] = useState<any>();
    const [vDataCKEN, setDataCKEN] = useState<any>();
    const [vIsLocalizeEN, setIsLocalizeEN] = useState<any>(false);
    const [vCusTypeCanSee, setCusTypeCanSee] = useState<any>([]);

    const [vCusType, setCusType] = useState<any>([]);
    const [vPublicDate, setPublicDate] = useState<any>(null);
    const [vValueLocale, setValueLocale] = useState(true);
    const [value, setValue] = useState();
    const [vDateStart, setDateStart] = useState<any>(null);
    const [vDateEnd, setDateEnd] = useState<any>(null);
    const [vTitleEn, setTitleEn] = useState('');
    const [vShortDetails, setShortDetails] = useState('');
    const [vLocalizeID, setLocalizeID] = useState('');

    const [vRaSpecificProperty, setRaSpecificProperty] = useState<any>('All');
    const [vPropertyID, setPropertyID] = useState<any>([]);

    const [perID, setPerID] = useState<any>();
    
    const { form, formProps, saveButtonProps } = useForm<IContentPostNews, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource: log_endpoint,
                    values: {
                        // user_profile            : user_data.id,
                        user_profile: user_data.user_profile.id,
                        log_time_stamp: Date.now(),
                        log_event: "Create",
                        menu_event: 'coupon',
                        SysPkID: recordData.data.id + ""
                    },
                    successNotification: false
                });
                setPerID(null)
            },
        }
    );

    const { selectProps: PropertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'contains',
                value: 'N',
            },
        ]
    });

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        // console.log('fileeeeeeeeeeeee', file)
                        body.append("files", file);
                        // let headers = new Headers();
                        // headers.append("Origin", "http://localhost:3000");
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                            method: "post",
                            body: body
                            // mode: "no-cors"
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                // console.log('resssssssssssssssss', res)
                                resolve({
                                    //   default: `${API_URL}/${res.filename}`
                                    default: res[0].url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    let CkConfig: any;
    CkConfig = {
        // plugins: ['bold', 'italic', 'underline', 'justify'] ,
        // plugins: ['justify'],
        toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            // "ckfinder",
            "|",
            "imageTextAlternative",
            "imageUpload",
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "justifyLeft",
            "justifyCenter",
            "justifyBlock"
            // "mediaEmbed",
            // "insertTable",
            // "tableColumn",
            // "tableRow",
            // "mergeTableCells",
            // "|",
            // "undo",
            // "redo"
        ],
        extraPlugins: [uploadPlugin],
        mediaEmbed: {
            // configuration...
            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
        },
        image: {
            toolbar: ['']
        }
    };

    const API_URL = useApiUrl();
    const { queryResult } = useShow<IContentPostNews>({
        metaData: {
            populate: ['*', 'localizations', 'localizations.pic', 'properties', 'pic', 'user_sub_types', 'notice_channel_types', 'notice_customer_type', 'createBy']
        },
    });
    const { data } = queryResult;
    let recordData: any = []
    recordData = data?.data ? data?.data : [];

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "content-news",
        metaData: {
            populate: [
                '*',
                'localizations',
                'localizations.pic',
                'properties',
                'pic',
                'user_sub_types',
                'notice_channel_types',
                'notice_customer_type',
                'createBy'
            ],
            locale: ['th']
        },
        pagination: {
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
            onSuccess: (data) => {
                console.log('data xxxx : ', data.data)
                // console.log('data.data[0]?.localizations : ', data.data[0]?.localizations[0].pic[0].url)
                callFetchData();

                let dataload = data?.data[0];
                let keepDataLoad = []
                for (let index = 0; index < dataload?.properties.length; index++) {
                    keepDataLoad.push(dataload?.properties[index]?.id)
                }
                if (!!keepDataLoad && keepDataLoad !== null) {
                    setPerID(keepDataLoad)
                }

                const fileList_TH: UploadFile[] =
                    dataload?.pic ? dataload?.pic?.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url:
                                v.url,
                            thumbUrl:
                                v.url
                        }
                    )): [];

                setImg({
                    name: 'files',
                    multiple: true,
                    defaultFileList: dataload?.pic ? [...fileList_TH] : [],
                })
                form.resetFields(["pic"]);

                let dataload_EN = data?.data[0]?.localizations;

                const fileList_EN: UploadFile[] =
                    dataload_EN?.length > 0 && dataload_EN[0]?.pic?.length > 0 ? dataload_EN[0]?.pic.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];

                setImgEn({
                    name: 'files',
                    multiple: true,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataload_EN[0]?.pic?.length > 0 ? [...fileList_EN] : [],

                })
                form.resetFields(["pic2"]);
            },
        },
    });

    useEffect(() => {
        refetch();
    },[]);
    
    function callFetchData() {
        setDateStart(recordData?.start_date ? recordData?.start_date : '');
        setDateEnd(recordData?.end_date ? recordData?.end_date : '');
        setPublicDate([recordData.start_date ? dayjs(recordData.start_date) : dayjs(), recordData.end_date ? dayjs(recordData.end_date) : dayjs()])

        setTitleEn(recordData.localizations.length > 0 ? recordData.localizations[0].name : '');
        setShortDetails(recordData.localizations.length > 0 ? recordData.localizations[0].short_detail : '')

        setLocalizeID(recordData.localizations.length > 0 ? recordData.localizations[0].id : '');

        setDataCKEN(recordData.localizations.length > 0 ? recordData.localizations[0].detail + "" : '')
        setIsLocalizeEN(recordData.localizations.length > 0 ? true : false)
        vProperties.pop();
        vProperties.push(recordData?.properties !== undefined && recordData.properties.length > 0 && recordData?.properties.map((v: any) => {return v.id}))
        setProperties(vProperties);

        setValue(recordData ? recordData.notice_channel : false)

        setRaSpecificProperty(recordData?.property_dev ? recordData?.property_dev : "")
        if (recordData.properties.length > 0) {
            vPropertyID.pop();
            vPropertyID.push(
                recordData?.properties.map((v: any) => {
                    return v.id
                })
            )
            setPropertyID(vPropertyID);
        }

        vCusType.pop();
        vCusType.push(recordData?.notice_customer_type !== undefined && recordData.notice_customer_type.length > 0 && recordData?.notice_customer_type.map((v: any) => {return v.id}))
        setCusType(vCusType);

        recordData?.notice_channel_types !== undefined && recordData?.notice_channel_types.length > 0 && recordData?.notice_channel_types.map((v: any) => {
            v.id == 3 && setCheckedApp(true)
            v.id == 2 && setCheckedEmail(true)
            v.id == 1 && setCheckedSMS(true)
        })

        form.setFieldsValue({
            properties:
                recordData?.properties !== undefined ?
                    recordData?.properties.length > 0 ?
                        recordData?.properties.map((v: any) => {
                            // console.log(v)
                            return { value: v.id, label: v.property_name }
                        })
                        : form.resetFields(["properties"])
                    : form.resetFields(["properties"]),

            notice_customer_type:
                recordData?.notice_customer_type !== undefined ?
                    recordData?.notice_customer_type.length > 0 ?
                        recordData?.notice_customer_type.map((v: any) => {
                            // console.log(v)
                            return { value: v.id, label: v.sub_type_name }
                        })
                        : form.resetFields(["notice_customer_type"])
                    : form.resetFields(["notice_customer_type"]),

            user_sub_types:
                recordData?.user_sub_types !== undefined ?
                    recordData?.user_sub_types.length > 0 ?
                        recordData?.user_sub_types.map((v: any) => {
                            // console.log(v)
                            return { value: v.id, label: v.sub_type_name }
                        })
                        : form.resetFields(["user_sub_types"])
                    : form.resetFields(["user_sub_types"]),

            name_en: recordData.localizations.length > 0 ? recordData.localizations[0].name : '',
            short_detail_en: recordData.localizations.length > 0 ? recordData.localizations[0].short_detail : ''

        })

        vCusTypeCanSee.pop();
        vCusTypeCanSee.push(recordData?.user_sub_types !== undefined && recordData.user_sub_types.length > 0 && recordData?.user_sub_types.map((v: any) => {return v.id}))
        setCusTypeCanSee(vCusTypeCanSee);
    }

    function fileChange_TH(e: any) {
        setKeepStatusIMG(e?.fileList.length)
        if(e.file.status === "removed") {
            setImg((pre:any) => ({
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));
            form?.resetFields(["pic"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setImg((pre:any) => ({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    function fileChange_EN(e: any) {
        setKeepStatusIMG(e?.fileList.length)
        if(e.file.status === "removed") {
            setImgEn((pre:any) => ({
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));
            form?.resetFields(["pic2"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setImgEn((pre:any) => ({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    function selectTime(time: any, timeString: any) {
        // setDateStart("");
        // setDateEnd("");

        if (timeString[0] == '' || timeString[1] == '') {
            setPublicDate(null)
        } else {
            setPublicDate(returnDayJsDateRange(timeString[0], timeString[1]))
        }

        // setDateStart(dayjs(timeString[0]));
        // setDateEnd(dayjs(timeString[1]));
    }

    const returnDayJsDateRange = (start: string | number | Date | dayjs.Dayjs | null | undefined, finish: string | number | Date | dayjs.Dayjs | null | undefined) => {
        // console.log("start", start);
        // console.log("finish", finish);
        // return [dayjs(start, "YYYY-MM-DD HH:mm"), dayjs(finish, "YYYY-MM-DD HH:mm")];
        return [dayjs(start), dayjs(finish)];
    };

    const onChangeNoti = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    function onChangeProperties(va: any) {
        vProperties.pop();
        vProperties.push(va);
        setProperties(vProperties);
        console.log('vProperties', vProperties);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const onChangeCheckApp = (e: CheckboxChangeEvent) => {
        setCheckedApp(e.target.checked);
    };

    const onChangeCheckSMS = (e: CheckboxChangeEvent) => {
        setCheckedSMS(e.target.checked);
    };

    const onChangeCheckEmail = (e: CheckboxChangeEvent) => {
        setCheckedEmail(e.target.checked);
    };

    const { selectProps: PrivUserSubTypeOnlyCus } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    function chkNoticeChan() {
        if (checkedSMS) {
            vAllNoticeChan.push(1);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedEmail) {
            vAllNoticeChan.push(2);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedApp) {
            vAllNoticeChan.push(3);
            setAllNoticeChan(vAllNoticeChan);
        }
    }

    function onChangeSubCusType(va: any) {
        vCusType.pop();
        vCusType.push(va);
        setCusType(vCusType);
    };


    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setValueLocale(value);
        if (value) {

        } else {
            setIsLocalizeEN(true)
        }
    };

    function updateLocaleEn() {
        UpdateLocale({
            resource: 'content-news',
            id: vLocalizeID,
            values: {
                detail: vDetailEN,
                name: vTitleEn,
                short_detail: vShortDetails,
                start_date: vDateStart,
                end_date: vDateEnd,
                pic: vImgEn?.defaultFileList?.length > 0 ?  vImgEn?.defaultFileList[0] : null
            }
        })
        replace("");
    }

    const onChangeTitleEn = (e: any) => {
        setTitleEn(e.target.value);
    }

    const onChangeShortDetailEN = (e: any) => {
        setShortDetails(e.target.value);
    }

    function onChangeUserTypeCanSee(va: any) {
        vCusTypeCanSee.pop();
        vCusTypeCanSee.push(va);
        setCusTypeCanSee(vCusTypeCanSee);
    };

    function updateOnfinish(values: any) {
        return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(values))
        );
    };

    const onChangeRadio5 = (e: RadioChangeEvent) => {
        setRaSpecificProperty(e.target.value);
    };
    const [ vKeep, setVKeep ] = useState<any>([]);
    const [ keepStatusIMG, setKeepStatusIMG ] = useState<any>(undefined);
    function onChangePropertyID(va: any) {
        let keepNeww:any = []
        for (let index = 0; index < va.length; index++) {
            keepNeww.push(va[index])
        }
        setVKeep(keepNeww)
        //console.log("va",va)
        setPerID(va)
        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
    };

    return (
        <Edit saveButtonProps={keepStatusIMG == 0 ? { disabled:true} : saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List
                title={t("content-post-news.title-edit")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("content-post-news.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    values.createBy = user_data.user_profile.id
                    values.updateBy = user_data.user_profile.id

                    // values.start_date = vDateStart
                    // values.end_date = vDateEnd

                    values.start_date = vPublicDate[0]
                    values.end_date = vPublicDate[1]

                    values.detail = vsetdetail
                    values.property_dev = vRaSpecificProperty
                    if (vPropertyID.length > 0) {
                        values.properties = vPropertyID[0]
                    }

                    values.user_sub_types = vCusTypeCanSee[0];
                    values.pic = vImg?.defaultFileList?.length > 0 ?  vImg?.defaultFileList[0] : null;

                    if (value) {
                        values.notice_customer_type = vCusType[0]
                    }
                    chkNoticeChan();
                    if (vAllNoticeChan.length > 0) {
                        values.notice_channel_types = vAllNoticeChan
                    }

                    if (vValueLocale == true) {
                        values.locale = "th"
                        updateOnfinish(values);
                    } else {
                        if (vIsLocalizeEN) {
                            updateLocaleEn();
                        }
                    }

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>

                        {
                            count == 0 ?
                                <>
                                    <div style={{ marginBottom: '2%' }}>
                                        <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" />
                                    </div>
                                </>
                                :
                                <>
                                    <div style={{ marginBottom: '2%' }}>
                                        <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" disabled />
                                    </div>
                                </>
                        }
                        {/* <div style={{ marginBottom:'2%' }}>
                            <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button"/>
                        </div> */}

                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: "1%" }}>
                                <span style={{ marginRight: "3%", fontSize: ".70vw", fontWeight: "bold", color: "#000" }}>Display Content :</span>
                                <span style={{ fontSize: "13px", color: "#000" }}>{vContentDisplay}</span>
                            </div>

                            {/* <Form.Item  label="Title" name="name" 
                                rules={[
                                    {
                                        required: true, message: 'please enter title.'
                                    },
                                    ]}>
                                <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                            </Form.Item> */}

                            {/* <Form.Item label="Short Detail" name="short_detail"
                                rules={[
                                    {
                                        required: true, message: 'please enter Sub Title.'
                                    },
                                ]}>
                                <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                            </Form.Item> */}

                            {
                                vValueLocale ?
                                    <>
                                        <Form.Item label="Title" name="name"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter title.'
                                                },
                                            ]}
                                        >
                                            <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                        </Form.Item>

                                        <Form.Item label="Short Detail" name="short_detail"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter Sub Title.'
                                                },
                                            ]}>
                                            <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item label="Title (EN)"
                                            name="name_en"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter title.'
                                                },
                                            ]}
                                        >
                                            {/* <TextArea style={{height: "30px", maxHeight: "80px"}} /> */}
                                            <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                defaultValue={vTitleEn}
                                                onChange={(e) => onChangeTitleEn(e)}
                                            />
                                        </Form.Item>

                                        <Form.Item label="Short Detail (EN)"
                                            name="short_detail_en"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter Sub Title.'
                                                },
                                            ]}
                                        >
                                            {/* <TextArea style={{height: "30px", maxHeight: "80px"}}/> */}
                                            <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                defaultValue={vShortDetails}
                                                onChange={(e) => onChangeShortDetailEN(e)}
                                            />
                                        </Form.Item>
                                    </>
                            }

                            {
                                vValueLocale ? // TH
                                    <Row>
                                        <Col span={3}>
                                            {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                            <span>
                                                <b><div style={{ fontSize: "12px" }}>Properties : </div><>&nbsp;</></b>
                                            </span>
                                        </Col>

                                        <Col span={8}>
                                            <Radio.Group onChange={onChangeRadio5} value={vRaSpecificProperty}>
                                                <Space direction="vertical">
                                                    <div>
                                                        <Radio value="All" style={{ marginBottom: "0%" }}> ทุกโครงการ </Radio>
                                                    </div>
                                                    <div>
                                                        <Radio value="Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Major เท่านั้น </Radio>
                                                    </div>

                                                    <div>
                                                        <Radio value="Non Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Non Major เท่านั้น </Radio>
                                                    </div>
                                                    <div style={{ width: '250px' }}>
                                                        <Radio value="Properties" style={{ marginBottom: "0%" }}> ระบุโครงการ </Radio>
                                                        {
                                                            vRaSpecificProperty == "Properties" ?
                                                                <>
                                                                    <div style={{ marginBottom: '2%' }}></div>
                                                                    <Form.Item
                                                                        // name="privilege_properties"
                                                                        name="properties"
                                                                        rules={[
                                                                            {
                                                                                required: vKeep.length == 0 ? true : false, message: 'Please select property.'
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            // size="small"
                                                                            //value={!!perID ? perID.map((e:any) => e) : null}
                                                                            style={{ width: "95%" }}
                                                                            mode="multiple"
                                                                            placeholder={t("select.sel")}
                                                                            {...PropertySelectProps}
                                                                            onChange={onChangePropertyID}
                                                                        />
                                                                        
                                                                    </Form.Item>
                                                                </>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </Space>
                                            </Radio.Group>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item
                                                // name="date_start"
                                                //label="Content public date"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select public date.'
                                                    },
                                                ]}
                                            >
                                                <div style={{display: 'flex'}}>
                                                    <p style={{color: 'red', marginRight: '3px'}}>*</p>
                                                    <p>Content public date</p>
                                                </div>
                                                {/* <DatePicker.RangePicker
                                                    // defaultValue={[ dayjs(recordData.start_date), dayjs(recordData.end_date)]}
                                                    value={[dayjs(vDateStart), dayjs(vDateEnd)]}
                                                    showTime
                                                    style={{ width: "100%", border: !!vDateStart ? '' : '1px solid red' }}
                                                    onChange={(date, dateString) => selectTime(date, dateString)}
                                                    format={format}
                                                /> */}
                                                <DatePicker.RangePicker
                                                    // defaultValue={[ dayjs(recordData.start_date), dayjs(recordData.end_date)]}
                                                    //value={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                    value={vPublicDate}
                                                    showTime
                                                    style={{ width: "100%", border: !!vDateStart ? '' : '1px solid red'}}
                                                    onChange={(date, dateString) => selectTime(date, dateString)}
                                                    format={format}
                                                />
                                                <p style={{display: !!vPublicDate ? 'none' : 'flex' , color: 'red'}}>Please select public date.</p>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            }

                            {
                                vValueLocale ?
                                    <>
                                        <Form.Item label="เนื้อหา"
                                            name="detail"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter detail.'
                                                },
                                            ]}
                                        >
                                            <CKEditor
                                                // name="detail"
                                                editor={ClassicEditor}
                                                // data={recordData.detail}
                                                data={recordData.detail ? recordData.detail : ''}
                                                config={CkConfig}
                                                onInit={(editor: any) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    // console.log("Editor is ready to use!", editor);
                                                    // console.log(
                                                    //     "toolbar: ",
                                                    //     Array.from(editor.ui.componentFactory.names())
                                                    // );
                                                    // console.log(
                                                    //     "plugins: ",
                                                    //     ClassicEditor.builtinPlugins.map((plugin:any) => plugin.pluginName)
                                                    // );
                                                }}
                                                onChange={(event: any, editor: any) => {
                                                    const data = editor.getData();
                                                    setdetail(data);
                                                    // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
                                                }}
                                                onBlur={(editor: any) => {
                                                    // console.log("Blur.", editor);
                                                }}
                                                onFocus={(editor: any) => {
                                                    // console.log("Focus.", editor);
                                                }}
                                            />
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item label="Content (EN)"
                                            // name="detail"
                                            rules={[
                                                {
                                                    required: true,
                                                },
                                            ]}
                                        >
                                            <CKEditor
                                                // name="detail"
                                                editor={ClassicEditor}
                                                // data={recordData.detail}
                                                // data={recordData.detail? recordData.detail : ''}
                                                data={vDataCKEN}
                                                // data={"kkkkkkk"}
                                                config={CkConfig}
                                                onInit={(editor: any) => {
                                                    // You can store the "editor" and use when it is needed.
                                                    // console.log("Editor is ready to use!", editor);
                                                    // console.log(
                                                    //     "toolbar: ",
                                                    //     Array.from(editor.ui.componentFactory.names())
                                                    // );
                                                    // console.log(
                                                    //     "plugins: ",
                                                    //     ClassicEditor.builtinPlugins.map((plugin:any) => plugin.pluginName)
                                                    // );
                                                }}
                                                onChange={(event: any, editor: any) => {
                                                    const data = editor.getData();
                                                    setDetailEN(data);
                                                    // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
                                                }}
                                                onBlur={(editor: any) => {
                                                    // console.log("Blur.", editor);
                                                }}
                                                onFocus={(editor: any) => {
                                                    // console.log("Focus.", editor);
                                                }}
                                            />
                                        </Form.Item>
                                    </>
                            }

                            {
                                vValueLocale ? // TH
                                    <Row>
                                        {/* <Col span={12}>
                                    <Form.Item name="seq" 
                                        label="Content Sequences"
                                    >
                                        <Input 
                                            type={"number"}
                                            placeholder="Sequences"
                                            // defaultValue={0}
                                            min={0}
                                            // size="small"
                                            style={{width: "95%", borderRadius: "5px"}}
                                            // value={vInputSeq}
                                            defaultValue={vInputSeq}
                                            onChange={e => setInputSeq(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col> */}

                                        <Col span={12}>
                                            <Form.Item
                                                name="status"
                                                label="Status"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select status.'
                                                    },
                                                ]}
                                            >
                                                <Select placeholder={t("select.sel")}
                                                    style={{ width: '95%' }}
                                                    onSelect={(value: any) => {
                                                        // console.log(value);
                                                        // setStatus(value);
                                                    }}
                                                    options={[
                                                        {
                                                            label: "Public",
                                                            value: "PUBLIC",
                                                        },
                                                        {
                                                            label: "Draft",
                                                            value: "DRAFT",
                                                        }
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="user_sub_types"
                                                label="User Type"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select user type.'
                                                    },
                                                ]}
                                            >
                                                <Select placeholder={t("select.sel")}
                                                    mode="multiple"
                                                    style={{ width: '100%' }}

                                                    onChange={onChangeUserTypeCanSee}

                                                    {...PrivUserSubTypeOnlyCus}
                                                />
                                            </Form.Item>

                                        </Col>
                                    </Row>
                                    :
                                    <></>
                            }
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel"><span style={{ color: "#f5222d", fontWeight: "300", fontSize: "16px", marginRight: "5px" }}>*</span>Thumbnail</span></div>
                            <Divider></Divider>
                            {
                                vValueLocale ?
                                    <>
                                        <Form.Item
                                            name="pic"
                                            rules={[
                                                {
                                                    // required: vImg?.defaultFileList?.length == 0 ? true : false, message: 'Please upload image.'
                                                    required: vImgEn !== [] ? false : true
                                                },
                                            ]}
                                        >
                                            <Dragger style={{ borderRadius: "10px" }}
                                                {...vImg}
                                                // defaultFileList={vImg? vImg: null}
                                                // defaultFileList={[...fileList]}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                beforeUpload={beforeUpload}
                                                onChange={fileChange_TH}
                                            >
                                                <p>Upload</p>
                                            </Dragger>
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item
                                            name="pic2"
                                            // name="pic"
                                            rules={[
                                                {
                                                    // required: vImgEn?.defaultFileList?.length == 0 ? true : false, message: 'Please upload image.'
                                                    required: vImgEn !== [] ? false : true
                                                },
                                            ]}
                                        >
                                            <Dragger style={{ borderRadius: "10px" }}
                                                {...vImgEn}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                beforeUpload={beforeUpload}
                                                onChange={fileChange_EN}
                                            >
                                                <p>Upload</p>
                                            </Dragger>
                                        </Form.Item>
                                    </>
                            }
                            <p style={{display: keepStatusIMG == 0 && vImgEn !== [] ? 'flex' : 'none', color: 'red'}}>Please upload image.</p>
                            <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Picture 750*500 PNG, JPG Maximum size 5MB</p>
                        </Card>


                        {
                            vValueLocale ? // TH
                                <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <div style={{ marginBottom: 20 }}><span className="title_panel">Notifications</span></div>
                                    <Divider></Divider>
                                    <Form.Item name="notice_channel" label={t("notice-ch")}>
                                        <Radio.Group
                                            options={[
                                                {
                                                    label: "แจ้งเตือน",
                                                    value: true,
                                                },
                                                {
                                                    label: "ไม่แจ้งเตือน",
                                                    value: false,
                                                }
                                            ]}
                                            onChange={onChangeNoti}
                                            value={value}
                                        />
                                    </Form.Item>

                                    {value === true ?
                                        <>
                                            <Form.Item label="Notice Date"
                                                name="notice_date"
                                                rules={[
                                                    { required: true, message: 'Please select date and time.' },
                                                ]}
                                                getValueProps={(value) => ({
                                                    value: value ? dayjs(value) : "",
                                                })}
                                            >
                                                <DatePicker
                                                    showTime
                                                    style={{ width: "100%" }}
                                                    format={format}
                                                />
                                            </Form.Item>

                                            <Form.Item label="Customer Type"
                                                name="notice_customer_type"
                                                rules={[
                                                    { required: true, message: 'Please select customer type.' },
                                                ]}
                                            >
                                                <Select
                                                    // size="small"
                                                    style={{ width: "100%" }}
                                                    mode="multiple"
                                                    placeholder={t("select.sel")}
                                                    // onChange={onChangeOnlyRightMulti}
                                                    onChange={onChangeSubCusType}
                                                    {...PrivUserSubTypeOnlyCus}
                                                />
                                            </Form.Item>
                                            <Form.Item style={{ marginBottom: "7%" }}>
                                                <Checkbox checked={checkedApp} onChange={onChangeCheckApp}>
                                                    In App
                                                </Checkbox>

                                                {
                                                    checkedApp ?
                                                        <>
                                                            <Form.Item name="notice_inApp"
                                                                rules={[
                                                                    { required: true, message: 'Please Type Message.' },
                                                                ]}
                                                            >
                                                                <Input placeholder="In app message" maxLength={85} />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <div style={{ marginBottom: '1%' }}></div>
                                                <Checkbox checked={checkedSMS} onChange={onChangeCheckSMS}>
                                                    SMS
                                                </Checkbox>

                                                {
                                                    checkedSMS ?
                                                        <>
                                                            <Form.Item name="notice_sms"
                                                                rules={[
                                                                    { required: true, message: 'Please Type Message.' },
                                                                ]}
                                                            >
                                                                <Input placeholder="SMS message" maxLength={85} />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }

                                                <div style={{ marginBottom: '1%' }}></div>
                                                <Checkbox checked={checkedEmail} onChange={onChangeCheckEmail}>
                                                    Email
                                                </Checkbox>
                                                {
                                                    checkedEmail ?
                                                        <>
                                                            <Form.Item name="notice_email"
                                                                rules={[
                                                                    { required: true, message: 'Please Type Message.' },
                                                                ]}
                                                            >
                                                                <TextArea placeholder="Message" maxLength={255} style={{ height: "30px", maxHeight: "80px" }} />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </Form.Item>
                                        </>
                                        :
                                        <></>
                                    }
                                </Card>
                                :
                                <></>
                        }


                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};