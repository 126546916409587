import { Avatar, Breadcrumb, Col, Create, DatePicker, Divider, Form, Icons, Input, List, Radio, RcFile, Row, Select, Space, Tooltip, Upload, message, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IUserAmpure, IUserDistrict, IUserNly, IUserProfile, IUserProvice, IUserprefix } from "interfaces";
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import { useRef, useState } from "react";
import { TOKEN_KEY } from "../../constants";
// import moment from "moment";
// import Moment from 'moment';
import dayjs from "dayjs";


import './styles.less';

let defaultPixurl: any = "../../images/svg/image.svg"
export const UserProfileCreate = () => {

    const t = useTranslate();
    const { Option } = Select;
    const { Dragger } = Upload;
    const API_URL = useApiUrl();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";
    const refUpload = useRef<any>();

    const {data: user_data} = useGetIdentity<any>();
    const { mutate: createUser } = useCreate<IUserProfile>();


    const [vpixlogo, setpixlogo] = useState<any>([]);
    const [swpixList, setswpixList] = useState<boolean>(true);
    const [swpwd, setswpwd] = useState<boolean>(false);
    const [pixURL, setpixURL] = useState<any>();
    const [vpixChange, setpixChange] = useState<any>();
    
    const [vTel, setTel] = useState<CountryPhoneInputValue>();
    const [vINDENID, setINDENID] = useState<boolean>();
    const [vbday, setbday] = useState<any>("");
    const [vAmper, setAmper] = useState<any>();
    const [vPovin, setPovin] = useState<any>();
    const [vDist, setDist] = useState<any>(1);
    const [vAmper2, setAmper2] = useState<any>();
    const [vPovin2, setPovin2] = useState<any>();
    const [vDist2, setDist2] = useState<any>(1);

    const { form, formProps, saveButtonProps }  = useForm<IUserProfile,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];

                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'users',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );
    const { replace } = useNavigation();

    const { selectProps: dataPrefix } = useSelect<IUserprefix>({
        resource: "user-prefixes",
        optionLabel: "prefix_name",
        optionValue: "id",
    });

    const { selectProps: dataNly } = useSelect<IUserNly>({
        resource: "nationalities",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
    });

    const { selectProps: dataProvice } = useSelect<IUserProvice>({
        metaData: {
            populate: '*'
        },
        resource: "provices",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
    });

    const { selectProps: dataAmphure } = useSelect<IUserAmpure>({
        metaData: {
            populate: '*'
        },
        resource: "amphures",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: 'province_id]',
                operator: 'in',
                value: vPovin
            }
        ]
    });

    const { selectProps: dataDistrict } = useSelect<IUserDistrict>({
        metaData: {
            populate: '*'
        },
        resource: "districts",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 10000,
        filters: [
            {
                field: 'amphure_id]',
                operator: 'in',
                value: vAmper
            }
        ]
    });

    const { selectProps: dataAmphure2 } = useSelect<IUserAmpure>({
        metaData: {
            populate: '*'
        },
        resource: "amphures",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: 'province_id]',
                operator: 'in',
                value: vPovin2
            }
        ]
    });

    const { selectProps: dataDistrict2 } = useSelect<IUserDistrict>({
        metaData: {
            populate: '*'
        },
        resource: "districts",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: 'amphure_id]',
                operator: 'in',
                value: vAmper2
            }
        ]

    });

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function fileChange(e: any) {
        if (e.file.response) {
            let res = e.file.response;
            changepixUrl(res[0].url, res[0])
        }
    }

    function changepixUrl(url: any, val: any) {
        setpixURL(url);
        setpixChange(val);
        setswpixList(false)
    }

    function removepixList() {
        setpixURL(defaultPixurl);
        setpixChange(undefined);
        setswpixList(true)
    }

    const postListQueryResult = useList<IUserDistrict>({
        resource: "districts",
        config: {
            filters: [
                {
                    field: "id",
                    operator: "in",
                    // value: vamper,
                    value: vDist,
                },
            ],
        },
    });

    const postListQueryResult2 = useList<IUserDistrict>({
        resource: "districts",
        config: {
            filters: [
                {
                    field: "id",
                    operator: "in",
                    value: vDist2
                },
            ],
        },
    });


    let nzipcode  :   any;
    nzipcode      = postListQueryResult?.data?.data[0] ? postListQueryResult?.data?.data[0]  : [];
    let nzipcode2  :   any;
    nzipcode2      = postListQueryResult2?.data?.data[0] ? postListQueryResult2?.data?.data[0]  : [];

    let kZip = nzipcode.zip_code;
    let kZip2 = nzipcode2.zip_code;

    form.setFieldsValue({
        postcode1: kZip,
        postcode2: kZip2
    });

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List  
                title=""             
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project-member.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("member-account.create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                extra : <></>
                }}
            />

            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values: any) => { 
                    console.log('values', values)

                    createUser(
                        {
                            resource: 'user-profiles',
                            values: {
                                avatar: vpixChange !== null && vpixChange !== undefined ? vpixChange.id : null,
                                user_prefix: values.user_prefix,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                nick_name: values.nick_name,
                                email: values.email_custom,
                                gender: values.gender,
                                nationality: values.nationality,

                                address1_addr_no: values.address1_addr_no,
                                address1_amphure: values.address1_amphure ? values.address1_amphure?.id : null,
                                address1_district: values.address1_district ? values.address1_district?.id : null,
                                address1_provice: values.address1_provice ? values.address1_provice?.id : null,
                                address1_road: values.address1_road,
                                address1_soi: values.address1_soi,

                                address2_addr_no: values.address2_addr_no,
                                address2_amphure: values.address2_amphure ? values.address2_amphure?.id : null,
                                address2_district: values.address2_district ? values.address2_district?.id : null,
                                address2_provice: values.address2_provice ? values.address2_provice?.id : null,
                                address2_road: values.address2_road,
                                address2_soi: values.address2_soi,
                                updateBy: user_data.user_profile?.id,
                                short_tel: vTel?.short ? vTel?.short : null,
                                tel: vTel?.phone ? vTel?.phone : null,
                                birthday: vbday ? vbday : null,

                                identity_no: values.identity_no,
                                identity_passport: values.identity_passport,

                                password1: values.password1,
                                password2: values.password2,
                                
                                user_type: 5, //GUEST
                                isStaff: true, // CREATE EMAIL
                                deleteStatus: "N",
                                createBy: user_data.user_profile.id
                            }, 
                            successNotification: false, 
                            errorNotification: false,
                        },
                        {
                            onSuccess(data:any, variables:any, context:any) {
                                replace("/project_member/user-account");
                            },
                        }
                    );
                    
                    // values.avatar = vpixChange !== null && vpixChange !== undefined ? vpixChange.id : null
                    // values.user_prefix = values.user_prefix
                    // values.first_name = values.first_name
                    // values.last_name = values.last_name
                    // values.nick_name = values.nick_name
                    // values.email = values.email
                    // values.gender = values.gender
                    // values.nationality = values.nationality

                    // values.address1_addr_no = values.address1_addr_no !== undefined ? values.address1_addr_no : '' 
                    // values.address1_amphure = values.address1_amphure ? values.address1_amphure?.id : null
                    // values.address1_district = values.address1_district ? values.address1_district?.id : null
                    // values.address1_provice = values.address1_provice ? values.address1_provice?.id : null
                    // values.address1_road = values.address1_road !== undefined ? values.address1_road : ''
                    // values.address1_soi = values.address1_soi !== undefined ? values.address1_soi : ''
                    // values.postcode1 = values.postcode1 ? values.postcode1 : ''


                    // values.address2_addr_no = values.address2_addr_no ? values.address2_addr_no : ''
                    // values.address2_amphure = values.address2_amphure ? values.address2_amphure?.id : null
                    // values.address2_district = values.address2_district ? values.address2_district?.id : null
                    // values.address2_provice = values.address2_provice ? values.address2_provice?.id : null
                    // values.address2_road = values.address2_road ? values.address2_road : ''
                    // values.address2_soi = values.address2_soi ? values.address2_soi : ''
                    // values.postcode2 = values.postcode2 ? values.postcode2 : ''

                    // if (values.identity_no) {
                    //     values.identity_no = values.identity_no
                    // }

                    // if (values.identity_passport) {
                    //     values.identity_passport = values.identity_passport
                    // }

                    // values.password1 = values.password1
                    // values.password2 = values.password2

                    // values.user_type = 5 //GUEST
                    // values.isStaff = true // CREATE EMAIL
                    // values.deleteStatus = "N"
                    // values.createBy = user_data.user_profile.id

                    // if (vTel !== null && vTel !== undefined) {
                    //     values.short_tel = vTel?.short
                    //     values.tel = vTel?.phone
                    // }

                    // if (vbday !== "" && vbday !== undefined) {
                    //     values.birthday = vbday;
                    // }


                    // return (
                    //     formProps?.onFinish &&
                    //     formProps?.onFinish(values)
                    // );
                }}
            >
                <Row gutter={8}>
                    <Col span={4} className="gutter-row">
                        <Form.Item
                            name="avatar"
                            className="form_projectPix"
                            style={{ marginBottom: "5%" }}
                        >
                            <Tooltip placement="left" color="lime" title={"AVATAR PROFILE"}>
                                <Dragger className="dragger_upload_custom_i_w"
                                    {...vpixlogo}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                    showUploadList={false}
                                    openFileDialogOnClick={false}
                                    ref={refUpload}
                                >
                                    <div className="dragger_upload_custom_i_h">
                                        <div className="projectPix_hover">
                                            <div>
                                                {swpixList == false ?
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={14} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <Avatar className="projectPix" src={pixURL} style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
                                        <span style={{ fontSize:"11px", color: "#DF6677" }}>* Picture 512*512 PNG, JPG size 5MB</span>
                                    </div>
                                </Dragger>
                            </Tooltip>
                        </Form.Item>
                    </Col>

                    <Col span={20} className="gutter-row">
                        <Row gutter={8}>
                            <Col span={6} className="gutter-row">
                                <Form.Item name="user_prefix" label={t("user-profiles.prefix")}>
                                    <Select placeholder={t("select.sel")} {...dataPrefix} />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.name")} name="first_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please input first name'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.lastname")} name="last_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please input last name'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.nickname")} name="nick_name">
                                    <Input />
                                </Form.Item>
                            </Col> 

                            <Col span={8} className="gutter-row">
                                <Form.Item 
                                    label="Email" 
                                    name="email_custom"
                                    // name="email"
                                    rules={[
                                        {
                                            required: true, message: 'Please input email'
                                        },
                                        {
                                            type: 'email',
                                            message: 'email must be formatted correctly',
                                        }
                                    ]}
                                >
                                    <Input type="email" />
                                </Form.Item>
                            </Col>

                            <Col span={10} className="gutter-row">
                                <Form.Item name="tel" label={t("user-profiles.tel")}>
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{ height: "32px", borderRadius: "5px", padding: "0" }}
                                            onChange={(v) => { setTel(v) }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            minLength={9}
                                            maxLength={12}
                                        />
                                    </ConfigProvider>
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item name="gender" label={t("user-profiles.gender")}>
                                    <Select placeholder={t("select.sel")}
                                        options={[
                                            {
                                                label: "Male",
                                                value: "M",
                                            },
                                            {
                                                label: "Female",
                                                value: "F",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8} className="gutter-row">
                                <Form.Item label=" " style={{ marginTop: "-1.2rem" }}>
                                    <Radio.Group onChange={(e) => {
                                        setINDENID(e.target.value);
                                        formProps?.form?.resetFields(["identity_no"])
                                        formProps?.form?.resetFields(["identity_passport"])
                                    }
                                    }>
                                        <Space direction="horizontal">
                                            <Radio value={true}>{t("user-profiles.true")}</Radio>
                                            <Radio value={false}>{t("user-profiles.false")}</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={16} className="gutter-row">
                                {(vINDENID == true || vINDENID == false) ?
                                    vINDENID == true ?
                                        <Form.Item label=" " name="identity_no"
                                            className="disable_req"
                                            style={{ marginTop: "-1.2rem" }}
                                            rules={[
                                                {
                                                    required: true, message: 'Please input national id'
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="National ID"
                                                maxLength={13}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                        :
                                        vINDENID == false &&
                                        <Form.Item label=" " name="identity_passport"
                                            className="disable_req"
                                            style={{ marginTop: "-1.2rem" }}
                                            rules={[
                                                {
                                                    required: true, message: 'Please input passport number'
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Passport number"
                                                maxLength={20}
                                            />
                                        </Form.Item>
                                    :
                                    <Form.Item label=" " style={{ marginTop: "-1.2rem" }}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                }
                            </Col>

                            <Col span={12} className="gutter-row">
                                <Form.Item
                                    name="password1"
                                    label={t("staff-profiles.pwd1")}
                                    rules={[
                                        {
                                            required: true,
                                            // message: 'please input your password!',
                                            message: '',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                // if (value.length >= 8 && value.length <= 16) {
                                                //     return Promise.resolve();
                                                // }
                                                // return Promise.reject(new Error('Password must be 8-16 characters only '));


                                                // console.log('value ', value)
                                                if (value.length >= 8 && value.length <= 16) {
                                                    return Promise.resolve();
                                                }

                                                if (value.length <= 8 && value.length >= 16) {
                                                    return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                }

                                                if (value.length < 0) {
                                                    return Promise.reject(new Error('please input your password'));
                                                }

                                                if (value == "") {
                                                    return Promise.reject(new Error('please input your password'));
                                                }

                                            },
                                        }),
                                    ]}
                                    hasFeedback

                                >
                                    <Input.Password style={{ borderRadius: 5 }}
                                        type="password"
                                        placeholder="Enter your password"
                                        iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                        minLength={8}
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12} className="gutter-row">
                                <Form.Item
                                    name="password2"
                                    label={t("staff-profiles.pwd2")}
                                    dependencies={['password1']}
                                    hasFeedback
                                    rules={[{ required: true, message: 'Please confirm your password!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password1') === value) {
                                                return Promise.resolve();
                                            }
                                            // return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                            return Promise.reject(new Error('Password is not match'));
                                        },
                                    }),
                                    ]}
                                >
                                    <Input.Password style={{ borderRadius: 5 }}
                                        type="password"
                                        placeholder="Enter your password"
                                        iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                        minLength={8}
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider style={{ margin: "30px 0px" }} />

                <Row gutter={8}>
                    <Col span={12} className="gutter-row">
                        <Form.Item name="birthday" label={t("user-profiles.bday")}>
                            <DatePicker
                                onChange={(date, dateString) => setbday(dateString)}
                                // disabledDate={(current) => {
                                //     let customDate = moment().format("YYYY-MM-DD");
                                //     return current && current > moment(customDate, "YYYY-MM-DD");
                                // }}
                                disabledDate={(current) => {
                                    let customDate = dayjs().format('YYYY-MM-DD');
                                    return current && dayjs(current).isAfter(customDate, 'day');
                                }}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item name="nationality" label={t("user-profiles.nly")}>
                            <Select placeholder={t("select.sel")} {...dataNly} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{ margin: "10px 0px" }} />

                <Row gutter={24}>
                    {/* ADDRESS1 */}
                    <Col span={12} className="gutter-row" style={{borderRight: "1px solid #EAEAEA"}}> 
                        <div style={{ marginBottom: "5%" }}><span style={{ fontSize: "14px", color: "#000" }}>{t("user-profiles.address1")}</span></div>
                        <Form.Item name="address1_addr_no" label={t("user-profiles.address")}>
                            <Input />
                        </Form.Item>

                        <Row gutter={8}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address1_soi" label={t("user-profiles.soi")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address1_provice" label={t("user-profiles.provice")}>
                                    <Select
                                        placeholder={t("select.sel")}
                                        {...dataProvice}
                                        onChange={(e) => setPovin(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="address1_district" label={t("user-profiles.district")}>
                                    <Select
                                        placeholder={t("select.sel")}
                                        {...dataDistrict}
                                        onChange={(e) => setDist(e)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address1_road" label={t("user-profiles.road")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address1_amphure" label={t("user-profiles.amphure")}>
                                    <Select
                                        placeholder={t("select.sel")}
                                        {...dataAmphure}
                                        onChange={(e) => setAmper(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="postcode1" label={t("user-profiles.post-code")} className="input_disable_onshow">
                                    {/* <Input disabled /> */}
                                    <Input disabled defaultValue={kZip} value={kZip} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    {/* ADDRESS2 */}
                    <Col span={12} className="gutter-row">
                        <div style={{ marginBottom: "5%" }}><span style={{ fontSize: "14px", color: "#000" }}>{t("user-profiles.address2")}</span></div>
                        <Form.Item name="address2_addr_no" label={t("user-profiles.address")}>
                            <Input />
                        </Form.Item>

                        <Row gutter={8}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address2_soi" label={t("user-profiles.soi")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address2_provice" label={t("user-profiles.provice")}>
                                    <Select
                                        {...dataProvice}
                                        placeholder={t("select.sel")}
                                        onChange={(e) => setPovin2(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="address2_district" label={t("user-profiles.district")}>
                                    <Select
                                        {...dataDistrict2}
                                        placeholder={t("select.sel")}
                                        onChange={(e) => setDist2(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address2_road" label={t("user-profiles.road")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address2_amphure" label={t("user-profiles.amphure")}>
                                    <Select
                                        {...dataAmphure2}
                                        placeholder={t("select.sel")}
                                        onChange={(e) => setAmper2(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="postcode2" label={t("user-profiles.post-code")} className="input_disable_onshow">
                                    {/* <Input disabled /> */}
                                    <Input disabled defaultValue={kZip2} value={kZip2} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
    </Create>
    );
};