/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { MjcInspecDoneJobList } from "./done_job";
import { MjcInspecInprogressJobList } from "./in_progress";
import { MjcInspecNewJobList } from "./new_job";
import { MjcInspecTodayJobList } from "./today_job";
import { majorCareUrl } from "../../constants";
import dayjs from "dayjs";
import axios from "axios";



export const HcuRepairInspecList: React.FC<IResourceComponentsProps> = () => {
    // const tabActive = localStorage.getItem("keyTabHCUMaster");
    const [ Tab, SetTab] = useState<any>();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TabPane } = Tabs;
    const tabJobActive = localStorage.getItem("keyTabInspecJob");
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [countTodayJob, setCountTodayJob] = useState<any>(undefined);
    const [countNewJob, setCountNewJob] = useState<any>(undefined);
    const [countWaitAppJob, setCountWaitAppJob] = useState<any>(undefined);
    const [countDoneJob, setCountDoneJob] = useState<any>(undefined);


    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabInspecJob", v)
    }

    const getDataK = async ()=>{
        const loaddataProperty = await axios.get(majorCareUrl + "/majorcare/findAllProperty" , { headers: { } });
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findHomecheckjobByStat`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                let newProp: any = [];
                if(prop_list){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property.id));
                    for (let i = 0; i < prop_list?.length; i++) {
                        let findProp = loaddataProperty?.data?.findIndex((f: any)=> f?.property_id == prop_list[i]);
        
                        if(findProp !== -1){
                            newProp.push({
                                id: loaddataProperty?.data[findProp]?._id,
                                property_id: loaddataProperty?.data[findProp]?.property_id,
                                property_name: loaddataProperty?.data[findProp]?.property_name,
                                property_name_en: loaddataProperty?.data[findProp]?.property_name_en,
                                is_active: loaddataProperty?.data[findProp]?.is_active,
                            });
                        }
                    }
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.property_obj[0]?.property_id == Number(prop_id))
                    let findProp = loaddataProperty?.data?.findIndex((f: any)=> f?.property_id == prop_id);
                    if(findProp !== -1){
                        newProp?.push({
                            id: loaddataProperty?.data[findProp]?._id,
                            property_id: loaddataProperty?.data[findProp]?.property_id,
                            property_name: loaddataProperty?.data[findProp]?.property_name,
                            property_name_en: loaddataProperty?.data[findProp]?.property_name_en,
                            is_active: loaddataProperty?.data[findProp]?.is_active,
                        });
                    }
                }
                else if(loaddataProperty?.data?.length > 0){
                    newProp = loaddataProperty?.data?.map((item:any)=>{
                        return {
                            id: item?._id,
                            property_id: item?.property_id,
                            property_name: item?.property_name,
                            property_name_en: item?.property_name_en,
                            is_active: item?.is_active,
                        }
                    })
                }

                const today = dayjs().format("YYYY-MM-DD")

                setCountTodayJob(dataLoad.filter((e:any) => {
                    const isInProperty = newProp.some((property:any) => {
                        return property.id === e.property;
                    });
                    return e.status === "6485bcf9049e59df2850eba2" || (e.status === "6485bd3a681aef755a4f2797" && e.appoint_start.startsWith(today)) && isInProperty
                }))

                let newDataNewJob = dataLoad.filter((e:any)=> e.status !== "6485bcf9049e59df2850eba2" )
                setCountNewJob(newDataNewJob)
                // setCountNewJob(dataLoad.filter((e:any)=> e.status !== "6485bcf9049e59df2850eba2" ));

                setCountWaitAppJob(dataLoad.filter((e:any)=> e.status == "6485bd3a681aef755a4f2797" || e.status == "6485bd56681aef755a4f2799" || e.status == "6485bd6e681aef755a4f279b"));

                setCountDoneJob(dataLoad.filter((e:any)=> e.status == "6485bd7d681aef755a4f279d" ));

            });
    }

    useEffect(() => {
        getDataK();
    }, []);
    
    return <>
        <List 
            title={t("mjc-inspec.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("mjc-inspec.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="tab" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabJobActive ? tabJobActive + "" : "TodayJob"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >
                        <TabPane
                            key={"TodayJob"}
                            tab={
                                <span>
                                    {t("mjc-inspec.menu-1")}
                                    <Badge showZero count={countTodayJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            {/* รายการวันนี้ */}
                            <MjcInspecTodayJobList/>
                        </TabPane>
                        <TabPane
                            key={"NewJob"}
                            tab={
                                <span>
                                    {t("mjc-inspec.menu-2")}
                                    {/* <Badge showZero count={countNewJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/> */}
                                    <Badge showZero count={countNewJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcInspecNewJobList />
                        </TabPane>

                        <TabPane
                            key={"InProgressJob"}
                            tab={
                                <span>
                                    {t("mjc-inspec.menu-3")}
                                    <Badge showZero count={countWaitAppJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcInspecInprogressJobList />
                        </TabPane>

                        <TabPane
                            key={"DoneJob"}
                            tab={
                                <span>
                                    {t("mjc-inspec.menu-4")}
                                    <Badge showZero count={countDoneJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcInspecDoneJobList />
                        </TabPane>

                    </Tabs>
                </Form.Item>
            </Form>
           
        </List>

        
    </>
}