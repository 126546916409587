/* eslint-disable */
import { Avatar, Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, List, Modal, Row, Select, ShowButton, Space, Table, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IProperties } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";

import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export const PropertiesList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Properties");
    // let data_property = Identity_data?.property.id
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'};

    const [properties, setProperties] = useState<any>()

    const { tableProps,searchFormProps } = useTable<IProperties,HttpError,IProperties>({
        metaData: {
            populate: '*'
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { id } = params;
                filters.push(
                    {
                        field       : "id",
                        // operator    : (prop_list || prop_id || id) ? "contains" : "nin",
                        // value       : id ? id : prop_list ? prop_list: prop_id ? prop_id : null,
                        operator: "eq",
                        value : !!properties ? properties : null
                    },
    
                );
            return filters;
        },
        initialFilter:[
            {
                field: "id",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            } 
        ],
        permanentSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
        // permanentFilter:[
        //     {
        //         field: "id",
        //         operator: data_property ? "eq" : "nin",
        //         value: data_property ? data_property : null
        //     }
        // ]
    });

    const onReset = () => {
        setProperties(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    const categoryQueryResult = useOne<IProperties>({
        resource: "properties",
        id: ''
    });
    
    let paginationLength:any = [];
    // paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    paginationLength = tableProps?.pagination ?  tableProps?.pagination?.total : [];
    
    const { confirm }           = Modal;
    const { mutate }            = useUpdate<IProperties>();
    const ed_point              = "properties";
    const [page, setPage]       = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState         = [ '10','30', '50', '100'];

    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IProperties>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                        
                };
            }
        },
    });

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'properties',
                SysPkID         : id+""
            },
            successNotification : false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data
            if(dataSet?.length == 1){
                searchFormProps?.form?.setFieldsValue({
                    property_name: dataSet[0]?.property_name
                })
            }
        },}
    });


    /* CONSOLE LOG */
    // console.log('property',tableProps.dataSource)

    return <>
        <List 
            title={t("properties.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("property.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("bc_properties.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        {/* <Form.Item name="q">
                            <Input style={{borderRadius: 20,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                        </Form.Item> */}
                        <Form.Item name="id" >
                            <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear
                                onChange={value => { setProperties(value); }} 
                            />
                            {/* <Input placeholder="please enter" size="small" style={{width: '250px'}}/> */}
                        </Form.Item>
                        <Form.Item >
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                    
                <Col span={6}>
                    <Form
                        // {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right"}}
                    >
                        {/* <Form.Item>
                            <ExportButton style={{borderRadius:5}}
                                onClick={triggerExport}
                                loading={exportLoading}
                            />
                        </Form.Item> */}
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Table {...tableProps} 
                
                bordered
                size="middle"
                rowKey      = "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    // total:paginationLength.length, 
                    total:paginationLength, 
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false
                }}
            >
                {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}
                {/* <Table.Column dataIndex="id" title={t("Lid")} align="center" sorter
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={value}  />
                        </>
                    }}
                /> */}

                {/* <Table.Column align="center" width={35} dataIndex="Project_Image" title="  " 
                    render={(value:any,record:any)=>{
                        return <>
                                {
                                    value? 
                                        <Avatar size={32} src={value.url} />
                                    :<>
                                        <Avatar size={32} icon={<Icons.UserOutlined />} />
                                    </>
                                }
                            </>
                    }}
                /> */}

                <Table.Column align="center" width={80} dataIndex="logo" title="  " 
                    render={(value:any,record:any)=>{
                        return <>
                                {
                                    value? 
                                        <Avatar size={32} src={value.url} />
                                    :<>
                                        <Avatar size={32} icon={<Icons.UserOutlined />} />
                                    </>
                                }
                            </>
                    }}
                />

                <Table.Column title="PROPERTY CODE" 
                    sorter={(a:any, b:any) => {
                        if (a?.property_code < b?.property_code) {return -1}
                        if (a?.property_code > b?.property_code) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record? record.property_code:"-" }  />
                        </>
                    }}
                />

                <Table.Column dataIndex="property_name" title="PROPERTY NAME" align="center" className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.property_name < b?.property_name) {return -1}
                        if (a?.property_name > b?.property_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record? record.property_name:"-"}  />
                        </>
                    }}
                />

                {/* <Table.Column title="PROPERTY NAME" 
                
                    render={(value:any,record:any)=>{
                        console.log("namexxxx",record)
                        return <>
                            <TextField value={"value"}  />
                        </>
                    }}
                /> */}


                {/* <Table.Column dataIndex="post_time" title="Post time" 
                    render={(value:any,record:any)=>{
                        return <>
                            <span> DATE: </span>
                            <TextField value={Moment(value).format('MM/d/yyyy')}  />
                            <span> TIME: </span>
                            <TextField value={Moment(value).format('HH:mm a')}  />
                        </>
                    }}
                /> */}

                <Table.Column title="PROPERTIES TYPE" align="center" className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.pp_type?.pp_name < b?.pp_type?.pp_name) {return -1}
                        if (a?.pp_type?.pp_name > b?.pp_type?.pp_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record.pp_type ? record.pp_type.pp_name:"ไม่ระบุ"}  />
                        </>
                    }}
                />

                <Table.Column title="PROJECT BRAND" 
                    sorter={(a:any, b:any) => {
                        if (a?.project_brand?.brand_name < b?.project_brand?.brand_name) {return -1}
                        if (a?.project_brand?.brand_name > b?.project_brand?.brand_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record.project_brand ? record.project_brand.brand_name:"ไม่ระบุ"}  />
                        </>
                    }}
                />

                <Table.Column title="DEVELOPER" align="center" className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.property_under < b?.property_under) {return -1}
                        if (a?.property_under > b?.property_under) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record? record.property_under:"ไม่ระบุ"}  />
                        </>
                    }}
                />

                <Table.Column title="CREATED BY" align="center" width={100} className="table_colmn_custom_left_value"
                    // sorter={(a:any, b:any) => {
                    //     if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                    //     if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                    //     return (0)
                    // }}
                    sorter={(a:any, b:any) => a?.createBy?.first_name.localeCompare(b?.createBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title="CREATED DATE" align="center" width={100}
                 sorter={(a:any, b:any) => {
                    if (a.createdAt < b.createdAt) {return -1}
                    if (a.createdAt > b.createdAt) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <>
                            {/* <TextField style={{fontWeight: 600}} value={record?.createdAt? record?.createdAt :"-"}  /> */}
                            {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                            {/* <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /> */}
                            {
                                record?.createdAt ?
                                    <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />

                <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                    // sorter={(a:any, b:any) => {
                    //     if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                    //     if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                    //     return (0)
                    // }}
                    sorter={(a:any, b:any) => a?.updateBy?.first_name.localeCompare(b?.updateBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title="LAST UPDATE" align="center" width={100}
                 sorter={(a:any, b:any) => {
                    if (a.updatedAt < b.updatedAt) {return -1}
                    if (a.updatedAt > b.updatedAt) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <>
                            {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                            {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                            {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                            {
                                record?.updatedAt ?
                                    <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />

                <Table.Column<IProperties>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed="right"
                    render={(_, record) => (
                        <Space>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            ></ShowButton>

                            {/* <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />

                            <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}
                            {
                                record.deleteStatus === "N" ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        />
                                        <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                                :
                                    <>
                                        {/* <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            disabled
                                        /> */}
                                        <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                            }

                        </Space>
                    )}
                />
            </Table>
        </List>
    </>
}