import { Col, Image, Row, Spin } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom, useTranslate } from "@pankod/refine-core";
import axios from "axios";
import { useState } from "react";
import { petURL } from "../../constants";
import './style/styles.css';


let recordData:any = undefined;
let pet_size:any = undefined;
let phoneico: any = "../../images/svg/phone_ico.svg"

export const PetDetail: React.FC<IResourceComponentsProps> = () => {
    
    
    let getID = window?.location?.href.split("/");
    let local: any = getID[getID?.length -1];
    let pageID: any = getID[getID?.length -2];

    const t = useTranslate();
    const [isLoading, setLoading] = useState<any>(true);

    const { data, refetch } = useCustom<any>({
        url: `${petURL}/api/lscpet/findOnePetRegis`,
        method: "get",
        config: {
            query: {
                id: local
            }
        },
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data[0];
                console.log(">>> dataLoad", dataLoad)
                recordData = dataLoad

                await axios.get(`${petURL}/api/lscpet/findOnePetSizeDetail?id=${dataLoad?.pet_size_detail}`, { headers: {  } })
                .then((res:any) => {
                    pet_size = res?.data?.size_name_en
                })

                setLoading(false);
            },
        },
    });

    // const args = {
    //     number: '0855369214', // String value with the number to call
    //     prompt: false, // Optional boolean property. Determines if the user should be prompted prior to the call 
    //     skipCanOpen: true // Skip the canOpenURL check
    //   }

    const [isModalVisible, setModalVisible] = useState(false);

    const showModal = () => {
        setModalVisible(true);
    };

    const handleCancel = () => {
        setModalVisible(false);
    };
    

    return (
        <Spin spinning={isLoading}>
            <div style={{height: "auto"}}>
                <div 
                    style={{
                        height: "50vh",
                        display: "flex",
                        alignItems: "start",
                        backgroundImage: `URL(`+ recordData?.pet_picture?.url +`)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat"
                    }}
                />
                <div style={{height: "auto",display: "flex", alignItems: "end", marginTop: "-10vh"}}>
                    <div
                        className="layout"
                        style={{
                            width: "100%",
                            height: "auto", 
                            backgroundColor: "#E6E7E8",
                            borderRadius: "40px 40px 0 0",
                        }}
                    >
                        <div
                            className="layout_detail"
                            style={{
                                padding: "5%",
                                height: "auto"
                            }}
                        >
                            <div style={{color: "#231F20", fontSize: "2rem", fontWeight: "600"}}>
                                {recordData?.pet_name}
                            </div>

                            <Row gutter={[12,12]} style={{marginTop: "0.5rem"}}>
                                <Col className="gutter-row" span={24}>
                                    <div style={{backgroundColor: "#fff", borderRadius: "10px", padding: "0.5rem"}}>
                                        <Row gutter={6}>
                                            <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <div style={{fontSize: "0.7rem"}}>
                                                    <div style={{color: "#58595B"}}>พักอยู่ที่</div>
                                                    <div style={{color: "#6C8CC7"}}>I LIVE AT</div>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={18} style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                                                <div
                                                    style={{
                                                        color: "#231F20",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem",
                                                        textTransform: "uppercase",
                                                        textAlign: "end"
                                                    }}
                                                >
                                                    {recordData?.property?.property_name}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={14}>
                                    <div style={{backgroundColor: "#fff", borderRadius: "10px", padding: "0.5rem"}}>
                                        <Row gutter={6}>
                                            <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <div style={{fontSize: "0.7rem"}}>
                                                    <div style={{color: "#58595B"}}>ประเภทสัตว์เลี้ยง</div>
                                                    <div style={{color: "#6C8CC7"}}>PET TYPE</div>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={12} style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                                                <div
                                                    style={{
                                                        color: "#231F20",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem",
                                                        textTransform: "uppercase"
                                                    }}
                                                >
                                                    {recordData?.pet_type_obj ? recordData?.pet_type_obj[0]?.pet_type_name : '-'}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={10}>
                                    <div style={{backgroundColor: "#fff", borderRadius: "10px", padding: "0.5rem"}}>
                                        <Row gutter={6}>
                                            <Col className="gutter-row" span={10} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <div style={{fontSize: "0.7rem"}}>
                                                    <div style={{color: "#58595B"}}>เพศ</div>
                                                    <div style={{color: "#6C8CC7"}}>GENDER</div>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={14} style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                                                <div
                                                    style={{
                                                        color: "#231F20",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem",
                                                        textTransform: "uppercase"
                                                    }}
                                                >
                                                    {recordData?.pet_gender == "1" ? "ชาย" : recordData?.pet_gender == "2" ? 'หญิง' : recordData?.pet_gender == "3" && "ไม่ระบุ"}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={24}>
                                    <div style={{backgroundColor: "#fff", borderRadius: "10px", padding: "0.5rem"}}>
                                        <Row gutter={6}>
                                            <Col className="gutter-row" span={10} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <div style={{fontSize: "0.7rem"}}>
                                                    <div style={{color: "#58595B"}}>สายพันธ์</div>
                                                    <div style={{color: "#6C8CC7"}}>BREED</div>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={14} style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                                                <div
                                                    style={{
                                                        color: "#231F20",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem",
                                                        textTransform: "uppercase"
                                                    }}
                                                >
                                                    {recordData?.pet_species_obj ? recordData?.pet_species_obj[0]?.pet_species_name : '-'}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={24}>
                                    <div style={{backgroundColor: "#fff", borderRadius: "10px", padding: "0.5rem"}}>
                                        <Row gutter={6}>
                                            <Col className="gutter-row" span={10} style={{display: "flex", justifyContent: "start", alignItems: "center"}}>
                                                <div style={{fontSize: "0.7rem"}}>
                                                    <div style={{color: "#58595B"}}>สี</div>
                                                    <div style={{color: "#6C8CC7"}}>COLOR</div>
                                                </div>
                                            </Col>
                                            <Col className="gutter-row" span={14} style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                                                <div
                                                    style={{
                                                        color: "#231F20",
                                                        fontWeight: "600",
                                                        fontSize: "0.8rem",
                                                        textTransform: "uppercase",
                                                        textAlign: "right"
                                                    }}
                                                >
                                                    {recordData?.pet_color ? recordData?.pet_color : '-'}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div
                            className="layout_spon"
                            style={{
                                height: "8vh",
                                backgroundColor: "#304658",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            <img src="https://lscmain.s3.ap-southeast-1.amazonaws.com/data_b4aac9492d.svg?updated_at=2023-11-08T10:59:59.604Z" style={{width: "90%"}} />
                        </div>
                        <div
                            className="layout_contact"
                            style={{
                                height: "18vh",
                                backgroundColor: "#FFC20E",
                                padding: "5%"
                            }}
                        >
                            <Row gutter={12}>
                                <Col className="gutter-row" span={18} style={{display: "flex", alignItems: "center"}}>
                                    <div>
                                        <div style={{color: "#231F20", fontSize: "1.3rem", fontWeight: "600"}}>
                                            OWNER'S CONTACT
                                        </div>
                                        <div style={{color: "#231F20", fontSize: "0.9rem", fontWeight: "600", lineHeight: "15px"}}>
                                            เบอร์โทรติดต่อฉุกเฉิน
                                        </div>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={6} style={{display: "flex", justifyContent: "center"}}>

                                    <div
                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            border: "1px solid #58595B",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50px",
                                        }}
                                    >
                                        <a href={`tel:${recordData?.owner_of_pet?.tel}`}>
                                        {/* <Icons.PhoneFilled style={{fontSize: "2rem", color: "#58595B"}}/> */}
                                            <Image preview={false} src={phoneico} />
                                        </a>
                                    </div>
                                </Col>
                            </Row>

                            <Row gutter={12} style={{marginTop: "1rem"}}>
                                <Col className="gutter-row" span={12}>
                                    <div style={{fontSize: "0.6rem"}}>
                                        <span style={{marginRight: "10px", color: "#58595B"}}>ชื่อ</span>
                                        <span style={{color: "#6C8CC7"}}>NAME</span>
                                    </div>
                                    <div style={{fontSize: "0.9rem"}}>
                                        <span style={{color: "#231F20"}}>
                                            {recordData?.owner_of_pet?.first_name}
                                        </span>
                                    </div>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    <div style={{fontSize: "0.6rem"}}>
                                        <span style={{marginRight: "10px", color: "#58595B"}}>โทร</span>
                                        <span style={{color: "#6C8CC7"}}>MOBILE NO.</span>
                                    </div>
                                    <div style={{fontSize: "0.9rem"}}>
                                        <span style={{color: "#231F20"}}>
                                            {recordData?.owner_of_pet?.tel}
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
    </Spin>
    )
}