import { Breadcrumb, Card, Checkbox, Col, Create, DatePicker, Divider, Form, Input, List, message, notification, Radio, RadioChangeEvent, RcFile, Row, Select, Space, Upload, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { ICouponGroup, IEventlog, IPrivillege, IPrivillegeCate, IPrivillegeUTLog, IProperties, IUnitType, IUnitTypeBedroom, IUserSubType } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import dayjs from "dayjs";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { PNCONDITION } from "./components/conditions";
import axios from "axios";

let resultK: any = [];
let dataAmountPlace:any = [];

export const ContentPostPrivilegeCreate: React.FC<IResourceComponentsProps> = () => {
    const { ...uploadProps_pixlist } = useStrapiUpload({ maxCount: 5, });
    const t = useTranslate();
    const { TextArea } = Input;
    const API_URL = useApiUrl();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: CreatePrivUTLog } = useCreate<IPrivillegeUTLog>();
    const log_endpoint = "even-logs";
    const { Option } = Select;
    const format = 'YYYY-MM-DD HH:mm';
    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;
    const [vsetdetail, setdetail] = useState<any>();
    const [vContentDisplay, setContentDisplay] = useState<any>("Privilege");
    const [vPrivID, setPrivID] = useState<any>("1");
    const [vPropertyID, setPropertyID] = useState<any>([]);
    const [checkedApp, setCheckedApp] = useState(false);
    const [checkedSMS, setCheckedSMS] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(false);
    const [vAllNoticeChan, setAllNoticeChan] = useState<any>([]);
    const [vSitUnitType, setSitUnitType] = useState<any>([]);
    const [vSitPriceType, setSitPriceType] = useState<any>([]);
    
    const [vDateStart, setDateStart] = useState<any>("");
    const [vDateEnd, setDateEnd] = useState<any>("");
    const [vDateEventStart, setDateEventStart] = useState<any>("");
    const [vDateEventEnd, setDateEventEnd] = useState<any>("");

    const [vDateKeepCouponStart, setDateKeepCouponStart] = useState<any>("");
    const [vDateKeepCouponEnd, setDateKeepCouponEnd] = useState<any>("");

    const [vDateUseCouponStart, setDateUseCouponStart] = useState<any>("");
    const [vDateUseCouponEnd, setDateUseCouponEnd] = useState<any>("");

    const [vUserConditionAmount, setUserConditionAmount] = useState<any>(0);
    const [vUserConditionName, setUserConditionName] = useState<any>("");
    const [vOnlyRight, setOnlyRight] = useState<any>();

    const [value, setValue] = useState(false);

    const [vIsTypeCoupon, setIsTypeCoupon] = useState(false);
    const [vIsTypeEvent, setIsTypeEvent] = useState(false);

    const [vRaPrivCoupon, setRaPrivCoupon] = useState<any>(0);
    const [vRaCalRight, setRaCalRight] = useState<any>(0);
    const [vRaLimitUser, setRaLimitUser] = useState<any>(0);
    const [vRaSpecificProperty, setRaSpecificProperty] = useState<any>(1);

    const [vRaRightCondition, setRaRightCondition] = useState<any>(0);

    const [vCondition, setCondition] = useState(false);
    const [vCanComment, setCanComment] = useState(false);

    const [vIsBirthDay, setIsBirthDay] = useState(false);

    const [vInputCouponAmount, setInputCouponAmount] = useState<any>(0);
    const [valueMax, setvalueMax] = useState<any>(0);
    const [vCoupon, setCoupon] = useState<any>(0);

    const [vInputEventCLout, setInputEventCLout] = useState<any>(0);
    const [vOnlyRightMulti, setOnlyRightMulti] = useState<any>([]);

    const [vCouponLeft, setCouponLeft] = useState<any>(0);
    const [vValueLocale, setValueLocale] = useState(true);

    const { formProps, saveButtonProps } = useForm<IPrivillege, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource: log_endpoint,
                    values: {
                        // user_profile            : user_data.id,
                        user_profile: user_data.user_profile.id,
                        log_time_stamp: Date.now(),
                        log_event: "Create",
                        menu_event: 'coupon',
                        SysPkID: recordData.data.id + ""
                    },
                    successNotification: false
                });

                if (vSitUnitType?.length > 0) {
                    createInPrivUtLog(recordData.data.id);
                }
            },
        }
    );

    const { selectProps: PrivilegeCategorySelectProps } = useSelect<IPrivillegeCate>({
        resource: "privilege-categories",
        optionLabel: "pc_name",
        optionValue: "id",
    });

    const { selectProps: PrivilegeSubCategorySelectProps } = useSelect<IPrivillegeCate>({
        resource: "privilege-sub-categories",
        optionLabel: "psc_name",
        optionValue: "id",
        filters: [
            {
                field: 'privilege_category_id',
                operator: 'eq',
                value: vPrivID,
            }
        ]
    });

    const unitTypeData = useList<IUnitType>({
        resource: "unit-types",
        metaData: {
            populate: ['property', 'property.id'],
        },
        config: {
            pagination: { current: 1, pageSize: 100 },
        },
    });

    const unitBedroom = useList<IUnitTypeBedroom>({
        resource: "unit-bedrooms",
        metaData: {
            populate: '*',
        },
        config: {
            pagination: { current: 1, pageSize: 100 },
        },
        queryOptions:{onSuccess(data) {
            let dataLoad: any = data?.data;

            dataAmountPlace = ([
                {
                    id: 1,
                    type_name: "น้อยกว่า 3 ล้าน",
                },
                {
                    id: 2,
                    type_name: "3 - 5.49 ล้าน",
                },
                {
                    id: 3,
                    type_name: "5.5 - 9.9 ล้าน",
                },
                {
                    id: 4,
                    type_name: "10 - 29.9 ล้าน",
                },
                {
                    id: 5,
                    type_name: "30 - 59.9 ล้าน",
                },
                {
                    id: 6,
                    type_name: "60 - 99.9 ล้าน",
                },
                {
                    id: 7,
                    type_name: "มากกว่า 100 ล้าน",
                },
            ]);
        },}
    })

    const { selectProps: PrivUserSubType } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    const { selectProps: PrivUserSubTypeOnlyCus } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    const { selectProps: PrivProperties } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'contains',
                value: 'N',
            },
        ]
    });

    const { selectProps: CouponCodeSelProps } = useSelect<ICouponGroup>({
        resource: "coupon-groups",
        optionLabel: "group_name",
        optionValue: "id",
        filters: [
            // {
            //     field       : 'coupons',
            //     operator    : 'ne',
            //     value       :  null,
            // },
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       :  "N",
            },
        ]
    });
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);

    let res_coupongroup: any
    let options_coupon:any = []

    const [vOpt, setOpt] = useState<any>();

    useEffect(() => {
        kk();
    }, [res_coupongroup])

    async function kk(){
        res_coupongroup = await axios.get(API_URL + "/like-systems/findCouponLeft", { headers: { Authorization: AuthStr } });
       

        for (let index = 0; index < res_coupongroup?.data.length; index++) {
            let keepData:any
            
            if (res_coupongroup?.data[index].deleteStatus != "Y") {
                keepData = {
                    label: res_coupongroup?.data[index].group_name,
                    value: res_coupongroup?.data[index].id
                }
                options_coupon.push(keepData)
            }
        }

        // res_coupongroup?.data?.map((item:any) => {
        //     if (item.deleteStatus != "Y") {
        //         options_coupon= [{
        //             label: item.group_name,
        //             value: item.id
        //         }]
        //     }
        // })

        // options_coupon = res_coupongroup?.data?.map((item:any) => ({
        //     label: item.group_name,
        //     value: item.id
        // }));

        console.log("options_coupon",options_coupon)
        setOpt(options_coupon)
    }

    const { ...uploadProps } = useStrapiUpload({ maxCount: 1, });

    const onChangeNoti = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    const [vPicID, setPicID] = useState<any>("");

    function fileChange(e: any) {
        console.log(e);
        if (e.file.response) {
            let res = e.file.response;
            setPicID(res[0].id)

        }
    }

    useEffect(() => {
        if(!!unitBedroom.data?.data && vInputCouponAmount){
            if(unitBedroom.data?.data.length > 0){
                setSitUnitType(unitBedroom.data?.data.map((e:any) => ({
                    id: e.id,
                    type_name: e.bedroomName,
                    amount: 0,
                    priv_id: '',
                    valueMax: (Number(vInputCouponAmount))
                })))
            }else{
                setSitUnitType([])
            }
        }

        if(dataAmountPlace?.length > 0 && vInputCouponAmount){
            setSitPriceType([
                {
                    id: 1,
                    type_name: "น้อยกว่า 3 ล้าน",
                    amount: 0,
                    valueMax: (Number(vInputCouponAmount))
                },
                {
                    id: 2,
                    type_name: "3 - 5.49 ล้าน",
                    amount: 0,
                    valueMax: (Number(vInputCouponAmount))
                },
                {
                    id: 3,
                    type_name: "5.5 - 9.9 ล้าน",
                    amount: 0,
                    valueMax: (Number(vInputCouponAmount))
                },
                {
                    id: 4,
                    type_name: "10 - 29.9 ล้าน",
                    amount: 0,
                    valueMax: (Number(vInputCouponAmount))
                },
                {
                    id: 5,
                    type_name: "30 - 59.9 ล้าน",
                    amount: 0,
                    valueMax: (Number(vInputCouponAmount))
                },
                {
                    id: 6,
                    type_name: "60 - 99.9 ล้าน",
                    amount: 0,
                    valueMax: (Number(vInputCouponAmount))
                },
                {
                    id: 7,
                    type_name: "มากกว่า 100 ล้าน",
                    amount: 0,
                    valueMax: (Number(vInputCouponAmount))
                },
            ])
        }
    }, [vInputCouponAmount])

    // console.log(">>> dataAmountPlace",dataAmountPlace)


    useEffect(() => {
      console.log('vSitPriceType : ', vSitPriceType)
    }, [vSitPriceType])
    
    

    function chkRoomType(id: any, type_name: any, amount: any) {
        let newData:any = [];
        for (let i = 0; i < vSitUnitType.length; i++) {
            if(vSitUnitType[i].id === id){
                vSitUnitType[i].amount = Number(amount)
                newData.push(vSitUnitType[i])
                for (let is = 0; is < vSitUnitType.length; is++) {
                    if(vSitUnitType[is].id !== id){
                        // valueMax
                        // vSitUnitType[is]
                        let aa = Number(vInputCouponAmount) -  (vSitUnitType.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))
                        console.log(aa)
                        vSitUnitType[is].valueMax = aa
                        console.log('vSitUnitType[is].amount : ', vSitUnitType[is].amount)
                        if(vSitUnitType[is].amount !== 0){
                            // if(vSitUnitType[is].amount > Number(amount)){
                                vSitUnitType[is].valueMax = Number(vSitUnitType[is].amount) + 1
                            // }
                            // else{
                            //     vSitUnitType[is].valueMax = Number(vSitUnitType[is].amount) - 1
                            // }
                        }

                        newData.push(vSitUnitType[is])
                    }
                }

            }
        }
        if(Number(Number(vInputCouponAmount) -  (newData.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))) === 0){
            for (let i = 0; i < vSitUnitType.length; i++) {
                vSitUnitType[i].valueMax = vSitUnitType[i].amount
            }
        }
        setSitUnitType((pre: any) => newData);
    }

    function chkPriceType(id: any, type_name: any, amount: any) {
        let newData:any = [];
        for (let i = 0; i < vSitPriceType?.length; i++) {
            if(vSitPriceType[i].id === id){
                vSitPriceType[i].amount = Number(amount)
                newData.push(vSitPriceType[i])
                for (let is = 0; is < vSitPriceType.length; is++) {
                    if(vSitPriceType[is].id !== id){
                        // valueMax
                        // vSitPriceType[is]
                        let aa = Number(vInputCouponAmount) -  (vSitPriceType.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))
                        console.log(aa)
                        vSitPriceType[is].valueMax = aa
                        console.log('vSitPriceType[is].amount : ', vSitPriceType[is].amount)
                        if(vSitPriceType[is].amount !== 0){
                                vSitPriceType[is].valueMax = Number(vSitPriceType[is].amount) + 1
                        }

                        newData.push(vSitPriceType[is])
                    }
                }

            }
        }
        if(Number(Number(vInputCouponAmount) -  (newData.map((p:any) => p.amount).reduce((accumulator:any, currentValue:any) => accumulator + currentValue,0))) === 0){
            console.log('1111')
            for (let i = 0; i < vSitPriceType.length; i++) {
                vSitPriceType[i].valueMax = vSitPriceType[i].amount
            }
        }
        setSitPriceType((pre: any) => newData)
    }

    function selectTime(time: any, timeString: any) {
        setDateStart("");
        setDateEnd("");

        setDateStart(dayjs(timeString[0]));
        setDateEnd(dayjs(timeString[1]));
    }

    function selectTimeEvent(time: any, timeString: any) {
        console.log('dayjs(timeString[0])', dayjs(timeString[0]))
        setDateEventStart("");
        setDateEventEnd("");

        setDateEventStart(dayjs(timeString[0]));
        setDateEventEnd(dayjs(timeString[1]));
    }

    function selectTimeKeepCouponDate(time: any, timeString: any) {
        setDateKeepCouponStart("");
        setDateKeepCouponEnd("");
        setDateKeepCouponStart(dayjs(timeString[0]));
        setDateKeepCouponEnd(dayjs(timeString[1]));
    }

    function selectTimeUseCouponDate(time: any, timeString: any) {
        setDateUseCouponStart("");
        setDateUseCouponEnd("");
        setDateUseCouponStart(dayjs(timeString[0]));
        setDateUseCouponEnd(dayjs(timeString[1]));
    }

    const onChangeCheckTypeCoupon = (e: CheckboxChangeEvent) => {
        e.target.checked ? setIsTypeCoupon(true) : setIsTypeCoupon(false)
        if (e.target.checked) { setIsTypeEvent(false) }
    };

    const onChangeCheckCondition = (e: CheckboxChangeEvent) => {
        e.target.checked ? setCondition(true) : setCondition(false)
    };

    const onChangeCheckTypeEvent = (e: CheckboxChangeEvent) => {
        e.target.checked ? setIsTypeEvent(true) : setIsTypeEvent(false)
        if (e.target.checked) { setIsTypeCoupon(false) }
    }

    const onChangeRadio1 = (e: RadioChangeEvent) => {
        setRaPrivCoupon(e.target.value);
    };

    const onChangeRadio2 = (e: RadioChangeEvent) => {
        setRaCalRight(e.target.value);
    };

    const onChangeRadio3 = (e: RadioChangeEvent) => {
        if(e.target.value == 1){
            setInputCouponAmount(9999);
            setvalueMax(9999);
        }
        setRaLimitUser(e.target.value);
    };

    function onChangeRadio4(e: RadioChangeEvent) {
        setRaRightCondition(e.target.value);

        switch (e.target.value) {
            case 1:
                setUserConditionName("สิทธิ 1 Unit");
                break;
            case 2:
                setUserConditionName("สิทธิ 1 Thai ID / Passport");
                break;
            case 3:
                setUserConditionName("ให้สิทธิเฉพาะตามประเภทผู้้ใช้");
                break;
            case 4:
                setUserConditionName("ให้สิทธิเฉพาะตาม Account Type");
                break;
            case 5:
                setUserConditionName("สิทธิของแต่ละ Room Type");
                break;
            case 6:
                setUserConditionName("สิทธิตามมูลค่า");
                break;
            case 7:
                setUserConditionName("สิทธิเฉพาะจำนวนสัตว์เลี้ยง");
                break;
            default:
                return '500';
        }
    }

    const onChangeRadio5 = (e: RadioChangeEvent) => {
        setRaSpecificProperty(e.target.value);
    };


    function onChangeOnlyRightMulti(va: any) {
        vOnlyRightMulti.pop();
        vOnlyRightMulti.push(va);
        setOnlyRightMulti(vOnlyRightMulti);
    }

    function onChangeOnlyRight(va: any) {
        setOnlyRight(va);
    }

    function createInPrivUtLog(priv_id: any) {
        for (let i = 0; i <= vSitUnitType.length - 1; i++) {
            CreatePrivUTLog({
                resource: 'privilege-ut-logs',
                values: {
                    user_condition_amount: String(vSitUnitType[i]?.amount),
                    // unit_type: vSitUnitType[i]?.id,
                    unit_type: null,
                    unit_bedroom: vSitUnitType[i]?.id,
                    privilege: priv_id + ""
                },
                successNotification: false
            });
        }
    }
    const [ vKeep, setVKeep ] = useState<any>([]);
    function onChangePropertyID(va: any) {
        let keepNeww:any = []
        for (let index = 0; index < va.length; index++) {
            keepNeww.push(va[index])
        }
        setVKeep(keepNeww)

        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
    };

    async function callCountCouponLeft(couponGroupID: any) {
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        resultK = await axios.get(API_URL + "/like-systems/countCouponLeft?CPGID=" + couponGroupID, { headers: { Authorization: AuthStr } });
        setCouponLeft(resultK.data);
    }

    const beforeUpload = (file: RcFile) => {
        var a = file.name;
        var name = a.split(".")[1];
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }

        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        if (name == "jfif") {
            message.error('You can only upload JPG/PNG file!');
            return Upload.LIST_IGNORE
        } else {
            return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
        }
    };

    const onChangeCheckApp = (e: CheckboxChangeEvent) => {
        setCheckedApp(e.target.checked);
    };

    const onChangeCheckSMS = (e: CheckboxChangeEvent) => {
        setCheckedSMS(e.target.checked);
    };

    const onChangeCheckEmail = (e: CheckboxChangeEvent) => {
        setCheckedEmail(e.target.checked);
    };

    function chkNoticeChan() {
        if (checkedSMS) {
            vAllNoticeChan.push(1);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedEmail) {
            vAllNoticeChan.push(2);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedApp) {
            vAllNoticeChan.push(3);
            setAllNoticeChan(vAllNoticeChan);
        }
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                            method: "post",
                            body: body
                            // mode: "no-cors"
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                resolve({
                                    default: res[0].url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setValueLocale(value);
    };

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const couponZeroMsg = () => {
        notification.warning({
            message: 'Warning',
            description: 'คูปองที่เลือกหมดแล้ว',
            duration: 2,
        });
    };

    const warningMsg = () => {
        notification.warning({
            message: 'Warning',
            description: 'จำนวนสิทธิไม่พอ',
            duration: 2,
        });
    };

    //console.log(">>> vSit",vSitUnitType)

    const [keepE, setKeepE] = useState<any>()
    function handlechange(e: any){

        if (e > vCouponLeft) {
            //console.log("vInputCouponAmount",vCouponLeft)
            setKeepE(vCouponLeft)
            setInputCouponAmount(vCouponLeft);
        }else{
            setKeepE(e)
            setInputCouponAmount(e);
            //console.log("e",e)
        }
    }
    
    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List
                title={t("content-post-privilege.title-create")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span style={{ color: "#cdba8c" }}>{t("content-post-privilege.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    values.createBy = user_data.user_profile.id
                    // values.notice_channel = values.notice_channel !== undefined ? values.notice_channel : value
                    values.notice_channel = value
                    
                    values.start_date = vDateStart
                    values.end_date = vDateEnd
                    values.pic = vPicID
                    if (vDateEventStart !== "") {
                        values.event_start_date = vDateEventStart
                        values.event_end_date = vDateEventEnd
                    }

                    if (vDateKeepCouponStart !== "") {
                        values.keep_coupon_start_date = vDateKeepCouponStart
                        values.keep_coupon_end_date = vDateKeepCouponEnd
                    }

                    if (vDateUseCouponStart !== "") {
                        values.use_coupon_start_date = vDateUseCouponStart
                        values.use_coupon_end_date = vDateUseCouponEnd
                    }

                    values.detail = vsetdetail

                    // Privilege กดรับตามคูปอง สิทธิ
                    values.privilege_promotion = vIsTypeCoupon
                    values.privilege_event = vIsTypeEvent

                    values.privilege_comment = vCanComment // เปิดให้ comment

                    // กำหนดเงื่อนไขการรับสิทธิ
                    values.user_condition = vCondition
                    values.user_condition_value = vRaRightCondition + ""


                    // values.user_condition_amount = vUserConditionAmount === "" ? 0 : vUserConditionAmount
                    values.user_condition_amount = parseInt(vUserConditionAmount)


                    values.user_condition_name = vUserConditionName


                    // radio box 1 privilege แบบคูปอง
                    if (vRaPrivCoupon == 1) {
                        values.privilege_coupon = true
                        values.coupon_group = vCoupon
                        values.coupon_amout = vInputCouponAmount

                    } else if (vRaPrivCoupon == 2) { // Privilege แบบสิทธิ
                        values.privilege_amount = true
                    } else {
                        values.privilege_coupon = false
                        values.privilege_amount = false
                    }

                    // radio box 2 วิธีการคำนวนสิทธิ
                    if (vRaCalRight == 1) { // โควต้ารวมทุกโครงการ (First come first serve)
                        values.coupon_condition = true
                    } else if (vRaCalRight == 2) { // แบ่งเท่า ๆ กันแต่ละโครงการ
                        values.coupon_condition = false
                    }

                    // radio box 3 Limit คนเข้าร่วม
                    if (vRaLimitUser == 1) { // ไม่จำกัด
                        values.limit_user = true
                    } else if (vRaLimitUser == 2) {  // จำกัดจำนวน
                        values.limit_user = false
                    }

                    if(vIsTypeCoupon == true){
                        values.coupon_amout = Number(vInputCouponAmount)
                        values.event_clout = Number(0)
                    }else if(vIsTypeEvent){
                        values.event_clout = Number(vInputEventCLout)
                        values.coupon_amout = Number(0)
                    }
                
                    if (vRaRightCondition === 3) { // ให้สิทธิเฉพาะ เก็บ user_condition_sub_types แบบ multi
                        values.user_condition_sub_types = vOnlyRightMulti[0].length > 0 ? vOnlyRightMulti[0] : null
                    }

                    if (vRaRightCondition === 4) { // Account Type เก็บ user_condition_sub_type แบบ 1 ตัว
                        values.user_condition_sub_types = vOnlyRight !== undefined ? vOnlyRight : null
                    }

                    // vSitPriceType

                    if (vIsBirthDay == false) {
                        // let value_m_1 = vSitUnitType.find((item:any) => item?.type_name == "น้อยกว่า 3 ล้าน");
                        // let value_m_2 = vSitUnitType.find((item:any) => item?.type_name == "3 - 5.49 ล้าน");
                        // let value_m_3 = vSitUnitType.find((item:any) => item?.type_name == "5.5 - 9.9 ล้าน");
                        // let value_m_4 = vSitUnitType.find((item:any) => item?.type_name == "10 - 29.9 ล้าน");
                        // let value_m_5 = vSitUnitType.find((item:any) => item?.type_name == "30 - 59.9 ล้าน");
                        // let value_m_6 = vSitUnitType.find((item:any) => item?.type_name == "60 - 99.9 ล้าน");
                        // let value_m_7 = vSitUnitType.find((item:any) => item?.type_name == "มากกว่า 100 ล้าน");

                        let value_m_1 = vSitPriceType.find((item:any) => item?.type_name == "น้อยกว่า 3 ล้าน");
                        let value_m_2 = vSitPriceType.find((item:any) => item?.type_name == "3 - 5.49 ล้าน");
                        let value_m_3 = vSitPriceType.find((item:any) => item?.type_name == "5.5 - 9.9 ล้าน");
                        let value_m_4 = vSitPriceType.find((item:any) => item?.type_name == "10 - 29.9 ล้าน");
                        let value_m_5 = vSitPriceType.find((item:any) => item?.type_name == "30 - 59.9 ล้าน");
                        let value_m_6 = vSitPriceType.find((item:any) => item?.type_name == "60 - 99.9 ล้าน");
                        let value_m_7 = vSitPriceType.find((item:any) => item?.type_name == "มากกว่า 100 ล้าน");

                        if(value_m_1 && value_m_2 && value_m_3 && value_m_4 && value_m_5 && value_m_6 && value_m_7){
                            values.unit_value_3m = value_m_1?.amount;
                            values.unit_value_4m =  value_m_2?.amount;
                            values.unit_value_9m =  value_m_3?.amount;
                            values.unit_value_29m =  value_m_4?.amount;
                            values.unit_value_59m =  value_m_5?.amount;
                            values.unit_value_99m =  value_m_6?.amount;
                            values.unit_value_100m =  value_m_7?.amount;
                        }
                    }

                    // property_under โครงการที่ได้รับสิทธิ
                    // 1 = ALL
                    // 2 = Only Major
                    // 3 = Non Major
                    // 4 = specific
                    values.property_under = vRaSpecificProperty + ""

                    

                    if (vIsBirthDay) {
                        values.birthday = vIsBirthDay
                        values.privilege_user_sub_type = 1 // ID ของ Guest
                    }

                    if (vValueLocale) {
                        values.locale = "th"
                    } else {
                        values.locale = "en"
                    }

                    chkNoticeChan();
                    if (vAllNoticeChan.length > 0) {
                        values.notice_channel_types = vAllNoticeChan
                    }

                    // if (vCouponLeft == 0 && vRaPrivCoupon == 1) { // ทำเช็คว่า coupon ที่เลิอกมาเหลือ 0 จะไม่ให้ create
                    //     couponZeroMsg()
                    // } else {
                    //     console.log('k',values)
                    //     return (
                    //         formProps.onFinish &&
                    //         formProps.onFinish(mediaUploadMapper(values))
                    //     );
                    // }

                    if (vCouponLeft == 0 && vRaPrivCoupon == 1) { // ทำเช็คว่า coupon ที่เลิอกมาเหลือ 0 จะไม่ให้ create
                        couponZeroMsg()
                    // } else if(Number(vInputCouponAmount) < 34 && vRaPrivCoupon == 1 && vRaCalRight == 2) { // hard code build hotfix
                    // } else if(Number(vInputCouponAmount) < 34 && vIsTypeCoupon && vRaCalRight == 2) { // hard code build hotfix
                    } else if(Number(vInputCouponAmount) < 34 && vIsTypeCoupon && vRaCalRight == 2 && Number(vRaSpecificProperty) == 1) { // hard code build hotfix
                        warningMsg()
                    }else{
                        // console.log('vInputCouponAmount', vInputCouponAmount)
                        // console.log('vIsTypeCoupon', vIsTypeCoupon)
                        // console.log('vRaCalRight', vRaCalRight)
                        // console.log('vRaSpecificProperty', vRaSpecificProperty)

                        
                        console.log('k',values)
                        return (
                            formProps.onFinish &&
                            formProps.onFinish(mediaUploadMapper(values))
                        );
                    }


                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <div style={{ marginBottom: '2%' }}>
                            <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" disabled />
                        </div>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: "1%" }}>
                                <span style={{ marginRight: "3%", fontSize: ".70vw", fontWeight: "bold", color: "#000" }}>Display Content :</span>
                                <span style={{ fontSize: "13px", color: "#000" }}>{vContentDisplay}</span>
                            </div>

                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        name="privilege_category"
                                        label="Privilege category"
                                        rules={[
                                            {
                                                required: true, message: 'Please select category.'
                                            },
                                        ]}
                                    >
                                        <Select
                                            style={{ width: "95%" }}
                                            placeholder={t("select.sel")}
                                            {...PrivilegeCategorySelectProps}
                                            onSelect={(value: any, record: any) => {

                                                if (record.label.indexOf("Birthday") >= 0) {
                                                    setIsBirthDay(true)
                                                } else {
                                                    setIsBirthDay(false)
                                                }
                                                setPrivID(value);
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>

                                    <Form.Item
                                        name="privilege_sub_category"
                                        label="Privilege sub category"
                                    >
                                        <Select
                                            style={{ width: "100%" }}
                                            // mode="multiple"
                                            placeholder={t("select.sel")}
                                            {...PrivilegeSubCategorySelectProps}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item label="Title" name="name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter title.'
                                            },
                                        ]}>
                                        <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <Form.Item label="Short Detail" name="short_detail"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter Sub Title.'
                                            },
                                        ]}>
                                        <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="เนื้อหา"
                                // name="detail"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <CKEditor
                                    // name="detail"
                                    editor={ClassicEditor}
                                    //data={recordData.detail}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "link",
                                            "bulletedList",
                                            "numberedList",
                                            "blockQuote",
                                            // "ckfinder",
                                            "|",
                                            "imageTextAlternative",
                                            "imageUpload",
                                            "imageStyle:full",
                                            "imageStyle:side",
                                            // "|",
                                            // "mediaEmbed",
                                            // "insertTable",
                                            // "tableColumn",
                                            // "tableRow",
                                            // "mergeTableCells",
                                            // "|",
                                            // "undo",
                                            // "redo"
                                        ],
                                        extraPlugins: [uploadPlugin],
                                        mediaEmbed: {
                                            // configuration...
                                            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
                                        },
                                        image: {
                                            toolbar: ['']
                                        }
                                    }}

                                    onInit={(editor: any) => {
                                        // You can store the "editor" and use when it is needed.
                                        // console.log("Editor is ready to use!", editor);
                                        // console.log(
                                        //     "toolbar: ",
                                        //     Array.from(editor.ui.componentFactory.names())
                                        // );
                                        // console.log(
                                        //     "plugins: ",
                                        //     ClassicEditor.builtinPlugins.map((plugin:any) => plugin.pluginName)
                                        // );
                                    }}
                                    onChange={(event: any, editor: any) => {
                                        const data = editor.getData();
                                        setdetail(data);
                                        // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
                                    }}
                                    onBlur={(editor: any) => {
                                        console.log("Blur.", editor);
                                    }}
                                    onFocus={(editor: any) => {
                                        console.log("Focus.", editor);
                                    }}
                                />
                            </Form.Item>
                            <Divider></Divider>

                            {/* ---------------------------------------------------------------------------------------------------------------- */}
                            {/* ---------------------------------------------------------------------------------------------------------------- */}
                            {/* ---------------------------------------------------------------------------------------------------------------- */}
                            <Col span={24}>
                                <Checkbox onChange={onChangeCheckTypeCoupon} checked={vIsTypeCoupon}>Privilege ที่สามารถกดรับ ตามโค้ดคูปอง / สิทธิ</Checkbox>
                            </Col>

                            {
                                vIsTypeCoupon &&
                                    <>
                                        <Row>
                                            <Col span={2} />
                                            <Col span={22} >
                                                <Radio.Group onChange={onChangeRadio1}>
                                                    <Space direction="vertical">
                                                        <div style={{ width: "800px" }}>
                                                            <Radio value={1} style={{ marginBottom: "0%" }}>Privilege แบบคูปอง </Radio>
                                                            {/* <Input placeholder="รหัสคูปอง" type={"number"} min={0} size="small" style={{width: "25%", borderRadius: "10px"}} /> */}

                                                            {
                                                                vRaPrivCoupon == 1 ?
                                                                    <>
                                                                        {/* kkkk */}
                                                                        <Select
                                                                            style={{ width: "22%" }}
                                                                            size="small"
                                                                            // mode="multiple"
                                                                            placeholder="รหัสคูปอง"
                                                                            onSelect={(value: any) => {
                                                                                // console.log(value);
                                                                                // setStatus(value);
                                                                                setCoupon(value);
                                                                            }}
                                                                            onChange={(value: any) => {
                                                                                callCountCouponLeft(value);
                                                                                // setCoupon(value);
                                                                            }}
                                                                            // {...CouponCodeSelProps}
                                                                            options={vOpt}
                                                                        />


                                                                        {/* <Select 
                                                                            style={{width:"22%"}} 
                                                                            size="small"
                                                                            // mode="multiple"
                                                                            placeholder="รหัสคูปอง"
                                                                            onSelect={(value: any) => {
                                                                                // console.log(value);
                                                                                // setStatus(value);
                                                                                setCoupon(value);
                                                                            }}
                                                                            onChange={(value: any) => {
                                                                                // callCountCouponLeft(value);
                                                                                // setCoupon(value);
                                                                            }}
                                                                            // {...CouponCodeSelProps}
                                                                        >
                                                                            {
                                                                                timeSlotData !== undefined?
                                                                                    timeSlotData.map((item:any,key:any) => {
                                                                                        return <>
                                                                                            {item !== null? 
                                                                                                <>
                                                                                                    <Option key={key} value={item.id}>{moment(item.time_start, "HH:mm").format('HH:mm') + ' - ' + moment(item.time_end, "HH:mm").format('HH:mm')}</Option>
                                                                                                </>
                                                                                            :<></>}
                                                                                        </>
                                                                                    })
                                                                                :<></>
                                                                            }
                                                                        </Select> */}





                                                                        <span style={{ marginLeft: "2%" }}></span>
                                                                        {/* จำนวนสิทธิ :  */}
                                                                        <Input
                                                                            type={"number"}
                                                                            // placeholder="จำนวนสิทธิคงเหลือ"
                                                                            // placeholder="จำนวนสิทธิคงเหลือ"
                                                                            defaultValue={0}
                                                                            min={0}
                                                                            max={vCouponLeft}
                                                                            size="small"
                                                                            style={{ width: "22%" }}
                                                                            value={keepE}
                                                                            //value={vInputCouponAmount <= vCouponLeft ? vInputCouponAmount : vCouponLeft}
                                                                            // onKeyPress={(e) => e.preventDefault()}
                                                                            onChange={e => {
                                                                                handlechange(e.target.value);
                                                                                // setInputCouponAmount(e.target.value);
                                                                                setvalueMax(e.target.value);
                                                                            }}
                                                                        />

                                                                        <span style={{ fontSize: ".7vw", fontWeight: "600" }}> จำนวนสิทธิคงเหลือ {vCouponLeft} </span>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                        </div>

                                                        <div>
                                                            <Radio value={2} style={{ marginBottom: "0%" }}>Privilege แบบสิทธิ </Radio>
                                                            {
                                                                vRaPrivCoupon == 2 ?
                                                                    <>
                                                                        {/* <Input type={"number"} defaultValue={0} min={0} size="small" style={{width: "25%", borderRadius: "10px"}} value={vInputCouponAmount} onChange={e => setInputCouponAmount(e.target.value)}/> */}
                                                                        <Input
                                                                            type={"number"}
                                                                            placeholder="จำนวนสิทธิคงเหลือ"
                                                                            // defaultValue={0} 
                                                                            min={0}
                                                                            size="small"
                                                                            style={{ width: "22%", borderRadius: "10px" }}
                                                                            // value={vInputCouponAmount}
                                                                            onChange={e => {
                                                                                setInputCouponAmount(e.target.value);
                                                                                setvalueMax(e.target.value);
                                                                            }}
                                                                        />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                        </div>
                                                    </Space>
                                                </Radio.Group>
                                            </Col>
                                        </Row>

                                        <div style={{ marginBottom: "1%" }}></div>
                                        <Row>
                                            <Col span={1} />
                                            <Col span={12} >
                                                <div>วิธีการคำนวนสิทธิ</div>
                                            </Col>
                                        </Row>
                                        <div style={{ marginBottom: "1%" }}></div>
                                        <Row>
                                            <Col span={2} />
                                            <Col span={12} >
                                                <Radio.Group onChange={onChangeRadio2}>
                                                    <Space direction="vertical">
                                                        <Radio value={1} style={{ marginBottom: "0%" }}>โควต้ารวมทุกโครงการ {"(First come first serve)"} </Radio>
                                                        <Radio value={2} style={{ marginBottom: "0%" }}>แบ่งเท่า ๆ กัน แต่ละโครงการ </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Col>
                                        </Row>




                                        <div style={{ marginBottom: "1%" }}></div>
                                        <Row>
                                            <Col span={1} />
                                            <Col span={12} >
                                                <div>ระยะเวลา</div>
                                            </Col>
                                        </Row>
                                        <div style={{ marginBottom: "1%" }}></div>

                                        <Row>
                                            <Col span={2}></Col>
                                            <Col span={4}>
                                                {/* <span><b><div style={{fontSize: "12px"}}>Period การกดรับโค้ดคูปอง / สิทธิ : </div><>&nbsp;</></b></span> */}
                                                <span><b><div style={{ fontSize: "12px" }}>Period การกดรับ : </div><>&nbsp;</></b></span>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                // name="" 
                                                >
                                                    <DatePicker.RangePicker
                                                        size="small"
                                                        showTime
                                                        style={{ width: "100%" }}
                                                        onChange={(date, dateString) => selectTimeKeepCouponDate(date, dateString)}
                                                        format={format}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {/* <div style={{marginBottom:"1%"}}></div> */}

                                        <Row style={{ marginTop: "-2%" }} >
                                            <Col span={2}></Col>
                                            <Col span={4}>

                                                {/* <span><b><div style={{fontSize: "12px"}}>Period การกดใช้โค้ดคูปอง / สิทธิ : </div><>&nbsp;</></b></span> */}
                                                <span><b><div style={{ fontSize: "12px" }}>Period การกดใช้ : </div><>&nbsp;</></b></span>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                // name="" 
                                                >
                                                    <DatePicker.RangePicker
                                                        size="small"
                                                        showTime
                                                        style={{ width: "100%" }}
                                                        onChange={(date, dateString) => selectTimeUseCouponDate(date, dateString)}
                                                        format={format}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </>
                            }

                            {/* ---------------------------------------------------------------------------------------------------------------- */}
                            {/* ---------------------------------------------------------------------------------------------------------------- */}
                            {/* ---------------------------------------------------------------------------------------------------------------- */}


                            <Col span={24}>
                                <Checkbox onChange={onChangeCheckTypeEvent} checked={vIsTypeEvent}>Privilege กิจกรรม</Checkbox>
                            </Col>

                            {
                                vIsTypeEvent &&
                                    <>
                                        <Row style={{ marginTop: "2%" }} >
                                            <Col span={2}></Col>
                                            <Col span={7}>
                                                {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                                <span><b><div style={{ fontSize: "12px" }}>Event date : </div><>&nbsp;</></b></span>
                                            </Col>
                                            <Col span={9}>
                                                <Form.Item
                                                    // name="privilege_event_start_date" 
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please Event Date.'
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker.RangePicker
                                                        size="small"
                                                        showTime
                                                        style={{ width: "100%" }}
                                                        onChange={(date, dateString) => selectTimeEvent(date, dateString)}
                                                        format={format}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div style={{ marginBottom: "-2%" }}></div>
                                        <Row>
                                            <Col span={1} />
                                            <Col span={12} >
                                                <div style={{ marginBottom: "0%" }}>Limit คนเข้าร่วม</div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={2} />
                                            <Col span={12} >
                                                <Form.Item
                                                 name="limit_user"
                                                 rules={[
                                                     {
                                                         required: true, message: 'Please Limit User.'
                                                     },
                                                 ]}
                                                >
                                                    <Radio.Group onChange={onChangeRadio3}>
                                                        <Space direction="vertical">
                                                            <Radio value={1} style={{ marginBottom: "0%" }} checked={true}>ไม่จำกัดจำนวน</Radio>
                                                            <div>
                                                                <Radio value={2} style={{ marginBottom: "0%" }}>จำกัดจำนวน </Radio>
                                                                {/* <Input type={"number"} min={0} size="small" style={{width: "50%", borderRadius: "10px"}} />  */}
                                                                {vRaLimitUser == 2 &&
                                                                    <Input
                                                                        type={"number"}
                                                                        placeholder="จำนวนคนเข้าร่วม"
                                                                        // defaultValue={0}
                                                                        min={0}
                                                                        size="small"
                                                                        style={{ width: "50%", borderRadius: "10px" }}
                                                                        // value={vInputCouponAmount}
                                                                        onChange={e => {
                                                                            setInputEventCLout(e.target.value);
                                                                            setvalueMax(e.target.value)
                                                                            setInputCouponAmount(e.target.value);
                                                                            setvalueMax(e.target.value);
                                                                        }}
                                                                    />  
                                                                }
                                                            </div>

                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item>
                                                
                                            </Col>
                                            {/* <Col span={10}>
                                            <Input type={"number"} min={0} size="small" style={{width: "50%", borderRadius: "10px"}} />
                                            </Col> */}
                                        </Row>
                                        <div style={{ marginBottom: "2%" }}></div>
                                        <Row>
                                            <Col span={1}></Col>
                                            <Col span={4}>
                                                <div style={{ marginBottom: "0%" }}>เปิดให้ Comment</div>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                name="privilege_comment"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select.'
                                                    },
                                                ]}
                                                >
                                                    <Select
                                                        // placeholder={t("select.sel")}
                                                        size="small"
                                                        placeholder="เปิดให้ Comment"
                                                        style={{ width: '40%' }}
                                                        onSelect={(value: any) => {
                                                            // console.log(value);
                                                            // setStatus(value);
                                                            setCanComment(value);
                                                        }}
                                                        options={[
                                                            {
                                                                label: "On",
                                                                value: true,
                                                            },
                                                            {
                                                                label: "Off",
                                                                value: false,
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>
                                                
                                            </Col>

                                        </Row>

                                    </>
                            }

                            <div style={{ marginTop: "5%" }}/>

                            <Row>
                                {/* <Col span={2} /> */}
                                <Col span={12} >
                                    <div>Set up สิทธิการใช้งาน</div>
                                </Col>
                            </Row>

                            <Row style={{ marginTop: "1%" }} >
                                <Col span={1}></Col>
                                <Col span={5}>
                                    {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                    {/* <span><b><div style={{fontSize: "12px"}}>User ที่ได้รับสิทธิ : </div><>&nbsp;</></b></span> */}
                                    <span><b><div style={{ color: "#f5222d", fontWeight: "300", display: "inline-block", fontSize: "16px", marginRight: "5px" }}>*</div><div style={{ fontSize: "12px", display: "inline-block" }}>User ที่มองเห็น : </div><>&nbsp;</></b></span>
                                </Col>
                                <Col span={9}>
                                    {
                                        vIsBirthDay ?
                                            <Form.Item
                                            // name="privilege_user_sub_type" 
                                            >
                                                <Select
                                                    size="small"
                                                    style={{ width: "95%" }}
                                                    // mode="multiple"
                                                    placeholder={t("select.sel")}
                                                    // {...PrivUserSubType}
                                                    defaultValue={1}
                                                    disabled
                                                >
                                                    <Option value={1}>Owner</Option>
                                                </Select>
                                            </Form.Item>
                                            :
                                            <Form.Item
                                                name="privilege_user_sub_type"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select.'
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    size="small"
                                                    style={{ width: "95%" }}
                                                    mode="multiple"
                                                    placeholder={t("select.sel")}
                                                    {...PrivUserSubType}
                                                />
                                            </Form.Item>
                                    }

                                </Col>
                            </Row>

                            <Row style={{ marginTop: "-1%" }} >
                                <Col span={1}></Col>
                                <Col span={5}>
                                    {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                    <span>
                                        <b><div style={{ fontSize: "12px" }}>โครงการที่ได้รับสิทธิ : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={15}>
                                    <Radio.Group onChange={onChangeRadio5} value={vRaSpecificProperty}>
                                        <Space direction="vertical">

                                            <div>
                                                <Radio value={1} style={{ marginBottom: "0%" }}> ทุกโครงการ </Radio>
                                            </div>
                                            <div>
                                                <Radio value={2} style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Major เท่านั้น </Radio>
                                            </div>

                                            <div>
                                                <Radio value={3} style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Non Major เท่านั้น </Radio>
                                            </div>
                                            <div style={{ width: '250px' }}>
                                                <Radio value={4} style={{ marginBottom: "0%" }}> ระบุโครงการ </Radio>
                                                {
                                                    vRaSpecificProperty == 4 ?
                                                        <>
                                                            <Form.Item
                                                                name="privilege_properties"
                                                                rules={[
                                                                    {
                                                                        required: vKeep.length == 0 ? true : false, message: 'Please select property.'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    size="small"
                                                                    style={{ width: "95%" }}
                                                                    mode="multiple"
                                                                    placeholder={t("select.sel")}
                                                                    {...PrivProperties}
                                                                    onChange={onChangePropertyID}
                                                                />
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </Space>
                                    </Radio.Group>
                                    {/* <Form.Item 
                                        name="privilege_properties"
                                     >
                                        <Select 
                                            size="small"
                                            style={{width:"95%"}}
                                            mode="multiple"
                                            placeholder={t("select.sel")}
                                            {...PrivProperties}
                                            onChange={onChangePropertyID}
                                        />
                                    </Form.Item> */}
                                </Col>
                            </Row>

                            <div style={{ marginBottom: "3%" }}></div>
                            {!vIsBirthDay &&
                                <PNCONDITION
                                    onChangeCheckCondition={onChangeCheckCondition}
                                    vCondition={vCondition}
                                    vIsTypeCoupon={vIsTypeCoupon}
                                    setUserConditionAmount={setUserConditionAmount}
                                    PrivUserSubType={PrivUserSubTypeOnlyCus}
                                    onChangeRadio4={onChangeRadio4}
                                    setUserConditionName={setUserConditionName}
                                    vRaRightCondition={vRaRightCondition}
                                    onChangeOnlyRightMulti={onChangeOnlyRightMulti}
                                    onChangeOnlyRight={onChangeOnlyRight}
                                    // unitTypeData={unitTypeData.data?.data}
                                    unitTypeData={unitBedroom.data?.data}
                                    vPropertyID={vPropertyID}
                                    chkRoomType={chkRoomType}

                                    vRaPrivCoupon={vRaPrivCoupon}
                                    vCouponLeft={vCouponLeft}
                                    //vInputCouponAmount={vInputCouponAmount}
                                    vInputCouponAmount={keepE}
                                    valueMax={valueMax}
                                    vSitUnitType={vSitUnitType}
                                    dataAmountPlace={dataAmountPlace}
                                    vSitPriceType={vSitPriceType}
                                    chkPriceType={chkPriceType}
                                />
                            }
                            <Divider />

                            <Row>
                                <Col span={12}>
                                    <Form.Item
                                        name="start_date"
                                        label="Content public date"
                                        rules={[
                                            {
                                                required: true, message: 'Please select public date.'
                                            },
                                        ]}
                                    >
                                        <DatePicker.RangePicker
                                            showTime
                                            // size="small"
                                            style={{ width: "95%" }}
                                            onChange={(date, dateString) => selectTime(date, dateString)}
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    {/* <Form.Item name="notice_channel" 
                                // label={t("notice-ch")}
                                label="Notice Channel"
                            >
                                <Radio.Group
                                    options={[
                                        {
                                            label: "แจ้งเตือน",
                                            value: true,
                                        },
                                        {
                                            label: "ไม่แจ้งเตือน",
                                            value: false,
                                        }
                                    ]}
                                    onChange={onChangeNoti}
                                />
                            </Form.Item> */}
                                    {/* <Form.Item name="seq" 
                                // label={t("notice-ch")}
                                // label="Content Privilege Sequences"
                                label="Content Sequences"
                            >
                                <Input 
                                    type={"number"}
                                    placeholder="Sequences"
                                    // defaultValue={0}
                                    min={0}
                                    size="small"
                                    style={{width: "100%", borderRadius: "5px"}}
                                    // value={vInputSeq}
                                    onChange={e => setInputSeq(e.target.value)}
                                />
                            </Form.Item> */}

                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[
                                            {
                                                required: true, message: 'Please select status.'
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")}
                                            // size="small"
                                            style={{ width: '95%' }}
                                            onSelect={(value: any) => {
                                                console.log(value);
                                                // setStatus(value);
                                            }}
                                            options={[
                                                {
                                                    label: "Public",
                                                    value: "PUBLIC",
                                                },
                                                {
                                                    label: "Draft",
                                                    value: "DRAFT",
                                                },
                                                {
                                                    label: "End",
                                                    value: "END",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row>
                                <Col span={12}>
                                    <Form.Item name="seq"
                                        // label={t("notice-ch")}
                                        // label="Content Activity &amp; Event Sequences"
                                        label="Content Sequences"
                                    >
                                        <Input
                                            type={"number"}
                                            placeholder="Sequences"
                                            // defaultValue={0}
                                            min={0}
                                            // size="small"
                                            style={{ width: "95%", borderRadius: "5px" }}
                                        // value={vInputSeq}
                                        // onChange={e => setInputSeq(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}></Col>
                            </Row> */}
                        </Card>
                    </Col>
                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel"><span style={{ color: "#f5222d", fontWeight: "300", fontSize: "16px", marginRight: "5px" }}>*</span>Thumbnail</span></div>
                            <Divider></Divider>
                            <Form.Item
                                name="pic"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload image.'
                                    },
                                ]}
                            >

                                <Upload.Dragger
                                    style={{ borderRadius: 10 }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    // multiple
                                    {...uploadProps}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                            </Form.Item>
                            <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Picture 750*500 PNG, JPG Maximum size 5MB</p>
                        </Card>


                        <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel">Notifications</span></div>
                            <Divider></Divider>
                            <Form.Item name="notice_channel" label={t("notice-ch")}>
                                <Radio.Group
                                    options={[
                                        {
                                            label: "แจ้งเตือน",
                                            value: true,
                                        },
                                        {
                                            label: "ไม่แจ้งเตือน",
                                            value: false,
                                        }
                                    ]}
                                    onChange={onChangeNoti}
                                    // value={value}
                                    defaultValue={value}
                                />
                            </Form.Item>

                            {value === true ?
                                <>
                                    <Form.Item label="Notice Date"
                                        name="notice_date"
                                        rules={[
                                            { required: true, message: 'Please select date and time.' },
                                        ]}
                                    >
                                        <DatePicker
                                            showTime
                                            style={{ width: "100%" }}
                                            format={format}
                                        />
                                    </Form.Item>

                                    <Form.Item label="Customer Type"
                                        name="notice_customer_type"
                                        rules={[
                                            { required: true, message: 'Please select customer type.' },
                                        ]}
                                    >
                                        <Select
                                            // size="small"
                                            style={{ width: "100%" }}
                                            mode="multiple"
                                            placeholder={t("select.sel")}
                                            // onChange={onChangeOnlyRightMulti}
                                            {...PrivUserSubTypeOnlyCus}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: "7%" }}>
                                        <Checkbox checked={checkedApp} onChange={onChangeCheckApp}>
                                            In App
                                        </Checkbox>

                                        {
                                            checkedApp ?
                                                <>
                                                    <Form.Item name="notice_inApp"
                                                        rules={[
                                                            { required: true, message: 'Please Type Message.' },
                                                        ]}
                                                    >
                                                        <Input placeholder="In app message" maxLength={85} />
                                                    </Form.Item>
                                                </>
                                                :
                                                <></>
                                        }

                                        <div style={{ marginBottom: '1%' }}></div>
                                        <Checkbox checked={checkedSMS} onChange={onChangeCheckSMS}>
                                            SMS
                                        </Checkbox>

                                        {
                                            checkedSMS ?
                                                <>
                                                    <Form.Item name="notice_sms"
                                                        rules={[
                                                            { required: true, message: 'Please Type Message.' },
                                                        ]}
                                                    >
                                                        <Input placeholder="SMS message" maxLength={85} />
                                                    </Form.Item>
                                                </>
                                                :
                                                <></>
                                        }

                                        <div style={{ marginBottom: '1%' }}></div>
                                        <Checkbox checked={checkedEmail} onChange={onChangeCheckEmail}>
                                            Email
                                        </Checkbox>
                                        {
                                            checkedEmail ?
                                                <>
                                                    <Form.Item name="notice_email"
                                                        rules={[
                                                            { required: true, message: 'Please Type Message.' },
                                                        ]}
                                                    >
                                                        <TextArea placeholder="Message" maxLength={255} style={{ height: "30px", maxHeight: "80px" }} />
                                                    </Form.Item>
                                                </>
                                                :
                                                <></>
                                        }
                                    </Form.Item>
                                </>
                                :
                                <></>
                            }
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};