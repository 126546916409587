/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, TableProps, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { TextArea } from "react-mde";
import axios from "axios";
import { TOKEN_KEY } from "../../../constants";

export const RepairDocumentOtherList: React.FC<IResourceComponentsProps> = () => {
    const [ Tab, SetTab] = useState<any>();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const tabTermActive = localStorage.getItem("keyTabTermRepair");
    const { mutate } = useUpdate<any>();
    const [form] = Form.useForm();
    const { TabPane } = Tabs;
    
    const API_URL = useApiUrl();
    const { create, edit } = useNavigation();
    const { confirm } = Modal;
    const [TabKey, SetTabKey] = useState<any>("true");
    const [ count, setCount] = useState(21);

    const token = localStorage.getItem(TOKEN_KEY);

    const [dataList, setDataList] = useState<any>([]);
    const [dataListInActive, setDataListInActive] = useState<any>([]);
    const [dataBlank, setDataBlank] = useState<any>([]);
    const prop_id = localStorage.getItem("PROPERTY_ID");

    // console.log('currentLocale', currentLocale)
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);


    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "repair-documents",
        metaData: {
            populate: ['*', 'property', 'repair_document_category', 'createBy', 'updateBy'],
        },
        pagination:{
            pageSize: 1000,
        },
        permanentFilter: [
            {
                field: "property][id]",
                operator: prop_list ? "in" : "eq",
                value: prop_list ? prop_list: prop_id,
                // value: prop_id, 
            }
        ],
        queryOptions: {
          onSuccess: (data:any) => {
            let dataLoad: any = data?.data;

            console.log('dataLoad', dataLoad)

            // dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            if(prop_id){
                dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            }

            // setDataList(dataLoad);

            let xx = dataLoad.filter((e:any)=> e.deleteStatus == "N");
            let newDataList = []
            for (let index = 0; index < xx.length; index++) {
                //dataList.push(xx[index]);
                newDataList.push(xx[index])
            }
            if (!!newDataList && newDataList !== null && !newDataList) {
                setDataList(newDataList)
            }else{
                setDataList(xx)
            }


            let yy = dataLoad.filter((e:any)=> e.deleteStatus == "Y");
            let newDataInActive = []
            for (let index = 0; index < yy.length; index++) {
                //dataListInActive.push(yy[index]);
                newDataInActive.push(yy[index])
            }
            if (!!newDataInActive && newDataInActive !== null && !newDataInActive) {
                setDataListInActive(newDataInActive)
            }else{
                setDataListInActive(yy)
            }

            dataList?.reverse();
            dataListInActive?.reverse();

          },
        },
    });

    console.log('dataList', dataList)
    console.log('dataListInActive', dataListInActive)

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        if (count < 2) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                if(count == 1){
                    refetch();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    async function updateStatus(id:any, property_id:any, stat:any){

        mutate({
            resource    : 'repair-documents',
            id          : id+"",
            // values      : vCurDelStatus,
            values      : {
                deleteStatus: stat
            },
            successNotification:false,
            errorNotification:false
        });

        refreshDataSource()
    }

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCount(0)
        refetch();
        // setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, property_id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, property_id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabTermRepair", v)
    }

    const columns: any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.property.property_name.localeCompare(b.property.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record?.property ? record?.property?.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'หมวด',
            dataIndex: 'category',
            key: 'category',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.repair_document_category.category_name.localeCompare(b.repair_document_category.category_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.repair_document_category ? record.repair_document_category.category_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ชื่อเอกสาร',
            dataIndex: 'doc_name',
            key: 'doc_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.doc_name !== "" ? record.doc_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
                    </>
                );
            },
        },
        {
            title: 'STATUS',
            // dataIndex: 'updateBy',
            key: 'deleteStatus',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.deleteStatus < b?.deleteStatus) {return -1}
                if (a?.deleteStatus > b?.deleteStatus) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record',record)
                return <>
                    {
                        record.deleteStatus === "N" ? 
                            <Tag style={{borderRadius: "10px"}} color={'green'}>ACTIVE</Tag>
                        :
                            <Tag style={{borderRadius: "10px"}} color={'red'}>INACTIVE</Tag>
                    }
                </>
            },
        },
        {
            title: 'CREATED BY',
            // dataIndex: 'createBy',
            key: 'createBy',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                    {/* <div >{record.createBy !== null ? 'xxxx' : "-"} {record.createBy !== null ? 'yyy' : ""}</div> */}
                </>
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'createdAt',
            width: 150,
            key: 'createdAt',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.createdAt < b.createdAt) {return -1}
                if (a.createdAt > b.createdAt) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'updateBy',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                    
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'Last Update',
            dataIndex: 'updatedAt',
            width: 150,
            key: 'updatedAt',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.updatedAt < b.updatedAt) {return -1}
                if (a.updatedAt > b.updatedAt) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 100,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                      {
                            record.deleteStatus === "N" ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        // onClick={()=> editModalShow(record.id)}
                                        onClick={() => edit("repair-document-other", record.property_id + "_" + record.id )}
                                        style={{
                                            marginRight:'4px'
                                        }}
                                    />
                                    <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record.id, record.property_id ) }} 
                                        danger
                                    ></Button>
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record.id, record.property_id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };


    const [selProp, setselProp] = useState<any>(undefined);
    const [selCate, setselCate] = useState<any>(undefined);
    const [nameSearch, setnameSearch] = useState<any>("");

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: selCateProp } = useSelect<any>({
        resource    : "repair-document-categories",
        optionLabel : "category_name",
        optionValue : "id",
        metaData: {
            populate: '*',
        },
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];
        if(mode == "S"){

            newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property?.id == selProp?.id}) : newData;
            newData = nameSearch !== "" ? dataList?.filter((e:any)=> e?.doc_name?.toLowerCase().includes(String(nameSearch)?.toLowerCase())) : newData;
            newData = selCate !== undefined ? dataList?.filter((e:any)=> e?.repair_document_category?.id  == selCate?.id) : newData;

            setDataList(newData);
        }else if(mode == "R"){
            form?.resetFields();
            setselProp(undefined);
            setselCate(undefined);
            setnameSearch("");

            refreshDataSource();
        }
    }

    function onStatusChange(v:any){
        console.log('val',v)
        if(v == "true" || v == true){
            SetTabKey("true")
        }else{
            SetTabKey("false")
        }
    }

    const totalItems = dataList?.length || 0;

    // Function to display total pages and items
    const showTotal = (total:any, range:any) => {
      return `${range[0]}-${range[1]} of ${total} items`;
    };

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
  
      
    return <>
        <List 
            // title={t("mjctermandcon.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjctermandcon.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" 
                        // {...formProps} 
                        onFinish={(values:any) => {}}
                        form = {form} 
                    >
                        <Row gutter={20} style={{width: "100%"}}>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select placeholder={t("select.sel")} 
                                        {...selProperty} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="category" label="Category">
                                    <Select placeholder={t("select.sel")} 
                                        {...selCateProp} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselCate({id:value, label: label?.children})
                                            }else{
                                                setselCate(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="doc_name" label="Search">
                                    <Input 
                                        placeholder="Search Document Name" 
                                        onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S", "xxxxx")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R", "xxxx")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form.Item style={{marginRight: "0",}}>
                        <CreateButton 
                            id="create_btn" 
                            onClick={()=>create("repair-document-other")} 
                            // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                        ></CreateButton>
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item name="deleteStatus" >
                <Tabs 
                    defaultActiveKey={"true"}
                    style={{ marginTop: 10, marginBottom: 10 }} 
                    onChange={onStatusChange} 
                >
                    <TabPane
                        key={"true"}
                        tab={
                            <span>
                                {"ACTIVE"}
                                <Badge count={dataList.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />

                    <TabPane
                        key={"false"}
                        tab={
                            <span>
                                {"TRASH"}
                                <Badge count={dataListInActive.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />
                </Tabs>
            </Form.Item>

            <div>
                {/* <Table 
                    style={{marginTop:'-3%'}}
                    // dataSource={filteredDataList.length > 0 ? filteredDataList : DataInfor} 
                    dataSource={dataList.length > 0 ? dataList : dataBlank} 
                    columns={columns} 
                    onChange={onChange} 
                    // pagination={{ size: "small", position: [ bottom] }} 
                    pagination={{ size: "small", position: [ "bottomRight"] }} 
                /> */}

                {
                    TabKey === "true" &&
                        <Table 
                            style={{marginTop:'-3%'}}
                            dataSource={dataList?.length > 0 ? dataList : dataBlank} 
                            columns={columns} 
                            // onChange={onChange} 
                            // pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 1300 }}
                        />
                }

                {
                    TabKey === "false" &&
                        <Table 
                            style={{marginTop:'-3%'}}
                            dataSource={dataListInActive?.length > 0 ? dataListInActive : dataBlank} 
                            columns={columns} 
                            // onChange={onChange} 
                            // pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 1300 }}
                        />
                }
            </div>
        </List>
    </>
}