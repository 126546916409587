/* eslint-disable */
import { Breadcrumb, Button, Card, Col, Create, DatePicker, Divider, Form, Input, List, Row, Select, Upload, message, notification, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { IDeliveryComp, IParcelBox, IParcelIn, IParcelList, IParcelType, IProperties, IUnit, IUserhunit } from "interfaces";
import { useEffect, useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { TOKEN_KEY } from "../../constants";
import { WebcamComponent } from "./WebcamComponent";
import './create.css';



export const ParcelInCreate: React.FC<IResourceComponentsProps> = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
    const isTablet = useMediaQuery({ query: '(max-width: 1024px)' });

    const { data: Identity_data } = useGetIdentity<any>();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    // console.log('data_property',data_property)
    const t = useTranslate();
    // const { mutate: CreateLog }             = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;
    const format = 'YYYY-MM-DD HH:mm';
    const [showCamera, setShowCamera] = useState(false);
    const [checked, setChecked] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [json, setjson] = useState<any[] | null>(null);

    const [checkedrifd, setcheckedrifd] = useState(false);



    const handleCapture = () => {
        console.log(1);
        setShowCamera(true);
        setChecked(false);
    };
    // const { replace } = useNavigation();
    const { replace, list } = useNavigation();

    const handleDelete = () => {
        setImageUrl(null);
        setChecked(false);

        // ตั้งค่า imageUrl ให้เป็น null เพื่อลบภาพ
    };
    let uploadlist: any[] = [];

    const API_URL = useApiUrl();
    const token = localStorage.getItem(TOKEN_KEY);


    const { Option } = Select;
    const { form, formProps, saveButtonProps }    = useForm<IParcelIn,HttpError,{}>(
        {
            
            onMutationSuccess(data, variables, context) {
                console.log('before');
                console.log(data,variables,context);
                let result : any = data;
                console.log('1',data);
                if(result.data.status && result.data.status == 203){
                    setcheckedrifd(true);
                    setIsCallApiLoading(false)
                    localStorage.setItem("Ref2", "1");
                    console.log(checkedrifd,'checkedrifd');
                    
                }
                else{
                    let recordData:any = []
                    recordData = data?.data? data?.data : [];
                    console.log('data mutateParcelList', recordData);
                    console.log('2');
                    mutateParcelList(recordData.data.id, recordData.data);
                }
                

                // replace("")
            },
            onMutationError: (data, variables, context) => {
                console.log('error')

                setIsCallApiLoading(false)
              },
              // redirect:"list"
              redirect: false,
              successNotification: false
            // redirect:"list"
        }
        
    );


    const useMediaWidth = () => {
        const [width, setWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWidth(window.innerWidth);

            window.addEventListener('resize', handleResize);

            // Clean up event listener on component unmount
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return width;
    };

    const width = useMediaWidth();

    const { mutate: createParcelList } = useCreate<IParcelList>();

    const [disabled, setDisabled] = useState(false);
    const [selProp, setProp] = useState<any>(0);
    const [vUnitID, setUnitID] = useState<any>(0);
    const [vProfileID, setProfileID] = useState<any>();
    const [vNote1, setNote1] = useState<any>('');
    const [vAnotherName, setAnotherName] = useState<any>('');

    const [vDeliverID, setDeliverID] = useState<any>(); // 0
    const [vBoxSizeID, setBoxSizeID] = useState<any>(); // 0
    const [vParcelType, setParcelType] = useState<any>(); // 0
    const [vShipingType, setShipingType] = useState<any>(); // 0
    const [vTrackingNo, setTrackingNo] = useState<any>(undefined); // ''
    const [vNote, setNote] = useState<any>(); // ''
    const [vRfid, setRfid] = useState<any>(); // ''
    const [vPic, setPic] = useState<any>(); // ''

    const [vDeliverArr, setDeliverArr] = useState<any>([]);
    const [vBoxSizeArray, setBoxSizeArray] = useState<any>([]);
    const [vParcelArray, setParcelArray] = useState<any>([]);
    const [vTrackingArray, setTrackingArray] = useState<any>([]);
    const [vNoteArray, setNoteArray] = useState<any>([]);
    const [vRfidArray, setRfidArray] = useState<any>([]);

    const [vShipingTypeArr, setShipingTypeArr] = useState<any>([]);
    const [vPicArray, setPicArray] = useState<any>([]);
    const [vPushArray, setPushArray] = useState<any>([]);
    const [isCallApiLoading, setIsCallApiLoading] = useState<boolean>(false);
    // const [ vReceivedDate, setReceivedDate ] = useState<any>("");
    const [vReceivedDate, setReceivedDate] = useState<any>(dayjs());

    const queryProperty = useList<IProperties>({
        metaData: { populate: '*' },
        config: {
            pagination: { current: 1, pageSize: 200 },
            filters: [
                {
                    field: "id",
                    operator: "eq",
                    value: selProp ? selProp : data_property
                },
            ],
        },
        resource: "properties",
    });

    let propertyData: any = queryProperty.data?.data ? queryProperty.data?.data : [];

    const queryUnit = useList<IUnit>({
        metaData: { populate: '*' },
        config: {
            pagination: { current: 1, pageSize: 200 },
            filters: [
                {
                    field: "id",
                    operator: "eq",
                    value: vUnitID
                },
            ],
        },
        resource: "units",
    });
    let unitData: any = queryUnit.data?.data ? queryUnit.data?.data : [];

    const { selectProps: selDeliver } = useSelect<IDeliveryComp>({
        resource: "delivery-companies",
        optionLabel: "title",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });




    const { selectProps: selBoxSize } = useSelect<IParcelBox>({
        resource: "parcel-box-sizes",
        optionLabel: "title",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selParcelType } = useSelect<IParcelType>({
        resource: "parcel-types",
        optionLabel: "title",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    // console.log('data_property', data_property)

    // let UnitData:any;
    const [UnitData, setUnitData] = useState<any>([]);
    const [UnitDataCyberpunk, setUnitDataCyberpunk] = useState<any>([]);
    let UnitListData


    const { listProps: hasUnitSimple, queryResult: { refetch } } = useSimpleList({
        resource: "user-has-units",
        metaData: {
            populate: [
                '*',
                'unit'
            ]
        },
        pagination: {
            pageSize: 10000,
        },
        permanentFilter: [
            {
                field: "unit][property][id]",
                operator: "eq",
                value: data_property ? data_property : selProp + ""
            },
            { // #1035 **ตีกลับ** Create Parcel In : Unit ไหนที่ไม่มีคน ไม่ต้องโชว์  / Unit ไหนที่มี Owner Contract ไม่ต้องโชว์
                //กรอง Unit ที่มีคนที่เป็น Approved Status แล้วเท่านั้น Unit ไหนที่มีคนรอ approve อยู่จะไม่ดึงมาโชว์
                field: "isOwnerConfirmed",
                operator: "eq",
                value: true
            },
            { // #1035 **ตีกลับ** Create Parcel In : Unit ไหนที่ไม่มีคน ไม่ต้องโชว์  / Unit ไหนที่มี Owner Contract ไม่ต้องโชว์
                //กรอง Unit ที่มีคนที่เป็น Approved Status แล้วเท่านั้น Unit ไหนที่มีคนรอ approve อยู่จะไม่ดึงมาโชว์
                field: "isAdminConfirmed",
                operator: "eq",
                value: true
            }
        ],
        queryOptions: {
            onSuccess: (data: any) => {
                // console.log('data has unit simple : ', data.data)
                UnitListData = data.data ? data.data : [];

                setUnitData(Array.from(new Set(data.data?.map((item: any) => item.unit.id))))

                let uniqueIds = new Set();

                // Use the filter method to remove duplicates
                let uniqueKk = UnitListData?.filter((item: any) => {
                    // Check if the ID is not already in the Set
                    if (!uniqueIds.has(item?.unit.id)) {
                        // Add the ID to the Set to mark it as seen
                        uniqueIds.add(item?.unit.id);
                        // Include the item in the result
                        return true;
                    }
                    // Exclude the item from the result
                    return false;
                });

                setUnitDataCyberpunk(
                    Array.from(
                        new Set(
                            uniqueKk?.map((item: any) => ({
                                label: item.unit.unit_name,
                                value: item.unit.id,
                            }))
                        )
                    )
                );

            },
        },
    });

    // console.log('UnitDataCyberpunk', UnitDataCyberpunk)

    // console.log('UnitData', UnitData)

    const Unit = useList<any>({
        resource: "user-has-units",
        metaData: {
            populate: '*',
        },
        config: {
            filters: [
                // {
                //     field: "unit][property][id]",
                //     operator: "eq",
                //     value: selProp + ""
                // },
                {
                    field: "unit][property][id]",
                    operator: "eq",
                    value: data_property ? data_property : selProp + ""
                },
                // {
                //     field: "unit]",
                //     operator: "ne",
                //     value: null || undefined
                // }
                // {
                //     field: "user_profile]",
                //     operator: "ne",
                //     value: null
                // }
                { // #1035 **ตีกลับ** Create Parcel In : Unit ไหนที่ไม่มีคน ไม่ต้องโชว์  / Unit ไหนที่มี Owner Contract ไม่ต้องโชว์
                    //กรอง Unit ที่มีคนที่เป็น Approved Status แล้วเท่านั้น Unit ไหนที่มีคนรอ approve อยู่จะไม่ดึงมาโชว์
                    field: "isOwnerConfirmed",
                    operator: "eq",
                    value: true
                },
                { // #1035 **ตีกลับ** Create Parcel In : Unit ไหนที่ไม่มีคน ไม่ต้องโชว์  / Unit ไหนที่มี Owner Contract ไม่ต้องโชว์
                    //กรอง Unit ที่มีคนที่เป็น Approved Status แล้วเท่านั้น Unit ไหนที่มีคนรอ approve อยู่จะไม่ดึงมาโชว์
                    field: "isAdminConfirmed",
                    operator: "eq",
                    value: true
                }
            ],
            pagination: { pageSize: 100 },

        },
        queryOptions: {
            retry: 3,
            onSuccess: (data) => {
                //   console.log('data xxxx : ', data.data)
            },
        },
        errorNotification: false
    });
    // const { data: DataUnit } = Unit;
    // let UnitData:any = DataUnit?.data? DataUnit?.data : [];

    // if(UnitData?.length > 0){
    //     UnitListData = Array.from(new Set(UnitData?.map((item: any) => item.unit.id))); 
    // }
    // console.log('UnitData', UnitData)
    // console.log('use UnitListData',UnitListData)

    // const { selectProps: SelPropert} = useSelect<IProperties>({
    //     resource: "properties",
    //     optionLabel: "property_name",
    //     optionValue: "id",
    //     filters: [
    //         {
    //             field: "deleteStatus",
    //             operator: "eq",
    //             value: "N"
    //         },
    //         // {
    //         //     field: "id",
    //         //     operator: "eq",
    //         //     value: PropListData
    //         // },
    //         // {
    //         //     field : "id",
    //         //     operator : data_property !== null ? "eq" : "ne",
    //         //     value : data_property !== null ? data_property : null
    //         // },

    //     ]   
    // });

    const { selectProps: selProperty } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        metaData: {
            populate: '*',
        },
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "id]",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            },
        ]
    });

    // const { selectProps: SelUnit } = useSelect<IUnit>({
    //     resource: "units",
    //     optionLabel: "unit_name",
    //     optionValue: "id",
    //     filters: [
    //         {
    //             field: "deleteStatus",
    //             operator: "eq",
    //             value : "N" 
    //         },
    //         {
    //             field: "id",
    //             operator: "eq",
    //             // operator: "in",
    //             // value: UnitListData.length > 0 ? UnitListData : "NULL"
    //             value: UnitData ? UnitData : null 
    //         }
    //     ],
    //     onSearch: (value) => [
    //         {
    //             field: "unit_name",
    //             operator: "contains",
    //             value,
    //         }
    //     ],
    //     hasPagination: false,
    //     fetchSize: 1000,
    //     errorNotification: false
    // });

    const { selectProps: selShipingType } = useSelect<IUnit>({
        resource: "parcel-shipping-types",
        optionLabel: "title",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ],
    });

    const UserHasUnit = useList<IUserhunit>({
        resource: "user-has-units",
        metaData: {
            populate: ['user_profile', 'user_sub_type', 'unit', 'unit.property'],
        },
        config: {
            filters: [
                {
                    field: "unit][id]",
                    operator: "eq",
                    value: vUnitID
                },
                {
                    field: "isOwnerConfirmed",
                    operator: "eq",
                    value: true
                },
                {
                    field: "isAdminConfirmed",
                    operator: "eq",
                    value: true
                },
                {
                    field: "user_profile][id]",
                    operator: "ne",
                    value: null
                },
            ],
            pagination: { current: 1, pageSize: 100 },
        }
    });

    let vUserData: any = UserHasUnit?.data ? UserHasUnit?.data : [];

    if (vUserData.data !== null || vUserData.data !== undefined) {
        if (vUserData.total > 0) {
            // const found = array1.find(element => element > 10);
            const findRenter = vUserData.data.find((e: { user_sub_type: { sub_type_name: string; }; }) => e.user_sub_type.sub_type_name == "Renter");
            const findResidentRenter = vUserData.data.find((e: { user_sub_type: { sub_type_name: string; }; }) => e.user_sub_type.sub_type_name == "Resident Renter");
            // console.log('findRenter', findRenter)
            // console.log('findResidentRenter', findResidentRenter)

            if (findRenter !== undefined || findResidentRenter !== undefined) {
                // let index = vUserData.data.findIndex((i: { user_sub_type: any; }) => i.user_sub_type.sub_type_name === "Owner");
                let index = vUserData.data.findIndex((i: { user_sub_type: any; }) => i.user_sub_type.id === 1); // id 1 = OWNER
                // console.log('index', index)
                if (index !== -1) {
                    vUserData.data.splice(index, 1)
                }
            }
        }
    }

    function toggleDisabled() {
        // setDisabled(!disabled);

        // ปรับเป็นกดสร้าง parcel list ได้แค่ 1
        // เพราะงั้นตอนกด add list แล้วปิดปุ่ม add เลยคับ
        setDisabled(true)
    }

    function fileChange(e: any) {
        console.log(vPushArray);
        console.log(vPicArray);


        if (e.file.response) {
            let res = e.file.response;
            const aa = vPicArray.filter((e: any) => e.id == res[0].id)
            if (aa.length == 0) {
                setPic(res[0].id)
                setPicArray((pre: any) => ([...pre, res[0]]))
            } else {
                const Newdata = vPicArray.filter((e: any) => e.id !== res[0].id)
                setPicArray((pre: any) => ([...Newdata]))
            }

            if (vPushArray.length > 0) {
                for (let i = 0; i < vPushArray.length; i++) {
                    let fill = vPushArray[i].filter((f: any) => { return f.id !== res[0].id })
                    vPushArray[i] = fill
                }
            }
        }
        else if (e.webcam) {
            console.log(e)
            let a = [];
            a.push(e.file);
            console.log(a);

            let res = a;
            console.log(res[0]);

            const aa = vPicArray.filter((e: any) => e.id == res[0].id)
            if (aa.length == 0) {
                setPic(res[0].id)
                setPicArray((pre: any) => ([...pre, res[0]]))
            } else {
                const Newdata = vPicArray.filter((e: any) => e.id !== res[0].id)
                setPicArray((pre: any) => ([...Newdata]))
            }

            if (vPushArray.length > 0) {
                for (let i = 0; i < vPushArray.length; i++) {
                    let fill = vPushArray[i].filter((f: any) => { return f.id !== res[0].id })
                    vPushArray[i] = fill
                }
            }
        }
        console.log(vPushArray, 'vPushArray');
        console.log(vPicArray, 'vPicArray');


    }


    const beforeUpload = (file: any) => {
        console.log(file, 'file');

        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        console.log(isJpgOrPng, 'isJpgOrPng');
        console.log(isLt2M, 'isLt2M');
        console.log(Upload.LIST_IGNORE, 'Upload.LIST_IGNORE');


        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function pushArray() {
        setPushArray((pre: any) => ([...pre, vPicArray]));
        setPicArray([]);
    }


    // ********* set ของลง Array ********* 
    function setDeliveryArr() {
        // let kDupValue = vDeliverArr.indexOf(vDeliverID)
        // if(kDupValue < 0){
        //     vDeliverArr.push(vDeliverID);
        //     setDeliverArr(vDeliverArr);
        // }

        // original
        vDeliverArr.push(vDeliverID);
        setDeliverArr(vDeliverArr);
    }

    function setBoxSizeArr() {
        vBoxSizeArray.push(vBoxSizeID);
        setBoxSizeArray(vBoxSizeArray);
    }

    function setParcelTypeArr() {
        vParcelArray.push(vParcelType);
        setParcelArray(vParcelArray);
    }

    function setTrackingNoArr() {
        vTrackingArray.push(vTrackingNo);
        setTrackingArray(vTrackingArray);
    }

    function setNoteArr() {
        vNoteArray.push(vNote);
        setNoteArray(vNoteArray);
    }

    function setRfidArr() {
        vNoteArray.push(vRfid);
        vRfidArray(vNoteArray);
    }



    function setShippingTypeArr() {

        // original
        vShipingTypeArr.push(vShipingType);
        setShipingTypeArr(vShipingTypeArr);
    }

    function clearVal() {
        setDeliverID(undefined)
        setBoxSizeID(undefined)
        setParcelType(undefined)
        setShipingType(undefined)
        setTrackingNo(undefined)
        setNote(undefined)
        setPic(undefined)
    }

    function toggleDisabledX() {
        if (disabled == true) {
            setDisabled(!disabled);
        }
    }

    const receivedDateHandle = (date: Dayjs | null, dateString: string, id: number) => {
        setReceivedDate(dateString);
    };

    const testFunc2 = async () => {
        // let Date_data = "2023-06-12T05:13:09.424Z";
        let Date_data = vReceivedDate;
        let run_no = 0;
        // console.log('selProp', selProp)
        let k = await axios.get(API_URL + "/like-systems/countParcelListByProperty?property_id=" + selProp, {
            headers: { Authorization: "Bearer " + token }
        });
        // console.log('k', k.data)
        run_no = k?.data + 1
        // Convert Date_data to Date object
        let date = new Date(Date_data);

        // Extract year, month, and date from the Date object
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let day = String(date.getDate()).padStart(2, "0");

        // Generate new_run_no in the specified format
        let new_run_no = `${year.toString().substr(-2)}${month}${run_no.toString().padStart(4, "0")}`;

        console.log(Number(new_run_no)); // Output: 23060001
    }

    async function mutateParcelList(valueID: any, data: any) {
        // console.log('data', data)

        // #2596 Parcel นิติรับพัสดุ เข้าให้ระบบสร้างเลข Running number เป็น YYMMxxxx 
        let IdProp: any
        if (selProp) {
            IdProp = selProp
        } else {
            IdProp = data_property
        }
        let Date_data = vReceivedDate;
        let run_no = 0;
        let k = await axios.get(API_URL + "/like-systems/countParcelListByProperty?property_id=" + IdProp, {
            headers: { Authorization: "Bearer " + token }
        });
        // console.log('k', k.data)

        // console.log('vDeliverID',vDeliverID)
        // console.log('vBoxSizeID',vBoxSizeID)
        // console.log('vParcelType',vParcelType)
        // console.log('vShipingType',vShipingType)
        // console.log('vTrackingNo',vTrackingNo)
        // console.log('vNote',vNote)
        console.log('vPic', vPic)
        console.log(json, 'json')

        // console.log('selProp', selProp)
        run_no = k?.data + 1
        // console.log('run_no', run_no)
        // Convert Date_data to Date object
        let date = new Date(Date_data);
        // Extract year, month, and date from the Date object
        let year = date.getFullYear();
        let month = String(date.getMonth() + 1).padStart(2, "0");
        let day = String(date.getDate()).padStart(2, "0");
        // Generate new_run_no in the specified format
        let new_run_no = `${year.toString().substr(-2)}${month}${run_no.toString().padStart(5, "0")}`;
        // let new_run_no = `${year.toString().substr(-2)}${month}${run_no.toString().padStart(3, "0")}`;
        // console.log(Number(new_run_no)); // Output: 23060001

        // console.log('new_run_no', new_run_no)
        // if(vPic == undefined){
        //     let id : any = null;
        //     console.log(json ,'json')
        //     json?.forEach((el : any) =>{
        //         console.log(el);
        //         id = el.id;

        //     })
        //     console.log(id);
        //     if(id){
        //         createParcelList({
        //             resource    : "parcel-lists",
        //             values      : {
        //                 tracking_no             : vTrackingNo,
        //                 parcel_type             : vParcelType,
        //                 parcel_box_size         : vBoxSizeID,
        //                 parcel_shipping_type    : vShipingType,
        //                 delivery_company        : vDeliverID,
        //                 note                    : vNote,
        //                 pic                     : vPic,
        //                 parcel_in               : valueID,
        //                 property_code           : selProp,
        //                 unit_code               : vUnitID,
        //                 parcel_status           : "WAITING",
        //                 running_code            : Number(new_run_no),
        //                 rfidcode                : vRfid,
        //                 gen_code                : propertyData[0].property_code + unitData[0].unit_code + vUserData.data[0].user_profile.id + vTrackingArray[vTrackingArray.length - 1] + ""
        //             },
        //             successNotification : (data, values, resource) => {
        //                 return {
        //                   message: `Successfully created parcel-in.`,
        //                   description: 'Successful',
        //                   type: "success",
        //                 };
        //               },
        //               errorNotification: (data, values, resource) => {
        //                 return {
        //                   message: `Error`,
        //                   description: "Error",
        //                   type: "error",
        //                 };
        //               },
        //         },
        //         {
        //             onError: (error, variables, context) => {
        //                 setIsCallApiLoading(false)
        //                 localStorage.setItem("Ref2", "1");

        //                 replace("/parcel/parcel-ins")
        //             },
        //             onSuccess: (data, variables, context) => {
        //                 setIsCallApiLoading(false)
        //                 localStorage.setItem("Ref2", "1");

        //                 replace("/parcel/parcel-ins")
        //             },
        //         });
        //     }
        // }

        createParcelList({
            resource: "parcel-lists",
            values: {
                tracking_no: vTrackingNo,
                parcel_type: vParcelType,
                parcel_box_size: vBoxSizeID,
                parcel_shipping_type: vShipingType,
                delivery_company: vDeliverID,
                note: vNote,
                pic: vPic,
                parcel_in: valueID,
                property_code: selProp,
                unit_code: vUnitID,
                parcel_status: "WAITING",
                // running_code: Number(new_run_no),
                rfidcode: vRfid,
                gen_code: propertyData[0].property_code + unitData[0].unit_code + vUserData.data[0].user_profile.id + vTrackingArray[vTrackingArray.length - 1] + ""
            },
            successNotification: (data, values, resource) => {
                return {
                    message: `Successfully created parcel-in.`,
                    description: 'Successful',
                    type: "success",
                };
            },
            errorNotification: (data, values, resource) => {
                return {
                    message: `Error`,
                    description: "Error",
                    type: "error",
                };
            },
        },
            {
                onError: (error, variables, context) => {
                    setIsCallApiLoading(false)
                    localStorage.setItem("Ref2", "1");
                    replace("/parcel/parcel-ins")
                },
                onSuccess: (data, variables, context) => {
                    setIsCallApiLoading(false)
                    localStorage.setItem("Ref2", "1");

                    replace("/parcel/parcel-ins")
                },
            });


        // localStorage.setItem("Ref2", "1");

        // replace("/parcel/parcel-ins")

    }

    const msgDupTrackingNo = (stat: any, msg: any) => {
        notification.error({
            message: '',
            description: msg,
            duration: 3,
        });
    };

    let resultQuery: any = []

    async function chkDupTrackingNo() {

        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        resultQuery = await axios.get(API_URL + "/like-systems/findDupTrackingNo?tracking_no=" + vTrackingNo, { headers: { Authorization: AuthStr } });

        // console.log('resultQuery', resultQuery.data)
        // return [{unitID: resultQuery2.data.id, propertyID: resultQuery2.data.property.id}]
        // return "k"
        // return resultQuery.data

        if (resultQuery.data == false) {
            setDeliveryArr(); setBoxSizeArr(); setParcelTypeArr(); setTrackingNoArr(); setNoteArr(); setRfidArr(); setShippingTypeArr(); clearVal(); toggleDisabled(); pushArray();
        } else if (resultQuery.data == true) {
            // msgDupTrackingNo(500, "Duplicate Tracking Number")
            msgDupTrackingNo(500, "หมายเลข tracking number ซ้ำ")

        }

    }

    const range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    const disabledDateTime = () => ({
        disabledHours: () => range(0, 24).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
    });

    // console.log('selProp', selProp) 


    
    form.setFieldsValue({
        create_date: dayjs(),
    })

    useEffect(() => {
        try {
            if (selProperty && selProperty.options && selProperty.options.length > 0) {
                if (data_property !== undefined && data_property !== null) {
                    form.setFieldsValue({
                        // property: data_property !== undefined ? data_property : null
                        property: data_property
                    })
                }
            }
        } catch (error) {
            form.setFieldsValue({
                property: null
            })
        }
    }, [selProperty])

    const filterOption = (input: any, option: any) => {
        const optionLabel = option.props.children.toString().toLowerCase();
        return optionLabel.indexOf(input.toLowerCase()) >= 0;
    };

    useEffect(() => {
        // console.log('1');
        // console.log(imageUrl);
        // console.log(json);

        if (imageUrl) {
            let d = {};
            json?.forEach((el: any) => {
                console.log(el);

                d = el
            })
            console.log(d);

            uploadlist.push(d)
            let result: any = {
                file: d,
                fileList: uploadlist,
                webcam: true
            }
            fileChange(result); // เรียกใช้ฟังก์ชันหาก imageUrl มีค่า
        }
    }, [json]); // ระบุ imageUrl เป็น dependency


    return (
        <Create saveButtonProps={saveButtonProps} isLoading={isCallApiLoading} pageHeaderProps={{ extra: <></> }}>
            <List
                title={t("parcel-in.title-create")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("parcel-in.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />

            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    // console.log('propertyData : ',propertyData);
                    // setIsCallApiLoading(true)
                    // console.log('after save', values);
                    values.createBy = user_data.user_profile.id
                    values.unit = vUnitID
                    values.user_profile = vProfileID !== undefined ? vProfileID : null
                    values.note = vNote1
                    values.in_date = vReceivedDate   // วันที่รับพัสดุ
                    values.create_date = dayjs();  // วันที่บันทึกรายการ parcel_in
                    values.customer_name = vAnotherName;
                    values.property = selProp !== 0 ? selProp + '' : data_property;
                    values.rfidcode = vRfid;

                    // values.locale = "th"
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                {/* <Button onClick={()=> mutateParcelList("casc","asdasd")}>xxxx</Button> */}
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={24}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={12}>
                                <Col className="gutter-row col" span={isMobile ? 24 : isTablet ? 12 : 6}>

                                    <Form.Item

                                        label="Property"
                                        name="property"
                                        rules={[{ required: true, message: 'Please select property' },]}
                                    >
                                        {/* {
                                            data_property !== null ?
                                                <Select 
                                                    {...selProperty} 
                                                    placeholder="Select property" 
                                                    value={data_property}
                                                    size="middle" 
                                                    style={{borderRadius: 20, width: '100%', position:'relative'}} 
                                                    onChange={(e) => setProp(e)}
                                                    allowClear
                                                />
                                            :
                                                <Select 
                                                    {...selProperty} 
                                                    placeholder="Select property" 
                                                    // value={selProp}
                                                    size="middle" 
                                                    style={{borderRadius: 20, width: '100%', position:'relative'}} 
                                                    onChange={(e) => setProp(e)}
                                                    allowClear
                                                />
                                        } */}

                                        <Select
                                            {...selProperty}
                                            placeholder="Select property"
                                            size="middle"
                                            // value={selProp !== 0 ? selProp : null}
                                            style={{ borderRadius: 20, width: '100%', position: 'relative' }}
                                            onChange={(e) => setProp(e)}
                                            allowClear
                                        />


                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                    <Form.Item

                                        // label={t("policy.policy_code")}
                                        label="Unit"
                                        name="unit"
                                        rules={[{ required: true, message: 'Please select unit' },]}
                                    >
                                        {/* <Select {...SelUnit}
                                            placeholder="Select unit" 
                                            size="middle" 
                                            style={{borderRadius: 20, width: '100%', position:'relative'}} 
                                            onSelect={(e: any)=>setUnitID(e)}
                                            onClear={()=> {
                                                setUnitID(0);
                                                form.resetFields(["unit"]);
                                            }}
                                            allowClear
                                            showSearch
                                        /> */}

                                        {/* <Select 
                                            // placeholder={t("select.sel")}
                                            placeholder="Select unit" 
                                            style={{ width: '100%' }}
                                            onSelect={(e: any)=>setUnitID(e)}
                                            onClear={()=> {
                                                setUnitID(0);
                                                form.resetFields(["unit"]);
                                            }}
                                            allowClear
                                            showSearch
                                            options={UnitDataCyberpunk}
                                        /> */}

                                        {/* <Select
                                            placeholder={t("select.sel")} 
                                            // onChange={(e:any, record:any) => { SetSelectedCategory(record)} }
                                            onSelect={(e: any)=>setUnitID(e)}
                                            showSearch
                                            allowClear
                                        >
                                            {
                                                UnitDataCyberpunk?.length > 0 ?
                                                        UnitDataCyberpunk?.map((item:any,key:any) => {
                                                        return <>
                                                            <Option 
                                                                value={item.value}
                                                                key={item.value}
                                                                // data={item}
                                                            >
                                                                {item.label ? item.label : 'NODATA'}
                                                            </Option>
                                                        </>
                                                    })
                                                : <></>
                                            }
                                        </Select> */}

                                        {/* <Select style={{ width: 200 }}>
                                            {UnitDataCyberpunk?.map((item:any) => (
                                                <Option key={item.value} value={item.value}>
                                                {item.label}
                                                </Option>
                                            ))}
                                        </Select> */}
                                        <Select
                                            style={{ width: '100%' }}
                                            // value={vUnitID} 
                                            // onChange={handleChange}
                                            onSelect={(e: any) => setUnitID(e)}
                                            onClear={() => {
                                                setUnitID(0);
                                                form.resetFields(["unit"]);
                                                form.resetFields(["user_profiles"]);
                                            }}
                                            showSearch
                                            filterOption={filterOption}
                                            allowClear
                                        >
                                            {UnitDataCyberpunk?.map((item: any) => (
                                                <Option key={item.value} value={item.value}> {item.label} </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                    <Form.Item
                                        label="Receiver Name "
                                        name="user_profiles"
                                    >
                                        <Select placeholder={t("select.sel")}
                                            style={{ width: '100%' }}
                                            allowClear
                                            onSelect={(value: any, label: any) => {
                                                setProfileID(value)
                                            }}
                                        >
                                            {
                                                vUserData !== undefined && vUserData.data !== undefined &&
                                                <>
                                                    {
                                                        vUserData.data.map((item: any, key: any) => {
                                                            return <>
                                                                <Option
                                                                    value={item.user_profile.id}
                                                                    key={item.user_profile.id}
                                                                >
                                                                    {item.user_profile ? item.user_profile.first_name : ''}{' '}{item.user_profile ? item.user_profile.last_name : ''}
                                                                </Option>
                                                            </>
                                                        })
                                                    }
                                                </>
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                                    <Form.Item
                                        // label={t("policy.policy_name")} 
                                        label="Another name"
                                        name="customer_name"
                                    // rules={[{required: true, message: 'Please enter parcel box size name (EN).'},]}
                                    >
                                        <Input onChange={(e) => setAnotherName(e.target.value)} />
                                    </Form.Item>
                                </Col>


                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 14}>
                                    <Form.Item
                                        // label={t("policy.policy_name")} 
                                        label="Note"
                                        name="note"
                                    // rules={[{required: true, message: 'Please enter parcel box size name (EN).'},]}
                                    >
                                        <Input onChange={(e) => setNote1(e.target.value)} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 10}>
                                    <Form.Item label="Received Date"
                                        name="create_date"
                                        rules={[
                                            { required: true, message: 'Please select date and time.' },
                                        ]}
                                    // getValueProps={(value) => ({
                                    //     value: recordData ? dayjs(recordData.received_date) : "",
                                    // })}
                                    >
                                        {/* ORIGINAL */}
                                        {/* <DatePicker 
                                            onChange={(date, dateString) => receivedDateHandle(date, dateString, 1)} 
                                            showTime
                                            format={format}
                                            style={{width: "100%"}}
                                        /> */}
                                        <DatePicker
                                            // className="m-0 p-0 pr-2 pl-2  "
                                            style={{ width: "100%" }}
                                            onChange={(date, dateString) => receivedDateHandle(date, dateString, 1)}
                                            showTime
                                            format={format}
                                            defaultValue={dayjs()}
                                            // disabledDate={(current) => {                 
                                            //     return (
                                            //         moment().add(-4, "days") >= current 
                                            //         ||
                                            //         moment().add(0, "month") <= current
                                            //     );
                                            // }}
                                            disabledDate={(current) => {
                                                return (
                                                    dayjs().subtract(4, "days").isAfter(current) ||
                                                    dayjs().add(0, "month").isBefore(current)
                                                );
                                            }}
                                        />
                                        {/* <DatePicker 
                                            onChange={(date, dateString) => receivedDateHandle(date, dateString, 1)} 
                                            showTime
                                            format={format}
                                            style={{width: "100%"}}

                                            // disabledTime={disabledDateTime}

                                            // disabledDate={(current) => {
                                            //     let todayDate = moment().format("YYYY-MM-DD");
                                            //     // let k2 = moment().add(4, 'days').format('YYYY-MM-DD')
                                            //     let k = moment(todayDate).add(4, 'days').format('YYYY-MM-DD')
                                            //     // return current && current > moment(k, "YYYY-MM-DD") || current < moment(todayDate, "YYYY-MM-DD");
                                            //     // return current < moment(todayDate, "YYYY-MM-DD") || current > moment(k, "YYYY-MM-DD");
                                            //     return current && current < moment(todayDate, "YYYY-MM-DD") || current > moment(k, "YYYY-MM-DD")
                                            // }}
                                        /> */}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 24 : 24}>
                        <Form.Item>
                            <span style={{
                                fontSize: "19px", color: "#000", paddingLeft: isMobile ?
                                    "0px" : "24px"
                            }}>Parcel list</span>
                        </Form.Item>

                    </Col>
                    <Col className="gutter-row" span={2} />
                </Row>

                <div style={{ borderRadius: 20, border: "1px solid #ddd", marginBottom: '1%', paddingTop: "1.5rem", paddingLeft: isMobile ? "24px" : isTablet ? "24px" : "0", paddingRight: isMobile ? "24px" : isTablet ? "24px" : "0" }}>
                    <Row gutter={12}>
                        <Col className="gutter-row" span={isMobile ? 0 : isTablet ? 0 : 2}> <div style={{ float: 'left', marginTop: "2rem", fontSize: "0.7vw", paddingLeft: "1.5rem" }}></div> </Col>
                        {/* TRACKING NO. */}
                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 5}>
                            <Form.Item
                                label="Tracking Number"
                                // name={[index, 'tracking_no']}
                                name="tracking_no"
                                rules={[{ required: true, message: 'Please enter tracking no.' },]}
                            >
                                <Input onChange={(e) => setTrackingNo(e.target.value)} />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                            <Form.Item
                                label="Parcel Type"
                            // name={[index, 'parcel_type']}
                            //rules={[{required: true, message: 'Please select parcel type'}]}
                            >
                                <Select {...selParcelType} onChange={(e) => setParcelType(e)} allowClear />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                            <Form.Item
                                label="Box Size"
                            // name={[index, 'parcel_box_size']}
                            // rules={[{required: true, message: 'Please select parcel box size'},]}
                            >
                                <Select {...selBoxSize} onChange={(e) => setBoxSizeID(e)} allowClear />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                            <Form.Item
                                label="Shiping Type"
                            // name={[index, 'parcel_shipping_type']}
                            // rules={[{required: true, message: 'Please select parcel type'},]}
                            >
                                <Select {...selShipingType} onChange={(e) => setShipingType(e)} allowClear />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 4}>
                            <Form.Item
                                label="Delivery Company"
                            // name={[index, 'delivery_company']}
                            // rules={[{required: true, message: 'Please select delivery company'},]}
                            >
                                <Select {...selDeliver} onChange={(e) => setDeliverID(e)} allowClear />
                            </Form.Item>
                        </Col>
                        {/* {isTablet &&
                            <Col className="gutter-row" span={12}>
                                <Form.Item
                                    label="Parcel Images"
                                // name={[index, 'pic']}
                                >
                                    <Upload.Dragger
                                        // {...vImg}
                                        style={{ borderRadius: 10 }}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        multiple
                                        onChange={fileChange}
                                        // disabled={checked} 
                                        beforeUpload={beforeUpload}
                                    >
                                        <p>Upload</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col> */}
                        {/* } */}
                    </Row>

                    <Row gutter={24}>
                        <Col className="gutter-row" span={isMobile ? 0 : isTablet ? 0 : 2} />
                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                            {/* {!isTablet && */}
                                <Form.Item
                                    label="Parcel Images"
                                // name={[index, 'pic']}
                                >
                                    <Upload.Dragger
                                        // {...vImg}
                                        style={{ borderRadius: 10 }}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        multiple
                                        onChange={fileChange}
                                        // disabled={checked} 
                                        beforeUpload={beforeUpload}
                                    >
                                        <p>Upload</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            {/* } */}


                            {!isMobile && !isTablet && <Form.Item label='Parcel images for snapshot'>
                                <Col className="gutter-row" span={24}>

                                    <Button type="primary" style={{ borderRadius: 10, height: 50, width: "100%", textAlign: "center" }} onClick={handleCapture}>
                                        Open Webcam
                                    </Button>
                                    {showCamera && <WebcamComponent setShowCamera={setShowCamera} setImageUrl={setImageUrl} setchecked={setChecked} setjson={setjson} />}
                                    {imageUrl && (
                                        <Card
                                            hoverable
                                            style={{ width: 240, marginTop: 6 }}
                                            cover={<img alt="Captured" src={imageUrl} />}
                                            actions={[
                                                <Button key="delete" type="primary" onClick={handleDelete}>
                                                    Delete
                                                </Button>
                                            ]}
                                        />
                                    )}

                                </Col>

                            </Form.Item>}

                        </Col>
                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 9}>
                            <Form.Item
                                label="Note"
                            // name={[index, 'note']}
                            // rules={[{required: true, message: 'Please select delivery company'},]}
                            >
                                <Input
                                    onChange={(e) => setNote(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" span={isMobile ? 24 : isTablet ? 12 : 6}>
                            <Form.Item
                                label="Rfid"
                            >
                                <Input 
                                    onChange={(e) => setRfid(e.target.value)} 
                                />
                                {checkedrifd && <div style={{ color: 'red' }}>RFID has already been taken.</div>}
                            </Form.Item>
                        </Col>

                        {/* <Col className="gutter-row" span={3}>
                          
                        </Col> */}
                    </Row>
                    <div style={{ marginBottom: "1vh" }}></div>
                </div>

            </Form>
        </Create>
    );
};