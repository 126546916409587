/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useSimpleList, useForm, DatePicker, Checkbox } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import {TimePicker } from 'antd';
import type { ColumnsType } from 'antd/es/table';
// import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
// import MDEditor from '@uiw/react-md-editor';
import Moment from 'moment';
import axios from "axios";
import { TOKEN_KEY } from "../../../constants";
import dayjs from "dayjs";

let swLocal : boolean = true;
let dataonFilter: any = [];
export const HcuNotiMsgList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    // console.log('prop_id', prop_id)
    // console.log('prop_list', prop_list)
    const { TextArea } = Input;
    const { Option } = Select;
    const API_URL = useApiUrl();
    const { Text } = Typography;
    const token = localStorage.getItem(TOKEN_KEY);

    const { create, edit } = useNavigation();

    const { TabPane } = Tabs;

    const [DataCus, setDataCus] = useState<any>();
    const [vTacType, setTacType] = useState<any>();

    const [vSms, setSms] = useState<any>();
    const [vEmail, setEmail] = useState<any>();
    const [vInApp, setInApp] = useState<any>();
    const [time_monday, settime_monday] = useState<any>();
    const [notiData, setNotiData] = useState<any>();
    const [dayData, setDayData] = useState<any>();

    const [vswLocal, setswLocal] = useState<boolean>(true);
    const [FileList, setFileList] = useState<any>([]);

    const [value, setValue] = useState<any>("");
    const [dataList, setDataList] = useState<any>([]);

    
    const { confirm } = Modal;
    const { mutate } = useUpdate<ICoupon>();
    const ed_point = "home-check-up-msgs";
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const [inputProperty, setInputProperty] = useState<any>()
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const { formProps } = useForm<any,HttpError,{}>()

    const [nameSearch, setnameSearch] = useState<any>("");
    const [selProp, setselProp] = useState<any>();
    const [text, setText] = useState('');

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "home-check-up-settings",
        metaData: { populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'], },
        pagination:{
            pageSize: 1000,
        },
        permanentFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx')
            let dataLoad: any = data?.data;
            console.log('dataLoad', dataLoad)

            // filter เอาแต่ data ของ property นั้น ๆ
            // if(prop_id !== null){
            //     dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            // }

            if(prop_list !== undefined){
                console.log('case 1')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property?.id));
            }else if(prop_id !== null){
                console.log('case 2')
                dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            }

            for (let index = 0; index < dataLoad?.length; index++) {
                // console.log('dataload', dataLoad[index])
                dataonFilter = dataLoad[index]?.hcu_setting?.filter((e:any)=> e.home_check_up_type?.name == "Notification")
                // dataonFilter.push(
                //     {
                //         "property": dataLoad[index].property.property_name,
                //         "data": dataLoad[index]?.hcu_setting?.filter((e:any)=> e.home_check_up_type?.name == "Notification"),
                //     },
                // )
                // dataonFilter.push(dataonFilter)
                dataonFilter.forEach((obj:any) => {
                    obj.property_name = dataLoad[index].property.property_name;
                    obj.property_id = dataLoad[index].property.id;
                    obj.record_id = dataLoad[index].id;
                });
                // console.log('dataonFilter', dataonFilter)
                // dataonFilter.push(dataonFilter);
                dataList?.push(dataonFilter)
            }

          },
        },
    });

    if(dataList?.length > 0){
        // const flattenedArray = dataList[0].concat(dataList[1]);
        const flattenedArray = dataList.reduce((accumulator:any, currentArray:any) => {
            return accumulator.concat(currentArray);
        }, []);

        dataonFilter = flattenedArray
        // console.log('flattenedArray', flattenedArray)
    }

    const columns : ColumnsType<any> = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.property")}</span>,
            dataIndex: 'property',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: '300px',
            sorter: (a:any, b:any) => a.property_name.localeCompare(b.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)7
                return (
                    <>
                        <div>{record ? record.property_name !== null ? record.property_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.noti-time-sms")}</span>,
            dataIndex: 'time_sms',
            key: 'time_sms',
            align: 'center',
            width: '150px',
            // sorter: (a:any, b:any) => a.time_sms.toString().localeCompare(b.time_sms.toString()),
            sorter:(a:any, b:any) => {
                if (a.time_sms < b.time_sms) {return -1}
                if (a.time_sms > b.time_sms) {return 1}
                return 0
            },
            render: (value:any) => {
                // console.log('time_inApp', value)
                return (
                    <>
                        <div>{value ? value !== null ? value.substring(0, value.length - 7) : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.noti-time-email")}</span>,
            dataIndex: 'time_email',
            key: 'time_email',
            align: 'center',
            width: '150px',
            //sorter: (a:any, b:any) => a.time_email.toString().localeCompare(b.time_email.toString()),
            sorter:(a:any, b:any) => {
                if (a.time_email < b.time_email) {return -1}
                if (a.time_email > b.time_email) {return 1}
                return 0
            },
            render: (value:any) => {
                //console.log('time_email', value)
                //if(value == null){ return value == 00:00:00.000};
                return (
                    <>
                        <div>{value ? value !== null ? value.substring(0, value.length - 7) : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.noti-time-app")}</span>,
            dataIndex: 'time_inApp',
            key: 'time_inApp',
            align: 'center',
            width: '150px',
            sorter: (a:any, b:any) => a.time_inApp.toString().localeCompare(b.time_inApp.toString()),
            render: (value:any) => {
                // console.log('time_inApp', value)
                return (
                    <>
                        <div>{value ? value !== null ?  value.substring(0, value.length - 7) : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.title")}</span>,
            dataIndex: 'noti',
            key: 'noti',
            align: 'center',
            width: '150px',
            // sorter:(a:any, b:any) => {
            //     if (a.sms < b.sms || a.email < b.email || a.inApp < b.inApp) {return -1}
            //     if (a.sms > b.sms || a.email > b.email || a.inApp > b.inApp) {return 1}
            //     return 0
            // },
            render: (value:any, record:any) => {
                    // console.log('record xxxx', record)
                    return (
                        <>
                            {
                                record.sms ?
                                    <>
                                        <Tag color={"#ADC6FF"} style={{border: "1px solid #DAC32F", borderRadius: "10px"}}>{"SMS"}</Tag>
                                    </>
                                :
                                    <></>
                            }
                            {
                                record.email ?
                                    <>
                                        <Tag color={"#1890FF"} style={{border: "1px solid #DAC32F", borderRadius: "10px"}}>{"EMAIL"}</Tag>
                                    </>
                                :
                                    <></>
                            }
                            {
                                record.inApp ?
                                    <>
                                        <Tag color={"#13C2C2"} style={{border: "1px solid #DAC32F", borderRadius: "10px"}}>{"IN-APP"}</Tag>
                                    </>
                                :
                                    <></>
                            }
                        </>
                    );
                },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.create-by")}</span>,
            // dataIndex: 'createBy',
            key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: '150px',
            sorter:(a:any, b:any) => {
                if (a.createBy.first_name < b.createBy.first_name) {return -1}
                if (a.createBy.first_name > b.createBy.first_name) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                </>
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.created-date")}</span>,
            // dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            width: '150px',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.update-by")}</span>,
            // dataIndex: 'updateBy',
            key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: '150px',
            sorter:(a:any, b:any) => {
                if (a.updateBy.first_name < b.updateBy.first_name) {return -1}
                if (a.updateBy.first_name > b.updateBy.first_name) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return <>
                    <div >{record.updateBy !== null ? record.updateBy?.first_name : "-"} </div>
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.notification.last-update")}</span>,
            // dataIndex: 'update_date',
            key: 'update_date',
            align: 'center',
            width: '150px',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: '80px',
            align: 'center',
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            onClick={() => edit("notification", record.property_id + "_" + record.id )}
                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                        /> */}
                        {/* <CreateButton id="create_btn" onClick={()=>edit("notification", )} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }></CreateButton> */}

                        {
                            record.deleteStatus === "N" ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        // onClick={()=> editModalShow(record.id)}
                                        onClick={() => edit("notification", record.property_id + "_" + record.id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        // disabled={false }
                                        style={{
                                            marginRight:'4px'
                                        }}
                                    />
                                    <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record.id, record.property_id ) }} 
                                        danger
                                    ></Button>
                                </>
                            :
                                <> 
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record.id, record.property_id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                        method: "post",
                        body: body
                        // mode: "no-cors"
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                            default: res[0].url
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    const handleChange = (e:any) => {
      setText(e.target.value);
    };
  
    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
    } = useModalForm<any>({
        resource: "home-check-up-msgs",
        metaData: {
            populate: '*',
            // locale:['th']
        }, 
        action: "create",
        redirect: false
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading ,
        show: editModalShow,
        close
    } = useModalForm<any>({
        resource: "home-check-up-msgs",
        metaData: {
            populate: '*',
            // populate: ['property','document_type','document_sub_type','file','localizations', 'localizations.property','localizations.document_type','localizations.document_sub_type','localizations.file', 'updateBy'],
            // locale:['th']
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            setDataCus(data?.data)
            // console.log('data on load', data?.data)
            // setdetail(data?.data.tac_content);
            // setdetail_en(data?.data.tac_content_en);
            setTacType(data?.data.type);

            setSms(data?.data.sms)
            setEmail(data?.data.email)
            setInApp(data?.data.inApp)

            editProps?.form?.setFieldsValue({
                // sms: data?.data.sms ? data?.data.sms : false,
                // email: data?.data.email ? data?.data.email : false,
                // inApp: data?.data.inApp ? data?.data.inApp : false,
                sms: data?.data.sms,
                email: data?.data.email,
                inApp: data?.data.inApp
            })

        }},
    });

    // console.log('filelist', FileList)
    const { selectProps: selDocType } = useSelect<any>({
        resource    : "document-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    function loadLocal(){
        // if(swLocal == true){
        //     editProps?.form?.setFieldsValue({doc_name: DataCus ? DataCus.doc_name : null})

        //     const fileList: UploadFile[] = 
        //         DataCus && DataCus?.file.map((v:any)=>(
        //             {
        //                 uid: String(v.id),
        //                 name: v.name,
        //                 status: "done",
        //                 url:
        //                     v.url,
        //                 thumbUrl:
        //                     v.url
        //             }
        //         ));

        //     setFile({
        //         name: 'files',
        //         multiple: true,
        //         defaultFileList: [...fileList],
        //     })

        //     if(DataCus && DataCus.file !== null){
        //         setFileList((pre:any)=> ([...DataCus.file]));
        //         // setFileList((pre:any)=> ([...pre , ...DataCus.file]));
        //     }else{
        //         setFileList([])
        //     }

        // }else{
        //     editProps?.form?.setFieldsValue({doc_name: DataCus.localizations.length > 0 && DataCus.localizations[0]?.doc_name ?  DataCus.localizations[0]?.doc_name : null})

        //     const fileList: UploadFile[] = 
        //         DataCus.localizations.length > 0 ? DataCus.localizations[0]?.file?.map((v:any)=>(
        //                 {
        //                     uid: String(v.id),
        //                     name: v.name,
        //                     status: "done",
        //                     url:
        //                         v.url,
        //                     thumbUrl:
        //                         v.url
        //                 }
        //             )) : []
          
        //     setFile({
        //         name: 'files',
        //         multiple: true,
        //         defaultFileList: [...fileList],
        //     })

        //     if(DataCus.localizations.length > 0 && DataCus.localizations[0]?.file !== null){
        //         setFileList((pre:any)=> ([...DataCus.localizations[0]?.file]));
        //     }else{
        //         setFileList([])
        //     }
        // }

        // editProps?.form?.resetFields(["file"]);
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    async function updateStatus(id:any, property_id:any, stat:any){
        console.log('id', id)
        console.log('property_id', property_id)
        console.log('stat', stat)

        let data = { 
            "data":{
                "property": property_id,
                "hcu_setting": [
                    {
                        "id": Number(id),
                        "deleteStatus": stat,
                    }
                ]
            }
        }
        // console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        // setCountInterval(0)
        refetch();
        setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, property_id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, property_id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const onChangeSwitch = (checked: boolean, type: any) => {
        // console.log(checked)
        // console.log(type)
        switch (type) {
            case 'sms':
                setSms(checked)
                break;
            case 'email':
                setEmail(checked)
                break;
            case 'inapp':
                setInApp(checked)
                break;
        }
    };

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };


    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
      
    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    function onSearchtable(data: any, mode:any){
        // let newData:any = data;
        let newData:any = [];
        if(mode == "S"){
            console.log('dataonFilter',dataonFilter )
            // console.log('time_monday',time_monday )
            // console.log('notiData',notiData)
            //newData = nameSearch !== "" ? dataonFilter?.filter((e:any)=> e?.property_name?.toLowerCase().includes(String(nameSearch).toLowerCase())):newData;
            // newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property_id.includes(String(selProp?.id))}):newData;
            if (!!inputProperty) {
                newData = !!inputProperty ? dataonFilter?.filter((e:any) => e?.property_name.toLowerCase()?.includes(inputProperty.toLowerCase()) ||
                e?.time_sms?.includes(inputProperty) ||
                e?.time_email?.includes(inputProperty) ||
                e?.time_inApp?.includes(inputProperty) ||
                e?.createBy?.first_name.toLowerCase()?.includes(inputProperty.toLowerCase()) ||
                e?.updateBy?.first_name.toLowerCase()?.includes(inputProperty.toLowerCase())
                ) : null;
                setDataList(newData);
                dataonFilter = newData
            }else if (!!time_monday) {
                newData = !!time_monday && notiData == "IN APP" ? dataonFilter?.filter((e:any)=> {return e?.time_inApp?.includes(String(time_monday))}) : 
                !!time_monday && notiData == "EMAIL" ? dataonFilter?.filter((e:any)=> {return e?.time_email?.includes(time_monday)}) :
                !!time_monday && notiData == "SMS" ? dataonFilter?.filter((e:any)=> {return e?.time_sms?.includes(time_monday)}) : null;
                setDataList(newData);
                dataonFilter = newData
            }else if (!!selProp) {
                newData = selProp !== undefined ? dataonFilter?.filter((e:any)=> {return e?.property_id == selProp?.id}) : newData;
                setDataList(newData);
                dataonFilter = newData
            }
            
            //console.log('newData', newData)
            // setDataList((pre: any) => newData);
            //dataonFilter = newData
            //setDataList(newData);
        }else if(mode == "R"){
            // setDataList((pre: any) => newData);
            setInputProperty(undefined)
            formProps?.form?.resetFields();
            clearDataSource()
            refetch();
            setselProp(undefined);
            setNotiData(undefined);
            settime_monday(undefined);
            // setselUnitType(undefined);
            setnameSearch("");
        }
    }

    // console.log('vEmail', vEmail)
    // console.log('vSms', vSms)
    // console.log('vTacType', vTacType)

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
    const plainOptions = ['IN APP', 'EMAIL', 'SMS'];
    
    const format = 'HH:mm';

    useEffect(() => {
      console.log("dayData",dayData)
    },)
    
    
    return <>
        <List 
            // title={t("mjcnotimsg.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjcnotimsg.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" style={{marginBottom:"1%"}} {...formProps} onFinish={(values:any) => {}}>
                        <Row gutter={20} style={{width: "100%"}}>

                            <Col span={4} className="gutter-row">
                                <Form.Item name="Search" label="Search">
                                    <Input onChange={(e) => setInputProperty(e.target.value)} placeholder="ระบุคำที่ต้องการค้นหา"/>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select placeholder={t("select.sel")} 
                                        {...selProperty} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                        {/* {
                                            dataProperty?.map((item:any,key:any) => {
                                                return <Option value={item?.id} key={item.id}>{item?.property_name}</Option>      
                                            })
                                        } */}
                                    </Select>
                                    {/* <Form.Item name="property">
                            <Select placeholder="Property" {...selProperty} allowClear style={{width: "220px"}}/>
                        </Form.Item> */}
                                </Form.Item>
                            </Col>
                            {/* <Col span={3} className="gutter-row">
                                <Form.Item name="วัน" label="วัน">
                                    <DatePicker onChange={(e) => setDayData(e)} allowClear />
                                </Form.Item>
                            </Col> */}
                            <Col span={3} className="gutter-row">
                                <Form.Item name="เวลาแจ้งเตือน" label="เวลาแจ้งเตือน">
                                    <TimePicker
                                        style={{width:'100%'}}
                                        onChange={(date:any, dateString:any) => {settime_monday(dateString)}}
                                        format={format} 
                                    />
                                    {/* <TimePicker 
                                        onChange={(e) => console.log(e)}
                                        defaultOpenValue={dayjs('00:00', 'HH:mm')} 
                                        format={'HH:mm'}
                                    /> */}
                                </Form.Item>
                            </Col>
                            <Col span={5} className="gutter-row">
                                <Form.Item name="plainOptions" label=" ">
                                    <div>
                                        <p style={{display: !!time_monday && !notiData ? 'flex' : 'none' , color: 'red', marginTop: '-35px'}}>* Please Select notification</p>
                                    </div>
                                        <Checkbox.Group
                                            onChange={(e)=>setNotiData(e)}
                                            options={plainOptions}
                                            disabled={!!notiData ? true : false}
                                        />
                                </Form.Item>
                            </Col>
                            
                            {/* <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label="Search">
                                    <Input placeholder="Search" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} suffix={<Icons.SearchOutlined/>} allowClear/>
                                </Form.Item>
                            </Col> */}
                            {/* <Col span={4} className="gutter-row">
                                <Form.Item name="unit_type" label="Unit Type">
                                    <Select placeholder={t("select.sel")} onChange={(value: any, label: any) => {if(value!== undefined){setselUnitType({id:value, label: label?.children})}else{setselUnitType(undefined)}}} allowClear>
                                        {
                                            dataUnitType?.map((item:any,key:any) => {
                                                return <Option value={item?.id} key={item.id}>{item?.unit_type_name_en}</Option>      
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={10} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form layout="vertical" style={{marginBottom:"1%",float: "right"}} {...formProps} onFinish={(values:any) => {}}>
                        <Form.Item style={{marginRight: "0",}} label=" ">
                            <CreateButton id="create_btn" onClick={()=>create("notification")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Table 
                // dataSource={dataWithIndex} 
                dataSource={dataonFilter} 
                // dataSource={dataList} 
                columns={columns} 
                onChange={onChange} 
                // pagination={{ size: "small", position: [ "bottomRight"] }} 
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    // showTotal: showTotal, 
                    pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                    showSizeChanger: true, // Optional - Show page size changer dropdown
                    // showQuickJumper: true, // Optional - Show quick jumper input
                    // pageSize: 10, // Optional - Default page size
                    pageSize: pageSize,
                    onChange: handlePageSizeChange,
                }}
                scroll={{ x: 1300 }}
            />
            {/* <Table columns={columns} dataSource={data} onChange={onChange} /> */}
        </List>

        <Modal 
            {...createModalProps}
            title={"Notification Messages Create"}
            okText={"Create"}
            // width={650}
            width={1250}
            afterClose={()=> {
                createProps?.form?.resetFields();
                setFileList([]);
            }}
        >
            <Form 
                {...createProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    values.property = values.property.id;
                    values.type = values.type.value;
                    values.msg = values.msg;
                    values.msg_en = values.msg;
                    values.createBy = user_data.user_profile.id;
                    values.deleteStatus = "N";

                    values.sms = values.sms == undefined ? false : true;
                    values.email = values.email == undefined ? false : true;
                    values.inApp = values.inApp == undefined ? false : true;

                    console.log(values)

                    return (
                        createProps.onFinish &&
                        createProps.onFinish(values)
                    );
                }}
            >
                <div className="divider-container">
                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty}/>
                            </Form.Item>

                            <Form.Item
                                name={["type", "value"]}
                                label="Type"
                                rules={[
                                    {
                                        required: true, message: "Please select type"
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t("select.sel")}
                                    // value={vDateOPArr}
                                    // onChange={handleChange}
                                >
                                    <Option value="HCUMSG_1">{"แจ้งเตือนรอบที่ 1"}</Option>
                                    <Option value="HCUMSG_2">{"แจ้งเตือนรอบที่ 2"}</Option>
                                    <Option value="POPUP_1">{"แจ้งเตือนรอดำเนินการติดต่อกลับ"}</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <Form.Item
                                name="inApp"
                                label="IN-APP" style={{width: "100%"}}
                            >
                                <Switch />
                            </Form.Item>

                            <Form.Item
                                name="msg_inapp"
                                label="Messages"
                                rules={[
                                    {
                                        required: true, message: "Please input description"
                                    },
                                ]}
                            >
                                <TextArea style={{height: "90px", maxHeight: "450px"}}
                                    // defaultValue={vShortDetails}
                                    // onChange={(e) => onChangeShortDetail(e)} 
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <Form.Item  
                                name="sms"
                                label="SMS" style={{width: "100%"}}
                            >
                                <Switch />
                            </Form.Item>

                            <Form.Item
                                name="msg_sms"
                                label="Messages"
                                rules={[
                                    {
                                        required: true, message: "Please input description"
                                    },
                                ]}
                            >
                                <TextArea style={{height: "90px", maxHeight: "450px"}}  
                                    maxLength={70}
                                    onChange={handleChange} 
                                    // defaultValue={vShortDetails}
                                    // onChange={(e) => onChangeShortDetail(e)}
                                />
                                <Text style={{float:'right'}}>{`${text.length} / 70`}</Text>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={6} className="gutter-row"></Col>
                        <Col span={2} className="gutter-row">
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={16}>
                            <Form.Item
                                name="email"
                                label="EMAIL" style={{width: "100%"}}
                            >
                                <Switch />
                            </Form.Item>

                            <Form.Item
                                name="description"
                                label="Messages"
                                rules={[
                                    {
                                        required: true, message: "Please input description"
                                    },
                                ]}
                            >
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "link",
                                            "bulletedList",
                                            "numberedList",
                                            "blockQuote",
                                            "|",
                                            "imageTextAlternative",
                                            "imageUpload",
                                            "imageStyle:full",
                                            "imageStyle:side",
                                        ],
                                        // plugins: [ FontColor ],
                                        extraPlugins: [uploadPlugin],
                                        mediaEmbed: {
                                            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                        },
                                        image: {
                                            toolbar: ['']
                                        }
                                
                                    }}
                                    
                                    onInit={(editor:any) => {}}
                                    onChange={(event:any, editor:any) => {
                                        const data = editor.getData();
                                        // setdetail(data);
                                    }}
                                    onBlur={(editor:any) => {}}
                                    onFocus={(editor:any) => {}}
                                />

                                {/* <MDEditor
                                    value={value}
                                    // onChange={(e) => setValue(e.target?.value)}
                                    // onChange={(e) => setTitle(e.target.value)}
                                    // onChange={e => setValue(e.target.value)}
                                    onChange={setValue}

                                />
                                <MDEditor.Markdown source={value} style={{ whiteSpace: 'pre-wrap' }} /> */}
                            </Form.Item>

                        </Col>
                    </Row>
                </div>


                {/* <Row gutter={16}>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            name={["property", "id"]}
                            label="Property"
                            rules={[
                                {
                                    required: true, message: "Please select property"
                                },
                            ]}
                        >
                            <Select placeholder={t("select.sel")} {...selProperty}/>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        <Form.Item
                            name={["type", "value"]}
                            label="Type"
                            rules={[
                                {
                                    required: true, message: "Please select type"
                                },
                            ]}
                        >
                             <Select
                                placeholder={t("select.sel")}
                                // value={vDateOPArr}
                                // onChange={handleChange}
                            >
                                <Option value="HCUMSG_1">{"แจ้งเตือนรอบที่ 1"}</Option>
                                <Option value="HCUMSG_2">{"แจ้งเตือนรอบที่ 2"}</Option>
                                <Option value="POPUP_1">{"แจ้งเตือนรอดำเนินการติดต่อกลับ"}</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        <Form.Item
                            name="msg"
                            label="Messages"
                            rules={[
                                {
                                    required: true, message: "Please input description"
                                },
                            ]}
                        >
                            <TextArea style={{height: "90px", maxHeight: "450px"}}  
                                // defaultValue={vShortDetails}
                                // onChange={(e) => onChangeShortDetail(e)} 
                            />
                        </Form.Item>
                    </Col>
                </Row> */}

                {/* <div><b>Nofifacations</b></div>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item  
                            name="sms"
                            label="SMS" style={{width: "100%"}}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item  
                            name="email"
                            label="EMAIL" style={{width: "100%"}}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item  
                            name="inApp"
                            label="IN-APP" style={{width: "100%"}}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row> */}
            </Form>
        </Modal>

        <Modal 
            {...editModalProps}
            title={"Notification Messages Edit"}
            okText = {"Save"}
            width={650}
            afterClose={()=> {
                editProps?.form?.resetFields();
                setswLocal(true);
            }}
        >
            <Form 
                {...editProps}
                layout="vertical"
                onFinish={(values:any) => {
                    values.type = values.type.value ? values.type.value : vTacType;
                    values.msg = values.msg
                    values.msg_en = values.msg_en
                    values.sms = vSms;
                    values.email = vEmail;
                    values.inApp = vInApp;
                    values.updateBy = user_data.user_profile.id;
                    setswLocal(true);
                    return (
                        editProps.onFinish &&
                        editProps.onFinish(values)
                    );
                }}
            >
                <Row gutter={16}>
                    <Radio.Group 
                        options={optionsRdioLocale} 
                        onChange={(e)=> {
                            swLocal = e.target.value
                            setswLocal(e.target.value)
                            loadLocal();
                        }}
                        defaultValue={true}
                        optionType="button"
                        style={{marginBottom: "2rem"}}
                    />

                    <Col span={24} className="gutter-row">
                        <Form.Item
                            name={["property", "id"]}
                            label="Property"
                            rules={[
                                {
                                    required: true, message: "Please select property"
                                },
                            ]}
                        >
                            <Select placeholder={t("select.sel")} {...selProperty} disabled={vswLocal == false ? true : false}/>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        <Form.Item
                            name={["type", "value"]}
                            label="Type"
                            rules={[
                                {
                                    required: vTacType !== null ? false : true, message: "Please select type"
                                },
                            ]}
                        >
                             <Select
                                placeholder={t("select.sel")}
                                value={vTacType ? vTacType : null}
                                defaultValue={DataCus ? DataCus.type : null}
                                disabled={vswLocal == false ? true : false}
                            >
                                <Option value="HCUMSG_1">{"แจ้งเตือนรอบที่ 1"}</Option>
                                <Option value="HCUMSG_2">{"แจ้งเตือนรอบที่ 2"}</Option>
                                <Option value="POPUP_1">{"แจ้งเตือนรอดำเนินการติดต่อกลับ"}</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        {
                            vswLocal ?
                                <>
                                    <Form.Item
                                        name="msg"
                                        label="Messages"
                                        rules={[
                                            {
                                                required: true, message: "Please input messages"
                                            },
                                        ]}
                                    >
                                        <TextArea style={{height: "90px", maxHeight: "450px"}} />
                                    </Form.Item>
                                </> 
                            :
                                <>
                                    <Form.Item
                                        name="msg_en"
                                        label="Messages EN"
                                        rules={[
                                            {
                                                required: true, message: "Please input messages"
                                            },
                                        ]}
                                    >
                                        <TextArea style={{height: "90px", maxHeight: "450px"}} />
                                    </Form.Item>
                                </>
                        }
                    </Col>
                </Row>
                {/* <Divider /> */}
                <div><b>Nofifacations</b></div>
                <Row gutter={16}>
                    <Col span={4}>
                        <Form.Item
                            name={["sms", "value"]}
                            label="SMS" style={{width: "100%"}}
                        >
                            <Switch onChange={(value:any) => (onChangeSwitch(value,"sms"))} checked={vSms} disabled={vswLocal == false ? true : false}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={["email", "value"]}
                            label="EMAIL" style={{width: "100%"}}
                        >
                            <Switch onChange={(value:any) => (onChangeSwitch(value,"email"))} checked={vEmail} disabled={vswLocal == false ? true : false}/>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            name={["inApp", "value"]}
                            label="IN-APP" style={{width: "100%"}}
                        >
                            <Switch onChange={(value:any) => (onChangeSwitch(value,"inapp"))} checked={vInApp} disabled={vswLocal == false ? true : false}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}