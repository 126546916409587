/* eslint-disable */
import { Show, TextField, Typography, Image, List, Space, Breadcrumb, Row, Col, Card, Carousel, Empty, Avatar, Switch } from "@pankod/refine-antd";

import { IFacility, IFacilityUnitType } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useList, useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {  StopOutlined, PhoneOutlined, HistoryOutlined } from "@ant-design/icons";

import Moment from 'moment';
// import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";

// import Moment from 'moment';

export const FacilitiesShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IFacility>({  
        metaData: {
            populate: ['property','gallery','facility_conditions','facility_conditions.condition_pix','facility_time_slots','facility_type','facility_unit_types','cover']
        },
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;
    const { Text } = Typography;

    let recordData:any = []
    recordData = data?.data? data?.data : [];

    const facilityUnitType = useList<IFacilityUnitType>({
        resource: "facility-unit-types",
        metaData: {
            populate: '*'
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "facility][id]",
                    operator: "eq",
                    value   : recordData.id + ""
                },
                {
                    field   : "deleteStatus",
                    operator: "contains",
                    value   : "N"
                },
            ]
        }
    });

    const { data: faciUnitType } = facilityUnitType;
    let faciUnitTypeData:any = []
    faciUnitTypeData = faciUnitType?.data? faciUnitType?.data : [];

    const [count, setCount] = useState(0)
    const [checkin, setcheckin] = useState<any>();
    const [vtimeslot, settimeslot] = useState<any>([]);

    useEffect(() => {
        if (count < 3) {
          const interval = setInterval(() => {
            setCount(prev => prev + 1)

            setcheckin(recordData.checkin==true? true:false)

            {
                recordData.facility_time_slots !== undefined ?
                    recordData.facility_time_slots.length > 0 ?
                        <>
                            {settimeslot(recordData.facility_time_slots)}
                        </>:<></>
                :<></>
            }
          }, 1000)
          return () => clearInterval(interval)
        }
    }, [count])

    if(vtimeslot !== null && vtimeslot !== undefined){
        if(vtimeslot.length > 0){
            vtimeslot.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(
                b.name,
                undefined,
                { numeric: true, sensitivity: 'base' }
            ));
        }
    }

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>

        <List 
            title={t("")}
            pageHeaderProps={{ 
                extra : <></>
            }}
        />
            <Space style={{marginBottom: "3%"}}>
                <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt.show")}</span></Breadcrumb.Item>
                </Breadcrumb>
            </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={18}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset",marginBottom: "2%"}}>
                                    <Row>
                                        <Col span={5}>
                                                {
                                                    record?.cover !== undefined && record?.cover !== null ?
                                                    <>
                                                        <Carousel style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px",height: "17vh"}}>
                                                            <Image preview={false} src={record?.cover === null ? "https://joeschmoe.io/api/v1/random" :record?.cover.url} width={"100%"} height={"17vh"} style={{height:"100%",objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px"}} />
                                                        </Carousel>
                                                    </>
                                                    :<>-</>
                                                }
                                        </Col>

                                        <Col className="gutter-row" span={19}>
                                            <div style={{marginLeft: "5%"}}>
                                                <TextField  style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#fff"}} value={record?.name ? record?.name : null} />
                                                <Text style={{color: "#fff",fontSize: "2vw",fontWeight: "600",marginLeft: "2%"}}>(</Text>
                                                <TextField style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#fff"}} value={record?.facility_type ? record?.facility_type.name : null} />
                                                <Text style={{color: "#fff",fontSize: "2vw",fontWeight: "600"}}>)</Text>

                                                <span className="id-userprofile" style={{marginLeft: "3%",width: "100%",position: "absolute",paddingTop: "2.5%"}}><Text style={{borderRadius: "50px 0px 0px 50px",paddingLeft: "3%",boxShadow: "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 0px 10px -10px"}}>ID</Text>
                                                    <TextField style={{borderRadius: "0px 50px 50px 0px", paddingLeft: "2%", paddingRight: "3%", boxShadow: "rgb(50 50 93 / 25%) -2px 6px 10px -2px, rgb(0 0 0 / 30%) 0px 0px 20px -10px"}} value={record?.id ? record?.id : '-'} />
                                                </span>
                                            </div>

                                            <div style={{marginLeft: "3%", marginBottom: "2%", marginTop: "2%", borderRadius: "10px"}}>
                                                {record?.no_booking === true ? 
                                                <>
                                                    <span 
                                                        style={{
                                                            fontSize: "0.8vw",
                                                            marginTop: "10%",

                                                            marginLeft: "2%",
                                                            borderRadius: "50px", 
                                                            paddingLeft: "2%", 
                                                            paddingRight: "2%",
                                                            paddingBottom: "1%",
                                                            paddingTop: "1%",
                                                            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                            background: "rgb(172, 172, 172)",
                                                        }} >
                                                            <TextField style={{color: "#fff"}} value="ไม่มีการจอง" />
                                                    </span>
                                                </>
                                                :
                                                <></>}

                                                {record?.private_room === true ? 
                                                <>
                                                    <span 
                                                        style={{
                                                            fontSize: "0.8vw",
                                                            marginTop: "10%",

                                                            marginLeft: "2%",
                                                            borderRadius: "50px", 
                                                            paddingLeft: "2%", 
                                                            paddingRight: "2%",
                                                            paddingBottom: "1%",
                                                            paddingTop: "1%",
                                                            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                            background: "rgb(172, 172, 172)",
                                                        }} >
                                                            <TextField style={{color: "#fff"}} value="ห้องส่วนตัว" />
                                                    </span>
                                                </>
                                                :
                                                <></>}

                                                {record?.capacity !== 0 && record?.capacity !== null && record?.capacity !== undefined ? 
                                                <>
                                                    <span 
                                                        style={{
                                                            fontSize: "0.8vw",
                                                            marginTop: "10%",

                                                            marginLeft: "2%",
                                                            borderRadius: "50px", 
                                                            paddingLeft: "2%", 
                                                            paddingRight: "2%",
                                                            paddingBottom: "1%",
                                                            paddingTop: "1%",
                                                            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                            background: "rgb(172, 172, 172)",
                                                        }} >
                                                            <TextField style={{color: "#fff"}} value={"การจำกัดจำนวนคน : " + record.capacity} />
                                                    </span>
                                                </>
                                                :
                                                <></>}

                                                <span 
                                                    style={{
                                                        fontSize: "0.8vw",
                                                        marginTop: "10%",

                                                        marginLeft: "2%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "rgb(172, 172, 172)",
                                                    }} >
                                                        <TextField style={{color: "#fff"}} value="เวลาให้บริการ : "/>
                                                        <TextField style={{color: "#fff"}}
                                                            value={Moment(recordData ? recordData.open_time:"NO DATA", 'HH:mm').format('HH:mm')}
                                                        />
                                                        <Text style={{color: "#fff"}}> - </Text>
                                                        <TextField style={{color: "#fff"}}
                                                            value={Moment(recordData ? recordData.close_time:"NO DATA", 'HH:mm').format('HH:mm')}
                                                        />
                                                </span>

                                                {record?.booking == true ?
                                                    <span 
                                                        style={{
                                                            fontSize: "0.8vw",
                                                            marginTop: "10%",

                                                            marginLeft: "2%",
                                                            borderRadius: "50px", 
                                                            paddingLeft: "2%", 
                                                            paddingRight: "2%",
                                                            paddingBottom: "1%",
                                                            paddingTop: "1%",
                                                            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                            background: "rgb(125, 232, 101)",
                                                        }} >
                                                            <TextField style={{color: "#fff"}} value="เปิดการจองล่วงหน้า" />
                                                    </span>
                                                    :
                                                    <span 
                                                        style={{
                                                            fontSize: "0.8vw",
                                                            marginTop: "10%",

                                                            marginLeft: "2%",
                                                            borderRadius: "50px", 
                                                            paddingLeft: "2%", 
                                                            paddingRight: "2%",
                                                            paddingBottom: "1%",
                                                            paddingTop: "1%",
                                                            boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                            background: "rgb(247, 49, 73)",
                                                        }} >
                                                            <TextField style={{color: "#fff"}} value="ปิดการจองล่วงหน้า" />
                                                    </span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={8} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                            <div style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>Description</div>
                                            <TextField style={{fontSize: "0.7vw"}} value={record?.description ? record?.description : "NO DATA"} />
                                        </Col>

                                        <Col className="gutter-row" span={8} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                            <div style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>Special Announcement</div>
                                            <TextField style={{fontSize: "0.7vw"}} value={record?.special_announcement ? record?.special_announcement : "NO DATA"} />
                                        </Col>

                                        <Col className="gutter-row" span={8}>
                                            <div style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>Note</div>
                                            <TextField style={{fontSize: "0.7vw"}} value={record?.note ? record?.note : "NO DATA"} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col> 

                            <Col className="gutter-row" span={24}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{marginBottom: "1%"}}>
                                    <Col className="gutter-row" span={6}>
                                        {
                                        recordData.gallery !== undefined && recordData.gallery !== null ? 
                                        <Carousel style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "30px",height: "25vh"}} autoplay>
                                            {
                                            recordData.gallery.map((data:any,index:any) => {
                                                    return <>
                                                        <Image src={data.url} width={"100%"} height={"27.5vh"} style={{height:"100%",objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "30px"}} />
                                                    </>
                                                })}
                                        </Carousel>
                                        :
                                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",height: "25vh"}}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </Card>
                                        }
                                    </Col>

                                    <Col className="gutter-row" span={18}>
                                        {recordData.facility_conditions ?
                                            recordData.facility_conditions !== undefined ?
                                                recordData.facility_conditions.length > 0 ?
                                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "3%"}}>
                                                        <div style={{fontSize: "0.9vw",fontWeight: "600",color: "#000",marginBottom: "2%"}}>Conditions</div>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                            {recordData.facility_conditions ? recordData? 
                                                                recordData.facility_conditions !== undefined ?
                                                                    recordData.facility_conditions.length > 0 ? 
                                                                        recordData.facility_conditions.map((v:any)=>{
                                                                            return  <Col className="gutter-row" span={6}>
                                                                                        <Row>
                                                                                            <Col span={4}>
                                                                                            {v.condition_pix !== null && v.condition_pix !== undefined ?
                                                                                                <Avatar src={v.condition_pix.url} style={{width: "80%",height: "80%"}}/>:<StopOutlined style={{fontSize: "0.6vw",color: "red"}}/>
                                                                                            }
                                                                                            </Col>
                                                                                            <Col span={20}><div style={{fontSize: "0.6vw",color: "#000",marginLeft: "1%",marginTop: "1%"}}>{v.description}</div></Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                        }) 
                                                                    : <></>
                                                                : <></>
                                                            : <></>: <Text style={{fontSize: "0.6vw",color: "#000"}}>No Condition</Text> }
                                                        </Row>
                                                    </Card>
                                                :<></>:<></>
                                            :<></>
                                        }

                                        {recordData.facility_time_slots ?  
                                            recordData.facility_time_slots !== undefined ?
                                                recordData.facility_time_slots.length > 0 ?
                                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "3%"}}>
                                                        <div style={{fontSize: "0.9vw",fontWeight: "600",color: "#000",marginBottom: "2%"}}>Time Slots</div>
                                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                            {vtimeslot !== undefined ?
                                                                vtimeslot.length > 0 ? 
                                                                    vtimeslot.map((v:any)=>{
                                                                        console.log(">>> v",v)
                                                                        return  <Col className="gutter-row" span={6}>
                                                                                    <Row>
                                                                                        <Col span={10}>
                                                                                            <span style={{background: "#8CDC71",borderRadius: "50px",paddingLeft: "10%",paddingRight: "10%",boxShadow: "rgba(33, 35, 38, 0.1) 0px 10px 10px -10px"}}>
                                                                                                <span style={{fontSize: "0.6vw",color: "#fff"}}>{v.name}</span>
                                                                                            </span>
                                                                                        </Col>
                                                                                        <Col span={14}>
                                                                                            <TextField style={{marginLeft: "3%",fontSize: "0.6vw",color: "#000"}} value={Moment(v.time_start,'HH:mm').format('HH:mm')} />
                                                                                                <span style={{fontSize: "0.6vw",color: "#000"}}> - </span>
                                                                                            <TextField style={{fontSize: "0.6vw",color: "#000"}} value={Moment(v.time_end,'HH:mm').format('HH:mm')} />
                                                                                        </Col>
                                                                                    </Row>
                                                                                </Col>
                                                                    }) 
                                                                : <></>
                                                            :<Text style={{fontSize: "0.6vw",color: "#000"}}>No Time Slot</Text> }
                                                        </Row>
                                                    </Card>
                                                :<></>:<></>
                                            :<></>
                                        }
                                    </Col>
                                </Row>    
                            </Col>

                            <Col className="gutter-row" span={16}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                    { recordData.reservation_permis == true ? 
                                        <>
                                            <div style={{marginBottom: "2%"}}><span style={{fontSize: "0.7vw",color: "#000", marginRight: "1%"}}>สิทธิการจอง</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked /></div>
                                            
                                            {recordData.reservation_user_round !== 0 && recordData.reservation_user_round !== null && recordData.reservation_user_round !== undefined ? 
                                            <>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={1}>
                                                        <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle',color: "#000",fontWeight: "300"}}><b>{recordData.reservation_user_round ? recordData.reservation_user_round: null }<>&nbsp;</></b></span>
                                                    </Col>

                                                    <Col className="gutter-row" span={1.5}>
                                                        <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle',color: "#000",fontWeight: "300"}}><b>รอบ / <>&nbsp;</></b></span>
                                                    </Col>

                                                    <Col className="gutter-row" span={4}>
                                                        <span style={{fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{recordData.facility_round_type_user ? recordData.facility_round_type_user.name: null}</span>
                                                    </Col> 
                                                </Row>
                                            </>
                                            :null}
                                            {/* ที่เทอทำแค่เสแสร้ง */}
                                            {recordData.reservation_unit_round !== 0 && recordData.reservation_unit_round !== null && recordData.reservation_unit_round !== undefined  ? 
                                            <>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={9}>
                                                        <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle',color: "#000",fontWeight: "300"}}><b>{recordData.reservation_unit_round ? recordData.reservation_unit_round: null} : <>&nbsp;</></b></span>
                                                    </Col>

                                                    <Col className="gutter-row" span={1.5}>
                                                        <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle',color: "#000",fontWeight: "300"}}><b>รอบ / <>&nbsp;</></b></span>
                                                    </Col>

                                                    <Col className="gutter-row" span={4}>
                                                        <span style={{fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{recordData.facility_round_type_unit ? recordData.facility_round_type_unit.name: null }</span>
                                                    </Col> 
                                                </Row>
                                            </>
                                            :null}

                                            {faciUnitTypeData.length > 0?
                                                faciUnitTypeData?.map((el:any,index:any) => {
                                                    return <>
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={9}>
                                                                <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle',color: "#000",fontWeight: "300"}}><b>{el.unit_type.name} : <>&nbsp;</></b></span>
                                                            </Col>

                                                            <Col className="gutter-row" span={1.8}>
                                                                <span style={{fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{el.limit_round}</span>
                                                            </Col>

                                                            <Col className="gutter-row" span={1.5}>
                                                                <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle',color: "#000",fontWeight: "300"}}><b>รอบ / <>&nbsp;</></b></span>
                                                            </Col>

                                                            <Col className="gutter-row" span={4}>
                                                                <span style={{fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{el.facility_round_type.name}</span>
                                                            </Col> 
                                                        </Row>
                                                    </>
                                                }):null}
                                                
                                        </>
                                        :
                                        <>
                                            {recordData.reservation_permis == false || recordData.reservation_permis == null ? 
                                            <>
                                                <span style={{fontSize: "0.7vw",color: "#000", marginRight: "1%"}}>สิทธิการจอง</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด"/>
                                            </>
                                            :
                                            <></>}
                                        </>
                                    }
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                    {
                                        recordData.advance_booking== true ? 
                                            <>
                                                <div><span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>การจอง</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked/>
                                                {
                                                    recordData.booking == true ? 
                                                    <>
                                                        <span style={{fontSize: "0.7vw",color: "#000",marginLeft: "8%"}}>ต้องจองล่วงหน้า
                                                            {
                                                                recordData.set_booking_day !== 0 && recordData.set_booking_day !== null && recordData.set_booking_day !== undefined ? 
                                                                    <>
                                                                        <span style={{marginLeft: "2%",marginRight: "2%",fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{recordData.set_booking_day}</span>
                                                                        <span style={{fontSize: "0.7vw",color: "#000"}}>วัน</span>
                                                                    </>
                                                                :
                                                                    null
                                                            }
                                                            {
                                                                recordData.set_booking_hours !== 0 && recordData.set_booking_hours !== null && recordData.set_booking_hours !== undefined ? 
                                                                    <>
                                                                        <span style={{marginLeft: "2%",marginRight: "2%",fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{recordData.set_booking_hours}</span>
                                                                        <span style={{fontSize: "0.7vw",color: "#000"}}>ชั่วโมง</span>
                                                                    </>
                                                                :
                                                                    null
                                                            }

                                                            {
                                                                recordData.set_booking_min !== 0 && recordData.set_booking_min !== null && recordData.set_booking_min !== undefined ? 
                                                                    <>
                                                                        <span style={{marginLeft: "2%",marginRight: "2%",fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{recordData.set_booking_min}</span>
                                                                        <span style={{fontSize: "0.7vw",color: "#000"}}>นาที</span>
                                                                    </>
                                                                :
                                                                    null
                                                            }
                                                        </span>
                                                    </>
                                                    :
                                                    <>
                                                        <span style={{fontSize: "0.7vw",color: "#000",marginLeft: "8%"}}>ไม่กำหนดเวลาจองล่วงหน้า</span>
                                                    </>
                                                }
                                                </div>
                                            </>
                                        :
                                            <>
                                                {
                                                    recordData.advance_booking == false || recordData.advance_booking == null ? 
                                                        <>
                                                            <span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>การจอง</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด"/>
                                                        </>
                                                    :
                                                        <></>
                                                }
                                        </>
                                    }
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "4%"}}>
                                    {
                                    checkin== true ? 
                                        <>
                                            <div style={{marginBottom: "3%"}}><span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>การเช็คอิน</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked/></div>
                                            <div>
                                                <span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>เช็คอินก่อนเวลาจองได้</span>
                                                <span style={{fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{recordData?.checkin_befor_time}</span>
                                                <span style={{fontSize: "0.7vw",color: "#000", marginLeft: "2%"}}>นาที</span>
                                            </div>

                                            <div>
                                                <span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>เช็คอินหลังเวลาจองได้ไม่เกิน</span>
                                                <span style={{fontSize: "0.7vw",color: "#000",fontWeight: "600"}}>{recordData?.checkin_after_time}</span>
                                                <span style={{fontSize: "0.7vw",color: "#000", marginLeft: "2%"}}>นาที</span>
                                            </div>
                                        </>
                                        :
                                        <>
                                        {
                                            checkin== false ? 
                                            <>
                                                <div><span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>การเช็คอิน</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด"/></div>
                                            </>
                                            :
                                            <></>
                                        }
                                        </>
                                    }
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "3%"}}>
                                    {recordData.facility_noti== true ? 
                                        <>
                                            <div><span style={{fontSize: "0.7vw",color: "#000",marginRight: "1.5%"}}>การแจ้งเตือน</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked/>
                                                <span style={{fontSize: "0.7vw",color: "#000",marginLeft: "5%"}}>ต้องแจ้งเตือนล่วงหน้า</span>
                                                <span style={{fontSize: "0.7vw",color: "#000", marginLeft: "2%", marginRight: "2%",fontWeight: "600"}}>{recordData.facility_noti_day}</span>
                                                <span style={{fontSize: "0.7vw",color: "#000"}}>วัน</span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {recordData.facility_noti== false || recordData.facility_noti== null ? 
                                            <>
                                                <div><span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>การแจ้งเตือน</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด"/></div>
                                            </>:null
                                            }
                                        </>
                                    }
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "3%"}}>
                                    {recordData.waiting_status== true ? 
                                        <>
                                            <div><span style={{fontSize: "0.7vw",color: "#000",marginRight: "1.5%"}}>Waiting List</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked/>
                                                <span style={{fontSize: "0.7vw",color: "#000",marginLeft: "5%"}}>เปิดให้มี Waiting List</span>
                                                <span style={{fontSize: "0.7vw",color: "#000", marginLeft: "2%", marginRight: "2%",fontWeight: "600"}}>{recordData.waiting_q}</span>
                                                <span style={{fontSize: "0.7vw",color: "#000"}}>คิว</span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            {recordData.waiting_status== false || recordData.waiting_status== null ? 
                                            <>
                                                <div><span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>Waiting List</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด"/></div>
                                            </>:null
                                            }
                                        </>
                                    }
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "1.5%"}}>
                                        { recordData.status== "OPEN" ? 
                                        <>
                                            <div><span style={{fontSize: "0.7vw",color: "#000",marginRight: "1.5%"}}>สถานะการให้บริการปัจจุบัน</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด" defaultChecked/></div>
                                        </>
                                        :
                                        <>
                                            {recordData.status== "CLOSES" ? 
                                            <>
                                                <div><span style={{fontSize: "0.7vw",color: "#000", marginRight: "2%"}}>สถานะการให้บริการปัจจุบัน</span><Switch disabled={true} checkedChildren="เปิด" unCheckedChildren="ปิด"/>
                                                    <span style={{fontSize: "0.7vw",color: "#000",marginLeft: "3%"}}>ตั้งแต่วันที่</span>
                                                    <span style={{fontSize: "0.7vw",color: "#000", marginLeft: "2%", marginRight: "2%",fontWeight: "600"}}>{Moment(recordData.start_opendate).format('D/MM/yyyy')}</span>

                                                    <span style={{fontSize: "0.7vw",color: "#000",marginLeft: "3%"}}>ถึงวันที่</span>
                                                    <span style={{fontSize: "0.7vw",color: "#000", marginLeft: "2%", marginRight: "2%",fontWeight: "600"}}>{Moment(recordData.end_opendate).format('D/MM/yyyy')}</span>
                                                </div>
                                                
                                                {recordData.close_note ? 
                                                <>
                                                    <TextField value={recordData ? recordData?.close_note: "NO NOTE"} style={{fontSize: "0.7vw",marginTop: "3%"}}/>
                                                </>
                                                :<></>}
                                            </>:null
                                            }
                                        </>
                                    }
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                                    <span style={{fontSize: "0.9vw",fontWeight: "600",color: "#000"}}>{t("property.title")} : <TextField style={{fontSize: "0.9vw",fontWeight: "600",letterSpacing: "0.5px",color: "#000"}} value={record?.property ? record?.property.property_name : <></>} /></span>

                                    <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw"}}>{t("phonebook.address")}</span></div>

                                    <TextField value={record?.property ? record?.property.address : <></>} style={{marginRight: "3%", fontSize: "0.7vw",color: "#000"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.city")} </Text>
                                    <TextField value={record?.property ? record?.property.city : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.province")} </Text>
                                    <TextField value={record?.property ? record?.property.province : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.post_code")} </Text>
                                    <TextField value={record?.property ? record?.property.postcode : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <Text style={{fontSize: "0.7vw"}}>{t("phonebook.fax")} </Text>
                                    <TextField value={record?.property ? record?.property.fax : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                    <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}>{t("properties.about")}</div><TextField style={{fontSize: "0.7vw"}} value={record?.property ? record?.property.about : <></>} />

                                    <div style={{marginTop: "3%"}}><span style={{background: "#40495F",borderRadius: "20px",padding: "1% 3% 1%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={record?.property ? record?.property.tel : '-'} /></span></div>     
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="gutter-row" span={6}>
                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>
                                                        
                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createdBy? record?.createdBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{fontSize: "0.7vw"}}>{record?.updatedBy? record?.updatedBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
        </Show>
    );
};