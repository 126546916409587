/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, message, RcFile, Row, Select, Upload, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import { IEventlog, IFacilityType } from "interfaces";

export const FacilitiesTypeCreate: React.FC<IResourceComponentsProps> = () => {

    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const { formProps, saveButtonProps } = useForm<IFacilityType,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            // console.log(data?.data);
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            // recordData.data.id + ""
            CreateLog({
                resource    : log_endpoint,
                values      : {
                    // user_profile            : user.id,
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(), 
                    log_event               : "Create",
                    menu_event              : 'facility-types',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
    });

    const API_URL = useApiUrl();
    // const { Option } = Select;
    // const { TextArea } = Input;
    const t = useTranslate();

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
            
        }
    }


    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
      };
      
    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            // title={t("fclt-type.create")}
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-type.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />

        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.createBy = user_data.user_profile.id
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("fclt-type.code")} name="code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("fclt-type.name")} name="name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>

                            </Col>

                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("fclt-type.name-en")} name="name_en"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name (ENG).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            {/* <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("fclt-type.desc")} name="description"
                                    rules={[
                                        {
                                            required: true, message: 'please enter name.'
                                        },
                                    ]}>
                                        <TextArea style={{height: "30px", maxHeight: "150px"}}/>
                                </Form.Item>
                            </Col> */}
                        </Row>

                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item
                            name="icon_pix"
                            label={t("fclt-type.icnpix")}
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            rules={[
                                {
                                    required: true, message: 'Please upload image.'
                                },
                            ]}>
                            <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                maxCount={1}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Upload.Dragger>
                            {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                        </Form.Item> 
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 50*50 PNG, JPG Maximum size 5MB</p>
                        {/* <p style={{textAlign: "left",color: "#DF6677"}}>Only Size is 50 x 50 px</p> */}

                        <Form.Item
                            name="main_pix"
                            label={t("fclt-type.imglist")}
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            rules={[
                                {
                                    required: true, message: 'Please upload image.'
                                },
                            ]}>
                            <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                maxCount={1}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Upload.Dragger>
                            {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 338*190 PNG, JPG Maximum size 5MB</p>
                        

                        {/* <p style={{textAlign: "left",color: "#DF6677"}}>Only Size is 300 x 300 px</p> */}
                    </Card>
                </Col>



                {/* <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff", marginBottom: "2%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col> */}
            </Row>
        </Form>
    </Create>   
    );
};