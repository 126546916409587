import { Breadcrumb, Button, Card, Checkbox, Col, Divider, Edit, Form, Icons, Input, Radio, Row, Select, Space, useForm, useSelect, Image, Empty, Switch, Upload, RcFile, message, UploadFile, DatePicker, TextField, Modal, Spin } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IFacility, IFacilityCondition, IFacilityRoundType, IFacilityTimeSlot, IFacilityType, IFacilityUnitType, IProperties, IUnitType } from "interfaces";
import { useEffect, useState } from "react";
import moment from 'moment';
import Moment from 'moment';
import dayjs, { Dayjs } from "dayjs";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TimePicker } from "antd";
import { TOKEN_KEY } from "../../constants";

let recordData :any = undefined;
let fcltID :any = undefined;
let propID :any = undefined;
let conditionData :any = [];
let unittypeData :any = [];
let fcltunittypeData :any = [];
export const FacilitiesEdit: React.FC<IResourceComponentsProps> = () => {

    const format = 'HH:mm';
    const format_k = 'HH:mm:ss';
    const t = useTranslate();
    const { Option } = Select;
    const { TextArea } = Input;
    const { Dragger } = Upload;
    const API_URL = useApiUrl();
    const userQuery = useGetIdentity();
    const {data : user_data} = userQuery;

    const { form, formProps, saveButtonProps } = useForm<IFacility>({redirect:"list"});

    const { mutate: UpdateFacilityTimeSlot }    = useUpdate<IFacilityTimeSlot>();
    const { mutate: UpdateFacilityUnitType }    = useUpdate<IFacilityUnitType>();
    const { mutate: UpdateFaciUnitType}     = useUpdate<IFacilityUnitType>();
    const { mutate: UpdateFaciOnlyTimeSlot} = useUpdate<IFacility>();
    const { mutate: CreateFaciUnitType}     = useCreate<IFacilityUnitType>();
    const { mutate: CreateFaciTimeSlot}     = useCreate<IFacilityTimeSlot>();

    const [isLoading, setisLoading] = useState<boolean>(true);

    const [valueProp, setProp] = useState<any>(); // !! OLD == vProperty
    const [valueRemark, setRemark] = useState<any>(); // !! OLD == vNoteRemark
    const [valuejongType, setjongType] = useState<any>(1); // !! OLD == vSetJongType
    const [valueJong, setJong] = useState<any>(); // !! OLD == vSetJong
    const [valueJongF, setJongF] = useState<any>(); // !! OLD == vSetJongF
    const [valuedateop, setdateop] = useState<any>(); // !! OLD == vDateOP
    const [valuedateopArr, setdateopArr] = useState<any>(); // !! OLD == vDateOPArr
    const [valueftimeSlot, setftimeSlot] = useState<any>([]); // !! OLD == vFacTimeSlot
    const [valuetimeSlot, settimeSlot] = useState<any>([]); // !! OLD == vTimeSlotTEST
    const [oldTimeSlot, setOldTimeSlot] = useState<any>([]); // Kom get old timeslot want to remove
    const [valuetimeslotTemp, settimeslotTemp] = useState<any>([]); // !! OLD == vTimeSlotTempTEST
    const [valueCondition, setCondition] = useState<any>([]); // !! OLD == vCondition
    const [valueWait, setWait] = useState<any>(); // !! OLD == vSetWait
    const [valueccJong, setccJong] = useState<any>(); // !! OLD == vSetCCJong
    const [valueNoti, setNoti] = useState<any>(); // !! OLD == vSetNoti

    const [inputjongType, setinputjongType] = useState<any>(); // !! OLD == inputJongType
    const [inputBooking, setinputBooking] = useState<any>(); // !! OLD == inputBooking
    const [inputres_user, setinputres_user] = useState(''); // !! OLD == inputRes_userR
    const [inputres_unit, setinputres_unit] = useState(''); // !! OLD == inputRes_unitR
    const [inputunitType, setinputunitType ] = useState<any>([]); // !! OLD == vInputUnitType
    const [inputJongFD, setinputJongFD] = useState<any>(''); // !! OLD == inputJongFD
    const [inputJongFH, setinputJongFH] = useState<any>(''); // !! OLD == inputJongFH
    const [inputJongFM, setinputJongFM] = useState<any>(''); // !! OLD == inputJongFM
    const [inputCbfTime, setinputCbfTime] = useState('');
    const [inputCafTime, setinputCafTime] = useState('');
    const [inputCincode, setinputCincode] = useState('');
    const [inputWaitQ, setinputWaitQ] = useState('');
    const [inputCCJong, setinputCCJong] = useState('');
    const [inputNoti, setinputNoti] = useState('');
    const [inputNotimsg, setinputNotimsg] = useState('');

    const [openTime, setopenTime] = useState<any>(); // !! OLD == vOpTime
    const [closeTime, setcloseTime] = useState<any>(); // !! OLD == vClTime
    const [swsitJong, setswsitJong] = useState<any>(); // เปลี่ยนโหมดการจอง !! OLD == swSitJong
    const [swsitJongL, setswsitJongL] = useState<any>(); // บังคับ sw การจอง true / false !! OLD == LSwSitJong
    const [swJong, setswJong]= useState<any>(); //!! OLD == LSwJong
    const [swlCheckin, setswlCheckin] = useState<any>(); //!! OLD == LSwCheckin
    const [swCheckin, setswCheckin] = useState<any>(); //!! OLD == swCheckin
    const [swStatus, setswStatus] = useState<any>(); //!! OLD == LSwStatus
    const [swstDate, setswstDate] = useState<any>();

    const [valuesitJong, setsitJong] = useState<any>(); //เปลี่ยนสิทธิการจอง !! OLD == vSetSitJong

    // const [valueround_typeunit, setround_typeunit] = useState<any>();
    // const [valueround_typeuser, setround_typeuser] = useState<any>();

    const [countZero, setcountZero] = useState<any>(); //COUNT CHECK !!OLD == vCountZero

    const [valueimgCover, setimgCover]  = useState<any>([]); // !! OLD == vImgCover
    const [valueimgGall, setimgGall]    = useState<any>([]); // !! OLD == vImgGall
    const [reqimgCover, setreqimgCover] = useState<any>(false); // !! OLD == RequireImgCover
    const [reqimgGallery, setreqimgGallery] = useState<any>(false); // !! OLD == RequireImgGallery

    const [valuesoDate, setsoDate] = useState<any>(""); // !! OLD == vSOpdate
    const [valueeoDate, seteoDate] = useState<any>(""); // !! OLD == vEOpdate

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [fieldKey, setfieldKey] = useState<any>([]);

    const { queryResult: {refetch} } = useShow<any>({
        metaData: {
            populate: '*'
        },
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;
            console.log(">>> dataLoad",dataLoad)

            recordData = dataLoad;
            fcltID = dataLoad?.id;
            propID = dataLoad?.property?.id;

            setProp(dataLoad?.property?.id);
            setRemark(dataLoad?.note);
            setopenTime(dataLoad?.open_time ? dataLoad?.open_time:"00:00:00");
            setcloseTime(dataLoad?.close_time ? dataLoad?.close_time: "00:00:00");

            if(dataLoad?.facility_time_slots){
                dataLoad?.facility_time_slots.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(b.name));
            }

            setftimeSlot(dataLoad?.facility_time_slots? dataLoad?.facility_time_slots : []);
            setCondition(dataLoad?.facility_conditions?.length > 0 ? dataLoad?.facility_conditions.map((e:any)=>{ return e.id }) :[]);

            setswsitJong(dataLoad?.reservation_permis == true ? "t": "f");
            setswsitJongL(dataLoad?.reservation_permis);
            setswJong(dataLoad?.advance_booking);
            setswCheckin(dataLoad?.checkin == true ? "t": "f");
            setswlCheckin(dataLoad?.checkin);
            setswStatus(dataLoad?.status == "OPEN" ? true :false);
            setinputNotimsg(dataLoad?.messages);
            setinputCincode(dataLoad?.checkin_code);

            if(dataLoad?.booking == false){ setjongType(1); } //NOBOOKING

            if(dataLoad?.private_room == true){ //PRIVATEROOM
                setjongType(2);
                setinputBooking(dataLoad?.max_user);
                setinputjongType(dataLoad?.capacity);
    
                form.setFieldsValue({ personPerRound1: dataLoad?.capacity});
            }

            if(dataLoad?.booking == true){ //BOOKING
                if(dataLoad?.private_room == true){ setjongType(2); }
                else{
                    setjongType(3);
                    setinputBooking(dataLoad?.max_user);
                    setinputjongType(dataLoad?.capacity);
                }
    
                form.setFieldsValue({ 
                    personPerRound2: dataLoad?.capacity,
                    maxUserBooking: dataLoad?.max_user
                 });
            }

            if(dataLoad?.dateopen_list && dataLoad?.dateopen_list !== ""){ //DATE OPEN
                setdateop(dataLoad?.dateopen_list);
                setdateopArr(dataLoad?.dateopen_list.split(','));
            }

            if(dataLoad?.reservation_per_user == true){
                setsitJong(1);
                setinputres_user(dataLoad?.reservation_user_round ? dataLoad?.reservation_user_round: null);
                form?.setFieldsValue({ facility_round_type_user: dataLoad?.facility_round_type_user ? dataLoad?.facility_round_type_user?.id: null})
            }
    
            if(dataLoad?.reservation_per_unit == true){
                setsitJong(2);
                setinputres_unit(dataLoad?.reservation_unit_round ? dataLoad?.reservation_unit_round: null);
                form?.setFieldsValue({ facility_round_type_unit: dataLoad?.facility_round_type_unit ? dataLoad?.facility_round_type_unit?.id: null})
            }
    
            if(dataLoad?.reservation_per_unittype == true){
                setsitJong(3);
            }

            if(dataLoad?.booking){
                setJong(dataLoad?.booking == true ? 2 :1);
                
                if(dataLoad?.set_booking_day !== null){
                    setJongF(1)
                    setinputJongFD(dataLoad?.set_booking_day)
                }
    
                if(dataLoad?.set_booking_hours !== null){
                    setJongF(2)
                    setinputJongFH(dataLoad?.set_booking_hours)
                }
    
                if(dataLoad?.set_booking_min !== null){
                    setJongF(3)
                    setinputJongFM(dataLoad?.set_booking_min)
                }
            }

            if(dataLoad?.waiting_status == true || dataLoad?.waiting_status == false){
                console.log(">>> dataLoad?.waiting_status", dataLoad?.waiting_status)
                setWait(dataLoad?.waiting_status == true ? 2:1);
                setinputWaitQ(dataLoad?.waiting_q);
            }

            if(dataLoad?.pre_cancel_booking == true || dataLoad?.pre_cancel_booking == false){
                setccJong(dataLoad?.pre_cancel_booking == true ? 2 :1);
            }
    
            if(dataLoad?.pre_cancel_booking_time){
                setinputCCJong(dataLoad?.pre_cancel_booking_time);
            }

            if(dataLoad?.facility_noti == true || dataLoad?.facility_noti == false){
                setNoti(dataLoad?.facility_noti == true ? 2 :1);
                setinputNoti(dataLoad?.facility_noti_day);
            }

            if(dataLoad?.status){
                setswstDate(dataLoad?.status == "OPEN" ? "t" :"f");
    
                setsoDate(dataLoad?.start_opendate ? Moment(dataLoad?.start_opendate).format('YYYY-MM-DD') :null);
                seteoDate(dataLoad?.end_opendate ? Moment(dataLoad?.end_opendate).format('YYYY-MM-DD') :null);
            }

            if(dataLoad?.gallery){
                setreqimgGallery(false)

                if(dataLoad?.gallery?.length > 0) {
                    const fileList: UploadFile[] = 
                        dataLoad.gallery ? dataLoad?.gallery?.map((e:any)=>(
                                {
                                    uid: String(e.id),
                                    name: e.name,
                                    status: "done",
                                    url: e.url,
                                    thumbUrl: e.url
                                }
                            )) 
                        : null
                    ;
    
                    setimgGall({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList],
                    })
                }
            }else{ setreqimgGallery(true); }
    
            if(dataLoad?.cover){
                setreqimgCover(false)

                const fileListCover: UploadFile[] = [
                    {
                        uid: dataLoad?.cover.id,
                        name: dataLoad?.cover.name,
                        status: "done",
                        url:
                            dataLoad?.cover.url,
                        thumbUrl:
                            dataLoad?.cover.url
                    }
                ];
    
                setimgCover({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [...fileListCover],
                })
            }else{ setreqimgCover(true); }
            
    
            form?.resetFields(["cover"]);
            form?.resetFields(["gallery"]);

            form?.setFieldsValue({
                property: dataLoad?.property ? dataLoad?.property?.id :null,
                facility_type: dataLoad?.facility_type ? dataLoad?.facility_type?.id :null,
            });

            setisLoading(false);
        }}
    });

    useEffect(() => { refetch() }, []);

    const { selectProps: selProperty } = useSelect<IProperties>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selfcltType } = useSelect<IFacilityType>({
        resource    : "facility-types",
        optionLabel : "name",
        optionValue : "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selfcltRType } = useSelect<IFacilityRoundType>({
        resource    : "facility-round-types",
        optionLabel : "name",
        optionValue : "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const condition = useList<IFacilityCondition>({ 
        resource: "facility-conditions",
        metaData: {
            populate: '*'
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "deleteStatus",
                    operator: "eq",
                    value   : "N"
                },
            ],
            pagination:{current: 1, pageSize: 100}
        },
        queryOptions:{onSuccess(data) {
            conditionData = data?.data;
        },}
    });

    const fcltunittype = useList<IFacilityUnitType>({
        resource: "facility-unit-types",
        metaData: {
            populate: '*'
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "facility][id]",
                    operator: "eq",
                    value   : fcltID ? fcltID : null
                },
                {
                    field   : "deleteStatus",
                    operator: "contains",
                    value   : "N"
                },
            ]
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            fcltunittypeData = data?.data;

            if(data?.data.length > 0){
                for(let i=0; i <= dataLoad?.length -1; i++){
                    inputunitType.push({
                        index: i, 
                        id: dataLoad[i].id,
                        limit_round: dataLoad[i].limit_round, 
                        unit_type: dataLoad[i].unit_type.id,
                        facility_round_type: dataLoad[i].facility_round_type.id
                    })
                }
            }

        }}
    });

    const unittype = useList<IUnitType>({
        resource: "unit-types",
        metaData: {
            populate: '*'
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "property][id]",
                    operator: "eq",
                    value   : propID ? propID : null
                }
            ]
        },
        queryOptions:{onSuccess(data) {
            unittypeData = data?.data;
        }}
    });

    const count_booking = () => {
        let count = []
        if(parseInt(inputjongType) !== null && parseInt(inputjongType) !== undefined && parseInt(inputjongType) > 0){
            for (var i=0; i < parseInt(inputjongType) ; i++) {
                count.push(<Option value={i + 1} >{i + 1}</Option>)
            }
            return count
        } 
    }

    const opt_max_user = () => {
        let count = []
        for (var i=0; i < 5 ; i++) {
            count.push(<Option value={i + 1} >{i + 1}</Option>)
        }
        return count
    }

    const isDisable = (id:any) => {
        if(valueCondition?.length > 0){
            var isMatch = valueCondition?.some(function(c: any){
                return c === id;
            });

            return( isMatch ? false : valueCondition?.length > 3 )
        }
    };

    const oncswsitJong = (checked: boolean) => {
        {checked==true ? setswsitJong("t") : setswsitJong("f")  }

        if(swsitJongL == true){
            setswsitJongL(false)
            setswsitJong("f")
        }else{
            setswsitJongL(true)
            setswsitJong("t")
        }
    };

    const oncswJong = (checked: boolean) => {
        {checked==true ? setswJong("t") : setswJong("f")  }

        if(swJong == true){
            setswJong(false)
            setJong(1)
        }else{
            setswJong(true)
            {recordData?.booking == true ? setJong(2): setJong(1)}
        }
    };

    const onswCheckin = (checked: boolean) => {
        {checked==true ? setswCheckin("t") : setswCheckin("f")  }

        if(swlCheckin == true){
            setswlCheckin(false)
            setswCheckin("f")
        }else{
            setswlCheckin(true)
            setswCheckin("t")
        }
    };

    const onswstDate = (checked: boolean) => {
        {checked== true ? setswstDate("t") : setswstDate("f")}

        if(swStatus == true){
            setswStatus(false);
            setswstDate("f");
        }else{
            setswStatus(true);
            setswstDate("t");
        }
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    //===================================== OLD FIELD

    function selectoldTime(time: any, timeString: any, round: any) {
        console.log('time', time)
        console.log('timeString', timeString)

        for(let i=0 ; i <= valueftimeSlot?.length -1; i++){
            if(valueftimeSlot[i].name === round){
                valueftimeSlot[i].time_start = timeString[0]  + ":00.000";
                valueftimeSlot[i].time_end = timeString[1]  + ":00.000";
            }
            // KOM CYBERPUNK 2023
            // if(valueftimeSlot[i].name === round){
            //     console.log(valueftimeSlot[i].name)
            //     console.log('valueftimeSlot[i].time_start', valueftimeSlot[i].time_start)
            //     console.log('valueftimeSlot[i].time_end', valueftimeSlot[i].time_end)
            //     // console.log('try to change old time', round)
            //     console.log('timeString[0] + ":00.000"', timeString[0] + ":00.000")
            //     console.log('timeString[1] + ":00.000"', timeString[1] + ":00.000")

            //     valueftimeSlot[i].time_start = timeString[0] + ":00.000";
            //     valueftimeSlot[i].time_end = timeString[1]  + ":00.000";
            // }
        }
        // console.log('kom', valueftimeSlot)
    }

    function rmoldItemFromArr(value:any){
        // console.log('first')
        console.log('rmnewItemFromArr', value)

        var filteredArray = valueftimeSlot?.filter((e: { id: any; }) => e.id !== value);
        var filteredOldArray = valueftimeSlot?.filter((e: { id: any; }) => e.id == value);
        setOldTimeSlot(filteredOldArray)

        UpdateFacilityTimeSlot({
            resource    : 'facility-time-slots',
            id          : filteredOldArray[0].id + "",
            values      : {
                facility    : null,
                updateBy    : user_data.user_profile.id
            },
            successNotification : false,
        })

        // console.log('filteredArray', filteredArray)
        setftimeSlot(filteredArray);    
    }

    // console.log('valueftimeSlot', valueftimeSlot)
    //===================================== NEW FIELD

    function selectnewTime(time: any, timeString: any, round: any) {
        valuetimeslotTemp?.push({time_start: timeString[0], time_end: timeString[1], name: round+""});
        settimeSlot(valuetimeslotTemp);
        rmandsortArr();
    }

    function rmnewItemFromArr(value:any){
        var filteredArray = valueftimeSlot?.filter((e: { id: any; }) => e.id !== value);
        settimeslotTemp(filteredArray);    
    }

    function rmandsortArr(){
        valuetimeSlot?.reduceRight((acc: { [x: string]: boolean; }, obj: { name: string | number; }, i: any) => {
            acc[obj.name]? valuetimeSlot?.splice(i, 1) : acc[obj.name] = true;
            return acc;
        }, Object.create(null));
        valuetimeSlot?.sort((a: { name: number; }, b: { name: number; }) => b.name - a.name);
    }

    //=================================================

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                const body = new FormData();
                loader.file.then((file: string | Blob) => {
                    body.append("files", file);
                    fetch(`${API_URL}/upload`, {
                        method: "post",
                        body: body 
                    })
                    .then((res) => res.json())
                    .then((res) => { resolve({ default: res[0].url}); })
                    .catch((err) => { reject(err);});
                });
                });
            }
        };
    }

    //===================================== UNIT TYPE FIED - SIT KAN JONG

    function onchangeInput(value:any, index:any, unitTypeName:any, unitTypeID:any, id:any, roundType:any) {
        inputunitType.push({id: id, index, limit_round: value, unit_type: unitTypeID, unitTypeName, facility_round_type: roundType});

        if(inputunitType?.length > 0){ removeunitArr(); }
    }

    function removeunitArr(){        
        inputunitType?.reduceRight((acc: { [x: string]: boolean; }, obj: { index: string | number; }, i: any) => {
            acc[obj.index]? inputunitType?.splice(i, 1) : acc[obj.index] = true;
            return acc;
        }, Object.create(null));
        inputunitType?.sort((a: { index: number; }, b: { index: number; }) => a.index - b.index);
        let count = inputunitType?.filter(function(item: any){
            return item.limit_round == '0'
        });
        setcountZero(count?.length)
    }

    //=================================================

    function changeCover(e: any, type:any) {
        if(e.file.status == "removed"){
            setreqimgCover(true)
        }else{
            setreqimgCover(false)
        }

        if(e.file.status === "removed") {
            setimgCover((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));

            form?.resetFields(["cover"]);
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setimgCover((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    function changeGall(e: any, type:any) {
        if(e.file.status == "removed"){
            setreqimgGallery(true)
        }else{
            setreqimgGallery(false)
        }

        if(e.file.status === "removed") {
            setimgGall((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));

            form?.resetFields(["gallery"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setimgGall((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

     //===================================== ON FINISH

    function updateFacOnlyTimek(v:any){
        console.log('timeslot data only k', v)
        // UpdateFaciOnlyTimeSlot({
        //     resource    : 'facilities',
        //     id          : fcltID + "",
        //     values      : {
        //         facility_time_slots  : v,
        //     },
        //     successNotification : false,
        // })
    }

    function createFacTimeSlot(){
        // console.log(">>> valuetimeSlot create",valuetimeSlot)
        // console.log(">>> fcltID create",fcltID)


        if(valuetimeSlot?.length > 0){
            for(let i=0 ; i <= valuetimeSlot?.length -1; i++){
                CreateFaciTimeSlot({
                    resource    : 'facility-time-slots',
                    values      : {
                        time_start  : valuetimeSlot[i]?.time_start + ":00.000", 
                        time_end    : valuetimeSlot[i]?.time_end + ":00.000",
                        name        : "round " + valuetimeSlot[i].name,
                        facility    : fcltID,
                        createBy    : user_data?.user_profile?.id,
                        updateBy    : user_data?.user_profile?.id
                    },
                    successNotification : false,
                })
            }
        }
    }

    function updateFacTimeSlot(){

        console.log(">>> valueftimeSlot update",valueftimeSlot)

        if(valueftimeSlot?.length > 0){
            for(let j=0 ; j <= valueftimeSlot?.length -1; j++){
                UpdateFacilityTimeSlot({
                    resource    : 'facility-time-slots',
                    id          : valueftimeSlot[j].id + "",
                    values      : {
                        time_start  : valueftimeSlot[j].time_start, 
                        time_end    : valueftimeSlot[j].time_end,
                        name        : valueftimeSlot[j].name,
                        facility    : fcltID,
                        updateBy    : user_data.user_profile.id
                    },
                    successNotification : false,
                })
            }
        }
    }

    function removeOldTimeSlot(){
        if(oldTimeSlot?.length > 0){
            for(let j=0 ; j <= oldTimeSlot?.length -1; j++){
                UpdateFacilityTimeSlot({
                    resource    : 'facility-time-slots',
                    id          : oldTimeSlot[j].id + "",
                    values      : {
                        facility    : null,
                        updateBy    : user_data.user_profile.id
                    },
                    successNotification : false,
                })
            }
        }
    }


    // console.log('valuetimeSlot new', valuetimeSlot)
    // console.log('valuetimeSlot old', valueftimeSlot)
    // console.log('OldTimeSlot want to remove', oldTimeSlot)


    function removeFaciUnitTypeData() {
        if(fcltunittypeData?.length > 0){
            for(let i=0 ; i <= fcltunittypeData.length -1; i++){
                UpdateFaciUnitType({
                    resource    : 'facility-unit-types',
                    id          : fcltunittypeData[i].id,
                    values      : {
                        deleteStatus : "Y"
                    },
                    successNotification : false,
                })
            }
        }
    }

    function updateFaciUnitType(){
        if(inputunitType?.length > 0){
            for(let j=0 ; j <= inputunitType?.length -1; j++){
                UpdateFacilityUnitType({
                    resource    : 'facility-unit-types',
                    id          : inputunitType[j].id + "",
                    values      : {
                        unit_type               : inputunitType[j].unit_type + "", 
                        facility_round_type     : inputunitType[j].facility_round_type,
                        limit_round             : inputunitType[j].limit_round,
                        facility                : fcltID,
                        createBy                : user_data.user_profile.id
                    },
                    successNotification : false,
                })
            }
        }
    }

    function createFaciUnitType(){
        if(inputunitType?.length > 0){
            for(let i=0 ; i <= inputunitType?.length -1; i++){
                CreateFaciUnitType({
                    resource    : 'facility-unit-types',
                    values      : {
                        unit_type               : inputunitType[i].unit_type, 
                        facility_round_type     : inputunitType[i].facility_round_type,
                        limit_round             : inputunitType[i].limit_round,
                        facility                : fcltID,
                        createBy                : user_data.user_profile.id
                    },
                    successNotification : false,
                })
            }
        }
    }

    const warning = () => {
        for(let i=0; i<= inputunitType?.length -1; i++){
            let field = "kSelectRound_" + i
            form.resetFields([field])
        }
        Modal.warning({
          title: <span>{t("msg-facility-booking.title-warning")}</span>,
          content: <span>{t("msg-facility-booking.content-warning")}</span>,
        });
    };

    function ondeltimeslot(id: any) {

    }

    function ondeltimeslotOLD(id: any) {
        let count = 0;
        // let newKey: any = fieldKey;
        // let newGroup: any = userGroup;
        let newGroupField: any = [];
    }

    return (
        <Spin size="large" spinning={isLoading}>
            <Edit 
                saveButtonProps={saveButtonProps}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt.edit")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>,
                }}
            >
                <Form 
                    {...formProps}
                    layout="vertical"
                    onFinish={(values:any) => {

                        values.updateBy = user_data?.user_profile?.id
                        // updateFacOnlyTimek(valueftimeSlot);
        
                        if(valuetimeSlot?.length > 0){
                            createFacTimeSlot();
                        }

                        updateFacTimeSlot();

                        // return "k"

                        values.property = valueProp;
                        values.facility_type = values?.facility_type;
        
                        //================== NO BOOKING
                        if(valuejongType == 1){
                            values.booking = false
                            values.private_room = false // ถ้าเลือก no booking เปลี่ยนฟิลด์ private room เป็น false
        
                            values.capacity = null
                            values.max_user = null
                            values.set_booking_day = null
                            values.set_booking_hours = null
                            values.set_booking_min = null
        
                            
                            values.reservation_per_user = null
                            values.reservation_per_unit = null
                            values.reservation_per_unittype = null
                            values.reservation_unit_round = null
                            values.facility_round_type_unit = null
        
                            values.reservation_user_round = null
                            values.facility_round_type_user = null
        
                            values.checkin = null
                            values.checkin_befor_time = null
                            values.checkin_after_time = null
                            values.checkin_code = null
        
                            values.facility_noti = null
                            values.facility_noti_day = null
                            values.messages = null
                            values.pre_cancel_booking = null
                            values.pre_cancel_booking_time = null
                            values.waiting_status = null
                            values.waiting_q = null
                            values.reservation_permis = null
                            values.advance_booking = null
        
                            values.facility_time_slots = null 
                        }
        
                        //================== PRIVATE ROOM 
                        if(valuejongType == 2){
                            // values.no_booking = null
                            values.private_room = true
                            // values.booking = null
                            values.booking = true // ถ้าเลือก private room เปลี่ยนฟิลด์ private room เป็น true, เปลี่ยนฟิลด์ booking เป็น true
                            values.no_booking = false // ถ้าไม่ใส่ no_booking = false ที่เมนู booking create จะไม่เห็น facility ที่เป็น private room
        
        
                            if(inputjongType !== ""){values.capacity = inputjongType}
                            if(inputBooking !== ""){values.max_user = inputBooking}
        
                            // Notifications
                            {valueNoti == 2 ? values.facility_noti= true:values.facility_noti = false }
                            if(valueNoti == 2){
                                if(inputNoti !== ""){values.facility_noti_day= inputNoti}
                                if(inputNotimsg !== ""){values.messages= inputNotimsg}
                            }
        
                            if(valuesitJong == 1){
                                {inputres_user !== "" ? values.reservation_user_round = inputres_user:  values.reservation_user_round = 0}
                                values.reservation_per_user = true
                                values.reservation_per_unit = false
                                values.reservation_per_unittype = false
            
                                values.reservation_unit_round = 0
                                values.facility_round_type_unit = null
                            }
        
                            // ต่อ unit
                            // ต้องเคลียร์ข้อมูลของ ต่อ user, unit type
                            if(valuesitJong == 2){
                                {inputres_unit !== "" ? values.reservation_unit_round  = inputres_unit:  values.reservation_unit_round  = 0}
                                values.reservation_per_user = false
                                values.reservation_per_unit = true
                                values.reservation_per_unittype = false
        
                                values.reservation_user_round = 0
                                values.facility_round_type_user = null
                            }
        
                            // ต่อ unit type
                            // ต้องเคลียร์ข้อมูลของ ต่อ user, unit
                            if(valuesitJong == 3){
                                values.reservation_per_user = false
                                values.reservation_per_unit = false
                                values.reservation_per_unittype = true
        
                                values.reservation_unit_round = 0
                                values.facility_round_type_unit = null
        
                                values.reservation_user_round = 0
                                values.facility_round_type_user = null
                            }
        
                            {swsitJong == "t" ? values.reservation_permis= true: values.reservation_permis= false }
        
                            // Advance booking
                            // {swJong == "t" ? values.advance_booking= true: values.advance_booking= false }
                            {swJong == true ? values.advance_booking= true: values.advance_booking= false }
        
                        }
        
                        //================== BOOKING
                        if(valuejongType == 3){
                            // console.log('vSetJongType = booking')
                            values.private_room = false // ถ้าเลือก booking เปลี่ยนฟิลด์ private room เป็น false
                            values.no_booking = false
                            values.booking = true
                            
                            // console.log('inputjongType in case 3 booking', inputjongType)
                            if(inputjongType !== ""){
                                values.capacity = inputjongType
                            }
        
                            if(inputBooking !== ""){
                                values.max_user = inputBooking
                            }
        
                            if(valueJongF == 1){if(inputJongFD !== ""){
                                values.set_booking_day = inputJongFD
                                values.set_booking_hours = null
                                values.set_booking_min = null
                            }}
            
                            if(valueJongF == 2){if(inputJongFH !== ""){
                                values.set_booking_day = null
                                values.set_booking_hours = inputJongFH
                                values.set_booking_min = null
                            }}
            
                            if(valueJongF == 3){if(inputJongFM !== ""){
                                values.set_booking_day = null
                                values.set_booking_hours = null
                                values.set_booking_min = inputJongFM
                            }}
            
                            {swCheckin == "t" ? values.checkin = true:values.checkin = false}
            
                            if(swCheckin == "t"){
            
                                if(inputCbfTime !== ""){
                                    values.checkin_befor_time = inputCbfTime
                                }
            
                                if(inputCafTime !== ""){
                                    values.checkin_after_time = inputCafTime
                                }
            
                                values.checkin_code = inputCincode
                            }
            
                            {valueNoti == 2 ? values.facility_noti= true:values.facility_noti = false }
            
                            if(valueNoti == 2){
                                if(inputNoti !== ""){values.facility_noti_day= inputNoti}
                                if(inputNotimsg !== ""){values.messages= inputNotimsg}
                            }
        
                            //advance_booking
                            console.log(">>> valueJong",valueJong)
                            console.log(">>> valueccJong",valueccJong)
                            {valueJong == 2 ? values.advance_booking = true:values.advance_booking = false }
            
                            {  valueccJong == 2 ? 
                                    values.pre_cancel_booking = true 
                                : values.pre_cancel_booking = false 
                            }
            
                            if(valueccJong == 2){
                                if(inputCCJong !== ""){
                                    values.pre_cancel_booking_time = Number(inputCCJong);
                                }
                            }
            
                            // ==============================================================================================
            
                            {valueWait == 2 ? values.waiting_status= true:values.waiting_status = false }
                            if(valueWait == 2){if(inputWaitQ !== ""){values.waiting_q= inputWaitQ}}

                            console.log(">>> valuesitJong",valuesitJong)
        
                            if(valuesitJong == 1){
                                {inputres_user !== "" ? values.reservation_user_round = inputres_user:  values.reservation_user_round = 0}
                                values.reservation_per_user = true
                                values.reservation_per_unit = false
                                values.reservation_per_unittype = false
            
                                values.reservation_unit_round = 0
                                values.facility_round_type_unit = null
            
                                if(fcltunittypeData?.length > 0){
                                    removeFaciUnitTypeData();
                                }
                            }
            
                            // ต่อ unit
                            // ต้องเคลียร์ข้อมูลของ ต่อ user, unit type
                            if(valuesitJong == 2){
                                {inputres_unit !== "" ? values.reservation_unit_round  = inputres_unit:  values.reservation_unit_round  = 0}
                                values.reservation_per_user = false
                                values.reservation_per_unit = true
                                values.reservation_per_unittype = false
            
                                values.reservation_user_round = 0
                                values.facility_round_type_user = null
            
                                if(fcltunittypeData?.length > 0){
                                    removeFaciUnitTypeData();
                                }
                            }
            
                            // ต่อ unit type
                            // ต้องเคลียร์ข้อมูลของ ต่อ user, unit
                            if(valuesitJong == 3){
                                values.reservation_per_user = false
                                values.reservation_per_unit = false
                                values.reservation_per_unittype = true
            
                                values.reservation_unit_round = 0
                                values.facility_round_type_unit = null
            
                                values.reservation_user_round = 0
                                values.facility_round_type_user = null
            
                            
                                if(inputunitType.length == countZero){ // ถ้าใส่ 0 รอบมาทั้งหมด ไม่ให้อัพเดท
                                    warning();
                                }else{
                                    // console.log('here 1')
                                    if(fcltunittypeData?.length > 0){
                                        updateFaciUnitType();
                                    }else{
                                        createFaciUnitType();
                                    }
                                }
        
                            }
        
                            {swsitJong == "t" ? values.reservation_permis= true: values.reservation_permis= false }
                            // {swJong == "t" ? values.advance_booking= true: values.advance_booking= false }
                            // console.log('here update advance booking')
                            swJong == true ? values.advance_booking = true : values.advance_booking = false;
                        }
        
                        // const today = dayjs().format("YYYY-MM-DD")
        
                        // ถ้า set สถานะการให้บริการปัจจุบัน ของ fac เป็น close แต่วันที่เริ่มปิดไม่ใช่ปัจจุบัน status จะยังเป็น OPEN อยู่
                        // vSOpdate เก็บวันเริ่มปิดให้บริการ
                        const date2 = dayjs();
                        let hours = date2.diff(valuesoDate, 'hours');
                        const days = Math.floor(hours / 24);
                        hours = hours - (days * 24);
                        // console.log('Days: ', days);
                        // console.log('Hours: ', hours);
        
                        // {swStdate == "t" ? values.status= "OPEN":values.status = "CLOSES" }
                        if(swstDate == "t"){
                            values.status = "OPEN"
                        }else if(swstDate == "f"){
                            if(days < 0){ // วันที่เริ่มปิดไม่ใช่วันปัจจุบัน สถานะจะเป็น OPEN อยู่, พอถึงวันที่ปิดเดี๋ยวให้ cronjob กวาดแล้วเปลี่ยน stat เอา
                                values.status = "OPEN"
                                // console.log('open')
                            }else{
                                values.status = "CLOSES"
                                // console.log('closes')
                            }
                        }
        
                        if(swstDate == "t"){
        
                            if(valuesoDate !== "" && valuesoDate !== null && valuesoDate !== undefined){
                                values.start_opendate = null;
                            }
        
                            if(valueeoDate !== "" && valueeoDate !== null && valueeoDate !== undefined){
                                values.end_opendate = null;
                            }
        
                            values.close_note = ""
                        }
        
                        if(swstDate == "f"){
        
                            if(valuesoDate !== "" && valuesoDate !== null && valuesoDate !== undefined){
                                values.start_opendate = Moment(valuesoDate).format('YYYY-MM-DD');
                            }
        
                            if(valueeoDate !== "" && valueeoDate !== null && valueeoDate !== undefined){
                                values.end_opendate = Moment(valueeoDate).format('YYYY-MM-DD');
                            }
        
                            // values.close_note = ""
                        }
        
                        if(openTime !== "" && openTime !== null && openTime !== undefined ){
                            values.open_time = openTime;
                        }
        
                        if(closeTime !== "" && closeTime !== null && closeTime !== undefined ){
                            values.close_time = closeTime;
                        }
        
                        // ต่อบัญชี
                        // ต้องเคลียร์ข้อมูลของ unit, unit type 

                        console.log(">>> valuedateopArr",valuedateopArr)
        
                        values.dateopen_list = valuedateop;
        
                        values.note = valueRemark;
        
                        if(valueCondition?.length > 0){
                            values.facility_conditions = valueCondition;
                        }
        
                        // ประเภทการจอง 
                        values.booking_type = null
        
                        // date_open_list
                        values.date_open_list = null
        
                        console.log(values)
        
        
                        return (
                            formProps.onFinish &&
                            formProps.onFinish(mediaUploadMapper(values))
                        );
                    }}
                >
                    <Row gutter={[16, 24]}>
                        <Col id="Col_w" className="gutter-row" span={18}>
                            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", marginBottom: "1.6%"  }}>
                                <Row gutter={[12,12]}>
                                    <Col className="gutter-row" span={12}> {/* PROPERTY */}
                                        <Form.Item  label={t("property.title")} name="property"
                                            rules={[{ required: true, message: 'Please enter property.' }]}
                                        >
                                            <Select placeholder={t("select.sel")} {...selProperty} onChange={(e)=> setProp(e)} />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={6}> {/* FACILITY TYPE */}
                                        <Form.Item  label={t("fclt-type.title")} name="facility_type"
                                            rules={[{ required: true, message: 'Please enter facility type.' }]}
                                        >
                                            <Select placeholder={t("select.sel")} {...selfcltType} />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={6}> {/* NAME */}
                                        <Form.Item  label={t("fclt.name")} name="name"
                                            rules={[{ required: true, message: 'Please enter name.' }]}
                                        >
                                            <Input showCount maxLength={85} />
                                        </Form.Item> 
                                    </Col>

                                    <Col className="gutter-row" span={24}> {/* DESCRIPTION */}
                                        <Form.Item  label={t("fclt.desc")} name="description"
                                            rules={[{ required: true, message: 'Please enter name.' }]}>
                                                <TextArea style={{height: "30px", maxHeight: "150px"}}/>
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={12}> {/* SPECIAL ANNOU */}
                                        <Form.Item  label={t("fclt.spc_ann")} name="special_announcement">
                                            <Input showCount maxLength={255} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[12,12]}>
                                    <Col className="gutter-row" span={24}> {/* REMARK */}
                                        <Form.Item label="Remark" rules={[ {required: true} ]}>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                // data={recordData ? recordData.note? recordData.note : '' : ''}
                                                data={valueRemark ? valueRemark : ''}
                                                config={{
                                                    alignment: {
                                                        options: [ 'left', 'right' ]
                                                    },
                                                    toolbar: [
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "link",
                                                        "bulletedList",
                                                        "numberedList",
                                                        "blockQuote",
                                                        'alignment',
                                                        "|",
                                                        "imageTextAlternative",
                                                        "imageUpload",
                                                        "imageStyle:full",
                                                        "imageStyle:side",
                                                    ],
                                                    extraPlugins: [uploadPlugin],
                                                    mediaEmbed: {
                                                        removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                    },
                                                    image: {
                                                        toolbar: ['']
                                                    }
                                                }}
                                                onChange={(event:any, editor:any) => {
                                                    const data = editor.getData();
                                                    setRemark(data);
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={[12,12]}>
                                    <Col className="gutter-row" span={12}> {/* JONG TYPE */}
                                        <Form.Item label = "ประเภทการจอง"
                                            rules={[{ required: true, message: 'Please select booking type' }]}
                                        >
                                            <Radio.Group 
                                                onChange={(e)=> setjongType(e?.target?.value)} 
                                                value={valuejongType} 
                                            >
                                                <Space direction="vertical">
                                                    <Radio value={1}>{t("fclt.nbooking")}</Radio>
                                                    <Radio value={2}>{t("fclt.priroom")}
                                                        { valuejongType === 2 &&
                                                            <Form.Item 
                                                                name="personPerRound1"
                                                                rules={[{ required: true, message: 'Please enter person/round' }]}
                                                            >
                                                                <Input 
                                                                    type={"number"} 
                                                                    min={1} 
                                                                    defaultValue={inputjongType} 
                                                                    style={{ width: "45%", marginLeft: "0%",borderRadius: "10px"}} suffix="คน/รอบ" 
                                                                    value={inputjongType}
                                                                    onChange={e => setinputjongType(e.target.value)}/> 
                                                            </Form.Item>
                                                        }
                                                    </Radio>
                                                    <Radio value={3}>{t("fclt.booking")}
                                                        { valuejongType === 3 && 
                                                            <Form.Item 
                                                                name="personPerRound2"
                                                                rules={[{ required: true, message: 'Please enter person/round' }]}
                                                            >
                                                                <Input 
                                                                    type={"number"} 
                                                                    min={1} 
                                                                    defaultValue={inputjongType} 
                                                                    style={{ width: "45%", marginLeft: "0%",borderRadius: "10px"}} suffix="คน/รอบ" 
                                                                    value={inputjongType} 
                                                                    onChange={e => {
                                                                        setinputjongType(e.target.value)
                                                                        form?.resetFields(["maxUserBooking"]);
                                                                    }}
                                                                /> 
                                                            </Form.Item>
                                                        }
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>

                                        { valuejongType == 3 && 
                                            <Form.Item 
                                                label={t("fclt.lm-user")}
                                                name="maxUserBooking"
                                                rules={[{ required: true, message: 'Please enter max user/booking' }]}
                                            >
                                                <Select 
                                                    style={{ width: "45%", borderRadius: "10px"}}
                                                    placeholder={t("select.sel")}
                                                    value={inputBooking}
                                                    onChange={(e)=> setinputBooking(e)}
                                                >
                                                    {parseInt(inputjongType) > 5 ? opt_max_user(): count_booking()}
                                                </Select>
                                            </Form.Item>    
                                        }
                                    </Col>

                                    <Col className="gutter-row" span={12}> {/* DATE OPEN */}
                                        <Form.Item  label={t("fclt.dateop")} 
                                            rules={[{ required: true, message: 'Please select date open list' }]}
                                        >
                                            <Select
                                                mode="multiple"
                                                placeholder={t("select.sel")}
                                                value={valuedateopArr}
                                                defaultValue={valuedateopArr}
                                                onChange={(e: any)=> {
                                                    setdateop('' + e)
                                                    setdateopArr((pre: any) => e)
                                                }}
                                            >
                                                <Option value="Monday">{t("day.monday")}</Option>
                                                <Option value="Tuesday">{t("day.tuesday")}</Option>
                                                <Option value="Wednesday">{t("day.wednesday")}</Option>
                                                <Option value="Thursday">{t("day.thursday")}</Option>
                                                <Option value="Friday">{t("day.friday")}</Option>
                                                <Option value="Saturday">{t("day.saturday")}</Option>
                                                <Option value="Sunday">{t("day.sunday")}</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={12}> {/* OPEN & CLOSE TIME */}
                                        <Row gutter={[16, 24]}>
                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label={t("fclt.optime")}
                                                    rules={[{ required: true, message: 'Please select open time.' }]}
                                                >
                                                    <TimePicker 
                                                        // value={openTime}
                                                        value={moment(openTime ? openTime:"00:00:00", 'HH:mm:ss')}
                                                        format={format}
                                                        onChange={(date, timeString) => setopenTime(timeString + ":00.000")}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label={t("fclt.cltime")}
                                                    rules={[{ required: true, message: 'Please select close time.' }]}
                                                >
                                                    <TimePicker 
                                                        value={moment(closeTime ? closeTime:"00:00:00", 'HH:mm:ss')}
                                                        format={format}
                                                        onChange={(date, timeString) => setcloseTime(timeString + ":00.000")}
                                                    />
                                                </Form.Item>

                                                
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card>
                            
                            {/* TIME SLOT */}
                            { valuejongType === 2 || valuejongType === 3 && 
                                <Card style={{borderRadius: 5, marginBottom: "1.6%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <div style={{marginBottom: "1vh"}}>กำหนดช่วงเวลาในการใช้งาน</div>
                                
                                    <Form.List name="fields">
                                        {(fields, { add, remove }) => {
                                            return (
                                                <div>
                                                    {
                                                        valueftimeSlot && 
                                                        valueftimeSlot?.length > 0 ? valueftimeSlot?.map((el:any,index:any) => {
                                                        return (
                                                            <Row key={index} style={{marginBottom: "1vh"}}>
                                                                <Col span={3}> รอบที่ {index + 1} </Col>
                                                                <Col span={16}>
                                                                    <TimePicker.RangePicker 
                                                                        defaultValue={[moment(el.time_start, 'HH:mm:ss'), moment(el.time_end, 'HH:mm:ss')]}
                                                                        // defaultValue={[moment("13:00", 'HH:mm:ss'), moment("16:00", 'HH:mm:ss')]}
                                                                        style={{width:'95%'}} 
                                                                        onChange={(date, dateString) => selectoldTime(date, dateString, el.name)}
                                                                        format={format}
                                                                    />
                                                                </Col>
                                                                <Col span={5}>
                                                                    {
                                                                        index === valueftimeSlot?.length - 1? 
                                                                            <Button
                                                                                size="small"
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => {rmoldItemFromArr(el.id);}}
                                                                                danger
                                                                                icon={<Icons.CloseOutlined />}
                                                                            />
                                                                        : null
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        )
                                                    }):<></>
                                                }

                                                    {fields.map((field, index ) => (
                                                        <div key={field.key}>
                                                            <Row>
                                                                <Col span={3}> รอบที่ {index + valueftimeSlot?.length + 1} </Col>
                                                                <Col span={16}>
                                                                    <TimePicker.RangePicker
                                                                        style={{width:'95%'}}
                                                                        onChange={(date, dateString) => selectnewTime(date, dateString, index + valueftimeSlot?.length + 1)}
                                                                        format={format} 
                                                                    />
                                                                </Col>
                                                                <Col span={5}>
                                                                {
                                                                    <Button
                                                                        size="small"
                                                                        className="dynamic-delete-button"
                                                                        onClick={() => { remove(field.name); rmnewItemFromArr(index + valueftimeSlot?.length + 1);}}
                                                                        danger
                                                                    >
                                                                        <Icons.CloseOutlined />
                                                                    </Button>
                                                                }
                                                                </Col>
                                                            </Row>
                                                            <div style={{marginBottom: "1vh",}}></div>
                                                        </div>
                                                    ))}

                                                    <Divider />

                                                    <Form.Item>
                                                        <Button
                                                            type="dashed"
                                                            onClick={() => add()}
                                                            style={{ width: "20%",backgroundColor: "#d6c6a0",borderRadius: "10px"}}
                                                        >
                                                            <Icons.PlusOutlined style={{color: "#fff"}} /> <span style={{color: "#fff"}}>{t("buttons.add")}</span>
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            );
                                        }}
                                    </Form.List>
                                </Card>
                            }

                            <Card style={{borderRadius: 5, marginBottom: "1.6%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}> {/* CONDITIONS */}
                                <Form.Item  label={t("fclt.condi")}>
                                    {conditionData && conditionData?.length > 0 ? 
                                        <Checkbox.Group 
                                            onChange={(e)=> setCondition(e)} 
                                            value={valueCondition}
                                            style={{ width: '100%' }}
                                        >
                                            <Row>
                                                { conditionData?.map((el:any,index:any) => {
                                                    return (
                                                        <Col span={6} style={{marginBottom: "2%"}}>
                                                            <Checkbox key={el.id} value={el.id} disabled={isDisable(el.id)}>
                                                                <Image 
                                                                    src={el.condition_pix.url}
                                                                    width={"2vh"}
                                                                    height={"2vh"}
                                                                    style={{
                                                                        objectFit: "cover",
                                                                        borderRadius: "10px",
                                                                    }} 
                                                                    preview={false} 
                                                                /> 
                                                                <span style={{marginLeft: "5px"}}>{el.description}</span>
                                                            </Checkbox>
                                                        </Col>
                                                    )})
                                                }
                                            </Row>
                                        </Checkbox.Group>
                                        :
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    }
                                </Form.Item>
                            </Card>
                            
                            { valuejongType === 3 && 
                                <Card style={{borderRadius: 5, marginBottom: "1.6%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <Form.Item>
                                        <span style={{fontSize: "0.7vw",marginRight: "1.5%"}}>สิทธิ์การจอง</span><Switch onChange={oncswsitJong} checked={swsitJongL} />
                                    </Form.Item>
                                    
                                    { swsitJong === "t" && 
                                        <Form.Item> {/* SIT KAN JONG */}
                                            <Radio.Group onChange={(e)=> setsitJong(e?.target?.value)} value={valuesitJong} style={{width: "100%"}}>
                                                <Space direction="vertical" style={{width: "100%"}}>
                                                    <Radio value={1}>ต่อบัญชี</Radio>
                                                    { valuesitJong == 1 && 
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={1} />
                                                            <Col className="gutter-row" span={2}>
                                                                <Input type={"number"} 
                                                                    pattern="[1-9]+"
                                                                    defaultValue={1} min={1} size="small" 
                                                                    style={{width: "100%",borderRadius: "10px"}} 
                                                                    value={inputres_user} 
                                                                    onChange={(e)=> {
                                                                        if(e?.target?.value?.length > 0){
                                                                            setinputres_user(e?.target?.value);
                                                                        }else if(e?.target?.value?.length == 0){
                                                                            setinputres_user('1')
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className="gutter-row" span={1.5}>
                                                                <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                            </Col>
                                                            <Col className="gutter-row" span={4}>
                                                                <Form.Item name="facility_round_type_user" style={{marginBottom: "0"}}>
                                                                    <Select 
                                                                        size="small" 
                                                                        style={{width: "100%",borderRadius: "10px",top: "-3px"}} 
                                                                        placeholder={t("select.sel")} 
                                                                        {...selfcltRType}
                                                                        // defaultValue={valueround_typeuser}
                                                                    />
                                                                </Form.Item>
                                                            </Col> 
                                                        </Row>
                                                    }
                                                    
                                                    <Radio value={2}>ต่อ Unit</Radio>
                                                    { valuesitJong == 2 && 
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={1} />
                                                            <Col className="gutter-row" span={2}>
                                                                <Input 
                                                                    type={"number"} 
                                                                    defaultValue={1} min={1} 
                                                                    size="small" 
                                                                    style={{width: "100%", borderRadius: "10px"}} 
                                                                    value={inputres_unit} 
                                                                    onChange={(e)=> {
                                                                        if(e?.target?.value?.length > 0){
                                                                            setinputres_unit(e?.target?.value);
                                                                        }else if(e?.target?.value?.length == 0){
                                                                            setinputres_unit('1')
                                                                        }
                                                                    }}
                                                                />
                                                            </Col>
                                                            <Col className="gutter-row" span={1.5}>
                                                                <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                            </Col>
                                                            <Col className="gutter-row" span={4}>
                                                                <Form.Item name="facility_round_type_unit" style={{marginBottom: "0"}}>
                                                                    <Select 
                                                                        size="small"
                                                                        style={{width: "100%", borderRadius: "10px",top: "-3px"}}
                                                                        placeholder={t("select.sel")}
                                                                        {...selfcltRType}
                                                                        // defaultValue={valueround_typeunit}
                                                                    />
                                                                </Form.Item>
                                                            </Col> 
                                                        </Row>
                                                    }
                                                    <Radio value={3}>ต่อ Unit Type</Radio>
                                                    { valuesitJong == 3 && 
                                                        <>
                                                            { fcltunittypeData?.length > 0 ? fcltunittypeData?.map((el:any,index:any) => {
                                                                return (
                                                                    <Row gutter={16}>
                                                                        <Col className="gutter-row" span={1} />
                                                                        <Col className="gutter-row" span={5}>
                                                                            <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle'}}><b>{el.unit_type ? el.unit_type.name : '-'} : <>&nbsp;</></b></span>
                                                                        </Col>
            
                                                                        <Col className="gutter-row" span={2}>
                                                                            <Input 
                                                                                type={"number"} min={1} size="small" style={{width: "100%",borderRadius: "10px"}} 
                                                                                defaultValue={el.limit_round}
                                                                                onChange={e => { onchangeInput(e.target.value, index, el.name, el.unit_type.id, el.id, el.facility_round_type.id);}}
                                                                            />
                                                                        </Col>
            
                                                                        <Col className="gutter-row" span={1.5}>
                                                                            <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                                        </Col>
            
                                                                        <Col className="gutter-row" span={4}>
                                                                            <Form.Item style={{marginBottom: "0"}}>
                                                                                <Select size="small" style={{width: "100%",borderRadius: "10px",top: "-3px"}} placeholder={t("select.sel")} {...selfcltRType}
                                                                                    // defaultValue={el.facility_round_type.name}
                                                                                    defaultValue={el.facility_round_type ? el.facility_round_type.name : '-'}
            
                                                                                    onChange={(e) => {
                                                                                        for(let i=0 ; i <= inputunitType?.length -1; i++){
                                                                                            if(inputunitType[i]?.index === index){
                                                                                                inputunitType[i].facility_round_type = e;
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col> 
                                                                    </Row>
                                                                )
                                                            }):
                                                            
                                                            unittypeData?.length > 0 && unittypeData?.map((el:any,index:any) => {
                                                                return (
                                                                    <Row gutter={16}>
                                                                        <Col className="gutter-row" span={1} />
                                                                        <Col className="gutter-row" span={5}>
                                                                            <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle'}}><b>{el.name} : <>&nbsp;</></b></span>
                                                                        </Col>
            
                                                                        <Col className="gutter-row" span={2}>
                                                                            <Input 
                                                                                type={"number"} min={0} size="small" style={{width: "100%",borderRadius: "10px"}} 
                                                                                value={el.limit_round}
                                                                                onChange={e => { onchangeInput(e.target.value, index, el.name, el.id, "", "");}}
                                                                            />
                                                                        </Col>
            
                                                                        <Col className="gutter-row" span={1.5}>
                                                                            <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                                        </Col>
            
                                                                        <Col className="gutter-row" span={4}>
                                                                            <Form.Item style={{marginBottom: "0"}}>
                                                                                <Select size="small" style={{width: "100%",borderRadius: "10px",top: "-3px"}} placeholder={t("select.sel")} {...selfcltRType}
                                                                                    value={el?.facility_round_type}
                                                                                    onChange={(e) => {
                                                                                        for(let i=0 ; i <= inputunitType?.length -1; i++){
                                                                                            if(inputunitType[i]?.index === index){
                                                                                                inputunitType[i].facility_round_type = e;
                                                                                            }
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col> 
                                                                    </Row>
                                                                )
                                                            })}
                                                        </>
                                                    }
                                                </Space>
                                            </Radio.Group>

                                            {/* { (valuejongType === 2 || valuejongType == 3) &&
                                                <>
                                                    { fcltunittypeData?.length > 0 ? fcltunittypeData?.map((el:any,index:any) => {
                                                        return (
                                                            <Row gutter={16}>
                                                                <Col className="gutter-row" span={1}></Col>

                                                                <Col className="gutter-row" span={5}>
                                                                    <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle'}}><b>{el.unit_type ? el.unit_type.name : '-'} : <>&nbsp;</></b></span>
                                                                </Col>

                                                                <Col className="gutter-row" span={2}>
                                                                    <Input 
                                                                        type={"number"} min={1} size="small" style={{width: "100%",borderRadius: "10px"}} 
                                                                        defaultValue={el.limit_round}
                                                                        onChange={e => { onchangeInput(e.target.value, index, el.name, el.unit_type.id, el.id, el.facility_round_type.id);}}
                                                                    />
                                                                </Col>

                                                                <Col className="gutter-row" span={1.5}>
                                                                    <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                                </Col>

                                                                <Col className="gutter-row" span={4}>
                                                                    <Form.Item style={{marginBottom: "0"}}>
                                                                        <Select size="small" style={{width: "100%",borderRadius: "10px",top: "-3px"}} placeholder={t("select.sel")} {...selfcltRType}
                                                                            // defaultValue={el.facility_round_type.name}
                                                                            defaultValue={el.facility_round_type ? el.facility_round_type.name : '-'}

                                                                            onChange={(e) => {
                                                                                for(let i=0 ; i <= inputunitType?.length -1; i++){
                                                                                    if(inputunitType[i]?.index === index){
                                                                                        inputunitType[i].facility_round_type = e;
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </Col> 
                                                            </Row>
                                                        )
                                                    }):
                                                    
                                                    unittypeData?.length > 0 && unittypeData?.map((el:any,index:any) => {
                                                        return (
                                                            <Row gutter={16}>
                                                                <Col className="gutter-row" span={1}></Col>

                                                                <Col className="gutter-row" span={5}>
                                                                    <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle'}}><b>{el.name} : <>&nbsp;</></b></span>
                                                                </Col>

                                                                <Col className="gutter-row" span={2}>
                                                                    <Input 
                                                                        type={"number"} min={0} size="small" style={{width: "100%",borderRadius: "10px"}} 
                                                                        value={el.limit_round}
                                                                        onChange={e => { onchangeInput(e.target.value, index, el.name, el.id, "", "");}}
                                                                    />
                                                                </Col>

                                                                <Col className="gutter-row" span={1.5}>
                                                                    <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                                </Col>

                                                                <Col className="gutter-row" span={4}>
                                                                    <Form.Item style={{marginBottom: "0"}}>
                                                                        <Select size="small" style={{width: "100%",borderRadius: "10px",top: "-3px"}} placeholder={t("select.sel")} {...selfcltRType}
                                                                            value={el?.facility_round_type}
                                                                            onChange={(e) => {
                                                                                for(let i=0 ; i <= inputunitType?.length -1; i++){
                                                                                    if(inputunitType[i]?.index === index){
                                                                                        inputunitType[i].facility_round_type = e;
                                                                                    }
                                                                                }
                                                                            }}
                                                                        />
                                                                    </Form.Item>
                                                                </Col> 
                                                            </Row>
                                                        )
                                                    })}
                                                </>
                                            } */}
                                        </Form.Item>
                                    }
                                </Card>
                            }
                        </Col>

                        <Col id="Col_w" className="gutter-row" span={6}>
                            <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                <Form.Item
                                    name="cover"
                                    label={t("fclt.cover")}
                                    rules={[{ required: reqimgCover, message: 'Please upload image cover.' }]}
                                >
                                    <Dragger style={{borderRadius: "10px"}} 
                                        {...valueimgCover}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        multiple
                                        maxCount={1}
                                        onChange={changeCover}
                                        beforeUpload={beforeUpload}
                                    >
                                        <p className="ant-upload-text">{t("upload")}</p>
                                    </Dragger>
                                </Form.Item>
                                <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 338*190 PNG, JPG Maximum size 5MB</p>


                                <Form.Item
                                    name="gallery"
                                    label={t("fclt.gall")}
                                    rules={[{ required: reqimgGallery, message: 'Please upload image.' }]}
                                >
                                    <Dragger style={{borderRadius: "10px"}} 
                                        {...valueimgGall}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        multiple
                                        maxCount={5}
                                        onChange={changeGall}
                                        beforeUpload={beforeUpload}
                                    >
                                        <p className="ant-upload-text">{t("upload")}</p>
                                    </Dragger>
                                </Form.Item>
                                <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 600*440 PNG, JPG Maximum size 5MB</p>
                            </Card>

                            { valuejongType == 3 && 
                                <>
                                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true, message: 'Please select.'
                                                },
                                            ]}>
                                            <span style={{fontSize: "0.7vw",marginRight: "5%"}}>การจองล่วงหน้า</span><Switch onChange={oncswJong} checked={swJong} />
                                        </Form.Item>

                                        {swJong === true &&
                                            <Row>
                                                <Col span={4}/>

                                                <Col span={10}>
                                                    <Radio.Group onChange={(e)=> setJongF(e?.target?.value)} value={valueJongF}>
                                                        <Space direction="vertical">
                                                            <Radio value={1} style={{marginBottom:"10%"}}>วัน</Radio>
                                                            <Radio value={2} style={{marginBottom:"10%"}}>ชั่วโมง</Radio>
                                                            {/* <Radio value={3}>นาที</Radio> */}
                                                        </Space>
                                                    </Radio.Group>

                                                </Col>

                                                <Col span={10}>
                                                    <Row>
                                                        <Col span={24} style={{height: "36px"}}>
                                                            {valueJongF === 1 ? <Input type="number" min={1} suffix="วัน" style={{marginBottom:"3%",borderRadius: "10px"}} value={inputJongFD} onChange={e => setinputJongFD(e.target.value)}/>:<></>}
                                                        </Col>

                                                        <Col span={24} style={{height: "36px"}}>
                                                            {valueJongF === 2 ?<Input type="number" min={1} suffix="ชั่วโมง" style={{marginBottom:"3%",borderRadius: "10px"}} value={inputJongFH} onChange={e => setinputJongFH(e.target.value)}/>:<></>}
                                                        </Col>

                                                        <Col span={24} style={{height: "36px"}}>
                                                            {valueJongF === 3 ?<Input type="number" min={1} suffix="นาที" style={{borderRadius: "10px"}} value={inputJongFM} onChange={e => setinputJongFM(e.target.value)}/>:<></>}
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        }

                                        <Form.Item
                                            rules={[
                                                {
                                                    required: true, message: 'Please select.'
                                                },
                                            ]}>
                                            <span style={{fontSize: "0.7vw",marginRight: "5%"}}>การเช็คอิน</span><Switch onChange={onswCheckin} checked={swlCheckin} />
                                        </Form.Item>

                                        {swCheckin === "t" &&
                                            <>
                                                <Row gutter={[16, 24]}>
                                                    <Col span={17} className="gutter-row">
                                                        <Form.Item><div style={{fontSize: "0.7vw"}}>เช็คอินก่อนเวลาจองได้ (นาที)</div></Form.Item>
                                                        <Form.Item><div style={{fontSize: "0.7vw"}}>เช็คอินหลังเวลาจองได้ไม่เกิน (นาที)</div></Form.Item>
                                                    </Col>

                                                    <Col span={7} className="gutter-row">
                                                        <Form.Item name="checkin_befor_time"><Input type="number" min={1} value={inputCbfTime} defaultValue={1} onChange={e => setinputCbfTime(e.target.value)}/></Form.Item>
                                                        
                                                        <Form.Item name="checkin_after_time"><Input type="number" min={1} value={inputCafTime} defaultValue={1} onChange={e => setinputCafTime(e.target.value)}/></Form.Item>
                                                    </Col>
                                                </Row>

                                                <Form.Item label={t("fclt.checkinc")} name="checkin_code">
                                                    <Input value={inputCincode} onChange={e => setinputCincode(e.target.value)} />
                                                </Form.Item>
                                            </>
                                        }

                                    </Card>

                                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                        <Form.Item 
                                            label={t("fclt.waiting")}
                                            // name="waiting_status"
                                            rules={[{ required: true, message: 'Please select.' }]}
                                        >
                                            <Form.Item>
                                                <Radio.Group onChange={(e)=> setWait(e?.target?.value)} value={valueWait}>
                                                    <Space direction="vertical">
                                                        <Radio value={1}>ไม่เปิดให้มี Waiting List</Radio>
                                                        <Radio value={2} style={{width: "100%"}}>เปิดให้มี Waiting List
                                                            {valueWait === 2 ? <Input type={"number"} min={1} max={10} defaultValue={1} style={{ width: "37%", marginLeft: "5%",borderRadius: "10px" }} suffix="คิว" value={inputWaitQ} onChange={e => setinputWaitQ(e.target.value)}/> : null}
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Form.Item>
                                    </Card>

                                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                        <Form.Item 
                                            label={t("fclt.ccjong")} 
                                            // name="pre_cancel_booking_time"
                                            rules={[{ required: true, message: 'Please select.' }]}
                                        >
                                            <Form.Item>
                                                <Radio.Group onChange={(e)=> setccJong(e?.target?.value)} value={valueccJong}>
                                                    <Space direction="vertical">
                                                        <Radio value={1}>ยกเลิกได้ตลอดเวลา</Radio>
                                                        <Radio value={2} style={{width: "100%"}}>ต้องยกเลิกล่วงหน้า
                                                            {valueccJong === 2 ? <Input type={"number"} min={1} max={10} defaultValue={1} style={{ width: "100%", marginLeft: "5%",borderRadius: "10px" }} suffix="ชั่วโมง" value={inputCCJong} onChange={e => setinputCCJong(e.target.value)}/> : null}
                                                        </Radio>
                                                    </Space>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Form.Item>
                                    </Card>
                                </>
                            }

                            { valuejongType == 2 || valuejongType == 3 &&
                                <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <Form.Item label={t("fclt.noti")} name="facility_noti"
                                        rules={[{ required: true, message: 'Please select.' }]}
                                        style={{marginBottom: "0"}}
                                    >
                                        <Form.Item>
                                            <Radio.Group onChange={(e)=> setNoti(e?.target?.value)} value={valueNoti}>
                                                <Space direction="vertical">
                                                    <Radio value={1}>ไม่แจ้งเตือน</Radio>
                                                    <Radio value={2}>ต้องกำหนดเตือนล่วงหน้า
                                                    {valueNoti === 2 ? <Input type={"number"} min={1} defaultValue={1} style={{ width: "30%", marginLeft: "5%",borderRadius: "10px" }} suffix="วัน" value={inputNoti} onChange={e => setinputNoti(e.target.value)}/> : null}
                                                    </Radio>
                                                </Space>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Form.Item>

                                    {valueNoti === 2 &&
                                        <Form.Item label={t("fclt.message")}>
                                            <TextArea style={{height: "30px", maxHeight: "60px"}} value={inputNotimsg} onChange={e => setinputNotimsg(e.target.value)}/>
                                        </Form.Item>
                                    }
                                </Card>
                            }

                            <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                <Form.Item label="สถานะการให้บริการปัจจุบัน"
                                    rules={[{ required: true, message: 'Please select.' }]}
                                >
                                    <Form.Item name="status">
                                        <Switch onChange={onswstDate} checked={swStatus} checkedChildren="เปิด" unCheckedChildren="ปิด"/>
                                    </Form.Item>
                                    {/* onswstdate */}

                                        { swstDate === "f" && // ปิด facility
                                            <>
                                                <Row gutter={[16, 24]}>
                                                    <Col span={24} className="gutter-row">
                                                        <Row gutter={[16, 24]}>
                                                            <Col span={8} className="gutter-row">
                                                                <div style={{fontSize: "0.7vw",marginTop: "5%",color: "#000"}}>{t("fclt.opd")}<span style={{color: "red"}}> *</span></div>
                                                            </Col>

                                                            <Col span={16} className="gutter-row">   
                                                                <Form.Item name="start_opendate" getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                                                                    rules={[
                                                                        {
                                                                            required: swstDate === "t" ? false:true, message: 'Please select start date.'
                                                                        },
                                                                ]}>
                                                                    <DatePicker format="YYYY-MM-DD" style={{width: "95%"}}
                                                                        onChange={(date, dateString) => setsoDate(dateString)} 
                                                                        // disabledDate={(current) => {
                                                                        //     let customDate = moment().format("YYYY-MM-DD");
                                                                        //     return current && current < moment(customDate, "YYYY-MM-DD");
                                                                        // }}
                                                                        disabledDate={(current) => {
                                                                            let todayDate = dayjs().format("YYYY-MM-DD");
                                                                            return current && current.isBefore(todayDate, 'day');
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col span={24} className="gutter-row">
                                                        <Row gutter={[16, 24]}>
                                                            <Col span={8} className="gutter-row">
                                                                <div style={{fontSize: "0.7vw",marginTop: "5%",color: "#000"}}>{t("fclt.cld")}<span style={{color: "red"}}> *</span></div>
                                                            </Col>

                                                            <Col span={16} className="gutter-row">
                                                                <Form.Item name="end_opendate" getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                                                                    rules={[
                                                                        {
                                                                            required: swstDate === "t" ? false:true, message: 'Please select end date.'
                                                                        },
                                                                ]}>
                                                                    <DatePicker format="YYYY-MM-DD" style={{width: "95%"}}
                                                                        onChange={(date, dateString) => seteoDate(dateString)} 
                                                                        // disabledDate={(current) => {
                                                                        //     let customDate = moment().format("YYYY-MM-DD");
                                                                        //     return current && current < moment(customDate, "YYYY-MM-DD");
                                                                        // }}
                                                                        disabledDate={(current) => {
                                                                            let todayDate = dayjs().format("YYYY-MM-DD");
                                                                            return current && current.isBefore(todayDate, 'day');
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                <Form.Item name="close_note" label={t("fclt.Clnote")}>
                                                    <TextArea style={{height: "30px", maxHeight: "150px"}} />
                                                </Form.Item>
                                            </>
                                        }
                                </Form.Item>
                            </Card>

                            <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={10}>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                        <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                    </Col>

                                    <Col className="gutter-row" span={14}>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{recordData?.createBy? recordData?.createBy.first_name :"-"}</div>
                                        <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(recordData?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                        <div style={{fontSize: "0.7vw"}}>{recordData?.updateBy? recordData?.updateBy.first_name :"-"}</div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
    {/* 
                    <Row style={{float: 'right',marginTop: "1.2rem",marginBottom: "1.2rem"}}>
                        <Button type="primary" 
                            onClick={(e) => {
                                setButtonDisabled(true)
                                formProps?.form?.submit()
                            }} 
                            icon={<Icons.SaveOutlined />} 
                            disabled={buttonDisabled}
                        >
                            {t("buttons.save")}
                        </Button>
                    </Row> */}
                </Form>
            </Edit>
        </Spin>
    )
}