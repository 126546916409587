import { Breadcrumb, Button, Col, Edit, Form, Icons, Input, List, Radio, RcFile, Row, Spin, Upload, UploadFile, message, notification, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { useState } from "react";
import { TOKEN_KEY, petURL } from "../../../constants";
import { getValueProps } from "@pankod/refine-strapi-v4";
import axios from "axios";

let rcdata: any = undefined;
export const PettypeEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();

    const [formCustom] = Form.useForm();
    
    const [icon, seticon] = useState<any>();
    const [isLoading, setLoading] = useState<boolean>(true);
    const [icnREF, seticnREF] = useState<any>();

    let getID = window?.location?.href.split("/");
    let pageID: any = getID[getID?.length -1];

    const { data } = useCustom<any>({
        url: `${petURL}/api/lscpet/findOnePetType`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                rcdata = dataLoad;

                if(dataLoad?.icon){
                    seticon({
                        "id": dataLoad?.icon?.id,
                        "url": dataLoad?.icon?.url,
                        "name": dataLoad?.icon?.name
                    })
                }

                const fileList: UploadFile[] = [
                    {
                        uid: dataLoad?.icon?.id,
                        name: dataLoad?.icon?.name,
                        status: "done",
                        url: dataLoad?.icon?.url,
                        thumbUrl: dataLoad?.icon?.url
                    }
                ];
    
                seticnREF({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [...fileList],
                })

                formCustom?.resetFields(["icn"]);

                formCustom?.setFieldsValue({
                    name_th: dataLoad?.pet_type_name,
                    name_en: dataLoad?.pet_type_name_en,
                    // is_charge: dataLoad?.is_charge
                })

                setLoading(false);
            },
        },
    });

    function fileChange(e:any){
        let res: any = e?.file;
        if(res?.status == "done"){
            seticon({
                "id": res?.response[0]?.id,
                "url": res?.response[0]?.url,
                "name": res?.response[0]?.name
            })
        }else if(res?.status == "removed"){
            seticon(undefined);
            seticnREF(undefined); //FIRST DEL
            formCustom?.resetFields(["icn"]);
        }
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    async function submitDT(values:any){
        let body: any = {
            "pet_type_name": values?.name_th,
            "pet_type_name_en": values?.name_en,
            "icon": icon,
            // "is_charge": values?.is_charge,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }
        
        let submit = await axios.put(petURL + "/api/lscpet/updatePetType?id=" + pageID, body, { headers: { } })
        .then(response => {
            // successNoti('Edit is Success');
            successNoti('200', 'Successfully edit pet type');

            replace("/pets/pet-masters")
        })
        .catch(error => {
            console.error('error ',error);
            faildNoti('Edit is Faild');
        });
    }

    const successNoti = (stat:any, msg:any) => {
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    return (
        <Spin size="large" spinning={isLoading}>
            <Edit pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/pet-masters")}}} saveButtonProps={{hidden: true}}>
                <List 
                    title=""
                    pageHeaderProps={{
                        breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-type.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-type.title-edit")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                        extra : <></>
                    }}
                />

                <div style={{width: "25%"}}>
                    <Form 
                        form={formCustom}
                        layout="vertical"
                        onFinish={(values:any) => {
                            submitDT(values);
                        }}
                    >
                        <Form.Item 
                            label={t("pet-type.name-type-th")}
                            name="name_th"
                            rules={[
                                {
                                    required: true, message: 'please input name th.'
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item 
                            label={t("pet-type.name-type-en")}
                            name="name_en"
                            rules={[
                                {
                                    required: true, message: 'please input name en.'
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        
                        <Form.Item
                            name="icn"
                            label={t("pet-type.icon")}
                            rules={[
                                {
                                    required: true, message: 'Please upload image.'
                                },
                            ]}
                            initialValue={icnREF}
                        >

                            <Upload.Dragger
                                {...icnREF}
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={1}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Upload.Dragger>
                        </Form.Item>
                        <p style={{fontSize:"11px", alignItems:"stretch", color: "#DF6677"}}>*{t("pet-type.size-img")}</p>

                        {/* <Row gutter={6}>
                            <Col className="gutter-row" span={8}>
                                <div style={{marginTop: "6px"}}>
                                    <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-type.charge")}</span>
                                </div>
                            </Col>
                            <Col className="gutter-row" span={16}>
                                <Form.Item 
                                    label=" "
                                    name="is_charge"
                                    className="disable_req visible-label"
                                    rules={[
                                        {
                                            required: true, message: 'please select charge'
                                        },
                                    ]}
                                    initialValue={rcdata?.is_charge}
                                >
                                    <Radio.Group name="radiogroup">
                                        <Radio value={false}>{t("pet-type.nh")}</Radio>
                                        <Radio value={true}>{t("pet-type.h")}</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row> */}
                    </Form>
                </div>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button type="primary" onClick={() => formCustom?.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
                </Row>
                
            </Edit>
        </Spin>
    )
}