/* eslint-disable */
import { Card, Col, Create, Divider, Form, Input, Row, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IPPType } from "interfaces";
// import { useState } from "react";
import "react-mde/lib/styles/css/react-mde-all.css";

export const PPtypesCreate: React.FC<IResourceComponentsProps> = () => {
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const t = useTranslate();
    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps }    = useForm<IPPType,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'pp_type',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );

    // const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
    //     "write",
    // );

    // const { queryResult } = useShow<IPPType>({  
    //         metaData: {
    //         populate: '*'
    //     }, 
    // });
    // const { data } = queryResult;
    // const record = data?.data;

    // function fileChange(e:any){
    //     console.log(e);
    //     if(e.file.response){
    //         let res = e.file.response;
    //         console.log(res[0]);     
    //     }
    // }
    
    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >

                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ borderRadius: 5,boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    {/* <Form.Item  label={t("pproperty-type.property-typecode")} name="pp_code" */}
                                    <Form.Item  label={"Property Type Code"} name="pp_code"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter properties code.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                                <Col className="gutter-row" span={12}>
                                    {/* <Form.Item  label={t("pproperty-type.property-typename")} name="pp_name" */}
                                    <Form.Item  label={"Property Type Name"} name="pp_name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter properties name.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "2%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                            <Divider></Divider>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={16}>
                                    <div style={{marginBottom: "5%"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("lupdate")}</div>
                                    <div style={{marginBottom: "5%"}}>{t("by")}</div>
                                </Col>                    
                                <Col className="gutter-row" span={8}>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};