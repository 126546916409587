/* eslint-disable */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Col, Edit, Form, Icons, Image, List, Modal, Row, Select, notification, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog } from "interfaces";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../../constants";


import axios from "axios";

export const HcuAssignEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const log_endpoint = "even-logs";
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [res_data, set_res_data] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);

    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let property_id = id[0]
    let setting_id = id[1]

    const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    const getDataK = async ()=>{
        // http://192.168.100.129:1337/api/home-check-up-settings/find_setting_by_id?property=2&type=Notification&get_all_setting=false&setting_id=10
        // let res_data = await axios.get(API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=Notification&get_all_setting=false&setting_id=${setting_id}`, { headers: { Authorization: AuthStr } });
        let res_data = await axios.get(
                API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&get_all_setting=false`, 
                { headers: { Authorization: AuthStr } }).then(async (res) => {
                    console.log('res data', res?.data)
                    set_res_data(res?.data)
                    // SetPropertyName(res?.data.property.property_name)

                    if(res?.data.inspec_team.length > 0){
                        for (let index = 0; index < res?.data.inspec_team.length; index++) {
                            // console.log('res?.data.inspec_team.id', res?.data.inspec_team[index].id)
                            InspectorId.push(res?.data.inspec_team[index].id)
                            Inspector.push(res?.data.inspec_team[index])

                            let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[user_profile][id][$eq]="+ res?.data.inspec_team[index].id, {
                                headers: { Authorization: "Bearer "+token }
                            });
                            // console.log('k', k)

                            IdFilter.push(k.data.data[0].id)
                        }
                    }
                    // console.log('InspectorId', InspectorId)
                    // console.log('Inspector', Inspector)
                    // console.log('IdFilter', IdFilter)
                    console.log(">>> res?.data.property",res?.data.property)
                    SetProperty({id: res?.data?.property?.id, label: res?.data.property?.property_name})
                });
    }

    // useEffect(() => {
    //     if (countInterval < 3) {
    //         const interval = setInterval(() => {
    //             setCountInterval(prev => prev + 1);
    //             if(countInterval === 1){
    //                 // console.log('call')
    //                 getDataK();
    //             }
    //             // console.log('res_data', res_data);
    //             // console.log('property', Property);
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();
    // console.log('checkedSms', checkedSms);

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "role_management][id]",
                operator: "eq",
                value: 19 // role Inspector
            },
            {
                field : "property][id]",
                operator : data_property !== null ? "eq" : "ne",
                value : data_property !== null ? data_property : null
            },
            {
                field: "id",
                operator: "nin",
                value: IdFilter.length > 0 ? IdFilter : 0
                // value: [79, 76]
            }
        ]
    });

    const AddInspectorToArr = async (e:any) => {
        // console.log('e', e);
        IdFilter.push(e);
        SetIdFilter(IdFilter);
        // let k = await axios.get(API_URL + "/user-type-mappings?populate=*&filters[id][$eq]="+ e, {
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        // console.log('k', k)
        let inspector_profile = k?.data?.data;
        // console.log('inspector_profile', inspector_profile[0].attributes.user_profile?.data.attributes)

        Inspector.push(inspector_profile[0].attributes.user_profile?.data.attributes);
        InspectorId.push(inspector_profile[0].attributes.user_profile?.data.id);
        SetInspector(Inspector);

        setTimeout(() => {
            form.resetFields(["Inspector"]);
        }, 500);

        // console.log('Inspector', Inspector)
        // console.log('IdFilter', IdFilter)
    }

    const RemoveItemFromArr = async (e:any) => {
        // console.log('e', e)
        Inspector.splice(e, 1); // 2nd parameter means remove one item only
        InspectorId.splice(e,1)
        IdFilter.splice(e, 1);
    }

    async function createAssignInspec(values:any) {

        // console.log('Inspector', Inspector)
        // console.log('InspectorId', InspectorId)
        // console.log('IdFilter', IdFilter)
        console.log('values', values)

        let data = { 
            "data":{
                "property": Property?.id,
                // inspec_team: [79,77,76]
                inspec_team: InspectorId,
                "updateBy" : Identity_data ? Identity_data.user_profile.id : null
                // inspec_team: 79
            }
        }

        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)

        successNoti('200', 'success');

        replace("/majorcare/setup-hcu")
    }

    const successNoti = (stat:any, msg:any) => {
        notification.success({
            // message: stat + ' : ' + msg.TH,
            message: msg,
            // description: msg,
            duration: 3,
        });
    };


    const warning = (index:any, first_name:any, last_name:any) => {
        Modal.warning({
        //   title: <span>{t("msg-facility-booking.title-warning")}</span>,
        //   content: <span>{t("msg-facility-booking.content-warning")}</span>,
        //   title: <span>Warning</span>,
          content: <span>คุณต้องการลบ {first_name} {last_name}</span>,
          onOk() {
            RemoveItemFromArr(index); refetch();
          },
          onCancel(){
          }
        });

        // RemoveItemFromArr(index); refetch();
    };

    const showDeleteConfirm = (index:any, first_name:any, last_name:any) => {
        Modal.confirm({
          title: `คุณต้องการลบ ${first_name} ${last_name}`,
          icon: <ExclamationCircleOutlined />,
        //   content: 'Some descriptions',
          okText: 'ลบ',
          okType: 'danger',
          cancelText: 'ยกเลิก',
          onOk() {
            console.log('OK');
            RemoveItemFromArr(index); refetch();

          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };
  
    console.log('InspectorId', InspectorId)
    console.log('Inspector', Inspector)
    console.log('IdFilter', IdFilter)

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // if(checked2lan == true){
                    //     createNoti(values)
                    // }else if(checked2lan == false && (checkedInApp == true || checkedSms == true || checkedEmail == true)){
                    //     setswlocal('en')
                    //     setchecked2lan(true)
                    // }else if(checkedInApp == false && checkedSms == false && checkedEmail == false){
                    //     error();
                    // }
                    
                    // values.property = values.property.id;
                    // values.type = values.type.value;

                    createAssignInspec(values);
                    // values.msg = values.msg;
                    // values.msg_en = values.msg;
                    // values.createBy = user_data.user_profile.id;
                    // values.deleteStatus = "N";

                    // values.sms = values.sms == undefined ? false : true;
                    // values.email = values.email == undefined ? false : true;
                    // values.inApp = values.inApp == undefined ? false : true;

                    // console.log(values)

                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={6} className="gutter-row" style={{borderRight: "1px solid #dedede",paddingTop: "2.5rem"}}>
                            <Form.Item
                                // name={["property", "id"]}
                                label="Property"
                                // rules={[
                                //     {
                                //         required: true, message: "Please select property"
                                //     },
                                // ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty}
                                    defaultValue={Property?.label} 
                                    value={Property?.label} 
                                    disabled
                                />
                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                name="Inspector"
                                label="Inspector"
                                // rules={[
                                //     {
                                //         required: true, message: "Please select type"
                                //     },
                                // ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selInspector}
                                    onSelect={(value: any, record:any) => {
                                        AddInspectorToArr(value);
                                        refetch();
                                    }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18} className="gutter-row" style={{paddingTop: "2.5rem"}}>
                            <Row gutter={82}>
                                <Col span={24} className="gutter-row">

                                <div style={{fontSize:"1vw"}}>{t("mdpc-assign-technician.list-of-technician")}</div>
                                   <div> 
                                    {
                                        Inspector.length > 0 ?
                                            Inspector.map((item: any, index: any) => {
                                                // console.log('item', item?.avatar?.data)
                                                // console.log('index', index)
                                                console.log('item', item)
                                                return <>
                                                <Row gutter={8} style={{marginTop: "1rem"}}>
                                                    <Col span={22} className="gutter-row">
                                                        <Row gutter={8}>
                                                            <Col span={4} className="gutter-row">

                                                                {/* <Image 
                                                                    alt="Avatar" 
                                                                    src={
                                                                        item?.avatar?.data !== null ? 
                                                                            item?.avatar?.data?.attributes.url 
                                                                        : 
                                                                            "https://lscmain.s3.ap-southeast-1.amazonaws.com/toppng_com_user_account_management_logo_user_icon_561x561_66926db800.png"
                                                                    }
                                                                    style={{borderRadius : "50px",width: "60%", height: "50px"}}
                                                                /> */}
                                                                <Image 
                                                                    alt="Avatar" 
                                                                    src={
                                                                        item?.avatar?.data !== null ? 
                                                                            item?.avatar?.data?.attributes.url !== undefined ?
                                                                                item?.avatar?.data?.attributes.url 
                                                                            :
                                                                                item?.avatar?.url !== undefined ?
                                                                                    item?.avatar?.url
                                                                                :
                                                                                "https://lscmain.s3.ap-southeast-1.amazonaws.com/toppng_com_user_account_management_logo_user_icon_561x561_66926db800.png"
                                                                        : 
                                                                            "https://lscmain.s3.ap-southeast-1.amazonaws.com/toppng_com_user_account_management_logo_user_icon_561x561_66926db800.png"
                                                                    }
                                                                    style={{borderRadius : "50px",width: "100%", height: "50px", filter: item.deleteStatus == "Y" ? 'grayscale(100%)' : '', opacity: item.deleteStatus == "Y" ? '0.2' : '1.0' }}
                                                                />
                                                            </Col>
                                                            <Col span={20} className="gutter-row">
                                                                <div style={{color: item.deleteStatus == "Y" ? '#ededed' : 'black'}}>{item.first_name} {item.last_name}</div>
                                                                <div style={{color: item.deleteStatus == "Y" ? '#ededed' : 'black'}}><Icons.PhoneOutlined />{" "}{item.tel ? item.tel : "no phone number"}</div>
                                                            </Col>
                                                        </Row> 
                                                    </Col>
                                                    <Col span={2} className="gutter-row">
                                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} 
                                                            // onClick={() => { RemoveItemFromArr(index); refetch(); }} 
                                                            // onClick={() => { warning(index, item.first_name, item.last_name) }} 
                                                            onClick={() => { showDeleteConfirm(index, item.first_name, item.last_name) }} 
                                                            danger 
                                                        />
                                                    </Col>

                                                </Row>
                                                <div style={{marginBottom:"20px"}}></div> 
 
                                                    {/* <div style={{marginBottom:"20px"}}></div> 
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <div key={key} style={{ display: "flex", alignItems: "center" }}>
                                                            <div className="circular_image">
                                                                <img
                                                                    src="https://lscmain.s3.ap-southeast-1.amazonaws.com/Karaoke_c5f17869b3.jpg"
                                                                    alt="Avatar"
                                                                />
                                                            </div>
                                                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                                                                <div>
                                                                    {item.first_name} {item.last_name}
                                                                </div>
                                                                <div>
                                                                    <Icons.PhoneOutlined />{" "}{item.tel}
                                                                </div>
                                                            </div>
                                                            <div style={{ marginLeft: "100px" }}>
                                                                <Icons.DeleteOutlined />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{marginBottom:"20px"}}></div> */}

                                                </>
                                            })
                                        :
                                            <>
                                                <div className="bgimg" style={{width:"100%", height:"700px", backgroundPosition:"center"}}></div>
                                            </>
                                    }
                                   </div>
                                </Col>
                                {/* <Col span={12} className="gutter-row">
                                    
                                </Col>
                                <Col span={12} className="gutter-row">
                                    
                                </Col>
                                <Col span={24} className="gutter-row" style={{marginTop: "3rem"}}>
                                    
                                </Col> */}
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Edit>   
    );
};