/* eslint-disable */
import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Badge, Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, Input, List, Modal, Row, Select, Space, Table, Tabs, Tag, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { IEventlog, IProperties, Ivehicle } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../constants";
// import moment from "moment";

const { TabPane } = Tabs;

let datavehicle: any = undefined;
export const VehicleList: React.FC<IResourceComponentsProps> = () => {
    const tabStatus = localStorage.getItem("keyTabStatus");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    // console.log('Identity_data', Identity_data)
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Vehicle");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { confirm } = Modal;
    const { mutate } = useUpdate<Ivehicle>();
    const ed_point = "vehicles";
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const [vProp, setProp] = useState<any>(undefined);
    const [vUnit, setUnit] = useState<any>(undefined);
    const [StickerId, setStickerId] = useState<any>(undefined);
    const [deleteStatus, setdeleteStatus] = useState<any>("N")

    const [keepPagination, setKeepPagination] = useState<any>()
    const [keepStatus, setKeepStatus] = useState<any>("N")
    const [keepBadge, setKeepBadge] = useState<any>()
    const [listData, setListdata] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(null);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
        
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const { tableProps, searchFormProps, tableQueryResult } = useTable<Ivehicle,HttpError, Ivehicle>({
        metaData: {
             populate: ['unit','unit.property', 'vehicle_brand', 'vehicle_color', 'vehicle_model', 'vehicle_type', 'updateBy', 'createBy', 'createdBy',
             'unit.property.project.project_brand','unit.unit_type']
            //populate: ['unit_type','unit_type.vehicle_limit']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property } = params;
            //console.log(">>> property", property)
            // console.log(">>> deleteStatus", deleteStatus)
            // console.log(">>> vUnit", vUnit)
            // console.log(">>> StickerId", StickerId)
            filters.push(
                // {
                //     field       : "unit][property][id]",
                //     operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                //     value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                // },
                {
                    field       : "unit][property][id]",
                    operator    : "eq",
                    value       : !!property ? property : null,
                },
                {
                    field       : "deleteStatus",
                    operator    : "eq",
                    value       : keepStatus
                    //value       : tabStatus
                },
                {
                    field       : "unit][unit_name]",
                    operator    : "contains",
                    value       : vUnit
                },
                {
                    field       : "sticker_id",
                    operator    : "contains",
                    value       : StickerId
                }
            );

            return filters;
        },
        initialPageSize: 10000,
        initialFilter: [
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : tabStatus
            },
            {
                field : "unit][property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        permanentSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],queryOptions: {
            onSuccess(data) {
                let dataLoad: any = data?.data;
                //console.log("dataLoad",dataLoad)
                // setListdata(dataLoad)
            },
        }
    });
    
    const datavhc = useList<Ivehicle>({ 
        resource: "vehicles" ,
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
                {
                    field       : "unit][id]",
                    operator    : !!keepBadge ? "eq" : "nin",
                    value       : !!keepBadge ? keepBadge : null
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                datavehicle = dataLoad;
            },
        }
    });

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
        pagination:{
            pageSize: 200
        }
    });

    const { selectProps: selUniT } = useSelect<any>({
        resource: "units",
        // resource: "units/findAllUnit",
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : (vProp || prop_list || prop_id) ? "eq" : "nin",
                value       : vProp ? vProp: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
        pagination:{
            pageSize: 2000
        }
    });

    const { triggerExport, isLoading: exportLoading } = useExport<Ivehicle>({
        mapData: (item) => {
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,
                };
            }
        },
    });

    const onReset = () => {
        setProp(undefined);
        setUnit(undefined);
        setStickerId(undefined);
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {deleteStatus: event},
        },{
            onSuccess(data, variables, context) {
                getData()
            },
            onError(error, variables, context) {
                getData()
            },
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'vehicles',
                SysPkID         : id+""
            },successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function onStatusChange(v:any){
        console.log("V",v)
        setdeleteStatus(v);
        setKeepStatus(v)
        setPage(1)
        searchFormProps.form?.submit();
        
        // localStorage.setItem("keyU",v)
        // localStorage.setItem("keyTabUserPortal", v)
        
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : sorterOrder
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }

        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSizeChange(pagination?.pageSize)
        }
    };
    
    const API_URL = useApiUrl();
    const getData = async ()=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/vehicles?populate=unit.property.project.project_brand&populate=unit.unit_type&populate=vehicle_brand&populate=vehicle_color&populate=vehicle_model&populate=vehicle_type&populate=createBy&populate=updateBy&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(vUnit){
                apiEndPoint += `&filters[unit][unit_name][$contains]=${vUnit}`
            }
            if(data_property){
                apiEndPoint += `&filters[unit][property][id][$eq]=${data_property}`
            }
            else if(vProp){
                apiEndPoint += `&filters[unit][property][id][$eq]=${vProp}`
            }
            if(StickerId){
                apiEndPoint += `&filters[sticker_id][$contains]=${StickerId}`
            }
            if(keepStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${keepStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${keepStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const resultList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let unit = null
                        let vehicle_brand = null
                        let vehicle_color = null
                        let vehicle_model = null
                        let vehicle_type = null
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.vehicle_brand?.data){
                            vehicle_brand = {id: attributes?.vehicle_brand?.data?.id , ...attributes?.vehicle_brand.data.attributes}
                        }
                        if(attributes?.vehicle_color?.data){
                            vehicle_color = {id: attributes?.vehicle_color?.data?.id , ...attributes?.vehicle_color.data.attributes}
                        }
                        if(attributes?.vehicle_model?.data){
                            vehicle_model = {id: attributes?.vehicle_model?.data?.id , ...attributes?.vehicle_model.data.attributes}
                        }
                        if(attributes?.vehicle_type?.data){
                            vehicle_type = {id: attributes?.vehicle_type?.data?.id , ...attributes?.vehicle_type.data.attributes}
                        }
                        try {
                            if(attributes?.unit?.data?.attributes){
                                const unitData = attributes?.unit?.data
                                let property = null
                                let unitType = null
                                try {
                                    if(unitData?.attributes?.property?.data){
                                        const propertyData = unitData?.attributes?.property?.data
                                        let project = null
    
                                        if(propertyData?.attributes?.aqi_response?.data){
                                            propertyData.attributes.aqi_response = propertyData?.attributes?.aqi_response?.data
                                        }
                                        if(propertyData?.attributes?.project?.data){
                                            const projectData = propertyData?.attributes?.project?.data
                                            let projectBrand = null
                                            if(projectData?.attributes?.project_brand?.data){
                                                const projectBrandData = projectData?.attributes?.project_brand?.data
                                                projectBrand = {id: projectBrandData?.id , ...projectBrandData?.attributes}
                                            }
                                            project = {id: projectData?.id , ...projectData?.attributes}
                                            project.project_brand = projectBrand
                                        }
                                        property = {id: propertyData?.id , ...propertyData?.attributes}
                                        property.project = project
                                    }
                                } catch (error) {
                                    property = null
                                }
                                try {
                                    if(unitData?.attributes?.unit_type?.data){
                                        const unitTypeData = unitData?.attributes?.unit_type?.data
                                        unitType = {id: unitTypeData?.id , ...unitTypeData?.attributes}
                                    }
                                } catch (error) {
                                    unitType = null
                                }
                                unit = {id: unitData?.id , ...unitData?.attributes}
                                unit.property = property
                                unit.unit_type = unitType
                            }
                        } catch (error) {
                            unit = null
                        }
                        const result = {id: item?.id , ...attributes}
                        result.createBy = createBy
                        result.updateBy = updateBy
                        result.unit = unit
                        result.vehicle_brand = vehicle_brand
                        result.vehicle_color = vehicle_color
                        result.vehicle_color = vehicle_color
                        result.vehicle_model = vehicle_model
                        result.vehicle_model = vehicle_model
                        result.vehicle_type = vehicle_type
                        return result
                    })
                    setListdata(resultList || [])
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    setLoadingTable(false)
                });
        } catch (error) {
            setLoadingTable(false)
        }
    }

    useEffect(() => {
        if (!vUnit && !vProp && !StickerId && Identity_data) {
            getData()
        }
      }, [vUnit, vProp, StickerId]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,keepStatus,Identity_data])
    
    return <>
        <List 
            title={t("vehicle.bc_title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
                }}
            >

            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        {/* <Form.Item name="property" >
                            <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear
                            />
                        </Form.Item> */}

                        <Form.Item name="property" >
                            <Select {...propertySelectProps} 
                                placeholder="Select property" 
                                size="middle" 
                                style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                allowClear
                                onClear={()=> setProp(undefined)}
                                onChange={(e) => setProp(e)}
                            />
                        </Form.Item>

                        <Form.Item name="unit" >
                            {/* <Select {...selUniT} 
                                placeholder="Select unit" 
                                size="middle" 
                                style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                allowClear
                                onClear={()=> setUnit(undefined)}
                                onChange={(e) => setUnit(e)}
                            /> */}
                            <Input 
                                onChange={(e) => setUnit(e.target.value)}
                                style={{borderRadius: 4}} 
                                placeholder="Search unit" allowClear
                            />
                        </Form.Item>


                        <Form.Item name="sticker_id" >
                            <Input placeholder="sticker ID"
                                onChange={(e) => setStickerId(e.target.value)} 
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={getData} htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                   
                <Col span={6}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right"}}
                    >
                        {/* <Form.Item>
                            <ExportButton style={{borderRadius:5}}
                                onClick={triggerExport}
                                loading={exportLoading}
                            />
                        </Form.Item> */}

                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Form
                {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        defaultActiveKey={`${tabStatus}`}
                        style={{ marginTop: 10, marginBottom: 10 }} 
                        onChange={(e:any) => {
                            localStorage.setItem("keyTabStatus",e)
                            onStatusChange(e)
                            // setKeepPagination(datac?.data.filter((A:any)=> A.deleteStatus == e).length)
                            searchFormProps?.form?.submit();
                        }} 
                    >
                        <TabPane
                            key={"N"}
                            tab={
                                <span>
                                    {"ACTIVE"}
                                    <Badge count={datavehicle?.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        />
                        <TabPane
                            key={"Y"}
                            tab={
                                <span>
                                    {"TRASH"}
                                    <Badge count={datavehicle?.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        />
                    </Tabs>
                </Form.Item>
            </Form>

            <Table 
                //{...tableProps}
                dataSource={listData}
                //dataSource={tableProps?.dataSource?.filter((e:any)=> e.deleteStatus == "N")}
                //dataSource={keepTable?.filter((e:any) => e.deleteStatus == keepStatus)}
                onChange={handleTableChange}
                loading = {loadingTable}
                bordered
                size    = "middle"
                rowKey  = "id"
                // pagination={{ 
                //     defaultPageSize : 10, 
                //     showSizeChanger : true, 
                //     locale:{ items_per_page: "/ Pages"},
                //     pageSizeOptions: pageSizeState, 
                //     onShowSizeChange , 
                //     total:tableProps?.dataSource?.length, 
                //     onChange(Current){ setPage(Current)},
                //     hideOnSinglePage: false
                // }}
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    // onShowSizeChange,
                    // onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false,
                    current: metaPagination?.page || page,
                    pageSize: metaPagination?.pageSize || pageSizeChange,
                    total: metaPagination?.total,
                    // metaPagination?.pageCount
                    position:["bottomRight"]
                }}  
                scroll={{ x: 1300 }}
            >
                    
                {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}
                
                {/* <Table.Column dataIndex="" title={t("BRAND")} width={150} sorter
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                <TextField value={record?.unit ? record?.unit?.property?.project?.project_brand?.brand_name : "-"}  />
                            }
                        </>
                    }}
                /> */}

                <Table.Column key={'[unit][property][property_name]'} title={t("property.Ltitle")} align="center" width={150}
                    sorter={(a:any, b:any) => {
                        if (a?.unit?.property?.property_name < b?.unit?.property?.property_name) {return -1}
                        if (a?.unit?.property?.property_name > b?.unit?.property?.property_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        //console.log("firstTTTT",record)
                        return <>
                            <TextField value={record?.unit ? record?.unit.property.property_name : "-"}  />
                        </>
                    }}
                />

                <Table.Column 
                    key={'[unit][unit_name]'} 
                    dataIndex="unit][unit_name]" 
                    title={t("vehicle.Lunit")} 
                    width={120}
                    align="center"
                    sorter={(a, b) => a.unit?.unit_name.localeCompare(b.unit?.unit_name)}
                    sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        //{console.log("record",record)}
                        return <>
                            <TextField value={record?.unit ? record?.unit.unit_name : "-"}  />
                        </>
                    }}
                />

                <Table.Column 
                    key={'[unit][unit_type][vehicle_limit]'} 
                    title={"LIMIT OF UNIT" } 
                    width={120}
                    align="center"
                    sorter={(a:any, b:any) => {
                        if (a?.unit?.unit_type?.vehicle_limit < b?.unit?.unit_type?.vehicle_limit) {return -1}
                        if (a?.unit?.unit_type?.vehicle_limit > b?.unit?.unit_type?.vehicle_limit) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                         {console.log("first",record)}
                        return <>
                            <TextField value={record?.unit ? record?.unit.unit_type ? record?.unit.unit_type.vehicle_limit:"-":"-"}  />
                        </>
                    }}
                />
                    
                {/* <Table.Column dataIndex="property" title={t("property.Ltitle")} width={150}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.unit ? record?.unit.property.property_name : "-"}  />
                        </>
                    }}
                /> */}

                <Table.Column key={'[license_plate]'} title={t("vehicle.Llicense")} align="center" width={150}
                    sorter={(a:any, b:any) => {
                        if (a?.license_plate < b?.license_plate) {return -1}
                        if (a?.license_plate > b?.license_plate) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record? record.license_plate:"-"}  />
                        </>
                    }}
                />

                {/* <Table.Column 
                    dataIndex="unit][unit_name]" 
                    title={t("vehicle.Lunit")} 
                    width={120}
                    sorter={(a, b) => a.unit.unit_name.localeCompare(b.unit.unit_name)}
                    sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.unit ? record?.unit.unit_name : "-"}  />
                        </>
                    }}
                /> */}

                <Table.Column key={'[sticker_id]'} title={t("vehicle.Lstk")} width={100} align={"center"}
                    sorter={(a:any, b:any) => {
                        if (a?.sticker_id < b?.sticker_id) {return -1}
                        if (a?.sticker_id > b?.sticker_id) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record.sticker_id !== null && record.sticker_id !== undefined? record?.sticker_id : "ไม่ระบุ"}  />
                        </>
                    }}
                />

                {/* <Table.Column dataIndex="license_plate" title={t("vehicle.Llicense")} width={150}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={value}  />
                        </>
                    }}
                /> */}

                <Table.Column key={'[vehicle_brand][brand_nameEN]'} title={"BRAND"} align="center" width={150}
                    sorter={(a:any, b:any) => {
                        if (a?.vehicle_brand?.brand_nameEN < b?.vehicle_brand?.brand_nameEN) {return -1}
                        if (a?.vehicle_brand?.brand_nameEN > b?.vehicle_brand?.brand_nameEN) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.vehicle_brand? record?.vehicle_brand.brand_nameEN :"-"}  />
                        </>
                    }}
                />

                {/* <Table.Column dataIndex="vehicle_model" title={t("vehicle.Lmodel")} width={150}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.vehicle_model? record?.vehicle_model.model_nameEN :"-"}  />
                        </>
                    }}
                /> */}

                <Table.Column key={'[vehicle_type][type_nameEN]'} title={"TYPE"} align="center" width={150}
                    sorter={(a:any, b:any) => {
                        if (a?.vehicle_type?.type_nameEN < b?.vehicle_type?.type_nameEN) {return -1}
                        if (a?.vehicle_type?.type_nameEN > b?.vehicle_type?.type_nameEN) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.vehicle_type? record?.vehicle_type.type_nameEN :"-"}  />
                        </>
                    }}
                />

                <Table.Column key={'[vehicle_color][color_nameEN]'} title={"COLOR"} align="center" width={150}
                    sorter={(a:any, b:any) => {
                        if (a?.vehicle_color?.color_nameEN < b?.vehicle_color?.color_nameEN) {return -1}
                        if (a?.vehicle_color?.color_nameEN > b?.vehicle_color?.color_nameEN) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.vehicle_color? record?.vehicle_color.color_nameEN :"-"}  />
                        </>
                    }}
                />

                <Table.Column key={'[approve_status]'} align="center" width={150} dataIndex="approve_status" title="APPROVE STATUS" 
                    sorter={(a:any, b:any) => {
                        if (a?.approve_status < b?.approve_status) {return -1}
                        if (a?.approve_status > b?.approve_status) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        let c = ""
                        let s = ""
                        c = (record.approve_status == "true") ? 'green':(record.approve_status == "false" || record.approve_status == null) ? 'red':''
                        s = (record.approve_status == "true") ?'APPROVE': (record.approve_status == "false" || record.approve_status == null) ?'REJECT':''

                        return <>
                            {value == "true" ?
                                <Tag style={{borderRadius: "10px"}} color={c}>
                                    {s}
                                </Tag> 
                            :
                                // value == "false" || value == null ?
                                value == "false" ?
                                    <Tag style={{borderRadius: "10px"}} color={c}>
                                        {s}
                                    </Tag>
                                :<>-</>
                            }
                        </>
                    }}
                />

                <Table.Column key={'[createBy][first_name]'} title="REQUEST BY" align="center" width={100} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                        if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.createBy ? record?.createBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column key={'[createdAt]'} title="REQUEST DATE" align="center" width={200}
                    sorter={(a:any, b:any) => {
                        if (a?.createdAt < b?.createdAt) {return -1}
                        if (a?.createdAt > b?.createdAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.createdAt ?
                                    <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />

                {/* <Table.Column dataIndex="updateBy" title="UPDATE BY" align="center" width={100}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                        </>
                    }}
                /> */}

                <Table.Column key={'[updateBy][first_name]'} title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                        if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column key={'[updatedAt]'} title="LAST UPDATE" align="center" width={200}
                    sorter={(a:any, b:any) => {
                        if (a?.updatedAt < b?.updatedAt) {return -1}
                        if (a?.updatedAt > b?.updatedAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.updatedAt ?
                                    <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                />
                  
                <Table.Column<Ivehicle>
                    width={80}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed= "right"
                    render={(_, record) => (
                        <Space>
                            {
                                record.deleteStatus === "N" ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        />
                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                                :
                                    <>
                                        <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                            }
                        </Space>
                    )}
                />
            </Table>
        </List>
    </>
}