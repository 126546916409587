import { Badge, Breadcrumb, Button, Col, CreateButton,  DatePicker,  Divider,  EditButton, ExportButton, Form, Icons,  Input,  List, Modal,  Radio,  Row, Select, ShowButton, Space, Switch, Table,  Tabs,  Tag,  TextField, useModalForm, useSelect, useSimpleList, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IAppointment, IAppointmentType, IEventlog, IProperties, IStaffHasProp, IUnit, IUserhunit } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import axios from "axios";
// import moment from "moment";
import { TOKEN_KEY } from "../../constants";
import { AppointmCreate } from "./create";
import { AppointmEdit } from "./edit";

let appointty_data :any = undefined;
let userhunit_data :any = undefined;
let staffdata: any = undefined;

export const AppointmList: React.FC<IResourceComponentsProps> = () => {

    const tabActive = localStorage.getItem("keyTabAppointment");
    const t = useTranslate();
    const { Option } = Select;
    const API_URL = useApiUrl();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Appointments");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const { TabPane } = Tabs;
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IUnit>();
    const ed_point                          = "appointments";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'};

    const [deleteStatus, setdeleteStatus]  = useState<any>("N");
    const [appointmentType, setappointmentType]  = useState<any>();

    const [firstName, setFirstName] = useState<any>()
    const [lastName, setLastName] = useState<any>()

    const [ vApptype, setApptype ] = useState<any>();
    const [ vProp, setProp ] = useState<any>();
    const [ vSelreg, setSelreg ] = useState(false);
    const [ vUnit, setUnit ] = useState<any>();
    const [ vSubtypeID, setSubtypeID ] = useState<any>([]);
    const [ vDate, setDate ] = useState<any>("");
    const [ vDaterec, setDaterec ] = useState<any>("");
    const [ vApptimeslot, setApptimeslot ] = useState<any>([]);
    const [ vTslot1, setTslot1 ] = useState<any>("");
    const [ vTslot2, setTslot2 ] = useState<any>("");
    const [ vAppstatus, setAppstatus ] = useState<any>("");
    const [ vConfirm, setConfirm ] = useState<boolean>(false);
    const [ vStaffhprop, setStaffhprop ] = useState<any>();

    const [ vCustomerID, setCustomerID]                 = useState<any>("");
    const [ vCustomerName, setCustomerName ]            = useState<any>("");
    const [ vCustomerLastName, setCustomerLastName ]    = useState<any>("");
    const [ vCustomerPhone, setCustomerPhone ]          = useState<any>("");
    const [ vCustomerEmail, setCustomerEmail ]          = useState<any>("");
    const [ selectedTab, setSelectedTab ]          = useState<any>('5');

    const [isDetail, setDetail] = useState<any>()
    const [isLoadingEdit, setLoadingEdit] = useState<boolean>(true);

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IAppointment,HttpError, IAppointment>({
        metaData: {
            populate: ['*', 'property', 'user_profile', 'updateBy', 'createBy']
        },
        initialFilter:[
            {
                field       : "appointmentType][id]",
                operator    : "eq",
                value       : selectedTab ? selectedTab : 5
            },
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : "N"
            },
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        initialPageSize: 20000,
        onSearch: (params) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    field       : "appointmentType][id]",
                    operator    : "eq",
                    value       : appointmentType
                },
                {
                    field       : "deleteStatus",
                    operator    : "eq",
                    value       : deleteStatus
                },
                // {
                //     field : "property][id]",
                //     operator: (prop_list || prop_id) ? "eq" : "nin",
                //     value: prop_list ? prop_list: prop_id ? prop_id : null,
                // },
                {
                    field       : "property][id]",
                    operator    : "eq",
                    value       : !!vProp ? vProp : null
                },
                {
                    field       : "customerFirstName",
                    operator    : "contains",
                    value       : !!firstName ? firstName : null
                },
                {
                    field       : "customerLastName",
                    operator    : "contains",
                    value       : !!lastName ? lastName : null
                },
            );
            return filters;
        },
        // queryOptions:{
        //     onSuccess(data) {
        //         let dataLoad = data?.data;
        //         console.log(">>> dataLoad", dataLoad)
        //     },
        // }
        // permanentFilter:[
        //     {
        //         field : "property][id]",
        //         operator: data_property ? "eq" : "nin",
        //         value: data_property ? data_property : null
        //     }
        // ]
    });

    // console.log('tableProps', tableProps)

    const AppType = useList<IAppointmentType>({ 
        resource: "appointment-types" ,
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : 'deleteStatus',
                    operator    : 'contains',
                    value       :  'N',
                },
            ]
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            if(dataLoad){
                setappointmentType(dataLoad[0]?.id)
            }
        },}
    });
    const {data:dataappPost} = AppType

    const appntData = useList<IAppointment>({ 
        resource: "appointments" ,
        metaData: {
            populate: ['appointmentType', 'appointmentType.id']
        },
        config:{
            sort: [{ order: "desc", field: "id" }],
            pagination:{current: 1, pageSize: 2000},
            filters: [
                {
                    field       : 'deleteStatus',
                    operator    : 'contains',
                    value       :  'N',
                },
            ]
        }
    });
    const {data:dataAppnt} = appntData

    const datacus = useList<IAppointment>({ 
        resource: "appointments" ,
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        }
    });
    const {data:dataCUSTOM} = datacus

    const apptype = useList<IAppointmentType>(
        {
            resource: "appointment-types",
            metaData: {
                populate: ['user_sub_types', 'user_sub_types.sub_type_name']
            },
            config:{
                filters: [
                    {
                        field       : 'deleteStatus',
                        operator    : 'contains',
                        value       :  'N',
                    },
                    {
                        field       : 'status',
                        operator    : 'eq',
                        value       :  true,
                    },
                ],
                pagination: { current: 1, pageSize: 1000 },
            },
            queryOptions:{onSuccess(data) {
                let dataLoad = data?.data;
                appointty_data = dataLoad ? dataLoad : [];
            },}
        }
    );

    const userhunit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile', 'user_profile.first_name', 'user_profile.last_name','user_sub_type','unit','unit.property','user_profile.user'],
        },
        config:{
            filters: [
                {
                    field   : "unit][property][id]",
                    operator: "eq",
                    value   : vProp
                },
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : vUnit
                },
                // {
                //     field       : "user_sub_type][id]",
                //     operator    : "in",
                //     value       : vSubtypeID?.length > 0 ? vSubtypeID : null
                // },
            ],
            pagination: { current: 1, pageSize: 1000 },
        },
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            // console.log(">>> dataLoad",dataLoad)
            userhunit_data = dataLoad ? dataLoad : [];

            // if(dataLoad?.length == 0){
            if(dataLoad?.length <= 0){
                setCustomerName("");
                setCustomerLastName("");
                setCustomerPhone("");
                setCustomerEmail("")
            }
        },}
    });

    const { selectProps: selApType } = useSelect<IAppointmentType>({
        resource: "appointment-types",
        optionLabel: "name",
        optionValue: "id",
        metaData: {
            populate: ['user_sub_types', 'user_sub_types.sub_type_name']
        },
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'status',
                operator    : 'eq',
                value       :  true,
            },
        ],
    });

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data
            if(dataSet?.length == 1){
                setProp(dataSet[0]?.id)
                createProps?.form?.setFieldsValue({
                    property: dataSet[0]?.id
                })
            }
        },},
        pagination:{
            pageSize:1000
        }
    });

    const { selectProps: selUnit } = useSelect<IUnit>({
        resource: "units",
        fetchSize: 200,
        metaData: {
            populate: '*'
        },
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "in",
                value       : vProp
            },
        ]
    });

    const [ StaffOption, setStaffOption ] = useState<any>(undefined);
    const [ count, setCount] = useState(0);

    const { selectProps: selStaff } = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile.first_name",
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            // {
            //     field: "property",
            //     operator: "null",
            //     value: false
            // },
            {
                field : "property][id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "user-type-mappings",
        metaData: {
            populate: [
                '*',
                'user_profile',
                'property'
            ]
        },
        pagination:{
            pageSize: 1000,
        },
        permanentFilter: [
            {
                field : "property][id]",
                operator : vProp ? "eq" : "nin",
                value : vProp ? vProp : null
            },
            {
                field: "status",
                operator: "eq",
                value: true
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            // console.log('data xxxx : ', data.data)
            let dataLoad: any = data?.data?.filter((f: any) => f?.user_profile !== null);

            let newData: any = [];

            // console.log(">>> dataLoad", dataLoad)

            for (let i = 0; i < dataLoad?.length; i++) {
                newData?.push(
                    {
                        "label":  dataLoad[i]?.user_profile?.first_name + " " + dataLoad[i]?.user_profile?.last_name,
                        "value": dataLoad[i]?.user_profile?.id
                    }
                )
            }

            if(newData?.length > 0){
                staffdata = newData;
            }

            // let data_staff = data.data?.map((data:any) => ({ label: data?.user_profile?.first_name + " " + data?.user_profile?.last_name , value: data?.user_profile?.id}))
            // const uniqueArray = data_staff.filter((item, index, array) => array.indexOf(item) === index);
            // // console.log('uniqueArray',uniqueArray)
            // console.log(">>> uniqueArray", uniqueArray)
            // setStaffOption(uniqueArray)


          },
        //   refetchInterval:2000,
        },
    });

    // console.log('StaffOption', StaffOption)
    // useEffect(() => {
    //     if (count < 3) { 
    //         const interval = setInterval(() => {
    //             setCount(prev => prev + 1)
    //             // setStaffOption(selStaff?.options);
    //             refetch();
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count])

    const optionsRadio = [
        { label: t("appointm-create.registered-cus"), value: true },
        { label: t("appointm-create.un-regis-cus"), value: false },
    ];

    const handleDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setDate(dateString);
        // SEND TO API FOR CAL TIMESLOT
        callAPI(dateString);
        // http://192.168.100.151:1337/api/appointment-time-slots/slot_chk/?date=2022-06-30
        filterTimeSlot(dateString);
    };

    const handleNewDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setDaterec(dayjs(dateString).format("YYYY-MM-DD"));
        // SEND TO API FOR CAL TIMESLOT
        callAPI(dateString);
    };

    async function callAPI(v:any){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let resultQuery1 :any = await axios.get(API_URL + "/appointment-time-slots/slot_chk/?date=" + v , { headers: { Authorization: AuthStr } });

        let arrayID :any = resultQuery1?.data?.find_master_dateto?.map((item:any) => {
            return item.id
        })
    } 
    
    async function filterTimeSlot(dateString:any){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let time_slot_choice = await axios.get(API_URL + "/appointment-time-slots?date=" + dateString , { headers: { Authorization: AuthStr } });
        setApptimeslot(time_slot_choice?.data?.data);
    }

    function filterSubType(id:any){
        var result = appointty_data.filter((obj: { id: any; }) => {
            return obj.id === id
        })

        let dataLoad:any = result ? result : [];

        if(vSubtypeID?.length > 0){
            vSubtypeID.pop()
            setSubtypeID([])
        }
        
        if(dataLoad[0]?.user_sub_types?.length > 0){
            for(let i=0; i<= dataLoad[0]?.user_sub_types?.length -1; i++){
                vSubtypeID.push(dataLoad[0]?.user_sub_types[i]?.id)
                setSubtypeID(vSubtypeID)
            }
        }
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IUnit>({
        mapData: (item) => {

            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                        
                };
            }
        },
    });

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(),
                log_event       : event,
                menu_event      : 'appointments',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }
    
    function onStatusChange(v:any){
        if(v == "Y"){
            setdeleteStatus("Y")
            setappointmentType(null)
        }else{
            setdeleteStatus("N")
            setappointmentType(v)
        }
        searchFormProps.form?.submit();
        localStorage.setItem("keyTabAppointment",v)
        setSelectedTab(v)
    }

    function onselcus(value:any){
        if(userhunit_data?.length > 0){
            setCustomerID(userhunit_data[0]?.user_profile?.id)
            setCustomerName(userhunit_data[0]?.user_profile?.first_name);
            setCustomerLastName(userhunit_data[0]?.user_profile?.last_name);
            setCustomerPhone(userhunit_data[0]?.user_profile?.tel);
            setCustomerEmail(userhunit_data[0]?.user_profile?.user?.email);
        }
    }

    function clearcus(){
        setCustomerID("")
        setCustomerName("");
        setCustomerLastName("");
        setCustomerPhone("");
        setCustomerEmail("");
    }

    var findPending = dataAppnt?.data.filter(function (el:any) {
        return el.appointment_status == "Pending" ;
    });

    var appntVisitor = findPending?.filter(function (el:any) { return el.appointmentType.id == 1 });    
    var appntSign = findPending?.filter(function (el:any) { return el.appointmentType.id == 2 });
    var appntReceivePaperJuristic = findPending?.filter(function (el:any) { return el.appointmentType.id == 3 });
    var appntUnitInspec = findPending?.filter(function (el:any) { return el.appointmentType.id == 4 });
    var appntUnitTransfer = findPending?.filter(function (el:any) { return el.appointmentType.id == 5 });

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose
    } = useModalForm<any>({
        resource: "appointments",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false,
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "appointments",
        metaData: {
            populate: ["appointmentType","appointment_time_slot","property","staff_has_properties","unit","user_profile","user_profile.user","reschedule_time_slot"],
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data: any) {
            let dataLoad = data?.data;

            setDetail(dataLoad);

            setApptype({id: dataLoad?.appointmentType?.id, label:dataLoad?.appointmentType?.name});
            setAppstatus(dataLoad?.appointment_status);
            filterSubType(dataLoad?.appointmentType?.id);
            setProp(dataLoad?.property?.id);
            setSelreg(dataLoad?.user_profile !== null ? true : false);
            setUnit(dataLoad?.unit?.id)

            setCustomerID(dataLoad?.user_profile?.id)
            setCustomerName(dataLoad?.user_profile !== null ? dataLoad?.user_profile?.first_name : dataLoad?.customerFirstName);
            setCustomerLastName(dataLoad?.user_profile !== null ? dataLoad?.user_profile?.last_name : dataLoad?.customerLastName);
            setCustomerPhone(dataLoad?.user_profile !== null ? dataLoad?.user_profile?.tel : dataLoad?.customerPhoneNumber);
            setCustomerEmail(dataLoad?.user_profile !== null ? dataLoad?.user_profile?.user?.email  : dataLoad?.customerEmail);
            setConfirm(dataLoad?.isConfirmed);
            setDate(dataLoad?.appointmentDate);
            setDaterec(dataLoad?.rescheduleDate);
            setTslot1(dataLoad?.appointment_time_slot ? dataLoad?.appointment_time_slot?.id : null);
            setTslot2(dataLoad?.reschedule_time_slot ? dataLoad?.reschedule_time_slot?.id : null);
            setStaffhprop(dataLoad?.staff_has_properties ? dataLoad?.staff_has_properties[0]?.id : null);
            filterTimeSlot(dataLoad?.appointmentDate);

            editProps?.form?.setFieldsValue({
                // customerFirstName: dataLoad?.user_profile ? dataLoad?.user_profile?.first_name + " " + dataLoad?.user_profile?.last_name : null,
                // customerFirstName: dataLoad?.user_profile ? dataLoad?.user_profile?.first_name : null,
                // customerLastName: dataLoad?.user_profile ? dataLoad?.user_profile?.last_name : null,
                staff_has_properties: dataLoad?.staff_has_properties ? dataLoad?.staff_has_properties[0]?.id : null,
                appointment_time_slot: dataLoad?.appointment_time_slot ? dataLoad?.appointment_time_slot?.slot_name : null,
                reschedule_time_slot: dataLoad?.reschedule_time_slot ? dataLoad?.reschedule_time_slot?.slot_name : null,
            })
        },}
    });

    const onReset = () => {
        setProp(undefined)
        setFirstName(undefined)
        setLastName(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    }

    return <>
        <List 
            title={t("appointm.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("appoint.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("appointm-type.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >

            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        <Form.Item name="property" >
                            <Select 
                                {...propertySelectProps}
                                placeholder="Select property" 
                                size="middle" 
                                style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                allowClear
                                onChange={(e : any) => setProp(e)}
                            />
                            {/* <Input placeholder="please enter" size="small" style={{width: '250px'}}/> */}
                        </Form.Item>

                        <Form.Item name="FirstName" >
                            <Input
                                onChange={(e) => setFirstName(e.target.value)}
                                prefix={<Icons.SearchOutlined />}
                                style={{borderRadius: 4}} 
                                placeholder="Search FirstName" size="middle" allowClear
                            />
                        </Form.Item>

                        <Form.Item name="LastName" >
                            <Input
                                onChange={(e) => setLastName(e.target.value)}
                                prefix={<Icons.SearchOutlined />}
                                style={{borderRadius: 4}} 
                                placeholder="Search LastName" size="middle" allowClear
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>

                    </Form>
                </Col>

                <Col span={6}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right"}}
                    >
                        <Form.Item>
                            <ExportButton style={{borderRadius:5}}
                                onClick={triggerExport}
                                loading={exportLoading}
                            />
                        </Form.Item>

                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true } onClick={() => createModalShow()}/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Form {...searchFormProps}>
                <Form.Item name="appointmentType" >
                    <Tabs 
                        // defaultActiveKey={tabActive? String(tabActive) : "1"}
                        style={{ marginTop: 10,marginBottom: 10 }} 
                        onChange={onStatusChange}
                        // accessKey="3"
                        activeKey={selectedTab ? selectedTab : "1"}
                    >
                        {
                            dataappPost?.data.map((item:any,key:any) => {
                                return <>
                                    {item.status !== null? 
                                    <>
                                        <TabPane 
                                            key={item.id}  
                                            tab={
                                                <span>
                                                    {item.name}  
                                                    <Badge 
                                                        count={
                                                            item.id == 1 ?
                                                                appntVisitor !== undefined ? appntVisitor.length : 0
                                                            : item.id == 2 ?
                                                                appntSign !== undefined ? appntSign.length : 0
                                                            : item.id == 3 ?
                                                                appntReceivePaperJuristic !== undefined ? appntReceivePaperJuristic.length : 0
                                                            : item.id == 4 ?
                                                                appntUnitInspec !== undefined ? appntUnitInspec.length : 0
                                                            : item.id == 5 ?
                                                                appntUnitTransfer !== undefined ? appntUnitTransfer.length : 0
                                                            : 0
                                                        } 

                                                        style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}
                                                    />
                                                </span> 
                                            }
                                        />
                                    </>
                                    :
                                    <></>}
                                </>
                            })
                        }

                        {dataappPost !== undefined && dataappPost.data?.length > 0 &&
                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={dataCUSTOM?.data.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        }
                        
                    </Tabs>
                </Form.Item>
            </Form>

            <Table 
                {...tableProps} 
                bordered
                size="middle"
                rowKey      = "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    total:tableQueryResult?.data?.total,
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false
                }}  
            >

                {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                <Table.Column title={t("property.Ltitle")} align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.property?.property_name < b?.property?.property_name) {return -1}
                        if (a?.property?.property_name > b?.property?.property_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        // console.log("first",record)
                        return <>
                            {/* <TextField value={record ? record.property.property_name : "-"}  /> */}
                            <TextField value={record.property ? record?.property?.property_name : "-"}  />
                        </>
                    }}
                />
                
                <Table.Column title="FIRST NAME" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.customerFirstName < b?.customerFirstName) {return -1}
                        if (a?.customerFirstName > b?.customerFirstName) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record.customerFirstName !== null ? record.customerFirstName : '-'}  />
                        </>
                    }}
                />

                <Table.Column title="LAST NAME" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.customerLastName < b?.customerLastName) {return -1}
                        if (a?.customerLastName > b?.customerLastName) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record.customerLastName !== null ? record.customerLastName: '-'}  />
                        </>
                    }}
                />

                <Table.Column title="TEL" width={100} align="center"
                    sorter={(a:any, b:any) => {
                        if (a?.customerPhoneNumber < b?.customerPhoneNumber) {return -1}
                        if (a?.customerPhoneNumber > b?.customerPhoneNumber) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record.customerPhoneNumber !== null ? record.customerPhoneNumber: '-'}  />
                        </>
                    }}
                />

                <Table.Column title="TYPE" width={200} align="center"
                sorter={(a:any, b:any) => {
                    if (a?.appointmentType?.name < b?.appointmentType?.name) {return -1}
                    if (a?.appointmentType?.name > b?.appointmentType?.name) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.appointmentType ? record?.appointmentType.name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title="APPOINTMENT DATE" align="center" width={100}
                sorter={(a:any, b:any) => {
                    if (a?.appointmentDate < b?.appointmentDate) {return -1}
                    if (a?.appointmentDate > b?.appointmentDate) {return 1}
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <> 
                            {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                            {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                            {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                            {
                                record?.updatedAt ?
                                    <TextField value={Moment(record?.appointmentDate).format('D/MM/yyyy')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}              
                />
                
                    <Table.Column title="Time Slot" width={200} align="center"
                    sorter={(a:any, b:any) => {
                        if (a?.appointment_time_slot?.slot_name < b?.appointment_time_slotv?.slot_name) {return -1}
                        if (a?.appointment_time_slot?.slot_name > b?.appointment_time_slot?.slot_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.appointment_time_slot ? record?.appointment_time_slot.slot_name :<>-</>}  />
                        </>
                    }}
                />
                
                    <Table.Column title="Reschedule Time Slot" width={200} align="center"
                    sorter={(a:any, b:any) => {
                        if (a?.reschedule_time_slot?.slot_name < b?.reschedule_time_slot?.slot_name) {return -1}
                        if (a?.reschedule_time_slot?.slot_name > b?.reschedule_time_slot?.slot_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.reschedule_time_slot ? record?.reschedule_time_slot.slot_name :<>-</>}  />
                        </>
                    }}
                />

                <Table.Column dataIndex="appointment_status" title="STATUS" width={100} align="center"
                    sorter={(a:any, b:any) => {
                        if (a?.appointment_status < b?.appointment_status) {return -1}
                        if (a?.appointment_status > b?.appointment_status) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        let c = ""
                        c = (record.appointment_status === "Pending") ? 'orange' 
                            : (record.appointment_status === "Reschedule") ? 'yellow' 
                            : (record.appointment_status === "Approved") ? 'green'
                            : (record.appointment_status === "Cancelled") ? 'red' 
                            : 'grey'
                        return <>
                            {value ?
                                <Tag style={{borderRadius: "10px"}}
                                    color={c}
                                >
                                    {record.appointment_status}
                                </Tag> 
                            :<></>
                            }
                        </>
                    }}
                />
                    <Table.Column title="Confirmed" width={200} align="center"
                    sorter={(a:any, b:any) => {
                        if (a?.isConfirmed < b?.isConfirmed) {return -1}
                        if (a?.isConfirmed > b?.isConfirmed) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.isConfirmed == true? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Publish</Tag>:<Tag style={{borderRadius: "30px",color: "#fff",padding: "1% 7% 1%"}} color={"processing"}>Draft</Tag>}  />
                        </>
                    }}
                />

                <Table.Column title="CREATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                        if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.createBy ? <>{record?.createBy.first_name}{' '}{record?.createBy.last_name}</> : "-"} />
                        </>
                    }}
                />

                <Table.Column title="CREATE DATE" align="center" width={100}
                   sorter={(a:any, b:any) => {
                        if (a?.createdAt < b?.createdAt) {return -1}
                        if (a?.createdAt > b?.createdAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.createdAt ?
                                    <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                    
                />

                <Table.Column title={t("Lupdateby")} align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                        if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {/* <TextField style={{fontWeight: 600}} value={record?.updatedBy? record?.updatedBy.first_name :"-"}  /> */}
                            <TextField value={record?.updateBy ? <>{record?.updateBy.first_name}{' '}{record?.updateBy.last_name}</> : "-"} />
                        </>
                    }}
                />

                <Table.Column title="LAST UPDATE" align="center" width={100}
                   sorter={(a:any, b:any) => {
                        if (a?.updatedAt < b?.updatedAt) {return -1}
                        if (a?.updatedAt > b?.updatedAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                            {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                            {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                            {
                                record?.updatedAt ?
                                    <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                    
                />
                
                <Table.Column<IUnit>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed = "right"
                    render={(_, record) => (
                        <Space>

                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}

                            >รายละเอียด</ShowButton>

                            {/* <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />

                            <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}

                            {
                                record.deleteStatus === "N" ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            onClick={() => editModalShow(record.id)}
                                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        />
                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                                :
                                    <>
                                        {/* <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            disabled
                                        /> */}
                                        <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                            }

                        </Space>
                    )}
                />
            </Table>
        </List>

        <Modal 
            {...createModalProps}
            title={<div>
                <span>Appointments Create</span>
                <Switch
                    style={{float: "right",marginRight: "1.5rem"}}
                    checkedChildren="Publish"
                    unCheckedChildren="Draft"
                    checked={vConfirm}
                    onChange={(e) => setConfirm(e)}
                />
            </div>}
            okText = {"Create"}
            maskClosable={false}
            width={500}
            afterClose={()=> {
                setConfirm(false)
                createProps?.form?.resetFields();
            }}
        >
            <AppointmCreate
                createProps={createProps}
                vConfirm={vConfirm}
            />
        </Modal>

        <Modal 
            {...editModalProps}
            title={<div>
                <span>Appointments Edit</span>
                <Switch
                    style={{float: "right",marginRight: "1.5rem"}}
                    checkedChildren="Publish"
                    unCheckedChildren="Draft"
                    checked={vConfirm}
                    onChange={(e) => setConfirm(e)}
                />
            </div>}
            okText = {"Save"}
            maskClosable={false}
            width={500}
            afterClose={()=> {
                setDate("");
                setDaterec("");
                setTslot1("");
                setTslot2("")
                setAppstatus("");
                setProp(undefined);
                setApptype(undefined);
                setUnit(undefined);
                setSelreg(false);
                setConfirm(false);
                setSubtypeID([]);
                clearcus();
                editProps?.form?.resetFields();
            }}
        >
            {isDetail &&
                <AppointmEdit 
                    editProps={editProps}
                    vConfirm={vConfirm}
                    recordData={isDetail}
                    isLoading={isLoadingEdit}
                    setisLoading={setLoadingEdit}
                />
            }
        </Modal>
        </>
}