/* eslint-disable */
import { Avatar, Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, Input, List, Modal,  Row,  Space, Table, Tag, TextField, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IFacilityCondition  } from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import { LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export const FacilitiesCondiList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility Condition");
    const { tableProps,searchFormProps,tableQueryResult } = useTable<IFacilityCondition,HttpError, IFacilityCondition>({
        metaData: {
            populate: '*'
        },  
    // });
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { code } = params;

                filters.push(
                    {
                        field       : "description",
                        operator    : "contains",
                        value       : code
                    },

                );

            return filters;
        },
        permanentSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
    });

    const categoryQueryResult = useOne<IFacilityCondition>({
        resource: "facility-conditions",
        id: ''
    });
    
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];

    // console.log(paginationLength);

    const { confirm }       = Modal;
    const { mutate }        = useUpdate<IFacilityCondition>();
    const ed_point          = "facility-conditions";
    const [page, setPage]   = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState     = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
            // console.log('onShowSizeChange : ', current, pageSize);
            setPageSizeChange(pageSize)
    }

    // const { triggerExport, isLoading: exportLoading } = useExport<IFacilityCondition>({
    //     mapData: (item) => {
    //         //console.log(item)
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id, 
                        
    //             };
    //         }
    //     },
    // });
    
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            // values      : vCurDelStatus,
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
            },successNotification:false
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'facility-conditions',
                SysPkID         : id+""
            },successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const onReset = () => {
       
        searchFormProps?.form?.resetFields();
        searchFormProps?.form?.submit();
    }

    return <>
        <List 
            title={t("facility-condition.list")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("facility-condition.list")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >

            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        <Form.Item name="code">
                            <Input style={{border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search Condition" prefix={<Icons.SearchOutlined />} allowClear/>
                        </Form.Item>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>

                <Col span={6}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right"}}
                    >
                        {/* <Form.Item>
                            <ExportButton style={{borderRadius:5}}
                                onClick={triggerExport}
                                loading={exportLoading}
                            />
                        </Form.Item> */}

                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Table {...tableProps} 
                bordered
                size = "middle"
                rowKey = "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    total:tableQueryResult?.data?.total, 
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false
                }}  
            >
                {/* <Table.Column width={40} title={t("facility-condition.Lno")}  key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}
                {/* <Table.Column dataIndex="code" title={t("fclt-type.Lcode")}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={value}  />
                        </>
                    }}
                /> */}

                <Table.Column align="center" width={80} dataIndex="condition_pix" title="  " 
                    render={(value:any,record:any)=>{
                        return <>
                                {
                                    value? 
                                        <Avatar size={32} src={value.url} />
                                    :<>
                                        <Avatar size={32} icon={<Icons.EnvironmentOutlined />} />
                                    </>
                                }
                            </>
                    }}
                />
                

                <Table.Column dataIndex="description" title={t("facility-condition.Ldesc")} 
                    sorter={(a:any, b:any) => {
                        if (a?.description < b?.description) {return -1}
                        if (a?.description > b?.description) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={value}  />
                        </>
                    }}
                />

                <Table.Column dataIndex="deleteStatus" title={t("facility-condition.status")}  align="center"
                    sorter={(a:any, b:any) => {
                        if (a?.deleteStatus < b?.deleteStatus) {return -1}
                        if (a?.deleteStatus > b?.deleteStatus) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {/* <TextField value={value}  /> */}
                            <TextField 
                                style={{fontWeight: 600}} 
                                value={
                                    value === "Y" ? 
                                        <Tag style={{borderRadius: "30px",color: "#B8B8B8",padding: "1% 7% 1%"}} color={"#F1F1F1"}>Inactive</Tag>
                                    :
                                        <><Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#00BB66"}>Active</Tag> </>
                                    }
                            />
                        </>
                    }}
                />

                <Table.Column title="CREATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                        if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title="CREATE DATE" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.createdAt < b?.createdAt) {return -1}
                        if (a?.createdAt > b?.createdAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record?.createdAt ?
                                    <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                    
                />

                <Table.Column title="UPDATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                        if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title="LAST UPDATE" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.updatedAt < b?.updatedAt) {return -1}
                        if (a?.updatedAt > b?.updatedAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                            {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                            {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                            {
                                record?.updatedAt ?
                                    <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                :
                                    <TextField value={'-'}  />
                            }
                        </>
                    }}
                    
                />

                {/* <Table.Column dataIndex="status" title="CONTENT STATUS" width={150}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField 
                                style={{fontWeight: 600}} 
                                value={
                                    value =="Draft" ? 
                                        <Tag style={{borderRadius: "30px",color: "#fff",padding: "1% 7% 1%"}} color={"processing"}>Inactive</Tag>
                                    :
                                        <><Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Active</Tag> </>
                                    }
                            />
                        </>
                    }}
                /> */}
                
                <Table.Column<IFacilityCondition>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed = "right"
                    render={(_, record) => (
                        <Space>
                            {/* <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            >รายละเอียด</ShowButton> */}

                            {/* <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />

                            <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}
                            {
                                record.deleteStatus === "N" ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        />
                                        <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                                :
                                    <>
                                        {/* <EditButton
                                            hideText
                                            size="small"
                                            recordItemId={record.id}
                                            disabled
                                        /> */}
                                        <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                            }
                        </Space>
                    )}
                />
            </Table>
        </List>
    </>
}