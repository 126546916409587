/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, consoleLog } from "../../../constants";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import axios from "axios";
import { useLocation } from "react-router-dom";


export const HcuNotiMsgEdit: React.FC<IResourceComponentsProps> = () => {
    
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const API_URL = useApiUrl();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [res_data, set_res_data] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);

    const [form] = Form.useForm()

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let property_id = id[0]
    let setting_id = id[1]
    // console.log('property_id', property_id)
    // console.log('setting_id', setting_id)

    const getDataK = async ()=>{
        // http://192.168.100.129:1337/api/home-check-up-settings/find_setting_by_id?property=2&type=Notification&get_all_setting=false&setting_id=10
        // let res_data = await axios.get(API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=Notification&get_all_setting=false&setting_id=${setting_id}`, { headers: { Authorization: AuthStr } });
        let res_data = await axios.get(
                API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=Notification&get_all_setting=false&setting_id=${setting_id}`, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    console.log(' getDataK res data', res?.data)
                    set_res_data(res?.data)
                    // SetPropertyName(res?.data.property.property_name)
                    SetProperty(res?.data.property.id)
                });
    }

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { TextArea } = Input;
    const { Text } = Typography;
    const format = 'HH:mm';

    const [dateString, setdateString] = useState("00:00:00")

    const t = useTranslate();
    const { Option } = Select;
    const log_endpoint = "even-logs";
    const { replace, list} = useNavigation();
    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    const [textcount, settextcount] = useState('');
    const [swlocal, setswlocal] = useState('th');
    const [checkedInApp, setcheckedInApp] = useState<boolean>(false);
    const [checkedSms, setcheckedSms] = useState<boolean>(false);
    const [checkedEmail, setcheckedEmail] = useState<boolean>(false);

    const [msg_inapp_th, setmsg_inapp_th] = useState<any>("");
    const [msg_sms_th, setmsg_sms_th] = useState<any>("");
    const [msg_email_th, setmsg_email_th] = useState<any>("");

    const [msg_inapp_en, setmsg_inapp_en] = useState<any>("");
    const [msg_sms_en, setmsg_sms_en] = useState<any>("");
    const [msg_email_en, setmsg_email_en] = useState<any>("");

    const [time_inapp, settime_inapp] = useState<any>();
    const [time_sms, settime_sms] = useState<any>();
    const [time_email, settime_email] = useState<any>();

    const [checked2lan, setchecked2lan] = useState<boolean>(false)
    const [roundType, setRoundType] = useState<any>(1)

    

    // const { form, formProps, saveButtonProps } = useForm<IFacilityBooking,HttpError,{}>({
    //     onMutationSuccess(data, variables, context) {
    //         let recordData:any = []
    //         recordData = data?.data? data?.data : [];
    //         CreateLog({
    //             resource : log_endpoint,
    //             values : {
    //                 user_profile            : user_data.user_profile.id,
    //                 log_time_stamp          : Date.now(),
    //                 log_event               : "Create",
    //                 menu_event              : 'hcu-settings',
    //                 SysPkID                 : recordData.data.id + ""
    //             },
    //             successNotification : false
    //         });
    //     },
    //     successNotification:false,
    //     errorNotification(error:any, values:any, resource:any) {
    //         return {
    //             message: error.toString(),
    //             type: "error" ,
    //             undoableTimeout: 1000
    //         }
    //     },
    //     onMutationError(error, variables, context) {
    //         console.log('error', error)
    //         console.log('variables', variables)
    //         console.log('context', context)
    //     },
    // });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        // filters: [
        //     {
        //         field: "deleteStatus",
        //         operator: "eq",
        //         value: "N"
        //     },
        //     {
        //         field : "id]",
        //         operator : data_property !== null ? "eq" : "ne",
        //         value : data_property !== null ? data_property : null
        //     },
        // ]
    });

    const error = () => {
        Modal.error({
          title: <span>Please Check at least 1 Choice</span>,
          content: <span></span>,
        });
    };

    const optionsRdioLocale = [
        { label: 'TH', value: 'th' },
        { label: 'EN', value: 'en' },
    ];

    const handleChange = (e:any) => {
        settextcount(e.target.value);
    };

    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setswlocal(value);
    };

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                        method: "post",
                        body: body
                        // mode: "no-cors"
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                            default: res[0].url
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    async function createNoti(values:any) {
        // console.log(">>> values",values)
        // console.log('time_inapp', dayjs(time_inapp).format("HH:mm:ss.sss"))
        // console.log('time_sms', time_sms)
        // console.log('time_email', time_email)

        console.log('msg_inapp_en', msg_inapp_en)
        console.log('msg_sms_en', msg_sms_en)
        console.log('msg_email_en', msg_email_en)

        let data = { 
            "data":{
                // "property": values?.property?.id,
                "property": property_id,
                "hcu_setting": [
                    {
                        "id": Number(setting_id),
                        // "home_check_up_type": {"name" : "Notification"},
                        "home_check_up_type": 1, // 1 = Notification
                        // "seq": Number(values?.type?.value),
                        "seq": Number(roundType),
                        // "date": 1,
                        "inApp": checkedInApp,
                        "sms": checkedSms,
                        "email": checkedEmail,
                        "inApp_th": msg_inapp_th,
                        "inApp_en": msg_inapp_en,
                        "sms_th": msg_sms_th,
                        "sms_en": msg_sms_en,
                        "email_th": msg_email_th,
                        "email_en": msg_email_en,
                        "time_inApp": time_inapp ? dayjs(time_inapp).format("HH:mm:ss.sss") : null,
                        "time_sms": time_sms ? dayjs(time_sms).format("HH:mm:ss.sss") : null,
                        "time_email": time_email ? dayjs(time_email).format("HH:mm:ss.sss") : null,
                        "updateBy" : Identity_data ? Identity_data.user_profile.id : null

                    },
                ]
            }
        }

        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        // console.log('res', res)

        successNoti('200', 'success');

        replace("/majorcare/setup-hcu")
    }

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });

        notification['success']({
            message: "Successful",
            description:
              'Successfully edit notification',
        });
    };

    useEffect(() => {
        if (countInterval < 3) {
        // if (countInterval < 7) {
          const interval = setInterval(() => {
            setCountInterval(prev => prev + 1)
            getDataK();
            console.log('res_data', res_data);
            // console.log('property', Property);

            // set checkbox
            setcheckedInApp(res_data? res_data.hcu_setting[0].inApp ? true : false : false)
            setcheckedSms(res_data? res_data.hcu_setting[0].sms ? true : false : false)
            setcheckedEmail(res_data? res_data.hcu_setting[0].email ? true : false : false)

            // set text in textbox
            setmsg_inapp_th(res_data? res_data.hcu_setting[0].inApp_th !== null ? res_data.hcu_setting[0].inApp_th : "" : "")
            setmsg_inapp_en(res_data? res_data.hcu_setting[0].inApp_en !== null ? res_data.hcu_setting[0].inApp_en : "" : "")

            setmsg_sms_th(res_data? res_data.hcu_setting[0].sms_th !== null ? res_data.hcu_setting[0].sms_th : "" : "")
            setmsg_sms_en(res_data? res_data.hcu_setting[0].sms_en !== null ? res_data.hcu_setting[0].sms_en : "" : "")

            setmsg_email_th(res_data? res_data.hcu_setting[0].email_th !== null ? res_data.hcu_setting[0].email_th : "" : "")
            setmsg_email_en(res_data? res_data.hcu_setting[0].email_en !== null ? res_data.hcu_setting[0].email_en : "" : "")

            // set time to timepicker
            settime_inapp(res_data? res_data.hcu_setting[0].time_inApp !== null ? dayjs(res_data.hcu_setting[0].time_inApp, "HH:mm") : null : null)
            settime_sms(res_data? res_data.hcu_setting[0].time_sms !== null ? dayjs(res_data.hcu_setting[0].time_sms, "HH:mm") : null : null)
            settime_email(res_data? res_data.hcu_setting[0].time_email !== null ? dayjs(res_data.hcu_setting[0].time_email, "HH:mm") : null : null)

            // set round type
            setRoundType(res_data? res_data.hcu_setting[0].seq !== null ? res_data.hcu_setting[0].seq : 0 : 0)
          }, 1000)
          return () => clearInterval(interval)
        }
    }, [countInterval])

    // useEffect(() => {
    //     getDataK();
    //         console.log('res_data', res_data);
    //         // console.log('property', Property);

    //         // set checkbox
    //         setcheckedInApp(res_data? res_data.hcu_setting[0].inApp ? true : false : false)
    //         setcheckedSms(res_data? res_data.hcu_setting[0].sms ? true : false : false)
    //         setcheckedEmail(res_data? res_data.hcu_setting[0].email ? true : false : false)

    //         // set text in textbox
    //         setmsg_inapp_th(res_data? res_data.hcu_setting[0].inApp_th !== null ? res_data.hcu_setting[0].inApp_th : "" : "")
    //         setmsg_inapp_en(res_data? res_data.hcu_setting[0].inApp_en !== null ? res_data.hcu_setting[0].inApp_en : "" : "")

    //         setmsg_sms_th(res_data? res_data.hcu_setting[0].sms_th !== null ? res_data.hcu_setting[0].sms_th : "" : "")
    //         setmsg_sms_en(res_data? res_data.hcu_setting[0].sms_en !== null ? res_data.hcu_setting[0].sms_en : "" : "")

    //         setmsg_email_th(res_data? res_data.hcu_setting[0].email_th !== null ? res_data.hcu_setting[0].email_th : "" : "")
    //         setmsg_email_en(res_data? res_data.hcu_setting[0].email_en !== null ? res_data.hcu_setting[0].email_en : "" : "")

    //         // set time to timepicker
    //         settime_inapp(res_data? res_data.hcu_setting[0].time_inApp !== null ? dayjs(res_data.hcu_setting[0].time_inApp, "HH:mm") : null : null)
    //         settime_sms(res_data? res_data.hcu_setting[0].time_sms !== null ? dayjs(res_data.hcu_setting[0].time_sms, "HH:mm") : null : null)
    //         settime_email(res_data? res_data.hcu_setting[0].time_email !== null ? dayjs(res_data.hcu_setting[0].time_email, "HH:mm") : null : null)

    //         // set round type
    //         setRoundType(res_data? res_data.hcu_setting[0].seq !== null ? res_data.hcu_setting[0].seq : 0 : 0)
    // }, []);

    // console.log('swlocal', swlocal)
    // console.log('!checkedInApp', checkedInApp)
    return (
    <Edit saveButtonProps={{ hidden: true }} pageHeaderProps={{extra: <></>}}>
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-noti.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                   
                    createNoti(values)

                    // if(checked2lan == true){
                    //     createNoti(values)
                    // }else if(checked2lan == false && (checkedInApp == true || checkedSms == true || checkedEmail == true)){
                    //     setswlocal('en')
                    //     setchecked2lan(true)
                    // }else if(checkedInApp == false && checkedSms == false && checkedEmail == false){
                    //     error();
                    // }

                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={6} className="gutter-row" style={{borderRight: "1px solid #dedede",paddingTop: "2.5rem"}}>
                            <Form.Item
                                // name={["property", "id"]}
                                // name="property"
                                label="Property"
                                // rules={[
                                //     {
                                //         required: true, message: "Please select property"
                                //     },
                                // ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty} 
                                    // value={res_data? res_data?.property?.property_name : "xxxx"} 
                                    defaultValue={Property} 
                                    value={Property} 
                                    disabled
                                />
                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                label="Type"
                                // rules={[
                                //     {
                                //         required: true, message: "Please select type"
                                //     },
                                // ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    defaultValue={roundType.toString()}
                                    value={roundType.toString()}
                                    onSelect={(value: any) => {
                                        console.log(value);
                                        setRoundType(value)
                                    }}
                                    disabled
                                >
                                    <Option value="1">{"แจ้งเตือนรอบที่ 1"}</Option>
                                    <Option value="2">{"แจ้งเตือนรอบที่ 2"}</Option>
                                    {/* <Option value="POPUP_1">{"แจ้งเตือนรอดำเนินการติดต่อกลับ"}</Option> */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18} className="gutter-row" style={{paddingTop: "2.5rem"}}>
                            <Row gutter={82}>
                                <Col span={24} className="gutter-row">
                                    <div style={{marginBottom: "3rem"}}>
                                        <span style={{marginRight: "1rem",fontSize: "13.44px",color: "#000"}}>Language</span>
                                        <Radio.Group 
                                            options={optionsRdioLocale} 
                                            onChange={onChangeLocale} 
                                            value={swlocal} 
                                            optionType="button" 
                                            // disabled={true} 
                                            className="custom_disable_radio_i"
                                        />
                                    </div>
                                </Col>


                                <Col span={12} className="gutter-row">
                                    <Checkbox 
                                        onChange={(e) => setcheckedInApp(e?.target?.checked)} 
                                        style={{marginBottom: "1.5rem"}}
                                        // checked={res_data? res_data.hcu_setting[0].inApp ? true : false : false}
                                        checked={checkedInApp}
                                    >
                                        <span style={{fontWeight: 500,fontSize: "18px",color: "#000"}}>In app</span>
                                    </Checkbox>
                                    <div className="inapp_panel">
                                        {swlocal == 'th' ?
                                            <Form.Item
                                                // name="msg_inapp_th"
                                                label="Messages"
                                                rules={[
                                                    {
                                                        required: checkedInApp ? true : false, message: "Please input description"
                                                    },
                                                ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}
                                                    // defaultValue={vShortDetails}
                                                    onChange={(e) => setmsg_inapp_th(e?.target?.value)} 
                                                    disabled={!checkedInApp ? true : false}
                                                    value={msg_inapp_th}
                                                    defaultValue={msg_inapp_th}
                                                />
                                            </Form.Item>
                                        :swlocal == 'en' &&
                                            <Form.Item
                                                // name="msg_inapp_en"
                                                label="Messages"
                                                rules={[
                                                    {
                                                        required: checkedInApp ? true : false, message: "Please input description"
                                                    },
                                                ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}
                                                    onChange={(e) => setmsg_inapp_en(e?.target?.value)} 
                                                    disabled={!checkedInApp ? true : false}
                                                    value={msg_inapp_en}
                                                    defaultValue={msg_inapp_en}
                                                />
                                            </Form.Item>
                                        }


                                        {
                                            swlocal === 'en' ?
                                                <></>
                                            :
                                                <>
                                                    <Form.Item 
                                                        // name="time_inApp" 
                                                        className="disable_req" 
                                                        rules={[{required: checkedInApp ? true : false,message: "select time"}]}
                                                    >
                                                        <TimePicker 
                                                            placeholder="Time" 
                                                            format={format}
                                                            // onChange={(date, dateString) => settime_inapp(dateString + ":00.000")}
                                                            onChange={(date, dateString) =>settime_inapp(dayjs(dateString+ ":00.000", "HH:mm"))}
                                                            
                                                            disabled={!checkedInApp ? true : false}
                                                            // disabled={!checkedInApp && swlocal !== 'th' ? true : false}
                                                            // disabled={swlocal === 'en' ? true : false}
                                                            // defaultValue={time_inapp}
                                                            value={time_inapp}
                                                        />
                                                    </Form.Item>
                                                </>
                                        }
                                        
                                    </div>
                                </Col>

                                
                                <Col span={12} className="gutter-row">
                                    <Checkbox 
                                        onChange={(e) => setcheckedSms(e?.target?.checked)} 
                                        style={{marginBottom: "1.5rem"}}
                                        checked={checkedSms}
                                    >
                                        <span style={{fontWeight: 500,fontSize: "18px",color: "#000"}}>SMS</span>
                                    </Checkbox>
                                    <div className="sms_panel">
                                        {swlocal == 'th' ?
                                            <Form.Item
                                                // name="msg_sms_th"
                                                label="Messages"
                                                rules={[
                                                    {
                                                        required: checkedSms ? true : false, message: "Please input description"
                                                    },
                                                ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}
                                                    maxLength={70}
                                                    onChange={(e) => {
                                                        setmsg_sms_th(e?.target?.value)
                                                        handleChange(e)
                                                    }} 
                                                    disabled={!checkedSms ? true : false}
                                                    value={msg_sms_th}
                                                    defaultValue={msg_sms_th}
                                                />
                                                <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount?.length} / 70`}</Text>
                                            </Form.Item>
                                        :swlocal == 'en' &&
                                            <Form.Item
                                                // name="msg_sms_en"
                                                label="Messages"
                                                rules={[
                                                    {
                                                        required: checkedSms ? true : false, message: "Please input description"
                                                    },
                                                ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}  
                                                    maxLength={70}
                                                    onChange={(e) => {
                                                        setmsg_sms_en(e?.target?.value)
                                                        handleChange(e)
                                                    }}
                                                    disabled={!checkedSms ? true : false}
                                                    value={msg_sms_en}
                                                    defaultValue={msg_sms_en}
                                                />
                                                <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount?.length} / 70`}</Text>
                                            </Form.Item>
                                        }

                                        {
                                            swlocal === 'en' ?
                                                <></>
                                            :
                                                <>
                                                    <Form.Item 
                                                        // name="time_sms" 
                                                        className="disable_req" 
                                                        rules={[{required: checkedSms ? true : false,message: "select time"}]}
                                                    >
                                                        <TimePicker
                                                            placeholder="Time" 
                                                            format={format}
                                                            // onChange={(date, dateString) => settime_sms(dateString + ":00.000")}
                                                            onChange={(date, dateString) =>settime_sms(dayjs(dateString+ ":00.000", "HH:mm"))}
                                                            disabled={!checkedSms ? true : false}
                                                            // disabled={swlocal === 'en' ? true : false}
                                                            defaultValue={time_sms}
                                                            value={time_sms}
                                                        />
                                                    </Form.Item>
                                                </>

                                        }
                                    </div>
                                </Col>
                                <Col span={24} className="gutter-row" style={{marginTop: "3rem"}}>
                                    <Checkbox 
                                        onChange={(e) => setcheckedEmail(e?.target?.checked)} 
                                        style={{marginBottom: "1.5rem"}}
                                        checked={checkedEmail}
                                    >
                                        <span style={{fontWeight: 500,fontSize: "18px",color: "#000"}}>Email</span>
                                    </Checkbox> 
                                    <div className="email_panel">
                                        {swlocal == 'th' ?
                                            <Form.Item label="Messages" name="msg_email_th" rules={[{required: checkedEmail ? true : false, message: "Please input message"}]}>
                                                <CKEditor
                                                    data={msg_email_th ? msg_email_th : ''}
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "link",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "blockQuote",
                                                            "|",
                                                            "imageTextAlternative",
                                                            "imageUpload",
                                                            "imageStyle:full",
                                                            "imageStyle:side",
                                                        ],
                                                        extraPlugins: [uploadPlugin],
                                                        mediaEmbed: {
                                                            // configuration...
                                                            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                        },
                                                        image: {
                                                            toolbar: ['']
                                                        }
                                                    }}
                                                    
                                                    onInit={(editor:any) => {
                                                    }}
                                                    onChange={(event:any, editor:any) => {
                                                        const data = editor.getData();
                                                        setmsg_email_th(data);
                                                    }}
                                                    onBlur={(editor:any) => {
                                                        console.log("Blur.", editor);
                                                    }}
                                                    onFocus={(editor:any) => {
                                                        console.log("Focus.", editor);
                                                    }}
                                                    disabled={!checkedEmail ? true : false}
                                                />
                                            </Form.Item>
                                        :swlocal == 'en' &&
                                            <Form.Item label="Messages" name="msg_email_en" rules={[{required: checkedEmail ? true : false, message: "Please input message"}]}>
                                                <CKEditor
                                                    data={msg_email_en ? msg_email_en : ''}
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "link",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "blockQuote",
                                                            "|",
                                                            "imageTextAlternative",
                                                            "imageUpload",
                                                            "imageStyle:full",
                                                            "imageStyle:side",
                                                        ],
                                                        extraPlugins: [uploadPlugin],
                                                        mediaEmbed: {
                                                            // configuration...
                                                            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                        },
                                                        image: {
                                                            toolbar: ['']
                                                        }
                                                    }}
                                                    
                                                    onInit={(editor:any) => {
                                                    }}
                                                    onChange={(event:any, editor:any) => {
                                                        const data = editor.getData();
                                                        setmsg_email_en(data);
                                                    }}
                                                    onBlur={(editor:any) => {
                                                        console.log("Blur.", editor);
                                                    }}
                                                    onFocus={(editor:any) => {
                                                        console.log("Focus.", editor);
                                                    }}
                                                    disabled={!checkedEmail ? true : false}
                                                />
                                            </Form.Item>
                                        }

                                        {
                                            swlocal === 'en' ?
                                            <></>
                                            :
                                            <>
                                                <Form.Item 
                                                    // name="time_email" 
                                                    className="disable_req" 
                                                    rules={[{required: checkedEmail ? true : false,message: "select time"}]}
                                                >
                                                    <TimePicker
                                                        placeholder="Time"
                                                        format={format}
                                                        // onChange={(date, dateString) => settime_email(dateString + ":00.000")}
                                                        onChange={(date, dateString) =>settime_email(dayjs(dateString+ ":00.000", "HH:mm"))}
                                                        disabled={!checkedEmail ? true : false}
                                                        // disabled={swlocal === 'en' ? true : false}
                                                        defaultValue={time_email}
                                                        value={time_email}
                                                    />
                                                </Form.Item>
                                            </>
                                        }                                        
                                        
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        {/* <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button> */}
                        <Button 
                            type="primary" 
                            onClick={() => form.submit()} 
                            icon={<Icons.SaveOutlined />}
                        >Save</Button>
                    </Row>
                </div>
            </Form>
    </Edit>   
    );
};