/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, Divider, EditButton, RcFile, UploadFile, TextField, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY } from "../../../constants";
import { ExclamationCircleOutlined, SendOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import axios from "axios";
import { DatePicker, message, notification, Table, Tag, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { majorCareUrl } from "../../../constants";

export const RepairMdpcSendToCustApproveAdmin: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const format = 'YYYY-MM-DD HH:mm';
    const { Text } = Typography;


    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();

    const [ RemarkStat, SetRemarkStat] = useState<any>(undefined);
    const [textcount1, settextcount1] = useState('');


    const token = localStorage.getItem(TOKEN_KEY);
    const dataBlank:any = []

    let work_id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    // id = arr_id.split("_")
    work_id = arr_id.split("%2C")
    let item_id = work_id[0]
    let job_id = work_id[1]

    const id_to_query = work_id.join(',');


    // Data ส่วนของ รายละเอียดการแจ้ง
    const [InspectorProfile, SetInspectorProfile] = useState<any>();
    const [dataDetailList, setDataDetailList] = useState<any>([]);

    const getDataK = async ()=>{
        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjob?id=648ab8ca326b1b890c5cf5ec
        // GET JOB DETAIL DATA
        let res_detail_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`,
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId?stat=${id_to_query}&page=1&limit=20`, 

            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                const xx = dataLoad.filter((item:any) => work_id.includes(item.job_id));

                // console.log('xx', xx)
                for (let index = 0; index < xx.length; index++) {
                    dataDetailList.push(xx[index])
                }
            });
    }
    console.log('dataDetailList', dataDetailList)

    const saveData = async ()=>{
        updateRepairJob();
    }

    const updateRepairJob = async () => {
        // เปลี่ยน stat เป็นส่งให้ลูกค้าอนุมัติ
        {/* 
            MAIN STATUS
            649859ca515b80b78fe13b84 = รอดำเนินการ
            649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
            649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
            64985a01515b80b78fe13b8a = เข้าประเมิน
            64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
            64985a29515b80b78fe13b8e = ประเมินเสร็จ
            64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
            64985a3c515b80b78fe13b92 = เข้าซ่อม
            64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
            64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
            64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
            64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
            64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
            64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
            64985bcf515b80b78fe13bba = ปิดงาน *
        */}

        for (let index = 0; index < work_id.length; index++) {
            // const element = work_id[index];
            // ****************** 1. UPDATE JOB with Selected stat ******************
            // **********************************************************************
            let data = {
                "status_id": "64ab86a5785d4236922eaff7",
                "status_name": "รออนุมัติใบแจ้งซ่อม",  
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
            };
            let res = await axios.patch(majorCareUrl + "/majorcare/repairJobMDPC/updateRepairjob?id=" + work_id[index], data, { headers: { } });

            // ****************** 2. CREATE TRAIL ******************
            // *****************************************************
            let dataTrail = {
                "repair_job": work_id[index],
                "status": "64ab86a5785d4236922eaff7",
                "status_name": "รออนุมัติใบแจ้งซ่อม",  
                // "sub_status": SubStat !== undefined ? SubStat.value : null,
                // "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                // "remark": Remark !== undefined ? Remark : "",
                "remark": RemarkStat !== undefined ? RemarkStat : "",
                "create_by": Identity_data?.user_profile,
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            }

            let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrailMDPC", dataTrail, { headers: { } });
        }

        successNoti();
        // list("repair/repair-job")
        replace("/repair-mdpc/repair-mdpc-job")

    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    const columns:any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            // sorter: (a:any, b:any) => a.job_id.localeCompare(b.job_id),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by.attributes !== undefined ? record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <div>
                            <Tag className="tag_mjc_stat_wait_inspect">
                                <TextField value={record.job_obj[0].status_name}  />
                            </Tag>
                        </div>
                        {/* <div>
                            {
                                record ? 
                                    record.job_obj[0]?.status_id !== null ?
                                        record.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" ?
                                            <Tag className="tag_mjc_stat_wait_inspect">
                                                <TextField value={'รับเรื่องแจ้งซ่อม'}  />
                                            </Tag>
                                        :
                                            record.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" ?
                                                <Tag className="tag_mjc_stat_inspect">
                                                    <TextField value={'เลื่อนนัดจากลูกค้า'}  />
                                                </Tag> 
                                            :
                                                record.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" ?
                                                    <Tag className="tag_mjc_stat_inspect">
                                                        <TextField value={'เลื่อนนัดจากนิติบุคคล'}  />
                                                    </Tag>
                                                :
                                                    <Tag className="tag_mjc_stat_wait_apprv">
                                                        <TextField value={'error 404'}  />
                                                    </Tag>
                                    : '-'
                                : '-'
                            }
                        </div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                    getDataK();

                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleClick = () => {
        setButtonDisabled(true);
        form.submit()
    };

    const handleChangeRemark1 = (e:any) => {
        settextcount1(e.target.value);
    };


    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        title="ยืนยันรายการส่งให้ลูกค้าอนุมัติ"
        // pageHeaderProps={{
        //     extra : <></>
        // }}
        pageHeaderProps={{
            breadcrumb: <Breadcrumb>
                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                <Breadcrumb.Item>MDPC แจ้งซ่อม</Breadcrumb.Item>
                {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><span className="last_breadcrumb">ส่งให้ลูกค้าอนุมัติ</span></Breadcrumb.Item>
            </Breadcrumb>,
            extra : <></>,
            onBack(e?) {
                replace("/repair-mdpc/repair-mdpc-job")
            },
        }}
    >
        <Form  
            form = {form} 
            // layout="horizontal"
            layout="vertical"
            onFinish={(values:any) => {
                // createAssignInspec(values);
                // updateRepairJob()
                saveData();
            }}
        >
            <Table  
                dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank}
                columns={columns} 
                // onChange={onChange} 
                // pagination={{ size: "small", position: [ bottom] }} 
                pagination={{ size: "small", position: [ 'bottomRight'] }} 
            />

            <Row>
                <Col span={18}>
                    <Form.Item 
                        label="หมายเหตุ" 
                        // name="remark"
                        style={{width:"100%"}}
                    >
                        <TextArea 
                            maxLength={255}
                            style={{ height: "90px", maxHeight: "80px" }}
                            // onChange={(e) => SetRemarkStat(e.target.value)}
                            onChange={(e) => { 
                                SetRemarkStat(e.target.value)
                                handleChangeRemark1(e)
                            }}
                        />
                        <Text style={{position: "absolute", display: "flex",right: 0}}>{`${textcount1?.length} / 255`}</Text>
                    </Form.Item>

                </Col>
            </Row> 

            <Divider/>

            <Row style={{float: 'right'}}>
                <Button type="ghost" onClick={() => (list("repair-inspec"))} icon={<Icons.SaveOutlined />} >ยกเลิก</Button>
                {/* <Button type="primary" onClick={() => saveData()} icon={<Icons.SaveOutlined />} style={{marginLeft:'6px'}} >ยืนยัน</Button> */}
                <Button type="primary" onClick={() => handleClick()} icon={<Icons.SaveOutlined />} disabled={buttonDisabled}>ยืนยัน</Button>

            </Row>
        </Form>
    </Edit>   
    );
};