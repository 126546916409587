import { createContext, useState } from "react";

export const Permission = createContext<any[]>([]);

export const StorePermission = (props: any) => {
    const [role_permission, setrole_permission] = useState();

    return (<>
        <Permission.Provider value={[role_permission, setrole_permission]} {...props} />
    </>);
};