/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Form, List, Radio, RadioChangeEvent, Row, Select, Upload, useForm, useSelect, Input, UploadFile, Divider, RcFile, message, DatePicker, Image, Button, Icons, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import { IEventlog, IUserhunit, IUserProfile, IUserSubType } from "interfaces";
import { useState } from "react";

// import moment from "moment";
import dayjs from "dayjs";

export const UserhunitEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();

    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    // const { mutate: CreateLog }             = useCreate<IEventlog>();
    const { mutate: updateUserSubType }     = useUpdate<IUserProfile>();

    // const log_endpoint                      = "even-logs";
    const { form,formProps, saveButtonProps } = useForm<IUserhunit,HttpError,{}>({

    });

    const { queryResult } = useShow<any>();

    // const { queryResult } = useShow<IUserhunit>({metaData: {
    //     populate: ['unit','unit.property','user_sub_type','unit.property.project','unit.property.project.project_brand','user_profile',
    //     'user_profile.user','requestBy','contract','poa',]
    //     }, 
    //     resource: 'user-has-units'
    // });
    const { data } = queryResult;
    // const record = data?.data;
    let recordData:any = data?.data ? data?.data : null;

    // console.log('recordData', recordData)

    const t = useTranslate();
    // const [count, setCount] = useState(0)
    const API_URL = useApiUrl();
    const { Dragger } = Upload;

    const [vPermission, setPermission] = useState<boolean>(true);

    const [vSetUSType, setUSType] = useState<any>();
    const [vSetUSTypeName, setUSTypeName] = useState<any>("");
    const [vSetUSProfile, setUSProfile] = useState<any>();
    const [vSetUnit, setUnit] = useState<any>();
    const [vLoad_Contract, setLoad_Contract] = useState<any>([]);
    const [vLoad_POA, setLoad_POA] = useState<any>([]);

    const [vsetAdminCon, setAdminCon] = useState<any>();
    const [vsetOwnerCon, setOwnerCon] = useState<any>();

    const [vUserFirstName, setUserFirstName] = useState(recordData?.user_profile ? recordData?.user_profile.first_name : '-');
    const [vUserLastName, setUserLastName] = useState(recordData?.user_profile ? recordData?.user_profile.last_name : '-');
    const [vUnitID, setUnitID] = useState(recordData?.unit ? recordData?.unit.unit_id : '-');
    const [vUnitCode, setUnitCode] = useState(recordData?.unit ? recordData?.unit.unit_code : '-');
    const [vUnitName, setUnitName] = useState(recordData?.unit ? recordData?.unit.unit_name : '-');
    const [vProp, setProp] = useState(recordData?.unit ? recordData?.unit.unit_name : '-');

    const [ vReasonRejectOwner, setReasonRejectOwner ] = useState<any>();
    const [ vReasonRejectAdmin, setReasonRejectAdmin ] = useState<any>();

    const [vContractC2, setContractC2] = useState<any>();
    const [vPoaC2, setPoaC2] = useState<any>();

    const [vProjectbrand, setProjectbrand] = useState<any>();
    const [vUsername, setUsername] = useState<any>();
    const [vUserAction, setUserAction] = useState<any>();
    const [vUserActionBy, setUserActionBy] = useState<any>();

    const [vDismode, setDismode] = useState<boolean>(true);
    const [vPOA, setPOA] = useState<boolean>(false);
    const [vOnContract, setOnContract] = useState<boolean>(false);
    const [vOnConfirm, setOnConfirm] = useState<boolean>(false);
    const [visible, setVisible] = useState(false);

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "user-has-units",
        metaData: {
            populate: [
                'unit',
                'unit.property',
                'user_sub_type',
                'unit.property.project',
                'unit.property.project.project_brand',
                'user_profile',
                'user_profile.user',
                'requestBy',
                'contract',
                'poa'
            ],
            locale:['th']
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData ? recordData.id : null,
            }
        ],
        queryOptions: {
            onSuccess: (data) => {
                console.log('data xxxx : ', data.data[0])
                // console.log('data.data[0]?.localizations : ', data.data[0]?.localizations[0].pic[0].url)
                form.setFieldsValue({
                    user_sub_type:
                        data.data[0]?.user_sub_type ?
                            data.data[0]?.user_sub_type.sub_type_name
                        : null,    
                });

                setUSType(data.data[0]?.user_sub_type ? data.data[0]?.user_sub_type.id: null)
                setUSProfile(data.data[0]?.user_profile ? data.data[0]?.user_profile.id: null)
                setUnit(data.data[0]?.unit ? data.data[0]?.unit.id: null)

                {data.data[0]?.isOwnerConfirmed !== null? //dismode
                    setOnConfirm(true):setOnConfirm(false)
                }

                {data.data[0]?.isOwnerConfirmed !== null? //dismode
                    setDismode(true):setDismode(false)
                }

                // {recordData?.user_sub_type.sub_type_name == "Renter" ? setRenter(true):setRenter(false)}

                {data.data[0]?.user_sub_type.sub_type_name == "Renter" ? //contract
                    setOnContract(true):setOnContract(false)
                }

                {data.data[0]?.isOwnerConfirmed !== null && data.data[0]?.poa !== null? //poa
                    setPOA(true):setPOA(false)
                }

                setOwnerCon(data.data[0]?.isOwnerConfirmed == true ? true : data.data[0]?.isOwnerConfirmed == false ? false : null)
                setAdminCon(data.data[0]?.isAdminConfirmed == true ? true : data.data[0]?.isAdminConfirmed == false ? false : null)

                setReasonRejectOwner(queryResult.data?.data.rejection_owner ? queryResult.data?.data.rejection_owner : "");
                setReasonRejectAdmin(queryResult.data?.data.rejection_admin ? queryResult.data?.data.rejection_admin : "");
                setUserFirstName(data.data[0]?.user_profile ? data.data[0]?.user_profile.first_name : '-');
                setUserLastName(data.data[0]?.user_profile ? data.data[0]?.user_profile.last_name : '-');
                setUnitID(data.data[0]?.unit ? data.data[0]?.unit.unit_id : '-');
                setUnitCode(data.data[0]?.unit ? data.data[0]?.unit.unit_code : '-');
                setUnitName(data.data[0]?.unit ? data.data[0]?.unit.unit_name : '-');
                setProp(data.data[0]?.unit ? data.data[0]?.unit.property ? data.data[0]?.unit.property.property_name :'-' : <></>);

                setProjectbrand(data.data[0]?.unit?.property?.project?.project_brand !== undefined && data.data[0]?.unit?.property?.project?.project_brand !== null ? 
                    data.data[0]?.unit.property.project.project_brand.brand_name:'NO DATA')

                setUsername(data.data[0]?.user_profile?.user?.username)

                {data.data[0]?.user_sub_type.id == 7 ? setUSTypeName("project-member.rt_approve"):setUSTypeName("project-member.ow_approve")}
                // {recordData?.user_sub_type.sub_type_name == "Renter" ? setRenter(true):setRenter(false)}

                {Identity_data && (Identity_data?.user_type?.attributes?.type_name == "Super Admin" || Identity_data?.user_type?.attributes?.type_name == "Admin") ?
                    setPermission(false)
                    :
                    setPermission(true)
                }

                data.data[0]?.requestBy ? data.data[0]?.requestBy.length > 0 ? 
                    data.data[0]?.requestBy.map((item:any,key:any) => {
                        return setUserAction(item.first_name + " " + item.last_name)
                }):<></>:<></>

                setUserActionBy(data.data[0]?.actionBy !== null && data.data[0]?.actionBy !== undefined ? data.data[0]?.actionBy:"ไม่ระบุ")

                if(data.data[0]?.contract !== null && data.data[0]?.contract !== undefined){
                    if(data.data[0]?.contract.length > 0){
                        setContractC2(data.data[0]?.contract) 
                    }
                }

                if(data.data[0]?.poa !== null){
                    const fileList2: UploadFile[] = [
                        {
                            uid: data.data[0]?.poa[0].id,
                            name: data.data[0]?.poa[0].name,
                            status: "done",
                            url:
                                data.data[0]?.poa[0].url,
                            thumbUrl:
                                data.data[0]?.poa[0].url
                        }
                    ];
        
                    setLoad_POA({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList2],
                    })
                }

                if(data.data[0]?.contract !== null){
                    const fileList: UploadFile[] = [
                        {
                            uid: data.data[0]?.contract[0].id,
                            name: data.data[0]?.contract[0].name,
                            status: "done",
                            url:
                                data.data[0]?.contract[0].url,
                            thumbUrl:
                                data.data[0]?.contract[0].url
                        }
                    ];
        
                    setLoad_Contract({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList],
                    })

                    form.resetFields(["contract"]);

                }

                form.resetFields(["poa"]);

            },
        },
    });

    // useEffect(() => {
    //     if (count < 3) {
    //       const interval = setInterval(() => {
    //         setCount(prev => prev + 1)

    //         form.setFieldsValue({
    //             user_sub_type:
    //                 recordData?.user_sub_type ?
    //                     recordData?.user_sub_type.sub_type_name
    //                 : null,    
    //         });

    //         setUSType(recordData?.user_sub_type ? recordData?.user_sub_type.id: null)
    //         setUSProfile(recordData?.user_profile ? recordData?.user_profile.id: null)
    //         setUnit(recordData?.unit ? recordData?.unit.id: null)

    //         {recordData?.isOwnerConfirmed !== null? //dismode
    //             setOnConfirm(true):setOnConfirm(false)
    //         }

    //         {recordData?.isOwnerConfirmed !== null? //dismode
    //             setDismode(true):setDismode(false)
    //         }

    //         // {recordData?.user_sub_type.sub_type_name == "Renter" ? setRenter(true):setRenter(false)}

    //         {recordData?.user_sub_type.sub_type_name == "Renter" ? //contract
    //             setOnContract(true):setOnContract(false)
    //         }

    //         {recordData?.isOwnerConfirmed !== null && recordData?.poa !== null? //poa
    //             setPOA(true):setPOA(false)
    //         }

    //         setOwnerCon(recordData?.isOwnerConfirmed == true ? true : recordData?.isOwnerConfirmed == false ? false : null)
    //         setAdminCon(recordData?.isAdminConfirmed == true ? true : recordData?.isAdminConfirmed == false ? false : null)

    //         setReasonRejectOwner(queryResult.data?.data.rejection_owner ? queryResult.data?.data.rejection_owner : "");
    //         setReasonRejectAdmin(queryResult.data?.data.rejection_admin ? queryResult.data?.data.rejection_admin : "");
    //         setUserFirstName(recordData?.user_profile ? recordData?.user_profile.first_name : '-');
    //         setUserLastName(recordData?.user_profile ? recordData?.user_profile.last_name : '-');
    //         setUnitID(recordData?.unit ? recordData?.unit.unit_id : '-');
    //         setUnitCode(recordData?.unit ? recordData?.unit.unit_code : '-');
    //         setUnitName(recordData?.unit ? recordData?.unit.unit_name : '-');
    //         setProp(recordData?.unit ? recordData?.unit.property ? recordData?.unit.property.property_name :'-' : <></>);

    //         setProjectbrand(recordData?.unit?.property?.project?.project_brand !== undefined && recordData?.unit?.property?.project?.project_brand !== null ? 
    //             recordData?.unit.property.project.project_brand.brand_name:'NO DATA')

    //         setUsername(recordData?.user_profile?.user?.username)

    //         {recordData?.user_sub_type.id == 7 ? setUSTypeName("project-member.rt_approve"):setUSTypeName("project-member.ow_approve")}
    //         // {recordData?.user_sub_type.sub_type_name == "Renter" ? setRenter(true):setRenter(false)}

    //         {Identity_data && (Identity_data?.user_type?.attributes?.type_name == "Super Admin" || Identity_data?.user_type?.attributes?.type_name == "Admin") ?
    //             setPermission(false)
    //             :
    //             setPermission(true)
    //         }

    //         recordData?.requestBy?recordData?.requestBy.length > 0 ? 
    //             recordData?.requestBy.map((item:any,key:any) => {
    //                 return setUserAction(item.first_name + " " + item.last_name)
    //         }):<></>:<></>

    //         setUserActionBy(recordData?.actionBy !== null && recordData?.actionBy !== undefined ? recordData?.actionBy:"ไม่ระบุ")

    //         if(recordData?.contract !== null && recordData?.contract !== undefined){
    //             if(recordData?.contract.length > 0){
    //                 setContractC2(recordData?.contract) 
    //             }
    //         }

    //         if(recordData?.poa !== null){
    //             const fileList2: UploadFile[] = [
    //                 {
    //                     uid: recordData?.poa.id,
    //                     name: recordData?.poa.name,
    //                     status: "done",
    //                     url:
    //                         recordData?.poa.url,
    //                     thumbUrl:
    //                         recordData?.poa.url
    //                 }
    //             ];
    
    //             setLoad_POA({
    //                 name: 'files',
    //                 multiple: true,
    //                 defaultFileList: [...fileList2],
    //             })
    //         }

    //         // form.resetFields(["contract"]);
    //         form.resetFields(["poa"]);
    //       }, 1000)
    //       return () => clearInterval(interval)
          
    //     }
    // }, [count,record])

    const { selectProps: SelUsersubtype } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field       : 'sub_type_name',
                operator    : 'in',
                // value       :  ['Guest','Owner','Pre Owner','Renter','Resident Owner','Resident Renter'],
                value       :  ['Guest','Owner','Owner Contract','Renter','Resident Owner','Resident Renter'],
            },
        ],
    });

    const onChangeAdminCon = ({ target: { value } }: RadioChangeEvent) => {
        setAdminCon(value);
    };

    const onChangeOwnerCon = ({ target: { value } }: RadioChangeEvent) => {
        setOwnerCon(value);
    };

    function onchangeUSType(value:any) {
        setUSType(value);
    }

    function fileChange_CON(e:any){
        if(e.file.response){
            let res = e.file.response;
            vContractC2.push(res[0]);
        }
    }

    function fileChange_POA(e:any){
        if(e.file.response){
            let res = e.file.response;
            setPoaC2(res[0]);
            if(recordData?.isOwnerConfirmed == null){
                {e.fileList.length !== 0 ? setOnConfirm(true):setOnConfirm(false)}
            }
        }
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG or PDF file');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function updateToCustomer(){
        updateUserSubType({
            resource    : 'user-profiles',
            id          : vSetUSProfile,
            values      : {
                user_type    : 2 + '' // customer
            },successNotification:false,errorNotification:false
        });
    }

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("project-member.edit")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("project-member.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("project-member.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />

        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.updateBy = user_data.user_profile.id
                values.updateBy = user_data.user_profile.id

                values.user_sub_type = vSetUSType
                values.user_profile = vSetUSProfile
                values.unit = vSetUnit



                {vContractC2 !== null && vContractC2 !== undefined ?
                    values.contract = vContractC2 : <></>
                }

                {vPoaC2 !== null && vPoaC2 !== undefined ?
                    values.poa = vPoaC2 : <></>
                }

                if(vsetAdminCon == true){
                    values.rejection_admin = ''
                }

                if(vsetOwnerCon == true){
                    values.rejection_owner = ''
                }

                if(vSetUSType !== 5){ // 5 == guest
                    updateToCustomer();
                }

                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label={t("project-member.pjbrand")}>
                                        <Input disabled={true} value={vProjectbrand}/>
                                </Form.Item>

                                <Input.Group compact>
                                    <Form.Item  
                                        label={t("unit.code")}   
                                        name={["unit","unit_code"]} 
                                        style={{width: "50%"}}
                                    >
                                        <div style={{backgroundColor: "#f5f5f5",border: "1px soLid #d9d9d9", padding: "5px 10px 5px",borderRadius: 5, width: "92%"}}><span style={{color: "rgba(0, 0, 0, 0.25)"}}>{vUnitCode}</span></div>
                                    </Form.Item>

                                    <Form.Item  
                                        label={t("unit.number")} 
                                        name={["unit","unit_name"]} 
                                        style={{width: "50%"}}
                                    >
                                        <div style={{backgroundColor: "#f5f5f5",border: "1px soLid #d9d9d9", padding: "5px 10px 5px",borderRadius: 5}}><span style={{color: "rgba(0, 0, 0, 0.25)"}}>{vUnitName}</span></div>
                                    </Form.Item>
                                </Input.Group>

                                <Form.Item  label={t("project-member.status")} name="deleteStatus">
                                    <Radio.Group style={{verticalAlign: 'middle'}} onChange={onChangeOwnerCon}>
                                        <Radio.Button value={"N"}>ACTIVE</Radio.Button>
                                        <Radio.Button value={"Y"}>IN ACTIVE</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item label={t("project-member.propname")}>
                                        <Input disabled={true} value={vProp}/>
                                </Form.Item>

                                <Input.Group compact>
                                    <Form.Item  
                                        label={t("project-member.fname")}
                                        name="user_profile.first_name"
                                        style={{width: "50%"}}
                                    >
                                            <div style={{backgroundColor: "#f5f5f5",border: "1px soLid #d9d9d9", padding: "5px 10px 5px",borderRadius: 5, width: "95%"}}><span style={{color: "rgba(0, 0, 0, 0.25)"}}>{vUserFirstName}</span></div>
                                    </Form.Item>

                                    <Form.Item  
                                        label={t("project-member.lname")}
                                        name={["user_profile","last_name"]}
                                        style={{width: "50%"}}
                                    >
                                            <div style={{backgroundColor: "#f5f5f5",border: "1px soLid #d9d9d9", padding: "5px 10px 5px",borderRadius: 5}}><span style={{color: "rgba(0, 0, 0, 0.25)"}}>{vUserLastName}</span></div>
                                    </Form.Item>
                                </Input.Group>

                                <Input.Group compact>
                                    <Form.Item  
                                        label={t("project-member.username")}
                                        // name="user_profile.first_name"
                                        style={{width: "50%"}}
                                    >
                                            {/* <div style={{backgroundColor: "#f5f5f5",border: "1px soLid #d9d9d9", padding: "5px 10px 5px",borderRadius: 5, width: "95%"}}><span style={{color: "rgba(0, 0, 0, 0.25)"}}>{vUserFirstName}</span></div> */}
                                            
                                            <Input required={true}
                                            disabled={true}
                                            style={{width: "95%",borderRadius: "5px"}} 
                                            onChange={(e) => setUsername(e.target.value)} 
                                            value={vUsername}/>
                                    </Form.Item>

                                    <Form.Item 
                                        name="user_sub_type" 
                                        label={t("Luser-sub-type")} 
                                        style={{width: "50%"}}
                                    > 
                                        <Select 
                                            placeholder={t("select.sel")}
                                            disabled={vPermission}
                                            {...SelUsersubtype} 
                                            onChange={onchangeUSType} 
                                            defaultValue={recordData?.user_sub_type !== null && recordData?.user_sub_type !== undefined ? recordData?.user_sub_type.sub_type_name : ''}
                                        />
                                    </Form.Item>
                                </Input.Group>

                                <Input.Group compact>

                                    <Form.Item label={t("project-member.actionby")} style={{width: "50%"}}>
                                        <Input disabled={true} value={vUserActionBy} style={{width: "95%"}}/>
                                    </Form.Item>

                                    <Form.Item label={t("project-member.useractionby")} style={{width: "50%"}}>
                                        <Input disabled={true} value={vUserAction}/>
                                    </Form.Item>

                                </Input.Group>

                                {vOnContract == true ? 
                                    <Input.Group compact>
                                            <Form.Item style={{width: "50%"}}
                                                name = "rentStartDate"
                                                label = {t("project-member.rentstdate")}
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select date and time.'
                                                    },
                                                ]}
                                                getValueProps={(value) => ({
                                                    value: value ? dayjs(value) : "",
                                                })}
                                            >
                                                <DatePicker disabled
                                                    style={{width: "95%"}}
                                                    // disabledDate={(current) => {
                                                    //     let customDate = moment().format("YYYY-MM-DD");
                                                    //     return current && current < moment(customDate, "YYYY-MM-DD");
                                                    // }}
                                                    disabledDate={(current) => {
                                                        let customDate = dayjs().format("YYYY-MM-DD");
                                                        return current && current.isBefore(customDate, 'day');
                                                    }}
                                                />
                                            </Form.Item>

                                            <Form.Item style={{width: "50%"}}
                                                name = "rentEndDate"
                                                label = {t("project-member.rentendate")}
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select date and time.'
                                                    },
                                                ]}
                                                getValueProps={(value) => ({
                                                    value: value ? dayjs(value) : "",
                                                })}
                                            >
                                                <DatePicker disabled
                                                    style={{width: "100%"}}
                                                    // disabledDate={(current) => {
                                                    //     let customDate = moment().format("YYYY-MM-DD");
                                                    //     return current && current < moment(customDate, "YYYY-MM-DD");
                                                    // }}
                                                    disabledDate={(current) => {
                                                        let customDate = dayjs().format("YYYY-MM-DD");
                                                        return current && current.isBefore(customDate, 'day');
                                                    }}
                                                />
                                            </Form.Item>
                                        </Input.Group>
                                :<></>}
                            </Col>
                        </Row>

                        <Divider />

                        {vOnConfirm == true && 
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>

                            <Row gutter={16}>
                                    <Col className="gutter-row" span={10}>
                                        <Form.Item  label={t(vSetUSTypeName)} name="isOwnerConfirmed">
                                            <Radio.Group style={{verticalAlign: 'middle'}} onChange={onChangeOwnerCon} disabled={vDismode}>
                                                <Radio.Button value={true}>{t("project-member-edit.true")}</Radio.Button>
                                                <Radio.Button value={false}>{t("project-member-edit.false")}</Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={14}>
                                        {vsetOwnerCon === false ? 
                                            <>
                                                <Form.Item name="rejection_owner" label=" " className="disable_req"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please enter reason.'
                                                        },
                                                    ]}
                                                >
                                                    <Input required={true} placeholder="reason of rejection" style={{borderRadius: "5px"}} onChange={(e) => setReasonRejectOwner(e.target.value)} value={vReasonRejectOwner}/>
                                                </Form.Item>
                                            </>
                                            :<></>
                                        }
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="gutter-row" span={12}>

                                <Row gutter={16}>
                                    <Col className="gutter-row" span={10}>
                                        <Form.Item  label={t("project-member.ad_approve")} name="isAdminConfirmed">
                                            <Radio.Group onChange={onChangeAdminCon}>
                                                <Radio.Button value={true} >{t("project-member-edit.true")}</Radio.Button>
                                                <Radio.Button value={false}>{t("project-member-edit.false")}
                                                </Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={14}>
                                        {vsetAdminCon == false ? 
                                            <>
                                                <Form.Item name="rejection_admin" label=" " className="disable_req"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please enter reason.'
                                                        }
                                                    ]}
                                                >
                                                    <Input required={true} placeholder="reason of rejection" style={{borderRadius: "5px"}} onChange={(e) => setReasonRejectAdmin(e.target.value)} value={vReasonRejectAdmin}/>
                                                </Form.Item>
                                            </>
                                            :<></>
                                        }
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                        }

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            {vPOA == true ?
                                <Col className="gutter-row" span={12}>
                                    <Form.Item name="poa" label={t("user-profiles.poa")}>
                                        <Dragger style={{borderRadius: "10px"}} 
                                            {...vLoad_POA}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}
                                            listType="picture"
                                            multiple
                                            maxCount={1}
                                            onChange={fileChange_POA}
                                            beforeUpload={beforeUpload}
                                        >
                                            <p className="ant-upload-text">{t("upload")}</p>
                                        </Dragger>
                                    </Form.Item>
                                    <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p>
                                </Col>
                                :
                                recordData !== null && recordData?.isOwnerConfirmed == null && 
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            name="poa"
                                            label={t("user-profiles.poa")}
                                            // valuePropName="fileList"
                                            // getValueProps={(data) => getValueProps(data, API_URL)}
                                        >
                                            <Dragger style={{borderRadius: "10px"}} 
                                            {...vLoad_POA}
                                            name="files"
                                            action={`${API_URL}/upload`}
                                            headers={{
                                                Authorization: `Bearer ${localStorage.getItem(
                                                    TOKEN_KEY,
                                                )}`,
                                            }}
                                                listType="picture"
                                                multiple
                                                maxCount={1}
                                                onChange={fileChange_POA}
                                                beforeUpload={beforeUpload}
                                            >
                                                <p className="ant-upload-text">{t("upload")}</p>
                                            </Dragger>
                                            <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p>
                                        </Form.Item>
                                    </Col>
                            }

                            {vOnContract == true ?
                            <Col className="gutter-row" span={12}>

                                <Form.Item name="contract" label={t("user-profiles.contract")}>
                                    <Dragger style={{borderRadius: "10px"}} 
                                        {...vLoad_Contract}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        multiple
                                        onChange={fileChange_CON}
                                        beforeUpload={beforeUpload}
                                    >
                                        <p className="ant-upload-text">{t("upload")}</p>
                                    </Dragger>
                                        {/* <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p> */}
                                </Form.Item>
                                <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p>

                                {recordData?.contract !== null && recordData?.contract !== undefined ?
                                    recordData?.contract.length > 0 ?
                                        <>
                                        <Button type="primary" onClick={() => setVisible(true)} style={{borderRadius: "10px",width: "100%"}} icon={<Icons.PlusOutlined/>}>
                                            Show Other Contract
                                        </Button>
                                        
                                        <div style={{ display: 'none' }}>
                                            <Image.PreviewGroup preview={{ visible, onVisibleChange: vis => setVisible(vis) }}>
                                                {recordData?.contract.map((v:any)=>{
                                                    return <>
                                                        <Image src={v.url} />
                                                    </>
                                                })}
                                            </Image.PreviewGroup>
                                        </div>
                                        </>:<></>:<></>
                                }
                            </Col>:<></>
                            }
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>   
    );
};