/* eslint-disable */
import { Breadcrumb, Card, Col, Create, DatePicker, Form, List, Radio, RadioChangeEvent, Row, Select, Space, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useList, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IFacilityBan, IProperties, IUnit, IUserhunit } from "interfaces";
import { useState } from "react";

import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import moment from 'moment';
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

export const FacilitiesBanCreate: React.FC<IResourceComponentsProps> = () => {
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const { form,formProps, saveButtonProps } = useForm<IFacilityBan,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource    : log_endpoint,
                values      : {
                    // user_profile            : user.id,
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(), 
                    log_event               : "Create",
                    menu_event              : 'facility-types',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
    });

    const t = useTranslate(); 
    const { Option } = Select;

    // const [count, setCount] = useState(0);
    const [vsetBanType, setBanType] = useState<any>();
    const [vsetBanStatus, setBanStatus] = useState<any>(true);
    const [vsetnote, setnote]   = useState<any>();
    const [vBandate, setBandate] = useState<any>("");
    const [vsetUnit, setUnit] = useState<any>();
    const [vProperty, setProp] = useState<any>();
    const [vCheckUnitNameB4, setCheckUnitNameB4] = useState<any>([]);

    // const [ vUserHasUnitID, setUserHasUnitID] = useState<any>();
    const [ vUserProfile, setUserProfile] = useState<any>();

    const Property = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['unit','unit.property'],
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            pagination:{current: 1, pageSize: 100} 
        }
    });
    const { data: Dataprop } = Property;
    let PropData:any = []
    PropData = Dataprop?.data? Dataprop?.data : [];  
    const PropListData = Array.from(new Set(PropData.map((item: any) => item.unit.property.id)));     
    console.log('vProperty', vProperty)


    const Unit = useList<any>({ 
        resource: "user-has-units",
        metaData: {
            populate: '*',
        },
        config:{
            filters: [
                {
                    field: "unit][property][id]",
                    operator: "eq",
                    value: vProperty + ""
                }
            ],
            pagination:{current: 1, pageSize: 1000}  
        }
    });
    const { data: DataUnit } = Unit;
    let UnitData:any = []
    UnitData = DataUnit?.data? DataUnit?.data : [];
    const UnitListData = Array.from(new Set(UnitData.map((item: any) => item.unit.id))); 

    const { selectProps: SelProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field: "id",
            //     operator: "eq",
            //     value: PropListData
            // }
        ]   
    });

    // console.log('UnitListData', [UnitListData])

    const { selectProps: SelUnit } = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            // {
            //     field: "id",
            //     operator: "in",
            //     value: UnitListData.length > 0 ? UnitListData : null
            // }
            {
                field: "property][id]",
                operator: "eq",
                value: vProperty + ""
            }
        ],
        // hasPagination: false,
        pagination:{
            pageSize: 2000
        }
    });

    const UserHasUnit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile'],
        },
        config:{
            filters: [
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : vsetUnit + ""
                },
            ]
        }
    });
    const { data: Datauserhunit } = UserHasUnit;
    let userhunitData:any = []
    userhunitData = Datauserhunit?.data? Datauserhunit?.data : [];

    const onChangeBantype = ({ target: { value } }: RadioChangeEvent) => {
        setBanType(value);
    };

    const onChangeBanStatus = ({ target: { value } }: RadioChangeEvent) => {
        setBanStatus(value); 
    };

    const BandateChange = (date: Dayjs | null, dateString: string, id: number) => {
        // setBandate(dayjs(dateString)); 
        setBandate(dateString);
    };

    function fileChange(e:any){
        if(e.file.response){
            let res = e.file.response;   
        }
    }

    function onchangeProp(value:any) {
        setProp(value);
        form.resetFields(["unit"]);
        form.resetFields(["user_profile"]);
    }

    function onchangeUnit(value:any) {
        setUnit(value);
        form.resetFields(["user_profile"]);
    }

    function onchangeUserHasUnit(value:any) {
        // setUserHasUnitID(value);
        setUser(value);
    }

    function setUser(v:any){
        setUserProfile(v);
    }

    function setUnitNameB4(v:any){
        vCheckUnitNameB4.pop()
        vCheckUnitNameB4.push(v)
    }

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            // title={t("fclt-ban.create")}
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-ban.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />

        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.createBy = user_data.user_profile.id
                values.ban_note = vsetnote
                values.ban_status = vsetBanStatus
                values.unit = values.unit
                {vsetBanType == false ? values.user_profile = vUserProfile + "" :  values.user_profile = null}

                // vsetBanType == false แบนแบบ user
                // vsetBanType == true แบนแบบ unit
                vsetBanType ? values.banType = "unit" : values.banType = "user"

                if(vBandate !== ""){
                    values.date_time = vBandate;
                }else{
                    values.date_time = null
                }
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("property.title")} name="property"
                                    rules={[
                                        {
                                            required: true, message: 'Please select property.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeProp} {...SelProp}/>
                                        
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("unit.title")} name="unit"
                                    rules={[
                                        {
                                            required: true, message: 'Please select unit.'
                                        },
                                    ]}>
                                        <Select 
                                            placeholder={t("select.sel")} 
                                            onChange={onchangeUnit} 
                                            {...SelUnit} 
                                            allowClear
                                        />
                                        
                                        {/* <Select placeholder={t("select.sel")} onChange={onchangeUnit} {...SelUnitUser}></Select> */}
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={5}>
                                <Form.Item  label={t("fclt-ban.bstatus")} name="ban_status"
                                    // rules={[
                                    //     {
                                    //         required: true, message: 'Please select ban type.'
                                    //     },
                                    // ]}
                                >
                                    <Radio.Group 
                                        // onChange={onChangeBanStatus} 
                                        style={{verticalAlign: 'middle'}} 
                                        defaultValue={true} 
                                        value={true}
                                    >
                                        <Radio.Button value={true}>{t("fclt-ban.true")}</Radio.Button>
                                        <Radio.Button value={false} disabled>{t("fclt-ban.false")}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={5}>
                                {vsetBanStatus == true ? 
                                <>
                                    <Form.Item 
                                        label={t("fclt-ban.bdate")} 
                                        name="date_time"
                                        // rules={[
                                        //     {
                                        //         required: true, message: 'Please select unban date.'
                                        //     },
                                        // ]}
                                    >
                                        <DatePicker 
                                            // showTime 
                                            format="YYYY-MM-DD" 
                                            // format="YYYY-MM-DD HH:mm" 
                                            // format="YYYY-MM-DD HH:mm A" 
                                            style={{width: "95%"}}
                                            onChange={(date, dateString) => BandateChange(date, dateString, 1)} 
                                            // disabledDate={(current) => {
                                            //     let customDate = moment().format("YYYY-MM-DD");
                                            //     return current && current < moment(customDate, "YYYY-MM-DD");
                                            // }}
                                            disabledDate={(current) => {
                                                let todayDate = dayjs().format("YYYY-MM-DD");
                                                return current && current.isBefore(todayDate, 'day');
                                            }}
                                        />
                                    </Form.Item>
                                </>
                                :<></>}
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("fclt-ban.note")} name="ban_note"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter ban note.'
                                        },
                                    ]}>
                                        <CKEditor
                                                name="detail"
                                                editor={ClassicEditor}
                                                //data={recordData.detail}
                                                config={{
                                                    toolbar: [
                                                        "heading",
                                                        "|",
                                                        "bold",
                                                        "italic",
                                                        "link",
                                                        "bulletedList",
                                                        "numberedList",
                                                        "blockQuote",
                                                        // "ckfinder",
                                                        "|",
                                                        "imageTextAlternative",
                                                        "imageUpload",
                                                        "imageStyle:full",
                                                        "imageStyle:side",
                                                        // "|",
                                                        // "mediaEmbed",
                                                        // "insertTable",
                                                        // "tableColumn",
                                                        // "tableRow",
                                                        // "mergeTableCells",
                                                        // "|",
                                                        // "undo",
                                                        // "redo"
                                                    ],
                                                    mediaEmbed: {
                                                        // configuration...
                                                        removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                    },
                                                    image: {
                                                        toolbar: ['']
                                                    }
                                                }}
                                                
                                                onInit={(editor:any) => {
                                                }}
                                                onChange={(event:any, editor:any) => {
                                                const data = editor.getData();
                                                setnote(data);
                                                }}
                                                onBlur={(editor:any) => {
                                                }}
                                                onFocus={(editor:any) => {
                                                }}                    
                                        />
                                </Form.Item>
                            </Col>
                        </Row>

                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item name="ban_type"
                             rules={[
                                {
                                    required: true, message: 'Please select ban type.'
                                },
                            ]}
                        >
                            <Radio.Group onChange={onChangeBantype}>
                                <Space direction="vertical">
                                    <Radio value={true}>{t("fclt-ban.btype-unit")}</Radio>
                                    <Radio value={false}>{t("fclt-ban.btype-user")}</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>

                        {vsetBanType == false ? 
                        <>
                            <Form.Item  label={t("user-profiles.user-profile")} name="user_profile"
                                rules={[
                                    {
                                        required: true, message: 'Please select user profile.'
                                    },
                                ]}
                            >
                                    <Select placeholder={t("select.sel")} onChange={onchangeUserHasUnit}>
                                        {
                                            userhunitData !== undefined?
                                                userhunitData.map((item:any,key:any) => {
                                                    return <>
                                                        {/* {item.status !== null && item.status !== undefined  && item.status !== false ?  */}
                                                        {item.user_profile !== null? 
                                                            <>
                                                                <Option key={key} value={item.user_profile.id}>{item.user_profile.first_name + ' ' + item.user_profile.last_name}</Option>
                                                            </>
                                                        :
                                                            <></>}
                                                    </>
                                                })
                                            :<></>
                                        }
                                    </Select>
                            </Form.Item>
                        </>
                        :<></>}
                    </Card>
                </Col>
            </Row>
        </Form>
    </Create>   
    );
};