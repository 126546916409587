/* eslint-disable */
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { List, Table, TextField, useTable, Space, EditButton, ShowButton, Icons, CreateButton, Form, Avatar, Button, Modal, Badge, Row, Col, ExportButton, Breadcrumb, Tag, Tabs, useModalForm, Tooltip, Select, Upload, RcFile, message, useSelect, Input, Divider, Radio, Switch, RadioChangeEvent } from "@pankod/refine-antd";
import { ICompany, IDepartment, IEventlog, IProjectBrand, IProperties, IRManage, IUserFilter, IUserProfile, IUserSubType, IUserType, IUserTypeMap, IUserprefix } from "interfaces";
import { useEffect, useRef, useState } from "react";
import Moment from 'moment';
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from "antd-country-phone-input";
import { TOKEN_KEY } from "../../constants";
import en from 'world_countries_lists/data/countries/en/world.json';
import axios from "axios";

let user_typemap_data: any = undefined;
let user_type_data: any = undefined;
let positiondt: any = undefined;
let MAILDATA:any = [];
let defaultPixurl: any = "../../images/svg/image.svg"

let TypeMapData: any;
let recordData: any;



export const UserManageList: React.FC<IResourceComponentsProps> = () => {
    
    const tabUserPortal = localStorage.getItem("keyTabUserPortal");
    const tabKeyStatus = localStorage.getItem("keyStatus");

    const { TabPane } = Tabs;
    const Ref = localStorage.getItem("Ref");
    if (Ref == "1") {
        localStorage.setItem("Ref", "0");
    }

    const t = useTranslate();
    const { Option } = Select;
    const { Dragger } = Upload;
    const API_URL = useApiUrl();
    const refUpload = useRef<any>()

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { data: Identity_data } = useGetIdentity<any>();
    let data_permission: any = Identity_data?.menu_access.find((item: any) => item?.module_master?.module_name == "Portal User Management");
    const { mutate: CreateLogDelete } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";
    const userQuery = useGetIdentity();
    const { data: user_data } = userQuery;
    const vCurDelStatus = { deleteStatus: 'Y' }

    const { confirm } = Modal;
    const { mutate } = useUpdate<IUserProfile>();
    const ed_point = "user-profiles";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = ['10', '30', '50', '100'];

    const { mutate: CreateUser} = useCreate<IUserTypeMap>();
    const { mutate: createUserTypeMap} = useCreate<IUserTypeMap>();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: UpdateUser } = useUpdate<IUserTypeMap>();

    const [selID, setID] = useState<any>();
    const [selStatus, setStatus] = useState<any>();
    const [vTel, setTel] = useState<CountryPhoneInputValue>();
    const [vCompany, setCompany] = useState<any>();
    const [vDepartment, setDepartment] = useState<any>();

    const [vpixlogo, setpixlogo] = useState<any>([]);
    const [swpixList, setswpixList] = useState<boolean>(true);
    const [swpwd, setswpwd] = useState<boolean>(false);
    const [pixURL, setpixURL] = useState<any>();
    const [vpixChange, setpixChange] = useState<any>();
    const [disabled, setDisabled] = useState(false);

    const [ vProp, setProp ] = useState<any>();
    const [ vPropArr, setPropArr] = useState<any>([]);
    const [vPropArrC, setPropArrC] = useState<any>([]);

    const [vRole, setRole] = useState<any>();
    const [searchRole, setSearchRole] = useState<any>();
    const [vRoleArr, setRoleArr] = useState<any>([]);
    const [vRoleArrC, setRoleArrC] = useState<any>([]);

    const [vPosition, setPosition] = useState<any>();
    const [vPositionArr, setPositionArr] = useState<any>([]);
    const [vPositionArrC, setPositionArrC] = useState<any>([]);
    const [selUtype, setselUtype] = useState<any>({id: "6",label: "Super Admin"});
    const [keepDataStatus, setKeepDataStatus] = useState<any>();

    //STAFF
    const [vStaffData, setStaffData] = useState<any>([]);

    //CHECK EMAIL
    const [vUserEmail, setUserEmail] = useState<any>();
    const [vcheckEmail, setcheckEmail] = useState();
    const [vdisabledSAVE, setdisabledSAVE] = useState(true);
    const [vMAILC, setMAILC] = useState<any>();
    const [vICONSEARCH, setICONSEARCH] = useState<any>(<Icons.SearchOutlined />);
    const [vSTYPEICON, setSTYPEICON] = useState<any>("#626262");
    const [vSTYPEINPUT, setSTYPEINPUT] = useState<any>("1px solid #d9d9d9");
    const [vTOOLTIP, setTOOLTIP] = useState<any>(t("msg-vld-mail.tooltip-search"));

    //USER TYPE MAPING
    const [vMappingID, setMappingID] = useState<any>();
    const [vremoveItem, setremoveItem] = useState<any>([]);

    const [search1stName, setSearch1stName] = useState<any>(undefined);
    const [searchLastName, setSearchLastName] = useState<any>(undefined);
    const [searchEmail, setSearchEmail] = useState<any>(undefined);

    const [pagee, setPagee] = useState<any>('1');

    const [dataUserType, setDataUserType] = useState<any>(selUtype?.id);
    const { tableProps, searchFormProps, tableQueryResult } = useTable<IUserProfile, HttpError, IUserFilter>({
        metaData: {
            populate: '*'
        },
        initialFilter: [
            {
                field: "user_type][type_name]",
                operator: "nin",
                value: ["Customer", "Guest"]
            },
            {
                field: "user_type][id]",
                operator: "eq",
                //value: selUtype?.id
                value: !!tabUserPortal ? tabUserPortal : selUtype?.id
            },
        ],
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { q, user_type } = params;
            filters.push(
                // {
                //     field: "q",
                //     operator: "eq",
                //     value: q,
                // },
                {
                    field: "user_type][id]",
                    operator: "eq",
                    // value: user_type
                    value: dataUserType
                },
                // {
                //     field       : "brand_nameEN",
                //     operator    : "contains",
                //     value       : brand_nameTH ? brand_nameTH : null
                // },

                {
                    field: "first_name",
                    operator: "contains" ,
                    value: search1stName ? search1stName : null
                },
                {
                    field: "last_name",
                    operator: searchLastName ? "contains" : "contains",
                    value: searchLastName ? searchLastName : searchLastName
                },
                {
                    field: "user][email]",
                    // operator: searchEmail ? "contains" : "contains",
                    operator: "contains",
                    value: !!searchEmail ? searchEmail : null
                }
            );

            return filters;
        },
        initialSorter:[
            {
                field: 'id',
                order: 'desc'
            },
        ],
        // queryOptions: {
        //     onSuccess(data) {
        //         console.log("data?.data",data?.data)
        //         keepDataNew(data?.data)
        //     },
        // }
    });
    
    const UserType = useList<IUserType>({
        resource: "user-types",
        config: {
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field: "type_name",
                    operator: "nin",
                    value: ["Customer", "Guest"]
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        },
        queryOptions: {
            onSettled(data, error) {
                let dataLoad = data?.data;
                keepDataNew(dataLoad)
                user_type_data = dataLoad
                //console.log("user",dataLoad)
                let newData = dataLoad?.filter(( e:any ) => e?.type_name == "Super Admin" )
                setKeepDataStatus(newData)
            },
        }
    });

    const [superAdminData, setSuperAdminData] = useState<any>()
    const [adminData, setAdminData] = useState<any>()
    const [staffDataNew, setStaffDataNew] = useState<any>()

    const keepDataNew = (data:any) => {
        //console.log("dataKeepDataNew",data)
        let dataLoad = data;
        let newDataSuperAdmin = dataLoad?.filter(( e:any ) => e?.type_name == "Super Admin" )
        //console.log("dataKeepSuper Admin",newDataSuperAdmin)
        setSuperAdminData(newDataSuperAdmin)

        let newDataAdmin = dataLoad?.filter(( e:any ) => e?.type_name == "Admin" )
        //console.log("dataKeepAdmin",newDataAdmin)
        setAdminData(newDataAdmin)

        let newDataStaff = dataLoad?.filter(( e:any ) => e?.type_name == "Staff" )
        //console.log("dataKeepStaff",newDataStaff)
        setStaffDataNew(newDataStaff)
    }

    const resultUserTypeMap = useList<IUserTypeMap>({
        metaData: {
            // populate: '*'
            populate: [ 'user_profile.user', 'company', 'department', 'role_management', 'user_sub_type', 'user_type', 'uuserser_profile', 'username']
        },
        resource: "user-type-mappings",
        config: {
            sort: [{ order: "desc", field: "id" }],
            pagination: { current: 1, pageSize: 1000 }
        },
        queryOptions: {
            onSettled(data, error) {
                let dataLoad = data?.data;
                //console.log("userTypeMap",dataLoad)
                user_typemap_data = dataLoad;
            },
        }
    });

    const resultProfile = useList<IUserProfile>({
        resource: "user-profiles",
        metaData: {
            populate: '*'
        },
        config: {
            filters: [
                {
                    field: "user_type][type_name]",
                    operator: "nin",
                    value: ["Customer", "Guest"]
                },
            ],
            sort: [{ order: "desc", field: "id" }],
            pagination: { current: 1, pageSize: 1000 }
        },
        queryOptions: {
            onSettled(data, error) {
                let dataLoad = data?.data;
                setSumStatus(dataLoad)
                //console.log("resultProfile",dataLoad)
            },
        }
    });

    const [sumStatus, setSumStatus] = useState<any>()

    const { triggerExport, isLoading: exportLoading } = useExport<IUserProfile>({
        metaData: {
            populate: ['user_type', 'user_type.name', 'nationality', 'nationality.id', 'nationality.name', 'user']
        },
        resourceName: "user-profiles",
        filters: [{
            field: "user_type][id]",
            operator: "eq",
            value: tabUserPortal !== null ? tabUserPortal : 6
        }],
        mapData: (item) => {
            if (item) {
                return { // เพิ่มฟิลที่ต้องการ Export
                    id: item.id,
                    user_type: item.user_type ? item.user_type.type_name : '-',
                    identity_no: item.identity_no ? item.identity_no : '-',
                    identity_passport: item.identity_passport ? item.identity_passport : '-',
                    first_name: item.first_name,
                    last_name: item.last_name,
                    nick_name: item.nick_name ? item.nick_name : '-',
                    gender: item.gender,
                    nationality: item.nationality ? item.nationality.name : '-',
                    birthday: item.birthday ? item.birthday : '-'
                };
            }
        },
    });

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function onShowSizeChange(current: any, pageSize: any) {
        setPageSizeChange(pageSize)
    }
    
    async function onStatusChange(v: any, index:any) {
        //console.log("v",v)
        //console.log("index",index)
        let dataLoad = await axios.get(API_URL + "/user-types?filters[id][$eq]=" + v, {
            headers: { Authorization: "Bearer "+ TOKEN_KEY }
        });
        
        let dataonLoad = dataLoad?.data?.data[0];
        setselUtype({id: dataonLoad?.id,label: dataonLoad?.attributes?.type_name});
        searchFormProps.form?.submit();
        localStorage.setItem("keyStatus", index)
        localStorage.setItem("keyTabUserPortal", v)
        setDataUserType(v)
    }

    function updateStatus(id: any, event: any) {
        mutate({
            resource: ed_point,
            id: id + "",
            values: vCurDelStatus,
            successNotification: false
        });

        CreateLogDelete({
            resource: log_endpoint,
            values: {
                user_profile: user_data.user_profile.id,
                log_time_stamp: Date.now(),
                log_event: event,
                menu_event: 'users',
                SysPkID: id + ""
            },
            successNotification: false
        });
    }

    function showDelConfirm(id: any) {
        confirm({
            title: 'Inactive Confirm ?',
            icon: <Icons.WarningOutlined />,
            content: 'Click Confirm to deactivate this data.',
            okText: 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id: any) {
        confirm({
            title: 'Activate Confirm ?',
            icon: <Icons.QuestionCircleOutlined />,
            content: 'Click Confirm to activate this data.',
            okText: 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function fileChange(e: any) {
        if (e.file.response) {
            let res = e.file.response;
            changepixUrl(res[0].url, res[0])
        }
    }

    function changepixUrl(url: any, val: any) {
        setpixURL(url);
        setpixChange(val);
        setswpixList(false)
    }

    function removepixList() {
        setpixURL(defaultPixurl);
        setpixChange(undefined);
        setswpixList(true)
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose,
    } = useModalForm<any>({
        resource: "user-profiles",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false,
        onMutationSuccess(data, variables, context) {
            let recordData:any = data?.data? data?.data : [];
            mutateCreateUser(recordData.data.id);
        },
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading,
        show: editModalShow,
        close: editClose,
    } = useModalForm<any>({
        resource: "user-profiles",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions: {
            onSuccess(data) {
                let dataLoad = data?.data;
                setID(dataLoad?.id);
                setdisabledSAVE(false);

                setTel({ phone: dataLoad ? dataLoad.tel : null, short: dataLoad ? dataLoad.short_tel : null })

                if (dataLoad?.avatar !== null) {
                    setswpixList(false);
                    setpixChange(dataLoad?.avatar)
                    setpixURL(dataLoad?.avatar.url);
                } else {
                    setswpixList(true);
                    setpixChange(undefined)
                    setpixURL(undefined);
                }

                // callAPIPropInfo(dataLoad.id);
            }
        },
    });

    const { selectProps: selProj } = useSelect<IProjectBrand>({
        resource: "project-brands",
        optionLabel: "brand_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "active_status",
                operator: "eq",
                value: true
            },
        ]
    });

    const { selectProps: selAccount } = useSelect<IUserSubType>({
        resource: "user-types",
        optionLabel: "type_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "type_name",
                operator: "in",
                value: ["Customer", "Guest"]
            }
        ]
    });

    const { selectProps: selPrefix } = useSelect<IUserprefix>({
        resource: "user-prefixes",
        optionLabel: "prefix_name",
        optionValue: "id",
    });

    const { selectProps: selUsertype } = useSelect<IUserType>({
        resource: "user-types",
        optionLabel: "type_name",
        optionValue: "id",
        filters: [
            {
                field: 'type_name',
                operator: 'in',
                value: ["Staff", "Admin"]
            }
        ]
    });

    const { selectProps: selProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "id",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ]
    });

    const { selectProps: selComp } = useSelect<ICompany>({
        resource: "companies",
        optionLabel: "company_name",
        optionValue: "id",
    });

    const { selectProps: selDepart } = useSelect<IDepartment>({
        resource: "departments",
        optionLabel: "dept_name",
        optionValue: "id",
        filters: [
            {
                field: 'company][id]',
                operator: 'eq',
                value: vCompany
            }
        ]
    });

    const { selectProps: selRole } = useSelect<IRManage>({
        resource: "role-managements",
        optionLabel: "custom_role_name",
        optionValue: "id",
        fetchSize: 1000,
    });

    const position = useList<IUserSubType>({
        resource: "user-sub-types",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "sub_type_name",
                    operator: "in",
                    value: ["Manager", "HOD", "Editor"]
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        },
        queryOptions: {
            onSuccess(data) {
                let dataLoad = data?.data;
                positiondt = dataLoad;
            }
        }
    });

    const { selectProps: roleSelProp } = useSelect<any>({
        resource: "role-managements",
        optionLabel: "custom_role_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
    });

    const userEmail = useList<IUserProfile>({
        metaData: {
            populate: '*'
        },
        resource: "user-profiles",
        config: {
            filters: [
                {
                    field: "user][email]",
                    operator: "in",
                    value: vMAILC !== undefined ? vMAILC : null,
                },
            ],
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            MAILDATA = dataLoad;
        },}
    });

    const typeMap = useList<IUserTypeMap>({
        resource: "user-type-mappings",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "user_profile][id]",
                    operator: "in",
                    value: selID !== undefined ? selID : null
                },
                {
                    field: "status",
                    operator: "in",
                    value: true
                },
            ],
            pagination: { current: 1, pageSize: 1000 }
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            TypeMapData = dataLoad ? dataLoad : [];
            //console.log(">>> dataLoad",dataLoad)

            if (TypeMapData[0]?.user_type?.type_name == "Admin") {
                let GetMappingID
                let GetTypeID
                let GetCompanyID
                let GetDepartmentID
                let GetRoleID

                dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
                    GetMappingID = v?.id
                    GetTypeID = v?.user_sub_type?.id
                    GetCompanyID = v?.company?.id
                    GetDepartmentID = v?.department ? v.department.id : null
                    GetRoleID = v?.role_management?.id
                })

                if (GetMappingID !== undefined && GetMappingID !== null) {
                    setMappingID(GetMappingID)
                }

                if (GetTypeID !== undefined && GetTypeID !== null) {
                    setPosition(GetTypeID)
                }

                if (GetCompanyID !== undefined && GetCompanyID !== null) {
                    setCompany(GetCompanyID)
                }

                if (GetDepartmentID !== undefined && GetDepartmentID !== null) {
                    setDepartment(GetDepartmentID)
                }

                if (GetRoleID !== undefined && GetRoleID !== null) {
                    setRole(GetRoleID)
                }

                setPosition(TypeMapData && TypeMapData.length > 0 && TypeMapData[0].user_sub_type ? TypeMapData[0].user_sub_type.id : undefined);

                editProps.form?.setFieldsValue({
                    SelComp:
                        TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                            return { value: v?.company?.id, label: v?.company?.company_name }
                        }),
                    SelDept:
                        TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                            return { value: v?.department?.id, label: v?.department?.dept_name }
                        }),
                    role:
                        TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                            return { value: v?.role_management?.id, label: v?.role_management?.custom_role_name }
                        }),
                    position:
                        TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                            return v?.user_sub_type?.id
                        }),
                })
            }else if (TypeMapData[0]?.user_type?.type_name == "Staff") {

                let GetCompanyID
                let GetDepartmentID

                dataLoad !== undefined && dataLoad?.length > 0 &&
                    dataLoad?.map((v: any) => {
                        GetCompanyID = v?.company ? v?.company?.id : null
                        GetDepartmentID = v?.department ? v?.department?.id : null
                    })

                if (GetCompanyID !== undefined && GetCompanyID !== null) {
                    setCompany(GetCompanyID)
                }

                if (GetDepartmentID !== undefined && GetDepartmentID !== null) {
                    setDepartment(GetDepartmentID)
                }

                setMappingID(dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
                    return (v?.id)
                }))

                setPositionArr(dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
                    return (v?.user_sub_type?.id)
                }))

                setRoleArr(dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
                    return (v?.role_management?.id)
                }))

                setPropArr(dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
                    return (v?.property?.id)
                }))

                setStatus(dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
                    return (v?.status)
                }))

                setStaffData(TypeMapData);
                
                editProps.form?.setFieldsValue({
                    SelComp:
                        TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                            return { value: v?.company?.id, label: v?.company?.company_name }
                        }),
                    SelDept:
                        TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                            return { value: v?.department?.id, label: v?.department?.dept_name }
                        }),
                })
            }

            // selUtype?.label == "Super Admin" ? "orange" : selUtype?.label == "Admin" ? "magenta" : selUtype?.label == "Staff"
        },}
    });

    const success = () => {
        Modal.success({
            title: <span>{t("msg-vld-mail.title-success")}</span>,
        //   content: 'some messages...some messages...',
        });
    };

    const error = () => {
        Modal.error({
          title: <span>{t("msg-vld-mail.title-error")}</span>,
          content: <span>{t("msg-vld-mail.content-error")}</span>,
        });
    };

    const warning = () => {
        Modal.warning({
          title: <span>{t("msg-vld-mail.title-warning")}</span>,
          content: <span>{t("msg-vld-mail.content-warning")}</span>,
        });
    };

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    function toggleDisabledX() {
        if(disabled == true){
            setDisabled(!disabled);
        }
    }

    function onchangeEmail(value: any) {
        setMAILC(value);
        setUserEmail(value)
        setcheckEmail(vUserEmail);

        if(vcheckEmail !== vUserEmail){
            setICONSEARCH(<Icons.SearchOutlined />);
            setTOOLTIP(t("msg-vld-mail.tooltip-search"));
            setdisabledSAVE(true);
            setSTYPEICON("inherit");
        }
    }

    function handlesearchemail(email: any) {
        if(email !== null && email !== undefined && email !== ""){
            if(vMAILC !== undefined && vMAILC !== null){
                opvalidateModal();
            }
        }else{
            error();
            setTOOLTIP(t("msg-vld-mail.tooltip-error"));
            setICONSEARCH(<Icons.StopOutlined />);
            setSTYPEICON("#cf1322");
            setSTYPEINPUT("1px solid #f5222d");
            setdisabledSAVE(true);
        }
    }

    function opvalidateModal() {
        if(MAILDATA.length > 0){
            error();
            setTOOLTIP(t("msg-vld-mail.tooltip-error"));
            setICONSEARCH(<Icons.StopOutlined />);
            setSTYPEICON("#cf1322");
            setSTYPEINPUT("1px solid #f5222d");
            setdisabledSAVE(true);
        }else{
            if(vSTYPEICON !== "#389e0d"){
                success();
                setTOOLTIP(t("msg-vld-mail.tooltip-success"));
                setICONSEARCH(<Icons.CheckOutlined />);
                setSTYPEICON("#389e0d");
                setSTYPEINPUT("1px solid #d9d9d9");
                setdisabledSAVE(false);
            }
        }
    }

    function onsetPropArr(){
        vPropArr.push(vProp);
        setPropArr(vPropArr);
    }

    function onsetRoleArr(){
        vRoleArr.push(vRole);
        setRoleArr(vRoleArr);
    }

    function onsetPositionArr(){
        vPositionArr.push(vPosition);
        setPositionArr(vPositionArr);
    }

    function clearArr(){
        setProp(undefined);
        setRole(undefined);
        setPosition(undefined);
    }

    function mutateCreateUser(valueID: any){
        if(selUtype?.label == "Admin"){
            CreateUser({
                resource    : "user-type-mappings",
                values      : {
                    company         : vCompany,
                    department      : vDepartment,
                    user_profile    : valueID,
                    user_type       : selUtype?.id,
                    user_sub_type   : vPosition,
                    role_management : vRole
                },
                successNotification : false,
            });
        }

        if(selUtype?.label == "Staff"){
            if(vPropArr.length > 0){
                for(let i=0 ; i <= vPropArr.length -1; i++){
                    createUserTypeMap({
                        resource    : "user-type-mappings",
                        values      : {
                            company         : vCompany,
                            department      : vDepartment,
                            user_profile    : valueID,
                            user_type       : selUtype?.id,
                            user_sub_type   : vPositionArr[i],
                            role_management : vRoleArr[i],
                            property        : vPropArr[i]
                        },
                        successNotification : false,
                    });
                }
            }
        }
    }

    //============================================

    function onChangeProp(va: any,index: any) {
        setProp(va);
        
        if (selUtype?.label == "Staff") {
            onChangePropUpdate(va,index)
        }
    };

    function onchangeRole(va: any,index: any) {
        setRole(va);

        if (selUtype?.label == "Staff") {
            onChangeRoleUpdate(va,index)
        }
    }

    const onChangePositionStaff = ({ target: { value } }: RadioChangeEvent , index : any) => {
        setPosition(value);

        if (selUtype?.label == "Staff") {
            onChangePositUpdate(value,index)
        }
    };

    // mode staff
    function onChangePropUpdate(v:any,i: any) { 
        if(!!!vPositionArrC[i]){
            setPropArr((pre: any) => pre.map((val: any,index :any) => {
                if (index === i) {
                    return v
                }
                return val
            }))
        }else{
            vPropArr?.splice(i,1,v);
        }
    }

    function onChangeRoleUpdate(v:any,i: any) { 
        if(!!!vPositionArrC[i]){
            setRoleArr((pre: any) => pre.map((val: any,index :any) => {
                if (index === i) {
                    return v
                }
                return val
            }))
        }else{
            vRoleArr?.splice(i,1,v);
        }
    }

    function onChangePositUpdate(v:any,i: any) {
        if(!!!vPositionArrC[i]){
            setPositionArr((pre: any) => pre.map((val: any,index :any) => {
                if (index === i) {
                    return v
                }
                return val
            }))
        }else{
            vPositionArr?.splice(i,1,v);
        }
    }

    function removeItemstaff(value: any) {
        var filteredArray = vStaffData.filter((e: { id: any; }) => e.id !== value);
        var filteredArrayRemove = vStaffData.filter((e: { id: any; }) => e.id == value);
        
        vremoveItem.push(filteredArrayRemove[0])

        setStaffData(filteredArray);
    }

    //STAFF NEW

    function onChangeProp_NEW(va: any,index: any) {
        setProp(va);
        
        if (selUtype?.label == "Staff") {
            onSetPropCreate(va,index)
        }
    };

    function onSetPropCreate(v:any,i: any) { 
        if(!!!vPropArrC[i]){
            setPropArrC((pre: any) => pre.map((val: any,index :any) => {
                if (index === i) {
                    return v
                }
                return val
            }))
        }else{
            vPropArrC?.splice(i,1,v);
        }
    }

    function onchangeRole_NEW(va: any,index: any) {
        setRole(va);

        if (selUtype?.label == "Staff") {
            onSetRoleCreate(va,index)
        }
    }

    function onSetRoleCreate(v:any,i: any) { // on new
        if(!!!vRoleArrC[i]){
            setRoleArrC((pre: any) => pre.map((val: any,index :any) => {
                if (index === i) {
                    return v
                }
                return val
            }))
        }else{
            vRoleArrC?.splice(i,1,v);
        }
    }

    const onChangePositionStaff_NEW = ({ target: { value } }: RadioChangeEvent , index : any) => {
        setPosition(value);

        if (selUtype?.label == "Staff") {
            onSetPositCreate(value,index)
        }
    };

    function onSetPositCreate(v:any,i: any) { // on new
        if(!!!vPositionArrC[i]){
            setPositionArrC((pre: any) => pre.map((val: any,index :any) => {
                if (index === i) {
                    return v
                }
                return val
            }))
        }else{
            vPositionArrC?.splice(i,1,v);
        }
    }

    function removeItemstaff_NEW(value: any) {
        var filteredPropArr = vRoleArrC.filter((e:any , i:any) => i !== value);
        var filteredRoleArr = vRoleArrC.filter((e:any , i:any) => i !== value);
        var filteredPositArr = vRoleArrC.filter((e:any , i:any) => i !== value);

        setPropArrC(filteredPropArr);
        setRoleArrC(filteredRoleArr);
        setPositionArrC(filteredPositArr);
    }

    function onsetPropArrC() {
        vPropArrC.push(vProp);
        setPropArrC(vPropArrC);
    }

    function onsetRoleArrC() {
        vRoleArrC.push(vRole);
        setRoleArrC(vRoleArrC);
    }

    function onsetPositionArrC() {
        vPositionArrC.push(vPosition);
        setPositionArrC(vPositionArrC);
    }

    function updateUserUpdatedBy() {
        CreateLog({
            resource: log_endpoint,
            values: {
                user_profile: user_data.user_profile.id,
                log_time_stamp: Date.now(),
                log_event: "Edit",
                menu_event: 'users',
                SysPkID: String(selID),
            },
            successNotification: false
        });
    }

    function mutateUpdateUser(valueID: any) {
        if (selUtype?.label == "Admin") {
            {
            TypeMapData !== undefined &&
                TypeMapData?.length > 0 ? 
                    UpdateUser({
                        resource: "user-type-mappings",
                        id: vMappingID,
                        values: {
                            user_profile: valueID,
                            company: vCompany,
                            department: vDepartment,
                            user_sub_type: vPosition,
                            role_management: vRole,
                            status : true
                        },
                        successNotification: false,
                    })
                    :
                    CreateUser({
                        resource: "user-type-mappings",
                        values: {
                            company         : vCompany,
                            department      : vDepartment,
                            user_profile    : valueID,
                            user_type       : selUtype?.id,
                            user_sub_type   : vPosition,
                            role_management : vRole,
                            status          : true
                        },
                        successNotification: false,
                    })
            }
            
        }

        if (selUtype?.label == "Staff") {
            if (vPropArr?.length > 0) {
                for (let i = 0; i <= vPropArr?.length - 1; i++) {
                    console.log(">>> vMappingID",vMappingID)
                    UpdateUser({
                        resource: "user-type-mappings",
                        id: vMappingID[i],
                        values: {
                            company: vCompany,
                            department: vDepartment,
                            user_sub_type: vPositionArr[i],
                            role_management: vRoleArr[i],
                            property: vPropArr[i]
                        },
                        successNotification: false,
                    });
                }
            }
            if (vPropArrC.length > 0) {
                for (let i = 0; i <= vPropArrC.length - 1; i++) {
                    CreateUser({
                        resource: "user-type-mappings",
                        values: {
                            company         : vCompany,
                            department      : vDepartment,
                            user_profile    : valueID,
                            user_type       : selUtype?.id,
                            user_sub_type   : vPositionArrC[i],
                            role_management : vRoleArrC[i],
                            property        : vPropArrC[i],
                            status          : true
                        },
                        successNotification: false,
                    });
                }
            }
            if (vremoveItem?.length > 0) {
                for (let i = 0; i <= vremoveItem?.length - 1; i++) {
                    UpdateUser({
                        resource: "user-type-mappings",
                        id: vremoveItem[i].id,
                        values: {
                            status: false
                        },
                        successNotification: false,
                    });
                }
            }
            
        }
    }

    //=====================================================================
 
    const onReset = () => {
        
        if (tabUserPortal == null) {
            setDataUserType(selUtype?.id)
        }else{
            setDataUserType(tabUserPortal)
            setSearch1stName(undefined);
            setSearchLastName(undefined);
            setSearchEmail(undefined);
            searchFormProps.form?.resetFields()
            searchFormProps.form?.submit()
        }
    };
    
    return (
        <>
            <List
                title={t("user-profiles.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("bc_user-profiles.path")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("bc_user-profiles.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            >

                <Row>
                    <Col span={24}>
                        <Form
                            {...searchFormProps}
                            layout="inline"
                            style={{ marginBottom: "2%" }}
                        >

                            {/* <Form.Item name="role_management">
                                <Select 
                                    {...roleSelProp}
                                    placeholder="Select Role" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                    allowClear
                                    onClear={() => setSearchRole(undefined)}
                                    onSelect={(e : any) => setSearchRole(e)}
                                    // onChange={value => { console.log(value); }} 
                                />
                            </Form.Item> */}

                            <Form.Item name="first_name">
                                <Input 
                                    style={{borderRadius: 5,border:"1px soLid #d9d9d9", height: 30}} 
                                    placeholder="first name" 
                                    // prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                    onChange={e => {
                                        if(e.target.value.length > 0){
                                            setSearch1stName(e.target.value)
                                        }else{
                                            setSearch1stName(undefined)
                                        }
                                    }}
                                />
                            </Form.Item>

                            <Form.Item name="last_name">
                                <Input 
                                    style={{borderRadius: 5,border:"1px soLid #d9d9d9", height: 30}} 
                                    placeholder="last name" 
                                    // prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                    onChange={e => {
                                        if(e.target.value.length > 0){
                                            setSearchLastName(e.target.value)
                                        }else{
                                            setSearchLastName(undefined)
                                        }
                                    }}
                                />
                            </Form.Item>

                            <Form.Item name="email">
                                <Input 
                                    style={{borderRadius: 5,border:"1px soLid #d9d9d9", height: 30}} 
                                    placeholder="email" 
                                    // prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                    onChange={e => {
                                        if(e.target.value.length > 0){
                                            setSearchEmail(e.target.value)
                                        }else{
                                            setSearchEmail(undefined)
                                        }
                                    }}
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>


                    {/* <Col span={12}>
                        
                    </Col> */}
                </Row>

                <Row style={{float:'right'}}>
                    <Col>
                        {/* <Form.Item>
                            <ExportButton style={{ borderRadius: 5 }}
                                onClick={triggerExport}
                                loading={exportLoading}
                            />
                        </Form.Item> */}

                        <Form.Item style={{ marginRight: "0", }}>
                            {/* <CreateButton id="create_btn" disabled={(data_permission?.create ? data_permission?.create == false ? true : false : true) || (selUtype?.label == "Super Admin" && true)} onClick={() => createModalShow()} /> */}
                            <CreateButton id="create_btn" disabled={(data_permission?.create ? data_permission?.create == false ? true : false : true) || (selUtype?.label == "Super Admin" && true)} />
                            {/* <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true}  /> */}
                        </Form.Item>
                    </Col>
                </Row>

                <Form
                    {...searchFormProps}
                >
                    <Form.Item name="user_type" >
                        {/* <Tabs
                            // defaultActiveKey={selUtype?.id}
                            defaultActiveKey={!!tabUserPortal ? tabUserPortal : selUtype?.id}
                            style={{ marginTop: 10, marginBottom: 10 }}
                            onChange={onStatusChange}
                        >
                            {
                                user_type_data?.map((item: any, key: any) => {
                                    return <TabPane key={item.id} tab={<span>{item.type_name}<Badge count={item.count ? item.count : 0} style={{ backgroundColor: '#dddddd', marginLeft: 5 }} /></span>}/>
                                })
                            }
                        </Tabs> */}
                        <Tabs defaultActiveKey={`${tabKeyStatus}`}
                            // onChange={(e) => onStatusChange(
                            //     !!superAdminData && e == "1" ? superAdminData[0]?.id,e : 
                            //     !!adminData && e == "2" ? adminData[0]?.id :
                            //     !!staffDataNew && e == "3" ? staffDataNew[0]?.id : null
                            // )}
                            onChange={(e) => {
                                !!superAdminData && e == "1" ? onStatusChange(superAdminData[0]?.id,e) :
                                !!adminData && e == "2" ? onStatusChange(adminData[0]?.id,e) :
                                !!staffDataNew && e == "3" ? onStatusChange(staffDataNew[0]?.id,e) : null
                                setPagee(e);
                            }
                            }
                        >
                            <TabPane
                                tab={
                                    <span>
                                        Super Admin
                                        <Badge 
                                            count={!!sumStatus ? sumStatus.filter((e:any) => e?.user_type?.type_name == "Super Admin").length : 0} 
                                            style={{ backgroundColor: '#dddddd', marginLeft: 5 }} 
                                        />
                                    </span>
                                }
                                key="1"
                            />
                            <TabPane
                                tab={
                                    <span>
                                        Admin
                                        <Badge 
                                            count={!!sumStatus ? sumStatus.filter((e:any) => e?.user_type?.type_name == "Admin").length : 0} 
                                            style={{ backgroundColor: '#dddddd', marginLeft: 5 }} 
                                        />
                                    </span>
                                }
                                key="2"
                            />
                            <TabPane
                                tab={
                                    <span>
                                        Staff
                                        <Badge 
                                            count={!!sumStatus ? sumStatus.filter((e:any) => e?.user_type?.type_name == "Staff").length : 0} 
                                            style={{ backgroundColor: '#dddddd', marginLeft: 5 }} 
                                        />
                                    </span>
                                }
                                key="3"
                            />
                            </Tabs>
                    </Form.Item>
                </Form>

                <Table 
                    {...tableProps}
                    bordered
                    size="middle"
                    rowKey="id"
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        locale: { items_per_page: "/ Pages" },
                        pageSizeOptions: pageSizeState,
                        onShowSizeChange,
                        total: tableQueryResult?.data?.total,
                        onChange(Current) {
                            setPage(Current)
                        }
                    }}
                    scroll={{ x: 1300 }}
                >

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1} /> */}
 
                    <Table.Column align="center" width={50} dataIndex="avatar" title="  " 
                        render={(value: any, record: any) => {
                            return value ? <Avatar size={32} src={value.url} /> : <Avatar size={32} icon={<Icons.UserOutlined />} />

                        }}
                    />

                    <Table.Column dataIndex="first_name" title={<span>{t("user-profiles.Lname")}</span>} align="center" width={120} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.first_name.localeCompare(b?.first_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            // console.log('record kom', record)
                            return <TextField value={value} />
                        }}
                    />

                    <Table.Column dataIndex="last_name" title="LAST NAME" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.last_name.localeCompare(b?.last_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField value={value} />
                        }}
                    />

                    <Table.Column title="USERNAME" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user?.username.localeCompare(b?.user?.username)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            //{console.log("first",record)}
                            return  <TextField value={record?.user? record?.user.username :"-"} />
                            // return  <TextField value={value.username} />
                        }}
                    />

                    <Table.Column title="EMAIL" align="center" width={200} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user?.email.localeCompare(b?.user?.email)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return  <TextField style={{ fontWeight: 600 }} value={record?.user? record?.user.email:"(ไม่ระบุ)"} />
                        }}
                    />

                    <Table.Column title="TEL" align="center" width={140} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.tel < b?.tel) {return -1}
                            if (a?.tel > b?.tel) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            return <TextField value={record.tel? record.tel:"(ไม่ระบุ)"} />
                        }}
                    />

                    <Table.Column title="COMPANY" align="center" width={200} className="table_colmn_custom_left_value"
                        // sorter={(a:any, b:any) => {
                        //     if (a?.id < b?.id) {return -1}
                        //     if (a?.id > b?.id) {return 1}
                        //     return (0)
                        // }}
                        sorter={(a, b) => user_typemap_data?.length > 0 && user_typemap_data?.find((e: any) => { return e.user_profile?.id === a.id })?.company?.company_name.localeCompare(user_typemap_data?.length > 0 && user_typemap_data?.find((e: any) => { return e.user_profile?.id === b.id })?.company?.company_name)}

                        render={(value: any, record: any) => {
                            return user_typemap_data?.length > 0 && user_typemap_data?.find((e: any) => { return e.user_profile?.id === record.id })?.company?.company_name
                        }}
                    />

                    <Table.Column title=" USER TYPE" width={200} align="center"
                        sorter={(a:any, b:any) => a?.user_type?.type_name.localeCompare(b?.user_type?.type_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField style={{ fontWeight: 600 }} value={record.user_type.type_name? record.user_type.type_name : "ไม่ระบุ"} />
                        }}
                    />

                    {/* <Table.Column dataIndex="user_role" title="USER ROLE" width={200}
                    render={(value:any,record:any)=>{
                        return <>
                            {
                                record.id && user_typemap_data?.length > 0 && user_typemap_data?.map((item:any,index:any)=> {
                                    return <>
                                        {
                                            record.id && item.user_profile && item.user_type && 
                                            <>
                                                {record.id === item.user_profile.id ? 
                                                    item.user_type.type_name == "Admin" ? 
                                                        item.role_management !== null ? item.role_management.custom_role_name : "ไม่ระบุ"
                                                    :
                                                    item.user_type.type_name === "Staff" ?
                                                        item.role_management !== null ? item.role_management.custom_role_name + ", " : "ไม่ระบุ"
                                                    :<></>
                                                :<></>}
                                            </>
                                        }
                                    </>
                                })
                            }     
                        </>
                    }}
                /> */}

                    <Table.Column title="USER ROLE" align="center" width={200}
                        // sorter={(a:any, b:any) => {
                        //     if (a?.id < b?.id) {return -1}
                        //     if (a?.id > b?.id) {return 1}
                        //     return (0)
                        // }}
                        sorter={(a, b) => user_typemap_data?.length > 0 && user_typemap_data?.find((e: any) => { return e.user_profile?.id === a.id })?.role_management?.custom_role_name.localeCompare(user_typemap_data?.length > 0 && user_typemap_data?.find((e: any) => { return e.user_profile?.id === b.id })?.role_management?.custom_role_name)}
                        render={(value: any, record: any) => {
                            return user_typemap_data?.length > 0 && user_typemap_data?.find((e: any) => { return e.user_profile?.id === record.id })?.role_management?.custom_role_name
                        }}
                    />

                    <Table.Column align="center" width={150} title="STATUS"
                        sorter={(a:any, b:any) => {
                            if (a?.deleteStatus < b?.deleteStatus) {return -1}
                            if (a?.deleteStatus > b?.deleteStatus) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            let c = ""
                            let s = ""
                            c = (record.deleteStatus === "N") ? 'green' : (record.deleteStatus === "Y") ? 'red' : ''
                            s = (record.deleteStatus === "N") ? 'ACTIVE' : (record.deleteStatus === "Y") ? 'INACTIVE' : ''
                            return <Tag style={{ borderRadius: "10px" }} color={c}>{s}</Tag>
                        }}
                    />

                    {/* <Table.Column width={190} dataIndex="createdAt" title="REGISTER DATE"
                        render={(value: any, record: any) => {
                            return <TextField value={Moment(value).format('D/MM/yyyy HH:mm')} />
                        }}
                    /> */}

                    <Table.Column title="CREATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.createBy?.first_name.localeCompare(b?.createBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField style={{ fontWeight: 600 }} value={record?.createBy ? record?.createBy.first_name : "-"} />
                        }}
                    />

                    <Table.Column title="CREATED DATE" align="center" width={120}
                        sorter={(a:any, b:any) => a?.createdAt.localeCompare(b?.createdAt)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')} />
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.updateBy?.first_name.localeCompare(b?.updateBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField style={{ fontWeight: 600 }} value={record?.updateBy ? record?.updateBy.first_name : "-"} />
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={120}
                        sorter={(a:any, b:any) => a?.updatedAt.localeCompare(b?.updatedAt)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')} />
                        }}
                    />

                    <Table.Column<IUserProfile>
                        width={120}
                        title="ACTIONS"
                        align="center"
                        dataIndex="actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>
                                {record.user_type.type_code !== "00" && <ShowButton hideText size="small" recordItemId={record.id} />}

                                {
                                    record.deleteStatus === "N" ?
                                        record.user_type.type_code !== "00" && <>
                                            <EditButton hideText size="small" recordItemId={record.id}
                                            //disabled={(data_permission?.update ? data_permission?.update == false ? true : false : true) || (selUtype?.label == "Super Admin" && true)}
                                            //disabled={!!keepDataStatus && keepDataStatus !== undefined ? tabUserPortal == keepDataStatus[0]?.id ? true : false : false}
                                            disabled = {tabKeyStatus == '1' || tabKeyStatus == null ? true : false}
                                            />
                                            {/* <EditButton hideText size="small" recordItemId={record.id} disabled={(data_permission?.update ? data_permission?.update == false ? true : false : true) || (selUtype?.label == "Super Admin" && true)} onClick={() => editModalShow(record.id)}/> */}
                                            <Button size="small" title="Inactive" icon={<Icons.LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true} />
                                        </>
                                        :
                                        record.user_type.type_code !== "00" && <Button size="small" title="Active" icon={<Icons.UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update == false ? true : false} />
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal
                {...createModalProps}
                title={<><Tag style={{textTransform: "uppercase"}} color={selUtype?.label == "Super Admin" ? "orange" : selUtype?.label == "Admin" ? "magenta" : selUtype?.label == "Staff" ? "blue" : "blue"}>{selUtype?.label}</Tag><span>User Management Create</span></>}
                okText={"Create"}
                maskClosable={false}
                width={850}
                afterClose={() => {
                    setID(undefined);
                    setTel(undefined);
                    setpixURL(defaultPixurl);
                    setswpixList(true);
                    setpixChange(undefined);
                    setDisabled(false);

                    //RESET EMAIL
                    setUserEmail(undefined);
                    setcheckEmail(undefined);
                    setdisabledSAVE(true);
                    setMAILC(undefined);
                    setICONSEARCH(<Icons.SearchOutlined />);
                    setSTYPEICON("#626262");
                    setSTYPEINPUT("1px solid #d9d9d9");
                    setTOOLTIP(t("msg-vld-mail.tooltip-search"))

                    createProps?.form?.resetFields();
                }}
                footer={<>
                    <Button onClick={()=> createClose()}>Cancle</Button>
                    <Button type="primary" disabled={vdisabledSAVE} onClick={() => createProps?.form?.submit()}>Create</Button>
                    </>
                }
            >
                <Form
                    {...createProps}
                    layout="vertical"
                    onFinish={(values: any) => {
                        if(vdisabledSAVE == false){
                            values.avatar = vpixChange !== null && vpixChange !== undefined ? vpixChange.id : null
                            values.user_prefix = values.user_prefix;
                            values.first_name = values.first_name;
                            values.last_name = values.last_name;
                            values.email = values.email;
                            values.user_type = selUtype?.id;

                            values.password1 = values.password1;
                            values.password2 = values.password2;

                            values.role = 1;
                            values.isStaff = true;
                            values.deleteStatus = "N";
                            values.createBy = user_data?.user_profile?.id;

                            if (vTel !== null && vTel !== undefined) {
                                values.short_tel = vTel?.short;
                                values.tel = vTel?.phone;
                            }
                            return (
                                createProps.onFinish &&
                                createProps.onFinish(values)
                            );
                        }else if(vdisabledSAVE == true){
                            if(vSTYPEICON == "inherit"){
                                warning();
                                setTOOLTIP(t("msg-vld-mail.tooltip-warning"));
                                setICONSEARCH(<Icons.WarningOutlined />);
                                setSTYPEICON("#d46b08");
                            }else{
                                error();
                                setTOOLTIP(t("msg-vld-mail.tooltip-error"));
                                setICONSEARCH(<Icons.StopOutlined />);
                                setSTYPEICON("#cf1322");
                            }
                            setSTYPEINPUT("1px solid #f5222d");
                        }
                    }}
                >
                    <Row gutter={8}>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                name="avatar"
                                className="form_projectPix"
                                style={{ marginBottom: "5%" }}
                            >
                                <Tooltip placement="left" color="lime" title={"AVATAR PROFILE"}>
                                    <Dragger className="dragger_upload_custom_i_w"
                                        {...vpixlogo}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        multiple
                                        maxCount={1}
                                        onChange={fileChange}
                                        beforeUpload={beforeUpload}
                                        showUploadList={false}
                                        openFileDialogOnClick={false}
                                        ref={refUpload}
                                    >
                                        <div className="dragger_upload_custom_i_m">
                                            <div className="projectPix_hover">
                                                <div>
                                                    {swpixList == false ?
                                                        <>
                                                            <Row gutter={16}>
                                                                <Col span={5} className="gutter-row" />
                                                                <Col span={7} className="gutter-row">
                                                                    <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                </Col>
                                                                <Col span={7} className="gutter-row">
                                                                    <Icons.DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                                </Col>
                                                                <Col span={5} className="gutter-row" />
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <Row gutter={16}>
                                                                <Col span={5} className="gutter-row" />
                                                                <Col span={14} className="gutter-row">
                                                                    <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                </Col>
                                                                <Col span={5} className="gutter-row" />
                                                            </Row>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <Avatar className="projectPix" src={pixURL} style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
                                        </div>
                                    </Dragger>
                                </Tooltip>
                            </Form.Item>
                        </Col>

                        <Col span={18} className="gutter-row">
                            <Row gutter={8}>
                                <Col span={6} className="gutter-row">
                                    <Form.Item name="user_prefix" label={t("user-profiles.prefix")}>
                                        <Select placeholder={t("select.sel")} {...selPrefix} />
                                    </Form.Item>
                                </Col>

                                <Col span={9} className="gutter-row">
                                    <Form.Item label={t("user-profiles.name")} name="first_name" rules={[{ required: true, message: 'Please input first name' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={9} className="gutter-row">
                                    <Form.Item label={t("user-profiles.lastname")} name="last_name" rules={[{ required: true, message: 'Please input last name' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={12} className="gutter-row">
                                    <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input email' }, { type: 'email', message: 'email must be formatted correctly' }]}>
                                        <Input 
                                            value={vUserEmail} 
                                            onChange={e => {
                                                onchangeEmail(e.target.value)
                                                if(vSTYPEINPUT == "1px solid #f5222d"){
                                                    setSTYPEINPUT("1px solid #d9d9d9")
                                                }
                                            }}
                                            style={{border : vSTYPEINPUT}}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={2} className="gutter-row">
                                    <Form.Item label=" ">
                                        <Tooltip placement="top" title={vTOOLTIP}>
                                            <Button style={{borderRadius: "5px",color: vSTYPEICON}} icon={vICONSEARCH} onClick={() => handlesearchemail(vUserEmail)}/>
                                        </Tooltip>
                                    </Form.Item>
                                </Col>

                                <Col span={10} className="gutter-row">
                                    <Form.Item name="tel" label={t("user-profiles.tel")}>
                                        <ConfigProvider locale={en}>
                                            <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{ height: "32px", borderRadius: "5px", padding: "0" }}
                                                onChange={(v) => { setTel(v) }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                minLength={9}
                                                maxLength={12}
                                            />
                                        </ConfigProvider>
                                    </Form.Item>
                                </Col>

                                <Col span={12} className="gutter-row">
                                    <Form.Item
                                        name="password1"
                                        label={t("staff-profiles.pwd1")}
                                        rules={[
                                            {
                                                required: true,
                                                // message: 'please input your password!',
                                                message: '',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    // if (value.length >= 8 && value.length <= 16) {
                                                    //     return Promise.resolve();
                                                    // }
                                                    // return Promise.reject(new Error('Password must be 8-16 characters only '));

                                                    if (value.length >= 8 && value.length <= 16) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length <= 8 && value.length >= 16) {
                                                        return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                    }

                                                    if (value.length < 0) {
                                                        return Promise.reject(new Error('please input your password'));
                                                    }

                                                    if (value == "") {
                                                        return Promise.reject(new Error('please input your password'));
                                                    }

                                                },
                                            }),
                                        ]}
                                        hasFeedback

                                    >
                                        <Input.Password style={{ borderRadius: 5 }}
                                            type="password"
                                            placeholder="Enter your password"
                                            iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                            minLength={8}
                                            maxLength={16}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12} className="gutter-row">
                                    <Form.Item
                                        name="password2"
                                        label={t("staff-profiles.pwd2")}
                                        dependencies={['password1']}
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please confirm your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password1') === value) {
                                                    return Promise.resolve();
                                                }
                                                // return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                return Promise.reject(new Error('Password is not match'));
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input.Password style={{ borderRadius: 5 }}
                                            type="password"
                                            placeholder="Enter your password"
                                            iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                            minLength={8}
                                            maxLength={16}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider style={{ margin: "10px" }} />

                    {selUtype && selUtype?.label == "Admin" ?
                        <>
                            <Row gutter={5} style={{ marginTop: "1rem" }}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                <Select
                                                    {...selComp}
                                                    onChange={(e) => {
                                                        setCompany(e);
                                                        createProps?.form?.resetFields(["SelDept"]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                <Select
                                                    {...selDepart}
                                                    onChange={(e) => {
                                                        setDepartment(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={5}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Role</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name={"role"} rules={[{ required: true, message: 'Please select role' }]}>
                                                <Select {...selRole} onChange={(e) => setRole(e)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Position</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item
                                                name="position"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select position'
                                                    },
                                                ]}
                                            >
                                                <Radio.Group name="radiogroup" onChange={(e) => setPosition(e?.target?.value)}>
                                                    {
                                                        positiondt?.map((el: any, index: any) => {
                                                            return <>
                                                                <Radio key={el.id} value={el.id} style={{ fontSize: "0.7vw", color: "#000" }}>{el.sub_type_name}</Radio>
                                                            </>
                                                        })
                                                    }
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                        : selUtype?.label == "Staff" &&
                        <>
                            <Row gutter={5} style={{ marginTop: "1rem" }}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                <Select
                                                    {...selComp}
                                                    onChange={(e) => {
                                                        setCompany(e);
                                                        createProps?.form?.resetFields(["SelDept"]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                <Select
                                                    {...selDepart}
                                                    onChange={(e) => {
                                                        setDepartment(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={5}>
                                <Col className="gutter-row" span={24}>
                                    <Form.List name="fields">
                                        {(fields, { add, remove }) => {
                                            return (
                                            <div>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={6}>
                                                        <Form.Item>
                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Properties Management</span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row" span={4}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => { add(); toggleDisabled();}}
                                                                style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                                disabled={disabled}
                                                            >
                                                                <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                {fields.map((field, index) => (
                                                    <div key={field.key}>
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "0.5rem"}}><span>{index+1} .</span></div> </Col>
                                                            <Col className="gutter-row" span={13}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={6}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>* </span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Properties</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={18}>
                                                                        <Form.Item
                                                                            // name={"property_" + index + 1}
                                                                            // name={index + 1}
                                                                            // name=""
                                                                            name={[index, 'property']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select property'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select {...selProp} onChange={(e) => setProp(e)}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={10}>
                                                                <Row gutter={16}>
                                                                    <Col className="gutter-row" span={4}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>* </span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Role</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={20}>
                                                                        <Form.Item
                                                                            name={[index, 'role']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select role'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select {...selRole} onChange={(e) => setRole(e)}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={1}/>

                                                            <Col className="gutter-row" span={14}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={5}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>* </span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Position</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={19}>
                                                                        <Form.Item
                                                                            name={[index, 'position']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select position'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Radio.Group name="radiogroup" onChange={(e) => setPosition(e?.target?.value)}>
                                                                                {
                                                                                    positiondt?.map((el:any,index:any) => {
                                                                                        return <>
                                                                                                <Radio key={el.id} value={el.id} style={{fontSize: "0.7vw",color: "#000"}}>{el.sub_type_name}</Radio>
                                                                                            </>
                                                                                        })
                                                                                }
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={4}>
                                                                {
                                                                    vProp && vRole && vPosition &&
                                                                        <>{fields?.length == index+1 &&
                                                                            <Button
                                                                                size="small"
                                                                                onClick={() => { onsetPropArr(); onsetRoleArr(); onsetPositionArr(); clearArr(); toggleDisabled();}}
                                                                                danger
                                                                                style={{marginRight: "3%",background: "#fff",borderColor: "#A1D64F",color: "#A1D64F"}}
                                                                            >
                                                                                <Icons.CheckOutlined/>
                                                                            </Button>
                                                                        }
                                                                        </>
                                                                }

                                                                {
                                                                    fields.length > 0 && (
                                                                        <Button
                                                                            size="small"
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => { remove(field.name); clearArr(); toggleDisabledX(); }}
                                                                            danger
                                                                        >
                                                                            <Icons.CloseOutlined />
                                                                        </Button>
                                                                    )
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <div style={{marginBottom: "1vh", borderTop: fields.length - 1 == index ? "0px":"1px solid #dedede"}} />
                                                    </div>
                                                ))}
                                            </div>
                                            );
                                        }}
                                    </Form.List>
                                </Col>
                            </Row>
                        </>
                    }
                </Form>
            </Modal>

            <Modal
                {...editModalProps}
                title={<><Tag style={{textTransform: "uppercase"}} color={selUtype?.label == "Super Admin" ? "orange" : selUtype?.label == "Admin" ? "magenta" : selUtype?.label == "Staff" ? "blue" : "blue"}>{selUtype?.label}</Tag><span>User Management Edit</span></>}
                okText={"Save"}
                width={850}
                maskClosable={false}
                afterClose={() => {
                    setID(undefined);

                    setMappingID(undefined);
                    setremoveItem([]);

                    setProp(undefined);
                    setPropArr([]);
                    setPropArrC([]);

                    setRole(undefined);
                    setRoleArr([]);
                    setRoleArrC([]);

                    setPosition(undefined);
                    setPositionArr([]);
                    setPositionArrC([]);

                    setStaffData([]);

                    setID(undefined);
                    setTel(undefined);
                    setpixURL(defaultPixurl);
                    setswpixList(true);
                    setpixChange(undefined);
                    setDisabled(false);
                    
                    editProps?.form?.resetFields();
                }}
                footer={<>
                    <Button onClick={()=> editClose()}>Cancle</Button>
                    <Button type="primary" disabled={vdisabledSAVE} onClick={() => editProps?.form?.submit()}>Save</Button>
                    </>
                }
            >
                <Form
                    {...editProps}
                    layout="vertical"
                    onFinish={(values: any) => {
                        if(vdisabledSAVE == false){
                            updateUserUpdatedBy();
    
                            values.avatar = vpixChange !== null && vpixChange !== undefined ? vpixChange.id : null
                            values.user_prefix = values.user_prefix;
                            values.first_name = values.first_name;
                            values.last_name = values.last_name;
                            values.email = values.email;
                            values.user_type = selUtype?.id;

                            if (vTel !== null && vTel !== undefined) {
                                values.short_tel = vTel?.short;
                                values.tel = vTel?.phone;
                            }

                            values.updateBy = user_data.user_profile.id
                            values.updatedBy = user_data.user_profile.id
                            
                            mutateUpdateUser(selID);
    
                            return (
                                editProps.onFinish &&
                                editProps.onFinish(values)
                            );
                        }else if(vdisabledSAVE == true){
                            if(vSTYPEICON == "inherit"){
                                warning();
                                setTOOLTIP(t("msg-vld-mail.tooltip-warning"));
                                setICONSEARCH(<Icons.WarningOutlined />);
                                setSTYPEICON("#d46b08");
                            }else{
                                error();
                                setTOOLTIP(t("msg-vld-mail.tooltip-error"));
                                setICONSEARCH(<Icons.StopOutlined />);
                                setSTYPEICON("#cf1322");
                            }
                            setSTYPEINPUT("1px solid #f5222d");
                        }
                    }}
                >
                    <Row gutter={8}>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                name="avatar"
                                className="form_projectPix"
                                style={{ marginBottom: "5%" }}
                            >
                                <Tooltip placement="left" color="lime" title={"AVATAR PROFILE"}>
                                    <Dragger className="dragger_upload_custom_i_w"
                                        {...vpixlogo}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        multiple
                                        maxCount={1}
                                        onChange={fileChange}
                                        beforeUpload={beforeUpload}
                                        showUploadList={false}
                                        openFileDialogOnClick={false}
                                        ref={refUpload}
                                    >
                                        <div className="dragger_upload_custom_i_m">
                                            <div className="projectPix_hover">
                                                <div>
                                                    {swpixList == false ?
                                                        <>
                                                            <Row gutter={16}>
                                                                <Col span={5} className="gutter-row" />
                                                                <Col span={7} className="gutter-row">
                                                                    <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                </Col>
                                                                <Col span={7} className="gutter-row">
                                                                    <Icons.DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                                </Col>
                                                                <Col span={5} className="gutter-row" />
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            <Row gutter={16}>
                                                                <Col span={5} className="gutter-row" />
                                                                <Col span={14} className="gutter-row">
                                                                    <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                                </Col>
                                                                <Col span={5} className="gutter-row" />
                                                            </Row>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                            <Avatar className="projectPix" src={pixURL} style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
                                        </div>
                                    </Dragger>
                                </Tooltip>
                            </Form.Item>
                        </Col>

                        <Col span={18} className="gutter-row">
                            <Row gutter={8}>
                                <Col span={6} className="gutter-row">
                                    <Form.Item name={["user_prefix","id"]} label={t("user-profiles.prefix")}>
                                        <Select placeholder={t("select.sel")} {...selPrefix} />
                                    </Form.Item>
                                </Col>

                                <Col span={9} className="gutter-row">
                                    <Form.Item label={t("user-profiles.name")} name="first_name" rules={[{ required: true, message: 'Please input first name' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={9} className="gutter-row">
                                    <Form.Item label={t("user-profiles.lastname")} name="last_name" rules={[{ required: true, message: 'Please input last name' }]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={14} className="gutter-row">
                                    <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input email' }, { type: 'email', message: 'email must be formatted correctly' }]} className="input_disable_onshow">
                                        <Input 
                                            value={vUserEmail}
                                            disabled={true}
                                            onChange={e => {
                                                onchangeEmail(e.target.value)
                                                if(vSTYPEINPUT == "1px solid #f5222d"){
                                                    setSTYPEINPUT("1px solid #d9d9d9")
                                                }
                                            }}
                                            style={{border : vSTYPEINPUT}}
                                        />
                                    </Form.Item>
                                </Col>

                                {/* <Col span={2} className="gutter-row">
                                    <Form.Item label=" ">
                                        <Tooltip placement="top" title={vTOOLTIP}>
                                            <Button style={{borderRadius: "5px",color: vSTYPEICON}} icon={vICONSEARCH} onClick={() => handlesearchemail(vUserEmail)}/>
                                        </Tooltip>
                                    </Form.Item>
                                </Col> */}

                                <Col span={10} className="gutter-row">
                                    <Form.Item name="tel" label={t("user-profiles.tel")}>
                                        <ConfigProvider locale={en}>
                                            <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{ height: "32px", borderRadius: "5px", padding: "0" }}
                                                value={vTel}
                                                onChange={(v) => { setTel(v) }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                minLength={9}
                                                maxLength={12}
                                            />
                                        </ConfigProvider>
                                    </Form.Item>
                                </Col>

                                <Col span={4} className="gutter-row">
                                    <Form.Item label=" ">
                                        <Switch
                                            checkedChildren={<Tooltip title="Change Password">Change</Tooltip>}
                                            unCheckedChildren={<Tooltip title="Disable">NotChange</Tooltip>}
                                            defaultChecked={false}
                                            onChange={(e) => setswpwd(e)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={10} className="gutter-row">
                                    {swpwd == true ?
                                        <Form.Item
                                            name="password1"
                                            label={t("staff-profiles.pwd1")}
                                            rules={[
                                                {
                                                    required: true,
                                                    // message: 'please input your password!',
                                                    message: '',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        // if (value.length >= 8 && value.length <= 16) {
                                                        //     return Promise.resolve();
                                                        // }
                                                        // return Promise.reject(new Error('Password must be 8-16 characters only '));

                                                        if (value.length >= 8 && value.length <= 16) {
                                                            return Promise.resolve();
                                                        }

                                                        if (value.length <= 8 && value.length >= 16) {
                                                            return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                        }

                                                        if (value.length < 0) {
                                                            return Promise.reject(new Error('please input your password'));
                                                        }

                                                        if (value == "") {
                                                            return Promise.reject(new Error('please input your password'));
                                                        }

                                                    },
                                                }),
                                            ]}
                                            hasFeedback

                                        >
                                            <Input.Password style={{ borderRadius: 5 }}
                                                type="password"
                                                placeholder="Enter your password"
                                                iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                                minLength={8}
                                                maxLength={16}
                                                disabled={!swpwd}
                                            />
                                        </Form.Item>
                                        :
                                        <Form.Item label={t("staff-profiles.pwd1")}>
                                            <Input disabled={true} />
                                        </Form.Item>
                                    }
                                </Col>

                                <Col span={10} className="gutter-row">
                                    {swpwd == true ?
                                        <Form.Item
                                            name="password2"
                                            label={t("staff-profiles.pwd2")}
                                            dependencies={['password1']}
                                            hasFeedback
                                            rules={[{ required: true, message: 'Please confirm your password!' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password1') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    // return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                    return Promise.reject(new Error('Password is not match'));
                                                },
                                            }),
                                            ]}
                                        >
                                            <Input.Password style={{ borderRadius: 5 }}
                                                type="password"
                                                placeholder="Enter your password"
                                                iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                                minLength={8}
                                                maxLength={16}
                                                disabled={!swpwd}
                                            />
                                        </Form.Item>
                                        :
                                        <Form.Item label={t("staff-profiles.pwd2")}>
                                            <Input disabled={true} />
                                        </Form.Item>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider style={{ margin: "10px" }} />

                    {selUtype && selUtype?.label == "Admin" ?
                        <>
                            <Row gutter={5} style={{ marginTop: "1rem" }}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                <Select
                                                    {...selComp}
                                                    onChange={(e) => {
                                                        setCompany(e);
                                                        createProps?.form?.resetFields(["SelDept"]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                <Select
                                                    {...selDepart}
                                                    onChange={(e) => {
                                                        setDepartment(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={5}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Role</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name={"role"} rules={[{ required: true, message: 'Please select role' }]}>
                                                <Select {...selRole} onChange={(e) => setRole(e)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Position</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item
                                                // name="position"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select position'
                                                    },
                                                ]}
                                            >
                                                <Radio.Group name="radiogroup" defaultValue={vPosition} value={vPosition} onChange={(e) => setPosition(e?.target?.value)}>
                                                    {
                                                        positiondt?.map((el: any, index: any) => {
                                                            return <>
                                                                <Radio key={el.id} value={el.id} style={{ fontSize: "0.7vw", color: "#000" }}>{el.sub_type_name}</Radio>
                                                            </>
                                                        })
                                                    }
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                        : selUtype?.label == "Staff" &&
                        <>
                            <Row gutter={5} style={{ marginTop: "1rem" }}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                <Select
                                                    {...selComp}
                                                    onChange={(e) => {
                                                        setCompany(e);
                                                        createProps?.form?.resetFields(["SelDept"]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                <Select
                                                    {...selDepart}
                                                    onChange={(e) => {
                                                        setDepartment(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={5}>
                                <Col className="gutter-row" span={24}>
                                    <Form.List name="fields">
                                        {(fields, { add, remove }) => {
                                            return (
                                            <div>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={6}>
                                                        <Form.Item>
                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Properties Management</span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row" span={4}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => { add(); toggleDisabled();}}
                                                                style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                                disabled={disabled}
                                                            >
                                                                <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                {vStaffData && vStaffData?.length > 0 && vStaffData?.map((el:any,index:any) => {
                                                    return <div key={index}>
                                                            <Row gutter={16}>
                                                                <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "20%"}}><span>{index+1} .</span></div> </Col>

                                                                <Col className="gutter-row" span={13}>
                                                                    <Row gutter={8}>
                                                                        <Col className="gutter-row" span={6}>
                                                                            <Form.Item>
                                                                                <span style={{fontSize: "0.7vw",color: "#000"}}>Properties</span>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" span={18}>
                                                                            <Form.Item>
                                                                                <Select {...selProp} onChange={(val)=> onChangeProp(val ,index)} defaultValue={el.property !== null ? el.property.id : null}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col className="gutter-row" span={10}>
                                                                    <Row gutter={16}>
                                                                        <Col className="gutter-row" span={4}>
                                                                            <Form.Item>
                                                                                <span  style={{fontSize: "0.7vw",color: "#000"}}>Role</span>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" span={20}>
                                                                            <Form.Item>
                                                                                <Select {...selRole} onChange={(val)=> onchangeRole(val ,index)} defaultValue={el.role_management !== null ? el.role_management.id : null}/>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col className="gutter-row" span={1}/>

                                                                <Col className="gutter-row" span={14}>
                                                                    <Row gutter={8}>
                                                                        <Col className="gutter-row" span={5}>
                                                                            <Form.Item>
                                                                                <span  style={{fontSize: "0.7vw",color: "#000"}}>Position</span>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" span={19}>
                                                                            <Form.Item>
                                                                                <Radio.Group name="radiogroup" onChange={(e) => onChangePositionStaff(e,index)} defaultValue={el.user_sub_type !== null ? el.user_sub_type.id : null}>
                                                                                    {
                                                                                        positiondt?.map((el:any,index:any) => {
                                                                                            return <Radio key={el.id} value={el.id} style={{fontSize: "0.7vw",color: "#000"}}>{el.sub_type_name}</Radio>
                                                                                        })
                                                                                    }
                                                                                </Radio.Group>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col span={4}>
                                                                    {
                                                                        vStaffData?.length > 0 ? (
                                                                            <Button
                                                                                size="small"
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => { removeItemstaff(el.id); clearArr();}}
                                                                                danger
                                                                            >
                                                                                <Icons.CloseOutlined />
                                                                            </Button>
                                                                        ) : null
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            <div style={{marginBottom: "1vh", borderTop: fields.length - 1 == index ? "0px":"1px solid #dedede"}} />
                                                        </div>
                                                    })
                                                }

                                                {fields.map((field, index) => (
                                                    <div key={field.key}>
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "20%"}}><span>{index + vStaffData?.length + 1} .</span></div> </Col>
                                                            <Col className="gutter-row" span={13}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={6}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Properties</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={18}>
                                                                        <Form.Item
                                                                            name={[index, 'property']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select property'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select {...selProp} onChange={(val)=> onChangeProp_NEW(val ,index)}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={10}>
                                                                <Row gutter={16}>
                                                                    <Col className="gutter-row" span={4}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Role</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={20}>
                                                                        <Form.Item
                                                                            name={[index, 'role']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select role'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select {...selRole} onChange={(val)=> onchangeRole_NEW(val ,index)}/>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={1}/>

                                                            <Col className="gutter-row" span={14}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={5}>
                                                                        <Form.Item>
                                                                            <span>Position</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={19}>
                                                                        <Form.Item
                                                                            name={[index, 'position']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select position'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Radio.Group name="radiogroup" onChange={(e) => onChangePositionStaff_NEW(e,index)}>
                                                                                {
                                                                                    positiondt?.map((el:any,index:any) => {
                                                                                        return <Radio key={el.id} value={el.id} style={{fontSize: "0.7vw",color: "#000"}}>{el.sub_type_name}</Radio>
                                                                                    })
                                                                                }
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={4}>
                                                                {
                                                                    vProp && vRole && vPosition &&
                                                                        <>{fields.length == index+1 &&
                                                                            <Button
                                                                                size="small"
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => { onsetPropArrC(); onsetRoleArrC(); onsetPositionArrC(); clearArr(); toggleDisabled();}}
                                                                                danger
                                                                                style={{marginRight: "3%",background: "#fff",borderColor: "#A1D64F",color: "#A1D64F"}}
                                                                            >
                                                                                <Icons.CheckOutlined/>
                                                                            </Button>
                                                                        }
                                                                        </>
                                                                }

                                                                {
                                                                    fields.length > 0 ? (
                                                                        <Button
                                                                            size="small"
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => { remove(field.name); clearArr(); removeItemstaff_NEW(index); toggleDisabledX();}}
                                                                            danger
                                                                        >
                                                                            <Icons.CloseOutlined />
                                                                        </Button>
                                                                    ) : null
                                                                }
                                                            </Col>
                                                        </Row>

                                                        <div style={{marginBottom: "1vh", borderTop: fields.length - 1 == index ? "0px":"1px solid #dedede"}} />
                                                    </div>
                                                ))}
                                            </div>
                                            );
                                        }}
                                    </Form.List>
                                </Col>
                            </Row>
                        </>
                    }
                </Form>
            </Modal>
        </>
    );
};
