import { Breadcrumb, Button, Col, Edit, Form, Icons, Input, List, Radio, Row, Select, Spin, notification } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { useState } from "react";
import { petURL, rentSaleURL } from "../../../constants";
import axios from "axios";

let optionpettype: any = undefined;
export const RentTransportEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { TextArea } = Input;
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();

    const [formCustom] = Form.useForm();
    
    const [valuepetType, setpetType] = useState<any>();
    const [vacinject, setvacinject] = useState<any>(null);
    const [vactype, setvactype] = useState<boolean>(true);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [injectRound, setInjectRound] = useState<boolean>(true);

    let getID = window?.location?.href.split("/");
    let pageID: any = getID[getID?.length -1];

    const { data: dataLoad } = useCustom<any>({
        url: `${rentSaleURL}/api/rentsale/findOneTransport`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log('dataLoad', dataLoad)

                setpetType(dataLoad?.pet_type);
                setvacinject(dataLoad?.vac_inject_yearly);
                setvactype(dataLoad?.vaccine_type)

                // vac_inject_round: boolean; // true == ครั้งเดียว, false == ทุก ๆ xxx ปี

                formCustom?.setFieldsValue({
                    name_th: dataLoad?.name,
                    name_en: dataLoad?.name_en,
                    // link: dataLoad?.link,
                })

                setLoading(false)

            }
        }
    }) 

    // const { data: datapettype } = useCustom<any>({
    //     url: `${petURL}/api/lscpet/findActivePetType`,
    //     method: "get",
    //     queryOptions:{
    //         onSuccess(data) {
    //             let dataLoad: any = data?.data;
    //             let newDT: any = [];

    //             for (let index = 0; index < dataLoad?.length; index++) {
    //                 newDT?.push({
    //                     label: dataLoad[index]?.pet_type_name,
    //                     value: dataLoad[index]?._id
    //                 })
    //             }

    //             if(newDT?.length > 0){
    //                 optionpettype = newDT;
    //             }

    //             setLoading(false);
    //         },
    //     },
    // });

    async function submitDT(values:any){
        let body: any = {
            "name": values?.name_th,
            "name_en": values?.name_en,
            "link": values?.link,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }
        
        let submit = await axios.put(rentSaleURL + "/api/rentsale/updateTransport?id=" + pageID, body, { headers: { } })
        .then(response => {
            successNoti('Successfully edit transportation');
            replace("/rent-sale/rentsale-masters")
        })
        .catch(error => {
            console.error('error ',error);
            faildNoti('Edit is Failed');
        });
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    const handleOnChange = (e: any) => {
        setInjectRound(e?.target?.value)
        if (e?.target?.value === true) {
          setvacinject(null);
        }
    };
      
    
    return(
        <Spin size="large" spinning={isLoading}>
            <Edit pageHeaderProps={{extra: <></>, onBack(e?) {replace("/rent-sale/rentsale-masters")}}} saveButtonProps={{hidden: true}}>
                <List 
                    // title={t("pet-vaccine.title-edit")}
                    title=""
                    pageHeaderProps={{
                        // breadcrumb: <Breadcrumb>
                        //     <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        //     <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                        //     <Breadcrumb.Item>{t("pet-vaccine.title")}</Breadcrumb.Item>
                        //     <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-vaccine.title-edit")}</span></Breadcrumb.Item>
                        // </Breadcrumb>,
                        extra : <></>
                    }}
                />

                <div style={{width: "50%"}}>
                    <Form 
                        form={formCustom}
                        layout="vertical"
                        onFinish={(values:any) => {
                            submitDT(values);
                        }}
                    >
                        <Row gutter={[24, 16]}>
                            <Col className="gutter-row" span={24}>
                                <Form.Item 
                                    label={t("Place Name (TH)")}
                                    name="name_th"
                                    rules={[
                                        {
                                            required: true, message: 'please enter name th.'
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item 
                                    label={t("Place Name (EN)")}
                                    name="name_en"
                                    rules={[
                                        {
                                            required: true, message: 'please enter name en.'
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>

                            {/* <Col className="gutter-row" span={24}>
                                <Form.Item 
                                    label={t("Link")}
                                    name="link"
                                    rules={[
                                        {
                                            required: true, message: 'please enter link.'
                                        },
                                    ]}
                                >
                                    <TextArea/>
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </Form>
                </div>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button type="primary" onClick={() => formCustom?.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
                </Row>
            </Edit>
        </Spin>
    )
}