/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Divider, Switch, Input, Typography, useSelect, InputNumber, Upload, RcFile, message, notification, Radio, Button, Icons } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL, ROCKET_URL, TOKEN_KEY } from "../../../constants";

import axios from "axios";
import { getValueProps, useStrapiUpload } from "@pankod/refine-strapi-v4";

export const RepairDocumentCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    const token = localStorage.getItem(TOKEN_KEY);
    const { replace, list} = useNavigation();

    const tabTermActive = localStorage.getItem("keyTabTermRepair");

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: CreateDoc } = useCreate<any>();
    const { TextArea } = Input;
    const { Text } = Typography;
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { confirm } = Modal;
    // const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    const log_endpoint = "even-logs";
    const [text, setText] = useState('');
    const [DocTh, SetDocTh] = useState<any>();
    const [DocEn, SetDocEn] = useState<any>();
    const [CoverEn, SetCoverEn] = useState<any>();
    const [CoverTh, SetCoverTh] = useState<any>();
    const [vInfoTh, setInfoTh] = useState<any>();
    const [vInfoEn, setInfoEn] = useState<any>();
    const [Stat, SetStat] = useState<any>(true);


    const [DocName, SetDocName] = useState<any>(undefined);
    const [DocNameEn, SetDocNameEn] = useState<any>(undefined);

    const [LinkVidTh, SetLinkVidTh] = useState<any>(undefined);
    const [LinkVidEn, SetLinkVidEn] = useState<any>(undefined);


    const { form, formProps, saveButtonProps } = useForm<IFacilityBooking,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource : log_endpoint,
                values : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(),
                    log_event               : "Create",
                    menu_event              : 'hcu-settings',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
        errorNotification(error:any, values:any, resource:any) {
            return {
                message: error.toString(),
                type: "error" ,
                undoableTimeout: 1000
            }
        },
        onMutationError(error, variables, context) {
            console.log('error', error)
            console.log('variables', variables)
            console.log('context', context)
        },
    });

    const handleChange = (e:any) => {
        setText(e.target.value);
    };

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            //  {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },

        ]
    });

    const { selectProps: selCategory } = useSelect<any>({
        resource    : "repair-document-categories",
        optionLabel : "category_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    function fileChangeDoc(e:any, type:any){
        console.log('e', e)
        console.log('type', type)
        if(type == "en"){
            if(e.file.response){
                let res = e.file.response;
                console.log(res)
                SetDocEn(res[0].id)
            }
        }else{
            if(e.file.response){
                let res = e.file.response;
                console.log(res)
                SetDocTh(res[0].id)
            }
        }
    }

    function fileChangeCover(e:any, type:any){
        console.log('e', e)
        console.log('type', type)
        if(type == "en"){
            if(e.file.response){
                let res = e.file.response;
                console.log(res)
                SetCoverEn(res[0].id)
            }
        }else{
            if(e.file.response){
                let res = e.file.response;
                console.log(res)
                SetCoverTh(res[0].id)
            }
        }
    }

    const { ...uploadProps } = useStrapiUpload(
        {
            maxCount: 1,
        }
    );
    
    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully created',
        });
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isJpgOrPng) {
          message.error('You can only upload PDF/doc/docx file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const beforeUploadPic = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        console.log('isJpgOrPng', isJpgOrPng)
        console.log('isLt2M', isLt2M)

        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    async function createDoc(values:any) {

        CreateDoc({
            resource : 'repair-documents',
            values : {
                doc_name : DocName !== undefined ? DocName : '-' ,
                doc_name_en : DocNameEn !== undefined ? DocNameEn : '-',
                cover : CoverTh,
                cover_en : CoverEn,
                document : DocTh,
                document_en : DocEn,
                link_vid : LinkVidTh,
                link_vid_en : LinkVidEn,
                deleteStatus: Stat ? "N" : "Y",
                property: values.property,
                repair_document_category: values.category,
                createBy: user_data.user_profile
            },
            successNotification : false
        });

        successNoti();
        replace("/repair-document")
    }

    // console.log('DocTh',DocTh);
    // console.log('DocEn',DocEn);
    // console.log('vInfoTh',vInfoTh);
    // console.log('vInfoEn',vInfoEn);

    return (
    <Create 
        saveButtonProps={{ hidden: true }}
        title="สร้างเอกสาร"
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{tabTermActive == "TAC" ? t("hcu-term.title") : t("hcu-info.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>เอกสาร</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">สร้างเอกสาร</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                {...formProps}
                layout="vertical"
                onFinish={(values:any) => {
                    values.property = values.property.id;
                    values.category = values.category.id;
                    // values.topic = values.topic;

                    createDoc(values);
                }}
            >
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                style={{width:'95%'}}
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                // name="category"
                                name={["category", "id"]}
                                label="category"
                                style={{width:'95%'}}
                                rules={[
                                    {
                                        required: true, message: "Please select category"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selCategory}/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        style={{width:'95%'}}
                                        name="status"
                                        label="สถานะ"
                                        rules={[
                                            {
                                                required: true, message: "Please select"
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            onChange={(e:any) => SetStat(e.target.value)}
                                            // defaultValue={recordData.coupon_condition ? 1 : 2}
                                            // value={vRaCalRight}
                                        >
                                            <Radio value={true} style={{ marginBottom: "0%" }}> ใช้งาน </Radio>
                                            <Radio value={false} style={{ marginBottom: "0%" }}> ไม่ใช้งาน </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    <Divider />

                    {/* ================================================================================ */}
                    {/* ======================================= THAI =================================== */}
                    <Row gutter={82}>
                        <Col span={24}>
                            <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                                ภาษาไทย (TH)
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="ชื่อเอกสาร"
                                name="doc_name"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="document name" 
                                    value={DocName}
                                    onChange={(e) => SetDocName(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Link VDO"
                                name="link_vid"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidTh}
                                    onChange={(e) => SetLinkVidTh(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ไฟล์เอกสาร (.pdf, .doc, .docx)"
                                name="doc_th"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload Document.'
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    style={{ borderRadius: 10, width:'95%' }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}

                                    listType="picture"
                                    // multiple
                                    {...uploadProps}
                                    // onChange={fileChange}
                                    onChange={(e:any) => fileChangeDoc(e, "th")}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ภาพปก"
                                name="cover_th"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload cover.'
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    style={{ borderRadius: 10, width:'95%' }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}

                                    listType="picture"
                                    {...uploadProps}
                                    onChange={(e:any) => fileChangeCover(e, "th")}
                                    beforeUpload={beforeUploadPic}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Link VDO"
                                name="link_vid"
                                style={{width:'95%', marginTop:'-5%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidTh}
                                    onChange={(e) => SetLinkVidTh(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Divider />

                    {/* ================================================================================ */}
                    {/* ======================================= ENG ==================================== */}
                    <Row gutter={82}>
                        <Col span={24}>
                            <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                                ภาษาอังกฤษ (EN)
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="ชื่อเอกสาร"
                                name="doc_name_en"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="document name en" 
                                    value={DocNameEn}
                                    onChange={(e) => SetDocNameEn(e.target.value)} 
                                    // value={vReasonRejectOwner} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Link VDO"
                                name="link_vid_en"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidEn}
                                    onChange={(e) => SetLinkVidEn(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ไฟล์เอกสาร (.pdf, .doc, .docx)"
                                name="doc_en"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload Document.'
                                    },
                                ]}
                            >

                                <Upload.Dragger
                                    style={{ borderRadius: 10, width:'95%' }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}

                                    listType="picture"
                                    // multiple
                                    {...uploadProps}
                                    // onChange={fileChange}
                                    onChange={(e:any) => fileChangeDoc(e, "en")}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ภาพปก"
                                name="cover_en"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload cover'
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    style={{ borderRadius: 10, width:'95%' }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}

                                    listType="picture"
                                    {...uploadProps}
                                    onChange={(e:any) => fileChangeCover(e, "en")}
                                    beforeUpload={beforeUploadPic}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                    </Row>


                    {/* <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Link VDO"
                                name="link_vid_en"
                                style={{width:'95%', marginTop:'-5%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidEn}
                                    onChange={(e) => SetLinkVidEn(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Divider />

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
            </Form>
    </Create>   
    );
};