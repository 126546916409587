import { Avatar, Breadcrumb, Col, DatePicker, Divider, Edit, Form, Icons, Input, List, Radio, RcFile, Row, Select, Space, Switch, Table, Tooltip, Upload, message, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, useApiUrl, useGetIdentity, useList, useNavigation, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import { IUserAmpure, IUserDistrict, IUserNly, IUserProfile, IUserProvice, IUserprefix } from "interfaces";
import { useRef, useState } from "react";
import en from 'world_countries_lists/data/countries/en/world.json';
import { TOKEN_KEY } from "../../constants";
// import moment from "moment";
import dayjs from "dayjs";
import { AlignType } from 'rc-table/lib/interface';

import './styles.less';

let defaultPixurl: any = "../../images/svg/image.svg"
let userID: any = undefined;
export const UserProfileEdit = () => {

    let getID = window?.location?.href.split("/");
    let pageID: any = getID[getID?.length -1];
    
    const t = useTranslate();
    const { Dragger } = Upload;
    const API_URL = useApiUrl();
    const refUpload = useRef<any>();
    const { replace } = useNavigation();

    const {data: user_data} = useGetIdentity<any>();
    const { mutate: updateUser } = useUpdate<IUserProfile>();

    const [vpixlogo, setpixlogo] = useState<any>([]);
    const [swpixList, setswpixList] = useState<boolean>(true);
    const [swpwd, setswpwd] = useState<boolean>(false);
    const [pixURL, setpixURL] = useState<any>();
    const [vpixChange, setpixChange] = useState<any>();

    const [vTel, setTel] = useState<CountryPhoneInputValue>();
    const [vINDENID, setINDENID] = useState<boolean>();
    const [vbday, setbday] = useState<any>("");
    const [vAmper, setAmper] = useState<any>();
    const [vPovin, setPovin] = useState<any>();
    const [vDist, setDist] = useState<any>(1);
    const [vAmper2, setAmper2] = useState<any>();
    const [vPovin2, setPovin2] = useState<any>();
    const [vDist2, setDist2] = useState<any>(1);

    const [EMAIL, setEMAIL] = useState<any>();

    const [vUserTypeID, setUserTypeID] = useState<any>();


    const { form, formProps, saveButtonProps } = useForm<IUserProfile, HttpError, {}>({resource: "user-profiles", id: pageID});

    // const {listProps , queryResult: {refetch} } = useSimpleList({
    //     resource: "user-profiles",
    //     syncWithLocation: false,
    //     permanentFilter: [
    //         {
    //             field: "id",
    //             operator: "in",
    //             value: pageID,
    //         }
    //     ],
    //     queryOptions:{onSuccess(data) {
    //         let dataLoad: any = data?.data[0];

    //         console.log(">>> dataLoad",dataLoad)
    //     },}
    // })

    // const populate = [
    //     'createBy',
    //     'createBy.avatar',
    //     'updateBy',
    //     'updateBy.avatar',
    //     'nationality',
    //     'user',
    //     'user_prefix',
    //     'user_sub_types',
    //     'user_type',
    //     'address1_amphure',
    //     'address1_district',
    //     'address1_provice',
    //     'address2_amphure',
    //     'address2_district',
    //     'address2_provice',
    //     'avatar'
    // ]

    const { queryResult } = useShow<IUserProfile>({
        resource: "user-profiles",
        id: pageID,
        metaData: {
            populate: [
                'createBy',
                'createBy.avatar',
                'updateBy',
                'updateBy.avatar',
                'nationality',
                'user',
                'user_prefix',
                'user_sub_types',
                'user_type',
                'address1_amphure',
                'address1_district',
                'address1_provice',
                'address2_amphure',
                'address2_district',
                'address2_provice',
                'avatar'
            ]
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log(">>> dataLoad", dataLoad)
                // setDataCus(dataLoad)
                userID = dataLoad?.user?.id;
                setEMAIL(dataLoad?.user?.email);
                dataLoad.user_type ? setUserTypeID(dataLoad.user_type.id) : setUserTypeID("");


                if (dataLoad?.identity_no !== null) {
                    setINDENID(true);
                } else if (dataLoad?.identity_passport !== null) {
                    setINDENID(false);
                }

                setTel({ phone: dataLoad ? dataLoad.tel : null, short: dataLoad ? dataLoad.short_tel : null })

                if (dataLoad?.avatar !== null) {
                    setswpixList(false);
                    setpixChange(dataLoad?.avatar)
                    setpixURL(dataLoad?.avatar.url);
                } else {
                    setswpixList(true);
                    setpixChange(undefined)
                    setpixURL(undefined);
                }

                //=====================================================================================//
                setPovin(dataLoad?.address1_provice ? dataLoad?.address1_provice?.id :undefined);
                setDist(dataLoad?.address1_district ? dataLoad?.address1_district?.id :undefined);
                setAmper(dataLoad?.address1_amphure ? dataLoad?.address1_amphure?.id :undefined);
                //=====================================================================================//
                setPovin2(dataLoad?.address2_provice ? dataLoad?.address2_provice?.id :undefined);
                setDist2(dataLoad?.address2_district ? dataLoad?.address2_district?.id :undefined);
                setAmper2(dataLoad?.address2_amphure ? dataLoad?.address2_amphure?.id :undefined);
                //=====================================================================================//

                formProps?.form?.setFieldsValue({
                    user_prefix: dataLoad?.user_prefix ? dataLoad?.user_prefix?.id : null,
                    nationality: dataLoad?.nationality ? dataLoad?.nationality?.id : null,
                    //=====================================================================================//
                    address1_provice: dataLoad?.address1_provice ? dataLoad?.address1_provice?.id : null,
                    address2_provice: dataLoad?.address2_provice ? dataLoad?.address2_provice?.id : null,
                    //=====================================================================================//
                    address1_district: dataLoad?.address1_district ? dataLoad?.address1_district?.id : null,
                    address2_district: dataLoad?.address2_district ? dataLoad?.address2_district?.id : null,
                    //=====================================================================================//
                    address1_amphure: dataLoad?.address1_amphure ? dataLoad?.address1_amphure?.id : null,
                    address2_amphure: dataLoad?.address2_amphure ? dataLoad?.address2_amphure?.id : null,
                    //=====================================================================================//
                })

                // callAPIPropInfo(dataLoad.id);
            }
        }
    });

    const { selectProps: dataPrefix } = useSelect<IUserprefix>({
        resource: "user-prefixes",
        optionLabel: "prefix_name",
        optionValue: "id",
    });

    const { selectProps: dataNly } = useSelect<IUserNly>({
        resource: "nationalities",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 1000,
    });

    const { selectProps: dataProvice } = useSelect<IUserProvice>({
        metaData: {
            populate: '*'
        },
        resource: "provices",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
    });

    const { selectProps: dataAmphure } = useSelect<IUserAmpure>({
        metaData: {
            populate: '*'
        },
        resource: "amphures",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: 'province_id]',
                operator: 'in',
                value: vPovin
            }
        ]
    });

    const { selectProps: dataDistrict } = useSelect<IUserDistrict>({
        metaData: {
            populate: '*'
        },
        resource: "districts",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 10000,
        filters: [
            {
                field: 'amphure_id]',
                operator: 'in',
                value: vAmper
            }
        ]
    });

    const { selectProps: dataAmphure2 } = useSelect<IUserAmpure>({
        metaData: {
            populate: '*'
        },
        resource: "amphures",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: 'province_id]',
                operator: 'in',
                value: vPovin2
            }
        ]
    });

    const { selectProps: dataDistrict2 } = useSelect<IUserDistrict>({
        metaData: {
            populate: '*'
        },
        resource: "districts",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: 'amphure_id]',
                operator: 'in',
                value: vAmper2
            }
        ]

    });

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function fileChange(e: any) {
        if (e.file.response) {
            let res = e.file.response;
            changepixUrl(res[0].url, res[0])
        }
    }

    function changepixUrl(url: any, val: any) {
        setpixURL(url);
        setpixChange(val);
        setswpixList(false)
    }

    function removepixList() {
        setpixURL(defaultPixurl);
        setpixChange(undefined);
        setswpixList(true)
    }

    const userhUnit = useList<any>({
        metaData: {
            populate: ['unit.property','unit.property.project_brand','unit.property.project']
        },
        resource: "user-has-units",
        config: {
            sort: [{ order: "asc", field: "unit.property][property_name]" }],
            filters: [
                {
                    field: "user_profile][id]",
                    operator: "in",
                    value: userID,
                },
                { // #1682 Properties Info ต้องเช็ค Status เพิ่มดังนี้
                    field: "isOwnerConfirmed",
                    operator: "eq",
                    value: true,
                },
                {
                    field: "isAdminConfirmed",
                    operator: "eq",
                    value: true,
                },
            ],
        },
    });

    const { data:userhUnitdt } = userhUnit;
    let UserHUnitData:any = []
    UserHUnitData = userhUnitdt?.data? userhUnitdt?.data : [];

    const dataSource2 = 
        UserHUnitData?.length > 0 ?
            UserHUnitData.map((el:any,index:any) => (
                    el.deleteStatus == "N" ? 
                {
                    key: String(el.id),
                    dev: el.unit.property.property_under,
                    prop_brand: el?.unit?.property?.project_brand !== null ? el?.unit?.property?.project_brand?.brand_name : '-',
                    pj_name: el.unit.property.project !== null ? el.unit.property.project.project_name : '-',
                    unit: el.unit.unit_name.slice(0, 9),
                    n_unit: UserHUnitData.length,
                }
                :<></>
            ))
        : null;
        
    console.log("UserHUnitData",UserHUnitData)
    console.log(">>> dataSource2",dataSource2)
  
  const columns = [
    {
        title: 'Developer',
        dataIndex: 'dev',
        key: 'develop',
    },
    {
        title: 'Property Brand',
        dataIndex: 'prop_brand',
        key: 'property_brand',
    },
    {
        title: 'Project Name',
        dataIndex: 'pj_name',
        key: 'project_name',
    },
    {
        title: 'Unit Info',
        dataIndex: 'unit',
        key: 'unit_name',
    },
    {
        title: 'Number Of Unit',
        dataIndex: 'n_unit',
        // key: 'no_unit',
        colSpan: 1,
        // rowSpan: 0,

        align: 'center' as AlignType,
        // render: (text: {} | null | undefined, row: any, index: number) => {
        //     if (index == 1) {
        //       return <a href="javascript:;">{text}</a>;
        //     }
        //     return {
        //       children: <a href="javascript:;">{text}</a>,
        //       props: {
        //         rowSpan: 5
        //       }
        //     };
        // }
        
        render: (value: any, row :any, index :any) => {
            // setCount(vCount + index)
            // console.log('value',value)
            // console.log('index',index) 
            // console.log('row',row)
            // console.log('vCount', vCount)
            const obj = {
                // value: value,
                children: value,
                props: {
                    // colSpan: 1,
                    rowSpan: 1
                }
            };

            if(index < 1){
                return obj;
            }

          }
    },
  ];

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List  
                title=""             
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project-member.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("member-account.edit")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                extra : <></>
                }}
            />

            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    updateUser(
                        {
                            resource: 'user-profiles',
                            id: pageID,
                            values: {
                                avatar: vpixChange ? vpixChange?.id : null,
                                user_prefix: values.user_prefix,
                                first_name: values.first_name,
                                last_name: values.last_name,
                                nick_name: values.nick_name,
                                email: values.email_custom,
                                gender: values.gender,
                                nationality: values.nationality,

                                address1_addr_no: values.address1_addr_no,
                                address1_amphure: values.address1_amphure ? values.address1_amphure?.id : null,
                                address1_district: values.address1_district ? values.address1_district?.id : null,
                                address1_provice: values.address1_provice ? values.address1_provice?.id : null,
                                address1_road: values.address1_road,
                                address1_soi: values.address1_soi,

                                address2_addr_no: values.address2_addr_no,
                                address2_amphure: values.address2_amphure ? values.address2_amphure?.id : null,
                                address2_district: values.address2_district ? values.address2_district?.id : null,
                                address2_provice: values.address2_provice ? values.address2_provice?.id : null,
                                address2_road: values.address2_road,
                                address2_soi: values.address2_soi,
                                updateBy: user_data.user_profile?.id,
                                short_tel: vTel?.short ? vTel?.short : null,
                                tel: vTel?.phone ? vTel?.phone : null,
                                birthday: vbday ? vbday : null,
                            }, 
                            successNotification: false, 
                            errorNotification: false,
                        },
                        {
                            onSuccess(data, variables, context) {
                                if (swpwd == true) {
                                    updateUser(
                                        {
                                            resource: 'users',
                                            id: userID,
                                            values: {
                                                password: values.password1,
                                            },
                                            successNotification: false, 
                                            errorNotification: false,
                                        },
                                        {
                                            onSuccess(data, variables, context) {
                                                replace("/project_member/user-account");
                                            },
                                        }
                                    )
                                }else{
                                    replace("/project_member/user-account");
                                }
                            },
                        }
                    );
                }}
            >
                <Row gutter={8}>
                    <Col span={4} className="gutter-row">
                        <Form.Item
                            name="avatar"
                            className="form_projectPix"
                            style={{ marginBottom: "5%" }}
                        >
                            <Tooltip placement="left" color="lime" title={"AVATAR PROFILE"}>
                                <Dragger className="dragger_upload_custom_i_w"
                                    {...vpixlogo}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                    showUploadList={false}
                                    openFileDialogOnClick={false}
                                    ref={refUpload}
                                >
                                    <div className="dragger_upload_custom_i_h">
                                        <div className="projectPix_hover">
                                            <div>
                                                {swpixList == false ?
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={14} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <Avatar className="projectPix" src={pixURL} style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
                                        <span style={{ fontSize:"11px", color: "#DF6677" }}>* Picture 512*512 PNG, JPG size 5MB</span>
                                    </div>
                                </Dragger>
                            </Tooltip>
                        </Form.Item>
                    </Col>

                    <Col span={20} className="gutter-row">
                        <Row gutter={8}>
                            <Col span={6} className="gutter-row">
                                <Form.Item name="user_prefix" label={t("user-profiles.prefix")}>
                                    <Select placeholder={t("select.sel")} {...dataPrefix} />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.name")} name="first_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please input first name'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.lastname")} name="last_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please input last name'
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.nickname")} name="nick_name">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={8} className="gutter-row">
                                <Form.Item label="Email" name="email_custom" className="input_disable_onshow"
                                    rules={[
                                        // {
                                        //     required: true, message: 'Please input email'
                                        // },
                                        {
                                            type: 'email',
                                            message: 'email must be formatted correctly',
                                        }
                                    ]}
                                    getValueProps={(value) => ({
                                        value: EMAIL ? EMAIL : "",
                                    })}
                                >
                                    <Input type="email" disabled={true} />
                                </Form.Item>
                            </Col>

                            <Col span={10} className="gutter-row">
                                <Form.Item name="tel" label={t("user-profiles.tel")}>
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput style={{ height: "32px", borderRadius: "5px", padding: "0" }}
                                            value={vTel}
                                            onChange={(v) => { setTel(v) }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            minLength={9}
                                            maxLength={12}
                                        />
                                    </ConfigProvider>
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item name="gender" label={t("user-profiles.gender")}>
                                    <Select placeholder={t("select.sel")}
                                        options={[
                                            {
                                                label: "Male",
                                                value: "M",
                                            },
                                            {
                                                label: "Female",
                                                value: "F",
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8} className="gutter-row">
                                <Form.Item label=" " className="input_disable_onshow" style={{ marginTop: "-1.2rem" }}>
                                    <Radio.Group
                                        onChange={(e) => {
                                            setINDENID(e.target.value);
                                            formProps?.form?.resetFields(["identity_no"])
                                            formProps?.form?.resetFields(["identity_passport"])
                                        }
                                        }
                                        value={vINDENID}
                                        disabled={true}
                                    >
                                        <Space direction="horizontal">
                                            <Radio value={true}>{t("user-profiles.true")}</Radio>
                                            <Radio value={false}>{t("user-profiles.false")}</Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col span={16} className="gutter-row">
                                {(vINDENID == true || vINDENID == false) ?
                                    vINDENID == true ?
                                        <Form.Item label=" " name="identity_no"
                                            className="disable_req input_disable_onshow"
                                            style={{ marginTop: "-1.2rem" }}
                                            rules={[
                                                {
                                                    required: true, message: 'Please input national id'
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="National ID"
                                                maxLength={13}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                disabled={true}
                                            />
                                        </Form.Item>
                                        :
                                        vINDENID == false &&
                                        <Form.Item label=" " name="identity_passport"
                                            className="disable_req input_disable_onshow"
                                            style={{ marginTop: "-1.2rem" }}
                                            rules={[
                                                {
                                                    required: true, message: 'Please input passport number'
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Passport number"
                                                maxLength={20}
                                                disabled={true}
                                            />
                                        </Form.Item>
                                    :
                                    <Form.Item label=" " style={{ marginTop: "-1.2rem" }}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                }
                            </Col>

                            <Col span={4} className="gutter-row">
                                <Form.Item label=" ">
                                    <Switch
                                        checkedChildren={<Tooltip title="Change Password">Change</Tooltip>}
                                        unCheckedChildren={<Tooltip title="Disable">NotChange</Tooltip>}
                                        defaultChecked={false}
                                        onChange={(e) => setswpwd(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={10} className="gutter-row">
                                {swpwd == true ?
                                    <Form.Item
                                        name="password1"
                                        label={t("staff-profiles.pwd1")}
                                        rules={[
                                            {
                                                required: true,
                                                // message: 'please input your password!',
                                                message: '',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    // if (value.length >= 8 && value.length <= 16) {
                                                    //     return Promise.resolve();
                                                    // }
                                                    // return Promise.reject(new Error('Password must be 8-16 characters only '));

                                                    if (value.length >= 8 && value.length <= 16) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length <= 8 && value.length >= 16) {
                                                        return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                    }

                                                    if (value.length < 0) {
                                                        return Promise.reject(new Error('please input your password'));
                                                    }

                                                    if (value == "") {
                                                        return Promise.reject(new Error('please input your password'));
                                                    }

                                                },
                                            }),
                                        ]}
                                        hasFeedback

                                    >
                                        <Input.Password style={{ borderRadius: 5 }}
                                            type="password"
                                            placeholder="Enter your password"
                                            iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                            minLength={8}
                                            maxLength={16}
                                            disabled={!swpwd}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item label={t("staff-profiles.pwd1")}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                }
                            </Col>

                            <Col span={10} className="gutter-row">
                                {swpwd == true ?
                                    <Form.Item
                                        name="password2"
                                        label={t("staff-profiles.pwd2")}
                                        dependencies={['password1']}
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please confirm your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password1') === value) {
                                                    return Promise.resolve();
                                                }
                                                // return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                return Promise.reject(new Error('Password is not match'));
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input.Password style={{ borderRadius: 5 }}
                                            type="password"
                                            placeholder="Enter your password"
                                            iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                            minLength={8}
                                            maxLength={16}
                                            disabled={!swpwd}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item label={t("staff-profiles.pwd2")}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider style={{ margin: "30px 0px" }} />

                <Row gutter={8}>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            name="birthday"
                            label={t("user-profiles.bday")}
                            getValueProps={(value) => ({ value: value ? dayjs(value) : "", })}
                        >
                            <DatePicker
                                format="YYYY-MM-DD"
                                onChange={(date, dateString) => setbday(dateString)}
                                // disabledDate={(current) => {
                                //     let customDate = moment().format("YYYY-MM-DD");
                                //     return current && current > moment(customDate, "YYYY-MM-DD");
                                // }}
                                disabledDate={(current) => {
                                    let customDate = dayjs().format('YYYY-MM-DD');
                                    return current && dayjs(current).isAfter(customDate, 'day');
                                }}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item name="nationality" label={t("user-profiles.nly")}>
                            <Select placeholder={t("select.sel")} {...dataNly} />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider style={{ margin: "10px 0px" }} />

                <Row gutter={16}>
                    <Col span={12} className="gutter-row">
                        <div style={{ marginBottom: "5%" }}><span style={{ fontSize: "14px", color: "#000" }}>{t("user-profiles.address1")}</span></div>
                        <Form.Item name="address1_addr_no" label={t("user-profiles.address")}>
                            <Input />
                        </Form.Item>

                        <Row gutter={8}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address1_soi" label={t("user-profiles.soi")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address1_provice" label={t("user-profiles.provice")}>
                                    <Select
                                        placeholder={t("select.sel")}
                                        {...dataProvice}
                                        onChange={(e) => setPovin(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="address1_district" label={t("user-profiles.district")}>
                                    <Select
                                        placeholder={t("select.sel")}
                                        {...dataDistrict}
                                        onChange={(e) => setDist(e)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address1_road" label={t("user-profiles.road")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address1_amphure" label={t("user-profiles.amphure")}>
                                    <Select
                                        placeholder={t("select.sel")}
                                        {...dataAmphure}
                                        onChange={(e) => setAmper(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="postcode1" label={t("user-profiles.post-code")} className="input_disable_onshow">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <div style={{ marginBottom: "5%" }}><span style={{ fontSize: "14px", color: "#000" }}>{t("user-profiles.address2")}</span></div>
                        <Form.Item name="address2_addr_no" label={t("user-profiles.address")}>
                            <Input />
                        </Form.Item>

                        <Row gutter={8}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address2_soi" label={t("user-profiles.soi")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address2_provice" label={t("user-profiles.provice")}>
                                    <Select
                                        {...dataProvice}
                                        placeholder={t("select.sel")}
                                        onChange={(e) => setPovin2(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="address2_district" label={t("user-profiles.district")}>
                                    <Select
                                        {...dataDistrict2}
                                        placeholder={t("select.sel")}
                                        onChange={(e) => setDist2(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item name="address2_road" label={t("user-profiles.road")}>
                                    <Input />
                                </Form.Item>

                                <Form.Item name="address2_amphure" label={t("user-profiles.amphure")}>
                                    <Select
                                        {...dataAmphure2}
                                        placeholder={t("select.sel")}
                                        onChange={(e) => setAmper2(e)}
                                    />
                                </Form.Item>

                                <Form.Item name="postcode2" label={t("user-profiles.post-code")} className="input_disable_onshow">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            {
                vUserTypeID !== "5" || vUserTypeID !== 5 ? 
                <>
                    {/* <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginTop: "2%"}}>
                        <div style={{marginBottom: "2%" }}><span style={{fontWeight: "bold",fontSize: "0.7vw",color: "#000"}}>Properties Info</span></div>
                    </Card> */}
                    <span style={{fontWeight: "bold",fontSize: "0.7vw",color: "#000"}}>Properties Info</span>
                    <Table dataSource={dataSource2 ? dataSource2 : []} columns={columns} bordered pagination={{hideOnSinglePage: false}}/>

                </>:<></>
            }
        </Edit>
    )
}