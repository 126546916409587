import { IResourceComponentsProps, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { useEffect, useState } from "react";
import { petURL } from "../../../constants";
import { Breadcrumb, Button, Card, Col, Divider, Edit, Form, Icons, Input, InputNumber, List, Modal, Row, Select, Spin, notification } from "@pankod/refine-antd";
import axios from "axios";

let optionpettype: any = undefined;
let optionpetsize: any = undefined;
export const PetspeciesEdit: React.FC<IResourceComponentsProps> = () => {
    
    const t = useTranslate();
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();

    const [keepMin, setKeepMin] = useState<any>()
    const [keepMax, setKeepMax] = useState<any>()

    const [formCustom] = Form.useForm();
    const [formModal] = Form.useForm();
    const [formModal_EDIT] = Form.useForm();
    const [formModal_EDIT_OLD] = Form.useForm();

    const [isLoading, setLoading] = useState<boolean>(true);
    const [valuepetType, setpetType] = useState<any>();
    const [valuepetSize, setpetSize] = useState<any>();


    const [sizeGroup, setsizeGroup] = useState<any>([]);
    const [sizeGroup_OLD, setsizeGroup_OLD] = useState<any>([]);

    const [ID, setID] = useState<any>();
    const [keyEDIT, setkeyEDIT] = useState<any>();
    const [keyEDIT_OLD, setkeyEDIT_OLD] = useState<any>();
    const [mdopen, setmdopen] = useState<boolean>(false);
    const [mdopen_EDIT, setmdopen_EDIT] = useState<boolean>(false);
    const [mdopen_EDIT_OLD, setmdopen_EDIT_OLD] = useState<boolean>(false);
    const [tickerFlag, settickerFlag] = useState<boolean>(false);

    const [showReqSize, setShowReqSize] = useState<boolean>(false);


    let getID = window?.location?.href.split("/");
    let pageID: any = getID[getID?.length -1];

    const { data: dataLoad } = useCustom<any>({
        url: `${petURL}/api/lscpet/findOnePetSpecies`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                setpetType(dataLoad?.pet_type)

                formCustom?.setFieldsValue({
                    name_th: dataLoad?.pet_species_name,
                    name_en: dataLoad?.pet_species_name_en,
                    pet_type: dataLoad?.pet_type
                })
            }
        }
    })

    const { data: datapetsize } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetSize`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_size_name_en,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpetsize = newDT;
                }
            },
        },
    });

    const { data: datasize } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetSizeDetailWithId`,
        method: "get",
        config: {
            query: {
                id: pageID
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newData?.push({
                        "id": dataLoad[index]?._id,
                        "size_name": dataLoad[index]?.size_name,
                        "size_name_en": dataLoad[index]?.size_name_en,
                        "pet_size": dataLoad[index]?.pet_size,
                        "size_from": dataLoad[index]?.size_from,
                        "size_to": dataLoad[index]?.size_to,
                        "status": "active"
                    })
                }
                //console.log("firstdata",newData)
                setsizeGroup_OLD(newData)
            }
        }
    })

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findActivePetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }

                setLoading(false);
            },
        },
    });

    async function submitDT(values:any){
        let body: any = {
            "pet_species_name": values?.name_th,
            "pet_species_name_en": values?.name_en,
            "pet_type": valuepetType,
            "is_charge": values?.is_charge,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }
        
        console.log('sizeGroup', sizeGroup)
        console.log('sizeGroup_OLD', sizeGroup_OLD)

        // for (let index = 0; index < sizeGroup_OLD.length; index++) {
        //     if(sizeGroup_OLD[index].status){

        //     }
        // }

        const hasRemoved = sizeGroup_OLD.some((item:any) => item.status === 'removed');

        if (hasRemoved) {
            setShowReqSize(true);
        } else {
            setShowReqSize(false);
        }

        console.log('hasRemoved', hasRemoved)
        
        // if(sizeGroup_OLD?.length <= 0 && sizeGroup?.length <= 0){
        if(hasRemoved && sizeGroup?.length <= 0){
            // console.log('req expense')
            setShowReqSize(true)
        }else{
        
            let submit = await axios.put(petURL + "/api/lscpet/updatePetSpecies?id=" + pageID, body, { headers: { } })
            .then(async response => {
                let res: any = response?.data;
                let data_CREATE: any = sizeGroup;
                let data_UPDATE: any = sizeGroup_OLD?.filter((f: any) => f?.status == "active");
                let data_REMOVE: any = sizeGroup_OLD?.filter((f: any) => f?.status == "removed");

                if(data_CREATE?.length > 0){
                    for (let i = 0; i < data_CREATE?.length; i++) {
                        let body: any = {
                            "pet_species": pageID,
                            "size_name": data_CREATE[i]?.size_name,
                            "size_name_en": data_CREATE[i]?.size_name_en,
                            "size_from": data_CREATE[i]?.size_from,
                            "pet_size": data_CREATE[i]?.pet_size,
                            "size_to": data_CREATE[i]?.size_to,
                            "create_by": {
                                "user_id": user_dt?.user_profile?.id,
                                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
                            },
                        }
                        
                        let submit = await axios.post(petURL + "/api/lscpet/createPetSizeDetail", body, { headers: { } })
                    }
                }

                if(data_UPDATE?.length > 0){
                    for (let i = 0; i < data_UPDATE?.length; i++) {
                        let idSIZE: any = data_UPDATE[i]?.id;
                        let body: any = {
                            "pet_species": pageID,
                            "size_name": data_UPDATE[i]?.size_name,
                            "size_name_en": data_UPDATE[i]?.size_name_en,
                            "pet_size": data_UPDATE[i]?.pet_size,
                            "size_from": data_UPDATE[i]?.size_from,
                            "size_to": data_UPDATE[i]?.size_to,
                            "update_by": {
                                "user_id": user_dt?.user_profile?.id,
                                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
                            },
                        }

                        let submit = await axios.put(petURL + "/api/lscpet/updatePetSizeDetail?id=" + idSIZE, body, { headers: { } })
                    }
                }

                if(data_REMOVE?.length > 0){
                    for (let i = 0; i < data_REMOVE?.length; i++) {
                        let idSIZE: any = data_REMOVE[i]?.id;
                        let submit = await axios.delete(petURL + "/api/lscpet/deletePetSizeDetail?id=" + idSIZE, { headers: { } })
                    }
                }

                successNoti('Successfully edit pet species');
                replace("/pets/pet-masters")

            })
            .catch(error => {
                console.error('error ',error);
                faildNoti('Edit is Faild');
            });
        }
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    function onsetvalEdit(values: any, key: any){
        formModal_EDIT?.setFieldsValue({
            size_th: values?.size_name,
            size_en: values?.size_name_en,
            pet_size: values?.pet_size,
            size_from: values?.size_from,
            size_to: values?.size_to,
        });

        setKeepMax(values?.size_to)
        setKeepMin(values?.size_from)
        setkeyEDIT(key);
        setmdopen_EDIT(true);
    }

    function onremovesize(key: any) {
        let newData: any = [];

        // console.log('key', key)
        // const index = sizeGroup.findIndex((item:any) => item._id === key);

        for (let i = 0; i < sizeGroup?.length; i++) {
            if(i !== key){ newData?.push(sizeGroup[i]) }
            // if(sizeGroup[i]?._id !== key){ newData?.push(sizeGroup[i]) }
        }

        setsizeGroup(newData);
        settickerFlag((pre)=> !pre);
    }

    function onfinishsize(values: any) {
        let newData: any = [{
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
        }]

        setsizeGroup((pre: any) => [...pre, ...newData]);
        settickerFlag((pre)=> !pre);
        setmdopen(false);
    }

    function onfinishsizeEDIT(values: any) {
        let dataLoad: any = sizeGroup;
        let newData: any = {
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
        }

        dataLoad[keyEDIT] = newData;

        setsizeGroup(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT(false);
    }

    ////////////////////////////////////////////////////////////////////// OLD

    function onsetvalEdit_OLD(values: any, key: any){
        formModal_EDIT_OLD?.setFieldsValue({
            size_th: values?.size_name,
            size_en: values?.size_name_en,
            pet_size: values?.pet_size,
            size_from: values?.size_from,
            size_to: values?.size_to,
        });
        //console.log("vaaa",values)
        setKeepMax(values?.size_to)
        setKeepMin(values?.size_from)
        setID(values?.id);
        setkeyEDIT_OLD(key);
        setmdopen_EDIT_OLD(true);
    }

    function onremovesize_OLD(key: any) {
        let newData: any = sizeGroup_OLD;
        // console.log('key', key)
        // console.log('newData', newData)

        const index = newData.findIndex((item:any) => item.id === key);
        // newData[key].status = "removed"
        newData[index].status = "removed"

        setsizeGroup_OLD(newData);
        settickerFlag((pre)=> !pre);
    }

    function onfinishsizeEDIT_OLD(values: any) {
        let dataLoad: any = sizeGroup_OLD;
        let newData: any = {
            "id": ID,
            "size_name": values?.size_th,
            "size_name_en": values?.size_en,
            "pet_size": values?.pet_size,
            "size_from": values?.size_from,
            "size_to": values?.size_to,
            "status": "active"
        }

        dataLoad[keyEDIT_OLD] = newData;

        setsizeGroup_OLD(dataLoad);
        settickerFlag((pre)=> !pre);
        setmdopen_EDIT_OLD(false);
    }

    // useEffect(() => {
    // //   console.log("keepMax",keepMax)
    // //   console.log("keepMin",keepMin)
    //   console.log("sizeGroup_OLD",sizeGroup_OLD)
    // },)
    
    
    const findSizeLabel = (value:any) => {
        const size = optionpetsize?.find((s:any) => s.value === value);
        return size ? size.label : 'Size Not Found'; // Default message if size is not found
    };

    useEffect(() => {
        setShowReqSize(false)
    }, [sizeGroup]);

    // useEffect(() => {
      
    // }, [sizeGroup_OLD]);

    return (
        <Spin size="large" spinning={isLoading}>
            <Edit title={t("pet-species.title-edit")} pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/pet-masters")}}} saveButtonProps={{hidden: true}}>
                <List 
                    title=""
                    pageHeaderProps={{
                        breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("pet-type.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-species.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                        extra : <></>
                    }}
                />

                <div style={{width: "25%"}}>
                    <Form 
                        form={formCustom}
                        layout="vertical"
                        onFinish={(values:any) => {
                            submitDT(values);
                        }}
                    >
                        <Form.Item 
                            label={t("pet-type.title")}
                            name="pet_type"
                            rules={[
                                {
                                    required: true, message: 'please select pet type.'
                                },
                            ]}
                        >
                            <Select
                                options={optionpettype}
                                showSearch
                                allowClear
                                onChange={(e: any)=> setpetType(e)}
                                onClear={()=> setpetType(undefined)}
                            />
                        </Form.Item>

                        <Form.Item 
                            label={t("pet-species.name-spc-th")}
                            name="name_th"
                            rules={[
                                {
                                    required: true, message: 'please input name th.'
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item 
                            label={t("pet-species.name-spc-en")}
                            name="name_en"
                            rules={[
                                {
                                    required: true, message: 'please input name en.'
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>

                        <Divider />

                        <div className="layout_size_custom">
                            <div className="layout_size_custom_header" style={{display: "flex"}}>
                                <div style={{marginRight: "1rem"}}>
                                    <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size")}</span>
                                </div>
                                <div style={{marginTop: "-0.4rem"}}>
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setmdopen(true);
                                        }}
                                        icon={<Icons.PlusOutlined />}
                                        size="small"
                                        style={{borderRadius: "5px"}}
                                    >{t("buttons.add")}</Button>
                                </div>
                            </div>
                            {
                                showReqSize &&
                                <div>
                                    <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#ff4d4f"}}>{t("config-property.please-add-size")}</span>
                                </div>
                            }
                        </div>

                        <div style={{marginTop: "10px"}}>
                            <div style={{ display:"none"}}>{JSON.stringify(tickerFlag)}</div>{/* ห้ามลบ ห้ามแก้ */}
                            {sizeGroup_OLD?.length > 0 && sizeGroup_OLD?.filter((f: any) => f?.status == "active")?.map((item:any,index:any) => {
                                return (
                                    <Card
                                        key={index}
                                        className="card_custom_pet_species"
                                        style={{
                                            width: "100%", 
                                            marginBottom: "10px", 
                                            border: "1px solid #dedede"
                                        }}
                                    >
                                        <Row gutter={[6,8]}>
                                            {/* <Col className="gutter-row" span={20}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={7}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-th")} :</span>
                                                    </Col>
                                                    <Col className="gutter-row" span={17}>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_name}</span>
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            <Col className="gutter-row" span={20}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={6}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("Size")} : </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={18}>
                                                        <span style={{fontSize: "0.7vw"}}>
                                                            {/* {item?.pet_size} */}
                                                            {findSizeLabel(item?.pet_size)}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "end"}}>
                                                <Button icon={<Icons.EditOutlined />} size="small" style={{marginRight: "5px"}} onClick={()=> onsetvalEdit_OLD(sizeGroup_OLD[index], index)}/>
                                                {/* <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremovesize_OLD(index)}/> */}
                                                <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremovesize_OLD(item?.id)}/>
                                            </Col>
                                            {/* <Col className="gutter-row" span={22}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={6}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")} : </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={18}>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_name_en}</span>
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            <Col className="gutter-row" span={22}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={12} style={{borderRight: "1px solid #dedede"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("pet-species.weight")} :</span>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_from}</span>
                                                    </Col>
                                                    <Col className="gutter-row" span={12} style={{paddingLeft: "10px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("pet-species.to")} :</span>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_to}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                )})
                            }

                            {sizeGroup?.length > 0 && sizeGroup?.map((item:any,index:any) => {
                                return (
                                    <Card
                                        key={index}
                                        className="card_custom_pet_species"
                                        style={{
                                            width: "100%", 
                                            marginBottom: "10px", 
                                            border: "1px solid #dedede"
                                        }}
                                    >
                                        <Row gutter={[6,8]}>
                                            {/* <Col className="gutter-row" span={20}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={6}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-th")} :</span>
                                                    </Col>
                                                    <Col className="gutter-row" span={18}>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_name}</span>
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            <Col className="gutter-row" span={20}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={6}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("Size")} : </span>
                                                    </Col>
                                                    <Col className="gutter-row" span={18}>
                                                        <span style={{fontSize: "0.7vw"}}>
                                                            {/* {item?.pet_size} */}
                                                            {findSizeLabel(item?.pet_size)}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col className="gutter-row" span={4} style={{display: "flex", justifyContent: "end"}}>
                                                <Button icon={<Icons.EditOutlined />} size="small" style={{marginRight: "5px"}} onClick={()=> onsetvalEdit(sizeGroup[index], index)}/>
                                                <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremovesize(index)}/>
                                                {/* <Button icon={<Icons.DeleteOutlined />} size="small" danger onClick={()=> onremovesize(item?._id)}/> */}
                                            </Col>
                                            {/* <Col className="gutter-row" span={22}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={6}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")} :</span>
                                                    </Col>
                                                    <Col className="gutter-row" span={18}>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_name_en}</span>
                                                    </Col>
                                                </Row>
                                            </Col> */}
                                            <Col className="gutter-row" span={22}>
                                                <Row gutter={6}>
                                                    <Col className="gutter-row" span={12} style={{borderRight: "1px solid #dedede"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("pet-species.weight")} :</span>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_from}</span>
                                                    </Col>
                                                    <Col className="gutter-row" span={12} style={{paddingLeft: "10px"}}>
                                                        <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000", marginRight: "5px"}}>{t("pet-species.to")} :</span>
                                                        <span style={{fontSize: "0.7vw"}}>{item?.size_to}</span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                )})
                            }
                        </div>

                        <Modal
                            title={t("pet-species.add-size")}
                            visible={mdopen}
                            onCancel={() => setmdopen(false)}
                            onOk={() => keepMin > keepMax ? null : formModal?.submit()}
                            afterClose={()=> {
                                setKeepMax(undefined);
                                setKeepMin(undefined);
                                formModal?.resetFields();
                            }}
                        >
                            <Form 
                                form={formModal}
                                layout="vertical"
                                onFinish={(values:any) => {
                                    onfinishsize(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")}</span>
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item 
                                            name="pet_size"
                                            rules={[
                                                {
                                                    required: true, message: 'please select pet size.'
                                                },
                                            ]}
                                        >
                                            <Select
                                                options={optionpetsize}
                                                showSearch
                                                allowClear
                                                onChange={(e: any)=> setpetSize(e)}
                                                onClear={()=> setpetSize(undefined)}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            {/* <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span> */}
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.weight")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            className="visible-label"
                                            name="size_from"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'please input size from.'
                                            //     },
                                            // ]}
                                            style={{marginBottom: 0}}
                                        >
                                            <InputNumber onChange={(e) => setKeepMin(e)} style={{width:'100%'}} min={0}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="size_to"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'please input size to.'
                                            //     },
                                            // ]}
                                            style={{marginBottom: 0}}
                                        >   
                                            <InputNumber style={{width:'100%'}} onChange={(e) => setKeepMax(e)} min={keepMin}/>
                                        </Form.Item>
                                    </Col>
                                    <span className="req_custom" style={{display: keepMin > keepMax ? 'flex' : 'none', width: '100%', marginLeft: '310px', marginTop: '10px' , color: "#ff4d4f", fontSize: "13px"}}>{t("pet-species.alert")}</span>
                                </Row>
                            </Form>
                        </Modal>

                        <Modal
                            title={t("pet-species.edit-size")}
                            visible={mdopen_EDIT}
                            onCancel={() => setmdopen_EDIT(false)}
                            //onOk={() => formModal_EDIT?.submit()}
                            onOk={() => keepMin > keepMax ? null : formModal_EDIT?.submit()}
                            afterClose={()=> {
                                setKeepMax(undefined);
                                setKeepMin(undefined);
                                formModal_EDIT?.resetFields();
                            }}
                        >
                            <Form 
                                form={formModal_EDIT}
                                layout="vertical"
                                onFinish={(values:any) => {
                                    onfinishsizeEDIT(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")}</span>
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item 
                                            name="pet_size"
                                        >
                                            <Select
                                                options={optionpetsize}
                                                showSearch
                                                allowClear
                                                onChange={(e: any)=> setpetSize(e)}
                                                onClear={()=> setpetSize(undefined)}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            {/* <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span> */}
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.weight")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="size_from"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'please input size from.'
                                            //     },
                                            // ]}
                                            style={{marginBottom: 0}}
                                        >
                                            <InputNumber onChange={(e) => setKeepMin(e)} style={{width:'100%'}} min={0}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="size_to"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'please input size to.'
                                            //     },
                                            // ]}
                                            style={{marginBottom: 0}}
                                        >
                                            <InputNumber style={{width:'100%'}} onChange={(e) => setKeepMax(e)} min={keepMin}/>
                                        </Form.Item>
                                    </Col>
                                    <span className="req_custom" style={{display: keepMin > keepMax ? 'flex' : 'none', width: '100%', marginLeft: '310px', marginTop: '10px' , color: "#ff4d4f", fontSize: "13px"}}>{t("pet-species.alert")}</span>
                                </Row>
                            </Form>
                        </Modal>

                        <Modal
                            title={t("pet-species.edit-size")}
                            visible={mdopen_EDIT_OLD}
                            onCancel={() => setmdopen_EDIT_OLD(false)}
                            // onOk={() => formModal_EDIT_OLD?.submit()}
                            onOk={() => keepMin > keepMax ? null : formModal_EDIT_OLD?.submit()}
                            afterClose={()=> {
                                setKeepMax(undefined);
                                setKeepMin(undefined);
                                formModal_EDIT_OLD?.resetFields();
                            }}
                        >
                            <Form
                                form={formModal_EDIT_OLD}
                                layout="vertical"
                                onFinish={(values:any) => {
                                    onfinishsizeEDIT_OLD(values);
                                }}
                            >
                                <Row gutter={6}>
                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.size-en")}</span>
                                        </div>
                                    </Col>
                                    <Col span={18}>
                                        <Form.Item 
                                            name="pet_size"
                                        >
                                            <Select
                                                options={optionpetsize}
                                                showSearch
                                                allowClear
                                                onChange={(e: any)=> setpetSize(e)}
                                                onClear={()=> setpetSize(undefined)}
                                            />
                                        </Form.Item>
                                    </Col>


                                    <Col span={6}>
                                        <div style={{marginTop: "5px"}}>
                                            {/* <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span> */}
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.weight")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="size_from"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'please input size from.'
                                            //     },
                                            // ]}
                                            style={{marginBottom: 0}}
                                        >
                                            {/* <Input /> */}
                                            <InputNumber onChange={(e) => setKeepMin(e)} style={{width:'100%'}} min={0}/>
                                        </Form.Item>
                                    </Col>

                                    <Col span={2}>
                                        <div style={{marginTop: "5px", paddingLeft: "10px"}}>
                                            <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-species.to")}</span>
                                        </div>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            className="visible-label"
                                            name="size_to"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'please input size to.'
                                            //     },
                                            // ]}
                                            style={{marginBottom: 0}}
                                        >
                                            {/* <Input /> */}
                                            <InputNumber style={{width:'100%'}} onChange={(e) => setKeepMax(e)} min={keepMin}/>
                                        </Form.Item>
                                    </Col>
                                    <span className="req_custom" style={{display: keepMin > keepMax ? 'flex' : 'none', width: '100%', marginLeft: '310px', marginTop: '10px' , color: "#ff4d4f", fontSize: "13px"}}>{t("pet-species.alert")}</span>
                                </Row>
                            </Form>
                        </Modal>
                    </Form>
                </div>

                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    <Button type="primary" onClick={() => formCustom?.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
                </Row>
            </Edit>
        </Spin>
    )
}