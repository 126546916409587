import { Badge, Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, List, Modal, Row, Select, Space, Table, Tabs, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useTranslate, useUpdate } from "@pankod/refine-core"
import { useState } from "react";
import moment from 'moment';
import { IProperties } from "interfaces";

let tableRec: any = undefined;
export const EstampConfigList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TabPane } = Tabs;
    const {data: Identity_data} = useGetIdentity<any>();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null;
    const { confirm } = Modal;
    const { mutate: update } = useUpdate<any>();

    const [page, setPage] = useState(1);
    const pageSizeState = [ '10','30', '50', '100'];
    const [pageSizeChange, setPageSizeChange] = useState(10);

    const [deleteStatus, setdeleteStatus ] = useState<any>('N');

    const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
        metaData: {
            populate: [
                "property",
                "createBy",
                "updateBy",
                "vehicle_type"
            ],
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property } = params;
            console.log(">>> property", property)
                filters.push(
                    {
                        field       : "property][id]",
                        operator    : "eq",
                        value       : property
                    },
                    // {
                    //     field       : "localizations][doc_name]",
                    //     operator    : "contains",
                    //     value       : vFilterEN !== undefined ? vFilterEN : null
                    // },
                );
            return filters;
        },
        permanentFilter:[
            {
                field : "property][id]",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ],
        queryOptions:{
            onSuccess(data) {
                tableRec = data?.data;
            },
        }
    });

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data
            // if(dataSet?.length == 1){
            //     setProp(dataSet[0]?.id)
            //     createProps?.form?.setFieldsValue({
            //         property: dataSet[0]?.id
            //     })
            // }
        },}
    });

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function onStatusChange(e:any){
        setdeleteStatus(e);
        // searchFormProps.form?.submit();
        // localStorage.setItem("keyU",v)
        // localStorage.setItem("keyTabUserPortal", v)
    }

    function onReset(){
        searchFormProps.form?.resetFields();
        searchFormProps.form?.submit();
    }

    function updateStatus(id:any, event:any){
        update({
            resource    : "visitor-stamp-configs",
            id          : id,
            values      : {
                updateBy        : Identity_data?.user_profile?.id,
                deleteStatus    : String(event)
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    return (
        <List 
            title={t("e-stamp-config.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("e-stamp-config.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row style={{ marginBottom: "1rem" }}>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"0"}}
                    >
                        <Row gutter={8} style={{width: "100%"}}>
                            <Col className="gutter-row" style={{width: "250px"}}>
                                <Form.Item name="property" style={{width: "100%", marginBottom:"0"}}>
                                    <Select 
                                        {...propertySelectProps} 
                                        placeholder="Select property" 
                                        size="middle" 
                                        style={{borderRadius: 20, position:'relative'}} 
                                        allowClear
                                        // onSelect={(e : any) => setvProp(e)}
                                        />
                                </Form.Item>
                            </Col>
                            <Col className="gutter-row" style={{width: "105px"}}>
                                <Button 
                                    icon={<Icons.SearchOutlined />}
                                    type="primary"
                                    style={{borderRadius: "5px"}}
                                    onClick={()=> searchFormProps.form?.submit()}
                                >
                                    {t("buttons.search")}
                                </Button>
                            </Col>
                            <Col className="gutter-row" span={1} style={{width: "80px"}}>
                                <Button 
                                    icon={<Icons.ReloadOutlined />}
                                    type="ghost" 
                                    style={{borderRadius: "5px"}}
                                    onClick={()=> onReset()}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={6} style={{display: "flex", justifyContent: "end"}}>
                    <CreateButton id="create_btn" 
                        // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                    />
                </Col>
            </Row>

            <Form.Item name="deleteStatus" style={{marginBottom: "0"}}>
                <Tabs 
                    defaultActiveKey={"N"}
                    style={{ marginTop: 10, marginBottom: 10 }} 
                    onChange={onStatusChange} 
                >
                    <TabPane
                        key={"N"}
                        tab={
                            <span>
                                {"ACTIVE"}
                                <Badge count={tableRec?.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />

                    <TabPane
                        key={"Y"}
                        tab={
                            <span>
                                {"TRASH"}
                                <Badge count={tableRec?.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                            </span>
                        }
                    />
                </Tabs>
            </Form.Item>

            <Table
                dataSource={tableProps?.dataSource?.filter((e:any)=> e.deleteStatus == deleteStatus)}
                bordered
                size="middle"
                rowKey = "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    total:tableProps?.dataSource?.length,
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false
                }}  
            >
                <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/>

                <Table.Column title="PROPERTY" dataIndex={"property"} width={300}
                    sorter={(a:any, b:any) => 
                    {
                        if (a?.property_name < b?.property_name) {return -1}
                        if (a?.property_name > b?.property_name) {return 1}
                        return (0)
                    }} 

                    render={(value:any,record:any)=>{
                        return <TextField value={value ? value?.property_name : '-'}  />
                    }}
                />

                <Table.Column title="ค่าบริการ" dataIndex={"charge_rate"}
                    width={"100"}
                    sorter={(a:any, b:any) => 
                        {
                            if (a?.charge_rate < b?.charge_rate) {return -1}
                            if (a?.charge_rate > b?.charge_rate) {return 1}
                            return (0)
                        }
                    } 
                    render={(value:any,record:any)=>{
                        return <TextField value={value ? value + " บาท" : "Free"}  />
                    }}
                />

                <Table.Column title="รายละเอียด" dataIndex={""}
                    width={"300"}
                    // sorter={(a:any, b:any) => 
                    //     {
                    //         if (a?.charge_rate < b?.charge_rate) {return -1}
                    //         if (a?.charge_rate > b?.charge_rate) {return 1}
                    //         return (0)
                    //     }
                    // } 
                    render={(value:any,record:any)=>{
                        //console.log("firstreee",record)
                        return <TextField value={"-"}  />
                    }}
                />

                <Table.Column title="สร้างโดย" dataIndex={"createBy"} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => a?.createBy?.first_name.localeCompare(b?.createBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.createBy ? record?.createBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title="วันที่สร้าง" dataIndex={"createdAt"}
                    width={150}
                    sorter={(a:any, b:any) => 
                        {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }
                    } 
                    render={(value:any,record:any)=>{
                        return <TextField value={value ? moment(value).format('D/MM/yyyy HH:mm') : "-"}  />
                    }}
                />

                <Table.Column title="อัพเดทโดย" dataIndex={"updateBy"} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => a?.updateBy?.first_name.localeCompare(b?.updateBy?.first_name)}
                        sortDirections={['ascend', 'descend']}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title="วันที่แก้ไข" dataIndex={"updatedAt"}
                    width={150}
                    sorter={(a:any, b:any) => 
                        {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }
                    }
                    render={(value:any,record:any)=>{
                        return <TextField value={value ? moment(value).format('D/MM/yyyy HH:mm') : "-"}  />
                    }}
                />

                <Table.Column<any>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed = "right"
                    render={(_, record) => (
                        <Space>
                            {
                                record.deleteStatus === "N" ?
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        recordItemId={record.id}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        // disabled
                                    />

                                    <Button 
                                        size="small" 
                                        title="Inactive"
                                        icon={<Icons.LockOutlined />} 
                                        onClick={() => { 
                                            showDelConfirm(record.id) 
                                        }} 
                                        danger 
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                </>
                                :
                                <Button 
                                    size="small" 
                                    title="Active" 
                                    icon={<Icons.UnlockOutlined />} 
                                    onClick={() => { showUnlockConfirm(record.id) }} 
                                    // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                />
                            }
                        </Space>
                    )}
                />
            </Table>
        </List>
    )
}