/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
// import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
// import MDEditor from '@uiw/react-md-editor';

let swLocal : boolean = true;
let dataonFilter: any = [];

export const RepairMdpcEmailGroupList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const { create, edit } = useNavigation();
    const [dataList, setDataList] = useState<any>([]);
    const [form] = Form.useForm();
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
 
    const userQuery = useGetIdentity();

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "email-groups",
        metaData: {
            populate: [
                '*',
                'property',
                'createBy',
                // 'appointmentType',
                // 'property',
                // 'project',
                // 'user_profile',
                // 'staff_has_properties',
                // 'appointment_time_slot',
                // 'reschedule_time_slot',
                'updateBy',
                // 'unit',
            ]
        },
        permanentFilter:[{
            field: 'type',
            operator: 'eq',
            value: 'Repair-mdpc'
        }],
        pagination:{
            pageSize: 100,
        },
        queryOptions: {
          onSuccess: (data) => {

            console.log('data xxxx : ', data.data);
            let dataLoad: any = data?.data;
            // dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            // if(prop_id){
            //     dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            // }

            if(prop_list !== undefined){
                console.log('case 1')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property?.id));
            }else if(prop_id !== null){
                console.log('case 2')
                dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            }
            
            // callFetchData();
            console.log('dataLoad', dataLoad)
            setDataList(dataLoad)
          },
        },
    });


    const columns: any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'Property',
            // dataIndex: 'property',
            // key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.property?.property_name.localeCompare(b.property?.property_name),
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <div>{record ? record?.property !== null ? record?.property?.property_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
                    </>
                );
            },
        },
        {
            title: 'EMAIL',
            // dataIndex: 'email',
            // key: 'email',
            align: 'center',
            className: "table_colmn_custom_left_value",
            render: (val:any, record:any) => {
                // return (
                //     <>
                //         <div>{record ? record.email !== null ? record.email : '-': '-'}</div>
                //         {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
                //     </>
                // );

                {
                    let splitEmail = record.email.split(",");
                    return <>
                         {
                            splitEmail.length !== 0 ?
                                splitEmail.map((data:any,index:any) => {
                                    // console.log('data : ', data);
                                    return <>
                                        <Tag className="tag_post"><TextField value={data ? data : <></> }  /></Tag>
                                    </>
                            })
                            : <>-</>
                        }
                    </>
                }
            },
        },
        {
            title: 'CREATE BY',
            // dataIndex: 'createBy',
            key: 'createBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.createBy?.first_name.localeCompare(b.createBy?.first_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                </>
            },
        },
        {
            title: 'CREATED DATE',
            // dataIndex: 'create_date',
            key: 'create_date',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'updateBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.updateBy?.first_name.localeCompare(b.updateBy?.first_name),
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'Last Update',
            // dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 100,
            fixed: 'right',
            align: 'center',
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            // onClick={() => edit("mjc-email-group", record.property.id + "_" + record.id )}
                            onClick={() => edit("repair-mdpc-email-group", record.id)}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                        />

                        {/* <EditButton
                            hideText
                            size="small"
                            recordItemId={record.id}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                        /> */}
                        {/* <CreateButton id="create_btn" onClick={()=>edit("notification", )} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }></CreateButton> */}
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    // type TablePaginationPosition =
    // | 'topLeft'
    // | 'topCenter'
    // | 'topRight'
    // | 'bottomLeft'
    // | 'bottomCenter'
    // | 'bottomRight';

    // const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    const [selProp, setselProp] = useState<any>();

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const clearDataSource = () => {
        setDataList([])
    }

    const [nameSearch, setnameSearch] = useState<any>("");

    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];

        if(mode == "S"){
            newData = nameSearch !== "" ? dataList?.filter((e:any)=> e?.property?.property_name?.toLowerCase().includes(String(nameSearch?.toLowerCase())) || e?.email?.toLowerCase().includes(String(nameSearch?.toLowerCase()))) : newData;
            newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property.id == selProp?.id}) : newData;
            setDataList(newData);                
        }else if(mode == "R"){
            form?.resetFields();
            clearDataSource()
            refetch();
            setselProp(undefined);
            setnameSearch("");
        }
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    return <>
        <List 
            title=""
        >

            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" 
                        // {...formProps} 
                        onFinish={(values:any) => {}}
                        form = {form} 
                    >
                        <Row gutter={20} style={{width: "100%"}}>
                            
                            <Col span={4} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select placeholder={t("select.sel")} 
                                        {...selProperty} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                    </Select>

                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="email" label="Search">
                                    <Input 
                                        placeholder="Search Email" 
                                        onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S", "xxxxx")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R", "xxxx")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form.Item style={{marginRight: "0",}}>
                        <CreateButton 
                            id="create_btn" 
                            onClick={()=>create("repair-mdpc-email-group")} 
                            // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                        ></CreateButton>
                    </Form.Item>
                </Col>
            </Row>


            {/* <Row gutter={12}>
                <Col span={22}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%"}}
                    >
                    </Form>
                </Col>
                <Col span={2}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton 
                                id="create_btn" 
                                onClick={()=>create("repair-mdpc-email-group")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row> */}

            <Table 
                dataSource={dataList} 
                columns={columns}
                onChange={onChange} 
                // pagination={{ size: "small", position: [ "bottomRight"] }} 
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    // showTotal: showTotal, 
                    pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                    showSizeChanger: true, // Optional - Show page size changer dropdown
                    // showQuickJumper: true, // Optional - Show quick jumper input
                    // pageSize: 10, // Optional - Default page size
                    pageSize: pageSize,
                    onChange: handlePageSizeChange,
                }}
                scroll={{ x: 1300 }}
            />
        </List>
    </>
}