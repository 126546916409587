/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { getValueProps } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY, consoleLog } from "../../constants";

let swLocal : boolean = true;
//let dataContent: any = undefined;
export const DocList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Document");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const { TabPane } = Tabs;
    const API_URL = useApiUrl();
    const { Dragger } = Upload;

    const [DataCus, setDataCus] = useState<any>();
    const [vIsFinance, setIsFinance] = useState<any>();
    const [vswLocal, setswLocal] = useState<boolean>(true);
    const { mutate: UpdateLocale } = useUpdate<any>();
    const [vFile, setFile] = useState<any>([]);
    const [FileList, setFileList] = useState<any>([]);

    const [document_type, setDocument_type] = useState<any>();
    const [document_sub_type, setDocument_sub_type] = useState<any>();
    const [doc_name, setDoc_name] = useState<any>();
    const [vFilterEN, setFilterEN] = useState<any>();
    const [dataContent, setDataContent] = useState<any>();
    const [dataProperty, setDataProperty] = useState<any>();
    
    const [deleteStatus, setdeleteStatus] = useState<any>("N")
    const [dataWithIndex, setDataWithIndex] = useState<any>()
    const [countl, setcountl] = useState<any>(0)
    
    const { confirm } = Modal;
    const { mutate } = useUpdate<ICoupon>();
    const ed_point = "documents";
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const [ dlStatus, setdlStatus] = useState("N");

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    const [pageSize, setPageSize] = useState(10); // Default page size

    const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
        metaData: {
            populate: ['*', 'localizations','localizations.updateBy','localizations.locale','updateBy','property','property.id','createBy', 'locale','document_sub_type','document_type','localizations.document_type'],
            locale:['th'],
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const {property} = params;
                filters.push(
                    {
                        field       : "document_type][id]",
                        operator    : "eq",
                        value       : !!document_type ? document_type : null
                    },
                    {
                        field       : "document_sub_type][id]",
                        operator    : "eq",
                        value       : !!document_sub_type ? document_sub_type : null
                    },
                    {
                        field       : "$or][0][doc_name]",
                        operator    : "contains",
                        value       : !!doc_name ? doc_name : null
                    },
                    {
                        field       : "$or][1][localizations][doc_name]",
                        operator    : "contains",
                        value       : !!doc_name ? doc_name : null
                    },
                    {
                        field       : "property][id]",
                        // operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                        // value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                        operator    : "eq",
                        value       : !!dataProperty ? dataProperty : null
                    },
                    {
                        field       : "deleteStatus",
                        operator    : "eq",
                        value       : deleteStatus
                    },
                );
            return filters;
        },
        // initialFilter: [
        //     {
        //         field       : "deleteStatus",
        //         operator    : "eq",
        //         value       : deleteStatus
        //     },
        //     {
        //         field : "property][id]",
        //         operator: (prop_list || prop_id) ? "eq" : "nin",
        //         value: prop_list ? prop_list: prop_id ? prop_id : null,
        //     }
        // ],
        queryOptions:{
            onSuccess(data) {
                let dataLoad:any = data?.data;
                //dataContent = dataLoad;
                setDataContent(dataLoad)
                // console.log("dataLoad",dataLoad)
            },
        }
        // permanentFilter:[
        //     {
        //         field : "property][id]",
        //         operator: data_property ? "eq" : "nin",
        //         value: data_property ? data_property : null
        //     }
        // ]
        
    });

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const columns = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => (page - 1) * pageSize + index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
            sorter: (a:any, b:any) => a.property?.property_name.localeCompare(b.property?.property_name),
            render: (property:any) => {
                return (
                    <>
                        <div>{property ? property.property_name !== null ? property.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'Document Name',
            dataIndex: 'doc_name',
            key: 'doc_name',
            sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
        },
        {
            title: 'DOCUMENT NAME (EN)',
            dataIndex: 'localizations',
            key: 'localizations',
            sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (localizations:any) => {
                if (Array.isArray(localizations)) {
                  return (
                    <>
                      {localizations.map((localizations, index) => (
                        <div key={index}>{localizations.doc_name !== undefined ? localizations.doc_name : '-'}</div>
                      ))}
                    </>
                  );
                }
                return <div>{localizations}</div>;
            },
        },
        {
            title: 'DOCUMENT TYPE',
            dataIndex: 'document_type',
            key: 'document_type',
            sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (document_type:any) => {
                return (
                    <>
                        <div>{document_type ? document_type.doc_name !== null ? document_type.doc_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.document_type.localeCompare(b.document_type),
        },
        {
            title: 'DOCUMENT SUB TYPE',
            dataIndex: 'document_sub_type',
            key: 'document_sub_type',
            sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (document_sub_type:any) => {
                // console.log('document_sub_type', document_sub_type ? document_sub_type.doc_name !== null ? document_sub_type.doc_name : 'null': 'null')
                return (
                    <>
                        <div>{document_sub_type ? document_sub_type.doc_name !== null ? document_sub_type.doc_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.document_sub_type.doc_name.localeCompare(b.document_sub_type.doc_name)
        },
        {
            title: 'CREATE BY',
            dataIndex: 'createBy',
            key: 'createBy',
            sorter: (a:any, b:any) => a.createBy?.first_name.localeCompare(b.createBy?.first_name),
            render: (createBy:any) => {
                return (
                    <>
                        <div >{createBy !== null? createBy.first_name ? createBy.first_name : "-" : "-"} {createBy !== null ? createBy.last_name ? createBy.last_name : "-" : "-"}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.document_type - b.document_type,
        },
        {
            title: 'CREATE DATE',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a:any, b:any) => {
                if (a.createdAt < b.createdAt) {return -1}
                if (a.createdAt > b.createdAt) {return 1}
                return (0)
            },
            render: (createdAt:any) => {
                return (
                    <>
                        <div >{createdAt ? Moment(createdAt).format('D/MM/yyyy HH:mm') : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UPDATE BY',
            dataIndex: 'updateBy',
            key: 'updateBy',
            sorter: (a:any, b:any) => a.updateBy?.first_name.localeCompare(b.updateBy?.first_name),
            render: (updateBy:any) => {
                return (
                    <>
                        {/* <div >{updateBy.first_name} {updateBy.last_name}</div> */}
                        <div >{updateBy !== null ? updateBy.first_name : "-"} {updateBy !== null ? updateBy.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'LAST UPDATE',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (a:any, b:any) => {
                if (a.updatedAt < b.updatedAt) {return -1}
                if (a.updatedAt > b.updatedAt) {return 1}
                return (0)
            },
            render: (updatedAt:any) => {
                return (
                    <>
                        <div >{updatedAt ? Moment(updatedAt).format('D/MM/yyyy HH:mm') : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            // fixed: 'right',
            // width: 90,
            // align: 'center',
            render: (record:any) => {
                return (
                    <>
                        {
                            record.deleteStatus === "N" ? 
                                <>
                                    <EditButton
                                        style={{marginRight:'4px'}}
                                        hideText
                                        size="small"
                                        onClick={()=> editModalShow(record.id)}
                                        disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                    
                                    <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                </>
                            :
                                <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                        }
                    </>
                );
            },
        },
    ];

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
    } = useModalForm<any>({
        resource: "documents",
        metaData: {
            populate: '*',
            locale:['th']
        },
        action: "create",
        redirect: false
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading ,
        show: editModalShow,
        close
    } = useModalForm<any>({
        resource: "documents",
        metaData: {
            populate: ['property','document_type','document_sub_type','file','localizations', 'localizations.property','localizations.document_type','localizations.document_sub_type','localizations.file', 'updateBy'],
            locale:['th']
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            setDataCus(data?.data)
            console.log(data?.data)

            if(data.data.document_type.id == 3){
                setIsFinance(true)
            }

            if(data?.data?.file !== null){

                let sortFileListData = data?.data?.file.sort(function (a: { id: number; }, b: { id: number; }) {
                    return b.id - a.id;
                    // return a.id - b.id;
                });
                // setFileList((pre:any)=> ([...pre , ...data?.data?.file]));
                setFileList((pre:any)=> ([...pre , ...sortFileListData]));
            }else{
                setFileList([])
            }

            // array.sort((a, b) => (a.id < b.id) ? 1 : -1);
            // FileList.sort((a: { id: number; }, b: { id: number; }) => (a.id < b.id) ? 1 : -1);
            // FileList.sort(function (a: { id: number; }, b: { id: number; }) {
            //     // return b.id - a.id;
            //     return a.id - b.id;
            // });

            // console.log('filelist', FileList)

        }},
    });

    const { selectProps: selDocType } = useSelect<any>({
        resource    : "document-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selDocSubType } = useSelect<any>({
        resource    : "document-sub-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    useEffect(() => {
        if(DataCus && countl == 0){
            const fileList: UploadFile[] = 
                DataCus?.file ? DataCus?.file.map((v:any)=>(
                    {
                        uid: String(v.id),
                        name: v.name,
                        status: "done",
                        url: v.url,
                        thumbUrl: v.url
                    }
                )):[];

            setFile({
                name: 'files',
                multiple: true,
                defaultFileList: [...fileList],
            })

            editProps?.form?.resetFields(["file"]);

            setcountl(1);
        }
    }, [editProps])
    
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    const beforeUpload = (file: RcFile) => {
        // const isJpgOrPng = file.type === 'application/pdf' 
        //     || file.type === 'text/csv' 
        //     || file.type === 'application/vnd.ms-excel' 
        //     || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        //     || file.type === 'application/msword' 
        //     || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const isJpgOrPng = file.type === 'application/pdf';

        if (!isJpgOrPng) {
        //   message.error('You can only upload PDF/CSV/XLS/XLSX/DOC/DOCX file.');
          message.error('You can only upload PDF file.');
        }
        const isLt2M = file.size / 1024 / 1024 < 25;
        if (!isLt2M) {
          message.error('File must smaller than 25MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function loadLocal(){
        if(swLocal == true){
            editProps?.form?.setFieldsValue({doc_name: DataCus ? DataCus.doc_name : null})

            const fileList: UploadFile[] = 
                DataCus && DataCus?.file.map((v:any)=>(
                    {
                        uid: String(v.id),
                        name: v.name,
                        status: "done",
                        url:
                            v.url,
                        thumbUrl:
                            v.url
                    }
                ));

            setFile({
                name: 'files',
                multiple: true,
                defaultFileList: [...fileList],
            })

            if(DataCus && DataCus.file !== null){
                setFileList((pre:any)=> ([...DataCus.file]));
                // setFileList((pre:any)=> ([...pre , ...DataCus.file]));
            }else{
                setFileList([])
            }

        }else{
            editProps?.form?.setFieldsValue({doc_name: DataCus.localizations.length > 0 && DataCus.localizations[0]?.doc_name ?  DataCus.localizations[0]?.doc_name : null})

            const fileList: UploadFile[] = 
                DataCus.localizations.length > 0 ? DataCus.localizations[0]?.file?.map((v:any)=>(
                        {
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url:
                                v.url,
                            thumbUrl:
                                v.url
                        }
                    )) : []
          
            setFile({
                name: 'files',
                multiple: true,
                defaultFileList: [...fileList],
            })

            if(DataCus.localizations.length > 0 && DataCus.localizations[0]?.file !== null){
                setFileList((pre:any)=> ([...DataCus.localizations[0]?.file]));
            }else{
                setFileList([])
            }
        }

        editProps?.form?.resetFields(["file"]);
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }
 
    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                deleteStatus    : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        });

        CreateLogDelete({ 
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(),  
                log_event       : event,
                menu_event      : 'document-sub-types',
                SysPkID         : id+""
            },
            errorNotification:false,
            successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function updateLocaleEn(value: any){
        UpdateLocale({
            resource : 'documents',
            id: DataCus.localizations[0].id,
            values:{
                doc_name : value?.doc_name,
                file : FileList,
                updateBy : user_data.user_profile.id,
                locale : "en"
            },
            successNotification:false,
            },{
                onSuccess(data, variables, context) {
                    close();
                },
            }
        )
    }

    function updateUserUpdateBy(){
        UpdateLocale({
            resource : 'documents',
            id: DataCus.id,
            values:{
                updateBy : user_data.user_profile.id
            },
            successNotification:false,
        },{
            onSuccess(data, variables, context) {
                close();
            },
        }
        )
    }

    function fileChange(e:any){
        let res = e.file;
        if(res.status == "removed"){
            const NewFileList = FileList.filter((e:any)=> e.name !== res.name)
            setFileList((pre:any)=> ([...NewFileList]))
        }else if(res.status == "done"){
            if(e.file.response){
                let res = e.file.response;
                const ListFile = FileList.filter((e:any)=> e.id == res[0].id)
                if(ListFile.length == 0){
                    setFileList((pre:any)=> ([...pre , res[0]]));
                }else{
                    const NewFileList = FileList.filter((e:any)=> e.id !== res[0].id)
                    setFileList((pre:any)=> ([...NewFileList]))
                }
            }
        }
    }

    const handlePageSizeChange = (current:any, size:any) => {
        setPage(current)
        setPageSize(size);
    };

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const onReset = () => {
        setDataProperty(undefined)
        setFilterEN(undefined);
        setDoc_name(undefined);
        setDocument_type(undefined);
        setDocument_sub_type(undefined);
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };
    
    return <>
            <List 
                title={t("document.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("document.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("document.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row gutter={12}>
                    <Col span={22}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="property">
                                <Select placeholder="Property" onChange={(e) => setDataProperty(e)} {...selProperty} allowClear style={{width: "220px"}}/>
                            </Form.Item>

                            <Form.Item name="document_type">
                                <Select placeholder="Document Type" onChange={(e) => setDocument_type(e)} {...selDocType} allowClear style={{width: "220px"}}/>
                            </Form.Item>

                            <Form.Item name="document_sub_type">
                                <Select placeholder="Document Sub Type" onChange={(e) => setDocument_sub_type(e)} {...selDocSubType} allowClear style={{width: "220px"}}/>
                            </Form.Item>

                            <Form.Item name="doc_name">
                                <Input placeholder="Document Name" 
                                    allowClear
                                    style={{borderRadius: 5}}
                                    onChange={(e) => setDoc_name(e.target.value)} 
                                />
                            </Form.Item>

                            <Form.Item name="doc_name_en">
                                <Input placeholder="Document Name (EN)" 
                                    allowClear
                                    style={{borderRadius: 5}}
                                    onChange={(e) => setFilterEN(e.target.value)} 
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button 
                                    onClick={onReset}
                                    htmlType="submit" 
                                    style={{borderRadius: "5px"}}
                                    icon={<Icons.UndoOutlined />}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                        
                    </Col>
                    <Col span={2}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item style={{marginRight: "0",}}>
                                {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                                {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} /> */}
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Form {...searchFormProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={"N"}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={(e: any)=> setdeleteStatus(e)} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        {/* <Badge count={dataCUSTOM?.data.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/> */}
                                        <Badge 
                                            count={dataContent?.filter((e:any)=> e.deleteStatus == "N").length}
                                            style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}
                                        />
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        {/* <Badge count={dataCUSTOM?.data.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/> */}
                                        <Badge 
                                            count={dataContent?.filter((e:any)=> e.deleteStatus == "Y").length}
                                            style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}
                                        />
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table 
                    //dataSource={dataWithIndex?.filter((f:any) => f?.deleteStatus == deleteStatus)} 
                    dataSource={dataContent?.filter((f:any) => f?.deleteStatus == deleteStatus)} 
                    //{...tableProps}
                    columns={columns} 
                    onChange={onChange} 
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                        showSizeChanger: true, // Optional - Show page size changer dropdown
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                />
            </List>

            <Modal 
                {...createModalProps}
                title={"Document Create"}
                okText = {"Create"}
                width={450}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                    setFileList([]);
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        // console.log(values)
                        values.property = values.property.id;
                        values.document_type = values.document_type.id;
                        // values.document_sub_type = values.document_sub_type.id;
                        values.document_sub_type = values?.document_type == 3 ? null : values.document_sub_type ;
                        values.doc_name = values.doc_name;
                        values.noti = true;
                        values.createBy = user_data.user_profile.id;
                        values.deleteStatus = "N";
                        values.locale = "th"
                        values.file = FileList;

                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty}/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["document_type", "id"]}
                                label="Document Type"
                                rules={[
                                    {
                                        required: true, message: "Please select document type"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selDocType}
                                    onSelect={(value: any) => {
                                        value == 3 ? setIsFinance(true) : setIsFinance(false)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        {
                            vIsFinance ?
                            <></>
                            :
                            <>
                                <Col span={24} className="gutter-row">
                                    <Form.Item
                                        name={["document_sub_type", "id"]}
                                        label="Document Sub Type"
                                        rules={[
                                            {
                                                required: true, message: "Please select document sub type"
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")} {...selDocSubType} />
                                    </Form.Item>
                                </Col>
                            </>
                        }

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="doc_name"
                                label="Document Name"
                                rules={[
                                    {
                                        required: true, message: "Please input dcoument name"
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="file"
                                label="Document files (Only PDF Files)"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: "Please select file"
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    style={{borderRadius: 10}}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Upload.Dragger>
                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Document Edit"}
                okText = {"Save"}
                width={450}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                    // swLocal= true;
                    setswLocal(true);
                    editProps?.form?.resetFields(["file"]);
                    setDataCus(undefined)
                    setcountl(0)
                    setFileList([])
                    setIsFinance(false)
                }}
            >
                <Form 
                    {...editProps}
                    layout="vertical"
                    onFinish={(values:any) => {    
                        values.document_type = values?.document_type.id;
                        values.noti = true;
                        if((values?.document_sub_type !== null && values?.document_sub_type !== undefined) && (values?.document_sub_type.id !== null && values?.document_sub_type.id !== undefined)){
                            values.document_sub_type = values?.document_sub_type.id
                        }else{
                            values.document_sub_type = null
                        }

                        // console.log('swLocal', swLocal)

                        if(swLocal == true){
                            values.locale = "th";
                            values.updateBy = user_data.user_profile.id;
                            values.file = FileList;
                            values.noti = true;
                            return (
                                editProps.onFinish &&
                                editProps.onFinish(values)
                            );
                        }else{
                            // console.log(values)
                            updateLocaleEn(values);
                            setswLocal(true);
                            updateUserUpdateBy();
                        }
                    }}
                    // onLoad={(values:any) => {
                    //     console.log('val', values)
                    // }}
                >
                    <Row gutter={16}>
                        <Radio.Group 
                            options={optionsRdioLocale} 
                            onChange={(e)=> {
                                swLocal = e.target.value
                                setswLocal(e.target.value)
                                loadLocal();
                            }}
                            defaultValue={true}
                            optionType="button"
                            style={{marginBottom: "2rem"}}
                        />

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty} disabled={vswLocal == false ? true : false}/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["document_type", "id"]}
                                label="Document Type"
                                rules={[
                                    {
                                        required: true, message: "Please select document type"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selDocType} disabled={vswLocal == false ? true : false} />
                            </Form.Item>
                        </Col>

                        {
                            vIsFinance ?
                            <></>
                            :
                            <>
                                 <Col span={24} className="gutter-row">
                                    <Form.Item
                                        name={["document_sub_type", "id"]}
                                        label="Document Sub Type"
                                    >
                                        <Select placeholder={t("select.sel")} {...selDocSubType} disabled={vswLocal == false ? true : false} allowClear/>
                                    </Form.Item>
                                </Col>
                            </>
                        }
                       
                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="doc_name"
                                label="Document Name"
                                rules={[
                                    {
                                        required: true, message: "Please input document name"
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="file"
                                label="Document files (Only PDF Files)"
                            >
                                <Dragger style={{borderRadius: "10px"}} 
                                    {...vFile}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}