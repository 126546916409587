/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, Divider, EditButton } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { Key, useEffect, useState } from "react";
import { TOKEN_KEY } from "../../../constants";
import { ExclamationCircleOutlined, ArrowRightOutlined, ArrowLeftOutlined, LeftOutlined, RightOutlined, } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { Carousel, DatePicker, notification, Table, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { majorCareUrl } from "../../../constants";

 {/* 
    6485ba66049e59df2850eb9e = รอดำเนินการ
    6485bcda049e59df2850eba0 = รับเรื่อง
    6485bcf9049e59df2850eba2 = รอตรวจ
    6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
    6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
    6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
    6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
    6485bd8b681aef755a4f279f = รอนัดหมาย
    6485bd96681aef755a4f27a1 = หมดอายุ
*/}

export const MjcDoneEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);
    const { create, edit } = useNavigation();

    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]

    // console.log('item_id', item_id)
    const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    // Data ส่วนของ รายละเอียดการแจ้ง
    const [JobNo, SetJobNo] = useState<any>();
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [Unit, SetUnit] = useState<any>();
    const [Customer, SetCustomer] = useState<any>();
    const [Type, SetType] = useState<any>();
    const [AppointStart, SetAppointStart] = useState<any>();
    const [AppointEnd, SetAppointEnd] = useState<any>();
    const [InpectorName, SetInpectorName] = useState<any>();

    // Data การอนุมัติจากลูกค้า
    const [ApproveDate, SetApproveDate] = useState<any>();
    const [ApproveName, SetApproveName] = useState<any>();
    const [ApproveType, SetApproveType] = useState<any>();
    const [ApproveUrl, SetApproveUrl] = useState<any>();

    const [dataChecklist, setDataCheckList] = useState<any>([]);

    const [EvaOne, SetEvaOne] = useState<any>();
    const [EvaTwo, SetEvaTwo] = useState<any>();
    const [ResultApprove, SetResultApprove] = useState<any>();

    const getDataK = async ()=>{
        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjob?id=648ab8ca326b1b890c5cf5ec
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findOneHomecheckjob?id=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad', dataLoad)
                console.log('dataLoad[0]', dataLoad[0])
                // console.log('Type', dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes)

                SetJobNo(dataLoad[0].job_id ? dataLoad[0].job_id : '-')
                SetCreateDate(dataLoad[0].create_date)
                SetProperty(dataLoad[0] ? dataLoad[0].property_obj.length > 0 ? dataLoad[0].property_obj[0].property_name : '-' : '-')
                SetUnit(dataLoad[0] ? dataLoad[0].unit_obj ? dataLoad[0].unit_obj[0].unit_name : '-' : '-')

                SetCustomer(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.first_name + ' ' + dataLoad[0].request_by.attributes.last_name : '-')

                // Data การอนุมัติจากลูกค้า
                SetApproveDate(dataLoad[0].approve ? dataLoad[0].update_date : '-')
                SetApproveName(dataLoad[0].approve ? dataLoad[0].approve.approve_name : '-')
                SetApproveType(dataLoad[0].approve ? dataLoad[0].approve.approve_user_type : '-')
                SetApproveUrl(dataLoad[0].approve ? dataLoad[0].approve.sign : '-')

                SetTransferDate(dataLoad[0] ? dataLoad[0].request_by ? dataLoad[0].request_by.unitDetail ? dayjs(dataLoad[0].request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-');

                SetType(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.user_sub_types.data.length > 0 ? dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-' : '-')

                SetResultApprove(dataLoad[0].approve ? dataLoad[0].approve.remark : '-')

            });

            let res_data_trail = await axios.get(
                majorCareUrl + `/majorcare/findTrailByJob?jid=` + item_id ,
                { headers: {  } }).then((res) => {
                    let dataTrail: any = res?.data;
                    console.log('dataTrail', dataTrail)
                    SetEvaOne(dataTrail[dataTrail.length -1] ? dataTrail[dataTrail.length -1].hcu_job[0] ? dataTrail[dataTrail.length -1].hcu_job[0].eva_form[0].value : '-' : '-')
                    SetEvaTwo(dataTrail[dataTrail.length -1] ? dataTrail[dataTrail.length -1].hcu_job[0] ? dataTrail[dataTrail.length -1].hcu_job[0].eva_form[1].value : '-' : '-')
                    // console.log('Type', dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes)
                    let trail_assign = dataTrail.filter((item:any) => item?.status == "6485bcf9049e59df2850eba2");
                    // console.log('trail_assign', trail_assign[trail_assign.length-1])
    
                    if(trail_assign){
                        // SetInpectorName(trail_assign ? trail_assign.assign.attributes.user_profile ? trail_assign.assign.attributes.user_profile.data.attributes.first_name + ' ' + trail_assign.assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )
                        // setAppointStart(trail_assign ? dayjs(trail_assign.appoint_start).format("DD/MM/YYYY HH:mm") : '-');
                        // setAppointEnd(trail_assign ? dayjs(trail_assign.appoint_end).format("DD/MM/YYYY HH:mm") : '-');
                        SetInpectorName(trail_assign ? trail_assign[trail_assign.length-1].assign.attributes.user_profile ? trail_assign[trail_assign.length-1].assign.attributes.user_profile.data.attributes.first_name + ' ' + trail_assign[trail_assign.length-1].assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )
                        SetAppointStart(trail_assign ? dayjs(trail_assign[trail_assign.length-1].appoint_start).format("DD/MM/YYYY HH:mm") : '-');
                        SetAppointEnd(trail_assign ? dayjs(trail_assign[trail_assign.length-1].appoint_end).format("DD/MM/YYYY HH:mm") : '-');
                    }else{
                        SetInpectorName('-')
                    }
                });
    }

    // console.log('InpectorName', InpectorName)

    const getDataTrail = async ()=>{

        // เช็คว่าเป็นสถานะ 'รอตรวจ' หรือยัง
        // http://localhost:10000/api/majorcare/findAllHomecheckTrailList?jid=648bcf8939a38859f358deaa
        let res_data_trail = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckTrailList?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoadTrail: any = res?.data;
                // console.log('dataLoadTrail', dataLoadTrail)

                for (let index = 0; index < dataLoadTrail.length; index++) {
                    dataTrail.push(dataLoadTrail[index]);
                }
            });

        // find checklist
        // https://api-dev-majorcare.techscape.app/api/majorcare/findAllHomecheckjobChecklist?jid=648c99b100bf4d6e42787790
        let res_data_checklist = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckjobChecklist?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
                
                // dataChecklist.push(dataLoad)

                // let xx = dataLoad.filter((e:any)=> e.type == "CHECKLIST");
                // for (let index = 0; index < xx.length; index++) {
                //     dataChecklist.push(xx[index]);
                // }

                for (let index = 0; index < dataLoad.length; index++) {
                    dataChecklist.push(dataLoad[index]);
                }

            });
    }

    // useEffect(() => {
    //     if (countInterval < 3) {
    //         const interval = setInterval(() => {
    //             setCountInterval(prev => prev + 1);
    //             if(countInterval === 1){
    //                 // console.log('call')
    //                 getDataK();
    //             }
    //             // console.log('res_data', res_data);
    //             // console.log('property', Property);
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [countInterval])

     // ========================= NEW CHECKLIST MAP DATA ===============================
    // Prepare table columns
    const columnsx = [
        {
            title: 'No.',
            // dataIndex: 'item_name',
            // key: 'item_name',
            render: (value:any, record:any, index:any) => {
                return (
                    <>
                        <div>{index + 1}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.zone")}</span>,
            dataIndex: 'zoneName',
            key: 'zoneName',
            // render: (value:any, record:any, index:any) => {
            //     console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.structure")}</span>,
            dataIndex: 'structureName',
            key: 'structureName',
            // render: (value:any, record:any, index:any) => {
            //     // console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.checklist")}</span>,
            dataIndex: 'checklistName',
            key: 'checklistName',
            // render: (value:any, record:any, index:any) => {
            //     // console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.check-status")}</span>,
            // dataIndex: 'inspec_stat',
            key: 'inspec_stat',
            render: (value:any, record:any, index:any) => {
                // console.log('record xxxx', record)
                return (
                    <>
                        {
                            record.is_status ?
                                // <Tag>ปกติ</Tag>
                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}> ปกติ </Tag>
                            :
                                // <Tag>ไม่ปกติ</Tag>
                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}> ไม่ปกติ </Tag>
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.warranty-conditions")}</span>,
            // dataIndex: 'waranty',
            // key: 'waranty',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.is_warantee ?
                                <div>{t("mjc-job.in-warranty")}</div>
                            :
                                <div>{t("mjc-job.out-warranty")}</div>
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.pic")}</span>,
            // dataIndex: 'pic',
            key: 'pic',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.pic ?
                                <>
                                    <Row>
                                        <Image 
                                            width={50} 
                                            height={50}
                                            src={record.pic[0]?.url} 
                                            style={{marginRight:'50px'}}
                                        />
                                        {
                                            record?.pic.length > 1 &&
                                                <div 
                                                    className="div_img_checklist"
                                                    style={{
                                                        backgroundImage: `url("${record.pic[0]?.url}")`,
                                                        width: '50px',
                                                        height: '50px',
                                                        backgroundSize:'cover',
                                                        backgroundColor:'#616161'
                                                        // backdropFilter: blur(0px')
                                                    }}
                                                    onClick={()=> {modalImg(record?.pic)}}
                                                >
                                                    <div
                                                        style={{
                                                            // alignItems:'center',
                                                            // justifyItems:'center',
                                                            // justifyContent:'center',
                                                            textAlign: 'center',
                                                            verticalAlign: 'center',
                                                            lineHeight: '50px',
                                                            fontSize:'25px',
                                                            color:'#ffffff',
                                                            width: '50px',
                                                            height: '50px',
                                                        }}
                                                    >
                                                        {/* {record?.pic.length -1}{'+'} */}
                                                        {'+'}{record?.pic.length -1}
                                                    </div>
                                                </div>
                                        }
                                    </Row>
                                </>
                            :
                                <>-</>
                        }
                    </>
                );
            },
        },
        {
            title: 'Action',
            // dataIndex: 'pic',
            key: 'action',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            onClick={() => edit("mjc-inspec-checklist-todayjob-edit", record._id + "_" + item_id + "_" + record.structureName + "_" + record.zoneName )}
                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                        />
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    // Prepare table data source
    const dataSourceX = dataChecklist.reduce((acc:any, item:any) => {
        // console.log('reduce item', item)
        let zoneName = '';
        let structureName = '';
        let checklistName = '';

        let is_status:any
        let is_warantee:any
        let pic:any
        let remark:any
        let _id:any
        let item_id:any
        // let zone_name:any
        // let structure_name:any

        if (item.type === 'CHECKLIST') {
            const structure = dataChecklist.find((data:any) => data.template_check_list_id === item.parent && data.type === 'STRUCTURE');
            // console.log('structure', structure)
            if (structure) {
                structureName = structure.item_detail[0] ? structure.item_detail[0].item_name : '-';
                const zone = dataChecklist.find((data:any) => data.template_check_list_id === structure.parent && data.type === 'ZONE');
                if (zone) {
                    // console.log('zone zzzzzzzzzzzzzzzzzzzz', zone)
                    zoneName = zone.item_detail[0] ? zone.item_detail[0].item_name : '-';
                }
            }
            checklistName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
            is_warantee = item.is_warantee;
            is_status = item.is_status;
            pic = item.pic;
            remark = item.remark;
            _id = item._id;
            item_id = item.item;
        } else if (item.type === 'STRUCTURE') {
            const zone = dataChecklist.find((data:any) => data.template_check_list_id === item.parent && data.type === 'ZONE');
            if (zone) {
                zoneName = zone.item_detail[0] ? zone.item_detail[0].item_name : '-';
            }
            structureName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
        } else if (item.type === 'ZONE') {
            zoneName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
        }

        if (item.type === 'CHECKLIST') {
            acc.push({
                key: item._id,
                zoneName,
                structureName,
                checklistName,

                is_warantee,
                is_status,
                pic,
                remark,
                _id,
                item_id
            });
        }

        return acc;
    }, []);
    
    // ========================= NEW CHECKLIST MAP DATA ===============================

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    getDataTrail();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();

    // async function createAssignInspec(values:any) {
    //     replace("/majorcare/setup-hcu")
    // }

    const handleModalClose = () => {
        // Handle modal close event if needed
    };
    
    const renderModalContent = (val:any) => (
        <Carousel
            autoplay
            style={{
            height: '80%',
            // width: '50%',
            margin: 0,
            padding: 0,
            background: 'rgba(0, 0, 0, 0.8)',
            }}
            arrows 
            nextArrow={<RightOutlined />} 
            prevArrow={<LeftOutlined/>}
        >
            {val.map((item: { url: string | undefined; }, key: Key | null | undefined) => (
            <div key={key} style={{ height: '80%', display: 'flex', justifyContent: 'center' }}>
                <Image
                preview={false}
                src={item.url}
                alt="carousel-image"
                style={{ height: '80%', objectFit: 'cover', borderRadius: '30px' }}
                />
            </div>
            ))}
        </Carousel>
    );

    const modalImg = (val:any) => {
      Modal.info({
        icon: false,
        content: renderModalContent(val),
        width: '50%',
        style: { top: 0, left: 0 },
        maskClosable: true,
        onOk: handleModalClose,
        onCancel: handleModalClose,
      });
    }
  
    const colTrail:any = [
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.operation-date")}</span>,
            dataIndex: 'create_date',
            key: 'create_date',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                // console.log('record',record)
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.list-creator")}</span>,
            dataIndex: 'create_by',
            key: 'create_by',
            // sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_by ? record.create_by.first_name + ' ' + record.create_by.last_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.status")}</span>,
            dataIndex: 'status',
            key: 'status',
            render: (val:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        { 
                            record ?
                                record.status == "6485ba66049e59df2850eb9e" ? 
                                        <Tag>รอดำเนินการ</Tag>
                                    : 
                                        record.status == "6485bcda049e59df2850eba0" ? 
                                            <Tag>รับเรื่อง</Tag>
                                        :
                                            record.status == "6485bcf9049e59df2850eba2" ? 
                                                <Tag>รอตรวจ</Tag>
                                            :
                                                record.status == "6485bd3a681aef755a4f2797" ? 
                                                    <Tag>เข้าตรวจหน้างาน</Tag>
                                                :
                                                    record.status == "6485bd56681aef755a4f2799" ? 
                                                        <Tag>เข้าตรวจหน้างาน - เกินกำหนด</Tag>
                                                    :
                                                        record.status == "6485bd6e681aef755a4f279b" ? 
                                                            <Tag>รออนุมัติผลการตรวจ</Tag>
                                                        :
                                                            record.status == "6485bd7d681aef755a4f279d" ? 
                                                                <Tag>เสร็จสมบูรณ์</Tag>
                                                            :
                                                                record.status == "6485bd8b681aef755a4f279f" ? 
                                                                    <Tag>รอนัดหมาย</Tag>
                                                                :
                                                                    record.status == "6485bd96681aef755a4f27a1" ? 
                                                                    <Tag>หมดอายุ</Tag>
                                                                : '-'
                            :
                                '-'
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.sla")}</span>,
            dataIndex: 'sla',
            key: 'sla',
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.sla ? record.sla.sla_time  + ' นาที' : '': ''}</div> */}
                        <div>{record ? record.sla ? record.sla.sla_time !== '-' && record.sla.sla_time !== '' ? record.sla.sla_time  + ' นาที' : '-' : '-': '-'}</div>

                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.remark")}</span>,
            dataIndex: 'remark',
            key: 'remark',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.remark ? record.remark : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    // console.log('Property', Property)
    // console.log('Unit', Unit)
    // console.log('Customer', Customer)
    // console.log('AppointStart', AppointStart)
    // console.log('AppointEnd', AppointEnd)
    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("service&repair.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("mjc-job.list")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    replace("/majorcare/setup-hcu")

                }}
            >

                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.detail")}
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label={t("mjc-job.job-no")}
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={JobNo} defaultValue={JobNo} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.property")}
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Input value={Property} defaultValue={Property} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.customer")}
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Customer} defaultValue={Customer} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label={t("mjc-job.created-date")}
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(CreateDate).format("DD/MM/YY HH:mm")} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.unit-no")}
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Unit} defaultValue={Unit} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.customer-type")} 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Type} defaultValue={Type} disabled/>
                                </Form.Item>

                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label={t("mjc-job.trans-date")} 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>   
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.last-appointment")}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.trans-date")} 
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <DatePicker className="ant-picker-mjc" /> */}
                            <Input defaultValue={AppointStart} value={AppointStart} disabled />
                        </Form.Item>

                        <Form.Item 
                            label={t("mjc-job.inspector")} 
                            // name="inspector"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Select/> */}
                            <Input defaultValue={InpectorName} value={InpectorName} disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.to-date")} 
                            // name=""
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input defaultValue={AppointEnd} value={AppointEnd} disabled />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.checklist")}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        {/* <Table 
                            columns={colChecklist}
                            // dataSource={dataChecklist}
                            dataSource={dataChecklist.length > 0 ? dataChecklist : null}
                        /> */}
                        {/* <Table columns={columnsx} dataSource={dataSourceX} /> */}
                        <Table columns={columnsx} dataSource={dataSourceX} />

                    </Col>
                </Row>

                <div style={{width:'100%'}}>
                    <Row>
                        <p style={{fontSize: "15px"}}>{`รายการตรวจทั้งหมด ${!!dataSourceX ? dataSourceX.length : "0"} รายการ`}</p>
                    </Row>
                    <Row>
                        <Col style={{paddingRight: "15px"}}>
                            <p style={{fontSize: "15px"}}>{`ปกติ ${dataSourceX.filter((e:any)=> e.is_status == true).length} รายการ`}</p>
                        </Col>
                        <Col>
                            <p style={{fontSize: "15px"}}>{`ไม่ปกติ ${dataSourceX.filter((e:any)=> e.is_status != true).length} รายการ`}</p>
                        </Col>
                    </Row>
                </div>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.customer-approve")}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.approve-date")}
                            // name=""
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input value={dayjs(ApproveDate).format("DD/MM/YY HH:mm")} defaultValue={dayjs(ApproveDate).format("DD/MM/YY HH:mm")}  disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.approver")}
                            // name=""
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input value={ApproveName} defaultValue={ApproveName} disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.customer-type")}
                            // name=""
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input value={ApproveType} defaultValue={ApproveType} disabled/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.signature")}
                            // name=""
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Image></Image> */}
                            <Image 
                                style={{border:"3px soLid #fff",width: "100%", height: "auto",display: "block",
                                marginLeft: "auto", marginRight: "auto",
                                // background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",
                                borderRadius: "10px"}}
                                src={ApproveUrl} 
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.checkin-on-time")}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={4}>
                         <div>
                            {t("mjc-job.inspecting-on-time")} :
                         </div>
                    </Col>
                    <Col span={3}>
                        {
                            EvaOne == 'Y' ?
                                <Tag color="green">{t("mjc-job.checkin-on-time")}</Tag>
                            :
                                <Tag color="red">{t("mjc-job.checkin-out-of-time")}</Tag>
                        }
                    </Col>
                    <Col span={3}>
                         <div>
                            {t("mjc-job.rating-inspection")} :
                         </div>
                    </Col>
                    <Col span={1}>
                        <Tag>{EvaTwo}</Tag>
                    </Col>
                    <Col span={2}>
                         <div>
                            {t("mjc-job.suggestion")} :
                         </div>
                    </Col>
                    <Col span={4}>
                        <Tag>{ResultApprove}</Tag>
                    </Col>

                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.job-log")}
                        </div>
                    </Col>
                </Row>

                <Row>
                <Col span={24}>
                        <Table 
                            columns={colTrail} 
                            dataSource={dataTrail.length > 0 ? dataTrail : null}
                        />
                    </Col>
                </Row>
            </Form>
    </Edit>   
    );
};