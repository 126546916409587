/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog, IProperties } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { TextArea } from "react-mde";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { TOKEN_KEY } from "../../../constants";

let listProps: any = [];
export const RepairFacilityTermConditionList: React.FC<IResourceComponentsProps> = () => {
    const [ Tab, SetTab] = useState<any>();
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const tabTermActive = localStorage.getItem("keyTabTermRepair");
    
    const { TabPane } = Tabs;
    const API_URL = useApiUrl();
    const { create, edit } = useNavigation();
    const { confirm } = Modal;
    const token = localStorage.getItem(TOKEN_KEY);


    const [dataList, setDataList] = useState<any>([]);
    const [dataBlank, setDataBlank] = useState<any>([]);
    const currentLocale = localStorage.getItem("CURRENT_LOCALE");

    const [dataSource, setDataSource] = useState<any>();
    const [tableFilter, setTableFilter] = useState<any>([]);

    const [Listdata, setListdata] = useState<any>([]);
    const [searchProp, setsearchProp] = useState<any>();
    const [searchTopic, setsearchTopic] = useState<any>();

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    console.log('prop_list', prop_list)

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "repair-setting-facilities",
        metaData: {
            // populate: [
            //     '*',
            // ]
            populate: ['*', 'rp_setting', 'rp_setting.createBy', 'rp_setting.updateBy','property'],
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            let dataLoad: any = data?.data;

            console.log('dataLoad', dataLoad)

            let newData: any = [];
            let dataonFilter: any = [];
            // dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            // if(prop_id){
            //     dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            // }

            if(prop_list){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property.id));
            }

            for (let index = 0; index < dataLoad?.length; index++) {
                // console.log('dataload loop', dataLoad[index])
                dataonFilter = dataLoad[index]?.rp_setting?.filter((e:any)=> e.repair_type?.name == "TAC" || e.repair_type?.name == "Information" )
                dataonFilter.forEach((obj:any) => {
                    obj.property_name = dataLoad[index].property.property_name;
                    obj.property_id = dataLoad[index].property.id;
                    obj.record_id = dataLoad[index].id;
                    obj.createdAt = dataLoad[index].createdAt;
                    obj.updatedAt = dataLoad[index].updatedAt;
                });

                newData.push(...dataonFilter)
            }

            if(newData?.length > 0){
                const flattenedArray = newData.reduce((accumulator:any, currentArray:any) => {
                    return accumulator.concat(currentArray);
                }, []);

                listProps = flattenedArray;
                setListdata(flattenedArray)
            }
            // SetTab(tabTermActive)

            // SetTab(tabTermActive ? tabTermActive : "Information")
            // console.log(">>> newData",newData)
            SetTab(tabTermActive ? tabTermActive : "TAC")

          },
        },
    });

    const { selectProps: selproperty} = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });


    // useEffect(() => {
    //     refetch();
    // }, []);

    // let DataInfor:any;
    // let DataTac:any;
    // if(dataList.length > 0){
    //     const flattenedArray = dataList.reduce((accumulator:any, currentArray:any) => {
    //         return accumulator.concat(currentArray);
    //     }, []);

    //     dataonFilter = flattenedArray

    //     DataTac = flattenedArray?.filter((e:any)=> e.repair_type?.name == "TAC")
    //     DataInfor = flattenedArray?.filter((e:any)=> e.repair_type?.name == "Information")
    // }


    async function updateStatus(id:any, property_id:any, stat:any){
        let data = { 
            "data":{
                "property": property_id,
                "rp_setting": [
                    {
                        "id": Number(id),
                        "deleteStatus": stat,
                    }
                ]
            }
        }
        let res = await axios.post(API_URL + "/repair-setting-facilities", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        refreshDataSource()
    }

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        refetch();
    };

    function showDelConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, property_id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, property_id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabTermRepair", v)
    }

    const columns:any = [
        {
            title: 'Property',
            dataIndex: 'property',
            width: 250,
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.property_name.localeCompare(b.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.property_name !== null ? record.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'TOPIC',
            dataIndex: 'topic',
            width: 300,
            key: 'topic',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.tac_th.localeCompare(b.tac_th),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.tac_th !== null ? record.tac_th : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: 'CREATED BY',
            // dataIndex: 'createBy',
            width: 150,
            key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                    {/* <div >{record.createBy !== null ? 'xxxx' : "-"} {record.createBy !== null ? 'yyy' : ""}</div> */}
                </>
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'createdAt',
            width: 150,
            key: 'createdAt',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.createdAt < b.createdAt) {return -1}
                if (a.createdAt > b.createdAt) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            width: 150,
            key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                    
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'Last Update',
            dataIndex: 'updatedAt',
            width: 150,
            key: 'updatedAt',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.updatedAt < b.updatedAt) {return -1}
                if (a.updatedAt > b.updatedAt) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'STATUS',
            // dataIndex: 'updateBy',
            width: 150,
            key: 'deleteStatus',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.deleteStatus < b?.deleteStatus) {return -1}
                if (a?.deleteStatus > b?.deleteStatus) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return <>
                    {
                         record.deleteStatus === "N" ? 
                            <Tag style={{borderRadius: "10px"}} color={'green'}>ACTIVE</Tag>
                         :
                            <Tag style={{borderRadius: "10px"}} color={'red'}>INACTIVE</Tag>
                    }
                </>
            },

        },
        {
            title: 'DOWNLOAD',
            dataIndex: 'dl',
            width: 110,
            key: 'dl',
            align: 'center',
            //sorter: (a:any, b:any) => a.createBy.first_name.toString().localeCompare(b.createBy.first_name.toString()),
            //sorter: (a:any, b:any) => a.seq.toString().localeCompare(b.seq.toString()),
            render: (createBy:any, record:any) => {
                return (
                    <>
                        {currentLocale == "en" ?
                            Tab == "Information" ?
                                <a href={record.tac_infomation_en ? record.tac_infomation_en[0].url : ''} download={record.tac_infomation_en ? record.tac_infomation_en.name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                            :
                                <a href={record.tac_content_en ? record.tac_content_en[0].url : ''} download={record.tac_content_en ? record.tac_content_en.name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                        :
                                Tab == "Information" ?
                                <a href={record.tac_infomation_th ? record.tac_infomation_th[0].url : ''} download={record.tac_infomation_th ? record.tac_infomation_th.name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                            :
                                <a href={record.tac_content_th ? record.tac_content_th[0].url : ''} download={record.tac_content_th ? record.tac_content_th[0].name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            width: 100,
            fixed: 'right',
            key: 'action',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {/* <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            onClick={() => edit("repair-tnc", record.property_id + "_" + record.id )}
                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                            style={{
                                 marginRight:'4px'
                            }}
                        /> */}
                      {
                            record.deleteStatus === "N" ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        // onClick={()=> editModalShow(record.id)}
                                        onClick={() => edit("repair-facility-tnc", record.property_id + "_" + record.id )}
                                        style={{
                                            marginRight:'2px'
                                        }}
                                    />
                                    <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record.id, record.property_id ) }} 
                                        danger
                                    ></Button>
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record.id, record.property_id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    function onsetProp(value: any){
        setsearchProp(value);
        if(value){
            onSearchtable(listProps, searchTopic, value);
        }else{
            onSearchtable(listProps, searchTopic, value);
        }
    }

    function onsetTopic(value: any){
        setsearchTopic(value);
        if(value){
            onSearchtable(listProps, value, searchProp);
        }else{
            onSearchtable(listProps, value, searchProp);
        }
    }

    function onSearchtable(data: any, topic: any, prop:any){
        let newData:any = data;
        if(topic || prop){
            newData = prop ? newData?.filter((e:any)=> {return ( e?.property_id === Number(prop))}): newData;
            newData = topic ? newData?.filter((e:any)=> {return ( e?.tac_th?.toLowerCase().includes(topic?.toLowerCase()) || e?.tac_en?.toLowerCase().includes(topic?.toLowerCase()))}): newData;

            setListdata((pre: any) => newData);
        }else{
            setListdata((pre: any) => newData);
        }
    }
    
    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
    
    return <>
        <List title="">
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout = "inline" style={{marginBottom:"1%"}}>
                        <Form.Item>
                            <Select 
                                placeholder="PROPERTY" 
                                allowClear 
                                style={{width: "220px"}}
                                {...selproperty}
                                onChange={(e:any)=> {
                                    if(e){
                                        onsetProp(e)
                                    }else{
                                        onsetProp(undefined)
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input 
                                placeholder="TOPIC"
                                allowClear
                                style={{borderRadius: 5}}
                                onChange={e => {
                                    if(e.target.value.length > 0){
                                        onsetTopic(e.target.value);
                                    }else{
                                        onsetTopic(undefined)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton 
                                id="create_btn" 
                                onClick={()=>create("repair-facility-tnc")}
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <div>
                {/* {
                    tableFilter?.length > 0 ?
                    <>
                        <Table 
                            // dataSource={DataTac ? DataTac.reverse() : dataBlank} 
                            dataSource={tableFilter ? tableFilter.reverse() : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{ size: "small", position: [ "bottomRight"] }} 
                        />
                    </>
                    :
                    <>
                        <Table 
                            dataSource={DataTac ? DataTac.reverse() : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{ size: "small", position: [ "bottomRight"] }} 
                        />
                    </>
                } */}

                {console.log(">>> Listdata",Listdata)}

                <Table 
                    dataSource={Listdata} 
                    columns={columns} 
                    // onChange={onChange} 
                    // pagination={{ size: "small", position: [ 'bottomRight' ] }}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        // showTotal: showTotal, 
                        pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                        showSizeChanger: true, // Optional - Show page size changer dropdown
                        // showQuickJumper: true, // Optional - Show quick jumper input
                        // pageSize: 10, // Optional - Default page size
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                        
                    }}
                    scroll={{ x: 1500 }}

                />
                
            </div>
            

        </List>

    
    </>
}