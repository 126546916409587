import { QuestionCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Form, Icons, Image, List, Modal, Select, Spin, Table, Tag, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom, useNavigation, useTranslate } from "@pankod/refine-core";
import axios from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import { petURL } from "../../constants";

// let dataTB: any = undefined;
let data_pet_type: any = undefined;
let optionpettype: any = undefined;
export const PetCommuList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { replace, list} = useNavigation();
    
    const [pageSize, setPageSize] = useState<any>(10);
    const [isLoading, setLoading] = useState<boolean>(true);
    const { confirm } = Modal;
    const [dataTB, setdataTB] = useState<any>([]);
    const [valuepetType, setpetType] = useState<any>();
    const [dataOriginal, setdataOriginal] = useState<any>([]);
    const [selProp, setselProp] = useState<any>(undefined);
    const [Unit, setUnit] = useState<any>(undefined);
    const [formSearch] = Form.useForm();
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const prop_id = localStorage.getItem("PROPERTY_ID");

    const { data: datapettype } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }
            },
        },
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 10000,
        sort: [{ order: "asc", field: "id" }],
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "in",
                value       : selProp?.id
            },
        ]
    });

    const { data, refetch } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetCommu`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad", dataLoad)
                // dataTB = dataLoad;
                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item?.property_obj?.id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e?.property_obj?.id == Number(prop_id))
                }
                setdataTB(dataLoad)
                setdataOriginal(dataLoad)
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            },
        },
    });

    async function updateStatus(id:any, val:any){
        console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.put(
            `${petURL}/api/lscpet/updatePetCommu?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        // refreshDataSource()
        refetch();
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : `${t("pet-commu-list.title-delete")}`, 
            icon    : <WarningOutlined />,
            content : `${t("pet-commu-list.detail-delete")}`,
            okText  : `${t("pet-commu-list.confirm")}`,
            cancelText : `${t("pet-commu-list.cancel")}`,
            onOk() {
                updateStatus(id, false);
                // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                // }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                // }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: `${t("pet-vacc-list.properties")}`,
            dataIndex: 'property',
            key: 'property',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.property_obj?.property_name < b?.property_obj?.property_name) {return -1}
                if (a?.property_obj?.property_name > b?.property_obj?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.property_obj ? record?.property_obj?.property_name : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-vacc-list.unit")}`,
            dataIndex: 'unit',
            key: 'unit',
            width: 120,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.unit?.unit_name < b?.unit?.unit_name) {return -1}
                if (a?.unit?.unit_name > b?.unit?.unit_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.unit ? record?.unit?.unit_name : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-commu-list.owner")}`,
            dataIndex: 'age',
            key: 'age',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.owner_of_pet?.first_name < b?.owner_of_pet?.first_name) {return -1}
                if (a?.owner_of_pet?.first_name > b?.owner_of_pet?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.owner_of_pet ? record?.owner_of_pet?.first_name + ' ' + record?.owner_of_pet?.last_name : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-commu-list.picture")}`,
            dataIndex: 'picture',
            key: 'picture',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            render: (value:any, record:any) => {
                let filteredData = data_pet_type?.filter((item:any) => item._id == record?.pet_regis_obj[0]?.pet_type);
                // console.log('filteredData', filteredData)
                return (
                    <span>
                        {/* <img src={record?.commu_picture ? record?.commu_picture.url : "https://lscmain.s3.ap-southeast-1.amazonaws.com/5203299_ee160df63b.jpg?updated_at=2023-11-01T18:22:21.402Z"} width="130" height="130" style={{ border: 'none' }} />  */}
                        <Image loading="lazy" src={record?.commu_picture ? record?.commu_picture.url : "https://lscmain.s3.ap-southeast-1.amazonaws.com/5203299_ee160df63b.jpg?updated_at=2023-11-01T18:22:21.402Z"} width={90} height={90} preview style={{borderRadius: "10px"}}/>
                    </span>
                )
            },
        },
        { 
            title: `${t("pet-commu-list.description")}`,
            dataIndex: 'picture',
            key: 'picture',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.commu_caption < b?.commu_caption) {return -1}
                if (a?.commu_caption > b?.commu_caption) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                let filteredData = data_pet_type?.filter((item:any) => item._id == record?.pet_regis_obj[0]?.pet_type);
                // console.log('filteredData', filteredData)
                return (
                    <span>
                        {
                            record?.commu_caption ? record?.commu_caption : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-commu-list.total_like")}`,
            dataIndex: 'total_like',
            key: 'total_like',
            width: 120,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.total_like < b?.total_like) {return -1}
                if (a?.total_like > b?.total_like) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                let filteredData = data_pet_type?.filter((item:any) => item._id == record?.pet_regis_obj[0]?.pet_type);
                // console.log('filteredData', filteredData)
                return (
                    <span>
                    {
                        record?.total_like ? record?.total_like : '-'
                    }
                    </span>
                )
            },
        },
        {
            title: `${t("pet-commu-list.status")}`,
            dataIndex: '',
            key: '',
            width: 120,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.is_active < b?.is_active) {return -1}
                if (a?.is_active > b?.is_active) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                console.log("re",record)
                return (
                    <span>{record?.is_active ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Active</Tag> : <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Inactive</Tag>}</span>
                )
            },
        },
        {
            title: `${t("pet-commu-list.create-by")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.create_by?.user_name.localeCompare(b?.create_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.create_by ? record?.create_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-commu-list.create-date")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => a?.create_date.localeCompare(b?.create_date),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.create_date ? dayjs(record?.create_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-commu-list.update-by")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-commu-list.last-update")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => a?.update_date.localeCompare(b?.update_date),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-commu-list.action")}`,
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                return (
                    <>
                    {
                        record.is_active ? 
                            <Button 
                                size="small" 
                                title="Delete" 
                                icon={<Icons.DeleteOutlined />} 
                                onClick={() => { showDelConfirm(record._id) }} 
                                danger
                            ></Button>
                        :
                            <Button 
                                size="small" 
                                title="Active" 
                                icon={<Icons.RetweetOutlined />} 
                                onClick={() => { showUnlockConfirm(record._id) }} 
                            ></Button>
                    }
                    </>
                );
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onsearchTB (data: any, mode: any){
        if(mode == "S"){
            // let newDT: any = data;    
            let newDT: any = dataOriginal;

            if(selProp?.id){
                newDT = newDT?.filter((item:any)=> item?.property_obj?.id == selProp?.id);
            }

            // if(valuepetType){
            //     newDT = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType))
            // }

            if(Unit){ 
                newDT = newDT?.filter((f: any)=> f?.unit?.id == Unit?.id)
            }

            setdataTB(newDT)

            // if(valuepetType){
            //     let dtFilter: any = newDT?.filter((f: any)=> f?.pet_regis_obj[0]?.pet_type == String(valuepetType));
            //     setdataTB(dtFilter)
            // }else{ //กรณีกดลบ select แล้วกดปุ่ม search แทนปุ่ม reset
            //     setdataTB(data)
            // }
        }else if(mode == "R"){
            let newDT: any = data;
            setdataTB(newDT);
            setpetType(undefined);
            setselProp(undefined);
            setUnit(undefined);
            formSearch?.resetFields();
        }
    }

    return (
        <>
        <List 
            title={t("pet-commu-list.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("pet-service.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-commu-list.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <div className="layout_panel">
                <Spin size="large" spinning={isLoading}>
                <Form 
                        form={formSearch}
                        layout="vertical"
                        style={{marginBottom: "1rem"}}
                    >
                        <div className="layout_custom_search">
                            <Form.Item
                                name="property"
                                label=""
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select 
                                    placeholder={t("pet-vacc-list.select-properties")}
                                    {...selProperty} allowClear 
                                    onChange={(value:any, record:any) => { 
                                        setselProp({id:value, label: record?.label})
                                    }}
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>

                            <Form.Item
                                label=" "
                                name="unit"
                                style={{marginBottom: 0, display: "inline-block"}}
                                className="visible-label"
                            >
                                <Select {...SelUnit}
                                    placeholder={t("pet-vacc-list.select-unit")} 
                                    size="middle" 
                                    onChange={(value:any, record:any) => { 
                                        setUnit({id:value, label: record?.label})
                                    }}
                                    onClear={()=> {
                                        setUnit(undefined);
                                        formSearch.resetFields(["unit"]);
                                    }}
                                    allowClear
                                    showSearch
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item>
                            
                            {/* <Form.Item 
                                label=""
                                name="pet-type"
                                className="visible-label"
                                style={{marginBottom: 0, display: "inline-block"}}
                            >
                                <Select
                                    options={optionpettype}
                                    // placeholder={t("select.sel")}
                                    placeholder={"Pet type"}
                                    showSearch
                                    allowClear
                                    onChange={(e: any)=> setpetType(e)}
                                    onClear={()=> setpetType(undefined)}
                                    style={{width: "200px", marginRight: "15px"}}
                                />
                            </Form.Item> */}

                            <Button 
                                icon={<Icons.SearchOutlined />} 
                                style={{borderRadius: "5px", marginRight: "15px"}} 
                                type="primary" 
                                onClick={()=> {
                                    onsearchTB(dataTB, "S")
                                }}
                            >
                                {t("buttons.search")}
                            </Button>

                            <Button 
                                icon={<Icons.ReloadOutlined />} 
                                style={{borderRadius: "5px"}} 
                                onClick={()=> {
                                    onsearchTB(dataOriginal, "R")
                                }}
                            >{t("buttons.reset")}</Button>
                        </div>
                    </Form>

                    <Table 
                        dataSource={dataTB ? dataTB : []} 
                        columns={columns}
                        pagination={{
                            size: "small",
                            position: ['bottomRight'],
                            pageSizeOptions: ['10', '30', '50', '100'], 
                            showSizeChanger: true, 
                            pageSize: pageSize,
                            onChange: handlePageSizeChange,
                        }}
                        scroll={{ x: 1300 }}
                    />
                </Spin>
            </div>
        </List>
        </>
    )
}