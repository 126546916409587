/* eslint-disable */
import { Breadcrumb, Button, Card, Col, DatePicker, Edit, Form, Input, InputNumber, List, Radio, RadioChangeEvent, Row, Space, Table, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { ICoupon, ICouponGroup, IEventlog } from "interfaces";
import { useEffect, useState } from "react";
// import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
// import { useState } from "react";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
// import {TinyMceEditor} from "../../components/ck";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

let recordData:any = undefined;
export const CouponGroupEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint          = "even-logs";
    const {data: user_data} = useGetIdentity<any>();

    const columns = [
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
    ];

    const optRadioCouponType = [
        { label: t("coupon.ra-fixed"), value: 1 },
        { label: t("coupon.ra-running"), value: 2 },
        { label: t("coupon.ra-third"), value: 3 },
    ];

    const [disabled, setdisabled] = useState<boolean>(true);
    const [ vCoupon, setCoupon ] = useState<any>([]);
    const [ vQuantity, setQuantity ] = useState<any>(0);
    const [ vCouponLeft, setCouponLeft ] = useState<any>(0);
    const [ vCouponUsed, setCouponUsed ] = useState<any>(0);
    const [ vStartDate, setStartDate ] = useState<any>(undefined);
    const [ vExpireDate, setExpireDate ] = useState<any>(undefined);
    const [ vCouponType, setCouponType ] = useState<any>();
    const [ vCouponDataSource, setCouponDataSource ] = useState<any>();
    const [vTotal, setTotal] = useState<any>(0);

    // ============================================================================================

    const { form, formProps, saveButtonProps } = useForm<ICouponGroup,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'coupon',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );

    const { queryResult: {refetch} } = useShow<ICouponGroup>({  
        metaData: {
            populate: [
                '*',
                'coupons',
                'createBy',
                'updateBy',
            ]
        },
        queryOptions:{onSuccess(data) {
            let dataLoad: any = data?.data;

            recordData = dataLoad;
            callFetchData(dataLoad);
            console.log(">>> dataLoad", dataLoad)
        },}
    });

    const { listProps: coupondata } = useSimpleList({
        resource: "coupons",
        metaData: {
            populate: ['coupon_group','createBy','updateBy','coupon_group.id']
        },
        initialSorter: [
          {
            field: "id",
            order: "desc",
          },
        ],
        pagination:{ pageSize: 100 },
        permanentFilter: [
            {
                field: "coupon_group][id]",
                operator: "in",
                value: recordData?.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            setCouponDataSource(data.data)
            callCountCouponLeft(recordData.id)
          },
        },
    });

    function callFetchData(record: any){
        vCoupon.pop();
        let newData: any = [];
        if(record?.coupons && record?.coupons.length > 0){
            newData.push(
                record?.coupons.map((e:any)=>{
                    return e.id
                })
            )
        }
        
        setTotal(record?.quantity)
        setCoupon((pre: any) => newData);
        setdisabled(false);
        
        // setCouponType(record?.couponType === "FIXED" ? 1 : record?.couponType === "RUNNING" ? 2 : 3)

        setCouponDataSource(record?.coupons ? record?.coupons : [])

        if(record?.couponType == "FIXED"){
            setCouponType(1);
        }else if(record?.couponType == "RUNNING"){
            setCouponType(2);
        }else if(record?.couponType == "THIRDPARTY"){
            setCouponType(3);
        }

        form?.setFieldsValue({
            coupons: 
                record?.coupons && record?.coupons?.length > 0 ? record?.coupons.map((e:any)=>{
                    return {value: e?.id, label: e?.coupon_name}
                }) 
                : form.resetFields(["coupons"])
        })
    }

    const handleExpireDate = (date: Dayjs | null, dateString: string, id: number) => {
        setExpireDate(dateString);
    };
    const handleStartDate = (date: Dayjs | null, dateString: string, id: number) => {
        setStartDate(dateString);
    };

    function onChangeCoupons(va:any){
        vCoupon.pop();
        vCoupon.push(va);
        setCoupon(vCoupon);
        console.log('vCoupon',vCoupon);
    };

    const onChangeCouponType = ({ target: { value } }: RadioChangeEvent) => {
        console.log('radio noti checked', value);
        setCouponType(value);
    };

    async function callCountCouponLeft(couponGroupID:any){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let resultK = await axios.get(API_URL + "/like-systems/countCouponLeft?CPGID=" + couponGroupID , { headers: { Authorization: AuthStr } });
        
        setCouponLeft(resultK?.data);
        setCouponUsed(recordData?.quantity - resultK?.data)
    }

    console.log(">>> vCouponType",vCouponType)

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("coupon-group.t-group-edit")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.updateBy = user_data.user_profile.id
                    values.coupons = vCoupon[0]

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={8}>
                    <Col span={18} className="gutter-row">
                        <Form.Item  
                            label={t("coupon-group.f-group-name")}
                            name="group_name"
                            rules={[
                                {
                                    required: true, message: 'Please enter coupon group name.'
                                },
                            ]}
                        >
                            <Input disabled={false}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item  
                            label="Quantity"
                            name="quantity"
                            className="input_disable_onshow"
                        >
                            <InputNumber 
                                defaultValue={vTotal}
                                placeholder="จำนวนสิทธิ"
                                min={0}
                                style={{width: "100%"}}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label="Short Detail"
                            name="shortDetail"
                        >
                            <Input disabled={false}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Start Date"
                            name="start_date"
                            className="input_disable_onshow"
                            getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setStartDate(dateString)}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Expire Date"
                            name="expire_date"
                            className="input_disable_onshow"
                            getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setExpireDate(dateString)}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item label="Coupon Type">
                            {/* <Radio.Group options={optRadioCouponType} onChange={onChangeCouponType} value={vCouponType} />   */}
                            <Radio.Group disabled={false} value={vCouponType} onChange={(e:any) => setCouponType(e.target.value)}>
                                <Space direction="horizontal">
                                    <Radio value={1}>{t("coupon.ra-fixed")}</Radio>
                                    <Radio value={2}>{t("coupon.ra-running")}</Radio>
                                    <Radio value={3}>{t("coupon.ra-third")}</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                    <Table
                            size="middle"
                            dataSource={vCouponDataSource}
                            columns={columns}
                            bordered
                            pagination={{hideOnSinglePage: false}}
                        />
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};