/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { RepairFacilityCategorylList } from "./category";
import { RepairFacilityZoneList } from "./zone";
// import { RepairCategoryAndDetailList } from "./category";
// import { RepairZoneList } from "./repair_zone";
// import { RepairAssignTechnicianList } from "./assign_technician";
// import { RepairEmailGroupList } from "./email_group";
// import { RepairTermConditionList } from "./term_and_condition";
// import { HcuChecklistList } from "./checklist";
// import { HcuStructureList } from "./structure";
// import { HcuZoneList } from "./zone";
// import { HcuNotiMsgList } from "./notification";
// import { HcuTermConditionList } from "./term_and_condition";
// import { HcuTemplateList } from "./template_master";
// import { HcuDaysList } from "./setup_days";
// import { HcuAssignList } from "./assign_inspec";


export const RepairMasterFacility: React.FC<IResourceComponentsProps> = () => {
    const tabActive = localStorage.getItem("keyTabRepairMaster");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    function onStatusChange(v:any){
        // if(v == "Y"){
        //     // setdeleteStatus("Y")
        //     // setappointmentType(null) 
        // }else{
        //     // setdeleteStatus("N")
        //     // setappointmentType(v)
        // }
        // searchFormProps.form?.submit();
        localStorage.setItem("keyTabRepairMaster", v)
    }

    return <>
        <List 
            // title={t("hcumaster.title")}
            title="Master แจ้งซ่อมส่วนกลาง"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major แจ้งซ่อมส่วนกลาง</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">Master แจ้งซ่อมส่วนกลาง</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></> 
            }}
        >
            <Tabs
                defaultActiveKey={tabActive? tabActive + "" : "1"}
                onChange={onStatusChange}
            >
                <Tabs.TabPane tab="โซนซ่อม" key="1">
                    <RepairFacilityZoneList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="หมวดหมู่การซ่อม" key="2">
                    <RepairFacilityCategorylList />
                </Tabs.TabPane>
                {/* <Tabs.TabPane tab="Email Group" key="3">
                </Tabs.TabPane> */}
            </Tabs>
        </List>

        
    </>
}