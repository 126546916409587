import { IResourceComponentsProps, useApiUrl, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { TOKEN_KEY } from "../../constants";
import { AntdList, Breadcrumb, Button, Card, Col, Form, Icons, Input, List, Radio, Row, Select } from "@pankod/refine-antd";
import { useState } from "react";
import moment from "moment";
import { Header } from "components/layout/header";

export const EstampList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const TOKEN = localStorage.getItem(TOKEN_KEY);
    const {data: Identity_data} = useGetIdentity<any>();

    const [selectmenu, setselectmenu] = useState<any>("stamp-in");

    const mockdata: any = [
        {
            "id": 1,
            "time-in": new Date().toISOString(),
            "time-out": new Date().toISOString(),
            "num-of-house": "11/11",
            "num-of-car": "กกต01",
            "code": "KKUYU001"
        },
        {
            "id": 2,
            "time_in": new Date().toISOString(),
            "time_out": new Date().toISOString(),
            "num_of_house": "11/12",
            "num_of_car": "กกต02",
            "code": "KKUYU002"
        },
        {
            "id": 3,
            "time_in": new Date().toISOString(),
            "time_out": new Date().toISOString(),
            "num_of_house": "11/13",
            "num_of_car": "กกต03",
            "code": "KKUYU003"
        },
        {
            "id": 4,
            "time_in": new Date().toISOString(),
            "time_out": new Date().toISOString(),
            "num_of_house": "11/14",
            "num_of_car": "กกต04",
            "code": "KKUYU004"
        },
        {
            "id": 5,
            "time_in": new Date().toISOString(),
            "time_out": new Date().toISOString(),
            "num_of_house": "11/15",
            "num_of_car": "กกต05",
            "code": "KKUYU005"
        }
    ]

    const renderItem = (item: any) => {
        const {
          id,
          code,
          time_in,
          time_out,
          num_of_house,
          num_of_car
        }: any = item;
    
        return (
          <AntdList.Item key={id}>
            <AntdList.Item onClick={(e)=> console.log(e)}>
              <Card
                id={id}
                style={{boxShadow: "rgba(99, 99, 99, 0.1) 0px 2px 5px 0px"}}
                bordered={true}
                hoverable={true}
                className="card_custom_andlist"
              >
                <div style={{width: "98%", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                    <span className="span-e-stamp-res">รหัส : {code}</span>
                </div>

                <div className="span-e-stamp-res">บ้านเลขที่ {num_of_house}</div>
                <div className="span-e-stamp-res">ทะเบียนรถ {num_of_car}</div>
                <div className="span-e-stamp-res">หมายเหตุ -</div>

                <Row gutter={12}>
                    <Col span={12} style={{borderRight: "1px solid #dedede"}}>
                        <div className="span-e-stamp-res">เวลาเข้า {moment(time_in).format('D/MM/yyyy HH:mm')}</div>
                    </Col>
                    <Col span={12}>
                        <div className="span-e-stamp-res">เวลาออก {moment(time_out).format('D/MM/yyyy HH:mm')}</div>
                    </Col>
                </Row>
                {/* <div>
                    <div className="span-e-stamp-res">เวลาเข้า {moment(time_in).format('D/MM/yyyy HH:mm')}</div>
                    <div className="span-e-stamp-res">เวลาออก {moment(time_out).format('D/MM/yyyy HH:mm')}</div>
                </div> */}
              </Card>
            </AntdList.Item>
          </AntdList.Item>
        );
    };

    return (
    <>
        <Header />
        <div className="e_stamp_layout_res">
            <List 
                title={t("e-stamp.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("e-stamp.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row gutter={12} style={{marginBottom: "3rem"}}>
                    <Col span={24}>
                        <Radio.Group defaultValue={"stamp-in"} buttonStyle="solid" size="large" onChange={(e)=> setselectmenu(e?.target?.value)} style={{width: "100%"}}>
                            <Radio.Button value="stamp-in" style={{width: "24%"}}><div className="span-e-stamp-res" style={{textAlign: "center"}}>ข้อมูลการเข้า</div></Radio.Button>
                            <Radio.Button value="stamp-out" style={{width: "25%"}}><div className="span-e-stamp-res" style={{textAlign: "center"}}>ข้อมูลการออก</div></Radio.Button>
                            <Radio.Button value="scan-qr" style={{width: "17%"}}><div className="span-e-stamp-res" style={{textAlign: "center"}}>แสกน QR</div></Radio.Button>
                            <Radio.Button value="stamp-list" style={{width: "34%"}}><div className="span-e-stamp-res" style={{textAlign: "center"}}>รายการเข้า-ออกทั้งหมด</div></Radio.Button>
                        </Radio.Group>
                    </Col>
                </Row>

                {/* <Radio.Group defaultValue={"stamp-in"} buttonStyle="solid" size="large" onChange={(e)=> setselectmenu(e?.target?.value)}>
                    <Radio.Button value="stamp-in"><span className="span-e-stamp-res">ข้อมูลการเข้า</span></Radio.Button>
                    <Radio.Button value="stamp-out"><span className="span-e-stamp-res">ข้อมูลการออก</span></Radio.Button>
                    <Radio.Button value="scan-qr"><span className="span-e-stamp-res">แสกน QR</span></Radio.Button>
                    <Radio.Button value="stamp-list"><span className="span-e-stamp-res">รายการเข้า-ออกทั้งหมด</span></Radio.Button>
                </Radio.Group> */}

                {selectmenu == "stamp-in" &&
                    <Row gutter={12}>
                        <Col span={6}><div className="span-e-stamp-res" style={{marginTop: "8px"}}>บ้านเลขที่ <span style={{color: "#ff4d4f"}}>*</span></div></Col>
                        <Col span={18}>
                            <Form.Item
                                className="disable_req"
                            >
                                <Select/>
                            </Form.Item>
                        </Col>
                        <Col span={6}><div className="span-e-stamp-res" style={{marginTop: "8px"}}>ทะเบียนรถ <span style={{color: "#ff4d4f"}}>*</span></div></Col>
                        <Col span={18}>
                            <Form.Item
                                className="disable_req"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={6}><div className="span-e-stamp-res" style={{marginTop: "8px"}}>หมายเหตุ</div></Col>
                        <Col span={18}>
                            <Form.Item
                                className="disable_req"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24} style={{display: "flex", justifyContent: "end"}}>
                            <Button type="primary" style={{borderRadius: "5px"}} icon={<Icons.PrinterOutlined />}><span className="span-e-stamp-res">พิมพ์</span></Button>
                        </Col>
                    </Row>
                }

                {selectmenu == "stamp-out" &&
                    <Row gutter={12}>
                        <Col span={6}><div className="span-e-stamp-res" style={{marginTop: "8px"}}>รหัส QR <span style={{color: "#ff4d4f"}}>*</span></div></Col>
                        <Col span={14}>
                            <Form.Item
                                className="disable_req"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Button type="ghost" style={{borderRadius: "5px", width: "100%"}} icon={<Icons.SearchOutlined />} />
                        </Col>
                        <Col span={24}>
                            <div style={{height: "auto", border: "1px solid #dedede", padding: "0.5rem 1rem 1rem 1rem"}}>
                                <Row gutter={12}>
                                    <Col span={24}><span className="span-e-stamp-res">รหัส</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">บ้านเลขที่</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">ทะเบียนรถ</span></Col>
                                    <Col span={24}><span className="span-e-stamp-res">วัน-เวลาขาเข้า</span></Col>
                                    <Col span={24}><span className="span-e-stamp-res">วัน-เวลาขาออก</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">ระยะเวลา</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">ค่าบริการ</span></Col>
                                    <Col span={24} style={{display: "flex", justifyContent: "center", alignItems: "center", height: "200px"}}>
                                        <div style={{width: "150px", height: "150px", border: "1px solid #dedede"}}></div>
                                    </Col>
                                    <Col span={24} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Button type="primary" style={{borderRadius: "5px", width: "150px"}}><span className="span-e-stamp-res">ชำระค่าบริการแล้ว</span></Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        {/* <Col span={24} style={{display: "flex", justifyContent: "end"}}>
                            <Button type="primary" size="large" style={{borderRadius: "5px"}} icon={<Icons.PrinterOutlined />}><span className="span-e-stamp-res">พิมพ์</span></Button>
                        </Col> */}
                    </Row>
                }

                {selectmenu == "scan-qr" && 
                <>
                    <Row gutter={12}>
                        <Col span={24}>
                            <div style={{height: "auto", border: "1px solid #dedede", padding: "0.5rem 1rem 1rem 1rem"}}>
                                <Row gutter={12}>
                                    <Col span={24}><span className="span-e-stamp-res">รหัส</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">บ้านเลขที่</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">ทะเบียนรถ</span></Col>
                                    <Col span={24}><span className="span-e-stamp-res">วัน-เวลาขาเข้า</span></Col>
                                    <Col span={24}><span className="span-e-stamp-res">วัน-เวลาขาออก</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">ระยะเวลา</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">ค่าบริการ</span></Col>
                                    <Col span={24} style={{display: "flex", justifyContent: "center", alignItems: "center", height: "200px"}}>
                                        <div style={{width: "150px", height: "150px", border: "1px solid #dedede"}}></div>
                                    </Col>
                                    <Col span={6}><div className="span-e-stamp-res" style={{marginTop: "8px"}}>หมายเหตุ</div></Col>
                                    <Col span={18}><Input /></Col>
                                    <Col span={24} style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem"}}>
                                        <Button type="primary" style={{borderRadius: "5px", width: "150px"}}><span className="span-e-stamp-res">ชำระค่าบริการแล้ว</span></Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={12} style={{marginTop: "1rem"}}>
                        <Col span={24}>
                            <div style={{height: "auto", border: "1px solid #dedede", padding: "0.5rem 1rem 1rem 1rem"}}>
                                <Row gutter={12}>
                                    <Col span={24}><span className="span-e-stamp-res">รหัส</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">บ้านเลขที่</span></Col>
                                    <Col span={12}><span className="span-e-stamp-res">ทะเบียนรถ</span></Col>
                                    <Col span={24}><span className="span-e-stamp-res">วันที่นัดหมาย</span></Col>
                                    <Col span={24} style={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1.5rem"}}>
                                        <Button type="primary" style={{borderRadius: "5px", width: "150px"}}><span className="span-e-stamp-res">ยืนยัน</span></Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </>
                }

                {selectmenu == "stamp-list" && 
                    <>
                        <Row gutter={6}>
                            {/* 5 19 */}
                            <Col xxl={2} xl={2} lg={3} md={2} sm={3} xs={3}><div className="span-e-stamp-res" style={{marginTop: "8px"}}>วันที่</div></Col>
                            <Col xxl={7} xl={7} lg={6} md={7} sm={6} xs={8}>
                                <Form.Item
                                    className="disable_req"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xxl={0} xl={0} lg={0} md={0} sm={0} xs={1}/>
                            <Col xxl={3} xl={3} lg={4} md={3} sm={4} xs={4}><div className="span-e-stamp-res" style={{marginTop: "8px"}}>บ้านเลขที่</div></Col>
                            <Col xxl={7} xl={7} lg={6} md={7} sm={6} xs={8}>
                                <Form.Item
                                    className="disable_req"
                                >
                                    <Input/>
                                </Form.Item>
                            </Col>
                            <Col xxl={5} xl={5} lg={5} md={5} sm={5} xs={24}>
                                <Button type="ghost" style={{borderRadius: "5px", width: "100%"}} icon={<Icons.SearchOutlined />}><span className="span-e-stamp-res">ค้นหาข้อมูล</span></Button>
                            </Col>
                        </Row>

                        <AntdList
                            rowKey={"id"}
                            grid={{ gutter: 12, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                            dataSource={mockdata}
                            renderItem={renderItem}
                            style={{ position: "relative", marginTop: "2rem" }}
                            pagination={{pageSize: 12, size: "small"}}
                        />
                    </>
                }
            </List>
        </div>
    </>
    )
}