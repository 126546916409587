/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Form, Input, List, Row, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useNavigation, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IParcelShippingType } from "interfaces";
import { useEffect, useState } from "react";

export const ParcelShippingEdit: React.FC<IResourceComponentsProps> = () => {
    const t                                 = useTranslate();
    // const { mutate: CreateLog }             = useCreate<IEventlog>();
    const { mutate: UpdateLocale }          = useUpdate<IParcelShippingType>();
    // const log_endpoint                      = "even-logs";
    // const { replace }                       = useNavigation();
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form,formProps, saveButtonProps }    = useForm<IParcelShippingType,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'policies',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
            },
        }
    );

    const [ vTitleEn, setTitleEn ] = useState('');
    const [ vLocalizeID, setLocalizeID ] = useState('');
    const [ count, setCount] = useState(0);

    const { queryResult } = useShow<IParcelShippingType>({  
            metaData: {
            // populate: '*'
            // populate: ['*','localizations','localizations.pic','properties','pic','user_sub_types','notice_channel_types','notice_customer_type','createBy','blog_type']
            populate: ['*','localizations','localizations.title','createBy','updateBy']
        }, 
    });
    const { data } = queryResult;
    let recordData:any = []
    recordData = data?.data? data?.data : [];
    // console.log('recordData', recordData)

    useEffect(() => {
        if (count < 3) { 
            const interval = setInterval(() => { 
                setCount(prev => prev + 1)
                if(recordData.localizations !== undefined){
                    console.log('record_data', recordData )

                    // setTitleEn(recordData.localizations.length > 0 ? recordData.localizations[0].name : 'xxxx');
                    setTitleEn(recordData.localizations[0].title);
                    setLocalizeID(recordData.localizations.length > 0 ? recordData.localizations[0].id : '');
                    // console.log('recordData.localizations[0].title', recordData.localizations[0].title)   
                    form?.setFieldsValue({
                        title_en: recordData.localizations ? recordData.localizations[0].title : null,
                    })
                }
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [count])

    function updateLocaleEn(){
        UpdateLocale({
            resource : 'parcel-shipping-types',
            id: vLocalizeID,
            values:{
                title : vTitleEn,
            }
            ,successNotification:false
        })
        // replace("");
    }

    function updateOnfinish(values:any){
        return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(values))
        );
    };
    // console.log('vTitleEn', vTitleEn)
    // console.log('vLocalizeID', vLocalizeID)
    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("parcel-box.title-edit")}
                pageHeaderProps={{ 
                    breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("parcel-box.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    // console.log(user_data.user_profile.id)
                    values.updateBy = user_data.user_profile.id
                    values.locale = "th"

                    updateLocaleEn();
                    updateOnfinish(values);

                    return ( 
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>

                        { count < 2 ?
                            <>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            // label={t("policy.policy_code")}
                                            label="Parcel Shipping Name (TH)"
                                            name="title"
                                            rules={[{required: true, message: 'Please enter shipping type name (TH).'},]}
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  
                                            // label={t("policy.policy_name")} 
                                            label="Parcel Shipping Name (EN)"
                                            name="title_en"
                                            rules={[{required: true, message: 'Please enter parcel type name (EN).'},]}
                                        >
                                            <Input 
                                                value={vTitleEn}
                                                defaultValue={vTitleEn}
                                                // defaultValue={recordData.localizations ? recordData.localizations[0].title : vTitleEn}
                                                onChange={e => setTitleEn(e.target.value)}
                                                disabled
                                            /> 
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        :
                            <>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item
                                            // label={t("policy.policy_code")}
                                            label="Parcel Shipping Name (TH)"
                                            name="title"
                                            rules={[{required: true, message: 'Please enter shipping type name (TH).'},]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  
                                            // label={t("policy.policy_name")} 
                                            label="Parcel Shipping Name (EN)"
                                            name="title_en"
                                            rules={[{required: true, message: 'Please enter parcel type name (EN).'},]}
                                        >
                                            <Input 
                                                value={vTitleEn}
                                                defaultValue={vTitleEn}
                                                // defaultValue={recordData.localizations ? recordData.localizations[0].title : vTitleEn}
                                                onChange={e => setTitleEn(e.target.value)}
                                            /> 
                                        </Form.Item>
                                    </Col>
                                </Row>
                            
                            </>
                        }
                                        
                            {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        // label={t("policy.policy_code")}
                                        label="Parcel Shipping Name (TH)"
                                        name="title"
                                        rules={[{required: true, message: 'Please enter shipping type name (TH).'},]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Form.Item  
                                        // label={t("policy.policy_name")} 
                                        label="Parcel Shipping Name (EN)"
                                        // name="title_en"
                                        // rules={[{required: true, message: 'Please enter parcel type name (EN).'},]}
                                    >
                                        <Input 
                                            value={vTitleEn}
                                            defaultValue={vTitleEn}
                                            // defaultValue={recordData.localizations ? recordData.localizations[0].title : vTitleEn}
                                            onChange={e => setTitleEn(e.target.value)}
                                        /> 
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </Card>
                    </Col>

                    {/* <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                            <Divider></Divider>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={16}>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>

                                <Col className="gutter-row" span={8}>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
            </Form>
        </Edit>   
    );
};