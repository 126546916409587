/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, message, RcFile, Row, Select, Upload, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { getValueProps } from "@pankod/refine-strapi-v4";
import { useState } from "react";
import { TOKEN_KEY, consoleLog } from "../../constants";
if(!consoleLog) {
    console.log = function() {} 
  }
export const DocumentCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Document");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [FileList, setFileList] = useState<any>([]);
    const [vIsFinance, setIsFinance] = useState<any>();

    const { form, formProps: createProps, saveButtonProps } = useForm<any>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            // CreateLog({
            //     resource    : log_endpoint,
            //     values      : {
            //         // user_profile            : user.id,
            //         user_profile            : user_data.user_profile.id,
            //         log_time_stamp          : Date.now(), 
            //         log_event               : "Create",
            //         menu_event              : 'facility-types',
            //         SysPkID                 : recordData.data.id + ""
            //     },
            //     successNotification : false
            // });
        },
    });

    const beforeUpload = (file: RcFile) => {
        // const isJpgOrPng = file.type === 'application/pdf' 
        //     || file.type === 'text/csv' 
        //     || file.type === 'application/vnd.ms-excel' 
        //     || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
        //     || file.type === 'application/msword' 
        //     || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        const isJpgOrPng = file.type === 'application/pdf';

        if (!isJpgOrPng) {
        //   message.error('You can only upload PDF/CSV/XLS/XLSX/DOC/DOCX file.');
          message.error('You can only upload PDF file.');
        }
        const isLt2M = file.size / 1024 / 1024 < 25;
        if (!isLt2M) {
          message.error('File must smaller than 25MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };
    
    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });

    const { selectProps: selDocType } = useSelect<any>({
        resource    : "document-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });
    
    const { selectProps: selDocSubType } = useSelect<any>({
        resource    : "document-sub-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    function fileChange(e:any){
        let res = e.file;
        if(res.status == "removed"){
            const NewFileList = FileList.filter((e:any)=> e.name !== res.name)
            setFileList((pre:any)=> ([...NewFileList]))
        }else if(res.status == "done"){
            if(e.file.response){
                let res = e.file.response;
                const ListFile = FileList.filter((e:any)=> e.id == res[0].id)
                if(ListFile.length == 0){
                    setFileList((pre:any)=> ([...pre , res[0]]));
                }else{
                    const NewFileList = FileList.filter((e:any)=> e.id !== res[0].id)
                    setFileList((pre:any)=> ([...NewFileList]))
                }
            }
        }
    }


    return<>
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("document.title-create")}
                pageHeaderProps={{ 
                    breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("document.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", width: '50%'}}>
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        console.log(values)
                        values.property = values.property.id;
                        values.document_type = values.document_type.id;
                        // values.document_sub_type = values.document_sub_type.id;
                        values.document_sub_type = values?.document_type == 3 ? null : values.document_sub_type ;
                        values.doc_name = values.doc_name;
                        values.noti = true;
                        values.createBy = Identity_data.user_profile.id;
                        values.deleteStatus = "N";
                        values.locale = "th"
                        values.file = FileList;

                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={16}>
                        <Col span={8} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty}/>
                            </Form.Item>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <Form.Item
                                name={["document_type", "id"]}
                                label="Document Type"
                                rules={[
                                    {
                                        required: true, message: "Please select document type"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selDocType}
                                    onSelect={(value: any) => {
                                        value == 3 ? setIsFinance(true) : setIsFinance(false)
                                    }}
                                />
                            </Form.Item>
                        </Col>

                        {
                            vIsFinance ?
                            <></>
                            :
                            <>
                                <Col span={8} className="gutter-row">
                                    <Form.Item
                                        name={["document_sub_type", "id"]}
                                        label="Document Sub Type"
                                        rules={[
                                            {
                                                required: true, message: "Please select document sub type"
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")} {...selDocSubType} />
                                    </Form.Item>
                                </Col>
                            </>
                        }

                        <Col span={16} className="gutter-row">
                            <Form.Item
                                name="doc_name"
                                label="Document Name"
                                rules={[
                                    {
                                        required: true, message: "Please input dcoument name"
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={8} className="gutter-row">
                            <Form.Item
                                name="file"
                                label="Document files (Only PDF Files)"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: "Please select file"
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    style={{borderRadius: 10}}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Upload.Dragger>
                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Create>   
    </>
};