/* eslint-disable */
import { Breadcrumb, Button, Col, Divider, Edit, Form, Icons, Image, Input, RcFile, Row, Select, TextField, UploadFile } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; // Import the timezone plugin for dayjs
import utc from 'dayjs/plugin/utc'; // Import the UTC plugin for dayjs
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../constants";

import { DatePicker, InputNumber, message, notification, Spin, Table, Tag, Upload } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import moment from "moment";
import { petURL } from "../../constants";

import customParseFormat from 'dayjs/plugin/customParseFormat'; // 
import duration from 'dayjs/plugin/duration'; // 

dayjs.extend(customParseFormat);
dayjs.extend(duration);
// dayjs.duration({ months: 12 })



const format = 'YYYY-MM-DD HH:mm';
const AuthStr = 'Bearer '.concat(TOKEN_KEY);

dayjs.extend(utc)
dayjs.extend(timezone)

let rcdata: any = undefined;
let data_pet_type: any = undefined;
let data_pet_species: any = undefined;
let data_pet_status: any = undefined;
let data_trail: any = undefined;
let is_lifetime: any = undefined;
// let option_pet_species: any = undefined;
// let option_pet_type: any = undefined;
let option_pet_size: any = undefined;
const option_reject_reason:any = [
    {
        label: "ห้ามเลี้ยงตามระเบียบ",
        value: "1",
    },
    {
        label: "ห้ามเลี้ยงตามกฏหมาย",
        value: "2",
    },
    {
        label: "น้ำหนักเกิน",
        value: "3",
    },
    {
        label: "สายพันธุ์ควบคุมพิเศษ",
        value: "4",
    },
    {
        label: "เอกสารไม่ครบ",
        value: "5",
    },
    {
        label: "เอกสารไม่ถูกต้อง",
        value: "6",
    },
    {
        label: "อื่น ๆ",
        value: "7",
    },
];

// เพศสัตว์เลี้ยง '1' == ผู้, '2' == เมีย, '3' == ไม่ระบุ 
const option_pet_gender:any = [
    {
        label: "male",
        value: "1",
    },
    {
        label: "female",
        value: "2",
    },
    {
        label: "N/A",
        value: "3",
    }
];

export const PetDataEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const {data: Identity_data} = useGetIdentity<any>();
    // const data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    // const data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    // console.log('Identity_data', Identity_data)
    const [form] = Form.useForm();
    const [buttonDisabled, setButtonDisabled] = useState(false);
   
    const [optionPetSpecies, setOptionPetSpecies] = useState<any>();
    const [optionPetType, setOptionPetType] = useState<any>();
    const [optionPetSize, setOptionPetSize] = useState<any>();

    const { replace, list} = useNavigation();
    const [isLoading, setLoading] = useState<boolean>(true);

    const [petPic, setPetPic] = useState<any>();
    const [petPicFile, setPetPicFile] = useState<any>();

    const [petMedCer, setPetMedCer] = useState<any>();
    const [petMedCerFile, setPetMedCerFile] = useState<any>();

    const [paidSlip, setPaidSlip] = useState<any>(undefined);
    const [petPaidSlipFile, setPaidSlipFile] = useState<any>();

    const [petQuotation, setQuotation] = useState<any>();
    const [petQuotationFile, setQuotationFile] = useState<any>();

    const [stat, setStat] = useState<any>(undefined);
    const [subStat, setSubStat] = useState<any>(undefined);
    const [petType, setPetType] = useState<any>(undefined);
    const [petSpecies, setPetSpecies] = useState<any>(undefined);
    const [petGender, setPetGender] = useState<any>(undefined);
    const [petSize, setPetSize] = useState<any>(undefined);

    const [isCharge, setIsCharge] = useState<any>(undefined);
    const [isBilling, setIsBilling] = useState<any>(undefined);

    const [reqQuotation, setReqQuotation] = useState<any>(false);
    // const [expireDate, setExpireDate] = useState<any>(undefined);

    const arr = window.location.href.split("/");
    const pet_id = arr[arr.length-1]

    const { data: pet_type } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad pet_type", dataLoad)
                data_pet_type = dataLoad;
                // setLoading(false);

                setOptionPetType(dataLoad.map((status:any) => ({
                    value: status._id,
                    label: status.pet_type_name,
                })));
            },
        },
    }); 
 
    const { data: pet_size } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetSize`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad pet_type", dataLoad)

                option_pet_size = dataLoad.map((status:any) => ({
                    value: status._id,
                    label: status.pet_size_name_en,
                }))
            },
        },
    });


    const { data: pet_species,  refetch: refetch2   } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetSpecies`,
        method: "get",
        queryOptions:{
            async onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad pet_species", dataLoad)
                data_pet_species = dataLoad;
                // setLoading(false);

                setOptionPetSpecies(dataLoad.map((status:any) => ({
                    value: status._id,
                    label: status.pet_species_name,
                })));

            },
        },
    });


    const { data: pet_stat } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetApproveStat`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                
                dataLoad = dataLoad.filter((e:any)=> e._id !== "6557382ca209821eb93c7492" ) // ลบบัญชีโดยผู้ใช้
                
                console.log('dataLoad stat >>>>>> ', dataLoad)
                dataLoad.reverse();

                data_pet_status = dataLoad.map((status:any) => ({
                    value: status._id,
                    label: status.status_name,
                }));
            },
        },
    });


    useEffect(() => {
        if(petType !== undefined){
            get_pet_species_filter();
        }
    }, [petType]);

    // useEffect(() => {
    //     if(petSpecies !== undefined){
    //         get_pet_size_filter();
    //     }
    // }, [petSpecies]);

    const get_pet_species_filter = async () =>{
        await axios.get(`${petURL}/api/lscpet/findAllPetSpeciesWithId?id=${petType?.value}`, { headers: {  } })
        .then((res) => {
            setOptionPetSpecies(res?.data.map((status:any) => ({
                value: status._id,
                label: status.pet_species_name,
            })))
        })
    }

    // const get_pet_size_filter = async () =>{
    //     await axios.get(`${petURL}/api/lscpet/findAllPetSizeDetailWithId?id=${petSpecies?.value}`, { headers: {  } })
    //     .then((res) => {
    //         setOptionPetSize(res?.data.map((status:any) => ({
    //             value: status._id,
    //             label: status.size_name_en,
    //         })))
    //     })
    // }

    const { data } = useCustom<any>({
        url: `${petURL}/api/lscpet/findOnePetRegis`,
        method: "get",
        config: {
            query: {
                id: pet_id
            }
        },
        queryOptions:{
            async onSuccess(data) {
                rcdata = data?.data[0];
                console.log('rcdata', rcdata)
                let isChargeProp
                is_lifetime = rcdata?.is_lifetime

                // setIsBilling(rcdata?.data?.is_billing) // true == billing, false == bank transfer

                // console.log('is_lifetime', is_lifetime)
                await axios.get(`${petURL}/api/lscpet/mobileCheckRequireMedicalCert?propertyID=${rcdata?.property?.id}`, { headers: {  } })
                .then((res) => {
                    console.log('res?.data?.is_charge', res?.data)
                    isChargeProp = res?.data?.is_charge
                    setIsCharge(res?.data?.is_charge)
                    setIsBilling(res?.data?.is_billing) // true == billing, false == bank transfer

                    if(res?.data?.is_charge == false){
                        data_pet_status = data_pet_status.filter((item:any) => {
                            return (
                              item.value === "6539f5fe59e64523844d0797" ||
                              item.value === "6539f60859e64523844d0799" ||
                              item.value === "6539f61a59e64523844d079b"
                            );
                        });
                    }
                })

                setStat({
                    value: rcdata?.pet_approve_stat_obj[0]?._id,
                    label: rcdata?.pet_approve_stat_obj[0]?.status_name
                })

                setSubStat({
                    value: rcdata?.pet_reject_reason,
                    label: ""
                })

                // GET DATA TRAIL
                await axios.get(`${petURL}/api/lscpet/findAllPetRegisTrailWithId?id=${pet_id}`, { headers: {  } })
                .then((res) => {
                    // console.log('data trail', res?.data)
                    let dataLoad: any = res?.data;
                    dataLoad = dataLoad.filter((item:any) => item.status_name !== "-");
                    data_trail = dataLoad.reverse();
                })

                if(rcdata?.paid_slip){
                    setPaidSlip({
                        "id": rcdata?.paid_slip?.id,
                        "url": rcdata?.paid_slip?.url,
                    })

                    const fileList: UploadFile[] = [
                        {
                            uid: rcdata?.paid_slip?.id,
                            name: rcdata?.paid_slip?.id,
                            status: "done",
                            url: rcdata?.paid_slip?.url,
                            thumbUrl: rcdata?.paid_slip?.url
                        }
                    ];

                    setPaidSlipFile({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList],
                    })

                    form?.resetFields(["paid_slip"]);

                }

                if(rcdata?.quotation){
                    setReqQuotation(false)
                    setQuotation({
                        "id": rcdata?.quotation?.id,
                        "url": rcdata?.quotation?.url,
                        "name": rcdata?.quotation?.id
                    })
                    const fileList: UploadFile[] = [
                        {
                            uid: rcdata?.quotation?.id,
                            name: rcdata?.quotation?.id,
                            status: "done",
                            url: rcdata?.quotation?.url,
                            thumbUrl: rcdata?.quotation?.url
                        }
                    ];
                    setQuotationFile({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList],
                    })
                    form?.resetFields(["quotation"]);
                }else{
                    setReqQuotation(true)
                }

                if(rcdata?.pet_picture){
                    setPetPic({
                        "id": rcdata?.pet_picture?.id,
                        "url": rcdata?.pet_picture?.url,
                        "name": rcdata?.pet_picture?.id
                    })
                    const fileList: UploadFile[] = [
                        {
                            uid: rcdata?.pet_picture?.id,
                            name: rcdata?.pet_picture?.id,
                            status: "done",
                            url: rcdata?.pet_picture?.url,
                            thumbUrl: rcdata?.pet_picture?.url
                        }
                    ];
                    setPetPicFile({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList],
                    })
    
                    form?.resetFields(["pet_pic"]);
                }

                if(rcdata?.pet_medical_cert){
                    setPetMedCer({
                        "id": rcdata?.pet_medical_cert?.id,
                        "url": rcdata?.pet_medical_cert?.url,
                        "name": rcdata?.pet_medical_cert?.id
                    })
                    const fileList2: UploadFile[] = [
                        {
                            uid: rcdata?.pet_medical_cert?.id,
                            name: rcdata?.pet_medical_cert?.id,
                            status: "done",
                            url: rcdata?.pet_medical_cert?.url,
                            thumbUrl: rcdata?.pet_medical_cert?.url
                        }
                    ];
                    setPetMedCerFile({
                        name: 'files',
                        multiple: true,
                        defaultFileList: [...fileList2],
                    })
                    form?.resetFields(["pet_medical_cert"]);
                }

                let pet_species_filtered = ""
                let pet_size = ""
                await axios.get(`${petURL}/api/lscpet/findOnePetSpecies?id=${rcdata?.pet_species}`, { headers: {  } })
                    .then((res) => {
                        pet_species_filtered = res?.data?.pet_species_name
                    })

                await axios.get(`${petURL}/api/lscpet/findOnePetSizeDetail?id=${rcdata?.pet_size_detail}`, { headers: {  } })
                    .then((res) => {
                        pet_size = res?.data?.size_name_en
                    })

                let pet_type_filtered = data_pet_type?.filter((item:any) => item._id == rcdata?.pet_type);
                // let pet_species_filtered = data_pet_species?.filter((item:any) => item._id == rcdata?.pet_species);

                // 4. ============== date ==================
                const startDate = dayjs(rcdata?.pet_birthday).format("YYYY-MM-DD");
                const endDate = dayjs();
                const years = endDate.diff(startDate, "year");
                const months = endDate.diff(startDate, "month");
                const days = endDate.diff(startDate, "day");

                // console.log('pet_species_filtered', pet_species_filtered)
                let approveDate = rcdata?.approve_date && dayjs.tz(rcdata?.approve_date, 'Asia/Bangkok').toISOString()
                const createDate = rcdata?.approve_date && dayjs.tz(rcdata?.create_date, 'Asia/Bangkok').toISOString()
                const registerDate = rcdata?.register_date && dayjs.tz(rcdata?.register_date, 'Asia/Bangkok').toISOString()
                const expireDate = rcdata?.expire_date && dayjs.tz(rcdata?.expire_date, 'Asia/Bangkok').toISOString()
                const paidDate = rcdata?.paid_date && dayjs.tz(rcdata?.paid_date, 'Asia/Bangkok').toISOString() 

                if(is_lifetime){
                    const app_date = rcdata?.approve_date;
                    approveDate = dayjs.utc(app_date).utcOffset(7);
                }
                // Given UTC date string
                // const utcDate = "2023-11-17T04:45:51.514Z";

                // // Convert to a Day.js object in UTC
                // const utcDateTime = dayjs.utc(utcDate);

                // // Convert to UTC+7
                // const dateTimeInUTCPlus7 = utcDateTime.tz('Asia/Bangkok');

                // console.log('registerDate',rcdata?.register_date)
                // const date = "2023-11-17T04:45:51.514Z";
                const date = rcdata?.register_date;
                const dateInGMTPlus7 = dayjs.utc(date).utcOffset(7);

                // console.log(dateInGMTPlus7.format()); // Display the date in GMT+7
                // console.log('registerDate', registerDate)
                // console.log('createDate', createDate)  

                form?.setFieldsValue({
                    // owner detail
                    property: rcdata?.property?.property_name,
                    unit: rcdata?.unit?.unit_name,
                    owner: rcdata?.owner_of_pet?.first_name + " " + rcdata?.owner_of_pet?.last_name,
                    type: rcdata?.user_type?.user_sub_type,

                    // pet detail
                    pet_name: rcdata?.pet_name,
                    // pet_type: rcdata?.pet_type,
                    pet_type: pet_type_filtered ? pet_type_filtered[0]?.pet_type_name : '-',
                    // pet_species: rcdata?.pet_species,
                    pet_species: pet_species_filtered,
                    pet_birthdate: dayjs(rcdata?.pet_birthday).format("DD-MM-YYYY HH:mm"),
                    // pet_birthdate: dayjs(rcdata?.pet_birthdate).format("YYYY-MM-DD"),
                    // pet_age: `${diffInYears} year ${remainingMonths} month ${diffInDays} day`,
                    // pet_age: diffInYears == 0 && remainingMonths == 0 ? `${diffInDays} day` : `${diffInYears} year ${remainingMonths} month ${diffInDays} day`,
                    // pet_age: diffInYears == 0 && remainingMonths == 0 ? `${days} days` : `${years} year ${months} month ${days} days`,
                    // pet_age: `${years} years ${months} months ${days} days`,
                    // pet_age: years == 0 && months == 0 ? `${days} days` : years == 0 && months > 0 ? `${months} months ${days} days` : `${years} years`,
                    pet_age: years == 0 && months == 0 ? `${days} days` : years == 0 && months > 0 ? `${months} months` : `${years} years`,
                    // pet_age: age,
                    pet_gender: rcdata?.pet_gender == "1" ? "male" : rcdata?.pet_gender == "2" ? "female" : "N/A",
                    pet_size: pet_size,
                    pet_color: rcdata?.pet_color,
                    pet_microchip: rcdata?.pet_microchip_code,
                    pet_special_detail: rcdata?.pet_special_detail,

                    // register detail
                    // register_date: rcdata?.register_date ? dayjs(rcdata?.register_date).format("DD-MM-YYYY HH:mm") : '-',
                    // register_date: !isChargeProp ? dayjs.tz(registerDate, 'Asia/Bangkok').format("YYYY-MM-DD HH:mm") : dayjs(rcdata?.register_date).format("YYYY-MM-DD HH:mm"),
                    register_date: !isChargeProp ? dateInGMTPlus7.format("YYYY-MM-DD HH:mm") : dayjs(rcdata?.register_date).format("YYYY-MM-DD HH:mm"),
                    // approve_date: rcdata?.approve_date ? dayjs(rcdata?.approve_date).format("DD-MM-YYYY HH:mm") : null,
                    // approve_date: isChargeProp ? rcdata?.approve_date ? approveDate : null : registerDate,
                    approve_date: isChargeProp ? rcdata?.approve_date ? approveDate : null : dateInGMTPlus7.format("YYYY-MM-DD HH:mm"),
                    // expire_date: rcdata?.expire_date ? dayjs(rcdata?.expire_date).format("DD-MM-YYYY HH:mm") : null,
                    // expire_date: rcdata?.expire_date ? dayjs(rcdata?.expire_date).toISOString() : undefined,
                    expire_date: rcdata?.expire_date ? expireDate : null,
                    paid_date: rcdata?.paid_date ? paidDate : null,
                    // paid_date: rcdata?.paid_date ? dayjs(rcdata?.paid_date).format("DD-MM-YYYY HH:mm") : null,
                    // paid_date: rcdata?.paid_date !== null && dayjs(rcdata?.paid_date),
                    paid_net: rcdata?.paid_net ? rcdata?.paid_net : null,

                    // status
                    pet_approve_stat: rcdata?.pet_approve_stat == "6557382ca209821eb93c7492" ? "ลบบัญชีโดยผู้ใช้" : rcdata?.pet_approve_stat,
                    quotation_price: rcdata?.quotation_price,
                    remark: rcdata?.remark,
                    pet_reject_reason: rcdata?.pet_reject_reason,
                    pet_reject_reason_another: rcdata?.pet_reject_reason_another
                })

                // setLoading(false);
                setTimeout(() => {
                    setLoading(false)
                }, 200);
            },
        },
    });


    const colTrail:any = [
        {
            title: `${t("pet-data-regis.transactionDate")}`,
            dataIndex: 'item_name',
            key: 'item_name',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                // console.log('record',record)
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: `${t("pet-data-regis.transactionMaker")}`,
            // dataIndex: 'name',
            key: 'name',
            // sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_by ? record.create_by.user_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: `${t("pet-data-regis.status")}`,
            dataIndex: 'createBy',
            key: 'createBy',
            render: (val:any, record:any) => {
                // console.log('record', record)
                // return (
                //     <>
                //         <Tag>{record.status_name}</Tag>
                //     </>
                // );

                return (
                    record ?
                        record?.status == "6539f59959e64523844d078f" ? // รอดำเนินการ
                            <Tag className="tag_pet_blue"><TextField value={record?.status_name}  /></Tag>
                        : record?.status == "6539f5c659e64523844d0791" ? // ตรวจสอบเสร็จสิ้น รอชำระเงิน
                            <Tag className="tag_pet_pink"><TextField value={record?.status_name}  /></Tag>
                        : record?.status == "6539f5e659e64523844d0793" ? // รอตรวจสอบสถานะชำระค่าบริการ
                            <Tag className="tag_pet_orange"><TextField value={record?.status_name}  /></Tag>
                        : record?.status == "6539f5f359e64523844d0795" ? // หมดอายุ
                            <Tag className="tag_pet_black"><TextField value={record?.status_name}  /></Tag>
                        : record?.status == "6539f5fe59e64523844d0797" ? // อนุมัติ
                            <Tag className="tag_pet_green"><TextField value={record?.status_name}  /></Tag>
                        : record?.status == "6539f60859e64523844d0799" ? // ไม่อนุมัติ
                            <Tag className="tag_pet_red"><TextField value={record?.status_name}  /></Tag>
                        : record?.status == "6539f61a59e64523844d079b" ? // ยกเลิกการลงทะเบียน
                            <Tag className="tag_pet_red"><TextField value={record?.status_name}  /></Tag>
                        : record?.status == "6557382ca209821eb93c7492" ? // ลบบัญชีโดยผู้ใช้
                            <Tag className="tag_pet_black"><TextField value={record?.status_name}  /></Tag>
                        : <></>

                    : '-'
                )
            },
        },
        {
            title: `${t("pet-data-regis.note")}`,
            dataIndex: 'remark',
            key: 'remark',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.remark ? record.remark : '-': '-'}</div>
                    </>
                );
            },
        },
    ];

    const updateJobAndTrail = async (value:any) => {
        console.log('values', value)
        // setButtonDisabled(true);
        // message.info('save นะจ๊ะ 🌛');

        // console.log('approve_date', dayjs(value?.approve_date).format("YYYY-MM-DD HH:mm:ss"))
        // console.log('expire_date', dayjs(value?.expire_date).format("YYYY-MM-DD HH:mm:ss"))
        // console.log('paid_date', dayjs(value?.paid_date).format("YYYY-MM-DD HH:mm:ss"))
        // console.log('expire_date', value?.expire_date)
        // console.log('paid_date', value?.paid_date)
        // console.log('pet_type', petType)
        // console.log('pet_species', petSpecies)
        // console.log('petGender', petGender)
        // console.log('petSize', petSize)
        // console.log('petPic',petPic)
        // console.log('petMedCer',petMedCer)
        // console.log('paidSlip',paidSlip)
        // console.log('petQuotation',petQuotation)
        // console.log('stat', stat) 

        let body:any
        body = {
            "pet_name": value?.pet_name,
            "pet_picture": petPic ? petPic : null,
            // "pet_size": "S",
            "pet_color": value?.pet_color,
            // "pet_birthday": "1993-12-20 07:44:00.000",
            // "pet_age": "30",
            "pet_microchip_code": value?.pet_microchip,
            "pet_special_detail": value?.pet_special_detail,
            "pet_medical_cert": petMedCer,
            "pet_id_card_picture":{
                "id": "",
                "url": ""
            },
            "paid_net": value?.paid_net,
            "paid_slip": paidSlip,
            "pet_approve_stat": value?.pet_approve_stat,
            // "status_name": stat?.label,
            "quotation": petQuotation,
            "quotation_price": value?.quotation_price,
            "remark": value?.remark,
            "update_by":{
                "user_id": Identity_data?.user_profile?.id,
                "user_name": Identity_data?.username,
                "user_type_id": Identity_data?.user_type?.id,
                "user_type_name": Identity_data?.user_type?.type_name
            }
        }

        if(petType !== undefined){
            body.pet_type = petType?.value
        }

        if(petSpecies !== undefined){
            body.pet_species = petSpecies?.value
        }

        if(petGender !== undefined){
            body.pet_gender = petGender?.value 
        }

        if(petSize !== undefined){
            body.pet_size_detail = petSize?.value
        }

        if(value?.pet_reject_reason){
            body.pet_reject_reason = value?.pet_reject_reason
        }

        if(value?.pet_reject_reason_another){
            body.pet_reject_reason_another = value?.pet_reject_reason_another
        }

        // if(value?.approve_date !== undefined){
        if(value?.pet_approve_stat == "6539f5fe59e64523844d0797"){ // อนุมัติ
            // "approve_date": "1993-12-20 07:44:00.000",
            // body.approve_date = dayjs(value?.approve_date).format("YYYY-MM-DD HH:mm:ss") + ".000"
            body.approve_date = dayjs().format("YYYY-MM-DD HH:mm:ss") + ".000"
        }else{
            body.approve_date = null
        }

        // if(value?.expire_date !== undefined || value?.expire_date !== null){
        if(value?.expire_date){
            body.expire_date = dayjs(value?.expire_date).format("YYYY-MM-DD HH:mm:ss") + ".000"
            // body.expire_date = dayjs(value?.expire_date).format("YYYY-MM-DD")
        }else{
            body.expire_date = null 
        }

        // if(value?.paid_date !== undefined || value?.paid_date !== null){
        if(value?.paid_date){
            // "approve_date": "1993-12-20 07:44:00.000",
            body.paid_date = dayjs(value?.paid_date).format("YYYY-MM-DD HH:mm:ss") + ".000"
        }else{
            body.paid_date = null
        }

        console.log('body', body)

        await axios.put(`${petURL}/api/lscpet/updatePetRegis?id=${pet_id}`, body, { headers: {  } })
        .then((res) => {
            console.log('res update >>>>', res)
        })


        let body_trail = {
            "pet_regis": pet_id,
            "status": value?.pet_approve_stat,
            "status_name": stat?.label,
            "reject_status": subStat?.value,
            "reject_status_name": subStat?.label,
            "remark": value?.remark,
            "create_by":{
                "user_id": Identity_data?.user_profile?.id,
                "user_name": Identity_data?.username,
                "user_type_id": Identity_data?.user_type?.id,
                "user_type_name": Identity_data?.user_type?.type_name
            }
        }

        // console.log('body_trail', body_trail)

        await axios.post(`${petURL}/api/lscpet/createPetRegisTrail`, body_trail, { headers: {  } })
        .then((res) => {
            console.log('res create trail >>>>', res)
        })

        console.log('stat >>>>> ', stat)

        // เพิ่มส่ง noti if stat == ไม่อนุมัติ "6539f60859e64523844d0799"
        if(stat?.value == "6539f60859e64523844d0799"){ //ไม่อนุมัติ
            // ส่ง Noti in app แจ้งผลไม่อนุมัติ ระบุสาเหตุย่อย
            // แสดงสถานะในหน้าจอ All Pet
            console.log('not approve', API_URL)
            let data = {
                "mode": "1",
                "pet_id": rcdata?._id,
                "profile_id": rcdata?.owner_of_pet?.id
            }
            let res_noti = await axios.post(API_URL + `/like-systems/sendNotiPet`, data , {
                headers: { Authorization: "Bearer " + TOKEN_KEY }
            });
        }

        // เพิ่มส่ง noti if stat == ตรวจสอบเสร็จสิ้น รอชำระเงิน "6539f5c659e64523844d0791"
        if(stat?.value == "6539f5c659e64523844d0791"){ //ตรวจสอบเสร็จสิ้น รอชำระเงิน
            // ส่ง Noti in app แนบใบเสนอราคา
            // แสดงสถานะในหน้าจอ All Pet
            let data = {
                "mode": "2",
                "pet_id": rcdata?._id,
                "profile_id": rcdata?.owner_of_pet?.id
            }
            let res_noti = await axios.post(API_URL + `/like-systems/sendNotiPet`, data , {
                headers: { Authorization: "Bearer " + TOKEN_KEY }
            });
        }

        if(stat?.value == "6539f5fe59e64523844d0797"){ //อนุมัติ
            console.log('CREATE PET ID CARD')
            let body_pet_id ={
                "_id": pet_id,
                "update_by":{
                    "user_id": Identity_data?.user_profile?.id,
                    "user_name": Identity_data?.username,
                    "user_type_id": Identity_data?.user_type?.id,
                    "user_type_name": Identity_data?.user_type?.type_name
                }
            }

            try {
                setLoading(true)
                await axios.post(`${API_URL}/like-systems/createPetIdCard`, body_pet_id, 
                { headers: { Authorization: AuthStr }})
                .then(async (res) => {
                    console.log('res pet id card >', res)

                    // ตอนที่นิติอนุมัติสัตว์เลี้ยง ต้องมี noti Inapp แจ้งเตือนลูกบ้านด้วย
                    let data = {
                        "mode": "3",
                        "pet_id": rcdata?._id,
                        "profile_id": rcdata?.owner_of_pet?.id
                    }
                    let res_noti = await axios.post(API_URL + `/like-systems/sendNotiPet`, data , {
                        headers: { Authorization: "Bearer " + TOKEN_KEY }
                    });

                    setLoading(false)
                })
            } catch (error) {
                console.log('error create pet id', error)
            }
        }
        successNoti();
        replace("/pets/pet-data")
    };

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'application/pdf' || file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload PDF/JPEG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function fileChange(e:any, type:any){
        let res: any = e?.file;

        switch (type) {
            case "PET_PICTURE":
                console.log('pet_picture')
                if(res?.status == "done"){
                    setPetPic({
                        "id": res?.response[0]?.id,
                        "url": res?.response[0]?.url,
                        "name": res?.response[0]?.id
                    })
                }else if(res?.status == "removed"){
                    setPetPic(undefined);
                    setPetPicFile(undefined); //FIRST DEL
                    form?.resetFields(["pet_pic"]);
                }
                break;
            case "MED_CERT":
                console.log('med_cert')
                if(res?.status == "done"){
                    setPetMedCer({
                        "id": res?.response[0]?.id,
                        "url": res?.response[0]?.url,
                        "name": res?.response[0]?.id
                    })
                }else if(res?.status == "removed"){
                    setPetMedCer(undefined);
                    setPetMedCerFile(undefined); //FIRST DEL
                    form?.resetFields(["pet_medical_cert"]);
                }
                break;
            case "PAID_SLIP":
                console.log('paid_slip')
                if(res?.status == "done"){
                    setPaidSlip({
                        "id": res?.response[0]?.id,
                        "url": res?.response[0]?.url,
                        "name": res?.response[0]?.id
                    })
                }else if(res?.status == "removed"){
                    setPaidSlip(undefined);
                    setPaidSlipFile(undefined); //FIRST DEL
                    form?.resetFields(["paid_slip"]);
                }
                break;
            case "QUOTATION":
                console.log('quotation')
                if(res?.status == "done"){
                    setReqQuotation(false)
                    setQuotation({
                        "id": res?.response[0]?.id,
                        "url": res?.response[0]?.url,
                        "name": res?.response[0]?.id
                    })
                }else if(res?.status == "removed"){
                    setReqQuotation(true)
                    setQuotation(undefined);
                    setQuotationFile(undefined); //FIRST DEL
                    form?.resetFields(["quotation"]);
                }
                break;
           
            default:
                console.log('wrong type')
        }
       
    }

    return (
        <Spin size="large" spinning={isLoading}>
            <Edit 
                title={t("pet-data-regis.title-edit")}
                saveButtonProps={{ hidden: true }}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                        <Breadcrumb.Item>{t("pet-data-regis.pet")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-data-regis.petData")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-data-regis.petView")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>,
                    onBack(e?) {
                        replace("/pets/pet-data")
                    },
                }}
            >
                <div style={{width: "100%"}}>
                    <Form  
                        form = {form} 
                        layout="vertical"
                        onFinish={(values:any) => {
                            updateJobAndTrail(values)
                        }}
                    >
                        {/* ====================== รายละเอียด ====================== */}
                        <Row gutter={82}>
                            <Col span={24}><div style={{marginBottom:'3%', fontSize:'2vh'}}>{t("pet-data-regis.data-detail")}</div></Col>
                        </Row>
                        <div style={{width: "75%"}}>
                            <Row>
                                <Col span={12}>
                                    <Form.Item 
                                        label={t("pet-data-regis.property")}
                                        name="property"
                                        style={{width:"95%"}} 
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item 
                                        label={t("pet-data-regis.unit")}
                                        name="unit"
                                        style={{width:"95%"}} 
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                {/* <Col span={12}>
                                    <Form.Item 
                                        label="Owner"
                                        name="owner"
                                        style={{width:"95%"}} 
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col> */}

                                <Col span={12}>
                                    <Form.Item 
                                        label={t("pet-data-regis.type")}
                                        name="type"
                                        style={{width:"95%"}} 
                                    >
                                        <Input disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </div>
                        
                        <Divider />

                        {/* ====================== ข้อมูลสัตว์เลี้ยง ====================== */}
                        <Row gutter={82}>
                            <Col span={24}><div style={{marginBottom:'3%', fontSize:'2vh'}}>{t("pet-data-regis.data-Pets")}</div></Col>
                        </Row>
                            <div style={{width: "100%"}}>
                                <Row>
                                    <Col span={8}>

                                        <Form.Item
                                            name="pet_pic"
                                            label={t("pet-data-regis.petImg")}
                                            // tooltip="Only PNG, JPG, PDF Maximum size 5MB"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'Please upload image.'
                                            //     },
                                            // ]}
                                            initialValue={petPicFile}
                                            style={{width:"95%"}} 
                                        >
                                            <Upload.Dragger
                                                {...petPicFile}
                                                style={{borderRadius: 10}}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                onChange={(e: any) => fileChange(e, "PET_PICTURE")}
                                                beforeUpload={beforeUpload}
                                            >
                                                <p className="ant-upload-text">{t("upload")}</p>
                                            </Upload.Dragger>
                                        </Form.Item>
                                        <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677",  marginTop:'-4%'}}>*Only PNG, JPG, PDF Maximum size 5MB</p>

                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            name="pet_medical_cert"
                                            label={t("pet-data-regis.medical")}
                                            initialValue={petMedCerFile}
                                            style={{width:"95%"}} 
                                        >
                                            <Upload.Dragger
                                                {...petMedCerFile}
                                                style={{borderRadius: 10}}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                onChange={(e: any) => fileChange(e, "MED_CERT")}
                                                beforeUpload={beforeUpload}
                                            >
                                                <p className="ant-upload-text">{t("upload")}</p>
                                            </Upload.Dragger>
                                        </Form.Item>
                                        <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677", marginTop:'-4%'}}>*Only PNG, JPG, PDF Maximum size 5MB</p>

                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.name")}
                                            name="pet_name"
                                            style={{width:"95%"}} 
                                            rules={[{required: true, message: 'Please set pet name.'}]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.pet-types")}
                                            name="pet_type"
                                            style={{width:"95%"}} 
                                            rules={[{required: true, message: 'Please select pet type.'}]}
                                        >
                                            <Select 
                                                options={optionPetType}
                                                placeholder={t("select.sel")}
                                                style={{ width: '100%' }}
                                                onSelect={(value: any, record:any) => {
                                                    setPetType(record)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.pet-species")}
                                            name="pet_species"
                                            style={{width:"100%"}} 
                                            rules={[{required: true, message: 'Please select pet species.'}]}
                                        >
                                            <Select 
                                                options={optionPetSpecies}
                                                placeholder={t("select.sel")}
                                                style={{ width: '100%' }}
                                                onSelect={(value: any, record:any) => {
                                                    setPetSpecies(record)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.hbd")}
                                            name="pet_birthdate"
                                            style={{width:"95%"}} 
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.age")}
                                            name="pet_age"
                                            style={{width:"95%"}} 
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.gender")}
                                            name="pet_gender"
                                            style={{width:"100%"}} 
                                        >
                                            {/* <Input /> */}
                                            <Select 
                                                options={option_pet_gender}
                                                onSelect={(value: any, record:any) => {
                                                    setPetGender(record)
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.size")}
                                            name="pet_size"
                                            style={{width:"95%"}}
                                            rules={[{required: true, message: 'Please select pet size.'}]}
                                        >
                                            <Select 
                                                // options={optionPetSize}
                                                options={option_pet_size}
                                                onSelect={(value: any, record:any) => {
                                                    setPetSize(record)
                                                }}
                                            /> 
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.color")}
                                            name="pet_color"
                                            style={{width:"95%"}} 
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.microID")}
                                            name="pet_microchip"
                                            style={{width:"100%"}} 
                                        >
                                            <Input disabled/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={24}>
                                        <Form.Item 
                                            label={t("pet-data-regis.Special")}
                                            name="pet_special_detail"
                                            style={{width:"100%"}} 
                                        >
                                            <TextArea style={{height: 100}}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
 
                            </div>

                        <Divider />

                        {
                            rcdata?.pet_id_card_picture && rcdata?.pet_id_card_picture?.id !== "" && <div style={{width:'100%'}}>
                                {/* ====================== PET CARD ====================== */}
                                <Row gutter={82}>
                                    <Col span={24}><div style={{marginBottom:'3%', fontSize:'2vh'}}>ข้อมูลบัตรประจำตัวสัตว์เลี้ยง</div></Col>
                                </Row>
                                <Row>
                                    <Col span={12}>
                                        <Image src={rcdata?.pet_id_card_picture?.url} />
                                    </Col>
                                    <Col span={1} /> 
                                    <Col span={11}>
                                        <Image src={rcdata?.pet_qr?.url} />
                                    </Col>
                                </Row>
                                <Divider />

                            </div>
                        }

                        {/* ====================== ข้อมูลการลงทะเบียน ====================== */}
                        <Row gutter={82}>
                            <Col span={24}><div style={{marginBottom:'3%', fontSize:'2vh'}}>{t("pet-data-regis.regisInfor")}</div></Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                    label={t("pet-data-regis.regisDay")}
                                    name="register_date"
                                    style={{width:"95%"}} 
                                >
                                    <Input disabled/>
                                </Form.Item>
                            </Col>
                            
                            <Col span={8}>
                                <Form.Item label={t("pet-data-regis.Approval")}
                                    name = "approve_date"
                                    // rules={[
                                    //     { required: true, message: 'Please select date and time.' },
                                    // ]}
                                    getValueProps={(value) => ({
                                        value: value ? dayjs(value) : null,
                                    })}
                                    style={{width:"95%"}} 
                                >
                                    <DatePicker
                                        placeholder="-"
                                        showTime
                                        style={{width:"100%"}}
                                        format={format}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                {
                                    isCharge ?
                                        <Form.Item 
                                            label={t("pet-data-regis.exp")}
                                            name="expire_date"
                                            // rules={[
                                            //     { required: true, message: 'Please select date and time.' },
                                            // ]}
                                            getValueProps={(value) => ({
                                                value: value ? dayjs(value) : null,
                                            })}
                                            // getValueProps={(value) => ( 
                                            //     {value: value ? dayjs(value): "",}
                                            // )}
                                            style={{width:"95%"}} 
                                        >
                                            {/* <DatePicker
                                                showTime
                                                placeholder="-"
                                                style={{width:"100%"}}
                                                format={format}
                                            /> */}
                                            <DatePicker 
                                                showTime 
                                                placeholder={is_lifetime ? 'ตลอดชีพ' : '-'}
                                                disabledDate={(current) => {
                                                    let customDate = moment().format("YYYY-MM-DD");
                                                    return current && current < moment(customDate, "YYYY-MM-DD");
                                                }}
                                                format={format}
                                                style={{width:"100%"}}
                                                disabled={is_lifetime ? true : false}
                                            />
                                        </Form.Item>
                                    :
                                        <Form.Item label={t("pet-data-regis.exp")}
                                            name = "forever_"
                                            style={{width: "95%"}} 
                                        >
                                            <Input value={"ตลอดชีพ"} defaultValue={"ตลอดชีพ"} disabled/>
                                        </Form.Item>
                                }
                            </Col>
                        </Row>

                        {/* กรณีที่ Property กำหนดไว้ว่ามีค่าบริการ และเป็นแบบชำระผ่านนิติ  */}
                        {
                            isCharge && <>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item label={t("pet-data-regis.payment")}
                                            name = "paid_date"
                                            // rules={[
                                            //     { required: true, message: 'Please select date and time.' },
                                            // ]}
                                            getValueProps={(value) => ({
                                                value: value ? dayjs(value) : null,
                                            })}
                                            style={{width: "95%"}} 
                                        >
                                            <DatePicker
                                                showTime
                                                placeholder="-"
                                                style={{width:"100%"}}
                                                format={format}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            label={t("pet-data-regis.paymentAmount")}
                                            name="paid_net"
                                            style={{width:"95%"}} 
                                        >
                                            {/* <Input /> */}
                                            <InputNumber 
                                                type={"number"}
                                                // placeholder="จำนวนเงินที่ชำระ"
                                                min={0}
                                                max={10000000}
                                                style={{
                                                    width: "100%", 
                                                    borderRadius: "4px"
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col span={8}>
                                        {/* <Form.Item 
                                            label="หลักฐานการชำระเงิน"
                                            name="paid_slip"
                                            style={{width:"95%"}} 
                                        >
                                            <Image src={paidSlip?.url ? paidSlip?.url : "https://lscmain.s3.ap-southeast-1.amazonaws.com/2566_11_03_10_04_43_d10198d6de.jpg?updated_at=2023-11-03T03:08:19.049Z"} style={{width: 140, height: "100%"}}></Image>
                                        </Form.Item> */}
                                        <Form.Item
                                            name="paid_slip"
                                            label={t("pet-data-regis.paymentProof")}
                                            initialValue={petPaidSlipFile}
                                            style={{width:"95%"}} 
                                        >
                                            <Upload.Dragger
                                                {...petPaidSlipFile}
                                                style={{borderRadius: 10}}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                onChange={(e: any) => fileChange(e, "PAID_SLIP")}
                                                beforeUpload={beforeUpload}
                                            >
                                                <p className="ant-upload-text">{t("upload")}</p>
                                            </Upload.Dragger>
                                        </Form.Item>
                                        <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677", marginTop:'-4%'}}>*Only PNG, JPG, PDF Maximum size 5MB</p>
                                    </Col>
                                </Row>
                            </>
                        }

                        <Divider />

                        {/* ====================== สถานะ ====================== */}
                        <Row gutter={82}>
                            <Col span={24}><div style={{marginBottom:'3%', fontSize:'2vh'}}>{t("pet-data-regis.status")}</div></Col>
                        </Row>
                        <Row>
                            <Col span={8}>
                                <Form.Item 
                                    label={t("pet-data-regis.status")}
                                    name="pet_approve_stat"
                                    style={{width:"95%"}} 
                                    rules={[{required: true, message: 'please select status.'}]}
                                >
                                    <Select 
                                        placeholder={t("select.sel")}
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            // console.log('record pet_approve_stat', record)
                                            setStat(record)
                                        }}
                                        options={data_pet_status}
                                    />
                                </Form.Item>
                            </Col>

                            {/* แสดงเฉพาะไม่อนุมัติ */}
                            {
                                stat?.value == "6539f60859e64523844d0799" &&
                                    <Col span={8}>
                                        <Form.Item 
                                            label="เหตุผล"
                                            name="pet_reject_reason"
                                            style={{width:"100%"}} 
                                            rules={[{required: true, message: 'please select reject reason.'}]}
                                        >
                                            <Select 
                                                placeholder={t("select.sel")}
                                                style={{ width: '100%' }}
                                                onSelect={(value: any, record:any) => {
                                                    setSubStat(record);
                                                }}
                                                options={option_reject_reason}
                                                allowClear
                                            />
                                        </Form.Item>
                                    </Col>
                            }
                        </Row>

                        {/* แสดงเฉพาะไม่อนุมัติ และเลือกเหตุผลมาเป็น อื่น ๆ */}
                        {
                            subStat?.value == "7" && 
                                <Row>
                                    <Col span={16}>
                                        <Form.Item 
                                            label="ระบุเหตุผล"
                                            name="pet_reject_reason_another"
                                            style={{width:"100%"}} 
                                        >
                                            <TextArea style={{height: "100px", maxHeight: "80px"}}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                        }

                        {/* กรณีเลือกสถานะ ตรวจสอบเสร็จสิ้น รอชำระเงิน และ config กำหนดว่า ชำระเงินไม่มีบิล */}
                        {
                            // isCharge && stat?.value == "" && <>
                            isBilling == false && stat?.value == "6539f5c659e64523844d0791" && <>
                                <Row>
                                    <Col span={8}>
                                        <Form.Item
                                            name="quotation"
                                            label="ใบเสนอราคา"
                                            initialValue={petQuotationFile}
                                            style={{width:"95%"}} 
                                            // rules={[{ required: reqQuotation, message: 'Please upload quotation.' }]}
                                        >
                                            <Upload.Dragger
                                                {...petQuotationFile}
                                                style={{borderRadius: 10}}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                maxCount={1}
                                                // onChange={fileChange}
                                                onChange={(e: any) => fileChange(e, "QUOTATION")}
                                                beforeUpload={beforeUpload}
                                            >
                                                <p className="ant-upload-text">{t("upload")}</p>
                                            </Upload.Dragger>
                                        </Form.Item>
                                        <p style={{fontSize:"8px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG, PDF Maximum size 5MB</p>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item 
                                            label="จำนวนเงิน"
                                            name="quotation_price"
                                            style={{width:"100%"}} 
                                            rules={[{ required: true, message: 'Please fill up price.' }]}

                                        >
                                            <InputNumber 
                                                type={"number"}
                                                // placeholder=""
                                                min={0}
                                                max={10000000}
                                                style={{
                                                    width: "100%", 
                                                    borderRadius: "4px"
                                                }}
                                                // formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                // onChange={(e: any) => handlechange(e?.target?.value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        }

                        <Row>
                            <Col span={16}>
                                <Form.Item 
                                    label={t("pet-data-regis.note")}
                                    name="remark"
                                    style={{width:"100%"}} 
                                >
                                    <TextArea style={{height: "100px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{float: 'right',marginTop: "1.2rem",marginBottom: "1.2rem"}}>
                            <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />} disabled={buttonDisabled}>Save</Button>
                        </Row>

                        <Divider />

                        {/* ====================== ประวัติการดำเนินงาน ====================== */}
                        <Row gutter={82}>
                            <Col span={24}><div style={{marginBottom:'3%', fontSize:'2vh'}}>{t("pet-data-regis.operationHis")}</div></Col>
                        </Row>

                        <Row>
                            <Col span={24}>
                                <Table 
                                    columns={colTrail} 
                                    dataSource={data_trail ? data_trail : null}
                                />
                            </Col>
                        </Row>

                    </Form>
                </div>
            </Edit>   
        </Spin>

    );
};