import { AuthProvider } from "@pankod/refine-core";
import { AuthHelper } from "@pankod/refine-strapi-v4";
import axios from "axios";
import { API_URL, TOKEN_KEY } from "./constants";
export const axiosInstance = axios.create();
axiosInstance.interceptors.request.use((config) => {
  if (config.url && config.url.includes('parcel-ins-create')) {
    config.url = config.url.replace('-create', '');
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
const strapiAuthHelper = AuthHelper(API_URL + "/api");

let user_profile: any = undefined;
let user_role: any = undefined;
let user_type: any = undefined;
let data_prop: any = undefined;
let chat_data: any = undefined;
let res_chat: any = undefined;

export const authProvider: AuthProvider = {
  
  login: async ({ username, password }) => {
    // const token = localStorage.getItem(TOKEN_KEY);
    let user_type
    const { data, status } = await strapiAuthHelper.login(username, password);

    let res = await axios.get(API_URL + `/api/like-systems/checkLoginPortal?username=${username}`, {
      headers: { Authorization: "Bearer " + TOKEN_KEY }
    });

    if(res.data !== undefined){
      user_type = res.data.user_type.type_name
    }

    // if (status === 200) {
    if (status === 200 && user_type == "Staff" || user_type == "Admin" || user_type == "Super Admin") {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      localStorage.setItem("USER_ID", data.user.id.toString());
      axiosInstance.defaults.headers = {
        Authorization: `Bearer ${data.jwt}`,
      };
      return Promise.resolve();
    }

    return Promise.reject();
  },

  logout: () => {
    console.log('====================================');
    console.log('log out ja');
    const iframe = document.getElementById('chatFrame') as HTMLIFrameElement;
    console.log('====================================');
    console.log(iframe);
    console.log('====================================');
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({ action: 'clearStorage' }, 'http://localhost:2000'); // chatURL ควรเป็น URL ของ iframe
    }
    localStorage.removeItem(TOKEN_KEY);

    return Promise.resolve();
  },
  checkError: () => {
    return Promise.resolve()
  },
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers = {
        Authorization: `Bearer ${token}`,
      };
      return Promise.resolve();
      
    }
    return Promise.reject();
  },
  getPermissions: () => 
  {
    return Promise.resolve()
  },
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    const { data, status } = await strapiAuthHelper.me(token);
    // console.log('status', status)
    if (status === 200) {
      const { id, username, email } = data;

      // let c = await axios.get(API_URL + "/api/user-profiles?populate=*&filters[user][id][$eq]=" + id, {
      //   headers: { Authorization: "Bearer "+token }
      // });

      // let k = await axios.get(API_URL + "/api/like-systems/findTypeMapping?user_id=" + id, {
      //   headers: { Authorization: "Bearer "+token }
      // });

      // user_profile = c.data.data[0];
      // user_role = k.data;


      // localStorage.setItem("USER_DATA",JSON.stringify(c.data.data[0].attributes));
      // localStorage.setItem("USER_TYPE",JSON.stringify(c.data.data[0].attributes.user_type.data.attributes.type_name));

      // if(user_role !== undefined){
      //   user_type = user_profile?.attributes?.user_type?.data?.attributes?.type_name

      //   // localStorage.setItem("USER_ROLE", user_role?.role?.id); 
      //   // localStorage.setItem("ROLE_MANAGE",JSON.stringify(user_role));
      //   if(user_type === "Staff"){
      //     // let data_p = await axios.get(API_URL + "/api/staff-has-properties?populate=*&filters[user_profile][user][id][$eq]=" + id, {
      //     //   headers: { Authorization: "Bearer "+token }
      //     // });
          // let data_p = await axios.get(API_URL + "/api/user-type-mappings?populate=*&filters[user_profile][user][id][$eq]=" + id, {
          //   headers: { Authorization: "Bearer "+token }
          // });

          // data_prop = data_p?.data?.data[0]?.attributes; 
          // console.log('user_profile in authprovider', user_profile)

          // console.log('data_prop in authprovider', data_prop)

          // let chat_data = await axios.get(API_URL + `/api/like-systems/regisXChatMobile?profile_id=${user_profile.id}&isPortal=true&property_id=${data_prop.property?.data.id}`, {
          //   headers: { Authorization: "Bearer "+token }
          // });
          // console.log('chat_data', chat_data.data)
      //     res_chat = chat_data.data

      //     // http://xxxx/api/like-systems/xChatAddContact?property_id=2 
      //     let add_contact = await axios.get(API_URL + `/api/like-systems/xChatAddContact?property_id=${data_prop.property?.data.id}`, {
      //       headers: { Authorization: "Bearer "+token } 
      //     });
      //     console.log('add_contact', add_contact.data)

      //     // let data_c = await axios.get(API_URL + `/api/like-systems/rocketChatServiceStaff?user_id=${user_profile?.id}&property_id=${data_prop?.property?.data?.id}`, {
      //     //   headers: { Authorization: "Bearer "+token }
      //     // });

      //     // let data_chat = data_c?.data?.data;

      //     // if(data_prop){ 
      //     //   localStorage.setItem("PROP", data_prop?.property?.data?.id);
      //     // }
      //   }
      // }

      let res = await axios.get(API_URL + `/api/like-systems/getUserIdentity?id=${id}`, {
        headers: { Authorization: "Bearer "+token }
      });
      // console.log('res getUserIdentity', res)

      // let res_chat_count = await axios.get("https://xchat.techscape.app/get-unread/" + res?.data.chat_id , { headers: { Authorization: 'Bearer ' + token } });
      // console.log('res call count', res_chat_count)

      // let res_chat_count = await axios.get(API_URL + `/api/like-systems/xChatCountUnread?chat_id=${res?.data.chat_id}`, {
      //   headers: { Authorization: "Bearer "+token } 
      // });
      // console.log('chat_count authprovider', res_chat_count?.data.count)
      // localStorage.setItem("CHAT_COUNT", res_chat_count?.data.count)
      // localStorage.setItem("CHAT_CONTACTS", res_chat_count?.data.contacts)
      if(res?.data){
        if(res?.data?.chat_count !== null && res?.data?.chat_count !== undefined){
          // console.log('res?.data?.chat_count', res?.data?.chat_count)
          localStorage.setItem("CHAT_COUNT", res?.data?.chat_count)
        }
        if(res?.data?.chat_contact_count !== null && res?.data?.chat_contact_count !== undefined){
          localStorage.setItem("CHAT_CONTACTS", res?.data?.chat_contact_count)
        }
        if(res?.data.property !== null && res?.data.property !== undefined){
          // console.log('res?.data.property', res?.data.property)
          localStorage.setItem("PROPERTY_ID", res?.data.property?.id)
          localStorage.setItem("PROPERTY_NAME", res?.data.property?.property_name)
        }
        if(res?.data?.chat_id !== null && res?.data?.chat_id !== undefined){
          localStorage.setItem("CHAT_ID", res?.data?.chat_id)
        }
        if(res?.data?.user_profile !== null && res?.data?.user_profile !== undefined){
          let niti_name = res?.data?.user_profile.first_name + " " + res?.data?.user_profile.last_name
          localStorage.setItem("NITI_NAME", niti_name);
          localStorage.setItem("PROFILE_ID", res?.data?.user_profile?.id);
        }
        if(res?.data?.show_red_dot !== null && res?.data?.show_red_dot !== undefined){
          // console.log('res?.data?.show_red_dot', res?.data?.show_red_dot)
          localStorage.setItem("SHOW_REDDOT", res?.data?.show_red_dot);
        }
        if(res?.data.prop_list !== null && res?.data.prop_list !== undefined){
          // console.log('res?.data.property', res?.data.property)
          localStorage.setItem("PROPERTY_MULTI_ID", res?.data?.prop_list.map((property:any) => property.id))
        }

      }
      

      return Promise.resolve(
        // {
        //   id,
        //   username,
        //   email,
        //   user_profile,
        //   user_type: user_profile?.attributes?.user_type?.data,
        //   user_sub_type: user_profile?.attributes?.user_sub_types?.data,
        //   role: user_role?.role,
        //   menu_access: user_role?.menu,
        //   // chat_id: user_profile?.attributes?.chat_id,
        //   property: data_prop ? data_prop.property?.data : null,
        //   // chat_data: chat_data ? chat_data.data : null
        //   chat_id: res_chat ? res_chat.code == "200" ? res_chat.chat_id : null : null,
        //   chat_username: res_chat ? res_chat.code == "200" ? res_chat.chat_username : null : null
        // }
        {
          id,
          username,
          email,
          // user_profile,
          user_profile: res?.data?.user_profile,
          user_type: res?.data?.user_type,
          user_sub_type: res?.data?.user_sub_types,
          role: res?.data?.role,
          menu_access: res?.data.menu_access,
          parent_menu: res?.data.parent_menu,
          child_menu: res?.data.child_menu,
          property: res?.data?.property,
          chat_id: res?.data?.chat_id,
          chat_username: res?.data.chat_username,
          show_red_dot: res?.data?.show_red_dot,
          prop_list: res?.data?.prop_list
        }
      );
    }

    return Promise.reject();
  },
};