/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, Select, ShowButton, Space, Table, TextField,  useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IPhoneBooks, IProperties } from "interfaces";
import Moment from 'moment';
import {  useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export const PhoneBooksList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Phone Books");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [ vProp, setProp ] = useState<any>();
    const [inputName, setInputName] = useState<any>();
    const [inputPhoneBook, setInputPhoneBook] = useState<any>();

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IPhoneBooks,HttpError, IPhoneBooks>({
        metaData: {
            populate: ['createBy', 'updateBy', 'phone_book_type', 'property']
        },  
        
    // });
        onSearch: (params) => {
            const filters: CrudFilters = [];
            // const { coupon_name } = params;
            const { q, property } = params;

            //     filters.push(
            //         {
            //             field       : "coupon_name",
            //             operator    : "contains",
            //             value       : coupon_name
            //         },

            //     );
            filters.push(
                {
                    field: "q",
                    operator: "eq",
                    value: q,
                },
                {
                    field       : "property][id]",
                    // operator    : (vProp || prop_list || prop_id) ? "eq" : "nin",
                    // value       : vProp ? vProp: prop_list ? prop_list: prop_id ? prop_id : null,
                    operator    : "eq",
                    value       : !!vProp ? vProp : null,
                },
                {
                    field       : "name",
                    operator    : "contains",
                    value       : !!inputName ? inputName : null,
                },
                {
                    field       : "phone_book_type][name]",
                    operator    : "contains",
                    value       : !!inputPhoneBook ? inputPhoneBook : null,
                },
            );

            return filters;
        },
        initialFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        permanentSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
        // permanentFilter:[
        //     {
        //         field : "property][id]",
        //         operator: data_property ? "eq" : "nin",
        //         value: data_property ? data_property : null
        //     }
        // ]
    });

const onReset = () => {
    setProp(undefined);
    setInputName(undefined)
    setInputPhoneBook(undefined)
    searchFormProps.form?.resetFields()
    searchFormProps.form?.submit()
};

const categoryQueryResult = useOne<IPhoneBooks>({
    metaData: {
        populate: '*'
    },
    resource: "phone-books",
    id: ''
});
let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IPhoneBooks>();
    const ed_point                          = "phone-books";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IPhoneBooks>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,
                };
            }
        },
    });

    let initF : any;
    initF = [{
            field       : 'deleteStatus',
            operator    : 'contains',
            value       :  'N',
        },
    ];
    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
    });
    
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'phonebook',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    return <>
            <List 
                title={t("bc_phonebooks.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("bc_phonebooks.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            {/* <Form.Item name="q">
                                <Input style={{borderRadius: 20,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item> */}

                            <Form.Item name="property" >
                                <Select 
                                    {...propertySelectProps}
                                    placeholder="Select property" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '250px', position:'relative'}} 
                                    allowClear
                                    onClear={() => setProp(undefined)}
                                    onSelect={(e : any) => setProp(e)}
                                    // onChange={value => { console.log(value); }} 
                                />
                                {/* <Input placeholder="please enter" size="small" style={{width: '250px'}}/> */}
                            </Form.Item>

                            <Form.Item name="Search" >
                                <Input
                                    onChange={(e) => setInputName(e.target.value)}
                                    style={{borderRadius: 4}} 
                                    placeholder="Search name" size="middle" allowClear
                                />
                            </Form.Item>

                            <Form.Item name="Search phone book type" >
                                <Input
                                    onChange={(e) => setInputPhoneBook(e.target.value)}
                                    style={{borderRadius: 4}} 
                                    placeholder="Search phone book type" size="middle" allowClear
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                {/* {
                                    vChkRoleUser ?
                                        <CreateButton id="create_btn" />
                                    :
                                        <></>
                                } */}
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        //total:paginationLength.length,
                        total:tableQueryResult?.data?.total, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                        
                >

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}
                    {/* <Table.Column dataIndex="id" title={t("Lid")} align="center" width={150} sorter
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}
                    
                    <Table.Column title="PROPERTY" align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.property?.property_name < b?.property?.property_name) {return -1}
                            if (a?.property?.property_name > b?.property?.property_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.property? record?.property.property_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="NAME" align="center" width={350} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.name < b?.name) {return -1}
                            if (a?.name > b?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.name:"-"}  />
                            </>
                        }}
                    />
                    <Table.Column title="PHONE" align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.phone < b?.phone) {return -1}
                            if (a?.phone > b?.phone) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.phone:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="PHONE BOOK TYPE" align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.phone_book_type?.name < b?.phone_book_type?.name) {return -1}
                            if (a?.phone_book_type?.name > b?.phone_book_type?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.phone_book_type? record?.phone_book_type.name :"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="phone_book_sub_type" title="PHONE BOOK SUB TYPE" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.phone_book_sub_type? record?.phone_book_sub_type.name :"-"}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title="CREATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                                {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                                {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />
                  
                    <Table.Column<IPhoneBooks>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton>

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */}

                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}