/* eslint-disable */
import { Show, TextField, Descriptions } from "@pankod/refine-antd";

// import { IProjectinfo } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { IPPType } from "interfaces";

// import Moment from 'moment';

export const PPtypesShow: React.FC<IResourceComponentsProps> = () => {
        const { queryResult } = useShow<IPPType>({  
            metaData: {
            populate: '*'
        }, 
    });
    const { data, isLoading } = queryResult;
    const record = data?.data;

    // const { Text } = Typography;
    console.log("mmmm",record)
    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>
            <div id="post_title">
                <TextField value={record?.pp_name ? record?.pp_name : '-'} />
            </div>

            <div id="post_content">
                <div className="title-content">
                    <Descriptions size="small" column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }} bordered>
                    <Descriptions.Item span={1} label="Property code">
                    <TextField value={record?.pp_code ? record?.pp_code : '-'} />
                    </Descriptions.Item>
                    </Descriptions>
                </div>
            </div>
        </Show>
    );
};