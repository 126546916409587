/* eslint-disable */
import { Breadcrumb, Button, Divider, Edit, Form, Icons, Row, TextField, Typography, useSelect, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../../constants";

import { notification, Table, Tag } from "antd";
import axios from "axios";
import { majorCareUrl } from "../../../constants";

export const RepairMdpcSendToCustApprove: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const format = 'YYYY-MM-DD HH:mm';

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);
    const { create, edit } = useNavigation();
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    const token = localStorage.getItem(TOKEN_KEY);
    const dataBlank:any = []

    const { Text } = Typography;

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);

    let work_id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    // id = arr_id.split("_")
    work_id = arr_id.split("%2C")
    let item_id = work_id[0]
    let job_id = work_id[1]

    const id_to_query = work_id.join(',');

    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    const [InspectorProfile, SetInspectorProfile] = useState<any>();

    const [textcount, settextcount] = useState('');
    const [dataDetailList, setDataDetailList] = useState<any>([]);
    
    const getDataK = async ()=>{
        // GET JOB DETAIL DATA
        let res_detail_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`, 
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId?stat=${id_to_query}&page=1&limit=20`, 

            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad Detail', dataLoad)
                SetPropertyIdJob(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.property_id : '-' : '-')

                // if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.property_obj[0]?.property_id == Number(prop_id))
                // }

                const xx = dataLoad.filter((item:any) => work_id.includes(item.job_id));

                for (let index = 0; index < xx.length; index++) {
                    dataDetailList.push(xx[index])
                }

                dataDetailList.reverse();
            });
    }

    console.log('dataDetailList', dataDetailList)

    const handleChange = (e:any) => {
        settextcount(e.target.value);
    };

    const saveData = async ()=>{
        updateRepairJob();
        // replace(`/mjc-inspec-today-job/edit/${job_id}_${JobIDHM}`)
        // replace(`/mjc-inspec-inprogress-job/edit/${job_id}_${JobIDHM}`)
    }

    const updateRepairJob = async () => {

        // เปลี่ยน stat เป็นส่งให้ลูกค้าอนุมัติ

        {/* 
            MAIN STATUS

            649859ca515b80b78fe13b84 = รอดำเนินการ
            649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
            649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
            64985a01515b80b78fe13b8a = เข้าประเมิน
            64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
            64985a29515b80b78fe13b8e = ประเมินเสร็จ
            64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
            64985a3c515b80b78fe13b92 = เข้าซ่อม
            64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
            64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
            64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
            64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
            64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
            64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
            64985bcf515b80b78fe13bba = ปิดงาน *
        */}

        for (let index = 0; index < work_id.length; index++) {
            // const element = work_id[index];
            
            // ****************** 1. UPDATE JOB with Selected stat ******************
            // **********************************************************************
            let data = {
                // "status_id": Stat.value,
                // "status_name": Stat.label, 
                "status_id": "64985bbe515b80b78fe13bb6",
                "status_name": "รออนุมัติงานซ่อม",  
                // "assign": InspectorProfile ? InspectorProfile[0] : null,
                // "appoint_start": AppointStart ? AppointStart : null,
                // "appoint_end": AppointEnd ? AppointEnd : null ,
            };
            let res = await axios.patch(majorCareUrl + "/majorcare/repairJobMDPC/updateRepairjob?id=" + work_id[index], data, { headers: { } });


            // ****************** 2. CREATE TRAIL ******************
            // *****************************************************

            let dataTrail = {
                "repair_job": work_id[index],
                // "status": Stat.value,
                // "status_name": Stat.label,
                "status": "64985bbe515b80b78fe13bb6",
                "status_name": "รออนุมัติงานซ่อม",  
                // "sub_status": SubStat !== undefined ? SubStat.value : null,
                // "sub_status_name": SubStat !== undefined ? SubStat.label : null,
                "appoint_start": AppointStart ? AppointStart : null,
                "appoint_end": AppointEnd ? AppointEnd : null ,
                "assign": InspectorProfile ? InspectorProfile[0] : null,
                "sla": {
                    "sla": "-",
                    "sla_time": "-"
                },
                // "remark": Remark !== undefined ? Remark : "",
                "create_by": Identity_data?.user_profile,
                "hod_approve": false,
                "hod_status": {
                    "hod_id": '',
                    "hod_name": '',
                    "hod_app_status": '', // A = approve, R = not approve 
                    "hod_remark": '',
                    "create_date": ''
                }
            }

            let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrailMDPC", dataTrail, { headers: { } });
        }

        successNoti();
        list("repair-mdpc-inspec")
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    const columns:any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            // sorter: (a:any, b:any) => a.job_id.localeCompare(b.job_id),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by.attributes !== undefined ? record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YYYY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <div>
                            <Tag className="tag_mjc_stat_wait_inspect">
                                <TextField value={record.job_obj[0].status_name}  />
                            </Tag>
                        </div>
                        {/* <div>
                            {
                                record ? 
                                    record.job_obj[0]?.status_id !== null ?
                                        record.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" ?
                                            <Tag className="tag_mjc_stat_wait_inspect">
                                                <TextField value={'รับเรื่องแจ้งซ่อม'}  />
                                            </Tag>
                                        :
                                            record.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" ?
                                                <Tag className="tag_mjc_stat_inspect">
                                                    <TextField value={'เลื่อนนัดจากลูกค้า'}  />
                                                </Tag> 
                                            :
                                                record.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" ?
                                                    <Tag className="tag_mjc_stat_inspect">
                                                        <TextField value={'เลื่อนนัดจากนิติบุคคล'}  />
                                                    </Tag>
                                                :
                                                    <Tag className="tag_mjc_stat_wait_apprv">
                                                        <TextField value={'error 404'}  />
                                                    </Tag>
                                    : '-'
                                : '-'
                            }
                        </div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                    getDataK();

                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        // resource: "home-check-up-settings",
        resource: "repair-setting-mdpcs",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "role_management][id]",
                operator: "eq",
                value: 21 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            {
                field: "property][id]",
                operator: "eq",
                value: PropertyIdJob
            },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    // const FindInspectorUserId = async (e:any) => {    
    //     let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
    //         headers: { Authorization: "Bearer "+token }
    //     });
    //     let inspector_profile = k?.data?.data;
    //     SetInspector(inspector_profile[0].attributes.user_profile?.data.id)
    //     SetInspectorProfile(inspector_profile)
    // }

    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleClick = () => {
        setButtonDisabled(true);
        form.submit()
    };

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        title="ยืนยันรายการส่งให้ลูกค้าอนุมัติ"
        // pageHeaderProps={{
        //     extra : <></>
        // }}
        pageHeaderProps={{
            breadcrumb: <Breadcrumb>
                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                <Breadcrumb.Item>MDPC แจ้งซ่อม (สำหรับช่าง)</Breadcrumb.Item>
                {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><span className="last_breadcrumb">ส่งให้ลูกค้าอนุมัติ</span></Breadcrumb.Item>
            </Breadcrumb>,
            extra : <></>,
            onBack(e?) {
                list("repair-mdpc-inspec")
            },
        }}
    >
        <Form  
            form = {form} 
            // layout="horizontal"
            layout="vertical"
            onFinish={(values:any) => {
                // createAssignInspec(values);
                // updateRepairJob()
                saveData();
            }}
        >
            <Table  
                dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank}
                columns={columns} 
                // onChange={onChange} 
                // pagination={{ size: "small", position: [ bottom] }} 
                pagination={{ size: "small", position: [ 'bottomRight'] }} 
            />

            <Divider/>

            <Row style={{float: 'right'}}>
                <Button type="ghost" onClick={() => (list("repair-inspec"))} icon={<Icons.SaveOutlined />} >ยกเลิก</Button>
                {/* <Button type="primary" onClick={() => saveData()} icon={<Icons.SaveOutlined />} style={{marginLeft:'6px'}} >ยืนยัน</Button> */}
                <Button type="primary" onClick={() => handleClick()} icon={<Icons.SaveOutlined />} style={{marginLeft:'6px'}} disabled={buttonDisabled} >ยืนยัน</Button>

            </Row>
        </Form>
    </Edit>   
    );
};