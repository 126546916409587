import { Col, DatePicker, Divider, Form, FormProps, Input, Radio, Row, Select, Spin, useSelect } from "@pankod/refine-antd";
import { useApiUrl, useGetIdentity, useList, useTranslate } from "@pankod/refine-core";
import { IAppointmentType, IProperties, IUnit, IUserhunit } from "interfaces";
import { useState } from "react";
// import moment from "moment";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

interface IAPPOINTMENT {
    createProps?: FormProps<{}>,
    vConfirm: any
}

let appoindt: any = undefined;
let userhunitdt: any = undefined;
let userhunitdtDEEP: any = undefined;
let staffdt: any = undefined;
export const AppointmCreate: React.FC<IAPPOINTMENT> = ({createProps, vConfirm}) => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    
    const {data: userdt} = useGetIdentity<any>();
    let userInProp = userdt?.property ? userdt?.property.id : null

    const [ isLoading, setLoading ] = useState<boolean>(true);
    const [ valueProp, setProp ] = useState<any>();
    const [ valueUnit, setUnit ] = useState<any>();
    const [ valueReg, setReg ] = useState<boolean>(false);
    const [ valueappType, setappType ] = useState<any>();

    const [ valuecusID, setcusID] = useState<any>("");
    const [ valuecusName, setcusName ] = useState<any>("");
    const [ valuecusLastName, setcusLastName ] = useState<any>("");
    const [ valuecusPhone, setcusPhone ] = useState<any>("");
    const [ valuecusEmail, setcusEmail ] = useState<any>("");

    const [ valuereqDate, setreqDate ] = useState<any>("");
    const [ timeslotdt, setslotdt ] = useState<any>([]);
    const [ valuetimeSlot, settimeSlot ] = useState<any>("");
    const [ valueStatus, setStatus ] = useState<any>("");
    const [ valuestaffinProp, setstaffinProp ] = useState<any>();

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { selectProps: propertydt } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data
            if(dataSet?.length == 1){
                setProp(dataSet[0]?.id)
                createProps?.form?.setFieldsValue({
                    property: dataSet[0]?.id
                })
            }
        },},
        pagination:{
            pageSize:1000
        }
    });

    const { selectProps: unitsdt } = useSelect<IUnit>({
        resource: "units",
        fetchSize: 200,
        metaData: {
            populate: '*'
        },
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator: (valueProp || prop_list || prop_id) ? "eq" : "nin",
                value: valueProp ? valueProp : prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const appointtype = useList<IAppointmentType>(
        {
            resource: "appointment-types",
            metaData: {
                populate: ['user_sub_types', 'user_sub_types.sub_type_name']
            },
            config:{
                filters: [
                    {
                        field       : 'deleteStatus',
                        operator    : 'contains',
                        value       :  'N',
                    },
                    {
                        field       : 'status',
                        operator    : 'eq',
                        value       :  true,
                    },
                ],
                pagination: { current: 1, pageSize: 1000 },
            },
            queryOptions:{onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    appoindt = newData;
                }

                setLoading(false)
            },}
        }
    );

    const userhunit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: [
                'user_profile',
                'user_profile.first_name',
                'user_profile.last_name',
                'user_sub_type','unit',
                'unit.property',
                'user_profile.user'
            ],
        },
        config:{
            filters: [
                {
                    field   : "unit][property][id]",
                    operator: (valueProp || prop_list || prop_id) ? "eq" : "nin",
                    value: valueProp ? valueProp : prop_list ? prop_list: prop_id ? prop_id : null,
                },
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : valueUnit ? valueUnit : null
                },
            ],
            pagination: { current: 1, pageSize: 1000 },
        },
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;

            let newData: any = [];
            if(dataLoad?.length > 0){
                userhunitdtDEEP = dataLoad;

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.user_profile?.first_name + " " + dataLoad[i]?.user_profile?.last_name,
                            "value": dataLoad[i]?.user_profile?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    userhunitdt = newData;
                }
            }else{
                clearcus();
            }
        },}
    });

    const staffinprop = useList<any>({
        resource: "user-type-mappings",
        metaData: {
            populate: [
                '*',
                'user_profile',
                'property'
            ]
        },
        config:{
            filters: [
                {
                    field : "property][id]",
                    // operator : valueProp ? "eq" : "nin",
                    // value : valueProp ? valueProp : null
                    operator: (valueProp || prop_list || prop_id) ? "eq" : "nin",
                    value: valueProp ? valueProp : prop_list ? prop_list: prop_id ? prop_id : null,
                },
                {
                    field: "status",
                    operator: "eq",
                    value: true
                },
                {
                    field: "role_management][custom_role_name]",
                    operator: "eq",
                    value: "Juristic"
                }
            ],
            pagination: { current: 1, pageSize: 1000 },
        },
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data?.filter((f: any) => f?.user_profile !== null);

            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.user_profile?.first_name + " " + dataLoad[i]?.user_profile?.last_name,
                            "value": dataLoad[i]?.user_profile?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    staffdt = newData;
                }
            }
        }}
    })

    const handleDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setreqDate(dateString);
        // SEND TO API FOR CAL TIMESLOT
        // callAPI(dateString);
        // http://192.168.100.151:1337/api/appointment-time-slots/slot_chk/?date=2022-06-30
        filterTimeSlot(dateString);
    };

    async function callAPI(v:any){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let resultQuery1 :any = await axios.get(API_URL + "/appointment-time-slots/slot_chk/?date=" + v , { headers: { Authorization: AuthStr } });

        let arrayID :any = resultQuery1?.data?.find_master_dateto?.map((item:any) => {
            return item.id
        })
    } 

    async function filterTimeSlot(dateString:any){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let time_slot_choice = await axios.get(API_URL + "/appointment-time-slots?date=" + dateString , { headers: { Authorization: AuthStr } });
        let dataLoad = time_slot_choice?.data?.data;
        
        if(dataLoad?.length > 0){
            let newData: any = [];
            for (let i = 0; i < dataLoad?.length; i++) {
                newData?.push(
                    {
                        "label":  dataLoad[i]?.attributes?.slot_name,
                        "value": dataLoad[i]?.id
                    }
                )
            }

            if(newData?.length > 0){
                setslotdt(newData)
            }
        }
    }

    function clearcus(){
        setcusID("")
        setcusName("");
        setcusLastName("");
        setcusPhone("");
        setcusEmail("");
    }

    function onselcus(e: any){
        let fidx: any = userhunitdtDEEP?.findIndex((f: any) => f?.user_profile?.id == Number(e));
        if(fidx !== -1){
            setcusID(userhunitdtDEEP[fidx]?.user_profile?.id)
            setcusName(userhunitdtDEEP[fidx]?.user_profile?.first_name);
            setcusLastName(userhunitdtDEEP[fidx]?.user_profile?.last_name);
            setcusPhone(userhunitdtDEEP[fidx]?.user_profile?.tel);
            setcusEmail(userhunitdtDEEP[fidx]?.user_profile?.user?.email);
        }
    }
    
    return (
        <Spin size="large" spinning={isLoading}>
            <Form 
                {...createProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    if(valuecusID !== ""){ values.user_profile = valuecusID;}

                    values.property = valueProp;
                    values.customerFirstName = valuecusName;
                    values.customerLastName = valuecusLastName;
                    values.customerPhoneNumber = valuecusPhone;
                    values.customerEmail = valuecusEmail;
                    values.appointmentDate = valuereqDate;
                    values.isConfirmed = vConfirm;
                    values.title = valueappType?.label;
                    values.appointmentType = valueappType?.value;
                    values.appointment_time_slot = valuetimeSlot;
                    // values.staff_has_properties = 2;
                    values.user_staff = valuestaffinProp;
                    values.appointment_status = valueStatus;
                    
                    values.deleteStatus = "N";
                    values.createBy = userdt?.user_profile.id;
                    return (
                        createProps?.onFinish &&
                        createProps?.onFinish(values)
                    );
                }}
            >
                <Row gutter={8}>
                    <Col span={24} className="gutter-row">
                        <Form.Item label={t("appointm-create.aptment-type")} name="appointmentType"
                            rules={[
                                {
                                    required: true, message: 'Please select appointment type'
                                },
                            ]}>
                                <Select 
                                    placeholder={t("select.sel")}
                                    options={appoindt}
                                    showSearch
                                    filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                    onSelect={(value: any, i: any) => setappType(i)}
                                />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item  label={t("appointm-create.property-title")} name="property"
                            rules={[{required: true, message: 'Please select property'},]}
                        >
                            <Select 
                                {...propertydt} 
                                placeholder={t("select.sel")} 
                                onSelect={(value: any) => {
                                    setProp(value);
                                    createProps?.form?.resetFields(["unit"]);
                                    createProps?.form?.resetFields(["customerFirstName"]);
                                    createProps?.form?.resetFields(["customerLastName"]);
                                    createProps?.form?.resetFields(["customerPhoneNumber"]);
                                    createProps?.form?.resetFields(["customerEmail"]);
                                    createProps?.form?.resetFields(["staff_has_properties"]);
                                    createProps?.form?.resetFields(["appointmentDate"]);
                                    createProps?.form?.resetFields(["appointment_time_slot"]);
                                    createProps?.form?.resetFields(["detail"]);
                                    setreqDate("");
                                    settimeSlot("");
                                    clearcus();
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        <Radio.Group 
                            value={valueReg}
                            onChange={(e: any) => {
                                setReg(e?.target?.value);
                                createProps?.form?.resetFields(["unit"]);
                                createProps?.form?.resetFields(["customerFirstName"]);
                                createProps?.form?.resetFields(["customerLastName"]);
                                createProps?.form?.resetFields(["customerPhoneNumber"]);
                                createProps?.form?.resetFields(["customerEmail"]);
                                clearcus();
                            }}
                        >
                            <Radio value={true}>{t("appointm-create.registered-cus")}</Radio>
                            <Radio value={false}>{t("appointm-create.un-regis-cus")}</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={8}>
                    {valueReg == true ?
                        <Col span={24} className="gutter-row">
                            <Row gutter={8}>
                                <Col span={24} className="gutter-row">
                                    <Form.Item  label={t("appointm-create.unit-title")} name="unit"
                                        rules={[{required: true, message: 'Please select unit'},]}
                                    >
                                        <Select 
                                            {...unitsdt} 
                                            placeholder={t("select.sel")}
                                            onSelect={(e: any) => {
                                                setUnit(e);
                                                createProps?.form?.resetFields(["customerFirstName"]);
                                                createProps?.form?.resetFields(["customerLastName"]);
                                                createProps?.form?.resetFields(["customerPhoneNumber"]);
                                                createProps?.form?.resetFields(["customerEmail"]);
                                                clearcus();
                                            }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={24} className="gutter-row">
                                    <Row gutter={8}>
                                        <Col span={4} className="gutter-row"><div style={{marginTop: "2.3rem"}}>To :</div></Col>
                                        <Col span={20} className="gutter-row">
                                            <Form.Item name="customerFirstName" label="Customer name" rules={[{required: true, message: 'Please select customer'},]}>
                                                <Select
                                                    options={userhunitdt}
                                                    placeholder={t("select.sel")} 
                                                    onSelect={(e: any)=> onselcus(e)}
                                                />
                                            </Form.Item>
                                        </Col>

                                        {valuecusID !== "" && <>
                                            <Col span={4} className="gutter-row" />
                                            <Col span={20} className="gutter-row">
                                                <Form.Item name="customerPhoneNumber" label="Phone number">
                                                    <Input placeholder="customer phone number" value={valuecusPhone !== "" ? valuecusPhone : "-"} defaultValue={valuecusPhone !== "" ? valuecusPhone : "-"} disabled/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4} className="gutter-row" />
                                            <Col span={20} className="gutter-row">
                                                <Form.Item name="customerEmail" label="Email">
                                                    <Input placeholder="customer email" value={valuecusEmail !== "" ? valuecusEmail : "-"} defaultValue={valuecusEmail !== "" ? valuecusEmail : "-"} disabled/>
                                                </Form.Item>
                                            </Col>
                                        </>}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    : valueReg == false &&
                        <Col span={24} className="gutter-row">
                            <Row gutter={8}>
                                <Col span={4} className="gutter-row"><div style={{marginTop: "2.3rem"}}>To :</div></Col>
                                <Col span={20} className="gutter-row">
                                    <Row gutter={8}>
                                        <Col span={12} className="gutter-row">
                                            <Form.Item name="customerFirstName" label="First name" rules={[{required: true, message: 'Please enter first name'}]}>
                                                <Input placeholder="Customer name" onChange={(e) => setcusName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className="gutter-row">
                                            <Form.Item name="customerLastName" label="Last name" rules={[{required: true, message: 'Please enter last name'}]}>
                                                <Input placeholder="Customer Lastname" onChange={(e) => setcusLastName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={4} className="gutter-row" />
                                <Col span={20} className="gutter-row">
                                    <Form.Item name="customerPhoneNumber" label="Phone number" rules={[{required: true, message: 'Please enter phone number'}]}>
                                        <Input placeholder="Customer phone number" onChange={(e) => setcusPhone(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={4} className="gutter-row" />
                                <Col span={20} className="gutter-row">
                                    <Form.Item name="customerEmail" label="Email" rules={[{required: true, message: 'Please enter email'}]}>
                                        <Input placeholder="Customer phone number" onChange={(e) => setcusEmail(e.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>

                <Divider />

                <Row gutter={8}>
                    <Col span={24} className="gutter-row">
                        <Form.Item 
                            name="staff_has_properties" 
                            label={t("appointm-create.staff")} 
                            rules={[{required: true, message: 'Please select Staff'}]}
                        >
                            {/* <Select placeholder={t("select.sel")} {...selStaff} /> */}
                            <Select 
                                options={staffdt}
                                placeholder={t("select.sel")}
                                // onClear={()=> {setProjectN(undefined), searchFormProps.form?.resetFields()}}
                                // onChange={(e) => setProjectN(e)}
                                // onSelect={(e:any) => setProjectN(e)}
                                // size="middle"
                                // allowClear
                                // showSearch
                                // optionFilterProp="children"
                                onSelect={(e: any)=> setstaffinProp(e)}
                                onClear={() => setstaffinProp(undefined)}
                                showSearch
                                allowClear
                                filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                // filterSort={(optionA, optionB) =>
                                //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                                // }

                                // options={staffdata}

                                // options={StaffOption?.filter((item: any, index: any, array: string | any[]) => array.indexOf(item) === index)}
                            >
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item label={t("appointm-create.aptment-date")} 
                            name="appointmentDate"
                            rules={[
                                {
                                    required: true, message: 'Please select appointment date.'
                                },
                            ]}
                        >
                            <DatePicker
                                style={{width: "100%"}}
                                onChange={(date, dateString) => handleDatePickerChange(date, dateString, 1)} 
                                disabledDate={(current) => {
                                    let customDate = dayjs().format("YYYY-MM-DD");
                                    return current && current.isBefore(customDate, 'day');
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item  
                            label={t("appointm-create.time-range")} 
                            name="appointment_time_slot"
                            rules={[
                                {
                                    required: true, message: 'Please select appointment times.'
                                },
                            ]}
                        >
                            <Select placeholder={t("select.sel")}
                                // options={vApptimeslot?.map((timeSlot:any) => ({ label: timeSlot?.attributes?.slot_name, value: timeSlot?.id }))}
                                options={timeslotdt}
                                onSelect={(value: any) => {
                                    settimeSlot(value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item  label={t("appointm-create.note")} name="detail">
                            <Input placeholder="Note"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={8}>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label={t("appointm-create.apt-stat")}
                            name="appointment_status"
                            rules={[
                                {
                                    required: true, message: 'Please select status.'
                                },
                            ]}
                        >
                                <Select placeholder={t("select.sel")}
                                    onSelect={(e: any) => {setStatus(e)}}
                                    options={[
                                        {
                                            label: "Pending",
                                            value: "Pending",
                                        },
                                        {
                                            label: "Approved",
                                            value: "Approved",
                                        },
                                        {
                                            label: "Cancelled",
                                            value: "Cancelled",
                                        }
                                    ]}
                                />
                        </Form.Item>
                    </Col>
                </Row>
            </Form> 
        </Spin>
    );
};