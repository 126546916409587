import { Breadcrumb, Card, Col, Create, Form, Input, List, Radio, RadioChangeEvent, Row, Select, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useGetIdentity, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IContactCenter, IProperties } from "interfaces";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CCreateLog } from "../../components/create_log/index";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

export const ContactCenterCreate: React.FC<IResourceComponentsProps> = () => {
    const token = localStorage.getItem(TOKEN_KEY);
    const t = useTranslate();
    const log_endpoint = "even-logs";
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { TextArea } = Input;
    const [ vStat, setStat ] = useState(true);
    const API_URL = useApiUrl();
    let data_property = user_data?.property !== null ? user_data?.property.id : null

    const [ vPropertyID, setPropertyID ] = useState<any>();
    // const [ vStaffs, setStaffs ] = useState<any>([]);
    
    // const [ vIsNewOrOld, setIsNewOrOld] = useState<boolean>();
    const [ vEmail, setEmail] = useState<any>("");
    const { formProps, saveButtonProps } = useForm<IContactCenter,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                <CCreateLog
                    endpoint={log_endpoint}
                    data={{
                        user_profile : user_data.user_profile.id,
                        log_time_stamp : Date.now(),
                        log_event : "Create",
                        menu_event : 'coupon',
                        SysPkID : recordData.data.id + ""
                    }}
                />
            },
        }
    );

    const [ count, setCount] = useState(0);
    const [ vPropCreate, setPropCreate ] = useState<any>([]);


    useEffect(() => {
        if (count < 3) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                // console.log('asd')
                getPropCreated();

            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    let resultQuery2:any = [];

    async function getPropCreated(){
        resultQuery2 = await axios.get(API_URL + "/contact-centers?populate=*" , { headers: { Authorization: 'Bearer ' + token } });
        // console.log('resultQuery2', resultQuery2)

        var result = resultQuery2?.data.data.filter((obj:any) => {
            // console.log('obj', obj)
            return obj.attributes.deleteStatus === "N"
        })

        // console.log('result', result)

        // if(resultQuery2?.data.data.length > 0){
        //     vPropCreate.pop();
        //     vPropCreate.push(resultQuery2?.data.data !== undefined ?
        //             resultQuery2?.data.data.length > 0 ? 
        //                 resultQuery2?.data.data.map((v:any)=>{
        //                     // return {value:v.id}
        //                     return v.attributes.property.data.id + ""
        //                 })
        //             : <></>
        //         : <></>)
        //         setPropCreate(vPropCreate);
        // }
        if(result.length > 0){
            vPropCreate.pop();
            vPropCreate.push(result !== undefined ?
                    result.length > 0 ? 
                        result.map((v:any)=>{
                            // return {value:v.id}
                            return v.attributes.property.data.id + ""
                        })
                    : <></>
                : <></>)
                setPropCreate(vPropCreate);
        }

        setPropCreate(vPropCreate.filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i))

    }

    let initF : any;
    initF = {
        field       : 'deleteStatus',
        operator    : 'contains',
        value       :  'N',
    };

    // const { selectProps: PropertySelectProps } = useSelect<IProperties>({
    //     resource    : "properties",
    //     optionLabel : "property_name",
    //     optionValue : "id",
    //     filters: [
    //         initF,
    //         {
    //             field : 'id',
    //             operator : 'nin',
    //             // value : 1
    //             // value :  ["1","2","3","7"],
    //             value :  vPropCreate[0],
    //         },
    //     ]
    // });

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null 
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data
            if(dataSet?.length == 1){
                setPropertyID(dataSet[0]?.id)
                formProps?.form?.setFieldsValue({
                    property: dataSet[0]?.id
                })
            }
        },}
    });
    // const [ vCondition, setCondition ] = useState(false);
    // const onChangeRadioNew = (e: RadioChangeEvent) => {
    //     setIsNewOrOld(e.target.value);
    //     // console.log('vRaPrivCoupon', vRaPrivCoupon)
    // };

    // function onChangePropertyID(va:any){
    //     vPropertyID.pop();
    //     vPropertyID.push(va);
    //     setPropertyID(vPropertyID);
    // };

    // function onChangeStaff(va:any){
    //     vStaffs.pop();
    //     vStaffs.push(va);
    //     setStaffs(vStaffs);
    // };

    const optionsRadioStat = [
        { label: t("menumgn-create.stat1"), value: true },
        { label: t("menumgn-create.stat2"), value: false },
    ];

    const onChangeStat = ({ target: { value } }: RadioChangeEvent) => {
        // true = active
        // false = inactive
        console.log('radio stats checked', value);
        setStat(value);
    };

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
    }

    return (
    <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            // title={t("banner-mgn.title-create")}
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("contact-center.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("contact-center.title-create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id
                    // values.default_contact = vCondition
                    values.subject = "Appointment"
                    values.property = vPropertyID;
                    values.deleteStatus = vStat == true ? "N" : "Y";
                    values.email = vEmail
                    // if(vIsNewOrOld == false){
                    //     values.contact_cc = vStaffs[0]
                    // }

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{marginBottom:'2%'}}></div>
                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Subject : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item 
                                        name="subject"
                                    >
                                        <Input value={"Appointment"} defaultValue="Appointment" disabled/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Property : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        // name="privilege_properties"
                                        name="property"
                                        rules={[
                                            {
                                                required: true, message: 'Please select property.'
                                            },
                                        ]}
                                    >
                                        <Select 
                                            // size="small"
                                            style={{width:"100%"}}
                                            // mode="multiple" 
                                            placeholder={t("select.sel")}
                                            {...propertySelectProps}
                                            onChange={(e)=> setPropertyID(e)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Email : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="email">
                                        <TextArea style={{height: "100px", maxHeight: "80px"}} placeholder="seperate email by comma ex. testmail@mail.com,testmail2@mail.com" onChange={(e) => onChangeEmail(e)} />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Status : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="active_status" style={{width: "100%"}} initialValue={true}
                                        // label={t("menumgn-create.status")}
                                        label=""
                                        rules={[
                                            {
                                                required: true, message: 'Please select status.'
                                            },
                                        ]}>
                                            {/* <div>Status : </div> */}
                                            {/* <Input style={{width: "95%"}}/> */}
                                            <Radio.Group
                                                options={optionsRadioStat}
                                                onChange={onChangeStat}
                                                // value={vValueNoti}
                                                value={vStat}
                                                defaultValue={true}
                                            />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};