/* eslint-disable */
import { Card, Col, Edit, Form, Input, Row, Upload, useForm, Select, useSelect, List, Breadcrumb, UploadFile, RcFile, message, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IEventlog, IProjectinfo, IProjectType } from "interfaces";
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../constants";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-mde/lib/styles/css/react-mde-all.css";
import GoogleMapReact from "google-map-react";
// import { MapMarker } from "pages/map";

// import Moment from 'moment';
import "./css/style.css";

export const ProjectinfoEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { Dragger } = Upload;
    const [ vprojdesc, setprojdesc] = useState<any>("");
    // const AnyReactComponent = ({ Xtext }) => <div>{Xtext}</div>;

    const ProjectInfoMarker = ({text} : any) => (
        <div className="pin" 
            // style={{
            // color: 'white', 
            // background: 'red',
            // padding: '15px 10px',
            // display: 'inline-flex',
            // textAlign: 'center',
            // alignItems: 'center',
            // justifyContent: 'center',
            // //   borderRadius: '100%',
            // //   transform: 'translate(-50%, -50%)'
            // }}
        >
          {text}
        </div>
      );
      

    //   let iconMarker = new window.google.maps.MarkerImage(
    //     "https://lh3.googleusercontent.com/bECXZ2YW3j0yIEBVo92ECVqlnlbX9ldYNGrCe0Kr4VGPq-vJ9Xncwvl16uvosukVXPfV=w300",
    //     null, /* size is determined at runtime */
    //     null, /* origin is 0,0 */
    //     null, /* anchor is bottom center of the scaled image */
    //     new window.google.maps.Size(32, 32)
    // );
      
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    // const { formProps, saveButtonProps } = useForm<IProjectinfo,HttpError,{}>();
    const { form,formProps, saveButtonProps }    = useForm<IProjectinfo>({redirect:"list"});
    const { queryResult } = useShow<IProjectinfo>({metaData: {
        // populate: '*'
        populate: ['project_type','Project_Image'],
        }, });
    const { data } = queryResult;
    // const record = data?.data;
    let recordData:any = []
    recordData = data?.data? data?.data : [];
    const { Option } = Select;
    // const [vUnitType, setUnitType]  = useState(recordData.unit_type ? recordData.unit_type.id : '-');

    // const { queryResult } = useShow<IProjectinfo>();
    // const { data } = queryResult;
    // const record = data?.data;

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
        "write",
    );
    const API_URL = useApiUrl();
    // const format  = 'HH:mm';
    // const format                            = 'HH:mm';
    // const { ...uploadProps_coverpix}    = useStrapiUpload({ maxCount: 5, });
    // const { ...uploadProps_pixlist}     = useStrapiUpload({ maxCount: 5, });
    // const { replace} = useNavigation();
    // const [count, setCount]             = useState(0)
    // const [vFileList, setFileList]      = useState([])
    const [vDragger, setDragger]        = useState<any>([]);
    const [vLat, setLat]                = useState(recordData.lat ? recordData.lat : 13.0000);
    const [vLng, setLng]                = useState(recordData.lng ? recordData.lng : 100.0000);
    // const [vProjectType, setProjectType]      = useState(recordData.project_type !== undefined && recordData.project_type !== null ? recordData.project_type.id : '-');
    const { selectProps: SelProjectType } = useSelect<IProjectType>({
        resource: "project-types",
        optionLabel: "pt_name",
        optionValue: "id",
        // filters: [initF]
    });

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
            
        }
    }
    const [position, setPosition] = useState({
        lat: recordData.lat ? parseFloat(recordData.lat) : parseFloat("13.0000"), 
        lng: recordData.lng ? parseFloat(recordData.lng) : parseFloat("100.0000")
    });
    const [ vProjectType, setProjectType ]  = useState<any>([]);

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "project-infos",
        metaData: {
            populate: [
                '*',
                'createBy',
                'updateBy',
                'project_type',
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();

          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        setLat(recordData.lat ? recordData.lat : 13.0000);
        setLng(recordData.lng ? recordData.lng : 100.0000);
        setprojdesc(recordData?.Project_Description)

        form.setFieldsValue({
            project_type:
                recordData?.project_type !== undefined ?
                    recordData.project_type.pt_name
                : null,
        });

        setPosition({
            lat: recordData.lat ? parseFloat(recordData.lat) : parseFloat("13.0000"), 
            lng: recordData.lng ? parseFloat(recordData.lng) : parseFloat("100.0000")
        })
        
        const fileList: UploadFile[] = 
            // recordData.Project_Image !== undefined ?
            recordData.Project_Image ?
                recordData.Project_Image.map((v:any)=>(
                    {
                        uid: String(v.id),
                        name: v.name,
                        status: "done",
                        url:
                            v.url,
                        thumbUrl:
                            v.url
                    }
                )) 
            : null
        ;

        setDragger({
            name: 'files',
            multiple: true,
            defaultFileList: [...fileList],
        })
        form.resetFields(["Project_Image"]);        
    }


    // useEffect(() => {
    //     if (count < 4) {
    //       const interval = setInterval(() => {
    //         setCount(prev => prev + 1)
    //         setLat(recordData.lat ? recordData.lat : 13.0000);
    //         setLng(recordData.lng ? recordData.lng : 100.0000);

    //         form.setFieldsValue({
    //             project_type:
    //                 recordData?.project_type !== undefined ?
    //                     recordData.project_type.pt_name
    //                 : null,
    //         });

    //         setPosition({
    //             lat: recordData.lat ? parseFloat(recordData.lat) : parseFloat("13.0000"), 
    //             lng: recordData.lng ? parseFloat(recordData.lng) : parseFloat("100.0000")
    //         })
            
    //         const fileList: UploadFile[] = 
    //             // recordData.Project_Image !== undefined ?
    //             recordData.Project_Image ?
    //                 recordData.Project_Image.map((v:any)=>(
    //                     {
    //                         uid: String(v.id),
    //                         name: v.name,
    //                         status: "done",
    //                         url:
    //                             v.url,
    //                         thumbUrl:
    //                             v.url
    //                     }
    //                 )) 
    //             : null
    //                 // (
    //                 //     {
    //                 //         uid: "-1",
    //                 //         name: "xxx.png",
    //                 //         status: "error"
    //                 //     } 
    //                 // )
    //           ;

    //         setDragger({
    //             name: 'files',
    //             multiple: true,
    //             defaultFileList: [...fileList],
    //         })

    //         // form.resetFields();
    //         form.resetFields(["Project_Image"]);
            
    //         // form.setFieldsValue({
    //         //     project_type:
    //         //         recordData?.project_type !== undefined ?
    //         //             recordData.project_type.pt_name
    //         //         : null,   
    //         // });

    //       }, 1000)
    //       return () => clearInterval(interval)
    //     }
    // }, [count])

    // console.log(vProject_Image)


    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";

    function updateUserUpdatedBy(){
        // mutate({
        //     resource    : end_point,
        //     id          : queryResult.data?.data.id+"",
        //     // values      : vCurUserUpdatedBy,
        //     values: { user_updated_by: user?.id, deleteStatus: 'N' },
        // });
 
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'project_info',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
        // replace("")
        // console.log("test event");
        // window.history.back()
        // window.location.reload(history.back()); 
    }

    const defaultProps = {
        center: {
            lat: 13.6619, 
            lng: 100.5868
        },
        zoom: 9,
    };
    
    // const handleApiLoaded = (map: any, maps: any) => {
    //     // use map and maps objects
    //     console.log('map ',map);
    //     console.log('maps ',maps);

    //     map.center(vLat, vLng);
    // };

    function onChangeProjectType(va:any){
        // console.log(va)
        vProjectType.pop();
        vProjectType.push(va);
        setProjectType(vProjectType);
    };

    // const fileList: UploadFile[] = [

    //     recordData.Project_Image ?
    //         recordData.Project_Image.map((v:any)=>{
    //             // console.log(v)
    //             return {
    //                 uid: v.id,
    //                 name: v.name,
    //                 status: "done",
    //                 url:
    //                     v.url,
    //                 thumbUrl:
    //                     v.url
    //             }
    //         })
    //     :
    //         {
    //             uid: "-2",
    //             name: "xxxxx.png",
    //             status: "error"
    //         }
    // ];

    const fileList: UploadFile[] = [
            {
                uid: "-1",
                name: "xxx.png",
                status: "done",
                url:
                "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
                thumbUrl:
                "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            },
          {
              uid: "2",
              name: "x22xx.png",
              status: "done",
              url:
                "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
              thumbUrl:
                "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
            },
          {
            uid: "-2",
            name: "yyy.png",
            status: "error"
          }
    ];

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              const body = new FormData();
              loader.file.then((file: string | Blob) => {
                // console.log('fileeeeeeeeeeeee', file)
                body.append("files", file);
                // let headers = new Headers();
                // headers.append("Origin", "http://localhost:3000");
                fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                  method: "post",
                  body: body
                  // mode: "no-cors"
                })
                  .then((res) => res.json())
                  .then((res) => {
                    // console.log('resssssssssssssssss', res)
                    resolve({
                    //   default: `${API_URL}/${res.filename}`
                      default: res[0].url
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            });
          }
        };
    }

    // const draggerProps = {
    //     name: 'files',
    //     multiple: true,
    //     defaultFileList: [...fileList],
    // };

    // console.log(vDragger);
    // console.log(draggerProps);
    // console.log('edit_projectinfo', recordData)

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
      };

    return (
     <Edit saveButtonProps={saveButtonProps} isLoading={isLoading} pageHeaderProps={{extra: <></>}}> 

            <List 
                title={t("projects-info-edit.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project-info.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("projects-info-edit.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>, 
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    updateUserUpdatedBy()

                    // address_amphure: IUserAmpure
                    // address_district: IUserDistrict
                    // address_provice: IUserProvice
                

                    //values.photo_list   = vphoto_list;
                    values.updateBy = user_data.user_profile.id
                    values.updatedBy = user_data.user_profile.id
                    values.project_type =  vProjectType[0]
                    values.Project_Description = vprojdesc
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                       
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={18}>
                                    <Form.Item  label={t("project-info.name")} name="Project_Name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project name.'
                                            },
                                        ]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    <Form.Item label={t("projects-type.title")} name="project_type"
                                        rules={[
                                            {
                                                required: true, message: 'Please select project types.'
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")} {...SelProjectType} onChange={onChangeProjectType}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label={t("project-info.des")}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}>

                                {/* <ReactMde
                                    selectedTab={selectedTab}
                                    onTabChange={setSelectedTab}
                                    generateMarkdownPreview={(markdown: string) =>
                                        Promise.resolve(
                                            <ReactMarkdown>{markdown}</ReactMarkdown>,
                                        )
                                    }
                                /> */}
                                <CKEditor
                                    // name="detail"
                                    editor={ClassicEditor}
                                    data={recordData.Project_Description? recordData?.Project_Description : ''}
                                    config={{
                                        alignment: {
                                            options: [ 'left', 'right' ]
                                        },
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "link",
                                            "bulletedList",
                                            "numberedList",
                                            "blockQuote",
                                            'alignment',
                                            "|",
                                            "imageTextAlternative",
                                            "imageUpload",
                                            "imageStyle:full",
                                            "imageStyle:side",
                                        ],
                                        extraPlugins: [uploadPlugin],
                                        mediaEmbed: {
                                            // configuration...
                                            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                        },
                                        image: {
                                            toolbar: ['']
                                        }
                                    }}
                                    
                                    onInit={(editor:any) => {
                                        // console.log('editor',editor)
                                    }}
                                    onChange={(event:any, editor:any) => {
                                        const data = editor.getData();
                                        setprojdesc(data);
                                        // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
                                    }}
                                    onBlur={(editor:any) => {
                                        // console.log("Blur.", editor);
                                    }}
                                    onFocus={(editor:any) => {
                                        // console.log("Focus.", editor);
                                    }}

                                />
                            </Form.Item>

                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    {/* <Form.Item  label="Project Tag" name="project-tag"> */}
                                    <Form.Item  label={t("project-info.status")} name="Project_Tag"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project status.'
                                            },
                                        ]}>
                                        <Select placeholder={t("select.sel")}>
                                            <Option value="New Project">New Project</Option>
                                            <Option value="In Progress">In Progress</Option>
                                            <Option value="Ready to Move In">Ready to Move In</Option>
                                            <Option value="Sold out">Sold Out</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* test */}
                                <Col className="gutter-row" span={12}>
                                    <Input.Group compact>
                                        <Form.Item  label={t("project-info.phone")} name="project_phone" style={{width: "50%"}}
                                        
                                            rules={[
                                                {
                                                    // required: true, message: 'please enter project tel.'
                                                },
                                            ]}>
                                            <Input style={{width: "95%"}}
                                                minLength={9}
                                                maxLength={10}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}/>
                                        </Form.Item>

                                        <Form.Item  label={t("project-info.line")} name="project_line" style={{width: "50%"}}
                                        
                                            rules={[
                                                {
                                                    // required: true, message: 'please enter project line.'
                                                },
                                            ]}>
                                            <Input />
                                        </Form.Item>
                                    </Input.Group>
                                </Col>
                            </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                    <Form.Item  label={t("project-info.link")} name="Project_Link"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project link.'
                                            },
                                        ]}>
                                        <Input />
                                    </Form.Item>
                            </Col>

                            {/* <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-info.local")} name="project_location"
                                    rules={[
                                        {
                                            required: true, message: 'please enter project location.'
                                         },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col> */}

                            <Col className="gutter-row" span={12}>
                                <Input.Group compact>
                                    <Form.Item  label={t("project-info.lat")} name="lat" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project location (latitude)'
                                            },
                                        ]}>
                                            <Input style={{width: "95%"}} 
                                            onKeyPress={(event) => {
                                                if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            />
                                    </Form.Item>

                                    <Form.Item  label={t("project-info.lng")} name="lng" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please enter project location (longitude)'
                                            },
                                        ]}>
                                            <Input  
                                            onKeyPress={(event) => {
                                                if (!/^[0-9]*\.?[0-9]*$/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }} 
                                            />
                                    </Form.Item>
                                </Input.Group>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col className="gutter-row" span={12}>
                                <Form.Item label={t("staff-profiles.status")} name="deleteStatus">
                                <Select placeholder={t("select.sel")}
                                    style={{ width: '94%' }}
                                    onSelect={(value: any) => {
                                        console.log(value);
                                            // setStatus(value);
                                        }}
                                    options={[
                                        {
                                            label: "ACTIVE",
                                            value: "N",
                                        },
                                        {
                                            label: "INACTIVE",
                                            value: "Y",
                                        }
                                    ]}
                                />
                                </Form.Item>
                            </Col> */}

                            <Col className="gutter-row" span={12}>
                                <Form.Item label={t("staff-profiles.status")} name="status"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter project status.'
                                        },
                                    ]}>
                                <Select placeholder={t("select.sel")}
                                    style={{ width: '97%' }}
                                    onSelect={(value: any) => {
                                        // console.log(value);
                                            // setStatus(value);
                                        }}
                                    options={[
                                        {
                                            label: "Public",
                                            value: "ACTIVE",
                                        },
                                        {
                                            label: "Draft",
                                            value: "INACTIVE",
                                        }
                                    ]}
                                />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Form.Item
                            name="Project_Image"
                            label={t("project-info.img")}
                            // valuePropName="fileList"
                            // getValueProps={(data) => getValueProps(data, API_URL)}
                        >
 
                        {/* <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                {...uploadProps_coverpix}
                                onChange={fileChange}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                        </Upload.Dragger> */}



                        <Dragger style={{borderRadius: "10px", width: '100%'}} 
                            // {...draggerProps}
                            {...vDragger}
                            name="files"
                            action={`${API_URL}/upload`}
                            headers={{
                                Authorization: `Bearer ${localStorage.getItem(
                                    TOKEN_KEY,
                                )}`,
                            }}
                            listType="picture"
                            multiple
                            // {...uploadProps_coverpix}
                            // maxCount={5}
                            maxCount={1}
                            onChange={fileChange}
                            beforeUpload={beforeUpload}
                        >
                            <p className="ant-upload-text">{t("upload")}</p>
                        </Dragger>
                        {/* <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p> */}
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 1340*783 PNG, JPG Maximum size 5MB</p>
                    </Card>

                    {/* <Card style={{backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: "8%" }}><span className="title_panel">{t("projects-type.title")}</span></div>
                        <Form.Item name="project_type"
                        >
                            <Select 
                                placeholder={t("select.sel")} {...SelProjectType} onChange={onChangeProjectType}
                            />
                        </Form.Item>
                    </Card> */}

                    <Card style={{backgroundColor: "#fff", marginBottom: "2%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        {/* <>xxxxxxxx</> */}
                        <div style={{ height: '25vh', width: '100%' }}>
                            {/* {vLat}
                            {", "}
                            {vLng} */}
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: "AIzaSyCv1oqlHy7gI3e5-DNe3rYyenP1KdPp1xk"//process.env.REACT_APP_MAP_ID,
                                }}
                                // defaultCenter={defaultProps.center}
                                defaultCenter={{lat: vLat, lng : vLng}}
                                defaultZoom={defaultProps.zoom}
                                // center={{lat: vLat, lng : vLng}}
                                center={position}
                                // zoom={9}
                                // onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                                yesIWantToUseGoogleMapApiInternals
                                // ref={(map) => map && map.panTo({lat: 25.0112183,lng: 121.52067570000001})}
                                // onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, vLat, vLng)}

                            >
                                {/* <MapMarker
                                    key={0}
                                    lat="13.65108"
                                    lng="100.63180"
                                >
                                </MapMarker> */}

                                <ProjectInfoMarker
                                    // lat={13.65108}
                                    // lng={100.63180}
                                    lat={vLat}
                                    lng={vLng}
                                    // text="My Marker"
                                />
                            </GoogleMapReact>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>
    );
};
