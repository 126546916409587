/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { RepairHodDoneJobList } from "./done_job";
import { RepairHodInprogressJobList } from "./inprogress_job";
import { RepairHodJobList } from "./job_list";
import { RepairHodTodayJobList } from "./today_job";
import { RepairHodWaitApproveJobList } from "./wait_approve_job";
import axios from "axios";
import { majorCareUrl } from "../../constants";
import dayjs from "dayjs";



export const RepairMajorHod: React.FC<IResourceComponentsProps> = () => {
    // const tabActive = localStorage.getItem("keyTabHCUMaster");
    const [ Tab, SetTab] = useState<any>();
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    const { TabPane } = Tabs;
    const tabJobActive = localStorage.getItem("keyTabJob");

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabJob", v)
    }

    const [countTodayJob, setCountTodayJob] = useState<any>();
    const [countRepair, setCountRepair] = useState<any>()
    const [countInpro, setCountInpro] = useState<any>()
    const [countWaitAppJob, setCountWaitAppJob] = useState<any>()
    const [countDone, setCountDone] = useState<any>()

    const getData = async () => {
        
        // TODAY JOB
        axios.get(
            majorCareUrl + `/majorcare/repairJobDetail/findMjcJobCount?stat=649859f8515b80b78fe13b88,64985a35515b80b78fe13b90,64985a3c515b80b78fe13b92,64985a01515b80b78fe13b8a&page=1&limit=500&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            const today = dayjs().format("YYYY-MM-DD");
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }
            const xx = dataLoad.filter((e:any) => e.job_obj[0]?.appoint_start?.startsWith(today));
            setCountTodayJob(xx?.length)
        }).catch((error) => {
            console.log('error', error)
        });


        // JOB LIST
        axios.get(
            majorCareUrl + `/majorcare/repairJobDetail/findMjcJobCount?stat=649859f8515b80b78fe13b88,64985a35515b80b78fe13b90&page=1&limit=500&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }
            setCountRepair(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });

        // INPROGRESS JOB
        axios.get(
            majorCareUrl + `/majorcare/repairJobDetail/findMjcJobCount?stat=64985a3c515b80b78fe13b92,64a7bf23785d4236922eaff6,64985a01515b80b78fe13b8a&page=1&limit=500&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }
            setCountInpro(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });

        let dataLoadAll: any
        axios.get(
            majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByStat?stat=64985bbe515b80b78fe13bb6&page=1&limit=500`, { headers: {  } })
        .then((res) => {
            dataLoadAll = res?.data;
            //console.log("dataLoadAll",dataLoadAll)
        }).catch((error) => {
            console.log('error', error)
        });

        // WAIT APPROVE JOB
        axios.get(
            // majorCareUrl + `/majorcare/repairJobDetail/findMjcJobCount?stat=64985bbe515b80b78fe13bb6&page=1&limit=5000&mode=1`, { headers: {  } })
            majorCareUrl + `/majorcare/repairJobDetail/findMjcJobCount?stat=64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64985b3b515b80b78fe13bac,64985bcf515b80b78fe13bba&page=1&limit=5000&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            // console.log('dataLoad list', dataLoad)
            // if(prop_list !== undefined){
            //     //console.log('case 1')
            //     dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            //     //console.log('case 2_dataMain',dataLoad)
                
            // }else if(prop_id !== null){
            //     //console.log('case 2')
            //     dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            //     //console.log('case 2_dataMain',dataLoad)
            // }

            if(prop_list !== undefined && prop_id == null){
                // console.log('case 1')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id) && item.job_obj[0].hod_approve === true);
                // setCountWaitAppJob(!!dataLoad && dataLoad !== [] && dataLoad !== null ? dataLoad?.length : 0)
                setCountWaitAppJob(!!dataLoad && dataLoad !== null ? dataLoad?.length : 0)
            }
            if(prop_id !== null && !prop_list){
                // console.log('case 2')
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id) && e.job_obj[0].hod_approve === true)
                // setCountWaitAppJob(!!dataLoad && dataLoad !== [] && dataLoad !== null ? dataLoad?.length : 0)
                setCountWaitAppJob(!!dataLoad && dataLoad !== null ? dataLoad?.length : 0)
            }
            if (prop_id !== null && !!prop_list) {
                // console.log('case 3')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id) && item.job_obj[0].hod_approve === true);
                // setCountWaitAppJob(!!dataLoad && dataLoad !== [] && dataLoad !== null ? dataLoad?.length : 0)
                setCountWaitAppJob(!!dataLoad && dataLoad !== null ? dataLoad?.length : 0)
            }
            if (!prop_list && prop_id == null) {
                // console.log('case 4')
                // setCountWaitAppJob(!!dataLoadAll && dataLoadAll !== [] && dataLoadAll !== null ? dataLoadAll?.length : 0)
                dataLoad = dataLoad.filter((item:any) => item.job_obj[0].hod_approve === true);

                // setCountWaitAppJob(!!dataLoadAll && dataLoadAll !== null ? dataLoadAll?.length : 0)
                setCountWaitAppJob(dataLoad?.length)
            }
            
        }).catch((error) => {
            console.log('error', error)
        });

        // DONE JOB
        axios.get(
            majorCareUrl + `/majorcare/repairJobDetail/findMjcJobCount?stat=64985bc6515b80b78fe13bb8,64985bcf515b80b78fe13bba&page=1&limit=101&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }
            setCountDone(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });

        
        // let res = await axios.get(
        //     majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId`,
        //     { headers: {  } }).then((res) => {
        //         let dataLoad: any = res?.data;
        //         //console.log('dataLoad', dataLoad)

        //         const today = new Date().toISOString().split('T')[0];
        //         let keepToday = dataLoad.filter((e:any) => (
        //         e.job_obj[0]?.status_id === "649859f8515b80b78fe13b88" || 
        //         e.job_obj[0]?.status_id === "64985a35515b80b78fe13b90" || 
        //         e.job_obj[0]?.status_id === "64985a3c515b80b78fe13b92" || 
        //         e.job_obj[0]?.status_id === "64985a01515b80b78fe13b8a" ))
        //         // console.log("keepTO",keepToday)
        //         setCountTodayJob(keepToday)

        //         let keepRepair = dataLoad.filter((e:any) => (
        //         e.job_obj[0]?.status_id === "649859f8515b80b78fe13b88" || 
        //         e.job_obj[0]?.status_id === "64985a35515b80b78fe13b90"))
        //         //console.log("keepRepair",keepRepair)
        //         setCountRepair(keepRepair)

        //         let keepInPro = dataLoad.filter((e:any) => ( 
        //         e.job_obj[0]?.status_id === "64985a3c515b80b78fe13b92" || 
        //         e.job_obj[0]?.status_id === "64a7bf23785d4236922eaff6" || 
        //         e.job_obj[0]?.status_id === "64985a01515b80b78fe13b8a"))
        //         //console.log("keepInPro",keepInPro)
        //         setCountInpro(keepInPro)

        //         let keepWaitAppJob = dataLoad.filter((e:any) => ( e.job_obj[0]?.hod_approve === true ))
        //         //console.log("keepWaitAppJob",keepWaitAppJob)
        //         setCountWaitAppJob(keepWaitAppJob)

        //         let keepDone = dataLoad.filter((e:any) => ( 
        //         e.job_obj[0]?.status_id === "64985bc6515b80b78fe13bb8" ||
        //         e.job_obj[0]?.status_id === "64985bcf515b80b78fe13bba" ))
        //         //console.log("keepDone",keepDone)
        //         setCountDone(keepDone)
        //     }
        // )
    }

    useEffect(() => {
        getData()
    }, [])
    

    return <>
        <List 
            // title={t("hcujob.title")}
            title="Major แจ้งซ่อม (สำหรับหัวหน้างาน)"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major care แจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcujob.title")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Major แจ้งซ่อม (สำหรับหัวหน้างาน)</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabJobActive ? tabJobActive + "" : "Today"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >
                        
                        <TabPane
                            key={"Today"}
                            tab={
                                <span>
                                    {"ตารางงานวันนี้"}
                                    <Badge showZero count={countTodayJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairHodTodayJobList />
                        </TabPane>

                        <TabPane
                            key={"JobList"}
                            tab={
                                <span>
                                    {"รายการแจ้งซ่อม"}
                                    <Badge showZero count={countRepair} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairHodJobList />
                        </TabPane>


                        <TabPane
                            key={"InProgress"}
                            tab={
                                <span>
                                    {"ระหว่างดำเนินการ"}
                                    <Badge showZero count={countInpro} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairHodInprogressJobList />
                        </TabPane>

                        <TabPane
                            key={"WaitApprove"}
                            tab={
                                <span>
                                    {"รอหัวหน้างานอนุมัติงานซ่อม"}
                                    <Badge showZero count={countWaitAppJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairHodWaitApproveJobList />
                        </TabPane>


                        <TabPane
                            key={"Done"}
                            tab={
                                <span>
                                    {"เสร็จสิ้น"}
                                    <Badge showZero count={countDone} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairHodDoneJobList />
                        </TabPane>
                       

                    </Tabs>
                </Form.Item>
            </Form>
           
        </List>

        
    </>
}