/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, Divider } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { useEffect, useState } from "react";
import dayjs from "dayjs";

import axios from "axios";
import { majorCareUrl } from "../../../constants";

export const MjcNewJobEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    // const userQuery = useGetIdentity(); // Get User Profile
    // const {data : user_data ,isLoading} = userQuery;

    const t = useTranslate();
    // const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    // const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]

    // console.log('item_id', item_id)

    const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    // Data ส่วนของ รายละเอียดการแจ้ง
    const [JobNo, SetJobNo] = useState<any>();
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [Unit, SetUnit] = useState<any>();
    const [Customer, SetCustomer] = useState<any>();
    const [Type, SetType] = useState<any>();


    const getDataK = async ()=>{
        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjob?id=648ab8ca326b1b890c5cf5ec
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findOneHomecheckjob?id=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad', dataLoad[0])
                // console.log('Type', dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes)

                SetJobNo(dataLoad[0].job_id ? dataLoad[0].job_id : '-')
                SetCreateDate(dataLoad[0].create_date)
                SetProperty(dataLoad[0].property_obj[0].property_name)
                SetCustomer(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.first_name + ' ' + dataLoad[0].request_by.attributes.last_name : '-')
                SetTransferDate(dataLoad[0] ? dataLoad[0].request_by ? dataLoad[0].request_by.unitDetail ? dayjs(dataLoad[0].request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-');
                // SetType(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-')
                SetType(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.user_sub_types.data.length > 0 ? dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-' : '-')
                
                SetUnit(dataLoad[0] ? dataLoad[0].unit_obj ? dataLoad[0].unit_obj[0].unit_name : '-' : '-')
            
            });
    }

    // useEffect(() => {
    //     if (countInterval < 3) {
    //         const interval = setInterval(() => {
    //             setCountInterval(prev => prev + 1);
    //             if(countInterval === 1){
    //                 // console.log('call')
    //                 getDataK();
    //             }
    //             // console.log('res_data', res_data);
    //             // console.log('property', Property);
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();
    // console.log('checkedSms', checkedSms);

    // async function createAssignInspec(values:any) {
    //     replace("/majorcare/setup-hcu")
    // }
  
    // console.log('Property', Property)
    // console.log('Unit', Unit)
    // console.log('Customer', Customer)
    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={
            {
                title: t("mjc-job.title-new"),
                extra : <></>
            }
        }
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("service&repair.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("mjc-job.list")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    replace("/majorcare/setup-hcu")

                }}
            >

                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.detail")}
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label={t("mjc-job.job-no")}
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={JobNo} defaultValue={JobNo} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.property")}
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Input value={Property} defaultValue={Property} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.customer")}
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Customer} defaultValue={Customer} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label={t("mjc-job.created-date")}
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(CreateDate).format("DD/MM/YY HH:mm")} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.unit-no")}
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Unit} defaultValue={Unit} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.customer-type")}
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Type} defaultValue={Type} disabled/>
                                </Form.Item>

                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label={t("mjc-job.trans-date")}
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>   
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
    </Edit>   
    );
};