/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Divider, Switch, Input, Typography, useSelect, InputNumber, Upload, RcFile, message, Edit, Button, Icons, UploadFile, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL, ROCKET_URL, TOKEN_KEY } from "../../../constants";

import axios from "axios";
import { getValueProps, useStrapiUpload } from "@pankod/refine-strapi-v4";
import Dragger from "antd/lib/upload/Dragger";

export const RepairTermEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    const token = localStorage.getItem(TOKEN_KEY);
    const { replace, list} = useNavigation();
    const [form] = Form.useForm()

    const tabTermActive = localStorage.getItem("keyTabTermRepair");
    console.log('tabTermActive', tabTermActive)

    const userQuery = useGetIdentity(); // Get User Profile
    // const {data : user_data ,isLoading} = userQuery;
    // const { mutate: CreateLog } = useCreate<IEventlog>();
    // const { mutate: create } = useCreate<any>();
    // const { TextArea } = Input;
    // const { Text } = Typography;
    const t = useTranslate();
    const API_URL = useApiUrl();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);

    // const { Option } = Select;
    // const { confirm } = Modal;
    // const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    // const log_endpoint = "even-logs";
    const [text, setText] = useState('');
    const [vTacTh, setTacTh] = useState<any>();
    const [vTacEn, setTacEn] = useState<any>();
    const [vInfoTh, setInfoTh] = useState<any>();
    const [vInfoEn, setInfoEn] = useState<any>();
    const [res_data, set_res_data] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);
    const [vImgEn, setImgEn] = useState<any>([]);
    const [vImgTh, setImgTh] = useState<any>([]);
    const [Topic, SetTopic] = useState<any>();

    const [RequireTh, SetRequireTh] = useState<any>(false);
    const [RequireEn, SetRequireEn] = useState<any>(false);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let property_id = id[0]
    let setting_id = id[1]
    
    const getDataK = async ()=>{
        console.log('tabTermActive', tabTermActive)
        // http://192.168.100.129:1337/api/home-check-up-settings/find_setting_by_id?property=2&type=Notification&get_all_setting=false&setting_id=10
        // let res_data = await axios.get(API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=Notification&get_all_setting=false&setting_id=${setting_id}`, { headers: { Authorization: AuthStr } });
        let res_data = await axios.get(
            // API_URL + `/repair-settings/find_setting_by_id?property=${property_id}&type=TAC&get_all_setting=false&setting_id=${setting_id}`, 
            API_URL + `/repair-settings/find_setting_by_id?property=${property_id}&type=${tabTermActive !== null ? tabTermActive : "Information"}&get_all_setting=false&setting_id=${setting_id}`, 
            { headers: { Authorization: AuthStr } }).then((res) => {
                console.log('res data', res?.data)

                set_res_data(res?.data) 
                // SetPropertyName(res?.data.property.property_name)
                SetProperty(res?.data.property.id)
                SetTopic(res?.data?.rp_setting[0].tac_th)

                if(tabTermActive == "TAC"){
                    const fileList_TH:any[] =
                        [
                            {
                                id: res?.data?.rp_setting[0]?.tac_content_th[0].id,
                                uid: String(res?.data?.rp_setting[0]?.tac_content_th[0].id),
                                name: res?.data?.rp_setting[0]?.tac_content_th[0].name,
                                status: "done",
                                url: res?.data?.rp_setting[0]?.tac_content_th[0].url,
                                thumbUrl: res?.data?.rp_setting[0]?.tac_content_th[0].url
                            }
                        ];
    
                        setImgTh({
                            name: 'files',
                            multiple: true,
                            // defaultFileList: [...fileList3],
                            defaultFileList: [...fileList_TH],
                        })
                        form.resetFields(["pic"]);
    
                    const fileList_EN:any[] =
                        [    
                            {
                                id: res?.data?.rp_setting[0]?.tac_content_en[0].id,
                                uid: String(res?.data?.rp_setting[0]?.tac_content_en[0].id),
                                name: res?.data?.rp_setting[0]?.tac_content_en[0].name,
                                status: "done",
                                url: res?.data?.rp_setting[0]?.tac_content_en[0].url,
                                thumbUrl: res?.data?.rp_setting[0]?.tac_content_en[0].url
                            }
                        ];
    
                        setImgEn({
                            name: 'files',
                            multiple: true,
                            // defaultFileList: [...fileList3],
                            defaultFileList: [...fileList_EN],
    
                        })
                        form.resetFields(["pic_en"]);
    
                
                }else{
                    if(res?.data?.rp_setting[0]?.tac_infomation_th){
                        const fileList_TH:any[] =
                        [
                            {
                                id: res?.data?.rp_setting[0]?.tac_infomation_th[0].id,
                                uid: String(res?.data?.rp_setting[0]?.tac_infomation_th[0].id),
                                name: res?.data?.rp_setting[0]?.tac_infomation_th[0].name,
                                status: "done",
                                url: res?.data?.rp_setting[0]?.tac_infomation_th[0].url,
                                thumbUrl: res?.data?.rp_setting[0]?.tac_infomation_th[0].url
                            }
                        ];
    
                        setImgTh({
                            name: 'files',
                            multiple: true,
                            // defaultFileList: [...fileList3],
                            defaultFileList: [...fileList_TH],
                        })
                        form.resetFields(["pic"]);
                    }
                    
    
                    if(res?.data?.rp_setting[0]?.tac_infomation_en){
                        const fileList_EN:any[] =
                        [    
                            {
                                id: res?.data?.rp_setting[0]?.tac_infomation_en[0].id,
                                uid: String(res?.data?.rp_setting[0]?.tac_infomation_en[0].id),
                                name: res?.data?.rp_setting[0]?.tac_infomation_en[0].name,
                                status: "done",
                                url: res?.data?.rp_setting[0]?.tac_infomation_en[0].url,
                                thumbUrl: res?.data?.rp_setting[0]?.tac_infomation_en[0].url
                            }
                        ];
    
                        setImgEn({
                            name: 'files',
                            multiple: true,
                            // defaultFileList: [...fileList3],
                            defaultFileList: [...fileList_EN],
    
                        })
                        form.resetFields(["pic_en"]);
                    }
                    
                }
    
                form.setFieldsValue({
                    pic_en: vImgEn,
                    pic: vImgTh,
                })

                form.setFieldsValue({
                    topic: res?.data?.rp_setting[0].tac_th,
                    // pic_en: '',
                    // pic: '',
                    // property_type: dataProp ? dataProp[0]?.property_type_id : null,
                })
            });
    }

    console.log('topic', Topic)

    // console.log('vImgEn', vImgEn.defaultFileList ? vImgEn.defaultFileList.length : '-')
    // console.log('vImgTh', vImgTh.defaultFileList ? vImgTh.defaultFileList.length : '-')

    // useEffect(() => {
    //     if (countInterval < 3) {
    //       const interval = setInterval(() => {
    //         setCountInterval(prev => prev + 1)

    //         if(countInterval === 0){

    //             getDataK();
    //             // console.log('res?.data', res_data);
    //             // console.log('property', Property);
    //             SetTopic(res_data? res_data.rp_setting[0].tac_th : "")
    
    //             // if(tabTermActive == "TAC"){
    //             //     const fileList_TH:any[] =
    //             //         [
    //             //             {
    //             //                 id: res_data?.rp_setting[0]?.tac_content_th[0].id,
    //             //                 uid: String(res_data?.rp_setting[0]?.tac_content_th[0].id),
    //             //                 name: res_data?.rp_setting[0]?.tac_content_th[0].name,
    //             //                 status: "done",
    //             //                 url: res_data?.rp_setting[0]?.tac_content_th[0].url,
    //             //                 thumbUrl: res_data?.rp_setting[0]?.tac_content_th[0].url
    //             //             }
    //             //         ];
    
    //             //         setImgTh({
    //             //             name: 'files',
    //             //             multiple: true,
    //             //             // defaultFileList: [...fileList3],
    //             //             defaultFileList: [...fileList_TH],
    //             //         })
    //             //         form.resetFields(["pic"]);
    
    //             //     const fileList_EN:any[] =
    //             //         [    
    //             //             {
    //             //                 id: res_data?.rp_setting[0]?.tac_content_en[0].id,
    //             //                 uid: String(res_data?.rp_setting[0]?.tac_content_en[0].id),
    //             //                 name: res_data?.rp_setting[0]?.tac_content_en[0].name,
    //             //                 status: "done",
    //             //                 url: res_data?.rp_setting[0]?.tac_content_en[0].url,
    //             //                 thumbUrl: res_data?.rp_setting[0]?.tac_content_en[0].url
    //             //             }
    //             //         ];
    
    //             //         setImgEn({
    //             //             name: 'files',
    //             //             multiple: true,
    //             //             // defaultFileList: [...fileList3],
    //             //             defaultFileList: [...fileList_EN],
    
    //             //         })
    //             //         form.resetFields(["pic_en"]);
    
                
    //             // }else{
    //             //     const fileList_TH:any[] =
    //             //         [
    //             //             {
    //             //                 id: res_data?.rp_setting[0]?.tac_infomation_th[0].id,
    //             //                 uid: String(res_data?.rp_setting[0]?.tac_infomation_th[0].id),
    //             //                 name: res_data?.rp_setting[0]?.tac_infomation_th[0].name,
    //             //                 status: "done",
    //             //                 url: res_data?.rp_setting[0]?.tac_infomation_th[0].url,
    //             //                 thumbUrl: res_data?.rp_setting[0]?.tac_infomation_th[0].url
    //             //             }
    //             //         ];
    
    //             //         setImgTh({
    //             //             name: 'files',
    //             //             multiple: true,
    //             //             // defaultFileList: [...fileList3],
    //             //             defaultFileList: [...fileList_TH],
    //             //         })
    //             //         form.resetFields(["pic"]);
    
    //             //     const fileList_EN:any[] =
    //             //         [    
    //             //             {
    //             //                 id: res_data?.rp_setting[0]?.tac_infomation_en[0].id,
    //             //                 uid: String(res_data?.rp_setting[0]?.tac_infomation_en[0].id),
    //             //                 name: res_data?.rp_setting[0]?.tac_infomation_en[0].name,
    //             //                 status: "done",
    //             //                 url: res_data?.rp_setting[0]?.tac_infomation_en[0].url,
    //             //                 thumbUrl: res_data?.rp_setting[0]?.tac_infomation_en[0].url
    //             //             }
    //             //         ];
    
    //             //         setImgEn({
    //             //             name: 'files',
    //             //             multiple: true,
    //             //             // defaultFileList: [...fileList3],
    //             //             defaultFileList: [...fileList_EN],
    
    //             //         })
    //             //         form.resetFields(["pic_en"]);
    //             // }
    
    //             // form.setFieldsValue({
    //             //     pic_en: vImgEn,
    //             //     pic: vImgTh,
    //             // })

    //         }
            

    //       }, 1000)
    //       return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    useEffect(() => {
        getDataK();
        // SetTopic(res_data? res_data.rp_setting[0].tac_th : "")
    }, [])

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                        method: "post",
                        body: body
                        // mode: "no-cors"
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                            default: res[0].url
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const handleChange = (e:any) => {
        setText(e.target.value);
    };

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    function fileChange(e:any, type:any){
        console.log('e', e)
        console.log('type', type)
        if(tabTermActive == "TAC"){
            console.log('file term')
            if(type == "en"){
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setTacEn(res[0].id)
                }
            }else{
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setTacTh(res[0].id)
                }
            }
        }else{
            console.log('file infor')
            if(type == "en"){
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setInfoEn(res[0].id)
                }
            }else{
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setInfoTh(res[0].id)
                }
            }
        }
        
    }

    const { ...uploadProps } = useStrapiUpload(
        {
            maxCount: 1,
        }
    );

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'application/pdf' || file.type === 'application/pdf';
        if (!isJpgOrPng) {
          message.error('You can only upload PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };
    
    async function createTerm(values:any) {
        // console.log('values',values)

        console.log('Identity_data', Identity_data)
        // console.log('picTh', vImgTh.defaultFileList[0].id)
        // console.log('picEn', vImgEn.defaultFileList[0].id)
        // console.log('Topic', Topic)
        let data = { 
            "data":{
                "property": property_id,
                "rp_setting": [
                    {
                        "id": Number(setting_id),
                        "seq": 2, // tac seq = 2
                        // "repair_type":{
                        //     "name": "TAC"
                        // },
                        "repair_type": 3, // 3 = TAC
                        "tac_th": Topic, // tac_th ใช้กับ tac
                        "tac_content_th": vImgTh.defaultFileList[0].id,
                        "tac_content_en": vImgEn.defaultFileList[0].id,
                        "updateBy" : Identity_data ? Identity_data.user_profile.id : null
                    }
                ]
            }
        }
        // console.log('data onFin', data)
        let res = await axios.post(API_URL + "/repair-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        successNoti();
        replace("/repair/repair-setup")

    }

    async function createInfo(values:any) {

        // console.log('Topic', Topic)
        // console.log('Number(setting_id)', Number(setting_id))
        let data = {
            "data":{
                "property": Number(property_id),
                "rp_setting": [
                    {
                        "id": Number(setting_id),
                        "seq": 1, // infor seq = 1
                        // "repair_type":{
                        //     "name": "TAC"
                        // },
                        "repair_type": 4, // 4 = Information
                        "tac_th": Topic, // tac_th ใช้กับ info
                        "tac_infomation_th": vImgTh.defaultFileList[0].id,
                        "tac_infomation_en": vImgEn.defaultFileList[0].id,
                        "updateBy" : Identity_data ? Identity_data.user_profile.id : null
                    }
                ]
            }
        }
        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/repair-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        successNoti();
        replace("/repair/repair-setup")

    }

    // const successNoti = (stat:any, msg:any) => {
    //     notification.success({
    //         // message: stat + ' : ' + msg.TH,
    //         message: msg,
    //         // description: msg,
    //         duration: 3,
    //     });
    // };
    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    function fileChange_TH(e: any) {
        // console.log('event', e)

        if(e.file.status == "removed"){
            SetRequireTh(true)
        }else{
            SetRequireTh(false)
        }


        if(e.file.status === "removed") {
            setImgTh((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));
            form?.resetFields(["pic"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setImgTh((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }

       
    }

    function fileChange_EN(e: any) {

        if(e.file.status == "removed"){
            SetRequireEn(true)
        }else{
            SetRequireEn(false)
        }

        if(e.file.status === "removed") {
            setImgEn((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));
            form?.resetFields(["pic_en"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setImgEn((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    // console.log('vTacTh',vTacTh);
    // console.log('vTacEn',vTacEn);
    // console.log('vInfoTh',vInfoTh);
    // console.log('vInfoEn',vInfoEn);
    // console.log('Topic', Topic)
    let title_name = `Edit ${tabTermActive == "TAC" ? "Term & Condition" : "Information" }`
    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        // saveButtonProps={saveButtonProps}
        title={title_name}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{tabTermActive == "TAC" ? t("hcu-term.title") : t("hcu-info.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{tabTermActive == "TAC" ? t("hcu-term.edit") : t("hcu-info.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    // values.topic = values.topic;

                    if(tabTermActive == "TAC"){
                        createTerm(values)
                    }else{
                        createInfo(values)
                    }
                }}
            >
                <div className="divider-container">
                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                // name={["property", "id"]}
                                label={t("property.title")}
                                // rules={[
                                //     {
                                //         required: true, message: "Please select property"
                                //     },
                                // ]}
                            >
                                {/* <Select placeholder={t("select.sel")} {...selProperty}/> */}
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty} 
                                    // value={res_data? res_data?.property?.property_name : "xxxx"} 
                                    defaultValue={Property} 
                                    value={Property} 
                                    disabled
                                />
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={t("hcu-info.topic")}
                                name="topic"
                                rules={[
                                    {
                                        required: true, message: "Please enter topic"
                                    },
                                ]}
                            >
                                <Input 
                                    value={Topic}
                                    defaultValue={Topic}
                                    // onChange={(e) => SetTopic(e)}
                                    onChange={e => SetTopic(e.target.value)}

                                    placeholder="detail" 
                                /> 
                            </Form.Item>
                        </Col>
                        <Col span={4} className="gutter-row">
                            <Form.Item
                                label={t("hcu-info.upload-pdf-th")}
                                name="pic"
                                rules={[
                                    {
                                        // required: vImgTh.defaultFileList ? vImgTh.defaultFileList.length > 0 : false ? false : true, message: 'Please upload PDF.'
                                        // required: vImgTh.defaultFileList.length > 0 ? false : true , message: 'Please upload PDF.'
                                        required: RequireTh , message: 'Please upload PDF.'
                                    },
                                ]}
                            >
                                <Dragger 
                                    style={{ borderRadius: "10px" }}
                                    {...vImgTh}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={beforeUpload}
                                    onChange={fileChange_TH}
                                >
                                    <p>Upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>

                        <Col span={4} className="gutter-row">
                            <Form.Item
                                 label={t("hcu-info.upload-pdf-en")}
                                name="pic_en"
                                rules={[
                                    {
                                        // required: vImgEn.length > 0 ? false : true, message: 'Please upload PDF.'
                                        // required: vImgEn.defaultFileList ? vImgEn.defaultFileList.length > 0 : false ? false : true, message: 'Please upload PDF.'
                                        // required: vImgEn.defaultFileList.length > 0 ? false : true , message: 'Please upload PDF.'
                                        required: RequireEn , message: 'Please upload PDF.'
                                    },
                                ]}
                            >
                                <Dragger 
                                    style={{ borderRadius: "10px" }}
                                    {...vImgEn}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={beforeUpload}
                                    onChange={fileChange_EN}
                                >
                                    <p>Upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        {/* <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button> */}
                        <Button 
                            type="primary" 
                            onClick={() => form.submit()} 
                            icon={<Icons.SaveOutlined />}
                        >Save</Button>
                    </Row>
                </div>
            </Form>
    </Edit>   
    );
};