/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { RepairDocumentCustomerList } from "./document_customer";
import { RepairDocumentOtherList } from "./document_other";


export const RepairDocument: React.FC<IResourceComponentsProps> = () => {
    const tabActive = localStorage.getItem("keyTabRepairMaster");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    function onStatusChange(v:any){
        // if(v == "Y"){
        //     // setdeleteStatus("Y")
        //     // setappointmentType(null) 
        // }else{
        //     // setdeleteStatus("N")
        //     // setappointmentType(v)
        // }
        // searchFormProps.form?.submit();
        localStorage.setItem("keyTabRepairMaster", v)
    }

    return <>
        <List 
            // title={t("hcumaster.title")}
            title="Services &amp; Repair Document"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    {/* <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Services &amp; Repair Document</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></> 
            }}
        >
            <Tabs
                defaultActiveKey={tabActive? tabActive + "" : "1"}
                onChange={onStatusChange}
            >
                <Tabs.TabPane tab="เอกสาร" key="1">
                    <RepairDocumentOtherList />
                </Tabs.TabPane>
                <Tabs.TabPane tab="เอกสารลูกค้า" key="2">
                    <RepairDocumentCustomerList />
                </Tabs.TabPane>
            </Tabs>
        </List>
    </>
}