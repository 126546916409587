/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Divider, Form, Input, InputNumber, List, Row, Select, useForm , useSelect} from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IProperties, IUnit, IUnitType } from "interfaces";
import "react-mde/lib/styles/css/react-mde-all.css";
import axios from "axios";
import { API_URL, TOKEN_KEY } from "../../constants";


// import type { RcFile, UploadProps } from 'antd/es/upload';
// import type { UploadFile } from 'antd/es/upload/interface';
import { useState } from "react";
import React from "react";

import { NumericFormat } from "react-number-format";

export const UnitCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog }     = useCreate<IEventlog>();
    const log_endpoint              = "even-logs";
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const token = localStorage.getItem(TOKEN_KEY);
    const [ vProperty, setProperty] = useState<any>();
    const [ vUnitValue, setUnitValue] = useState<any>(0);
    const [ unitArea, setUnitArea] = useState<any>(0);

    const { 
        // formProps, 
        formProps: formProps,
        saveButtonProps 
    // } = useForm<IUnit,HttpError,{}>(
    } = useForm<any>({
        resource: "units",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: "list",
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource    : log_endpoint,
                values      : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(), 
                    log_event               : "Create",
                    menu_event              : 'unit',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },}
    );

    const { selectProps: SelPropertyType } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       : 'N',
            },
        ]
    });

    const { selectProps: SelUnitType } = useSelect<IUnitType>({
        resource: "unit-types",
        optionLabel: "name",
        optionValue: "id",
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       : 'N',
            },
            {
                field       : 'property][id]',
                operator    : 'eq',
                value       : vProperty
            }
        ]
    });

    function fSetUnitValue(v:any){
        // console.log('unit value', v.target.value);
        // parseFloat(v.target.value.replace(/,/g, ''));
        setUnitValue(v.target.value);
    }

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("unit-create.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("unit.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><span className="last_breadcrumb">{t("unit-create.title")}</span></Breadcrumb.Item>
                                </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={async (values:any) => {
                    values.createBy = user_data.user_profile.id
                    // console.log('vUnitValue', parseFloat(vUnitValue.replace(/,/g, '')))
                    values.unit_area = values.unit_area.toString()
                    values.unit_value = parseFloat(vUnitValue.replace(/,/g, ''))
                    values.unitStatus = "N"

                    // console.log('val', values)

                    // let data_post
                    // let data_unit_type 
                    // data_unit_type = { 
                    //     // "unit_type": {
                    //     "property": {
                    //         "property_id": find_unit[index]?.property?.id,
                    //         "property_name": find_unit[index]?.property?.property_name,
                    //         "property_name_en": find_unit[index]?.property?.property_name,
                    //         "property_type_id": find_unit[index]?.property ? find_unit[index]?.property?.pp_type ? find_unit[index]?.property?.pp_type?.id : "" : "",
                    //         "property_type_name": find_unit[index]?.property ? find_unit[index]?.property?.pp_type ? find_unit[index]?.property?.pp_type?.pp_name : "" : "",
                    //         "property_type_name_en": find_unit[index]?.property ? find_unit[index]?.property?.pp_type ? find_unit[index]?.property?.pp_type?.pp_name : "" : "",
                    //     },
                    //     "unit_type_id": find_unit[index]?.unit_type ? find_unit[index]?.unit_type?.id : "",
                    //     "unit_type_name": find_unit[index]?.unit_type ? find_unit[index]?.unit_type?.name : "",
                    //     "unit_type_name_en": find_unit[index]?.unit_type ? find_unit[index]?.unit_type?.name : "",
                    //     // },
                    // }

                    // data_post = {
                    //     "unit_id": find_unit[index]?.id,
                    //     "unit_name": find_unit[index]?.unit_name,
                    //     "unit_name_en": find_unit[index]?.unit_name,
                    //     "transfer_date": find_unit[index]?.owner_in_date ? date : null,
                    //     "unit_type": data_unit_type
                    // }
                    // console.log('data_post', data_post)
                    

                    // // 1. หาว่า unit code ซ้ำมั้ย
                    // // let res = await axios.post(API_URL + "/api/like-systems/checkDupUnitCode?id=2", data_post , { headers: { Authorization: 'Bearer ' + token } });
                    // let res = await axios.get(API_URL + "/api/like-systems/checkDupUnitCode?id=2", data_post , { headers: { Authorization: 'Bearer ' + token } });

                    // console.log('res', res)
                    // // 2. 

                    // console.log('values', values)


                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ borderRadius: 5, marginBottom: "2%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>

                            <Row gutter={[16, 24]}>

                                <Col id="Col_w" className="gutter-row" span={3}>
                                    <Form.Item  label={t("unit.code")} name="unit_code"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter unit code.'
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col id="Col_w" className="gutter-row" span={9}>
                                    <Form.Item  label={t("unit.name")} name="unit_name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter unit name.'
                                            },
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </Col>

                                <Col id="Col_w" className="gutter-row" span={6}>
                                    <Form.Item  label={t("Unit Area")} name="unit_area"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter unit area size.'
                                            },
                                        ]}
                                    >
                                        <InputNumber 
                                            style={{width:'100%'}} 
                                            min={0} 
                                            // onChange={(e) => setUnitArea(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col id="Col_w" className="gutter-row" span={6}>
                                    <Form.Item  label={t("unit.value")} name="unit_value"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter unit value.'
                                            },
                                        ]}
                                    >
                                        <NumericFormat
                                            type="text"
                                            thousandSeparator=","
                                            maxLength={15}
                                            style={{border: "1px solid #d9d9d9",borderRadius: "5px",height: "33px",width: "100%"}}
                                            onChange={(e) => fSetUnitValue(e)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: "3%" }}><span className="title_panel">{t("relation.txt")}</span></div>
                            <Divider></Divider>

                            <Form.Item  label={t("property.title")} name="property"
                                rules={[
                                    {
                                        required: true, message: 'Please select property.'
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...SelPropertyType} 
                                    onChange={value => {
                                        setProperty(value);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item  label={t("unit-type.title")} name="unit_type"
                                rules={[
                                    {
                                        required: true, message: 'Please select unit type.'
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...SelUnitType}/>
                            </Form.Item>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};