/* eslint-disable */
import { Breadcrumb, Card, Col, Create, DatePicker, Form, List, Row, Select, useForm, useSelect, Input, Upload, message, Button, Modal, RcFile, Tooltip, Icons, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useGetLocale, useList, useNavigation, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IFacility, IFacilityBan, IFacilityBooking, IFacilityTimeSlot, IFacilityType, IProperties, IUnit, IUserhunit} from "interfaces";
import { useState } from "react";
import { TOKEN_KEY } from "../../constants";
import moment from 'moment';
import { WarningOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";
import axios from "axios";
import dayjs from "dayjs";

export const FacilitiesBKCreate: React.FC<IResourceComponentsProps> = () => {
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const { mutate: createBooking }         = useCreate<IFacilityBooking>();

    const { confirm }                       = Modal;
    const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    const log_endpoint                      = "even-logs";
    const { form, formProps, saveButtonProps } = useForm<IFacilityBooking,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            // CreateLog({
            //     resource    : log_endpoint,
            //     values      : {
            //         user_profile            : user_data.user_profile.id,
            //         log_time_stamp          : Date.now(), 
            //         log_event               : "Create",
            //         menu_event              : 'facility-bookings',
            //         SysPkID                 : recordData.data.id + ""
            //     },
            //     successNotification : false
            // });
        },
        // onMutationError(data, variables, context){
        //     console.log('data error', data)
        //     console.log('data variables', variables)
        //     console.log('data context', context)
        //     return {
        //         message: error.toString(),
        //         type: "error" ,
        //         undoableTimeout: 1000
        //     }
        // },
        errorNotification(error:any, values:any, resource:any) {
            // console.log('error', error)
            // console.log('values', values)
            // console.log('resource', resource)
            return {
                message: error.toString(),
                type: "error" ,
                undoableTimeout: 1000
            }
        },
        onMutationError(error, variables, context) {
            console.log('error', error)
            console.log('variables', variables)
            console.log('context', context)

            // console.log('formProps.onFinishFailed ',formProps.onFinishFailed)
            // console.log('formProps.onAbort ',formProps.onAbort)

        },
        
 
        // onMutationError((error: TError, variables: TVariables, context: any) => void)
    });

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;

    const [ vDateuse, setDateuse ] = useState<any>("");
    const [ vDateForWaitList, setDateForWaitList ] = useState<any>("");
    const [vProperty, setProp] = useState<any>();
    const [ vImageID, setImageID] = useState<any>();
    const [ vsetUnit, setUnit] = useState<any>();
    const [ vUserProfile, setUserProfile] = useState<any>();
    const [ vFacility, setFacility] = useState<any>();
    const [ vFacilityType, setFacilityType] = useState<any>();
    const [ vMaxUser, setMaxUser] = useState<any>();
    const [ vIDTimeslot, setIDTimeslot ] = useState<any>([]);
    const [ vShowTimeslot, setShowTimeslot ] = useState<any>(false);
    const [ vTimeSlot1, setTimeSlot1 ]  = useState<any>("");
    const { replace }   = useNavigation();
    const [vTOOLTIP, setTOOLTIP] = useState<any>("Check booking"); // t("msg-vld-mail.tooltip-search")
    const [vSTYPEICON, setSTYPEICON] = useState<any>("#626262");
    const [vICONSEARCH, setICONSEARCH] = useState<any>(<Icons.SearchOutlined />);

    const [ vOwnerCanBook, setOwnerCanBook ]  = useState<any>("500");
    // const [ vWaitingListStat, setWaitingListStat ]  = useState<any>(false);

    // console.log("vMaxUser",vMaxUser)
    // console.log('vUserProfile', vUserProfile)

    const Property = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['unit','unit.property'],
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            pagination:{ pageSize: 100000}
        }
    });
    const { data: Dataprop } = Property;
    let PropData:any = []
    PropData = Dataprop?.data? Dataprop?.data : [];  
    const PropListData = Array.from(new Set(PropData.map((item: any) => item.unit.property.id)));

    // console.log('PropListData', PropListData)
    const Unit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: '*',
        },
        config:{
            filters: [
                {
                    field: "unit][property][id]",
                    operator: "eq",
                    value: vProperty + ""
                },
                // { // #1278 Create Facility Booking : ต้องไม่โชว์ Unit ที่มี Owner Contract เลย
                //     field: "user_sub_type][sub_type_name]",
                //     operator: "nin",
                //     value: "Owner-Contract"
                // },
                { // #1278 Create Facility Booking : ต้องไม่โชว์ Unit ที่มี Owner Contract เลย
                    field: "user_sub_type][id]",
                    operator: "ne",
                    value: 6
                },
                { // #1277 Create Facility Booking : ตรง User Profile ให้กรอง Approved Status ด้วย ถ้ายังไม่ผ่านการ Approved บ้านมาต้องยังไม่มีชื่อขึ้น
                    field   : "isOwnerConfirmed",
                    operator: "eq",
                    value   : true
                },
                { // #1277 Create Facility Booking : ตรง User Profile ให้กรอง Approved Status ด้วย ถ้ายังไม่ผ่านการ Approved บ้านมาต้องยังไม่มีชื่อขึ้น
                    field   : "isAdminConfirmed",
                    operator: "eq",
                    value   : true
                }
            ],
            pagination:{pageSize: 100},
        }
    });
    const { data: DataUnit } = Unit;

    console.log('DataUnit', DataUnit)

    let UnitData:any = []
    UnitData = DataUnit?.data? DataUnit?.data : [];
    const UnitListData = Array.from(new Set(UnitData.map((item: any) => item.unit.id))); 


    // console.log('UnitListData', UnitListData)


    const { selectProps: SelProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field: "id",
            //     // operator: "eq",
            //     operator: "in",
            //     value: PropListData
            // }
        ],
        // fetchSize: 100,
        pagination:{
            pageSize: 100
        }
    });

    // console.log('SelProp', SelProp)

    console.log('vProperty', vProperty)

    const { selectProps: SelUnit } = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            // {
            //     field: "id",
            //     operator: "eq",
            //     // operator: "in",
            //     value: UnitListData.length > 0 ? UnitListData : "NULL"
            // },
            {
                field: "property][id]",
                operator: "eq",
                // operator: "in",
                value: vProperty
            },
            {
                field: "unitStatus",
                operator: "ne",
                value: "N"
            },
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ],
        // fetchSize: 2000,
        pagination:{
            pageSize: 1000
        }
    });

    const BannedUser = useList<IFacilityBan>({ 
        resource: "facility-bans", 
        metaData: {
            populate: ['user_profile', 'user_profile.id'],
        },
        config:{
            filters: [
                {
                    field   : "ban_type",
                    operator: "eq",
                    value   : false // false = ban user
                },
                {
                    field   : "ban_status",
                    operator: "eq",
                    value   : true // true = still banned
                },
                {
                    field   : "deleteStatus",
                    operator: "contains",
                    value   : "N"
                },
            ]
        }
    });
    const { data: dataBannedUser } = BannedUser;
    let bannedUserData:any = []
    bannedUserData = dataBannedUser?.data? dataBannedUser?.data : [];
    // console.log('bannedUserData', bannedUserData)
    
    const UserDetail = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile'],
        },
        config:{
            filters: [
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : vsetUnit + ""
                },
                {
                    field   : "deleteStatus",
                    operator: "contains",
                    value   : "N"
                },
                {
                    field   : "user_sub_type][id]",
                    operator: "ne",
                    value   : 6 // owner-contract
                },
                { // #1277 Create Facility Booking : ตรง User Profile ให้กรอง Approved Status ด้วย ถ้ายังไม่ผ่านการ Approved บ้านมาต้องยังไม่มีชื่อขึ้น
                    field   : "isOwnerConfirmed",
                    operator: "eq",
                    value   : true
                },
                { // #1277 Create Facility Booking : ตรง User Profile ให้กรอง Approved Status ด้วย ถ้ายังไม่ผ่านการ Approved บ้านมาต้องยังไม่มีชื่อขึ้น
                    field   : "isAdminConfirmed",
                    operator: "eq",
                    value   : true
                },

                // {
                //     field   : "user_profile][deleteStatus]",
                //     operator: "contains",
                //     value   : "N"
                // },
                // {
                //     field   : "user_profile][user][block]",
                //     operator: "eq",
                //     value   : false
                // },
            ],
            pagination:{
                pageSize: 2000
            }
        }
    });
    const { data: XdataK } = UserDetail;
    let userData:any = []
    userData = XdataK?.data? XdataK?.data : [];

    // console.log('userData', userData)

    function removeDuplicates(userData: any, bannedUser: any) {
        // console.log('userData', userData)
        // console.log('bannedUser', bannedUser)
        const userID = new Set(
            bannedUser?.map((item:any) => item.user_profile?.id)
        );
        // console.log('userID', userID)
        let k = userData?.filter((item:any) => !userID.has(item.user_profile?.id));
        // console.log('k', k)
        // return bannedUser.filter((item:any) => !userID.has(item.user_profile.id));
        return k
    }
      
    //   const allClientsArr = [
    //     { name: "Martha", id: 1 },
    //     { name: "John", id: 2 },
    //     { name: "Jane", id: 3 },
    //     { name: "Mary", id: 4 },
    //   ];
    //   const activeClientsArr = [
    //     { name: "John", customerid: 2 },
    //     { name: "Mary", customerid: 4 },
    //   ];
      
    const filteredUserData = removeDuplicates(userData, bannedUserData);
    // console.log('result', filteredUserData);
    
    const { selectProps: SelFacility } = useSelect<IFacility>({
        resource: "facilities",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 50,
        filters:[
            {
                field: "facility_type][id]",
                operator: "eq",
                value: vFacilityType
            },
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'no_booking',
                operator    : 'eq',
                value       :  false,
            },
            {
                field       : 'property][id]',
                operator    : 'eq',
                value       :  vProperty,
            }
        ]
    });

    const { selectProps: SelFacilityType } = useSelect<IFacilityType>({
        resource    : "facility-types",
        optionLabel : "name",
        optionValue : "id",
        fetchSize: 50,
        filters:[
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'propertyID',
                operator    : 'eq',
                value       : vProperty,
            }
        ]
    });

    const TimeSlotDetail = useList<IFacilityTimeSlot>({ 
        resource: "facility-time-slots", 
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "id",
                    operator: "eq",
                    value: vIDTimeslot
                },
            ],
            pagination:{
                pageSize: 2000
            }
        }
    });
    const { data: kTimeSlotData } = TimeSlotDetail;
    let timeSlotData:any = []
    timeSlotData = kTimeSlotData?.data? kTimeSlotData?.data : [];

    // sorting time slot
    if(timeSlotData !== undefined){
        // console.log('sorting.')
        timeSlotData.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(
            b.name,
            undefined,
            { numeric: true, sensitivity: 'base' }
        ));
    }

    // console.log('timeSlotData >>>>>>', timeSlotData) 

    const locale = useGetLocale();
    const currentLocale = locale();
 
    const DateuseChange = (date: Dayjs | null, dateString: string, id: number) => {
        // let date = dayjs(dateString).format('MM/DD/YYYY')
        let kDate = dayjs(date).format('DD/MM/YYYY')
        // console.log('kDate', kDate)
        // console.log('dateString', dateString)
        let kDate2 = dayjs(date).format("YYYY-MM-DD")

        setDateForWaitList(kDate2);
        // console.log('kDate2', kDate2);
        // setDateuse(dateString);
        setDateuse(kDate);
        callAPI(dateString);
    };

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState( currentLocale === 'en' ? "There's been Fully Booked! Do you want to get on the Waitlist?" : "Facility นี้ถูกจองเต็มแล้ว! คุณต้องการรอคิวใน Waiting List หรือไม่");
    const [vModalTitle, setModalTitle] = useState("Facility full!");
    const [vChangeProfile, setChangeProfile] = useState<any>(false);

    // const showModal = () => {
    //   setVisible(true);
    // };

    const handleOk = () => {
      setModalText('Booking...');
      setConfirmLoading(true);

      callAPIwaitingList();
      
      setTimeout(() => {
        setVisible(false);
        setConfirmLoading(false);
      }, 2000);
    };

    const handleCancel = () => {
      setVisible(false);
    };


    let resultQuery1:any = [];
    let resultQuery2:any = [];
    // let resultQuery3:any = [];
    let resultAddWaitlist:any = [];
    let resultChkFacilityPrivate:any = [];
    let arrayID:any = [];
    const token = localStorage.getItem(TOKEN_KEY);
    async function callAPI(v:any){
        resultQuery1 = await axios.get(API_URL + "/facility-time-slots/slot_time_chk?date=" + v  + "&property=" + vProperty + "&unit=" + vsetUnit + "&facility=" + vFacility + "&max_user=" + vMaxUser + "&isAdmin=true", { headers: { Authorization: 'Bearer ' + token } });

        // console.log('resultQuery1', resultQuery1)
        // if( resultQuery1.data.EN === "There's been a problem with your booking. Please contact juristic person" || 
        //     resultQuery1.data.EN === "You already have booking on/at this date/time. Please cancel the previous booking." ||
        //     resultQuery1.data.EN === "There's been Fully Booked!" ||
        //     resultQuery1.data.EN === "Your booking quota is full. Please contact juristic person." ||
        //     resultQuery1.data.EN === "Time is up for booking. Please contact juristic person." ||
        //     resultQuery1.data.EN === "Facility is closed!" ){

        //     currentLocale === 'en' ?
        //         message.error ({
        //             content: resultQuery1.data.EN,
        //         })
        //     :
        //         message.error ({
        //             content: resultQuery1.data.TH,
        //         });
        //     setShowTimeslot(false); 
        // // }else if(resultQuery1.data.EN === "There's been Fully Booked! Do you want to get on the Waitlist?"){
        // }else if(resultQuery1.data.EN === "There's been Fully Booked!"){
        //     showModal();
        // }else{
        //     if(resultQuery1.data.length > 0){
        //         setShowTimeslot(true);
        //         arrayID = resultQuery1.data.map((item:any) => {
        //             return item.id
        //         })
        //         getIDtimeSlot(arrayID)
        //     }else{
        //         message.error ({  
        //             content: 'ไม่มีรอบจองในวันที่เลือก',
        //         }); 
        //     }
        // }

        // console.log('resultQuery1.data.length ', resultQuery1.data )

        if(resultQuery1.data.length > 0){
            setShowTimeslot(true);
            arrayID = resultQuery1.data.map((item:any) => {
                return item.id
            })
            getIDtimeSlot(arrayID)
        }else{
            // console.log('resultQuery1.data', resultQuery1.data)
            if(resultQuery1.data.STAT !== undefined){

                // console.log('resultQuery1.data.STAT', resultQuery1.data)
                setShowTimeslot(true);
                arrayID = resultQuery1.data.timeslot.map((item:any) => {
                    return item.id
                })

                // console.log('arrayID',arrayID);
                getIDtimeSlot(arrayID)

                // ถ้า stat เป็น waitlist create ลง table waitlist เส้น facility-time-slot > Add_waitlist(ctx)
                // setWaitingListStat(true)
            }else{
                console.log('resultQuery1.data.EN', resultQuery1.data.EN)
                console.log('resultQuery1.data.TH', resultQuery1.data.TH)

                // let errorMsgEN = resultQuery1.data.EN
                // let errorMsgTH = resultQuery1.data.TH

                currentLocale === 'en' ?
                    // message.error ({
                    //     content: resultQuery1.data.EN,
                    // })
                    errornotiCallApi(500, resultQuery1.data.EN)
                :
                    // message.error ({
                    //     content: resultQuery1.data.TH,
                    // });
                    errornotiCallApi(500, resultQuery1.data.TH)

            }
            // currentLocale === 'en' ?
            //     message.error ({
            //         content: resultQuery1.data.EN,
            //     })
            //     // message.info ({
            //     //     content: resultQuery1.data.EN,
            //     // })
            // :
            //     message.error ({
            //         content: resultQuery1.data.TH,
            //     });
            //     // message.info ({
            //     //     content: resultQuery1.data.TH,
            //     // });

            // message.error ({
            //     content: 'ไม่มีรอบจองในวันที่เลือก',
            // });
        }
    } 

    // console.log('vTimeSlot1',vTimeSlot1)
    // console.log('vDateuse', vDateuse)

    async function callAPIwaitingList(){
        resultQuery2 = await axios.get(API_URL + "/facility-time-slots/slot_time_chk?date=" + vDateuse + "&property=" + vProperty + "&unit=" + vsetUnit + "&facility=" + vFacility + "&max_user=" + vMaxUser, { headers: { Authorization: 'Bearer ' + token } });
        createBookingManual();
        replace("");
    }

    // const { confirm } = Modal;

    function showSaveWaitlistConf() {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Save to waiting list ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to save this data.',
            okText  : 'Confirm',
            onOk() {
                saveData()
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    async function callChkIsFacilityPrivate(){

        resultChkFacilityPrivate = await axios.get(API_URL + "/facility-bookings/isFacilityPrivateRoom?facility=" + vFacility , { headers: { Authorization: 'Bearer ' + token } });
        console.log('callChkIsFacilityPrivate', resultChkFacilityPrivate.data)

        return resultChkFacilityPrivate.data
    }

    async function callChkWaitlist(){

        // const dateK = dayjs(vDateuse).format("YYYY-MM-DD")
        const dateK = moment(vDateuse, "DD-MM-YYYY").format("YYYY-MM-DD");
        console.log('dateK', dateK)

        resultAddWaitlist = await axios.get(API_URL + "/facility-bookings/capacityCHK?facility=" + vFacility + "&max_user=" + vMaxUser + "&date=" + dateK + "&facility_time_slot=" + vTimeSlot1 , { headers: { Authorization: 'Bearer ' + token } });
        console.log('callChkWaitlist', resultAddWaitlist.data)

        if(resultAddWaitlist.data){
            // แสดง modal ว่า save แบบ waitlist
            showSaveWaitlistConf()
        }else{
            // save ปกติ
            saveData()
        }
    }

    function saveData(){
        createBooking(
            {
                resource : 'facility-bookings',
                values : {
                    createBy : user_data.user_profile.id,
                    date : moment(vDateuse, "DD-MM-YYYY").format("YYYY-MM-DD"), 
                    facility_time_slot: vTimeSlot1,
                    user_profile: vUserProfile,
                    property: vProperty,
                    facility: vFacility,
                    unit: vsetUnit,
                    facility_type: vFacilityType,
                    max_user: vMaxUser,
                    deleteStatus: 'N',
                    receipt_pix : vImageID !== undefined ? vImageID : null,
                    facility_status_booking: "Booking"
                },
                successNotification : false,
                errorNotification : false
            },{
                onSuccess: async(data: any, link: any) =>{
                    replace("")
                },onError(error, variables, context) {
                    errornoti(error.response.status, error.response.data);
                },
            },
        );
    }

    function createBookingManual(){
        CreateBookingCaseWaiting({
            resource    : log_endpoint,
            values      : {
                property        : vProperty,
                unit            : vsetUnit,
                user_profile    : vUserProfile + "",
                facility        : vFacility,
                facility_type   : vFacilityType,
                facility_time_slot : vTimeSlot1,
                max_user        : vMaxUser,
            },
        });
    }

    const error = (errorMsg:any, stat:any) => {
        Modal.error({
          title: <span>{stat}</span>,
          content: <span>{errorMsg}</span>,
        });
    };

    async function handleCheckOwnerRenter() {
        resultQuery1 = await axios.get(API_URL + "/facility-bookings/checkUnitHaveRenter?userID=" + vUserProfile  + "&unitID=" + vsetUnit, { headers: { Authorization: 'Bearer ' + token } });
        // console.log('resultQuery1', resultQuery1.data)
        
        if(resultQuery1.data.code == "500"){
            setOwnerCanBook("500")
            // error(resultQuery1.data.msgEN, resultQuery1.data.status);
            error(resultQuery1.data.msgEN, "");
        }else{
            setChangeProfile(true)
            setOwnerCanBook("200")
        }
        // if(email !== null && email !== undefined && email !== ""){
        //     setcountm(0)
        //     setcountcm(0)
        // }else{
        //     error();
        //     setTOOLTIP(t("msg-vld-mail.tooltip-error"));
        //     setICONSEARCH(<Icons.StopOutlined />);
        //     setSTYPEICON("#cf1322");
        //     setSTYPEINPUT("1px solid #f5222d");
        //     setdisabledSAVE(true);
        // }
    }

    function getIDtimeSlot(k:any) {
        setIDTimeslot(k);
    }

    function onchangeProp(value:any) {
        setProp(value);

        form.resetFields(["unit"]);
        form.resetFields(["user_profile"]);
    }

    function onchangeUnit(value:any) {
        setUnit(value);

        form.resetFields(["user_profile"]);
    }

    function onchangeUserHasUnit(value:any) {
        setUser(value);
        setChangeProfile(false)
    }

    function setUser(v:any){
        setUserProfile(v);
    }

    function onchangeFacility(value:any) {
        setFacility(value);
    }

    function onchangeFacilityType(value:any) {
        setFacilityType(value);
    }

    function onchangeInput(value:any) {
        setMaxUser(value);
    }

    function fileChange(e:any){
        if(e.file.response){
            let res = e.file.response;

            // console.log('kkkkkkk fileChange ',res[0].id);
            //setPhotoList(res[0]);
            setImageID(res[0].id)
        }
        
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    // async function call_checkin_api(){
    //     resultQuery3 = await axios.get(API_URL + "/facility-time-slots/slot_time_chk?date=" + vDateuse + "&property=" + vProperty + "&unit=" + vsetUnit + "&facility=" + vFacility + "&max_user=" + vMaxUser, { headers: { Authorization: 'Bearer ' + token } });
    //     // createBookingManual();
    //     replace("");
    // }

    async function call_Add_waitlist() {
        // PARAMS
        // max_user            : input_para.max_user,
        // user_profile        : input_para.userID,
        // facility            : input_para.facility,
        // unit                : input_para.unit,
        // property            : input_para.property,

        const dateK = dayjs(vDateuse).format("MM/DD/YYYY")
        // console.log('dateK', dateK)
        // console.log('vDateForWaitList', vDateForWaitList);
        // resultQuery1 = await axios.get(API_URL + "/facility-time-slots/Add_waitlist?userID=" + vUserProfile  + "&unitID=" + vsetUnit, { headers: { Authorization: 'Bearer ' + token } });
        resultAddWaitlist = await axios.get(API_URL + "/facility-time-slots/Add_waitlist?property=" + vProperty + "&unit=" + vsetUnit + "&facility=" + vFacility + "&max_user=" + vMaxUser + "&userID=" + vUserProfile + "&facility_time_slot=" + vTimeSlot1 + "&date=" + vDateForWaitList, { headers: { Authorization: 'Bearer ' + token } });
        // console.log('resultAddWaitlist', resultAddWaitlist);
        replace("");
    }

    // function saveDataToWaitingList() {
    //     confirm({
    //         title   : 'Save to waiting list ?', 
    //         icon    : <WarningOutlined />,
    //         content : 'Click Confirm to save this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             // call_Add_waitlist(id, "Inactive");
    //             call_Add_waitlist();
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }

    const errornoti = (stat:any, msg:any) => {

        if(msg.errorMessage == "undefined"){
            msg.errorMessage = "something wrong."
        }

        notification.error({
            // message: stat + ' : ' + msg.TH,
            message: msg.TH,
            description: msg.errorMessage,
            duration: 3,
        });
    };

    const errornotiCallApi = (stat:any, msg:any) => {
        notification.error({
            message: '',
            description: msg,
            duration: 3,
        });
    };
    const overCapacity = () => {
        notification.error({
            message: 'Over Capacity',
            description: 'capacity over limit',
            duration: 3,
        });
    };

    // console.log('vChangeProfile', vChangeProfile)
    //   console.log(parseInt('max user',vMaxUser))
    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-booking.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />

        <Modal
            title={vModalTitle}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <p>{modalText}</p>
        </Modal>

        <Form {...formProps} layout="vertical"
            onFinish={async (values:any) => {

                values.createBy = user_data.user_profile.id
                values.date = moment(vDateuse, "DD-MM-YYYY").format("YYYY-MM-DD");
                values.facility_time_slot = vTimeSlot1;
                values.user_profile = Number(vUserProfile);
                values.max_user = Number(values.max_user)

                // call_checkin_api(); 

                if(vOwnerCanBook == "500"){ // ข้อมูลไม่ครบ
                    error("Cannot booking", "500")
                }else{

                    // #1517 Facility Booking : Type Private Room ต้องไม่มี Waiting List ถ้ากดจองเกิน Max user ให้ขึ้นว่า จำนวนคนเกิน
                    // 1.เช็คว่า facility ที่จะจองเป็น private room ไม่ต้อง call callChkWaitlist()
                    let result:any 
                    // let result2:any 
                    result = await callChkIsFacilityPrivate();
                    // console.log(result)
                    // console.log(result.stat)
                    // console.log(result.facility)

                    // 2. เช็คว่าคนที่จองมาเกินหรือเปล่า
                    // result2 = callChkIsFacilityPrivate();

                    if(result.stat == true && result.facility[0].capacity >= parseInt(vMaxUser)){
                        console.log('values', values)
                        console.log("under cap")
                        return (
                            formProps.onFinish &&
                            formProps.onFinish(mediaUploadMapper(values))
                        );
                    }else if(result.stat == true && result.facility[0].capacity < parseInt(vMaxUser)){
                        // console.log("over cap")
                        overCapacity();
                    }else{
                        console.log("waitlist check")
                        // 1. call เพื่อเช็คว่าเข้าเงื่อนไข waiting list มั้ย
                        callChkWaitlist();
                    }


                    // 2. ถ้าเป็น true ขึ้น popup ว่าข้อมูลที่จะบุ้ค มันลง waitlist นะ ย้ายไปไว้ใน callChkWaitlist
                    // if(vWaitingListStat){ // ข้อมูลที่เลือกจองเป็น waiting list
                    //     saveDataToWaitingList();
                    // }else{ // ข้อมูลที่จองปกติ

                    //     // "facility_status_booking": "Booking",
                    //     // "facility_status_checkin": "Checked in",
                    //     // "facility_status_admin": "Acknowledged",
                    //     // "locale": "string"
                    //     createBooking(
                    //         {
                    //             resource    : 'facility-bookings',
                    //             values      : {
                    //                 createBy : user_data.user_profile.id,
                    //                 date : moment(vDateuse, "DD-MM-YYYY").format("YYYY-MM-DD"), 
                    //                 facility_time_slot: vTimeSlot1,
                    //                 user_profile: vUserProfile,
                    //                 property: vProperty,
                    //                 facility: vFacility,
                    //                 unit: vsetUnit,
                    //                 facility_type: vFacilityType,
                    //                 max_user: vMaxUser,
                    //                 deleteStatus: 'N',
                    //                 receipt_pix : vImageID !== undefined ? vImageID : null,
                    //                 facility_status_booking: "Booking"

                    //             },
                    //             successNotification : false,
                    //             errorNotification : false
                    //         },{
                    //             onSuccess: async(data: any, link: any) =>{
                    //                 // console.log('data onSuccess', data)
                    //                 // console.log('onSuccess >>> ', data.data.data.id) // id ของ parcel in
                    //                 // setParcelInID(data.data.data.id)
                    //                 replace("")
                                   
                    //             },onError(error, variables, context) {
                    //                 // console.log('onError', error.response.data)
                    //                 // console.log('onError', error.response.status)

                    //                 // console.log('variables', variables)
                    //                 // console.log('context', context)
                    //                 errornoti(error.response.status, error.response.data);

                    //                 // console.log('onError', error.message)

                    //             },
                    //             // onSettled(data, error, variables, context) {
                    //             //     console.log('onSettled data ', data)
                    //             //     console.log('onSettled error ', error)
                    //             //     console.log('onSettled variables ', variables)
                    //             //     console.log('onSettled context ', context)
                    //             // },
                    //         },

                    //     );

                    // //     // return (
                    // //     //     formProps.onFinish &&
                    // //     //     formProps.onFinish(mediaUploadMapper(values))
                    // //     // );
                    // }
                }
                
            }}
            onFinishFailed={(value:any) =>{
                console.log('onFinishFailed 1', value)
            }}
            onAbort={(value:any) =>{
                console.log('onAbort 2', value)
            }}
            
        >
            <Row gutter={[16, 24]}>
                
                <Col id="Col_w" className="gutter-row" span={12}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}> */}
                        <Row gutter={{ xs: 8, sm: 16}}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("property.title")} name="property"
                                    rules={[
                                        {
                                            required: true, message: 'Please select property.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeProp} {...SelProp}/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("unit.title")} name="unit"
                                    rules={[
                                        {
                                            required: true, message: 'Please select unit.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeUnit} {...SelUnit} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("user-profiles.user-profile")} name="user_profile"
                                    rules={[
                                        {
                                            required: true, message: 'Please select user profile.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeUserHasUnit}>
                                            {/* {
                                                userData !== undefined?
                                                    userData.map((item:any,key:any) => {
                                                        return <>
                                                            {item.user_profile !== null? 
                                                                <>
                                                                    <Option key={key} value={item.user_profile.id}>{item.user_profile.first_name + ' ' + item.user_profile.last_name}</Option>
                                                                </>
                                                            :
                                                                <></>}
                                                        </>
                                                    })
                                                :
                                                    <></>
                                            } */}

                                            {
                                                filteredUserData != undefined?
                                                    filteredUserData.map((item:any,key:any) => {
                                                        return <>
                                                            {item.user_profile !== null? 
                                                                <>
                                                                    <Option key={key} value={item.user_profile.id}>{item.user_profile.first_name + ' ' + item.user_profile.last_name}</Option>
                                                                </>
                                                            :
                                                                <></>}
                                                        </>
                                                    })
                                                :
                                                    <></>
                                            }
                                        </Select>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={2}>
                                <Form.Item label=" ">
                                    <Tooltip placement="top" title={vTOOLTIP}>

                                        {
                                            vUserProfile == undefined ?
                                                <Button style={{borderRadius: "5px",color: vSTYPEICON}} icon={vICONSEARCH} onClick={() => handleCheckOwnerRenter()} disabled/>
                                            :
                                                <Button style={{borderRadius: "5px",color: vSTYPEICON}} icon={vICONSEARCH} onClick={() => handleCheckOwnerRenter()}/>
                                        }
                                        {/* <Button style={{borderRadius: "5px",color: vSTYPEICON}} icon={vICONSEARCH} onClick={() => handleCheckOwnerRenter()}/> */}
                                    
                                    </Tooltip>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {
                    vOwnerCanBook == "500" || vChangeProfile == false ?
                    <></>
                    :
                    <>
                        <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        
                        <Form.Item  label={t("fclt-booking.typemsjong")} name="facility_type"
                            rules={[
                                {
                                    required: true, message: 'Please select facility type.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelFacilityType} onChange={onchangeFacilityType}/>
                        </Form.Item>

                        <Form.Item  label={t("fclt-booking.typemjong")} name="facility"
                            rules={[
                                {
                                    required: true, message: 'Please select facility.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelFacility} onChange={onchangeFacility}/>
                        </Form.Item>

                        <Form.Item  label={t("fclt-booking.lmuser")} name="max_user"
                            rules={[
                                {
                                    required: true, message: 'Please enter number of user.'
                                },
                            ]}>
                                {/* <Input type={"number"} min={1} max={10}/> */}

                                <Input 
                                    type={"number"} 
                                    min={1} max={10}
                                    onChange={e => { onchangeInput(e.target.value)}}
                                    // onKeyPress={(event) => {
                                    //     if (/^[0-9\b]+$/.test(event.key)) {
                                    //     event.preventDefault();
                                    //     }
                                    // }}
                                />
                        </Form.Item>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item label={t("fclt-booking.dateuse")} name="date"
                            rules={[
                                {
                                    required: true, message: 'Please select date.'
                                },
                            ]}>


                                {/* <DatePicker 
                                    onChange={(date, dateString) => handleNewDatePickerChange(date, dateString, 1)} 
                                    disabledDate={(current) => {
                                        let customDate = moment().format("YYYY-MM-DD");
                                        return current && current > moment(customDate, "YYYY-MM-DD");
                                    }}
                                    style={{width: "100%"}}
                                /> */}


                            <DatePicker  
                                // format="DD/MM/YYYY" 
                                format="MM/DD/YYYY" 
                                style={{width: "100%"}}
                                onChange={(date, dateString) => DateuseChange(date, dateString, 1)} 
                                // disabledDate={(current) => {
                                //     let todayDate = moment().format("YYYY-MM-DD");
                                //     // let maxDate3Month = moment().format("YYYY-MM-DD");

                                //     // var new_date = moment(todayDate, "YYYY-MM-DD");
                                //     // new_date.add(5, 'days');
                                //     // console.log('new_date', moment(todayDate).add(5, 'days').format('YYYY-MM-DD'))

                                //     let k = moment(todayDate).add(3, 'months').format('YYYY-MM-DD')
                                //     // return current && current < moment(todayDate, "YYYY-MM-DD");
                                //     return current && current > moment(k, "YYYY-MM-DD") || current < moment(todayDate, "YYYY-MM-DD");
                                //     // return current && current < moment(todayDate, "YYYY-MM-DD") && moment(todayDate, "YYYY-MM-DD") > moment(todayDate, "YYYY-MM-DD").add('days', 30) ;
                                // }}
                                disabledDate={(current:any) => {
                                    let todayDate = moment().format("YYYY-MM-DD");
                                    let futureDate = moment(todayDate).add(3, 'months').format('YYYY-MM-DD');
                                  
                                    return (
                                      moment(current).isAfter(futureDate) || moment(current).isBefore(todayDate)
                                    );
                                }}
                            />


                        </Form.Item>

                        {
                            vShowTimeslot ?
                                <>
                                    <Form.Item  label={t("fclt-booking.timeuse")} 
                                        name="facility_time_slot"
                                        rules={[
                                            {
                                                required: true, message: 'Please select times.'
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")} 
                                            onSelect={(value: any) => {
                                                setTimeSlot1(value);
                                            }}
                                        >
                                            {
                                                timeSlotData !== undefined?
                                                    timeSlotData.map((item:any,key:any) => {
                                                        return <>
                                                            {item !== null? 
                                                                <>
                                                                    <Option key={key} value={item.id}>{moment(item.time_start, "HH:mm").format('HH:mm') + ' - ' + moment(item.time_end, "HH:mm").format('HH:mm')}</Option>
                                                                </>
                                                            :<></>}
                                                        </>
                                                    })
                                                :<></>
                                            }
                                        </Select>
                                    </Form.Item>
                                </>
                            :<></>
                        }

                        <Form.Item
                            name="receipt_pix"
                            label={t("fclt-booking.slip")}
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                        >
                            <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                maxCount={1}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Upload.Dragger>
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                    </Card>
                </Col>
                    
                    </>
                }

                
            </Row>
        </Form>
    </Create>   
    );
};