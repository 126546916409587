/* eslint-disable */
import {  Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, Upload, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";

import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

let swLocal : boolean = true;

export const DocSubTypeList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const TOKEN = localStorage.getItem(TOKEN_KEY);

    const { TabPane } = Tabs;
    const { Dragger } = Upload;

    const { mutate: UpdateLocale } = useUpdate<any>();

    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Document Sub Type");

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()
    
    const [DataCus, setDataCus] = useState<any>();
    const [vPicID, setPicID] = useState<any>("");
    const [vImg, setImg] = useState<any>([]);

    const [vswLocal, setswLocal] = useState<boolean>(true);
    const [vFilterEN, setFilterEN] = useState<any>(undefined);
    
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<ICoupon>();
    const ed_point                          = "document-sub-types";
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
        metaData: {
            // populate: '*',
            populate: ['*', 'localizations','localizations.updateBy','localizations.locale','updateBy','createBy', 'locale','document_type','localizations.document_type'],
            locale:['th']
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const {document_type,doc_name,deleteStatus} = params;
                filters.push(
                    {
                        field       : "document_type][id]",
                        operator    : "contains",
                        value       : document_type !== undefined ? document_type : null
                    },
                    {
                        field       : "doc_name",
                        operator    : "contains",
                        value       : doc_name !== undefined ? doc_name : null
                    },
                    {
                        field       : "localizations][doc_name]",
                        operator    : "contains",
                        value       : vFilterEN !== undefined ? vFilterEN : null
                    },
                    {
                        field       : "deleteStatus",
                        operator    : "eq",
                        value       : deleteStatus !== undefined ? deleteStatus : "N"
                    },
                );
            return filters;
        },
        initialFilter: [
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : "N"
            },
        ],
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
    });

    const datacus = useList<any>({ 
        resource: "document-sub-types" ,
        metaData: {
            populate: '*',
            locale:['th']
        },
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        }
    });
    const {data:dataCUSTOM} = datacus

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
    } = useModalForm<any>({
        resource: "document-sub-types",
        metaData: {
            populate: '*',
            locale:['th']
        },
        action: "create",
        redirect: false
    });


    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading ,
        show: editModalShow,
        close
    } = useModalForm<any>({
        resource: "document-sub-types",
        metaData: {
            populate: [
                'document_type',
                'updateBy',
                'icon',
                'localizations',
                'localizations.document_type',
                'localizations.icon',
            ],
            locale:['th']
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad:any = data?.data;
            console.log('>>>> dataLoad', dataLoad);
            setDataCus(dataLoad)

            const fileList: UploadFile[] = dataLoad?.icon ? 
            [
                {
                    uid: dataLoad?.icon.id,
                    name: dataLoad?.icon.name,
                    status: "done",
                    url: dataLoad?.icon.url,
                    thumbUrl: dataLoad?.icon.url
                }
            ]
            :[];

            setImg({
                name: 'files',
                multiple: true,
                defaultFileList: fileList?.length > 0 ? [...fileList] : null,
            })

            editProps?.form?.resetFields(["icon"]);
        }},
    });

    const { selectProps: selDocType } = useSelect<any>({
        resource    : "document-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function loadLocal(){
        if(swLocal == true){
            setPicID(DataCus?.icon ? DataCus?.icon?.id : undefined);

            const fileList: UploadFile[] = DataCus?.icon ? 
            [
                {
                    uid: DataCus?.icon.id,
                    name: DataCus?.icon.name,
                    status: "done",
                    url: DataCus?.icon.url,
                    thumbUrl: DataCus?.icon.url
                }
            ]
            :[];

            setImg({
                name: 'files',
                multiple: true,
                defaultFileList: fileList?.length > 0 ? [...fileList] : null,
            })

            editProps?.form?.resetFields(["icon"]);

            editProps?.form?.setFieldsValue({
                doc_name: DataCus ? DataCus.doc_name : null
            })
        }else{
            if(DataCus?.localizations?.length > 0){
                setPicID(DataCus?.localizations[0]?.icon ? DataCus?.localizations[0]?.icon?.id : undefined);

                const fileList: UploadFile[] = DataCus?.localizations[0]?.icon ? 
                [
                    {
                        uid: DataCus?.localizations[0]?.icon.id,
                        name: DataCus?.localizations[0]?.icon.name,
                        status: "done",
                        url: DataCus?.localizations[0]?.icon.url,
                        thumbUrl: DataCus?.localizations[0]?.icon.url
                    }
                ]
                :[];
    
                setImg({
                    name: 'files',
                    multiple: true,
                    defaultFileList: fileList?.length > 0 ? [...fileList] : null,
                })
    
                editProps?.form?.resetFields(["icon"]);
                // doc_name
                editProps?.form?.setFieldsValue({
                    doc_name: DataCus?.localizations[0]?.doc_name
                })
            }
        }
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                deleteStatus    : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'document-sub-types',
                SysPkID         : id+""
            },
            errorNotification:false,
            successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function updateLocaleEn(value: any){
        UpdateLocale({
            resource : 'document-sub-types',
            id: DataCus.localizations[0].id,
            values:{
                doc_name : value?.doc_name,
                updateBy : user_data.user_profile.id,
                icon : vPicID,
                locale : "en"
            },
            successNotification:false,
            },{
                onSuccess(data, variables, context) {
                    close();
                },
            }
        )
    }

    function updateUserUpdateBy(){
        UpdateLocale({
            resource : 'document-sub-types',
            id: DataCus.id,
            values:{
                updateBy : user_data.user_profile.id
            },
            successNotification:false,
        },{
            onSuccess(data, variables, context) {
                close();
            },
        }
        )
    }

    function fileChange(e:any){
        let res = e.file;
        if(res.status == "removed"){
            setPicID(null)
        }else if(res.status == "done"){
            setPicID(res?.response[0].id)
        }
    }

    return <>
            <List 
                title={t("document-sub-type.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("document.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("document-sub-type.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row gutter={12}>
                    <Col span={22}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="document_type">
                                <Select placeholder={t("select.sel")} {...selDocType} allowClear style={{width: "220px"}}/>
                            </Form.Item>

                            <Form.Item name="doc_name">
                                <Input placeholder="Sub Type Name" 
                                    allowClear
                                    style={{borderRadius: 5}}
                                />
                            </Form.Item>

                            <Form.Item name="doc_name_en">
                                <Input placeholder="Sub Type Name (EN)" 
                                    allowClear
                                    style={{borderRadius: 5}}
                                    onChange={(e) => setFilterEN(e.target.value)} 

                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button 
                                    onClick={() => {
                                        setFilterEN(undefined);
                                        searchFormProps.form?.resetFields()
                                        searchFormProps.form?.submit()
                                    }} 
                                    htmlType="submit" 
                                    style={{borderRadius: "5px"}}
                                    icon={<Icons.UndoOutlined />}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                        
                    </Col>
                    <Col span={2}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Form {...searchFormProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={"N"}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={()=> searchFormProps.form?.submit()} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={dataCUSTOM?.data.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={dataCUSTOM?.data.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableProps?.dataSource?.length,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    <Table.Column title="DOCUMENT TYPE" width={150}
                        sorter={(a:any, b:any) => (a?.document_type?.doc_name || 'ไม่ระบุ').localeCompare(b?.document_type?.doc_name || 'ไม่ระบุ')}
                        render={(value:any,record:any)=>{
                            return <>
                                <Tag color={"#E8CE26"} style={{border: "1px solid #DAC32F"}}>{record?.document_type? record?.document_type.doc_name :"ไม่ระบุ"}</Tag>
                            </>
                        }}
                    />

                    <Table.Column dataIndex="doc_name" title="DOCUMENT SUB TYPE NAME" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.doc_name < b?.doc_name) {return -1}
                            if (a?.doc_name > b?.doc_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value? value : "-"}  />
                            </>
                        }}
                    />

                    <Table.Column 
                        title="DOCUMENT SUB TYPE NAME (EN)" 
                        width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.localizations[0]?.doc_name < b?.localizations[0]?.doc_name) {return -1}
                            if (a?.localizations[0]?.doc_name > b?.localizations[0]?.doc_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record && record.localizations.length > 0 && record.localizations[0].locale == "en" ?
                                        <TextField value={record ? record.localizations[0].doc_name : "-"} />     
                                    : <>-</>   
                                }                            
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => (a?.createBy?.first_name|| '-').localeCompare(b?.createBy?.first_name|| '-')}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.createdAt < b.createdAt) {return -1}
                        if (a.createdAt > b.createdAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField style={{fontWeight: 600}} value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField style={{fontWeight: 600}} value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => (a?.updateBy?.first_name|| '-').localeCompare(b?.updateBy?.first_name|| '-')}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={100}
                     sorter={(a:any, b:any) => {
                        if (a.updatedAt < b.updatedAt) {return -1}
                        if (a.updatedAt > b.updatedAt) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField style={{fontWeight: 600}} value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField style={{fontWeight: 600}} value={'-'}  />
                                }
                            </>
                        }}
                    />
                  
                    <Table.Column<any>
                        width={80}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        render={(_, record) => (
                            <Space>
                                 {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                onClick={()=> editModalShow(record.id)}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />

                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...createModalProps}
                title={"Document Sub Type Create"}
                okText = {"Create"}
                width={450}
                afterClose={()=> {
                    setPicID(undefined);
                    createProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.document_type = values.document_type.id;
                        values.doc_name = values.doc_name;
                        values.createBy = user_data?.user_profile.id;
                        values.deleteStatus = "N";
                        values.icon = vPicID

                        values.locale = "th"
                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["document_type", "id"]}
                                label="Document Type"
                                rules={[
                                    {
                                        required: true, message: "Please select document type"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selDocType}/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="doc_name"
                                label="Document Sub Type Name"
                                rules={[
                                    {
                                        required: true, message: "Please input sub type name"
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="icon"
                                label="Icon"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload icon.'
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    style={{borderRadius: 10}}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}

                                    listType="picture"
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Document Sub Type Edit"}
                okText = {"Save"}
                width={450}
                afterClose={()=> {
                    // setImg([])
                    setPicID(undefined);
                    editProps?.form?.resetFields();
                    swLocal= true;
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {    
                        values.document_type = values?.document_type.id
                        if(swLocal == true){
                            values.locale = "th";
                            values.updateBy = user_data?.user_profile.id;
                            values.icon = vPicID

                            return (
                                editProps.onFinish &&
                                editProps.onFinish(values)
                            );
                        }else{
                            updateLocaleEn(values);
                            updateUserUpdateBy();
                        }
                    }}
                >
                    <Row gutter={16}>
                        <Radio.Group 
                            options={optionsRdioLocale} 
                            onChange={(e)=> {
                                // console.log(e.target.value)
                                setswLocal(e.target.value)
                                swLocal = e.target.value
                                loadLocal();
                            }}
                            defaultValue={true}
                            optionType="button"
                            style={{marginBottom: "2rem"}}
                        />

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["document_type", "id"]}
                                label="Document Type"
                                rules={[
                                    {
                                        required: true, message: "Please select document type"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selDocType} disabled={vswLocal == false ? true : false}/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="doc_name"
                                label="Document Sub Type Name"
                                rules={[
                                    {
                                        required: true, message: "Please input sub type name"
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name="icon"
                                label="Icon"
                                // valuePropName="fileList"
                                // getValueProps={(data) => getValueProps(data, API_URL)}
                                // rules={[
                                //     {
                                //         required: true, message: 'Please upload icon.'
                                //     },
                                // ]}
                            >
                                {/* <Dragger 
                                    {...vImg}
                                    style={{borderRadius: "10px"}} 
                                    // style={{ borderRadius: 10 }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Dragger> */}
                                <Dragger style={{borderRadius: "10px"}} 
                                    {...vImg}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${TOKEN}`,
                                    }}
                                    listType="picture"
                                    // multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Dragger>
                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}