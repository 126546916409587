import { Breadcrumb, Card, Col, Create, Form, Input, List, Radio, Row, Select, useForm, useSelect } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useGetLocale, useTranslate } from "@pankod/refine-core"
import { IProperties, IvehicleType } from "interfaces";
import { useState } from "react";

export const EstampConfigCreate: React.FC<IResourceComponentsProps> = () => {
    
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null;

    const [isCharge, setCharge] = useState<boolean>(false);
    const [isChargeM, setChargeM] = useState<boolean>(false);

    const { form, formProps: createProps, saveButtonProps } = useForm<any>();

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data
            // if(dataSet?.length == 1){
            //     setProp(dataSet[0]?.id)
            //     createProps?.form?.setFieldsValue({
            //         property: dataSet[0]?.id
            //     })
            // }
        },}
    });

    const { selectProps: vehicleSelectProps } = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        filters:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ],
    })
    
    return (
        <Create 
            title={t("e-stamp-config.title-create")}
            saveButtonProps={saveButtonProps} 
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item>{t("e-stamp-config.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("e-stamp-config.title-create")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                extra: <></>
            }}
        >
            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", width: '100%'}}>
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.createBy = Identity_data?.user_profile?.id

                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={[12,12]}>
                        <Col span={2} className="gutter-row">
                            <div style={{marginTop: "7px"}}>{t("Property")}</div>
                        </Col>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                name={"property"}
                                label=" "
                                className="disable_req none_cell"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                                style={{marginBottom: "0"}}
                            >
                                <Select placeholder={t("select.sel")} {...propertySelectProps}/>
                            </Form.Item>
                        </Col>
                        <Col span={16} className="gutter-row"/>
                        <Col span={2} className="gutter-row">
                            <div style={{marginTop: "7px"}}>{t("ค่าบริการ")}</div>
                        </Col>
                        <Col span={22} className="gutter-row">
                            <Form.Item
                                name={"is_charge"}
                                label=" "
                                className="disable_req none_cell"
                                rules={[
                                    {
                                        required: true, message: "Please select"
                                    },
                                ]}
                                style={{marginBottom: "0"}}
                                initialValue={false}
                            >
                                <Radio.Group onChange={(e)=> setCharge(e?.target?.value)} defaultValue={false}>
                                    <Radio value={false}><span>{t("ฟรี")}</span></Radio>
                                    <Radio value={true}><span>{t("มีค่าใช้จ่าย")}</span></Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {isCharge == true &&
                        <>
                            <Col span={2} className="gutter-row">
                                <div style={{marginTop: "7px"}}>{t("วิธีคิดค่าค่าบริการ")}</div>
                            </Col>
                            <Col span={22} className="gutter-row">
                                <Form.Item
                                    name={"charge_method"}
                                    label=" "
                                    className="disable_req none_cell"
                                    rules={[
                                        {
                                            required: true, message: "Please select"
                                        },
                                    ]}
                                    style={{marginBottom: "0"}}
                                    initialValue={false}
                                >
                                    <Radio.Group onChange={(e)=> setChargeM(e?.target?.value)} defaultValue={false}>
                                        <Radio value={false}><span>{t("คิดเป็นรายชั่วโมง")}</span></Radio>
                                        <Radio value={true}><span>{t("เหมาจ่าย")}</span></Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </>
                        }
                        {isCharge == true && isChargeM == false &&
                        <>
                            <Col span={2} className="gutter-row">
                                <div style={{marginTop: "7px"}}>{t("เวลาเริ่มต้น")}</div>
                            </Col>
                            <Col span={2} className="gutter-row">
                                <Form.Item
                                    name={"start_hour"}
                                    label=" "
                                    className="disable_req none_cell"
                                    rules={[
                                        {
                                            required: true, message: "Please enter start time"
                                        },
                                    ]}
                                    style={{marginBottom: "0"}}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={1} className="gutter-row">
                                <div style={{marginTop: "7px"}}>{t("ถึง")}</div>
                            </Col>
                            <Col span={2} className="gutter-row">
                                <Form.Item
                                    name={"end_hour"}
                                    label=" "
                                    className="disable_req none_cell"
                                    rules={[
                                        {
                                            required: true, message: "Please enter end time"
                                        },
                                    ]}
                                    style={{marginBottom: "0"}}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={17} className="gutter-row"/>

                            {/* <Col span={2} className="gutter-row">
                                <div style={{marginTop: "7px"}}>{t("จำนวน")}</div>
                            </Col>
                            <Col span={2} className="gutter-row">
                                <Form.Item
                                    name={"charge_rate"}
                                    label=" "
                                    className="disable_req none_cell"
                                    rules={[
                                        {
                                            required: true, message: "Please enter charge rate"
                                        },
                                    ]}
                                    style={{marginBottom: "0"}}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={1} className="gutter-row">
                                <div style={{marginTop: "7px"}}>{t("บาท")}</div>
                            </Col> */}

                            {/* <Col span={19} className="gutter-row"/> */}
                        </>
                        }
                        {/* <Col span={17} className="gutter-row"/> */}

                        {
                            isCharge == true && <>
                            <Col span={2} className="gutter-row">
                                <div style={{marginTop: "7px"}}>{t("จำนวน")}</div>
                            </Col>
                            <Col span={2} className="gutter-row">
                                <Form.Item
                                    name={"charge_rate"}
                                    label=" "
                                    className="disable_req none_cell"
                                    rules={[
                                        {
                                            required: true, message: "Please enter charge rate"
                                        },
                                    ]}
                                    style={{marginBottom: "0"}}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={1} className="gutter-row">
                                <div style={{marginTop: "7px"}}>{t("บาท")}</div>
                            </Col>

                            </>
                        }
                        

                        <Col span={2} className="gutter-row">
                            <div style={{marginTop: "7px"}}>{t("ประเภทรถ")}</div>
                        </Col>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                name={"vehicle_type"}
                                label=" "
                                className="disable_req none_cell"
                                rules={[
                                    {
                                        required: true, message: "Please select vehicle type"
                                    },
                                ]}
                                style={{marginBottom: "0"}}
                            >
                                <Select placeholder={t("select.sel")} {...vehicleSelectProps}/>
                            </Form.Item>
                        </Col>
                        <Col span={16} className="gutter-row"/>
                    </Row>
                </Form>
            </Card>
        </Create>
    )
}