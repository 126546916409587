/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, EditButton, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, CreateButton, InputNumber, Card, DatePicker, Spin } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useTranslate, useUpdate } from "@pankod/refine-core";
// import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { petURL } from "../../constants";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { IProperties, IUnit } from "interfaces";
import dayjs from "dayjs";

// let dataTB: any = undefined;

export const SuggestionList: React.FC<IResourceComponentsProps> = () => {
    const [form] = Form.useForm();
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    // let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Suggestion");
    // let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const prop_id = localStorage.getItem("PROPERTY_ID");

    const [pageSize, setPageSize] = useState<any>(10);
    const [ vPropertyInput, setPropertyInput] = useState<any>();
    const { confirm } = Modal;
    const [isLoading, setLoading] = useState<boolean>(true);
    const [dataUnit, setDataUnit] = useState<any>();
    const [dataTB, setDataTB] = useState<any>();
    
    const { data, refetch } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllSuggestion`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                // console.log(">>> dataLoad", dataLoad)

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item?.property?.id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e?.property?.id == Number(prop_id))
                }

                //dataTB = dataLoad;
                setDataTB(dataLoad)
                setLoading(false);
            },
        },
    });

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: selectUnit } = useSelect<IUnit>({
        resource    : "units",
        optionLabel : "unit_name",
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "property][id]",
                operator: "eq",
                value: !!vPropertyInput ? vPropertyInput : null
            }
        ]
    });

    async function updateStatus(id:any, val:any){
        console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.put(
            `${petURL}/api/lscpet/updateSuggestion?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        // refreshDataSource()
        refetch();
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                // }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                // return new Promise((resolve, reject) => {
                //     setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                // }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: 'PROPERTIES',
            dataIndex: 'property',
            key: 'property',
            width: 100,
            sorter: (a:any, b:any) => {
                if (a?.property?.property_name < b?.property?.property_name) {return -1}
                if (a?.property?.property_name > b?.property?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.property ? record?.property?.property_name : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: 'UNIT',
            dataIndex: 'unit',
            key: 'unit',
            width: 100,
            sorter: (a:any, b:any) => {
                if (a?.unit?.unit_name < b?.unit?.unit_name) {return -1}
                if (a?.unit?.unit_name > b?.unit?.unit_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.unit ? record?.unit?.unit_name : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: 'หัวข้อ',
            dataIndex: 'suggest_cate',
            key: 'suggest_cate',
            width: 100,
            sorter: (a:any, b:any) => {
                if (a?.suggestion_category_obj[0]?.suggest_cat_name < b?.suggestion_category_obj[0]?.suggest_cat_name) {return -1}
                if (a?.suggestion_category_obj[0]?.suggest_cat_name > b?.suggestion_category_obj[0]?.suggest_cat_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('filteredData', filteredData)
                return (
                    <>
                            
                    { record?.suggestion_category_obj ? record?.suggestion_category_obj[0]?.suggest_cat_name : '-'}
                    </>
                )
            },
        },
        {
            title: 'ข้อเสนอแนะ',
            dataIndex: 'description',
            key: 'description',
            width: 200,
            sorter: (a:any, b:any) => {
                if (a?.suggestion_description < b?.suggestion_description) {return -1}
                if (a?.suggestion_description > b?.suggestion_description) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>
                        {
                            record?.suggestion_description ? record?.suggestion_description : '-'
                        }
                    </span>
                )
            },
        },
        {
            title: 'CREATED BY',
            dataIndex: 'create_by',
            key: 'create_by',
            width: 90,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.create_by?.first_name < b?.create_by?.first_name) {return -1}
                if (a?.create_by?.first_name > b?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record?.create_by?.first_name ? record?.create_by?.first_name : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            width: 90,
            sorter: (a:any, b:any) => a.job_obj[0]?.create_date.localeCompare(b.job_obj[0]?.create_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'UPDATE BY',
            dataIndex: 'update_by',
            key: 'update_by',
            width: 90,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.update_by?.first_name < b?.update_by?.first_name) {return -1}
                if (a?.update_by?.first_name > b?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record?.update_by?.first_name ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'UPDATE DATE',
            dataIndex: 'update_date',
            key: 'update_date',
            width: 90,
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.update_date !== null ? dayjs(record.update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 60,
            align: 'center',
            render: (value:any, record:any) => {
                return (
                    <>
                    {
                        record.is_active ? 
                            <Button 
                                size="small" 
                                title="Delete" 
                                icon={<Icons.DeleteOutlined />} 
                                onClick={() => { showDelConfirm(record._id) }} 
                                danger
                            ></Button>
                        :
                            <Button 
                                size="small" 
                                title="Active" 
                                icon={<Icons.RetweetOutlined />} 
                                onClick={() => { showUnlockConfirm(record._id) }} 
                            ></Button>
                    }
                    </>
                );
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const onReset = () => {
        setPropertyInput(undefined);
        setDataUnit(undefined);
        form.resetFields();
        refetch();
    }

    function onSearchtable(dataPro:any, unit:any) {
        let newData = dataTB
        console.log("dataTB",newData)
        console.log("dataPro",dataPro)
        console.log("unit",unit)

        if (!!dataPro && !unit) {
            let dataFilter = newData.filter((e:any) => e?.property?.id == dataPro)
            //console.log("dataFilter1",dataFilter)
            setDataTB(dataFilter)
        }else if (!!dataPro && !!unit) {
            let dataFilter = newData.filter((e:any) => e?.property?.id == dataPro && e?.unit?.id == unit)
            //console.log("dataFilter2",dataFilter)
            setDataTB(dataFilter)
        }
         
    }

    return <>
        <List 
            title={t("suggestion.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("suggestion.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("suggestion.title")}</span></Breadcrumb.Item> */}
                </Breadcrumb>,
                extra : <></>
            }}
        >

            <Row>
                <Col span={24}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                        form={form}
                    >

                        <Form.Item name="property_name" >
                            <Select {...propertySelectProps} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '180px'}} allowClear
                                onChange={value => { 
                                    setPropertyInput(value);
                                }} 
                            />
                        </Form.Item>

                        <Form.Item name="selUnit">
                            <Select 
                                {...selectUnit}
                                placeholder="Select unit"
                                size="middle"
                                allowClear
                                onChange={(e) => setDataUnit(e)}
                                style={{borderRadius: 5, width: '130px'}}
                            />
                        </Form.Item>
                    
                        <Form.Item >
                            <Button onClick={() => onSearchtable(vPropertyInput,dataUnit)} htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => onReset()} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>

                </Col>
            </Row>

            <div className="layout_panel">
                <Spin size="large" spinning={isLoading}>
                    <Table 
                        dataSource={dataTB ? dataTB : []} 
                        columns={columns}
                        pagination={{
                            size: "small",
                            position: ['bottomRight'],
                            pageSizeOptions: ['10', '30', '50', '100'], 
                            showSizeChanger: true, 
                            pageSize: pageSize,
                            onChange: handlePageSizeChange,
                        }}
                        scroll={{ x: 1500 }}
                    />
                </Spin>
            </div>

        </List>
    </>
}