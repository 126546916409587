/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Form, Input, List, Row, Select, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IEventlog, IFacility, IFacilityCheckinQR, IProperties } from "interfaces";
// import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";

// import {TinyMceEditor} from "../../components/ck";

export const FaciiltyGenCheckInEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint          = "even-logs";

    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form, formProps, saveButtonProps } = useForm<IFacilityCheckinQR,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'coupon',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );
    // const API_URL = useApiUrl();
    // const { ...uploadProps } = useStrapiUpload({ maxCount: 1, });
    const { queryResult } = useShow<IFacilityCheckinQR>({  
        metaData: {
            // populate: '*'
            populate: ['facility', 'facility.name', 'facility.property']
        }, 
    });
    const { data } = queryResult;
    // const record = data?.data;
    let recordData  :   any;
    recordData = queryResult?.data ? queryResult?.data : [];
    recordData = data?.data? data?.data : [];

    const [ vProperty, setProp] = useState<any>();
    const [ vFacility, setFacility] = useState<any>();
    // const [ vPropertyName, setPropName] = useState<any>();
    // const [ vFacilityName, setFacilityName] = useState<any>();
    const [count, setCount] = useState(0)

    // console.log('vProperty', vProperty)
    // console.log('vFacility', vFacility)
    // console.log('vProperty', vPropertyName)
    // console.log('vFacility', vFacilityName)

    useEffect(() => {
        if (count < 2) {
          const interval = setInterval(() => {
            setCount(prev => prev + 1)

            recordData.facility ? setFacility(recordData.facility.id) : setFacility(null)
            recordData.facility ? setProp(recordData.facility.property.id) : setProp(null)

            // recordData.facility ? setFacilityName(recordData.facility.name) : setFacilityName('-')
            // recordData.facility ? setPropName(recordData.facility.property.property_name) : setPropName('-')

            // const fileList: UploadFile[] = 
            //     recordData.coupon_pix ?
            //         recordData.coupon_pix.length > 0 ?
            //             recordData.coupon_pix.map((v:any)=>(
            //                 {
            //                     uid: String(v.id),
            //                     name: v.name,
            //                     status: "done",
            //                     url:
            //                         v.url,
            //                     thumbUrl:
            //                         v.url
            //                 }
            //             )) 
            //     : null : null;

            // setCouponPix({
            //     name: 'files',
            //     multiple: true,
            //     defaultFileList: [...fileList],
            // })

            // form.resetFields(["coupon_pix"]);

            form.setFieldsValue({
                // properties: 
                //     recordData?.properties !== undefined ?
                //         recordData?.properties.length > 0 ? 
                //             recordData?.properties.map((v:any)=>{
                //                 // console.log(v)
                //                 return {value:v.id, label:v.property_name}
                //             }) 
                //         : form.resetFields(["properties"])
                //     : form.resetFields(["properties"]),

                // notice_customer_type:
                //     recordData?.notice_customer_type !== undefined ?
                //         recordData?.notice_customer_type.length > 0 ? 
                //             recordData?.notice_customer_type.map((v:any)=>{
                //                 console.log(v)
                //                 return {value:v.id, label:v.sub_type_name}
                //             }) 
                //         : form.resetFields(["notice_customer_type"])
                //     : form.resetFields(["notice_customer_type"]),
                
                // user_sub_types: 
                //     recordData?.user_sub_types !== undefined ?
                //         recordData?.user_sub_types.length > 0 ? 
                //             recordData?.user_sub_types.map((v:any)=>{
                //                 // console.log(v)
                //                 return {value:v.id, label:v.sub_type_name}
                //             })
                //         : form.resetFields(["user_sub_types"])
                //     : form.resetFields(["user_sub_types"]),

                // name_en: recordData.localizations.length > 0 ? recordData.localizations[0].name : '',
                // short_detail_en: recordData.localizations.length > 0 ? recordData.localizations[0].short_detail : ''
                facility: recordData.facility ? recordData.facility.name : '',
                property: recordData.facility ? recordData.facility.property.property_name : '-',
            })

          }, 1000)

          return () => clearInterval(interval)
        }
    }, [count])


    const { selectProps: SelProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field: "id",
            //     operator: "eq",
            //     value: PropListData
            // }
        ]   
    });


    const { selectProps: SelFacility } = useSelect<IFacility>({
        resource: "facilities",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 50,
        filters:[
            // {
            //     field: "facility_type][id]",
            //     operator: "eq",
            //     value: vFacilityType
            // },
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            // {
            //     field       : 'no_booking',
            //     operator    : 'eq',
            //     value       :  false,
            // },
            {
                field       : 'property][id]',
                operator    : 'eq',
                value       :  vProperty,
            }
        ]
    });

    // console.log("testttt",queryResult)

    // const [switchToggle, setSwitchToggle] = useState(queryResult.data?.data.coupon_active);

    // const onChange_sw = (checked: any) => {
    //     setSwitchToggle(checked);
    // };

    // const { selectProps: Selpptype } = useSelect<IPPType>({
    //     resource: "pp-types",
    //     optionLabel: "pp_name",
    //     optionValue: "id",
    //     // filters: [initF]
    // });

    function onchangeProp(value:any) {
        setProp(value);
    }

    function onchangeFacility(value:any) {
        setFacility(value);
    }

    // function fileChange(e:any){
    //     console.log(e);
    //     if(e.file.response){
    //         let res = e.file.response;
    //         console.log(res[0]);
    //         //setPhotoList(res[0]);
            
    //     }
    // }
    // const beforeUpload = (file: RcFile) => {
    //     const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    //     if (!isJpgOrPng) {
    //       message.error('You can only upload JPG/PNG file!');
    //     }
    //     const isLt2M = file.size / 1024 / 1024 < 5;
    //     if (!isLt2M) {
    //       message.error('Image must smaller than 5MB!');
    //     }
    //     return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    // };
      
    return (
    <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("fclt-gen-qr.create")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-gen-qr.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.createBy = user_data.user_profile.id
                values.facility = vFacility
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={16}>
                    <Card style={{ borderRadius: 5, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Row>
                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("property.title")} name="property"
                                    rules={[
                                        {
                                            required: true, message: 'Please select property.'
                                        },
                                    ]}
                                >
                                    <Select 
                                        placeholder={t("select.sel")} 
                                        onChange={onchangeProp} 
                                        {...SelProp}
                                        // value={vPropertyName}
                                        // value={vProperty}
                                        // defaultValue={vProperty}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={1}></Col>

                            <Col span={8}>
                                <Form.Item  label={t("fclt-gen-qr.f-facility")} name="facility"
                                    rules={[
                                        {
                                            required: true, message: 'Please select facility.'
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={t("select.sel")}
                                        {...SelFacility}
                                        onChange={onchangeFacility}
                                        // value={vFacilityName}
                                        // value={vFacility}
                                        // defaultValue={vFacility}
                                        disabled
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col className="gutter-row" span={24}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("fclt-gen-qr.f-title")} name="title"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter title.'
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Form.Item  label={t("fclt-gen-qr.f-code")} name="code"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'Please enter coupon code.'
                                            //     },
                                            // ]}
                                        >
                                            <Input  disabled/>
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" span={24}>
                                        <Form.Item  label={t("fclt-gen-qr.f-note")} name="note"
                                            // rules={[
                                            //     {
                                            //         required: true, message: 'Please enter title.'
                                            //     },
                                            // ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>


                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>
    );
};