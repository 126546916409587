/* eslint-disable */
import { Breadcrumb, Button, Checkbox, Col, CreateButton, Drawer, EditButton, Form, Icons, Input, List, Modal, Row, Space, Table, TextField, useDrawerForm, useModalForm, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IModuleAccess, IRManage } from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import type { ColumnsType } from "antd/es/table";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import axios from "axios";
import { TOKEN_KEY, API_URL } from "../../constants";
const AuthStr = 'Bearer '.concat(TOKEN_KEY);

let recordData :any = undefined;
export const RoleList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const userQuery = useGetIdentity();

    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    console.log('data_permission', data_permission)
    const {data : user_data ,isLoading} = userQuery;
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const { mutate: updateModule } = useUpdate<IModuleAccess>();
    const { mutate: updateRole } = useUpdate<any>();
    const [moduleAccState, setModuleAccState] = useState<IModuleAccess[]>([]);
    const [Customname, setCustomname] = useState<any>()
    const [roleName, setRoleName] = useState<any>()

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IRManage,HttpError,IRManage>({
        metaData: {
            populate: '*'
        },
        onSearch(params) {
            const filters: CrudFilters = [];

                filters.push(
                    {
                        field       : "custom_role_name",
                        operator    : "contains",
                        value       : !!roleName ? roleName : null
                    },
                );

            return filters;
        },
        initialFilter:[
            {
                field       : "custom_role_name",
                operator    : "ne",
                value       : "admin"
            },
        ],
    });

    const { tableProps: table_moduleAcc } = useTable<IModuleAccess,HttpError, IModuleAccess>({ 
        resource: "module-accesses",
        metaData: {
            populate: '*'
        },
        initialSorter: [
            {
                field: "id",
                order: "asc",
            },
        ],
    });

    const {refetch} = useList<IModuleAccess>({
        resource: "module-accesses",
        metaData: {
          populate: ["role", "role.role_master", "module_master"],
        },
        config: {
            pagination:{current: 1, pageSize: 200},
            sort: [{ order: "asc", field: "module_master.module_code" }],
            filters: [{ field: "role][id]", operator: "in", value: recordData? recordData : 1 }],
        },
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;

            if (dataLoad && dataLoad?.length > 0) {
                setModuleAccState(
                    dataLoad?.map((val) => ({
                    ...val,
                  }))
                );
            }
        }}
    });

    const columns: ColumnsType<IModuleAccess> = [
        {
          title: "Menu",
          dataIndex: "menu",
          key: "menu",
          width: 200,
          render: (_, record) => <>{record?.module_master?.module_name}</>,
        },
        {
            title: "View",
            key: "view",
            dataIndex: "view",
            align: "center",
            width: 70,
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.view} 
                  onChange={(e) => onChangePermissions2(record, "view", e)}
                />
              </>
            ),
          },
        {
          title: "Create",
          key: "create",
          dataIndex: "create",
          align: "center",
          width: 70,
          render: (_, record) => (
            <>
              <Checkbox
                checked={record.create}
                onChange={(e) => onChangePermissions2(record, "create", e)}
                // disabled
              />
            </>
          ),
        },
        {
            title: "Update",
            key: "update",
            dataIndex: "update",
            align: "center",
            width: 70,
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.update}
                  onChange={(e) => onChangePermissions2(record, "update", e)}
                //   disabled
                />
              </>
            ),
          },
        // {
        //   title: "Delete",
        //   key: "delete",
        //   dataIndex: "delete",
        //   align: "center",
        //   width: 70,
        //   render: (_, record) => (
        //     <>
        //       <Checkbox
        //         checked={record.delete}
        //         onChange={(e) => onChangePermissions2(record, "delete", e)}
        //         // disabled
        //       />
        //     </>
        //   ),
        // },
        // {
        //     title: "Assign",
        //     key: "assign",
        //     dataIndex: "assign",
        //     align: "center",
        //     width: 70,
        //     render: (_, record) => (
        //       <>
        //         <Checkbox
        //           checked={record.assign}
        //           onChange={(e) => onChangePermissions2(record, "assign", e)}
        //         //   disabled
        //         />
        //       </>
        //     ),
        //   },
        //   {
        //     title: "Approve",
        //     key: "approve",
        //     dataIndex: "approve",
        //     align: "center",
        //     width: 70,
        //     render: (_, record) => (
        //       <>
        //         <Checkbox
        //           checked={record.approve}
        //           onChange={(e) => onChangePermissions2(record, "approve", e)}
        //         //   disabled
        //         />
        //       </>
        //     ),
        //   },
    ];

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose
    } = useModalForm<any>(
        {
            resource: "role-managements",
            metaData: {
                populate: '*',
            },
            action: "create",
            redirect: false,
            async onMutationSuccess(data, variables, context) {
                const {id, attributes} = data.data.data
                // console.log('id', id)
                // console.log('attributes', attributes)
                // console.log('API_URL', API_URL)

                let res = await axios.get(API_URL + "/api/like-systems/createModuleAccess?role_id=" + id, {
                    headers: { Authorization: AuthStr }
                });
                // console.log('res', res)

            },
        }
    );

    const { 
        drawerProps: editDrawerProps,
        formProps: editProps,
        show: editDrawerShow,
        close: editDrawerClose
        // saveButtonProps
    } = useDrawerForm<any>({
        resource: "role-managements",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;

            setCustomname(dataLoad?.custom_role_name)
        }}
    });

    const onChangePermissions2 = (record: any, key: string, e: CheckboxChangeEvent) => {
        switch(key) {
            case 'view':
                updateModule({resource: "module-accesses",id: record.id,values: {view: e.target.checked},successNotification: false});
                break;
            case 'create':
                updateModule({resource: "module-accesses",id: record.id,values: {create: e.target.checked},successNotification: false});
                break;
            case 'update':
                updateModule({resource: "module-accesses",id: record.id,values: {update: e.target.checked},successNotification: false});
                break;
            case 'delete':
                updateModule({resource: "module-accesses",id: record.id,values: {delete: e.target.checked},successNotification: false});
                break;
            case 'assign':
                updateModule({resource: "module-accesses",id: record.id,values: {assign: e.target.checked},successNotification: false});
                break;
            case 'approve':
                updateModule({resource: "module-accesses",id: record.id,values: {approve: e.target.checked},successNotification: false});
                break;
            default:
              return 'foo';
        }
    }

    function createAndRedirect(values:any){
        values.createBy = user_data.user_profile.id
        values.role_master = 1
        return (
            createProps?.onFinish &&
            createProps?.onFinish(mediaUploadMapper(values))
        );
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }
 
    function fupdateRole(id:any){
        updateRole({
            resource    : "role-managements",
            id          : id,
            values      : {
                custom_role_name: Customname,
                last_editor_user: Identity_data?.user_profile?.id
            },
            errorNotification:false,
            successNotification:false
        },{
            onSuccess: async(data: any, link: any) =>{
                editDrawerClose();
            }
         });
    }
  

    //const [seed, setSeed] = useState();


    async function fCheckAll(id:any, type:any){
        // console.log('id', id)
        let res = await axios.get(API_URL + `/api/like-systems/checkPermissionAll?role_id=${id}&type=${type}`, {
            headers: { Authorization: AuthStr }
        });
        // console.log('check all ',res)
        editDrawerClose();
        refetch();
    }

    const onReset = () => {
        setRoleName(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    }

    return <>
        <List 
            title={t("role.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("role.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("role.list")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                    >
                        <Form.Item name="title">
                            <Input onChange={(e) => setRoleName(e.target.value)} style={{borderRadius: 4,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                        </Form.Item>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={6}>
                    <Form
                        {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"2%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Table {...tableProps} 
                bordered
                size="middle"
                rowKey= "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    total:tableQueryResult?.data?.total, 
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false
                }} 
            >
                {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                <Table.Column title="ROLE NAME" 
                    sorter={(a:any, b:any) => {
                        if (a?.custom_role_name < b?.custom_role_name) {return -1}
                        if (a?.custom_role_name > b?.custom_role_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={record?.custom_role_name}  />
                    }}
                />

                <Table.Column title="CREATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.user_creator?.first_name < b?.user_creator?.first_name) {return -1}
                        if (a?.user_creator?.first_name > b?.user_creator?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField style={{fontWeight: 600}} value={record?.user_creator? record?.user_creator.first_name :"-"}  />
                    }}
                />

                <Table.Column title="CREATE DATE" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.createdAt < b?.createdAt) {return -1}
                        if (a?.createdAt > b?.createdAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={record?.createdAt ? Moment(record?.createdAt).format('D/MM/yyyy HH:mm') : '-'}  />
                    }}
                />

                <Table.Column title="UPDATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.last_editor_user?.first_name < b?.last_editor_user?.first_name) {return -1}
                        if (a?.last_editor_user?.first_name > b?.last_editor_user?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        //console.log("first",record)
                        return <TextField style={{fontWeight: 600}} value={record?.last_editor_user? record?.last_editor_user.first_name :"-"}  />
                    }}
                />

                <Table.Column title="LAST UPDATE" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.updatedAt < b?.updatedAt) {return -1}
                        if (a?.updatedAt > b?.updatedAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <TextField value={record?.updatedAt ? Moment(record?.updatedAt).format('D/MM/yyyy HH:mm') : '-'}  />
                    }}
                />

                <Table.Column<IRManage>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed = "right"
                    render={(_, record) => (
                        <Space>
                            {/* <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id} 
                                disabled
                            /> */}
                            <EditButton 
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={()=> {
                                    editDrawerShow(record.id);
                                    recordData = record.id;
                                }}
                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            />
                        </Space>
                    )}
                />
            </Table>
        </List>

        <Modal 
            {...createModalProps}
            title={"Role Management Create"}
            okText = {"Create"}
            maskClosable={false}
            width={400}
            afterClose={()=> {
                createProps?.form?.resetFields();
            }}
        >
            <Form 
                {...createProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    createAndRedirect(values);
                }}
            >
                <Row gutter={8}>
                    <Col span={24} className="gutter-row">
                        <Form.Item  label={t("role.name")} name="custom_role_name"
                            rules={[{ required: true, message: 'Please enter role name.'}]}>
                                <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Drawer 
            {...editDrawerProps}
            title="Role Management Edit"
            width={900}
            maskClosable={false}
            onClose={()=> {
                recordData = undefined;
                setCustomname(undefined)
                editDrawerClose();
            }}
        >
            <Row gutter={8}>
                <Col span={20}>
                    <Form.Item  label={t("role.name")}
                        rules={[{required: true, message: 'Please enter role name.'}]}
                    >
                        <Input value={Customname} onChange={(e: any) => setCustomname(e.target.value)}/>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Button style={{width: "100%"}} type="primary" onClick={()=> fupdateRole(recordData)}>Update Role</Button>
                </Col>
            </Row>

            <Row gutter={8} >

                <Col span={16}></Col>

                <Col span={4}>
                    <Button style={{width: "100%", float:'right'}} type="primary" onClick={()=> {fCheckAll(recordData, 'true')}}>Check All</Button>
                </Col>
                <Col span={4}>
                    <Button style={{width: "100%", float:'right'}} type="primary" onClick={()=> fCheckAll(recordData, 'false')}>Uncheck All</Button>
                </Col>

            </Row>

            <Table {...table_moduleAcc}
                columns={columns}
                dataSource={moduleAccState}
                pagination={false}
                className="scrolling_table"
                scroll={{y: "70vh"}}
                style={{boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",border: "2px solid #dedede"}}
            />
        </Drawer>
    </>
}