/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Edit, Form, Input, List, Radio, RadioChangeEvent, Row, Select, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useShow, useTranslate, useUpdate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IContactCenter, IProperties } from "interfaces";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
// import type { CheckboxChangeEvent } from 'antd/es/checkbox';
// import { CCreateLog } from "../../components/create_log/index";
import axios from "axios";
// import { TOKEN_KEY } from "../../constants";

export const RepairEmailGroupEdit: React.FC<IResourceComponentsProps> = () => {
    // const token = localStorage.getItem(TOKEN_KEY);
    const {data: Identity_data} = useGetIdentity<any>();
    const t = useTranslate();
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { TextArea } = Input;
    const [ vStat, setStat ] = useState(false);
    const { mutate: updateEmailGroup } = useUpdate<any>();

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let property_id = id[0]
    let setting_id = id[1]

    const [ vPropertyID, setPropertyID ] = useState<any>([]);
    // const [ vStaffs, setStaffs ] = useState<any>([]);
    const { replace, list} = useNavigation();
    
    // const [ vIsNewOrOld, setIsNewOrOld] = useState<boolean>();
    const [ vEmail, setEmail] = useState<any>("");
    const { form, formProps, saveButtonProps } = useForm<IContactCenter,HttpError,{}>(
        {
            resource: 'email-groups',
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // console.log('kkkkkkkkkkk')
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                // <CCreateLog
                //     endpoint={log_endpoint}
                //     data={{
                //         user_profile : user_data.user_profile.id,
                //         log_time_stamp : Date.now(),
                //         log_event : "Create",
                //         menu_event : 'coupon',
                //         SysPkID : recordData.data.id + ""
                //     }}
                // />
                // replace("majorcare/setup-hcu")
                // list('mjc-email-group')
                // replace("/majorcare/setup-hcu")
                window.history.back()
            },
        }
    );

    const [ count, setCount] = useState(0);
    const [ vPropCreate, setPropCreate ] = useState<any>([]);
    // const [ Email, SetEmail ] = useState<any>([]);

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "email-groups",
        metaData: {
            populate: [
                '*',
                'property',
            ],
            // locale:['th']
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                // value: recordData.id,
                value: id[0],
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            // console.log('data xxxx : ', data.data)
            let dataLoad = data.data
            console.log(dataLoad[0])
            // callFetchData();
            setEmail(dataLoad[0].email)
            // setPropertyID(dataLoad[0].property.id)
            vPropertyID.push(dataLoad[0].property.id);


            form.setFieldsValue({
                property:
                    dataLoad[0].property ?
                        dataLoad[0].property.property_name
                    : null,   
                email:
                    dataLoad[0].email ?
                        dataLoad[0].email
                    : null,   
            });
          },
        },
    });

        
    useEffect(() => {
        if (count < 3) { 
            const interval = setInterval(() => {
                setCount(prev => prev + 1)
                // console.log('asd')
                // getPropCreated();

            }, 1000)
            return () => clearInterval(interval)
        }
    }, [count])

    let resultQuery2:any = [];

    console.log('vPropCreate',vPropCreate[0])

    let initF : any;
    initF = {
        field       : 'deleteStatus',
        operator    : 'contains',
        value       :  'N',
    };

    const { selectProps: PropertySelectProps } = useSelect<IProperties>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        filters: [
            initF,
            {
                field : 'id',
                operator : 'nin',
                // value : 1
                // value :  ["1","2","3","7"],
                value :  vPropCreate[0],
            },
        ]
    });

    function onChangePropertyID(va:any){
        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
    };

    const optionsRadioStat = [
        { label: t("menumgn-create.stat1"), value: true },
        { label: t("menumgn-create.stat2"), value: false },
    ];

    const onChangeStat = ({ target: { value } }: RadioChangeEvent) => {
        // true = active
        // false = inactive
        console.log('radio stats checked', value);
        setStat(value);
    };

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value);
    }
 
    function saveData(){
        updateEmailGroup(
            {
                resource : 'email-groups',
                id : id[0] ,
                values : {
                    property : vPropertyID,
                    email : vEmail, 
                    updateBy : Identity_data ? Identity_data.user_profile.id : null
                },
            },{
                onSuccess: async(data: any, link: any) =>{
                    console.log('data', data)
                    replace("/repair/repair-setup")
                    // window.history.back();
                },onError(error, variables, context) {
                    // errornoti(error.response.status, error.response.data);
                },
            },
        )
    }

    return (
    <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            // title={t("banner-mgn.title-create")}
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("contact-center.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">Email Group Edit</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    console.log('kkkk',user_data.user_profile.id )

                    values.updateBy = user_data.user_profile.id
                    // values.default_contact = vCondition
                    // values.subject = "Appointment"
                    values.property = vPropertyID[0];
                    // values.deleteStatus = vStat == true ? "N" : "Y";
                    values.email = vEmail
                    // if(vIsNewOrOld == false){
                    //     values.contact_cc = vStaffs[0]
                    // }

                    saveData();
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(mediaUploadMapper(values))
                    // );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{marginBottom:'2%'}}></div>
                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Property : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        // name="privilege_properties"
                                        name="property"
                                        rules={[
                                            {
                                                required: true, message: 'Please select property.'
                                            },
                                        ]}
                                    >
                                        <Select 
                                            // size="small"
                                            style={{width:"100%"}}
                                            // mode="multiple"  
                                            // value={}
                                            placeholder={t("select.sel")}
                                            {...PropertySelectProps}
                                            onChange={onChangePropertyID}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={3}>
                                    <span>
                                        <b><div style={{fontSize: "12px"}}>Email : </div><>&nbsp;</></b>
                                    </span>
                                </Col>
                                <Col span={12}>
                                    <Form.Item 
                                        name="email"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter email.'
                                            },
                                        ]}
                                    >
                                        <TextArea 
                                            style={{height: "100px", maxHeight: "80px"}} 
                                            placeholder="seperate email by comma ex. testmail@mail.com,testmail2@mail.com" 
                                            onChange={(e) => onChangeEmail(e)} 
                                            value={vEmail}
                                            defaultValue={vEmail}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};