/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";

import axios from "axios";
import TextArea from "@uiw/react-md-editor/lib/components/TextArea";

export const HcuStructureCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [form] = Form.useForm();

    // const userQuery = useGetIdentity(); // Get User Profile
    const user_data:any = {
        id: Identity_data ? Identity_data?.user_profile.id : null,
        first_name: Identity_data ? Identity_data?.user_profile.first_name : null,
        last_name: Identity_data ? Identity_data?.user_profile.last_name : null,
        tel: Identity_data ? Identity_data?.user_profile.tel : null,
        email: Identity_data ? Identity_data?.user_profile.email : null,
        avatar: Identity_data ? Identity_data?.user_profile.avatar : null,
    }

    const t = useTranslate();
    const { replace, list} = useNavigation();

    const [ZoneTh, SetZoneTh] = useState<any>("");
    const [ZoneEn, SetZoneEn] = useState<any>("");
    const [ZoneDesc, SetZoneDesc] = useState<any>("");

    async function createZone(values:any) {

        let data = {
            "item_name": ZoneTh,
            "item_name_en": ZoneEn,
            "item_desc": ZoneDesc,
            "item_type": "6481f77fad20334eef199be2", // 6481f77fad20334eef199be2 == structure
            "is_default": 1,
            "create_by": user_data ? user_data : null
        }

        // console.log('data onFin', data)
        let res = await axios.post(majorCareUrl + "/majorcare/createItemList", data , { headers: { } }) 
        .then(response => {
            // Handle successful response
            console.log(response.data);
            successNoti('200', 'success');
            replace("/majorcare/master-hcu")
        })
        .catch(error => {
            // Handle error
            console.error('error ',error);
            errorNoti('200', 'success');
        });
        // console.log('res', res)
        // successNoti('200', 'success');
        // replace("/majorcare/master-hcu")
    }

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully create structure',
        });
    };

    const errorNoti = (stat:any, msg:any) => {
        notification.warning({
            message: "This name already exists in the system.",
            duration: 3,
        });
    };

    
    return (
    <Create 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-structure.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    createZone(values);
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={12} 
                            className="gutter-row" 
                            style={{
                                // borderRight: "1px solid #dedede",
                                paddingTop: "2.5rem"
                            }}
                        >
                            <Form.Item
                                name="item_name"
                                label="Name (TH)"
                                rules={[
                                    {
                                        required: true, message: "Please enter structure name"
                                    },
                                ]}
                            >
                                <Input placeholder="structure name th" onChange={(e) => SetZoneTh(e.target.value)} />

                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                // name="Inspector"
                                name="item_name_en"
                                label="Name (EN)"
                                rules={[
                                    {
                                        required: true, message: "Please enter structure name"
                                    },
                                ]}
                            >
                                <Input placeholder="structure name en" onChange={(e) => SetZoneEn(e.target.value)} />

                            </Form.Item>

                            <Row gutter={24} >
                                <Col span={24}>
                                    <Form.Item
                                        // name={["type", "value"]}
                                        // name="Inspector"
                                        name="item_desc"
                                        label="Description"
                                        // rules={[
                                        //     {
                                        //         required: true, message: "Please enter zone name"
                                        //     },
                                        // ]}

                                    >
                                        <Input 
                                            placeholder="structure desc" 
                                            onChange={(e) => SetZoneDesc(e.target.value)} 
                                        />

                                    </Form.Item>
                                </Col>
                                
                            </Row>
                           
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Create>   
    );
};