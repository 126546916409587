import { Col, DatePicker, Divider, Form, FormProps, Input, Radio, Row, Select, Spin, useSelect } from "@pankod/refine-antd";
import { useApiUrl, useGetIdentity, useList, useShow, useTranslate } from "@pankod/refine-core";
import { IAppointmentType, IProperties, IUnit, IUserhunit } from "interfaces";
import { useState } from "react";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
// import moment from "moment";
import { TOKEN_KEY } from "../../constants";
import axios from "axios";
interface IAPPOINTMENT {
    editProps?: FormProps<{}>,
    vConfirm?: any,
    recordData?: any,
    isLoading?: boolean
    setisLoading?: any
}

let appoindt: any = undefined;
let userhunitdt: any = undefined;
let userhunitdtDEEP: any = undefined;
let staffdt: any = undefined;
export const AppointmEdit: React.FC<IAPPOINTMENT> = ({editProps, vConfirm, recordData, isLoading, setisLoading}) => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    
    const {data: userdt} = useGetIdentity<any>();
    let userInProp = userdt?.property ? userdt?.property.id : null

    const [ valueProp, setProp ] = useState<any>();
    const [ valueUnit, setUnit ] = useState<any>();
    const [ valueReg, setReg ] = useState<boolean>(false);
    const [ valueappType, setappType ] = useState<any>();

    const [ valuecusID, setcusID] = useState<any>("");
    const [ valuecusName, setcusName ] = useState<any>("");
    const [ valuecusLastName, setcusLastName ] = useState<any>("");
    const [ valuecusPhone, setcusPhone ] = useState<any>("");
    const [ valuecusEmail, setcusEmail ] = useState<any>("");

    const [ valuereqDate, setreqDate ] = useState<any>("");
    const [ valuereqDateRT, setreqDateRT ] = useState<any>("");
    const [ timeslotdt, setslotdt ] = useState<any>([]);
    const [ valuetimeSlot, settimeSlot ] = useState<any>("");
    const [ valuetimeSlotRT, settimeSlotRT ] = useState<any>();
    const [ valueStatus, setStatus ] = useState<any>("");
    const [ valuestaffinProp, setstaffinProp ] = useState<any>();

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { queryResult: { refetch } } = useShow({
        resource: "appointments",
        id: recordData?.id,
        metaData: {
            populate: [
                '*',
                'appointmentType',
                'property',
                'project',
                'user_profile',
                'staff_has_properties',
                'appointment_time_slot',
                'reschedule_time_slot',
                'updateBy',
                'unit',
                'user_staff',
                'user_staff.user_profile',
                'user_staff.property',
                'user_staff.company',
                'user_staff.department',
            ]
        },
        queryOptions: {
            onSuccess: (data) => {
                let dataLoad: any = data?.data;
                console.log(">>> dataLoad", dataLoad)
                setisLoading(false);

                setappType({id: dataLoad?.appointmentType?.id, label:dataLoad?.appointmentType?.name});
                setStatus(dataLoad?.appointment_status);
                setProp(dataLoad?.property?.id);
                setReg(dataLoad?.user_profile ? true : false);
                setreqDate(dataLoad?.appointmentDate);
                settimeSlot(dataLoad?.appointment_time_slot?.id)

                //CALL FUNCTION ============================================================
                filterTimeSlot(dataLoad?.appointmentDate);
                //==========================================================================

                if(dataLoad?.user_profile){
                    setUnit(dataLoad?.unit?.id);
                    setcusID(dataLoad?.user_profile?.id);
                    setcusName(dataLoad?.user_profile?.first_name);
                    setcusLastName(dataLoad?.user_profile?.last_name);
                    setcusPhone(dataLoad?.user_profile?.tel);
                    setcusEmail(dataLoad?.user_profile?.user?.email);

                    editProps?.form?.setFieldsValue({
                        unit: dataLoad?.unit?.id,
                    });
                }else{
                    setcusID("");
                    setcusName(dataLoad?.customerFirstName);
                    setcusLastName(dataLoad?.customerLastName);
                    setcusPhone(dataLoad?.customerPhoneNumber);
                    setcusEmail(dataLoad?.customerEmail);
                }

                editProps?.form?.setFieldsValue({
                    appointmentType: dataLoad?.appointmentType?.id,
                    property: dataLoad?.property?.id,
                    user_staff: dataLoad?.user_staff?.id
                })
            }
        }
    })

    const { selectProps: propertydt } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        queryOptions:{onSuccess(data) {
            let dataSet = data?.data
            if(dataSet?.length == 1){
                setProp(dataSet[0]?.id)
                editProps?.form?.setFieldsValue({
                    property: dataSet[0]?.id
                })
            }
        },},
        pagination:{
            pageSize:1000
        }
    });

    const { selectProps: unitsdt } = useSelect<IUnit>({
        resource: "units",
        fetchSize: 200,
        metaData: {
            populate: '*'
        },
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator: (valueProp || prop_list || prop_id) ? "eq" : "nin",
                value: valueProp ? valueProp : prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const appointtype = useList<IAppointmentType>(
        {
            resource: "appointment-types",
            metaData: {
                populate: ['user_sub_types', 'user_sub_types.sub_type_name']
            },
            config:{
                filters: [
                    {
                        field       : 'deleteStatus',
                        operator    : 'contains',
                        value       :  'N',
                    },
                    {
                        field       : 'status',
                        operator    : 'eq',
                        value       :  true,
                    },
                ],
                pagination: { current: 1, pageSize: 1000 },
            },
            queryOptions:{onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    appoindt = newData;
                }
            },}
        }
    );

    const userhunit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: [
                'user_profile',
                'user_profile.first_name',
                'user_profile.last_name',
                'user_sub_type','unit',
                'unit.property',
                'user_profile.user'
            ],
        },
        config:{
            filters: [
                {
                    field   : "unit][property][id]",
                    operator: (valueProp || prop_list || prop_id) ? "eq" : "nin",
                    value: valueProp ? valueProp : prop_list ? prop_list: prop_id ? prop_id : null,
                },
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : valueUnit ? valueUnit : null
                },
            ],
            pagination: { current: 1, pageSize: 1000 },
        },
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;

            let newData: any = [];
            if(dataLoad?.length > 0){
                userhunitdtDEEP = dataLoad;

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.user_profile?.first_name + " " + dataLoad[i]?.user_profile?.last_name,
                            "value": dataLoad[i]?.user_profile?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    userhunitdt = newData;
                }
            }else{
                // clearcus();
            }
        },}
    });

    const staffinprop = useList<any>({
        resource: "user-type-mappings",
        metaData: {
            populate: [
                '*',
                'user_profile',
                'property'
            ]
        },
        config:{
            filters: [
                {
                    field : "property][id]",
                    operator: (valueProp || prop_list || prop_id) ? "eq" : "nin",
                    value: valueProp ? valueProp : prop_list ? prop_list: prop_id ? prop_id : null,
                },
                {
                    field: "status",
                    operator: "eq",
                    value: true
                },
                {
                    field: "role_management][custom_role_name]",
                    operator: "eq",
                    value: "Juristic"
                }
            ],
            pagination: { current: 1, pageSize: 1000 },
        },
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data?.filter((f: any) => f?.user_profile !== null);

            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.user_profile?.first_name + " " + dataLoad[i]?.user_profile?.last_name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    staffdt = newData;
                }
            }
        }}
    });

    const handleDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setreqDate(dateString);
        // SEND TO API FOR CAL TIMESLOT
        // callAPI(dateString);
        // http://192.168.100.151:1337/api/appointment-time-slots/slot_chk/?date=2022-06-30
        filterTimeSlot(dateString);
    };

    const handleNewDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setreqDateRT(dayjs(dateString).format("YYYY-MM-DD"));
    }

    async function filterTimeSlot(dateString:any){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let time_slot_choice = await axios.get(API_URL + "/appointment-time-slots?date=" + dateString , { headers: { Authorization: AuthStr } });
        let dataLoad = time_slot_choice?.data?.data;

        if(dataLoad?.length > 0){
            let newData: any = [];
            for (let i = 0; i < dataLoad?.length; i++) {
                newData?.push(
                    {
                        "label":  dataLoad[i]?.attributes?.slot_name,
                        "value": dataLoad[i]?.id
                    }
                )
            }

            if(newData?.length > 0){
                setslotdt(newData)
            }
        }
    }

    function clearcus(){
        setcusID("")
        setcusName("");
        setcusLastName("");
        setcusPhone("");
        setcusEmail("");
    }

    return (
        <Spin size="large" spinning={isLoading}>
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        if(valuecusID !== ""){values.user_profile = valuecusID;}
                        values.customerFirstName = valuecusName;
                        values.customerLastName = valuecusLastName;
                        values.customerPhoneNumber = valuecusPhone;
                        values.customerEmail = valuecusEmail;
                        values.appointmentDate = valuereqDate;
                        values.isConfirmed = vConfirm;
                        values.title = valueappType?.label;
                        values.appointmentType = valueappType?.id;
                        values.user_staff = valuestaffinProp;

                    if (valueStatus == "Reschedule"){
                        values.appointment_time_slot = null;
                        values.reschedule_time_slot = valuetimeSlotRT;
                        values.appointment_status = "Reschedule";

                        if(valuereqDateRT !== "" && valuereqDateRT){
                            values.rescheduleDate = valuereqDateRT;
                        }else{
                            values.rescheduleDate = null;
                        }
                    }else{
                        values.appointment_time_slot = valuetimeSlot;
                        values.reschedule_time_slot = null;
                        values.rescheduleDate = null;
                    }

                    values.deleteStatus = "N";
                    values.updateBy = userdt?.user_profile.id;
                    return (
                        editProps?.onFinish &&
                        editProps?.onFinish(values)
                    );
                }}
            >
                <Row gutter={8}>
                    <Col span={24} className="gutter-row">
                        <Form.Item label={t("appointm-create.aptment-type")} name={"appointmentType"}
                            rules={[
                                {
                                    required: true, message: 'Please select appointment type'
                                },
                            ]}>
                                <Select 
                                    options={appoindt}
                                    placeholder={t("select.sel")}
                                    showSearch
                                    filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                    onSelect={(value: any, i: any) => setappType(i)}
                                />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item  label={t("appointm-create.property-title")} name={"property"}
                            rules={[{required: true, message: 'Please select property'},]}
                        >
                            <Select 
                                {...propertydt} 
                                placeholder={t("select.sel")} 
                                onSelect={(value: any) => {
                                    setProp(value);
                                    editProps?.form?.resetFields(["unit"]);
                                    editProps?.form?.resetFields(["customerFirstName"]);
                                    editProps?.form?.resetFields(["customerLastName"]);
                                    editProps?.form?.resetFields(["customerPhoneNumber"]);
                                    editProps?.form?.resetFields(["customerEmail"]);
                                    editProps?.form?.resetFields(["staff_has_properties"]);
                                    editProps?.form?.resetFields(["appointmentDate"]);
                                    editProps?.form?.resetFields(["appointment_time_slot"]);
                                    editProps?.form?.resetFields(["detail"]);
                                    setreqDate("");
                                    settimeSlot("");
                                    clearcus();
                                }}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        <Radio.Group
                            value={valueReg}
                            onChange={(e) => {
                                setReg(e?.target?.value);
                                editProps?.form?.resetFields(["unit"]);
                                editProps?.form?.resetFields(["customerFirstName"]);
                                editProps?.form?.resetFields(["customerLastName"]);
                                editProps?.form?.resetFields(["customerPhoneNumber"]);
                                editProps?.form?.resetFields(["customerEmail"]);
                                clearcus();
                            }}
                        >
                            <Radio value={true}>{t("appointm-create.registered-cus")}</Radio>
                            <Radio value={false}>{t("appointm-create.un-regis-cus")}</Radio>
                        </Radio.Group>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={8}>
                    {valueReg == true ? 
                        <Col span={24} className="gutter-row">
                            <Row gutter={8}>
                                <Col span={24} className="gutter-row">
                                    <Form.Item  label={t("appointm-create.unit-title")} name={"unit"}
                                        rules={[{required: true, message: 'Please select unit'},]}
                                    >
                                        <Select 
                                            {...unitsdt} 
                                            placeholder={t("select.sel")}
                                            onSelect={(value: any) => {
                                                setUnit(value);
                                                editProps?.form?.resetFields(["customerFirstName"]);
                                                editProps?.form?.resetFields(["customerLastName"]);
                                                editProps?.form?.resetFields(["customerPhoneNumber"]);
                                                editProps?.form?.resetFields(["customerEmail"]);
                                                clearcus();
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="gutter-row">
                                    <Row gutter={8}>
                                        <Col span={4} className="gutter-row"><div style={{marginTop: "2.3rem"}}>To :</div></Col>
                                        <Col span={20} className="gutter-row">
                                            <Form.Item name="customerFirstName" label="Customer name" rules={[{required: true, message: 'Please select customer'},]}>
                                                <Select
                                                    options={userhunitdt}
                                                    placeholder={t("select.sel")} 
                                                    // onSelect={(value: any, label: any) => {onselcus(value)}}
                                                />
                                            </Form.Item>
                                        </Col>

                                        {valuecusID !== "" && <>
                                            <Col span={4} className="gutter-row" />
                                            <Col span={20} className="gutter-row">
                                                <Form.Item name="customerPhoneNumber" label="Phone number">
                                                    <Input placeholder="customer phone number" value={valuecusPhone !== "" ? valuecusPhone : "-"} defaultValue={valuecusPhone !== "" ? valuecusPhone : "-"} disabled/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4} className="gutter-row" />
                                            <Col span={20} className="gutter-row">
                                                <Form.Item name="customerEmail" label="Email">
                                                    <Input placeholder="customer phone number" value={valuecusEmail !== "" ? valuecusEmail : "-"} defaultValue={valuecusEmail !== "" ? valuecusEmail : "-"} disabled/>
                                                </Form.Item>
                                            </Col>
                                        </>}
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    : valueReg == false &&
                        <Col span={24} className="gutter-row">
                            <Row gutter={8}>
                                <Col span={4} className="gutter-row"><div style={{marginTop: "2.3rem"}}>To :</div></Col>
                                <Col span={20} className="gutter-row">
                                    <Row gutter={8}>
                                        <Col span={12} className="gutter-row">
                                            <Form.Item name="customerFirstName" label="First name" rules={[{required: true, message: 'Please enter first name'}]}>
                                                <Input placeholder="Customer name" onChange={(e) => setcusName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className="gutter-row">
                                            <Form.Item name="customerLastName" label="Last name" rules={[{required: true, message: 'Please enter last name'}]}>
                                                <Input placeholder="Customer Lastname" onChange={(e) => setcusLastName(e.target.value)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={4} className="gutter-row" />
                                <Col span={20} className="gutter-row">
                                    <Form.Item name="customerPhoneNumber" label="Phone number" rules={[{required: true, message: 'Please enter phone number'}]}>
                                        <Input placeholder="Customer phone number" onChange={(e) => setcusPhone(e.target.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={4} className="gutter-row" />
                                <Col span={20} className="gutter-row">
                                    <Form.Item name="customerEmail" label="Email" rules={[{required: true, message: 'Please enter email'}]}>
                                        <Input placeholder="Customer phone number" onChange={(e) => setcusEmail(e.target.value)} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    }
                </Row>

                <Divider />

                <Row gutter={8}>
                    <Col span={24} className="gutter-row">
                        <Form.Item name={"user_staff"} label={t("appointm-create.staff")} rules={[{required: true, message: 'Please select Staff'}]}>
                            <Select
                                options={staffdt}
                                placeholder={t("select.sel")}
                                onSelect={(e: any)=> setstaffinProp(e)}
                                onClear={() => setstaffinProp(undefined)}
                                showSearch
                                allowClear
                                filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item label={t("appointm-create.aptment-date")} 
                            name="appointmentDate"
                            rules={[
                                {
                                    required: true, message: 'Please select appointment date.'
                                },
                            ]}
                            getValueProps={(value) => ({
                                value: value ? dayjs(value) : "",
                            })}
                        >
                            <DatePicker
                                style={{width: "100%"}}
                                onChange={(date, dateString) => handleDatePickerChange(date, dateString, 1)} 
                                // disabledDate={(current) => {
                                //     let customDate = moment().format("YYYY-MM-DD");
                                //     return current && current < moment(customDate, "YYYY-MM-DD");
                                // }}
                                disabledDate={(current) => {
                                    let todayDate = dayjs().format("YYYY-MM-DD");
                                    return current && current.isBefore(todayDate, 'day');
                                }}
                                disabled={valueStatus == "Reschedule" ? true: false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item  
                            label={t("appointm-create.time-range")} 
                            name={"appointment_time_slot"}
                            rules={[
                                {
                                    required: (valueStatus == "Reschedule" || valueStatus == "Approved" || valueStatus == "Cancelled") ? false: true, message: 'Please select appointment times.'
                                },
                            ]}
                        >
                            <Select placeholder={t("select.sel")}
                                // options={vApptimeslot?.map((timeSlot:any) => ({ label: timeSlot?.attributes?.slot_name, value: timeSlot?.id }))}
                                options={timeslotdt}
                                onSelect={(value: any) => {
                                    settimeSlot(value);
                                }}
                                disabled={valueStatus == "Reschedule" ? true: false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item  label={t("appointm-create.note")} name="detail">
                            <Input placeholder="Note"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={8}>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label={t("appointm-create.apt-stat")}
                            name="appointment_status"
                            rules={[
                                {
                                    required: true, message: 'Please select status.'
                                },
                            ]}
                        >
                                <Select placeholder={t("select.sel")}
                                    onSelect={(e: any) => {
                                        setStatus(e)
                                        editProps?.form?.resetFields(["rescheduleDate"]);
                                        editProps?.form?.resetFields(["reschedule_time_slot"]);
                                    }}
                                    options={[
                                        {
                                            label: "Pending",
                                            value: "Pending",
                                        },
                                        {
                                            label: "Reschedule",
                                            value: "Reschedule",
                                        },
                                        {
                                            label: "Approved",
                                            value: "Approved",
                                        },
                                        {
                                            label: "Cancelled",
                                            value: "Cancelled",
                                        }
                                    ]}
                                />
                        </Form.Item>
                    </Col>
                    {valueStatus == "Reschedule" && <>
                        <Col span={24} className="gutter-row">
                            <Form.Item label={t("appointm-create.re-aptment-date")} 
                                name = "rescheduleDate"
                                rules={[
                                    {
                                        required: true, message: 'Please select date and time.'
                                    },
                                ]}
                                getValueProps={(value) => (
                                    {
                                        value: value ? dayjs(value): "",
                                    }
                                )}
                            >
                                <DatePicker
                                    style={{width: "100%"}}
                                    onChange={(date, dateString) => handleNewDatePickerChange(date, dateString, 1)} 
                                    disabledDate={(current) => {
                                        let customDate = dayjs().format("YYYY-MM-DD");
                                        return current && current < dayjs(customDate, "YYYY-MM-DD");
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="gutter-row">
                            <Form.Item label={t("appointm-create.re-aptment-time")} name="reschedule_time_slot"
                                rules={[
                                    {
                                        required: true, message: 'Please select time slot.'
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} //{...SelTimeSlot}
                                    // options={vApptimeslot?(timeSlot:any) => ({ label: timeSlot?.attributes?.slot_name, value: timeSlot?.id }))} 
                                    options={timeslotdt}
                                    onSelect={(value: any) => {
                                        settimeSlotRT(value);
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </>}
                </Row>
            </Form>
        </Spin>
    );
};