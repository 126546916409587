import { IResourceComponentsProps, useCustom, useNavigation, useTranslate } from "@pankod/refine-core"
import { petURL, rentSaleURL } from "../../../constants";
import { Breadcrumb, Button, Col, EditButton, Form, Icons, List, Modal, Row, Spin, Table, Tag } from "@pankod/refine-antd";
import { useState } from "react";
import dayjs from "dayjs";


let dataTBLOCAL: any = undefined;
export const RSLocationList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { create, edit, replace, list} = useNavigation();
    const { confirm } = Modal;
    
    const [pageSize, setPageSize] = useState<any>(10);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [valuepetType, setpetType] = useState<any>();
    const [searchVacType, setSearchVacType] = useState<any>(undefined);
    const [dataTB, setdataTB] = useState<any>([]);
    const [formSearch] = Form.useForm();

    const { data, refetch } = useCustom<any>({
        url: `${rentSaleURL}/api/rentsale/findAllMasterLocation`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataTB(dataLoad);
                dataTBLOCAL = dataLoad;

                setLoading(false)

            },
        },
    });

    const columns:any = [
        {
            title: 'ชื่อ',
            dataIndex: '',
            key: '',
            width: 140,
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <span>{record?.name}</span>
                )
            },
        },
        {
            title: 'ชื่ออังกฤษ',
            dataIndex: '',
            key: '',
            width: 140,
            render: (value:any, record:any) => {
                return (<span>{record?.name_en}</span>)
            },
        },
        {
            title: 'Status',
            width: 140,
            sorter: (a:any, b:any) => {
                return (1)
            },
            render: (value:any, record:any) => {
                return (
                    <span>{record?.is_active ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Active</Tag> : <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Inactive</Tag>}</span>
                )
            },
        },
        {
            title: 'Create By',
            width: 140,
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            render: (value:any, record:any) => {
                return (<span>{record?.create_by ? record?.create_by?.user_name : '-'}</span>)
            },
        },
        {
            title: 'Update By',
            width: 140,
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            render: (value:any, record:any) => {
                return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
            },
        },
        {
            title: 'Last Update',
            width: 140,
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                // return (<EditButton hideText size="small" onClick={()=> replace("/pet-vaccine/edit/" + record?._id)}/>);
                return (
                    <>
                    {
                        record.is_active ? 
                            <span>
                                <EditButton style={{marginRight: '2px'}} hideText size="small" onClick={()=> replace("/rent-sale/master-location/edit/" + record?._id)}/>
                                <Button 
                                    size="small" 
                                    title="Delete" 
                                    icon={<Icons.DeleteOutlined />} 
                                    // onClick={() => { showDelConfirm(record._id) }} 
                                    danger
                                ></Button>
                            </span>
                        :
                            <Button 
                                size="small" 
                                title="Active" 
                                icon={<Icons.RetweetOutlined />} 
                                // onClick={() => { showUnlockConfirm(record._id) }} 
                            ></Button>
                    }
                    </>
                );
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onsearchTB (data: any, mode: any){
        if(mode == "S"){
            let newDT: any = data;    

            if(searchVacType !== undefined){
                newDT = newDT?.filter((f: any)=> f?.vaccine_type == searchVacType);
                setdataTB(newDT);
            }

            if(valuepetType){
                newDT = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType));
                setdataTB(newDT);
            }

            setdataTB(newDT)
            // if(valuepetType){
            //     let dtFilter: any = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType));
            //     setdataTB(dtFilter);
            // }else{ //กรณีกดลบ select แล้วกดปุ่ม search แทนปุ่ม reset
            //     setdataTB(data)
            // }
        }else if(mode == "R"){
            let newDT: any = data;
            setdataTB(newDT);
            setpetType(undefined);
            setSearchVacType(undefined)
            // formSearch?.resetFields(['pet-type']);
            formSearch?.resetFields();
        }
    }

    return (
        <div className="layout_panel">
            <Spin size="large" spinning={isLoading}>

            {/* <Form 
                form={formSearch}
                layout="vertical"
                style={{marginBottom: "1rem"}}
            >
                <div className="layout_custom_search">
                    <Form.Item 
                        label=" "
                        name=""
                        className="visible-label"
                        style={{marginBottom: 0, display: "inline-block"}}
                    >
                        <Select/>
                    </Form.Item>

                    <Form.Item 
                        label=" "
                        name=""
                        className="visible-label"
                        style={{marginBottom: 0, display: "inline-block"}}
                    >
                        <Select/>
                    </Form.Item>

                    <Button 
                        icon={<Icons.SearchOutlined />} 
                        style={{borderRadius: "5px", marginRight: "15px"}} 
                        type="primary" 
                        onClick={()=> {
                            onsearchTB(dataTBLOCAL, "S")
                        }}
                    >
                        {t("buttons.search")}
                    </Button>

                    <Button 
                        icon={<Icons.ReloadOutlined />} 
                        style={{borderRadius: "5px"}} 
                        onClick={()=> {
                            onsearchTB(dataTBLOCAL, "R")
                        }}
                    >Reset</Button>
                </div>
            </Form> */}

                <Table 
                    dataSource={dataTB ? dataTB : []} 
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        pageSizeOptions: ['10', '30', '50', '100'], 
                        showSizeChanger: true, 
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 1300 }}
                />
            </Spin>
        </div>
    )
}