/* eslint-disable */
import { Breadcrumb, Card, Col, List, Row, Show, Space, TextField, Typography, Descriptions } from "@pankod/refine-antd";

import { ICoupon } from "interfaces";
// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
// import {QRCodeCanvas} from 'qrcode.react';
// import { useBarcode } from 'react-barcodes';
// import { PhoneOutlined,ScheduleOutlined } from "@ant-design/icons";
// import { useState } from "react";

import Moment from 'moment';

export const CouponGroupShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { queryResult } = useShow<ICoupon>({  
        metaData: {
        populate: '*'
    }, 
    });
   
    const { data, isLoading } = queryResult;
    const record = data?.data;
    // const [vbar, setbar] = useState(record?.coupon_barcode ? record?.coupon_barcode : '-');
    let recordData:any = []
    recordData = data?.data? data?.data : [];

    // console.log(">>> recordData",recordData)
    const { Text } = Typography;

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("")}
                pageHeaderProps={{ 
                    extra : <></>
                }}
            />
                <Space style={{marginBottom: "3%"}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("coupon.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("coupon-show.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={24}>
                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",border: "unset",marginBottom: "1%"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={18} style={{borderRight: "1px solid #ddd"}}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={16}>
                                            <Descriptions title={recordData.group_name !== null ? "(" + recordData.group_name + ")" : "ไม่มีชื่อกลุ่ม"} layout="horizontal" bordered column={2}>
                                                {
                                                    recordData.coupons &&
                                                        recordData?.coupons.length !== 0 &&
                                                            recordData?.coupons.map((data:any,index:any) => {
                                                                return <>
                                                                    {/* <Tag className="tag_post"><TextField value={data? data.coupon_name : <></> }  /></Tag> */}
                                                                    <Descriptions.Item label={"Coupon  Name"}>
                                                                        {data? data.coupon_name : <></> }
                                                                    </Descriptions.Item>
                                                                </>
                                                            })
                                                }
                                            </Descriptions>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={6}>
                                    {/* <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div> */}
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={10}>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                            <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                        </Col>
                                                                
                                        <Col className="gutter-row" span={14}>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                            <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                            <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Show>
);
};