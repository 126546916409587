/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY } from "../../../constants";

import axios from "axios";

export const HcuNotiMsgCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    console.log('createBy',Identity_data )
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { TextArea } = Input;
    const { Text } = Typography;
    const format = 'HH:mm';

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const log_endpoint = "even-logs";
    const { replace, list} = useNavigation();
    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    const [textcount, settextcount] = useState('');
    const [swlocal, setswlocal] = useState('th');
    const [checkedInApp, setcheckedInApp] = useState<boolean>(false);
    const [checkedSms, setcheckedSms] = useState<boolean>(false);
    const [checkedEmail, setcheckedEmail] = useState<boolean>(false);

    console.log('checkedSms', checkedSms)

    const [msg_inapp_th, setmsg_inapp_th] = useState<any>("");
    const [msg_sms_th, setmsg_sms_th] = useState<any>("");
    const [msg_email_th, setmsg_email_th] = useState<any>("");

    const [msg_inapp_en, setmsg_inapp_en] = useState<any>("");
    const [msg_sms_en, setmsg_sms_en] = useState<any>("");
    const [msg_email_en, setmsg_email_en] = useState<any>("");

    const [time_inapp, settime_inapp] = useState<any>();
    const [time_sms, settime_sms] = useState<any>();
    const [time_email, settime_email] = useState<any>();

    // const [checked2lan, setchecked2lan] = useState<boolean>(false) // เอาปุ่มออก ไม่อยากแก้ฟังก์ชั่น เลยเปลี่ยนเป็น true
    const [checked2lan, setchecked2lan] = useState<boolean>(true)

    const { form, formProps, saveButtonProps } = useForm<any,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource : log_endpoint,
                values : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(),
                    log_event               : "Create",
                    menu_event              : 'hcu-settings',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
        errorNotification(error:any, values:any, resource:any) {
            return {
                message: error.toString(),
                type: "error" ,
                undoableTimeout: 1000
            }
        },
        onMutationError(error, variables, context) {
            console.log('error', error)
            console.log('variables', variables)
            console.log('context', context)
        },
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    const error = () => {
        Modal.error({
          title: <span>Please Check at least 1 Choice</span>,
          content: <span></span>,
        });
    };

    const optionsRdioLocale = [
        { label: 'TH', value: 'th' },
        { label: 'EN', value: 'en' },
    ];

    const handleChange = (e:any) => {
        settextcount(e.target.value);
    };

    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setswlocal(value);
    };

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                        method: "post",
                        body: body
                        // mode: "no-cors"
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                            default: res[0].url
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    async function createNoti(values:any) {
        console.log(">>> values",values)
        console.log('Number(values?.type?.value)', Number(values?.type?.value))
        let data = { 
            "data":{
                "property": values?.property?.id,
                "hcu_setting": [
                    {
                        "home_check_up_type":{"name":"Notification"},
                        "seq": Number(values?.type?.value),
                        // "date": 1,
                        "inApp": checkedInApp,
                        "sms": checkedSms,
                        "email": checkedEmail,
                        "inApp_th": msg_inapp_th,
                        "inApp_en": msg_inapp_en,
                        "sms_th": msg_sms_th,
                        "sms_en": msg_sms_en,
                        "email_th": msg_email_th,
                        "email_en": msg_email_en,
                        "time_inApp": time_inapp,
                        "time_sms": time_sms,
                        "time_email": time_email,
                        "createBy" : Identity_data ? Identity_data.user_profile.id : null
                    },
                ]
            }
        }

        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)

        successNoti('200', 'success');

        replace("/majorcare/setup-hcu")
    }

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully create notification',
        });
    };

    return (
    <Create 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-noti.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                // {...createProps} 
                {...formProps}
                layout="vertical"
                onFinish={(values:any) => {
                    if(checked2lan == true){
                        createNoti(values)
                    }else if(checked2lan == false && (checkedInApp == true || checkedSms == true || checkedEmail == true)){
                        setswlocal('en')
                        setchecked2lan(true)
                    }else if(checkedInApp == false && checkedSms == false && checkedEmail == false){
                        error();
                    }

                    // values.property = values.property.id;
                    // values.type = values.type.value;
                    // values.msg = values.msg;
                    // values.msg_en = values.msg;
                    // values.createBy = user_data.user_profile.id;
                    // values.deleteStatus = "N";

                    // values.sms = values.sms == undefined ? false : true;
                    // values.email = values.email == undefined ? false : true;
                    // values.inApp = values.inApp == undefined ? false : true;

                    // console.log(values)

                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={6} className="gutter-row" style={{borderRight: "1px solid #dedede",paddingTop: "2.5rem"}}>
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty}/>
                            </Form.Item>

                            <Form.Item
                                name={["type", "value"]}
                                label="Type"
                                rules={[
                                    {
                                        required: true, message: "Please select type"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")}>
                                    <Option value="1">{"แจ้งเตือนรอบที่ 1"}</Option>
                                    <Option value="2">{"แจ้งเตือนรอบที่ 2"}</Option>
                                    {/* <Option value="POPUP_1">{"แจ้งเตือนรอดำเนินการติดต่อกลับ"}</Option> */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18} className="gutter-row" style={{paddingTop: "2.5rem"}}>
                            <Row gutter={82}>
                                <Col span={24} className="gutter-row">
                                    <div style={{marginBottom: "3rem"}}>
                                        <span style={{marginRight: "1rem",fontSize: "13.44px",color: "#000"}}>Language</span><Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={swlocal} optionType="button" disabled={true} className="custom_disable_radio_i"/>
                                    </div>
                                </Col>
                                <Col span={12} className="gutter-row">
                                    <Checkbox onChange={(e) => setcheckedInApp(e?.target?.checked)} style={{marginBottom: "1.5rem"}}><span style={{fontWeight: 500,fontSize: "18px",color: "#000"}}>In app</span></Checkbox>
                                    <div className="inapp_panel">
                                        {swlocal == 'th' ?
                                            <Form.Item
                                                name="msg_inapp_th"
                                                label="Messages"
                                                rules={[
                                                    {
                                                        required: checkedInApp ? true : false, message: "Please input description"
                                                    },
                                                ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}
                                                    // defaultValue={vShortDetails}
                                                    onChange={(e) => setmsg_inapp_th(e?.target?.value)} 
                                                    disabled={!checkedInApp ? true : false}
                                                />
                                            </Form.Item>
                                        :swlocal == 'en' &&
                                            <Form.Item
                                                name="msg_inapp_en"
                                                label="Messages"
                                                rules={[
                                                    {
                                                        required: checkedInApp ? true : false, message: "Please input description"
                                                    },
                                                ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}
                                                    onChange={(e) => setmsg_inapp_en(e?.target?.value)} 
                                                    disabled={!checkedInApp ? true : false}
                                                />
                                            </Form.Item>
                                        }

                                        <Form.Item name="time_inApp" className="disable_req" rules={[{required: checkedInApp ? true : false,message: "select time"}]}>
                                            <TimePicker 
                                                placeholder="Time" 
                                                format={format}
                                                onChange={(date, dateString) => settime_inapp(dateString + ":00.000")}
                                                disabled={!checkedInApp ? true : false}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={12} className="gutter-row">
                                    <Checkbox onChange={(e) => setcheckedSms(e?.target?.checked)} style={{marginBottom: "1.5rem"}}><span style={{fontWeight: 500,fontSize: "18px",color: "#000"}}>SMS</span></Checkbox>
                                    <div className="sms_panel">
                                        {swlocal == 'th' ?
                                            <Form.Item
                                                name="sms_th"
                                                label="Messages"
                                                // rules={[
                                                //     {
                                                //         required: checkedSms ? true : false, message: "Please input sms"
                                                //     },
                                                // ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}
                                                    maxLength={70}
                                                    onChange={(e) => {
                                                        setmsg_sms_th(e?.target?.value)
                                                        handleChange(e)
                                                    }} 
                                                    disabled={!checkedSms ? true : false}
                                                />
                                                <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount?.length} / 70`}</Text>
                                            </Form.Item>
                                        :swlocal == 'en' &&
                                            <Form.Item
                                                name="sms_en"
                                                label="Messages"
                                                // rules={[
                                                //     {
                                                //         required: checkedSms ? true : false, message: "Please input sms"
                                                //     },
                                                // ]}
                                            >
                                                <TextArea style={{height: "90px", minHeight: "90px",maxHeight: "250px"}}  
                                                    maxLength={70}
                                                    onChange={(e) => {
                                                        setmsg_sms_en(e?.target?.value)
                                                        handleChange(e)
                                                    }}
                                                    disabled={!checkedSms ? true : false}
                                                />
                                                <Text style={{position: "absolute",display: "flex",right: 0}}>{`${textcount?.length} / 70`}</Text>
                                            </Form.Item>
                                        }

                                        <Form.Item name="time_sms" className="disable_req" rules={[{required: checkedSms ? true : false,message: "select time"}]}>
                                            <TimePicker
                                                placeholder="Time" 
                                                format={format}
                                                onChange={(date, dateString) => settime_sms(dateString + ":00.000")}
                                                disabled={!checkedSms ? true : false}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col span={24} className="gutter-row" style={{marginTop: "3rem"}}>
                                    <Checkbox onChange={(e) => setcheckedEmail(e?.target?.checked)} style={{marginBottom: "1.5rem"}}><span style={{fontWeight: 500,fontSize: "18px",color: "#000"}}>Email</span></Checkbox>
                                    <div className="email_panel">
                                        {swlocal == 'th' ?
                                            <Form.Item label="Messages" name="msg_email_th" rules={[{required: checkedEmail ? true : false, message: "Please input message"}]}>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "link",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "blockQuote",
                                                            "|",
                                                            "imageTextAlternative",
                                                            "imageUpload",
                                                            "imageStyle:full",
                                                            "imageStyle:side",
                                                        ],
                                                        extraPlugins: [uploadPlugin],
                                                        mediaEmbed: {
                                                            // configuration...
                                                            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                        },
                                                        image: {
                                                            toolbar: ['']
                                                        }
                                                    }}
                                                    
                                                    onInit={(editor:any) => {
                                                    }}
                                                    onChange={(event:any, editor:any) => {
                                                        const data = editor.getData();
                                                        setmsg_email_th(data);
                                                    }}
                                                    onBlur={(editor:any) => {
                                                        console.log("Blur.", editor);
                                                    }}
                                                    onFocus={(editor:any) => {
                                                        console.log("Focus.", editor);
                                                    }}
                                                    disabled={!checkedEmail ? true : false}
                                                />
                                            </Form.Item>
                                        :swlocal == 'en' && 
                                            <Form.Item label="Messages" name="msg_email_en" rules={[{required: checkedEmail ? true : false, message: "Please input message"}]}>
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    config={{
                                                        toolbar: [
                                                            "heading",
                                                            "|",
                                                            "bold",
                                                            "italic",
                                                            "link",
                                                            "bulletedList",
                                                            "numberedList",
                                                            "blockQuote",
                                                            "|",
                                                            "imageTextAlternative",
                                                            "imageUpload",
                                                            "imageStyle:full",
                                                            "imageStyle:side",
                                                        ],
                                                        extraPlugins: [uploadPlugin],
                                                        mediaEmbed: {
                                                            // configuration...
                                                            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                                        },
                                                        image: {
                                                            toolbar: ['']
                                                        }
                                                    }}
                                                    
                                                    onInit={(editor:any) => {
                                                    }}
                                                    onChange={(event:any, editor:any) => {
                                                        const data = editor.getData();
                                                        setmsg_email_en(data);
                                                    }}
                                                    onBlur={(editor:any) => {
                                                        console.log("Blur.", editor);
                                                    }}
                                                    onFocus={(editor:any) => {
                                                        console.log("Focus.", editor);
                                                    }}
                                                    disabled={!checkedEmail ? true : false}
                                                    // style={{
                                                    //     color:'red',
                                                    //     backgroundColor:'red'
                                                    // }}
                                                />
                                            </Form.Item>
                                        }

                                        <Form.Item name="time_email" className="disable_req" rules={[{required: checkedEmail ? true : false,message: "select time"}]} >
                                            <TimePicker
                                                placeholder="Time"
                                                format={format}
                                                onChange={(date, dateString) => settime_email(dateString + ":00.000")}
                                                disabled={!checkedEmail ? true : false}
                                            />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Create>   
    );
};