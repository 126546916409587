import { Badge, Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal,  Row,  Select,  Space, Table, Tabs, Tag, TextField, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useExport, useGetIdentity, useTranslate, useUpdate } from "@pankod/refine-core";
import { IUnit } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import {  WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

export const BannerMgnList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TabPane } = Tabs;
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Banner Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const [ vProp, setvProp ] = useState<any>();
    const [ Category, setCategory ] = useState<any>(undefined);

    const [titleTH, setTitleTH] = useState<any>(undefined);
    const [titleEN, setTitleEN] = useState<any>(undefined);
    const [listData, setListdata] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(null);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [activeBadgeNumber, setActiveBadgeNumber] = useState<any>(null)
    const [trashBadgeNumber, setTrashBadgeNumber] = useState<any>(null)

    const { confirm } = Modal;
    const { mutate } = useUpdate<IUnit>();
    const ed_point = "banner-managements";
    const [ dlStatus, setdlStatus] = useState("N");
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const { triggerExport, isLoading: exportLoading } = useExport<IUnit>({
        mapData: (item) => {
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,  
                };
            }
        },
    });
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'DRAFT'}

    const { formProps } = useForm<any,HttpError,{}>() 

    const { selectProps: propertySelectProps } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        fetchSize: 1000,
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
    });

    const { mutate: UpdateLocale } = useUpdate<any>();

    function updateStatus(id:any, localizeId:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        },{
            onSuccess(data, variables, context) {
                getData()
            },
            onError(error, variables, context) {
                getData()
            },
        });

        if(localizeId && localizeId !== null || localizeId !== undefined){
            UpdateLocale({
                resource : ed_point,
                id: localizeId,
                values : {
                    updateBy        : user_data.user_profile.id + "",
                    deleteStatus    : vCurDelStatus.deleteStatus
                },errorNotification:false,
                successNotification:false,
                },{
                    onSuccess(data, variables, context) {
                        // close();
                    },
                }
            )
        }
    }

    function showDelConfirm(id:any, localizeId: any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, localizeId: any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const onReset = () => {
        setvProp(null)
        setCategory(undefined)
        setTitleTH(undefined)
        setTitleEN(undefined)
        formProps.form?.resetFields()
        // formProps.form?.submit()
    };

    function onStatusChange(v:any){
        setdlStatus(v)
        setPage(1)
        // formProps.form?.submit();
        localStorage.setItem("keyU",v)
        localStorage.setItem("keyTabUserPortal", v)
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : sorterOrder
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }

        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSizeChange(pagination?.pageSize)
        }
    };
    
    const API_URL = useApiUrl();
    const getData = async ()=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(vProp){
                apiEndPoint += `&filters[properties][id][$eq]=${vProp}`
            }
            if(Category){
                apiEndPoint += `&filters[tag][$contains]=${Category}`
            }
            if(titleTH){
                apiEndPoint += `&filters[title][$contains]=${titleTH}`
            }
            if(titleEN){
                apiEndPoint += `&filters[title_en][$contains]=${titleEN}`
            }
            if(dlStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${dlStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${dlStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            if(tableSorter?.title && tableSorter?.order){
                if(tableSorter?.title?.toLowerCase() == '[property_dev]'){
                    apiEndPoint += `&sort[0]=property_dev:${tableSorter?.order}&sort[1]=properties.property_name:${tableSorter?.order}`
                }
                else{
                    apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
                }
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const resultList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let localizations = []
                        let pic = []
                        let pic_en = []
                        let properties = []
                        let user_types = []
                        let content_ae = null
                        let content_announcement = null
                        let content_blog = null
                        let content_promotion = null
                        let privilege = null
                        let user_sub_types = []
                        let content_new = null
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.localizations?.data && attributes?.localizations?.data?.length > 0){
                            localizations = attributes?.localizations?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.pic?.data && attributes?.pic?.data?.length > 0){
                            pic = attributes?.pic?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.pic_en?.data && attributes?.pic_en?.data?.length > 0){
                            pic_en = attributes?.pic_en?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.properties?.data && attributes?.properties?.data?.length > 0){
                            properties = attributes?.properties?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.user_types?.data && attributes?.user_types?.data?.length > 0){
                            user_types = attributes?.user_types?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.content_ae?.data){
                            content_ae = {id: attributes?.content_ae?.data?.id , ...attributes?.content_ae.data.attributes}
                        }
                        if(attributes?.content_announcement?.data){
                            content_announcement = {id: attributes?.content_announcement?.data?.id , ...attributes?.content_announcement.data.attributes}
                        }
                        if(attributes?.content_blog?.data){
                            content_blog = {id: attributes?.content_blog?.data?.id , ...attributes?.content_blog.data.attributes}
                        }
                        if(attributes?.content_promotion?.data){
                            content_promotion = {id: attributes?.content_promotion?.data?.id , ...attributes?.content_promotion.data.attributes}
                        }
                        if(attributes?.privilege?.data){
                            privilege = {id: attributes?.privilege?.data?.id , ...attributes?.privilege.data.attributes}
                        }
                        if(attributes?.user_sub_types?.data && attributes?.user_sub_types?.data?.length > 0){
                            user_sub_types = attributes?.user_sub_types?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.content_new?.data){
                            content_new = {id: attributes?.content_new?.data?.id , ...attributes?.content_new.data.attributes}
                        }
                        const result = {id: item?.id , ...attributes}
                        result.createBy = createBy
                        result.updateBy = updateBy
                        result.localizations = localizations
                        result.pic = pic
                        result.pic_en = pic_en
                        result.properties = properties
                        result.user_types = user_types
                        result.content_ae = content_ae
                        result.content_announcement = content_announcement
                        result.content_blog = content_blog
                        result.content_promotion = content_promotion
                        result.privilege = privilege
                        result.user_sub_types = user_sub_types
                        result.content_new = content_new
                        return result
                    })
                    setListdata(resultList || [])
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    setLoadingTable(false)
                });
        } catch (error) {
            console.log(error)
            setLoadingTable(false)
        }
    }
    const getBadgeNumber = async (deleteStatus:any)=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${1}&pagination[page]=${1}`
            if(deleteStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${deleteStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${deleteStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            apiEndPoint += `&sort[id]=desc`
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data
                    if(dataLoad?.meta?.pagination?.total){
                        const total = dataLoad?.meta?.pagination?.total > 0 ? dataLoad?.meta?.pagination?.total : null
                        if(deleteStatus.toLowerCase() == 'y'){
                            setTrashBadgeNumber(total)
                        }
                        else{
                            setActiveBadgeNumber(total)
                        }
                    }
                }).catch((e) => {
                    console.log(e)
                });
        } catch (error) {
        }
    }

    useEffect(() => {
        if (!vProp && !Category && !titleTH && !titleEN && Identity_data) {
            getData()
        }
      }, [vProp, Category, titleTH, titleEN]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,dlStatus,Identity_data])

    useEffect(() => {
        if(Identity_data){
            getBadgeNumber('Y')
            getBadgeNumber('N')
        }
    }, [Identity_data])
    
    return <>
            
            <List 
                title={t("banner-mgn.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("banner-mgn.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("banner-mgn.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row>
                    <Col span={18}>
                        <Form
                            {...formProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                           <Row gutter={8} style={{width: "100%"}}>
                                <Col className="gutter-row" span={5}>
                                    <Form.Item name="properties" style={{width: "100%"}}>
                                        <Select 
                                            {...propertySelectProps}
                                            placeholder="Select property" 
                                            size="middle" 
                                            style={{borderRadius: 20, position:'relative'}} 
                                            allowClear
                                            onSelect={(e : any) => setvProp(e)}
                                            />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={5}>
                                    <Form.Item name="in" style={{width: "100%"}}>
                                        <Select placeholder={t("select.sel")}
                                            style={{ width: '100%' }}
                                            onChange={(e)=>setCategory(e)}
                                            options={[
                                                {
                                                    label: "Promotion",
                                                    value: "Promotion",
                                                },
                                                {
                                                    label: "Privilege",
                                                    value: "Privilege",
                                                },
                                                {
                                                    label: "News",
                                                    value: "News",
                                                },
                                                {
                                                    label: "Announcement",
                                                    value: "Announcement",
                                                },
                                                {
                                                    label: "Blog",
                                                    value: "Blog",
                                                },
                                                {
                                                    label: "Activity & Event",
                                                    value: "Activity & Event",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Form.Item name="titleTH" style={{width: "100%"}}>
                                        <Input 
                                            onChange={(e) => setTitleTH(e.target.value)}
                                            style={{borderRadius: 4}} 
                                            placeholder="Search title TH" 
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={4}>
                                    <Form.Item name="titleEN" style={{width: "100%"}}>
                                        <Input
                                            onChange={(e) => setTitleEN(e.target.value)}
                                            style={{borderRadius: 4}} 
                                            placeholder="Search title EN" 
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2} style={{marginRight: "15px"}}>
                                    <Form.Item >
                                        <Button onClick={getData} htmlType="button"  type="primary" style={{borderRadius: "5px"}}>
                                            <Icons.SearchOutlined /> Search
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={2}>
                                    <Form.Item >
                                        <Button onClick={onReset} htmlType="reset" style={{borderRadius: "5px"}}>
                                            <Icons.UndoOutlined /> Reset
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>

                        <Form {...formProps}>
                                <Form.Item name="deleteStatus" >
                                    <Tabs 
                                        defaultActiveKey={"N"}
                                        style={{ marginTop: 10, marginBottom: 10 }} 
                                        onChange={onStatusChange} 
                                    >
                                        <TabPane
                                            key={"N"}
                                            tab={
                                                <span>
                                                    {"ACTIVE"}
                                                    <Badge count={activeBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                                </span>
                                            }
                                        />

                                        <TabPane
                                            key={"Y"}
                                            tab={
                                                <span>
                                                    {"TRASH"}
                                                    <Badge count={trashBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                                </span>
                                            }
                                        />
                                    </Tabs>
                                </Form.Item>
                            </Form>

                    </Col>

                    <Col span={6}>
                        <Form
                            {...formProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table 
                    dataSource={listData}
                    onChange={handleTableChange}
                    loading = {loadingTable}
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        current: metaPagination?.page || page,
                        pageSize: metaPagination?.pageSize || pageSizeChange,
                        total: metaPagination?.total,
                        // metaPagination?.pageCount
                        position:["bottomRight"]
                    }}
                >

                    <Table.Column
                        key={'[property_dev]'} 
                        title={t("content-post-promotion.col-properties")}  
                        width={100}
                        align="center"
                        className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            // if (a?.property_dev < b?.property_dev) {return 1}
                            // if (a?.property_dev > b?.property_dev) {return -1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.property_dev == "All" ?
                                        <Tag className="tag_post"><TextField value={'All Properties'}  /></Tag>
                                    :   record?.property_dev == "Major" ?
                                            <Tag className="tag_post"><TextField value={'Only Major'}  /></Tag>
                                        : record?.property_dev == "Non Major" ?
                                            <Tag className="tag_post"><TextField value={'Non Major'}  /></Tag>
                                            :
                                                record?.properties.length !== 0 ?
                                                    record?.properties.map((data:any,index:any) => {
                                                        return <>
                                                            <Tag className="tag_post"><TextField value={data? data.property_name : <></> }  /></Tag>
                                                        </>
                                                    })
                                                : <>-</>
                                }
                            </>
                        }}
                    />

                    <Table.Column key={'[tag]'} title="CATEGORY" width={150} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            // if (a?.tag < b?.tag) {return 1}
                            // if (a?.tag > b?.tag) {return -1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{ 
                            return <>
                                <TextField value={record?.tag}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[title]'} title="CONTENT TITLE (TH)" width={150} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            // if (a?.localizations[0]?.title < b?.localizations[0]?.title) {return -1}
                            // if (a?.localizations[0]?.title > b?.localizations[0]?.title) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{ 
                            return <>
                                <TextField value={record?.localizations[0]? record?.localizations[0].title :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[localizations][title]'} title="CONTENT TITLE (EN)" width={150} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            // if (a?.localizations[0]?.title < b?.localizations[0]?.title) {return -1}
                            // if (a?.localizations[0]?.title > b?.localizations[0]?.title) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{ 
                            return <>
                                <TextField value={record?.localizations[0]? record?.localizations[0].title :"-"}  />
                            </>
                        }}
                    />


                    <Table.Column key={'[start_date]'} title="PUBLIC DATE" width={120} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.start_date < b?.start_date) {return -1}
                            if (a?.start_date > b?.start_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                             <TextField value={record? <>{dayjs(record?.start_date).format("DD/MM/YYYY")}</> :"-"} /> 
                                
                            </>
                        }}
                    />

                    <Table.Column key={'[createBy][first_name]'} title="CREATE BY" align="center" width={120} className="table_colmn_custom_left_value"
                        // sorter={(a:any, b:any) => a?.createBy?.first_name.localeCompare(b?.createBy?.first_name)}
                        //     sortDirections={['ascend', 'descend']}
                        sorter={(a:any, b:any) => {
                            // if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            // if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.createBy ? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[createdAt]'} title="CREATED DATE" width={150} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                               <TextField value={record?.createdAt ?<>{dayjs(record?.createdAt).format("DD/MM/YYYY")}</> : "-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[updateBy][first_name]'} title="UPDATE BY" align="center" width={120} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.updateBy?.first_name.localeCompare(b?.updateBy?.first_name)}
                            sortDirections={['ascend', 'descend']}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.updateBy ? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[updatedAt]'} title="LAST UPDATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                             
                            </>
                        }}
                        
                    />

                    <Table.Column<any>
                        width={50}
                        //title={t("content-post-promotion.col-action")} 
                        align    = "center"
                        dataIndex= "actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>

                                {/* <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}

                                 {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />

                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id, record?.localizations[0].id) }} danger></Button>
                                        </>
                                    :
                                        <> 
                                            <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id, record?.localizations[0].id) }} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}