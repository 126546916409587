import { Badge, Breadcrumb, Button, Col, EditButton,  Form,  Icons,  Input,  List, Modal,  Row,  Space, Table, Tabs, Tag, TextField, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IAppointmentType, IEventlog, IUnit } from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

const { TabPane } = Tabs;

export const AppointmTypeList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const {data: Identity_data} = useGetIdentity<any>();
    // let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Appointments-type");
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IUnit>();
    const ed_point                          = "appointment-types";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()

    const tabAppountment = localStorage.getItem("keyTabStatusAppountmentType") || 'N';
    const [keepName, setKeepName] = useState<any>()
    const [keepDataStatus, setKeepDataStatus] = useState<any>(tabAppountment || 'N')

    const { tableProps,searchFormProps,tableQueryResult } = useTable<IAppointmentType,HttpError, IAppointmentType>({
        metaData: {
            populate: '*'
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { code,deleteStatus } = params;
                filters.push(
                    {
                        field       : "unit_name",
                        operator    : "contains",
                        value       : code
                    },
                    {
                        field       : "name",
                        operator    : "contains",
                        value       : keepName
                    },
                    {
                        field       : "deleteStatus",
                        operator    : "eq",
                        value       : deleteStatus
                    },
                );

            return filters;
        },
        initialFilter: [
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : !!keepDataStatus ? keepDataStatus : tabAppountment
            },
        ],
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
    });

    const datacus = useList<IAppointmentType>({ 
        resource: "appointment-types" ,
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        }
    });
    const {data:dataCUSTOM} = datacus

    // const { triggerExport, isLoading: exportLoading } = useExport<IUnit>({
    //     mapData: (item) => {
    //         if(item){
    //             return { // เพิ่มฟิลที่ต้องการ Export
    //                 id  : item.id, 
                        
    //             };
    //         }
    //     },
    // });

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'appointments_type',
                SysPkID         : id+""
            },successNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        searchFormProps.form?.submit();
        setKeepDataStatus(v)
        localStorage.setItem("keyTabStatusAppountmentType",v)
        //localStorage.setItem("keyTabUserPortal", v)
        
    }

    const onReset = () => {
        setKeepName(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };


    return <>
            
            <List 
                title={t("appointm-type.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("appoint.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("appointm-type.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                           <Form.Item name="q">
                                <Input onChange={(e) => setKeepName(e.target.value)} style={{border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            {/* <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" />
                            </Form.Item> */}
                        </Form>
                    </Col>
                </Row>

                <Form {...searchFormProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={!tabAppountment ? keepDataStatus : `${tabAppountment}`}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={onStatusChange} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={dataCUSTOM?.data.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={dataCUSTOM?.data.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table 
                    //{...tableProps} 
                    dataSource={tableProps?.dataSource?.filter((f: any)=> keepDataStatus == 'Y' ? f?.deleteStatus == keepDataStatus : (f?.deleteStatus || f?.deleteStatus == keepDataStatus))}
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableProps?.dataSource?.length,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >
                    {/* <Table.Column dataIndex="id" title={t("Lid")} /> */}
                    {/* <Table.Column width={150} dataIndex="post_code" title="Post Code" 
                        render={(value:any,record:any)=>{
                            return <>
                                    {
                                        value? 
                                            <TextField value={value} />
                                        :<>
                                            <TextField value={"(ไม่ระบุ)"} />
                                        </>
                                    }
                                </>
                        }}
                    /> */}

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column dataIndex="id" title={t("Lid")} align="center" width={150} sorter
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="code" title="CODE" width={400}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title="NAME" width={600}
                     sorter={(a:any, b:any) => {
                        if (a?.name < b?.name) {return -1}
                        if (a?.name > b?.name) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{ 
                            return <>
                                <TextField value={record? record.name:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="remark" title="REMARK" width={600}
                        sorter={(a:any, b:any) => {
                            if (a?.remark < b?.remark) {return -1}
                            if (a?.remark > b?.remark) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{ 
                            console.log('value', value)
                            return <>
                                {
                                    record.remark == "" ?
                                        <TextField value={"-"}  />
                                    :
                                        <TextField value={record.remark !== null ? record.remark : "-"}  />
                                }
                                {/* <TextField value={value !== null ? value : "-"}  /> */}
                            </>
                        }}
                    />

                    <Table.Column title="STATUS" width={200} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.status < b?.status) {return -1}
                            if (a?.status > b?.status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.status == true? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Publish</Tag>:<Tag style={{borderRadius: "30px",color: "#fff",padding: "1% 7% 1%"}} color={"processing"}>Draft</Tag>}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="unit_name" title="UNIT NAME" 
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="post_time" title="Post time" 
                        render={(value:any,record:any)=>{
                            return <>
                                <span> DATE: </span>
                                <TextField value={Moment(value).format('MM/d/yyyy')}  />
                                <span> TIME: </span>
                                <TextField value={Moment(value).format('HH:mm a')}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title="CREATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                        if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            console.log("xxxtest",record)
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt> b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                    sorter={(a:any, b:any) => {
                        if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                        if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            console.log("xxxtest",record)
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt> b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                                {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                                {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />
                  
                    <Table.Column<IUnit>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                {/* <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton> */}

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */}
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger></Button>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}