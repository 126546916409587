/* eslint-disable */
import { Breadcrumb, Button, Col, Create, Form, Icons, Image, Input, List, Modal, Row, Select, Typography, notification, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog } from "interfaces";
import { useState } from "react";
import { TOKEN_KEY } from "../../../constants";

import axios from "axios";

export const RepairAssignCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null
    // console.log('Identity_data', Identity_data)
    // console.log('data_multi_property', data_multi_property)
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { TextArea } = Input;
    const { Text } = Typography;
    const format = 'HH:mm';

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const log_endpoint = "even-logs";
    const { replace, list} = useNavigation();
    const token = localStorage.getItem(TOKEN_KEY);
    const [vProp, setProp] = useState<any>(undefined);

    const [modal, contextHolder] = Modal.useModal();

    // console.log('checkedSms', checkedSms)

    const [Inspector, SetInspector] = useState<any>([]);
    const [InspectorId, SetInspectorId] = useState<any>([]);
    const [IdFilter, SetIdFilter] = useState<any>([]);

    const [checked2lan, setchecked2lan] = useState<boolean>(false)

    const { form, formProps, saveButtonProps } = useForm<any,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource : log_endpoint,
                values : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(),
                    log_event               : "Create",
                    menu_event              : 'hcu-settings',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
        errorNotification(error:any, values:any, resource:any) {
            return {
                message: error.toString(),
                type: "error" ,
                undoableTimeout: 1000
            }
        },
        onMutationError(error, variables, context) {
            console.log('error', error)
            console.log('variables', variables)
            console.log('context', context)
        },
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    console.log('vProp',vProp)

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            // role Inspector id = 16
            // role home checkup ช่าง id = 19
            // role major care ช่าง id = 21
            // role major care ผู้ดูแลระบบ id = 18
            {
                field: "role_management][id]",
                operator: "eq",
                value: 21 // role Inspector
            },
            {
                field : "user_profile][deleteStatus]",
                operator : "eq",
                value : "N"
            },
            // {
            //     field : "property][id]",
            //     operator : data_property !== null ? "eq" : "ne",
            //     value : data_property !== null ? data_property : null
            // },
            {
                field : "property][id]",
                operator : vProp !== undefined ? "eq" : "null",
                value : vProp !== undefined ? vProp : false
            },
            // {
            //     field : "property][id]",
            //     operator : "null",
            //     value : true
            // },
            {
                field: "id",
                operator: "nin",
                value: IdFilter.length > 0 ? IdFilter : 0
            }
        ]
    });

    // const { selectProps: selInspectorAdmin } = useSelect<any>({
    //     resource    : "user-type-mappings",
    //     optionLabel : "user_profile][first_name]",
    //     metaData: {
    //         populate: '*',
    //         // locale:['user_profile','user_profile.avatar']
    //     },
    //     optionValue : "id",
    //     fetchSize: 1000,
    //     filters: [
    //         // role Inspector id = 16
    //         // role home checkup ช่าง id = 19
    //         // role major care ช่าง id = 21
    //         // role major care ผู้ดูแลระบบ id = 18
    //         {
    //             field: "role_management][id]",
    //             operator: "eq",
    //             value: 21 // role Inspector
    //         },
    //         {
    //             field : "user_profile][deleteStatus]",
    //             operator : "eq",
    //             value : "N"
    //         },
    //         {
    //             field : "property][id]",
    //             operator : "null",
    //             value : true
    //         },
    //         {
    //             field: "id",
    //             operator: "nin",
    //             value: IdFilter.length > 0 ? IdFilter : 0
    //         }
    //     ]
    // });

    // console.log('selInspector', selInspector)
    // console.log('selInspectorAdmin', selInspectorAdmin)

    // const [ count, setCount] = useState(0);
    // const [ InspecAdminOption, setInspecAdminOption ] = useState<any>(undefined);


    // useEffect(() => {
    //     if (count < 3) { 
    //         const interval = setInterval(() => {
    //             setCount(prev => prev + 1)
    //             setInspecAdminOption(selInspectorAdmin?.options);

    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count])

    const AddInspectorToArr = async (e:any) => {
        // console.log('e', e)
        IdFilter.push(e)
        SetIdFilter(IdFilter);
        // let k = await axios.get(API_URL + "/user-type-mappings?populate=*&filters[id][$eq]="+ e, {
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        console.log('k', k)
        let inspector_profile = k?.data?.data
        // console.log('inspector_profile', inspector_profile[0].attributes.user_profile?.data.attributes)

        Inspector.push(inspector_profile[0].attributes.user_profile?.data.attributes);
        InspectorId.push(inspector_profile[0].attributes.user_profile?.data.id);
        SetInspector(Inspector)

        setTimeout(() => {
            form.resetFields(["Inspector"]);
        }, 500);

        // console.log('Inspector', Inspector)
        // console.log('IdFilter', IdFilter)
    }

    const RemoveItemFromArr = async (e:any) => {
        // console.log('e', e)
        Inspector.splice(e, 1); // 2nd parameter means remove one item only
        InspectorId.splice(e,1)
        IdFilter.splice(e, 1);
    }

    // console.log('Inspector', Inspector)
    // console.log('InspectorId', InspectorId)
    // console.log('IdFilter', IdFilter)

    async function createAssignInspec(values:any) {

        console.log('Inspector', Inspector)
        console.log('InspectorId', InspectorId)
        console.log('IdFilter', IdFilter)
        console.log('values', values)

        let data = { 
            "data":{
                "property": values?.property,
                inspec_team: InspectorId,
                "createBy" : Identity_data ? Identity_data.user_profile.id : null
            }
        }

        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/repair-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        successNoti();

        replace("/repair/repair-setup")

    }

    // const successNoti = (stat:any, msg:any) => {
    //     notification.success({
    //         // message: stat + ' : ' + msg.TH,
    //         message: msg,
    //         // description: msg,
    //         duration: 3,
    //     });
    // };

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully created',
        });
    };

    const warnningAddInspec = () => {
        notification.warning({
            // message: stat + ' : ' + msg.TH,
            message: "กรุณาเลือกช่างอย่างน้อย 1 คน",
            // description: msg,
            duration: 3,
        });
    };

    return (
    <Create saveButtonProps={{ hidden: true }} pageHeaderProps={{extra: <></>}}>
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.create")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Setup แจ้งซ่อม</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                {...formProps}
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    // createAssignInspec(values);
                    if(InspectorId.length <= 0){
                        warnningAddInspec()
                    }else{
                        values.property = values.property.id;
                        createAssignInspec(values);

                    }
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={6} className="gutter-row" style={{borderRight: "1px solid #dedede",paddingTop: "2.5rem"}}>
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty}
                                    onChange={(e) => setProp(e)}
                                />
                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                name="Inspector"
                                label="Inspector"
                                // rules={[
                                //     {
                                //         required: true, message: "Please select type"
                                //     },
                                // ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selInspector}
                                    onSelect={(value: any, record:any) => {
                                        AddInspectorToArr(value);
                                        refetch();
                                    }}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={18} className="gutter-row" style={{paddingTop: "2.5rem"}}>
                            <Row gutter={82}>
                                <Col span={24} className="gutter-row">

                                <div style={{fontSize:"1vw"}}>{t("mdpc-assign-technician.list-of-technician")}</div>
                                   <div> 
                                    {
                                        Inspector.length > 0 ?
                                            Inspector.map((item: any, index: any) => {
                                                // console.log('item', item)
                                                // console.log('index', index)
                                                return <>
                                                <Row gutter={8} style={{marginTop: "1rem"}}>
                                                    <Col span={22} className="gutter-row">
                                                        <Row gutter={8}>
                                                            <Col span={4} className="gutter-row">
                                                                <Image 
                                                                    alt="Avatar" 
                                                                    src={item?.avatar?.data !== null ? item?.avatar?.data?.attributes.url : "https://lscmain.s3.ap-southeast-1.amazonaws.com/toppng_com_user_account_management_logo_user_icon_561x561_66926db800.png"}
                                                                    style={{borderRadius : "50px",width: "100%", height: "50px"}}
                                                                />
                                                            </Col>
                                                            <Col span={20} className="gutter-row">
                                                                <div>{item.first_name} {item.last_name}</div>
                                                                <div><Icons.PhoneOutlined />{" "}{item.tel ? item.tel : "no phone number"}</div>
                                                            </Col>
                                                        </Row> 
                                                    </Col>
                                                    <Col span={2} className="gutter-row">
                                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} 
                                                            onClick={() => { RemoveItemFromArr(index); refetch(); }} 
                                                            danger 
                                                        />
                                                    </Col>

                                                </Row>
                                                <div style={{marginBottom:"20px"}}></div> 
                                                </>
                                            })
                                        :
                                            <>
                                                <div className="bgimg" style={{width:"100%", height:"700px", backgroundPosition:"center"}}></div>
                                            </>
                                    }
                                   </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Create>   
    );
};