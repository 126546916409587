/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
// import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
// import MDEditor from '@uiw/react-md-editor';


let swLocal : boolean = true;
let dataonFilter: any = [];
export const RepairMdpcAssignTechnicianList: React.FC<IResourceComponentsProps> = () => {
    const [form] = Form.useForm();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const { create, edit } = useNavigation();
    const [dataList, setDataList] = useState<any>([]);
    const [dataBlank, setDataBlank] = useState<any>([]);

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);


    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource:'repair-setting-mdpcs',
        metaData: {
            // populate: [
            //     '*',
            // ]
            populate: ['*', 'rp_setting', 'rp_setting.createBy', 'rp_setting.updateBy', 'createBy', 'updateBy','property'],

        },
        pagination:{
            pageSize: 1000,
        },
        permanentFilter: [
            // {
            //     field: "property][id]",
            //     operator: "eq",
            //     value: prop_list ? prop_list: prop_id,
            //     // value: prop_id,
            // }
            {
                field: "property][id]",
                operator: prop_list ? "in" : "eq",
                value: prop_list ? prop_list: prop_id,
                // value: prop_id,
            }
        ],
        queryOptions: {
          onSuccess: (data:any) => {
            let dataLoad: any = data?.data;
            // dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))

            // if(prop_id){
            //     dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            // }

            // if(prop_list){
            //     dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property.id));
            // }
            if(prop_list !== undefined){
                console.log('case 1')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property?.id));
            }else if(prop_id !== null){
                console.log('case 2')
                dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            }

            // console.log('prop_list', prop_list)
            // console.log('dataLoad kkk', dataLoad)
            
            let xx = dataLoad.filter((e:any) => (e.inspec_team.length > 0 ));

            // let numbersArray = data_multi_property !== null ? data_multi_property?.split(',').map(Number) : [] ;
            // if(numbersArray?.length > 1){
            //     xx = xx.filter((item:any) => numbersArray?.includes(item.property.id));
            // }else{
            //     xx = xx.filter((e:any) => (e.property.id == Number(prop_id) ));
            // }
            let newDataList = []
            for (let index = 0; index < xx.length; index++) {
                //dataList.push(xx[index]);
                newDataList.push(xx[index])
            }
            if (!!newDataList && newDataList !== null) {
                setDataList(newDataList.reverse())
            }else{
                setDataList(xx)
            }

            // setDataList(dataLoad.reverse())
            
          },
        },
    });

    // console.log('dataList', dataList)

    const columns: any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'Property',
            // dataIndex: 'property',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.property.property_name.localeCompare(b.property.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record?.property !== null ? record?.property?.property_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            //sorter: (a:any, b:any) => a.inspec_team.first_name.localeCompare(b.inspec_team.first_name),
            render: (val:any, record:any) => {
                // console.log('record', record)
                // if (Array.isArray(record.inspec_team)) {
                if (record.inspec_team.length > 0) {
                  return (
                    <>
                      {record.inspec_team.map((val:any, index:any) => (
                        // <div key={index}>{val !== undefined ? val.first_name : '-'}</div>
                        <Tag color={"#00FF32"} style={{border: "1px solid #DAC32F", borderRadius: "10px", color:"#234164"}}>{val.first_name}</Tag>
                      ))}
                    </>
                  );
                }else{
                    return <div>-</div>;
                }
                // return <div>{val}</div>;
                // return <div>xxxx</div>;
            },
        },
        {
            title: 'CREATE BY',
            // dataIndex: 'createBy',
            key: 'createBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.createBy?.first_name.localeCompare(b.createBy?.first_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                </>
            },
        },
        {
            title: 'CREATED DATE',
            // dataIndex: 'create_date',
            key: 'create_date',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'updateBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.updateBy?.first_name.localeCompare(b.updateBy?.first_name),
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'Last Update',
            // dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 100,
            fixed: 'right',
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            onClick={() => edit("repair-mdpc-assign-technician", record?.property?.id + "_" + record.id )}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                        />
                        {/* <CreateButton id="create_btn" onClick={()=>edit("notification", )} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }></CreateButton> */}

                    </>
                );
            },
        },
    ];

    // console.log(">>> prop_list", prop_list);
    // console.log(">>> prop_id", prop_id);


    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
      
    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    const [selProp, setselProp] = useState<any>();
    const [nameSearch, setnameSearch] = useState<any>("");

    const clearDataSource = () => {
        setDataList([])
    }

    function onSearchtable(data: any, mode:any, type:any){
        // let newData:any = data;
        let newData:any = [];
        if(mode == "S"){
            // newData = nameSearch !== "" ? dataonFilter?.filter((e:any)=> e?.email?.includes(String(nameSearch))):newData; // search email
            // newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property_id.includes(String(selProp?.id))}):newData;
            if (!!selProp && !nameSearch) {
                newData = dataList?.filter((e:any) => e?.property?.id == selProp?.id);
                setDataList(newData);
                //console.log('newData', newData)
            }else if(!selProp && !!nameSearch){
                newData = dataList?.filter((item:any) => item?.property?.property_name?.toLowerCase().includes(nameSearch?.toLowerCase()) || item.inspec_team?.some((prop:any) => prop.first_name?.toLowerCase().includes(nameSearch?.toLowerCase())));
                setDataList(newData);
                //console.log('newData', newData)
            }else if(!!selProp && !!nameSearch){
                newData = dataList?.filter((e:any) => e?.property?.id == selProp?.id &&
                (e?.property?.property_name?.toLowerCase().includes(nameSearch?.toLowerCase()) || e.inspec_team?.some((prop:any) => prop.first_name?.toLowerCase().includes(nameSearch?.toLowerCase())))
                );
                setDataList(newData);
                //console.log('newData', newData)
            }
            
            // newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property?.id == selProp?.id}) : newData;
            // newData = nameSearch !== undefined ?  dataList?.filter((item:any) => item.inspec_team?.some((prop:any) => prop.first_name.includes(nameSearch))) : newData;
            //console.log('newData', newData)
            //setDataList(newData);
        }else if(mode == "R"){
            form?.resetFields();
            clearDataSource()
            refetch();
            setselProp(undefined);
            // setselUnitType(undefined);
            setnameSearch("");
        }
    
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    return <>
        <List 
            // title={t("mjcnotimsg.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjcnotimsg.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" 
                        // {...formProps} 
                        onFinish={(values:any) => {}}
                        form = {form} 
                    >
                        <Row gutter={20} style={{width: "100%"}}>
                            
                            <Col span={4} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select placeholder={t("select.sel")} 
                                        {...selProperty} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="inspec_name" label="Name">
                                    <Input placeholder="Search Inspector" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        // suffix={<Icons.SearchOutlined/>} 
                                    allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S", "xxxx")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R", "xx")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                   
                    <Form.Item style={{marginRight: "0",}}>
                        <CreateButton 
                            id="create_btn" 
                            onClick={()=>create("repair-mdpc-assign-technician")} 
                            // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                        ></CreateButton>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={12}>
                <Col span={22}>
                    <Form
                        // {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%"}}
                    >

                        {/* <Form.Item name="property">
                            <Select placeholder="Property" {...selProperty} allowClear style={{width: "220px"}}/>
                        </Form.Item>

                        <Form.Item name="type">
                            <Select placeholder="Type" {...selDocType} allowClear style={{width: "220px"}}/>
                        </Form.Item>

                        <Form.Item >
                            <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item> */}

                        {/* <Form.Item >
                            <Button 
                                onClick={() => {
                                    // searchFormProps.form?.submit()
                                    searchFormProps.form?.resetFields()
                                    searchFormProps.form?.submit()
                                }} 
                                htmlType="submit" 
                                style={{borderRadius: "5px"}}
                                icon={<Icons.UndoOutlined />}
                            >
                                Reset
                            </Button>
                        </Form.Item> */}
                    </Form>

                </Col>
                {/* <Col span={2}>
                    <Form
                        // {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton 
                                id="create_btn" 
                                onClick={()=>create("repair-mdpc-assign-technician")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col> */}
            </Row>

            <Table 
                dataSource={dataList?.length > 0 ? dataList : dataBlank} 
                columns={columns} 
                onChange={onChange} 
                // pagination={{ size: "small", position: [ bottom] }} 
                // pagination={{ size: "small", position: [ "bottomRight"] }} 
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    // showTotal: showTotal, 
                    pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                    showSizeChanger: true, // Optional - Show page size changer dropdown
                    // showQuickJumper: true, // Optional - Show quick jumper input
                    // pageSize: 10, // Optional - Default page size
                    pageSize: pageSize,
                    onChange: handlePageSizeChange,
                }}
                scroll={{ x: 1300 }}
            />
            {/* <Table columns={columns} dataSource={data} onChange={onChange} /> */}
        </List>
    </>
}