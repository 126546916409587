import { TextField,Card, Show, List, Space, Breadcrumb, Row, Col, Form, Carousel, Image, Empty, Divider, Tag } from "@pankod/refine-antd";
import { IAppointment, IUserhunit } from "interfaces";
import { useList, useShow, useTranslate } from "@pankod/refine-core";
import { ScheduleOutlined } from "@ant-design/icons";

// import Moment from 'moment';
// import { useEffect, useState } from "react";
import dayjs from "dayjs";
// import "./css/style.css";

export const AppointmShow = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IAppointment>({  
        metaData: {
        populate: [
            'property',
            'property.address_amphure',
            'property.address_district',
            'property.address_provice',
            'property.image_list',
            'project',
            'user_profile',
            'user_profile.first_name',
            'user_profile.last_name',
            'user_profile.user_type',
            'user_profile.avatar',
            'appointmentType',
            'appointment_time_slot',
            'unit'
        ]
    }, 
});

    const { data, isLoading } = queryResult;
    const record = data?.data;

    let recordData:any = []
    recordData = data?.data? data?.data : [];
    console.log('recordData', recordData)


    const UserHasUnit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile','user_sub_type','unit','unit.property'],
        },
        config:{
            filters: [
                {
                    field   : "user_profile][id]",
                    operator: "eq",
                    value   : recordData.user_profile ? recordData.user_profile.id + "" : null
                },
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : recordData.unit ? recordData.unit.id + "" : null
                },
            ]
        }
    });

    // {
    //     UserHasUnit.data ?
    //         UserHasUnit.data?.data.filter((obj:any) => {
    //             // console.log('obj', obj)
    //             return obj.unit.id === recordData.unit.id
    //         })
    //     :
    //         <></>
    // }

    console.log('UserHasUnit', UserHasUnit.data?.data)

    
    // const { Text } = Typography;
    // console.log("mmmmm",recordData.property.image_list ? "true":"false")

    return (
        <Show 
            isLoading={isLoading} 
            pageHeaderProps={{extra: <></>}}
        >        
            <List 
                    title={t("")}
                    pageHeaderProps={
                        { 
                            extra : <></>,
                            // breadcrumbRender: <></>
                        }
                    }
                    // pageHeaderProps={{ 
                    //     breadcrumb:  <Breadcrumb>
                    //     <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    //     <Breadcrumb.Item>{t("appoint.title")}</Breadcrumb.Item>
                    //     <Breadcrumb.Item><span className="last_breadcrumb">{t("appointm-show.title")}</span></Breadcrumb.Item>
                    // </Breadcrumb>,
                    //     extra : <></>
                    // }}
            />

                <Space style={{marginBottom: "3%"}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("appoint.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("appointm-show.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={13}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset",marginBottom: "2%"}}>
                                        <Form.Item>
                                            <ScheduleOutlined style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#D6C6A0",marginRight: "3%"}} />
                                            {
                                                recordData?.appointmentType ? 
                                                <>
                                                    <TextField value={record?.appointmentType ? record?.appointmentType.name : '-'} style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#fff"}} />
                                                </>
                                                :
                                                <>NO DATA</>
                                                }
                                        </Form.Item>

                                        {/* {
                                            recordData?.project ? 
                                            <>
                                            <span>
                                                <span 
                                                    style={{
                                                        fontSize: "0.8vw",
                                                        color: "#fff",
                                                        marginTop: "10%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "#D6C6A0"
                                                    }} 
                                                >
                                                    <TextField style={{fontSize: "0.8vw",marginRight: "1%",color: "#fff"}} value={recordData?.project.project_name !== null ? recordData?.project.project_name : <></>} />
                                                    <Text style={{fontSize: "0.8vw",color: "#fff"}}>(</Text><TextField style={{fontSize: "0.8vw",color: "#fff"}} value={recordData?.project.project_code !== null ? recordData?.project.project_code : <></>} /><Text style={{fontSize: "0.8vw",color: "#fff"}}>)</Text>
                                                </span>
                                            </span>
                                            </>
                                            :
                                            <></>
                                        } */}

                                        {/* {
                                            recordData?.appointmentType ? 
                                            <>
                                            <span style={{marginLeft: "2%"}}>
                                                <TextField 
                                                    style={{
                                                        fontSize: "0.8vw",
                                                        color: "#40495F",
                                                        marginTop: "10%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "#F2F3F5"
                                                    }} 
                                                        value={recordData?.appointmentType.name !== null ? recordData?.appointmentType.name : <></>}  />
                                            </span>
                                            </>
                                            :
                                            <></>
                                        } */}

                                        {/* <span style={{marginLeft: "2%"}}>
                                            <TextField 
                                                style={{
                                                    fontSize: "0.8vw",
                                                    color: "#40495F",
                                                    marginTop: "10%",
                                                    borderRadius: "50px", 
                                                    paddingLeft: "2%", 
                                                    paddingRight: "2%",
                                                    paddingBottom: "1%",
                                                    paddingTop: "1%",
                                                    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                    background: "#F2F3F5"
                                                }} 
                                                    value={record?.appointment_time_slot ? record?.appointment_time_slot.slot_name :<>-</>}  />
                                        </span> */}
                                    </Card>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                        
                                        {/* <div style={{marginBottom: "3%"}}><span style={{fontSize: "1vw",fontWeight: "600"}}>{t("property.title")} : <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.property ? record?.property.property_name : <></>} /></span></div>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={12} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                                {
                                                    recordData?.property ?<>
                                                        <div>{t("properties.address")} <TextField value={recordData?.property.address ? recordData?.property.address : '-'} /></div>
                                                        <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={recordData?.property.address_soi !== null ? recordData?.property.address_soi : <></>} />
                                                        <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={recordData?.property.address_road !== null ? recordData?.property.address_road : <></>} />
                                                        <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={recordData?.property.address_amphure !== null ? recordData?.property.address_amphure.name_th : <></>} />
                                                        <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={recordData?.property.address_provice !== null ? recordData?.property.address_provice.name_th : <></>} />
                                                        <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={recordData?.property.address_district !== null ? recordData?.property.address_district.name_th : <></>} />
                                                        <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={recordData?.property.address_district !== null ? recordData?.property.address_district.zip_code : <></>} />
                                                    </>
                                                    :
                                                    <></>
                                                }
                                                
                                                <div>
                                                    <Text style={{marginRight: "2%",fontSize: "0.7vw"}}>{t("properties.tax")} : </Text> <TextField style={{marginRight: "5%",fontSize: "0.7vw"}} value={record?.property ? record?.property.tax_no : '-'} />
                                                    <Text style={{marginRight: "2%",fontSize: "0.7vw"}}>{t("properties.fax")} : </Text> <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={record?.property ? record?.property.fax : '-'} />
                                                </div>
                                                
                                                <div style={{marginTop: "5%"}}><span style={{background: "#40495F",borderRadius: "20px",padding: "1% 3% 1%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={record?.property ? record?.property.tel : '-'} /></span></div>
                                            </Col>
                                                
                                            <Col className="gutter-row" span={12}>
                                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                                    <Col className="gutter-row" span={12}>
                                                        <div style={{fontSize: "0.7vw"}}>{t("lat")} : <TextField style={{fontSize: "0.7vw"}} value={record?.property ? record?.property.lat : '-'} /></div>
                                                    </Col>
                                                    
                                                    <Col className="gutter-row" span={12}>
                                                        <div style={{fontSize: "0.7vw"}}>{t("lng")} : <TextField style={{fontSize: "0.7vw"}} value={record?.property? record?.property.lng : '-'} /></div>
                                                    </Col>
                                                </Row> 
                                            
                                                <div style={{marginTop: "5%",fontSize: "0.7vw"}}>{t("properties.about")} : <TextField style={{fontSize: "0.7vw"}} value={record?.property ? record?.property.about : '-'} /></div>
                                            </Col>
                                        </Row> */}

                                        <div style={{marginBottom: "3%"}}><span style={{fontSize: "1vw",fontWeight: "600"}}>วันที่ Create : <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={recordData?.createdAt ? dayjs(recordData?.createdAt).format("YYYY-MM-DD") : <></>} /></span></div>

                                        <Divider></Divider>

                                        <div style={{marginBottom: "3%",textDecoration: "underline"}}><span style={{fontSize: "1vw",fontWeight: "600"}}>รายละเอียดการนัด</span></div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>วันที่นัด : &nbsp;
                                                <TextField style={{fontSize: ".9vw",fontWeight: "400",letterSpacing: "0.5px"}} value={recordData?.appointmentDate ? recordData?.appointmentDate :<>-</>} />
                                            </span>
                                        </div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>เวลาที่นัด : &nbsp;
                                                <TextField style={{fontSize: ".9vw",fontWeight: "400",letterSpacing: "0.5px"}} value={recordData?.appointment_time_slot ? recordData?.appointment_time_slot.slot_name :<>-</>} />
                                            </span>
                                        </div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>โครงการ : &nbsp;
                                                <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.property ? record?.property.property_name : <></>} />
                                            </span>
                                        </div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>Customer Type : &nbsp;
                                                {/* <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} 
                                                    value={
                                                        recordData?.user_profile ?
                                                            recordData?.user_profile.user_type ? recordData?.user_profile.user_type.type_name : <></>
                                                            : 
                                                                <></>
                                                    } 
                                                /> */}

                                                {/* <Tag className="tag_post">
                                                    {data.user_sub_type.sub_type_name} | &nbsp;
                                                    {data.unit.property.property_name} | &nbsp;
                                                    {data.unit.unit_name}
                                                </Tag> */}
                                                {
                                                    // UserHasUnit.data ?
                                                    //     UserHasUnit.data?.data.filter((obj:any) => {
                                                    //         // console.log('obj', obj)
                                                    //         return obj.unit.id === recordData.unit.id
                                                    //     })
                                                    // :
                                                    //     <></>
                                                }

                                                {
                                                    UserHasUnit.data ? 
                                                        UserHasUnit.data?.data.length > 0 ? 
                                                            UserHasUnit.data?.data.map((data:any) => {
                                                                return <>
                                                                    <Tag className="tag_post">
                                                                        {/* <TextField value={data.user_sub_type.sub_type_name} /> */}
                                                                        {data.user_sub_type.sub_type_name} | &nbsp;
                                                                        {data.unit.property.property_name} | &nbsp;
                                                                        {data.unit.unit_name}
                                                                    </Tag>
                                                                </>
                                                            })
                                                        : <></>
                                                    : <></>
                                                }

                                                
                                                {/* {
                                                    record.coupons ?
                                                        record?.coupons.length !== 0 ?
                                                            record?.coupons.map((data:any,index:any) => {
                                                                // console.log('data : ', data);
                                                                return <>
                                                                    <Tag className="tag_post"><TextField value={data? data.coupon_name : <></> }  /></Tag>
                                                                </>
                                                            })
                                                        : <>-</>
                                                    : <>-</>
                                                } */}
                                            </span>
                                        </div>
                                    </Card>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                        {/* <Divider></Divider> */}
                                        <div style={{marginBottom: "3%",textDecoration: "underline"}}><span style={{fontSize: "1vw",fontWeight: "600"}}>รายละเอียดส่วนตัว</span></div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>ชื่อ-นามสกุล : &nbsp;
                                                <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={recordData?.customerFirstName ? recordData?.customerFirstName : <></>} />
                                                {' '}
                                                <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={recordData?.customerLastName ? recordData?.customerLastName : <></>} />
                                            </span>
                                        </div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>อีเมล์ : &nbsp;
                                                <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={recordData?.customerEmail ? recordData?.customerEmail : <></>} />
                                            </span>
                                        </div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>เบอร์โทรศัพท์ : &nbsp;
                                                <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={recordData?.customerPhoneNumber ? recordData?.customerPhoneNumber : <></>} />
                                            </span>
                                        </div>
                                        <div style={{marginBottom: "3%"}}>
                                            <span style={{fontSize: ".9vw",fontWeight: "400"}}>รายละเอียด : &nbsp;
                                                <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={recordData?.detail ? recordData?.detail : <></>} />
                                            </span>
                                        </div>
                                    </Card>
                                </Col>

                                {/* <Col className="gutter-row" span={24}>
                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                                        <Row gutter={16}>

                                            <Col className="gutter-row" span={22}>
                                                <div style={{marginTop: "1.7%"}}>
                                                <TextField style={{marginRight: "1%",fontSize: "0.7vw"}} value={recordData?.customerFirstName !== null ? recordData?.customerFirstName : <></>} />
                                                <TextField style={{marginRight: "2%",fontSize: "0.7vw"}} value={recordData?.customerLastName !== null ? recordData?.customerLastName : <></>} />
                                                <span style={{background: "#D6C6A0",borderRadius: "20px",padding: "0.5% 2% 0.5%",color: "#fff",marginRight: "2%"}}><Text style={{fontSize: "0.7vw",color: "#fff",marginRight: "2%"}}>Email :</Text><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={recordData?.customerEmail !== null ? recordData?.customerEmail : <></>} /></span>
                                                <span style={{background: "#40495F",borderRadius: "20px",padding: "0.5% 2% 0.5%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={recordData?.customerPhoneNumber !== null ? recordData?.customerPhoneNumber : <></>} /></span>
                                                </div>
                                            </Col>

                                        </Row>
                                    </Card>
                                </Col> */}

                            </Row>
                    </Col>

                    {/* {console.log(recordData.property)} */}

                    <Col className="gutter-row" span={5}>
                    {/* recordData.property.image_list !== undefined && recordData.property.image_list !== null ?  */}
                        {recordData.property !== undefined && recordData.property !== null ? 
                        <>
                            {
                                recordData.property.image_list !== null ? 
                                    <Carousel style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px",height: "23vh"}} autoplay>
                                        {
                                            recordData.property.image_list.map((data:any,index:any) => {
                                                return <>
                                                    {/* {console.log(data.url)} */}
                                                    <Image
                                                        src={data.url}
                                                        width={"100%"} height={"23vh"} style={{height:"100%",objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px"}} />
                                                    </>
                                            })
                                            }
                                    </Carousel>
                                    :
                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",height: "23vh"}}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </Card>
                            }
                        </>
                        :<></>}
                    </Col>


                    {/* <Col className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>
                                                        
                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createdBy? record?.createdBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{fontSize: "0.7vw"}}>{record?.updatedBy? record?.updatedBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
        </Show>
    );
};
    
    
    
//     <Card
//     style={{ borderRadius: 5 }}
//     loading={isLoading}
// >
//     <PageHeader
//         style={{ padding: 0 }}
//         onBack={() => window.history.back()}
//         title= ""
//     />

        

//         <Card style={{backgroundColor: "#fff"}}>
//         <Descriptions
//             size="small"
//             // title={<Tag color="blue"> test </Tag>}
//             column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }}
//             bordered>
//             <Descriptions.Item span={1} label={t("title")}>
//                 <TextField value={record?.title ? record?.title : '-'} />
//             </Descriptions.Item>
//             <Descriptions.Item span={1} label={t("detail")}>
//                 <TextField value={record?.detail ? record?.detail : '-'} />
//             </Descriptions.Item>
//             <Descriptions.Item span={3} label="Created At" >
//                 <TextField value={Moment().format('D/MM/YYYY HH:mm a')}  />
//             </Descriptions.Item>
//         </Descriptions>
//         </Card>
// </Card>
// }