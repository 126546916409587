/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Divider, Switch, Input, Typography, useSelect, InputNumber, Upload, RcFile, message, notification, Radio, Button, Icons, Edit } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL, ROCKET_URL, TOKEN_KEY } from "../../../constants";

import axios from "axios";
import { getValueProps, useStrapiUpload } from "@pankod/refine-strapi-v4";
import Dragger from "antd/lib/upload/Dragger";


export const RepairDocumentEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const token = localStorage.getItem(TOKEN_KEY);
    const { replace, list} = useNavigation();

    const tabTermActive = localStorage.getItem("keyTabTermRepair");

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: UpdateDoc } = useUpdate<any>();
    const { TextArea } = Input;
    const { Text } = Typography;
    const t = useTranslate();
    const [form] = Form.useForm();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { confirm } = Modal;
    // const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    const log_endpoint = "even-logs";
    const [text, setText] = useState('');


    const [DocTh, SetDocTh] = useState<any>();
    const [DocThId, SetDocThId] = useState<any>();
    const [DocEn, SetDocEn] = useState<any>();
    const [DocEnId, SetDocEnId] = useState<any>();
    const [CoverEn, SetCoverEn] = useState<any>();
    const [CoverEnId, SetCoverEnId] = useState<any>();
    const [CoverTh, SetCoverTh] = useState<any>();
    const [CoverThId, SetCoverThId] = useState<any>();



    const [vInfoTh, setInfoTh] = useState<any>();
    const [vInfoEn, setInfoEn] = useState<any>();
    const [Stat, SetStat] = useState<any>(true);
    const [Property, SetProperty] = useState<any>();
    const [Category, SetCategory] = useState<any>();

    const [RequireDocTh, SetRequireDocTh] = useState<any>(false);
    const [RequireDocEn, SetRequireDocEn] = useState<any>(false);
    const [RequireCoverTh, SetRequireCoverTh] = useState<any>(false);
    const [RequireCoverEn, SetRequireCoverEn] = useState<any>(false);


    const [DocName, SetDocName] = useState<any>(undefined);
    const [DocNameEn, SetDocNameEn] = useState<any>(undefined);

    const [LinkVidTh, SetLinkVidTh] = useState<any>(undefined);
    const [LinkVidEn, SetLinkVidEn] = useState<any>(undefined);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let doc_id = id[1]

    // const { form, formProps, saveButtonProps } = useForm<IFacilityBooking,HttpError,{}>({
    //     onMutationSuccess(data, variables, context) {
    //         let recordData:any = []
    //         recordData = data?.data? data?.data : [];
    //         CreateLog({
    //             resource : log_endpoint,
    //             values : {
    //                 user_profile            : user_data.user_profile.id,
    //                 log_time_stamp          : Date.now(),
    //                 log_event               : "Create",
    //                 menu_event              : 'hcu-settings',
    //                 SysPkID                 : recordData.data.id + ""
    //             },
    //             successNotification : false
    //         });
    //     },
    //     errorNotification(error:any, values:any, resource:any) {
    //         return {
    //             message: error.toString(),
    //             type: "error" ,
    //             undoableTimeout: 1000
    //         }
    //     },
    //     onMutationError(error, variables, context) {
    //         console.log('error', error)
    //         console.log('variables', variables)
    //         console.log('context', context)
    //     },
    // });

    const getDataK = async ()=>{
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let res = await axios.get(API_URL + `/repair-documents?populate=*&&filters[id][$eq]=${doc_id}` , { headers: { Authorization: AuthStr } });

        let DataLoad = res?.data?.data[0]
        DataLoad = DataLoad?.attributes
        console.log('DataLoad', DataLoad)

        let DataCate = DataLoad.repair_document_category && DataLoad.repair_document_category?.data?.id
        let DataProperty = DataLoad.property && DataLoad.property.data?.id

        SetDocName(DataLoad ? DataLoad.doc_name : '-')
        SetDocNameEn(DataLoad ? DataLoad.doc_name_en : '-')

        SetLinkVidTh(DataLoad ? DataLoad.link_vid : '-')
        SetLinkVidEn(DataLoad ? DataLoad.link_vid_en : '-')

        SetStat(DataLoad ? DataLoad.deleteStatus == "N" ? true : false : false)

        SetProperty(DataProperty);
        SetCategory(DataCate);


        // ========== DOC TH ===========
        SetDocThId(DataLoad?.document?.data.id);
        const fileList_Doc_Th:any[] =
            [
                {
                    id: DataLoad?.document?.data.id,
                    uid: String(DataLoad?.document?.data.id),
                    name: DataLoad?.document?.data?.attributes.name,
                    status: "done",
                    url: DataLoad?.document?.data?.attributes.url,
                    thumbUrl: DataLoad?.document?.data?.attributes.url
                }
            ];

            SetDocTh({
                name: 'files',
                multiple: true,
                // defaultFileList: [...fileList3],
                defaultFileList: [...fileList_Doc_Th],
            })
        form.resetFields(["doc_th"]);

        // ========== DOC EN ===========
        SetDocEnId(DataLoad?.document_en?.data.id);
        const fileList_Doc_En:any[] =
            [
                {
                    id: DataLoad?.document_en?.data.id,
                    uid: String(DataLoad?.document_en?.data.id),
                    name: DataLoad?.document_en?.data?.attributes.name,
                    status: "done",
                    url: DataLoad?.document_en?.data?.attributes.url,
                    thumbUrl: DataLoad?.document_en?.data?.attributes.url
                }
            ];

            SetDocEn({
                name: 'files',
                multiple: true,
                // defaultFileList: [...fileList3],
                defaultFileList: [...fileList_Doc_En],
            })
        form.resetFields(["doc_en"]);

        // ========== PIC TH ===========
        SetCoverThId(DataLoad?.cover?.data.id)
        const fileList_Pic_Th:any[] =
            [
                {
                    id: DataLoad?.cover?.data.id,
                    uid: String(DataLoad?.cover?.data.id),
                    name: DataLoad?.cover?.data?.attributes.name,
                    status: "done",
                    url: DataLoad?.cover?.data?.attributes.url,
                    thumbUrl: DataLoad?.cover?.data?.attributes.url
                }
            ];

            SetCoverTh({
                name: 'files',
                multiple: true,
                // defaultFileList: [...fileList3],
                defaultFileList: [...fileList_Pic_Th],
            })
        form.resetFields(["cover_th"]);

        // ========== PIC EN ===========
        SetCoverEnId(DataLoad?.cover_en?.data.id)
        const fileList_Pic_En:any[] =
            [
                {
                    id: DataLoad?.cover_en?.data.id,
                    uid: String(DataLoad?.cover_en?.data.id),
                    name: DataLoad?.cover_en?.data?.attributes.name,
                    status: "done",
                    url: DataLoad?.cover_en?.data?.attributes.url,
                    thumbUrl: DataLoad?.cover_en?.data?.attributes.url
                }
            ];

            SetCoverEn({
                name: 'files',
                multiple: true,
                // defaultFileList: [...fileList3],
                defaultFileList: [...fileList_Pic_En],
            })
        form.resetFields(["cover_en"]);


        form.setFieldsValue({
            doc_th: DocTh,
            doc_en: DocEn,
            cover_th: CoverTh,
            cover_en: CoverEn,
        })


        form.setFieldsValue({
            doc_name: DataLoad.doc_name,
            link_vid: DataLoad.link_vid,
            doc_name_en: DataLoad.doc_name_en,
            link_vid_en: DataLoad.link_vid_en,
            category: DataCate, 
            property: DataProperty,
            status: DataLoad.deleteStatus == "N" ? true : false
        })

        // // ======================== FIND PROPERTY TYPE ========================================
        // // https://api-dev-majorcare.techscape.app/api/majorcare/findAllPropertyType
        // let res = await axios.get(majorCareUrl + "/majorcare/findAllPropertyType" , { headers: { } });
        // console.log('res', res)
        // let Data = res?.data

        // SetDataProperty(Data ? Data : null)

        // // ======================== FIND DATA ZONE ========================================
        // // https://api-dev-majorcare.techscape.app/api/majorcare/repairZone/649577113b5aa8c99502b24a
        // let resDataCatDetail = await axios.get(majorCareUrl + "/majorcare/repairCategoryDetail/findOne/" + item_id , { headers: { } });
        // console.log('resDataZone', resDataCatDetail?.data)

        // // console.log('resDataZone?.data.property_type', resDataCatDetail?.data.property_type)

        // let newData: any = [];

        // if(resDataCatDetail?.data?.property_type){
        //     for (let index = 0; index < resDataCatDetail?.data?.property_type.length; index++) {
        //         newData?.push(resDataCatDetail?.data?.property_type[index]?._id)
        //     }
        // }

        // SetPropertyType((pre:any)=> newData)

        // SetWaranty(resDataCatDetail?.data.warantee)
        // SetSla(resDataCatDetail?.data.sla)
        // SetFixingTime(resDataCatDetail?.data.repair_time)
        // SetZoneTh(resDataCatDetail?.data.category_detail_name);
        // SetZoneEn(resDataCatDetail?.data.category_detail_name_en);

        // setCategorySelected(resDataCatDetail?.data.category_name);
        
    }

    console.log('DocTh', DocTh)

    // console.log('LinkVidTh', LinkVidTh)

    useEffect(() => {
        getDataK();
        // getCategory();
    }, []);

    const handleChange = (e:any) => {
        setText(e.target.value);
    };

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
             {
                field : "id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });

    const { selectProps: selCategory } = useSelect<any>({
        resource    : "repair-document-categories",
        optionLabel : "category_name",
        metaData: {
            populate: '*',
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    // function fileChangeDoc(e:any, type:any){
    //     console.log('e', e)
    //     console.log('type', type)
    //     if(type == "en"){
    //         if(e.file.status == "removed"){
    //             SetRequireDocEn(true)
    //         }else{
    //             SetRequireDocEn(false)
    //         }
    //         if(e.file.response){
    //             let res = e.file.response;
    //             console.log(res)
    //             SetDocEnId(res[0].id)
    //         }
    //     }else{
    //         if(e.file.response){
    //             if(e.file.status == "removed"){
    //                 SetRequireDocTh(true)
    //             }else{
    //                 SetRequireDocTh(false)
    //             }
    //             let res = e.file.response;
    //             console.log(res)
    //             SetDocThId(res[0].id)
    //         }
    //     }
    // }

    function fileChangeDoc(e: any, type:any) {
        // console.log('event', e)
        if(type == "en"){
            if(e.file.status === "removed") {
                SetDocEn((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [],
                }));
                form?.resetFields(["doc_en"])
            }else if(e.file.status === "done"){
                if (e.file.response) {
                    let res = e.file.response[0];
                    SetDocEn((pre:any) => ({
                        name: 'files',
                        multiple: false,
                        defaultFileList: [
                            {
                                id: res?.id,
                                uid: String(res?.id),
                                name: res?.name,
                                status: "done",
                                url: res?.url,
                                thumbUrl: res?.url
                            }
                        ],
                    }))
                }
            }
        }else{
            if(e.file.status === "removed") {
                SetDocTh((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [],
                }));
                form?.resetFields(["doc_th"])
            }else if(e.file.status === "done"){
                if (e.file.response) {
                    let res = e.file.response[0];
                    SetDocTh((pre:any) => ({
                        name: 'files',
                        multiple: false,
                        defaultFileList: [
                            {
                                id: res?.id,
                                uid: String(res?.id),
                                name: res?.name,
                                status: "done",
                                url: res?.url,
                                thumbUrl: res?.url
                            }
                        ],
                    }))
                }
            }
        }
    }

    function fileChangeDocTh(e: any, type:any) {
        // console.log('event', e)

        if(e.file.status == "removed"){
            SetRequireDocTh(true)
        }else{
            SetRequireDocTh(false)
        }
        
        if(e.file.status === "removed") {
            SetDocTh((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));
            SetDocThId(undefined);

            form?.resetFields(["doc_th"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                SetDocTh((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))

                SetDocThId(res?.id);

            }
        }
    }

    function fileChangeDocEn(e: any, type:any) {
        // console.log('event', e)

        if(e.file.status == "removed"){
            SetRequireDocEn(true)
        }else{
            SetRequireDocEn(false)
        }
        
        if(e.file.status === "removed") {
            SetDocEn((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));
            SetDocEnId(undefined);

            form?.resetFields(["doc_en"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                SetDocEn((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))

                SetDocEnId(res?.id);

            }
        }
    }

    function fileChangeCoverTh(e: any, type:any) {
        // console.log('event', e)

        if(e.file.status == "removed"){
            SetRequireCoverTh(true)
        }else{
            SetRequireCoverTh(false)
        }
        
        if(e.file.status === "removed") {
            SetCoverTh((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));
            SetCoverThId(undefined);

            form?.resetFields(["cover_th"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                SetCoverTh((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))

                SetCoverThId(res?.id);

            }
        }
    }

    function fileChangeCoverEn(e: any, type:any) {
        // console.log('event', e)


        if(e.file.status == "removed"){
            SetRequireCoverEn(true)
        }else{
            SetRequireCoverEn(false)
        }
        
        if(e.file.status === "removed") {
            SetCoverEn((pre:any) => ({
                name: 'files',
                multiple: false,
                defaultFileList: [],
            }));
            SetCoverEnId(undefined);

            form?.resetFields(["cover_en"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                SetCoverEn((pre:any) => ({
                    name: 'files',
                    multiple: false,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))

                SetCoverEnId(res?.id);
            }
        }
    }

    // function fileChangeCover(e:any, type:any){
    //     console.log('e', e)
    //     console.log('type', type)
    //     if(type == "en"){
    //         if(e.file.response){
    //             let res = e.file.response;
    //             console.log(res)
    //             SetCoverEn(res[0].id)
    //         }
    //     }else{
    //         if(e.file.response){
    //             let res = e.file.response;
    //             console.log(res)
    //             SetCoverTh(res[0].id)
    //         }
    //     }
    // }

    const { ...uploadProps } = useStrapiUpload(
        {
            maxCount: 1,
        }
    );
    
    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };

    const beforeUploadDoc = (file: RcFile) => {
        console.log('file', file)
        const isJpgOrPng = file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (!isJpgOrPng) {
        //   message.error('You can only upload PDF file!');
          message.error('You can only upload PDF/doc/docx file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 25;
        if (!isLt2M) {
          message.error('Image must smaller than 25MB!');
        }
        console.log('isJpgOrPng', isJpgOrPng)
        console.log('isLt2M', isLt2M)

        if(isJpgOrPng && isLt2M){
            return isJpgOrPng && isLt2M
        }else{
            return Upload.LIST_IGNORE
        }
        // return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;

    };

    const beforeUploadPic = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        console.log('isJpgOrPng', isJpgOrPng)
        console.log('isLt2M', isLt2M)

        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    async function createDoc(values:any) {

        // console.log('DocTh',DocTh)
        // console.log('DocEn',DocEn)
        // console.log('CoverEn',CoverEn)
        // console.log('CoverTh',CoverTh)
        // console.log('user_data', user_data)
        
        console.log('DocEn?.defaultFileList[0].id', DocEn?.defaultFileList[0].id)
        let data = {
            "doc_name" : DocName !== undefined ? DocName : '-' ,
            "doc_name_en" : DocNameEn !== undefined ? DocNameEn : '-',
            "cover" : CoverThId,
            "cover_en" : CoverEnId,
            "document" : DocThId,
            "document_en" : DocEnId,
            "link_vid" : LinkVidTh,
            "link_vid_en" : LinkVidEn,
            "deleteStatus": Stat ? "N" : "Y",
            "property": Property,
            "repair_document_category": Category,
            "updateBy": user_data?.user_profile
        }
        console.log('data', data)

        UpdateDoc({
            resource : 'repair-documents',
            id: doc_id + "",
            values : {
                doc_name : DocName !== undefined ? DocName : '-' ,
                doc_name_en : DocNameEn !== undefined ? DocNameEn : '-',
                cover : CoverThId,
                cover_en : CoverEnId,
                document : DocThId,
                document_en : DocEnId,
                link_vid : LinkVidTh,
                link_vid_en : LinkVidEn,
                deleteStatus: Stat ? "N" : "Y",
                property: Property,
                repair_document_category: Category,
                updateBy: user_data?.user_profile
            },
            successNotification : false
        });

        successNoti();
        replace("/repair-document")
    }

    // console.log('DocTh',DocTh);
    // console.log('DocEn',DocEn);
    // console.log('vInfoTh',vInfoTh);
    // console.log('vInfoEn',vInfoEn);

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        title="แก้ไขเอกสาร"
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{tabTermActive == "TAC" ? t("hcu-term.title") : t("hcu-info.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>เอกสาร</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">แก้ไขเอกสาร</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    // values.category = values.category.id;
                    // values.topic = values.topic;

                    createDoc(values);
                }}
            >
                    <Row>
                        <Col span={8}>
                            <Form.Item
                                // name={["property", "id"]}
                                label="Property"
                                style={{width:'95%'}}
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...selProperty}
                                    defaultValue={Property} 
                                    value={Property} 
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                // name="category"
                                // name={["category", "id"]}
                                label="category"
                                style={{width:'95%'}}
                                rules={[
                                    {
                                        required: true, message: "Please select category"
                                    },
                                ]}
                            >
                                <Select 
                                    onChange={(value: any, record:any) => {
                                        // setStat(value);
                                        // setStat(record);
                                        SetCategory(value);
                                    }}
                                    placeholder={t("select.sel")} 
                                    {...selCategory}
                                    defaultValue={Category} 
                                    value={Category} 
                                    // disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        style={{width:'95%'}}
                                        name="status"
                                        label="สถานะ"
                                        rules={[
                                            {
                                                required: true, message: "Please select"
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            onChange={(e:any) => SetStat(e.target.value)}
                                            defaultValue={Stat}
                                            value={Stat}
                                        >
                                            <Radio value={true} style={{ marginBottom: "0%" }}> ใช้งาน </Radio>
                                            <Radio value={false} style={{ marginBottom: "0%" }}> ไม่ใช้งาน </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    
                    <Divider />

                    {/* ================================================================================ */}
                    {/* ======================================= THAI =================================== */}
                    <Row gutter={82}>
                        <Col span={24}>
                            <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                                ภาษาไทย (TH)
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="ชื่อเอกสาร"
                                // name="doc_name"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="document name" 
                                    value={DocName}
                                    defaultValue={DocName}
                                    onChange={(e) => SetDocName(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Link VDO"
                                // name="link_vid"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidTh}
                                    defaultValue={LinkVidTh}
                                    onChange={(e) => SetLinkVidTh(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ไฟล์เอกสาร (.pdf, .doc, .docx)"
                                name="doc_th"
                                rules={[
                                    {
                                        required: RequireDocTh, message: 'Please upload Document.'
                                    },
                                ]}
                                style={{ width:'95%' }}
                            >
                                <Dragger 
                                    {...DocTh}
                                    style={{ borderRadius: "10px" }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    // headers={{
                                    //     Authorization: `Bearer ${token}`,
                                    // }}
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={beforeUploadDoc}
                                    // onChange={(e:any) => fileChangeDoc(e, "th")}
                                    onChange={fileChangeDocTh}
                                >
                                    <p>Upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ภาพปก"
                                name="cover_th"
                                rules={[
                                    {
                                        required: RequireCoverTh, message: 'Please upload Cover.'
                                    },
                                ]}
                                style={{ width:'95%' }}
                            >
                                <Dragger 
                                    {...CoverTh}
                                    style={{ borderRadius: "10px" }}
                                    // name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={beforeUploadPic}
                                    // onChange={(e:any) => fileChangeCover(e, "th")}
                                    onChange={fileChangeCoverTh}
                                >
                                    <p>Upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>

                    {/* <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Link VDO"
                                name="link_vid"
                                style={{width:'95%', marginTop:'-5%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidTh}
                                    onChange={(e) => SetLinkVidTh(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Divider />

                    {/* ================================================================================ */}
                    {/* ======================================= ENG ==================================== */}
                    <Row gutter={82}>
                        <Col span={24}>
                            <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                                ภาษาอังกฤษ (EN)
                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="ชื่อเอกสาร"
                                // name="doc_name_en"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="document name en" 
                                    value={DocNameEn}
                                    onChange={(e) => SetDocNameEn(e.target.value)} 
                                    // value={vReasonRejectOwner} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Link VDO"
                                // name="link_vid_en"
                                style={{width:'95%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidEn}
                                    onChange={(e) => SetLinkVidEn(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ไฟล์เอกสาร (.pdf, .doc, .docx)"
                                name="doc_en"
                                rules={[
                                    {
                                        required: RequireDocEn, message: 'Please upload Document.'
                                    },
                                ]}
                                style={{ width:'95%' }}

                            >
                                <Dragger 
                                    {...DocEn}
                                    style={{ borderRadius: "10px" }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={beforeUploadDoc}
                                    // onChange={(e:any) => fileChangeDoc(e, "en")}
                                    onChange={fileChangeDocEn}

                                >
                                    <p>Upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="ภาพปก"
                                name="cover_en"
                                rules={[
                                    {
                                        required: RequireCoverEn, message: 'Please upload Cover.'
                                    },
                                ]}
                                style={{ width:'95%' }}
                            >
                                <Dragger 
                                    {...CoverEn}
                                    style={{ borderRadius: "10px" }}
                                    // name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    maxCount={1}
                                    beforeUpload={beforeUploadPic}
                                    // onChange={(e:any) => fileChangeCover(e, "en")}
                                    onChange={fileChangeCoverEn}

                                >
                                    <p>Upload</p>
                                </Dragger>
                            </Form.Item>
                        </Col>
                    </Row>


                    {/* <Row>
                        <Col span={8}>
                            <Form.Item
                                label="Link VDO"
                                name="link_vid_en"
                                style={{width:'95%', marginTop:'-5%'}}
                            >
                                <Input 
                                    placeholder="link video" 
                                    value={LinkVidEn}
                                    onChange={(e) => SetLinkVidEn(e.target.value)} 
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}

                    <Divider />

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
            </Form>
    </Edit>   
    );
};