import { Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, Input, List, Modal,  Row,  Space, Table, Tag, TextField, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IContactCenter, IEventlog, IUnit } from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import dayjs from "dayjs";

export const ContactCenterList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Contact Center");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const [keepEmail, setKeepEmail] = useState<any>();
    
    const { tableProps,searchFormProps } = useTable<IContactCenter,HttpError, IContactCenter>({
        metaData: {
            populate: '*'
        },
        onSearch(params) {
            const filters: CrudFilters = [];

                filters.push(
                    {
                        field       : "email",
                        operator    : "contains",
                        value       : !!keepEmail ? keepEmail : null
                    },
                );

            return filters;
        },
        permanentFilter:[
            {
                field : "property][id]",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ]
    });

    const categoryQueryResult = useOne<IContactCenter>({
        resource: "contact-centers",
        id: ''
    });
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm } = Modal;
    const { mutate } = useUpdate<IUnit>();
    const ed_point = "contact-centers";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    // const [vSplitMail, setSplitMail] = useState<any>([])

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'contact_center',
                SysPkID         : id+""
            }
        });
    }

    const onReset = () => {
        setKeepEmail(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    }

    // console.log()
    return <>
            
            <List 
                title={t("contact-center.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("contact-center.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("contact-center.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="email">
                                <Input 
                                    onChange={(e) => setKeepEmail(e.target.value)} 
                                    style={{borderRadius: 4,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} 
                                    placeholder="Search email" 
                                    prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            {/* <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item> */}

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:paginationLength.length, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                >
                    {/* <Table.Column dataIndex="id" title={t("Lid")} /> */}
                    {/* <Table.Column width={150} dataIndex="post_code" title="Post Code" 
                        render={(value:any,record:any)=>{
                            return <>
                                    {
                                        value? 
                                            <TextField value={value} />
                                        :<>
                                            <TextField value={"(ไม่ระบุ)"} />
                                        </>
                                    }
                                </>
                        }}
                    /> */}

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column dataIndex="contact_cc" title="CONTACT" width={350}
                        render={(value:any,record:any)=>{ 
                            console.log('record', record)
                            return <>
                                {
                                    record?.contact_cc.length !== 0 ?
                                        record?.contact_cc.map((data:any,index:any) => {
                                            console.log('data : ', data);
                                            return <>
                                                <Tag className="tag_post">
                                                    <TextField value={data ?<> {data.first_name} {' '} {data.last_name}</> : <></> }  />
                                                </Tag>
                                            </>
                                        })
                                    : <>-</>
                                }
                            </>
                        }}
                    /> */}

                    <Table.Column title="EMAIL" width={500}
                        sorter={(a:any, b:any) => {
                            if (a?.email < b?.email) {return -1}
                            if (a?.email > b?.email) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{ 
                            // console.log('record', record)
                            {
                                let splitEmail = record.email.split(",");
                                return <>
                                     {
                                        splitEmail.length !== 0 ?
                                            splitEmail.map((data:any,index:any) => {
                                                // console.log('data : ', data);
                                                return <>
                                                    <Tag className="tag_post"><TextField value={data ? data : <></> }  /></Tag>
                                                </>
                                        })
                                        : <>-</>
                                    }
                                </>
                            }
                        }}
                    />

                    {/* <Table.Column dataIndex="properties" title={t("content-post-promotion.col-properties")}  width={150}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.properties.length !== 0 ?
                                        record?.properties.map((data:any,index:any) => {
                                            // console.log('data : ', data);
                                            return <>
                                                <Tag className="tag_post"><TextField value={data? data.property_name : <></> }  /></Tag>
                                            </>
                                        })
                                    : <>-</>
                                }
                            </>
                        }}
                    /> */}

                    <Table.Column title={t("content-post-promotion.col-properties")}  width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.property?.property_name < b?.property?.property_name) {return -1}
                            if (a?.property?.property_name > b?.property?.property_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                <Tag className="tag_post"><TextField value={record.property? record.property.property_name : <></> }  /></Tag>
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="default_contact" title="DEFAULT CONTACT" width={150}
                        render={(value:any,record:any)=>{ 
                            return <>
                                {
                                    value ?
                                        <><CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  /></>
                                    :
                                        <><CloseCircleOutlined style={{color:'#FF0000',fontSize:24}}   /></>
                                }                                
                            </>
                        }}
                    />    */}

                    <Table.Column title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="createAt" title="CREATE DATE" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="updateAt" title="LAST UPDATE" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    {/* <Table.Column dataIndex="updateAt" title="LAST UPDATE" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    /> */}

                    <Table.Column<IContactCenter>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                {/* <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton> */}

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}

                                {/* <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */}
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            />
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}