/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";

import axios from "axios";
import TextArea from "@uiw/react-md-editor/lib/components/TextArea";

export const HcuStructureEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [form] = Form.useForm();

    // const userQuery = useGetIdentity(); // Get User Profile
    const user_data:any = {
        id: Identity_data ? Identity_data?.user_profile.id : null,
        first_name: Identity_data ? Identity_data?.user_profile.first_name : null,
        last_name: Identity_data ? Identity_data?.user_profile.last_name : null,
        tel: Identity_data ? Identity_data?.user_profile.tel : null,
        email: Identity_data ? Identity_data?.user_profile.email : null,
        avatar: Identity_data ? Identity_data?.user_profile.avatar : null,
    }

    const t = useTranslate();
    const { replace, list} = useNavigation();

    const [ZoneTh, SetZoneTh] = useState<any>("");
    const [ZoneEn, SetZoneEn] = useState<any>("");
    const [ZoneDesc, SetZoneDesc] = useState<any>("");
    const [countInterval, setCountInterval] = useState(0);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let setting_id = id[1]
    // console.log('item_id', item_id)

    const getDataK = async ()=>{
        // http://192.168.100.129:1337/api/home-check-up-settings/find_setting_by_id?property=2&type=Notification&get_all_setting=false&setting_id=10
        let res = await axios.get(majorCareUrl + "/majorcare/get_list_by_id?id=" + item_id , { headers: { } });
        // console.log('res', res)
        let Data = res?.data
        SetZoneTh(Data.item_name);
        SetZoneEn(Data.item_name_en);
        SetZoneDesc(Data.item_desc);

        form.setFieldsValue({
            name: Data.item_name,
            name_en: Data.item_name_en,
            desc: Data.item_desc
        })
    }

    // useEffect(() => {
    //     if (countInterval < 3) {
    //         const interval = setInterval(() => {
    //             setCountInterval(prev => prev + 1);
    //             if(countInterval === 1){
    //                 // console.log('call')
    //                 getDataK();
    //             }
    //             // console.log('res_data', res_data);
    //             // console.log('property', Property);
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    async function update(values:any) {
        let data = {
            "item_name": ZoneTh,
            "item_name_en": ZoneEn,
            "item_desc": ZoneDesc,
            "update_by": user_data ? user_data : null
        }
        let res = await axios.put(majorCareUrl + "/majorcare/updateItemList?id=" + item_id , data , { headers: { } });
        // console.log('res', res)
        successNoti('200', 'success');

        replace("/majorcare/master-hcu")
    }

    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully edit structure',
        });
    };

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{
            title: "Edit",
            extra:<></>
        }}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-structure.edit")}</span></Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">Structure Master Edit</span></Breadcrumb.Item> */}
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    update(values);
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={12} 
                            className="gutter-row" 
                            style={{
                                paddingTop: "2.5rem"
                            }}
                        >
                            <Form.Item
                                label="Name (TH)"
                                name="name"
                                rules={[
                                    {
                                        required: true, message: "Please enter structure name"
                                    },
                                ]}
                            >
                                <Input 
                                    placeholder="structure name th" 
                                    onChange={(e) => SetZoneTh(e.target.value)} 
                                    value={ZoneTh}
                                    defaultValue={ZoneTh}
                                />

                            </Form.Item>

                            <Form.Item
                                label="Name (EN)"
                                name="name_en"
                                rules={[
                                    {
                                        required: true, message: "Please enter structure eng name"
                                    },
                                ]}
                            >
                                <Input 
                                    placeholder="structure name en" 
                                    onChange={(e) => SetZoneEn(e.target.value)} 
                                    value={ZoneEn}
                                    defaultValue={ZoneEn}
                                />

                            </Form.Item>

                            <Row gutter={24} >
                                <Col span={24}>
                                    <Form.Item
                                        label="Description"
                                        name="desc"
                                        // rules={[
                                        //     {
                                        //         required: true, message: "Please enter description"
                                        //     },
                                        // ]}
                                    >
                                        <Input 
                                            placeholder="structure desc" 
                                            onChange={(e) => SetZoneDesc(e.target.value)} 
                                            defaultValue={ZoneDesc}
                                            value={ZoneDesc}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Edit>   
    );
};