/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useCustom, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { useState } from "react";
import { RepairFacilityDoneJob } from "./done_job";
import { RepairFacilityInprogressJob } from "./inprogress_job";
import { RepairFacilityNewJob } from "./new_job";
import { majorCareUrl } from "../../constants";

// import { MjcRepairDoneJob } from "./done_job";
// import { MjcRepairInprogressJob } from "./inprogress_job";
// import { MjcRepairNewJob } from "./new_job";
// import { MjcRepairTodayJob } from "./today_job";
// import { MjcRepairWaitAppointmentJobList } from "./waiting_appointment_job";
// import { MjcRepairWaitingApproveJob } from "./waiting_approv_job";
// import { MjcRepairWaitingCustApproveJob } from "./wait_cust_approve";
// import { MjcDone } from "./done";
// import { MjcInProgress } from "./in_progress";
// import { MjcNewJob } from "./new_job";
// import { MjcWaiting } from "./waiting";
// import { HcuChecklistList } from "./checklist";
// import { HcuStructureList } from "./structure";
// import { HcuZoneList } from "./zone";
// import { HcuNotiMsgList } from "./notification";
// import { HcuTermConditionList } from "./term_and_condition";
// import { HcuTemplateList } from "./template_master";
// import { HcuDaysList } from "./setup_days";
// import { HcuAssignList } from "./assign_inspec";


export const RepairFacilityAdmin: React.FC<IResourceComponentsProps> = () => {
    // const tabActive = localStorage.getItem("keyTabHCUMaster");
    const [ Tab, SetTab] = useState<any>();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TabPane } = Tabs;
    const tabJobActive = localStorage.getItem("keyTabJob");
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [countNewJob, setCountNewJob] = useState<any>(undefined);
    const [countInprogressJob, setCountInProgressJob] = useState<any>(undefined);
    const [countDoneJob, setCountDoneJob] = useState<any>(undefined);


    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabJob", v)
    }

    // NEW JOB
    const { data } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailFacility/findFacilityJobCount`,
        method: "get",
        config: {
            query: {
                stat: '64b505fef3440e02a3156fb3,64b50671f3440e02a3156fb5',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log('dataLoad new job', dataLoad)
                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }
                setCountNewJob(dataLoad?.length)
            },
        },
    });

    // INPROGRESS JOB
    const { data: data2 } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailFacility/findFacilityJobCount`,
        method: "get",
        config: {
            query: {
                stat: '64b505fef3440e02a3156fb3',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log('dataLoad inprogress job', dataLoad)

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }
                setCountInProgressJob(dataLoad?.length)
            },
        },
    });

    // DONE JOB
    const { data: data3 } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailFacility/findFacilityJobCount`,
        method: "get",
        config: {
            query: {
                stat: '64b50609f3440e02a3156fb4',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log('dataLoad done job', dataLoad)

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }
                setCountDoneJob(dataLoad?.length)
            },
        },
    });


    return <>
        <List 
            // title={t("hcujob.title")}
            title="รายการแจ้งซ่อมส่วนกลาง"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>แจ้งซ่อมส่วนกลาง</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcujob.title")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">รายการแจ้งซ่อมส่วนกลาง</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabJobActive ? tabJobActive + "" : "NewJob"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >
                        <TabPane
                            key={"NewJob"}
                            tab={
                                <span>
                                    {"รายการใหม่"}
                                    <Badge showZero count={countNewJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairFacilityNewJob />
                        </TabPane>

                        <TabPane
                            key={"InProgress"}
                            tab={
                                <span>
                                    {"ระหว่างดำเนินการ"}
                                    <Badge showZero count={countInprogressJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairFacilityInprogressJob />
                        </TabPane>

                        <TabPane
                            key={"Done"}
                            tab={
                                <span>
                                    {"เสร็จสิ้น"}
                                    <Badge showZero count={countDoneJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <RepairFacilityDoneJob />
                        </TabPane>

                    </Tabs>
                </Form.Item>
            </Form>
           
        </List>

        
    </>
}