/* eslint-disable */
import { Card, Col, Edit, Form, Input, Row, Upload, useForm,Divider, Select, useSelect, List, Breadcrumb, UploadFile, TextField, Button, Icons, useModal, Modal, Avatar, RcFile, message, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useShow, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IPPType, IProjectBrand, IProjectType, IProperties, IUserAmpure, IUserDistrict, IUserProvice } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
import moment from 'moment';

import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

export const PropertiesEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { Option } = Select;
    const { TextArea } = Input;
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form, formProps, saveButtonProps }    = useForm<IProperties>({redirect:"list"});
    const { queryResult } = useShow<IProperties>({metaData: {
        populate: ['address_amphure','address_district','address_provice','image_list','logo','address_amphure',
        'pp_type','project','project_brand','createBy.avatar','updateBy.avatar']
        }, });
    const { data } = queryResult;
    // const record = data?.data;
    const API_URL = useApiUrl();

    const { Dragger } = Upload;

    let recordData:any  = []
    recordData          = data?.data? data?.data : [];

    const { modalProps, show, close } = useModal();

    // const [ count, setCount] = useState(0)
    const [ vProject, setproject] = useState();
    const [ vPpType, setpptype] = useState();
    const [ vamper, SetAmper] = useState<any>();
    const [ vpovin, Setpovin] = useState<any>();
    const [ vdist, Setdist] = useState<any>(1);

    const [vDragger, setDragger] = useState<any>([]);
    const [vLogo, setLogo] = useState<any>();
    const [vPJBRAND, setPJBRAND] = useState<any>();
    const [vsetTel, setTel] = useState<CountryPhoneInputValue>();

    // console.log(recordData)

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "properties",
        metaData: {
            populate: [
                '*',
                'project',
                'pp_type',
                'createBy',
                'updateBy',
                'address_amphure',
                'address_district',
                'address_provice',
                'project_brand',
                'propertyClass',
                'developer',
                'bank_acc_juristics',
                'juristic_by',
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();

          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        Setpovin(recordData.address_provice ? recordData.address_provice.id : "");
        SetAmper(recordData.address_amphure ? recordData.address_amphure.id : "");
        Setdist(recordData.address_district ? recordData.address_district.id : "");

        Setpovin(recordData.address_provice ? recordData.address_provice.id : "");
        SetAmper(recordData.address_amphure ? recordData.address_amphure.id : "");
        Setdist(recordData.address_district ? recordData.address_district.id : "");

        setproject(recordData.project ? recordData.project.id : null)
        setpptype(recordData.pp_type ? recordData.pp_type.id : null)
        setPJBRAND(recordData.project_brand ? recordData.project_brand.id : null);

        setTel({ phone: recordData ? recordData.tel : null, short: recordData ? recordData.short_tel : null })

        form.setFieldsValue({
            address_provice: 
                recordData.address_provice ? 
                    recordData.address_provice.name_th
                : null,
            address_amphure: 
                recordData.address_amphure ? 
                    recordData.address_amphure.name_th
                : null,

            address_district: 
                recordData?.address_district ?
                    recordData.address_district.name_th
                : null,     
            
            postcode: 
                recordData.postcode ? 
                    recordData.postcode
                : null,
            project: 
                recordData.project ? 
                    recordData.project.project_name
                : null,
            pp_type: 
                recordData.pp_type ? 
                    recordData.pp_type.pp_name
                : null,
            project_brand:
                recordData?.project_brand ?
                    recordData.project_brand.brand_name
                : null,
        });

        const fileList: UploadFile[] = 
        recordData.image_list?.length > 0 ?
            recordData.image_list.map((v:any)=>(
                {
                    uid: String(v.id),
                    name: v.name,
                    status: "done",
                    url:
                        v.url,
                    thumbUrl:
                        v.url
                }
            )) 
        : null
      ;

        setDragger({
            name: 'files',
            multiple: true,
            defaultFileList: [...fileList],
        })

        const fileListx: UploadFile[] = [
        {
            uid: recordData.logo.id,
            name: recordData.logo.name,
            status: "done",
            url:
                recordData.logo.url,
            thumbUrl:
                recordData.logo.url
        }
        ];

        setLogo({
            name: 'files',
            multiple: true,
            defaultFileList: [...fileListx],
        })

        form.resetFields(["image_list"]);
        form.resetFields(["logo"]);
    }

    // useEffect(() => {
    //     if (count < 3) {
    //         const interval = setInterval(() => {
    //             setCount(prev => prev + 1)

    //             Setpovin(recordData.address_provice ? recordData.address_provice.id : "");
    //             SetAmper(recordData.address_amphure ? recordData.address_amphure.id : "");
    //             Setdist(recordData.address_district ? recordData.address_district.id : "");

    //             Setpovin(recordData.address_provice ? recordData.address_provice.id : "");
    //             SetAmper(recordData.address_amphure ? recordData.address_amphure.id : "");
    //             Setdist(recordData.address_district ? recordData.address_district.id : "");

    //             setproject(recordData.project ? recordData.project.id : null)
    //             setpptype(recordData.pp_type ? recordData.pp_type.id : null)
    //             setPJBRAND(recordData.project_brand ? recordData.project_brand.id : null);

    //             setTel({ phone: recordData ? recordData.tel : null, short: recordData ? recordData.short_tel : null })

    //             form.setFieldsValue({
    //                 address_provice: 
    //                     recordData.address_provice ? 
    //                         recordData.address_provice.name_th
    //                     : null,
    //                 address_amphure: 
    //                     recordData.address_amphure ? 
    //                         recordData.address_amphure.name_th
    //                     : null,

    //                 address_district: 
    //                     recordData?.address_district ?
    //                         recordData.address_district.name_th
    //                     : null,     
                    
    //                 postcode: 
    //                     recordData.postcode ? 
    //                         recordData.postcode
    //                     : null,
    //                 project: 
    //                     recordData.project ? 
    //                         recordData.project.project_name
    //                     : null,
    //                 pp_type: 
    //                     recordData.pp_type ? 
    //                         recordData.pp_type.pp_name
    //                     : null,
    //                 project_brand:
    //                     recordData?.project_brand ?
    //                         recordData.project_brand.brand_name
    //                     : null,
    //             });

    //             const fileList: UploadFile[] = 
    //             recordData.image_list?.length > 0 ?
    //                 recordData.image_list.map((v:any)=>(
    //                     {
    //                         uid: String(v.id),
    //                         name: v.name,
    //                         status: "done",
    //                         url:
    //                             v.url,
    //                         thumbUrl:
    //                             v.url
    //                     }
    //                 )) 
    //             : null
    //           ;

    //             setDragger({
    //                 name: 'files',
    //                 multiple: true,
    //                 defaultFileList: [...fileList],
    //             })

    //             const fileListx: UploadFile[] = [
    //             {
    //                 uid: recordData.logo.id,
    //                 name: recordData.logo.name,
    //                 status: "done",
    //                 url:
    //                     recordData.logo.url,
    //                 thumbUrl:
    //                     recordData.logo.url
    //             }
    //             ];

    //             setLogo({
    //                 name: 'files',
    //                 multiple: true,
    //                 defaultFileList: [...fileListx],
    //             })

    //         form.resetFields(["image_list"]);
    //         form.resetFields(["logo"]);

    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count])

    const { selectProps: Selpptype } = useSelect<IPPType>({
        resource: "pp-types",
        optionLabel: "pp_name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ]
    });

    const { selectProps: selPJBRAND } = useSelect<IProjectBrand>({
        resource: "project-brands",
        optionLabel: "brand_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'active_status',
                operator    : 'eq',
                value       : true
            },
        ] 
    });

    const { selectProps: SelProjectType } = useSelect<IProjectType>({
        resource: "projects",
        optionLabel: "project_name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ]
    });

    const { selectProps: SelAmphure } = useSelect<IUserAmpure>({
        metaData: {
            populate: '*'
            }, 
        resource: "amphures",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters     : [
            {
                field       : 'province_id]',
                operator    : 'in',
                value       : vpovin
            }
        ]
    });
    
    const { selectProps: SelDistrict } = useSelect<IUserDistrict>({
        metaData: {
            populate: '*'
        }, 
        resource: "districts",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 10000,
        filters     : [
            {
                field       : 'amphure_id]',
                operator    : 'in',
                value       : vamper
            }
        ]
    });
    
    const { selectProps: SelProvice } = useSelect<IUserProvice>({
        metaData: {
            populate: '*'
        }, 
        resource: "provices",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 100,
    });

    const postListQueryResult = useList<IUserDistrict>({
        resource: "districts",
        config: {
            filters: [
                {
                    field: "id",
                    operator: "in",
                    value: vdist,
                },
            ],
        },
    });

    let nzipcode  :   any;
    nzipcode      = postListQueryResult?.data?.data[0] ? postListQueryResult?.data?.data[0]  : [];
    let kZip = nzipcode.zip_code;
    function amperchahng(value:any) {
        console.log(`selected ${value}`);
        SetAmper(value);
    }
    
    function povinchahng(value:any) {
        console.log(`selected ${value}`);
        Setpovin(value);
    }

    function districtchahng(value:any) {
        console.log(`selected ${value}`);
        Setdist(value);
    }

    function onchangeProject(value:any) {
        setproject(value);
    }

    function onchangePPtype(value:any) {
        setpptype(value);
    }

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
        }
    }

    const { mutate: CreateLog }     = useCreate<IEventlog>();
    const log_endpoint              = "even-logs";

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'properties',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    }

    form.setFieldsValue({
        postcode: kZip,
    });

    // console.log(recordData)

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("properties-edit.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item>{t("property.title")}</Breadcrumb.Item>
                                    <Breadcrumb.Item><span className="last_breadcrumb">{t("properties-edit.title")}</span></Breadcrumb.Item>
                                </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    updateUserUpdatedBy()
                    values.updateBy             = user_data.user_profile.id;
                    values.updatedBy            = user_data.user_profile.id;
                    values.project              = vProject;
                    values.pp_type              = vPpType;
                    values.project_brand        = vPJBRAND;

                    values.address_provice     = vpovin;
                    values.address_amphure     = vamper;
                    values.address_district    = vdist;

                    if (vsetTel !== null && vsetTel !== undefined) {
                        values.short_tel = vsetTel?.short
                        values.tel = vsetTel?.phone
                    }

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={24} style={{marginTop: "-60px",marginBottom: "1%"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={22} />
                            <Col className="gutter-row" span={2}>
                                <Button onClick={show} style={{width: "100%",borderRadius: "5px"}} icon={<Icons.InfoCircleOutlined/>}>Info</Button>    
                            </Col>
                        </Row>
                    </Col>
                    <Col id="Col_w" className="gutter-row" span={24}>
                        <Card style={{ borderRadius: 5, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={16}>
                                        <Col id="Col_w" className="gutter-row" span={24}>
                                            <Row gutter={16}>
                                                <Col className="gutter-row" span={8}>
                                                <Form.Item  label={t("projects.title")} name="project">
                                                    <Select 
                                                        placeholder={t("select.sel")} 
                                                        {...SelProjectType} 
                                                        onChange={onchangeProject}
                                                    />
                                                </Form.Item>  
                                                </Col>
                                                <Col className="gutter-row" span={8}>
                                                <Form.Item  label={t("pp-type.title")} name="pp_type"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select property type'
                                                        },
                                                    ]}>
                                                        <Select 
                                                            placeholder={t("select.sel")}
                                                            {...Selpptype}
                                                            onChange={onchangePPtype}/>
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row" span={8}>
                                                    <Form.Item  label={t("project-brand.title")} name="project_brand">
                                                        <Select 
                                                            placeholder={t("select.sel")}
                                                            {...selPJBRAND}
                                                            onChange={value => {
                                                                setPJBRAND(value);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col className="gutter-row" span={6}>
                                            <Form.Item  label={t("properties.code")} name="property_code"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter property code.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item  label={t("properties.name")} name="property_name"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter property name.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item label={t("properties.developer")} name="property_under"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select.'
                                                    },
                                                ]}>
                                                    <Select placeholder={t("select.sel")}>
                                                        <Option value="Major">Major</Option>
                                                        <Option value="Non Major">Non Major</Option>
                                                    </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}></Col>

                                        <Col span={12}>
                                            <Form.Item  label={t("properties.area-size")} name="area_size"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter area size.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={24}>
                                            <Row gutter={16}>
                                                <Col className="gutter-row" span={12}>
                                                <Form.Item  label={t("lat")} name="lat">
                                                    <Input/>
                                                </Form.Item>


                                                </Col>
                                                <Col className="gutter-row" span={12}>
                                                    <Form.Item  label={t("lng")} name="lng">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                                <Col className="gutter-row" span={24}>
                                                    <Form.Item  label={t("properties.about")} name="about"
                                                        rules={[
                                                            {
                                                            },
                                                        ]}>
                                                        <TextArea style={{ height: "80px" }} />
                                                    </Form.Item>
                                                </Col>

                                                <Divider />

                                                <Col  className="gutter-row" span={24}>
                                                    <Form.Item name="address" label={t("user-profiles-create.address")}
                                                        rules={[
                                                            {
                                                                required: true, message: 'Please input address'
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                                    </Form.Item>

                                                    <Row gutter={16}>
                                                        <Col className="gutter-row" span={12}>
                                                            <Form.Item name="address_soi" label={t("user-profiles-create.soi")}>
                                                                <Input/>
                                                            </Form.Item>

                                                            <Form.Item name="address_provice" label={t("user-profiles-create.provice")}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please input province'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select 
                                                                    placeholder={t("select.sel")}
                                                                    {...SelProvice}
                                                                    onChange={povinchahng}
                                                                />
                                                            </Form.Item>

                                                            <Form.Item name="address_district" label={t("user-profiles-create.district")}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please input sub district'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select 
                                                                    placeholder={t("select.sel")}
                                                                    {...SelDistrict}
                                                                    onChange={districtchahng}
                                                                />
                                                            </Form.Item>

                                                            <Form.Item  label={t("properties.tax")} name="tax_no">
                                                                <Input/>
                                                            </Form.Item>

                                                            <Form.Item name="tel" label={t("user-profiles.tel")}>
                                                                <ConfigProvider locale={en}>
                                                                    <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{height: "32px",borderRadius: "5px",padding: "0"}} value={vsetTel}
                                                                        onChange={(v) => {
                                                                            setTel(v);
                                                                        }}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                            }
                                                                        }}
                                                                        minLength={9}
                                                                        maxLength={12}
                                                                    />
                                                                </ConfigProvider>
                                                            </Form.Item> 
                                                        </Col>

                                                        <Col className="gutter-row" span={12}>
                                                            <Form.Item name="address_road" label={t("user-profiles-create.road")}>
                                                                <Input/>
                                                            </Form.Item>

                                                            <Form.Item name="address_amphure" label={t("user-profiles-create.amphure")} style={{width: "50%"}}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select district'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select 
                                                                    placeholder={t("select.sel")}
                                                                    {...SelAmphure}
                                                                    onChange={amperchahng}
                                                                />
                                                            </Form.Item>

                                                            <Form.Item name="zip_code" label={t("user-profiles-create.post-code")}>
                                                                <div style={{visibility: "hidden",height: "0px"}}>{kZip}</div>
                                                                <Input disabled defaultValue={kZip} value={kZip} />
                                                            </Form.Item>

                                                            <Form.Item  label={t("properties.fax")} name="fax">
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={12}/>
                                        <Col className="gutter-row" span={12} style={{borderLeft: "1px solid #ddd"}}>
                                            {recordData.logo ? 
                                            
                                            <>
                                                <Form.Item
                                                    name="logo"
                                                    label={t("properties.logo")}
                                                >
                                                    <Dragger style={{borderRadius: "10px"}} 
                                                        {...vLogo}
                                                        name="files"
                                                        action={`${API_URL}/upload`}
                                                        headers={{
                                                            Authorization: `Bearer ${localStorage.getItem(
                                                                TOKEN_KEY,
                                                            )}`,
                                                        }}
                                                        listType="picture"
                                                        multiple
                                                        // {...uploadProps_coverpix}
                                                        maxCount={1}
                                                        onChange={fileChange}
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        <p className="ant-upload-text">{t("upload")}</p>
                                                    </Dragger>
                                                    {/* <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p> */}
                                                </Form.Item>
                                                <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p>
                                                    </>
                                                    :
                                                    <>
                                                <Form.Item
                                                    name="logo"
                                                    label={t("properties.logo")}
                                                >
                                                    <Dragger style={{borderRadius: "10px"}} 
                                                        name="files"
                                                        action={`${API_URL}/upload`}
                                                        headers={{
                                                            Authorization: `Bearer ${localStorage.getItem(
                                                                TOKEN_KEY,
                                                            )}`,
                                                        }}
                                                        listType="picture"
                                                        multiple
                                                        // {...uploadProps_coverpix}
                                                        maxCount={1}
                                                        onChange={fileChange}
                                                        beforeUpload={beforeUpload}
                                                    >
                                                        <p className="ant-upload-text">{t("upload")}</p>
                                                    </Dragger>
                                                    {/* <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p> */}
                                                </Form.Item>
                                                <p style={{textAlign: "center",fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 512*512 PNG, JPG size 5MB</p>
                                            </>

                                        }

                                        <Form.Item
                                            name="image_list"
                                            label={t("properties.img-list")}
                                        >

                                            <Dragger style={{borderRadius: "10px"}} 
                                                {...vDragger}
                                                name="files"
                                                action={`${API_URL}/upload`}
                                                headers={{
                                                    Authorization: `Bearer ${localStorage.getItem(
                                                        TOKEN_KEY,
                                                    )}`,
                                                }}
                                                listType="picture"
                                                multiple
                                                maxCount={5}
                                                onChange={fileChange}
                                                beforeUpload={beforeUpload}
                                            >
                                                <p className="ant-upload-text">{t("upload")}</p>
                                            </Dragger>
                                            {/* <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p> */}
                                        </Form.Item>
                                        <p style={{textAlign: "center", fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 1314*783 PNG, JPG size 5MB</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>

                <Modal onOk={close} {...modalProps} footer={[<Button key="submit" type="primary" onClick={close}>OK</Button>]}>
                    <div><Icons.InfoCircleOutlined style={{marginRight: "3%"}}/><TextField value={"Data Create & Update"}/></div>
                    <Row gutter={16} style={{marginTop: "6%"}}>
                        <Col className="gutter-row" span={24}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={15}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={4}>
                                        {
                                            recordData?.createBy?
                                                recordData.createBy.avatar !== null ?

                                                <Avatar size={32} src={recordData.createBy.avatar.url} style={{marginTop: "-7px"}}/>
                                            :<>
                                                <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                            </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        }
                                        </Col>
                                        <Col className="gutter-row" span={8}><span>{t("created")}</span></Col>
                                        <Col className="gutter-row" span={12}><TextField value={moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}/></Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={7}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                        {/* <Col className="gutter-row" span={18}><TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null? recordData.createBy.first_name !== null && recordData.createBy.last_name !== null ? recordData.updateBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}/></Col> */}
                                        <Col className="gutter-row" span={18}>
                                            <TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null && recordData?.createBy !== undefined? 
                                                recordData?.createBy.first_name !== null && recordData.createBy.last_name !== null ? 
                                                recordData.createBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="gutter-row" span={24} style={{marginTop: "3%"}}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={15}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={4}>
                                        {
                                            recordData?.createBy?
                                                recordData.createBy.avatar !== null ?

                                                <Avatar size={32} src={recordData.createBy.avatar.url} style={{marginTop: "-7px"}}/>
                                            :<>
                                                <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                            </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        }
                                        </Col>
                                        <Col className="gutter-row" span={8}><span>{t("lupdate")}</span></Col>
                                        <Col className="gutter-row" span={12}><TextField value={moment(recordData?.updatedAt).format('D/MM/yyyy HH:mm')}/></Col>
                                    </Row>
                                </Col>
                                <Col className="gutter-row" span={7}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                        <Col className="gutter-row" span={18}>
                                            <TextField style={{marginLeft: "3%"}} value={recordData?.updateBy !== null && recordData?.updateBy !== undefined? 
                                                recordData?.updateBy.first_name !== null && recordData.updateBy.last_name !== null ? 
                                                recordData.updateBy.first_name + " " + recordData.updateBy.last_name : null :"ไม่ระบุ"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal>
            </Form>
        </Edit>
    );
};