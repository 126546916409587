/* eslint-disable */
import { Show, TextField, Typography, List, Space, Breadcrumb, Card, Row, Col, Divider, Form } from "@pankod/refine-antd";

import { IPhoneBooks } from "interfaces";
// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { BookFilled, PhoneOutlined } from "@ant-design/icons";

import Moment from 'moment';

// import Moment from 'moment';

export const PhoneBooksShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IPhoneBooks>({  
        metaData: {
        populate: '*'
    }, 
});

    const { data, isLoading } = queryResult;
    const record = data?.data;

    const { Text } = Typography;

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>
            <List 
                    title={t("")}
                    pageHeaderProps={{ 
                        extra : <></>
                    }}
            />

                <Space style={{marginBottom: "3%"}}>
                    <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("phonebook.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("phonebook.show")}</span></Breadcrumb.Item>
                    </Breadcrumb>
                </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={13}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset",marginBottom: "2%"}}>
                                        <Form.Item>
                                            <BookFilled style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#D6C6A0",marginRight: "3%"}} />
                                            <TextField value={record?.name ? record?.name : '-'} style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#fff"}} />
                                        </Form.Item>
                                        
                                        <Form.Item>
                                            <span  style={{backgroundColor: "#F2F3F5",padding: "1% 2% 1%",borderRadius: "20px",boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"}}>
                                                <PhoneOutlined style={{fontSize: "1vw",color: "#40495F",marginRight: "1%"}}/>
                                                <TextField value={record?.phone ? record?.phone : '-'} style={{fontSize: "1vw",color: "#40495F"}} />
                                            </span>
                                        </Form.Item>
                                    </Card>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                            <Col className="gutter-row" span={12} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                                <span className="title_panel" style={{fontSize: "0.7vw"}}>{t("phonebook-sub-type.title")}</span><TextField style={{padding: "2%",marginLeft: "3%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.phone_book_sub_type ? record?.phone_book_sub_type.name : 'NO DATA'} />
                                            </Col>

                                            <Col className="gutter-row" span={12}>
                                                <span className="title_panel" style={{fontSize: "0.7vw"}}>{t("phonebook-type.title")}</span><TextField style={{padding: "2%",marginLeft: "3%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.phone_book_type ? record?.phone_book_type.name : 'NO DATA'} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col className="gutter-row" span={24}>
                                    <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                                            <span style={{fontSize: "1vw",fontWeight: "600"}}>{t("property.title")} : <TextField style={{fontSize: "1vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.property ? record?.property.property_name : <></>} /></span>

                                            <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}><span style={{fontSize : "0.7vw"}}>{t("phonebook.address")}</span></div>

                                            <TextField value={record?.property ? record?.property.address : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                            <Text style={{fontSize: "0.7vw"}}>{t("phonebook.city")} </Text>
                                                    <TextField value={record?.property ? record?.property.city : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                            <Text style={{fontSize: "0.7vw"}}>{t("phonebook.province")} </Text>
                                            <TextField value={record?.property ? record?.property.province : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                            <Text style={{fontSize: "0.7vw"}}>{t("phonebook.post_code")} </Text>
                                            <TextField value={record?.property ? record?.property.postcode : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                            <Text style={{fontSize: "0.7vw"}}>{t("phonebook.fax")} </Text>
                                            <TextField value={record?.property ? record?.property.fax : <></>} style={{marginRight: "3%", fontSize: "0.7vw"}}/>

                                            <div style={{ marginBottom: "1%", marginTop: "3%", fontSize: "0.7vw"}}>{t("properties.about")}</div><TextField style={{fontSize: "0.7vw"}} value={record?.property ? record?.property.about : <></>} />

                                            <div style={{marginTop: "3%"}}><span style={{background: "#40495F",borderRadius: "20px",padding: "1% 3% 1%",color: "#fff"}}><PhoneOutlined style={{fontSize: "0.7vw",color: "#fff",marginRight: "1%"}}/><TextField style={{color: "#fff",fontSize: "0.7vw"}} value={record?.property ? record?.property.tel : '-'} /></span></div>     
                                    </Card>
                                </Col>

                            </Row>
                    </Col>

                    <Col className="gutter-row" span={5}>

                    </Col>


                    <Col className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>
                                                        
                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createdBy? record?.createdBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{fontSize: "0.7vw"}}>{record?.updatedBy? record?.updatedBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
        </Show>
    );
};