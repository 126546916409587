/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal,  Row,  Select,  ShowButton,  Space, Table, Tag, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IFacilityBan, IProperties} from "interfaces";
import Moment from 'moment';
import { useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

export const FacilitiesBanList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility Ban User");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const { tableProps,searchFormProps,tableQueryResult } = useTable<IFacilityBan,HttpError, IFacilityBan>({
        metaData: {
            populate: '*'
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property, user_profile } = params;
                filters.push(
                    {
                        field       : "property][id]",
                        // operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                        operator : "eq",
                        value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                    },
                    {
                        field       : "user_profile][first_name]",
                        // operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                        operator    : "contains",
                        value       : user_profile ? user_profile : null,
                    },
                );
            return filters;
        },
        initialFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],queryOptions: {
            onSuccess(data) {
                //console.log("data",data?.data)
            },
        }
    });

    const categoryQueryResult = useOne<IFacilityBan>({
        resource: "facility-bans",
        id: ''
    });
        
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IFacilityBan>();
    const ed_point                          = "facility-bans";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
            // console.log('onShowSizeChange : ', current, pageSize);
            setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IFacilityBan>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                        
                };
            }
        },
    });

    const { selectProps: SelPropert} = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ],
    });
    
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            // values      : vCurDelStatus,
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
            }
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'facility-bans',
                SysPkID         : id+""
            }
        });
    }

    // function showDelConfirm(id:any) {
    //     confirm({
    //         // title   : 'Deactivate Confirm ?',
    //         title   : 'Inactive Confirm ?', 
    //         icon    : <WarningOutlined />,
    //         content : 'Click Confirm to deactivate this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             vCurDelStatus.deleteStatus = 'Y'
    //             updateStatus(id, "Inactive");
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }

    // function showUnlockConfirm(id:any) {
    //     confirm({
    //         title   : 'Activate Confirm ?',
    //         icon    : <QuestionCircleOutlined />,
    //         content : 'Click Confirm to activate this data.',
    //         okText  : 'Confirm',
    //         onOk() {
    //             vCurDelStatus.deleteStatus = 'N'
    //             updateStatus(id, "Active");
    //             return new Promise((resolve, reject) => {
    //                 setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
    //             }).catch(() => console.log('something wrong.'));
    //         },
    //         onCancel() { },
    //     });
    // }

    const onReset = () => {
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    // console.log(tableProps)

    return <>

            <List 
                title={t("fclt-ban.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-ban.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            {/* <Form.Item name="q">
                                <Input style={{borderRadius: 20,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item> */}

                            {/* <Form.Item name="property">
                                <Select placeholder={t("select.sel")} {...SelPropert} 
                                    // onSelect={(value: any) => {
                                    //     console.log(value);
                                    //     setProperty(value);
                                    //     // getUserDetail(value);
                                    // }}
                                />
                            </Form.Item> */}

                            <Form.Item name="property" >
                                <Select {...SelPropert} placeholder="Select property" size="middle" style={{borderRadius: 20, width: '250px', position:'relative'}} allowClear/>
                            </Form.Item>

                            <Form.Item name="user_profile">
                                <Input 
                                    style={{borderRadius: 5,border:"1px soLid #d9d9d9", height: 30}} 
                                    placeholder="Search Name" 
                                    prefix={<Icons.SearchOutlined />} 
                                    allowClear
                                />
                            </Form.Item>                               

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                {/*  */}

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        // total:paginationLength.length, 
                        total:tableQueryResult?.data?.total, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >

                    {/* <Table.Column width={40} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <div> */}
                    <Table.Column title={t("property.Ltitle")} width={200} align="center" className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.property?.property_name < b?.property?.property_name) {return -1}
                        if (a?.property?.property_name > b?.property?.property_name) {return 1}
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            //console.log('record', record)
                            return <>
                                <TextField value={record?.property? record?.property.property_name :"-"}  />
                                {/* <TextField value={value}  /> */}
                            </>
                        }}
                    />
                    {/* </div> */}

                    <Table.Column title={t("fclt-ban.Lbtype")} width={100} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.ban_type < b?.ban_type) {return -1}
                            if (a?.ban_type > b?.ban_type) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record.ban_type == true ? <Tag style={{borderRadius: "30px",padding: "1% 10% 1%"}} color={"blue"}>Unit</Tag>:<>-</> && record.ban_type == null ? <>-</>:<></> && record.ban_type == false ?<Tag style={{borderRadius: "30px",padding: "1% 10% 1%"}} color={"orange"}>Personal</Tag> : <></> }/>
                            </>
                        }}
                    />

                    <Table.Column title={t("user-profiles.Lname2")}  width={200} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.user_profile?.first_name < b?.user_profile?.first_name) {return -1}
                            if (a?.user_profile?.first_name > b?.user_profile?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile? <>{record?.user_profile ? record?.user_profile.first_name : ''} {' '} {record?.user_profile? record?.user_profile.last_name : ''}</> :"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="user_profile" title={t("user-profiles.Llastname")}  width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile? record?.user_profile.last_name :"-"}  />
                            </>
                        }}
                    /> */}
                    

                    <Table.Column title={t("user-profiles.Ltel")}  width={150} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.user_profile?.tel < b?.user_profile?.tel) {return -1}
                            if (a?.user_profile?.tel > b?.user_profile?.tel) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile !== null ? record?.user_profile.tel !== null ? record?.user_profile.tel : "-" : "-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={t("user-profiles.LunitN")}  width={200} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.unit?.unit_name < b?.unit?.unit_name) {return -1}
                            if (a?.unit?.unit_name > b?.unit?.unit_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.unit? record?.unit.unit_name:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="ban_status" title={t("fclt-ban.Lbstatus")} width={150} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.ban_status < b?.ban_status) {return -1}
                            if (a?.ban_status > b?.ban_status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record.ban_status == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && record.ban_status == null ? <>-</>:<></> && record.ban_status == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/>
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="user_profile" title={t("user-profiles.Llastname")}  width={200}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile? record?.user_profile.last_name :"-"}  />

                                {console.log(">>>> user_profile",record.user_profile)}
                            </>
                        }}
                    /> */}

                    <Table.Column title={t("fclt-ban.col-bandate")} align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        // <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                        <TextField value={Moment(record?.createdAt).format('DD/MM/yyyy')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title={t("fclt-ban.col-unbandate")} align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.date_time < b?.date_time) {return -1}
                            if (a?.date_time > b?.date_time) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.date_time ?
                                        <TextField value={Moment(record?.date_time).format('DD/MM/yyyy')}  />

                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column title={t("fclt-ban.col-bancheck")} align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.ban_check < b?.ban_check) {return -1}
                            if (a?.ban_check > b?.ban_check) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //console.log('record',record)
                            return <>
                                {
                                    // record? 
                                        record?.ban_check !== null ?
                                            record?.ban_check ? "AUTO" : "MANUAL"
                                        : <>-</>
                                    // :
                                    //     <>-</> 
                                }
                                {/* <TextField 
                                    style={{fontWeight: 600}} 
                                    value={record?.createBy? record?.createBy.first_name :"-"}  
                                /> */}
                            </>
                        }}
                    />

                    <Table.Column title={t("fclt-ban.col-banby")} align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="updatedBy" title="UPDATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="updateAt" title="LAST UPDATE" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    {/* <Table.Column dataIndex="updatedBy" title="UPDATE BY" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="updateAt" title="LAST UPDATE" align="center" width={100}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    /> */}
                  
                    <Table.Column<IFacilityBan>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                ></ShowButton>

                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                />

                                {/* <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */}
                                {/* {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger></Button>
                                        </>
                                    :
                                        <>
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} ></Button>
                                        </>
                                } */}
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}