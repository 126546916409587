/* eslint-disable */
import { Card, Col, Edit, Divider, Form, Input, Row, useForm, TextField, List, Breadcrumb } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, ITerm } from "interfaces";
import Moment from 'moment';
import { useState } from "react";
// import ReactMarkdown from "react-markdown";
// import ReactMde from "react-mde";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const TermEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps } = useForm<ITerm,HttpError,{}>({redirect:"list"});
    const { queryResult } = useShow<ITerm>({metaData: {
        populate: '*'
        }, });
    const { data } = queryResult;
    const record = data?.data;
    let recordData:any = []
    recordData = data?.data? data?.data : [];
    // const { replace} = useNavigation();
    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";
    const [ vsetdetail, setdetail] = useState<any>();
    const [ vsetdetailEng, setdetailEng] = useState<any>();

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'term-and-conditions',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
        // replace("")
    } 

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
        "write",
    );

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              const body = new FormData();
              loader.file.then((file: string | Blob) => {
                // console.log('fileeeeeeeeeeeee', file)
                body.append("files", file);
                // let headers = new Headers();
                // headers.append("Origin", "http://localhost:3000");
                fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                  method: "post",
                  body: body
                  // mode: "no-cors"
                })
                  .then((res) => res.json())
                  .then((res) => {
                    // console.log('resssssssssssssssss', res)
                    resolve({
                    //   default: `${API_URL}/${res.filename}`
                      default: res[0].url
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            });
          }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    let CkConfig: any;
    CkConfig = {
        toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            // "ckfinder",
            "|",
            "imageTextAlternative",
            "imageUpload",
            "imageStyle:full",
            "imageStyle:side",
            // "|",
            // "mediaEmbed",
            // "insertTable",
            // "tableColumn",
            // "tableRow",
            // "mergeTableCells", 
            // "|",
            // "undo",
            // "redo"
        ],
        extraPlugins: [uploadPlugin],
        mediaEmbed: {
            // configuration...
            removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
        },
        image: {
            toolbar: ['']
        }
    };
  
    console.log("edit posts-type",record)
    //const API_URL = useApiUrl();
    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("term.bc_title-e")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("term.bc_title-e")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
          onFinish={(values:any) => {
            updateUserUpdatedBy();
            //values.photo_list   = vphoto_list;
            values.updateBy = user_data.user_profile.id
            // values.updatedBy = user_data.user_profile.id
            values.tac_content = vsetdetail
            values.tac_content_en = vsetdetailEng

            return (
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
               
            );
        }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("term.code")} name="tac_code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("term.name")} name="tac_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col span={9}>
                                <Form.Item  label={t("term.name_en")} name="tac_name_en"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item label={t("term.content")} name="tac_content"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>

                                    {/* <ReactMde
                                        selectedTab={selectedTab}
                                        onTabChange={setSelectedTab}
                                        generateMarkdownPreview={(markdown: string) =>
                                            Promise.resolve(
                                                <ReactMarkdown>{markdown}</ReactMarkdown>,
                                            )
                                        }
                                    /> */}

                                    <CKEditor
                                        // name="detail"
                                        editor={ClassicEditor}
                                        data={recordData.tac_content? recordData.tac_content : ''}
                                        config={CkConfig} 
                                        onInit={(editor:any) => {}}
                                        onChange={(event:any, editor:any) => {
                                            const data = editor.getData();
                                            setdetail(data);
                                        }}
                                        onBlur={(editor:any) => {}}
                                        onFocus={(editor:any) => {}}
                                    />

                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item label={t("term.content_en")} name="tac_content_en"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>

                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={recordData.tac_content? recordData.tac_content : ''}
                                        config={CkConfig} 
                                        onInit={(editor:any) => {}}
                                        onChange={(event:any, editor:any) => {
                                            const data = editor.getData();
                                            setdetailEng(data);
                                        }}
                                        onBlur={(editor:any) => {}}
                                        onFocus={(editor:any) => {}}
                                    />

                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("term.link")} name="tac_link"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition link.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("term.link_en")} name="tac_link_en"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition link.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={14}>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>
    );
};