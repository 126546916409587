/* eslint-disable */
import { Show, TextField, Typography, List, Space, Breadcrumb, Row, Col, Card, Avatar, Image, Tag, Divider } from "@pankod/refine-antd";

import { IProject } from "interfaces";
import dayjs from "dayjs";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
import ReactJson from 'react-json-view'

import Moment from 'moment';
import { useState } from "react";

// import Moment from 'moment';

export const ApiLogShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    let arr = window.location.href.split("/");
    const [dataSource, setDataSource] = useState<any>([]);
    const [ApiName, setApiName] = useState<any>(undefined);
    const [ReturnStat, setReturnStat] = useState<any>(undefined);
    const [ReturnData, setReturnData] = useState<any>(undefined);
    const [CreateDate, setCreateDate] = useState<any>(undefined);
    const [RequestJSON, setRequestJSON] = useState<any>(undefined);

    const { queryResult } = useShow<IProject>({  
        resource: 'api-logs',
        id: arr[arr.length - 1],
        metaData: {
            populate: '*'
        }, 
        queryOptions: {
            onSuccess: (data) => {
                // console.log('data xxxx : ', data)
            //   callFetchData();
                dataSource.push(data?.data)
                let splitApiName = dataSource[0]?.api_name?.split("/");

                setApiName(splitApiName[0])
                setReturnStat(dataSource[0].return_status)
                setReturnData(dataSource[0].return_data)
                setCreateDate(dataSource[0].create_at)

                let kkJson = JSON.parse(dataSource[0].request_data);

                setRequestJSON(kkJson)
            },
        },
    });

    // console.log('dataSource', dataSource)

    console.log('RequestJSON', RequestJSON)

    const { data, isLoading } = queryResult;
    const record = data?.data;
    const { Text } = Typography;



 
    return (
        <Show 
            isLoading={isLoading} 
            // pageHeaderProps={{extra: <></>}}
            pageHeaderProps={{ 
                extra : <></>
            }}
        >
                <Row gutter={[16, 24]}>
                    <Col span={2}>
                        <Tag color="blue">{ApiName}</Tag>
                    </Col>
                    <Col span={2}>
                        {
                            ReturnStat == "200" ?
                                <Tag color="green">{ReturnStat}</Tag>
                            :
                                <Tag color="red">{ReturnStat}</Tag>
                        }
                    </Col>
                    <Col span={10}>
                        {
                            ReturnData
                        }
                    </Col>
                    <Col span={4}>
                        {
                            dayjs(CreateDate).format("DD-MM-YYYY HH:mm")
                        }
                    </Col>
                </Row>

                <br />
                <Divider/>
                <br />

                <Row gutter={[16, 24]}>
                    <Col span={24}>
                        {/* <Tag color="blue">{ApiName}</Tag> */}
                        <ReactJson src={RequestJSON} />


                    </Col>
                </Row>
        </Show>
    );
};