/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, Divider, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { Key, useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY } from "../../../constants";
import { ExclamationCircleOutlined, LeftOutlined, RightOutlined, ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Dayjs } from "dayjs";

import dayjs from "dayjs";

import axios from "axios";
import { Carousel, DatePicker, notification, Table, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { majorCareUrl } from "../../../constants";

 {/* 
    6485ba66049e59df2850eb9e = รอดำเนินการ
    6485bcda049e59df2850eba0 = รับเรื่อง
    6485bcf9049e59df2850eba2 = รอตรวจ
    6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
    6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
    6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
    6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
    6485bd8b681aef755a4f279f = รอนัดหมาย
    6485bd96681aef755a4f27a1 = หมดอายุ
*/}

export const MjcInProgressEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const format = 'YYYY-MM-DD HH:mm';

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [res_data, set_res_data] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);

    const [InpectorName, SetInpectorName] = useState<any>();

    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]

    // console.log('item_id', item_id)

    // const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    // Data ส่วนของ รายละเอียดการแจ้ง
    const [JobNo, SetJobNo] = useState<any>();
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [Unit, SetUnit] = useState<any>();
    const [Customer, SetCustomer] = useState<any>();
    const [Type, SetType] = useState<any>();


    // เช็คว่าเป็นสถานะ 'รอตรวจ' หรือยัง
    const [IsWaitForInspec, SetIsWaitForInspec] = useState<any>(false);

    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);
    const [LastTrail, setLastTrail] = useState<any>(undefined);
    const [LastTrailDate, setLastTrailDate] = useState<any>(undefined);

    // Data แก้ไขสถานะรายการ ทำวันนัด
    const [Stat, setStat] = useState<any>();
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [Inspector, SetInspector] = useState<any>();
    const [InspectorProfile, SetInspectorProfile] = useState<any>();
    const [PropertyId, SetPropertyId] = useState<any>();
    const [Remark, SetRemark] = useState<any>("");

    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    let sla_days:any
    let sla_hours:any


    // console.log('Inspector', Inspector)
  
    const [dataChecklist, setDataCheckList] = useState<any>([]);

    const getDataK = async ()=>{

        // Data หลักของ job
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findOneHomecheckjob?id=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad', dataLoad)

                SetPropertyIdJob(dataLoad ? dataLoad[0].property_obj.length > 0 ? dataLoad[0].property_obj[0].property_id : null : null )

                SetJobNo(dataLoad[0].job_id ? dataLoad[0].job_id : '-')
                SetCreateDate(dataLoad[0].create_date)
                SetProperty(dataLoad[0].property_obj[0].property_name)
                SetPropertyId(dataLoad[0].property_obj[0].property_id)

                // SetUnit(dataLoad[0].unit ? dataLoad[0].unit.attributes ? dataLoad[0].unit.attributes.unit_name : '-' : '-')
                SetUnit(dataLoad[0] ? dataLoad[0].unit_obj ? dataLoad[0].unit_obj[0].unit_name : '-' : '-')

                setStat(dataLoad[0] ? dataLoad[0].status : null)

                SetCustomer(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.first_name + ' ' + dataLoad[0].request_by.attributes.last_name : '-')
                SetTransferDate(dataLoad[0] ? dataLoad[0].request_by ? dataLoad[0].request_by.unitDetail ? dayjs(dataLoad[0].request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-');
                // SetType(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-')
                SetType(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.user_sub_types.data.length > 0 ? dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-' : '-')
                

                
            });

        // // เช็คว่าเป็นสถานะ 'รอตรวจ' หรือยัง
        // // http://localhost:10000/api/majorcare/findAllHomecheckTrailList?jid=648bcf8939a38859f358deaa
        // let res_data_trail = await axios.get(
        //     majorCareUrl + `/majorcare/findAllHomecheckTrailList?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
        //     { headers: {  } }).then((res) => {
        //         let dataLoadTrail: any = res?.data;
        //         // console.log('dataLoadTrail', dataLoadTrail)

        //         for (let index = 0; index < dataLoadTrail.length; index++) {
        //             dataTrail.push(dataLoadTrail[index]);
        //         }
        //     });

        // find checklist
        // https://api-dev-majorcare.techscape.app/api/majorcare/findAllHomecheckjobChecklist?jid=648c99b100bf4d6e42787790
        let res_data_checklist = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckjobChecklist?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            });

        let res_data_trail = await axios.get(
            majorCareUrl + `/majorcare/findTrailByJob?jid=` + item_id ,
            { headers: {  } }).then((res) => {
                let dataTrail: any = res?.data;
                console.log('dataTrail', dataTrail)

                SetRemark(dataTrail[dataTrail.length-1].remark)

                // let trail_assign = dataTrail.find((item:any) => item?.status == "6485bcf9049e59df2850eba2");
                let trail_assign = dataTrail.filter((item:any) => item?.status == "6485bcf9049e59df2850eba2");
                console.log('trail_assign', trail_assign[trail_assign.length-1])


                if(trail_assign){
                    // SetInpectorName(trail_assign ? trail_assign.assign.attributes.user_profile ? trail_assign.assign.attributes.user_profile.data.attributes.first_name + ' ' + trail_assign.assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )
                    // setAppointStart(trail_assign ? dayjs(trail_assign.appoint_start).format("DD/MM/YYYY HH:mm") : '-');
                    // setAppointEnd(trail_assign ? dayjs(trail_assign.appoint_end).format("DD/MM/YYYY HH:mm") : '-');
                    SetInpectorName(trail_assign ? trail_assign[trail_assign.length-1]?.assign?.attributes?.user_profile ? trail_assign[trail_assign.length-1]?.assign?.attributes?.user_profile?.data?.attributes?.first_name + ' ' + trail_assign[trail_assign.length-1]?.assign?.attributes?.user_profile?.data?.attributes?.last_name  : '-' : '-' )
                    setAppointStart(trail_assign ? dayjs(trail_assign[trail_assign.length-1]?.appoint_start).format("DD/MM/YYYY HH:mm") : '-');
                    setAppointEnd(trail_assign ? dayjs(trail_assign[trail_assign.length-1]?.appoint_end).format("DD/MM/YYYY HH:mm") : '-');
                

                    // // Set สถานะการทำงาน
                    setLastTrail(trail_assign ? trail_assign[trail_assign.length-1].status : undefined)
                    // setLastTrailDate(dataTrail ? dayjs(dataTrail[0].create_date).format("YYYY-MM-DD HH:mm") : '-')
  
                }else{
                    SetInpectorName('-')
                }

                // SetInpectorName(dataTrail[0].assign ? dataTrail[0].assign.attributes.user_profile ? dataTrail[0].assign.attributes.user_profile.data.attributes.first_name + ' ' + dataTrail[0].assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )
                // console.log('Type', dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes)
                // SetInpectorName(dataTrail[0].assign ? dataTrail[0].assign.attributes.user_profile ? dataTrail[0].assign.attributes.user_profile.data.attributes.first_name + ' ' + dataTrail[0].assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )
            });
    }

    const getDataTrail = async ()=>{

        // เช็คว่าเป็นสถานะ 'รอตรวจ' หรือยัง
        // http://localhost:10000/api/majorcare/findAllHomecheckTrailList?jid=648bcf8939a38859f358deaa
        let res_data_trail = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckTrailList?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoadTrail: any = res?.data;
                // console.log('dataLoadTrail', dataLoadTrail)

                for (let index = 0; index < dataLoadTrail.length; index++) {
                    dataTrail.push(dataLoadTrail[index]);
                }
            });

            // find checklist
        // https://api-dev-majorcare.techscape.app/api/majorcare/findAllHomecheckjobChecklist?jid=648c99b100bf4d6e42787790
        let res_data_checklist = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckjobChecklist?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
                
                // dataChecklist.push(dataLoad)

                // let xx = dataLoad.filter((e:any)=> e.type == "CHECKLIST");
                // for (let index = 0; index < xx.length; index++) {
                //     dataChecklist.push(xx[index]);
                // }

                for (let index = 0; index < dataLoad.length; index++) {
                    dataChecklist.push(dataLoad[index]);
                }

            });
    }


      

    // const modalImg = (val:any) => {
    //     console.log('val',val)
    //     Modal.success({
    //     //   title: <span>Warning</span>,
    //         icon:false,
    //         content: 
    //         // <span>Please import coupon file</span>
    //          <Carousel 
    //             style={{ 
    //                 boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    //                 // borderRadius: "10px",
    //                 height: "auto",
    //                 width: "auto"
    //             }} 
    //             autoplay
    //         >
    //             {
    //                 val.map((item: any, key: any) => {
    //                     return <>
    //                         <Image 
    //                             src={item.url} 
    //                             width={"100%"} 
    //                             height={"100%"} 
    //                             style={{height:"100%",objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "30px"}} 
    //                         />
    //                     </>
    //                 })
    //             }
    //         </Carousel>
            
    //         ,
    //     });
    // };

    const handleModalClose = () => {
        // Handle modal close event if needed
    };
    
    const renderModalContent = (val:any) => (
        <Carousel
            autoplay
            style={{
            height: '80%',
            // width: '50%',
            margin: 0,
            padding: 0,
            background: 'rgba(0, 0, 0, 0.8)',
            }}
            arrows 
            nextArrow={<RightOutlined />} 
            prevArrow={<LeftOutlined/>}
        >
            {val.map((item: { url: string | undefined; }, key: Key | null | undefined) => (
            <div key={key} style={{ height: '80%', display: 'flex', justifyContent: 'center' }}>
                <Image
                preview={false}
                src={item.url}
                alt="carousel-image"
                style={{ height: '80%', objectFit: 'cover', borderRadius: '30px' }}
                />
            </div>
            ))}
        </Carousel>
    );

    const modalImg = (val:any) => {
      Modal.info({
        icon: false,
        content: renderModalContent(val),
        width: '50%',
        style: { top: 0, left: 0 },
        maskClosable: true,
        onOk: handleModalClose,
        onCancel: handleModalClose,
      });
    }

    const colTrail:any = [
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.operation-date")}</span>,
            dataIndex: 'create_date',
            key: 'create_date',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                // console.log('record',record)
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.list-creator")}</span>,
            dataIndex: 'create_by',
            key: 'create_by',
            // sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record ? record.create_by ? record.create_by.first_name + ' ' + record.create_by.last_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.status")}</span>,
            dataIndex: 'status',
            key: 'status',
            render: (val:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {/* 
                            6485ba66049e59df2850eb9e = รอดำเนินการ
                            6485bcda049e59df2850eba0 = รับเรื่อง
                            6485bcf9049e59df2850eba2 = รอตรวจ
                            6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
                            6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
                            6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
                            6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
                            6485bd8b681aef755a4f279f = รอนัดหมาย
                            6485bd96681aef755a4f27a1 = หมดอายุ
                        */}
                        { 
                            record ?
                                record.status == "6485ba66049e59df2850eb9e" ? 
                                        <Tag>รอดำเนินการ</Tag>
                                    : 
                                        record.status == "6485bcda049e59df2850eba0" ? 
                                            <Tag>รับเรื่อง</Tag>
                                        :
                                            record.status == "6485bcf9049e59df2850eba2" ? 
                                                <Tag>รอตรวจ</Tag>
                                            :
                                                record.status == "6485bd3a681aef755a4f2797" ? 
                                                    <Tag>เข้าตรวจหน้างาน</Tag>
                                                :
                                                    record.status == "6485bd56681aef755a4f2799" ? 
                                                        <Tag>เข้าตรวจหน้างาน - เกินกำหนด</Tag>
                                                    :
                                                        record.status == "6485bd6e681aef755a4f279b" ? 
                                                            <Tag>รออนุมัติผลการตรวจ</Tag>
                                                        :
                                                            record.status == "6485bd7d681aef755a4f279d" ? 
                                                                <Tag>เสร็จสมบูรณ์</Tag>
                                                            :
                                                                record.status == "6485bd8b681aef755a4f279f" ? 
                                                                    <Tag>รอนัดหมาย</Tag>
                                                                :
                                                                    record.status == "6485bd96681aef755a4f27a1" ? 
                                                                    <Tag>หมดอายุ</Tag>
                                                                : '-'
                            :
                                '-'
                        }
                        {/* <div>-</div> */}
                        {/* <div>{record ? record.unit ? record.unit.attributes ? record.unit.attributes.unit_name : '-': '-' : '-'}</div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.sla")}</span>,
            dataIndex: 'sla',
            key: 'sla',
            render: (value:any, record:any) => {

                {
                    if(record.sla && record.sla.sla_time > 60){
                        console.log('over 60')
                        const hours = Math.floor(record.sla.sla_time / 60);
                        const days = Math.floor(hours / 24);
                        console.log(`${record.sla.sla_time} minutes is equal to ${days} days and ${hours % 24} hours`);
                        sla_days = days
                        sla_hours = hours % 24

                    }
                }
                return (
                    
                    <>
                        {
                            record.sla && record.sla.sla_time > 60 ?
                                <div>
                                    {
                                        sla_days > 0 ?
                                            <div>{sla_days} วัน {sla_hours} ชั่วโมง</div>
                                        :
                                            <div> {sla_hours} ชั่วโมง</div>
                                    }
                                </div>
                            :
                                <div>{record ? record.sla ? record.sla.sla_time !== '-' && record.sla.sla_time !== '' ? record.sla.sla_time  + ' นาที' : '-' : '-': '-'}</div>
                        }
                        {/* <div>{record ? record.sla ? record.sla.sla_time !== '-' && record.sla.sla_time !== '' ? record.sla.sla_time  + ' นาที' : '-' : '-': '-'}</div> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.operation-history.remark")}</span>,
            dataIndex: 'remark',
            key: 'remark',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.remark ? record.remark : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    // ========================= NEW CHECKLIST MAP DATA ===============================
    // Prepare table columns
    const columnsx = [
        {
            title: 'No.',
            // dataIndex: 'item_name',
            // key: 'item_name',
            render: (value:any, record:any, index:any) => {
                return (
                    <>
                        <div>{index + 1}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.inspection-date")}</span>,
            // dataIndex: 'item_name',
            // key: 'item_name',
            render: (value:any, record:any, index:any) => {
                return (
                    <>
                        <div>{record ? dayjs(record.update_date).format("DD/MM/YYYY HH:mm") : '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.zone")}</span>,
            dataIndex: 'zoneName',
            key: 'zoneName',
            // render: (value:any, record:any, index:any) => {
            //     console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.structure")}</span>,
            dataIndex: 'structureName',
            key: 'structureName',
            // render: (value:any, record:any, index:any) => {
            //     // console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.checklist")}</span>,
            dataIndex: 'checklistName',
            key: 'checklistName',
            // render: (value:any, record:any, index:any) => {
            //     // console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.check-status")}</span>,
            // dataIndex: 'inspec_stat',
            key: 'inspec_stat',
            render: (value:any, record:any, index:any) => {
                // console.log('record xxxx', record)
                return (
                    <>
                        {/* {
                            record.is_status ?
                                // <Tag>ปกติ</Tag>
                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}> ปกติ </Tag> 
                            :
                                // <Tag>ไม่ปกติ</Tag>
                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}> ไม่ปกติ </Tag>
                        } */}
                         {
                            record.is_status ?
                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>{t("mjc-job.normal")}</Tag>
                            : 
                            record.is_status == null || record.is_status == undefined ?
                                <>-</>
                            :
                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>{t("mjc-job.n-normal")}</Tag>
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.warranty-conditions")}</span>,
            // dataIndex: 'waranty',
            // key: 'waranty',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.is_warantee ?
                                <div>{t("mjc-job.in-warranty")}</div>
                            :
                                <div>{t("mjc-job.out-warranty")}</div>
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("mjc-job.table-header.checklist.pic")}</span>,
            // dataIndex: 'pic',
            key: 'pic',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.pic ?
                                <>
                                    <Row>
                                        <Image 
                                            width={50} 
                                            height={50}
                                            src={record.pic[0]?.url} 
                                            style={{marginRight:'50px'}}
                                        />
                                        {
                                            record?.pic.length > 1 &&
                                                <div 
                                                    className="div_img_checklist"
                                                    style={{
                                                        backgroundImage: `url("${record.pic[1]?.url}")`,
                                                        width: '50px',
                                                        height: '50px',
                                                        backgroundSize:'cover',
                                                        backgroundColor:'#616161'
                                                        // backdropFilter: blur(0px')
                                                    }}
                                                    onClick={()=> {modalImg(record?.pic)}}
                                                >
                                                    <div
                                                        style={{
                                                            // alignItems:'center',
                                                            // justifyItems:'center',
                                                            // justifyContent:'center',
                                                            textAlign: 'center',
                                                            verticalAlign: 'center',
                                                            lineHeight: '50px',
                                                            fontSize:'25px',
                                                            color:'#ffffff',
                                                            width: '50px',
                                                            height: '50px',
                                                        }}
                                                    >
                                                        {/* {record?.pic.length -1}{'+'} */}
                                                        {'+'}{record?.pic.length -1}
                                                    </div>
                                                </div>
                                        }
                                    </Row>
                                </>
                            :
                                <>-</>
                        }
                    </>
                );
            },
        },
        // {
        //     title: 'Action',
        //     // dataIndex: 'pic',
        //     key: 'action',
        //     render: (value:any, record:any) => {
        //         // console.log('record', record)
        //         return (
        //             <>
        //                 <EditButton
        //                     hideText
        //                     size="small"
        //                     onClick={() => edit("mjc-inspec-checklist-todayjob-edit", record._id + "_" + item_id + "_" + record.structureName + "_" + record.zoneName )}
        //                     disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
        //                 />
        //             </>
        //         );
        //     },
        //     // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        // },
    ];

    // Prepare table data source
    const dataSourceX = dataChecklist.reduce((acc:any, item:any) => {
        // console.log('reduce item', item)
        let zoneName = '';
        let structureName = '';
        let checklistName = '';

        let is_status:any
        let is_warantee:any
        let pic:any
        let remark:any
        let _id:any
        let item_id:any
        let update_date:any = item ? item.update_date : '-'
        // let zone_name:any
        // let structure_name:any

        if (item.type === 'CHECKLIST') {
            const structure = dataChecklist.find((data:any) => data.template_check_list_id === item.parent && data.type === 'STRUCTURE');
            // console.log('structure', structure)
            if (structure) {
                structureName = structure.item_detail[0] ? structure.item_detail[0].item_name : '-';
                const zone = dataChecklist.find((data:any) => data.template_check_list_id === structure.parent && data.type === 'ZONE');
                if (zone) {
                    // console.log('zone zzzzzzzzzzzzzzzzzzzz', zone)
                    zoneName = zone.item_detail[0] ? zone.item_detail[0].item_name : '-';
                }
            }
            checklistName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
            is_warantee = item.is_warantee;
            is_status = item.is_status;
            pic = item.pic;
            remark = item.remark;
            _id = item._id;
            item_id = item.item;
        } else if (item.type === 'STRUCTURE') {
            const zone = dataChecklist.find((data:any) => data.template_check_list_id === item.parent && data.type === 'ZONE');
            if (zone) {
                zoneName = zone.item_detail[0] ? zone.item_detail[0].item_name : '-';
            }
            structureName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
        } else if (item.type === 'ZONE') {
            zoneName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
        }

        if (item.type === 'CHECKLIST') {
            acc.push({
                key: item._id,
                zoneName,
                structureName,
                checklistName,

                update_date,
                is_warantee,
                is_status,
                pic,
                remark,
                _id,
                item_id
            });
        }

        return acc;
    }, []);
    // ========================= NEW CHECKLIST MAP DATA ===============================
    // console.log('first')

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    getDataTrail();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();
    // console.log('checkedSms', checkedSms);

    // const { selectProps: selProperty } = useSelect<any>({
    //     resource    : "properties",
    //     optionLabel : "property_name",
    //     metaData: {
    //         populate: '*',
    //         // locale:['th']
    //     },
    //     optionValue : "id",
    //     fetchSize: 1000,
    //     filters: [
    //         {
    //             field: "deleteStatus",
    //             operator: "eq",
    //             value: "N"
    //         },
    //         {
    //             field : "id]",
    //             operator : data_property !== null ? "eq" : "ne",
    //             value : data_property !== null ? data_property : null
    //         },
    //     ]
    // });

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        resource: "home-check-up-settings",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            // {
            //     field: "role_management][id]",
            //     operator: "eq",
            //     value: 19 // role Inspector
            // },
            // {
            //     field: "property][id]",
            //     operator: "in",
            //     // value: PropertyId ? PropertyId : null
            //     value: PropertyIdJob ? PropertyIdJob : null 

            // },
            // {
            //     field: "user_profile][id]",
            //     operator: "in",
            //     value: InspectorSettingId ? InspectorSettingId : null // role Inspector
            // }
            {
                field: "role_management][id]",
                operator: "eq",
                value: 19 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            {
                field: "property][id]",
                operator: "eq",
                value: PropertyIdJob
            },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const AddInspectorToArr = async (e:any) => {
        // console.log('e', e);
        IdFilter.push(e);
        SetIdFilter(IdFilter);
        // let k = await axios.get(API_URL + "/user-type-mappings?populate=*&filters[id][$eq]="+ e, {
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        // console.log('k', k)
        let inspector_profile = k?.data?.data;
        // console.log('inspector_profile', inspector_profile[0].attributes.user_profile?.data.attributes)

        Inspector.push(inspector_profile[0].attributes.user_profile?.data.attributes);
        InspectorId.push(inspector_profile[0].attributes.user_profile?.data.id);
        SetInspector(Inspector);

        setTimeout(() => {
            form.resetFields(["Inspector"]);
        }, 500);

        // console.log('Inspector', Inspector)
        // console.log('IdFilter', IdFilter)
    }

    const FindInspectorUserId = async (e:any) => {
        // console.log('e', e);
    
        let k = await axios.get(API_URL + "/user-type-mappings?populate=user_profile.avatar&filters[id][$eq]="+ e, {
            headers: { Authorization: "Bearer "+token }
        });
        // console.log('k', k)
        let inspector_profile = k?.data?.data;
        // console.log('inspector_profile', inspector_profile[0].attributes.user_profile?.data.attributes)
        // console.log('inspector_profile[0].attributes.user_profile?.data.id', inspector_profile[0].attributes.user_profile?.data.id)
        SetInspector(inspector_profile[0].attributes.user_profile?.data.id)
        SetInspectorProfile(inspector_profile)
        // Inspector.push(inspector_profile[0].attributes.user_profile?.data.attributes);
        // InspectorId.push(inspector_profile[0].attributes.user_profile?.data.id);
        // SetInspector(Inspector);

        // setTimeout(() => {
        //     form.resetFields(["Inspector"]);
        // }, 500);

        // console.log('Inspector', Inspector)
        // console.log('IdFilter', IdFilter)
    }

    const RemoveItemFromArr = async (e:any) => {
        // console.log('e', e)
        Inspector.splice(e, 1); // 2nd parameter means remove one item only
        InspectorId.splice(e,1)
        IdFilter.splice(e, 1);
    }
  
    const handleDatePickerChange = (date: Dayjs | null, dateString: string, type: any) => {
        console.log('dateString', dateString)

        if(type == "start"){
            setAppointStart(dateString);
        }else{
            setAppointEnd(dateString);
        }
      
    };

    const onChangeRemark = (e: any) => {
        SetRemark(e.target.value);
    }

    async function updateJobAndTrail(){
        // console.log('id : ', item_id)

        // let data = {
        //     "status": Stat,  // หน้านี้ควรจะเป็นรอตรวจ
        // };
        // // console.log('data', data)
        // // let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + item_id, data, { headers: { } });
        // let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + item_id, data, { headers: { } });

        // // console.log('res', res)

        // // cal SLA
        // let dataTrail = {
        //     "home_check_job" : item_id,
        //     "status": Stat, // หน้านี้ควรจะเป็นรอตรวจ
        //     "appoint_start": AppointStart,
        //     "appoint_end": AppointEnd,
        //     "assign": InspectorProfile[0],
        //     "sla": {
        //         "sla": "-",
        //         "sla_time": "-"
        //     },
        //     "remark": "",
        //     "create_by": Identity_data?.user_profile,
        //     "create_date": dayjs()
        // }
        // let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
        // console.log('res_trail', res_trail)

        // // setCountInterval(0)

        // successNoti('200', 'success');

        // let dataAssign = {
        //     "id": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.id : null,
        //     "first_name":InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.first_name : null,
        //     "last_name": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.last_name : null,
        //     "tel": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.tel : null,
        //     "email": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.email : null,
        // }

        // let data = {
        //     "status": Stat,  // หน้านี้ควรจะเป็นรอตรวจ
        // };

        let dataAssign:any
        let data:any


        if(Stat == "6485bcf9049e59df2850eba2"){ // รอตรวจ
            // ถ้าเป็นรอตรวจ ควรจะได้วันนัดหมายใหม่และชื่อช่าง
            dataAssign = {
                "id": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.id : null,
                "first_name":InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.first_name : null,
                "last_name": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.last_name : null,
                "tel": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.tel : null,
                "email": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.email : null,
            }

            data = {
                "status": Stat,
                "appoint_start": AppointStart ? AppointStart : "",
                "appoint_end": AppointEnd ? AppointEnd : "",
                "assign": InspectorProfile ? dataAssign : null,
            };
        } else {
            data = {
                "status": Stat,
            };
        }
        
        let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + item_id, data, { headers: { } });
        // console.log('res', res)

        let dataTrail = {
            "home_check_job" : item_id,
            "status": Stat, // หน้านี้ควรจะเป็นรอตรวจ
            "appoint_start": AppointStart ? AppointStart : null,
            "appoint_end": AppointEnd ? AppointEnd : null ,
            "assign": InspectorProfile ? InspectorProfile[0] : null,
            "sla": {
                "sla": "-",
                "sla_time": "-"
            },
            "remark": Remark !== undefined ? Remark : "",
            "create_by": Identity_data?.user_profile,
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
        console.log('res_trail', res_trail)

        // setCountInterval(0)

        successNoti('200', 'success');
        
        replace("/majorcare/mjc-job")

        
    }

    const successNoti = (stat:any, msg:any) => {

        notification.success({
            // message: stat + ' : ' + msg.TH,
            message: msg,
            // description: msg,
            duration: 3,
        });
    };

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("service&repair.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("mjc-job.list")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    updateJobAndTrail();
                }}
            >

                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                        {t("mjc-job.detail")}
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label={t("mjc-job.job-no")}
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={JobNo} defaultValue={JobNo} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.property")}
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Input value={Property} defaultValue={Property} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.customer")}
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Customer} defaultValue={Customer} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label={t("mjc-job.created-date")}
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(CreateDate).format("DD/MM/YY HH:mm")} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.unit-no")}
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Unit} defaultValue={Unit} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label={t("mjc-job.customer-type")} 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Type} defaultValue={Type} disabled/>
                                </Form.Item>

                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label={t("mjc-job.trans-date")} 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>   
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.last-appointment")}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.trans-date")} 
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <DatePicker className="ant-picker-mjc" /> */}
                            <Input defaultValue={AppointStart} value={AppointStart} disabled />
                        </Form.Item>

                        <Form.Item 
                            label={t("mjc-job.inspector")}
                            // name="inspector"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Select/> */}
                            <Input defaultValue={InpectorName} value={InpectorName} disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item 
                            label={t("mjc-job.to-date")}
                            // name=""
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <DatePicker className="ant-picker-mjc" /> */}
                            <Input defaultValue={AppointEnd} value={AppointEnd} disabled />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.edit-item-status")}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        
                        <Form.Item 
                            label={t("mjc-job.status")} 
                            // name="status"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <Select/> */}

                            {/* 
                                6485ba66049e59df2850eb9e = รอดำเนินการ
                                6485bcda049e59df2850eba0 = รับเรื่อง
                                6485bcf9049e59df2850eba2 = รอตรวจ
                                6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
                                6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
                                6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
                                6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
                                6485bd8b681aef755a4f279f = รอนัดหมาย
                                6485bd96681aef755a4f27a1 = หมดอายุ
                            */}
                            
                            <Select 
                                placeholder={t("select.sel")}
                                style={{ width: '100%' }}
                                onSelect={(value: any) => {
                                    console.log(value);
                                    // setAptStatus(value);
                                    setStat(value);
                                }}
                                // defaultValue={LastTrail}
                                // value={LastTrail}
                                value={Stat}
                                options={[
                                    {
                                        label: "รอดำเนินการ",
                                        value: "6485ba66049e59df2850eb9e",
                                    },
                                    {
                                        label: "รับเรื่อง",
                                        value: "6485bcda049e59df2850eba0",
                                    },
                                    {
                                        label: "รอนัดหมาย",
                                        value: "6485bd8b681aef755a4f279f",
                                    },
                                    {
                                        label: "รอตรวจ",
                                        value: "6485bcf9049e59df2850eba2",
                                    },
                                    {
                                        label: "เข้าตรวจหน้างาน",
                                        value: "6485bd3a681aef755a4f2797",
                                    },
                                    {
                                        label: "เข้าตรวจหน้างาน - เกินกำหนด",
                                        value: "6485bd56681aef755a4f2799",
                                    },
                                    {
                                        label: "รออนุมัติผลการตรวจ",
                                        value: "6485bd6e681aef755a4f279b",
                                    },
                                    {
                                        label: "หมดอายุ",
                                        value: "6485bd96681aef755a4f27a1",
                                    },
                                    {
                                        label: "เสร็จสมบูรณ์",
                                        value: "6485bd7d681aef755a4f279d",
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                    {
                            Stat == "6485bcf9049e59df2850eba2" &&
                                <>
                                    <Form.Item 
                                        label={t("mjc-job.appointment-date")}
                                        // name="appointment_date"
                                        style={{
                                            width:"90%",
                                            // marginTop:'-3%'
                                        }}
                                    >
                                    {/* <DatePicker className="ant-picker-mjc" /> */}

                                    <DatePicker
                                        style={{width: "100%"}}
                                        className="ant-picker-mjc" 
                                        // onChange={(date, dateString) => handleDatePickerChange(date, dateString, 1)} 
                                        onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "start")} 
                                        // disabledDate={(current) => { 
                                        //     let customDate = moment().format("YYYY-MM-DD");
                                        //     return current && current < moment(customDate, "YYYY-MM-DD");
                                        // }}
                                        format={format}
                                        showTime
                                        // showHour
                                        // showMinute
                                    />
                                </Form.Item>
                            </>
                        }
                        {
                            Stat == "6485bcf9049e59df2850eba2" &&
                            <>
                                <Form.Item 
                                    label={t("mjc-job.inspector")}
                                    // name="inspector"
                                    style={{
                                        width:"90%", 
                                        // marginTop:'-3%'
                                    }}
                                >
                                    {/* <Select/> */}
                                    <Select 
                                        placeholder={t("select.sel")} 
                                        {...selInspector}
                                        onSelect={(value: any, record:any) => {
                                            // console.log('record', record)
                                            // AddInspectorToArr(value);
                                            FindInspectorUserId(value)
                                            // refetch();
                                            // SetInspector()
                                        }}
                                    >
                                    </Select>
                                </Form.Item>
                            </>
                        }
                        
                       
                    </Col>
                    <Col span={8}>
                        {
                            Stat == "6485bcf9049e59df2850eba2" &&
                                <>
                                    <Form.Item 
                                        label={t("mjc-job.last-appointment")} 
                                        // name="status"
                                        style={{
                                            width:"100%",
                                            // marginTop:'27%', 
                                            // position:'absolute'
                                        }}
                                    >
                                        {/* <DatePicker className="ant-picker-mjc"/> */}
                                        <DatePicker
                                            style={{width: "100%"}}
                                            className="ant-picker-mjc" 
                                            // onChange={(date, dateString) => handleDatePickerChange(date, dateString, 1)} 
                                            onChange={(date:any, dateString:any) => handleDatePickerChange(date, dateString, "end")} 
                                            // disabledDate={(current) => {
                                            //     let customDate = moment().format("YYYY-MM-DD");
                                            //     return current && current < moment(customDate, "YYYY-MM-DD");
                                            // }}
                                            showTime
                                            format={format}
                                        />
                                    </Form.Item>
                                </>
                        }
                        
                    </Col>

                    <Col span={8}>

                    </Col>
                </Row>

                {/* <Row>
                    <Col span={18}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            style={{width:"90%"}}
                        >
                            <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                onChange={(e) => onChangeRemark(e)}
                            />
                        </Form.Item>
                    </Col>
                </Row> */}
                <Row>
                    <Col span={18}>
                        <Form.Item 
                            label={t("mjc-job.note")} 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            {/* <TextArea></TextArea> */}
                            <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                // defaultValue={RemarkFromLastTrail}
                                // value={Remark}
                                onChange={(e) => onChangeRemark(e)}
                                disabled={Stat == "6485bd6e681aef755a4f279b" ? true : false}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.checklist")}
                        </div>
                    </Col>
                </Row>
               
                <Row gutter={82} >
                    <Col span={24}>
                        {/* <Table 
                            columns={colChecklist}
                            // dataSource={dataChecklist}
                            dataSource={dataChecklist.length > 0 ? dataChecklist : null}
                        /> */}
                        <Table columns={columnsx} dataSource={dataSourceX} />

                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            {t("mjc-job.operation-history")}
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Table 
                            columns={colTrail} 
                            dataSource={dataTrail.length > 0 ? dataTrail : null}
                        />
                    </Col>
                </Row>


                <Row style={{float: 'right',marginTop: "1.2rem"}}>
                    {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                    <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>{t("buttons.save")}</Button>
                </Row>
            </Form>
    </Edit>   
    );
};