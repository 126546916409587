/* eslint-disable */
import { Breadcrumb, Card, Col, DatePicker, Divider, Edit, Form, Input, List, message, Radio, RadioChangeEvent, RcFile, Row, Select, Space, Upload, UploadFile, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IBannerMgn, IContenPostAnnoucement, IContenPostBlogs, IContentPostActivityEvent, IContentPostPromotion, IContentPostType, IEventlog, IPopupMgn, IPrivillege, IProperties, IUserSubType } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useEffect, useState } from "react";
import { CCreateLog } from "../../components/create_log/index";
import dayjs from "dayjs";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

let promotion_option: undefined
let news_option: undefined
let blog_option: undefined
let announcement_option: undefined
let ae_option: undefined
let privilege_option: undefined

export const PopupMgnEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { TextArea } = Input;

    const log_endpoint = "even-logs";
    const format = 'YYYY-MM-DD HH:mm';

    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;

    const [vsetdetail, setdetail] = useState<any>();
    const [vsetdetail_en, setdetail_en] = useState<any>();
    const [vPropertyID, setPropertyID] = useState<any>([]);
    const [vImg, setImg] = useState<any>([]);
    const [vImgEn, setImgEn] = useState<any>([]);

    const [vInputSeq, setInputSeq] = useState<any>(0);
    const [vIsNewOrOld, setIsNewOrOld] = useState<any>(undefined);

    const [vDateStart, setDateStart] = useState<any>("");
    const [vDateEnd, setDateEnd] = useState<any>("");
    const [vContent, setContent] = useState<any>("");
    const [vContentTitle, setContentTitle] = useState<any>("");
    const [vContentName, setContentName] = useState<any>("");
    const [vContentType, setContentType] = useState<any>("");
    const [vOnlyRightMulti, setOnlyRightMulti] = useState<any>([]);
    const [vRaSpecificProperty, setRaSpecificProperty] = useState<any>(0);
    const [vValueLocale, setValueLocale] = useState(true);
    const [vEventDate, setEventDate] = useState<any>(null);

    const [reqImgTh, setReqImgTh] = useState<any>(false);
    const [reqImgEn, setReqImgEn] = useState<any>(false);

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const { form, formProps, saveButtonProps } = useForm<IBannerMgn, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                <CCreateLog
                    endpoint={log_endpoint}
                    data={{
                        user_profile: user_data.user_profile.id,
                        log_time_stamp: Date.now(),
                        log_event: "Create",
                        menu_event: 'coupon',
                        SysPkID: recordData.data.id + ""
                    }}
                />
            },
        }
    );

    let CkConfig: any;
    CkConfig = {
        toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "blockQuote",
            "|",
            "imageTextAlternative",
            "imageUpload",
            "imageStyle:full",
            "imageStyle:side",
        ],
        extraPlugins: [uploadPlugin],
        mediaEmbed: {
            // configuration...
            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
        },
        image: {
            toolbar: ['']
        }
    };

    const { queryResult: {refetch} } = useShow<IPopupMgn>({
        metaData: {
            populate: [
                '*',
                'properties',
                'user_types',
                'user_sub_types',
                'content_new',
                'content_announcement',
                'content_promotion',
                'content_ae',
                'content_blog',
                'privilege',
                'updateBy',
                'createBy',
                'pic',
                'pic_en',
            ]
        },
        queryOptions:{
            onSuccess(data: any) {
                console.log(">>> data?.data",data?.data)
                callFetchData(data?.data);
                let dataload = data?.data;


                if(dataload?.pic){
                    setReqImgTh(false)
                    const fileList_TH: UploadFile[] =
                    dataload?.pic ? dataload?.pic?.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url:
                                v.url,
                            thumbUrl:
                                v.url
                        }
                    )): [];

                    setImg({
                        name: 'files',
                        multiple: true,
                        defaultFileList: dataload?.pic?.length > 0 ? [...fileList_TH] : [],
                    })
                    form.resetFields(["pic"]);
                }else{
                    setReqImgTh(true)
                }
               

                // let dataload_EN = data?.data[0]?.localizations;

                if(dataload?.pic_en){
                    setReqImgEn(false)
                    const fileList_EN: UploadFile[] =
                    dataload?.pic_en?.length > 0 ? dataload?.pic_en?.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: v.name,
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];

                    setImgEn({
                        name: 'files',
                        multiple: true,
                        // defaultFileList: [...fileList3],
                        defaultFileList: dataload?.pic_en?.length > 0 ? [...fileList_EN] : [],

                    })
                    form.resetFields(["pic_en"]);
                }else{
                    setReqImgEn(true)
                }
                
            },
        }
    });
    // const { data } = queryResult;
    // let recordData: any = []
    // recordData = data?.data ? data?.data : [];
    // console.log('recordData', recordData)
    // const [ vTimeSlotTempTEST, setTimeSlotTempTEST ]    = useState<any>([]);
    // const [ vTimeSlotTEST, setTimeSlotTEST ]    = useState<any>([]);

    // console.log('vContent id', vContent)
    // console.log('vContentTitle title', vContentTitle)

    //==============================================================================================//

    // const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
    //     resource: "popup-managements",
    //     metaData: {
    //         populate: [
    //             '*',
    //             'properties',
    //             'user_types',
    //             'user_sub_types',
    //             'content_new',
    //             'content_announcement',
    //             'content_promotion',
    //             'content_ae',
    //             'content_blog',
    //             'privilege',
    //             'updateBy',
    //             'createBy',
    //         ]
    //     },
    //     pagination: {
    //         pageSize: 100,
    //     },
    //     permanentFilter: [
    //         {
    //             field: "id",
    //             operator: "in",
    //             value: recordData.id,
    //         }
    //     ],
    //     queryOptions: {
    //         onSuccess: (data) => {
    //             console.log(">>> data",data?.data)
    //             // callFetchData();

                
    //         },
    //     },
    // });

    const qContentPromotionData = useList<any>({ 
        resource: "content-promotions", 
        config:{ pagination:{ pageSize:100 }},
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }
                if(newData?.length > 0){
                    promotion_option = newData;
                }
            }
        }}
    });
    const { data: promotionData } = qContentPromotionData;
    let vPromotionData: any = []
    vPromotionData = promotionData?.data ? promotionData?.data : [];

    const qContentNewsData = useList<any>({ 
        resource: "content-news", 
        config:{ pagination:{ pageSize:100 }},
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }
                if(newData?.length > 0){
                    news_option = newData;
                }
            }
        }}
    });
    const { data: newsData } = qContentNewsData;
    let vNewsData: any = []
    vNewsData = newsData?.data ? newsData?.data : [];

    const qContentBlogData = useList<any>({ 
        resource: "content-blogs", 
        config:{ pagination:{ pageSize:100 }},
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }
                if(newData?.length > 0){
                    blog_option = newData;
                }
            }
        }}
    });
    const { data: blogData } = qContentBlogData;
    let vBlogData: any = []
    vBlogData = blogData?.data ? blogData?.data : [];

    const qContentAnnData = useList<any>({ 
        resource: "content-announcements", 
        config:{ pagination:{ pageSize:100 }},
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }
                if(newData?.length > 0){
                    announcement_option = newData;
                }
            }
        }}
    });
    const { data: annData } = qContentAnnData;
    let vAnnData: any = []
    vAnnData = annData?.data ? annData?.data : [];

    const qContentAeData = useList<IContentPostActivityEvent>({ 
        resource: "content-aes", 
        config:{ pagination:{ pageSize:100 }},
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }
                if(newData?.length > 0){
                    ae_option = newData;
                }
            }
        }}
    });
    const { data: aeData } = qContentAeData;
    let vAeData: any = []
    vAeData = aeData?.data ? aeData?.data : [];

    const qContentPrivData = useList<IPrivillege>({ 
        resource: "privileges", 
        config:{ pagination:{ pageSize:100 }},
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            let newData: any = [];
            if(dataLoad?.length > 0){
                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }
                if(newData?.length > 0){
                    privilege_option = newData;
                }
            }
        }}
    });
    const { data: privData } = qContentPrivData;
    let vPrivData: any = []
    vPrivData = privData?.data ? privData?.data : [];

    const { selectProps: PropertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'contains',
                value: 'N',
            }
        ]
    });

    const { selectProps: PrivUserSubTypeOnlyCus } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    const { selectProps: selContentPostType } = useSelect<IContentPostType>({
        resource: "content-post-types",
        optionLabel: "type_name",
        optionValue: "type_name",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N',
            },
        ]
    });

    // useEffect(() => {
    //     refetch();
    //     // console.log('');
    // }, []);

    //==============================================================================================//

    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setValueLocale(value);
        if (value) {
            console.log('th')
        } else {
            console.log('en')
        }
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const onChangeRadio5 = (e: RadioChangeEvent) => { setRaSpecificProperty(e.target.value); };

    const onChangeRadioNew = (e: RadioChangeEvent) => {
        setIsNewOrOld(e.target.value);
        form?.resetFields()
    };

    const returnDayJsDateRange = (start: string | number | Date | dayjs.Dayjs | null | undefined, finish: string | number | Date | dayjs.Dayjs | null | undefined) => {
        return [dayjs(start), dayjs(finish)];
    };

    //==============================================================================================//

    function callFetchData(record: any) {
        setDateStart(record.start_date ? record.start_date : '');
        setDateEnd(record.end_date ? record.end_date : '');
        setContentType(record.tag ? record.tag : '')
        setEventDate([record.start_date ? dayjs(record.start_date) : dayjs(), record.end_date ? dayjs(record.end_date) : dayjs()])

        if (record.properties.length > 0) {
            vPropertyID.pop();
            vPropertyID.push(
                record?.properties.map((v: any) => {
                    return v.id
                })
            )
            setPropertyID(vPropertyID);
        }

        setdetail(record.detail2 ? record.detail2 : '')
        setdetail_en(record.detail2_en ? record.detail2_en : '')

        // set content 
        if (record?.content_ae !== null) {
            setContent(record?.content_ae.id)
            setContentName(record?.content_ae.short_detail)
        } else if (record?.content_announcement !== null) {
            setContent(record?.content_announcement.id)
            setContentName(record?.content_announcement.short_detail)
        } else if (record?.content_blog !== null) {
            setContent(record?.content_blog.id)
            setContentName(record?.content_blog.short_detail)
        } else if (record?.content_new !== null) {
            setContent(record?.content_new.id)
            setContentName(record?.content_new.short_detail)
        } else if (record?.content_promotion !== null) {
            setContent(record?.content_promotion.id)
            setContentName(record?.content_promotion.short_detail)
        } else if (record?.privilege !== null) {
            setContent(record?.privilege.id)
            setContentName(record?.privilege.short_detail)
        }

        setRaSpecificProperty(record?.property_dev ? record?.property_dev : "")
        // setIsNewOrOld(record?.popup_type ? record?.popup_type : "")
        setIsNewOrOld(record?.popup_type ? record?.popup_type : false)

        // setOnlyRightMulti(record?.user_sub_types ? record?.user_sub_types : "");
        if (record.user_sub_types.length > 0) {
            vOnlyRightMulti.pop();
            vOnlyRightMulti.push(
                record?.user_sub_types.map((v: any) => {
                    return v.id
                })
            )
            setOnlyRightMulti(vOnlyRightMulti);
        }

        form.setFieldsValue({
            properties:
                record?.properties !== undefined ?
                    record?.properties.length > 0 ?
                        record?.properties.map((v: any) => {
                            // console.log(v)
                            return { value: v.id, label: v.property_name }
                        })
                        : form.resetFields(["properties"])
                    : form.resetFields(["properties"]),

            user_sub_types:
                record?.user_sub_types !== undefined ?
                    record?.user_sub_types.length > 0 ?
                        record?.user_sub_types.map((v: any) => {
                            // console.log(v)
                            return { value: v.id, label: v.sub_type_name }
                        })
                        : form.resetFields(["user_sub_types"])
                    : form.resetFields(["user_sub_types"]),

            // pic:

            // pic_en:
        })


        setInputSeq(record?.seq ? record?.seq : 0);
    }

    function fileChange_TH(e: any) {
        if(e.file.status === "removed") {
            setReqImgTh(true)
            setImg((pre:any) => ({
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));
            form?.resetFields(["pic"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                setReqImgTh(false)
                let res = e.file.response[0];
                setImg((pre:any) => ({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    function fileChange_EN(e: any) {
        if(e.file.status === "removed") {
            setReqImgEn(true)
            setImgEn((pre:any) => ({
                name: 'files',
                multiple: true,
                defaultFileList: [],
            }));
            form?.resetFields(["pic2"])
        }else if(e.file.status === "done"){
            if (e.file.response) {
                let res = e.file.response[0];
                setReqImgEn(false)
                setImgEn((pre:any) => ({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [
                        {
                            id: res?.id,
                            uid: String(res?.id),
                            name: res?.name,
                            status: "done",
                            url: res?.url,
                            thumbUrl: res?.url
                        }
                    ],
                }))
            }
        }
    }

    function selectTime(time: any, timeString: any) {
        setDateStart(dayjs(timeString[0]));
        setDateEnd(dayjs(timeString[1]));
    }

    function onChangePropertyID(va: any) {
        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
    };

    function onChangeOnlyRightMulti(va: any) {
        vOnlyRightMulti.pop();
        vOnlyRightMulti.push(va);
        setOnlyRightMulti(vOnlyRightMulti);
    }

    function onChangeContent(value: any, key: any) {
        setContent(value);
        setContentName(key.key)
    }

    function onChangeContentType(value: any, label: any) { setContentType(label.label); }

    function selectTimeEvent(time: any, timeString: any) {

        // console.log('timeString', timeString)
        if (timeString[0] == '' || timeString[1] == '') {
            setEventDate(null)
        } else {
            setEventDate(returnDayJsDateRange(timeString[0], timeString[1]))
        }
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                            method: "post",
                            body: body
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                resolve({
                                    default: res[0].url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    // *endpoint content post*
    // content-promotions
    // content-news
    // content-blogs
    // content-announcements
    // content-aes
    // privileges

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List
                title={t("popup-mgn.title-edit")}
                // title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("banner-mgn.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("banner-mgn.title-create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    values.updateBy = user_data.user_profile.id
                    // values.start_date = vDateStart
                    // values.end_date = vDateEnd
                    values.start_date = vEventDate[0]
                    values.end_date = vEventDate[1]
                    // values.detail = vsetdetail
                    values.seq = "1"
                    values.property_dev = vRaSpecificProperty
                    values.popup_type = vIsNewOrOld


                    // values.accept = vCondition // accept btn
                    // values.detail2 = vsetdetail !== null ? vsetdetail : ''
                    // values.detail2 = String(vsetdetail)
                    // values.detail2 = vsetdetail
                    // values.detail2_en = vsetdetail_en !== null ? vsetdetail_en : ''
                    // values.detail2_en = String(vsetdetail_en)

                    if (vValueLocale) {
                        // TH
                        values.detail2 = vsetdetail
                    } else {
                        // EN
                        values.detail2_en = vsetdetail_en
                    }

                    if (vPropertyID.length > 0) {
                        values.properties = vPropertyID[0]
                    }

                    values.user_sub_types = vOnlyRightMulti[0]
                    values.pic = vImg?.defaultFileList?.length > 0 ?  vImg?.defaultFileList[0] : null;

                    if (vIsNewOrOld == false) { // เลือก content จากของที่มีอยู่
                        // console.log('ffff', vContentName)
                        values.title = vContentName

                        vContentType == "Promotion" ? values.content_promotion = vContent : <></>
                        vContentType == "Privilege" ? values.privilege = vContent : <></>
                        vContentType == "News" ? values.content_new = vContent : <></>
                        vContentType == "Blog" ? values.content_blog = vContent : <></>
                        vContentType == "Announcement" ? values.content_announcement = vContent : <></>
                        vContentType == "Activity & Event" ? values.content_ae = vContent : <></>
                    }
                    // vContentType == "Promotion" ? values.content_promotion = vContent : <></>
                    // vContentType == "Privilege" ? values.privilege = vContent : <></>
                    // vContentType == "News" ? values.content_new = vContent : <></>
                    // vContentType == "Blog" ? values.content_blog = vContent : <></>
                    // vContentType == "Announcement" ? values.content_announcement = vContent : <></>
                    // vContentType == "Activity & Event" ? values.content_ae = vContent : <></>

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                {vIsNewOrOld !== undefined &&
                    <Row gutter={[16, 24]}>
                        <Col id="Col_w" className="gutter-row" span={18}>
                            <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>

                                <Radio.Group onChange={onChangeRadioNew} value={vIsNewOrOld}>
                                    <Radio value={true}>สร้างใหม่</Radio>
                                    <Radio value={false}>เลือกจากข้อมูลในระบบ</Radio>
                                </Radio.Group>

                                <div style={{ marginBottom: '2%' }} />

                                {
                                    vIsNewOrOld ? // สร้างใหม่
                                        <>
                                            <Row>
                                                <Col span={12}>
                                                    <div style={{ marginBottom: '2%' }}>
                                                        <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>

                                                {
                                                    vValueLocale ? // TH
                                                        <>
                                                            <Col span={12}>
                                                                <Form.Item label="Title" name="title"
                                                                    style={{ width: "95%" }}
                                                                    rules={[{ required: true, message: 'Please enter title.' },]}
                                                                >
                                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item label="Short Detail" name="short_detail"
                                                                    rules={[{ required: true, message: 'Please enter short detail.' },]}
                                                                >
                                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                                                    // defaultValue={vShortDetails}
                                                                    // onChange={(e) => onChangeShortDetail(e)} 
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                        :
                                                        <>
                                                            <Col span={12}>
                                                                <Form.Item label="Title (EN)" name="title_en"
                                                                    style={{ width: "95%" }}
                                                                    rules={[{ required: true, message: 'Please enter title (EN).' },]}
                                                                >
                                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={12}>
                                                                <Form.Item label="Short Detail (EN)" name="short_detail_en"
                                                                    rules={[{ required: true, message: 'Please enter short detail.' },]}
                                                                >
                                                                    <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                }


                                                {/* <Col span={12}>
                                                    <Form.Item  label="Title" name="title"
                                                        style={{width:"95%"}}
                                                        rules={[{required: true, message: 'Please enter title.'},]}
                                                    >
                                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                                    </Form.Item>
                                                </Col> */}
                                                {/* <Col span={1}></Col> */}
                                                {/* <Col span={12}>
                                                    <Form.Item  label="Title (EN)" name="title_en"
                                                        rules={[{required: true, message: 'Please enter title (EN).'},]}
                                                    >
                                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                                    </Form.Item>
                                                </Col> */}
                                            </Row>

                                            {
                                                vValueLocale ? // TH
                                                    <>
                                                        <Form.Item
                                                            label="Detail"
                                                        // name="detail"
                                                        // name="detail2"
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //     },
                                                        // ]}
                                                        >
                                                            <CKEditor
                                                                // name="detail"
                                                                editor={ClassicEditor}
                                                                // data={recordData.detail2? recordData.detail2 : ''}
                                                                data={vsetdetail ? vsetdetail : ''}
                                                                config={CkConfig}
                                                                onInit={(editor: any) => {

                                                                }}
                                                                onChange={(event: any, editor: any) => {
                                                                    const data = editor.getData();
                                                                    console.log('data', data)
                                                                    setdetail(data);
                                                                }}
                                                                onBlur={(editor: any) => { }}
                                                                onFocus={(editor: any) => { }}
                                                            />
                                                        </Form.Item>

                                                        <Row>
                                                            <Col span={11}>
                                                                <Form.Item name="tag" label="tag">
                                                                    <Select placeholder={t("select.sel")}
                                                                        // mode="multiple"
                                                                        {...selContentPostType}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Form.Item
                                                            // name="detail2_en"
                                                            label="Detail (EN)"
                                                        >
                                                            <CKEditor
                                                                // name="detail"
                                                                editor={ClassicEditor}
                                                                // data={recordData.detail2_en? recordData.detail2_en : ''}
                                                                data={vsetdetail_en ? vsetdetail_en : ''}
                                                                config={CkConfig}
                                                                onInit={(editor: any) => {
                                                                }}
                                                                onChange={(event: any, editor: any) => {
                                                                    const data = editor.getData();
                                                                    console.log('data', data)
                                                                    setdetail_en(data);
                                                                }}
                                                                onBlur={(editor: any) => { }}
                                                                onFocus={(editor: any) => { }}
                                                            />
                                                        </Form.Item>
                                                    </>
                                            }

                                        </>
                                        :
                                        vIsNewOrOld == false && // เลือกจากของเดิม
                                        <>
                                            <Row>
                                                <Col span={5}>
                                                    <span>
                                                        <b><div style={{ fontSize: "12px" }}>Content Type : </div><>&nbsp;</></b>
                                                    </span>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="tag">
                                                        <Select placeholder={t("select.sel")}
                                                            onChange={onChangeContentType}
                                                            {...selContentPostType}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}></Col>
                                            </Row>
                                            <Row>
                                                <Col span={5}>
                                                    <span>
                                                        <b><div style={{ fontSize: "12px" }}>Title : </div><>&nbsp;</></b>
                                                    </span>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item name="title"
                                                        rules={[
                                                            {
                                                                required: true, message: 'Please select content title.'
                                                            },
                                                        ]}
                                                    >
                                                        {/* <Select showSearch
                                                            allowClear
                                                            placeholder="Type to search"
                                                            // {...SelFaciliType} 
                                                            onChange={onChangeContent}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
                                                            filterSort={(optionA, optionB) =>
                                                                (optionA!.children as unknown as string)
                                                                    .toLowerCase()
                                                                    .localeCompare((optionB!.children as unknown as string).toLowerCase())
                                                            }
                                                        >
                                                            {
                                                                vContentType == "Promotion" ?
                                                                    vPromotionData !== undefined ?
                                                                        vPromotionData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            return <><Option value={item.id} key={item.short_detail} >{item.short_detail}</Option></>
                                                                        })
                                                                        :
                                                                        <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "News" ?
                                                                    vNewsData !== undefined ?
                                                                        vNewsData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Blog" ?
                                                                    vBlogData !== undefined ?
                                                                        vBlogData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Announcement" ?
                                                                    vAnnData !== undefined ?
                                                                        vAnnData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Activity & Event" ?
                                                                    vAeData !== undefined ?
                                                                        vAeData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                            {
                                                                vContentType == "Privilege" ?
                                                                    vPrivData !== undefined ?
                                                                        vPrivData.map((item: any, key: any) => {
                                                                            // console.log("promotion data",item);
                                                                            return <><Option value={item.id} key={item.short_detail}>{item.short_detail}</Option></>
                                                                        })
                                                                        : <></>
                                                                    : <></>
                                                            }

                                                        </Select> */}

                                                        <Select 
                                                            showSearch
                                                            allowClear
                                                            placeholder="Type to search"
                                                            onChange={onChangeContent}
                                                            options={
                                                                vContentType == "Privilege" ? privilege_option
                                                                : vContentType == "Activity & Event" ? ae_option
                                                                : vContentType == "Announcement" ? announcement_option
                                                                : vContentType == "Blog" ? blog_option
                                                                : vContentType == "News" ? news_option
                                                                : vContentType == "Promotion" ? promotion_option
                                                                : undefined
                                                            }
                                                        >
                                                            
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={7}></Col>
                                            </Row>
                                        </>
                                }

                                {
                                    vValueLocale && // TH
                                    <>
                                        <div style={{ marginBottom: '2%' }}></div>
                                        <Row>
                                            <Col span={3}>
                                                {/* <span style={{float:'right' }}><b>Period การกดรับโค้ดคูปอง / สิทธิ : <>&nbsp;</></b></span> */}
                                                <span>
                                                    <b><div style={{ fontSize: "12px" }}>Properties : </div><>&nbsp;</></b>
                                                </span>
                                            </Col>

                                            <Col span={15}>
                                                <Radio.Group onChange={onChangeRadio5} value={vRaSpecificProperty}>
                                                    <Space direction="vertical">
                                                        <div>
                                                            <Radio value="All" style={{ marginBottom: "0%" }}> ทุกโครงการ </Radio>
                                                        </div>
                                                        <div>
                                                            <Radio value="Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Major เท่านั้น </Radio>
                                                        </div>

                                                        <div>
                                                            <Radio value="Non Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Non Major เท่านั้น </Radio>
                                                        </div>
                                                        <div style={{ width: '250px' }}>
                                                            <Radio value="Properties" style={{ marginBottom: "0%" }}> ระบุโครงการ </Radio>
                                                            {
                                                                vRaSpecificProperty == "Properties" ?
                                                                    <>
                                                                        <div style={{ marginBottom: '2%' }}></div>
                                                                        <Form.Item
                                                                            // name="privilege_properties"
                                                                            name="properties"
                                                                        >
                                                                            <Select
                                                                                size="small"
                                                                                style={{ width: "95%" }}
                                                                                mode="multiple"
                                                                                placeholder={t("select.sel")}
                                                                                {...PropertySelectProps}
                                                                                onChange={onChangePropertyID}
                                                                            />
                                                                        </Form.Item>
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </div>
                                                    </Space>
                                                </Radio.Group>
                                            </Col>
                                        </Row>
                                        <div style={{ marginBottom: '2%' }}></div>
                                        {/* <Row>
                                                <Col span={3}>
                                                    <span>
                                                        <b><div style={{fontSize: "12px"}}>Accept Botton : </div><>&nbsp;</></b>
                                                    </span>
                                                </Col>
                                                <Col span={11}>
                                                    <div style={{marginLeft:'2%'}}><Checkbox checked={vCondition} onChange={onChangeCheckCondition}></Checkbox> Yes</div>
                                                </Col>
                                            </Row> */}

                                        <div style={{ marginBottom: '1%' }}></div>
                                        <Row>
                                            <Col span={11}>
                                                <Form.Item
                                                    // name="user_types"
                                                    name="user_sub_types"
                                                    label="User Type"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select user type.'
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        // size="small"
                                                        style={{ width: "95%" }}
                                                        mode="multiple"
                                                        placeholder={t("select.sel")}
                                                        onChange={onChangeOnlyRightMulti}
                                                        {...PrivUserSubTypeOnlyCus}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={1}></Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    // name="start_date"
                                                    label="Content public date"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select public date.'
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker.RangePicker
                                                        // defaultValue={[ dayjs(recordData.start_date), dayjs(recordData.end_date)]}
                                                        // value={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                        value={vEventDate}
                                                        // defaultValue={[ dayjs(vDateStart), dayjs(vDateEnd)]}
                                                        showTime
                                                        style={{ width: "100%" }}
                                                        // onChange={(date, dateString) => selectTime(date, dateString)}
                                                        onChange={(date, dateString) => selectTimeEvent(date, dateString)}

                                                        format={format}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/* <Col span={11}>
                                                <Form.Item name="seq"
                                                    // label={t("notice-ch")} 
                                                    // label="Content Promotion Sequences"
                                                    label="Sequences"
                                                >
                                                    <Input
                                                        type={"number"}
                                                        placeholder="Sequences"
                                                        // defaultValue={0}
                                                        min={0}
                                                        // size="small"
                                                        style={{ width: "100%", borderRadius: "5px" }}
                                                        // value={vInputSeq}
                                                        onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col> */}

                                            {/* <Col span={1}></Col> */}

                                            <Col span={11}>
                                                <Form.Item
                                                    name="status"
                                                    label="Status"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select status.'
                                                        },
                                                    ]}
                                                >
                                                    <Select placeholder={t("select.sel")}
                                                        style={{ width: '100%' }}
                                                        options={[
                                                            {
                                                                label: "Public",
                                                                value: "PUBLIC",
                                                            },
                                                            {
                                                                label: "Draft",
                                                                value: "DRAFT",
                                                            }
                                                        ]}
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={1}></Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    // label={t("notice-ch")} 
                                                    // label="Content Promotion Sequences"
                                                    label="External link"
                                                    name="link_external"
                                                >
                                                    <Input
                                                        // type={"number"}
                                                        placeholder="external link"
                                                        // defaultValue={0}
                                                        min={0}
                                                        // size="small"
                                                        style={{ width: "100%", borderRadius: "5px" }}
                                                    // value={vInputSeq}
                                                    // onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {/* <Row>
                                            <Col span={11}>
                                                <Form.Item
                                                    // label={t("notice-ch")} 
                                                    // label="Content Promotion Sequences"
                                                    label="External link"
                                                    name="link_external"
                                                >
                                                    <Input
                                                        // type={"number"}
                                                        placeholder="external link"
                                                        // defaultValue={0}
                                                        min={0}
                                                        // size="small"
                                                        style={{ width: "100%", borderRadius: "5px" }}
                                                    // value={vInputSeq}
                                                    // onChange={e => setInputSeq(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row> */}
                                    </>
                                }
                            </Card>
                        </Col>


                        {
                            vIsNewOrOld &&
                            <>
                                <Col id="Col_w" className="gutter-row" span={6}>
                                    <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                        <div style={{ marginBottom: 20 }}><span className="title_panel"><span style={{ color: "#f5222d", fontWeight: "300", fontSize: "16px", marginRight: "5px" }}>*</span>Thumbnail</span></div>
                                        <Divider></Divider>

                                        {
                                            vValueLocale ? // TH
                                                <>
                                                    <Form.Item
                                                        name="pic"
                                                        rules={[
                                                            {
                                                                required: reqImgTh, message: 'Please upload image.'
                                                            },
                                                        ]}
                                                        // valuePropName="fileList"
                                                        // getValueProps={(data) => getValueProps(data, API_URL)}
                                                    >
                                                        <Upload.Dragger
                                                            {...vImg}
                                                            style={{ borderRadius: 10 }}
                                                            name="files"
                                                            action={`${API_URL}/upload`}
                                                            headers={{
                                                                Authorization: `Bearer ${localStorage.getItem(
                                                                    TOKEN_KEY,
                                                                )}`,
                                                            }}
                                                            listType="picture"
                                                            // multiple
                                                            // {...uploadProps}
                                                            maxCount={1}
                                                            onChange={fileChange_TH}
                                                            beforeUpload={beforeUpload}
                                                        >
                                                            <p>Upload</p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                    <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Only Picture 750*750 PNG, JPG Maximum size 5MB</p>
                                                </>
                                                :
                                                <>
                                                    <Form.Item 
                                                        name="pic_en"
                                                        rules={[
                                                            {
                                                                required: reqImgEn, message: 'Please upload image.'
                                                            },
                                                        ]}
                                                    >
                                                        <Upload.Dragger
                                                            {...vImgEn}
                                                            style={{ borderRadius: 10 }}
                                                            name="files"
                                                            action={`${API_URL}/upload`}
                                                            headers={{
                                                                Authorization: `Bearer ${localStorage.getItem(
                                                                    TOKEN_KEY,
                                                                )}`,
                                                            }}
                                                            listType="picture"
                                                            // multiple
                                                            // {...uploadProps}
                                                            onChange={fileChange_EN}
                                                            beforeUpload={beforeUpload}
                                                        >
                                                            <p>Upload</p>
                                                        </Upload.Dragger>
                                                    </Form.Item>
                                                    <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Only Picture 750*750 PNG, JPG Maximum size 5MB</p>
                                                </>
                                        }

                                    </Card>
                                </Col>
                            </>
                        }
                    </Row>
                }
            </Form>
        </Edit>
    );
};