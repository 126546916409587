import { Show, Spin } from "@pankod/refine-antd";
import { IResourceComponentsProps, useShow, useTranslate } from "@pankod/refine-core";
import { IContentPostPromotion } from "interfaces";
import { useState } from "react";

let recordData:any = undefined;
export const ContentPostPromotionShowUNTOKEN: React.FC<IResourceComponentsProps> = () => {
    let getID = window?.location?.href.split("/");
    let local: any = getID[getID?.length -1];
    let pageID: any = getID[getID?.length -2];

    console.log(">>> local",local)
    console.log(">>> pageID",pageID)

    const t = useTranslate();
    const [isLoading, setLoading] = useState<any>(true);
    
    const { queryResult } = useShow<IContentPostPromotion>({  
        resource: "content-aes",
        metaData: {
            populate: '*',
            headers: {
                Authorization: `Bearer ${process.env.NO_AUTH_TOKEN_VIEW}`
            }
        },
        id: pageID,
        // meta:{ headers: { Authorization: `Bearer ${TOKEN}` }, },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log(">>> dataLoad", dataLoad)
                recordData = data?.data;
                setLoading(false);
            },
        }
    });


    return (
        <Spin spinning={isLoading}>
            <div style={{height: "100vh", backgroundColor: "#294060"}}></div>
        </Spin>
    )
}