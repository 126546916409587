import { Breadcrumb, Card, Checkbox, Col, Create, DatePicker, Divider, Form, Input, List, message, Radio, RadioChangeEvent, RcFile, Row, Select, Space, Upload, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IContenPostBlogs, ICoupon, IEventlog, IProperties, IUserSubType } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import {  TimePicker } from "antd";
import dayjs from "dayjs";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

export const ContentPostBlogCreate: React.FC<IResourceComponentsProps> = () => {
    // const { ...uploadProps_pixlist} = useStrapiUpload({ maxCount: 5, });
    const t = useTranslate();
    const { TextArea } = Input;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";
    const format = 'YYYY-MM-DD HH:mm';
    const userQuery = useGetIdentity(); // Get User Profile
    const { data: user_data, isLoading } = userQuery;
    const [vsetdetail, setdetail] = useState<any>();
    // const [ vStatus, setStatus ] = useState<any>("");
    // const [ vInputSeq, setInputSeq] = useState<any>(0);
    const [vContentDisplay, setContentDisplay] = useState<any>("Blogs");
    const [checkedApp, setCheckedApp] = useState(false);
    const [checkedSMS, setCheckedSMS] = useState(false);
    const [checkedEmail, setCheckedEmail] = useState(false);
    const [vAllNoticeChan, setAllNoticeChan] = useState<any>([]);

    const { formProps, saveButtonProps } = useForm<IContenPostBlogs, HttpError, {}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData: any = []
                recordData = data?.data ? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource: log_endpoint,
                    values: {
                        // user_profile            : user_data.id,
                        user_profile: user_data.user_profile.id,
                        log_time_stamp: Date.now(),
                        log_event: "Create",
                        menu_event: 'coupon',
                        SysPkID: recordData.data.id + ""
                    },
                    successNotification: false
                });
            },
        }
    );

    const { selectProps: PropertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'contains',
                value: 'N',
            },
        ]
    });

    const { selectProps: BlogTypeSelectProps } = useSelect<IProperties>({
        resource: "blog-types",
        optionLabel: "blog_name",
        optionValue: "id",
    });

    const API_URL = useApiUrl();
    const { ...uploadProps } = useStrapiUpload({ maxCount: 1, });
    const { queryResult } = useShow<ICoupon>({
        metaData: {
            populate: '*'
        },
    });
    const { data } = queryResult;
    // const record = data?.data;

    const [value, setValue] = useState(false);

    const onChangeNoti = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };
    // console.log("testttt",queryResult)

    // const [switchToggle, setSwitchToggle] = useState(queryResult.data?.data.coupon_active);
    // const onChange_sw = (checked: any) => {
    //     setSwitchToggle(checked);
    // };

    // const { selectProps: Selpptype } = useSelect<IPPType>({
    //     resource: "pp-types",
    //     optionLabel: "pp_name",
    //     optionValue: "id",
    //     // filters: [initF]
    // });

    function fileChange(e: any) {
        console.log(e);
        if (e.file.response) {
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
        }
    }

    const { selectProps: PrivUserSubTypeOnlyCus } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field: 'sub_type_name',
                operator: 'contains',
                value: ['Owner', 'Renter', 'Resident Renter', 'Resident Owner', 'Guest'],
            },
        ]
    });

    const [vDateStart, setDateStart] = useState<any>("");
    const [vDateEnd, setDateEnd] = useState<any>("");

    function selectTime(time: any, timeString: any) {
        setDateStart(dayjs(timeString[0]));
        setDateEnd(dayjs(timeString[1]));
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const onChangeCheckApp = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        setCheckedApp(e.target.checked);
    };

    const onChangeCheckSMS = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        setCheckedSMS(e.target.checked);
    };

    const onChangeCheckEmail = (e: CheckboxChangeEvent) => {
        // console.log('checked = ', e.target.checked);
        setCheckedEmail(e.target.checked);
    };

    function chkNoticeChan() {
        if (checkedSMS) {
            vAllNoticeChan.push(1);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedEmail) {
            vAllNoticeChan.push(2);
            setAllNoticeChan(vAllNoticeChan);
        }

        if (checkedApp) {
            vAllNoticeChan.push(3);
            setAllNoticeChan(vAllNoticeChan);
        }

        console.log('vAllNoticeChan', vAllNoticeChan);
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        // console.log('fileeeeeeeeeeeee', file)
                        body.append("files", file);
                        // let headers = new Headers();
                        // headers.append("Origin", "http://localhost:3000");
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                            method: "post",
                            body: body
                            // mode: "no-cors"
                        })
                            .then((res) => res.json())
                            .then((res) => {
                                // console.log('resssssssssssssssss', res)
                                resolve({
                                    //   default: `${API_URL}/${res.filename}`
                                    default: res[0].url
                                });
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                });
            }
        };
    }
    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }


    const [vValueLocale, setValueLocale] = useState(true);
    const onChangeLocale = ({ target: { value } }: RadioChangeEvent) => {
        setValueLocale(value);
        if (value) {
            console.log('th')
        } else {
            console.log('en')
        }
    };

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];


    const [vRaSpecificProperty, setRaSpecificProperty] = useState<any>('All');
    const [vPropertyID, setPropertyID] = useState<any>([]);

    const onChangeRadio5 = (e: RadioChangeEvent) => {
        console.log('onChangeRadio5', e.target.value);
        setRaSpecificProperty(e.target.value);
    };
    const [ vKeep, setVKeep ] = useState<any>([]);
    function onChangePropertyID(va: any) {
        let keepNeww:any = []
        for (let index = 0; index < va.length; index++) {
            keepNeww.push(va[index])
        }
        setVKeep(keepNeww)

        vPropertyID.pop();
        vPropertyID.push(va);
        setPropertyID(vPropertyID);
    };

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List
                title={t("content-post-blog.title-create")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("content-post-blog.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra: <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {
                    values.createBy = user_data.user_profile.id
                    values.start_date = vDateStart
                    values.end_date = vDateEnd
                    values.detail = vsetdetail
                    // values.seq = vInputSeq

                    values.property_dev = vRaSpecificProperty
                    values.properties = vPropertyID[0]

                    // values.notice_channel = values.notice_channel !== undefined ? values.notice_channel : value
                    values.notice_channel = value


                    if (vValueLocale) {
                        values.locale = "th"
                    } else {
                        values.locale = "en"
                    }

                    chkNoticeChan();
                    if (vAllNoticeChan.length > 0) {
                        values.notice_channel_types = vAllNoticeChan
                    }

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <div style={{ marginBottom: '2%' }}>
                            <Radio.Group options={optionsRdioLocale} onChange={onChangeLocale} value={vValueLocale} optionType="button" disabled />
                        </div>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: "1%" }}>
                                <span style={{ marginRight: "3%", fontSize: ".70vw", fontWeight: "bold", color: "#000" }}>Display Content :</span>
                                <span style={{ fontSize: "13px", color: "#000" }}>{vContentDisplay}</span>
                            </div>

                            <Form.Item
                                name="blog_type"
                                label="Category"
                                rules={[
                                    {
                                        required: true, message: 'Please select blog type.'
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: "50%" }}
                                    // mode="multiple"
                                    placeholder={t("select.sel")}
                                    {...BlogTypeSelectProps}
                                />
                            </Form.Item>

                            {/* <Form.Item
                                name="start_date"
                                label = "Event date"
                                rules={[
                                    {
                                        required: true, message: 'please select date.'
                                    },
                                ]}
                            >
                                <DatePicker.RangePicker
                                    showTime
                                    style={{width:"100%"}}
                                    onChange={(date, dateString) => selectTime(date, dateString)}
                                    format={format}
                                />
                            </Form.Item> */}


                            <Form.Item label="Title" name="name"
                                rules={[
                                    {
                                        required: true, message: 'Please enter title.'
                                    },
                                ]}>
                                <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                            </Form.Item>


                            <Form.Item label="Short Detail" name="short_detail"
                                rules={[
                                    {
                                        required: true, message: 'Please enter Sub Title.'
                                    },
                                ]}>
                                <TextArea style={{ height: "30px", maxHeight: "80px" }} />
                            </Form.Item>

                            <Row>
                                {/* <Col span={12}>
                                    <Form.Item
                                        name="properties"
                                        label = "Property"
                                        rules={[
                                            {
                                                required: true, message: 'please select property.'
                                            },
                                        ]}
                                    >
                                        <Select 
                                            style={{width:"95%"}}
                                            mode="multiple"
                                            placeholder={t("select.sel")}
                                            {...PropertySelectProps}
                                        />
                                    </Form.Item>
                                </Col> */}

                                <Col span={3}>
                                    <span>
                                        <b><div style={{ fontSize: "12px" }}>Properties : </div><>&nbsp;</></b>
                                    </span>
                                </Col>

                                <Col span={8}>
                                    <Radio.Group onChange={onChangeRadio5} defaultValue="All">
                                        <Space direction="vertical">
                                            <div>
                                                <Radio value="All" style={{ marginBottom: "0%" }}> ทุกโครงการ </Radio>
                                            </div>
                                            <div>
                                                <Radio value="Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Major เท่านั้น </Radio>
                                            </div>

                                            <div>
                                                <Radio value="Non Major" style={{ marginBottom: "0%" }}> เฉพาะโครงการของ Non Major เท่านั้น </Radio>
                                            </div>
                                            <div style={{ width: '250px' }}>
                                                <Radio value="Properties" style={{ marginBottom: "0%" }}> ระบุโครงการ </Radio>
                                                {
                                                    vRaSpecificProperty == "Properties" ?
                                                        <>
                                                            <div style={{ marginBottom: '2%' }}></div>
                                                            <Form.Item
                                                                // name="privilege_properties"
                                                                name="properties"
                                                                rules={[
                                                                    {
                                                                        required: true , message: 'Please select property.'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    // size="small"
                                                                    style={{ width: "95%" }}
                                                                    mode="multiple"
                                                                    placeholder={t("select.sel")}
                                                                    {...PropertySelectProps}
                                                                    onChange={onChangePropertyID}
                                                                />
                                                               
                                                            </Form.Item>
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </Space>
                                    </Radio.Group>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="start_date"
                                        label="Content public date"
                                        rules={[
                                            {
                                                required: true, message: 'Please select public date.'
                                            },
                                        ]}
                                    >
                                        {/* <TimePicker.RangePicker 
                                            onChange={(date, dateString) => selectTime(date, dateString)}
                                            format={format}
                                        /> */}
                                        <DatePicker.RangePicker
                                            showTime
                                            style={{ width: "100%" }}
                                            onChange={(date, dateString) => selectTime(date, dateString)}
                                            format={format}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item 
                                label="เนื้อหา"
                                name="detail"
                                rules={[
                                    {
                                        required: true, message: 'Please enter detail'
                                    },
                                ]}
                            >
                               
                                <CKEditor
                                    // name="detail"
                                    editor={ClassicEditor}
                                    //data={recordData.detail}
                                    config={{
                                        toolbar: [
                                            "heading",
                                            "|",
                                            "bold",
                                            "italic",
                                            "link",
                                            "bulletedList",
                                            "numberedList",
                                            "blockQuote",
                                            // "ckfinder",
                                            "|",
                                            "imageTextAlternative",
                                            "imageUpload",
                                            "imageStyle:full",
                                            "imageStyle:side",
                                            "|",
                                            // {name: 'paragraph' , items: ['NumberList', 'JustifyCenter']}
                                            'JustifyCenter',
                                            // "JustifyCenter",
                                            // "mediaEmbed",
                                            // "insertTable",
                                            // "tableColumn",
                                            // "tableRow",
                                            // "mergeTableCells",
                                            // "|",
                                            // "undo",
                                            // "redo"
                                        ],
                                        extraPlugins: [uploadPlugin],
                                        mediaEmbed: {
                                            // configuration...
                                            removeProviders: ['instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube']
                                        },
                                        image: {
                                            toolbar: ['']
                                        }
                                    }}

                                    onInit={(editor: any) => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log("Editor is ready to use!", editor);
                                        console.log(
                                            "toolbar: ",
                                            Array.from(editor.ui.componentFactory.names())
                                        );
                                        console.log(
                                            "plugins: ",
                                            ClassicEditor.builtinPlugins.map((plugin: any) => plugin.pluginName)
                                        );
                                    }}
                                    onChange={(event: any, editor: any) => {
                                        const data = editor.getData();
                                        setdetail(data);
                                        // console.log(">>>>>>>>>>>>>>",{ event, editor, data });
                                    }}
                                    onBlur={(editor: any) => {
                                        console.log("Blur.", editor);
                                    }}
                                    onFocus={(editor: any) => {
                                        console.log("Focus.", editor);
                                    }}
                                />
                            </Form.Item>

                            <Row>
                                {/* <Col span={12}>
                            <Form.Item name="seq" 
                                label="Content Sequences"
                            >
                                <Input 
                                    type={"number"}
                                    placeholder="Sequences"
                                    // defaultValue={0}
                                    min={0}
                                    // size="small"
                                    style={{width: "95%", borderRadius: "5px"}}
                                    // value={vInputSeq}
                                    onChange={e => setInputSeq(e.target.value)}
                                />
                            </Form.Item>
                        </Col> */}

                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[
                                            {
                                                required: true, message: 'Please select status.'
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")}
                                            style={{ width: '95%' }}
                                            onSelect={(value: any) => {
                                                console.log(value);
                                                // setStatus(value);
                                            }}
                                            options={[
                                                {
                                                    label: "Public",
                                                    value: "PUBLIC",
                                                },
                                                {
                                                    label: "Draft",
                                                    value: "DRAFT",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="user_sub_types"
                                        label="User Type"
                                        rules={[
                                            {
                                                required: true, message: 'Please select user type.'
                                            },
                                        ]}
                                    >
                                        <Select placeholder={t("select.sel")}
                                            mode="multiple"
                                            style={{ width: '100%' }}
                                            // onSelect={(value: any) => {
                                            //     console.log(value);
                                            //     // setStatus(value);
                                            // }}
                                            {...PrivUserSubTypeOnlyCus}
                                        // options={[
                                        //     {
                                        //         label: "Public",
                                        //         value: "PUBLIC",
                                        //     },
                                        //     {
                                        //         label: "Draft",
                                        //         value: "DRAFT",
                                        //     }
                                        // ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row>
                        <Col span={12}>
                            <Form.Item name="seq" 
                                // label={t("notice-ch")}
                                // label="Content Blog Sequences"
                                label="Content Sequences"
                            >
                                <Input 
                                    type={"number"}
                                    placeholder="Sequences"
                                    // defaultValue={0}
                                    min={0}
                                    // size="small"
                                    style={{width: "100%", borderRadius: "5px"}}
                                    // value={vInputSeq}
                                    onChange={e => setInputSeq(e.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                    </Row> */}

                            {/* <Row>
                                <Col span={12}>
                                    <Form.Item name="seq"
                                        // label={t("notice-ch")}
                                        // label="Content Activity &amp; Event Sequences"
                                        label="Content Sequences"
                                    >
                                        <Input
                                            type={"number"}
                                            placeholder="Sequences"
                                            // defaultValue={0}
                                            min={0}
                                            // size="small"
                                            style={{ width: "95%", borderRadius: "5px" }}
                                        // value={vInputSeq}
                                        // onChange={e => setInputSeq(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}></Col>
                            </Row> */}


                        </Card>
                    </Col>

                    <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel"><span style={{ color: "#f5222d", fontWeight: "300", fontSize: "16px", marginRight: "5px" }}>*</span>Thumbnail</span></div>
                            <Divider></Divider>
                            <Form.Item
                                name="pic"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload image.'
                                    },
                                ]}
                            >

                                <Upload.Dragger
                                    style={{ borderRadius: 10 }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    {...uploadProps}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                            </Form.Item>
                            <p style={{ fontSize: "10px", alignItems: "stretch", color: "#DF6677" }}>*Picture 1024*864 PNG, JPG Maximum size 5MB</p>
                        </Card>

                        <Card style={{ backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <div style={{ marginBottom: 20 }}><span className="title_panel">Notifications</span></div>
                            <Divider></Divider>
                            <Form.Item name="notice_channel" label={t("notice-ch")}>
                                <Radio.Group
                                    defaultValue={false}
                                    options={[
                                        {
                                            label: "แจ้งเตือน",
                                            value: true,
                                        },
                                        {
                                            label: "ไม่แจ้งเตือน",
                                            value: false,
                                        }
                                    ]}
                                    onChange={onChangeNoti}
                                />
                            </Form.Item>

                            {value === true ?
                                <>
                                    <Form.Item label="Notice Date"
                                        name="notice_date"
                                        rules={[
                                            { required: true, message: 'Please select date and time.' },
                                        ]}
                                    >
                                        <DatePicker
                                            showTime
                                            style={{ width: "100%" }}
                                            format={format}
                                        />
                                    </Form.Item>

                                    <Form.Item label="Customer Type"
                                        name="notice_customer_type"
                                        rules={[
                                            { required: true, message: 'Please select customer type.' },
                                        ]}
                                    >
                                        <Select
                                            // size="small"
                                            style={{ width: "100%" }}
                                            mode="multiple"
                                            placeholder={t("select.sel")}
                                            // onChange={onChangeOnlyRightMulti}
                                            {...PrivUserSubTypeOnlyCus}
                                        />
                                    </Form.Item>
                                    <Form.Item style={{ marginBottom: "7%" }}>
                                        <Checkbox checked={checkedApp} onChange={onChangeCheckApp}>
                                            In App
                                        </Checkbox>

                                        {
                                            checkedApp ?
                                                <>
                                                    <Form.Item name="notice_inApp"
                                                        rules={[
                                                            { required: true, message: 'Please Type Message.' },
                                                        ]}
                                                    >
                                                        <Input placeholder="In app message" maxLength={85} />
                                                    </Form.Item>
                                                </>
                                                :
                                                <></>
                                        }

                                        <div style={{ marginBottom: '1%' }}></div>
                                        <Checkbox checked={checkedSMS} onChange={onChangeCheckSMS}>
                                            SMS
                                        </Checkbox>

                                        {
                                            checkedSMS ?
                                                <>
                                                    <Form.Item name="notice_sms"
                                                        rules={[
                                                            { required: true, message: 'Please Type Message.' },
                                                        ]}
                                                    >
                                                        <Input placeholder="SMS message" maxLength={85} />
                                                    </Form.Item>
                                                </>
                                                :
                                                <></>
                                        }

                                        <div style={{ marginBottom: '1%' }}></div>
                                        <Checkbox checked={checkedEmail} onChange={onChangeCheckEmail}>
                                            Email
                                        </Checkbox>
                                        {
                                            checkedEmail ?
                                                <>
                                                    <Form.Item name="notice_email"
                                                        rules={[
                                                            { required: true, message: 'Please Type Message.' },
                                                        ]}
                                                    >
                                                        <TextArea placeholder="Message" maxLength={255} style={{ height: "30px", maxHeight: "80px" }} />
                                                    </Form.Item>
                                                </>
                                                :
                                                <></>
                                        }
                                    </Form.Item>
                                </>
                                :
                                <></>
                            }
                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};