/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { RepairAssignTechnicianList } from "./assign_technician";
import { RepairEmailGroupList } from "./email_group";
import { RepairTermConditionList } from "./term_and_condition";
// import { HcuChecklistList } from "./checklist";
// import { HcuStructureList } from "./structure";
// import { HcuZoneList } from "./zone";
// import { HcuNotiMsgList } from "./notification";
// import { HcuTermConditionList } from "./term_and_condition";
// import { HcuTemplateList } from "./template_master";
// import { HcuDaysList } from "./setup_days";
// import { HcuAssignList } from "./assign_inspec";


export const Repair: React.FC<IResourceComponentsProps> = () => {
    const tabActive = localStorage.getItem("keyTabRepairSetup");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    function onStatusChange(v:any){
        // if(v == "Y"){
        //     // setdeleteStatus("Y")
        //     // setappointmentType(null) 
        // }else{
        //     // setdeleteStatus("N")
        //     // setappointmentType(v)
        // }
        // searchFormProps.form?.submit();
        localStorage.setItem("keyTabRepairSetup", v)
    }

    return <>
        <List 
            // title={t("hcumaster.title")}
            title="Setup แจ้งซ่อม (Major care)"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">Setup แจ้งซ่อม</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></> 
            }}
        >
            <Tabs
                defaultActiveKey={tabActive? tabActive + "" : "1"}
                onChange={onStatusChange}
            >
                <Tabs.TabPane tab="Term &amp; Conditions" key="1">
                    <RepairTermConditionList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="ช่าง" key="2">
                    <RepairAssignTechnicianList />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Email Group" key="3">
                    {/* <HcuChecklistList/> */}
                    <RepairEmailGroupList />
                </Tabs.TabPane>
            </Tabs>
        </List>

        
    </>
}