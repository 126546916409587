import { Badge, Breadcrumb, Button, Col, CreateButton, EditButton, Form, Icons, Input, List, Modal,  Row,  Select,  ShowButton,  Space, Table, Tabs, Tag, TextField, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useGetIdentity, useTranslate, useUpdate } from "@pankod/refine-core";
import { IFacilityBan, IPrivillegeCate} from "interfaces";
// import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

const { TabPane } = Tabs;

export const ContentPostPrivilegeList: React.FC<IResourceComponentsProps> = () => {

    const tabPrivilege = localStorage.getItem("keyTabStatusPrivilege") ?? 'N';

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Privilege");

    // const prop_id = localStorage.getItem("PROPERTY_ID");
    // const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IFacilityBan>();
    const ed_point                          = "privileges";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    
    const userQuery                         = useGetIdentity();
    const {data : user_data}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'DRAFT'}

    const [ vPrivCate, setPrivCate] = useState<any>("");
    const [ vSubCate, setSubCate] = useState<any>("");
    const [vInputTitle, setInputTitle]      = useState<any>();
    const [ vStatus, setStatus ] = useState<any>(undefined);
    const [keepDataStatus, setKeepDataStatus] = useState<any>(tabPrivilege == 'Y' ? 'Y' : 'N')
    const [listData, setListdata] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(null);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [activeBadgeNumber, setActiveBadgeNumber] = useState<any>(null)
    const [trashBadgeNumber, setTrashBadgeNumber] = useState<any>(null)

    // const [datafield, setDatafield] = useState<any>("id")
    // const [dataorder, setDataorder] = useState<any>("desc")

    const { formProps } = useForm<any,HttpError,{}>() 

    const { selectProps: privCateSelectProps } = useSelect<IPrivillegeCate>({
        resource: "privilege-categories",
        optionLabel: "pc_name",
        // optionValue: "pc_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
        ],
    });

    const { selectProps: privCateSubSelectProps } = useSelect<IPrivillegeCate>({
        resource: "privilege-sub-categories",
        optionLabel: "psc_name",
        // optionValue: "psc_name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        filters: [
            {
                field: 'deleteStatus',
                operator: 'eq',
                value: 'N'
            },
            {
                field: 'privilege_category_id',
                operator: 'eq',
                value: vPrivCate + ""
            }
        ],
    });
    const onReset = () => {
        setInputTitle(undefined);
        setStatus(undefined);
        setPrivCate(undefined);
        setSubCate(undefined);
        formProps.form?.resetFields()
    };

    const { mutate: UpdateLocale } = useUpdate<any>();

    function updateStatus(id:any, localizeId:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
            },
            errorNotification:false,
            successNotification:false
        },{
            onSuccess(data, variables, context) {
                getData()
            },
            onError(error, variables, context) {
                getData()
            },
        });

        if(localizeId && localizeId !== null || localizeId !== undefined){
            UpdateLocale({
                resource : ed_point,
                id: localizeId,
                values : {
                    updateBy        : user_data.user_profile.id + "",
                    deleteStatus    : vCurDelStatus.deleteStatus
                    // status    : vCurDelStatus.deleteStatus
                },errorNotification:false,
                successNotification:false,
                },{
                    onSuccess() {
                        // close();
                    },
                }
            )
        }
    }

    function showDelConfirm(id:any, localizeId: any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, localizeId: any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, localizeId);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        setKeepDataStatus(v);
        setPage(1)
        localStorage.setItem("keyTabStatusPrivilege",v)
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : sorterOrder
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }

        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSizeChange(pagination?.pageSize)
        }
    };
    
    const API_URL = useApiUrl();
    const getData = async ()=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(vPrivCate){
                apiEndPoint += `&filters[privilege_category][id][$eq]=${vPrivCate}`
            }
            if(vSubCate){
                apiEndPoint += `&filters[privilege_sub_category][id][$eq]=${vSubCate}`
            }
            if(vInputTitle){
                apiEndPoint += `&filters[name][$contains]=${vInputTitle}`
            }
            if(vStatus){
                apiEndPoint += `&filters[status][$contains]=${vStatus}`
            }
            if(keepDataStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${keepDataStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${keepDataStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const resultList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let unit = null
                        let content_post = null
                        let coupon_group = null
                        let coupons = []
                        let localizations = []
                        let notice_channel_types = []
                        let notice_customer_type = []
                        let pic = []
                        let privilege_category = null
                        let privilege_properties = []
                        let privilege_sub_category = null
                        let privilege_user_sub_type = []
                        let user_condition_sub_types = []
                        let user_profile = null
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.content_post?.data){
                            content_post = {id: attributes?.content_post?.data?.id , ...attributes?.content_post.data.attributes}
                        }
                        if(attributes?.coupon_group?.data){
                            coupon_group = {id: attributes?.coupon_group?.data?.id , ...attributes?.coupon_group.data.attributes}
                        }
                        if(attributes?.coupons?.data && attributes?.coupons?.data?.length > 0){
                            coupons = attributes?.coupons?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.localizations?.data && attributes?.localizations?.data?.length > 0){
                            localizations = attributes?.localizations?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.notice_channel_types?.data && attributes?.notice_channel_types?.data?.length > 0){
                            notice_channel_types = attributes?.notice_channel_types?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.notice_customer_type?.data && attributes?.notice_customer_type?.data?.length > 0){
                            notice_customer_type = attributes?.notice_customer_type?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.pic?.data && attributes?.pic?.data?.length > 0){
                            pic = attributes?.pic?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.privilege_category?.data){
                            privilege_category = {id: attributes?.privilege_category?.data?.id , ...attributes?.privilege_category.data.attributes}
                        }
                        if(attributes?.privilege_properties?.data && attributes?.privilege_properties?.data?.length > 0){
                            privilege_properties = attributes?.privilege_properties?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.privilege_sub_category?.data){
                            privilege_sub_category = {id: attributes?.privilege_sub_category?.data?.id , ...attributes?.privilege_sub_category.data.attributes}
                        }
                        if(attributes?.privilege_user_sub_type?.data && attributes?.privilege_user_sub_type?.data?.length > 0){
                            privilege_user_sub_type = attributes?.privilege_user_sub_type?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.user_condition_sub_types?.data && attributes?.user_condition_sub_types?.data?.length > 0){
                            user_condition_sub_types = attributes?.user_condition_sub_types?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        if(attributes?.user_profile?.data){
                            user_profile = {id: attributes?.user_profile?.data?.id , ...attributes?.user_profile.data.attributes}
                        }
                        try {
                            if(attributes?.unit?.data?.attributes){
                                const unitData = attributes?.unit?.data
                                let property = null
                                let unitType = null
                                try {
                                    if(unitData?.attributes?.property?.data){
                                        const propertyData = unitData?.attributes?.property?.data
                                        let project = null
    
                                        if(propertyData?.attributes?.aqi_response?.data){
                                            propertyData.attributes.aqi_response = propertyData?.attributes?.aqi_response?.data
                                        }
                                        if(propertyData?.attributes?.project?.data){
                                            const projectData = propertyData?.attributes?.project?.data
                                            let projectBrand = null
                                            if(projectData?.attributes?.project_brand?.data){
                                                const projectBrandData = projectData?.attributes?.project_brand?.data
                                                projectBrand = {id: projectBrandData?.id , ...projectBrandData?.attributes}
                                            }
                                            project = {id: projectData?.id , ...projectData?.attributes}
                                            project.project_brand = projectBrand
                                        }
                                        property = {id: propertyData?.id , ...propertyData?.attributes}
                                        property.project = project
                                    }
                                } catch (error) {
                                    property = null
                                }
                                try {
                                    if(unitData?.attributes?.unit_type?.data){
                                        const unitTypeData = unitData?.attributes?.unit_type?.data
                                        unitType = {id: unitTypeData?.id , ...unitTypeData?.attributes}
                                    }
                                } catch (error) {
                                    unitType = null
                                }
                                unit = {id: unitData?.id , ...unitData?.attributes}
                                unit.property = property
                                unit.unit_type = unitType
                            }
                        } catch (error) {
                            unit = null
                        }
                        const result = {id: item?.id , ...attributes}
                        result.createBy = createBy
                        result.updateBy = updateBy
                        result.unit = unit
                        result.content_post = content_post
                        result.coupon_group = coupon_group
                        result.coupons = coupons
                        result.localizations = localizations
                        result.notice_channel_types = notice_channel_types
                        result.notice_customer_type = notice_customer_type
                        result.pic = pic
                        result.privilege_category = privilege_category
                        result.privilege_properties = privilege_properties
                        result.privilege_sub_category = privilege_sub_category
                        result.privilege_user_sub_type = privilege_user_sub_type
                        result.user_condition_sub_types = user_condition_sub_types
                        result.user_profile = user_profile
                        return result
                    })
                    setListdata(resultList || [])
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    setLoadingTable(false)
                });
        } catch (error) {
            setLoadingTable(false)
        }
    }
    const getBadgeNumber = async (deleteStatus:any)=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${1}&pagination[page]=${1}`
            if(deleteStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${deleteStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${deleteStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            apiEndPoint += `&sort[id]=desc`
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data
                    if(dataLoad?.meta?.pagination?.total){
                        const total = dataLoad?.meta?.pagination?.total > 0 ? dataLoad?.meta?.pagination?.total : null
                        if(deleteStatus.toLowerCase() == 'y'){
                            setTrashBadgeNumber(total)
                        }
                        else{
                            setActiveBadgeNumber(total)
                        }
                    }
                }).catch((e) => {
                    console.log(e)
                });
        } catch (error) {
        }
    }

    useEffect(() => {
        if (!vPrivCate && !vSubCate && !vInputTitle && !vStatus && Identity_data) {
            getData()
        }
      }, [vPrivCate, vSubCate, vInputTitle, vStatus]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,keepDataStatus,Identity_data])

    useEffect(() => {
        if(Identity_data){
            getBadgeNumber('Y')
            getBadgeNumber('N')
        }
    }, [Identity_data])

    return <>
            
            <List 
                title={t("content-post-privilege.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("content-post-privilege.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={22}>
                        <Form
                            {...formProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Row gutter={8} style={{width: "100%"}}>
                                <Col className="gutter-row">
                                    <Form.Item name="properties" style={{width: "200px", marginRight: "0"}}>
                                        <Select 
                                            {...privCateSelectProps}
                                            placeholder="Select category"
                                            size="middle"
                                            style={{borderRadius: 20}}
                                            onChange={(e) => setPrivCate(e)}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item name="privilege_sub_category" style={{width: "200px", marginRight: "0"}}>
                                        <Select {...privCateSubSelectProps} placeholder="Select sub category" size="middle" style={{borderRadius: '20'}} onChange={(e) => setSubCate(e)} allowClear/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item name="input_title" style={{width: "200px", marginRight: "0"}}>
                                        <Input placeholder="Title (TH)" 
                                            value={vInputTitle} 
                                            onChange={e => {
                                                if(e.target.value.length > 0){
                                                    setInputTitle(e.target.value)
                                                }else{
                                                    setInputTitle(undefined)
                                                }
                                            }}
                                            allowClear
                                            style={{borderRadius: 5}}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item name="in" style={{width: "200px", marginRight: "0"}}>
                                        <Select placeholder={t("select.sel")}
                                            style={{ width: '100%' }}
                                            onSelect={(value: any) => {
                                                // console.log(value);
                                                setStatus(value);
                                            }}
                                            options={[
                                                {
                                                    label: "Public",
                                                    value: "PUBLIC",
                                                },
                                                {
                                                    label: "Draft",
                                                    value: "DRAFT",
                                                },
                                                {
                                                    label: "End",
                                                    value: "END",
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item style={{width: "100px", marginRight: "0"}}>
                                        <Button onClick={getData} htmlType="button" type="primary" style={{borderRadius: "5px"}}>
                                            <Icons.SearchOutlined /> Search
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row">
                                    <Form.Item style={{width: "100px", marginRight: "0"}}>
                                        <Button onClick={onReset} htmlType="reset" style={{borderRadius: "5px"}}>
                                            <Icons.UndoOutlined /> Reset
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    <Col span={2}>
                        <Form.Item style={{marginRight: "0", float: "right"}}>
                            <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                        </Form.Item>
                    </Col>
                </Row>

                <Form {...formProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={!tabPrivilege ? keepDataStatus : `${tabPrivilege}`}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={onStatusChange} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={activeBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={trashBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table 
                    dataSource={listData}
                    onChange={handleTableChange}
                    loading = {loadingTable}
                    bordered
                    size="middle"
                    rowKey = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        hideOnSinglePage: false,
                        current: metaPagination?.page || page,
                        pageSize: metaPagination?.pageSize || pageSizeChange,
                        total: metaPagination?.total,
                        // metaPagination?.pageCount
                        position:["bottomRight"]
                    }}  
                >
                    {/* <Table.Column width={30} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                    {/* <Table.Column align="center" width={30} dataIndex="pic" title="  " 
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {
                                    value ?
                                        <Avatar.Group>
                                            { 
                                                value.map((item:any,index:any)=> {
                                                    // console.log('item', item)
                                                    return <>
                                                        <Avatar src={item.url}/>
                                                    </>
                                                })
                                            }
                                        </Avatar.Group>
                                    :
                                        <>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} />
                                        </>
                                }
                            </>
                        }}
                    /> */}

                    <Table.Column key={'[start_date]'} title="PUBLIC DATE" width={120} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.start_date < b?.start_date) {return -1}
                            if (a?.start_date > b?.start_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record ? record?.name : "-"} /> */}
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                                <TextField value={record?.start_date? <>{dayjs(record?.start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.end_date).format("DD/MM/YYYY")}</> :"-"} />

                            </>
                        }}
                    />


                    {/* <Table.Column dataIndex="seq" width={30} title="SEQUENCES" align="center"
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                <TextField value={record?.seq ? record?.seq : "-"} />
                            </>
                        }}
                    /> */}

                    <Table.Column key={'[privilege_promotion]'} title="PRIVILEGE" width={35} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.privilege_promotion < b?.privilege_promotion) {return -1}
                            if (a?.privilege_promotion > b?.privilege_promotion) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record',record)
                            return <>
                                {/* <TextField value={record ? record?.name : "-"} /> */}
                                {
                                    // record.privilege_coupon ?
                                    record.privilege_promotion ?
                                        <>
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"blue"}> ตามโค้ดคูปอง / สิทธิ </Tag>
                                        </>
                                    :
                                        record.privilege_event ? <> <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}> กิจกรรม </Tag> </> : "-"
                                }
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="name" title="TITLE" width={250} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record.name ? record?.name : "-"} />
                            </>
                        }}
                    /> */}

                    <Table.Column 
                        key={'[name]'}
                        // title={t("content-post-news.col-content-title")} 
                        title="CONTENT TITLE (TH)" 
                        width={300}
                        align="center"
                        className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.localizations[0]?.name < b?.localizations[0]?.name) {return -1}
                            if (a?.localizations[0]?.name > b?.localizations[0]?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {
                                    record ? 
                                        record.locale == "th" ?
                                            <TextField value={record ? record?.name : "-"} />
                                        :record ?
                                            record.localizations.length > 0 ?
                                                record.localizations[0].locale = "th" ?
                                                    <TextField value={record ? record.localizations[0].name : "-"} />     
                                                : <>-</>   
                                            :<>-</>
                                        :<>-</>
                                    :<>-</>
                                }                            
                            </>
                        }}
                    />

                    <Table.Column key={'[localizations][name]'} title="CONTENT TITLE (EN)" width={300} align="center" className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.localizations[0]?.name < b?.localizations[0]?.name) {return -1}
                            if (a?.localizations[0]?.name > b?.localizations[0]?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {
                                    record ? 
                                        record.locale == "en" ?
                                            <TextField value={record ? record?.name : "-"} />
                                        :record ?
                                            record.localizations.length > 0 ?
                                                record.localizations[0].locale = "en" ?
                                                    <TextField value={record ? record.localizations[0].name : "-"} />     
                                                : <>-</>   
                                            :<>-</>
                                        :<>-</>
                                    :<>-</>
                                }
                                
                            </>
                        }}
                    />


                    <Table.Column key={'[privilege_category][pc_name]'} title="CATEGORY" width={50} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.privilege_category?.pc_name < b?.privilege_category?.pc_name) {return -1}
                            if (a?.privilege_category?.pc_name > b?.privilege_category?.pc_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record.privilege_category ? record?.privilege_category.pc_name : "-"} />
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                            </>
                        }}
                    />

                    <Table.Column key={'[privilege_sub_category][psc_name]'} title="SUB CATEGORY" width={150} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.privilege_sub_category?.psc_name < b?.privilege_sub_category?.psc_name) {return -1}
                            if (a?.privilege_sub_category?.psc_name > b?.privilege_sub_category?.psc_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record.privilege_sub_category ? record?.privilege_sub_category.psc_name : "-"} />
                                {/* <TextField value={record ? record?.name : "-"} /> */}
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                            </>
                        }}
                    />

                    {/* <Table.Column width={30} title="SEQUENCES" align="center"
                     sorter={(a:any, b:any) => {
                        const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                        setDatafield("seq")
                        setDataorder(sorttest)
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                <TextField value={record?.seq ? record?.seq : "-"} />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="name" title="CONDITION" width={150} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record ? record?.name : "-"} />
                                <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/>
                            </>
                        }}
                    /> */}

                    <Table.Column key={'[engagement]'} title="ENGAGEMENT" width={150} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.engagement < b?.engagement) {return -1}
                            if (a?.engagement > b?.engagement) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {/* <TextField value={record ? record?.name : "-"} /> */}
                                <TextField value={record? record?.engagement : "-"} />
                                {/* <TextField value={value ? value : "-"} /> */}
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                            </>
                        }}
                    />

            

                    

                    <Table.Column key={'[event_start_date]'} title="EVENT DATE" width={120} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.event_start_date < b?.event_start_date) {return -1}
                            if (a?.event_start_date > b?.event_start_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record?.privilege_event_start_date? <>{dayjs(record?.privilege_event_start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.privilege_event_end_date).format("DD/MM/YYYY")}</> :"-"} /> */}
                                <TextField value={record?.event_start_date? <>{dayjs(record?.event_start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.event_end_date).format("DD/MM/YYYY")}</> :"-"} />

                            </>
                        }}
                    />

                    <Table.Column key={'[keep_coupon_start_date]'} title="PERIOD DATE" width={120} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.keep_coupon_start_date < b?.keep_coupon_start_date) {return -1}
                            if (a?.keep_coupon_start_date > b?.keep_coupon_start_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record ? record?.name : "-"} /> */} 
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                                {
                                    record?.keep_coupon_start_date ?
                                        <>
                                            <TextField value={record?.keep_coupon_start_date? <>{dayjs(record?.keep_coupon_start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.keep_coupon_end_date).format("DD/MM/YYYY")}</> :"-"} />
                                        </>
                                    :
                                        record?.use_coupon_start_date ? <TextField value={record?.use_coupon_start_date? <>{dayjs(record?.use_coupon_start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.use_coupon_end_date).format("DD/MM/YYYY")}</> :"-"} /> : <>-</>
                                }
                            </>
                        }}
                    />

              
                    
                    <Table.Column key={'[status]'} title="STATUS" width={80} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.status < b?.status) {return -1}
                            if (a?.status > b?.status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record ? record?.name : "-"} /> */}
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                                <TextField value={record?.status ? record?.status : "-"}  />

                            </>
                        }}
                    />

                    <Table.Column key={'[createBy][first_name]'} title="CREATE BY"  align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record);
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[createdAt]'} title='CREATED DATE'  align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createdAt? dayjs(record?.createdAt).format("DD-MM-YYYY HH:mm") :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[updateBy][first_name]'} title={t("content-post-activity.col-updateby")}  align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log('record', record);
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                                {/* <TextField value={record?.updateBy ? <>{record?.updateBy[0].first_name}{' '}{record?.updateBy[0].last_name}</> : "-"} /> */}
                            </>
                        }}
                    />

                    <Table.Column key={'[updatedAt]'} title={t("content-post-activity.col-lastupdate")}  align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updatedAt? dayjs(record?.updatedAt).format("DD-MM-YYYY HH:mm") :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column<any>
                        width={50}
                        title={t("content-post-activity.col-action")} 
                        align    = "center"
                        dataIndex= "actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                ></ShowButton>
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id, record?.localizations[0].id) }} danger></Button>
                                        </>
                                    :
                                        <>
                                            <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id, record?.localizations[0].id)}} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}