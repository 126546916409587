/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Divider, Form, Input, List, Row, useForm, TextField } from "@pankod/refine-antd";
import { IResourceComponentsProps, useCreate, useGetIdentity, useNavigation, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IvehicleBrand } from "interfaces";
import Moment from 'moment';

export const VehicleTEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    // const { formProps, saveButtonProps } = useForm<IvehicleBrand,HttpError,{}>();
    const { formProps, saveButtonProps }    = useForm<IvehicleBrand>({redirect:"list"});
    const { queryResult } = useShow<IvehicleBrand>({metaData: {
        populate: '*'
        }, });
    const { data } = queryResult;
    const record = data?.data;

    // const { replace} = useNavigation();
    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";
    
    //const API_URL = useApiUrl();

    function updateUserUpdatedBy(){
        // mutate({
        //     resource    : end_point,
        //     id          : queryResult.data?.data.id+"",
        //     // values      : vCurUserUpdatedBy, 
        //     values: { user_updated_by: user?.id, deleteStatus: 'N' },
        // });

        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'vehicle-type',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
        // replace("");
    }
     
    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("vehicle.bc_title_t_edit")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title_t_edit")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            updateUserUpdatedBy();
            console.log(user_data.user_profile.id)
            values.updateBy = user_data.user_profile.id
            return ( 
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
            );
         }}>
            <Row gutter={[16, 24]}>
            <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("vehicle.type-code")} name="type_code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter type code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("vehicle.type-name_th")} name="type_nameTH"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter type name(th).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("vehicle.type-name_en")} name="type_nameEN"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter type name(en).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={16}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={8}>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>   
    );
};