/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog, IProperties } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";

let listProps: any = [];
export const RepairFacilityEmailGroupList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity();
    const { create, edit } = useNavigation();
    const [dataList, setDataList] = useState<any>([]);

    const [Listdata, setListdata] = useState<any>([]);
    const [searchProp, setsearchProp] = useState<any>();
    const [searchTopic, setsearchTopic] = useState<any>();

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);


    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "email-groups",
        metaData: {
            populate: [
                '*',
                'property',
                'createBy',
                // 'appointmentType',
                // 'property',
                // 'project',
                // 'user_profile',
                // 'staff_has_properties',
                // 'appointment_time_slot',
                // 'reschedule_time_slot',
                'updateBy',
                // 'unit',
            ]
        },
        permanentFilter:[
            {
                field: 'type',
                operator: 'eq',
                value: 'Repair-facility'
            },
            {
                field: "property][id]",
                operator: prop_list ? "in" : "eq",
                value: prop_list ? prop_list: prop_id,
                // value: prop_id,
            }
        ],
        pagination:{
            pageSize: 100,
        },
        queryOptions: {
          onSuccess: (data) => {
            let dataLoad: any = data?.data;
            console.log(">>> dataLoad",dataLoad)
            // dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))

            // if(prop_id){
            //     dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            // }

            listProps = dataLoad;
            setListdata(dataLoad)
            // setDataList(dataLoad)
          },
        },
    });

    const { selectProps: selproperty} = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const columns:any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'Property',
            // dataIndex: 'property',
            // key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.property?.property_name < b?.property?.property_name) {return -1}
                if (a?.property?.property_name > b?.property?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <div>{record ? record?.property !== null ? record?.property?.property_name : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
                    </>
                );
            },
        },
        {
            title: 'EMAIL',
            // dataIndex: 'email',
            // key: 'email',
            align: 'center',
            className: "table_colmn_custom_left_value",
            //sorter: (a:any, b:any) => a.inspec_team.first_name.localeCompare(b.inspec_team.first_name),
            render: (val:any, record:any) => {
                // return (
                //     <>
                //         <div>{record ? record.email !== null ? record.email : '-': '-'}</div>
                //         {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
                //     </>
                // );

                {
                    let splitEmail = record.email.split(",");
                    return <>
                         {
                            splitEmail.length !== 0 ?
                                splitEmail.map((data:any,index:any) => {
                                    // console.log('data : ', data);
                                    return <>
                                        <Tag className="tag_post"><TextField value={data ? data : <></> }  /></Tag>
                                    </>
                            })
                            : <>-</>
                        }
                    </>
                }
            },
        },
        {
            title: 'CREATED BY',
            // dataIndex: 'createBy',
            key: 'createBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                </>
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'createdAt',
            width: 150,
            key: 'createdAt',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.createdAt < b.createdAt) {return -1}
                if (a.createdAt > b.createdAt) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'updateBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'Last Update',
            dataIndex: 'updatedAt',
            width: 150,
            key: 'updatedAt',
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.updatedAt < b.updatedAt) {return -1}
                if (a.updatedAt > b.updatedAt) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 100,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            // onClick={() => edit("mjc-email-group", record.property.id + "_" + record.id )}
                            onClick={() => edit("repair-facility-email-group", record.id)}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                        />

                        {/* <EditButton
                            hideText
                            size="small"
                            recordItemId={record.id}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                        /> */}
                        {/* <CreateButton id="create_btn" onClick={()=>edit("notification", )} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }></CreateButton> */}
                    </>
                );
            },
        },
    ];

    function onsetProp(value: any){
        setsearchProp(value);
        if(value){
            onSearchtable(listProps, searchTopic, value);
        }else{
            onSearchtable(listProps, searchTopic, value);
        }
    }

    function onsetEmail(value: any){
        setsearchTopic(value);
        if(value){
            onSearchtable(listProps, value, searchProp);
        }else{
            onSearchtable(listProps, value, searchProp);
        }
    }

    function onSearchtable(data: any, email: any, prop:any){
        let newData:any = data;
        if(email || prop){
            newData = prop ? newData?.filter((e:any)=> {return ( e?.property?.id === Number(prop))}): newData;

            let mergeData: any = [];
            for (let index = 0; index < newData?.length; index++) {
                let spltemail: any = newData[index]?.email.split(",")

                if(email && spltemail?.length > 0){
                    let checkEmail: any = spltemail?.find((e:any)=> {return ( e?.toLowerCase().includes(email?.toLowerCase()))});
                    if(checkEmail){
                        mergeData.push(newData[index])
                    }
                }
            }

            if(mergeData?.length > 0){
                newData = mergeData;
            }else if(email){
                newData = [];
            }

            setListdata((pre: any) => newData);
        }else{
            setListdata((pre: any) => newData);
        }
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    return <>
        <List title="">
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout = "inline" style={{marginBottom:"1%"}}>
                        <Form.Item>
                            <Select 
                                placeholder="PROPERTY" 
                                allowClear 
                                style={{width: "220px"}}
                                {...selproperty}
                                onChange={(e:any)=> {
                                    if(e){
                                        onsetProp(e)
                                    }else{
                                        onsetProp(undefined)
                                    }
                                }}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Input 
                                placeholder="EMAIL"
                                allowClear
                                style={{borderRadius: 5}}
                                onChange={e => {
                                    if(e.target.value.length > 0){
                                        onsetEmail(e.target.value);
                                    }else{
                                        onsetEmail(undefined)
                                    }
                                }}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton 
                                id="create_btn" 
                                onClick={()=>create("repair-facility-email-group")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>

            <Table 
                dataSource={Listdata} 
                columns={columns}
                // onChange={onChange} 
                // pagination={{ size: "small", position: [ "bottomRight"] }} 
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    // showTotal: showTotal, 
                    pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                    showSizeChanger: true, // Optional - Show page size changer dropdown
                    // showQuickJumper: true, // Optional - Show quick jumper input
                    // pageSize: 10, // Optional - Default page size
                    pageSize: pageSize,
                    onChange: handlePageSizeChange,
                }}
                scroll={{ x: 1500 }}
            />
        </List>
    </>
}