/* eslint-disable */
import { List, Table, TextField, EditButton, Tag, TableProps, Row, Button, Icons, notification, Modal, Checkbox, Form, useSelect, Col, Select, Input } from "@pankod/refine-antd";
import { IResourceComponentsProps, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import { majorCareUrl } from "../../../constants";
import dayjs from "dayjs";
import { CheckCircleOutlined, QuestionOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";


{/* 
    MAIN STATUS

    649859ca515b80b78fe13b84 = รอดำเนินการ
    649859e7515b80b78fe13b86 = รับเรื่องแจ้งซ่อม
    649859f8515b80b78fe13b88 = นัด วัน/เวลา ประเมินหน้างาน
    64985a01515b80b78fe13b8a = เข้าประเมิน
    64985a0f515b80b78fe13b8c = เข้าประเมิน-เกินกำหนด
    64985a29515b80b78fe13b8e = ประเมินเสร็จ
    64985a35515b80b78fe13b90 = นัด วัน/เวลา เข้าซ่อม
    64985a3c515b80b78fe13b92 = เข้าซ่อม
    64985a47515b80b78fe13b94 = เข้าซ่อม-เกินกำหนด
    64985a67515b80b78fe13b96 = เลื่อนนัดจากลูกค้า *
    64985ae5515b80b78fe13ba2 = เลื่อนนัดจากนิติ *
    64985b3b515b80b78fe13bac = อุปกรณ์อยู่ระหว่างการผลิต *
    64985bbe515b80b78fe13bb6 = รออนุมัติงานซ่อม
    64985bc6515b80b78fe13bb8 = อนุมัติงานซ่อม
    64985bcf515b80b78fe13bba = ปิดงาน *

    64a7bf07785d4236922eaff5 = ไม่อนุมัติโดยหัวหน้างาน
    64a7bf23785d4236922eaff6 = อนุมัติโดยหัวหน้างาน
*/}

export const RepairMdpcHodWaitApproveJobList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    // let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    // let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { create, edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(9);
    const [dataList, setDataList] = useState<any>([]);
    const [dataDetailList, setDataDetailList] = useState<any>([]);

    const dataBlank:any = []

    const [form] = Form.useForm();
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [selProp, setselProp] = useState<any>(undefined);
    const [nameSearch, setnameSearch] = useState<any>("");
    const [Unit, setUnit] = useState<any>(undefined);
    const [SearchStat, setSearchStat] = useState<any>(undefined);
    const [keepCustomerName, setKeepCustomerName] = useState<any>();
    const [keepDataDetail, setKeepDataDetail] = useState<any>(undefined)
    const [isLoading, setIsLoading] = useState<any>(true);

    const option_search:any = [
        {
            label: "เลื่อนนัดจากลูกค้า",
            value: "64985a67515b80b78fe13b96",
        },
        {
            label: "เลื่อนนัดจากนิติ",
            value: "64985ae5515b80b78fe13ba2",
        },
        {
            label: "อุปกรณ์อยู่ระหว่างการผลิต",
            value: "64985b3b515b80b78fe13bac",
        },
        {
            label: "ปิดงาน",
            value: "64985bcf515b80b78fe13bba",
        },
    ];

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    const { selectProps: SelUnit } = useSelect<any>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 10000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "in",
                value       : selProp?.id
            },
        ]
    });

    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];
        if(mode == "S"){

            // search job no
            newData = nameSearch !== "" ? dataDetailList?.filter((e:any)=> e?.job_obj[0].job_id?.includes(String(nameSearch))) : newData;
            // search prop
            newData = selProp !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id) : newData;
            // search unit
            newData = Unit !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0].request_by?.unitDetail?.unit_id == Unit?.id) : newData;
            // search stat
            newData = SearchStat !== undefined ? dataDetailList?.filter((item:any)=> item?.job_obj[0]?.status_id == SearchStat?.value) : newData;
            // search CustomerName
            newData = keepCustomerName !== undefined ? dataDetailList?.filter((item:any) => item?.job_obj[0]?.request_by?.attributes?.first_name?.includes(String(keepCustomerName)) ||
            item?.job_obj[0]?.request_by?.attributes?.last_name?.includes(String(keepCustomerName))
            ) : newData
            // search detail
            newData = keepDataDetail !== undefined ? dataDetailList?.filter((item:any) => item?.zone_obj[0]?.zone_name?.includes(String(keepDataDetail)) ||
            item?.category_obj[0]?.category_name?.includes(String(keepDataDetail)) ||
            item?.category_detail_obj[0]?.category_detail_name.includes(String(keepDataDetail))
            ) : newData

            setDataDetailList(newData);
        }else if(mode == "R"){
            form?.resetFields();
            setselProp(undefined);
            setKeepCustomerName(undefined)
            setKeepDataDetail(undefined);
            setUnit(undefined)
            refreshDataSource();
            setnameSearch("");
            setSearchStat(undefined)

        }
    }



    const getDataK = async ()=>{

        // GET JOB DATA

        // GET JOB DETAIL DATA
        let res_data = await axios.get(
            // majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`, 
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStat?stat=64985bbe515b80b78fe13bb6&page=1&limit=500`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad ', dataLoad)
                // console.log('prop_id', prop_id)
                if(prop_list !== undefined){
                    console.log('case 1')
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    console.log('case 2')
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }
                // รอหัวหน้าอนุมัติงานซ่อม เห็นรายการทั้งหมดตามสิทธิ์ของ Property ที่ตั้งไว้ แสดงสถานะรายการที่รอหัวหน้าอนุมัติ ดูจากรายการที่  hod_approve: true สามารถ edit รายการได้
                // ====================================================================================================================================
                // const xx = dataLoad.filter((e:any) => (e.job_obj[0]?.status_id === "64985bbe515b80b78fe13bb6") && e.job_obj[0].hod_approve === true );
                const xx = dataLoad.filter((e:any) => (e.job_obj[0].hod_approve === true ) );
                // console.log('xx', xx)
                // ====================================================================================================================================


                // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" || e.job_obj[0]?.status_id == "64985a67515b80b78fe13b96" || e.job_obj[0]?.status_id == "64985ae5515b80b78fe13ba2" ) 
                // let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86") 
                // let xx = dataLoad.filter((e:any)=> e.job_obj[0]?.status_id == "649859e7515b80b78fe13b86" ) 
                for (let index = 0; index < xx.length; index++) {
                    dataDetailList.push(xx[index]);
                }

                dataDetailList.reverse();
                setIsLoading(false)

            });
    }
    // console.log('dataList', dataList)
    console.log('dataDetailList', dataDetailList)
    const { confirm } = Modal;
    const successNoti = (stat:any, msg:any) => {
        notification.success({
            // message: stat + ' : ' + msg.TH,
            message: msg,
            // description: msg,
            duration: 3,
        });
    };

    useEffect(() => {
        if (countInterval <= 1) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 0){
                    getDataK();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    async function updateStatus(id:any, val:any){
        // console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.patch(
            majorCareUrl + `/majorcare/repairJobMDPC/updateRepairjob?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
        setDataDetailList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            width: 180,
            key: 'job_id',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.job_id < b?.job_obj[0]?.job_id) {return -1}
                if (a?.job_obj[0]?.job_id > b?.job_obj[0]?.job_id) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            width: 150,
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name < b?.job_obj[0]?.request_by?.unitDetail?.property_name) {return -1}
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name > b?.job_obj[0]?.request_by?.unitDetail?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UNIT NO.',
            dataIndex: 'unit',
            key: 'unit',
            width: 120,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name < b?.job_obj[0]?.request_by?.unitDetail?.unit_name) {return -1}
                if (a?.job_obj[0]?.request_by?.unitDetail?.unit_name > b?.job_obj[0]?.request_by?.unitDetail?.unit_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.unit_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'รายละเอียด',
            dataIndex: 'details',
            key: 'details',
            width: 250,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.category_obj[0]?.category_name < b?.category_obj[0]?.category_name) {return -1}
                if (a?.category_obj[0]?.category_name > b?.category_obj[0]?.category_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            width: 200,
            key: 'cus_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.attributes?.first_name < b?.job_obj[0]?.request_by?.attributes?.first_name) {return -1}
                if (a?.job_obj[0]?.request_by?.attributes?.first_name > b?.job_obj[0]?.request_by?.attributes?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].request_by.attributes !== undefined ? record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            width: 200,
            key: 'create_date',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) {return -1}
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'APPOINTMENT DATE',
            dataIndex: 'appoint_start',
            key: 'appoint_start',
            width: 200,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.appoint_start < b?.job_obj[0]?.appoint_start) {return -1}
                if (a?.job_obj[0]?.appoint_start > b?.job_obj[0]?.appoint_start) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <div>{record ? record.job_obj[0].appoint_start !== null ? dayjs(record.job_obj[0].appoint_start).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'ช่างผู้รับผิดชอบ',
            // dataIndex: 'inspec_name',
            width: 200,
            key: 'inspec_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name < b?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name) {return -1}
                if (a?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name > b?.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        <div>
                            {
                                record ?
                                    record.job_obj[0]?.assign !== null ?
                                        record.job_obj[0]?.assign?.attributes?.user_profile ?
                                            record.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.first_name + ' ' +  record.job_obj[0]?.assign?.attributes?.user_profile?.data?.attributes?.last_name
                                        : '-'
                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'วันส่งอนุมัติ',
            dataIndex: 'send_approve_date',
            width: 200,
            key: 'send_approve_date',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_trails_obj[0]?.create_date < b?.job_trails_obj[0]?.create_date) {return -1}
                if (a?.job_trails_obj[0]?.create_date > b?.job_trails_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                console.log('record', record)
                console.log('record.job_obj[0].job_trails_obj', record.job_trails_obj)
                return (
                    <>
                        <div>{record ? record.job_trails_obj !== null ? dayjs(record.job_trails_obj[record.job_trails_obj.length - 1]?.create_date).format("YYYY-MM-DD HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.create_date.localeCompare(b.create_date),
        },
        {
            title: 'CREATED BY',
            dataIndex: 'create_by',
            key: 'create_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_by?.first_name < b?.job_obj[0]?.create_by?.first_name) {return -1}
                if (a?.job_obj[0]?.create_by?.first_name > b?.job_obj[0]?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.create_by ? record?.job_obj[0]?.create_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.create_by?.last_name ? record?.job_obj[0]?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'UPDATE BY',
            dataIndex: 'update_by',
            key: 'update_by',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.update_by?.first_name < b?.job_obj[0]?.update_by?.first_name) {return -1}
                if (a?.job_obj[0]?.update_by?.first_name > b?.job_obj[0]?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (updateBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.update_by ? record?.job_obj[0]?.update_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.update_by?.last_name ? record?.job_obj[0]?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'Last Update',
            dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => a.job_obj[0]?.update_date.localeCompare(b.job_obj[0]?.update_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].update_date !== null ? dayjs(record.job_obj[0].update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            width: 150,
            fixed: 'right',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.status_name < b?.job_obj[0]?.status_name) {return -1}
                if (a?.job_obj[0]?.status_name > b?.job_obj[0]?.status_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>
                            <Tag className="tag_mjc_stat_wait_inspect">{record.job_obj[0].status_name}</Tag>
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            record.job_obj[0].is_active === true ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        style={{marginRight:'5px'}}
                                        onClick={() => edit("repair-mdpc-hod-view-job", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record._id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    return <>
        <Row>
            <Col span={24}>
                <Form layout="vertical" 
                    onFinish={(values:any) => {}}
                    form = {form}
                >
                    <Row gutter={8} style={{width: "100%"}}>
                        <Col className="gutter-row">
                            <Form.Item
                                name="property"
                                // label="Search"
                                style={{width: "200px", marginRight: "0"}}
                            >
                                <Select 
                                    placeholder="Property" 
                                    {...selProperty} allowClear 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    suffixIcon={<Icons.SearchOutlined/>}
                                    onChange={(value:any, record:any) => {
                                        setselProp({id:value, label: record?.label})
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item
                                
                                name="unit"
                                style={{width: "120px", marginRight: "0"}}
                            >
                                <Select {...SelUnit}
                                    placeholder="Unit" 
                                    size="middle" 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    // onSelect={(e: any)=>setUnitID(e)}
                                    suffixIcon={<Icons.SearchOutlined/>}
                                    onChange={(value:any, record:any) => { 
                                        setUnit({id:value, label: record?.label})
                                    }}
                                    onClear={()=> {
                                        setUnit(undefined);
                                        form.resetFields(["unit"]);
                                    }}
                                    allowClear
                                    showSearch
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row">
                            <Form.Item name="template_name"  style={{width: "150px", marginRight: "0"}}>
                                <Input 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    placeholder="Job No." 
                                    onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                    suffix={<Icons.SearchOutlined/>} 
                                allowClear/>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row">
                            <Form.Item name="cust_name"  style={{width: "180px", marginRight: "0"}}>
                                <Input 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    placeholder="Customer name" 
                                    onChange={(e) => setKeepCustomerName(e.target.value)} 
                                    suffix={<Icons.SearchOutlined/>} 
                                    allowClear
                                />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row">
                            <Form.Item name="detail"  style={{width: "180px", marginRight: "0"}}>
                                <Input 
                                    style={{borderRadius: 5, position:'relative'}} 
                                    placeholder="รายละเอียด" 
                                    onChange={(e) => setKeepDataDetail(e.target.value)} 
                                    suffix={<Icons.SearchOutlined/>} 
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                        
                        <Col className="gutter-row">
                            <Form.Item >
                                <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataDetailList,"S", "xxxxxx")}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col  className="gutter-row">
                            <Form.Item >
                                <Button 
                                    onClick={() => {
                                        onSearchtable(dataDetailList,"R", "xxxx")
                                    }} 
                                    style={{borderRadius: "5px"}}
                                    icon={<Icons.UndoOutlined />}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>

        <Table 
            dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank} 
            columns={columns} 
            loading={isLoading}
            onChange={onChange} 
            pagination={{
                size: "small",
                position: ['bottomRight'],
                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                showSizeChanger: true, // Optional - Show page size changer dropdown
                pageSize: pageSize,
                onChange: handlePageSizeChange,
            }}
            scroll={{ x: 2800 }}
        />
        <Row style={{float:'right', paddingTop:'10px'}}>
            <Col span={24} >
                {t("repair-stat.title")} {dataDetailList?.length}
            </Col>
        </Row>
    </>
}