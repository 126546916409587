/* eslint-disable */
// SEARCH KEYWORDS
// keyDYNAMIC_FORM  = dynamic time slot form

import { Breadcrumb,  Card, Checkbox, Col, Create, DatePicker, Divider, Form, Input, List, Radio, RadioChangeEvent, Row, Select, Space, Switch, Upload, useForm, useSelect,Image,Empty, RcFile, message, Modal, Tag } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import { IEventlog, IFacilityType, IFacility, IProperties, IFacilityCondition, IFacilityTimeSlot, IFacilityRoundType, IUnitType, IFacilityUnitType } from "interfaces";
import { useState } from "react";
import moment, { isMoment } from 'moment';
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, TimePicker } from "antd";
import "./css/style.css";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

import type { CheckboxValueType } from 'antd/es/checkbox/Group';

let ConditionData:any = [];
let UnitTypeData:any = [];
export const FacilitiesCreate: React.FC<IResourceComponentsProps> = () => {

    const format = 'HH:mm';
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { TextArea } = Input;
    const t = useTranslate();
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: CreateFaciTimeSlot} = useCreate<IFacilityTimeSlot>();
    const { mutate: CreateFaciUnitType} = useCreate<IFacilityUnitType>();
    const log_endpoint = "even-logs";

    const [vProperty, setProp] = useState<any>();
    const [vSetJongType, setJongType] = useState();
    const [vSetNoti, setNoti] = useState();
    const [vSetWait, setWait] = useState();
    const [vSetCCJong, setCCJong] = useState();
    const [vSetSitJong, setSitJong] = useState();
    
    const [vSetJongF, setJongF] = useState();

    const [swJong, setswJong] = useState("f");
    const [swCheckin, setswCheckin] = useState("f");
    const [swStdate, setswStdate] = useState("t");
    const [swSitJong, setswSitJong] = useState("f");
    const [vIsRequired, setIsRequired] = useState<boolean>();
    const [inputJongType, setinputJongType] = useState('');
    const [inputBooking, setinputBooking] = useState('');
    const [inputJongFD, setinputJongFD] = useState('');
    const [inputJongFH, setinputJongFH] = useState('');
    const [inputJongFM, setinputJongFM] = useState('');

    const [inputCbfTime, setinputCbfTime] = useState('');
    const [inputCafTime, setinputCafTime] = useState('');
    const [inputCincode, setinputCincode] = useState('');

    const [inputNoti, setinputNoti] = useState('');
    const [inputNotimsg, setinputNotimsg] = useState('');

    const [inputWaitQ, setinputWaitQ] = useState('');
    const [inputCCJong, setinputCCJong] = useState('');

    const [inputRes_userR, setinputRes_userR] = useState<any>(1);
    const [inputRes_unitR, setinputRes_unitR] = useState<any>(1);
    
    const [vDateOP, setDateOP] = useState<any>("");
    const [ vInputUnitType, setInputUnitType ] = useState<any>([]);
    const [ vSOpdate, setSOpdate ] = useState<any>("");
    const [ vEOpdate, setEOpdate ] = useState<any>("");
    const [ vOpTime, setOpTime ] = useState<any>([]);
    const [ vClTime, setClTime ] = useState<any>([]);
    const [dislUnit, setDislUnit] = useState(true);
    const [ vCondition, setCondition ] = useState<any>([]);
    const [vCountZero, setCountZero] = useState<any>()
    const [ vNoteRemark, setNoteRemark] = useState<any>();

    // Dynamic Facility Use Time
    //
    // โปรดอ่าน READ ME BRO ที่ต้องทำเพิ่ม
    // 
    //  done 1. ตอนกดเลือก ถ้า round ซ้ำ ให้ลบของเก่าออกจาก vTimeSlot ด้วย
    //  done 2. ตอนกด save ที่ onMutationSuccess ให้ merge array ตัวที่ name: (round) ซ้ำกัน เข้าด้วยกัน
    // 3. กรณีที่กดลบ round นั้น ๆ ให้ลบออกจาก vTimeSlot ด้วย
    const [ vTimeSlotTempTEST, setTimeSlotTempTEST ]    = useState<any>([]);
    const [ vTimeSlotTEST, setTimeSlotTEST ]    = useState<any>([]);

    const { form, formProps, saveButtonProps } = useForm<IFacility,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource    : log_endpoint,
                values      : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(), 
                    log_event               : "Create",
                    menu_event              : 'facilities',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false,
            });

            if(vTimeSlotTEST.length > 0){
                removeAndSortArr();
                for(let i=0 ; i <= vTimeSlotTEST.length -1; i++){
                    CreateFaciTimeSlot({
                        resource    : 'facility-time-slots',
                        values      : {
                            time_start  : vTimeSlotTEST[i].time_start + ":00.000", 
                            time_end    : vTimeSlotTEST[i].time_end + ":00.000",
                            name        : "round " + vTimeSlotTEST[i].name,
                            facility    : recordData.data.id,
                            createBy    : user_data.user_profile.id
                        },
                        successNotification : false,
                    }
                    );
                }
            }

            // console.log('vInputUnitType onMutationSuccess >>>>>>> ', vInputUnitType)

            if(vInputUnitType.length > 0){
                for(let i=0 ; i <= vInputUnitType.length -1; i++){
                    
                    CreateFaciUnitType({
                        resource    : 'facility-unit-types',
                        values      : {
                            unit_type               : vInputUnitType[i].unit_type, 
                            facility_round_type     : vInputUnitType[i].facility_round_type,
                            limit_round             : vInputUnitType[i].limit_round,
                            // limit_round             : parseInt(vInputUnitType[i].limit_round),
                            facility                : recordData.data.id,
                            createBy                : user_data.user_profile.id
                        },
                        successNotification : false,
                    })
                }
            }
        },
    });

    const condition = useList<IFacilityCondition>({ 
        resource: "facility-conditions",
        metaData: {
            populate: '*'
        },
        
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "deleteStatus",
                    operator: "eq",
                    value   : "N"
                },
            ],
            pagination:{current: 1, pageSize: 100}
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data
            ConditionData = dataLoad
        },}
    });

    const unitType = useList<IUnitType>({
        resource: "unit-types",
        metaData: {
            populate: '*'
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field   : "property][id]",
                    operator: "eq",
                    value   : vProperty + ""
                },
            ]
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data
            UnitTypeData = dataLoad
        },}
    });

    const { selectProps: Selprop } = useSelect<IProperties>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: SelFaciliType } = useSelect<IFacilityType>({
        resource    : "facility-types",
        optionLabel : "name",
        optionValue : "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: SelFaciliRType } = useSelect<IFacilityRoundType>({
        resource    : "facility-round-types",
        optionLabel : "name",
        optionValue : "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const warning = () => {
        
        for(let i=0; i<= vInputUnitType.length -1; i++){
            let field = "kSelectRound_" + i
            form.resetFields([field])
        }

        Modal.warning({
          title: <span>{t("msg-facility-booking.title-warning")}</span>,
          content: <span>{t("msg-facility-booking.content-warning")}</span>,
        });
    };

    const count_booking = () => {
        let count = []
        if(parseInt(inputJongType) !== null && parseInt(inputJongType) !== undefined && parseInt(inputJongType) > 0){
            for (var i=0; i < parseInt(inputJongType) ; i++) {
                count.push(<Option value={i + 1} >{i + 1}</Option>)
            }
            return count
        } 
    }

    const opt_max_user = () => {
        let count = []
        for (var i=0; i < 5 ; i++) {
            count.push(<Option value={i + 1} >{i + 1}</Option>)
        }
        return count
    }

    console.log(">>> vIsRequired",vIsRequired)

    const onChangeProp = ({ target: { value } }: RadioChangeEvent) => {
        setJongType(value);
        // if(vIsRequired == undefined){
        //     if(value == 1){
        //         setIsRequired(false)
        //     }
        // }else{

        // }
        if(value == 1){
            setIsRequired(false)
        }else{
            setIsRequired(undefined)
        }
    };

    const onChangeNoti = ({ target: { value } }: RadioChangeEvent) => {
        setNoti(value);
    };

    const onChangeWait = ({ target: { value } }: RadioChangeEvent) => {
        setWait(value);
    };

    const onChangeccJong = ({ target: { value } }: RadioChangeEvent) => {
        setCCJong(value);
    };

    const onChangeJongF = ({ target: { value } }: RadioChangeEvent) => {
        setJongF(value);
    };

    const onChangeSitJong = ({ target: { value } }: RadioChangeEvent) => {
        setSitJong(value);
    };

    const StartopdateChange = (date: Dayjs | null, dateString: string, id: number) => {
        setSOpdate(dateString);
    };

    const EndopdateChange = (date: Dayjs | null, dateString: string, id: number) => {
        setEOpdate(dateString);
    };

    const onChangeswJong = (checked: boolean) => {
        {checked==true ? setswJong("t") : setswJong("f")  }
    };

    const onChangeswCheckin = (checked: boolean) => {
        {checked==true ? setswCheckin("t") : setswCheckin("f")  }
    };

    const onChangeswStdate = (checked: boolean) => {
        {checked==true ? setswStdate("t") : setswStdate("f")  }
    };

    const onChangeCondition = (checkedValues: CheckboxValueType[]) => {
        setCondition(checkedValues);
    };

    const isDisable = (id:any) => {
        var isMatch = vCondition.some(function(c: any){
            return c === id;
        });

        return(
            isMatch ? false : vCondition.length > 3
        )
    };

    const onChangeswSitJong = (checked: boolean) => {
        {checked==true ? setswSitJong("t") : setswSitJong("f")  }
    };

    const toggleDislUnit = () => {
        setDislUnit(!dislUnit);
    };

    function fileChange(e:any){
        if(e.file.response){
            let res = e.file.response;
        }
    }

    function opTime(time: any, timeString: any) { 
        setOpTime(timeString + ":00.000");
    }

    function clTime(time: any, timeString: any) {
        setClTime(timeString + ":00.000");
    }

    function onchangeProp(value:any) {
        setProp(value);

        if(vProperty == undefined && vProperty == null){
            toggleDislUnit();
        }
    }

    function onchangeInput(value:any, index:any, unitTypeName:any, unitTypeID:any) {
        vInputUnitType.push({index, limit_round: value, unit_type: unitTypeID, unitTypeName, facility_round_type: ""})
        if(vInputUnitType?.length > 0){
            removeUnitArray();
        }
    }

    function onchangeSelectRoundType(value:any, index:any, unitTypeName:any, unitTypeID:any) {
        for(let i=0 ; i <= vInputUnitType.length -1; i++){
            if(vInputUnitType[i].index === index){
                vInputUnitType[i].facility_round_type = value;
            }
        }
    }

    function removeUnitArray(){        
        vInputUnitType?.reduceRight((acc: { [x: string]: boolean; }, obj: { index: string | number; }, i: any) => {
            acc[obj.index]? vInputUnitType.splice(i, 1) : acc[obj.index] = true;
            return acc;
        }, Object.create(null));
        vInputUnitType?.sort((a: { index: number; }, b: { index: number; }) => a.index - b.index);

        let countZero = vInputUnitType?.filter(function(item: any){
            return item.limit_round == '0'
        });


        setCountZero(countZero.length)
        // console.log('countZero', countZero)
    }

    function onChangeByUnit(v:any){
        if(v.target.value == "0"){
            setinputRes_unitR(1)
        }else{
            setinputRes_unitR(v.target.value)
        }
    }

    function onChangeByUser(v:any){
        if(v.target.value == "0"){
            setinputRes_userR(1)
        }else{
            setinputRes_userR(v.target.value)
        }
    }

    function onaddtimeslot(fields:any,mode:any){
        if(fields?.length >= 0 && mode == "add"){
            setIsRequired(false);
        }else if(vTimeSlotTempTEST?.length == 0 && mode == "del"){
            setIsRequired(undefined);
        }
    }

    function selectTime(time: any, timeString: any, round: any) {
        vTimeSlotTempTEST.push({time_start: timeString[0], time_end: timeString[1], name: round+""});
        setTimeSlotTEST(vTimeSlotTempTEST);
    }
    
    function removeAndSortArr(){
        vTimeSlotTEST.reduceRight((acc: { [x: string]: boolean; }, obj: { name: string | number; }, i: any) => {
            acc[obj.name]? vTimeSlotTEST.splice(i, 1) : acc[obj.name] = true;
            return acc;
        }, Object.create(null));
        vTimeSlotTEST.sort((a: { name: number; }, b: { name: number; }) => b.name - a.name);
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function handleChange(value:any) {
        setDateOP('' + value);
    }

    function onSelMaxUser(value:any) {
        console.log('val', value)
        setinputBooking(value);
    }

    function updateOnFinish(values:any){
        console.log(">>> values",values)
        return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(values))
        );
    }

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
          upload: () => {
            return new Promise((resolve, reject) => {
              const body = new FormData();
              loader.file.then((file: string | Blob) => {
                // console.log('fileeeeeeeeeeeee', file)
                body.append("files", file);
                // let headers = new Headers();
                // headers.append("Origin", "http://localhost:3000");
                fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                  method: "post",
                  body: body
                  // mode: "no-cors"
                })
                  .then((res) => res.json())
                  .then((res) => {
                    // console.log('resssssssssssssssss', res)
                    resolve({
                    //   default: `${API_URL}/${res.filename}`
                      default: res[0].url
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  });
              });
            });
          }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
    }

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
 
        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                if(vSetJongType == 1){
                     //NO BOOKING
                    values.booking = false
                    values.private_room = false // ถ้าเลือก no booking เปลี่ยนฟิลด์ private room เป็น false

                    //===============================================================================
                    values.dateopen_list = vDateOP;

                    const date2 = dayjs();
                    let hours = date2.diff(vSOpdate, 'hours');
                    const days = Math.floor(hours / 24);
                    hours = hours - (days * 24);

                    if(swStdate == "t"){
                        values.status = "OPEN"
                    }else if(swStdate == "f"){
                        if(days < 0){ // วันที่เริ่มปิดไม่ใช่วันปัจจุบัน สถานะจะเป็น OPEN อยู่, พอถึงวันที่ปิดเดี๋ยวให้ cronjob กวาดแล้วเปลี่ยน stat เอา
                            values.status = "OPEN"
                        }else{
                            values.status = "CLOSES"
                        }
                    }
                    
                    if(swStdate == "t"){
                        values.start_opendate = null
                        values.end_opendate = null
                    }else if(swStdate == "f"){ // f = facility close 
                        if(vSOpdate !== "" && vSOpdate !== null && vSOpdate !== undefined){
                            values.start_opendate = moment(vSOpdate).format('YYYY-MM-DD');
                        }
                        if(vEOpdate !== "" && vEOpdate !== null && vEOpdate !== undefined){
                            values.end_opendate = moment(vEOpdate).format('YYYY-MM-DD');
                        }
                    }else{
                        values.start_opendate = null
                        values.end_opendate = null
                    }

                    if(vOpTime !== "" && vOpTime !== null && vOpTime !== undefined ){
                        values.open_time = vOpTime;
                    }

                    if(vClTime !== "" && vClTime !== null && vClTime !== undefined ){
                        values.close_time = vClTime;
                    }

                    if(vCondition?.length > 0){
                        values.facility_conditions = vCondition;
                    }

                    values.note = vNoteRemark
                    values.booking_type = null
                    values.date_open_list = null
                    values.maxUserBooking = null
                    values.createBy = user_data.user_profile.id
                    //===============================================================================

                    if(vInputUnitType?.length == vCountZero){ // ถ้าใส่ 0 รอบมาทั้งหมด ไม่ให้อัพเดท
                        warning();
                    }else{
                        if(vIsRequired == false){
                            updateOnFinish(values);
                        }
                    }

                }else if(vSetJongType == 2){
                    //PRIVATE ROOM
                    if(vSetJongType == 2 && vTimeSlotTempTEST?.length <= 0){
                        setIsRequired(true);
                    }else if(vSetJongType == 2 && vTimeSlotTempTEST?.length > 0){
                        values.isStaff = true // เอาไว้เช็คจอง private room
                        values.private_room = true
                        values.booking = true // ถ้าเลือก private room เปลี่ยนฟิลด์ private room เป็น true, เปลี่ยนฟิลด์ booking เป็น true
                        values.no_booking = false // ถ้าไม่ใส่ no_booking = false ที่เมนู booking create จะไม่เห็น facility ที่เป็น private room

                        values.capacity = inputJongType !== "" ? inputJongType : null;
                        values.max_user = inputBooking !== "" ? inputBooking : null;

                        // Notifications
                        if(vSetNoti == 2){
                            values.facility_noti = true
                            values.facility_noti_day= inputNoti !== "" ? inputNoti : null
                            values.messages= inputNotimsg !== "" ? inputNotimsg : null
                        }else{
                            values.facility_noti = false
                        }

                        if(vSetSitJong == 1){
                            values.reservation_user_round = inputRes_userR !== "" ? inputRes_userR : 0
                            values.reservation_per_user = true
                            values.reservation_per_unit = false
                            values.reservation_per_unittype = false
        
                            values.reservation_unit_round = 0
                            values.facility_round_type_unit = null
                        }
    
                        // ต่อ unit
                        // ต้องเคลียร์ข้อมูลของ ต่อ user, unit type
                        if(vSetSitJong == 2){
                            values.reservation_unit_round = inputRes_unitR !== "" ? inputRes_unitR : 0
                            values.reservation_per_user = false
                            values.reservation_per_unit = true
                            values.reservation_per_unittype = false
    
                            values.reservation_user_round = 0
                            values.facility_round_type_user = null
                        }
    
                        // ต่อ unit type
                        // ต้องเคลียร์ข้อมูลของ ต่อ user, unit
                        if(vSetSitJong == 3){
                            values.reservation_per_user = false
                            values.reservation_per_unit = false
                            values.reservation_per_unittype = true
        
                            values.reservation_unit_round = 0
                            values.facility_round_type_unit = null
    
                            values.reservation_user_round = 0
                            values.facility_round_type_user = null
                        }
                        values.reservation_permis = swSitJong == "t" ? true : false
                    }

                    //===============================================================================
                    values.dateopen_list = vDateOP;

                    const date2 = dayjs();
                    let hours = date2.diff(vSOpdate, 'hours');
                    const days = Math.floor(hours / 24);
                    hours = hours - (days * 24);

                    if(swStdate == "t"){
                        values.status = "OPEN"
                    }else if(swStdate == "f"){
                        if(days < 0){ // วันที่เริ่มปิดไม่ใช่วันปัจจุบัน สถานะจะเป็น OPEN อยู่, พอถึงวันที่ปิดเดี๋ยวให้ cronjob กวาดแล้วเปลี่ยน stat เอา
                            values.status = "OPEN"
                        }else{
                            values.status = "CLOSES"
                        }
                    }
                    
                    if(swStdate == "t"){
                        values.start_opendate = null
                        values.end_opendate = null
                    }else if(swStdate == "f"){ // f = facility close 
                        if(vSOpdate !== "" && vSOpdate !== null && vSOpdate !== undefined){
                            values.start_opendate = moment(vSOpdate).format('YYYY-MM-DD');
                        }
                        if(vEOpdate !== "" && vEOpdate !== null && vEOpdate !== undefined){
                            values.end_opendate = moment(vEOpdate).format('YYYY-MM-DD');
                        }
                    }else{
                        values.start_opendate = null
                        values.end_opendate = null
                    }

                    if(vOpTime !== "" && vOpTime !== null && vOpTime !== undefined ){
                        values.open_time = vOpTime;
                    }

                    if(vClTime !== "" && vClTime !== null && vClTime !== undefined ){
                        values.close_time = vClTime;
                    }

                    if(vCondition?.length > 0){
                        values.facility_conditions = vCondition;
                    }

                    values.note = vNoteRemark
                    values.booking_type = null
                    values.date_open_list = null
                    values.maxUserBooking = null
                    values.createBy = user_data.user_profile.id
                    //===============================================================================

                    if(vInputUnitType?.length == vCountZero){ // ถ้าใส่ 0 รอบมาทั้งหมด ไม่ให้อัพเดท
                        warning();
                    }else{
                        if(vIsRequired == false && vTimeSlotTempTEST?.length > 0){
                            updateOnFinish(values);
                        }
                    }

                }else if(vSetJongType == 3){
                    //BOOKING
                    if(vSetJongType == 3 && vTimeSlotTempTEST?.length <= 0){
                        setIsRequired(true);
                    }else if(vSetJongType == 3 && vTimeSlotTempTEST?.length > 0){
                        values.private_room = false // ถ้าเลือก booking เปลี่ยนฟิลด์ private room เป็น false
                        values.no_booking = false
                        values.booking = true

                        values.capacity = inputJongType !== "" ? inputJongType : null
                        values.max_user = inputBooking !== "" ? inputBooking : null
                        values.checkin = swCheckin == "t" ? true : false
                        
                        if(swCheckin == "t"){
                            if(inputCbfTime !== ""){values.checkin_befor_time = inputCbfTime}
                            if(inputCafTime !== ""){values.checkin_after_time = inputCafTime}
                            values.checkin_code = inputCincode
                        }

                        // Notifications
                        if(vSetNoti == 2){
                            values.facility_noti = true
                            values.facility_noti_day= inputNoti !== "" ? inputNoti : null
                            values.messages= inputNotimsg !== "" ? inputNotimsg : null
                        }else{
                            values.facility_noti = false
                        }

                        if(vSetWait == 2){
                            values.waiting_status= true
                            values.waiting_q= inputWaitQ !== "" ? inputWaitQ : null
                        }else{
                            values.waiting_status = false
                        }

                        if(vSetCCJong == 2){
                            values.pre_cancel_booking = true
                            values.pre_cancel_booking_time = inputCCJong !== "" ? Number(inputCCJong) : null
                        }else{
                            values.pre_cancel_booking = false
                        }

                        if(vSetSitJong == 1){
                            values.reservation_user_round = inputRes_userR !== "" ? inputRes_userR : 0
                            values.reservation_per_user = true
                            values.reservation_per_unit = false
                            values.reservation_per_unittype = false
        
                            values.reservation_unit_round = 0
                            values.facility_round_type_unit = null
                        }
        
                        // ต่อ unit
                        // ต้องเคลียร์ข้อมูลของ ต่อ user, unit type
                        if(vSetSitJong == 2){
                            values.reservation_unit_round = inputRes_unitR !== "" ? inputRes_unitR : 0
                            values.reservation_per_user = false
                            values.reservation_per_unit = true
                            values.reservation_per_unittype = false
        
                            values.reservation_user_round = 0
                            values.facility_round_type_user = null
                        }

                        if(vSetSitJong == 3){
                            values.reservation_per_user = false
                            values.reservation_per_unit = false
                            values.reservation_per_unittype = true
        
                            values.reservation_unit_round = 0
                            values.facility_round_type_unit = null
        
                            values.reservation_user_round = 0
                            values.facility_round_type_user = null
                        }

                        if(swSitJong == "t"){
                            values.reservation_permis = true
                        }else{
                            values.reservation_permis = false
                        }

                        values.reservation_permis = swSitJong == "t" ? true : false

                        // Advance booking
                        values.advance_booking = swJong == "t" ? true : false

                        if(vSetJongF == 1){values.set_booking_day = inputJongFD !== "" ? inputJongFD : null}
                        if(vSetJongF == 2){values.set_booking_hours = inputJongFH !== "" ? inputJongFH : null}
                        if(vSetJongF == 3){values.set_booking_min = inputJongFM !== "" ? inputJongFM : null}

                        //===============================================================================
                        values.dateopen_list = vDateOP;

                        const date2 = dayjs();
                        let hours = date2.diff(vSOpdate, 'hours');
                        const days = Math.floor(hours / 24);
                        hours = hours - (days * 24);

                        if(swStdate == "t"){
                            values.status = "OPEN"
                        }else if(swStdate == "f"){
                            if(days < 0){ // วันที่เริ่มปิดไม่ใช่วันปัจจุบัน สถานะจะเป็น OPEN อยู่, พอถึงวันที่ปิดเดี๋ยวให้ cronjob กวาดแล้วเปลี่ยน stat เอา
                                values.status = "OPEN"
                            }else{
                                values.status = "CLOSES"
                            }
                        }
                        
                        if(swStdate == "t"){
                            values.start_opendate = null
                            values.end_opendate = null
                        }else if(swStdate == "f"){ // f = facility close 
                            if(vSOpdate !== "" && vSOpdate !== null && vSOpdate !== undefined){
                                values.start_opendate = moment(vSOpdate).format('YYYY-MM-DD');
                            }
                            if(vEOpdate !== "" && vEOpdate !== null && vEOpdate !== undefined){
                                values.end_opendate = moment(vEOpdate).format('YYYY-MM-DD');
                            }
                        }else{
                            values.start_opendate = null
                            values.end_opendate = null
                        }

                        if(vOpTime !== "" && vOpTime !== null && vOpTime !== undefined ){
                            values.open_time = vOpTime;
                        }

                        if(vClTime !== "" && vClTime !== null && vClTime !== undefined ){
                            values.close_time = vClTime;
                        }

                        if(vCondition?.length > 0){
                            values.facility_conditions = vCondition;
                        }

                        values.note = vNoteRemark
                        values.booking_type = null
                        values.date_open_list = null
                        values.maxUserBooking = null
                        values.createBy = user_data.user_profile.id
                        //===============================================================================

                        if(vInputUnitType?.length == vCountZero){ // ถ้าใส่ 0 รอบมาทั้งหมด ไม่ให้อัพเดท
                            warning();
                        }else{
                            if(vIsRequired == false && vTimeSlotTempTEST?.length > 0){
                                updateOnFinish(values);
                            }
                        }
                    }
                }
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px", marginBottom: "1.6%" }}>
                        <Row gutter={8}>
                            <Col className="gutter-row" span={8}>
                                <Form.Item  label={t("property.title")} name="property"
                                    rules={[
                                        {
                                            required: true, message: 'Please select property.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} {...Selprop} onChange={onchangeProp} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("fclt-type.title")} name="facility_type"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter facility type.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} {...SelFaciliType} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={10}>
                                <Form.Item  label={t("fclt.name")} name="name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        <Input showCount maxLength={85} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("fclt.desc")} name="description"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        {/* <TextArea style={{height: "30px", maxHeight: "150px"}}/> */}
                                        <Input/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item  label={t("fclt.spc_ann")} name="special_announcement">
                                    <Input showCount maxLength={255} />
                                </Form.Item>
                            </Col>

                            {/* <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("fclt.note")} name="note"
                                    rules={[
                                        {
                                            required: true, message: 'please enter note.'
                                        },
                                    ]}>
                                        <TextArea style={{height: "30px", maxHeight: "150px"}}/>
                                </Form.Item>
                            </Col> */}

                            <Col span={24}>
                                <Form.Item label="Remark" rules={[{required: true,},]}>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            alignment: {
                                                options: [ 'left', 'right' ]
                                            },
                                            toolbar: [
                                                "heading",
                                                "|",
                                                "bold",
                                                "italic",
                                                "link",
                                                "bulletedList",
                                                "numberedList",
                                                "blockQuote",
                                                'alignment',
                                                "|",
                                                "imageTextAlternative",
                                                "imageUpload",
                                                "imageStyle:full",
                                                "imageStyle:side",
                                            ],
                                            extraPlugins: [uploadPlugin],
                                            mediaEmbed: {
                                                // configuration...
                                                removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                            },
                                            image: {
                                                toolbar: ['']
                                            }
                                        }}
                                        
                                        onInit={(editor:any) => {
                                            // console.log('editor',editor)
                                        }}
                                        onChange={(event:any, editor:any) => {
                                            const data = editor.getData();
                                            setNoteRemark(data);
                                        }}
                                        onBlur={(editor:any) => {
                                            // console.log("Blur.", editor);
                                        }}
                                        onFocus={(editor:any) => {
                                            // console.log("Focus.", editor);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={8}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item label = "ประเภทการจอง" name="booking_type"
                                    rules={[
                                        {
                                            required: true, message: 'Please select booking type'
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={onChangeProp} value={vSetJongType}>
                                        <Space direction="vertical">
                                            <Radio value={1}>{t("fclt.nbooking")}</Radio>

                                            <Radio value={2}>
                                                <div style={{display: "flex"}}>
                                                    <div style={{marginRight: "1rem"}}>{t("fclt.priroom")}</div>
                                                    <div style={{marginTop: "-0.3rem"}}>
                                                        {vSetJongType === 2 &&
                                                            <Form.Item 
                                                                name="personPerRound"
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please enter person/round'
                                                                    },
                                                                ]}
                                                                style={{marginBottom: 0}}
                                                            >
                                                                <Input 
                                                                    type={"number"} 
                                                                    min={1} 
                                                                    style={{ width: "65%", marginLeft: "0%",borderRadius: "10px"}} suffix="คน/รอบ" value={inputJongType} 
                                                                    onChange={e => setinputJongType(e.target.value) }
                                                                /> 
                                                            </Form.Item>
                                                        }
                                                    </div>
                                                </div>
                                            </Radio>

                                            <Radio value={3}>
                                                <div style={{display: "flex"}}>
                                                    <div style={{marginRight: "1rem"}}>{t("fclt.booking")}</div>
                                                    <div style={{marginTop: "-0.3rem"}}>
                                                        {vSetJongType === 3 &&
                                                            <Form.Item 
                                                                name="personPerRound"
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please enter person/round'
                                                                    },
                                                                ]}
                                                                style={{marginBottom: 0}}
                                                            >
                                                                <Input 
                                                                    type={"number"}
                                                                    min={1} 
                                                                    style={{ width: "65%", marginLeft: "0%",borderRadius: "10px"}} 
                                                                    suffix="คน/รอบ" 
                                                                    value={inputJongType} 
                                                                    onChange={e => {
                                                                        setinputJongType(e.target.value)
                                                                        form.resetFields(["maxUserBooking"]);
                                                                    }} 
                                                                /> 
                                                            </Form.Item>
                                                        }
                                                    </div>
                                                </div>
                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>

                                {vSetJongType == 3 && 
                                    <div style={{marginBottom: "3%"}}>
                                        <span>{t("fclt.lm-user")}</span>
                                        <Form.Item 
                                            name="maxUserBooking"
                                            rules={[
                                                {
                                                    required: true, message: 'Please enter max user/booking'
                                                },
                                            ]}
                                        >
                                            <Select
                                                style={{ 
                                                    width: "48%", 
                                                    borderRadius: "10px"
                                                }}
                                                placeholder={t("select.sel")}
                                                onChange={onSelMaxUser}
                                            >
                                                {
                                                    parseInt(inputJongType) > 5 ?
                                                        <>{opt_max_user()}</>
                                                    :
                                                        <>{count_booking()}</>
                                                }
                                                
                                            </Select>
                                        </Form.Item>
                                    </div>
                                }
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Row gutter={8}>
                                    <Col className="gutter-row" span={24}>
                                        <Form.Item label={t("fclt.dateop")} name="date_open_list"
                                            rules={[
                                                {
                                                    required: true, message: 'Please select date open list'
                                                },
                                            ]}
                                        >
                                            <Select
                                                tokenSeparators={[',']}
                                                mode="multiple"
                                                placeholder={t("select.sel")}
                                                onChange={handleChange}
                                            >
                                                <Option value="Monday">{t("day.monday")}</Option>
                                                <Option value="Tuesday">{t("day.tuesday")}</Option>
                                                <Option value="Wednesday">{t("day.wednesday")}</Option>
                                                <Option value="Thursday">{t("day.thursday")}</Option>
                                                <Option value="Friday">{t("day.friday")}</Option>
                                                <Option value="Saturday">{t("day.saturday")}</Option>
                                                <Option value="Sunday">{t("day.sunday")}</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={24}>
                                        <Row gutter={8}>
                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label={t("fclt.optime")} name="open_time"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select open time.'
                                                    },
                                                ]}>
                                                    <TimePicker format={format} onChange={(date, dateString) => opTime(date, dateString)} style={{width: "100%"}}/>
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={12}>
                                                <Form.Item  label={t("fclt.cltime")} name="close_time"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select close time.'
                                                        },
                                                    ]}>
                                                    <TimePicker format={format} onChange={(date, dateString) => clTime(date, dateString)} style={{width: "100%"}}/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>

                    {/* keyDYNAMIC_FORM ================================================================*/}
                    {(vSetJongType == 2 || vSetJongType == 3) && 
                        <Card style={{borderRadius: 5, marginBottom: "1.6%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Form.List name="fields">
                                {(fields, { add, remove }) => {
                                    return (
                                    <div>
                                        <div style={{marginBottom: "1.5rem",display: "flex"}}>
                                            <div style={{marginRight: "1rem"}}><span style={{color:"red"}}>*</span> กำหนดช่วงเวลาในการใช้งาน</div>
                                            <div style={{marginTop: "-0.4rem"}}>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        onaddtimeslot(fields,"add")
                                                        add()}
                                                    }
                                                    style={{ width: "120px",backgroundColor: "#d6c6a0",borderRadius: "10px"}}
                                                >
                                                    <PlusOutlined style={{color: "#fff"}} /><span style={{color: "#fff"}}>Add</span>
                                                </Button>
                                            </div>
                                        </div>
                                        {vIsRequired == true? <div style={{color: "red"}}>Please add facility open hours</div> :null}
                                        {fields.map((field, index) => (
                                            <div key={field.key}>
                                                <Row>
                                                    <Col span={3}><div style={{marginTop: "0.4rem"}}><Tag color="orange">รอบที่ {index + 1}</Tag></div></Col>

                                                    <Col span={9}>
                                                        <Form.Item
                                                            name={[index, 'timeslot']}
                                                            rules={[
                                                                {
                                                                    required: true, message: 'Please select timeslot'
                                                                },
                                                            ]}
                                                        >
                                                            <TimePicker.RangePicker 
                                                                style={{width:'95%'}}
                                                                onChange={(date, dateString) => selectTime(date, dateString, index+1)}
                                                                format={format} 
                                                            />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={5}>
                                                        {fields?.length > 1 ? (
                                                            <Button
                                                                size="small"
                                                                className="dynamic-delete-button"
                                                                onClick={() => remove(field.name)}
                                                                danger
                                                            >
                                                                <CloseOutlined />
                                                            </Button>
                                                            ) : null
                                                        }
                                                    </Col>
                                                </Row>
                                                <div style={{marginBottom: "1vh",}}></div>
                                            </div>
                                        ))}
                                    </div>
                                    );
                                }}
                            </Form.List>
                        </Card>
                    }

                    <Card style={{borderRadius: 5, marginBottom: "1.6%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item  label={t("fclt.condi")} name="facility_conditions">
                            {ConditionData !== null && ConditionData.length > 0 ? 
                            <>
                                <Checkbox.Group style={{ width: '100%' }} onChange={onChangeCondition}>
                                    <Row>
                                        {
                                            ConditionData?.map((el:any,index:any) => {
                                                return <>
                                                    <Col span={6} style={{marginBottom: "2%"}}>
                                                        <Checkbox disabled={isDisable(el.id)} key={el.id} value={el.id} ><Image src={el.condition_pix.url} width={"2vh"} height={"2vh"} style={{objectFit: "cover",borderRadius: "10px"}} preview={false} /> {el.description}</Checkbox>
                                                    </Col>
                                                </>
                                            })
                                        }
                                    </Row>
                                </Checkbox.Group>
                            </>
                            :
                            <>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </>}
                        </Form.Item>
                    </Card>

                    {vSetJongType == 3 && 
                        <Card style={{borderRadius: 5, marginBottom: "1.6%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Form.Item>
                                <span style={{fontSize: "0.7vw",marginRight: "1.5%"}}>สิทธิ์การจอง</span><Switch defaultChecked={false} onChange={onChangeswSitJong} />
                            </Form.Item>

                            {swSitJong === "t" ? 
                            <>
                                {/* hotfix bro */}
                                <Form.Item name="booking" 
                                    rules={[
                                        {
                                            required: vSetSitJong ? false : true, message: 'Please select'
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={onChangeSitJong} value={vSetSitJong} style={{width: "100%"}}> 
                                        <Space direction="vertical" style={{width: "100%"}}>
                                            <Radio value={1}>ต่อบัญชี</Radio>
                                            {vSetSitJong == 1 ? 
                                                <>
                                                    <Row gutter={16}>
                                                        <Col className="gutter-row" span={1}></Col>
                                                        <Col className="gutter-row" span={2}>
                                                            <Input type={"number"} 
                                                                pattern="[1-9]+"
                                                                defaultValue={1} min={1} size="small" 
                                                                style={{width: "100%",borderRadius: "10px"}} 
                                                                value={inputRes_userR} 
                                                                // onChange={e => setinputRes_userR(e.target.value)}
                                                                onChange={onChangeByUser}
                                                            />
                                                        </Col>
                                                        <Col className="gutter-row" span={1.5}>
                                                            <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                        </Col>
                                                        <Col className="gutter-row" span={4}>
                                                            <Form.Item name="facility_round_type_user" style={{marginBottom: "0"}} 
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select'
                                                                    },
                                                                ]}>
                                                                    <Select size="small" style={{width: "100%",borderRadius: "10px",top: "-3px"}} placeholder={t("select.sel")} {...SelFaciliRType}
                                                                        onSelect={(value: any) => {
                                                                            // setFacility_user(value);
                                                                        }}
                                                                    />
                                                            </Form.Item>
                                                        </Col> 
                                                    </Row>
                                                </>:<></>
                                            }

                                            <Radio value={2}>ต่อ Unit</Radio> 
                                            {vSetSitJong == 2 ? 
                                                <>
                                                    <Row gutter={16}>
                                                        <Col className="gutter-row" span={1}></Col>
                                                        <Col className="gutter-row" span={2}>
                                                            <Input 
                                                                type={"number"} 
                                                                defaultValue={1} min={1} 
                                                                size="small" 
                                                                style={{width: "100%", borderRadius: "10px"}} 
                                                                value={inputRes_unitR} 
                                                                // onChange={e => setinputRes_unitR(e.target.value)}
                                                                onChange={onChangeByUnit}
                                                            />
                                                        </Col>
                                                        <Col className="gutter-row" span={1.5}>
                                                            <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                        </Col>
                                                        <Col className="gutter-row" span={4}>
                                                            <Form.Item name="facility_round_type_unit" style={{marginBottom: "0"}} 
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select'
                                                                    },
                                                                ]}
                                                            >
                                                                    <Select size="small" style={{width: "100%", borderRadius: "10px",top: "-3px"}} placeholder={t("select.sel")} {...SelFaciliRType}
                                                                        onSelect={(value: any) => {
                                                                            // setFacility_unit(value);
                                                                        }}
                                                                    />
                                                            </Form.Item>
                                                        </Col> 
                                                    </Row>
                                                </>
                                                :<></>}

                                            <Radio value={3} disabled={dislUnit}>ต่อ Unit Type</Radio>
                                            <br/>
                                        </Space>
                                    </Radio.Group>

                                    {vSetSitJong == 3 ? 
                                        <>
                                            {
                                                UnitTypeData?.map((el:any,index:any) => {
                                                    return <>
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={1}></Col>

                                                            <Col className="gutter-row" span={5}>
                                                                <span style={{fontSize: "0.7vw", float:'left', verticalAlign:'middle'}}><b>{el.name} : <>&nbsp;</></b></span>
                                                            </Col>

                                                            <Col className="gutter-row" span={3}>
                                                                    <Input
                                                                        type={"number"}
                                                                        // defaultValue={0} 
                                                                        min={0}
                                                                        size="small"
                                                                        style={{width: "100%",borderRadius: "10px"}}
                                                                        onChange={e => { onchangeInput(e.target.value, index, el.name, el.id);}}
                                                                    />
                                                            </Col>

                                                            <Col className="gutter-row" span={1.5}>
                                                                <span style={{fontSize: "0.7vw", float:'right', verticalAlign:'middle'}}><b>รอบ / <>&nbsp;</></b></span>
                                                            </Col>
                                                            
                                                            <Col className="gutter-row" span={4}>
                                                                <Form.Item 
                                                                    // name="kSelectRound"
                                                                    name={"kSelectRound_" + index}
                                                                    style={{marginBottom: "0"}}
                                                                    rules={[
                                                                        {
                                                                            required: true, message: 'Please select'
                                                                        },
                                                                    ]}>
                                                                        <Select size="small" style={{width: "100%",borderRadius: "10px",top: "-3px"}} placeholder={t("select.sel")} {...SelFaciliRType}
                                                                            onChange={value => {
                                                                                onchangeSelectRoundType(value, index, el.name, el.id)
                                                                            }}
                                                                        />
                                                                </Form.Item>
                                                            </Col> 
                                                        </Row>
                                                        </>
                                                    })
                                            }
                                        </>
                                        :<></>
                                    }
                                </Form.Item>
                            </>
                            :
                            <></>}
                        </Card>
                    }
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item
                            name="cover"
                            label={t("fclt.cover")}
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            rules={[
                                {
                                    required: true, message: 'Please upload image.'
                                },
                            ]}>

                            <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                maxCount={1}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Upload.Dragger>
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 338*190 PNG, JPG Maximum size 5MB</p>

                        <Form.Item
                            name="gallery"
                            label={t("fclt.gall")}
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                            rules={[
                                {
                                    required: true, message: 'Please upload image.'
                                },
                            ]}
                        >
                        
                            <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                maxCount={5}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p className="ant-upload-text">{t("upload")}</p>
                            </Upload.Dragger>
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 600*440 PNG, JPG Maximum size 5MB</p>
                    </Card>

                    {
                        // vSetJongType == 3 || vSetJongType == 2 
                        vSetJongType == 3 ? 
                            <>
                                <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <Form.Item rules={[{ required: true, message: 'Please select.' }, ]}>
                                        <span style={{fontSize: "0.7vw",marginRight: "5%"}}>การจองล่วงหน้า</span><Switch defaultChecked={false} onChange={onChangeswJong} />
                                    </Form.Item>

                                    {
                                        swJong === "t" ? 
                                            <>
                                                {/* <Form.Item name="booking"
                                                    rules={[
                                                        {
                                                            required: swJong === "t" ? true:false, message: 'Please select booking.'
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group onChange={onChangeJong} value={vSetJong}>
                                                        <Space direction="vertical">
                                                            <Radio value={1}>ไม่กำหนดเวลาจองล่วงหน้า</Radio>
                                                            <Radio value={2}>ต้องจองล่วงหน้า</Radio>
                                                        </Space>
                                                    </Radio.Group>
                                                </Form.Item> */}

                                                <Row>
                                                    <Col span={4} />
                                                    <Col span={10} >
                                                        <Radio.Group onChange={onChangeJongF} value={vSetJongF}>
                                                            <Space direction="vertical">
                                                                <Radio value={1} style={{marginBottom:"1%"}}>วัน</Radio>
                                                                <Radio value={2} style={{marginBottom:"10%"}}>ชั่วโมง</Radio>
                                                                {/* <Radio value={3}>นาที</Radio> */}
                                                            </Space>
                                                        </Radio.Group>
                                                    </Col>

                                                    <Col span={10}>
                                                        <Row>
                                                            <Col span={24} style={{height: "34px"}}>
                                                                {vSetJongF === 1 ? <Input type="number" min={1} suffix="วัน" style={{marginBottom:"3%",borderRadius: "10px"}} value={inputJongFD} onChange={e => setinputJongFD(e.target.value)}/>:<></>}
                                                            </Col>

                                                            <Col span={24} style={{height: "34px"}}>
                                                                {vSetJongF === 2 ?<Input type="number" min={1} suffix="ชั่วโมง" style={{marginBottom:"3%",borderRadius: "10px"}} value={inputJongFH} onChange={e => setinputJongFH(e.target.value)}/>:<></>}
                                                            </Col>

                                                            <Col span={24} style={{height: "34px"}}>
                                                                {vSetJongF === 3 ?<Input type="number" min={1} suffix="นาที" style={{borderRadius: "10px"}} value={inputJongFM} onChange={e => setinputJongFM(e.target.value)}/>:<></>}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                            </>
                                        :
                                            <></>
                                    }

                                    {/* {
                                        vSetJong === 2 ? 
                                            <>
                                                <Row>
                                                    <Col span={4} />
                                                    <Col span={10} >
                                                        <Radio.Group onChange={onChangeJongF} value={vSetJongF}>
                                                            <Space direction="vertical">
                                                                <Radio value={1} style={{marginBottom:"1%"}}>วัน</Radio>
                                                                <Radio value={2} style={{marginBottom:"10%"}}>ชั่วโมง</Radio>
                                                                <Radio value={3}>นาที</Radio>
                                                            </Space>
                                                        </Radio.Group>
                                                    </Col>

                                                    <Col span={10}>
                                                        <Row>
                                                            <Col span={24} style={{height: "34px"}}>
                                                                {vSetJongF === 1 ? <Input type="number" min={1} suffix="วัน" style={{marginBottom:"3%",borderRadius: "10px"}} value={inputJongFD} onChange={e => setinputJongFD(e.target.value)}/>:<></>}
                                                            </Col>

                                                            <Col span={24} style={{height: "34px"}}>
                                                                {vSetJongF === 2 ?<Input type="number" min={1} suffix="ชั่วโมง" style={{marginBottom:"3%",borderRadius: "10px"}} value={inputJongFH} onChange={e => setinputJongFH(e.target.value)}/>:<></>}
                                                            </Col>

                                                            <Col span={24} style={{height: "34px"}}>
                                                                {vSetJongF === 3 ?<Input type="number" min={1} suffix="นาที" style={{borderRadius: "10px"}} value={inputJongFM} onChange={e => setinputJongFM(e.target.value)}/>:<></>}
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                        :
                                            <></>
                                    } */}

                                    <Form.Item>
                                        <span style={{fontSize: "0.7vw",marginRight: "5%"}}>การเช็คอิน</span><Switch defaultChecked={false} onChange={onChangeswCheckin} />
                                    </Form.Item>

                                    {
                                        swCheckin === "t" ? 
                                        <>
                                            <Row gutter={[16, 24]}>
                                                <Col span={17} className="gutter-row">
                                                    <Form.Item><div style={{fontSize: "0.7vw"}}>เช็คอินก่อนเวลาจองได้ (นาที)</div></Form.Item>
                                                    <Form.Item><div style={{fontSize: "0.7vw"}}>เช็คอินหลังเวลาจองได้ไม่เกิน (นาที)</div></Form.Item>
                                                </Col>

                                                <Col span={7} className="gutter-row">
                                                    <Form.Item name="checkin_befor_time"
                                                        rules={[
                                                            {
                                                                required: swCheckin === "t" ? true:false, message: ''
                                                            },
                                                        ]}>
                                                        <Input type="number" min={1} value={inputCbfTime} defaultValue={1} onChange={e => setinputCbfTime(e.target.value)}/>
                                                    </Form.Item>
                                                    
                                                    <Form.Item name="checkin_after_time"
                                                        rules={[
                                                            {
                                                                required: swCheckin === "t" ? true:false, message: ''
                                                            },
                                                        ]}>
                                                        <Input type="number" min={1} value={inputCafTime} defaultValue={1} onChange={e => setinputCafTime(e.target.value)}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Form.Item label={t("fclt.checkinc")} name="checkin_code">
                                                <Input value={inputCincode} onChange={e => setinputCincode(e.target.value)} />
                                            </Form.Item>
                                        </>
                                    :
                                        <></>
                                    }

                                </Card>

                    {/* :    */}


                        



                        {
                            vSetJongType == 3 ?
                                <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                    <Form.Item label={t("fclt.waiting")} name="waiting_status"
                                        rules={[
                                            {
                                                required: true, message: 'Please select waiting list type.'
                                            },
                                        ]}>
                                        <Radio.Group onChange={onChangeWait} value={vSetWait}>
                                            <Space direction="vertical">
                                                <Radio value={1}>ไม่เปิดให้มี Waiting List</Radio>
                                                <Radio value={2} style={{width: "100%"}}>เปิดให้มี Waiting List
                                                    {vSetWait === 2 ? <Input type={"number"} min={1} max={10} defaultValue={1} style={{ width: "37%", marginLeft: "5%",borderRadius: "10px" }} suffix="คิว" value={inputWaitQ} onChange={e => setinputWaitQ(e.target.value)}/> : null}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Card>
                            :
                                <></>
                        }
                       

                        <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Form.Item label={t("fclt.ccjong")} name="pre_cancel_booking_time"
                                rules={[
                                    {
                                        required: true, message: 'Please select cancel booking type.'
                                    },
                                ]}>
                                <Radio.Group onChange={onChangeccJong} value={vSetCCJong}>
                                    <Space direction="vertical">
                                        <Radio value={1}>ยกเลิกได้ตลอดเวลา</Radio>
                                        <Radio value={2} style={{width: "100%"}}>ต้องยกเลิกล่วงหน้า
                                            {vSetCCJong === 2 ? <Input type={"number"} min={1} max={10} defaultValue={1} style={{ width: "100%", marginLeft: "5%",borderRadius: "10px" }} suffix="ชั่วโมง" value={inputCCJong} onChange={e => setinputCCJong(e.target.value)}/> : null}
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </Card>
                    </>:<></>}

                    {
                        vSetJongType == 2 ||vSetJongType == 3? 
                            <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                                <Form.Item label={t("fclt.noti")} name="facility_noti"
                                    rules={[
                                        {
                                            required: true, message: 'Please select notification type.'
                                        },
                                    ]}>
                                        <Radio.Group onChange={onChangeNoti} value={vSetNoti}>
                                            <Space direction="vertical">
                                                <Radio value={1}>ไม่แจ้งเตือน</Radio>
                                                <Radio value={2}>ต้องกำหนดเตือนล่วงหน้า
                                                    {vSetNoti === 2 ? <Input type={"number"} min={1} defaultValue={1} style={{ width: "30%", marginLeft: "5%",borderRadius: "10px" }} suffix="วัน" value={inputNoti} onChange={e => setinputNoti(e.target.value)}/> : null}
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                </Form.Item>

                                {vSetNoti === 2 ? 
                                    <Form.Item label={t("fclt.message")}>
                                        <TextArea style={{height: "30px", maxHeight: "60px"}} value={inputNotimsg} onChange={e => setinputNotimsg(e.target.value)}/>
                                    </Form.Item>
                                    :<></>
                                }
                            </Card>
                        :
                            <></>
                    }

                    {/* ============================================================================= */}

                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item label="สถานะการให้บริการปัจจุบัน">

                            <Form.Item name="status">
                                <Switch defaultChecked={true} onChange={onChangeswStdate} checkedChildren="เปิด" unCheckedChildren="ปิด" />
                            </Form.Item>

                            {swStdate === "t" ? 
                            <>
                                
                            </>
                            :
                            <> 
                                <Row gutter={[16, 24]}>
                                    <Col span={24} className="gutter-row">
                                        <Row gutter={[16, 24]}>
                                            <Col span={8} className="gutter-row">
                                                <div style={{fontSize: "0.7vw",marginTop: "5%",color: "#000"}}>{t("fclt.opd")}<span style={{color: "red"}}> *</span></div>
                                            </Col>

                                            <Col span={16} className="gutter-row">
                                                <Form.Item name="start_opendate" style={{marginBottom: "1%"}}
                                                    rules={[
                                                        {
                                                            required: swStdate === "t" ? false:true, message: 'Please select start date.'
                                                        },
                                                ]}>
                                                    <DatePicker format="YYYY-MM-DD" style={{width: "95%"}}
                                                        onChange={(date, dateString) => StartopdateChange(date, dateString, 1)} 
                                                        // disabledDate={(current) => {
                                                        //     let customDate = moment().format("YYYY-MM-DD");
                                                        //     return current && current < moment(customDate, "YYYY-MM-DD");
                                                        // }}
                                                        disabledDate={(current) => {
                                                            let todayDate = dayjs().format("YYYY-MM-DD");
                                                            return current && current.isBefore(todayDate, 'day');
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col span={24} className="gutter-row">
                                        <Row gutter={[16, 24]}>
                                            <Col span={8} className="gutter-row">
                                                <div style={{fontSize: "0.7vw",marginTop: "5%",color: "#000"}}>{t("fclt.cld")}<span style={{color: "red"}}> *</span></div>
                                            </Col>

                                            <Col span={16} className="gutter-row">
                                                <Form.Item name="end_opendate"
                                                rules={[
                                                    {
                                                        required: swStdate === "t" ? false:true, message: 'Please select end date.'
                                                    },
                                                ]}>
                                                    <DatePicker format="YYYY-MM-DD" style={{width: "95%"}}
                                                        onChange={(date, dateString) => EndopdateChange(date, dateString, 1)} 
                                                        // disabledDate={(current) => {
                                                        //     let customDate = moment().format("YYYY-MM-DD");
                                                        //     return current && current < moment(customDate, "YYYY-MM-DD");
                                                        // }}
                                                        disabledDate={(current) => {
                                                            let todayDate = dayjs().format("YYYY-MM-DD");
                                                            return current && current.isBefore(todayDate, 'day');
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Form.Item name="close_note" label={t("fclt.Clnote")}
                                    // rules={[
                                    //     {
                                    //         required: swStdate === "t" ? false:true, message: 'Please enter close note'
                                    //     },
                                    // ]}
                                >
                                    <TextArea style={{height: "30px", maxHeight: "150px"}} />
                                </Form.Item>
                            </>}
                        </Form.Item>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Create>   
    );
};