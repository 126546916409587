/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, Row, Select, useForm, useSelect } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IvehicleBrand, IvehicleModel, IvehicleType } from "interfaces";
import { useState } from "react";

export const VehicleMCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint          = "even-logs";
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form, formProps, saveButtonProps } = useForm<IvehicleModel,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'vehicle-models',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );

    const [seltype, setseltype] = useState<any>();
    const [selbrand, setselbrand] = useState<any>();

    const { selectProps: selvehicleType } = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const { selectProps: selvehicleBrand } = useSelect<IvehicleBrand>({
        resource: "vehicle-brands",
        optionLabel: "brand_nameEN",
        optionValue: "id",
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'vehicle_type][id]',
                operator    : 'eq',
                value       : seltype,
            },
        ] 
    });

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("vehicle.bc_title_m_create")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title_m_create")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            console.log(user_data.user_profile.id)
              values.createBy = user_data.user_profile.id
             return ( 
                 formProps.onFinish &&
                 formProps.onFinish(mediaUploadMapper(values))
             );
         }}>
            <Row gutter={16}>
                <Col id="Col_w" className="gutter-row" span={24}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.type")} name="vehicle_type"
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")}
                                        {...selvehicleType}
                                        onChange={(e) => {
                                            setseltype(e);
                                            form.resetFields(["vehicle_brand"]);
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.brand")} name="vehicle_brand"
                                rules={[
                                    {
                                        required: true, message: 'Please select'
                                    },
                                ]}>
                                    <Select placeholder={t("select.sel")}
                                        {...selvehicleBrand}
                                        onChange={(e) => setselbrand(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.model-code")} name="medel_code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter model code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.model-name_en")} name="model_nameEN"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter model name(en).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item  label={t("vehicle.model-name_th")} name="model_nameTH"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter model name(th).'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {/* <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={16}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={8}>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                            </Col>
                        </Row>
                    </Card>
                </Col> */}
            </Row>
        </Form>
    </Create>   
    );
};