/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Divider, Form, Input, List, message, RcFile, Row, Select, Upload, useForm , useSelect} from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate  } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IPPType, IProjectBrand, IProjectType, IProperties, IUserAmpure, IUserDistrict, IUserProvice } from "interfaces";
import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
import { useState } from "react";

import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

export const PropertiesCreate: React.FC<IResourceComponentsProps> = () => {

    const t                                 = useTranslate();
    const { Option } = Select;
    const { TextArea } = Input;
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const [ vamper, SetAmper]               = useState<any>();
    const [ vpovin, Setpovin]               = useState<any>();
    // const [ vpostcode, Setpostcode]     = useState<any>();
    const [ vdist, Setdist]                 = useState<any>(1);
    const [vsetTel, setTel] = useState<CountryPhoneInputValue>();

    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const { formProps, saveButtonProps }    = useForm<IProperties,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'properties',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );
    const API_URL = useApiUrl();

    const { selectProps: Selpptype } = useSelect<IPPType>({
        resource: "pp-types",
        optionLabel: "pp_name",
        optionValue: "id",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ]
    });

    const { selectProps: selPJBRAND } = useSelect<IProjectBrand>({
        resource: "project-brands",
        optionLabel: "brand_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'active_status',
                operator    : 'eq',
                value       : true
            },
        ] 
    });

    const { selectProps: SelProjectType, queryResult: { refetch } } = useSelect<IProjectType>({
        resource: "projects",
        optionLabel: "project_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ],
    });
    refetch();


    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);   
        }
    }

    const { selectProps: SelAmphure } = useSelect<IUserAmpure>({
        metaData: {
            populate: '*'
            }, 
        resource: "amphures",
        optionLabel: "name_th",
        optionValue: "id",
        fetchSize: 1000,
        filters     : [
            {
                field       : 'province_id]',
                operator    : 'in',
                value       : vpovin
            }
        ]
    });
    
    const { selectProps: SelDistrict } = useSelect<IUserDistrict>({
        metaData: {
            populate: '*'
        }, 
        resource    : "districts",
        optionLabel : "name_th",
        optionValue : "id",
        fetchSize   : 10000,
        filters     : [
            {
                field       : 'amphure_id]',
                operator    : 'in',
                value       : vamper
            }
        ]
    });

    const { selectProps: SelProvice } = useSelect<IUserProvice>({
        metaData: {
            populate: '*'
        }, 
        resource    : "provices",
        optionLabel : "name_th",
        optionValue : "id",
        fetchSize   : 100,
    });
    
    const postListQueryResult = useList<IUserDistrict>({
        resource: "districts",
        config: {
            filters: [
                {
                    field: "id",
                    operator: "in",
                    value: vamper,
                },
            ],
        },
    });

    let nzipcode  :   any;
    nzipcode      = postListQueryResult?.data?.data[0] ? postListQueryResult?.data?.data[0]  : [];
    let kZip = nzipcode.zip_code;

    function amperchahng(value:any) {
        console.log(`selected ${value}`);
        SetAmper(value);
    }

    function povinchahng(value:any) {
        console.log(`selected ${value}`);
        Setpovin(value);
    }

    function districtchahng(value:any) {
        console.log(`selected ${value}`);
        Setdist(value);
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
      };

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("properties-create.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("property.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("properties-create.title")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.createBy = user_data.user_profile.id

                if(vsetTel !== null && vsetTel !== undefined){
                    values.short_tel = vsetTel?.short
                    values.tel = vsetTel?.phone
                }

                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
        >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={24}>
                        <Card style={{ borderRadius: 5, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={16}>
                                        <Col id="Col_w" className="gutter-row" span={24}>
                                            <Row gutter={16}>
                                                <Col className="gutter-row" span={8}>
                                                    <Form.Item  label={t("projects.title")} name="project">
                                                        <Select
                                                            placeholder={t("select.sel")} 
                                                            {...SelProjectType}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row" span={8}>
                                                    <Form.Item  label={t("pp-type.title")} name="pp_type"
                                                        rules={[
                                                            {
                                                                required: true, message: 'Please select property type.'
                                                            },
                                                        ]}
                                                    >
                                                        <Select 
                                                            placeholder={t("select.sel")}
                                                            {...Selpptype}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col className="gutter-row" span={8}>
                                                    <Form.Item  label={t("project-brand.title")} name="project_brand">
                                                        <Select placeholder={t("select.sel")}
                                                            {...selPJBRAND}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col className="gutter-row" span={6}>
                                            <Form.Item  label={t("properties.code")} name="property_code"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter property code.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={18}>
                                            <Form.Item  label={t("properties.name")} name="property_name"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter property name.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}>
                                            <Form.Item label={t("properties.developer")} name="property_under"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select.'
                                                    },
                                                ]}>
                                                    <Select placeholder={t("select.sel")}>
                                                        <Option value="Major">Major</Option>
                                                        <Option value="Non Major">Non Major</Option>
                                                    </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col span={12}></Col>

                                        <Col span={12}>
                                            <Form.Item  label={t("properties.area-size")} name="area_size"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter area size.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={24}>
                                            <Row gutter={16}>
                                                <Col className="gutter-row" span={12}>
                                                <Form.Item  label={t("lat")} name="lat">
                                                    <Input/>
                                                </Form.Item>


                                                </Col>
                                                <Col className="gutter-row" span={12}>
                                                    <Form.Item  label={t("lng")} name="lng">
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                                <Col className="gutter-row" span={24}>
                                                    <Form.Item  label={t("properties.about")}name="about"
                                                        rules={[
                                                            {
                                                                // required: true, message: 'please enter title.'
                                                            },
                                                        ]}>
                                                        <TextArea style={{ height: 30 }} />
                                                    </Form.Item>
                                                </Col>

                                                <Divider />

                                                <Col  className="gutter-row" span={24}>
                                                    <Form.Item name="address" label={t("user-profiles-create.address")}
                                                        rules={[
                                                            {
                                                                required: true, message: 'Please input address'
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                                    </Form.Item>

                                                    <Row gutter={16}>
                                                        <Col className="gutter-row" span={12}>
                                                            <Form.Item name="address_soi" label={t("user-profiles-create.soi")}>
                                                                <Input/>
                                                            </Form.Item>

                                                            <Form.Item name="address_provice" label={t("user-profiles-create.provice")}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please input province'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select 
                                                                    placeholder={t("select.sel")}
                                                                    {...SelProvice}
                                                                    onChange={povinchahng}/>
                                                            </Form.Item>

                                                            <Form.Item name="address_district" label={t("user-profiles-create.district")}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please input sub district'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select 
                                                                    placeholder={t("select.sel")}
                                                                    {...SelDistrict} 
                                                                    onChange={SetAmper}/>
                                                            </Form.Item>

                                                            <Form.Item  label={t("properties.tax")} name="tax_no">
                                                                <Input/>
                                                            </Form.Item>

                                                            <Form.Item name="tel" label={t("user-profiles.tel")}>
                                                                <ConfigProvider locale={en}>
                                                                    <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{height: "32px",borderRadius: "5px",padding: "0"}}
                                                                        onChange={(v) => {
                                                                            setTel(v);
                                                                        }}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                            }
                                                                        }}
                                                                        minLength={9}
                                                                        maxLength={12}
                                                                    />
                                                                </ConfigProvider>
                                                            </Form.Item> 
                                                        </Col>

                                                        <Col className="gutter-row" span={12}>
                                                            <Form.Item name="address_road" label={t("user-profiles-create.road")}>
                                                                <Input/>
                                                            </Form.Item>

                                                            <Form.Item name="address_amphure" label={t("user-profiles-create.amphure")}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select district'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select placeholder={t("select.sel")}{...SelAmphure} 
                                                                    onChange={amperchahng}/>
                                                            </Form.Item>

                                                            <Form.Item name="zip_code" label={t("user-profiles-create.post-code")}>
                                                                <div style={{visibility: "hidden",height: "0px"}}>{kZip}</div>
                                                                <Input disabled defaultValue={kZip} value={kZip} />
                                                            </Form.Item>

                                                            <Form.Item  label={t("properties.fax")} name="fax">
                                                                <Input />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>     
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={12}/>
                                        <Col className="gutter-row" span={12} style={{borderLeft: "1px solid #ddd"}}>
                                            <Form.Item
                                                name="logo"
                                                label={t("properties.logo")}
                                                valuePropName="fileList"
                                                getValueProps={(data) => getValueProps(data, API_URL)}
                                            >
                                                <Upload.Dragger
                                                    style={{borderRadius: 10}}
                                                    name="files"
                                                    action={`${API_URL}/upload`}
                                                    headers={{
                                                        Authorization: `Bearer ${localStorage.getItem(
                                                            TOKEN_KEY,
                                                        )}`,
                                                    }}
                                                    listType="picture"
                                                    multiple
                                                    // {...uploadProps_coverpix}
                                                    maxCount={1}
                                                    onChange={fileChange}
                                                >
                                                    <p className="ant-upload-text">{t("upload")}</p>
                                                </Upload.Dragger>
                                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                                            </Form.Item>
                                            <p style={{textAlign: "center", fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 512*512 PNG, JPG size 5MB</p>

                                            <Form.Item
                                                name="image_list"
                                                label={t("properties.img-list")}
                                                valuePropName="fileList"
                                                getValueProps={(data) => getValueProps(data, API_URL)}
                                            >
                                                <Upload.Dragger
                                                    style={{borderRadius: 10}}
                                                    name="files"
                                                    action={`${API_URL}/upload`}
                                                    headers={{
                                                        Authorization: `Bearer ${localStorage.getItem(
                                                            TOKEN_KEY,
                                                        )}`,
                                                    }}
                                                    listType="picture"
                                                    multiple
                                                    // {...uploadProps_coverpix}
                                                    maxCount={5}
                                                    onChange={fileChange}
                                                    beforeUpload={beforeUpload}
                                                >
                                                    <p className="ant-upload-text">{t("upload")}</p>
                                                </Upload.Dragger>
                                                {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                                            </Form.Item>
                                            <p style={{textAlign: "center", fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Picture 1314*783 PNG, JPG size 5MB</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Card>
                    </Col>
                </Row>
            </Form>
        </Create>
    );
};
