/* eslint-disable */
import { Badge, Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, Space, Table, Tabs, TextField, useModalForm, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog , Ivehicle } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";

let dataVHC: any = [];

export const VehicleTList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Vehicle Types");
    const { TabPane } = Tabs;
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<Ivehicle>();
    const ed_point                          = "vehicle-types";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()
    const [selecteDeleteStatus, setSelectedDeleteStatus] = useState<any>('Y')
    const [activeBadgeNumber, setActiveBadgeNumber] = useState<any>(null)
    const [trashBadgeNumber, setTrashBadgeNumber] = useState<any>(null)

    const { tableProps,searchFormProps,tableQueryResult } = useTable<Ivehicle,HttpError, Ivehicle>({
        metaData: {
            populate: '*'
        },  
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { deleteStatus } = params;

            filters.push(
                {
                    field       : "deleteStatus",
                    operator    : "eq",
                    value       : deleteStatus
                },
            );
            return filters;
        },
        initialFilter: [
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : "N"
            },
        ],
        permanentSorter: [
            {
                field: datafield,
                order: dataorder,
            },
        ],
    });

    // const datavhc = useList<Ivehicle>({ 
    //     resource: "vehicle-types" ,
    //     config:{
    //         sort: [{ order: "desc", field: "id" }],
    //         filters: [
    //             {
    //                 field       : "deleteStatus",
    //                 operator    : "in",
    //                 value       : ["N","Y"]
    //             },
    //         ],
    //         pagination:{current: 1, pageSize: 10000000}
    //     },
    //     queryOptions:{onSuccess(data) {
    //         dataVHC = data?.data;
    //     },}
    // });

    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<Ivehicle>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,
                };
            }
        },
    });

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'vehicle-types',
                SysPkID         : id+""
            },
            successNotification : false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        // setSelectedDeleteStatus(v)
        searchFormProps.form?.submit();
        localStorage.setItem("keyU",v)
        localStorage.setItem("keyTabUserPortal", v)
        
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose
    } = useModalForm<any>({
        resource: "vehicle-types",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "vehicle-types",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data) {
            
        },}
    });

    const API_URL = useApiUrl();
    const getData = async ()=>{
        // setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/vehicle-types?populate=*&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(selecteDeleteStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${selecteDeleteStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${selecteDeleteStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            // if(tableSorter?.title && tableSorter?.order){
            //     apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            // }
            // else{
            //     apiEndPoint += `&sort[id]=desc`
            // }
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data
                    if(dataLoad?.meta?.pagination?.total){
                        const total = dataLoad?.meta?.pagination?.total > 0 ? dataLoad?.meta?.pagination?.total : null
                        if(selecteDeleteStatus.toLowerCase() == 'y'){
                            setTrashBadgeNumber(total)
                        }
                        else{
                            setActiveBadgeNumber(total)
                        }
                    }
                    // const resultList = dataLoad?.data?.map((item: any) => {
                    //     const attributes = item?.attributes
                    //     let createBy = null
                    //     let updateBy = null
                    //     if(attributes?.createBy?.data){
                    //         createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                    //     }
                    //     if(attributes?.updateBy?.data){
                    //         updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                    //     }
                    //     const result = {id: item?.id , ...attributes}
                    //     result.createBy = createBy
                    //     result.updateBy = updateBy
                    //     return result
                    // })
                    // setListdata(resultList || [])
                    // setMetaPagination(dataLoad?.meta?.pagination)
                    // setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    // setLoadingTable(false)
                });
        } catch (error) {
            // setLoadingTable(false)
        }
    }
    
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    // }, [page,pageSizeChange,tableSorter,selecteDeleteStatus,Identity_data])
    }, [selecteDeleteStatus,Identity_data])

    
    useEffect(() => {
        if(Identity_data){
            setSelectedDeleteStatus('N')
        }
    }, [Identity_data])


    return <>
            <List 
                title={t("vehicle.bc_title_t")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.bc_title_t")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form {...searchFormProps}>
                            <Form.Item name="deleteStatus" >
                                <Tabs 
                                    defaultActiveKey={"N"}
                                    style={{ marginTop: 10, marginBottom: 10 }} 
                                    onChange={onStatusChange} 
                                >
                                    <TabPane
                                        key={"N"}
                                        tab={
                                            <span>
                                                {"ACTIVE"}
                                                <Badge count={activeBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                            </span>
                                        }
                                    />

                                    <TabPane
                                        key={"Y"}
                                        tab={
                                            <span>
                                                {"TRASH"}
                                                <Badge count={trashBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                            </span>
                                        }
                                    />
                                </Tabs>
                            </Form.Item>
                        </Form>
                    </Col>
                        
                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                {/* <CreateButton id="create_btn"  onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
 
                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:tableQueryResult.data?.total,
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}  
                >
                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/>                     */}
                    {/* <Table.Column dataIndex="id" title={t("Lid")} align="center" width={150} sorter
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    <Table.Column title={t("vehicle.Ltype-code")} align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.type_code < b?.type_code) {return -1}
                            if (a?.type_code > b?.type_code) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.type_code:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={"TYPE NAME (TH)"} align="center" width={120} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.type_nameTH < b?.type_nameTH) {return -1}
                            if (a?.type_nameTH > b?.type_nameTH) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.type_nameTH:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title={"TYPE NAME (EN)"} align="center" width={120} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.type_nameEN < b?.type_nameEN) {return -1}
                            if (a?.type_nameEN > b?.type_nameEN) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record? record.type_nameEN:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                                {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                                {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />
                  
                    <Table.Column<Ivehicle>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>
                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                // onClick={()=> editModalShow(record.id)}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            <Modal 
                {...createModalProps}
                title={"Vehicle Type Create"}
                okText = {"Create"}
                maskClosable={false}
                width={300}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.type_code = values.type_code;
                        values.type_nameTH = values.type_nameTH;
                        values.type_nameEN = values.type_nameEN;
                        
                        values.deleteStatus = "N";
                        values.createBy = user_data.user_profile.id;
                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type-code")} name="type_code"
                                rules={[
                                    {
                                        required: true, message: 'Please enter type code.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type-name_th")} name="type_nameTH"
                                rules={[
                                    {
                                        required: true, message: 'Please enter type name(th).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type-name_en")} name="type_nameEN"
                                rules={[
                                    {
                                        required: true, message: 'Please enter type name(en).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Vehicle Type Edit"}
                okText = {"Save"}
                maskClosable={false}
                width={300}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.type_code = values.type_code;
                        values.type_nameTH = values.type_nameTH;
                        values.type_nameEN = values.type_nameEN;
                        
                        values.deleteStatus = "N";
                        values.updateBy = user_data.user_profile.id;
                        return (
                            editProps.onFinish &&
                            editProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={8}>
                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type-code")} name="type_code"
                                rules={[
                                    {
                                        required: true, message: 'Please enter type code.'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type-name_th")} name="type_nameTH"
                                rules={[
                                    {
                                        required: true, message: 'Please enter type name(th).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24} className="gutter-row">
                            <Form.Item  label={t("vehicle.type-name_en")} name="type_nameEN"
                                rules={[
                                    {
                                        required: true, message: 'Please enter type name(en).'
                                    },
                                ]}>
                                    <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}