/* eslint-disable */
import { Create, Form, Select, useForm, useSelect, Row, Col, Card, List, Breadcrumb, Space, RadioChangeEvent, Icons, Modal, Divider, Tooltip, Upload, RcFile, message, Avatar, Input, Button, Radio } from "@pankod/refine-antd";
import { HttpError, useApiUrl, useCreate, useGetIdentity, useList, useTranslate,  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { ICompany, IDepartment, IEventlog, IProperties, IRManage, IUserprefix, IUserProfile, IUserSubType, IUserType, IUserTypeMap } from "interfaces";
import { useEffect, useRef, useState } from "react";
import { TOKEN_KEY } from "../../constants";
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from "antd-country-phone-input";
import en from 'world_countries_lists/data/countries/en/world.json';

let MAILDATA:any = [];
let defaultPixurl: any = "../../images/svg/image.svg";
let positiondt: any = undefined;
let propdt: any = undefined;
let roledt: any = undefined;

export const UserManageCreate = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Dragger } = Upload;
    const refUpload = useRef<any>();
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const [selUtype, setselUtype] = useState<any>();
    const [vTel, setTel] = useState<CountryPhoneInputValue>();
    const [vCompany, setCompany] = useState<any>();
    const [vDepartment, setDepartment] = useState<any>();
    const [disabled, setDisabled] = useState(false);

    const [ vProp, setProp ] = useState<any>();
    const [ vPropArr, setPropArr] = useState<any>([]);
    const [vPropArrC, setPropArrC] = useState<any>([]);

    const [vRole, setRole] = useState<any>();
    const [vRoleArr, setRoleArr] = useState<any>([]);
    const [vRoleArrC, setRoleArrC] = useState<any>([]);

    const [vPosition, setPosition] = useState<any>();
    const [vPositionArr, setPositionArr] = useState<any>([]);
    const [vPositionArrC, setPositionArrC] = useState<any>([]);

    // AVATAR
    const [vpixlogo, setpixlogo] = useState<any>([]);
    const [swpixList, setswpixList] = useState<boolean>(true);
    const [pixURL, setpixURL] = useState<any>();
    const [vpixChange, setpixChange] = useState<any>();
    
    //CHECK EMAIL
    const [vUserEmail, setUserEmail] = useState<any>();
    const [vcheckEmail, setcheckEmail] = useState();
    const [vdisabledSAVE, setdisabledSAVE] = useState(true);
    const [vMAILC, setMAILC] = useState<any>();
    const [vICONSEARCH, setICONSEARCH] = useState<any>(<Icons.SearchOutlined />);
    const [vSTYPEICON, setSTYPEICON] = useState<any>("#626262");
    const [vSTYPEINPUT, setSTYPEINPUT] = useState<any>("1px solid #d9d9d9");
    const [vTOOLTIP, setTOOLTIP] = useState<any>(t("msg-vld-mail.tooltip-search"));

    const [dtmgnProp, setmgnProp] = useState<any>([]) //MGNPROP

    const { mutate: createUser} = useCreate<IUserTypeMap>();
    const { mutate: createUserTypeMap} = useCreate<IUserTypeMap>();
    
    const { form, formProps, saveButtonProps }  = useForm<IUserProfile,HttpError,{}>(
        // {
        //     onMutationSuccess(data, variables, context) {
        //         let recordData:any = []
        //         recordData = data?.data? data?.data : [];
        //         mutateCreateUser(recordData.data.id);
        //     },
        // }
        {onMutationSuccess(data, variables, context) {
            let recordData:any = data?.data? data?.data : [];
            mutateCreateUser(recordData.data.id);
        },}
    );

    const userEmail = useList<IUserProfile>({
        metaData: {
            populate: '*'
        },
        resource: "user-profiles",
        config: {
            filters: [
                {
                    field: "user][email]",
                    operator: "in",
                    value: vMAILC !== undefined ? vMAILC : null,
                },
            ],
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            MAILDATA = dataLoad;
        },}
    });

    const position = useList<IUserSubType>({
        resource: "user-sub-types",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "sub_type_name",
                    operator: "in",
                    value: ["Manager", "HOD", "Editor"]
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        },
        queryOptions: {
            onSuccess(data) {
                let dataLoad = data?.data;
                positiondt = dataLoad;
            }
        }
    });

    const { selectProps: selProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ],
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.property_name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    propdt = newData;
                }
            },
        }
    });

    const { selectProps: selComp } = useSelect<ICompany>({
        resource: "companies",
        optionLabel: "company_name",
        optionValue: "id",
    });

    const { selectProps: selDepart } = useSelect<IDepartment>({
        resource: "departments",
        optionLabel: "dept_name",
        optionValue: "id",
        filters: [
            {
                field: 'company][id]',
                operator: 'eq',
                value: vCompany
            }
        ]
    });

    const { selectProps: selRole } = useSelect<IRManage>({
        resource: "role-managements",
        optionLabel: "custom_role_name",
        optionValue: "id",
        fetchSize: 1000,
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.custom_role_name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    roledt = newData;
                }
            },
        }
    });

    const { selectProps: selPrefix } = useSelect<IUserprefix>({
        resource: "user-prefixes",
        optionLabel: "prefix_name",
        optionValue: "id",
    });

    const { selectProps: selUserType } = useSelect<IUserType>({
        resource: "user-types",
        optionLabel: "type_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'type_name',
                operator    : 'in',
                value       : ["Staff","Admin"]
            }
        ]
    });

    const success = () => {
        Modal.success({
            title: <span>{t("msg-vld-mail.title-success")}</span>,
        //   content: 'some messages...some messages...',
        });
    };

    const error = () => {
        Modal.error({
          title: <span>{t("msg-vld-mail.title-error")}</span>,
          content: <span>{t("msg-vld-mail.content-error")}</span>,
        });
    };

    const warning = () => {
        Modal.warning({
          title: <span>{t("msg-vld-mail.title-warning")}</span>,
          content: <span>{t("msg-vld-mail.content-warning")}</span>,
        });
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function onchangeEmail(value: any) {
        setMAILC(value);
        setUserEmail(value)
        setcheckEmail(vUserEmail);

        if(vcheckEmail !== vUserEmail){
            setICONSEARCH(<Icons.SearchOutlined />);
            setTOOLTIP(t("msg-vld-mail.tooltip-search"));
            setdisabledSAVE(true);
            setSTYPEICON("inherit");
        }
    }

    function handlesearchemail(email: any) {
        if(email !== null && email !== undefined && email !== ""){
            if(vMAILC !== undefined && vMAILC !== null){
                opvalidateModal();
            }
        }else{
            error();
            setTOOLTIP(t("msg-vld-mail.tooltip-error"));
            setICONSEARCH(<Icons.StopOutlined />);
            setSTYPEICON("#cf1322");
            setSTYPEINPUT("1px solid #f5222d");
            setdisabledSAVE(true);
        }
    }

    function opvalidateModal() {
        if(MAILDATA?.length > 0){
            error();
            setTOOLTIP(t("msg-vld-mail.tooltip-error"));
            setICONSEARCH(<Icons.StopOutlined />);
            setSTYPEICON("#cf1322");
            setSTYPEINPUT("1px solid #f5222d");
            setdisabledSAVE(true);
        }else{
            if(vSTYPEICON !== "#389e0d"){
                success();
                setTOOLTIP(t("msg-vld-mail.tooltip-success"));
                setICONSEARCH(<Icons.CheckOutlined />);
                setSTYPEICON("#389e0d");
                setSTYPEINPUT("1px solid #d9d9d9");
                setdisabledSAVE(false);
            }
        }
    }

    function fileChange(e: any) {
        if (e.file.response) {
            let res = e.file.response;
            changepixUrl(res[0].url, res[0])
        }
    }

    function changepixUrl(url: any, val: any) {
        setpixURL(url);
        setpixChange(val);
        setswpixList(false)
    }

    function removepixList() {
        setpixURL(defaultPixurl);
        setpixChange(undefined);
        setswpixList(true)
    }

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    function toggleDisabledX() {
        if(disabled == true){
            setDisabled(!disabled);
        }
    }

    function clearArr(){
        setProp(undefined);
        setRole(undefined);
        setPosition(undefined);
    }

    function mutateCreateUser(valueID: any){
        if(selUtype?.label == "Admin"){
            createUser({
                resource    : "user-type-mappings",
                values      : {
                    company         : vCompany,
                    department      : vDepartment,
                    user_profile    : valueID,
                    user_type       : selUtype?.id,
                    user_sub_type   : vPosition,
                    role_management : vRole
                },
                successNotification : false,
            });
        }

        if(selUtype?.label == "Staff"){
            if(dtmgnProp?.length > 0){
                for(let i=0 ; i <= dtmgnProp?.length -1; i++){
                    createUserTypeMap({
                        resource    : "user-type-mappings",
                        values      : {
                            company         : vCompany,
                            department      : vDepartment,
                            user_profile    : valueID,
                            user_type       : selUtype?.id,
                            user_sub_type   : dtmgnProp[i]?.position,
                            role_management : dtmgnProp[i]?.role,
                            property        : dtmgnProp[i]?.property
                        },
                        successNotification : false,
                    });
                }
            }
        }
    }

    function onsetmgnProp(key: any, prop: any, role: any, position: any){
        let newData: any = dtmgnProp;

        newData.push(
            {
                "key": key,
                "property": prop,
                "role": role,
                "position": position,
            }
        )

        console.log(">>> newData", newData)

        setmgnProp((pre: any)=> newData)

        clearArr();
        toggleDisabled();
    }

    function ondelmgnProp(key: any){
        let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
        if(findDT !== -1){
            let newData: any = dtmgnProp;
            
            newData?.splice(findDT, 1);
            setmgnProp((pre: any)=> newData);

            clearArr();
            toggleDisabledX();
        }else{
            clearArr();
            toggleDisabledX();
        }
    }

    function onselProp(key: any, e: any){
        let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
        if(findDT !== -1){
            let newData: any = dtmgnProp;

            newData[findDT].property = e;
            setmgnProp((pre: any)=> newData);
        }else{
            setProp(e);
        }
    }

    function onselRole(key: any, e: any){
        console.log(e)
        let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
        if(findDT !== -1){
            let newData: any = dtmgnProp;

            newData[findDT].role = e;
            setmgnProp((pre: any)=> newData);
        }else{
            setRole(e);
        }
    }

    function onselPosition(key: any, e: any){
        let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
        if(findDT !== -1){
            let newData: any = dtmgnProp;

            newData[findDT].position = e;
            setmgnProp((pre: any)=> newData);
        }else{
            setPosition(e);
        }
    }

    //======================================================================================

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("")}
                pageHeaderProps={{ extra : <></>}}
            /> 

            <Space style={{marginBottom: "5%"}}>
                <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("bc_user-profiles.path")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("user-profiles.title-create")}</span></Breadcrumb.Item>
                </Breadcrumb>
            </Space>

            <Form 
                {...formProps} 
                layout="vertical"
                onFinishFailed={()=> {
                    //   if(formProps.form?.getFieldError("email") !== undefined && formProps.form?.getFieldError("email").length > 0) {
                    //     setSTYPEINPUT("1px solid #f5222d")
                    //   }else{
                    //     setSTYPEINPUT("1px solid #d9d9d9")
                    //   }
                  }}
                onFinish={(values:any) => {
                    if(vdisabledSAVE == false){
                        values.avatar = vpixChange !== null && vpixChange !== undefined ? vpixChange.id : null
                        values.user_prefix = values.user_prefix;
                        values.first_name = values.first_name;
                        values.last_name = values.last_name;
                        values.email = values.email;
                        values.user_type = selUtype?.id;

                        values.password1 = values.password1;
                        values.password2 = values.password2;

                        values.role = 1;
                        values.isStaff = true;
                        values.deleteStatus = "N";
                        values.createBy = user_data?.user_profile?.id;

                        if (vTel !== null && vTel !== undefined) {
                            values.short_tel = vTel?.short;
                            values.tel = vTel?.phone;
                        }
                        return (
                            formProps.onFinish &&
                            formProps.onFinish(mediaUploadMapper(values))
                        );
                    }else if(vdisabledSAVE == true){
                        if(vSTYPEICON == "inherit"){
                            warning();
                            setTOOLTIP(t("msg-vld-mail.tooltip-warning"));
                            setICONSEARCH(<Icons.WarningOutlined />);
                            setSTYPEICON("#d46b08");
                        }else{
                            error();
                            setTOOLTIP(t("msg-vld-mail.tooltip-error"));
                            setICONSEARCH(<Icons.StopOutlined />);
                            setSTYPEICON("#cf1322");
                        }
                        setSTYPEINPUT("1px solid #f5222d");
                    }
                }}
            >
                <Row gutter={8}>
                    <Col span={4} className="gutter-row">
                        <Form.Item
                            name="avatar"
                            className="form_projectPix"
                            style={{ marginBottom: "5%" }}
                        >
                            <Tooltip placement="left" color="lime" title={"AVATAR PROFILE"}>
                                <Dragger className="dragger_upload_custom_i_w"
                                    {...vpixlogo}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                    showUploadList={false}
                                    openFileDialogOnClick={false}
                                    ref={refUpload}
                                >
                                    <div className="dragger_upload_custom_i_m">
                                        <div className="projectPix_hover">
                                            <div>
                                                {swpixList == false ?
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={14} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <Avatar className="projectPix" src={pixURL} style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
                                        <span style={{fontSize:"11px", alignItems:"stretch", color: "#DF6677"}}>*Picture 512*512 PNG, JPG Max size 5MB</span>
                                    </div>
                                </Dragger>
                            </Tooltip>
                        </Form.Item>
                    </Col>
                    <Col span={20} className="gutter-row">
                        <Row gutter={8}>
                            <Col span={3} className="gutter-row">
                                <Form.Item name="user_type" label={t("user-profiles.user-type")}
                                    rules={[
                                        {
                                            required: true, message: 'Please select user type.'
                                        },
                                    ]}
                                >
                                    <Select {...selUserType}
                                        placeholder={t("select.sel")}
                                        onChange={(e:any ,value:any) => setselUtype({id: value?.value, label: value?.label})}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="user_prefix" label={t("user-profiles.prefix")}>
                                    <Select placeholder={t("select.sel")} {...selPrefix} />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.name")} name="first_name" rules={[{ required: true, message: 'Please input first name' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.lastname")} name="last_name" rules={[{ required: true, message: 'Please input last name' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item name="tel" label={t("user-profiles.tel")}>
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{ height: "32px", borderRadius: "5px", padding: "0" }}
                                            onChange={(v) => { setTel(v) }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            minLength={9}
                                            maxLength={12}
                                        />
                                    </ConfigProvider>
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input email' }, { type: 'email', message: 'email must be formatted correctly' }]}>
                                    <Input 
                                        value={vUserEmail} 
                                        onChange={e => {
                                            onchangeEmail(e.target.value)
                                            if(vSTYPEINPUT == "1px solid #f5222d"){
                                                setSTYPEINPUT("1px solid #d9d9d9")
                                            }
                                        }}
                                        style={{border : vSTYPEINPUT}}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={2} className="gutter-row">
                                <Form.Item label=" ">
                                    <Tooltip placement="top" title={vTOOLTIP}>
                                        <Button style={{borderRadius: "5px",color: vSTYPEICON}} icon={vICONSEARCH} onClick={() => handlesearchemail(vUserEmail)}/>
                                    </Tooltip>
                                </Form.Item>
                            </Col>

                            <Col span={16} className="gutter-row" />

                            <Col span={6} className="gutter-row">
                                <Form.Item
                                    name="password1"
                                    label={t("staff-profiles.pwd1")}
                                    rules={[
                                        {
                                            required: true,
                                            // message: 'please input your password!',
                                            message: '',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                console.log('value', value)
                                                console.log('value_', _)
                                                // if (value.length >= 8 && value.length <= 16) {
                                                //     return Promise.resolve();
                                                // }
                                                // return Promise.reject(new Error('Password must be 8-16 characters only '));

                                                if (value.length >= 8 && value.length <= 16) {
                                                    return Promise.resolve();
                                                }

                                                if (value.length <= 8 && value.length >= 16) {
                                                    return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                }

                                                if (value.length < 0) {
                                                    return Promise.reject(new Error('please input your password'));
                                                }

                                                if (value == "") {
                                                    return Promise.reject(new Error('please input your password'));
                                                }

                                            },
                                        }),
                                    ]}
                                    hasFeedback

                                >
                                    <Input.Password style={{ borderRadius: 5 }}
                                        type="password"
                                        placeholder="Enter your password"
                                        iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                        minLength={8}
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item
                                    name="password2"
                                    label={t("staff-profiles.pwd2")}
                                    dependencies={['password1']}
                                    hasFeedback
                                    rules={[{ required: true, message: 'Please confirm your password!' },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password1') === value) {
                                                return Promise.resolve();
                                            }
                                            // return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                            return Promise.reject(new Error('Password is not match'));
                                        },
                                    }),
                                    ]}
                                >
                                    <Input.Password style={{ borderRadius: 5 }}
                                        type="password"
                                        placeholder="Enter your password"
                                        iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                        minLength={8}
                                        maxLength={16}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider style={{ margin: "10px" }} />

                {selUtype && selUtype?.label == "Admin" ?
                    <>
                        <Row gutter={8}>
                            <Col span={4} className="gutter-row" />
                            <Col span={20} className="gutter-row">
                                <Row gutter={8} style={{ marginTop: "1rem" }}>
                                    <Col className="gutter-row" span={12}>
                                        <Row gutter={8}>
                                            <Col className="gutter-row" span={6}>
                                                <Form.Item>
                                                    <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                    <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                                <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                    <Select
                                                        {...selComp}
                                                        onChange={(e) => {
                                                            setCompany(e);
                                                            form?.resetFields(["SelDept"]);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Row gutter={8}>
                                            <Col className="gutter-row" span={6}>
                                                <Form.Item>
                                                    <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                    <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={18}>
                                                <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                    <Select
                                                        {...selDepart}
                                                        onChange={(e) => {
                                                            setDepartment(e);
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <Row gutter={8}>
                                    <Col className="gutter-row" span={12}>
                                        <Row gutter={5}>
                                            <Col className="gutter-row" span={6}>
                                                <Form.Item>
                                                    <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                    <span style={{ fontSize: "0.7vw", color: "#000" }}>Role</span>
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                                <Form.Item name={"role"} rules={[{ required: true, message: 'Please select role' }]}>
                                                    <Select {...selRole} onChange={(e) => setRole(e)} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                        <Row gutter={8}>
                                            <Col className="gutter-row" span={6}>
                                                <Form.Item>
                                                    <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                    <span style={{ fontSize: "0.7vw", color: "#000" }}>Position</span>
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" span={18}>
                                                <Form.Item
                                                    name="position"
                                                    rules={[
                                                        {
                                                            required: true, message: 'Please select position'
                                                        },
                                                    ]}
                                                >
                                                    <Radio.Group name="radiogroup" onChange={(e) => setPosition(e?.target?.value)}>
                                                        {
                                                            positiondt?.map((el: any, index: any) => {
                                                                return <>
                                                                    <Radio key={el.id} value={el.id} style={{ fontSize: "0.7vw", color: "#000" }}>{el.sub_type_name}</Radio>
                                                                </>
                                                            })
                                                        }
                                                    </Radio.Group>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                    : selUtype?.label == "Staff" &&
                    <Row gutter={8}>
                        <Col span={4} className="gutter-row" />
                        <Col span={20} className="gutter-row">
                            <Row gutter={8} style={{ marginTop: "1rem" }}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={8}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                <Select
                                                    {...selComp}
                                                    onChange={(e) => {
                                                        setCompany(e);
                                                        form?.resetFields(["SelDept"]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={8}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                <Select
                                                    {...selDepart}
                                                    onChange={(e) => {
                                                        setDepartment(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={8}>
                                <Col className="gutter-row" span={24}>
                                    <Form.List name="fields">
                                        {(fields, { add, remove }) => {
                                            return (
                                            <div>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={4}>
                                                        <Form.Item>
                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>  Properties Management</span>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col className="gutter-row" span={3}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => { add(); toggleDisabled();}}
                                                                style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                                disabled={disabled}
                                                            >
                                                                <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                {fields.map((field, index) => (
                                                    <div key={field.key}>
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "0.5rem"}}><span>{index+1} .</span></div> </Col>
                                                            <Col className="gutter-row" span={13}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={6}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>* </span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Properties</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={18}>
                                                                        <Form.Item
                                                                            // name={"property_" + index + 1}
                                                                            // name={index + 1}
                                                                            // name=""
                                                                            name={[index, 'property']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select property'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select 
                                                                                // {...selProp}
                                                                                showSearch
                                                                                options={propdt}
                                                                                onChange={(e) => onselProp(field.key ,e)}
                                                                                filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={10}>
                                                                <Row gutter={16}>
                                                                    <Col className="gutter-row" span={4}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>* </span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Role</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={20}>
                                                                        <Form.Item
                                                                            name={[index, 'role']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select role'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select 
                                                                                showSearch 
                                                                                options={roledt} 
                                                                                onChange={(e) => onselRole(field.key ,e)}
                                                                                filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={1}/>

                                                            <Col className="gutter-row" span={14}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={5}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>* </span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Position</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={19}>
                                                                        <Form.Item
                                                                            name={[index, 'position']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select position'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Radio.Group name="radiogroup" onChange={(e) => onselPosition(field.key ,e?.target?.value)}>
                                                                                {
                                                                                    positiondt?.map((el:any,index:any) => {
                                                                                        return <>
                                                                                                <Radio key={el.id} value={el.id} style={{fontSize: "0.7vw",color: "#000"}}>{el.sub_type_name}</Radio>
                                                                                            </>
                                                                                        })
                                                                                }
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={4}>
                                                                {
                                                                    vProp && vRole && vPosition &&
                                                                        <>{fields?.length == index+1 &&
                                                                            <Button
                                                                                size="small"
                                                                                // onClick={() => { onsetPropArr(); onsetRoleArr(); onsetPositionArr(); clearArr(); toggleDisabled();}}
                                                                                onClick={() => onsetmgnProp(field.key, vProp, vRole, vPosition)}
                                                                                danger
                                                                                style={{marginRight: "3%",background: "#fff",borderColor: "#A1D64F",color: "#A1D64F"}}
                                                                            >
                                                                                <Icons.CheckOutlined/>
                                                                            </Button>
                                                                        }
                                                                        </>
                                                                }

                                                                {
                                                                    fields.length > 0 && (
                                                                        <Button
                                                                            size="small"
                                                                            className="dynamic-delete-button"
                                                                            // onClick={() => { remove(field.name); clearArr(); toggleDisabledX(); }}
                                                                            onClick={() => { remove(field.name); ondelmgnProp(field.key);}}
                                                                            danger
                                                                        >
                                                                            <Icons.CloseOutlined />
                                                                        </Button>
                                                                    )
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <div style={{marginBottom: "1vh", borderTop: fields.length - 1 == index ? "0px":"1px solid #dedede"}} />
                                                    </div>
                                                ))}
                                            </div>
                                            );
                                        }}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </Form>
        </Create>
    );
};