

import { Button, message } from '@pankod/refine-antd';
import { useApiUrl } from '@pankod/refine-core';
import React, { useCallback, useRef } from 'react';
import Webcam from 'react-webcam';
import { TOKEN_KEY } from "../../constants";

const videoConstraints = {
  width: 640,
  height: 360,
  facingMode: "user"
};

interface WebcamComponentProps {
  setShowCamera: (value: boolean) => void;
  setImageUrl: (value: string | null) => void;
  setchecked: (value: boolean) => void;
  setjson: (value: any[]) => void;


}
let jsonformat : any;
const base64ToBlob = (base64: string) => {
  const binaryString = window.atob(base64.split(',')[1]);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return new Blob([bytes], {type: 'image/jpeg'});
};

export const WebcamComponent: React.FC<WebcamComponentProps> = ({ setShowCamera, setImageUrl,setchecked,setjson}) => {
  const webcamRef = useRef<Webcam>(null);
  const API_URL = useApiUrl();

  const validateFile = (blob: Blob) => {
    const isJpgOrPng = blob.type === 'image/jpeg' || blob.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return false;
    }
    const isLt2M = blob.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error('Image must smaller than 5MB!');
      return false;
    }
    return true;
  };


const uploadImage = async (blob: Blob) => {
  if (!validateFile(blob)) return;

  const formData = new FormData();
  formData.append("files", blob, "webcam.jpg");
  const token = localStorage.getItem(TOKEN_KEY);

  try {
      const response = await fetch(`${API_URL}/upload`, {
          method: "POST",
          headers: { Authorization: `Bearer ${token}` },
          body: formData,
      });
      
      if (response.ok) {
          const jsonData = await response.json();  // รอรับ JSON data ก่อน
          console.log("Image uploaded successfully", jsonData);
          setjson(jsonData);  // อัปเดต state ด้วยข้อมูล JSON ที่ได้
      } else {
          console.error("Upload failed");
          setjson([]);  // อัปเดต state ให้เป็น empty array ถ้า upload ไม่สำเร็จ
      }
  } catch (error) {
      console.error("Error uploading image:", error);
      setjson([]);  // อาจตั้งค่า error หรือ empty array ถ้ามีข้อผิดพลาด
  }
};

const capture = useCallback(() => {
  const imageSrc = webcamRef.current?.getScreenshot();
  if (imageSrc) {
      const blob = base64ToBlob(imageSrc);
      setImageUrl(imageSrc);
      setShowCamera(false);
      setchecked(true);
      uploadImage(blob);  // เรียกใช้ upload และ setjson จะเกิดขึ้นในนั้น
  } else {
      setImageUrl(null);
  }
}, [setImageUrl, setShowCamera, uploadImage, setchecked, setjson]);

// ส่วนอื่นๆ ของ component ที่ไม่มีการเปลี่ยนแปลง

  return (
    <>
      <Webcam
        audio={false}
        height={360}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={640}
        videoConstraints={videoConstraints}
      />
      <Button onClick={capture}>Capture photo</Button>
      <Button onClick={() => setShowCamera(false)}>Close Camera</Button>
    </>
  );
};


