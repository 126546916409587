/* eslint-disable */
import { Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal,  Row,  Space, Table, Tag, TextField, Typography, useSimpleList, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useExport, useGetIdentity, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { IAppointmentType, IBannerMgn, IEventlog, IPopupMgn, IUnit } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import {  LockOutlined, UnlockOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export const PopupMgnList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Popup Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const [keepTitle, setKeepTitle] = useState<any>();
    
    const { tableProps,searchFormProps } = useTable<any,HttpError, any>({
        resource: 'popup-managements',
        metaData: {
            populate: [
                '*',
                'properties',
                'user_types',
                'user_sub_types',
                'content_new',
                'content_announcement',
                'content_promotion',
                'content_ae',
                'content_blog',
                'privilege',
                'updateBy',
                'createBy',
            ]
        },
        onSearch(params) {
            const filters: CrudFilters = [];

                filters.push(
                    {
                        field       : "title",
                        operator    : "contains",
                        value       : !!keepTitle ? keepTitle : null
                    },
                );

            return filters;
        },
        permanentSorter:[
            {
                field: 'id',
                order: "desc",
            }
        ],
        // permanentFilter:[
        //     {
        //         field : "properties][id]",
        //         operator : data_property !== null ? "in" : "ne",
        //         value: data_property !== null ? data_property : null
        //     }
        // ]
        // initialFilter: [
        //     {
        //         field: "deleteStatus",
        //         operator: "eq",
        //         value : "N" 
        //     }
        // ]
    });

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "popup-managements",
        metaData: {
            populate: [
                '*',
            ]
        },
        pagination:{
            pageSize: 100,
        },
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            // callFetchData();
          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
    }, []);

    // const { Text } = Typography;

    // const onReset = () => {
    //     searchFormProps.form?.resetFields()
    //     searchFormProps.form?.submit()
    // };

    // const convertArrayToString = (array: any[]) => array.join(', ');

    const categoryQueryResult = useOne<IBannerMgn>({
        resource: "banner-managements",
        id: ''
    });
    let paginationLength:any = [];
    paginationLength = categoryQueryResult.data?.data ?  categoryQueryResult.data?.data : [];
    const { confirm } = Modal;
    const { mutate } = useUpdate<IUnit>();
    const ed_point = "popup-managements";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    function onShowSizeChange(current:any, pageSize:any) {
        // console.log('onShowSizeChange : ', current, pageSize);
        setPageSizeChange(pageSize)
    }

    const { triggerExport, isLoading: exportLoading } = useExport<IUnit>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id,  
                };
            }
        },
    });

    // function onStatusChange(){
    //     searchFormProps.form?.submit();
    // }

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {status : 'PUBLIC'}

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        });

        // CreateLogDelete({
        //     resource    : log_endpoint,
        //     values      : {
        //         user_profile    : user_data.user_profile.id,
        //         log_time_stamp  : Date.now(), 
        //         log_event       : event,
        //         menu_event      : 'appointments_type',
        //         SysPkID         : id+""
        //     },successNotification:false
        // });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.status = 'DRAFT'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.status = 'PUBLIC'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function smartTrim(string: string, maxLength: number) {
        if (!string) return string;
        if (maxLength < 1) return string;
        if (string.length <= maxLength) return string;
        if (maxLength == 1) return string.substring(0,1) + '...';
    
        var midpoint = Math.ceil(string.length / 2);
        var toremove = string.length - maxLength;
        var lstrip = Math.ceil(toremove/2);
        var rstrip = toremove - lstrip;
        var totalStr = string.substring(0, midpoint-lstrip) + '...' + string.substring(midpoint+rstrip);

        totalStr = totalStr.replace(/&nbsp;/g, '');
        // return string.substring(0, midpoint-lstrip) + '...' 
        // + string.substring(midpoint+rstrip);

        return totalStr
    }

    const onReset = () => {
        setKeepTitle(undefined)
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    }

    // console.log()
    return <>
            
            <List 
                title={t("popup-mgn.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("popup-mgn.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("popup-mgn.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={18}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            <Form.Item name="title">
                                <Input onChange={(e) => setKeepTitle(e.target.value)} style={{borderRadius: 4,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="submit" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>

                    <Col span={6}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Table {...tableProps} 
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        onShowSizeChange , 
                        total:paginationLength.length, 
                        onChange(Current){ setPage(Current)},
                        hideOnSinglePage: false
                    }}
                >
                    {/* <Table.Column dataIndex="id" title={t("Lid")} /> */}
                    {/* <Table.Column width={150} dataIndex="post_code" title="Post Code" 
                        render={(value:any,record:any)=>{
                            return <>
                                    {
                                        value? 
                                            <TextField value={value} />
                                        :<>
                                            <TextField value={"(ไม่ระบุ)"} />
                                        </>
                                    }
                                </>
                        }}
                    /> */}

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}



                    <Table.Column title={t("banner-mgn.col-title")} width={350}
                        sorter={(a:any, b:any) => {
                            if (a?.title < b?.title) {return -1}
                            if (a?.title > b?.title) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{ 
                            return <>
                                <TextField value={record? record.title:"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="detail2" title="DETAIL" width={500}
                        render={(value:any,record:any)=>{ 
                            return <>
                               
                                {
                                    record.popup_type == false ?
                                        record.content_ae !== null ? <> <TextField value={smartTrim(record.content_ae.detail.replace(/<(.|\n)*?>/g, ''), 100)} /> </> : 
                                        record.content_announcement !== null ? <> <TextField value={smartTrim(record.content_announcement.detail.replace(/<(.|\n)*?>/g, ''), 100)} /> </> : 
                                        record.content_blog !== null ? <> <TextField value={smartTrim(record.content_blog.detail.replace(/<(.|\n)*?>/g, ''), 100)} /> </> : 
                                        record.content_new !== null ? <> <TextField value={smartTrim(record.content_new.detail.replace(/<(.|\n)*?>/g, ''), 100)} /> </> : 
                                        record.content_promotion !== null ? <> <TextField value={smartTrim(record.content_promotion.detail.replace(/<(.|\n)*?>/g, ''), 100)} /> </> : 
                                        record.privilege !== null ? <> <TextField value={smartTrim(record.privilege.detail.replace(/<(.|\n)*?>/g, ''), 100)} /> </> : 
                                        '-'
                                    :

                                        record.detail2_en !== null ?
                                            <>
                                                <TextField value={smartTrim(value.replace(/<(.|\n)*?>/g, ''), 100)} />
                                            </>
                                        :
                                            <>
                                                <TextField value={record.detail2_en !== null ? smartTrim(record.detail2_en.replace(/<(.|\n)*?>/g, ''), 100): '-'} />
                                            </>
                                }
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="detail2_en" title="DETAIL (EN)" width={500}
                        render={(value:any,record:any)=>{ 
                            return <>
                              
                               
                                        record.detail2_en !== null ?
                                            <>
                                                <TextField value={smartTrim(value.replace(/<(.|\n)*?>/g, ''), 100)} />
                                            </>
                                        :
                                            <>
                                                <TextField value={record.detail2_en !== null ? smartTrim(record.detail2_en.replace(/<(.|\n)*?>/g, ''), 100): '-'} />
                                            </>
                                
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="properties" title="PROPERTIES" width={350}
                        render={(value:any,record:any)=>{ 
                            console.log('record', record)
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="properties" title={t("content-post-promotion.col-properties")}  width={150}
                        render={(value:any,record:any)=>{
                            // console.log('record', record)
                            return <>
                                {
                                    record?.property_dev == "All" ?
                                        <Tag className="tag_post"><TextField value={<>All properties</>}  /></Tag>
                                    :
                                    record?.property_dev == "Major" ?
                                        <Tag className="tag_post"><TextField value={<>Only Major properties</>}  /></Tag>
                                    :
                                    record?.property_dev == "Non Major" ?
                                        <Tag className="tag_post"><TextField value={<>Only non Major properties</>}  /></Tag>
                                    :
                                        record?.properties.length !== 0 ?
                                            record?.properties.map((data:any,index:any) => {
                                                // console.log('data : ', data);
                                                return <>
                                                    <Tag className="tag_post"><TextField value={data? data.property_name : <></> }  /></Tag>
                                                </>
                                            })
                                        : <>-</>
                                }
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="user_sub_types" title={t("banner-mgn.col-usertype")} width={150}
                        render={(value:any,record:any)=>{ 
                            // console.log('user type', record)
                            return <>
                               
                                {
                                    record?.user_sub_types.length !== 0 ?
                                        record?.user_sub_types.map((data:any,index:any) => {
                                            // console.log('data : ', data);
                                            return <> 
                                                <Tag className="tag_post"><TextField value={data? data.sub_type_name : <></> }  /></Tag>
                                            </>
                                        })
                                    : <>-</>
                                }
                            </>
                        }}
                    />    */}


                    {/* <Table.Column  title="PUBLIC DATE" width={120} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.start_date? <>{dayjs(record?.start_date).format("DD/MM/YYYY")}{' - '}{dayjs(record?.end_date).format("DD/MM/YYYY")}</> :"-"} />
                            </>
                        }}
                    /> */}
                    <Table.Column title={t("content-post-promotion.col-public-date")}  width={120}
                        sorter={(a:any, b:any) => {
                            if (a?.start_date < b?.start_date) {return -1}
                            if (a?.start_date > b?.start_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.start_date? dayjs(record?.start_date).format("DD-MM-YYYY") :"-"} />
                            </>
                        }}
                    />
                         <Table.Column title={"END DATE"}  width={120}
                        sorter={(a:any, b:any) => {
                            if (a?.end_date < b?.end_date) {return -1}
                            if (a?.end_date > b?.end_date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            console.log('end', record)
                            return <>
                                <TextField value={record?.end_date? dayjs(record?.end_date).format("DD-MM-YYYY") :"-"} />
                            </>
                        }}
                    />

                    <Table.Column title="STATUS" width={80} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.status < b?.status) {return -1}
                            if (a?.status > b?.status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField value={record ? record?.name : "-"} /> */}
                                {/* <TextField style={{fontWeight: 600}} value={value == true ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Banned</Tag>:<>-</> && value == null ? <>-</>:<></> && value == false ?<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Unbanned</Tag> : <></> }/> */}
                                <TextField value={record?.status ? record?.status : "-"}  />

                            </>
                        }}
                    />

                    <Table.Column title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column title="CREATE DATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    {/* <Table.Column width={30} title="SEQUENCES" align="center"
                     sorter={(a:any, b:any) => {
                        const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                        setDatafield("seq")
                        setDataorder(sorttest)
                        return (0)
                    }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.seq ? record?.seq : "-"} />
                            </>
                        }}
                    /> */}

                    <Table.Column title="LAST UPDATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                                {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                                {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column<any>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                {/* <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton> */}

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                /> */}

                                {/* <DeleteButton
                                     hideText
                                     size="small"
                                     recordItemId={record.id}
                                 /> */}
                                {
                                    record.status === "PUBLIC" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                            />
                                            <Button size="small" title="Inactive" icon={<LockOutlined />} onClick={() => { showDelConfirm(record.id) }} danger></Button>
                                        </>
                                    :
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            />
                                            <Button size="small" title="Active" icon={<UnlockOutlined />} onClick={() => { showUnlockConfirm(record.id) }} ></Button>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}