/* eslint-disable */
import { Breadcrumb, Col, Divider, Edit, Form, Input, Modal, Row, Select, UploadFile, useSelect, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { Upload, notification } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";


export const MjcRepairNewJobEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const format = 'YYYY-MM-DD HH:mm';

    const { Option } = Select;

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [res_data, set_res_data] = useState<any>();
    const [countInterval, setCountInterval] = useState(0);

    const [InpectorName, SetInpectorName] = useState<any>();

    const token = localStorage.getItem(TOKEN_KEY);

    const [modal, contextHolder] = Modal.useModal();

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let job_id = id[1]

    // console.log('item_id', item_id)
    // console.log('job_id', job_id)

    // const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    // Data ส่วนของ รายละเอียด
    const [JobNo, SetJobNo] = useState<any>();
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [Unit, SetUnit] = useState<any>();
    const [Customer, SetCustomer] = useState<any>();
    const [CustomerTelNo, SetCustomerTelNo] = useState<any>();
    const [CustomerEmail, SetCustomerEmail] = useState<any>();
    const [Type, SetType] = useState<any>();


    // Data ส่วนของ รายละเอียดการแจ้งซ่อม
    const [ZoneId, SetZoneId] = useState<any>(undefined); 
    const [ZoneName, SetZoneName] = useState<any>(undefined); 
    const [CateId, SetCateId] = useState<any>(undefined); 
    const [CateName, SetCateName] = useState<any>(undefined); 
    const [CateDetailId, SetCateDetailId] = useState<any>(undefined); 
    const [CateDetailName, SetCateDetailName] = useState<any>(undefined); 
    const [WarantyDate, SetWarantyDate] = useState<any>(undefined); 
    const [WarantyExpire, SetWarantyExpire] = useState<any>(undefined); 
    const [IsWaranty, SetIsWaranty] = useState<any>(undefined); 
    const [RemarkDetail, SetRemarkDetail] = useState<any>(undefined); 
    const [vImgEn, setImgEn] = useState<any>();
    const [vVid, setVid] = useState<any>();


     // MASTER REPAIR DATA
     const [ DataZone, SetDataZone] = useState<any>([]);
     const [ DataCategory, SetDataCategory] = useState<any>([]);
     const [ DataCatDetail, SetDataCatDetail] = useState<any>([]);


    // SELECTED DATA
    // const [ SelectedCateDetail, SetSelectedCateDetail] = useState<any>([]);
    // const [ SelectedCategory, SetSelectedCategory] = useState<any>();
    // const [ SelectedZone, SetSelectedZone] = useState<any>();
    // const [ RemarkStat, SetRemarkStat] = useState<any>("");


    const [InspectorSettingId, SetInspectorSettingId] = useState<any>();

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);
    const [LastTrail, setLastTrail] = useState<any>(undefined);
    const [LastTrailDate, setLastTrailDate] = useState<any>(undefined);
    const [dataSourceTrail, SetDataSourceTrail] = useState<any>([]);


    // Data แก้ไขสถานะรายการ ทำวันนัด
    const [Stat, setStat] = useState<any>();
    const [AppointStart, setAppointStart] = useState<any>();
    const [AppointEnd, setAppointEnd] = useState<any>();
    const [Inspector, SetInspector] = useState<any>();
    const [InspectorProfile, SetInspectorProfile] = useState<any>();
    const [PropertyId, SetPropertyId] = useState<any>();
    const [Remark, SetRemark] = useState<any>("");

    const [PropertyIdJob, SetPropertyIdJob] = useState<any>();

    const [dataSource, SetDataSource] = useState<any>([]);


    // let sla_days:any
    // let sla_hours:any

    function isISOString(dateString:any) {
        const parsedDate = dayjs(dateString);
        if(parsedDate.isValid() && parsedDate.toISOString() === dateString){
            return dateString
        }else{
            return  dayjs(dateString, 'DD/MM/YYYY').format("YYYY-MM-DD");
        }
        // return parsedDate.isValid() && parsedDate.toISOString() === dateString;
    }

    const getDataK = async ()=>{
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairJobDetail/findRepairJobDetailByJobId?stat=${job_id}`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad xxx', dataLoad)
                dataSource.push(dataLoad[0])
                let keepTime = null

                // Set รายละเอียด
                SetJobNo(dataLoad ? dataLoad[0].job_obj.length > 0 ? dataLoad[0].job_obj[0].job_id : '-' : '-')
                SetCreateDate(dataLoad ? dataLoad[0].job_obj[0].create_date ? dataLoad[0].job_obj[0].create_date : '-' : '-')
                // SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by ? dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-')
                // SetTransferDate(isISOString(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date))
                //SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by ? dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YYYY") : '-' : '-')
                keepTime = dayjs(dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date).format("DD/MM/YYYY")
                if (keepTime == "Invalid Date") {
                    SetTransferDate(dataLoad ? dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date : '-')
                }else {
                    SetTransferDate(keepTime)
                }

                if (dataLoad[0].job_obj[0].request_by.unitDetail.transfer_date == "") {
                    SetTransferDate("-")
                }

                SetProperty(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.property_name : '-' : '-')
                SetUnit(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.unit_name : '-' : '-')
                SetPropertyIdJob(dataLoad ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.unitDetail.property_id : '-' : '-')
                
                SetCustomer(dataLoad[0].job_obj[0].request_by.attributes !== undefined ? dataLoad[0].job_obj[0].request_by.attributes.first_name + ' ' + dataLoad[0].job_obj[0].request_by.attributes.last_name : '-')
                SetType(dataLoad[0] ? dataLoad[0].job_obj[0].request_by ? dataLoad[0].job_obj[0].request_by.attributes.user_sub_types.data.length > 0 ? dataLoad[0].job_obj[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-' : '-' : '-')
                // SetCustomerTelNo(dataLoad[0].job_obj[0].request_by.tel !== undefined ? dataLoad[0].job_obj[0].request_by.tel : '-')
                SetCustomerTelNo(dataLoad[0]?.job_obj[0]?.request_by?.tel !== undefined ? dataLoad[0].job_obj[0].request_by.tel : dataLoad[0].job_obj[0]?.request_by?.attributes?.tel)


                // SetCustomerEmail(dataLoad[0].job_obj[0].request_by?.attributes.user !== undefined ? dataLoad[0].job_obj[0].request_by?.attributes.user?.attributes.email : '-')
                SetCustomerEmail(
                    dataLoad[0].job_obj[0].request_by?.attributes.user !== undefined ? 
                        dataLoad[0].job_obj[0].request_by?.attributes.user?.attributes.email 
                    : dataLoad[0].job_obj[0].request_by?.attributes?.username ? dataLoad[0].job_obj[0].request_by?.attributes?.username 
                    : dataLoad[0].job_obj[0].request_by?.username ? dataLoad[0].job_obj[0].request_by?.username : '-'
                )
                
                if(dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date){
                    // const currentDate = dayjs(dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date);
                    // const daysToAdd = dataLoad[0]?.category_detail_obj[0] ? dataLoad[0]?.category_detail_obj[0]?.warantee : 0;
                    // const newDate = currentDate.add(daysToAdd, 'day');
                    // console.log('WarantyExpire', dayjs(newDate).format("YYYY-MM-DD"))
                    // SetWarantyExpire(dayjs(newDate).format("YYYY-MM-DD"))

                    // const currentDate = dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date;
                    let currentDate = dayjs(dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date);
                    if(!currentDate.isValid()){
                        currentDate = dayjs(dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date , 'DD/MM/YYYY')
                    }
                    const parsedDate = dayjs(currentDate, 'YYYY-MM-DD');
                    const daysToAdd = dataLoad[0]?.category_detail_obj[0] ? dataLoad[0]?.category_detail_obj[0]?.warantee : 0;
                    const newDate = parsedDate.add(daysToAdd, 'day');
                    // SetWarantyExpire(dayjs(newDate).format("YYYY-MM-DD"))
                    SetWarantyExpire(dayjs(newDate).format("DD/MM/YYYY") == "Invalid Date" ? "-" : dayjs(newDate).format("DD/MM/YYYY"))
                }

                if (dataLoad[0].job_obj[0].request_by?.unitDetail?.transfer_date == "") {
                    SetWarantyExpire("-")
                }


                // Set รายละเอียดการแจ้งซ่อม
                SetZoneId(dataLoad ? dataLoad[0].zone_obj.length > 0 ? dataLoad[0].zone_obj[0]._id : '-' : '-')
                SetZoneName(dataLoad ? dataLoad[0].zone_obj.length > 0 ? dataLoad[0].zone_obj[0].zone_name : '-' : '-')
                SetCateId(dataLoad ? dataLoad[0].category_obj.length > 0 ? dataLoad[0].category_obj[0]._id : '-' : '-')
                SetCateName(dataLoad ? dataLoad[0].category_obj.length > 0 ? dataLoad[0].category_obj[0].category_name : '-' : '-')
                SetCateDetailId(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0]._id : '-' : '-')
                SetCateDetailName(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0].category_detail_name : '-' : '-')
                SetWarantyDate(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0]?.category_detail_obj[0]?.warantee : '-' : '-')
                SetIsWaranty(dataLoad ? dataLoad[0].category_detail_obj.length > 0 ? dataLoad[0].category_detail_obj[0].status : '-' : '-')
                SetRemarkDetail(dataLoad ? dataLoad[0].detail : '-')

                const fileList_EN: UploadFile[] =
                    dataLoad && dataLoad[0]?.pic?.length > 0 ? dataLoad[0]?.pic.map((v: any) => (
                        {
                            id: v.id,
                            uid: String(v.id),
                            name: 'detail_' + v.id,
                            status: "done",
                            url: v.url,
                            thumbUrl: v.url
                        }
                    )): [];
                    
                setImgEn({
                    name: 'files',
                    multiple: true,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.pic?.length > 0 ? [...fileList_EN] : [],
                })
                form.resetFields(["pic2"]);

                const fileListVid:any= []
                if (dataLoad[0]?.vdo?.id && dataLoad[0]?.vdo?.url) {
                    fileListVid.push({
                      id: dataLoad[0]?.vdo?.id,
                      uid: String(dataLoad[0]?.vdo?.id),
                      name: 'video',
                      status: "done",
                      url: dataLoad[0]?.vdo?.url,
                      thumbUrl: dataLoad[0]?.vdo?.url
                    });
                  }

                setVid({
                    name: 'files',
                    multiple: false,
                    // defaultFileList: [...fileList3],
                    defaultFileList: dataLoad[0]?.vdo  ? [...fileListVid] : [],
                })
                form.resetFields(["pic"]);


                // Set สถานะการทำงาน




                // Set ประวัติ
            });
    }


    const getDataTrail = async ()=>{
        // http://localhost:10000/api/majorcare/repairJobTrail/findRepairTrailByJob?jid=64a57929c65706886187b7ec
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/repairJobTrail/findRepairTrailByJob?jid=${job_id}`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // console.log('dataLoad trail', dataLoad.reverse())

                let dataTrail:any = dataLoad.reverse()
                // dataSource.push(dataLoad[0])
                // console.log('dataTrail', dataTrail)

                for (let index = 0; index < dataTrail.length; index++) {
                    dataSourceTrail.push(dataTrail[index])   
                }
                
                // Set สถานะการทำงาน
                setLastTrail(dataTrail ? dataTrail[0].status_name : '-')
                setLastTrailDate(dataTrail ? dayjs(dataTrail[0].create_date).format("YYYY-MM-DD HH:mm") : '-')
                // Set ประวัติ
            });
    }

    // console.log('dataSourceTrail', dataSourceTrail)
    const getRepairZone = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairZone`,
        { headers: {  } }).then((res) => {
            let dataZone: any = res?.data;
            // console.log('dataZone', dataZone)
            SetDataZone(dataZone);
        });
    }

    const getRepairCategory = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairCategory`,
        { headers: {  } }).then((res) => {
            let dataCate: any = res?.data;
            // console.log('dataCate', dataCate)
            SetDataCategory(dataCate);
        });
    }

    const getRepairCateDetail = async () => {
        let res = await axios.get(
        majorCareUrl + `/majorcare/repairCategoryDetail`,
        { headers: {  } }).then((res) => {
            let dataCateDetail: any = res?.data;
            // console.log('dataCateDetail', dataCateDetail)
            SetDataCatDetail(dataCateDetail);
        });
    }

   
    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    // getDataTrail();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
        getRepairZone();
        getRepairCategory();
        getRepairCateDetail();
    }, []);

    const [form] = Form.useForm();

    const { listProps: listPropsData, queryResult: { refetch: refetch2 } } = useSimpleList({
        // resource: "home-check-up-settings",
        resource: "repair-settings",
        metaData: {
            // populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],
            populate: '*',
        },
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx', data)
            let dataLoad = data?.data
            // console.log('dataLoad inspector', dataLoad)

            let data_this_prop:any = dataLoad.find((item:any) => item?.property?.id == data_property);
            // console.log('data_this_prop', data_this_prop)

            const inspectorId = data_this_prop?.inspec_team?.map((obj:any) => obj.id);
            // console.log('inspectorId', inspectorId)
            SetInspectorSettingId(inspectorId)
          },
        },
    });

    const { selectProps: selInspector , queryResult: { refetch }} = useSelect<any>({
        resource    : "user-type-mappings",
        optionLabel : "user_profile][first_name]",
        metaData: {
            populate: '*',
            // locale:['user_profile','user_profile.avatar']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "role_management][id]",
                operator: "eq",
                value: 21 // role Inspector
            },
            // {
            //     field: "property][id]",
            //     // operator: "in",
            //     operator: PropertyIdJob ? "in" : "nin",
            //     // value: PropertyId ? PropertyId : null 
            //     value: PropertyIdJob ? PropertyIdJob : "xxxxxxxxxxx" 
            // },
            {
                field: "property][id]",
                operator: "eq",
                value: PropertyIdJob
            },
            {
                field: "user_profile][id]",
                // operator: "in",
                operator: InspectorSettingId ? "in" : "nin",
                value: InspectorSettingId ? InspectorSettingId : "xxxxxxxxxxxx"
            },
            {
                field: "user_profile][deleteStatus]",
                // operator: "in",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const handleDatePickerChange = (date: Dayjs | null, dateString: string, type: any) => {
        // console.log('dateString', dateString)

        if(type == "start"){
            setAppointStart(dateString);
        }else{
            setAppointEnd(dateString);
        }
      
    };

    const onChangeRemark = (e: any) => {
        SetRemark(e.target.value);
    }

    async function updateJobAndTrail(){

        let dataAssign:any
        let data:any


        if(Stat == "6485bcf9049e59df2850eba2"){ // รอตรวจ
            // ถ้าเป็นรอตรวจ ควรจะได้วันนัดหมายใหม่และชื่อช่าง
            dataAssign = {
                "id": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.id : null,
                "first_name":InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.first_name : null,
                "last_name": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.last_name : null,
                "tel": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.tel : null,
                "email": InspectorProfile ? InspectorProfile[0].attributes.user_profile.data.attributes.email : null,
            }

            data = {
                "status": Stat,
                "appoint_start": AppointStart ? AppointStart : "",
                "appoint_end": AppointEnd ? AppointEnd : "",
                "assign": InspectorProfile ? dataAssign : null,
            };
        } else {
            data = {
                "status": Stat,
            };
        }
        
        let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + item_id, data, { headers: { } });
        // console.log('res', res)

        let dataTrail = {
            "home_check_job" : item_id,
            "status": Stat, // หน้านี้ควรจะเป็นรอตรวจ
            "appoint_start": AppointStart ? AppointStart : null,
            "appoint_end": AppointEnd ? AppointEnd : null ,
            "assign": InspectorProfile ? InspectorProfile[0] : null,
            "sla": {
                "sla": "-",
                "sla_time": "-"
            },
            "remark": Remark !== undefined ? Remark : "",
            "create_by": Identity_data?.user_profile,
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
        // console.log('res_trail', res_trail)

        // setCountInterval(0)

        successNoti('200', 'success');
        // replace("/majorcare/mjc-job")
        replace("/repair/repair-job")

        
    } 

    const successNoti = (stat:any, msg:any) => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };
 
    return (
    <Edit 
        title="รายละเอียด"
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{
            breadcrumb: <Breadcrumb>
                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                <Breadcrumb.Item>รายการแจ้งซ่อม</Breadcrumb.Item>
                {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                <Breadcrumb.Item><span className="last_breadcrumb">รายละเอียด</span></Breadcrumb.Item>
            </Breadcrumb>,
            extra : <></>,
            onBack(e?) {
                // replace("/majorcare/mjc-job")
                replace("/repair/repair-job")

            },
        }}
    >
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    // createAssignInspec(values);
                    updateJobAndTrail();
                }}
            >

                {/* ====================== รายละเอียด ====================== */}
                {/* ======================================================= */}
                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียด
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} > 
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label="Job no."
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={JobNo} defaultValue={JobNo} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Property"
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Input value={Property} defaultValue={Property} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="ผู้แจ้ง"
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Customer} defaultValue={Customer} disabled/>
                                </Form.Item>
                                <Form.Item 
                                    label="วันที่หมดประกัน"
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={WarantyExpire} defaultValue={WarantyExpire} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่แจ้ง"
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(CreateDate).format("DD/MM/YYYY HH:mm")} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Unit" 
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Unit} defaultValue={Unit} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="ประเภท" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Type} defaultValue={Type} disabled/>
                                </Form.Item>

                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่โอน" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>   
                                </Form.Item>
                                <Form.Item 
                                    label="Email ผู้แจ้ง" 
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={CustomerEmail} defaultValue={CustomerEmail} disabled/>   
                                </Form.Item>
                                <Form.Item 
                                    label="เบอร์ผู้แจ้ง" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={CustomerTelNo} defaultValue={CustomerTelNo} disabled/>   
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />


                {/* ====================== รายละเอียดการแจ้งซ่อม ====================== */}
                {/* ================================================================ */}
                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียดการแจ้งซ่อม
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="โซน" 
                            // name="zone"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input disabled value={ZoneName} />
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item 
                            label="หมวด" 
                            // name="category"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input disabled value={CateName} />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item 
                            label="รายการ" 
                            // name="details"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input disabled value={CateDetailName} />
                        </Form.Item>
                    </Col>
                </Row>


                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="ประกัน" 
                            // name="zone"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {WarantyDate}
                        </Form.Item>

                    </Col>

                    <Col span={8}>

                        <Form.Item 
                            label="อยู่ในประกัน" 
                            // name="category"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            {/* <div>{SelectedCateDetail? SelectedCateDetail.data ? SelectedCateDetail.data.status == "Y" ? 'ใช่' : 'ไม่ใช่' : '-' : '-' }</div> */}
                            <div>{IsWaranty ? IsWaranty == "Y" ? 'ใช่' : 'ไม่ใช่' : '-' }</div>
                        </Form.Item>
                    </Col>                    
                </Row>

                <Row>
                    <Col span={16}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            <TextArea style={{ height: "90px", maxHeight: "80px" }}
                                // onChange={(e) => SetRemarkDetail(e.target.value)}
                                value={RemarkDetail}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="รูปประกอบ (สูงสุด 5 รูป)"
                            name="pic2"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vImgEn}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={5}
                                // beforeUpload={beforeUpload}
                                // onChange={fileChange_EN}
                                disabled
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item
                            label="ไฟล์วิดีโอ (1 คลิป)"
                            name="pic"
                        >
                            <Upload.Dragger 
                                style={{ borderRadius: "10px" }}
                                {...vVid}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                maxCount={1}
                                multiple={false}
                                // beforeUpload={beforeUploadVid}
                                // onChange={fileChange_vid}
                                disabled
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
    </Edit>   
    );
};