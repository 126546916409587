import { Breadcrumb, Col, Create, Form, Input, List, Radio, RcFile, Row, Upload, message, notification, useForm } from "@pankod/refine-antd"
import { HttpError, IResourceComponentsProps, useApiUrl, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import axios from "axios";
import { TOKEN_KEY, petURL } from "../../../constants";
import { useState } from "react";

export const PettypeCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();
    const { formProps, saveButtonProps } = useForm<any,HttpError,{}>();
    
    const [icon, seticon] = useState<any>()

    function fileChange(e:any){
        let res: any = e?.file;
        if(res?.status == "done"){
            seticon({
                "id": res?.response[0]?.id,
                "url": res?.response[0]?.url,
                "name": res?.response[0]?.name
            })
        }else if(res?.status == "removed"){
            seticon(undefined);
            formProps?.form?.resetFields(["icn"]);
        }
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    async function submitDT(values:any){
        let pet_type_code = ""
        await axios.get(petURL + "/api/lscpet/findAllPetType", { headers: { } }).then(res =>{
            // console.log('res', res);
            let total_pet = res?.data.length
            total_pet += 1
            // console.log('total_pet', total_pet)
            pet_type_code = total_pet.toString();
        })
        // console.log('res_pet_type', res_pet_type?.data?.length)
        let body: any = {
            "pet_type_name": values?.name_th,
            "pet_type_name_en": values?.name_en,
            "icon": icon,
            // "is_charge": values?.is_charge,
            "pet_type_code": pet_type_code,
            "create_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
        }

        // console.log('body', body)
        let submit = await axios.post(petURL + "/api/lscpet/createPetType", body, { headers: { } })
        .then(response => {
            successNoti('Create is Success');
            replace("/pets/pet-masters")
        })
        .catch(error => {
            console.error('error ',error);
            faildNoti('Create is Failed');
        });
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>, onBack(e?) {replace("/pets/pet-masters")}}}>
            <List 
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("pet-type.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-type.title-create")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />

            <div style={{width: "25%"}}>
                <Form 
                    {...formProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        submitDT(values);
                    }}
                >
                    <Form.Item 
                        label={t("pet-type.name-type-th")}
                        name="name_th"
                        rules={[
                            {
                                required: true, message: 'please input name th.'
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item 
                        label={t("pet-type.name-type-en")}
                        name="name_en"
                        rules={[
                            {
                                required: true, message: 'please input name en.'
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    
                    <Form.Item
                        name="icn"
                        label={t("pet-type.icon")}
                        valuePropName="fileList"
                        getValueProps={(data) => getValueProps(data, API_URL)}
                        rules={[
                            {
                                required: true, message: 'Please upload image.'
                            },
                        ]}>

                        <Upload.Dragger
                            style={{borderRadius: 10}}
                            name="files"
                            action={`${API_URL}/upload`}
                            headers={{
                                Authorization: `Bearer ${localStorage.getItem(
                                    TOKEN_KEY,
                                )}`,
                            }}
                            listType="picture"
                            maxCount={1}
                            onChange={fileChange}
                            beforeUpload={beforeUpload}
                        >
                            <p className="ant-upload-text">{t("upload")}</p>
                        </Upload.Dragger>
                    </Form.Item>
                    <p style={{fontSize:"11px", alignItems:"stretch", color: "#DF6677"}}>*{t("pet-type.size-img")}</p>

                    {/* <Row gutter={6}>
                        <Col className="gutter-row" span={8}>
                            <div style={{marginTop: "6px"}}>
                                <span className="req_custom" style={{marginRight: "4px", color: "#ff4d4f", fontSize: "13px"}}>*</span>
                                <span style={{fontSize: "0.7vw", fontWeight: "500", color: "#000"}}>{t("pet-type.charge")}</span>
                            </div>
                        </Col>
                        <Col className="gutter-row" span={16}>
                            <Form.Item 
                                label=" "
                                name="is_charge"
                                className="disable_req visible-label"
                                rules={[
                                    {
                                        required: true, message: 'please select charge'
                                    },
                                ]}
                                initialValue={false}
                            >
                                <Radio.Group name="radiogroup" defaultValue={false}>
                                    <Radio value={false}>{t("pet-type.nh")}</Radio>
                                    <Radio value={true}>{t("pet-type.h")}</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row> */}
                </Form>
            </div>
        </Create>
    )
}