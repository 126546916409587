/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, Row, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IDeliveryComp, IEventlog } from "interfaces";

export const DeliveryCompCreate: React.FC<IResourceComponentsProps> = () => {
    const t                                 = useTranslate();
    // const { mutate: CreateLog }             = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps }    = useForm<IDeliveryComp,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : { 
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'policies',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
            },
        }
    );

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                title={t("delivery-comp.title-create")}
                pageHeaderProps={{ 
                    breadcrumb: <Breadcrumb>
                            <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                            <Breadcrumb.Item><span className="last_breadcrumb">{t("delivery-comp.title")}</span></Breadcrumb.Item>
                        </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    // console.log(user_data.user_profile.id)
                    values.createBy = user_data.user_profile.id
                    values.locale = "th"
                    return ( 
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={12}>
                                    <Form.Item
                                        // label={t("policy.policy_code")}
                                        label="Company Name (TH)"
                                        name="title"
                                        rules={[{required: true, message: 'Please enter delivery company name (TH).'},]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Form.Item  
                                        // label={t("policy.policy_name")} 
                                        label="Company Name (EN)"
                                        name="title_en"
                                        rules={[{required: true, message: 'Please enter delivery company name (EN).'},]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    {/* <Col id="Col_w" className="gutter-row" span={6}>
                        <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                            <Divider></Divider>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={16}>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>

                                <Col className="gutter-row" span={8}>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                    <div style={{marginBottom: "5%"}}>-</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col> */}
                </Row>
            </Form>
        </Create>   
    );
};