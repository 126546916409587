/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Divider, Form, Input, List, message, Radio, RcFile, Row, Upload, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { getValueProps, mediaUploadMapper, useStrapiUpload } from "@pankod/refine-strapi-v4";
import { IEventlog, IProjectBrand } from "interfaces";

import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import { useState } from "react";
import { TOKEN_KEY } from "../../constants";

export const ProjectBrandCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TextArea } = Input;
    const API_URL = useApiUrl();
    const [vsetTel, setTel] = useState<CountryPhoneInputValue>();
    const { ...uploadProps } = useStrapiUpload({ maxCount: 1, });
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint          = "even-logs";
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps } = useForm<IProjectBrand,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'project-brands',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );

    function fileChange(e:any){
        console.log(e);
        if(e.file.response){
            let res = e.file.response;
            console.log(res[0]);
            //setPhotoList(res[0]);
        }
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };


    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List  
            title={t("project-brand.title")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("project-brand.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("project-brand.create")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            console.log(user_data.user_profile.id)
              values.createBy = user_data.user_profile.id

              if(vsetTel !== null && vsetTel !== undefined){
                values.short_tel = vsetTel?.short
                values.tel = vsetTel?.phone
            }

             return ( 
                 formProps.onFinish &&
                 formProps.onFinish(mediaUploadMapper(values))
             );
         }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.name")} name="brand_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.address")} name="address">
                                        <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                {/* <Form.Item  label={t("project-brand.tel")} name="tel"
                                    rules={[
                                        {
                                            required: true, message: 'please enter tel.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item> */}

                                <Form.Item name="tel" label={t("project-brand.tel")}>
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{ height: "32px", borderRadius: "5px", padding: "0"}}
                                            onChange={(v) => {
                                                setTel(v);
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            minLength={9}
                                            maxLength={12}
                                        />
                                    </ConfigProvider>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.color")} name="color_gradients"
                                    rules={[
                                        {
                                            required: true, message: 'please enter color gradients.'
                                        },
                                    ]}>
                                        {/* <Input /> */}
                                        <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.status")} name="active_status"
                                    rules={[
                                        {
                                            required: true, message: 'Please select status.'
                                        },
                                    ]}>
                                        <Radio.Group>
                                            <Radio value={true}>Active</Radio>
                                            <Radio value={false}>Inactive</Radio>
                                        </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>

                    <Card style={{backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div style={{ marginBottom: 20 }}><span className="title_panel">Thumbnail</span></div>
                        <Divider></Divider>
                        <Form.Item
                            name="bg_image"
                            valuePropName="fileList"
                            getValueProps={(data) => getValueProps(data, API_URL)}
                        >

                            <Upload.Dragger
                                style={{borderRadius: 10}}
                                name="files"
                                action={`${API_URL}/upload`}
                                headers={{
                                    Authorization: `Bearer ${localStorage.getItem(
                                        TOKEN_KEY,
                                    )}`,
                                }}
                                listType="picture"
                                multiple
                                {...uploadProps}
                                onChange={fileChange}
                                beforeUpload={beforeUpload}
                            >
                                <p>Upload</p>
                            </Upload.Dragger>
                            {/* <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p> */}
                        </Form.Item>
                        <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                    </Card>

                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={16}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={8}>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Create>   
    );
};