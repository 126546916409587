/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Form, Input, List, Row, Select, useForm, useSelect, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useList, useShow, useTranslate, useUpdate  } from "@pankod/refine-core";
import { mediaUploadMapper  } from "@pankod/refine-strapi-v4";
import { ICouponGroup, IEventlog } from "interfaces";
import { useEffect, useState } from "react";
// import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";
// import { useState } from "react";

// import {TinyMceEditor} from "../../components/ck";

export const MgnNewsEventEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const { data: user } = useGetIdentity();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const { mutate: updateMne} = useUpdate<any>();

    // const log_endpoint = "even-logs";
    // const { Option } = Select;

    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const [vPropID, setPropID] = useState<any>();
    const [disabled, setDisabled] = useState(false);
    const [ vContentType, setContentType ] = useState<any>(undefined);
    const [ vContent, setContent ] = useState<any>(undefined);

    // const [ vCouponType, setCouponType ] = useState<any>();

    const qContentNewsData = useList<any>({ resource: "content-news", });
    const { data: newsData } = qContentNewsData;
    let vNewsData:any = []
    vNewsData = newsData?.data? newsData?.data : [];

    const qContentAeData = useList<any>({ resource: "content-aes", });
    const { data: aeData } = qContentAeData;
    let vAeData:any = []
    vAeData = aeData?.data? aeData?.data : [];

    const { queryResult } = useShow<any>({
        metaData: {
            // populate: '*'
            populate: ['*', 'property','updateBy','createBy', 'news_event_seqs', 'news_event_seqs.seq', 'news_event_seqs.content_new', 'news_event_seqs.content_ae'],
        }
    });
    const { data } = queryResult;
    let recordData: any;
    // recordData = queryResult?.data ? queryResult?.data : [];
    recordData = data?.data ? data?.data : [];


    const { form, formProps, saveButtonProps } = useForm<ICouponGroup,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                // CreateLog({
                //     resource    : log_endpoint,
                //     values      : {
                //         // user_profile            : user_data.id,
                //         user_profile            : user_data.user_profile.id,
                //         log_time_stamp          : Date.now(), 
                //         log_event               : "Create",
                //         menu_event              : 'coupon',
                //         SysPkID                 : recordData.data.id + ""
                //     },
                //     successNotification : false
                // });
                createNewEventSeq(recordData.data.id);
            },
        }
    );

    const { selectProps: SelProp } = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field: "id",
            //     operator: "eq",
            //     value: PropListData
            // }
        ]   
    });

    const { selectProps: selContentPostType } = useSelect<any>({
        resource    : "content-post-types",
        optionLabel : "type_name",
        optionValue : "type_name",
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       : 'N',
            },
            {
                field : 'type_name',
                operator : 'contains',
                value :  ["Activity","News"],
            },
        ]
    });

    const contentPostType = useList<any>({
        resource: "content-post-types",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field : 'type_name',
                    operator : 'contains',
                    value :  ["Activity","News"],
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        }
    });

    const { data: dataContentPostType } = contentPostType;
    let ContentPostTypeData: any = []
    ContentPostTypeData = dataContentPostType?.data ? dataContentPostType?.data : [];

    // console.log('ContentPostTypeData',ContentPostTypeData)

    function onchangeProp(value:any) {
        setPropID(value);
    }

    // function toggleDisabled() {
    //     setDisabled(!disabled);
    // }

    function toggleDisabledX() {
        if(disabled == true){
            setDisabled(!disabled);
        }
    }

    // function onChangeContentType(value:any, label:any) {
    //     // console.log('content name insert to tag', label);
    //     // console.log('content id', value);
    //     setContentType(label.label);
    // }

    function onChangePostType(value:any, label:any) {
        setContentType(label.label);
    }

    function onChangeContent(value:any, key:any) {
        // console.log('onChangeContent 1', value)
        // console.log('onChangeContent 2', key)
        setContent(value);
    }

    // function onchangeRole(value:any) {
    //     setRole(value);
    // }

    const [ vContentTypeArr, setContentTypeArr] = useState<any>([]);
    const [ vContentArr, setContentArr] = useState<any>([]);
    const [ vContentSeq, setContentSeq] = useState<any>([]);
    const [ vIndexArr, setIndexArr] = useState<any>([]);

    // function onsetContentTypeArr(){
    //     vContentTypeArr.push(vContentType);
    //     setContentTypeArr(vContentTypeArr);
    // }

    // function onsetContentArr(){
    //     vContentArr.push(vContent);
    //     setContentArr(vContentArr);
    // }

    // function onsetIndexArr(){
    //     vIndexArr.push(vContentSeq);
    //     setIndexArr(vIndexArr);
    // }

    // function clearState(){
    //     setContent(undefined)
    //     setContentType(undefined)
    //     setContentSeq(undefined)
    // }
    const { mutate: mutateNewsEntSeq} = useCreate<any>();

    function createNewEventSeq(valueID: any){
        if(vContentArr.length > 0){
            for(let i=0 ; i <= vContentArr.length -1; i++){
                console.log('vContent', vContentArr[i])
                console.log('vContentSeq', vIndexArr[i])
            
                mutateNewsEntSeq({
                    resource    : "news-event-seqs",
                    values      : {
                        content_ae : vContentTypeArr[i] == "Activity & Event" ? vContentArr[i] : null,
                        content_new : vContentTypeArr[i] == "News" ? vContentArr[i] : null,
                        seq : vIndexArr[i],
                        manage_news_event: valueID
                    },
                    successNotification : false,
                });
            }
        }
    }

    const [count, setCount] = useState(0)
    // const [vTypeAe, setTypeAe] = useState<any>(undefined)
    // const [vTypeNews, setTypeNews] = useState<any>(undefined)

    const mneMap = useList<any>({
        resource: "news-event-seqs",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "manage_news_event][id]",
                    operator: "in",
                    value: recordData.id
                },
                {
                    field: "deleteStatus",
                    operator: "eq",
                    value: "N"
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        }
    });

    const { data: dataMneMap } = mneMap;
    let MneMapData: any = []
    MneMapData = dataMneMap?.data ? dataMneMap?.data : [];

    // console.log('MneMapData', MneMapData)

    const [vMneData, setMneData] = useState(recordData ? recordData.news_event_seqs :[]);


    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "manage-news-events",
        metaData: {
            populate: [
                '*',
                'property',
                'news_event_seqs',
                'createBy',
                'updateBy'
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();
          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        // console.log('ContentPostTypeData[0].type_name', ContentPostTypeData[0].type_name)
        // setTypeNews(ContentPostTypeData[0] !== null ? ContentPostTypeData[0].type_name : '')
        // setTypeAe(ContentPostTypeData[1] !== null ? ContentPostTypeData[1].type_name : '' )
        setMneData(recordData ? recordData.news_event_seqs : []);
        // console.log('useEffect')
        form.setFieldsValue({
            property:
                recordData?.property ?
                    recordData.property.property_name
                : null,
        });
    }


    // useEffect(() => {
    //     if (count < 3) {
    //         const interval = setInterval(() => {
    //             setCount(prev => prev + 1);

    //             // console.log('ContentPostTypeData[0].type_name', ContentPostTypeData[0].type_name)
    //             // setTypeNews(ContentPostTypeData[0] !== null ? ContentPostTypeData[0].type_name : '')
    //             // setTypeAe(ContentPostTypeData[1] !== null ? ContentPostTypeData[1].type_name : '' )
    //             setMneData(recordData ? recordData.news_event_seqs : []);
    //             // console.log('useEffect')
    //             form.setFieldsValue({
    //                 property:
    //                     recordData?.property ?
    //                         recordData.property.property_name
    //                     : null,
    //             });
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count])

    
    // function removeItemFromArray(value:any){
    //     console.log('deleted mne id >> ', value)
    //     var filteredArray = MneMapData.filter((e: { id: any; }) => e.id !== value);
    //     // console.log('filteredArray', filteredArray)
    //     // console.log('MneMapData', MneMapData)
    //     setMneData(filteredArray);
    //     funcUpdateMneData(filteredArray);
    // }

    // function funcUpdateMneData(v:any){
    //     updateMne({
    //         resource    : 'manage-news-events',
    //         id          : recordData.id + "",
    //         values      : {
    //             news_event_seqs  : v,
    //         },
    //         successNotification : false,
    //     })
    //     setCount(0);
    // }



    // console.log('vMneData', vMneData)
    // console.log('MneMapData', MneMapData)
    // console.log('recordData', recordData)
    // console.log('vTypeAe', vTypeAe)
    // console.log('vTypeNews', vTypeNews)
    // console.log('vContentType', vContentType)
    // console.log('vContent', vContent)
    // console.log('vContentTypeArr', vContentTypeArr)
    // console.log('vContentArr', vContentArr) 
    // console.log('vIndexArr', vIndexArr)
    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <List 
                // title={t("coupon-create.title")}
                title=""
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        {/* <Breadcrumb.Item>{t("coupon-group.title")}</Breadcrumb.Item> */}
                        <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                        {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("coupon-group.t-group-name")}</span></Breadcrumb.Item> */}
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("mgn-news-event.edit")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            />
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id
                    values.property = vPropID

                    

                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={18}>
                        <Card style={{ borderRadius: 5, boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                            <Row gutter={16}>
                                <Col className="gutter-row" span={24}>
                                    <Row gutter={16}>
                                        <Col className="gutter-row" span={16}>
                                            <Form.Item  
                                                // label={t("coupon-group.f-group-name")}
                                                label="Title"
                                                name="title"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter title.'
                                                    },
                                                ]}
                                            >
                                                <Input/>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={8}>
                                            <Form.Item  label={t("property.title")} name="property"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select property.'
                                                    },
                                                ]}>
                                                    <Select placeholder={t("select.sel")} onChange={onchangeProp} {...SelProp}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>

                        <div style={{marginBottom:'25px'}}></div>

                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.List name="fields">
                            {(fields, { add, remove }) => {
                                return (
                                <div>
                                    {/* <Row gutter={16}>
                                        <Col className="gutter-row" span={4}>
                                            <Form.Item>
                                                <span style={{fontSize: "0.7vw",color: "#000"}}>Add Sequences</span>
                                            </Form.Item>
                                        </Col>
                                        <Col className="gutter-row" span={3}>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => { add(); toggleDisabled();}}
                                                    style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                    disabled={disabled}
                                                >
                                                    <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row> */}

                                    {
                                        // MneMapData !== null && MneMapData !== undefined && MneMapData ?
                                        // MneMapData.length > 0 ? 
                                        //     MneMapData?.map((el:any,index:any) => {
                                        vMneData !== null && vMneData !== undefined && vMneData ?
                                        vMneData.length > 0 ? 
                                            vMneData?.map((el:any,index:any) => {
                                                return <>
                                                    {
                                                        // console.log('el', el)
                                                    }
                                                    <div>

                                                        {/* <Col className="gutter-row" span={16}>
                                                            <Form.Item
                                                                name={[index, 'content_type']}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select content type'
                                                                    },
                                                                ]} 
                                                            >
                                                                <Select {...selContentPostType} 
                                                                    // onChange={onChangePostType}
                                                                    onChange={(val)=> onChangePostType(val ,index)} 
                                                                    // defaultValue={el.property !== null ? el.property.id : null}
                                                                    defaultValue={el.content_new !== null ? el.content_new.id : el.content_new !== null ? el.content_new.id : null}
                                                                />
                                                            </Form.Item>
                                                        </Col> */}

                                                        <Row gutter={24}>
                                                            {setContentSeq(index+1)}
                                                            <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "20%"}}><span>{index+1} .</span></div> </Col>

                                                            {/* OLD DATA MAP CONTENT TYPE */}
                                                            <Col className="gutter-row" span={10}>
                                                                <Row gutter={12}>
                                                                    <Col className="gutter-row" span={8}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>*</span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Content Type</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={16}>
                                                                        <Form.Item
                                                                            name={[index, 'content_type']}
                                                                            // rules={[
                                                                            //     {
                                                                            //         required: true, message: 'Please select content type'
                                                                            //     },
                                                                            // ]}
                                                                        >
                                                                            <Select {...selContentPostType} 
                                                                                // onChange={onChangePostType}
                                                                                onChange={(val)=> onChangePostType(val ,index)} 
                                                                                // defaultValue={el.property !== null ? el.property.id : null}
                                                                                defaultValue={
                                                                                    el.content_new !== null ? 
                                                                                        // "News" 
                                                                                        ContentPostTypeData.length > 0 ? ContentPostTypeData[0].type_name : '-'
                                                                                        // <>News</>
                                                                                        // ContentPostTypeData[0].type_name
                                                                                    : el.content_ae !== null ? 
                                                                                        //"Activity & Event" 
                                                                                        ContentPostTypeData.length > 0 ? ContentPostTypeData[1].type_name : '-'
                                                                                        // ContentPostTypeData > 0 ? ContentPostTypeData[1].type_name : null
                                                                                        // ContentPostTypeData[1].type_name
                                                                                    : null
                                                                                }
                                                                                value={
                                                                                    el.content_new !== null ? 
                                                                                        // "News" 
                                                                                        ContentPostTypeData.length > 0 ? ContentPostTypeData[0].type_name : '-'
                                                                                    : el.content_ae !== null ? 
                                                                                        //"Activity & Event" 
                                                                                        ContentPostTypeData.length > 0 ? ContentPostTypeData[1].type_name : '-'
                                                                                    : null
                                                                                }
                                                                                disabled
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            {/* OLD DATA MAP CONTENT */} 
                                                            <Col className="gutter-row" span={10}>
                                                                <Row gutter={12}>
                                                                    <Col className="gutter-row" span={4}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#fc0303"}}>*</span>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>Content</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={18}>
                                                                        <Form.Item name={[index, 'content']} >
                                                                            <Select
                                                                                onChange={onChangeContent}
                                                                                defaultValue={
                                                                                    el.content_new !== null ? 
                                                                                        el.content_new.short_detail ? el.content_new.short_detail : '-'
                                                                                    : el.content_ae !== null ? 
                                                                                        el.content_ae.short_detail ? el.content_ae.short_detail : '-'
                                                                                    : null
                                                                                }
                                                                                value={
                                                                                    el.content_new !== null ? 
                                                                                        el.content_new.short_detail ? el.content_new.short_detail : '-'
                                                                                    : el.content_ae !== null ? 
                                                                                        el.content_ae.short_detail ? el.content_ae.short_detail : '-'
                                                                                    : null
                                                                                }
                                                                                disabled
                                                                            >
                                                                            </Select>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={2}>

                                                                {/* {
                                                                    vMneData.length > 0 ? (
                                                                        <Button
                                                                            size="small"
                                                                            className="dynamic-delete-button"
                                                                            onClick={() => { removeItemFromArray(el.id);}}
                                                                            danger
                                                                        >
                                                                            <Icons.CloseOutlined />
                                                                        </Button>
                                                                    ) : null
                                                                } */}
                                                            </Col>
                                                        </Row>
                                                        <div style={{marginBottom: "1vh",}}></div>
                                                    </div>
                                                </>
                                            }):<></>
                                        :
                                        <></>
                                    }

                                    {/* {fields.map((field, index) => (
                                        <div key={field.key}>
                                            <Row gutter={24}>
                                                {setContentSeq(index+1)}
                                                <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "20%"}}><span>{index+1} .</span></div> </Col>

                                                <Col className="gutter-row" span={10}>
                                                    <Row gutter={12}>
                                                        <Col className="gutter-row" span={8}>
                                                            <Form.Item>
                                                                <span style={{fontSize: "0.7vw",color: "#fc0303"}}>*</span>
                                                                <span style={{fontSize: "0.7vw",color: "#000"}}>Content Type</span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className="gutter-row" span={16}>
                                                            <Form.Item
                                                                name={[index, 'content_type']}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select content type'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select {...selContentPostType} onChange={onChangePostType} />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col className="gutter-row" span={10}>
                                                    <Row gutter={12}>
                                                        <Col className="gutter-row" span={4}>
                                                            <Form.Item>
                                                                <span style={{fontSize: "0.7vw",color: "#fc0303"}}>*</span>
                                                                <span style={{fontSize: "0.7vw",color: "#000"}}>Content</span>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col className="gutter-row" span={18}>
                                                            <Form.Item
                                                                name={[index, 'content']}
                                                                rules={[
                                                                    {
                                                                        required: true, message: 'Please select content'
                                                                    },
                                                                ]}
                                                            >
                                                                <Select
                                                                    onChange={onChangeContent}
                                                                >
                                                                    {
                                                                        vContentType == "Activity & Event" ?
                                                                            vAeData !== undefined?
                                                                                vAeData.map((item:any,key:any) => {
                                                                                    // console.log("promotion data",item);
                                                                                    return <><Option value={item.id} key={item.short_detail}>{item.short_detail ? item.short_detail : item.id}</Option></>
                                                                                })
                                                                            :<></>
                                                                        :<></>
                                                                    }

                                                                    {
                                                                        vContentType == "News" ?
                                                                            vNewsData !== undefined?
                                                                                vNewsData.map((item:any,key:any) => {
                                                                                    // console.log("promotion data",item);
                                                                                    return <><Option value={item.id} key={item.short_detail}>{item.short_detail ? item.short_detail : item.id}</Option></>
                                                                                })
                                                                            :<></>
                                                                        :<></>
                                                                    }
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>

                                                <Col span={2}>
                                                    {
                                                        vContentType !== undefined && vContent !== undefined ?
                                                            <>{fields.length == index+1 ?
                                                                <Button
                                                                    size="small"
                                                                    className="dynamic-delete-button"
                                                                    onClick={() => { onsetContentTypeArr(); onsetContentArr(); onsetIndexArr(); clearState(); toggleDisabled();}}
                                                                    danger
                                                                    style={{marginRight: "3%"}}
                                                                >
                                                                    <Icons.CheckOutlined/>
                                                                </Button>:null
                                                            }
                                                            </>
                                                        :null
                                                    }

                                                    {
                                                        fields.length > 0 ? (
                                                            <Button
                                                                size="small"
                                                                className="dynamic-delete-button"
                                                                onClick={() => { remove(field.name); clearState(); toggleDisabledX(); }}
                                                                danger
                                                            >
                                                                <Icons.CloseOutlined />
                                                            </Button>
                                                        ) : null
                                                    }
                                                </Col>
                                            </Row>
                                            <div style={{marginBottom: "1vh"}}></div>
                                        </div>
                                    ))} */}
                                </div>
                                );
                            }}
                        </Form.List>
                    </Col>
                </Row>
            </Form>
        </Edit>
    );
};