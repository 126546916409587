/* eslint-disable */
import { Card, Checkbox, Col, Edit, Form, Input, RadioChangeEvent, Row, Table, useForm, useTable } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useList, useShow, useTranslate, useUpdate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IModuleAccess, IModuleMaster, IRManage } from "interfaces";
import { useEffect, useState } from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import type { ColumnsType } from "antd/es/table";
import "react-mde/lib/styles/css/react-mde-all.css";

export const RoleEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;

    const [page, setPage] = useState(1);
    const pageSizeState = [ '10','30', '50', '100'];
    const [pageSizeChange, setPageSizeChange] = useState(10);

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    const { mutate: updateModule } = useUpdate<IModuleAccess>();
    let moduleAccess: IModuleAccess[] = [];
    let moduleMaster: IModuleMaster[] = [];
    // let roles: IRManage[] = [];
    const [ vRoleID, setRoleID] = useState('0')
    
    const [moduleAccState, setModuleAccState] = useState<IModuleAccess[]>([]);

    const { queryResult } = useShow<IModuleMaster>({  
        metaData: {
        populate: '*'
        }, 
    });
    const { data } = queryResult;
    let recordData:any = []
    recordData = data?.data? data?.data : [];

    const [count, setCount] = useState(0)
    
    useEffect(() => {
        if (count < 2) {
            const interval = setInterval(() => {
            setCount(prev => prev + 1);
            setRoleID(recordData.role_master ? recordData.role_master.id : 0);
          }, 1000)
  
          return () => clearInterval(interval)
        }
    }, [count])

    const moduleAccessRes = useList<IModuleAccess>({
        resource: "module-accesses",
        metaData: {
          populate: ["role", "role.role_master", "module_master"],
        },
        config: {
            pagination:{current: 1, pageSize: 200},
            sort: [{ order: "asc", field: "module_master.module_code" }],
        //   filters: [{ field: "role][id]", operator: "in", value: '2' }],
        //   filters: [{ field: "role][name]", operator: "in", value: '2' }],
            filters: [{ field: "role][id]", operator: "in", value: recordData ? recordData.id : 1 }],
        },
    });
    
    const { data: moduleAccessData, isFetching } = moduleAccessRes;
    moduleAccess = moduleAccessData?.data ? moduleAccessData?.data : [];

    const moduleMasterRes = useList<IModuleMaster>({
      resource: "module-masters",
      config: {
        pagination:{current: 1, pageSize: 200},
        sort: [{ order: "asc", field: "module_code" }],
      },
    });
    
    const { data: moduleMasterData } = moduleMasterRes;
    moduleMaster = moduleMasterData?.data ? moduleMasterData?.data : [];

    
    useEffect(() => {
        // console.log('moduleAccess', moduleAccess);

        // setModuleAccState(
        // )
        // setModuleAccState(
        //     moduleMaster.map((val, idx) => ({
        //       id: idx.toString(),
        //       act_create: false,
        //       act_delete: false,
        //       act_export: false,
        //       act_import: false,
        //       act_update: false,
        //       use_workflow: false,
        //       active: false,
        //       isCreat: true,
        //       module_master: val,
        //       role: roles.find(
        //         (val: IRManage) => val.id.toString() === vRoleID
        //       ),
        //     }))
        //   );

        if (moduleAccess !== undefined && moduleAccess.length !== 0) {
          setModuleAccState(
            moduleAccess.map((val) => ({
              ...val,
            //   isCreat: false,
            }))
          );
        } else {
        //   setModuleAccState(
        //     moduleMaster.map((val:any, idx:any) => (
        //     {
        //         id: idx.toString(),
        //         create: false,
        //         delete: false,
        //         update: false,
        //         //   isCreat: true,
        //         module_master: val,
        //         role: roles.find(
        //             (val: IRoleManagement) => val.id.toString() === roleId
        //         )
        //     }))
        //   );
        }


      }, [moduleAccess]);
 
    const { formProps, saveButtonProps } = useForm<IRManage,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                CreateLog({
                    resource : log_endpoint,
                    values : {
                        user_profile : user_data.user_profile.id,
                        log_time_stamp : Date.now(), 
                        log_event : "Create",
                        menu_event : 'pp_type',
                        SysPkID : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    ); 

    // useEffect(() => {
    //     setRoleID(recordData.role_master ? recordData.role_master.id : 0);
    // }, [vRoleID])
    
    const { tableProps } = useTable<IModuleAccess,HttpError, IModuleAccess>({ 
        resource: "module-accesses",
        metaData: {
            populate: '*'
        },
        initialSorter: [
            {
                field: "id",
                order: "asc",
            },
        ],
    });

    const columns: ColumnsType<IModuleAccess> = [
        {
          title: "Menu",
          dataIndex: "menu",
          key: "menu",
          render: (_, record) => <>{record.module_master?.module_name}</>,
        },
        {
            title: "View",
            key: "view",
            dataIndex: "view",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.view} 
                  onChange={(e) => onChangePermissions2(record, "view", e)}
                />
              </>
            ),
          },
        {
          title: "Create",
          key: "create",
          dataIndex: "create",
          align: "center",
          render: (_, record) => (
            <>
              <Checkbox
                checked={record.create}
                onChange={(e) => onChangePermissions2(record, "create", e)}
                disabled
              />
            </>
          ),
        },
        {
            title: "Update",
            key: "update",
            dataIndex: "update",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.update}
                  onChange={(e) => onChangePermissions2(record, "update", e)}
                  disabled
                />
              </>
            ),
          },
        {
          title: "Delete",
          key: "delete",
          dataIndex: "delete",
          align: "center",
          render: (_, record) => (
            <>
              <Checkbox
                checked={record.delete}
                onChange={(e) => onChangePermissions2(record, "delete", e)}
                disabled
              />
            </>
          ),
        },
        {
            title: "Assign",
            key: "assign",
            dataIndex: "assign",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.assign}
                  onChange={(e) => onChangePermissions2(record, "assign", e)}
                  disabled
                />
              </>
            ),
          },
          {
            title: "Approve",
            key: "approve",
            dataIndex: "approve",
            align: "center",
            render: (_, record) => (
              <>
                <Checkbox
                  checked={record.approve}
                  onChange={(e) => onChangePermissions2(record, "approve", e)}
                  disabled
                />
              </>
            ),
          },
    ];

    const onChangePermissions2 = (record: any, key: string, e: CheckboxChangeEvent) => {
        switch(key) {
            case 'view':
                updateModule({resource: "module-accesses",id: record.id,values: {view: e.target.checked},successNotification: false});
                break;
            case 'create':
                updateModule({resource: "module-accesses",id: record.id,values: {create: e.target.checked},successNotification: false});
                break;
            case 'update':
                updateModule({resource: "module-accesses",id: record.id,values: {update: e.target.checked},successNotification: false});
                break;
            case 'delete':
                updateModule({resource: "module-accesses",id: record.id,values: {delete: e.target.checked},successNotification: false});
                break;
            case 'assign':
                updateModule({resource: "module-accesses",id: record.id,values: {assign: e.target.checked},successNotification: false});
                break;
            case 'approve':
                updateModule({resource: "module-accesses",id: record.id,values: {approve: e.target.checked},successNotification: false});
                break;
            default:
              return 'foo';
        }
    }

    const [value, setValue] = useState();
    const onChangeNoti = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <Form {...formProps} layout="vertical"
                onFinish={(values:any) => {
                    values.createBy = user_data.user_profile.id
                    return (
                        formProps.onFinish &&
                        formProps.onFinish(mediaUploadMapper(values))
                    );
                }}
            >
                <Row gutter={[16, 24]}>
                    <Col id="Col_w" className="gutter-row" span={12}>
                        <Card style={{ borderRadius: 5,boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={24}>
                                    <Form.Item  label={t("role.name")} name="custom_role_name"
                                        rules={[
                                            {
                                                required: true, message: 'Please enter role name.'
                                            },
                                        ]}>
                                            <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Form>

            <div style={{marginBottom:"2%"}}></div>

            <Row>
                <Col span={24}>
                    <Table {...tableProps}
                        columns={columns}
                        dataSource={moduleAccState}
                        pagination={false}
                        // scroll={{y:400}}
                    />
                </Col>
            </Row>
        </Edit>
    );
};