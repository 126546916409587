/* eslint-disable */
import { Breadcrumb, Col, EditButton, Form, Input, List, Modal, Row, Space, Table, Tag, TextField, useModalForm, useTable, Image, Radio, Icons, Button, useForm, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { IPaymentNoti } from "interfaces";
import { useEffect, useState } from "react";
import Moment from 'moment';

let nf = new Intl.NumberFormat('en-US');
export const PaymentNotiList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const userQuery = useGetIdentity();
    const {data : user_data , isLoading} = userQuery;
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Payment Notification");

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()

    // const [page, setPage] = useState(1);
    // const [pageSizeChange, setPageSizeChange] = useState(10);
    // const pageSizeState = [ '10','30', '50', '100'];
    const [imgurl, setimgurl] = useState<any>()
    const [vIDBIL, setIDBIL] = useState<any>()
    const [vIDBANK, setIDBANK] = useState<any>()

    const [ count, setCount] = useState<any>(0);
    const [nameSearch, setnameSearch] = useState<any>("");

    const [form] = Form.useForm();

    const [dataList, setDataList] = useState<any>([]);
    const [DataBlank, SetDataBlank] = useState<any>([]);

    
    // const { tableProps,searchFormProps,tableQueryResult:{refetch} } = useTable<IPaymentNoti,HttpError, IPaymentNoti>({
    //     metaData: {
    //         populate: '*'
    //     },  
    //     onSearch: (params) => {
    //         const filters: CrudFilters = [];
    //         // const { vehicle_type, brand_nameTH, deleteStatus } = params;
    //         //     filters.push(
    //         //         {
    //         //             field       : "vehicle_type][id]",
    //         //             operator    : "eq",
    //         //             value       : vehicle_type ? vehicle_type: null
    //         //         },
    //         //         {
    //         //             field       : "brand_nameEN",
    //         //             operator    : "contains",
    //         //             value       : brand_nameTH ? brand_nameTH : null
    //         //         },
    //         //         {
    //         //             field       : "deleteStatus",
    //         //             operator    : "eq",
    //         //             value       : deleteStatus
    //         //         },
    //         //     );
    //             return filters;
    //     },
    //     // initialFilter: [
    //     //     {
    //     //         field       : "deleteStatus",
    //     //         operator    : "eq",
    //     //         value       : "N"
    //     //     },
    //     // ],
    //     permanentSorter: [
    //         {
    //             field: datafield,
    //             order: dataorder,
    //         },
    //     ],
    //     queryOptions:{onSuccess(data){
    //         let dataLoad = data?.data;
    //         // console.log(">>> dataLoad",dataLoad)

    //         for (let index = 0; index < dataLoad.length; index++) {
    //             dataList.push(dataLoad[index]);
    //         }
    //   }}
    // });

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "billing-payment-notis",
        metaData: {
            populate: [
                'billing_invoice',
                'bank_config_payment',
                'unit'
            ]
        },
        pagination:{
            pageSize: 1000,
        },
        permanentFilter: [
            // {
            //     field: datafield,
            //     order: dataorder,
            // },
        ],
        queryOptions: {
          onSuccess: (data) => {
            let dataLoad = data?.data;
            // console.log(">>> dataLoad",dataLoad)

            for (let index = 0; index < dataLoad.length; index++) {
                dataList.push(dataLoad[index]);
            }
          },
        },
    });

    // console.log('dataList', dataList)
    const {
        modalProps: editModalProps,
        formProps: editProps,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "billing-payment-notis",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data
            console.log(dataLoad)
            setIDBIL(dataLoad?.billing_invoice ? dataLoad?.billing_invoice?.id : null);
            setIDBANK(dataLoad?.bank_config_payment ? dataLoad?.bank_config_payment?.id : null);
            if(dataLoad?.transfer_slip){
                setimgurl(dataLoad?.transfer_slip?.url)
            }


        },}
    });

    // function onShowSizeChange(current:any, pageSize:any) {
    //     setPageSizeChange(pageSize)
    // }

    const columns = [
        // {
        //     title: 'No.',
        //     dataIndex: 'item_name',
        //     key: 'item_name',
        //     width: 300,
        //     // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
        //     sorter: (a:any, b:any) => a.zone_name.localeCompare(b.zone_name),
        //     render: (value:any, record:any) => {
        //         // console.log('record', record)
        //         return (
        //             <>
        //                 <div>{record ? record.zone_name !== null ? record.zone_name : '-': '-'}</div>
        //                 {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}
        //             </>
        //         );
        //     },
        // },
        {
            title: t("payment-noti.trans-date"),
            // dataIndex: 'name',
            key: 'transfer_date',
            width: 300,
            sorter: (a:any, b:any) => a.transfer_date.localeCompare(b.transfer_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{value?.transfer_date ? value?.transfer_date !== null ?  Moment(value?.transfer_date).format('D/MM/yyyy HH:mm') : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: t("payment-noti.amount"),
            // dataIndex: 'name',
            key: 'amount',
            width: 300,
            sorter: (a:any, b:any) => a.amount.localeCompare(b.amount),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div><TextField value={value?.amount ? nf.format(value?.amount) : "-"} /></div>
                    </>
                );
            },
        },
        {
            title: t("payment-noti.bll-invoice"),
            // dataIndex: 'createBy',
            key: 'billing_invoice',
            width: 200,
            sorter: (a:any, b:any) => a.billing_invoice?.invoice_code.localeCompare(b.billing_invoice?.invoice_code),
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div><TextField value={record? record.billing_invoice.invoice_code :"-"}  /></div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: t("payment-noti.bnk-payment"),
            // dataIndex: 'updateBy',
            key: 'bank_config_payment',
            width: 200,
            sorter: (a:any, b:any) => a.bank_config_payment?.account_name.localeCompare(b.bank_config_payment?.account_name),
            render: (value:any, record:any) => {
                // console.log('record xxx', record)
                return (
                    <>
                        <TextField value={!!record && record.bank_config_payment? record.bank_config_payment.account_name:"-"}  />
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: t("payment-noti.payment-status"),
            // dataIndex: 'updateBy',
            key: 'payment_stat',
            width: 200,
            sorter: (a:any, b:any) => {
                if (a?.payment_stat < b?.payment_stat) {return -1}
                if (a?.payment_stat > b?.payment_stat) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                console.log("record",record)
                let color = (value?.payment_stat == true) ?'green':(value?.payment_stat === false) ?'red':'gray'
                let txt = (value?.payment_stat == true) ?'ชำระเงินเรียบร้อย':(value?.payment_stat == false) ?'แจ้งชำระเงินไม่ถูกต้อง':'ยังไม่มีการตรวจสอบ'
                return <Tag style={{borderRadius: "10px"}} color={color}>{txt}</Tag> 
            },
        },
        {
            title: 'CREATE DATE',
            // dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: (a:any, b:any) => {
                if (a.createdAt < b.createdAt) {return -1}
                if (a.createdAt > b.createdAt) {return 1}
                return (0)
            },
            render: (createdAt:any) => {
                return (
                    <>
                        <div >{createdAt ? Moment(createdAt).format('D/MM/yyyy HH:mm') : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'LAST UPDATE',
            // dataIndex: 'updatedAt',
            key: 'updatedAt',
            sorter: (a:any, b:any) => {
                if (a.updatedAt < b.updatedAt) {return -1}
                if (a.updatedAt > b.updatedAt) {return 1}
                return (0)
            },
            render: (updatedAt:any) => {
                return (
                    <>
                        <div >{updatedAt ? Moment(updatedAt).format('D/MM/yyyy HH:mm') : '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 120,
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => editModalShow(record.id)}
                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            />
                        }
                    </>
                );
            },
        },
    ];

    const [pageSize, setPageSize] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const clearDataSource = () => {
        setDataList([])
    }
    
    useEffect(() => {
        if (count < 2) { 
            const interval = setInterval(() => {
                setCount((prev: number) => prev + 1)
                if(count == 0){
                    refetch();
                }
                // console.log('kkk')
            }, 1000)
            return () => clearInterval(interval)
        }
        refetch();

    }, [count])

    // useEffect(() => {
    // }, [])

    const refreshDataSource = () => {
        clearDataSource()
        setCount(0);
    };


    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];
        if(mode == "S"){
            console.log('nameSearch', nameSearch)
            newData = nameSearch !== "" ? dataList?.filter((e:any)=> e?.billing_invoice?.invoice_code?.includes(String(nameSearch))) : newData;
            // newData = nameSearchEn !== "" ? dataList?.filter((e:any)=> e?.zone_name_en?.includes(String(nameSearchEn))) : newData;
            // newData = selProp !== undefined ?  dataList?.filter((item:any) => item.property_type.some((prop:any) => prop.property_type_id === selProp?.id)) : newData;
            console.log('newData', newData)
            setDataList(newData);
        }else if(mode == "R"){
            form?.resetFields();
            // getDataK();
            refreshDataSource();
            setnameSearch("");
        }
    }
    
    return <>
        <List 
            title={t("payment-noti.title-list")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("billing.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("payment-noti.title-list")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Col span={22}>
                <Form layout="vertical" 
                    // {...formProps} 
                    onFinish={(values:any) => {}}
                    form = {form} 
                >
                    <Row gutter={20} style={{width: "100%"}}>
                        <Col span={6} className="gutter-row">
                            <Form.Item name="template_name" label="Search">
                                <Input placeholder="Billing Invoice" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                    // suffix={<Icons.SearchOutlined/>} 
                                allowClear/>
                            </Form.Item>
                        </Col>
                        {/* <Col span={4} className="gutter-row">
                            <Form.Item name="template_name_en" label=" ">
                                <Input placeholder="Search Zone EN" onChange={(e) => { if(e.target.value.length > 0){ setnameSearchEn(e?.target?.value)}else{setnameSearchEn("")}}} 
                                    // suffix={<Icons.SearchOutlined/>} 
                                allowClear/>
                            </Form.Item>
                        </Col> */}
                        <Col span={5} className="gutter-row">
                            <Row gutter={24}>
                                <Col span={12} className="gutter-row">
                                    <Form.Item label=" ">
                                        <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S", "xxxxxx")}>
                                            <Icons.SearchOutlined /> Search
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="gutter-row">
                                    <Form.Item label=" ">
                                        <Button 
                                            onClick={() => {
                                                onSearchtable(dataList,"R", "xxxx")
                                            }} 
                                            style={{borderRadius: "5px"}}
                                            icon={<Icons.UndoOutlined />}
                                        >
                                            Reset
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Col>


            {/* <Table {...tableProps} 
                bordered
                size="middle"
                rowKey= "id"
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    total:tableProps?.dataSource?.length,
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false,
                }} 
            >
                <Table.Column width={70} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/>

                <Table.Column dataIndex="transfer_date" title={t("payment-noti.trans-date")}
                 sorter={(a:any, b:any) => {
                    const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                    setDatafield("transfer_date")
                    setDataorder(sorttest)
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <TextField value={value ? Moment(value).format('D/MM/yyyy HH:mm') : "-"} />
                    }}
                />

                <Table.Column dataIndex="amount" title={t("payment-noti.amount")}
                 sorter={(a:any, b:any) => {
                    const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                    setDatafield("amount")
                    setDataorder(sorttest)
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <TextField value={value ? nf.format(value) : "-"} />
                    }}
                />

                <Table.Column title={t("payment-noti.bll-invoice")}
                 sorter={(a:any, b:any) => {
                    const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                    setDatafield("billing_invoice.invoice_code")
                    setDataorder(sorttest)
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        return <TextField value={record? record.billing_invoice.invoice_code :"-"}  />
                    }}
                />

                <Table.Column title={t("payment-noti.bnk-payment")}
                sorter={(a:any, b:any) => {
                    const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                    setDatafield("bank_config_payment.account_name")
                    setDataorder(sorttest)
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        console.log("PPPPay",record)
                        return <TextField value={!!record && record.bank_config_payment? record.bank_config_payment.account_name:"-"}  />
                    }}
                />

                <Table.Column dataIndex="payment_stat" title={t("payment-noti.payment-status")}
                 sorter={(a:any, b:any) => {
                    const sorttest = dataorder === 'desc' ? 'asc' : 'desc';
                    setDatafield("payment_stat")
                    setDataorder(sorttest)
                    return (0)
                }}
                    render={(value:any,record:any)=>{
                        let color = (value == true) ?'green':(value === false) ?'red':'gray'
                        // let txt = (value == true) ?'ฝากชำระเงินเรียบร้อย':(value == false) ?'การฝากชำระไม่ถูกต้อง':'ยังไม่มีการตรวจสอบ'
                        let txt = (value == true) ?'ชำระเงินเรียบร้อย':(value == false) ?'แจ้งชำระเงินไม่ถูกต้อง':'ยังไม่มีการตรวจสอบ'
                        return <Tag style={{borderRadius: "10px"}} color={color}>{txt}</Tag> 
                    }}
                />

                <Table.Column<IPaymentNoti>
                    width={100}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => editModalShow(record.id)}
                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            />
                        </Space>
                    )}
                />
            </Table> */}
            <Table 
                dataSource={dataList?.length > 0 ? dataList : DataBlank} 
                columns={columns} 
                // onChange={onChange} 
                // pagination={{ size: "small", position: [ 'bottomRight' ] }} 
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    // showTotal: showTotal, 
                    pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                    showSizeChanger: true, // Optional - Show page size changer dropdown
                    // showQuickJumper: true, // Optional - Show quick jumper input
                    // pageSize: 10, // Optional - Default page size
                    pageSize: pageSize,
                    onChange: handlePageSizeChange,
                }}
            />
        </List>

        <Modal 
            {...editModalProps}
            title={"Payment Notification Edit"}
            okText = {"Save"}
            maskClosable={false}
            width={400}
            afterClose={()=> {
                setimgurl(undefined);
                setIDBANK(undefined);
                setIDBIL(undefined);
                editProps?.form?.resetFields();
                refreshDataSource();

            }}
        >
            <Form 
                {...editProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    values.payment_stat = values.payment_stat;
                    values.billing_invoice = vIDBIL;
                    values.bank_config_payment = vIDBANK;

                    values.updateBy = user_data.user_profile.id;
                    return (
                        editProps.onFinish &&
                        editProps.onFinish(values)
                    );
                }}
            >
                <Row gutter={8}>
                    <Col span={16} className="gutter-row">
                        <Form.Item label={t("payment-noti.trans-date")} name="transfer_date"
                            getValueProps={(value) => ({
                                value: value ? Moment(value).format('D/MM/yyyy') : "-",
                            })}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>
                    <Col span={8} className="gutter-row">
                        <Form.Item label={t("payment-noti.trans-time")} name="transfer_date"
                            getValueProps={(value) => ({
                                value: value ? Moment(value).format('HH:mm') : "-",
                            })}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                        <Form.Item label={t("payment-noti.amount")} name="amount"
                            getValueProps={(value) => ({
                                value: value ? nf.format(value): "-",
                            })}
                        >
                            <Input disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item label={t("payment-noti.bll-invoice")} name={["billing_invoice","invoice_code"]}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={12} className="gutter-row">
                        <Form.Item label={t("payment-noti.bnk-payment")} name={["bank_config_payment","account_name"]}>
                            <Input disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row" style={{ display: "flex", justifyContent: "center"}}>
                        <Image src={imgurl} style={{width: "11rem",height: "15rem",border: "1px solid #ddd"}}/>
                    </Col>

                    <Col span={24} className="gutter-row" style={{ display: "flex", justifyContent: "center",marginTop: "3rem"}}>
                        <Form.Item label=" " name="payment_stat" className="disable_req_c" rules={[{required: true,message: "Please select"}]}>
                            <Radio.Group 
                                style={{verticalAlign: 'middle'}} 
                                onChange={(e) => ""}
                            >
                                <Radio.Button value={true}><Icons.CheckOutlined style={{marginRight: "0.5rem"}}/>{t("payment-noti.true")}</Radio.Button>
                                <Radio.Button value={false}><Icons.CloseOutlined style={{marginRight: "0.5rem"}}/>{t("payment-noti.false")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}