/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, notification, Space } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";

import axios from "axios";
import TextArea from "@uiw/react-md-editor/lib/components/TextArea";

export const RepairFacilityCategoryCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [form] = Form.useForm();

    // const userQuery = useGetIdentity(); // Get User Profile

    // console.log('Identity_data', Identity_data?.user_profile)

    const user_data:any = {
        id: Identity_data ? Identity_data?.user_profile.id : null,
        first_name: Identity_data ? Identity_data?.user_profile.first_name : null,
        last_name: Identity_data ? Identity_data?.user_profile.last_name : null,
        tel: Identity_data ? Identity_data?.user_profile.tel : null,
        email: Identity_data ? Identity_data?.user_profile.email : null,
        avatar: Identity_data ? Identity_data?.user_profile.avatar : null,
    }

    // console.log('user_data', user_data)

    const t = useTranslate();
    const { replace, list} = useNavigation();

    const [ZoneTh, SetZoneTh] = useState<any>("");
    const [ZoneEn, SetZoneEn] = useState<any>("");
    const [ZoneDesc, SetZoneDesc] = useState<any>("");
    const [ZoneStat, SetZoneStat] = useState<any>(true);
    const [DataProperty, SetDataProperty] = useState<any>();
    const [PropertyType, SetPropertyType] = useState<any>();
    const [IsSr, SetIsSr] = useState<boolean>(false)
    const [ZoneData, SetZoneData] = useState<any>();
    const [ZoneSelected, setZoneSelected] = useState<any>();

    async function createData(values:any) {
        // let dataProp = DataProperty.filter((item:any) =>
        //     PropertyType.includes(item._id)
        // );
        let data = {
            "zone_name": ZoneSelected ? ZoneSelected.value : null,
            "category_name": ZoneTh,
            "category_name_en": ZoneEn,
            "is_default" : true,
            "is_active" : ZoneStat,
            "create_by": user_data ? user_data : null
        }

        console.log('data onFin', data)

        let res = await axios.post(majorCareUrl + "/majorcare/repairCategoryFacility", data , { headers: { } })
        .then(response => {
            // Handle successful response
            console.log(response.data);
            successNoti();
            replace("/repair-facility/repair-facility-master")
        })
        .catch(error => {
            // Handle error
            console.error('error ',error);
            errorNoti('200', 'success');
        });
    }

    useEffect(() => {
        getZone();
    }, []);
    
    

    console.log('ZoneSelected', ZoneSelected)
    const getZone:any = async ()=>{
        let res = await axios.get(majorCareUrl + "/majorcare/repairZoneFacility" , { headers: { } }).then(response => {
            console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            SetZoneData(dataLoad ? filteredDataCate : null)
        })
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully created',
        });
    };

    const errorNoti = (stat:any, msg:any) => {
        notification.warning({
            message: "This name already exists in the system.",
            duration: 3,
        });
    };

    return (
    <Create 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>แจ้งซ่อมส่วนกลาง</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-zone.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Category Master Create</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    createData(values);
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container"> 
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        
                        <Col span={12} 
                            className="gutter-row" 
                            style={{
                                // borderRight: "1px solid #dedede",
                                paddingTop: "2.5rem"
                            }}
                        >
                            <Form.Item
                                name="zone"
                                label="บริเวณ"
                                rules={[
                                    {
                                        required: true, message: "Please select zone"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")}
                                    options={
                                        ZoneData && 
                                        ZoneData?.map((value:any) => ( 
                                            { 
                                                label: value?.zone_name, 
                                                value: value?._id,
                                                data: value
                                            }))
                                    }
                                    onSelect={(value: any, record:any) => {
                                        setZoneSelected(record);
                                    }}
                                />
                            </Form.Item>
                            <Form.Item
                                name="item_name"
                                label="ชื่อหมวด (TH)"
                                rules={[
                                    {
                                        required: true, message: "Please enter category name"
                                    },
                                ]}
                            >
                                <Input placeholder="zone name th" onChange={(e) => SetZoneTh(e.target.value)} />

                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                // name="Inspector"
                                name="item_name_en"
                                label="ชื่อหมวด (EN)"
                                rules={[
                                    {
                                        required: true, message: "Please enter category name (en)"
                                    },
                                ]}
                            >
                                <Input placeholder="zone name en" onChange={(e) => SetZoneEn(e.target.value)} />

                            </Form.Item>

                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        // name={["type", "value"]}
                                        // name="Inspector"
                                        name="status"
                                        label="สถานะ"
                                        rules={[
                                            {
                                                required: true, message: "Please select"
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            onChange={(e:any) => SetZoneStat(e.target.value)}
                                            // defaultValue={recordData.coupon_condition ? 1 : 2}
                                            // value={vRaCalRight}
                                        >
                                            <Radio value={true} style={{ marginBottom: "0%" }}> ใช้งาน </Radio>
                                            <Radio value={false} style={{ marginBottom: "0%" }}> ไม่ใช้งาน </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                           
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Create>   
    );
};