/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, DatePicker, Form, Input, List, Row, Select, TextField, Upload, useForm, useSelect, UploadFile, message, Modal, RcFile, notification, useSimpleList } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useGetLocale, useList, useNavigation, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { TOKEN_KEY } from "../../constants";
import { IEventlog, IFacility, IFacilityBan, IFacilityBooking, IFacilityTimeSlot, IFacilityType, IProperties, IUnit, IUserhunit } from "interfaces";
import { useEffect, useState } from "react";
import { WarningOutlined } from "@ant-design/icons";

import moment from 'moment';
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import axios from "axios";

export const FacilitiesBKEdit: React.FC<IResourceComponentsProps> = () => {

    const token = localStorage.getItem(TOKEN_KEY);

    const userQuery                         = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { mutate: CreateLog }             = useCreate<IEventlog>();
    const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    const { mutate: updateBooking }         = useUpdate<IFacilityBooking>();

    const log_endpoint                      = "even-logs";
    const { form,formProps, saveButtonProps } = useForm<IFacilityBooking,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource    : log_endpoint,
                values      : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(), 
                    log_event               : "Create",
                    menu_event              : 'facility-bookings',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
    });

    const { queryResult } = useShow<IFacilityBooking>({metaData: {
        populate: '*'}, });
    const { data }              = queryResult;
    const record                = data?.data;
    let recordData:any = []
    recordData = data?.data ? data?.data : [];

    // console.log('recordData', recordData)

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { Dragger } = Upload;
    const locale = useGetLocale();
    const currentLocale = locale();
    // const [count, setCount] = useState(0)
    const [countcheck, setcountcheck] = useState(0)
    const [vProperty, setProp] = useState<any>();
    const [vsetUnit, setUnit] = useState<any>();
    const [vsetProfile, setProfile] = useState<any>();
    const [vsetFacility, setFacility] = useState<any>();
    
    const [vFacilityType, setFacilityType] = useState<any>();
    const [vDateuse, setDateuse] = useState<any>("");
    const [ vMaxUser, setMaxUser] = useState<any>();
    // const [vTimeuse, setTimeuse] = useState<any>("");
    // const [ vShowTimeslot, setShowTimeslot ] = useState<any>(false);
    const [ vIDTimeslot, setIDTimeslot ] = useState<any>([]);
    const [ vUserProfile, setUserProfile] = useState<any>();
    const [ vTimeSlot1, setTimeSlot1 ]  = useState<any>("");
    const [vSlip, setSlip] = useState<any>([]);

    let resultChkFacilityPrivate:any = [];


    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "facility-bookings",
        metaData: {
            populate: [
                '*',
                'facility',
                'property',
                'unit',
                'user_profile',
                'facility_type',
                'createBy',
                'updateBy',
                'facility_time_slot',
            ]
        },
        pagination:{
            pageSize: 100,
        },
        permanentFilter: [
            {
                field: "id",
                operator: "in",
                value: recordData.id,
            }
        ],
        queryOptions: {
          onSuccess: (data) => {
            console.log('data xxxx : ', data.data)
            callFetchData();

          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('');
     }, []);

    function callFetchData(){
        let timeSlotXX
            
        if(recordData.facility_time_slot !== undefined && recordData.facility_time_slot !== null){
            timeSlotXX = moment(recordData.facility_time_slot.time_start, "HH:mm").format('HH:mm') + ' - ' + moment(recordData.facility_time_slot.time_end, "HH:mm").format('HH:mm');
        }else{
            timeSlotXX = null;
        }

        setMaxUser(recordData?.max_user !== "" && recordData?.max_user !== null && recordData?.max_user !== undefined ? recordData.max_user : null)

        form.setFieldsValue({
            property:
                recordData?.property ?
                    recordData.property.property_name
                : null,   
            unit:
                recordData?.unit ?
                    recordData.unit.unit_name
                : null,       
            user_profile:
                recordData?.user_profile ?
                    recordData.user_profile.first_name+ ' ' + recordData.user_profile.last_name
                : null,       
            facility:
                recordData?.facility ?
                    recordData.facility.name
                : null,       
            facility_type:
                recordData?.facility_type ?
                    recordData.facility_type.name
                : null,
            facility_time_slot: timeSlotXX,
        });
        // merge
        setProp(recordData?.property ?recordData.property.id: null)
        setUnit(recordData?.unit ?recordData.unit.id: null)
        setProfile(recordData?.user_profile ?recordData.user_profile.id: null)
        setFacility(recordData?.facility ?recordData.facility.id: null)
        setFacilityType(recordData?.facility_type ?recordData.facility_type.id: null)

        if(recordData?.date !== null && recordData?.date !== undefined){
            setDateuse(moment(recordData.date).format("DD/MM/YYYY"))
        }else{
            setDateuse(recordData?.date ?recordData.date: null)
        }

        // setTimeuse(recordData?.time ?recordData.time: null)

        if(recordData.receipt_pix !== null && recordData.receipt_pix !== undefined){
            const fileList: UploadFile[] = [
                {
                    uid: recordData.receipt_pix.id,
                    name: recordData.receipt_pix.name,
                    status: "done",
                    url:
                        recordData.receipt_pix.url,
                    thumbUrl:
                        recordData.receipt_pix.url
                }
                ];

                setSlip({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [...fileList],
            })

            form.resetFields(["receipt_pix"]);
        }
    }

    // useEffect(() => {
    //     if (count < 3) {
    //       const interval = setInterval(() => {
    //         setCount(prev => prev + 1)

    //         let timeSlotXX
            
    //         if(recordData.facility_time_slot !== undefined && recordData.facility_time_slot !== null){
    //             timeSlotXX = moment(recordData.facility_time_slot.time_start, "HH:mm").format('HH:mm') + ' - ' + moment(recordData.facility_time_slot.time_end, "HH:mm").format('HH:mm');
    //         }else{
    //             timeSlotXX = null;
    //         }

    //         setMaxUser(recordData?.max_user !== "" && recordData?.max_user !== null && recordData?.max_user !== undefined ? recordData.max_user : null)

    //         form.setFieldsValue({
    //             property:
    //                 recordData?.property ?
    //                     recordData.property.property_name
    //                 : null,   
    //             unit:
    //                 recordData?.unit ?
    //                     recordData.unit.unit_name
    //                 : null,       
    //             user_profile:
    //                 recordData?.user_profile ?
    //                     recordData.user_profile.first_name+ ' ' + recordData.user_profile.last_name
    //                 : null,       
    //             facility:
    //                 recordData?.facility ?
    //                     recordData.facility.name
    //                 : null,       
    //             facility_type:
    //                 recordData?.facility_type ?
    //                     recordData.facility_type.name
    //                 : null,
    //             facility_time_slot: timeSlotXX,
    //         });
    //         // merge
    //         setProp(recordData?.property ?recordData.property.id: null)
    //         setUnit(recordData?.unit ?recordData.unit.id: null)
    //         setProfile(recordData?.user_profile ?recordData.user_profile.id: null)
    //         setFacility(recordData?.facility ?recordData.facility.id: null)
    //         setFacilityType(recordData?.facility_type ?recordData.facility_type.id: null)

    //         if(recordData?.date !== null && recordData?.date !== undefined){
    //             setDateuse(moment(recordData.date).format("DD/MM/YYYY"))
    //         }else{
    //             setDateuse(recordData?.date ?recordData.date: null)
    //         }

    //         setTimeuse(recordData?.time ?recordData.time: null)

    //         if(recordData.receipt_pix !== null && recordData.receipt_pix !== undefined){
    //             const fileList: UploadFile[] = [
    //                 {
    //                     uid: recordData.receipt_pix.id,
    //                     name: recordData.receipt_pix.name,
    //                     status: "done",
    //                     url:
    //                         recordData.receipt_pix.url,
    //                     thumbUrl:
    //                         recordData.receipt_pix.url
    //                 }
    //                 ];
    
    //                 setSlip({
    //                     name: 'files',
    //                     multiple: true,
    //                     defaultFileList: [...fileList],
    //             })
    
    //             form.resetFields(["receipt_pix"]);
    //         }

    //       }, 1000)
    //       return () => clearInterval(interval)
          
    //     }
    // }, [count,record])

    if(vDateuse !== null && vDateuse !== undefined && vDateuse !== "" && 
        vProperty !== null && vProperty !== undefined && 
        vsetUnit !== null && vsetUnit !== undefined &&
        vsetFacility !== null && vsetFacility !== undefined && 
        vMaxUser !== null && vMaxUser !== undefined)
    {
        if(countcheck == 0){
            setcountcheck(1);
            callAPI(vDateuse);
        }
    }

    const Property = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['unit','unit.property'],
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
            pagination:{current: 1, pageSize: 100}   // K create
        }
    });
    const { data: Dataprop } = Property;
    let PropData:any = []
    PropData = Dataprop?.data? Dataprop?.data : [];  
    const PropListData = Array.from(new Set(PropData.map((item: any) => item.unit.property.id)));

    const Unit = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: '*',
        },
        config:{
            filters: [
                {
                    field: "unit][property][id]",
                    operator: "eq",
                    value: vProperty + ""
                }
            ],
            pagination:{current: 1, pageSize: 100}   // K create
        }
    });
    const { data: DataUnit } = Unit;
    let UnitData:any = []
    UnitData = DataUnit?.data? DataUnit?.data : [];
    const UnitListData = Array.from(new Set(UnitData.map((item: any) => item.unit.id))); 

    const { selectProps: SelProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field: "id",
                operator: "eq",
                value: PropListData
            }
        ]   
    });

    const { selectProps: SelUnit } = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "id",
                operator: "eq",
                value: UnitListData.length > 0 ? UnitListData:"NULL"
            }
        ]
    });

    const BannedUser = useList<IFacilityBan>({ 
        resource: "facility-bans", 
        metaData: {
            populate: ['user_profile', 'user_profile.id'],
        },
        config:{
            filters: [
                {
                    field   : "ban_type",
                    operator: "eq",
                    value   : false // false = ban user
                },
                {
                    field   : "ban_status",
                    operator: "eq",
                    value   : true // true = still banned
                },
                {
                    field   : "deleteStatus",
                    operator: "contains",
                    value   : "N"
                },
            ]
        }
    });
    const { data: dataBannedUser } = BannedUser;
    let bannedUserData:any = []
    bannedUserData = dataBannedUser?.data? dataBannedUser?.data : [];
    // console.log('bannedUserData', bannedUserData)
    
    const UserDetail = useList<IUserhunit>({ 
        resource: "user-has-units", 
        metaData: {
            populate: ['user_profile'],
        },
        config:{
            filters: [
                {
                    field   : "unit][id]",
                    operator: "eq",
                    value   : vsetUnit + ""
                },
            ]
        }
    });
    const { data: XdataK } = UserDetail;
    let userData:any = []
    userData = XdataK?.data? XdataK?.data : [];

    function removeDuplicates(userData: any, bannedUser: any) {
        // console.log('userData', userData)
        // console.log('bannedUser', bannedUser)
        const userID = new Set(
            bannedUser?.map((item:any) => item.user_profile?.id)
        );
        // console.log('userID', userID)
        let k = userData?.filter((item:any) => !userID.has(item.user_profile?.id));
        // console.log('k', k)
        // return bannedUser.filter((item:any) => !userID.has(item.user_profile.id));
        return k
    }

    const filteredUserData = removeDuplicates(userData, bannedUserData);


    const { selectProps: SelFacility } = useSelect<IFacility>({
        resource: "facilities",
        optionLabel: "name",
        optionValue: "id",
        fetchSize: 50,
        filters:[
            {
                field: "facility_type][id]",
                operator: "eq",
                value: vFacilityType
            },
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'no_booking',
                operator    : 'eq',
                value       :  false,
            },
            {
                field       : 'property][id]',
                operator    : 'eq',
                value       :  vProperty,
            }
        ]
    });

    const { selectProps: SelFacilityType } = useSelect<IFacilityType>({
        resource    : "facility-types",
        optionLabel : "name",
        optionValue : "id",
        fetchSize: 50,
        filters:[
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'propertyID',
                operator    : 'eq',
                value       : vProperty,
            }
        ]
    });

    const TimeSlotDetail = useList<IFacilityTimeSlot>({ 
        resource: "facility-time-slots", 
        config:{
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "id",
                    operator: "eq",
                    value: vIDTimeslot.length > 0 ? vIDTimeslot : null
                },
            ]
        }
    });
    const { data: kTimeSlotData } = TimeSlotDetail;
    let timeSlotData:any = []
    timeSlotData = kTimeSlotData?.data? kTimeSlotData?.data : [];

        // sorting time slot
    if(timeSlotData !== undefined){
        timeSlotData.sort((a: { name: string; }, b: { name: any; }) => a.name.localeCompare(
            b.name,
            undefined,
            { numeric: true, sensitivity: 'base' }
        ));
    }

    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState( currentLocale === 'en' ? "There's been Fully Booked! Do you want to get on the Waitlist?" : "Facility นี้ถูกจองเต็มแล้ว! คุณต้องการรอคิวใน Waiting List หรือไม่");
    const [vModalTitle, setModalTitle] = useState("Facility full!");
    const { replace }   = useNavigation();
    
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setModalText('Booking...');
        setConfirmLoading(true);
  
        callAPIwaitingList();
        
        setTimeout(() => {
          setVisible(false);
          setConfirmLoading(false);
        }, 2000);
    };

    async function callAPIwaitingList(){
        resultQuery2 = await axios.get(API_URL + "/facility-time-slots/slot_time_chk?date=" + vDateuse + "&property=" + vProperty + "&unit=" + vsetUnit + "&facility=" + vsetFacility + "&max_user=" + vMaxUser, { headers: { Authorization: 'Bearer ' + token } });
        createBookingManual();
        replace("");
    }

    function createBookingManual(){
        CreateBookingCaseWaiting({
            resource    : log_endpoint,
            values      : {
                property        : vProperty,
                unit            : vsetUnit,
                user_profile    : vUserProfile + "",
                facility        : vsetFacility,
                facility_type   : vFacilityType,
                facility_time_slot : vTimeSlot1,
                max_user        : vMaxUser,
            },
        });
    }


    const handleCancel = () => {
        setVisible(false);
    };
  
    const [vIsCallAPI, setIscallAPI] = useState<any>(false);

    const DateuseChange = (date: Dayjs | null, dateString: string, id: number) => {
        let kDate = dayjs(date).format('DD/MM/YYYY')
        setDateuse(kDate);
        // setDateuse(dateString);
        callAPI(dateString);
    };

    function getIDtimeSlot(k:any) {
        setIDTimeslot(k);
    }

    let resultQuery1:any = [];
    let resultQuery2:any = [];
    let arrayID:any = [];

    async function callAPI(v:any){
        // resultQuery1 = await axios.get(API_URL + "/facility-time-slots/slot_time_chk?date=" + v  + "&property=" + vProperty + "&unit=" + vsetUnit + "&facility=" + vsetFacility + "&max_user=" + vMaxUser, { headers: { Authorization: 'Bearer ' + token } });
        resultQuery1 = await axios.get(API_URL + "/facility-time-slots/slot_time_chk?date=" + v  + "&property=" + vProperty + "&unit=" + vsetUnit + "&facility=" + vsetFacility + "&max_user=" + vMaxUser + "&isAdmin=true", { headers: { Authorization: 'Bearer ' + token } });

        // if( resultQuery1.data.EN === "There's been a problem with your booking. Please contact juristic person" || 
        //     resultQuery1.data.EN === "You already have booking on/at this date/time. Please cancel the previous booking." ||
        //     resultQuery1.data.EN === "There's been Fully Booked!" ||
        //     resultQuery1.data.EN === "Your booking quota is full. Please contact juristic person." ||
        //     resultQuery1.data.EN === "Time is up for booking. Please contact juristic person." ||
        //     resultQuery1.data.EN === "Facility is closed!" ){

        //     currentLocale === 'en' ?
        //         message.error ({
        //             content: resultQuery1.data.EN,
        //         })
        //     :
        //         message.error ({
        //             content: resultQuery1.data.TH,
        //         });
        //     setShowTimeslot(false);
        // }else if(resultQuery1.data.EN === "There's been Fully Booked! Do you want to get on the Waitlist?"){
        //     showModal();
        // }else{
        //     if(resultQuery1.data.length > 0){
        //         setShowTimeslot(true);
        //         arrayID = resultQuery1.data.map((item:any) => {
        //             return item.id
        //         })
        //         getIDtimeSlot(arrayID)
        //     }else{
        //         message.error ({
        //             content: 'ไม่มีรอบจองในวันที่เลือก',
        //         });
        //     }
        // }
        if(resultQuery1.data.length > 0){
            setIscallAPI(true);
            // setShowTimeslot(true);
            arrayID = resultQuery1.data.map((item:any) => {
                return item.id
            })
            getIDtimeSlot(arrayID)
        }else{
            setIscallAPI(false);
            currentLocale === 'en' ?
                // message.error ({
                //     content: resultQuery1.data.EN, 
                // })

                errornotiCallApi(500, resultQuery1.data.EN)

            :
                // message.error ({
                //     content: resultQuery1.data.TH,
                // });

                errornotiCallApi(500, resultQuery1.data.TH)

        }
    }

    const errornotiCallApi = (stat:any, msg:any) => {
        notification.error({
            message: stat,
            description: msg,
            duration: 3,
        });
    };

    function onchangeInput(value:any) {
        setMaxUser(value);
    }

    function onchangeProp(value:any) {
        setProp(value);

        form.resetFields(["unit"]);
        form.resetFields(["user_profile"]);
        form.resetFields(["facility_type"]);
        form.resetFields(["facility"]);
        form.resetFields(["max_user"]);
        form.resetFields(["date"]);
        form.resetFields(["facility_time_slot"]);
        form.resetFields(["receipt_pix"]);
    }

    function onchangeUnit(value:any) {
        setUnit(value);
    }

    function onchangeProfile(value:any) {
        setProfile(value);
        setUser(value);
    }

    function setUser(v:any){
        setUserProfile(v);
    }

    function onchangeFacility(value:any) {
        setFacility(value);
    }

    function onchangeFacilityType(value:any) {
        setFacilityType(value);
    }

    function fileChange(e:any){
        if(e.file.response){
            let res = e.file.response;
        }
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    function updateOnFinish(values:any){
        return (
            formProps.onFinish &&
            formProps.onFinish(mediaUploadMapper(values))
        );
    }

    const warning = () => {
        // for(let i=0; i<= vInputUnitType.length -1; i++){
        //     let field = "kSelectRound_" + i
        //     form.resetFields([field])
        // }
        Modal.warning({
          title: <span>{t("msg-check-facibook-edit.title-warning")}</span>,
          content: <span>{t("msg-check-facibook-edit.content-warning")}</span>,
        });
    };

    let resultAddWaitlist:any = [];

    async function callChkWaitlist(){
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        // const dateK = dayjs(vDateuse).format("YYYY-MM-DD")
        const dateK = moment(vDateuse, "DD-MM-YYYY").format("YYYY-MM-DD");
        // console.log('dateK', dateK)

        // resultAddWaitlist = await axios.get(API_URL + "/facility-bookings/capacityCHK?facility=" + vsetFacility + "&max_user=" + vMaxUser + "&date=" + dateK + "&facility_time_slot=" + vTimeSlot1 , { headers: { Authorization: 'Bearer ' + token } });
        // resultK = await axios.get(API_URL + "/like-systems/countCouponLeft?CPGID=" + couponGroupID , { headers: { Authorization: AuthStr } });
        
        // resultQuery = await axios.get(API_URL + "/like-systems/findDupTrackingNo?tracking_no=" + vTrackingNo , { headers: { Authorization: AuthStr } });
        resultAddWaitlist = await axios.get(API_URL + "/like-systems/findBookingCap?facility=" + vsetFacility + "&max_user=" + vMaxUser + "&date=" + dateK + "&facility_time_slot=" + vTimeSlot1 , { headers: { Authorization: AuthStr } });
        
        console.log('callChkWaitlist', resultAddWaitlist.data)
        if(resultAddWaitlist.data){
            // แสดง modal ว่า save แบบ waitlist
            showSaveWaitlistConf()
        }else{
            // save ปกติ
            saveData()
        }
    }

    const { confirm }= Modal;

    function showSaveWaitlistConf() {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Save to waiting list ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to save this data.',
            okText  : 'Confirm',
            onOk() {
                saveData()
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const errornoti = (stat:any, msg:any) => {

        if(msg.errorMessage == "undefined"){
            msg.errorMessage = "something wrong."
        }
        notification.error({
            // message: stat + ' : ' + msg.TH,
            message: msg.TH,
            description: msg.errorMessage,
            duration: 3,
        });
    };

    function saveData(){

        updateBooking(
            {
                resource : 'facility-bookings',
                id : recordData.id ,
                values : {
                    updateBy : user_data.user_profile.id,
                    date : moment(vDateuse, "DD-MM-YYYY").format("YYYY-MM-DD"), 
                    facility_time_slot: vTimeSlot1,
                    user_profile: vUserProfile,
                    property: vProperty,
                    facility: vsetFacility,
                    unit: vsetUnit,
                    facility_type: vFacilityType,
                    max_user: vMaxUser,
                    deleteStatus: 'N',
                    // receipt_pix : vImageID !== undefined ? vImageID : null,
                    facility_status_booking: "Booking"
                },
            },{
                onSuccess: async(data: any, link: any) =>{
                    console.log('data', data)
                    replace("")
                },onError(error, variables, context) {
                    errornoti(error.response.status, error.response.data);
                },
            },
        )
    }

    const overCapacity = () => {
        notification.error({
            message: 'Over Capacity',
            description: 'capacity over limit',
            duration: 3,
        });
    };

    async function callChkIsFacilityPrivate(){

        resultChkFacilityPrivate = await axios.get(API_URL + "/facility-bookings/isFacilityPrivateRoom?facility=" + vsetFacility , { headers: { Authorization: 'Bearer ' + token } });
        console.log('callChkIsFacilityPrivate', resultChkFacilityPrivate.data)

        return resultChkFacilityPrivate.data
    }

    // console.log('vIsCallAPI', vIsCallAPI)
    console.log('vTimeSlot1', vTimeSlot1)

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-booking.edit")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />

        <Modal
            title={vModalTitle}
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
        >
            <p>{modalText}</p>
        </Modal>

        <Form {...formProps} layout="vertical"
            onFinish={async (values:any) => {
                values.updateBy = user_data.user_profile.id;
                values.date = moment(vDateuse, "DD-MM-YYYY").format("YYYY-MM-DD");
                values.facility_time_slot = vTimeSlot1;
                values.user_profile = vsetProfile;

                values.property = vProperty;
                values.unit = vsetUnit;
                values.facility = vsetFacility;
                values.facility_type = vFacilityType;

                // #1517 Facility Booking : Type Private Room ต้องไม่มี Waiting List ถ้ากดจองเกิน Max user ให้ขึ้นว่า จำนวนคนเกิน
                // 1.เช็คว่า facility ที่จะจองเป็น private room ไม่ต้อง call callChkWaitlist()
                let result:any 
                // let result2:any 
                result = await callChkIsFacilityPrivate();

                // เช็คว่ากด callAPI ใหม่หรือยัง
                if(vIsCallAPI == false){
                    warning();
                    // callAPI(vDateuse);
                }else{

                    if(result.stat == true && result.facility[0].capacity >= parseInt(vMaxUser)){
                        return (
                            formProps.onFinish &&
                            formProps.onFinish(mediaUploadMapper(values))
                        );
                        // console.log("under cap")
                    }else if(result.stat == true && result.facility[0].capacity < parseInt(vMaxUser)){
                        // console.log("over cap")
                        overCapacity();
                    }else{
                        console.log("waitlist check")
                        // 1. call เพื่อเช็คว่าเข้าเงื่อนไข waiting list มั้ย
                        callChkWaitlist();
                    }




                    // // updateOnFinish(values)
                    // // 1. call เพื่อเช็คว่าเข้าเงื่อนไข waiting list มั้ย
                    // callChkWaitlist(); 
                }

                // return (
                //     formProps.onFinish &&
                //     formProps.onFinish(mediaUploadMapper(values))
                // );
            }}
        >
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={12}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("property.title")} name="property"
                                    rules={[
                                        {
                                            required: true, message: 'Please select property.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeProp} {...SelProp} disabled/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("unit.title")} name="unit"
                                    rules={[
                                        {
                                            required: true, message: 'Please select unit.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeUnit} {...SelUnit} disabled/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("user-profiles.user-profile")} name="user_profile"
                                    rules={[
                                        {
                                            required: true, message: 'Please select user profile.'
                                        },
                                    ]}>
                                        <Select placeholder={t("select.sel")} onChange={onchangeProfile} disabled
                                        >
                                            {/* {
                                                userData !== undefined?
                                                    userData.map((item:any,key:any) => {
                                                        return <>
                                                            {item.user_profile !== null? 
                                                                <>
                                                                    <Option key={key} value={item.user_profile.id}>{item.user_profile.first_name + ' ' + item.user_profile.last_name}</Option>
                                                                </>
                                                            :<></>}
                                                        </>
                                                    })
                                                :<></>
                                            } */}

                                            {
                                                filteredUserData != undefined?
                                                    filteredUserData.map((item:any,key:any) => {
                                                        return <>
                                                            {item.user_profile !== null? 
                                                                <>
                                                                    <Option key={key} value={item.user_profile.id}>{item.user_profile.first_name + ' ' + item.user_profile.last_name}</Option>
                                                                </>
                                                            :
                                                                <></>}
                                                        </>
                                                    })
                                                :
                                                    <></>
                                            }
                                        </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>

                        <Form.Item  label={t("fclt-booking.typemjong")} name="facility_type"
                            rules={[
                                {
                                    required: true, message: 'Please select facility type.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelFacilityType} onChange={onchangeFacilityType}/>
                        </Form.Item>

                        <Form.Item  label={t("fclt-booking.typemsjong")} name="facility"
                            rules={[
                                {
                                    required: true, message: 'Please select facility.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelFacility} onChange={onchangeFacility}/>
                        </Form.Item>

                        

                        <Form.Item  label={t("fclt-booking.lmuser")} name="max_user"
                            rules={[
                                {
                                    required: true, message: 'Please enter.'
                                },
                            ]}
                        >
                            <Input 
                                type={"number"} 
                                min={1} max={10}
                                onChange={e => { onchangeInput(e.target.value)}}
                                // onKeyPress={(event) => {
                                //     if (/^[0-9\b]+$/.test(event.key)) {
                                //     event.preventDefault();
                                //     }
                                // }}
                            />
                        </Form.Item>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <Form.Item label={t("fclt-booking.dateuse")} name="date" getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                            rules={[
                                {
                                    required: true, message: 'Please select date.'
                                },
                            ]}>
                            {/* <DatePicker 
                                format="YYYY-MM-DD" 
                                style={{width: "100%"}}
                                onChange={(date, dateString) => DateuseChange(date, dateString, 1)} 
                                disabledDate={(current) => {
                                    let customDate = moment().format("YYYY-MM-DD");
                                    return current && current < moment(customDate, "YYYY-MM-DD");
                                }}
                            /> */}

                            <DatePicker  
                                // format="DD/MM/YYYY" 
                                format="MM/DD/YYYY" 
                                style={{width: "100%"}}
                                onChange={(date, dateString) => DateuseChange(date, dateString, 1)} 
                                // disabledDate={(current) => {
                                //     let todayDate = moment().format("YYYY-MM-DD");
                                //     let k = moment(todayDate).add(3, 'months').format('YYYY-MM-DD')
                                //     // return current && current < moment(todayDate, "YYYY-MM-DD");
                                //     return current && current > moment(k, "YYYY-MM-DD") || current < moment(todayDate, "YYYY-MM-DD");
                                // }}
                                disabledDate={(current) => {
                                    let todayDate = moment().format("YYYY-MM-DD");
                                    let futureDate = moment(todayDate).add(3, 'months').format('YYYY-MM-DD');
                                    return (
                                      current.isAfter(futureDate, 'day') || current.isBefore(todayDate, 'day')
                                    );
                                }}
                            />


                        </Form.Item>

                        <Form.Item  label={t("fclt-booking.timeuse")} name="facility_time_slot">
                                <Select placeholder={t("select.sel")}
                                    defaultValue={recordData.facility_time_slot? <>{moment(recordData.facility_time_slot.time_start, "HH:mm").format('HH:mm') + ' - ' + moment(recordData.facility_time_slot.time_end, "HH:mm").format('HH:mm')}</> : <></>}
                                    onSelect={(value: any) => {
                                        setTimeSlot1(value);
                                    }}
                                >
                                {
                                    timeSlotData !== undefined?
                                        timeSlotData.map((item:any,key:any) => {
                                            return <>
                                                {item !== null? 
                                                    <>
                                                        <Option key={key} value={item.id}>{moment(item.time_start, "HH:mm").format('HH:mm') + ' - ' + moment(item.time_end, "HH:mm").format('HH:mm')}</Option>
                                                    </>
                                                :<></>}
                                            </>
                                        })
                                    :<></>
                                }
                                </Select>
                        </Form.Item>

                        {recordData.receipt_pix ?                         
                            <>
                            <Form.Item
                                name="receipt_pix"
                                label={t("fclt-booking.slip")}
                            >
                                <Dragger style={{borderRadius: "10px"}} 
                                    {...vSlip}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Dragger>
                            </Form.Item>
                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                            </>
                            :
                            <>
                            <Form.Item
                                name="receipt_pix"
                                label={t("fclt-booking.slip")}
                            >
                                <Dragger style={{borderRadius: "10px"}} 
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                >
                                    <p className="ant-upload-text">{t("upload")}</p>
                                </Dragger>
                            </Form.Item>
                            <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                            </>
                        }
                    </Card>

                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={14}>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{fontSize: "0.7vw"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>   
    );
};