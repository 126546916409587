/* eslint-disable */
import { Card, Col, Edit, Divider, Form, Input, Row, useForm, TextField, List, Breadcrumb, Radio, Upload, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IProjectBrand } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { TOKEN_KEY } from "../../constants";
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';

export const ProjectBrandEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TextArea } = Input;
    const API_URL = useApiUrl();
    const [vImg, setImg] = useState<any>([]);

    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { form, formProps, saveButtonProps }    = useForm<IProjectBrand>({redirect:"list"});
    const { queryResult } = useShow<IProjectBrand>({metaData: {
        populate: '*'
        }, });
    const { data } = queryResult;
    const record = data?.data;
    const { mutate: CreateLog }    = useCreate<IEventlog>();
    const log_endpoint             = "even-logs";
    const { Dragger } = Upload;

    let recordData: any;
    recordData = queryResult?.data ? queryResult?.data : [];
    recordData = data?.data ? data?.data : [];

    const [count, setCount] = useState(0)
    const [vsetTel, setTel] = useState<CountryPhoneInputValue>(); 

    useEffect(() => {
        if (count < 3) {
            const interval = setInterval(() => {
                setCount(prev => prev + 1);

                setTel({ phone: recordData ? recordData.tel : null, short: recordData ? recordData.short_tel : null })

                const fileList2: UploadFile[] = 
                    recordData.bg_image ?
                        recordData.bg_image.map((v:any)=>(
                            {
                                uid: String(v.id),
                                name: v.name,
                                status: "done",
                                url:
                                    v.url,
                                thumbUrl:
                                    v.url
                            }
                        )) 
                    : null
                ;

                setImg({
                    name: 'files',
                    multiple: true,
                    defaultFileList: [...fileList2],
                })

                form.resetFields(["bg_image"]);

            }, 500)

            return () => clearInterval(interval)
        }

    }, [count])

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'project-brands',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
    } 

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };
 
    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("post_type-edit.title")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("project-brand.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("project-brand.create")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
          onFinish={(values:any) => {
            updateUserUpdatedBy();
            values.updateBy = user_data.user_profile.id

            if (vsetTel !== null && vsetTel !== undefined) {
                values.short_tel = vsetTel?.short
                values.tel = vsetTel?.phone
            }
            return (
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
               
            );
        }}>
            <Row gutter={[16, 24]}>
            <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.name")} name="brand_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.address")} name="address">
                                    <TextArea style={{height: "30px", maxHeight: "80px"}}/>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item name="tel" label={t("user-profiles.tel")}>
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{height: "32px",borderRadius: "5px",padding: "0"}} value={vsetTel}
                                            onChange={(v) => {
                                                setTel(v);
                                            }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                                }
                                            }}
                                            minLength={9}
                                            maxLength={12}
                                        />
                                    </ConfigProvider>
                                </Form.Item> 
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.color")} name="color_gradients"
                                    rules={[
                                        {
                                            required: true, message: 'please enter color gradients.'
                                        },
                                    ]}>
                                        {/* <Input /> */}
                                        <TextArea />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("project-brand.status")} name="active_status"
                                    rules={[
                                        {
                                            required: true, message: 'Please select status.'
                                        },
                                    ]}>
                                        <Radio.Group>
                                            <Radio value={true}>Active</Radio>
                                            <Radio value={false}>Inactive</Radio>
                                        </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>

                <Card style={{backgroundColor: "#fff", marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                    <div style={{ marginBottom: 20 }}><span className="title_panel">Background Image</span></div>
                    <Divider></Divider>
                    <Form.Item name="bg_image">
                        <Dragger style={{borderRadius: "10px"}} 
                            {...vImg}
                            name="files"
                            action={`${API_URL}/upload`}
                            headers={{
                                Authorization: `Bearer ${localStorage.getItem(
                                    TOKEN_KEY,
                                )}`,
                            }}
                            listType="picture"
                            maxCount={1}
                            beforeUpload={beforeUpload}
                        >
                            <p>Upload</p>
                        </Dragger>
                    </Form.Item>
                    <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*Only PNG, JPG Maximum size 5MB</p>
                </Card>


                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={14}>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                <div style={{marginBottom: "5%"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                <div style={{marginBottom: "5%"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </Form>
    </Edit>
    );
};