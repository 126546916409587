/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, Icon, notification, ShowButton, Tooltip, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { majorCareUrl } from "../../../constants";
import { CheckCircleOutlined, QuestionOutlined, WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

    {/* 
        MAIN STATUS
        64b505fef3440e02a3156fb3 = เปิดงาน
        64b50609f3440e02a3156fb4 = ปิดงาน
    */}

    {/* 
        SUB STATUS
        ******* main = เปิดงาน ******* option_open_job
        64b50671f3440e02a3156fb5 = รอดำเนินการ
        64b5067ef3440e02a3156fb6 = กำลังดำเนินการ

        ******* main = เลื่อนนัดจากนิติ ******* option_close_job
        64b5069bf3440e02a3156fb7 = งานซ้ำ
        64b506abf3440e02a3156fb8 = ซ่อมเสร็จแล้ว
        64b506c4f3440e02a3156fb9 = เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ
        64b506dcf3440e02a3156fba = เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)
        64b506ebf3440e02a3156fbb = นิติลงมติยังไม่ดำเนินการ
    */}


export const RepairFacilityInprogressJob: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    // let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    // let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const { create, edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(6);
    const [dataList, setDataList] = useState<any>([]);
    const [dataDetailList, setDataDetailList] = useState<any>([]);
    const [JobId, SetJobId] = useState<any>(undefined);

    const [form] = Form.useForm();
    const { Option } = Select;
    // ******** MAIN STATUS ********
    const option_main_status:any = [
        {
            label: "เปิดงาน",
            value: "64b505fef3440e02a3156fb3",
        },
        {
            label: "ปิดงาน",
            value: "64b50609f3440e02a3156fb4",
        },
    ];

    // ******** WORKING SUB STATUS ***********
    const option_sub_stat:any = [
        {
            label: "รอดำเนินการ",
            value: "64b50671f3440e02a3156fb5",
        },
        {
            label: "กำลังดำเนินการ",
            value: "64b5067ef3440e02a3156fb6",
        },
        {
            label: "งานซ้ำ",
            value: "64b5069bf3440e02a3156fb7",
        },
        {
            label: "ซ่อมเสร็จแล้ว",
            value: "64b506abf3440e02a3156fb8",
        },
        {
            label: "เช็คแล้วหมดประกัน รอมติการซ่อมจากนิติ",
            value: "64b506c4f3440e02a3156fb9",
        },
        {
            label: "เช็คแล้วหมดประกัน (Major Support การดูแลการซ่อม)",
            value: "64b506dcf3440e02a3156fba",
        },
        {
            label: "นิติลงมติยังไม่ดำเนินการ",
            value: "64b506ebf3440e02a3156fbb",
        },
    ];

    const [dataChecklist, setDataCheckList] = useState<any>([]);
    const dataBlank:any = []

    const getDataK = async ()=>{
        // ระหว่างดำเนินการ ให้แสดงทุกสถานะ ยกเว้น ปิดงาน 

        // GET JOB DETAIL DATA
        let res_detail_data = await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailFacility/findRepairJobDetailByJobId`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad Detail', dataLoad)
                // console.log('prop_id', prop_id)

                let dataReverse:any = dataLoad.reverse()

                // if(prop_id !== null){
                //     dataLoad = dataLoad?.filter((e:any)=> e.property_obj[0]?.property_id == Number(prop_id))
                // }
                // let xx = dataReverse.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0].status_id == "64b505fef3440e02a3156fb3" && e.job_obj[0].sub_status_id == "64b50671f3440e02a3156fb5" )
                let xx = dataReverse.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0].status_id !== "64b50609f3440e02a3156fb4" )
                let keepData = []
                for (let index = 0; index < xx.length; index++) {
                    keepData.push(xx[index])
                    // dataDetailList.push(xx[index]);
                }
                if (!!keepData && keepData !== null) {
                    //console.log("firstKeepData",keepData)
                    setDataDetailList(keepData)
                }else{
                    setDataDetailList(xx)
                }
                // dataDetailList.reverse();
            });
    }
    // console.log('dataList', dataList)
    console.log('dataDetailList', dataDetailList)
    const { confirm } = Modal;
    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });

        notification['success']({
            message: "Successful",
            description:
                'Successfully updated',
        });
    };

    function showAcknowledge(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'ยืนยันการรับเรื่องแจ้งซ่อม', 
            icon    : <QuestionOutlined />,
            // content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateAcknowledge(id, "ยืนยันการรับเรื่องแจ้งซ่อม");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    async function updateAcknowledge(id:any, event:any){
        console.log('id : ', id)

        // ================================= CAL SLA ======================
        // ถ้า sla time ไม่เกินที่กำหนด 24 ชั่วโมง ขึ้น Y ถ้าเกิน N
        // เอาเวลาที่กด รับเรื่อง - วันที่สร้าง log 1 คิดมาเป็นจำนวนนาที

        // 1 วันมี 1440 นาที
        let presentDateTime:any = dayjs().format("YYYY-MM-DD HH:mm:ss")

        let SlaVal = 'Y'    // Y = ไม่เกิน      N = เกิน
        let SlaTime = "0"
        let TrailDate:any

        let res_data_trail = await axios.get(
            majorCareUrl + `/majorcare/repairJobTrail/findRepairTrailByJob?jid=` + id ,
            { headers: {  } }).then((res) => {
                let dataTrail: any = res?.data;
                console.log('dataTrail', dataTrail[0])

                TrailDate = dataTrail ? dayjs(dataTrail[0].create_date).format("YYYY-MM-DD HH:mm:ss") : dayjs().format("YYYY-MM-DD HH:mm:ss")

                // console.log('TrailDate', TrailDate)
                // console.log('presentDateTime', presentDateTime)

                const startDateTime = dayjs(TrailDate);
                const endDateTime = dayjs(presentDateTime);
                const diffInMinutes = endDateTime.diff(startDateTime, 'minute');
                // console.log(diffInMinutes);

                if(diffInMinutes > 1440 ){ // ถ้าเกิน 1 วัน
                    SlaVal = 'N'
                }
                SlaTime = diffInMinutes.toString()

            });

            console.log('SlaTime', SlaTime)

        // ================================= UPDATE JOB's STAT ======================
        let data = {
            "status_id": "649859e7515b80b78fe13b86",  // รับเรื่องแจ้งซ่อม
            "status_name": "รับเรื่องแจ้งซ่อม",  // รับเรื่องแจ้งซ่อม
            "sla": {
                "sla": SlaVal,
                "sla_time": SlaTime,
            }
        };
        let res = await axios.patch(majorCareUrl + "/majorcare/repairJob/updateRepairjob?id=" + id, data, { headers: { } });
        // console.log('res', res)

        // ================================= CREATE TRAIL ======================

        let dataTrail = {
            "repair_job" : id,
            "status": "649859e7515b80b78fe13b86", // รับเรื่องแจ้งซ่อม
            "status_name": "รับเรื่องแจ้งซ่อม",
            "sub_status": null,
            "appoint_start": "",
            "appoint_end": "",
            // "assign": {
            //     "name":"",
            //     "lastname":""
            // },
            "sla": {
                "sla": SlaVal,
                "sla_time": SlaTime
            },
            "remark": "",
            "create_by": Identity_data?.user_profile,
            "hod_status": {
                "hod_id": '',
                "hod_name": '',
                "hod_app_status": '', // A = approve, R = not approve 
                "hod_remark": '',
                "create_date": ''
            }
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/repairJobTrail", dataTrail, { headers: { } });
        // console.log('res_trail', res_trail)

        setCountInterval(0)
        // setDataList([])
        setDataDetailList([])

        successNoti('200', 'success');
    }

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "pp-types",
        metaData: {
            populate: [
                '*',
            ],
        },
        pagination: {
            pageSize: 10,
        },
        queryOptions: {
            onSuccess: (data:any) => {
                // console.log('data xxxx : ', data.data)
                getDataK();
            },
        },
    });

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 0){
                    // getDataK();
                    refetch();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        // getDataK();
        // refetch();
        getRepairZone();
        getRepairCategory();
    }, []);

    async function updateStatus(id:any, val:any){
        // console.log('id', id)
        let data = {
            "is_active": val,
        }
        let res_update = await axios.patch(
            // majorCareUrl + `/majorcare/updateHomecheckjob?id=` + id , data ,
            majorCareUrl + `/majorcare/repairJobFacility/updateRepairjob?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        // setDataList([])
        setDataDetailList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        // setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        // console.log('id', id)
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 2000); // orginal = 1000
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: 'JOB NO.',
            dataIndex: 'job_id',
            key: 'job_id',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.job_id < b?.job_obj[0]?.job_id) {return -1}
                if (a?.job_obj[0]?.job_id > b?.job_obj[0]?.job_id) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.job_id !== null ? record.job_id : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].job_id !== null ? record.job_obj[0].job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'PROPERTY',
            // dataIndex: 'name',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name < b?.job_obj[0]?.request_by?.unitDetail?.property_name) {return -1}
                if (a?.job_obj[0]?.request_by?.unitDetail?.property_name > b?.job_obj[0]?.request_by?.unitDetail?.property_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.property_obj.length > 0 ? record.property_obj[0].property_name : '-': '-'}</div> */}
                        <div>{record ? record.job_obj[0].request_by ? record.job_obj[0].request_by.unitDetail.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'บริเวณ',
            dataIndex: 'zone', 
            key: 'zone',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.zone_obj[0]?.zone_name < b?.zone_obj[0]?.zone_name) {return -1}
                if (a?.zone_obj[0]?.zone_name > b?.zone_obj[0]?.zone_name_) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div> */}
                        <div>{ 
                            record ? 
                                record.zone_obj[0] !== undefined ? 
                                    record.zone_obj[0].zone_name : '-': '-'} </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'หมวดหมู่',
            dataIndex: 'category',
            key: 'category',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.category_obj[0]?.category_name < b?.category_obj[0]?.category_name) {return -1}
                if (a?.category_obj[0]?.category_name > b?.category_obj[0]?.category_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.zone_obj[0] !== undefined ? record.zone_obj[0].zone_name : '-': '-'} - {record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'} - {record ? record.category_detail_obj[0] !== undefined ? record.category_detail_obj[0].category_detail_name : '-': '-'} </div> */}
                        <div>{record ? record.category_obj[0] !== undefined ? record.category_obj[0].category_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'CUSTOMER NAME',
            // dataIndex: 'updateBy',
            key: 'cus_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.request_by?.attributes?.first_name < b?.job_obj[0]?.request_by?.attributes?.first_namee) {return -1}
                if (a?.job_obj[0]?.request_by?.attributes?.first_name > b?.job_obj[0]?.request_by?.attributes?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{
                            record ? 
                                record.job_obj[0].request_by.attributes !== undefined ?
                                    record.job_obj[0].request_by.attributes.first_name + " " + record.job_obj[0].request_by.attributes.last_name  
                                : '-'
                                : '-'
                            }</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'STATUS',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.status_name < b?.job_obj[0]?.status_name) {return -1}
                if (a?.job_obj[0]?.status_name > b?.job_obj[0]?.status_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>
                        {
                                record ? 
                                    record?.job_obj[0] ?
                                        record?.job_obj[0].status_name ?
                                            <Tag className="tag_mjc_stat"><TextField value={record?.job_obj[0].status_name}  /></Tag>
                                        : '-'
                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'SUB STATUS',
            dataIndex: 'sub_status',
            key: 'sub_status',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.sub_status_name < b?.job_obj[0]?.sub_status_name) {return -1}
                if (a?.job_obj[0]?.sub_status_name > b?.job_obj[0]?.sub_status_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>
                            {
                                record ? 
                                    record?.job_obj[0] ?
                                        record?.job_obj[0].sub_status_name ?
                                            <Tag className="tag_mjc_stat"><TextField value={record?.job_obj[0].sub_status_name}  /></Tag>
                                        : '-'
                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'CREATE BY',
            // dataIndex: 'createBy',
            key: 'createBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_by?.first_name < b?.job_obj[0]?.create_by?.first_name) {return -1}
                if (a?.job_obj[0]?.create_by?.first_name > b?.job_obj[0]?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.create_by ? record?.job_obj[0]?.create_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.create_by?.last_name ? record?.job_obj[0]?.create_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'CREATED DATE',
            dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.create_date < b?.job_obj[0]?.create_date) {return -1}
                if (a?.job_obj[0]?.create_date > b?.job_obj[0]?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].create_date !== null ? dayjs(record.job_obj[0].create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'updateBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.update_by?.first_name < b?.job_obj[0]?.update_by?.first_name) {return -1}
                if (a?.job_obj[0]?.update_by?.first_name > b?.job_obj[0]?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (updateBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.job_obj[0]?.update_by ? record?.job_obj[0]?.update_by?.first_name  : "-" : "-"} {record?.job_obj[0]?.update_by?.last_name ? record?.job_obj[0]?.update_by?.last_name : ""}</div>
                    </>
                );
            },
        },
        {
            title: 'Last Update',
            dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.job_obj[0]?.update_date < b?.job_obj[0]?.update_date) {return -1}
                if (a?.job_obj[0]?.update_date > b?.job_obj[0]?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_obj[0].update_date !== null ? dayjs(record.job_obj[0].update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 100,
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {
                            record.job_obj[0].is_active === true ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        style={{marginRight:'5px'}}
                                        onClick={() => edit("repair-facility-new-job", record._id + "_" + record.job_id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                    <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record.job_obj[0]._id) }} 
                                        danger
                                    ></Button>
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record.job_obj[0]._id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };


    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property !== null ? "eq" : "ne",
            //     value : data_property !== null ? data_property : null
            // },
        ]
    });

    const getRepairZone = async () => {
        let res = await axios.get(majorCareUrl + "/majorcare/repairZoneFacility" , { headers: { } }).then(response => {
            // console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            // let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            // SetDataZone(dataLoad ? filteredDataCate : null)
            SetDataZone(dataLoad ? dataLoad : null)
        })
    }

    const getRepairCategory = async () => {
        let res = await axios.get(majorCareUrl + "/majorcare/repairCategoryFacility" , { headers: { } }).then(response => {
            // console.log('kkkk',response.data);
            let dataLoad:any
            dataLoad = response.data
            // let filteredDataCate = dataLoad.filter((e:any)=> e.is_active == true)
            // SetDataCategory(dataLoad ? filteredDataCate : null)
            SetDataCategory(dataLoad ? dataLoad : null)
        })
    }



    // MASTER REPAIR DATA
    const [ DataZone, SetDataZone] = useState<any>([]);
    const [ DataCategory, SetDataCategory] = useState<any>([]);

    const [selProp, setselProp] = useState<any>();
    const [nameSearch, setnameSearch] = useState<any>("");
    const [ SelectedCategory, SetSelectedCategory] = useState<any>(undefined);
    const [ SelectedZone, SetSelectedZone] = useState<any>(undefined);
    const [Stat, setStat] = useState<any>(undefined);
    const [SubStat, setSubStat] = useState<any>(undefined);

    function onSearchtable(data: any, mode:any, type:any){
        let newData:any = [];
        if(mode == "S"){
            newData = nameSearch !== "" ? dataDetailList?.filter((e:any)=> e?.job_obj[0].job_id.includes(String(nameSearch))) : newData;
            newData = selProp !== undefined ? dataDetailList?.filter((e:any)=> {return e?.job_obj[0].request_by?.unitDetail?.property_id == selProp?.id}) : newData;
            newData = SelectedZone !== undefined ? dataDetailList?.filter((e:any)=> {return e?.zone == SelectedZone?.value}) : newData;
            newData = SelectedCategory !== undefined ? dataDetailList?.filter((e:any)=> {return e?.category == SelectedCategory?.value}) : newData;
            newData = Stat !== undefined ? dataDetailList?.filter((e:any)=> {return e?.job_obj[0].status_id == Stat?.value}) : newData;
            newData = SubStat !== undefined ? dataDetailList?.filter((e:any)=> {return e?.job_obj[0].sub_status_id == SubStat?.value}) : newData;
            setDataDetailList(newData);
        }else if(mode == "R"){
            form?.resetFields();
            refreshDataSource();
            setselProp(undefined);
            setnameSearch("");
            SetSelectedCategory(undefined);
            SetSelectedZone(undefined);
            setStat(undefined);
            setSubStat(undefined);
        }
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };
    
    return <>
        <List title="">
            {/* <Row style={{float: 'right',marginBottom: "1.2rem"}}>
                <CreateButton 
                    id="create_btn" 
                    onClick={() => create("repair-facility-new-job")}
                    // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                />
            </Row> */}

            <Row gutter={12}>
                <Col span={24}>
                    <Form layout="vertical" 
                        // {...formProps} 
                        onFinish={(values:any) => {}}
                        form = {form} 
                    >
                        <Row gutter={24} style={{width: "100%"}}>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select placeholder={t("select.sel")} 
                                        {...selProperty} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                    </Select>

                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="job_no" label="Job No." >
                                    <Input 
                                        placeholder="Job number" 
                                        onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} 
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="zone" label="Zone">
                                    <Select
                                        placeholder={t("select.sel")} 
                                        onChange={(e:any, record:any) => SetSelectedZone(record)}
                                    >
                                        {
                                            DataZone?.length > 0 ?
                                                DataZone?.map((item:any,key:any) => {
                                                    return <>
                                                        <Option 
                                                            value={item._id}
                                                            key={item._id}
                                                        >
                                                            {item.zone_name ? item.zone_name : 'NODATA'}
                                                        </Option>
                                                    </>
                                                })
                                            : <></>
                                        }
                                    </Select>

                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="category" label="Category">
                                    <Select
                                        placeholder={t("select.sel")} 
                                        onChange={(e:any, record:any) => { SetSelectedCategory(record)} }
                                    >
                                        {
                                            DataCategory?.length > 0 ?
                                                // DataCategory?.filter((item:any) => item.zone_name == SelectedZone?.value).map((item:any,key:any) => {
                                                DataCategory?.map((item:any,key:any) => {
                                                    return <>
                                                        <Option 
                                                            value={item._id}
                                                            key={item._id}
                                                            data={item}
                                                        >
                                                            {item.category_name ? item.category_name : 'NODATA'}
                                                        </Option>
                                                    </>
                                                })
                                            : <></>
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="status" label="Status">
                                    <Select 
                                        placeholder={t("select.sel")}
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            setStat(record);
                                        }}
                                        options={option_main_status}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="gutter-row">
                                <Form.Item name="sub_status" label="Sub Status">
                                    <Select 
                                        placeholder={t("select.sel")}
                                        style={{ width: '100%' }}
                                        onSelect={(value: any, record:any) => {
                                            setSubStat(record);
                                        }}
                                        options={option_sub_stat}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S", "xxxxx")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R", "xxxx")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>

            {/* <div>🌛🌛🌛🌛🌛🌛🌛🌛🌛🌛🌛🌛🌛🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆🔆</div> */}
            <div>
                {
                    dataDetailList.length > 0 ?
                        <Table 
                            // dataSource={dataList} 
                            // dataSource={dataList.length > 0 ? dataList : dataBlank} 
                            dataSource={dataDetailList.length > 0 ? dataDetailList : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ 'bottomRight'] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 2000 }}
                        />
                    :
                        <Table  
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{ size: "small", position: [ 'bottomRight'] }} 
                            scroll={{ x: 2000 }}
                        />
                }
            </div>
        </List>
    </>
}