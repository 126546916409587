/* eslint-disable */
import { Col, Empty, Row,  TextField, Icons } from "@pankod/refine-antd";

import { IFacilityCheckinQR } from "interfaces";
import { useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";
import {QRCodeCanvas} from 'qrcode.react';
import React, { useRef } from "react";
import { useReactToPrint } from 'react-to-print';
import "./css/style.css";

export const FaciiltyGenCheckInShow: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();

    const { queryResult } = useShow<IFacilityCheckinQR>({  
        metaData: {
            populate: ['facility', 'facility.name', 'facility.property', 'createBy', 'updateBy']
        }, 
    });
   
    const { data } = queryResult;
    const record = data?.data;

    const componentRef = useRef<any>();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });

    class ComponentToPrint extends React.Component {
        render() {
          return (
            
            <div>
              <Row gutter={16}>
                <Col className="gutter-row" span={24} style={{display: "flex",justifyContent: "center",marginTop: "3%"}}>
                    <TextField 
                        className="t_2" 
                        value={record !== undefined ? "(" + "โครงการ " +record.facility.property.property_name + ")" : "ไม่มีเนื้อหา"} 
                        style={{fontSize: "22px",marginLeft: "2%"}}
                    />
                    <TextField 
                        className="t_1" 
                        value={record !== undefined ? "Facility  : " + record.facility.name : "ไม่มีเนื้อหา"} 
                        style={{fontSize: "22px",marginLeft: "2%"}}
                    />
                </Col>

                <Col className="gutter-row" span={24} style={{display: "flex",justifyContent: "center"}}>
                    {record?.code !== undefined && record?.code !== null ? 
                        <QRCodeCanvas 
                            value={record?.code !== null ? record?.code : '-'} 
                            style={{width: "350px",height: "350px",marginTop: "5%",marginLeft: "3%",marginBottom: "3%"}} 
                        />
                    :
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    }
                </Col>

                <Col className="gutter-row" span={24} style={{display: "flex",justifyContent: "center"}}>
                    <div style={{padding: "1% 2% 1% 2%",borderRadius: "10px",width: "400px",textAlign: "center",marginTop: "2%"}}>
                        <TextField value={record !== undefined ? record.title : "ไม่มีเนื้อหา"} style={{fontSize: "18px"}}/>
                    </div>
                </Col>
              </Row>
            </div>
          );
        }
      }

    return (
        <>
            <div style={{maxWidth: "600px",margin: "2% auto",borderRadius: "15px",border: "1px solid #ddd"}}>
                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={24}>
                        <ComponentToPrint ref={componentRef} />
                    </Col>
                    <Col className="gutter-row" span={24} style={{display: "flex",justifyContent: "center",marginBottom: "3%"}}>
                        <Row gutter={16} style={{width: "300px"}}>
                            <Col className="gutter-row" span={14}>
                                <div style={{marginBottom: "5%",fontSize: "15px"}}>{t("createdBy")}</div>
                                <div style={{marginBottom: "5%",fontSize: "15px"}}>{t("lupdateBy")}</div>
                            </Col>

                            <Col className="gutter-row" span={10}>
                                <div style={{marginBottom: "5%",fontSize: "15px"}}>{record?.createBy? record?.createBy.first_name :"-"}</div>
                                <div style={{fontSize: "15px"}}>{record?.updateBy? record?.updateBy.first_name :"-"}</div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="btn_custom_i_print" onClick={handlePrint} style={{paddingTop: "5px"}}>
                <Icons.PrinterOutlined style={{marginRight: "10px"}}/><span>Export</span>
            </div>
        </>
    )
};