/* eslint-disable */
import { Show, TextField, Typography, Image, List, Space, Breadcrumb, Row, Col, Card, Carousel, Empty } from "@pankod/refine-antd";

import { IProjectinfo } from "interfaces";

// import { useTranslate, useShow } from "@pankod/refine-core";
import { useShow, useTranslate } from "@pankod/refine-core";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {  GlobalOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";

import Moment from 'moment';
import GoogleMapReact from "google-map-react";
import { useEffect, useState } from "react";

// import Moment from 'moment';

export const ProjectinfoShow: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();

    const { queryResult } = useShow<IProjectinfo>({  
        metaData: {
        populate: '*'
    }, 
});
    const { data, isLoading } = queryResult;
    const record = data?.data;
    //console.log(record);
    // const llat = String(record?.lat)
    // const llng =String(record?.lng)
    const { Text } = Typography;



    let recordData:any = []
    recordData = data?.data? data?.data : [];

    const [count, setCount]             = useState(0)
    const [vLat, setLat]                = useState(recordData.lat ? recordData.lat : 13.0000);
    const [vLng, setLng]                = useState(recordData.lng ? recordData.lng : 100.0000);
    const hlat:any = Number
    const hlng:any = Number

    const [position, setPosition] = useState({
        lat: recordData.lat ? parseFloat(recordData.lat) : parseFloat("13.0000"), 
        lng: recordData.lng ? parseFloat(recordData.lng) : parseFloat("100.0000")
    });
    useEffect(() => {
        // hlat == record?.lat
        // hlng == record?.lng
        if (count < 4) {
          const interval = setInterval(() => {
            setCount(prev => prev + 1)
            setLat(recordData.lat ? recordData.lat : 13.0000);
            setLng(recordData.lng ? recordData.lng : 100.0000);


            setPosition({
                lat: recordData.lat ? parseFloat(recordData.lat) : parseFloat("13.0000"), 
                lng: recordData.lng ? parseFloat(recordData.lng) : parseFloat("100.0000")
            })

          }, 1000)
          return () => clearInterval(interval)
        }
    }, [count])
//   console.log( position)
    const defaultProps = {
        lat: record?.lat, 
        lng: record?.lng,
        // center: {
        //     lat: vLat, 
        //     lng: vLng,
        // },
        zoom:10,
        // center: {
        //     lat: 13.6619, 
        //     lng: 100.5868
        // },
        // zoom: 9,
    };
  
    const ProjectInfoMarker = ({text} : any) => (
        <div className="pin" 
            // style={{
            // color: 'white', 
            // background: 'red',
            // padding: '15px 10px',
            // display: 'inline-flex',
            // textAlign: 'center',
            // alignItems: 'center',
            // justifyContent: 'center',
            // //   borderRadius: '100%',
            // //   transform: 'translate(-50%, -50%)'
            // }}
        >
          {text}
        </div>
      );

//    console.log("mmmm",record)

    return (
        <Show isLoading={isLoading} pageHeaderProps={{extra: <></>}}>

        <List 
            title={t("")}
            pageHeaderProps={{ 
                extra : <></>
            }}
        />
            <Space style={{marginBottom: "3%"}}>
                <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("project-info.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("projects-info-show.title")}</span></Breadcrumb.Item>
                </Breadcrumb>
            </Space>

                <Row gutter={[16, 24]}>
                    <Col className="gutter-row" span={18}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",background: "linear-gradient(151deg, rgba(64,73,95,1) 0%, rgba(43,51,70,1) 93%)",border: "unset",marginBottom: "2%"}}>
                                    <Row>
                                        {/* <Col span={5}>
                                                {
                                                    record?.pix !== undefined ?
                                                    <>
                                                        <Avatar 
                                                            style={{border:"2px soLid #fff",width: "100%", height: "auto",display: "block", marginLeft: "auto", marginRight: "auto",background: "radial-gradient(circle, rgba(97,106,131,1) 0%, rgba(72,79,98,1) 64%)",borderRadius: "10px"}}
                                                            src={record?.pix === null ? "https://joeschmoe.io/api/v1/random" :record?.pix.url} 
                                                        />
                                                    </>:<>-</>
                                                }
                                        </Col> */}
                                            
                                        <Col className="gutter-row" span={19}>
                                            <div style={{marginLeft: "5%"}}>
                                                <TextField  style={{fontSize: "2vw",fontWeight: "600",letterSpacing: "0.5px",color : "#fff"}} value={record?.Project_Name ? record?.Project_Name : '-'} />
                                            </div>

                                            <div style={{marginLeft: "5%", marginBottom: "2%", marginTop: "2%", borderRadius: "10px"}}>
                                                <span className="id-userprofile"><Text style={{borderRadius: "50px 0px 0px 50px",paddingLeft: "3%",boxShadow: "rgb(50 50 93 / 25%) 0px 6px 12px -2px, rgb(0 0 0 / 30%) 0px 0px 10px -10px"}}>ID</Text>
                                                    <TextField style={{borderRadius: "0px 50px 50px 0px", paddingLeft: "2%", paddingRight: "3%", boxShadow: "rgb(50 50 93 / 25%) -2px 6px 10px -2px, rgb(0 0 0 / 30%) 0px 0px 20px -10px"}} value={record?.id ? record?.id : '-'} />
                                                </span>

                                                <span 
                                                    style={{
                                                        fontSize: "0.8vw",
                                                        marginTop: "10%",

                                                        marginLeft: "2%",
                                                        borderRadius: "50px", 
                                                        paddingLeft: "2%", 
                                                        paddingRight: "2%",
                                                        paddingBottom: "1%",
                                                        paddingTop: "1%",
                                                        boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
                                                        background: "rgb(172, 172, 172)",
                                                    }} >
                                                        <TextField style={{color: "#fff"}} value={record?.Project_Tag ? record?.Project_Tag : '-'} />
                                                </span>

                                                <span style={{marginLeft: "2%"}}>
                                                    {
                                                        record?.status == null ? <></>:

                                                        record?.status == "ACTIVE" ?
                                                            // <span className="status_user_show"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={record?.status ? record?.status : '-'} /></span>
                                                            <span className="status_user_show"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value="PUBLIC" /></span>
                                                            :
                                                            // <span className="status_user_show_n"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value={record?.status ? record?.status : '-'} /></span>
                                                            <span className="status_user_show_n"><TextField style={{borderRadius: "50px", paddingLeft: "2%", paddingRight: "2%"}} value="DRAFT" /></span>
                                                    }
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%"}}>
                                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                        <Col className="gutter-row" span={12} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                            <GlobalOutlined style={{fontSize: "0.7vw", marginRight: "1%",border: "1px solid #40495F",padding: "1.5% 1.5% 1.5%",borderRadius: "30px",color: "#40495F"}}/><a href={record?.Project_Link ? record?.Project_Link : '#'} target="_blank"><TextField className="link_custom" style={{marginLeft: "2%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.Project_Link ? record?.Project_Link : 'NO DATA'} /></a>
                                        </Col>

                                        <Col className="gutter-row" span={6} style={{borderRight: "1px solid rgb(172, 172, 172)"}}>
                                            <PhoneOutlined style={{fontSize: "0.7vw", marginRight: "1%",border: "1px solid #40495F",padding: "3% 3% 3%",borderRadius: "30px",color: "#40495F"}}/><TextField style={{marginLeft: "3%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px",marginRight: "1%"}} value={record?.country_code} /><TextField style={{fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.project_phone ? record?.project_phone : 'NO DATA'} />
                                        </Col>

                                        <Col className="gutter-row" span={6}>
                                            {/* <MailOutlined style={{fontSize: "0.7vw", marginRight: "1%",border: "1px solid #40495F",padding: "3% 3% 3%",borderRadius: "30px",color: "#40495F"}}/><TextField style={{padding: "2%",marginLeft: "3%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.project_line ? record?.project_line : 'NO DATA'} /> */}
                                            <Image src={"/../images/svg/line_logo.svg"} preview={false} style={{width: "1.8rem",height: "1.8rem",marginRight: "1%",border: "1px solid #40495F",padding: "3% 3% 3%",borderRadius: "30px"}}/><TextField style={{padding: "2%",marginLeft: "3%",fontSize: "0.7vw",fontWeight: "600",letterSpacing: "0.5px"}} value={record?.project_line ? record?.project_line : 'NO DATA'} />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                    <Col className="gutter-row" span={12}>
                                        {/* <Carousel style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px",height: "25vh"}} autoplay>
                                            {
                                                recordData.Project_Image !== undefined && recordData.Project_Image !== null ?
                                                recordData.Project_Image.map((data:any,index:any) => {
                                                    return <>
                                                        <Image
                                                            src={data.url}
                                                            width={"100%"} height={"25vh"} style={{height:"100%",objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px"}} />
                                                    </>
                                                })
                                            : 
                                            <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",height: "25vh"}}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </Card>
                                            }
                                            
                                        </Carousel> */}

                                        {
                                        recordData.Project_Image !== undefined && recordData.Project_Image !== null ? 
                                        <Carousel style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px",height: "25vh"}} autoplay>
                                            {
                                            recordData.Project_Image.map((data:any,index:any) => {
                                                    return <>
                                                        <Image
                                                            src={data.url}
                                                            width={"100%"} height={"25vh"} style={{height:"100%",objectFit: "cover",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",borderRadius: "10px"}} />
                                                    </>
                                                })}
                                        </Carousel>
                                        :
                                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",height: "25vh"}}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                        </Card>
                                        }
                                    </Col>

                                    <Col className="gutter-row" span={12}>
                                            <div style={{ height: '25vh', width: '100%',boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginBottom: "2%",borderRadius: "10px" }}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={{
                                                        key: "AIzaSyCv1oqlHy7gI3e5-DNe3rYyenP1KdPp1xk"//process.env.REACT_APP_MAP_ID,
                                                    }}
                                                    defaultZoom={defaultProps.zoom}
                                                    center={position}
                                                    yesIWantToUseGoogleMapApiInternals
                                                >
                                                    <ProjectInfoMarker
                                                        lat={vLat}
                                                        lng={vLng}
                                                    />
                                                </GoogleMapReact>
                                            </div>
                                    </Col>
                                </Row>    
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",marginTop: "1%",marginBottom: "10%"}}>
                                    <TextField style={{fontSize: "0.7vw"}} value={record?.Project_Description ? record?.Project_Description : '-'} />
                                </Card>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="gutter-row" span={6}>
                        <Card style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <div style={{ marginBottom: "7%" }}><span className="title_information" style={{fontSize: "0.7vw"}}>{t("information.txt")}</span></div>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col className="gutter-row" span={10}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("created")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("by")}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                    <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                                </Col>
                                                        
                                <Col className="gutter-row" span={14}>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}>{record?.createdBy? record?.createdBy.first_name :"-"}</div>
                                    <div style={{marginBottom: "5%",fontSize: "0.7vw"}}><TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /></div>
                                    <div style={{fontSize: "0.7vw"}}>{record?.updatedBy? record?.updatedBy.first_name :"-"}</div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
        </Show>
    );
};