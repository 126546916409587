/* eslint-disable */
import { Edit, Form, Input, useForm, useSelect, Row, Col, Divider, Card, List, Space, Breadcrumb, RadioChangeEvent, Icons, useModal, Modal, useSimpleList, Upload, Tooltip, message, RcFile, Avatar, Select, Button, Switch, Radio } from "@pankod/refine-antd";
import { HttpError, useApiUrl, useCreate, useGetIdentity, useList, useShow, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICompany, IDepartment, IEventlog, IProperties, IRManage, IUserprefix, IUserProfile, IUserSubType, IUserType, IUserTypeMap } from "interfaces";
import { useRef, useState,useEffect } from "react";
import { TOKEN_KEY } from "../../constants";
import CountryPhoneInput, { ConfigProvider, CountryPhoneInputValue } from 'antd-country-phone-input';
import en from 'world_countries_lists/data/countries/en/world.json';
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import axios from "axios";

let MAILDATA:any = [];
let defaultPixurl: any = "../../images/svg/image.svg";
let positiondt: any = undefined;
let propdt: any = undefined;
let roledt: any = undefined;
let TypeMapData: any;

export const UserManageEdit = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Dragger } = Upload;
    const log_endpoint = "even-logs";
    const refUpload = useRef<any>();
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;

    const [swpwd, setswpwd] = useState<boolean>(false);
    const [vUserPwd, SetPwd] = useState();
    const [RealUserID, SetRealUserID] = useState();

    const [selID, setID] = useState<any>();
    const [selUtype, setselUtype] = useState<any>();
    const [vTel, setTel] = useState<CountryPhoneInputValue>();
    const [selStatus, setStatus] = useState<any>();
    const [vCompany, setCompany] = useState<any>();
    const [vDepartment, setDepartment] = useState<any>();
    const [disabled, setDisabled] = useState(false);

    const [ vProp, setProp ] = useState<any>();
    const [ vPropArr, setPropArr] = useState<any>([]);
    const [vPropArrC, setPropArrC] = useState<any>([]);

    const [vRole, setRole] = useState<any>();
    const [vRoleArr, setRoleArr] = useState<any>([]);
    const [vRoleArrC, setRoleArrC] = useState<any>([]);

    const [vPosition, setPosition] = useState<any>();
    const [vPositionArr, setPositionArr] = useState<any>([]);
    const [vPositionArrC, setPositionArrC] = useState<any>([]);

    // AVATAR
    const [vpixlogo, setpixlogo] = useState<any>([]);
    const [swpixList, setswpixList] = useState<boolean>(true);
    const [pixURL, setpixURL] = useState<any>();
    const [vpixChange, setpixChange] = useState<any>();
    
    //CHECK EMAIL
    const [vUserEmail, setUserEmail] = useState<any>();
    const [vcheckEmail, setcheckEmail] = useState();
    const [vdisabledSAVE, setdisabledSAVE] = useState(true);
    const [vMAILC, setMAILC] = useState<any>();
    const [vICONSEARCH, setICONSEARCH] = useState<any>(<Icons.SearchOutlined />);
    const [vSTYPEICON, setSTYPEICON] = useState<any>("#626262");
    const [vSTYPEINPUT, setSTYPEINPUT] = useState<any>("1px solid #d9d9d9");
    const [vTOOLTIP, setTOOLTIP] = useState<any>(t("msg-vld-mail.tooltip-search"));

    //USER TYPE MAPING
    const [vMappingID, setMappingID] = useState<any>();
    const [vremoveItem, setremoveItem] = useState<any>([]);

    //STAFF
    // const [vStaffData, setStaffData] = useState<any>([]);

    const [dtmgnAdmin, setmgnAdmin] = useState<any>() //MGN ADMIN
    const [dtmgnProp, setmgnProp] = useState<any>([]) //MGNPROP STAFF
    const [dtmgnPropOLD, setmgnPropOLD] = useState<any>([]) //MGNPROPOLD STAFF
    const [tickerFlag, settickerFlag] = useState<boolean>(false)

    const { mutate: updateUser } = useUpdate<IUserTypeMap>();
    const { mutate: createUser} = useCreate<IUserTypeMap>();
    const { mutate: CreateLog } = useCreate<IEventlog>();

    const [countInterval, setCountInterval] = useState(0);
    
    const { form, formProps, saveButtonProps } = useForm<IUserProfile, HttpError, {}>();

    const { queryResult } = useShow<IUserProfile>({
        metaData: {
            // populate: '*'
            populate: ['*','user', 'user_prefix', 'user_type', 'avatar']
        },
        queryOptions:{onSuccess(data) {
            let dataLoad:any = data?.data;
            // console.log(">>> dataLoad",dataLoad)

            form?.setFieldsValue({
                // user_prefix: dataLoad?.user_prefix ? {label: dataLoad?.user_prefix?.prefix_name, id:dataLoad?.user_prefix?.id}: null,
                // user_type: dataLoad?.user_type ? {label: dataLoad?.user_type?.type_name , id:dataLoad?.user_type?.id}: null,
                user_prefix: dataLoad?.user_prefix ? dataLoad?.user_prefix?.id: null,
                user_type: dataLoad?.user_type ? dataLoad?.user_type?.id: null,
            }) 

            setID(dataLoad?.id);
            setselUtype({id: dataLoad?.user_type?.id, label: dataLoad?.user_type?.type_name});
            setdisabledSAVE(false);

            setTel({ phone: dataLoad ? dataLoad.tel : null, short: dataLoad ? dataLoad.short_tel : null })
            SetRealUserID(dataLoad ? dataLoad?.user ? dataLoad?.user?.id : null : null )

            if (dataLoad?.avatar !== null) {
                setswpixList(false);
                setpixChange(dataLoad?.avatar);
                setpixURL(dataLoad?.avatar ? dataLoad?.avatar.url : undefined);
            } else {
                setswpixList(true);
                setpixChange(undefined);
                setpixURL(undefined);
            }

            // form?.setFieldsValue({
            //     // user_prefix: dataLoad?.user_prefix ? {label: dataLoad?.user_prefix?.prefix_name, id:dataLoad?.user_prefix?.id}: null,
            //     // user_type: dataLoad?.user_type ? {label: dataLoad?.user_type?.type_name , id:dataLoad?.user_type?.id}: null,
            //     user_prefix: dataLoad?.user_prefix ? dataLoad?.user_prefix?.id: null,
            //     user_type: dataLoad?.user_type ? dataLoad?.user_type?.id: null,
            // })
            // recordData = data?.data ? data?.data : [];


        },}
    });


    const userEmail = useList<IUserProfile>({
        metaData: {
            populate: '*'
        },
        resource: "user-profiles",
        config: {
            filters: [
                {
                    field: "user][email]",
                    operator: "in",
                    value: vMAILC !== undefined ? vMAILC : null,
                },
            ],
        },
        queryOptions:{onSuccess(data) {
            let dataLoad = data?.data;
            MAILDATA = dataLoad;
        },}
    });

    const position = useList<IUserSubType>({
        resource: "user-sub-types",
        metaData: {
            populate: '*'
        },

        config: {
            sort: [{ order: "asc", field: "id" }],
            filters: [
                {
                    field: "sub_type_name",
                    operator: "in",
                    value: ["Manager", "HOD", "Editor"]
                },
            ],
            pagination: { current: 1, pageSize: 100 }
        },
        queryOptions: {
            onSuccess(data) {
                let dataLoad = data?.data;
                positiondt = dataLoad;
            }
        }
    });

    // const typeMap = useList<IUserTypeMap>({
    // const typeMap = useList<IUserTypeMap>({
    //     resource: "user-type-mappings",
    //     metaData: {
    //         populate: '*'
    //     },

    //     config: {
    //         sort: [{ order: "asc", field: "id" }],
    //         filters: [
    //             {
    //                 field: "user_profile][id]",
    //                 operator: "in",
    //                 value: selID !== undefined ? selID : null
    //             },
    //             {
    //                 field: "status",
    //                 operator: "in",
    //                 value: true
    //             },
    //         ],
    //         pagination: { current: 1, pageSize: 1000 }
    //     },
    //     queryOptions:{onSuccess(data) {
    //         let dataLoad = data?.data;
    //         TypeMapData = dataLoad ? dataLoad : [];

    //         // console.log(">>> TypeMapData", TypeMapData)

    //         if (TypeMapData[0]?.user_type?.type_name == "Admin") {
    //             let GetMappingID
    //             let GetTypeID
    //             let GetCompanyID
    //             let GetDepartmentID
    //             let GetRoleID

    //             dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
    //                 GetMappingID = v?.id
    //                 GetTypeID = v?.user_sub_type?.id
    //                 GetCompanyID = v?.company?.id
    //                 GetDepartmentID = v?.department ? v.department.id : null
    //                 GetRoleID = v?.role_management?.id
    //             })

    //             if(TypeMapData?.length > 0){
    //                 let newData: any = undefined;
                    
    //                 newData = {
    //                     "id": TypeMapData[0]?.id,
    //                     "role": TypeMapData[0]?.role_management?.id,
    //                     "position": TypeMapData[0]?.user_sub_type?.id,
    //                 }

    //                 setmgnAdmin(newData)
    //             }

    //             setMappingID(GetMappingID ? GetMappingID : null)
    //             setPosition(GetTypeID ? GetTypeID : null)
    //             setCompany(GetCompanyID ? GetCompanyID : null)
    //             setDepartment(GetDepartmentID ? GetDepartmentID: null)
    //             setRole(GetRoleID ? GetRoleID : null)

    //             // setPosition(TypeMapData && TypeMapData.length > 0 && TypeMapData[0].user_sub_type ? TypeMapData[0].user_sub_type.id : undefined);

    //             form?.setFieldsValue({
    //                 SelComp:
    //                     TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
    //                         return { value: v?.company?.id, label: v?.company?.company_name }
    //                     }),
    //                 SelDept:
    //                     TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
    //                         return { value: v?.department?.id, label: v?.department?.dept_name }
    //                     }),
    //                 role:
    //                     TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
    //                         return { value: v?.role_management?.id, label: v?.role_management?.custom_role_name }
    //                     }),
    //                 position:
    //                     TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
    //                         return v?.user_sub_type?.id
    //                     }),
    //             })
    //         }else if (TypeMapData[0]?.user_type?.type_name == "Staff") {
    //             console.log("STAFF")
    //             let GetCompanyID
    //             let GetDepartmentID

    //             dataLoad && dataLoad?.length > 0 && dataLoad?.map((f: any) => {
    //                 GetCompanyID = f?.company ? f?.company?.id : null
    //                 GetDepartmentID = f?.department ? f?.department?.id : null
    //             })

    //             setCompany(GetCompanyID ? GetCompanyID : null)
    //             setDepartment(GetDepartmentID ? GetDepartmentID: null)

    //             if(TypeMapData?.length > 0){
    //                 let newData: any = []
                    
    //                 for (let i = 0; i < TypeMapData?.length; i++) {
    //                     newData.push(
    //                         {
    //                             "id": TypeMapData[i]?.id,
    //                             "key": i,
    //                             "property": TypeMapData[i]?.property?.id,
    //                             "role": TypeMapData[i]?.role_management?.id,
    //                             "position": TypeMapData[i]?.user_sub_type?.id,
    //                             "type": TypeMapData[i]?.user_type?.type_name,
    //                             "status": true
    //                         }
    //                     )
    //                 }
    //                 setmgnPropOLD(newData)
    //             }
                
    //             form?.setFieldsValue({
    //                 SelComp:
    //                     TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
    //                         return { value: v?.company?.id, label: v?.company?.company_name }
    //                     }),
    //                 SelDept:
    //                     TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
    //                         return { value: v?.department?.id, label: v?.department?.dept_name }
    //                     }),
    //             })
    //         }

    //         // selUtype?.label == "Super Admin" ? "orange" : selUtype?.label == "Admin" ? "magenta" : selUtype?.label == "Staff"
    //     },}
    // });

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "user-type-mappings",
        metaData: {
            populate: [
                '*', 
                'user_type',
                'user_type.type_name',
                'role_management',
                'user_sub_type',
                'company',
                'company.company_name',
                'property',
                'department',
                'department.dept_name'
            ]
        },
        pagination: {
            pageSize: 100,
        },
        
        permanentFilter: [
            // {
            //     field: "id",
            //     operator: "in",
            //     value: recordData.id,
            // }
            {
                field: "user_profile][id]",
                operator: "in",
                value: selID !== undefined ? selID : null
            },
            {
                field: "status",
                operator: "in",
                value: true
            },
        ],
        queryOptions: {
            onSuccess: (data) => {
                let dataLoad = data?.data;
                TypeMapData = dataLoad ? dataLoad : [];
    
                // console.log(">>> TypeMapData", TypeMapData)
    
                if (TypeMapData[0]?.user_type?.type_name == "Admin") {
                    let GetMappingID
                    let GetTypeID
                    let GetCompanyID
                    let GetDepartmentID
                    let GetRoleID
    
                    dataLoad && dataLoad?.length > 0 && dataLoad?.map((v: any) => {
                        GetMappingID = v?.id
                        GetTypeID = v?.user_sub_type?.id
                        GetCompanyID = v?.company?.id
                        GetDepartmentID = v?.department ? v.department.id : null
                        GetRoleID = v?.role_management?.id
                    })
    
                    if(TypeMapData?.length > 0){
                        let newData: any = undefined;
                        
                        newData = {
                            "id": TypeMapData[0]?.id,
                            "role": TypeMapData[0]?.role_management?.id,
                            "position": TypeMapData[0]?.user_sub_type?.id,
                        }
    
                        setmgnAdmin(newData)
                    }
    
                    setMappingID(GetMappingID ? GetMappingID : null)
                    setPosition(GetTypeID ? GetTypeID : null)
                    setCompany(GetCompanyID ? GetCompanyID : null)
                    setDepartment(GetDepartmentID ? GetDepartmentID: null)
                    setRole(GetRoleID ? GetRoleID : null)
    
                    // setPosition(TypeMapData && TypeMapData.length > 0 && TypeMapData[0].user_sub_type ? TypeMapData[0].user_sub_type.id : undefined);
    
                    form?.setFieldsValue({
                        SelComp:
                            TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                                return { value: v?.company?.id, label: v?.company?.company_name }
                            }),
                        SelDept:
                            TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                                return { value: v?.department?.id, label: v?.department?.dept_name }
                            }),
                        role:
                            TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                                return { value: v?.role_management?.id, label: v?.role_management?.custom_role_name }
                            }),
                        position:
                            TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                                return v?.user_sub_type?.id
                            }),
                    })
                }else if (TypeMapData[0]?.user_type?.type_name == "Staff") {
                    console.log("STAFF")
                    let GetCompanyID
                    let GetDepartmentID
    
                    dataLoad && dataLoad?.length > 0 && dataLoad?.map((f: any) => {
                        GetCompanyID = f?.company ? f?.company?.id : null
                        GetDepartmentID = f?.department ? f?.department?.id : null
                    })
    
                    setCompany(GetCompanyID ? GetCompanyID : null)
                    setDepartment(GetDepartmentID ? GetDepartmentID: null)
    
                    if(TypeMapData?.length > 0){
                        let newData: any = []
                        
                        for (let i = 0; i < TypeMapData?.length; i++) {
                            newData.push(
                                {
                                    "id": TypeMapData[i]?.id,
                                    "key": i,
                                    "property": TypeMapData[i]?.property?.id,
                                    "role": TypeMapData[i]?.role_management?.id,
                                    "position": TypeMapData[i]?.user_sub_type?.id,
                                    "type": TypeMapData[i]?.user_type?.type_name,
                                    "status": true
                                }
                            )
                        }
                        setmgnPropOLD(newData)
                    }
                    
                    form?.setFieldsValue({
                        SelComp:
                            TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                                return { value: v?.company?.id, label: v?.company?.company_name }
                            }),
                        SelDept:
                            TypeMapData && TypeMapData?.length > 0 && TypeMapData?.map((v: any) => {
                                return { value: v?.department?.id, label: v?.department?.dept_name }
                            }),
                    })
                }
        },
        // refetchInterval: 2000
        
    }});

    // useEffect(() => {
    //     refetch();
    //     // console.log('');
    // }, []);

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 0){
                    refetch();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    const { selectProps: selProp } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ],
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.property_name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    propdt = newData;
                }
            },
        }
    });

    const { selectProps: selComp } = useSelect<ICompany>({
        resource: "companies",
        optionLabel: "company_name",
        optionValue: "id",
    });

    const { selectProps: selDepart } = useSelect<IDepartment>({
        resource: "departments",
        optionLabel: "dept_name",
        optionValue: "id",
        filters: [
            {
                field: 'company][id]',
                operator: 'eq',
                value: vCompany
            }
        ]
    });

    const { selectProps: selRole } = useSelect<IRManage>({
        resource: "role-managements",
        optionLabel: "custom_role_name",
        optionValue: "id",
        fetchSize: 1000,
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newData: any = [];

                for (let i = 0; i < dataLoad?.length; i++) {
                    newData?.push(
                        {
                            "label":  dataLoad[i]?.custom_role_name,
                            "value": dataLoad[i]?.id
                        }
                    )
                }

                if(newData?.length > 0){
                    roledt = newData;
                }
            },
        }
    });

    const { selectProps: selPrefix } = useSelect<IUserprefix>({
        resource: "user-prefixes",
        optionLabel: "prefix_name",
        optionValue: "id",
    });

    const { selectProps: selUserType } = useSelect<IUserType>({
        resource: "user-types",
        optionLabel: "type_name",
        optionValue: "id",
        filters     : [
            {
                field       : 'type_name',
                operator    : 'in',
                value       : ["Staff","Admin"]
            }
        ]
    });

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const success = () => {
        Modal.success({
            title: <span>{t("msg-vld-mail.title-success")}</span>,
        //   content: 'some messages...some messages...',
        });
    };

    const error = () => {
        Modal.error({
          title: <span>{t("msg-vld-mail.title-error")}</span>,
          content: <span>{t("msg-vld-mail.content-error")}</span>,
        });
    };

    const warning = () => {
        Modal.warning({
          title: <span>{t("msg-vld-mail.title-warning")}</span>,
          content: <span>{t("msg-vld-mail.content-warning")}</span>,
        });
    };

    function updateUserUpdatedBy() {
        CreateLog({
            resource: log_endpoint,
            values: {
                user_profile: user_data.user_profile.id,
                log_time_stamp: Date.now(),
                log_event: "Edit",
                menu_event: 'users',
                SysPkID: String(selID),
            },
            successNotification: false
        });
    }

    const updateuserData = async (v: any) => {
        const AuthStr = 'Bearer '.concat(TOKEN_KEY);
        let data: {};
        data = {
            // "email": vUserEmail,
            // "username": vUserEmail,
            "password": vUserPwd
        };
        let send = await axios.put(API_URL + "/users/" + RealUserID, data, { headers: { Authorization: AuthStr } });
    }

    function fileChange(e: any) {
        if (e.file.response) {
            let res = e.file.response;
            changepixUrl(res[0].url, res[0])
        }
    }

    function changepixUrl(url: any, val: any) {
        setpixURL(url);
        setpixChange(val);
        setswpixList(false)
    }

    function removepixList() {
        setpixURL(defaultPixurl);
        setpixChange(undefined);
        setswpixList(true)
    }

    function toggleDisabled() {
        setDisabled(!disabled);
    }

    function toggleDisabledX() {
        if(disabled == true){
            setDisabled(!disabled);
        }
    }

    function onchangeEmail(value: any) {
        setMAILC(value);
        setUserEmail(value)
        setcheckEmail(vUserEmail);

        if(vcheckEmail !== vUserEmail){
            setICONSEARCH(<Icons.SearchOutlined />);
            setTOOLTIP(t("msg-vld-mail.tooltip-search"));
            setdisabledSAVE(true);
            setSTYPEICON("inherit");
        }
    }

    function opvalidateModal() {
        if(MAILDATA?.length > 0){
            error();
            setTOOLTIP(t("msg-vld-mail.tooltip-error"));
            setICONSEARCH(<Icons.StopOutlined />);
            setSTYPEICON("#cf1322");
            setSTYPEINPUT("1px solid #f5222d");
            setdisabledSAVE(true);
        }else{
            if(vSTYPEICON !== "#389e0d"){
                success();
                setTOOLTIP(t("msg-vld-mail.tooltip-success"));
                setICONSEARCH(<Icons.CheckOutlined />);
                setSTYPEICON("#389e0d");
                setSTYPEINPUT("1px solid #d9d9d9");
                setdisabledSAVE(false);
            }
        }
    }

    function clearState(){
        setProp(undefined);
        setRole(undefined);
        setPosition(undefined);
    }

    function onsetmgnProp(key: any, prop: any, role: any, position: any){
        let newData: any = dtmgnProp;

        newData.push(
            {
                "key": key,
                "property": prop,
                "role": role,
                "position": position,
            }
        )

        setmgnProp((pre: any)=> newData)

        clearState();
        toggleDisabled();
    }

    function ondelmgnPropOLD(key: any){
        let findDT: any = dtmgnPropOLD?.findIndex((f:any) => f.key === Number(key));
        if(findDT !== -1){
            let newData: any = dtmgnPropOLD;
            newData[findDT].status = false;

            setmgnPropOLD((pre: any)=> newData)
            settickerFlag((pre)=> !pre);
        }
    }

    function ondelmgnProp(key: any){
        let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
        if(findDT !== -1){
            let newData: any = dtmgnProp;
            
            newData?.splice(findDT, 1);
            setmgnProp((pre: any)=> newData);

            // clearArr();
            // toggleDisabledX();
        }else{
            clearState();
            toggleDisabledX();
        }
    }

    function onselProp(key: any, e: any, mode: any){
        if(mode == "old"){
            let findDTOLD: any = dtmgnPropOLD?.findIndex((f:any) => f.key === Number(key));
            if(findDTOLD !== -1){
                let newDataOLD: any = dtmgnPropOLD;

                newDataOLD[findDTOLD].property = e;
                setmgnPropOLD((pre: any)=> newDataOLD);
            }
        }else if(mode == "new"){
            let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
            if(findDT !== -1){
                let newData: any = dtmgnProp;

                newData[findDT].property = e;
                setmgnProp((pre: any)=> newData);
            }else{
                setProp(e);
            }
        }
    }

    function onselRole(key: any, e: any, mode: any){
        if(mode == "old"){
            let findDTOLD: any = dtmgnPropOLD?.findIndex((f:any) => f.key === Number(key));
            if(findDTOLD !== -1){
                let newDataOLD: any = dtmgnPropOLD;

                newDataOLD[findDTOLD].role = e;
                setmgnPropOLD((pre: any)=> newDataOLD);
            }
        }else if(mode == "new"){
            let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
            if(findDT !== -1){
                let newData: any = dtmgnProp;

                newData[findDT].role = e;
                setmgnProp((pre: any)=> newData);
            }else{
                setRole(e);
            }
        }
    }

    function onselPosition(key: any, e: any , mode: any){
        if(mode == "old"){
            let findDTOLD: any = dtmgnPropOLD?.findIndex((f:any) => f.key === Number(key));
            if(findDTOLD !== -1){
                let newDataOLD: any = dtmgnPropOLD;

                newDataOLD[findDTOLD].position = e;
                setmgnPropOLD((pre: any)=> newDataOLD);
            }
        }else if(mode == "new"){
            let findDT: any = dtmgnProp?.findIndex((f:any) => f.key === Number(key));
            if(findDT !== -1){
                let newData: any = dtmgnProp;

                newData[findDT].position = e;
                setmgnProp((pre: any)=> newData);
            }else{
                setPosition(e);
            }
        }
    }
    
    function updatemgnProp(){
        if (selUtype?.label == "Admin") {
            if(dtmgnAdmin){
                updateUser({
                    resource: "user-type-mappings",
                    id: dtmgnAdmin?.id,
                    values: {
                        company: vCompany,
                        department: vDepartment,
                        user_sub_type: vPosition,
                        role_management: vRole,
                        // status : true
                    },
                    successNotification: false,
                })
            }else{
                createUser({
                    resource: "user-type-mappings",
                    values: {
                        company         : vCompany,
                        department      : vDepartment,
                        user_profile    : selID,
                        user_type       : selUtype?.id,
                        user_sub_type   : vPosition,
                        role_management : vRole,
                        status          : true
                    },
                    successNotification: false,
                });
            }

            if(dtmgnPropOLD?.length > 0){ //ONCHANGE STAFF ==> ADMIN
                for (let i = 0; i < dtmgnPropOLD?.length; i++) {
                    updateUser({
                        resource: "user-type-mappings",
                        id: dtmgnPropOLD[i]?.id,
                        values: {
                            status: false,
                        },
                        successNotification: false,
                    });
                }
            }
        }else if (selUtype?.label == "Staff") {
            if(dtmgnPropOLD?.length > 0){ //OLDMGNPROP
                for (let i = 0; i < dtmgnPropOLD?.length; i++) {
                    if(dtmgnPropOLD[i]?.status == true){ //UPDATE
                        updateUser({
                            resource: "user-type-mappings",
                            id: dtmgnPropOLD[i]?.id,
                            values: {
                                company: vCompany,
                                department: vDepartment,
                                user_sub_type: dtmgnPropOLD[i]?.position,
                                role_management: dtmgnPropOLD[i]?.role,
                                property: dtmgnPropOLD[i]?.property
                            },
                            successNotification: false,
                        });
                    }else if(dtmgnPropOLD[i]?.status == false){ //DEL
                        updateUser({
                            resource: "user-type-mappings",
                            id: dtmgnPropOLD[i]?.id,
                            values: {
                                status: false,
                            },
                            successNotification: false,
                        });
                    }
                    
                }
            }

            if(dtmgnProp?.length > 0){ //NEWMGNPROP
                for (let i = 0; i < dtmgnProp?.length; i++) {
                    createUser({
                        resource: "user-type-mappings",
                        values: {
                            company         : vCompany,
                            department      : vDepartment,
                            user_profile    : selID,
                            user_type       : selUtype?.id,
                            user_sub_type: dtmgnProp[i]?.position,
                            role_management: dtmgnProp[i]?.role,
                            property: dtmgnProp[i]?.property,
                            status          : true
                        },
                        successNotification: false,
                    });
                }

            }

            if(dtmgnAdmin){ //ONCHANGE ADMIN ==> STAFF
                updateUser({
                    resource: "user-type-mappings",
                    id: dtmgnAdmin?.id,
                    values: {
                        status : false
                    },
                    successNotification: false,
                })
            }
        }
    }

    return (
        <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
            <Space style={{ marginBottom: "5%" }}>
                <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("bc_user-profiles.path")}</Breadcrumb.Item>
                    <Breadcrumb.Item ><span style={{ color: "#cdba8c" }}>{t("user-profiles.title-edit")}</span></Breadcrumb.Item>
                </Breadcrumb>
            </Space>

            <Form {...formProps} layout="vertical"
                onFinish={(values: any) => {

                    if(vdisabledSAVE == false){
                        // console.log(">>> values",values)
                        // updatemgnProp();
                        updateUserUpdatedBy();

                        values.avatar = vpixChange !== null && vpixChange !== undefined ? vpixChange.id : null
                        values.user_prefix = values.user_prefix;
                        values.first_name = values.first_name;
                        values.last_name = values.last_name;
                        values.email = values.email;
                        values.user_type = selUtype?.id;

                        if (vTel !== null && vTel !== undefined) {
                            values.short_tel = vTel?.short;
                            values.tel = vTel?.phone;
                        }

                        values.updateBy = user_data.user_profile.id
                        values.updatedBy = user_data.user_profile.id
                        
                        // mutateUpdateUser(selID);
                        updatemgnProp();

                        if(swpwd == true){
                            updateuserData("k")
                        }


                        return (
                            formProps.onFinish &&
                            formProps.onFinish(mediaUploadMapper(values))
                        );
                    }else if(vdisabledSAVE == true){
                        if(vSTYPEICON == "inherit"){
                            warning();
                            setTOOLTIP(t("msg-vld-mail.tooltip-warning"));
                            setICONSEARCH(<Icons.WarningOutlined />);
                            setSTYPEICON("#d46b08");
                        }else{
                            error();
                            setTOOLTIP(t("msg-vld-mail.tooltip-error"));
                            setICONSEARCH(<Icons.StopOutlined />);
                            setSTYPEICON("#cf1322");
                        }
                        setSTYPEINPUT("1px solid #f5222d");
                    }
                }}
            >           
                <Row gutter={8}>
                    <Col span={4} className="gutter-row">
                        <Form.Item
                            name="avatar"
                            className="form_projectPix"
                            style={{ marginBottom: "5%" }}
                        >
                            <Tooltip placement="left" color="lime" title={"AVATAR PROFILE"}>
                                <Dragger className="dragger_upload_custom_i_w"
                                    {...vpixlogo}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    multiple
                                    maxCount={1}
                                    onChange={fileChange}
                                    beforeUpload={beforeUpload}
                                    showUploadList={false}
                                    openFileDialogOnClick={false}
                                    ref={refUpload}
                                >
                                    <div className="dragger_upload_custom_i_m">
                                        <div className="projectPix_hover">
                                            <div>
                                                {swpixList == false ?
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={7} className="gutter-row">
                                                                <Icons.DeleteOutlined className="icons_upload" onClick={() => { removepixList() }} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                    :
                                                    <>
                                                        <Row gutter={16}>
                                                            <Col span={5} className="gutter-row" />
                                                            <Col span={14} className="gutter-row">
                                                                <Icons.CameraOutlined className="icons_upload" onClick={() => refUpload?.current?.upload.uploader.onClick()} />
                                                            </Col>
                                                            <Col span={5} className="gutter-row" />
                                                        </Row>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                        <Avatar className="projectPix" src={pixURL} style={{ width: "100%", height: "100%", borderRadius: "10px" }} />
                                        <span style={{fontSize:"11px", alignItems:"stretch", color: "#DF6677"}}>*Picture 512*512 PNG, JPG Max size 5MB</span>
                                    </div>
                                </Dragger>
                            </Tooltip>
                        </Form.Item>
                    </Col>
                    <Col span={20} className="gutter-row">
                        <Row gutter={8}>
                            <Col span={3} className="gutter-row">
                                <Form.Item name="user_type" label={t("user-profiles.user-type")}
                                    rules={[
                                        {
                                            required: true, message: 'Please select user type.'
                                        },
                                    ]}
                                >
                                    <Select {...selUserType}
                                        placeholder={t("select.sel")}
                                        onChange={(e:any ,value:any) => {
                                            if(value?.label !== selUtype?.label){
                                                clearState();
                                            }
                                            setselUtype({id: value?.value, label: value?.label})
                                        }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={3} className="gutter-row">
                                <Form.Item name="user_prefix" label={t("user-profiles.prefix")}>
                                    <Select placeholder={t("select.sel")} {...selPrefix} />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.name")} name="first_name" rules={[{ required: true, message: 'Please input first name' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label={t("user-profiles.lastname")} name="last_name" rules={[{ required: true, message: 'Please input last name' }]}>
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item name="tel" label={t("user-profiles.tel")}>
                                    <ConfigProvider locale={en}>
                                        <CountryPhoneInput defaultValue={{ short: 'TH' }} style={{ height: "32px", borderRadius: "5px", padding: "0" }}
                                            value={vTel}
                                            onChange={(v) => { setTel(v) }}
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            minLength={9}
                                            maxLength={12}
                                        />
                                    </ConfigProvider>
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input email' }, { type: 'email', message: 'email must be formatted correctly' }]} className="input_disable_onshow">
                                    <Input 
                                        value={vUserEmail}
                                        disabled={true}
                                        onChange={e => {
                                            onchangeEmail(e.target.value)
                                            if(vSTYPEINPUT == "1px solid #f5222d"){
                                                setSTYPEINPUT("1px solid #d9d9d9")
                                            }
                                        }}
                                        style={{border : vSTYPEINPUT}}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={18} className="gutter-row" />

                            <Col span={2} className="gutter-row">
                                <Form.Item label=" ">
                                    <Switch
                                        checkedChildren={<Tooltip title="Change Password">Change</Tooltip>}
                                        unCheckedChildren={<Tooltip title="Disable">NotChange</Tooltip>}
                                        defaultChecked={false}
                                        onChange={(e) => setswpwd(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={6} className="gutter-row">
                                {swpwd == true ?
                                    <Form.Item
                                        name="password1"
                                        label={t("staff-profiles.pwd1")}
                                        rules={[
                                            {
                                                required: true,
                                                message: '',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {

                                                    if (value.length >= 8 && value.length <= 16) {
                                                        return Promise.resolve();
                                                    }

                                                    if (value.length <= 8 && value.length >= 16) {
                                                        return Promise.reject(new Error('Password must be 8-16 characters only '));
                                                    }

                                                    if (value.length < 0) {
                                                        return Promise.reject(new Error('please input your password'));
                                                    }

                                                    if (value == "") {
                                                        return Promise.reject(new Error('please input your password'));
                                                    }

                                                },
                                            }),
                                        ]}
                                        hasFeedback

                                    >
                                        <Input.Password style={{ borderRadius: 5 }}
                                            type="password"
                                            placeholder="Enter your password"
                                            iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                            minLength={8}
                                            maxLength={16}
                                            disabled={!swpwd}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item label={t("staff-profiles.pwd1")}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                }
                            </Col>

                            <Col span={6} className="gutter-row">
                                {swpwd == true ?
                                    <Form.Item
                                        name="password2"
                                        label={t("staff-profiles.pwd2")}
                                        dependencies={['password1']}
                                        hasFeedback
                                        rules={[{ required: true, message: 'Please confirm your password!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password1') === value) {
                                                    return Promise.resolve();
                                                }
                                                // return Promise.reject(new Error('the two passwords that you entered do not match!'));
                                                return Promise.reject(new Error('Password is not match'));
                                            },
                                        }),
                                        ]}
                                    >
                                        <Input.Password style={{ borderRadius: 5 }}
                                            type="password"
                                            placeholder="Enter your password"
                                            iconRender={visible => (visible ? <Icons.EyeTwoTone /> : <Icons.EyeInvisibleOutlined />)}
                                            minLength={8}
                                            maxLength={16}
                                            disabled={!swpwd}
                                            onChange={(e:any) => { SetPwd(e.target.value)}}
                                        />
                                    </Form.Item>
                                    :
                                    <Form.Item label={t("staff-profiles.pwd2")}>
                                        <Input disabled={true} />
                                    </Form.Item>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider style={{ margin: "10px" }} />

                {selUtype && selUtype?.label == "Admin" ?
                    <Row gutter={8}>
                        <Col span={4} className="gutter-row" />
                        <Col span={20} className="gutter-row">
                            <Row gutter={8} style={{ marginTop: "1rem" }}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={8}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                <Select
                                                    {...selComp}
                                                    onChange={(e) => {
                                                        setCompany(e);
                                                        form?.resetFields(["SelDept"]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={8}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                <Select
                                                    {...selDepart}
                                                    onChange={(e) => {
                                                        setDepartment(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={8}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Role</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name={"role"} rules={[{ required: true, message: 'Please select role' }]}>
                                                <Select {...selRole} onChange={(e) => setRole(e)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={8}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#ff4d4f" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Position</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item
                                                // name="position"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please select position'
                                                    },
                                                ]}
                                            >
                                                <Radio.Group name="radiogroup" defaultValue={vPosition} value={vPosition} onChange={(e) => setPosition(e?.target?.value)}>
                                                    {
                                                        positiondt?.map((el: any, index: any) => {
                                                            return <>
                                                                <Radio key={el.id} value={el.id} style={{ fontSize: "0.7vw", color: "#000" }}>{el.sub_type_name}</Radio>
                                                            </>
                                                        })
                                                    }
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    : selUtype?.label == "Staff" &&
                    <Row gutter={8}>
                        <Col span={4} className="gutter-row" />
                        <Col span={20} className="gutter-row">
                            <Row gutter={8} style={{ marginTop: "1rem" }}>
                                <Col className="gutter-row" span={12}>
                                    <Row gutter={8}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Company</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18} style={{ paddingRight: "20px" }}>
                                            <Form.Item name="SelComp" rules={[{ required: true, message: 'Please select company' }]}>
                                                <Select
                                                    {...selComp}
                                                    onChange={(e) => {
                                                        setCompany(e);
                                                        form?.resetFields(["SelDept"]);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col className="gutter-row" span={12}>
                                    <Row gutter={8}>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item>
                                                <span style={{ fontSize: "0.7vw", color: "#fc0303" }}>* </span>
                                                <span style={{ fontSize: "0.7vw", color: "#000" }}>Department</span>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" span={18}>
                                            <Form.Item name="SelDept" rules={[{ required: true, message: 'Please select department' }]}>
                                                <Select
                                                    {...selDepart}
                                                    onChange={(e) => {
                                                        setDepartment(e);
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row gutter={8}>
                                <Col className="gutter-row" span={24}>
                                    <Form.List name="fields">
                                        {(fields, { add, remove }) => {
                                            return (
                                            <div>
                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={4}>
                                                        <Form.Item>
                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>  Properties Management</span>
                                                        </Form.Item>
                                                    </Col>
                                                    {/* <Col className="gutter-row" span={3}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => { add(); toggleDisabled();}}
                                                                style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                                disabled={disabled}
                                                            >
                                                                <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                            </Button>
                                                        </Form.Item>
                                                    </Col> */}
                                                </Row>
                                                {/* .status = false */}
                                                {dtmgnPropOLD && dtmgnPropOLD?.length > 0 && dtmgnPropOLD?.filter((e:any)=> e.status == true)?.map((el:any,index:any) => {
                                                    return (
                                                        <div key={index}>
                                                            <Row gutter={16}>
                                                                <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "20%"}}><span>{index + 1} .</span></div> </Col>
                                                                <Col className="gutter-row" span={13}>
                                                                    <Row gutter={8}>
                                                                        <Col className="gutter-row" span={6}>
                                                                            <Form.Item>
                                                                                <span style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.property")}</span>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" span={18}>
                                                                            <Form.Item>
                                                                                <div style={{ display:"none"}}>{JSON.stringify(tickerFlag)}</div>
                                                                                {/* <Select 
                                                                                    // {...selProp} 
                                                                                    options={propdt}
                                                                                    filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                                    defaultValue={el.property ? el.property : null}
                                                                                    // onChange={(val)=> onChangeProp(val ,index)}
                                                                                    onChange={(e: any)=> onselProp(index, e, "old")}
                                                                                    // value={el.property.id}
                                                                                    // defaultOpen={el.property.id}
                                                                                /> */}
                                                                                <Select 
                                                                                    {...selProp}
                                                                                    options={propdt}
                                                                                    filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                                    defaultValue={el.property ? el.property : null}
                                                                                    onChange={(e: any)=> onselProp(index, e, "old")}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col className="gutter-row" span={10}>
                                                                    <Row gutter={16}>
                                                                        <Col className="gutter-row" span={4}>
                                                                            <Form.Item>
                                                                                <span  style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.role")}</span>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" span={20}>
                                                                            <Form.Item>
                                                                                {/* <Select 
                                                                                    options={roledt}
                                                                                    defaultValue={el.role ? el.role : null}
                                                                                    // onChange={(val)=> onchangeRole(val ,index)} 
                                                                                    onChange={(e: any)=> onselRole(index, e, "old")}
                                                                                    filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                                    // value={el.role_management.id}
                                                                                /> */}
                                                                                <Select 
                                                                                    {...selProp}
                                                                                    options={roledt}
                                                                                    defaultValue={el.role ? el.role : null}
                                                                                    onChange={(e: any)=> onselRole(index, e, "old")}
                                                                                    filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                                />
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col className="gutter-row" span={1}/>

                                                                <Col className="gutter-row" span={14}>
                                                                    <Row gutter={8}>
                                                                        <Col className="gutter-row" span={5}>
                                                                            <Form.Item>
                                                                                <span  style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.position")}</span>
                                                                            </Form.Item>
                                                                        </Col>
                                                                        <Col className="gutter-row" span={19}>
                                                                            <Form.Item>
                                                                                <Radio.Group 
                                                                                    name="radiogroup" 
                                                                                    defaultValue={el.position ? el.position : null}
                                                                                    // onChange={(e) => onChangePositionStaff(e,index)} 
                                                                                    onChange={(e) => onselPosition(index, e?.target?.value, "old")} 
                                                                                >
                                                                                    {
                                                                                        positiondt?.map((el:any,index:any) => {
                                                                                            return <Radio 
                                                                                                key={el.id} 
                                                                                                value={el.id} 
                                                                                                style={{fontSize: "0.7vw",color: "#000"}}
                                                                                            >
                                                                                                {el.sub_type_name}
                                                                                            </Radio>
                                                                                        })
                                                                                    }
                                                                                </Radio.Group>
                                                                            </Form.Item>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>

                                                                <Col span={4}>
                                                                    <Button
                                                                        size="small"
                                                                        className="dynamic-delete-button"
                                                                        // onClick={() => { removeItemstaff(el.id); clearArr();}}
                                                                        // onClick={() => { removeItemstaff(el.id); ondelmgnProp(index);}}
                                                                        onClick={() => {ondelmgnPropOLD(index);}}
                                                                        danger
                                                                    >
                                                                        <Icons.CloseOutlined />
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <div style={{marginBottom: "1vh", borderTop: fields.length - 1 == index ? "0px":"1px solid #dedede"}} />
                                                        </div>
                                                    )
                                                })}

                                                {fields.map((field, index) => (
                                                    <div key={Number(field.key + dtmgnPropOLD?.length)}>
                                                        <Row gutter={16}>
                                                            <Col className="gutter-row" span={1}> <div style={{float:'right',marginTop: "20%"}}><span>{index + dtmgnPropOLD?.filter((e:any)=> e.status == true)?.length + 1} .</span></div> </Col>
                                                            <Col className="gutter-row" span={13}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={6}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.property")}</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={18}>
                                                                        <Form.Item
                                                                            name={[index, 'property']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select property'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                options={propdt}
                                                                                filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                                // onChange={(val)=> onChangeProp_NEW(val ,index)}
                                                                                onChange={(e: any) => onselProp(Number(field.key + dtmgnPropOLD?.length), e, "new")}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={10}>
                                                                <Row gutter={16}>
                                                                    <Col className="gutter-row" span={4}>
                                                                        <Form.Item>
                                                                            <span style={{fontSize: "0.7vw",color: "#000"}}>{t("user-profiles.role")}</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={20}>
                                                                        <Form.Item
                                                                            name={[index, 'role']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select role'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                options={roledt}
                                                                                filterOption={(input:any, option:any) => (option?.label ?? '').includes(input)}
                                                                                // onChange={(val)=> onchangeRole_NEW(val ,index)}
                                                                                onChange={(e: any) => onselRole(Number(field.key + dtmgnPropOLD?.length), e, "new")}
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col className="gutter-row" span={1}/>

                                                            <Col className="gutter-row" span={14}>
                                                                <Row gutter={8}>
                                                                    <Col className="gutter-row" span={5}>
                                                                        <Form.Item>
                                                                            <span>{t("user-profiles.position")}</span>
                                                                        </Form.Item>
                                                                    </Col>
                                                                    <Col className="gutter-row" span={19}>
                                                                        <Form.Item
                                                                            name={[index, 'position']}
                                                                            rules={[
                                                                                {
                                                                                    required: true, message: 'Please select position'
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Radio.Group 
                                                                                name="radiogroup" 
                                                                                // onChange={(e) => onChangePositionStaff_NEW(e,index)}
                                                                                onChange={(e) => onselPosition(Number(field.key + dtmgnPropOLD?.length), e?.target?.value, "new")} 
                                                                            >
                                                                                {
                                                                                    positiondt?.map((el:any,index:any) => {
                                                                                        return <Radio key={el.id} value={el.id} style={{fontSize: "0.7vw",color: "#000"}}>{el.sub_type_name}</Radio>
                                                                                    })
                                                                                }
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                    </Col>
                                                                </Row>
                                                            </Col>

                                                            <Col span={4}>
                                                                {/* {
                                                                    vProp && vRole && vPosition &&
                                                                        <>{fields.length == index+1 &&
                                                                            <Button
                                                                                size="small"
                                                                                className="dynamic-delete-button"
                                                                                onClick={() => { onsetPropArrC(); onsetRoleArrC(); onsetPositionArrC(); clearArr(); toggleDisabled();}}
                                                                                danger
                                                                                style={{marginRight: "3%",background: "#fff",borderColor: "#A1D64F",color: "#A1D64F"}}
                                                                            >
                                                                                <Icons.CheckOutlined/>
                                                                            </Button>
                                                                        }
                                                                        </>
                                                                } */}
                                                                {
                                                                    vProp && vRole && vPosition &&
                                                                        <>{fields?.length == index+1 &&
                                                                            <Button
                                                                                size="small"
                                                                                // onClick={() => { onsetPropArr(); onsetRoleArr(); onsetPositionArr(); clearArr(); toggleDisabled();}}
                                                                                onClick={() => onsetmgnProp(Number(field.key + dtmgnPropOLD?.length), vProp, vRole, vPosition)}
                                                                                danger
                                                                                style={{marginRight: "3%",background: "#fff",borderColor: "#A1D64F",color: "#A1D64F"}}
                                                                            >
                                                                                <Icons.CheckOutlined/>
                                                                            </Button>
                                                                        }
                                                                        </>
                                                                }

                                                                {
                                                                    fields.length > 0 ? (
                                                                        <Button
                                                                            size="small"
                                                                            className="dynamic-delete-button"
                                                                            // onClick={() => { remove(field.name); clearArr(); removeItemstaff_NEW(index); toggleDisabledX();}}
                                                                            onClick={() => { remove(field.name); ondelmgnProp(Number(field.key + dtmgnPropOLD?.length));}}
                                                                            danger
                                                                        >
                                                                            <Icons.CloseOutlined />
                                                                        </Button>
                                                                    ) : null
                                                                }
                                                            </Col>
                                                        </Row>

                                                        <div style={{marginBottom: "1vh", borderTop: fields.length - 1 == index ? "0px":"1px solid #dedede"}} />
                                                    </div>
                                                ))}

                                                <Row gutter={16}>
                                                    <Col className="gutter-row" span={3}>
                                                        <Form.Item>
                                                            <Button
                                                                type="dashed"
                                                                onClick={() => { add(); toggleDisabled();}}
                                                                style={{ width: "100%",borderRadius: "10px",border: "1px solid #ddd",fontSize: "0.7vw"}}
                                                                disabled={disabled}
                                                            >
                                                                <Icons.PlusOutlined style={{color: "#C6C6C6"}} /> <span style={{color: "#C6C6C6"}}>Add</span>
                                                            </Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </div>
                                            );
                                        }}
                                    </Form.List>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                }
            </Form>
        </Edit>
    );
};
