/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField, useTable, Input, Button, EditButton, Select, useSelect, useModalForm, Radio, UploadFile, Checkbox, Divider, CreateButton, RadioChangeEvent, DatePicker } from "@pankod/refine-antd";
import { Spin, Tag, TimePicker } from 'antd';
import { CrudFilters, HttpError, IResourceComponentsProps, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { useState } from "react";

import moment from 'moment';

let swLocal : boolean = true;

export const JuristicWorkingDayList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Juristic Working Day");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const local_property_id = localStorage.getItem("PROPERTY_ID");

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    // const { TabPane } = Tabs;
    // const API_URL = useApiUrl();
    // const { Dragger } = Upload;
    const { TextArea } = Input;
    const format = 'HH:mm';

    const [DataCus, setDataCus] = useState<any>();
    // const [swBankType, setswBankType] = useState<any>();
    const [vswLocal, setswLocal] = useState<boolean>(true);
    const { mutate: UpdateLocale } = useUpdate<any>();
    // const [vFile, setFile] = useState<any>([]);
    // const [FileList, setFileList] = useState<any>([]);

    const [monday, setmonday] = useState<any>(false);
    const [tuesday, settuesday] = useState<any>(false);
    const [wednesday, setwednesday] = useState<any>(false);
    const [thursday, setthursday] = useState<any>(false);
    const [friday, setfriday] = useState<any>(false);
    const [saturday, setsaturday] = useState<any>(false);
    const [sunday, setsunday] = useState<any>(false);

    const [timemonday, settimemonday] = useState<any>();
    const [time_endmonday, settime_endmonday] = useState<any>();
    const [timetuesday, settimetuesday] = useState<any>();
    const [time_endtuesday, settime_endtuesday] = useState<any>();
    const [timewednesday, settimewednesday] = useState<any>();
    const [time_endwednesday, settime_endwednesday] = useState<any>();
    const [timethursday, settimethursday] = useState<any>();
    const [time_endthursday, settime_endthursday] = useState<any>();
    const [timefriday, settimefriday] = useState<any>();
    const [time_endfriday, settime_endfriday] = useState<any>();
    const [timesaturday, settimesaturday] = useState<any>();
    const [time_endsaturday, settime_endsaturday] = useState<any>();
    const [timesunday, settimesunday] = useState<any>();
    const [time_endsunday, settime_endsunday] = useState<any>();
    const [msg_en, set_msg_en] = useState<any>();

    const [time_monday, settime_monday] = useState<any>([]);
    const [time_tuesday, settime_tuesday] = useState<any>([]);
    const [time_wednesday, settime_wednesday] = useState<any>([]);
    const [time_thursday, settime_thursday] = useState<any>([]);
    const [time_friday, settime_friday] = useState<any>([]);
    const [time_saturday, settime_saturday] = useState<any>([]);
    const [time_sunday, settime_sunday] = useState<any>([]);


    // const [time_monday, settime_monday] = useState<any>([moment().format('HH:mm:ss'), moment().format('HH:mm:ss')]);
    // const [time_tuesday, settime_tuesday] = useState<any>([moment().format('HH:mm:ss'), moment().format('HH:mm:ss')]);
    // const [time_wednesday, settime_wednesday] = useState<any>([moment().format('HH:mm:ss'), moment().format('HH:mm:ss')]);
    // const [time_thursday, settime_thursday] = useState<any>([moment().format('HH:mm:ss'), moment().format('HH:mm:ss')]);
    // const [time_friday, settime_friday] = useState<any>([moment().format('HH:mm:ss'), moment().format('HH:mm:ss')]);
    // const [time_saturday, settime_saturday] = useState<any>([moment().format('HH:mm:ss'), moment().format('HH:mm:ss')]);
    // const [time_sunday, settime_sunday] = useState<any>([moment().format('HH:mm:ss'), moment().format('HH:mm:ss')]);

    // const [timee, settime] = useState<any>([]);

    console.log('time_sunday', time_sunday)

    
    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;

    const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
        resource: "juristic-working-days",
        metaData: {
            populate: [
                'createBy',
                'updateBy',
                'property',
                'property.property_name',
                'locale',
                'localizations',
                'localizations.property',
            ],
            locale:['th']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { property, expense_type, bank_type } = params;
            filters.push(
                {
                    field       : "property][id]",
                    //operator    : (property || prop_list || prop_id) ? "eq" : "nin",
                    operator : "eq",
                    value       : property ? property: prop_list ? prop_list: prop_id ? prop_id : null,
                },
            );
            return filters;
        },
        permanentSorter: [
            {
                field: "id",
                order: "desc",
            },
        ],
        permanentFilter:[
            // {
            //     field : "property][id]",
            //     operator : data_property !== null || data_property !== undefined ? "in" : "ne",
            //     value: data_property !== null || data_property !== undefined ? data_property : null
            // }
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ]
    });

    // console.log('data_property', data_property)

    // const datacus = useList<any>({ 
    //     resource: "documents" ,
    //     metaData: {
    //         populate: '*',
    //         locale:['th']
    //     },
    //     config:{
    //         sort: [{ order: "desc", field: "id" }],
    //         filters: [
    //             {
    //                 field       : "deleteStatus",
    //                 operator    : "in",
    //                 value       : ["N","Y"]
    //             },
    //         ],
    //         pagination:{current: 1, pageSize: 10000000}
    //     }
    // });
    // const {data:dataCUSTOM} = datacus

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
    } = useModalForm<any>({
        resource: "juristic-working-days",
        metaData: {
            populate: '*',
            locale:['th']
        },
        action: "create",
        redirect: false
    });
 
    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading ,
        show: editModalShow,
        close
    } = useModalForm<any>({
        resource: "juristic-working-days",
        metaData: {
            populate: [
                'property',
                'property.property_name',
                'locale',
                'localizations',
                'localizations.property',
            ],
            locale:['th']
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data;
            console.log('dataLoad', dataLoad)

            // editProps?.form?.setFieldsValue({
            //     property: dataLoad ? dataLoad?.property?.id : null,
            // })
            // console.log('dataLoad >>>>>>>>> ',dataLoad)
            // console.log('dataLoad.property.property_name', dataLoad.property.property_name)
            setDataCus(data?.data);
            setmonday(dataLoad?.monday);
            settuesday(dataLoad?.tuesday);
            setwednesday(dataLoad?.wednesday);
            setthursday(dataLoad?.thursday);
            setfriday(dataLoad?.friday);
            setsaturday(dataLoad?.saturday);
            setsunday(dataLoad?.sunday);

            // time_monday.push

            // const b = [dataLoad?.mo_start_time , dataLoad?.mo_end_time]

            // console.log(b)
            settime_monday([dataLoad?.mo_start_time , dataLoad?.mo_end_time])
            settime_tuesday([dataLoad?.tu_start_time , dataLoad?.tu_end_time])
            settime_wednesday([dataLoad?.we_start_time , dataLoad?.mo_end_time])
            settime_thursday([dataLoad?.th_start_time , dataLoad?.th_end_time])
            settime_friday([dataLoad?.fr_start_time , dataLoad?.fr_end_time])
            settime_saturday([dataLoad?.sa_start_time , dataLoad?.sa_end_time])
            settime_sunday([dataLoad?.su_start_time , dataLoad?.su_end_time])


            // settime_monday([dataLoad?.mo_start_time ? dataLoad?.mo_start_time : moment().format('HH:mm:ss') , dataLoad?.mo_end_time ? dataLoad?.mo_end_time : moment().format('HH:mm:ss') ])
            // settime_tuesday([dataLoad?.tu_start_time ? dataLoad?.tu_start_time : moment().format('HH:mm:ss') , dataLoad?.tu_end_time ? dataLoad?.tu_end_time : moment().format('HH:mm:ss')])
            // settime_wednesday([dataLoad?.we_start_time ? dataLoad?.we_start_time : moment().format('HH:mm:ss') , dataLoad?.mo_end_time ? dataLoad?.mo_end_time : moment().format('HH:mm:ss') ])
            // settime_thursday([dataLoad?.th_start_time ? dataLoad?.th_start_time : moment().format('HH:mm:ss') , dataLoad?.th_end_time ? dataLoad?.th_end_time : moment().format('HH:mm:ss')])
            // settime_friday([dataLoad?.fr_start_time ? dataLoad?.fr_start_time : moment().format('HH:mm:ss') , dataLoad?.fr_end_time ? dataLoad?.fr_end_time : moment().format('HH:mm:ss') ])
            // settime_saturday([dataLoad?.sa_start_time ? dataLoad?.sa_start_time : moment().format('HH:mm:ss') , dataLoad?.sa_end_time ? dataLoad?.sa_end_time : moment().format('HH:mm:ss')])
            // settime_sunday([dataLoad?.su_start_time ? dataLoad?.su_start_time : moment().format('HH:mm:ss') , dataLoad?.su_end_time ? dataLoad?.su_end_time : moment().format('HH:mm:ss')])
            
            // settime_monday([{
            //     0:dataLoad?.mo_start_time ? dataLoad?.mo_start_time : null,
            //     1:dataLoad?.mo_end_time ? dataLoad?.mo_end_time : null
            // }])

            // settime_monday([
            //     dataLoad?.mo_start_time ? dataLoad?.mo_start_time : null,
            // ],
            // [
            //     dataLoad?.mo_end_time ? dataLoad?.mo_end_time : null
            // ])



            // settimemonday(dataLoad?.mo_start_time ? dataLoad?.mo_start_time : null)
            // settime_endmonday(dataLoad?.mo_end_time ? dataLoad?.mo_end_time : null)
            // settimetuesday(dataLoad?.tu_start_time ? dataLoad?.tu_start_time : null)
            // settime_endtuesday(dataLoad?.tu_end_time ? dataLoad?.tu_end_time : null)
            // settimewednesday(dataLoad?.we_start_time ? dataLoad?.we_start_time : null)
            // settime_endwednesday(dataLoad?.we_end_time ? dataLoad?.we_end_time : null)
            // settimethursday(dataLoad?.th_start_time ? dataLoad?.th_start_time : null)
            // settime_endthursday(dataLoad?.th_end_time ? dataLoad?.th_end_time : null)
            // settimefriday(dataLoad?.fr_start_time ? dataLoad?.fr_start_time : null)
            // settime_endfriday(dataLoad?.fr_end_time ? dataLoad?.fr_end_time : null)

            // settimesaturday(dataLoad?.sa_start_time ? dataLoad?.sa_start_time : null)
            // settime_endsaturday(dataLoad?.sa_end_time ? dataLoad?.sa_end_time : null)
            // settimesunday(dataLoad?.su_start_time ? dataLoad?.su_start_time : null)
            // settime_endsunday(dataLoad?.su_end_time ? dataLoad?.su_end_time : null)

            // editProps?.form?.setFieldsValue({
            //     // property: dataLoad ? dataLoad?.property?.id : null,
            //     mo_start_time: dataLoad?.mo_start_time ? moment(dataLoad?.mo_start_time,'HH:mm') : null,
            //     mo_end_time: dataLoad?.mo_end_time ? moment(dataLoad?.mo_end_time,'HH:mm') : null,
            //     tu_start_time: dataLoad?.tu_start_time ? moment(dataLoad?.tu_start_time,'HH:mm') : null,
            //     tu_end_time: dataLoad?.tu_end_time ? moment(dataLoad?.tu_end_time,'HH:mm') : null,
            //     we_start_time: dataLoad?.we_start_time ? moment(dataLoad?.we_start_time,'HH:mm') : null,
            //     we_end_time: dataLoad?.we_end_time ? moment(dataLoad?.we_end_time,'HH:mm') : null,
            //     th_start_time: dataLoad?.th_start_time ? moment(dataLoad?.th_start_time,'HH:mm') : null,
            //     th_end_time: dataLoad?.th_end_time ? moment(dataLoad?.th_end_time,'HH:mm') : null,
            //     fr_start_time: dataLoad?.fr_start_time ? moment(dataLoad?.fr_start_time,'HH:mm') : null,
            //     fr_end_time: dataLoad?.fr_end_time ? moment(dataLoad?.fr_end_time,'HH:mm') : null,
            //     sa_start_time: dataLoad?.sa_start_time ? moment(dataLoad?.sa_start_time,'HH:mm') : null,
            //     sa_end_time: dataLoad?.sa_end_time ? moment(dataLoad?.sa_end_time,'HH:mm') : null,
            //     su_start_time: dataLoad?.su_start_time ? moment(dataLoad?.su_start_time,'HH:mm') : null,
            //     su_end_time: dataLoad?.su_end_time ? moment(dataLoad?.su_end_time,'HH:mm') : null,
            //     // property: dataLoad ? dataLoad.property.property_name : null,
            //     // property: dataLoad ? dataLoad.property : null,
            //     // property: data?.data ? data?.data.property : null,
            // })

            // loadLocal();
            
        }},
    });


    const juristic_working_day_data = useList<any>({ 
        resource: "juristic-working-days", 
        metaData: {
            populate: [
                "property"
            ],
            locale:['th']
        },
        config:{
            sort: [{ order: "asc", field: "id" }],
        }
    });
    // console.log('juristic_working_day_data', juristic_working_day_data.data?.data)
    const property_id = juristic_working_day_data.data?.data.map((item) => item.property.id)
    // console.log('property_id', property_id)


    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        optionValue : "id",
        metaData: {
            populate: 'property_name',
        },
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
            // {
            //     field: "id]",
            //     operator: "nin",
            //     value: property_id ? property_id : null
            // }
        ]
    });

    // console.log('selProperty', selProperty)

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    const onChangeLocal = (e: RadioChangeEvent) => {
        swLocal = e.target.value
        setswLocal((pre:any) => e.target.value)
        loadLocal(e.target.value);
    };
    

    function loadLocal(sw:any){
        if(sw == true){
            editProps?.form?.setFieldsValue({
                property: DataCus ? DataCus.property : null,
            })
        }else if(sw == false){
            if(DataCus?.localizations?.length > 0){
                set_msg_en(DataCus.localizations[0]?.msg)
                editProps?.form?.setFieldsValue({
                    property: DataCus.localizations[0]?.property ? DataCus.localizations[0]?.property : null,
                    msg_en: DataCus.localizations[0]?.msg ? DataCus.localizations[0]?.msg : "-",
                })
            }
        }

        editProps?.form?.resetFields(["pic"]);
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    function updateLocaleEn(value: any){
        console.log('value updateLocaleEn', value)

        const data:any = {
            property : value?.property.id,
            msg : value?.msg_en,
            updateBy : user_data.user_profile.id,

            monday : value.monday,
            tuesday : value.tuesday,
            wednesday : value.wednesday,
            thursday : value.thursday,
            friday : value.friday,
            saturday : value.saturday,
            sunday : value.sunday,

            // if(monday == true){
            //     values.mo_start_time = moment(time_monday[0],'HH:mm:ss').format('HH:mm:ss');
            //     values.mo_end_time = moment(time_monday[1],'HH:mm:ss').format('HH:mm:ss');
            // }else{
            //     values.mo_start_time = null;
            //     values.mo_end_time = null;
            // }

            // if(tuesday == true){
            //     values.tu_start_time = moment(time_tuesday[0],'HH:mm:ss').format('HH:mm:ss');
            //     values.tu_end_time = moment(time_tuesday[1],'HH:mm:ss').format('HH:mm:ss');
            // }else{
            //     values.tu_start_time = null;
            //     values.tu_end_time = null;
            // }

            // if(wednesday == true){
            //     values.we_start_time = moment(time_wednesday[0],'HH:mm:ss').format('HH:mm:ss');
            //     values.we_end_time = moment(time_wednesday[1],'HH:mm:ss').format('HH:mm:ss');
            // }else{
            //     values.we_start_time = null;
            //     values.we_end_time = null;
            // }

            // if(thursday == true){
            //     values.th_start_time = moment(time_thursday[0],'HH:mm:ss').format('HH:mm:ss');
            //     values.th_end_time = moment(time_thursday[1],'HH:mm:ss').format('HH:mm:ss');
            // }else{
            //     values.th_start_time = null;
            //     values.th_end_time = null;
            // }

            // if(friday == true){
            //     values.fr_start_time = moment(time_friday[0],'HH:mm:ss').format('HH:mm:ss');
            //     values.fr_end_time = moment(time_friday[1],'HH:mm:ss').format('HH:mm:ss');
            // }else{
            //     values.fr_start_time = null;
            //     values.fr_end_time = null;
            // }

            // if(saturday == true){
            //     values.sa_start_time = moment(time_saturday[0],'HH:mm:ss').format('HH:mm:ss');
            //     values.sa_end_time = moment(time_saturday[1],'HH:mm:ss').format('HH:mm:ss');
            // }else{
            //     values.sa_start_time = null;
            //     values.sa_end_time = null;
            // }

            // if(sunday == true){
            //     values.su_start_time = moment(time_sunday[0],'HH:mm:ss').format('HH:mm:ss');
            //     values.su_end_time = moment(time_sunday[1],'HH:mm:ss').format('HH:mm:ss');
            // }else{
            //     values.su_start_time = null;
            //     values.su_end_time = null;
            // }

            mo_start_time : monday == true && time_monday[0] !== "" ? moment(time_monday[0],'HH:mm:ss').format('HH:mm:ss') : null,
            mo_end_time : monday == true && time_monday[1] !== "" ? moment(time_monday[1],'HH:mm:ss').format('HH:mm:ss') : null,

            tu_start_time : tuesday == true && time_tuesday[0] !== "" ? moment(time_tuesday[0],'HH:mm:ss').format('HH:mm:ss') : null,
            tu_end_time : tuesday == true && time_tuesday[1] !== "" ? moment(time_tuesday[1],'HH:mm:ss').format('HH:mm:ss') : null,

            we_start_time : wednesday == true && time_wednesday[0] !== "" ? moment(time_wednesday[0],'HH:mm:ss').format('HH:mm:ss') : null,
            we_end_time : wednesday == true && time_wednesday[1] !== "" ? moment(time_wednesday[1],'HH:mm:ss').format('HH:mm:ss') : null,

            th_start_time : thursday == true && time_thursday[0] !== "" ? moment(time_thursday[0],'HH:mm:ss').format('HH:mm:ss') : null,
            th_end_time : thursday == true && time_thursday[1] !== "" ? moment(time_thursday[1],'HH:mm:ss').format('HH:mm:ss') : null,

            fr_start_time : friday == true && time_friday[0] !== "" ? moment(time_friday[0],'HH:mm:ss').format('HH:mm:ss') : null,
            fr_end_time : friday == true && time_friday[1] !== "" ? moment(time_friday[1],'HH:mm:ss').format('HH:mm:ss') : null,

            sa_start_time : saturday == true && time_saturday[0] !== "" ? moment(time_saturday[0],'HH:mm:ss').format('HH:mm:ss') : null,
            sa_end_time : saturday == true && time_saturday[1] !== "" ? moment(time_saturday[1],'HH:mm:ss').format('HH:mm:ss') : null,

            su_start_time : sunday == true && time_sunday[0] !== "" ? moment(time_sunday[0],'HH:mm:ss').format('HH:mm:ss') : null,
            su_end_time : sunday == true && time_sunday[1] !== "" ? moment(time_sunday[1],'HH:mm:ss').format('HH:mm:ss') : null,

            locale : "en"
        }
        UpdateLocale({
            resource : 'juristic-working-days',
            id: DataCus.localizations[0].id,
            values: data,
            successNotification:false,
            },{
                onSuccess(data, variables, context) {
                    close();
                },
            }
        )
    }

    console.log('time_wednesday', time_wednesday )
    
    return <>
            <List 
                title={t("juristicworkingday.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        {/* <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item> */}
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("juristicworkingday.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
                <Row gutter={12}>
                    <Col span={22}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >

                            <Form.Item name="property">
                                <Select placeholder="Property" {...selProperty} allowClear style={{width: "220px"}}/>
                            </Form.Item>

                            <Form.Item >
                                <Button htmlType="submit" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button 
                                    onClick={() => {
                                        // searchFormProps.form?.submit()
                                        searchFormProps.form?.resetFields()
                                        searchFormProps.form?.submit()
                                    }} 
                                    htmlType="submit" 
                                    style={{borderRadius: "5px"}}
                                    icon={<Icons.UndoOutlined />}
                                >
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                        
                    </Col>
                    <Col span={2}>
                        <Form
                            {...searchFormProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item style={{marginRight: "0",}}>
                                {/* <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                                {/* <CreateButton id="create_btn" onClick={()=> createModalShow()} disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                                <CreateButton id="create_btn" onClick={()=> createModalShow()} />
                            
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                {data_property !== undefined &&
                    <Table {...tableProps} 
                        bordered
                        size="middle"
                        rowKey = "id"
                        pagination={{ 
                            defaultPageSize:10, 
                            showSizeChanger: true, 
                            locale:{ items_per_page: "/ Pages"},
                            pageSizeOptions: pageSizeState, 
                            onShowSizeChange , 
                            // total:tableProps?.dataSource?.length,
                            total:tableQueryResult.data?.total,

                            onChange(Current){ setPage(Current)},
                            hideOnSinglePage: false
                        }}
                        scroll={{x: 1300}}
                    >
                        {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1} /> */}

                        <Table.Column title="PROPERTY" width={400}
                            sorter={(a:any, b:any) => {
                                if (a?.property?.property_name < b?.property?.property_name) {return -1}
                                if (a?.property?.property_name > b?.property?.property_name) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                //console.log('record', record)
                                return <>
                                    <TextField value={record ? record?.property?.property_name :"ไม่ระบุ"}  />
                                </>
                            }}
                        />

                        <Table.Column 
                            // dataIndex="expense_type" 
                            title="WORKING DAYS"
                            width={300}
                            render={(value:any,record:any)=>{
                                // console.log('record', record)
                                return <>
                                    { record.monday ? <><Tag className="tag_post">MONDAY</Tag></> : <></> }
                                    { record.tuesday ? <><Tag className="tag_post">TUESDAY</Tag></> : <></> }
                                    { record.wednesday ? <><Tag className="tag_post">WEDNESDAY</Tag></> : <></> }
                                    { record.thursday ? <><Tag className="tag_post">THURSDAY</Tag></> : <></> }
                                    { record.friday ? <><Tag className="tag_post">FRIDAY</Tag></> : <></> }
                                    { record.saturday ? <><Tag className="tag_post">SATURDAY</Tag></> : <></> }
                                    { record.sunday ? <><Tag className="tag_post">SUNDAY</Tag></> : <></> }
                                </>
                            }}
                        />

                        <Table.Column 
                            // dataIndex="expense_type"
                            title="MESSAGES"
                            width={300}
                            sorter={(a:any, b:any) => {
                                if (a?.msg < b?.msg) {return -1}
                                if (a?.msg > b?.msg) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField value={record ? record?.msg : "-"} />
                                </>
                            }}
                        />

                        {/* <Table.Column dataIndex="bank_type" 
                            title="BANK TYPE" 
                            width={200}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField value={value ? value :"ไม่ระบุ"}  />
                                </>
                            }}
                        /> */}

                        {/* <Table.Column dataIndex="bank" title="BANK" width={300}
                            render={(value:any,record:any)=>{
                                return <>
                                    <Tag color={"#E8CE26"} style={{border: "1px solid #DAC32F"}}>{value ? value?.bank_code :"ไม่ระบุ"}</Tag>
                                    <TextField value={value ? value?.bank_name:"ไม่ระบุ"}  />
                                </>
                            }}
                        />

                        <Table.Column dataIndex="billing_id" title="BILLING ID " width={200}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField value={value ? value:"ไม่ระบุ"}  />
                                </>
                            }}
                        /> */}

                        <Table.Column title="CREATE BY" align="center" width={200}
                            sorter={(a:any, b:any) => {
                                if (a?.createBy?.first_nam < b?.createBy?.first_nam) {return -1}
                                if (a?.createBy?.first_nam > b?.createBy?.first_nam) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                                </>
                            }}
                        />

                        <Table.Column title="CREATE DATE" align="center" width={200}
                            sorter={(a:any, b:any) => {
                                if (a.createdAt < b.createdAt) {return -1}
                                if (a.createdAt > b.createdAt) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    {
                                        record?.createdAt ?
                                            <TextField value={moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                        :
                                            <TextField value={'-'}  />
                                    }
                                </>
                            }}
                        />

                        <Table.Column title="UPDATE BY" align="center" width={200}
                            sorter={(a:any, b:any) => {
                                if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                                if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                // console.log('record', record)
                                return <>
                                    <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                                </>
                            }}
                        />

                        <Table.Column title="LAST UPDATE" align="center" width={200}
                            sorter={(a:any, b:any) => {
                                if (a.updatedAt < b.updatedAt) {return -1}
                                if (a.updatedAt > b.updatedAt) {return 1}
                                return (0)
                            }}
                            render={(value:any,record:any)=>{
                                return <>
                                    {
                                        record?.updatedAt ?
                                            <TextField value={moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                        :
                                            <TextField value={'-'}  />
                                    }
                                </>
                            }}
                        />

                        <Table.Column<any>
                            width={80}
                            title    = "ACTIONS"
                            align    = "center"
                            dataIndex= "actions"
                            fixed = "right"
                            render={(_, record) => (
                                <Space>
                                    <EditButton
                                        hideText
                                        size="small"
                                        onClick={()=> (editModalShow(record.id), loadLocal(true))}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                }

            </List>

            <Modal 
                {...createModalProps}
                title={"Juristic Working Day Create"}
                okText = {"Create"}
                width={450}
                afterClose={()=> {
                    createProps?.form?.resetFields();
                    setmonday(false);
                    settuesday(false);
                    setwednesday(false);
                    setthursday(false);
                    setfriday(false);
                    setsaturday(false);
                    setsunday(false);

                    settime_monday([]);
                    settime_tuesday([]);
                    settime_wednesday([]);
                    settime_thursday([]);
                    settime_friday([]);
                    settime_saturday([]);
                    settime_sunday([]);
                }}
            >
                <Form 
                    {...createProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        values.createBy = user_data.user_profile.id

                        values.property = values?.property.id;
                        values.monday = monday;
                        values.tuesday = tuesday;
                        values.wednesday = wednesday;
                        values.thursday = thursday;
                        values.friday = friday;
                        values.saturday = saturday;
                        values.sunday = sunday;

                        if(monday == true){
                            values.mo_start_time = moment(time_monday[0],'HH:mm:ss').format('HH:mm:ss');
                            values.mo_end_time = moment(time_monday[1],'HH:mm:ss').format('HH:mm:ss');
                        }

                        if(tuesday == true){
                            values.tu_start_time = moment(time_tuesday[0],'HH:mm:ss').format('HH:mm:ss');
                            values.tu_end_time = moment(time_tuesday[1],'HH:mm:ss').format('HH:mm:ss');
                        }

                        if(wednesday == true){
                            values.we_start_time = moment(time_wednesday[0],'HH:mm:ss').format('HH:mm:ss');
                            values.we_end_time = moment(time_wednesday[1],'HH:mm:ss').format('HH:mm:ss');
                        }

                        if(thursday == true){
                            values.th_start_time = moment(time_thursday[0],'HH:mm:ss').format('HH:mm:ss');
                            values.th_end_time = moment(time_thursday[1],'HH:mm:ss').format('HH:mm:ss');
                        }

                        if(friday == true){
                            values.fr_start_time = moment(time_friday[0],'HH:mm:ss').format('HH:mm:ss');
                            values.fr_end_time = moment(time_friday[1],'HH:mm:ss').format('HH:mm:ss');
                        }

                        if(saturday == true){
                            values.sa_start_time = moment(time_saturday[0],'HH:mm:ss').format('HH:mm:ss');
                            values.sa_end_time = moment(time_saturday[1],'HH:mm:ss').format('HH:mm:ss');
                        }

                        if(sunday == true){
                            values.su_start_time = moment(time_sunday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.su_end_time = moment(time_sunday[1],'HH:mm:ss').format('HH:mm:ss');
                        }

                        values.msg = values?.msg;
                        values.locale = "th";

                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }}
                >
                    <Row gutter={16}>
                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                // name={["property", "property_name"]}
                                // name="property"
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty}/>
                            </Form.Item>

                            <Divider />

                            <Row gutter={16}>
                                {/* MONDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="monday">
                                        <Checkbox checked={monday} onChange={(e) => setmonday(e.target.checked)} disabled={swLocal ? false : true}>Monday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {monday == true &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="monday_time"
                                                    rules={[
                                                        { required: true, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_monday(dateString)}}
                                                        format={format} 
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* TUESDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="tuesday">
                                        <Checkbox checked={tuesday} onChange={(e) => settuesday(e.target.checked)} disabled={swLocal ? false : true}>Tuesday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {tuesday == true &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="tuesday_time"
                                                    rules={[
                                                        { required: true, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_tuesday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* WEDNESDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="wednesday">
                                        <Checkbox checked={wednesday} onChange={(e) => setwednesday(e.target.checked)} disabled={swLocal ? false : true}>Wednesday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {wednesday == true &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="wednesday_time"
                                                    rules={[
                                                        { required: true, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_wednesday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* THURSDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="thursday">
                                        <Checkbox checked={thursday} onChange={(e) => setthursday(e.target.checked)} disabled={swLocal ? false : true}>Thursday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {thursday == true &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="thursday_time"
                                                    rules={[
                                                        { required: true, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_thursday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* FRIDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="friday">
                                        <Checkbox checked={friday} onChange={(e) => setfriday(e.target.checked)} disabled={swLocal ? false : true}>Friday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {friday == true && 
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="friday_time"
                                                    rules={[
                                                        { required: true, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_friday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* SATURDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="saturday">
                                        <Checkbox checked={saturday} onChange={(e) => setsaturday(e.target.checked)} disabled={swLocal ? false : true}>Saturday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                {saturday == true &&
                                    <Row gutter={16}>
                                        <Col span={24} className="gutter-row">
                                            <Form.Item
                                                name="saturday_time"
                                                rules={[
                                                    { required: true, message: 'select date & time.' },
                                                ]}
                                            >
                                                <TimePicker.RangePicker
                                                    style={{width:'100%'}}
                                                    onChange={(date, dateString) => {settime_saturday(dateString)}}
                                                    format={format}
                                                    disabled={swLocal ? false : true}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                                </Col>
                                {/* SUNDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="sunday">
                                        <Checkbox checked={sunday} onChange={(e) => setsunday(e.target.checked)} disabled={swLocal ? false : true}>Sunday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                {sunday == true &&
                                    <Row gutter={16}>
                                        <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="sunday_time"
                                                    rules={[
                                                        { required: true, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_sunday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                    </Row>
                                }
                                </Col>
                            </Row>

                            <Form.Item label="Message" name="msg">
                                <TextArea style={{minHeight: "30px", maxHeight: "80px"}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>

            <Modal 
                {...editModalProps}
                title={"Juristic Working Day Edit"}
                okText = {"Save"}
                width={450}
                afterClose={()=> {
                    editProps?.form?.resetFields();
                    setmonday(false);
                    settuesday(false);
                    setwednesday(false);
                    setthursday(false);
                    setfriday(false);
                    setsaturday(false);
                    setsunday(false);

                    settime_monday([]);
                    settime_tuesday([]);
                    settime_wednesday([]);
                    settime_thursday([]);
                    settime_friday([]);
                    settime_saturday([]);
                    settime_sunday([]);
                    swLocal = true;
                    setswLocal(true);
                }}
            >
                <Form 
                    {...editProps} 
                    layout="vertical"
                    onFinish={(values:any) => {   
                        if(swLocal == true){
                            values.updateBy = user_data.user_profile.id

                            values.property = values?.property.id;
                            values.monday = monday;
                            values.tuesday = tuesday;
                            values.wednesday = wednesday;
                            values.thursday = thursday;
                            values.friday = friday;
                            values.saturday = saturday;
                            values.sunday = sunday;

                            if(monday == true){
                                values.mo_start_time = moment(time_monday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.mo_end_time = moment(time_monday[1],'HH:mm:ss').format('HH:mm:ss');
                            }else{
                                values.mo_start_time = null;
                                values.mo_end_time = null;
                            }
    
                            if(tuesday == true){
                                values.tu_start_time = moment(time_tuesday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.tu_end_time = moment(time_tuesday[1],'HH:mm:ss').format('HH:mm:ss');
                            }else{
                                values.tu_start_time = null;
                                values.tu_end_time = null;
                            }
    
                            if(wednesday == true){
                                values.we_start_time = moment(time_wednesday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.we_end_time = moment(time_wednesday[1],'HH:mm:ss').format('HH:mm:ss');
                            }else{
                                values.we_start_time = null;
                                values.we_end_time = null;
                            }
    
                            if(thursday == true){
                                values.th_start_time = moment(time_thursday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.th_end_time = moment(time_thursday[1],'HH:mm:ss').format('HH:mm:ss');
                            }else{
                                values.th_start_time = null;
                                values.th_end_time = null;
                            }
    
                            if(friday == true){
                                values.fr_start_time = moment(time_friday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.fr_end_time = moment(time_friday[1],'HH:mm:ss').format('HH:mm:ss');
                            }else{
                                values.fr_start_time = null;
                                values.fr_end_time = null;
                            }
    
                            if(saturday == true){
                                values.sa_start_time = moment(time_saturday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.sa_end_time = moment(time_saturday[1],'HH:mm:ss').format('HH:mm:ss');
                            }else{
                                values.sa_start_time = null;
                                values.sa_end_time = null;
                            }
    
                            if(sunday == true){
                                values.su_start_time = moment(time_sunday[0],'HH:mm:ss').format('HH:mm:ss');
                                values.su_end_time = moment(time_sunday[1],'HH:mm:ss').format('HH:mm:ss');
                            }else{
                                values.su_start_time = null;
                                values.su_end_time = null;
                            }
    
                            values.msg = values?.msg;
                            values.locale = "th";

                            values.msg_en = values?.msg_en;
                            updateLocaleEn(values);

                            return (
                                editProps.onFinish &&
                                editProps.onFinish(values)
                            );
                            
                        }else if(swLocal == false){
                            values.msg_en = values?.msg_en;
                            // values.monday = monday;
                            // values.tuesday = tuesday;
                            // values.wednesday = wednesday;
                            // values.thursday = thursday;
                            // values.friday = friday;
                            // values.saturday = saturday;
                            // values.sunday = sunday;
                            updateLocaleEn(values);
                        }
                    }}
                >
                    <Row gutter={16}>
                        <Radio.Group 
                            options={optionsRdioLocale} 
                            onChange={(e)=> {onChangeLocal(e)}}
                            defaultValue={true}
                            optionType="button"
                            style={{marginBottom: "2rem"}}
                        />

                        <Col span={24} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty} disabled={swLocal ? false : true}/>
                            </Form.Item>

                            <Divider />

                            <Row gutter={16}>
                                {/* MONDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="monday">
                                        <Checkbox checked={monday} onChange={(e) => setmonday(e.target.checked)} disabled={swLocal ? false : true}>Monday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {monday == true && time_monday?.length > 0 &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="monday_time"
                                                    rules={[
                                                        // { required: time_monday[0] == "" && time_monday[1] == "" ? true : false, message: 'select date & time.' },
                                                        { required: time_monday[0] == null || time_monday[1] == null ? true : false, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        // defaultValue={[moment(time_monday[0], 'HH:mm'), moment(time_monday[1], 'HH:mm')]}
                                                        defaultValue={
                                                            time_monday[0] ?
                                                                [moment(time_monday[0], 'HH:mm'), moment(time_monday[1], 'HH:mm')]
                                                            :
                                                                null
                                                        }
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_monday(dateString)}}
                                                        format={format} 
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* TUESDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="tuesday">
                                        <Checkbox checked={tuesday} onChange={(e) => settuesday(e.target.checked)} disabled={swLocal ? false : true}>Tuesday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {tuesday == true && time_tuesday?.length > 0 &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="tuesday_time"
                                                    rules={[
                                                        // { required: time_tuesday[0] == "" && time_tuesday[1] == "" ? true : false, message: 'select date & time.' },
                                                        { required: time_tuesday[0] == null || time_tuesday[1] == null ? true : false, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        defaultValue={
                                                            time_tuesday[0] ?
                                                                [moment(time_tuesday[0], 'HH:mm'), moment(time_tuesday[1], 'HH:mm')]
                                                            :
                                                                null
                                                        }
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_tuesday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* WEDNESDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="wednesday">
                                        <Checkbox checked={wednesday} onChange={(e) => setwednesday(e.target.checked)} disabled={swLocal ? false : true}>Wednesday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {wednesday == true && time_wednesday?.length > 0 &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="wednesday_time"
                                                    rules={[
                                                        // { required: time_wednesday[0] == "" && time_wednesday[1] == "" ? true : false, message: 'select date & time.' },
                                                        { required: time_wednesday[0] == null || time_wednesday[1] == null ? true : false, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        // defaultValue={[moment(time_wednesday[0], 'HH:mm'), moment(time_wednesday[1], 'HH:mm')]}
                                                        defaultValue={
                                                            time_wednesday[0] ?
                                                                [moment(time_wednesday[0], 'HH:mm'), moment(time_wednesday[1], 'HH:mm')]
                                                            :
                                                                null
                                                        }
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_wednesday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* THURSDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="thursday">
                                        <Checkbox checked={thursday} onChange={(e) => setthursday(e.target.checked)} disabled={swLocal ? false : true}>Thursday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {thursday == true && time_thursday?.length > 0 &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="thursday_time"
                                                    rules={[
                                                        // { required: time_thursday[0] == "" && time_thursday[1] == "" ? true : false, message: 'select date & time.' },
                                                        { required: time_thursday[0] == null || time_thursday[1] == null ? true : false, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        // defaultValue={[moment(time_thursday[0], 'HH:mm'), moment(time_thursday[1], 'HH:mm')]}
                                                        defaultValue={
                                                            time_thursday[0] ?
                                                                [moment(time_thursday[0], 'HH:mm'), moment(time_thursday[1], 'HH:mm')]
                                                            :
                                                                null
                                                        }
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_thursday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* FRIDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="friday">
                                        <Checkbox checked={friday} onChange={(e) => setfriday(e.target.checked)} disabled={swLocal ? false : true}>Friday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                    {friday == true && time_friday?.length > 0 &&
                                        <Row gutter={16}>
                                            <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="friday_time"
                                                    rules={[
                                                        // { required: time_friday[0] == "" && time_friday[1] == "" ? true : false, message: 'select date & time.' },
                                                        { required: time_friday[0] == null || time_friday[1] == null ? true : false, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        // defaultValue={[moment(time_friday[0], 'HH:mm'), moment(time_friday[1], 'HH:mm')]}
                                                        defaultValue={
                                                            time_friday[0] ?
                                                                [moment(time_friday[0], 'HH:mm'), moment(time_friday[1], 'HH:mm')]
                                                            :
                                                                null
                                                        }
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_friday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                                {/* SATURDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="saturday">
                                        <Checkbox checked={saturday} onChange={(e) => setsaturday(e.target.checked)} disabled={swLocal ? false : true}>Saturday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                {saturday == true && time_saturday?.length > 0 &&
                                    <Row gutter={16}>
                                        <Col span={24} className="gutter-row">
                                            <Form.Item
                                                name="saturday_time"
                                                rules={[
                                                    // { required: time_saturday[0] == "" && time_saturday[1] == "" ? true : false, message: 'select date & time.' },
                                                    { required: time_saturday[0] == null || time_saturday[1] == null ? true : false, message: 'select date & time.' },
                                                ]}
                                            >
                                                <TimePicker.RangePicker
                                                    // defaultValue={[moment(time_saturday[0], 'HH:mm'), moment(time_saturday[1], 'HH:mm')]}
                                                    defaultValue={
                                                        time_saturday[0] ?
                                                            [moment(time_saturday[0], 'HH:mm'), moment(time_saturday[1], 'HH:mm')]
                                                        :
                                                            null
                                                    }                                                    
                                                    style={{width:'100%'}}
                                                    onChange={(date, dateString) => {settime_saturday(dateString)}}
                                                    format={format}
                                                    disabled={swLocal ? false : true}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                }
                                </Col>
                                {/* SUNDAY */}
                                <Col span={10} className="gutter-row">
                                    <Form.Item name="sunday">
                                        <Checkbox checked={sunday} onChange={(e) => setsunday(e.target.checked)} disabled={swLocal ? false : true}>Sunday</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={14} className="gutter-row">
                                {sunday == true && time_sunday?.length > 0 &&
                                    <Row gutter={16}>
                                        <Col span={24} className="gutter-row">
                                                <Form.Item
                                                    name="sunday_time"
                                                    rules={[
                                                        // { required: time_sunday[0] == "" && time_sunday[1] == "" ? true : false, message: 'select date & time.' },
                                                        { required: time_sunday[0] == null || time_sunday[1] == null ? true : false, message: 'select date & time.' },
                                                    ]}
                                                >
                                                    <TimePicker.RangePicker
                                                        defaultValue={
                                                            time_sunday[0] ?
                                                                [moment(time_sunday[0], 'HH:mm'), moment(time_sunday[1], 'HH:mm')]
                                                            :
                                                                null
                                                        }
                                                        style={{width:'100%'}}
                                                        onChange={(date, dateString) => {settime_sunday(dateString)}}
                                                        format={format}
                                                        disabled={swLocal ? false : true}
                                                    />
                                                </Form.Item>
                                            </Col>
                                    </Row>
                                }
                                </Col>
                            </Row>

                            {swLocal ?
                                <Form.Item label="Message" name="msg">
                                    <TextArea style={{minHeight: "30px", maxHeight: "80px"}}/>
                                </Form.Item>
                                :
                                <Form.Item label="Message" name="msg_en">
                                    <TextArea style={{minHeight: "30px", maxHeight: "80px"}} value={msg_en} defaultValue={msg_en}/>
                                </Form.Item>
                            }
                            
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </>
}