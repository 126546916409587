import { IEventlog, IProjectBrand, IProperties, IUser, IUserFilter, IUserProfile, IUserSubType, IUserhunit } from "interfaces";
import { CrudFilters, HttpError, IResourceComponentsProps, useCreate, useCustom, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core"
import { Breadcrumb, Button, Col, CreateButton, EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, Select, ShowButton, Space, Spin, Table, Tag, TextField, useSelect, useTable } from "@pankod/refine-antd";
import { useEffect, useState } from "react";
import Moment from 'moment';
import { API_URL } from "../../constants";

let artemp:any = []
let dataTemp:any = []
let dataTBLOCAL: any = undefined;
export const UserList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { Option } = Select;
    const { confirm } = Modal;

    const { data: userdt } = useGetIdentity<any>();
    let dtInprop: any = userdt?.property ? userdt?.property?.id : null;
    let data_permission: any = userdt?.menu_access.find((item: any) => item?.module_master?.module_name == "Member Account");

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    const [isLoading, setLoading] = useState<boolean>(true);
    const { mutate } = useUpdate<IUserProfile>();
    const { mutate: CreateLogDelete } = useCreate<IEventlog>();
    const { mutate: updateUser } = useUpdate<IUser>();
    
    const [vDevelop, setDevelop] = useState<any>("All"); //selDevelop
    const [vProjectB, setProjectB] = useState<any>(); //selProjectB
    const [vProp, setProp] = useState<any>(); //selProjectN
    const [vuserType, setuserType] = useState<any>(); //selAccountT
    const [vStatus, setStatus] = useState<any>("N"); //selStatus
    const [swuserID, setswuserID] = useState<any>("TID"); //selID
    const [vuserID, setuserID] = useState<any>(); //inputID
    const [vuserTel, setuserTel] = useState<any>(); //inputTEL
    const [vuserMail, setuserMail] = useState<any>(); //inputMAIL
    const [vuserName, setuserName] = useState<any>(); //inputNAME
    const [dataStatus, setdataStatus] = useState<any>('Y');

    const [userinUnit, setuserinUnit] = useState<any>();

    const [dataSoce, setDataSoce] = useState<any>([]);

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);

    // const dataStatus = { deleteStatus: 'Y' }

    // console.log(">>> userinUnit",userinUnit)

    const { data: dataLoad , refetch } = useCustom<any>({
        url: `${API_URL}/api/user-profiles/find_all`,
        method: "get",
        config: {
            filters: [
                {
                    field: "user",
                    operator: "nin",
                    value: null
                },
                {
                    field: "first_name",
                    operator: "ne",
                    value: "-"
                },
                {
                    field: "user_type][type_name]",
                    operator: "eq",
                    value: ["Customer", "Guest"]
                },
            ],
            sort: [
                {
                    field: 'id',
                    order: 'desc',
                },
            ],
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                console.log('dataLoad', dataLoad);
                setDataSoce(dataLoad);
                setLoading(false);
                dataTBLOCAL = dataLoad;
                artemp = dataLoad
                dataTemp = dataLoad
                console.log("dataTemp",dataTemp)
                //console.log('datafil', dataLoad.filter((e:any) => e?.user?.username == "pimpunnee@gmail.com"));
            },
        },
    });

    const { tableProps, searchFormProps, tableQueryResult } = useTable<IUserProfile, HttpError, IUserFilter>({
        resource: 'user-profiles',
        metaData: {
            populate: '*'
        },
        initialPageSize: 100000,
        permanentFilter: [
            {
                field: "user",
                operator: "nin",
                value: null
            },
            {
                field: "first_name",
                operator: "ne",
                value: "-"
            },
            {
                field: "user_type][type_name]",
                operator: "eq",
                value: ["Customer", "Guest"]
            },
        ],
        permanentSorter: [
            {
                field: 'id',
                order: 'desc',
            },
        ],
        // onSearch: (params) => {
        //     const filters: CrudFilters = [];
        //     const { } = params;
        //     filters.push(
        //         {
        //             field: "id",
        //             operator: "eq",
        //             value: userinUnit ? userinUnit : null
        //         },
        //         {
        //             field: "user_type][id]",
        //             operator: "eq",
        //             value: vuserType ? vuserType : null
        //         },
        //         {
        //             field: 'identity_no',
        //             operator: "contains",
        //             value: swuserID == "TID" && vuserID ? vuserID : null
        //         },
        //         {
        //             field: 'identity_passport',
        //             operator: "contains",
        //             value: swuserID == "PID" && vuserID ? vuserID : null
        //         },
        //         {
        //             field: "tel",
        //             operator: "contains",
        //             value: vuserTel ? vuserTel : null
        //         },
        //         {
        //             field: "user][email]",
        //             operator: "contains",
        //             value: vuserMail ? vuserMail : null
        //         },
        //         {
        //             field: "first_name",
        //             operator: "contains",
        //             value: vuserName ? vuserName : null
        //         },
        //         {
        //             field: "deleteStatus",
        //             operator: "eq",
        //             value: vStatus ? vStatus : "N"
        //         },
        //     );

        //     return filters;
        // },
        queryOptions: {
            onSuccess: (data) => {
                let dataLoad: any = data?.data;
                // setDataSoce(dataLoad);
                // setLoading(false);
                // dataTBLOCAL = dataLoad;
                // console.log(">>> dataLoad_table2500",dataLoad)
            },
          },
    });

    const userhunitdt = useList<IUserhunit>({
        resource: "user-has-units",
        metaData: {
            populate: ['unit', 'unit.property', 'unit.property.project_brand', 'unit.project', 'unit.project.project_name', 'user_profile']
        },
        config: {
            filters: [
                {
                    field: "unit][property][property_under]",
                    operator: "eq",
                    value: vDevelop == "All" ? ['Major', 'Non Major'] : vDevelop
                },
                {
                    field: "unit][property][project_brand][id]",
                    operator: vProjectB ? "eq": "nin",
                    value: vProjectB ? vProjectB : null,
                },
                {
                    field: "unit][property][id]",
                    operator: vProp ? "eq" : "nin",
                    value: vProp ? vProp : null
                },
                {
                    field: "unit][property][id]",
                    operator: (prop_list || prop_id) ? "eq" : "nin",
                    value: prop_list ? prop_list: prop_id ? prop_id : null,
                },
            ],
            pagination: { current: 1, pageSize: 10000 }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                
                let newData: any = [];
                if(dataLoad?.length > 0){
                    if(vProp || vProjectB){
                        for (let i = 0; i < dataLoad?.length; i++) {
                            newData.push(dataLoad[i]?.user_profile?.id)
                        }
                        console.log("firstNewData",newData)
                        setuserinUnit(newData);
                    }else{
                        setuserinUnit(undefined);
                    }
                }else if(dataLoad?.length == 0){
                    newData = [];
                    setuserinUnit(undefined);
                }

                console.log(">>> newData", newData)
                // console.log(">>> dataLoad_list", dataLoad)
            },
        }
    });

    const { selectProps: dataprojectBrand } = useSelect<IProjectBrand>({
        resource: "project-brands",
        optionLabel: "brand_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "active_status",
                operator: "eq",
                value: true
            },
        ]
    });

    const { selectProps: dataProperty } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                // operator : dtInprop ? "eq" : "nin",
                // value : dtInprop ? dtInprop : null
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const { selectProps: datauserType } = useSelect<IUserSubType>({
        resource: "user-types",
        optionLabel: "type_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "type_name",
                operator: "in",
                value: ["Customer", "Guest"]
            }
        ]
    });
    
    function onsearchTB (data:any){
        let newDT: any = data
        if (!!newDT) {
            console.log("newDT",newDT)
            if(vuserName !== undefined){
                newDT = newDT?.filter(
                (item:any) => item?.first_name.toUpperCase().includes(String(vuserName.toUpperCase())) ||
                item?.last_name.toUpperCase().includes(String(vuserName.toUpperCase())) ||
                item?.first_name.toUpperCase().includes(String(vuserName.split(" ")[0].toUpperCase())) &&
                item?.last_name.toUpperCase().includes(String(vuserName.split(" ")[1].toUpperCase())) && item?.deleteStatus == vStatus)
                setDataSoce(newDT)
                artemp = newDT
            }
    
            if(vuserMail !== undefined){
                newDT = newDT?.filter(
                (item:any) => item?.user?.email?.toUpperCase().includes(String(vuserMail.toUpperCase())) && item?.deleteStatus == vStatus)
                setDataSoce(newDT)
                artemp = newDT
            }

            if(vuserTel !== undefined){
                console.log("vuserTel",vuserTel)
                newDT = newDT?.filter(
                (item:any) => item?.tel?.includes(String(vuserTel)))
                console.log("newDT",newDT)
                setDataSoce(newDT)
                artemp = newDT
            }

            if(swuserID == "TID" && !!vuserID){
                console.log("swuserID",vuserID)
                newDT = newDT?.filter(
                (item:any) => item?.identity_no?.includes(String(vuserID)))
                setDataSoce(newDT)
                artemp = newDT
            }

            if(swuserID == "PID" && !!vuserID){
                console.log("swuserID",vuserID)
                newDT = newDT?.filter(
                (item:any) => item?.identity_passport?.includes(String(vuserID)))
                setDataSoce(newDT)
                artemp = newDT
            }

            if(vStatus !== undefined){
                newDT = newDT?.filter(
                (item:any) => item?.deleteStatus == vStatus)
                setDataSoce(newDT)
                artemp = newDT
            }

            if(vuserType !== undefined){
                newDT = newDT?.filter(
                (item:any) => item?.user_type?.id == vuserType)
                setDataSoce(newDT)
                artemp = newDT
            }

            if(vProp !== undefined && userinUnit !== undefined){
                console.log("userinUnitvProp",userinUnit)
                let keepUnitFor = []
                for (let index = 0; index < userinUnit.length; index++) {
                    //console.log("userinUnit",userinUnit[index])
                    let dataUnit = newDT?.filter((item:any) => item?.id == userinUnit[index])
                    //console.log("dataUnit",dataUnit)
                    if (!!dataUnit) {
                        for (let ix = 0; ix < dataUnit.length; ix++) {
                            //console.log("dataUnit",dataUnit[ix])
                            keepUnitFor.push(dataUnit[ix])
                        }
                    }
                }

                setDataSoce(keepUnitFor)
                artemp = keepUnitFor
            }
            
            if(vProjectB !== undefined && userinUnit !== undefined){
                console.log("userinUnitvProjectB",userinUnit)
                let keepProjectB = []
                for (let index = 0; index < userinUnit.length; index++) {
                    //console.log("userinUnit",userinUnit[index])
                    let dataUnit = newDT?.filter((item:any) => item?.id == userinUnit[index])
                    //console.log("dataUnit",dataUnit)
                    if (!!dataUnit) {
                        for (let ix = 0; ix < dataUnit.length; ix++) {
                            //console.log("dataUnit",dataUnit[ix])
                            keepProjectB.push(dataUnit[ix])
                        }
                    }
                }
                console.log("keepProjectB",keepProjectB)
                setDataSoce(keepProjectB)
                artemp = keepProjectB
            }

            if(vDevelop !== undefined && userinUnit !== undefined){
                console.log("userinUnitvDevelop",userinUnit)
                let keepUnitForDe = []
                for (let index = 0; index < userinUnit.length; index++) {
                    //console.log("userinUnit",userinUnit[index])
                    let dataUnit = newDT?.filter((item:any) => item?.id == userinUnit[index])
                    //console.log("dataUnit",dataUnit)
                    if (!!dataUnit) {
                        for (let ix = 0; ix < dataUnit.length; ix++) {
                            //console.log("dataUnit",dataUnit[ix])
                            keepUnitForDe.push(dataUnit[ix])
                        }
                    }
                }
                console.log("keepUnitForDe",keepUnitForDe)
                setDataSoce(keepUnitForDe)
                artemp = keepUnitForDe
            }

        }else{
            console.log("NO")
        }
        

    }

    // useEffect(() => {
    //   console.log("vProjectB",vProjectB)
    //   console.log("vDevelop",vDevelop)
    //   console.log("vProp",vProp)
    //   console.log("userinUnit",userinUnit)
    // },[])

    useEffect(() => {
      console.log('dataSoce: ', dataSoce)
      if(dataSoce == undefined){
        setDataSoce(artemp)
        console.log("artemp",artemp)
      }
    }, [dataSoce])
    

    const onReset = (data:any) => {
        let newDT: any = data;
        setDevelop("All");
        setProjectB(undefined);
        setProp(undefined);
        setuserType(undefined);
        setStatus("N");
        setswuserID("TID")
        setuserID(undefined);
        setuserTel(undefined);
        setuserMail(undefined);
        setuserName(undefined);
        setuserinUnit(undefined);
        // console.log("newDT",newDT)
        artemp = dataTemp
        setDataSoce(newDT);
        //console.log("artemp",artemp)
    
        
        searchFormProps.form?.resetFields();
        //searchFormProps.form?.submit();
    }

    function showDelConfirm(id: any, data: any) {
        confirm({
            title: 'Inactive Confirm ?',
            icon: <Icons.WarningOutlined />,
            content: 'Click Confirm to deactivate this data.',
            okText: 'Confirm',
            onOk() {
                setdataStatus('Y')
                updateStatus(id, "Inactive");

                if (data?.user !== null) {
                    updateBlockUser(data?.user.id, true)
                }

                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id: any, data: any) {
        confirm({
            title: 'Activate Confirm ?',
            icon: <Icons.QuestionCircleOutlined />,
            content: 'Click Confirm to activate this data.',
            okText: 'Confirm',
            onOk() {
                setdataStatus('N')
                updateStatus(id, "Active");

                if (data?.user !== null) {
                    updateBlockUser(data?.user.id, false)
                }

                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function updateBlockUser(userID: any, v: boolean) {
        updateUser({
            resource: 'users',
            id: userID + "",
            values: {
                blocked: v,
            }, successNotification: false, errorNotification: false
        });
    }

    function updateStatus(id: any, event: any) {
        mutate({
            resource: "user-profiles",
            id: id + "",
            values: {deleteStatus : String(dataStatus)},
        });

        CreateLogDelete({
            resource: "even-logs",
            values: {
                user_profile: userdt?.user_profile?.id,
                log_time_stamp: Date.now(),
                log_event: event,
                menu_event: 'users',
                SysPkID: id + ""
            }, successNotification: false, errorNotification: false
        });
    }

    function onShowSizeChange(current: any, pageSize: any) {
        setPageSizeChange(pageSize)
    }
    
    return (
        <List
            title={t("member-account.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("project-member.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("member-account.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra: <></>
            }}
        >
            <Row>
                <Col span={18}>
                    <Form
                        {...searchFormProps}
                        layout="inline"
                        style={{ marginBottom: "2%", display: "block" }}
                    >
                        <Row gutter={8}>
                            <Col className="gutter-row" span={4}>
                                <Form.Item name="selDevelop" style={{ marginBottom: "unset", width: "100%" }}>
                                    <Select
                                        placeholder="Developer"
                                        size="middle"
                                        onClear={() => setDevelop(undefined)}
                                        onChange={(e) => setDevelop(e)}
                                        defaultValue={"All"}
                                    >
                                        <Option value="All">All</Option>
                                        <Option value="Major">Major</Option>
                                        <Option value="Non Major">Non Major</Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item name="selProjectB" style={{ marginBottom: "unset", width: "100%" }}>
                                    <Select
                                        {...dataprojectBrand}
                                        placeholder="Project Brand"
                                        size="middle"
                                        allowClear
                                        onClear={() => setProjectB(undefined)}
                                        onChange={(e) => setProjectB(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={8}>
                                <Form.Item name="selProjectN" style={{ marginBottom: "unset", width: "100%" }}>
                                    <Select
                                        {...dataProperty}
                                        placeholder="Property Name"
                                        size="middle"
                                        allowClear
                                        onClear={() => setProp(undefined)}
                                        onChange={(e) => setProp(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item name="SelAccountT" style={{ marginBottom: "unset", width: "100%" }}>
                                    <Select
                                        {...datauserType}
                                        placeholder="User Type"
                                        size="middle"
                                        allowClear
                                        onClear={() => setuserType(undefined)}
                                        onChange={(e) => setuserType(e)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item name="selStatus" style={{ marginBottom: "unset", width: "100%" }}>
                                    <Select
                                        placeholder="Status"
                                        size="middle"
                                        // allowClear
                                        onClear={() => setStatus("N")}
                                        onChange={(e) => setStatus(e)}
                                        defaultValue={"N"}
                                    >
                                        <Option value="N">Active</Option>
                                        <Option value="Y">In Active</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={8} style={{ marginTop: "1%" }}>
                            <Col className="gutter-row" span={6}>
                                <Row gutter={8}>
                                    <Col className="gutter-row" span={10}>
                                        <Form.Item name="selID" style={{ width: "100%" }}>
                                            <Select
                                                placeholder="ID"
                                                size="middle"
                                                // allowClear
                                                onClear={() => setswuserID(undefined)}
                                                onChange={(e) => setswuserID(e)}
                                                defaultValue={"TID"}
                                            >
                                                <Option value="TID">THAI ID</Option>
                                                <Option value="PID">PASSPORT</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" span={14}>
                                        <Form.Item name="inputID" style={{ width: "100%" }}>
                                            <Input placeholder="Thai ID/Passport"
                                                // value={inputID}
                                                allowClear
                                                onChange={e => {
                                                    if (e.target.value.length > 0) {
                                                        setuserID(e.target.value)
                                                    } else {
                                                        setuserID(undefined)
                                                    }
                                                }}
                                                style={{ borderRadius: 5 }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item name="inputTEL" style={{ width: "100%" }}>
                                    <Input placeholder="Phone number"
                                        // value={inputTEL}
                                        onChange={e => {
                                            if (e.target.value.length > 0) {
                                                setuserTel(e.target.value)
                                            } else {
                                                setuserTel(undefined)
                                            }
                                        }}
                                        allowClear
                                        style={{ borderRadius: 5 }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item name="inputEMAIL" style={{ width: "100%" }}>
                                    <Input placeholder="Email"
                                        // value={inputMAIL}
                                        onChange={e => {
                                            if (e.target.value.length > 0) {
                                                setuserMail(e.target.value)
                                            } else {
                                                setuserMail(undefined)
                                            }
                                        }}
                                        allowClear
                                        style={{ borderRadius: 5 }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={4}>
                                <Form.Item name="inputNAME" style={{ width: "100%" }}>
                                    <Input placeholder="First Name"
                                        // value={inputNAME}
                                        onChange={e => {
                                            if (e.target.value.length > 0) {
                                                setuserName(e.target.value)
                                            } else {
                                                setuserName(undefined)
                                            }
                                        }}
                                        allowClear
                                        style={{ borderRadius: 5 }}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Button 
                                    htmlType="submit" 
                                    type="primary" 
                                    style={{ borderRadius: "5px", marginRight: "8px" }} 
                                    icon={<Icons.SearchOutlined />}
                                    onClick={() => onsearchTB(dataTBLOCAL)}
                                >
                                    {t("buttons.search")}
                                </Button>

                                <Button 
                                    htmlType="submit"
                                    style={{ borderRadius: "5px" }}
                                    icon={<Icons.UndoOutlined />}
                                    onClick={() => onReset(dataTBLOCAL)}
                                >
                                    {t("buttons.reset")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                <Col span={6} style={{ marginTop: "44px" }}>
                    <Form.Item style={{marginRight: "0", float: "right"}}>
                        {/* <ExportButton style={{ borderRadius: 5, zIndex: '1', marginRight: "8px" }}
                            // onClick={triggerExport}
                            // loading={exportLoading}
                        /> */}

                        <CreateButton
                            id="create_btn"
                            value={1}
                            // onClick={() => createModalShow()}
                            disabled={data_permission?.create ? data_permission?.create == false ? true : false : true}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <Spin size="large" spinning={isLoading}>
                <Table 
                    //{...tableProps}
                    dataSource={!!dataSoce ? dataSoce : []}
                    bordered
                    size="middle"
                    rowKey="id"
                    pagination={{
                        defaultPageSize: 10, 
                        showSizeChanger: true,
                        locale: { items_per_page: "/ Pages" },
                        pageSizeOptions: [10, 50, 100],
                        onShowSizeChange,
                        //total: tableQueryResult?.data?.total,
                        total: !!dataSoce ? dataSoce.length : null,
                        onChange(Current) { 
                            //console.log(Current)
                            setDataSoce(undefined)   
                            setProp(undefined);
                            //setPage(Current)
                         }
                    }}
                    scroll={{ x: 1300 }}
                >

                    {/* <Table.Column width={40} title="No." align="center" key='index' 
                        render={(value, item, index) => (page - 1) * pageSizeChange + index + 1} 
                    /> */}

                    {/* <Table.Column align="center" width={50} dataIndex="avatar" title="  "
                        render={(value: any, record: any) => {
                            return <>{value ? <Avatar size={32} src={value.url} /> : <Avatar size={32} icon={<Icons.UserOutlined />} />}</>
                        }}
                    /> */}

                    <Table.Column title="REFERENCE CODE" align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.user_ref < b?.user_ref) {return -1}
                            if (a?.user_ref > b?.user_ref) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => { 
                            return <>
                                <TextField value={record ? record.user_ref : "-"} />
                            </>
                        }} 
                    />


                    <Table.Column title="FIRST NAME" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.first_name.localeCompare(b?.first_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            // console.log("xxxxaaa",record)
                            return <>
                                <TextField value={record? record.first_name:"-"} />
                            </>
                        }}
                    />

                    <Table.Column title="LAST NAME" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.last_name.localeCompare(b?.last_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <>
                                <TextField value={record? record.last_name:"-"} />
                            </>
                        }}
                    />

                    <Table.Column  title="USERNAME" align="center" width={200} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user?.username.localeCompare(b?.user?.username)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField value={record?.user? record?.user.username :"-"} />
                        }}
                    />

                    <Table.Column title="TEL" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.tel < b?.tel) {return -1}
                            if (a?.tel > b?.tel) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            return <TextField value={record.tel} />
                        }}
                    />

                    <Table.Column title="NUMBER OF UNIT" align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a.numberOf < b.numberOf) {return -1}
                            if (a.numberOf > b.numberOf) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            return  <TextField value={record.numberOf ? record.numberOf :"-"} />
                        }}
                    />

                    <Table.Column title="USER TYPE" width={150} align={"center"} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => a?.user_type?.type_name.localeCompare(b?.uuser_type?.type_name)}
                        sortDirections={['ascend', 'descend']}
                        render={(value: any, record: any) => {
                            return <TextField value={record !== null && record !== undefined ? record.user_type.type_name : "ไม่ระบุ"} />
                        }}
                    />

                    <Table.Column align="center" width={150} title="STATUS"
                        sorter={(a:any, b:any) => {
                            if (a?.deleteStatus < b?.deleteStatus) {return -1}
                            if (a?.deleteStatus > b?.deleteStatus) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            let c = ""
                            let s = ""
                            c = (record.deleteStatus === "N") ? 'green' : (record.deleteStatus === "Y") ? 'red' : ''
                            s = (record.deleteStatus === "N") ? 'ACTIVE' : (record.deleteStatus === "Y") ? 'INACTIVE' : ''
                            return <>
                                {value &&
                                    <Tag style={{ borderRadius: "10px" }}
                                        color={c}
                                    >
                                        {s}
                                    </Tag>
                                }
                            </>
                        }}
                    />

                    <Table.Column title="REGIS BY" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            return <TextField style={{ fontWeight: 600 }} value={record?.createBy ? record?.createBy.first_name : "-"} />
                        }}
                    />

                    <Table.Column width={190} title="REGISTER DATE"
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            return <TextField value={Moment(record.createdAt).format('D/MM/yyyy HH:mm')} />
                        }}
                    />

                    <Table.Column title="UPDATE BY" align="center" width={150} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            return <TextField style={{ fontWeight: 600 }} value={record?.updateBy ? record?.updateBy.first_name : "-"} />
                        }}
                    />

                    <Table.Column title="LAST UPDATE" align="center" width={190}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value: any, record: any) => {
                            return <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')} />
                        }}
                    />
                    <Table.Column<IUserProfile>
                        // z-index = {-1}
                        width={120}
                        title="ACTIONS"
                        align="center"
                        dataIndex="actions"
                        fixed="right"
                        render={(_, record) => (
                            <Space>
                                {record?.user_type.type_code !== "00" && <ShowButton hideText size="small" recordItemId={record?.id}>รายละเอียด</ShowButton>}

                                {record?.deleteStatus === "N" ? record?.user_type.type_code !== "00" &&
                                        <>
                                            <EditButton hideText size="small" recordItemId={record?.id} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true} />
                                            
                                            <Button size="small" title="Inactive" icon={<Icons.LockOutlined />} onClick={() => { showDelConfirm(record?.id, record) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true} />
                                        </>
                                        :
                                        record?.user_type.type_code !== "00" && <Button size="small" title="Active" icon={<Icons.UnlockOutlined />} onClick={() => { showUnlockConfirm(record?.id, record) }} ></Button>
                                }

                            </Space>
                        )}
                    />
                </Table>
            </Spin>
        </List>
    )
}
