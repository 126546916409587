import React from 'react'
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useShow, useTranslate, useUpdate } from "@pankod/refine-core";

type Props = {}

export const listnull: React.FC<IResourceComponentsProps> = () => {
  return (
    <div>
    </div>
    // <div className='background-landing-k'></div>


  )
}

export default listnull