/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select,Typography, useSelect, useModalForm, Radio, message, RcFile, UploadFile, Switch, Divider, TableProps, useForm } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import axios from "axios";
import { ICoupon, IEventlog } from "interfaces";
// import Moment from 'moment';
import { useEffect, useState } from "react";
// import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { HashRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import { FontColor } from '@ckeditor/ckeditor5-font';
// import MDEditor from '@uiw/react-md-editor';
import { majorCareUrl } from "../../../constants";
import dayjs from "dayjs";
import e from "express";
  
let swLocal : boolean = true;
let dataonFilter: any = [];
export const MjcDone: React.FC<IResourceComponentsProps> = () => {
    const [keepJobNo, setKeepJobNo] = useState<any>()
    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    // console.log('majorCareUrl', majorCareUrl)
    const { create, edit } = useNavigation();
    const [countInterval, setCountInterval] = useState(0);
    const [dataList, setDataList] = useState<any>([]);
    const [keepData, setKeepData] = useState<any>();
    const dataBlank:any = []
    const { formProps } = useForm<any,HttpError,{}>()
    const getDataK = async ()=>{
        // http://192.168.100.129:1337/api/home-check-up-settings/find_setting_by_id?property=2&type=Notification&get_all_setting=false&setting_id=10
        // let res_data = await axios.get(API_URL + `/home-check-up-settings/find_setting_by_id?property=${property_id}&type=Notification&get_all_setting=false&setting_id=${setting_id}`, { headers: { Authorization: AuthStr } });
       
        // 6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
        // 6485bd96681aef755a4f27a1 = หมดอายุ
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findHomecheckjobByStat`,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad', dataLoad)
                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.request_by?.unitDetail?.property_id == Number(prop_id))
                }

                let xx = dataLoad.filter((e:any)=> e.status == "6485bd7d681aef755a4f279d" || e.status == "6485bd96681aef755a4f27a1" )
                let newDatalist = []
                for (let index = 0; index < xx.length; index++) {
                    // dataList.push(xx[index]);
                    newDatalist.push(xx[index])
                }
                if (!!newDatalist && newDatalist !== null) {
                    setDataList(newDatalist)
                }else{
                    setDataList(xx)
                }
                setKeepData(xx)
            });
    }
    //console.log('dataList', dataList)

    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){ 
                    // console.log('call')
                    getDataK();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const columns:any = [
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.job-no")}</span>,
            dataIndex: 'item_name',
            key: 'item_name',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.job_id.localeCompare(b?.job_id),
            width: 150,
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.job_id !== null ? record.job_id : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.property")}</span>,
            // dataIndex: 'name',
            key: 'name',
            width: 120,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.property_obj[0]?.property_name.localeCompare(b?.property_obj[0]?.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record ? record.property_obj.length > 0 ? record.property_obj[0].property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.unit-no")}</span>,
            // dataIndex: 'createBy',
            key: 'unit_no',
            width: 120,
            sorter: (a:any, b:any) => a?.unit_obj[0]?.unit_name.localeCompare(b?.unit_obj[0]?.unit_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.unit_obj.length > 0 ? record.unit_obj[0].unit_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.customer-name")}</span>,
            // dataIndex: 'cus_name',
            key: 'cus_name',
            width: 200,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.request_by?.attributes?.first_name.localeCompare(b?.request_by?.attributes?.first_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>-</div> */}
                        <div>{record ? record.request_by.attributes !== undefined ? record.request_by.attributes.first_name + " " + record.request_by.attributes.last_name  : '-': '-'}</div>

                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.trans-date")}</span>,
            dataIndex: 'transfer_date',
            key: 'transfer_date',
            width: 170,
            align: 'center',
            sorter: (a:any, b:any) => a?.request_by?.unitDetail?.transfer_date.localeCompare(b?.request_by?.unitDetail?.transfer_date),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.request_by ? record.request_by.unitDetail ? dayjs(record.request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.appointment-date")}</span>,
            dataIndex: 'appointment_date',
            key: 'appointment_date',
            width: 170,
            align: 'center',
            sorter: (a:any, b:any) => a?.create_date.localeCompare(b?.create_date),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.appoint_start !== undefined ? dayjs(record.appoint_start).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.responsible-person")}</span>,
            // dataIndex: 'assign',
            key: 'assign',
            width: 180,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.assign?.first_name.localeCompare(b?.assign?.first_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.assign ? record.assign.first_name + ' ' + record.assign.last_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.approve-date")}</span>,
            dataIndex: 'approve_date',
            key: 'approve_date',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>-</div> */}
                        <div>{record ? record.update_date !== undefined ? dayjs(record.update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.approver")}</span>,
            dataIndex: 'approve',
            key: 'approve',
            width: 180,
            align: 'center',
            className: "table_colmn_custom_left_value",
            // sorter: (a:any, b:any) => {
            //     if (a?.approve?.approve_name < b?.approve?.approve_name) {return -1}
            //     if (a?.approve?.approve_name > b?.approve?.approve_name) {return 1}
            //     return (0)
            // },
            sorter: (a:any, b:any) => a?.approve?.approve_name.localeCompare(b?.approve?.approve_name),
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>-</div> */}
                        <div>{record ? record.approve !== undefined ? record.approve.approve_name : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.finish-date")}</span>,
            dataIndex: '',
            key: 'finish',
            width: 180,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.update_date !== undefined ? dayjs(record.update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.create-by")}</span>,
            // dataIndex: 'createBy',
            // key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.create_by?.first_name < b?.create_by?.first_name) {return -1}
                if (a?.create_by?.first_name > b?.create_by?.first_name) {return 1}
                return (0)
            },
            render: (createBy:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.create_by?.first_name : "-"} {record?.create_by?.last_name ? record?.create_by?.last_name : "-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.created-date")}</span>,
            // dataIndex: 'createDate',
            key: 'createDate',
            width: 150,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.create_date < b?.create_date) {return -1}
                if (a?.create_date > b?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>-</div> */}
                        <div>{record ? record.create_date !== undefined ? dayjs(record.create_date).format("DD/MM/YY HH:mm")  : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.update-by")}</span>,
            // dataIndex: 'updateBy',
            // key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: 150,
            sorter: (a:any, b:any) => {
                if (a?.update_by?.first_name < b?.update_by?.first_name) {return -1}
                if (a?.update_by?.first_name > b?.update_by?.first_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div >{record ? record?.update_by?.first_name : "-"} {record?.update_by?.last_name ? record?.update_by?.last_name : "-"}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.last-update")}</span>,
            // dataIndex: 'update_date',
            // key: 'update_date',
            align: 'center',
            width: 150,
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.update_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.status")}</span>,
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 150,
            sorter: (a:any, b:any) => a?.status.localeCompare(b?.status),
            render: (value:any, record:any) => {
                return (
                    <>

                        {/* // 6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
                        // 6485bd96681aef755a4f27a1 = หมดอายุ */}
                        <div>
                            {
                                record ? 
                                    record.status !== null ?
                                        record.status == "6485bd7d681aef755a4f279d" ?
                                            <Tag className="tag_mjc_stat_done"><TextField value={'เสร็จสมบูรณ์'}  /></Tag>
                                        :
                                            <Tag className="tag_mjc_stat_done_expire"><TextField value={'หมดอายุ'}  /></Tag>
                                    : '-'
                                : '-'
                            }
                        </div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.repair-job.sla")}</span>,
            dataIndex: 'status',
            key: 'status',
            fixed: 'right',
            width: 90,
            render: (value:any, record:any) => {
                return (
                    <>
                        {/* <div>{record ? record.sla ? record.sla.first_name + ' ' + record.assign.last_name : '-': '-'}</div> */}
                        {
                            record.sla != undefined ? 
                                record.sla.sla == "Y" ? 
                                    <div style={{color:'green'}}>On Time</div>
                                : record.sla.sla == "N" ? 
                                        <div style={{color:'red'}}>Over Time</div>
                                    :
                                        '-'
                            : '-'
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 85,
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            onClick={() => edit("mjc-done", record._id + "_" + record.item_name )}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                        />
                    </>
                );
            },
        },
    ];

    // console.log('filelist', FileList)
    const { selectProps: selDocType } = useSelect<any>({
        resource    : "document-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';

    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomRight');
    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        setCountInterval(0)
        setRefreshFlag(!refreshFlag);
    };

    const searchData = () => {
        let newData = dataList
        //console.log("keepJobNoNewData",newData)
        let filter = newData.filter((e:any) => e?.job_id.includes(keepJobNo.toUpperCase()));
        //console.log("filter",filter)
        setDataList(filter)
        setKeepData(filter)
    }

    const onReset = () => {
        setKeepData(undefined)
        setKeepJobNo(null)
        formProps?.form?.resetFields();
        refreshDataSource()
    }
    
    return <>
        <List 
            title=""
        >
            <Row>
                <Col span={18}>
                    <Form
                        layout = "inline"
                        style={{marginBottom:"2%"}}
                        {...formProps}
                    >
                        <Form.Item name="Job_no" >
                            <Input
                                onChange={(e) => setKeepJobNo(e.target.value)}
                                placeholder="Search job" 
                                prefix={<Icons.SearchOutlined />} 
                                allowClear
                            />
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => searchData()} type="primary" style={{borderRadius: "5px"}}>
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>

                        <Form.Item >
                            <Button onClick={() => onReset()} style={{borderRadius: "5px"}}>
                                <Icons.UndoOutlined /> Reset
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            <div style={{width:'100%'}}>
                {
                    dataList.length > 0 ?
                        <Table 
                            // dataSource={dataList} 
                            dataSource={dataList.length > 0 ? dataList : dataBlank } 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 2650 }}
                        />
                    :
                        <Table  
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 2650 }}
                        />
                }
            </div>
            {/* <div style={{width:'100%'}}>
                <Row>
                    <p style={{fontSize: "20px"}}>{`รายการตรวจทั้งหมด ${!!keepData ? keepData.length : "0"} รายการ`}</p>
                </Row>
                <Row>
                    <Col style={{paddingRight: "20px"}}>
                        <p style={{fontSize: "20px"}}>{`ปกติ ${!!keepData && keepData.status == "6485bd7d681aef755a4f279d" ? keepData.length : "0"} รายการ`}</p>
                    </Col>
                    <Col>
                        <p style={{fontSize: "20px"}}>{`ไม่ปกติ ${!!keepData && keepData.status != "6485bd7d681aef755a4f279d" ? keepData.length : "0"} รายการ`}</p>
                    </Col>
                </Row>
            </div> */}
            <Row style={{float:'right', paddingTop:'10px'}}>
                <Col span={24} >
                    {t("repair-stat.title")} {!!keepData ? keepData?.length : 0}
                </Col>
            </Row>
        </List>
    </>
}