import { message, notification } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { API_URL, TOKEN_KEY, chatURL } from "../../../../constants";
import './ch.less';

export const ChatMain: React.FC<any> = ({ getMessageFromFrame,c_user,pass }) => {

    const [count, setCount]             = useState(0);
    // const [tigger ,setTigger]           = useState(0);
    const {data: Identity_data}         = useGetIdentity<any>();
    // const [channel , setChannel]        = useState<any>("general"); 
    const token = localStorage.getItem(TOKEN_KEY);
    // const niti_name = localStorage.getItem("NITI_NAME"); 

    const iframeRef = useRef<any>(null);
 
    //console.log(c_user);
    // console.log(">>> Identity_data",Identity_data)
    const { user_profile, username, email, chat_id } = Identity_data
    // console.log(">>> Identity_data on chatMain",Identity_data)

    const onMessageReceivedFromIframe = async (event:any) => {
        // console.log("onMessageReceivedFromIframe : ", event);
        // console.log('event.data', event.data); 
        let c = count;
        if (event.data.event === 'new-message') {
            c = c+1;
            setCount(c);
            getMessageFromFrame(c);
            console.log(c);
        } 

        if (event.data.event === 'new-message-noti') {
            let data = { 
                "sender_id": event.data.msg_data.sender_id,
                "receiver_id": event.data.msg_data.receiver_id,
                "message": event.data.msg_data.message
            }
            let res = await axios.post(API_URL + "/api/notifications/send_by_xchat_message", data , { headers: { Authorization: 'Bearer ' + token } });
            // console.log('res', res)
        }
    };
    
    const addIframeListener = () =>window.addEventListener("message", onMessageReceivedFromIframe);
    const removeIframeListener = () =>window.removeEventListener("message", onMessageReceivedFromIframe);
    const cc = (token:any) => { 
        console.log(">> token",token)
        console.log('user', c_user)
        console.log('pass', pass) 
        document.querySelector("iframe")?.contentWindow?.postMessage({
            event: 'login-chat',
            user : c_user,
            pwd : pass 
            },"*")
    }
    const dd = () =>{ 
        const niti_name = localStorage.getItem("NITI_NAME"); 
        // console.log('niti_name', niti_name) 
        document.querySelector("iframe")?.contentWindow?.postMessage({
            event: 'niti-user',
            // niti_name : "TSX!999" // ชื่อ นิติที่ Login เข้ามา
            // niti_name : `${user_profile.attributes.first_name} ${user_profile.attributes.last_name}` // ชื่อ นิติที่ Login เข้ามา
            // niti_name : `${user_profile.first_name} ${user_profile.last_name}` // ชื่อ นิติที่ Login เข้ามา
            niti_name : `${niti_name}` // ชื่อ นิติที่ Login เข้ามา
        },"*") 
    }

    const reloadIframe = () => {
        console.log(iframeRef.current);
        if (iframeRef.current) {
            iframeRef.current.src = iframeRef.current.src;
            console.log(iframeRef.current.src);
            // msgNotification();
        }
    }

    const msgNotification = () => {
        notification.info({
            message: "information",
            description: "chat session reconected",
            duration: 3,
        });
    };

    async function findNewContact() {
        const chat_contact_count = localStorage.getItem("CHAT_CONTACTS");
        let res_chat_count = await axios.get(API_URL + `/api/like-systems/xChatCountUnread?chat_id=${chat_id}`, {
          headers: { Authorization: "Bearer "+token }
        });
        // console.log('Number(chat_contact_count)', Number(chat_contact_count))
        // console.log('res_chat_count?.data.contacts', res_chat_count?.data.contacts)
        if(Number(chat_contact_count) !== res_chat_count?.data.contacts){
          console.log('found new contact, reload!') 
          localStorage.setItem("CHAT_CONTACTS", res_chat_count?.data.contacts)
          reloadIframe();
        }
    }

    const success = () => {
        // message
        //   .loading('chat session reconnecting...', 10)
        //   .then(() => message.success('Loading finished', 10))
        //   .then(() => message.info('Loading finished is finished', 10));
        message
        .loading({
                content: 'chat session reconnecting...', 
                duration: 4,
                style: {
                    // marginTop: '20vh',
                },
            })
        .then(() => message.success({
            content: 'reconnected', 
            duration: 2,
            style: {
                // marginTop: '20vh',
            },
        }))
      };
    
    const heatbeat = async () =>{
        cc("");
        dd(); 
        findNewContact();
    }

    useEffect(() => {
        addIframeListener();
        heatbeat();
        const interval = setInterval(() => {
            // console.log('call heartbeat') 
            heatbeat();
        },2000)
        const interval2 = setInterval(() => {
            // console.log('reload iframe 10 mins')
            // 600000 = 10 mins
            success();
            reloadIframe();
        },600000)
        // const interval3 = setInterval(() => {
        //     console.log('show modal b4 iframe reload')
        //     // 594000 = 9.9 mins
        //     success();
        // },114000)
        return () => {
          removeIframeListener();
        };
    }, []);

    // const cur_user = {};
    return <>
        <iframe
            id="chatFrame"
            ref={iframeRef} 
            // src="https://xchatv2.techscape.app" // SIT
            // src="https://xchat.techscape.app" // UAT
            // src="https://chat.lifescape.co.th" // PROD
            src={chatURL}
            style={{ width: '100%', height: '100%', border: 'none', display: 'block' }}
            // name={''+Date.now()+''} 
        >
        </iframe>
    </>
}