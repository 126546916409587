/* eslint-disable */
import { Breadcrumb, Card, Col, Edit, Form, Input, List, Row, useForm, useSelect,Select, Upload, Radio, RadioChangeEvent, RcFile, message, Button, Icons, useModal, Modal, Avatar, TextField, useSimpleList } from "@pankod/refine-antd";
import { IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useShow, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IProperties, IUnit, Ivehicle, IvehicleBrand, IvehicleColor, IvehicleModel, IvehicleType } from "interfaces";

import type { UploadFile } from 'antd/es/upload/interface';
import { useEffect, useState } from "react";

import { TOKEN_KEY } from "../../constants";
import moment, { isMoment } from 'moment';

let recordData: any = undefined;
export const VehicleEdit: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const API_URL = useApiUrl();
    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { TextArea } = Input;
    const { Dragger } = Upload;
    const { replace} = useNavigation();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint = "even-logs";

    const [vImg, setImg]        = useState<any>([]);
    const [vBrand, setbrand]    = useState<any>();
    const [vColor, setcolor]    = useState<any>();
    const [vType, settype]      = useState<any>();
    const [vModel, setmodel]    = useState<any>();
    const [vProperty, setProperty]  = useState<any>();
    const [vUnit, setUnit]          = useState<any>();
    const [vUnitName, setUnitName]  = useState<any>();
    const [vAppstatus, setAppstatus] = useState<boolean>();
    const [ vHaveSticker, setHaveSticker] = useState<boolean>();

    const [seltype, setseltype] = useState<any>();
    const [selbrand, setselbrand] = useState<any>();
    const { modalProps, show, close } = useModal();

    const { form,formProps, saveButtonProps } = useForm<Ivehicle>({redirect:"list"});

    const { queryResult } = useShow<Ivehicle>({
        // resource: "vehicles",
        metaData: {
            populate: [
                'unit',
                'unit.property',
                'vehicle_brand',
                'vehicle_color',
                'vehicle_model',
                'vehicle_type',
                'property',
                'pix',
                'createBy.avatar',
                'updateBy.avatar'
            ],
        },
        queryOptions:{
            onSuccess(data: any) {
                recordData = data?.data
                console.log(">>> data?.data", data)
                callFetchData(data?.data)
            },
        }
    });

    // const { data } = queryResult;
    // // const record = data?.data;

    // let recordData:any = []
    // recordData = data?.data? data?.data : [];

    // console.log(">>> recordData",recordData)

    // const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
    //     resource: "vehicles",
    //     metaData: {
    //         populate: [
    //             '*',
    //             'unit',
    //             'vehicle_brand',
    //             'vehicle_color',
    //             'vehicle_model',
    //             'vehicle_type',
    //             'updateBy',
    //             'createBy'
    //         ]
    //     },
    //     pagination:{
    //         pageSize: 100,
    //     },
    //     permanentFilter: [
    //         {
    //             field: "id",
    //             operator: "in",
    //             value: recordData.id,
    //         }
    //     ],
    //     queryOptions: {
    //         onSuccess: (data) => {
    //             console.log('data xxxx : ', data.data)
    //             callFetchData();

    //         },
    //     },
    // });

    // useEffect(() => {
    //     refetch();
    //     // console.log('');
    //  }, []);

    function callFetchData(record: any){
        console.log(">>> record",record)
        setbrand(record?.vehicle_brand ? record?.vehicle_brand.id: null);
        setcolor(record?.vehicle_color ? record?.vehicle_color.id: null);
        settype(record?.vehicle_type ? record?.vehicle_type.id: null);
        setmodel(record?.vehicle_model ? record?.vehicle_model.id: null);
        setUnitName(record?.unit ? record?.unit.unit_name : null);

        setHaveSticker(record?.sticker_status ? record?.sticker_status : false)

        setProperty(record?.unit ? record?.unit.property.id : null);
        setUnit(record?.unit ? record?.unit.id : null);

        setAppstatus(record?.approve_status ? record?.approve_status == "true" ? true:false : undefined);

        form?.setFieldsValue({
            vehicle_type:
                record?.vehicle_type ?
                    record?.vehicle_type?.type_nameEN
                : null,
            vehicle_brand:
                record?.vehicle_brand ?
                    record?.vehicle_brand?.brand_nameEN
                : null,
            vehicle_model:
                record?.vehicle_model ?
                    record?.vehicle_model.model_nameEN
                : null,
            vehicle_color:
                record?.vehicle_color ?
                    record?.vehicle_color.color_nameEN
                : null,
            property:
                record?.unit ?
                    record?.unit.property.property_name
                : null,
            unit:
                record?.unit ?
                    record?.unit.unit_name
                : null,
            approve_status:
                record?.approve_status !== null && record?.approve_status !== undefined ?
                    record?.approve_status == "true" ? true:false
                : null,
        })

        const fileList: UploadFile[] = [
            {
                uid: record?.pix.id,
                name: record?.pix.name,
                status: "done",
                url:
                    record?.pix?.url,
                thumbUrl:
                    record?.pix?.url
            }
            ];

            setImg({
                name: 'files',
                multiple: true,
                defaultFileList: [...fileList],
        })
        form.resetFields(["pix"]);
    }

    const { selectProps: SelPropertyType } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
    });

    const { selectProps: SelUnit} = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 100,
        filters: [
            {
                field   : "property][id]",
                operator: "eq",
                value: vProperty
            }
        ]
    });
    
    const { selectProps: SelTypeV} = useSelect<IvehicleType>({
        resource: "vehicle-types",
        optionLabel: "type_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const { selectProps: SelBrandV} = useSelect<IvehicleBrand>({
        resource: "vehicle-brands",
        optionLabel: "brand_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'vehicle_type][id]',
                operator    : 'eq',
                value       : seltype,
            },
        ] 
    });

    const { selectProps: SelModelV} = useSelect<IvehicleModel>({
        resource: "vehicle-models",
        optionLabel: "model_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
            {
                field       : 'vehicle_type][id]',
                operator    : 'eq',
                value       : seltype,
            },
            {
                field       : 'vehicle_brand][id]',
                operator    : 'eq',
                value       : selbrand,
            },
        ] 
    });

    const { selectProps: SelColorV} = useSelect<IvehicleColor>({
        resource: "vehicle-colors",
        optionLabel: "color_nameEN",
        optionValue: "id",
        fetchSize: 100,
        filters     : [
            {
                field       : 'deleteStatus',
                operator    : 'contains',
                value       :  'N',
            },
        ] 
    });

    const onChangeApprove = ({ target: { value } }: RadioChangeEvent) => {
        setAppstatus(value);
        if(value == false){
            form?.resetFields(["reject_msg"])
        }
    };

    function updateUserUpdatedBy(){
        CreateLog({
            resource    : log_endpoint,
            values      : {
                user_profile            : user_data.user_profile.id,
                log_time_stamp          : Date.now(), 
                log_event               : "Edit",
                menu_event              : 'properties',
                SysPkID                 : queryResult.data?.data.id+""
            },
            successNotification : false
        });
        // replace("")
    }

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };

    const onChangeRadio = (e: RadioChangeEvent) => {
        // console.log('onChangeRadio3', e.target.value);
        setHaveSticker(e.target.value);
        // console.log('vRaPrivCoupon', vRaPrivCoupon)
    };

    return (
     <Edit saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("vehicle.edit")}
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("vehicle.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("vehicle.edit")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            updateUserUpdatedBy();
            values.updateBy       = user_data.user_profile.id
            values.vehicle_type   = vType
            values.vehicle_brand  = vBrand
            values.vehicle_model  = vModel
            values.vehicle_color  = vColor
            values.unit           = vUnit

            values.approve_status = vAppstatus + ""
            
            if(vAppstatus == false){
                values.reject_msg = values.reject_msg
            }else if(vAppstatus == true){
                values.reject_msg = ""
            }

            return ( 
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
            );
         }}>
            <Row gutter={[16, 24]}>
                <Col className="gutter-row" span={24} style={{marginTop: "-60px",marginBottom: "1%"}}>
                    <Row gutter={16}>
                        <Col className="gutter-row" span={22} />
                        <Col className="gutter-row" span={2}>
                            <Button onClick={show} style={{width: "100%",borderRadius: "5px"}} icon={<Icons.InfoCircleOutlined/>}>Info</Button>    
                        </Col>
                    </Row>
                </Col>

                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={[16, 24]}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.license")} name="license_plate"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter license plate.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.year")} name="year"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter year.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("vehicle.sticstatus")} name="sticker_status"
                                    rules={[
                                        {
                                            required: true, message: 'Please select.'
                                        },
                                    ]}
                                    >
                                    <Radio.Group 
                                        style={{verticalAlign: 'middle'}}
                                        onChange={onChangeRadio}
                                    >
                                        <Radio.Button value={true}>{t("vehicle.sttrue")}</Radio.Button>
                                        <Radio.Button value={false}>{t("vehicle.stfalse")}</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            {
                                vHaveSticker ?
                                    <>
                                        <Col className="gutter-row" span={6}>
                                            <Form.Item  label={t("vehicle.sticid")} name="sticker_id"
                                                rules={[
                                                    {
                                                        required: true, message: 'Please enter sticker id.'
                                                    },
                                                ]}>
                                                    <Input />
                                            </Form.Item>
                                        </Col>
                                    </>
                                :
                                    <></>
                            }


                            {/* going back to the corner where I first saw you */}
                            {/* your lips my lips */}
                            {/* apocalypse */}
                            {/* ว่ามันเกิดอะไร */}
                            <Col span={12}>
                                {/* <div style={{ marginBottom: "5%",marginTop: "5%" }}><span className="title_panel">{t("vehicle.pix")}</span></div> */}
                                <Form.Item name="pix" label={t("vehicle.pix")}>
                                    <Dragger style={{borderRadius: "10px"}} 
                                        {...vImg}
                                        name="files"
                                        action={`${API_URL}/upload`}
                                        headers={{
                                            Authorization: `Bearer ${localStorage.getItem(
                                                TOKEN_KEY,
                                            )}`,
                                        }}
                                        listType="picture"
                                        // multiple
                                        maxCount={1}
                                        // onChange={fileChange}
                                        beforeUpload={beforeUpload}
                                    >
                                        <p className="ant-upload-text">{t("upload")}</p>
                                    </Dragger>
                                    {/* <p style={{textAlign: "center",color: "#DF6677",marginTop: "2%"}}>{t("user-profiles.limitupload")}</p> */}
                                </Form.Item>
                                <p style={{fontSize:"10px", alignItems:"stretch", color: "#DF6677"}}>*PNG, JPG Maximum size 5MB</p>

                                <p></p>
                            </Col>

                            <Col span={12}>
                                <Input.Group compact>
                                    <Form.Item  label={t("vehicle.type")} name="vehicle_type" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please select.'
                                            },
                                        ]}
                                    >
                                            <Select 
                                                placeholder={t("select.sel")} 
                                                {...SelTypeV} 
                                                value={vType} 
                                                style={{width: "95%"}}
                                                onChange={(e) => {
                                                    setseltype(e);
                                                    settype(e);
                                                    form.resetFields(["vehicle_brand"]);
                                                    form.resetFields(["vehicle_model"]);
                                                }}
                                            />
                                    </Form.Item>

                                    <Form.Item  label={t("vehicle.brand")} name="vehicle_brand" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please select.'
                                            },
                                        ]}
                                    >
                                            <Select 
                                                placeholder={t("select.sel")} 
                                                {...SelBrandV} 
                                                value={vBrand} 
                                                onChange={(e) => {
                                                    setselbrand(e);
                                                    setbrand(e);
                                                }}
                                            />
                                    </Form.Item>
                                </Input.Group>

                                <Input.Group compact>
                                    <Form.Item  label={t("vehicle.model")} name="vehicle_model" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please select.'
                                            },
                                        ]}
                                    >
                                            <Select 
                                                placeholder={t("select.sel")} 
                                                {...SelModelV} 
                                                value={vModel} 
                                                style={{width: "95%"}}
                                                onChange={value => {
                                                    setmodel(value);
                                                }}
                                            />
                                    </Form.Item>

                                    <Form.Item  label={t("vehicle.color")} name="vehicle_color" style={{width: "50%"}}
                                        rules={[
                                            {
                                                required: true, message: 'Please select.'
                                            },
                                        ]}
                                    >
                                            <Select 
                                                placeholder={t("select.sel")} 
                                                {...SelColorV}  
                                                onChange={value => {
                                                    setcolor(value);
                                                }}
                                            />
                                    </Form.Item>
                                </Input.Group>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                            <Form.Item  label={t("property.title")} name="property"
                                rules={[
                                    {
                                        required: true, message: 'Please select Property.'
                                    },
                                ]}
                            >
                                <Select 
                                    placeholder={t("select.sel")} 
                                    {...SelPropertyType} 
                                    value={vProperty}
                                    onChange={value => {
                                        form.resetFields(["unit"]);
                                        setProperty(value);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item  label={t("unit.title")} 
                                name="unit"
                                rules={[
                                    {
                                        required: true, message: 'Please enter unit code.'
                                    },
                                ]}
                            >
                                    {/* <div style={{visibility: "hidden",height: "0px"}}>{vUnit}</div> */}
                                    <Select 
                                        placeholder={t("select.sel")}
                                        {...SelUnit}
                                        onChange={value => {
                                            setUnit(value);
                                        }}
                                    />
                            </Form.Item>

                            {/* {console.log(vAppstatus)} */}

                            <Form.Item  label={t("vehicle.appstatus")} name="approve_status"
                                rules={[
                                    {
                                        required: true, message: 'Please select.'
                                    },
                                ]}
                                >
                                <Radio.Group style={{verticalAlign: 'middle'}} onChange={onChangeApprove} value={vAppstatus}>
                                    <Radio.Button value={true}>{t("vehicle.true")}</Radio.Button>
                                    <Radio.Button value={false}>{t("vehicle.false")}</Radio.Button>
                                </Radio.Group>
                            </Form.Item>

                            {vAppstatus == false &&
                                <Form.Item  label={t("vehicle.reject-msg")} name="reject_msg"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter license reject message.'
                                        },
                                    ]}>
                                        {/* <Input /> */}
                                        <TextArea style={{minHeight: "100px", maxHeight: "200px"}}/>
                                </Form.Item>
                            }
                    </Card>
                </Col>
            </Row>

            <Modal onOk={close} {...modalProps} footer={[<Button key="submit" type="primary" onClick={close}>OK</Button>]}>
                <div><Icons.InfoCircleOutlined style={{marginRight: "3%"}}/><TextField value={"Data Create & Update"}/></div>
                <Row gutter={16} style={{marginTop: "6%"}}>
                    <Col className="gutter-row" span={24}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={15}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4}>
                                    {
                                        recordData?.createBy?
                                            recordData?.createBy?.avatar !== null ?

                                            <Avatar size={32} src={recordData?.createBy?.avatar?.url} style={{marginTop: "-7px"}}/>
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                    }
                                    </Col>
                                    <Col className="gutter-row" span={8}><span>{t("created")}</span></Col>
                                    <Col className="gutter-row" span={12}><TextField value={moment(recordData?.createdAt).format('D/MM/yyyy HH:mm')}/></Col>
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                    {/* <Col className="gutter-row" span={18}><TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null? recordData.createBy.first_name !== null && recordData.createBy.last_name !== null ? recordData.updateBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}/></Col> */}
                                    <Col className="gutter-row" span={18}>
                                        <TextField style={{marginLeft: "3%"}} value={recordData?.createBy !== null && recordData?.createBy !== undefined? 
                                            recordData?.createBy.first_name !== null && recordData.createBy.last_name !== null ? 
                                            recordData.createBy.first_name + " " + recordData.createBy.last_name : null :"ไม่ระบุ"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>

                    <Col className="gutter-row" span={24} style={{marginTop: "3%"}}>
                        <Row gutter={16}>
                            <Col className="gutter-row" span={15}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={4}>
                                    {
                                        recordData?.updateBy?
                                            recordData?.updateBy?.avatar !== null ?

                                            <Avatar size={32} src={recordData?.updateBy?.avatar?.url} style={{marginTop: "-7px"}}/>
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                        </>:<Avatar size={32} icon={<Icons.UserOutlined />} style={{marginTop: "-7px"}}/>
                                    }
                                    </Col>
                                    <Col className="gutter-row" span={8}><span>{t("lupdate")}</span></Col>
                                    <Col className="gutter-row" span={12}><TextField value={moment(recordData?.updatedAt).format('D/MM/yyyy HH:mm')}/></Col>
                                </Row>
                            </Col>
                            <Col className="gutter-row" span={7}>
                                <Row gutter={16}>
                                    <Col className="gutter-row" span={6}><span>{t("by")}</span></Col>
                                    <Col className="gutter-row" span={18}>
                                        <TextField style={{marginLeft: "3%"}} value={recordData?.updateBy !== null && recordData?.updateBy !== undefined? 
                                            recordData?.updateBy.first_name !== null && recordData.updateBy.last_name !== null ? 
                                            recordData.updateBy.first_name + " " + recordData.updateBy.last_name : null :"ไม่ระบุ"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal>
        </Form>
    </Edit>   
    );
};