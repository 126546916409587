/* eslint-disable */
import { Badge, Breadcrumb, Button, Col, CreateButton, DatePicker, EditButton, ExportButton, Form, Icons, Input, List, message, Modal,  Row,  Select,  ShowButton,  Space, Table, Tabs, Tag, TextField, Tooltip, useForm, useSelect, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useGetLocale, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IFacility, IFacilityBooking, IFacilityType, IProperties, IUnit} from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined, CheckCircleOutlined, QuestionOutlined, CloseCircleOutlined} from "@ant-design/icons";
import axios from "axios";
import { TOKEN_KEY } from "../../constants";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";

const { TabPane } = Tabs;

export const FacilitiesBKList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility Booking");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    // const prop_id = localStorage.getItem("PROPERTY_ID");
    // const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);
    
    const todayDate = dayjs().format("YYYY-MM-DD");
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IFacilityBooking>();
    const ed_point                          = "facility-bookings";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    const { replace }       = useNavigation();
    const API_URL           = useApiUrl();
    let resultQuery1:any    = [];
    const locale            = useGetLocale();
    const currentLocale     = locale();
    const token             = localStorage.getItem(TOKEN_KEY);
    const [ vProp, setProp ] = useState<any>();
    const [dataTime, setDataTime] = useState<any>()
    const [ vUnit, setUnit ] = useState<any>();
    const [ vType, setType ] = useState<any>();
    const [keepDataStatus, setKeepDataStatus] = useState<any>('N')
    const [listData, setListdata] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(null);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [activeBadgeNumber, setActiveBadgeNumber] = useState<any>(null)
    const [trashBadgeNumber, setTrashBadgeNumber] = useState<any>(null)

    const { formProps } = useForm<any,HttpError,{}>() 

    const { triggerExport, isLoading: exportLoading } = useExport<IFacilityBooking>({
        mapData: (item) => {
            //console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                };
            }
        },
    });

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            // values      : vCurDelStatus,
            values      : {
                updateBy        : user_data.user_profile.id + "",
                deleteStatus    : vCurDelStatus.deleteStatus
            }
        },{
            onSuccess(data, variables, context) {
                getData()
            },
            onError(error, variables, context) {
                getData()
            },
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'facility-bookings',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showAcknowledge(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Acknowledge Confirm', 
            icon    : <QuestionOutlined />,
            // content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateAcknowledge(id, "Acknowledged");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function updateAcknowledge(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : {
                updateBy        : user_data.user_profile.id + "",
                facility_status_admin : event
            },
            successNotification: false
        });
    }

    function CancelBooking(id:any) {
        confirm({
            title   : 'Cancel Booking', 
            icon    : <QuestionOutlined />,
            // content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateStatBooking(id, "Cancelled");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    async function updateStatBooking(id:any, event:any){
        resultQuery1 = await axios.get(API_URL + "/facility-bookings/booking_cancel?facility_booking=" + id  , { headers: { Authorization: 'Bearer ' + token } });
        
        if(resultQuery1.data.EN === "Time is up for cancelling this booking. Please contact juristic person."){
            currentLocale === 'en' ?
                message.error ({
                    content: resultQuery1.data.EN,
                },5)
            :
                message.error ({
                    content: resultQuery1.data.TH,
                },5);
        }else{
            currentLocale === 'en' ?
                message.success ({
                    content: resultQuery1.data.EN,
                },3)
            :
                message.success ({
                    content: resultQuery1.data.TH,
                },3);
            window.location.reload();
        }

    }

    function onStatusChange(v:any){
        // formProps.form?.submit();
        setKeepDataStatus(v);
        setPage(1)
        localStorage.setItem("keyU",v)
        localStorage.setItem("keyTabUserPortal", v)
        
    }

    const onReset = () => {
        setProp(undefined)
        setUnit(undefined)
        setType(undefined)
        setDataTime(undefined)
        setKeepStatusBooking(undefined)
        formProps.form?.resetFields()
        // formProps.form?.submit()
    }

    const { selectProps: propertySelectProps } = useSelect<IProperties>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        filters:[
            {
                field: "id",
                operator: data_property ? "eq" : "nin",
                value: data_property ? data_property : null
            }
        ],
        fetchSize: 100,
    });

    const { selectProps: selUnit } = useSelect<IUnit>({
        resource: "units",
        fetchSize: 500,
        metaData: {
            populate: ['*', 'property']
        },
        optionLabel: "unit_name",
        optionValue: "id",
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value : "N" 
            },
            {
                field       : "property][id]",
                operator    : "eq",
                value       : !!vProp ? vProp : null
            },
        ]
    });

    const { selectProps: facilityTypeSelectProps } = useSelect<IFacilityType>({
        resource: "facility-types",
        optionLabel: "name",
        optionValue: "id",
        // optionLabel: "projectNameTH",
        // filters: initF,
        filters: [
            {
                field       : 'deleteStatus',
                operator    : 'eq',
                value       : 'N',
            },
        ],
        fetchSize: 100,
    });
    const [keepStatusBooking, setKeepStatusBooking] = useState<any>()

    const statusbooking = [
        {
            value: 'Booking',
            label: 'Booking',
        },
        {
            value: 'Cancelled',
            label: 'Cancelled',
        },
        {
            value: 'Waitlist',
            label: 'Waitlist',
        },
        {
            value: 'Reschedule',
            label: 'Reschedule',
        },
    ]

    const handleNewDatePickerChange = (date: Dayjs | null, dateString: string, id: number) => {
        setDataTime(dayjs(dateString).format("YYYY-MM-DD"));
        //console.log("firsteeee",dayjs(dateString).format("DD-MM-YYYY"))
    };

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : sorterOrder
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }

        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSizeChange(pagination?.pageSize)
        }
    };
    
    const getData = async ()=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(vProp){
                apiEndPoint += `&filters[property][id][$eq]=${vProp}`
            }
            if(vUnit){
                apiEndPoint += `&filters[unit][id][$eq]=${vUnit}`
            }
            if(vType){
                apiEndPoint += `&filters[facility_type][id][$eq]=${vType}`
            }
            if(dataTime){
                apiEndPoint += `&filters[date][$contains]=${dataTime}`
            }
            if(keepStatusBooking){
                apiEndPoint += `&filters[facility_status_booking][$contains]=${keepStatusBooking}`
            }
            if(keepDataStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${keepDataStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${keepDataStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
    
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: 'Bearer ' + token } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const resultList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let unit = null
                        let property = null
                        let facility = null
                        let facility_type = null
                        let facility_time_slot = null
                        let user_profile = null
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.facility?.data){
                            facility = {id: attributes?.facility?.data?.id , ...attributes?.facility.data.attributes}
                        }
                        if(attributes?.facility_type?.data){
                            facility_type = {id: attributes?.facility_type?.data?.id , ...attributes?.facility_type.data.attributes}
                        }
                        if(attributes?.facility_time_slot?.data){
                            facility_time_slot = {id: attributes?.facility_time_slot?.data?.id , ...attributes?.facility_time_slot.data.attributes}
                        }
                        if(attributes?.user_profile?.data){
                            user_profile = {id: attributes?.user_profile?.data?.id , ...attributes?.user_profile.data.attributes}
                        }
                        try {
                            if(attributes?.unit?.data?.attributes){
                                const unitData = attributes?.unit?.data
                                let unitType = null
                                try {
                                    if(unitData?.attributes?.property?.data){
                                        const propertyData = unitData?.attributes?.property?.data
                                        let project = null
    
                                        if(propertyData?.attributes?.aqi_response?.data){
                                            propertyData.attributes.aqi_response = propertyData?.attributes?.aqi_response?.data
                                        }
                                        if(propertyData?.attributes?.project?.data){
                                            const projectData = propertyData?.attributes?.project?.data
                                            let projectBrand = null
                                            if(projectData?.attributes?.project_brand?.data){
                                                const projectBrandData = projectData?.attributes?.project_brand?.data
                                                projectBrand = {id: projectBrandData?.id , ...projectBrandData?.attributes}
                                            }
                                            project = {id: projectData?.id , ...projectData?.attributes}
                                            project.project_brand = projectBrand
                                        }
                                        property = {id: propertyData?.id , ...propertyData?.attributes}
                                        property.project = project
                                    }
                                } catch (error) {
                                    property = null
                                }
                                try {
                                    if(unitData?.attributes?.unit_type?.data){
                                        const unitTypeData = unitData?.attributes?.unit_type?.data
                                        unitType = {id: unitTypeData?.id , ...unitTypeData?.attributes}
                                    }
                                } catch (error) {
                                    unitType = null
                                }
                                unit = {id: unitData?.id , ...unitData?.attributes}
                                unit.property = property
                                unit.unit_type = unitType
                            }
                        } catch (error) {
                            unit = null
                            property = null
                        }
                        if(attributes?.property?.data){
                            property = {id: attributes?.property?.data?.id , ...attributes?.property.data.attributes}
                        }
                        const result = {id: item?.id , ...attributes}
                        result.createBy = createBy
                        result.updateBy = updateBy
                        result.unit = unit
                        result.property = property
                        result.facility = facility
                        result.facility_type = facility_type
                        result.facility_time_slot = facility_time_slot
                        result.user_profile = user_profile
                        return result
                    })
                    setListdata(resultList || [])
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    setLoadingTable(false)
                });
        } catch (error) {
            setLoadingTable(false)
        }
    }
    const getBadgeNumber = async (deleteStatus:any)=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${1}&pagination[page]=${1}`
            if(deleteStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${deleteStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${deleteStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            apiEndPoint += `&sort[id]=desc`
    
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: 'Bearer ' + token } }).then((res) => {
                    const dataLoad: any = res?.data
                    if(dataLoad?.meta?.pagination?.total){
                        const total = dataLoad?.meta?.pagination?.total > 0 ? dataLoad?.meta?.pagination?.total : null
                        if(deleteStatus.toLowerCase() == 'y'){
                            setTrashBadgeNumber(total)
                        }
                        else{
                            setActiveBadgeNumber(total)
                        }
                    }
                }).catch((e) => {
                    console.log(e)
                });
        } catch (error) {
        }
    }

    useEffect(() => {
        if (!vProp && !dataTime && !vUnit && !vType && !keepStatusBooking && Identity_data) {
            getData()
        }
      }, [vProp, dataTime, vUnit, vType, keepStatusBooking]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,keepDataStatus,Identity_data])

    useEffect(() => {
        if(Identity_data){
            getBadgeNumber('Y')
            getBadgeNumber('N')
        }
    }, [Identity_data])

    return <>
            
            <List 
                title={t("fclt-booking.list")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("fclt-m.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("fclt-booking.list")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >
 
                <Row>
                    <Col span={20}>
                        <Form
                            {...formProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >

                            <Form.Item name="property" >
                                <Select {...propertySelectProps} 
                                    placeholder="Select property" 
                                    size="middle" 
                                    style={{borderRadius: 20, width: '150px', position:'relative'}} 
                                    allowClear
                                    onChange={(e) => setProp(e)}
                                />
                            </Form.Item>

                            <Form.Item name="unit" >
                                <Select {...selUnit} placeholder="Select unit" size="middle" style={{borderRadius: 20, width: '150px', position:'relative'}} allowClear onChange={(e) => setUnit(e)}/>
                            </Form.Item>

                            <Form.Item name="facility_type">
                                <Select {...facilityTypeSelectProps}
                                    placeholder="facility type"
                                    size="middle"
                                    style={{borderRadius: 20, width: '150px', position:'relative'}}
                                    allowClear
                                    onChange={(e) => setType(e)}
                                />
                            </Form.Item>

                            <Form.Item name="receivedDate">
                                <DatePicker 
                                    placeholder="Select book date"
                                    onChange={(date, dateString) => handleNewDatePickerChange(date, dateString, 1)} 
                                />
                            </Form.Item>

                            <Form.Item name="สถานะการจอง">
                                <Select
                                    placeholder="สถานะการจอง"
                                    size="middle"
                                    style={{borderRadius: 20, width: '150px', position:'relative'}}
                                    allowClear
                                    options={ statusbooking }
                                    onChange={(e)=> setKeepStatusBooking(e) }
                                />
                            </Form.Item>

                                <Form.Item >
                                    <Button onClick={getData} htmlType="button" type="primary" style={{borderRadius: "5px"}}>
                                        <Icons.SearchOutlined /> Search
                                    </Button>
                                </Form.Item>

                                <Form.Item >
                                    <Button onClick={onReset} htmlType="reset" style={{borderRadius: "5px"}}>
                                        <Icons.UndoOutlined /> Reset
                                    </Button>
                                </Form.Item>
                         
                        </Form>
                    </Col>
                        

                    <Col span={4}>
                        <Form
                            {...formProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Form {...formProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={"N"}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={onStatusChange} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={activeBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={trashBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table
                    dataSource={listData}
                    onChange={handleTableChange}
                    loading = {loadingTable}
                    bordered
                    size="middle"
                    rowKey      = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        hideOnSinglePage: false,
                        current: metaPagination?.page || page,
                        pageSize: metaPagination?.pageSize || pageSizeChange,
                        total: metaPagination?.total,
                        // metaPagination?.pageCount
                        position:["bottomRight"]
                    }}  
                    scroll={{ x: 1300 }}
                >
                    <Table.Column key={'[property][property_name]'} title={t("property.Ltitle")} align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.property?.property_name < b?.property?.property_name) {return -1}
                            if (a?.property?.property_name > b?.property?.property_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <Tooltip placement="top" title={record?.property? record?.property.property_name :"NO DATA"}>
                                    <div style={{width: 150}}><div className="descCut"><TextField value={record?.property? record?.property.property_name :"-"}  /></div></div>
                                </Tooltip>
                            </>
                        }}
                    />

                    <Table.Column key={'[user_profile][first_name]'} title={"FULL NAME "} align="center"  width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.user_profile?.first_name < b?.user_profile?.first_name) {return -1}
                            if (a?.user_profile?.first_name > b?.user_profile?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.user_profile? record?.user_profile.first_name+" "+ record?.user_profile.last_name :"-"}  />
                            </>
                        }}
                    />

                   
                    
                    <Table.Column key={'[unit][unit_name]'} title={"UNIT NUMBER"} align="center" width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.unit?.unit_name < b?.unit?.unit_name) {return -1}
                            if (a?.unit?.unit_name > b?.unit?.unit_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //{console.log("record",record)}
                            return <>
                                <TextField value={record?.unit? record?.unit.unit_name :"-"}  />
                            </>
                        }}
                    />                 
                    
                    <Table.Column key={'[facility_type][name]'} title={t("fclt-type.Ltitle")} align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.facility_type?.name < b?.facility_type?.name) {return -1}
                            if (a?.facility_type?.name > b?.facility_type?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.facility_type? record?.facility_type.name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[facility][name]'} title={"FACILITY NAME"} align="center" width={200}
                        sorter={(a:any, b:any) => {
                            if (a?.facility?.name < b?.facility?.name) {return -1}
                            if (a?.facility?.name > b?.facility?.name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.facility? record?.facility.name :"-"}  />
                            </>
                        }}
                    />


                    <Table.Column 
                        key={'[facility_status_booking]'}
                        title={t("fclt-booking.bookingstat")}  
                        width={100} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.facility_status_booking < b?.facility_status_booking) {return -1}
                            if (a?.facility_status_booking > b?.facility_status_booking) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            //console.log('record x', record?.facility_status_booking)
                            return <>
                                {/* <TextField value={record?.facility_status_booking? record?.facility_status_booking :"-"}  /> */}
                                {


                                    record?.facility_status_booking === "Booking" ?
                                        <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#DCFDD4"}>
                                            <TextField style={{color:"#1D1929"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                        </Tag>
                                    :
                                        record?.facility_status_booking === "Waitlist" ?
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FFB800"}>
                                                <TextField style={{color:"#1D1D1D"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                            </Tag>
                                        :
                                            record?.facility_status_booking === "Cancelled" ?
                                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FDD4D4"}>
                                                    <TextField style={{color:"#AC1616"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                                </Tag>
                                            :
                                                record?.facility_status_booking === "Reschedule" ?
                                                    <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#Reschedule"}>
                                                        <TextField style={{color:"#1D1D1D"}} value={record?.facility_status_booking? record?.facility_status_booking :"-"}  />
                                                    </Tag>
                                                :
                                                <TextField value={'-'}  />

                                }
                            </>
                        }}
                    />

                    <Table.Column 
                        key={'[facility_status_checkin]'}
                        dataIndex="facility_status_checkin" 
                        title={t("fclt-booking.checkinstat")}  
                        width={100} align="center"

                        sorter={(a, b) => a.facility_status_checkin?.localeCompare(b.facility_status_checkin)}
                        sortDirections={['ascend', 'descend']}

                        render={(value:any,record:any)=>{
                            // console.log('facility_status_checkin', record)
                            return <>
                                {/* <TextField value={record?.facility_status_checkin? record?.facility_status_checkin :"-"}  /> */}
                                {
                                    // todayDate > record.date ?
                                    //     <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FDD4D4"}>
                                    //         <TextField style={{color:"#AC1616"}} value={"No check in"}  />
                                    //     </Tag>
                                    // :
                                    //     <TextField value={'-'}  />

                                    record.facility.checkin !== null?
                                        record?.facility_status_checkin === "Checked in" ?
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#DCFDD4"}>
                                                <TextField style={{color:"#1D1929"}} value={record?.facility_status_checkin? record?.facility_status_checkin :"-"}  />
                                            </Tag>
                                        :
                                            // record?.facility_status_checkin === "No Check in" ?
                                            //     <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FDD4D4"}>
                                            //         <TextField style={{color:"#AC1616"}} value={record?.facility_status_checkin? record?.facility_status_checkin :"-"}  />
                                            //     </Tag>
                                            // :
                                                record?.facility_status_checkin === "Late Check in" ?
                                                    <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FFF8B7"}>
                                                        <TextField style={{color:"#FF0000"}} value={record?.facility_status_checkin? record?.facility_status_checkin :"-"}  />
                                                    </Tag>
                                                :
                                                    todayDate > record.date ?
                                                        <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"#FDD4D4"}>
                                                            <TextField style={{color:"#AC1616"}} value={"No check in"}  />
                                                        </Tag>
                                                    :
                                                        <TextField value={'-'}  />
                                    : <>-</>
                                }
                            </>
                        }}
                    />


                    <Table.Column key={'[max_user]'} title={t("fclt-booking.bookingcount")}  width={80} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.max_user < b?.max_user) {return -1}
                            if (a?.max_user > b?.max_user) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={record?.max_user? record?.max_user :"NO DATA"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[date]'} title={"BOOK DATE"} width={150} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.date < b?.date) {return -1}
                            if (a?.date > b?.date) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.time? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>{record.time}</Tag>:<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>NO DATA</Tag> }/> */}
                                <TextField style={{fontWeight: 600}} 
                                    value={ 
                                        record.facility_time_slot? 
                                            <>
                                                <TextField style={{fontWeight: 600}} value={record?.date? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>{Moment(record.date).format('DD MMMM YYYY')}</Tag>:<Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>NO DATA</Tag> }/> <br/> 
                                               
                                            </>                                            
                                             
                                        :
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>NO DATA</Tag> 
                                    }
                                />
                            </>
                        }}
                    />

                    <Table.Column key={'[facility_time_slot][time_start]'} title={"FACILITY BOOKING"} width={150} align="center"
                        sorter={(a:any, b:any) => {
                            if (a?.facility_time_slot?.time_start < b?.facility_time_slot?.time_start) {return -1}
                            if (a?.facility_time_slot?.time_start > b?.facility_time_slot?.time_start) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} 
                                    value={ 
                                        record.facility_time_slot? 
                                            <>
                                                 
                                                <Tag style={{borderRadius: "30px",padding: "1% 7% 1%",marginTop: "5%"}} color="#D1D1D1">{Moment(record.facility_time_slot.time_start, "HH:mm").format('HH:mm') + ' - ' + Moment(record.facility_time_slot.time_end, "HH:mm").format('HH:mm')}</Tag>
                                            </>                                            
                                             
                                        :
                                            <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>NO DATA</Tag> 
                                    }
                                />
                            </>
                        }}
                    />

                    <Table.Column 
                        key={'[createBy][first_name]'}
                        title="CREATE BY" 
                        align="center" 
                        width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[createdAt]'} title="CREATE AT" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            // if (a?.createdAt < b?.createdAt) {return -1}
                            // if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />

                    <Table.Column 
                        key={'[updateBy][first_name]'}
                        title="UPDATE BY" 
                        align="center" 
                        width={150}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[updatedAt]'} title="LAST UPDATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            // if (a?.updatedAt < b?.updatedAt) {return -1}
                            // if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                    />


                    <Table.Column 
                        dataIndex="facility_status_admin" 
                        title={"ACKNOWLEDGE"}  
                        width={120} align="center"
                        render={(value:any,record:any)=>{
                            return <>
                               
                                {
                                    record.facility_status_admin === "Acknowledged" ? 
                                    <>
                                        <CheckCircleOutlined style={{color:'#00CD15',fontSize:24}}  />
                                    </>
                                    :
                                    <>
                                        {/* grey */}
                                        <Tooltip placement="rightTop" title="Acknowledge">
                                            {data_permission?.update ? data_permission?.update == false ? 
                                            <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24, cursor: "not-allowed"}} /> 
                                            : <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { showAcknowledge(record.id) }}/>
                                            : <CheckCircleOutlined style={{color:'#A8A8A8',fontSize:24, cursor: "not-allowed"}} /> }
                                        </Tooltip>
                                    </>
                                }
                            </>
                        }}
                    />

                    <Table.Column<IFacilityBooking>
                        width={150}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                            {
                                record.facility_status_booking === "Cancelled" ? 
                                <>
                                    <CloseCircleOutlined style={{color:'#FF0000',fontSize:24}}/>
                                </>
                                :
                                <>
                                    {/* grey */}
                                    <Tooltip placement="rightTop" title="Cancel Booking">
                                        <CloseCircleOutlined style={{color:'#A8A8A8',fontSize:24}}  onClick={() => { CancelBooking(record.id) }} />
                                    </Tooltip>
                                </>
                            }

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                >รายละเอียด</ShowButton>

                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>

            {/*  */}
        </>
}