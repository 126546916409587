import { Breadcrumb, Button, Col, Icons, List, Row, Tabs } from "@pankod/refine-antd"
import { IResourceComponentsProps, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import type { TabsProps } from 'antd';
import { useState } from "react";
import { PetVaccAllList } from "./all";
import { PetVaccRemindList } from "./remind";


export const PetVaccList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TabPane } = Tabs;
    const { create, edit } = useNavigation();

    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Facility");

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [tabm, settabm] = useState<any>("all");
    
    return (
        <List 
            title={t("pet-vacc-list.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("pet-service.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-vacc-list.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Row gutter={6}>
                <Col className="gutter-row" span={24}>
                    <Tabs 
                        defaultActiveKey={"type"}
                        onChange={(e)=> settabm(e)}
                    >
                        <TabPane
                            key={"all"}
                            tab={<span>{t("pet-vacc-list.all")}</span>}
                        />
                        <TabPane
                            key={"remind"}
                            tab={<span>{t("pet-vacc-list.remind")}</span>}
                        />
                    </Tabs>
                </Col>
            </Row>

            {
                tabm == "all" ? <PetVaccAllList /> : <PetVaccRemindList />
            }
        </List>
    )
}