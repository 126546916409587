/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { MjcDone } from "./done";
import { MjcInProgress } from "./in_progress";
import { MjcNewJob } from "./new_job";
import { MjcWaiting } from "./waiting";
// import { HcuChecklistList } from "./checklist";
// import { HcuStructureList } from "./structure";
// import { HcuZoneList } from "./zone";
// import { HcuNotiMsgList } from "./notification";
// import { HcuTermConditionList } from "./term_and_condition";
// import { HcuTemplateList } from "./template_master";
// import { HcuDaysList } from "./setup_days";
// import { HcuAssignList } from "./assign_inspec";
import { majorCareUrl } from "../../constants";
import axios from "axios";



export const HcuRepairList: React.FC<IResourceComponentsProps> = () => {
    // const tabActive = localStorage.getItem("keyTabHCUMaster");
    const [ Tab, SetTab] = useState<any>();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TabPane } = Tabs;
    const tabJobActive = localStorage.getItem("keyTabJob");

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabJob", v)
    }
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const [countNewJob, setCountNewJob] = useState<any>(undefined);
    const [countInprogressJob, setCountInProgressJob] = useState<any>(undefined);
    const [countWaitAppJob, setCountWaitAppJob] = useState<any>(undefined);
    const [countDoneJob, setCountDoneJob] = useState<any>(undefined);


    const getDataK = async ()=>{

        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findHomecheckjobByStat`, 
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                if(prop_list){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.request_by?.unitDetail?.property_id == Number(prop_id))
                }

                setCountNewJob(dataLoad.filter((e:any) => (e.status === "6485ba66049e59df2850eb9e" )));

                setCountWaitAppJob(dataLoad.filter((e:any)=>  e.status == "6485bd96681aef755a4f27a1" || e.status == "6485bcda049e59df2850eba0" || e.status == "6485bd8b681aef755a4f279f" ));

                setCountInProgressJob(dataLoad.filter((e:any)=> e.status == "6485bcf9049e59df2850eba2" || e.status == "6485bd3a681aef755a4f2797" || e.status == "6485bd56681aef755a4f2799" || e.status == "6485bd6e681aef755a4f279b" ));

                setCountDoneJob(dataLoad.filter((e:any)=> e.status == "6485bd7d681aef755a4f279d" || e.status == "6485bd96681aef755a4f27a1" ));

            });
    }

    useEffect(() => {
        getDataK();
    }, []);

    return <>
        <List 
            title={t("hcujob.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcujob.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabJobActive ? tabJobActive + "" : "NewJob"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >
                        <TabPane
                            key={"NewJob"}
                            tab={
                                <span>
                                    {"รายการใหม่"}
                                    <Badge showZero count={countNewJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>

                                </span>
                            }
                        >
                            <MjcNewJob/>
                        </TabPane>
                        <TabPane
                            key={"Waiting"}
                            tab={
                                <span>
                                    {"รอนัดหมาย"}
                                    <Badge showZero count={countWaitAppJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>

                                </span>
                            }
                        >
                            <MjcWaiting />
                        </TabPane>

                        <TabPane
                            key={"InProgress"}
                            tab={
                                <span>
                                    {"ระหว่างดำเนินการ"}
                                    <Badge showZero count={countInprogressJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>

                                </span>
                            }
                        >
                            <MjcInProgress/>
                        </TabPane>

                        <TabPane
                            key={"Done"}
                            tab={
                                <span>
                                    {"เสร็จสิ้น"}
                                    <Badge showZero count={countDoneJob?.length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>

                                </span>
                            }
                        >
                            <MjcDone />
                        </TabPane>

                    </Tabs>
                </Form.Item>
            </Form>
           
        </List>

        
    </>
}