import React from "react";
import routerProvider from "@pankod/refine-react-router-v6";
import { TitleProps } from "@pankod/refine-core";
import { TextField } from "@pankod/refine-antd";

const { Link } = routerProvider;

export const Title: React.FC<TitleProps> = ({ collapsed }) => (
  <Link to="/">
    {collapsed ? (
      <img
        src={"/images/logo_news.svg"}
        alt="LifeScape"
        style={{
          width: "50px",
          padding: "12px 10px",
        }}
      />
    ) : (
      <img
        src={"/images/logo_news.svg"}
        alt="LifeScape"
        style={{
          width: "100px",
          padding: "12px 10px",
        }}
      />
    )}
  </Link>
);
