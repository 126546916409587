/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, TableProps, useSimpleList } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
// import { TextArea } from "react-mde";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { TOKEN_KEY } from "../../../constants";


let swLocal : boolean = true;
let dataonFilter: any = [];
let newDataInfor:any
let newDataTac:any
export const RepairTermConditionList: React.FC<IResourceComponentsProps> = () => {
    const [ Tab, SetTab] = useState<any>();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const tabTermActive = localStorage.getItem("keyTabTermRepair");
    
    const { TabPane } = Tabs;
    const API_URL = useApiUrl();
    const { create, edit } = useNavigation();
    const { confirm } = Modal;
    const token = localStorage.getItem(TOKEN_KEY);
    const [form] = Form.useForm();

    const vCurDelStatus = {deleteStatus : 'Y'}
    const [dataList, setDataList] = useState<any>([]);
    const [dataBlank, setDataBlank] = useState<any>([]);
    const currentLocale = localStorage.getItem("CURRENT_LOCALE");

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "repair-settings",
        metaData: {
            // populate: [
            //     '*',
            // ]
            populate: ['*', 'rp_setting', 'rp_setting.createBy', 'rp_setting.updateBy'],
        },
        pagination:{
            pageSize: 1000,
        },
        permanentFilter: [
            {
                field: "property][id]",
                operator: prop_list ? "in" : "eq",
                value: prop_list ? prop_list: prop_id,
                // value: prop_id,
            }
        ],
        queryOptions: {
          onSuccess: (data:any) => {
            let dataLoad: any = data?.data;


            // if(prop_id){
            //     dataLoad = dataLoad.filter((e:any)=> e.property?.id == Number(prop_id))
            // }
            if(prop_list !== undefined){
                console.log('case 1')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property?.id));
            }else if(prop_id !== null){
                console.log('case 2')
                dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            }

            //console.log('dataLoad after', dataLoad)

            for (let index = 0; index < dataLoad?.length; index++) {
                // console.log('dataload loop', dataLoad[index])
                dataonFilter = dataLoad[index]?.rp_setting?.filter((e:any)=> e.repair_type?.name == "TAC" || e.repair_type?.name == "Information" )

                // if(prop_id !== null){
                //     dataonFilter = dataLoad[index]?.property.filter((e:any)=> e.id == Number(prop_id))
                // }

                dataonFilter.forEach((obj:any) => {
                    obj.property_name = dataLoad[index].property.property_name;
                    obj.property_id = dataLoad[index].property.id;
                    obj.record_id = dataLoad[index].id;
                });
                dataList.push(dataonFilter)
            }
            // SetTab(tabTermActive)

            setTimeout(() => {
                checkDataList();
            }, 1000);

            SetTab(tabTermActive ? tabTermActive : "Information")

          },
        },
    });

    //console.log('dataList', dataList)

    useEffect(() => {
        refetch();
    }, []);

    const [DataTac, SetDataTac] = useState<any>([]);
    const [DataInfor, SetDataInfor] = useState<any>([]);

    // let DataInfor:any;
    // let DataTac:any;
    // if(dataList.length > 0){
    //     const flattenedArray = dataList.reduce((accumulator:any, currentArray:any) => {
    //         return accumulator.concat(currentArray);
    //     }, []);

    //     dataonFilter = flattenedArray

    //     // DataTac = flattenedArray?.filter((e:any)=> e.repair_type?.name == "TAC")
    //     // DataInfor = flattenedArray?.filter((e:any)=> e.repair_type?.name == "Information")
    //     SetDataTac(flattenedArray?.filter((e:any)=> e.repair_type?.name == "TAC"))
    //     SetDataInfor(flattenedArray?.filter((e:any)=> e.repair_type?.name == "Information"))

    //     console.log('DataInfor', DataInfor)
    //     console.log('DataTac', DataTac)
    // }
    const checkDataList = () =>{
        if(dataList.length > 0){
            const flattenedArray = dataList.reduce((accumulator:any, currentArray:any) => {
                return accumulator.concat(currentArray);
            }, []);
    
            dataonFilter = flattenedArray
            // DataTac = flattenedArray?.filter((e:any)=> e.repair_type?.name == "TAC")
            // DataInfor = flattenedArray?.filter((e:any)=> e.repair_type?.name == "Information")
            newDataTac = flattenedArray?.filter((e:any)=> e.repair_type?.name == "TAC")
            SetDataTac(newDataTac)
            // SetDataTac(flattenedArray?.filter((e:any)=> e.repair_type?.name == "TAC"))
            newDataInfor = flattenedArray?.filter((e:any)=> e.repair_type?.name == "Information")
            SetDataInfor(newDataInfor)
            //SetDataInfor(flattenedArray?.filter((e:any)=> e.repair_type?.name == "Information"))
            
            // console.log('DataInfor', DataInfor)
            // console.log('DataTac', DataTac)
    
        }
    }    

    async function updateStatus(id:any, property_id:any, stat:any){
        // mutate({
        //     resource    : ed_point,
        //     id          : id+"",
        //     values      : {
        //         deleteStatus    : vCurDelStatus.deleteStatus
        //     },
        //     errorNotification:false,
        //     successNotification:false
        // });

        // console.log('id', id)
        // console.log('property_id', property_id)
        // console.log('stat', stat)

        let data = { 
            "data":{
                "property": property_id,
                "rp_setting": [
                    {
                        "id": Number(id),
                        "deleteStatus": stat,
                    }
                ]
            }
        }
        // console.log('data onFin', data)
        let res = await axios.post(API_URL + "/repair-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        refreshDataSource()
    }

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        // setCountInterval(0)
        refetch();
        // setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, property_id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, property_id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabTermRepair", v)
    }

    const columns: any = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },
        {
            title: 'Property',
            dataIndex: 'property',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.property_name.localeCompare(b.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.property_name !== null ? record.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'TOPIC',
            dataIndex: 'topic',
            key: 'topic',
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.tac_th.localeCompare(b.tac_th),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.tac_th !== null ? record.tac_th : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: 'CREATED BY',
            // dataIndex: 'createBy',
            key: 'createBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.createBy?.first_name.localeCompare(b.createBy?.first_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                    {/* <div >{record.createBy !== null ? 'xxxx' : "-"} {record.createBy !== null ? 'yyy' : ""}</div> */}
                </>
            },
        },
        {
            title: 'CREATED DATE',
            // dataIndex: 'create_date',
            key: 'create_date',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'UPDATE BY',
            // dataIndex: 'updateBy',
            key: 'updateBy',
            width: 150,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.updateBy?.first_name.localeCompare(b.updateBy?.first_name),
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                    
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'Last Update',
            // dataIndex: 'update_date',
            key: 'update_date',
            width: 150,
            align: 'center',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: 'STATUS',
            // dataIndex: 'updateBy',
            key: 'deleteStatus',
            width: 100,
            align: 'center',
            sorter: (a:any, b:any) => a.deleteStatus.localeCompare(b.deleteStatus),
            render: (value:any, record:any) => {
                return <>
                    {
                         record.deleteStatus === "N" ? 
                            <Tag style={{borderRadius: "10px"}} color={'green'}>ACTIVE</Tag>
                         :
                            <Tag style={{borderRadius: "10px"}} color={'red'}>INACTIVE</Tag>
                    }
                </>
            },

        },
        {
            title: 'DOWNLOAD',
            dataIndex: 'dl',
            key: 'dl',
            width: 110,
            align: 'center',
            //sorter: (a:any, b:any) => a.createBy.first_name.toString().localeCompare(b.createBy.first_name.toString()),
            //sorter: (a:any, b:any) => a.seq.toString().localeCompare(b.seq.toString()),
            render: (createBy:any, record:any) => {
                return (
                    <>
                        {currentLocale == "en" ?
                            Tab == "Information" ?
                                // <a href={record.tac_infomation_en[0].url} ={record.tac_infomation_en[0].name} target='_blank'>
                                // <a href={record.tac_infomation_en.length > 0 ? record.tac_infomation_en[0].url : ''} download={record.tac_infomation_en.length > 0 ? record.tac_infomation_en.name : ''} target='_blank'>
                                <a href={record.tac_infomation_en ? record.tac_infomation_en[0].url : ''} download={record.tac_infomation_en ? record.tac_infomation_en.name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                            :
                                // <a href={record.tac_content_en[0].url} download={record.tac_content_en[0].name} target='_blank'>
                                // <a href={record.tac_content_en.length > 0 ? record.tac_content_en[0].url : ''} download={record.tac_content_en.length > 0 ? record.tac_content_en.name : ''} target='_blank'>
                                <a href={record.tac_content_en ? record.tac_content_en[0].url : ''} download={record.tac_content_en ? record.tac_content_en.name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                        :
                                Tab == "Information" ?
                                // <a href={record.tac_infomation_th[0].url} download={record.tac_infomation_th[0].name} target='_blank'>
                                // <a href={record.tac_infomation_th.length > 0 ? record.tac_infomation_th[0].url : ''} download={record.tac_infomation_th.length > 0 ? record.tac_infomation_th.name : ''} target='_blank'>
                                <a href={record.tac_infomation_th ? record.tac_infomation_th[0].url : ''} download={record.tac_infomation_th ? record.tac_infomation_th.name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                            :
                                // <a href={record.tac_content_th[0].url} download={record.tac_content_th[0].name} target='_blank'>
                                // <a href={record.tac_content_th.length > 0 ? record.tac_content_th[0].url : ''} download={record.tac_content_th.length > 0 ? record.tac_content_th[0].name : ''} target='_blank'>
                                <a href={record.tac_content_th ? record.tac_content_th[0].url : ''} download={record.tac_content_th ? record.tac_content_th[0].name : ''} target='_blank'>
                                    <Button icon={<Icons.DownloadOutlined />}></Button>
                                </a>
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            width: 100,
            align: 'center',
            fixed: 'right',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        {/* <EditButton
                            hideText
                            size="small"
                            // onClick={()=> editModalShow(record.id)}
                            onClick={() => edit("repair-tnc", record.property_id + "_" + record.id )}
                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                            // disabled={false }
                            style={{
                                 marginRight:'4px'
                            }}
                        /> */}
                      {
                            record.deleteStatus === "N" ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        // onClick={()=> editModalShow(record.id)}
                                        onClick={() => edit("repair-tnc", record.property_id + "_" + record.id )}
                                        style={{
                                            marginRight:'4px'
                                        }}
                                    />
                                    <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record.id, record.property_id ) }} 
                                        danger
                                    ></Button>
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record.id, record.property_id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        //console.log('params', pagination, filters, sorter, extra);
    };

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';


    const [selProp, setselProp] = useState<any>();
    const [nameSearch, setnameSearch] = useState<any>("");

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: !!prop_list || !!prop_id ? [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : prop_list ? "in" : "eq",
                value : prop_list ? prop_list : prop_id
            },
        ]:[
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
        ]
    });

    function onSearchtable(data: any, mode:any, type:any){
        // let newData:any = data;
        if(type == "TAC"){
            console.log('tac')
            // SEARCH TERM
            let newData:any = [];
            if(mode == "S"){
                // newData = nameSearch !== "" ? dataonFilter?.filter((e:any)=> e?.email?.includes(String(nameSearch))):newData; // search email
                // newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property_id.includes(String(selProp?.id))}):newData;
                newData = selProp !== undefined ? DataTac?.filter((e:any)=> {return e?.property_id == selProp?.id}) : newData;
                // console.log('newData', newData)
                SetDataTac(newData);                
                //console.log('DataTac',DataTac)
            }else if(mode == "R"){
                form?.resetFields();
                clearDataSource()
                // refetch();
                SetDataTac(newDataTac)
                setselProp(undefined);
                // setselUnitType(undefined);
                setnameSearch("");
            }
        }else{
            console.log('infor')
            // SEARCH INFORMATION
            let newData:any = [];
            if(mode == "S"){
                // newData = nameSearch !== "" ? dataonFilter?.filter((e:any)=> e?.email?.includes(String(nameSearch))):newData;
                // newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property_id.includes(String(selProp?.id))}):newData;
                newData = selProp !== undefined ? DataInfor?.filter((e:any)=> {return e?.property_id == selProp?.id}) : newData;
                console.log('newData', newData)
                // setDataList((pre: any) => newData);
                // setDataList(newData);
                SetDataInfor(newData);
            }else if(mode == "R"){
                // setDataList((pre: any) => newData);
                form?.resetFields();
                clearDataSource()
                SetDataInfor(newDataInfor)
                //refetch();
                setselProp(undefined);
                // setselUnitType(undefined);
                setnameSearch("");
            }
        }
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    return <>
        <List 
            // title={t("mjctermandcon.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjctermandcon.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >
            {/* <Row gutter={12}>
                <Col span={2}>
                    <Form
                        // {...searchFormProps}
                        layout = "inline"
                        style={{marginBottom:"1%",float: "right"}}
                    >
                        <Form.Item style={{marginRight: "0",}}>
                            <CreateButton 
                                id="create_btn" 
                                onClick={()=>create("repair-tnc")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row> */}

            <Row gutter={12}>
                    <Col span={22}>
                        <Form layout="vertical" 
                            // {...formProps} 
                            onFinish={(values:any) => {}}
                            form = {form} 
                        >
                            <Row gutter={20} style={{width: "100%"}}>
                                
                                <Col span={4} className="gutter-row">
                                    <Form.Item name="property" label="Property">
                                        <Select placeholder={t("select.sel")} 
                                            {...selProperty} 
                                            onChange={(value: any, label: any) => {
                                                if(value!== undefined){
                                                    setselProp({id:value, label: label?.children})
                                                }else{
                                                    setselProp(undefined)
                                                }
                                            }} 
                                            allowClear
                                        >
                                        </Select>

                                    </Form.Item>
                                </Col>
                                <Col span={5} className="gutter-row">
                                    <Row gutter={24}>
                                        <Col span={12} className="gutter-row">
                                            <Form.Item label=" ">
                                                <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S", tabTermActive)}>
                                                    <Icons.SearchOutlined /> Search
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12} className="gutter-row">
                                            <Form.Item label=" ">
                                                <Button 
                                                    onClick={() => {
                                                        // onSearchtable(dataList,"R")
                                                        onSearchtable(dataList,"R", tabTermActive)
                                                    }} 
                                                    style={{borderRadius: "5px"}}
                                                    icon={<Icons.UndoOutlined />}
                                                >
                                                    Reset
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col span={2}>
                        <Form.Item style={{marginRight: "0",}}>
                        <CreateButton 
                                id="create_btn" 
                                onClick={()=>create("repair-tnc")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Col>
                </Row>

            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabTermActive ? tabTermActive + "" : "Information"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >
                        <TabPane
                            key={"Information"}
                            tab={
                                <span>
                                    {"Informations"}
                                </span>
                            }
                        />
                        <TabPane
                            key={"TAC"}
                            tab={
                                <span>
                                    {"Term & conditions"}
                                </span>
                            }
                        />
                    </Tabs>
                </Form.Item>
            </Form>

            <div>
                {/* {
                    Tab == "Information" ?
                        <>
                            <Table 
                                // dataSource={filteredDataList.length > 0 ? filteredDataList : DataInfor} 
                                dataSource={DataInfor ? DataInfor.reverse() : dataBlank} 
                                columns={columns} 
                                onChange={onChange} 
                                // pagination={{ size: "small", position: [ bottom] }} 
                                pagination={{ size: "small", position: [ "bottomRight"] }} 
                            />
                        </>
                    :
                        <>
                            <Table 
                                dataSource={DataTac ? DataTac.reverse() : dataBlank} 
                                columns={columns} 
                                onChange={onChange} 
                                // pagination={{ size: "small", position: [ bottom] }} 
                                pagination={{ size: "small", position: [ "bottomRight"] }} 
                            />
                        </>
                } */}

                {
                    Tab == "Information" &&
                    <>
                        <Table 
                            // dataSource={filteredDataList.length > 0 ? filteredDataList : DataInfor} 
                            dataSource={DataInfor ? DataInfor.reverse() : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ "bottomRight"] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 1500 }}
                        />
                    </>
                }

                {
                    Tab == "TAC" &&
                    <>
                        <Table 
                            dataSource={DataTac ? DataTac.reverse() : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ "bottomRight"] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 1500 }}
                        />
                    </>
                }
            </div>
            

        </List>

    
    </>
}