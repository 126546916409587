/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Tabs, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, TableProps, useSimpleList, useForm } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, IEventlog } from "interfaces";
import Moment from 'moment';
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import type { ColumnsType } from 'antd/es/table';
// import { TextArea } from "react-mde";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import { TOKEN_KEY } from "../../../constants";


let swLocal : boolean = true;
let dataonFilter: any = [];

export const HcuTermConditionList: React.FC<IResourceComponentsProps> = () => {
    const [ Tab, SetTab] = useState<any>();

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const tabTermActive = localStorage.getItem("keyTabTerm");
    const token = localStorage.getItem(TOKEN_KEY);

    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    
    const { TabPane } = Tabs;
    const API_URL = useApiUrl();

    const { create, edit } = useNavigation();
    
    const { confirm } = Modal;
    const { mutate } = useUpdate<ICoupon>();
    // const ed_point = "home-check-up-msgs";
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}
    const [dataList, setDataList] = useState<any>([]);

    const { formProps } = useForm<any,HttpError,{}>()
    const [nameSearch, setnameSearch] = useState<any>("");
    const [selProp, setselProp] = useState<any>();
    const [dataBlank, setDataBlank] = useState<any>([]);

    const [searchValue, setSearchValue] = useState('');

    const currentLocale = localStorage.getItem("CURRENT_LOCALE");
    // console.log('currentLocale', currentLocale)

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                        method: "post",
                        body: body
                        // mode: "no-cors"
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                            default: res[0].url
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    // const { ...uploadProps} = useStrapiUpload({ maxCount: 1, });

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "home-check-up-settings",
        metaData: {
            // populate: [
            //     '*',
            // ]
            populate: ['*', 'hcu_setting', 'hcu_setting.createBy', 'hcu_setting.updateBy'],

        },
        permanentFilter:[
            {
                field : "property][id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            }
        ],
        pagination:{
            pageSize: 1000,
        },
        queryOptions: {
          onSuccess: (data:any) => {
            // console.log('onSuccess xxxxxxxxxxxxxxxx')
            let dataLoad: any = data?.data;
            // console.log('dataLoad', dataLoad)

            // filter เอาแต่ data ของ property นั้น ๆ
            // if(prop_id !== null){
            //     dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            // }

            if(prop_list !== undefined){
                console.log('case 1')
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.property?.id));
            }else if(prop_id !== null){
                console.log('case 2')
                dataLoad = dataLoad?.filter((e:any)=> e.property?.id == Number(prop_id))
            }

            for (let index = 0; index < dataLoad?.length; index++) {
                // console.log('dataload loop', dataLoad[index])
                dataonFilter = dataLoad[index]?.hcu_setting?.filter((e:any)=> e.home_check_up_type?.name == "TAC" || e.home_check_up_type?.name == "Information" )
                // dataonFilter = dataLoad[index]?.hcu_setting?.filter((e:any)=> e.home_check_up_type?.name == "TAC")
                // console.log('dataonFilter', dataonFilter)
                dataonFilter.forEach((obj:any) => {
                    obj.property_name = dataLoad[index].property.property_name;
                    obj.property_id = dataLoad[index].property.id;
                    obj.record_id = dataLoad[index].id;
                });
                
                dataList.push(dataonFilter)
            }
 
            // SetTab(tabTermActive)
            SetTab(tabTermActive ? tabTermActive : "Information")

          },
        },
    });

    useEffect(() => {
        refetch();
        // console.log('call refetch')
        // console.log('');
    }, []);
    // const { tableProps,searchFormProps,tableQueryResult } = useTable<any, HttpError, any>({
    //     metaData: {
    //         populate: '*',
    //         // populate: ['*', 'localizations','localizations.updateBy','localizations.locale','updateBy','property','property.id','createBy', 'locale','document_sub_type','document_type','localizations.document_type'],
    //         // locale:['th']
    //     },
    //     resource:'home-check-up-settings',
    //     onSearch: (params) => {
    //         const filters: CrudFilters = [];
    //         const {deleteStatus, property} = params;
    //             // console.log('deleteStatus',deleteStatus)
    //             filters.push(
    //             );
    //         return filters;
    //     },
    //     permanentSorter: [
    //         // {
    //         //     field: "id",
    //         //     order: "desc",
    //         // },
    //     ],
    //     queryOptions:{onSuccess(data) {
    //         let dataLoad: any = data?.data;
    //         // console.log(">>> dataLoad",dataLoad)
    //         // console.log('onSuccess', data)

    //         // for (let index = 0; index < dataLoad?.length -1; index++) {
    //         //     console.log('dataload', dataLoad[index])
    //         //     dataonFilter = dataLoad[index]?.hcu_setting?.filter((e:any)=> e.home_check_up_type?.name == "TAC")
    //         //     dataonFilter.forEach((obj:any) => {
    //         //         obj.property_name = dataLoad[index].property.property_name;
    //         //         obj.property_id = dataLoad[index].property.id;
    //         //         obj.record_id = dataLoad[index].id;
    //         //     });
    //         //     dataList.push(dataonFilter)
    //         // }
    //         for (let index = 0; index < dataLoad?.length -1; index++) {
    //             // console.log('dataload', dataLoad[index])
    //             dataonFilter = dataLoad[index]?.hcu_setting?.filter((e:any)=> e.home_check_up_type?.name == "TAC" && e.home_check_up_type?.name == "Information" )
    //             console.log('dataonFilter', dataonFilter)
    //             dataonFilter.forEach((obj:any) => {
    //                 obj.property_name = dataLoad[index].property.property_name;
    //                 obj.property_id = dataLoad[index].property.id;
    //                 obj.record_id = dataLoad[index].id;
    //             });
    //             dataList.push(dataonFilter)
    //         }

    //         SetTab(tabTermActive)


    //     },}
    // });

    // console.log('dataonFilter', dataonFilter)
    // console.log('dataList', dataList)
    let DataInfor:any;
    let DataTac:any;
    if(dataList?.length > 0){
        // const flattenedArray = dataList[0].concat(dataList[1]);
        const flattenedArray = dataList.reduce((accumulator:any, currentArray:any) => {
            return accumulator.concat(currentArray);
        }, []);

        dataonFilter = flattenedArray
        // console.log('flattenedArray', flattenedArray)

        // DataInfor = flattenedArray?.filter((e:any)=> e.seq == 1)
        // DataTac = flattenedArray?.filter((e:any)=> e.seq == 2)

        DataTac = flattenedArray?.filter((e:any)=> e.home_check_up_type?.name == "TAC")
        DataInfor = flattenedArray?.filter((e:any)=> e.home_check_up_type?.name == "Information")

        // console.log('DataInfor', DataInfor)
        // console.log('DataTac', DataTac)

    }

    const { selectProps: selDocType } = useSelect<any>({
        resource    : "document-types",
        optionLabel : "doc_name",
        metaData: {
            populate: '*',
            locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            }
        ]
    });

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator: (prop_list || prop_id) ? "eq" : "nin",
                value: prop_list ? prop_list: prop_id ? prop_id : null,
            },
        ]
    });

    const optionsRdioLocale = [
        { label: 'TH', value: true },
        { label: 'EN', value: false },
    ];

    // useEffect(() => {
    //     // if(DataCus && countl == 0){
    //     //     const fileList: UploadFile[] = 
    //     //         DataCus?.file ? DataCus?.file.map((v:any)=>(
    //     //             {
    //     //                 uid: String(v.id),
    //     //                 name: v.name,
    //     //                 status: "done",
    //     //                 url: v.url,
    //     //                 thumbUrl: v.url
    //     //             }
    //     //         )):[];

    //     //     setFile({
    //     //         name: 'files',
    //     //         multiple: true,
    //     //         defaultFileList: [...fileList],
    //     //     })

    //     //     editProps?.form?.resetFields(["file"]);

    //     //     setcountl(1);
    //     // }
    // }, [editProps])

    function loadLocal(){
        // if(swLocal == true){
        //     editProps?.form?.setFieldsValue({doc_name: DataCus ? DataCus.doc_name : null})

        //     const fileList: UploadFile[] = 
        //         DataCus && DataCus?.file.map((v:any)=>(
        //             {
        //                 uid: String(v.id),
        //                 name: v.name,
        //                 status: "done",
        //                 url:
        //                     v.url,
        //                 thumbUrl:
        //                     v.url
        //             }
        //         ));

        //     setFile({
        //         name: 'files',
        //         multiple: true,
        //         defaultFileList: [...fileList],
        //     })

        //     if(DataCus && DataCus.file !== null){
        //         setFileList((pre:any)=> ([...DataCus.file]));
        //         // setFileList((pre:any)=> ([...pre , ...DataCus.file]));
        //     }else{
        //         setFileList([])
        //     }

        // }else{
        //     editProps?.form?.setFieldsValue({doc_name: DataCus.localizations.length > 0 && DataCus.localizations[0]?.doc_name ?  DataCus.localizations[0]?.doc_name : null})

        //     const fileList: UploadFile[] = 
        //         DataCus.localizations.length > 0 ? DataCus.localizations[0]?.file?.map((v:any)=>(
        //                 {
        //                     uid: String(v.id),
        //                     name: v.name,
        //                     status: "done",
        //                     url:
        //                         v.url,
        //                     thumbUrl:
        //                         v.url
        //                 }
        //             )) : []
          
        //     setFile({
        //         name: 'files',
        //         multiple: true,
        //         defaultFileList: [...fileList],
        //     })

        //     if(DataCus.localizations.length > 0 && DataCus.localizations[0]?.file !== null){
        //         setFileList((pre:any)=> ([...DataCus.localizations[0]?.file]));
        //     }else{
        //         setFileList([])
        //     }
        // }

        // editProps?.form?.resetFields(["file"]);
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }

    async function updateStatus(id:any, property_id:any, stat:any){
        // mutate({
        //     resource    : ed_point,
        //     id          : id+"",
        //     values      : {
        //         deleteStatus    : vCurDelStatus.deleteStatus
        //     },
        //     errorNotification:false,
        //     successNotification:false
        // });

        console.log('id', id)
        console.log('property_id', property_id)
        console.log('stat', stat)

        let data = { 
            "data":{
                "property": property_id,
                "hcu_setting": [
                    {
                        "id": Number(id),
                        "deleteStatus": stat,
                    }
                ]
            }
        }
        // console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        refreshDataSource()
    }

    const [refreshFlag, setRefreshFlag] = useState(false);

    const clearDataSource = () => {
        setDataList([])
    }

    const refreshDataSource = () => {
        clearDataSource()
        // setCountInterval(0)
        refetch();
        setRefreshFlag(!refreshFlag);
    };

    function showDelConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, property_id, "Y");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any, property_id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                // vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, property_id, "N");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function smartTrim(string: string, maxLength: number) {
        if (!string) return string;
        if (maxLength < 1) return string;
        if (string.length <= maxLength) return string;
        if (maxLength == 1) return string.substring(0,1) + '...';
    
        var midpoint = Math.ceil(string.length / 2);
        var toremove = string.length - maxLength;
        var lstrip = Math.ceil(toremove/2);
        var rstrip = toremove - lstrip;

        var totalStr = string.substring(0, midpoint-lstrip) + '...' + string.substring(midpoint+rstrip);

        totalStr = totalStr.replace(/&nbsp;/g, '');

        return totalStr
    }

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabTerm", v)
    }

    const columns: ColumnsType<any> = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        //     key: 'index',
        //     render: (text:any, record:any, index:any) => index + 1,
        //     sorter: (a:any, b:any) => a.index - b.index,
        // },

        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.property")}</span>,
            dataIndex: 'property',
            key: 'property',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: '300px',
            sorter: (a:any, b:any) => a.property_name.localeCompare(b.property_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.property_name !== null ? record.property_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.topic")}</span>,
            dataIndex: 'topic',
            key: 'topic',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: '200px',
            sorter: (a:any, b:any) => a.tac_th.localeCompare(b.tac_th),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <div>{record ? record.tac_th !== null ? record.tac_th : '-': '-'}</div>
                        {/* <Tag className="tag_post"><TextField value={record ? record.property_name !== null ? record.property_name : '-': '-' }  /></Tag> */}

                    </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.create-by")}</span>,
            // dataIndex: 'createBy',
            key: 'createBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: '140px',
            sorter: (a:any, b:any) => a.createBy?.first_name.localeCompare(b.createBy?.first_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                return <>
                    <div >{record.createBy !== null ? record.createBy?.first_name : "-"} </div>
                    {/* <div >{record.createBy !== null ? 'xxxx' : "-"} {record.createBy !== null ? 'yyy' : ""}</div> */}
                </>
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.created-date")}</span>,
            dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            width: '140px',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.update-by")}</span>,
            // dataIndex: 'updateBy',
            key: 'updateBy',
            align: 'center',
            className: "table_colmn_custom_left_value",
            width: '140px',
            sorter: (a:any, b:any) => a.updateBy?.first_name.localeCompare(b.updateBy?.first_name),
            render: (value:any, record:any) => {
                return <>
                    <div >{record?.updateBy !== null ? record?.updateBy?.first_name : "-"} </div>
                </>
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.last-update")}</span>,
            dataIndex: 'update_date',
            key: 'update_date',
            align: 'center',
            width: '140px',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.status")}</span>,
            // dataIndex: 'updateBy',
            align: 'center',
            width: '120px',
            sorter: (a:any, b:any) => a?.deleteStatus.toString().localeCompare(b?.deleteStatus.toString()),
            key: 'deleteStatus',
            render: (value:any, record:any) => {
                return <>
                    {
                         record.deleteStatus === "N" ? 
                            <Tag style={{borderRadius: "10px"}} color={'green'}>{t("status.active")}</Tag>
                         :
                            <Tag style={{borderRadius: "10px"}} color={'red'}>{t("status.inactive")}</Tag>
                    }
                </>
            },

        },

       


        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.term&condition.download")}</span>,
            dataIndex: 'dl',
            key: 'dl',
            fixed: 'right',
            width: 110,
            align: 'center',
            //sorter: (a:any, b:any) => a.createBy.first_name.toString().localeCompare(b.createBy.first_name.toString()),
            //sorter: (a:any, b:any) => a.seq.toString().localeCompare(b.seq.toString()),
            render: (createBy:any, record:any) => {
                return (
                    <>
                        {/* <div >{createBy.first_name ? createBy.first_name : "-"} {createBy.last_name ? createBy.last_name : "-"}</div> */}
 
                        
                        {currentLocale == "en" ?
                            Tab == "Information" ?
                                <>
                                    {
                                        record.tac_infomation_en && 
                                        <a href={record.tac_infomation_en.url} download={record.tac_infomation_en.name} target='_blank'>
                                            <Button icon={<Icons.DownloadOutlined />}></Button>
                                        </a>
                                    }
                                    
                                </>
                            :
                                <>
                                    {
                                        record.tac_content_en && 
                                        <a href={record.tac_content_en.url} download={record.tac_content_en.name} target='_blank'>
                                            <Button icon={<Icons.DownloadOutlined />}></Button>
                                        </a>
                                    }
                                </>
                        :
                                Tab == "Information" ?
                                <>
                                    {
                                        record.tac_infomation_th &&
                                        <a href={record.tac_infomation_th.url} download={record.tac_infomation_th.name} target='_blank'>
                                            <Button icon={<Icons.DownloadOutlined />}></Button>
                                        </a>
                                    }
                                </>
                            :
                                <>
                                    {
                                        record.tac_content_th &&
                                        <a href={record.tac_content_th.url} download={record.tac_content_th.name} target='_blank'>
                                            <Button icon={<Icons.DownloadOutlined />}></Button>
                                        </a>
                                    }
                                </>
                               
                        }
                        {/* <a href={record.tac_content_en.url} download={record.tac_content_en.name} target='_blank'>
                            <Button icon={<Icons.DownloadOutlined />}></Button>
                        </a> */}
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'ACTION',
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        
                        {
                            record.deleteStatus === "N" ? 
                                <>
                                    <EditButton
                                        hideText
                                        size="small"
                                        // onClick={()=> editModalShow(record.id)}
                                        onClick={() => edit("termandcondition", record.property_id + "_" + record.id )}
                                        // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        // disabled={false }
                                        style={{
                                            marginRight:'4px'
                                        }}
                                    />
                                    <Button 
                                        size="small" 
                                        title="Delete" 
                                        icon={<Icons.DeleteOutlined />} 
                                        onClick={() => { showDelConfirm(record.id, record.property_id ) }} 
                                        danger
                                    ></Button>
                                </>
                            :
                                <>
                                    <Button 
                                        size="small" 
                                        title="Active" 
                                        icon={<Icons.RetweetOutlined />} 
                                        onClick={() => { showUnlockConfirm(record.id, record.property_id) }} 
                                    ></Button>
                                </>
                        }
                    </>
                );
            },
        },
    ];

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    // let filteredDataList:any
    // if(DataInfor){
    //     filteredDataList = DataInfor.filter((record:any) => {
    //         // Customize the search condition based on your requirements
    //         return (
    //           record.property?.property_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
    //           record.email?.toLowerCase().includes(searchValue.toLowerCase())
    //         );
    //     });
    // }

    // const filteredDataList = dataList.filter((record:any) => {
    //     // Customize the search condition based on your requirements
    //     return (
    //       record.property?.property_name?.toLowerCase().includes(searchValue.toLowerCase()) ||
    //       record.email?.toLowerCase().includes(searchValue.toLowerCase())
    //     );
    //   });
      

    // console.log('filteredDataList', filteredDataList)
   

    type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';
      
    const [bottom, setBottom] = useState<TablePaginationPosition>('bottomCenter');

    function onSearchtable(data: any, mode:any){
        // let newData:any = data;
        let newData:any = [];
        if(mode == "S"){
            newData = nameSearch !== "" ? dataonFilter?.filter((e:any)=> e?.tac_th?.toLowerCase().includes(String(nameSearch).toLowerCase())):newData;
            // newData = selProp !== undefined ? dataList?.filter((e:any)=> {return e?.property_id.includes(String(selProp?.id))}):newData;
            newData = selProp !== undefined ? dataonFilter?.filter((e:any)=> {return e?.property_id == selProp?.id}) : newData;
            console.log('newData', newData)
            // setDataList((pre: any) => newData);
            setDataList(newData);
        }else if(mode == "R"){
            // setDataList((pre: any) => newData);
            formProps?.form?.resetFields();
            clearDataSource()
            refetch();
            setselProp(undefined);
            // setselUnitType(undefined);
            setnameSearch("");
        }
    }

    const [pageSize, setPageSize] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    const [pageSizeTerm, setPageSizeTerm] = useState(10); // Default page size
    // const [pageSizeInActive, setPageSizeInActive] = useState(10); // Default page size
    const handlePageSizeChangeTerm = (current:any, size:any) => {
        setPageSize(size);
    };


    return <>
        <List 
            // title={t("mjctermandcon.title")}
            title=""
            // pageHeaderProps={{
            //     breadcrumb: <Breadcrumb>
            //         <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
            //         <Breadcrumb.Item><span className="last_breadcrumb">{t("mjctermandcon.title")}</span></Breadcrumb.Item>
            //     </Breadcrumb>,
            //     extra : <></>
            // }}
        >

            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabTermActive ? tabTermActive + "" : "Information"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >
                        <TabPane
                            key={"Information"}
                            tab={
                                <span>
                                    {t("table-header.setup-hcu.term&condition.infomation")}
                                </span>
                            }
                        />
                        <TabPane
                            key={"TAC"}
                            tab={
                                <span>
                                    {t("table-header.setup-hcu.term&condition.title")}
                                </span>
                            }
                        />
                    </Tabs>
                </Form.Item>
            </Form>

            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" style={{marginBottom:"1%"}} {...formProps} onFinish={(values:any) => {}}>
                        <Row gutter={20} style={{width: "100%"}}>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label="Search">
                                    <Input placeholder="Search topic" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} suffix={<Icons.SearchOutlined/>} allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select placeholder={t("select.sel")} 
                                        {...selProperty} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                    </Select>

                                   
                                </Form.Item>
                            </Col>
                            {/* <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label="Search">
                                    <Input placeholder="Search" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} suffix={<Icons.SearchOutlined/>} allowClear/>
                                </Form.Item>
                            </Col> */}
                            {/* <Col span={4} className="gutter-row">
                                <Form.Item name="unit_type" label="Unit Type">
                                    <Select placeholder={t("select.sel")} onChange={(value: any, label: any) => {if(value!== undefined){setselUnitType({id:value, label: label?.children})}else{setselUnitType(undefined)}}} allowClear>
                                        {
                                            dataUnitType?.map((item:any,key:any) => {
                                                return <Option value={item?.id} key={item.id}>{item?.unit_type_name_en}</Option>      
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col> */}
                            <Col span={5} className="gutter-row">
                                <Row gutter={24}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(dataList,"S")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    // onSearchtable(dataList,"R")
                                                    onSearchtable(dataList,"R")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset 
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form layout="vertical" style={{marginBottom:"1%",float: "right"}} {...formProps} onFinish={(values:any) => {}}>
                        <Form.Item style={{marginRight: "0",}} label=" ">
                            <CreateButton id="create_btn" onClick={()=>create("termandcondition")} 
                                // disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }
                            ></CreateButton>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>


            <div>
                {/* {
                    Tab == "Information" ?
                        <>
                            <Table 
                                // dataSource={filteredDataList.length > 0 ? filteredDataList : DataInfor} 
                                dataSource={DataInfor} 
                                columns={columns} 
                                onChange={onChange} 
                                // pagination={{ size: "small", position: [ bottom] }} 
                                pagination={{ size: "small", position: [ "bottomRight"] }} 
                            />

                        </>
                    :
                        <>
                            <Table 
                                dataSource={DataTac} 
                                columns={columns} 
                                onChange={onChange} 
                                // pagination={{ size: "small", position: [ bottom] }} 
                                pagination={{ size: "small", position: [ "bottomRight"] }} 
                            />
                        </>
                } */}
                {
                    Tab == "Information" &&
                    <>
                        <Table 
                            dataSource={DataInfor ? DataInfor.reverse() : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ "bottomRight"] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSize,
                                onChange: handlePageSizeChange,
                            }}
                            scroll={{ x: 1300 }}
                        />
                    </>
                }
                {
                    Tab == "TAC" &&
                    <>
                        <Table 
                            dataSource={DataTac ? DataTac.reverse() : dataBlank} 
                            columns={columns} 
                            onChange={onChange} 
                            // pagination={{ size: "small", position: [ bottom] }} 
                            // pagination={{ size: "small", position: [ "bottomRight"] }} 
                            pagination={{
                                size: "small",
                                position: ['bottomRight'],
                                // showTotal: showTotal, 
                                pageSizeOptions: ['10', '20', '50'], // Optional - Customize the available page sizes
                                showSizeChanger: true, // Optional - Show page size changer dropdown
                                // showQuickJumper: true, // Optional - Show quick jumper input
                                // pageSize: 10, // Optional - Default page size
                                pageSize: pageSizeTerm,
                                onChange: handlePageSizeChangeTerm,
                            }}
                            scroll={{ x: 1300 }}
                        />
                    </>
                }
            </div>
            

        </List>

    
    </>
}