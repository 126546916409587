/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, notification, Space, Edit } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useEffect, useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { TOKEN_KEY, majorCareUrl } from "../../../constants";
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import axios from "axios";
import TextArea from "@uiw/react-md-editor/lib/components/TextArea";

export const RepairFacilityZoneEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const [form] = Form.useForm();

    // const userQuery = useGetIdentity(); // Get User Profile
    // console.log('Identity_data', Identity_data?.user_profile)
    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]


    const user_data:any = {
        id: Identity_data ? Identity_data?.user_profile.id : null,
        first_name: Identity_data ? Identity_data?.user_profile.first_name : null,
        last_name: Identity_data ? Identity_data?.user_profile.last_name : null,
        tel: Identity_data ? Identity_data?.user_profile.tel : null,
        email: Identity_data ? Identity_data?.user_profile.email : null,
        avatar: Identity_data ? Identity_data?.user_profile.avatar : null,
    }

    // console.log('user_data', user_data)



    const t = useTranslate();
    const { replace, list} = useNavigation();

    const [ZoneTh, SetZoneTh] = useState<any>("");
    const [ZoneEn, SetZoneEn] = useState<any>("");
    // const [ZoneDesc, SetZoneDesc] = useState<any>("");
    const [ZoneStat, SetZoneStat] = useState<any>(true);
    const [DataProperty, SetDataProperty] = useState<any>();
    const [PropertyType, SetPropertyType] = useState<any>();
    const [SelectedPropertyType, SetSelectedPropertyType] = useState<any>();
    const [Property, SetProperty] = useState<any>();



    const getDataK = async ()=>{

        // ======================== FIND PROPERTY TYPE ========================================
        // https://api-dev-majorcare.techscape.app/api/majorcare/findAllPropertyType
        let res = await axios.get(majorCareUrl + "/majorcare/findAllPropertyType" , { headers: { } });
        // console.log('res', res)
        let Data = res?.data

        SetDataProperty(Data ? Data : null)

        // ======================== FIND DATA ZONE ========================================
        // https://api-dev-majorcare.techscape.app/api/majorcare/repairZone/649577113b5aa8c99502b24a
        let resDataZone = await axios.get(majorCareUrl + "/majorcare/repairZoneFacility/findOne/" + item_id , { headers: { } });
        console.log('resDataZone', resDataZone?.data)

        SetZoneStat(resDataZone?.data ? resDataZone?.data.is_active : false)
        SetZoneTh(resDataZone?.data ? resDataZone?.data.zone_name : false)
        SetZoneEn(resDataZone?.data ? resDataZone?.data.zone_name_en : false)

        console.log('resDataZone?.data.property_type', resDataZone?.data.property_type)

        // let dataProp = DataProperty?.filter((e:any)=> e._id == resDataZone?.data.property_type);
        console.log('resDataZone?.data.property_type', resDataZone?.data.property_type)
        // resDataZone?.data.property_type?.map((item:any,index:any) => { return PropertyType?.push(item?._id)})

        SetProperty(
            resDataZone?.data.property !== undefined ? 
                resDataZone?.data.property.length > 0 ?
                    resDataZone?.data.property 
                : undefined
            : undefined
        )
        // let newData: any = [];
        // for (let index = 0; index < resDataZone?.data?.property_type.length; index++) {
        //     newData?.push(resDataZone?.data?.property_type[index]?._id)
        // }

        // SetPropertyType((pre:any)=> newData)
        // SetPropertyType(resDataZone ? resDataZone?.data.property_type : null )

        form.setFieldsValue({
            item_name: resDataZone?.data.zone_name,
            item_name_en: resDataZone?.data.zone_name_en,
            status: resDataZone?.data.is_active,
            // property_type: dataProp ? dataProp[0]?.property_type_id : null,
            property:
                resDataZone?.data.property !== undefined ?
                    resDataZone?.data.property.length > 0 ?
                        resDataZone?.data.property.map((v: any) => {
                            // console.log(v)
                            return { value: v.value, label: v.label }
                        })
                        : form.resetFields(["property"])
                    : form.resetFields(["property"]),
        })
    }

    console.log('Property', Property)
    const [countInterval, setCountInterval] = useState(0);

    // useEffect(() => {
    //     if (countInterval < 3) {
    //         const interval = setInterval(() => {
    //             setCountInterval(prev => prev + 1);
    //             // if(countInterval === 1){
    //                 // getDataTrail();
    //                 getDataK();
    //             // }s
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    useEffect(() => {
        getDataK();
    }, [])

    const dataProperty = [
        { id: 1, label: 'บ้าน' },
        { id: 2, label: 'คอนโด' },
        { id: 3, label: 'ทาวน์โฮม' },
        // Add more items as needed
    ];

    const onSelectProp = (e:any, value:any) => {
        // console.log('event', e)
        console.log('value', value)
        SetProperty(value)
    }


    async function createZone(values:any) {
        let dataProp: any = [];
        for (let index = 0; index < PropertyType?.length; index++) {
            let findProp = DataProperty?.find((item:any) => item?._id == PropertyType[index])
            if(findProp){
                dataProp?.push(findProp)
            }
        }

        let data = {
            "zone_name": ZoneTh,
            "zone_name_en": ZoneEn,
            // "item_desc": ZoneDesc,
            // "property_type": dataProp[0], 
            // "property_type": dataProp,
            "property": Property,
            "is_default" : true,
            "is_active" : ZoneStat,
            "update_by": user_data ? user_data : null
        }

        console.log('data onFin', data)

        let res = await axios.patch(majorCareUrl + "/majorcare/repairZoneFacility/" + item_id, data , { headers: { } })
        .then(response => {
            // Handle successful response
            console.log(response.data);
            successNoti();
            replace("/repair-facility/repair-facility-master")
        })
        .catch(error => {
            // Handle error
            console.error('error ',error);
            errorNoti('200', 'success');
        });
    }

    const successNoti = () => {
        notification['success']({
            message: "Successful",
            description:
              'Successfully updated',
        });
    };
 
    const errorNoti = (stat:any, msg:any) => {
        notification.warning({
            message: "This name already exists in the system.",
            duration: 3,
        });
    };

    const { selectProps: SelPropert} = useSelect<any>({
        resource: "properties",
        optionLabel: "property_name",
        optionValue: "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            {
                field : "id]",
                operator : data_property ? "eq" : "nin",
                value : data_property ? data_property : null
            },
        ]
    });
    // const AddPropTypeToArr = (checkedValues: CheckboxValueType[]) => {
    //     let newData: any = [];
    //     for (let index = 0; index < checkedValues.length; index++) {
    //         newData?.push(checkedValues[index])
    //     }

    //     SetPropertyType((pre:any)=> newData)
    // }
    
    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Major แจ้งซ่อม</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-zone.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">Zone Master Create</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                // {...createProps} 
                // {...formProps}
                form = {form} 
                layout="vertical"
                onFinish={(values:any) => {
                    // values.property = values.property.id;
                    createZone(values);
                    // return (
                    //     formProps.onFinish &&
                    //     formProps.onFinish(values)
                    // );
                }}
            >

                <div className="divider-container">
                    <Row gutter={82} style={{borderTop: "1px solid #dedede",borderBottom: "1px solid #dedede"}}>
                        <Col span={12} 
                            className="gutter-row" 
                            style={{
                                // borderRight: "1px solid #dedede",
                                paddingTop: "2.5rem"
                            }}
                        >

                            <Form.Item
                                name="property"
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please enter zone name"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} 
                                    mode="multiple" 
                                    {...SelPropert} 
                                    // onSelect={(e:any, value: any) => {
                                    //     // console.log(value);
                                    //     // setProperty(value);
                                    //     // getUserDetail(value);
                                    //     onSelectProp(e, value);
                                    // }}
                                    onChange={(e:any, value: any) => {
                                        onSelectProp(e, value);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="item_name"
                                label="Zone Name (TH)"
                                rules={[
                                    {
                                        required: true, message: "Please enter zone name"
                                    },
                                ]}
                            >
                                <Input placeholder="zone name th" value={ZoneTh} onChange={(e) => SetZoneTh(e.target.value)} />

                            </Form.Item>

                            <Form.Item
                                // name={["type", "value"]}
                                // name="Inspector"
                                name="item_name_en"
                                label="Zone Name (EN)"
                                rules={[
                                    {
                                        required: true, message: "Please enter zone name"
                                    },
                                ]}
                            >
                                <Input placeholder="zone name en" value={ZoneEn} onChange={(e) => SetZoneEn(e.target.value)} />
                            </Form.Item>

                            {/* <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        label="Property Type"
                                    >
                                        <Checkbox.Group 
                                            style={{ width: '100%' }}
                                            // onChange={AddPropTypeToArr()}
                                            onChange={AddPropTypeToArr}
                                            value={PropertyType}
                                        >
                                            {DataProperty?.map((item:any) => (
                                                <Col span={24} style={{ marginBottom: "2%" }}>
                                                <Checkbox 
                                                    key={item.property_type_id}
                                                    value={item._id}
                                                >
                                                    {item.property_type_name}
                                                </Checkbox>
                                                </Col>
                                            ))}
                                        </Checkbox.Group>
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Row gutter={24} >
                                <Col span={12} >
                                    <Form.Item
                                        // name={["type", "value"]}
                                        // name="Inspector"
                                        name="status"
                                        label="สถานะ"
                                        rules={[
                                            {
                                                required: true, message: "Please select"
                                            },
                                        ]}
                                    >
                                        <Radio.Group
                                            onChange={(e:any) => SetZoneStat(e.target.value)}
                                            // defaultValue={recordData.coupon_condition ? 1 : 2}
                                            // value={vRaCalRight}
                                        >
                                            <Radio value={true} style={{ marginBottom: "0%" }}> ใช้งาน </Radio>
                                            <Radio value={false} style={{ marginBottom: "0%" }}> ไม่ใช้งาน </Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                           
                        </Col>
                    </Row>

                    <Row style={{float: 'right',marginTop: "1.2rem"}}>
                        {/* {swlocal == 'th' && <Checkbox style={{marginRight: '4rem',marginTop: "0.5rem"}} onChange={(e) => setchecked2lan(e?.target?.checked)}>บันทึกทั้ง 2 ภาษา</Checkbox>} */}
                        <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                    </Row>
                </div>
            </Form>
    </Edit>   
    );
};