/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Divider, Switch, Input, Typography, useSelect, InputNumber, Upload, RcFile, message, notification } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { IEventlog, IFacilityBooking} from "interfaces";
import { useState } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_URL, ROCKET_URL, TOKEN_KEY } from "../../../constants";

import axios from "axios";
import { getValueProps, useStrapiUpload } from "@pankod/refine-strapi-v4";

export const HcuTermCreate: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    let data_multi_property = Identity_data?.prop_list?.length > 1 ? Identity_data?.prop_list.map((property:any) => property.id) : null

    const token = localStorage.getItem(TOKEN_KEY);
    const { replace, list} = useNavigation();

    const tabTermActive = localStorage.getItem("keyTabTerm");

    const userQuery = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading} = userQuery;
    const { mutate: CreateLog } = useCreate<IEventlog>();
    // const { mutate: create } = useCreate<any>();
    const { TextArea } = Input;
    const { Text } = Typography;
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { Option } = Select;
    const { confirm } = Modal;
    // const { mutate: CreateBookingCaseWaiting } = useCreate<IFacilityBooking>();
    const log_endpoint = "even-logs";
    const [text, setText] = useState('');
    const [vTacTh, setTacTh] = useState<any>();
    const [vTacEn, setTacEn] = useState<any>();
    const [vInfoTh, setInfoTh] = useState<any>();
    const [vInfoEn, setInfoEn] = useState<any>();



    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                    const body = new FormData();
                    loader.file.then((file: string | Blob) => {
                        body.append("files", file);
                        fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                        method: "post",
                        body: body
                        // mode: "no-cors"
                        })
                        .then((res) => res.json())
                        .then((res) => {
                            resolve({
                            default: res[0].url
                            });
                        })
                        .catch((err) => {
                            reject(err);
                        });
                    });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    const { form, formProps, saveButtonProps } = useForm<IFacilityBooking,HttpError,{}>({
        onMutationSuccess(data, variables, context) {
            let recordData:any = []
            recordData = data?.data? data?.data : [];
            CreateLog({
                resource : log_endpoint,
                values : {
                    user_profile            : user_data.user_profile.id,
                    log_time_stamp          : Date.now(),
                    log_event               : "Create",
                    menu_event              : 'hcu-settings',
                    SysPkID                 : recordData.data.id + ""
                },
                successNotification : false
            });
        },
        errorNotification(error:any, values:any, resource:any) {
            return {
                message: error.toString(),
                type: "error" ,
                undoableTimeout: 1000
            }
        },
        onMutationError(error, variables, context) {
            console.log('error', error)
            console.log('variables', variables)
            console.log('context', context)
        },
    });

    const handleChange = (e:any) => {
        setText(e.target.value);
    };

    const { selectProps: selProperty } = useSelect<any>({
        resource    : "properties",
        optionLabel : "property_name",
        metaData: {
            populate: '*',
            // locale:['th']
        },
        optionValue : "id",
        fetchSize: 1000,
        filters: [
            {
                field: "deleteStatus",
                operator: "eq",
                value: "N"
            },
            // {
            //     field : "id]",
            //     operator : data_property ? "eq" : "nin",
            //     value : data_property ? data_property : null
            // },
            {
                field : "id]",
                operator : data_multi_property?.length > 0 ? "in" : data_property ? "eq" : "nin",
                value : data_multi_property?.length > 0 ? data_multi_property : data_property ? data_property : null
            },
        ]
    });

    function fileChange(e:any, type:any){
        console.log('e', e)
        console.log('type', type)
        if(tabTermActive == "TAC"){
            console.log('file term')
            if(type == "en"){
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setTacEn(res[0].id)
                }
            }else{
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setTacTh(res[0].id)
                }
            }
        }else{
            console.log('file infor')
            if(type == "en"){
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setInfoEn(res[0].id)
                }
            }else{
                if(e.file.response){
                    let res = e.file.response;
                    console.log(res)
                    setInfoTh(res[0].id)
                }
            }
        }
        
    }

    const { ...uploadProps } = useStrapiUpload(
        {
            maxCount: 1,
        }
    );
    
    const successNoti = (stat:any, msg:any) => {
        // notification.success({
        //     // message: stat + ' : ' + msg.TH,
        //     message: msg,
        //     // description: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description:
              'Successfully create term and condition',
        });
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'application/pdf';
        if (!isJpgOrPng) {
          message.error('You can only upload PDF file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M || Upload.LIST_IGNORE;
    };
    
    async function createTerm(values:any) {
        // console.log('values',values)
        let data = { 
            "data":{
                "property": values.property,
                "hcu_setting": [
                    {
                        // "seq": 2, // tac seq = 2
                        "seq": 1, // tac seq = 2
                        "home_check_up_type":{
                            "name": "TAC"
                        },
                        "tac_th": values.topic, // tac_th ใช้กับ tac
                        "tac_content_th": vTacTh,
                        "deleteStatus": "N",
                        "tac_content_en": vTacEn,
                        "createBy" : Identity_data ? Identity_data.user_profile.id : null
                    }
                ]
            }
        }
        console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        successNoti('200', 'success');
        replace("/majorcare/setup-hcu")
    }

    async function createInfo(values:any) {
        let data = {
            "data":{
                "property": values.property,
                "hcu_setting": [
                    {
                        "seq": 1, // infor seq = 1
                        "home_check_up_type":{
                            "name": "Information"
                        },
                        "tac_th": values.topic,
                        "deleteStatus": "N",
                        "tac_infomation_th": vInfoTh,
                        "tac_infomation_en": vInfoEn,
                        "createBy" : Identity_data ? Identity_data.user_profile.id : null
                    },
                ]
            }
        }
        // console.log('data onFin', data)
        let res = await axios.post(API_URL + "/home-check-up-settings", data , { headers: { Authorization: 'Bearer ' + token } });
        console.log('res', res)
        successNoti('200', 'success');
        replace("/majorcare/setup-hcu")
    }

    // console.log('vTacTh',vTacTh);
    // console.log('vTacEn',vTacEn);
    // console.log('vInfoTh',vInfoTh);
    // console.log('vInfoEn',vInfoEn);

    return (
    <Create 
        saveButtonProps={saveButtonProps}
        title={tabTermActive == "TAC" ? "Create Term and condition" : "Create Information"}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{tabTermActive == "TAC" ? t("hcu-term.title") : t("hcu-info.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{tabTermActive == "TAC" ? t("hcu-term.create") : t("hcu-info.create")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form 
                {...formProps}
                layout="vertical"
                onFinish={(values:any) => {
                    values.property = values.property.id;
                    values.topic = values.topic;
                    if(tabTermActive == "TAC"){
                        createTerm(values)
                    }else{
                        createInfo(values)
                    }
                }}
            >
                <div className="divider-container">
                    {/* <Row>
                        <Col>
                            <div>test</div>
                        </Col>
                    </Row> */}
                    <Row gutter={24}>
                        <Col span={6} className="gutter-row">
                            <Form.Item
                                name={["property", "id"]}
                                label="Property"
                                rules={[
                                    {
                                        required: true, message: "Please select property"
                                    },
                                ]}
                            >
                                <Select placeholder={t("select.sel")} {...selProperty}/>
                            </Form.Item>
                        </Col>

                        <Col span={2}>
                            <Divider type="vertical" className="long-divider" ></Divider>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="TOPIC"
                                name="topic"
                            >
                                <Input placeholder="detail" /> 
                            </Form.Item>
                        </Col>
                        <Col span={4} className="gutter-row">
                            
                            <Form.Item
                                label="Upload PDF (TH)"
                                name="pic"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload PDF.'
                                    },
                                ]}
                            >

                                <Upload.Dragger
                                    style={{ borderRadius: 10 }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}

                                    listType="picture"
                                    // multiple
                                    {...uploadProps}
                                    // onChange={fileChange}
                                    onChange={(e:any) => fileChange(e, "th")}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>

                        <Col span={4} className="gutter-row">
                            <Form.Item
                                label="Upload PDF (EN)"
                                name="pic_en"
                                valuePropName="fileList"
                                getValueProps={(data) => getValueProps(data, API_URL)}
                                rules={[
                                    {
                                        required: true, message: 'Please upload PDF.'
                                    },
                                ]}
                            >
                                <Upload.Dragger
                                    style={{ borderRadius: 10 }}
                                    name="files"
                                    action={`${API_URL}/upload`}
                                    headers={{
                                        Authorization: `Bearer ${localStorage.getItem(
                                            TOKEN_KEY,
                                        )}`,
                                    }}
                                    listType="picture"
                                    // multiple
                                    {...uploadProps}
                                    onChange={(e:any) => fileChange(e, "en")}
                                    beforeUpload={beforeUpload}
                                >
                                    <p>Upload</p>
                                </Upload.Dragger>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </Form>
    </Create>   
    );
};