import { IResourceComponentsProps, useCustom, useList, useNavigation, useTranslate } from "@pankod/refine-core"
import axios from "axios";
import { petURL } from "../../../constants";
import { Button, Col, EditButton, Image, Form, Icons, Row, List, Select, Spin, Table, Breadcrumb, Modal, useSelect, Tabs, TextField, Tag } from "@pankod/refine-antd";
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

export const PetDataGuestRegis: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { TabPane } = Tabs;
    const { replace, list, edit} = useNavigation();

    return (
        <>
            <div className="layout_panel">
               COMING SOON 
            </div>
        </>
    )
}