/* eslint-disable */
import { Breadcrumb, Create, List, Select, useForm, Modal, Form, Row, Col, Input, Typography, useSelect, Image,TimePicker, Button, Checkbox, Radio, RadioChangeEvent, Icons, Edit, Divider, EditButton } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core";
import { Key, useEffect, useState } from "react";
import { TOKEN_KEY } from "../../../constants";
import { ExclamationCircleOutlined, SendOutlined, ArrowRightOutlined, ArrowLeftOutlined, LeftOutlined, RightOutlined, } from "@ant-design/icons";
import dayjs from "dayjs";
import { Dayjs } from "dayjs";

import axios from "axios";
import { Carousel, DatePicker, notification, Table, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { majorCareUrl } from "../../../constants";

{/* 
    6485ba66049e59df2850eb9e = รอดำเนินการ
    6485bcda049e59df2850eba0 = รับเรื่อง
    6485bcf9049e59df2850eba2 = รอตรวจ
    6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
    6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
    6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
    6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
    6485bd8b681aef755a4f279f = รอนัดหมาย
    6485bd96681aef755a4f27a1 = หมดอายุ
*/}

export const MjcInspecNewJobEdit: React.FC<IResourceComponentsProps> = () => {
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null


    const t = useTranslate();
    const API_URL = useApiUrl();
    const { replace, list} = useNavigation();
    const AuthStr = 'Bearer '.concat(TOKEN_KEY);
    const [countInterval, setCountInterval] = useState(0);
    const { create, edit } = useNavigation();
    const [Stat, setStat] = useState<any>();

    // Data trail
    const [dataTrail, setDataTrail] = useState<any>([]);

    let id:any
    let arr = window.location.href.split("/");
    let arr_id = arr[arr.length-1]
    id = arr_id.split("_")
    let item_id = id[0]
    let item_name = id[1]

    // console.log('item_id', item_id)
    const [Inspector, SetInspector] = useState<any>([]); // user-profile
    const [InspectorId, SetInspectorId] = useState<any>([]); // id user-profile
    const [IdFilter, SetIdFilter] = useState<any>([]); // id user type mapping

    // Data ส่วนของ รายละเอียดการแจ้ง
    const [JobNo, SetJobNo] = useState<any>();
    const [CreateDate, SetCreateDate] = useState<any>();
    const [TransferDate, SetTransferDate] = useState<any>();
    const [Property, SetProperty] = useState<any>();
    const [Unit, SetUnit] = useState<any>();
    const [Customer, SetCustomer] = useState<any>();
    const [Type, SetType] = useState<any>();

    const [AppointStart, SetAppointStart] = useState<any>();
    const [AppointEnd, SetAppointEnd] = useState<any>();

    const [InpectorName, SetInpectorName] = useState<any>();
    const [Remark, SetRemark] = useState<any>("");

    const [LastTrailStat, SetLastTrailStat] = useState<any>();
    const [dataChecklist, setDataCheckList] = useState<any>([]);

    const getDataK = async ()=>{
        // https://api-dev-majorcare.techscape.app/api/majorcare/findOneHomecheckjob?id=648ab8ca326b1b890c5cf5ec
        let res_data = await axios.get(
            majorCareUrl + `/majorcare/findOneHomecheckjob?id=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                console.log('dataLoad', dataLoad)
                console.log('dataLoad[0]', dataLoad[0])

                SetJobNo(dataLoad[0].job_id ? dataLoad[0].job_id : '-')
                SetCreateDate(dataLoad[0].create_date)

                SetProperty(dataLoad[0] ? dataLoad[0].property_obj.length > 0 ? dataLoad[0].property_obj[0].property_name : '-' : '-')
                SetUnit(dataLoad[0] ? dataLoad[0].unit_obj.length > 0 ? dataLoad[0].unit_obj[0].unit_name : '-' : '-')
                SetCustomer(dataLoad[0].request_by.attributes !== undefined ? dataLoad[0].request_by.attributes.first_name + ' ' + dataLoad[0].request_by.attributes.last_name : '-')

                // Transfer Date
                SetTransferDate(dataLoad[0] ? dataLoad[0].request_by ? dataLoad[0].request_by.unitDetail ? dayjs(dataLoad[0].request_by.unitDetail.transfer_date).format("DD/MM/YY") : '-' : '-': '-');
                SetType(dataLoad[0] ? dataLoad[0].request_by ? dataLoad[0].request_by.attributes.user_sub_types.data.length > 0 ? dataLoad[0].request_by.attributes.user_sub_types.data[0].attributes.sub_type_name : '-' : '-' : '-')

            });

            let res_data_trail = await axios.get(
                majorCareUrl + `/majorcare/findTrailByJob?jid=` + item_id ,
                { headers: {  } }).then((res) => {
                    let dataTrail: any = res?.data;
                    console.log('dataTrail', dataTrail[0])
                    SetInpectorName(dataTrail[0].assign ? dataTrail[0].assign.attributes.user_profile ? dataTrail[0].assign.attributes.user_profile.data.attributes.first_name + ' ' + dataTrail[0].assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )
                });
    }

    // console.log('dataChecklist', dataChecklist)

    const getDataTrail = async ()=>{
        // เช็คว่าเป็นสถานะ 'รอตรวจ' หรือยัง
        // http://localhost:10000/api/majorcare/findAllHomecheckTrailList?jid=648bcf8939a38859f358deaa
        let res_data_trail = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckTrailList?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoadTrail: any = res?.data;
                console.log('dataLoadTrail', dataLoadTrail)

                let data_trail = dataLoadTrail.filter((e:any)=> e.status == "6485bcf9049e59df2850eba2");
                SetAppointStart(data_trail[data_trail.length-1].appoint_start ? data_trail[data_trail.length-1].appoint_start : '-')
                SetAppointEnd(data_trail[data_trail.length-1].appoint_end ? data_trail[data_trail.length-1].appoint_end : '-')
                SetInpectorName(data_trail[data_trail.length-1].assign ? data_trail[data_trail.length-1].assign.attributes.user_profile ? data_trail[data_trail.length-1].assign.attributes.user_profile.data.attributes.first_name + ' ' + data_trail[data_trail.length-1].assign.attributes.user_profile.data.attributes.last_name  : '-' : '-' )

                console.log('last trail', dataLoadTrail[0])
                SetLastTrailStat(dataLoadTrail ? dataLoadTrail[0].status : '6485bd3a681aef755a4f2797')

                for (let index = 0; index < dataLoadTrail.length; index++) {
                    dataTrail.push(dataLoadTrail[index]);
                }
            });

        // find checklist
        // https://api-dev-majorcare.techscape.app/api/majorcare/findAllHomecheckjobChecklist?jid=648c99b100bf4d6e42787790
        let res_data_checklist = await axios.get(
            majorCareUrl + `/majorcare/findAllHomecheckjobChecklist?jid=` + item_id ,  // 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // //console.log('dataLoad Check', dataLoad)
                for (let index = 0; index < dataLoad.length; index++) {
                    dataChecklist.push(dataLoad[index]);
                }
            });
    }

    // useEffect(() => {
    //     if (countInterval < 3) {
    //         const interval = setInterval(() => {
    //             setCountInterval(prev => prev + 1);
    //             if(countInterval === 1){
    //                 // console.log('call')
    //                 getDataK();
    //             }
    //             // console.log('res_data', res_data);
    //             // console.log('property', Property);
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [countInterval])

    // ========================= NEW CHECKLIST MAP DATA ===============================
    // Prepare table columns
    const columnsx = [
        {
            title: 'No.',
            // dataIndex: 'item_name',
            // key: 'item_name',
            render: (value:any, record:any, index:any) => {
                return (
                    <>
                        <div>{index + 1}</div>
                    </>
                );
            },
        },
        {
            title: 'Zone',
            dataIndex: 'zoneName',
            key: 'zoneName',
            // render: (value:any, record:any, index:any) => {
            //     console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: 'Structure',
            dataIndex: 'structureName',
            key: 'structureName',
            // render: (value:any, record:any, index:any) => {
            //     // console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: 'Checklist',
            dataIndex: 'checklistName',
            key: 'checklistName',
            // render: (value:any, record:any, index:any) => {
            //     // console.log('record jjjjjjjjj', record)
            //     return (
            //         <> 
            //             -
            //         </>
            //     );
            // },
        },
        {
            title: 'สถานะการตรวจ',
            key: 'inspec_stat',
            render: (value:any, record:any, index:any) => {
                console.log('record checklist', record)
                return (
                    <> 
                        <Radio.Group 
                            onChange={(val:any)=>(onChangeInspecStat(record._id, val.target.value)) }
                            defaultValue={record.is_status !== null ? record.is_status : false}
                        >
                            <Radio value={true}>ปกติ</Radio>
                            <Radio value={false}>ไม่ปกติ</Radio>
                        </Radio.Group>
                    </>
                );
            },
        },
        {
            title: 'เงื่อนไขการรับประกัน',
            // dataIndex: 'waranty',
            // key: 'waranty',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.is_warantee ?
                                <div>อยู่ในประกัน</div>
                            :
                                <div>ไม่อยู่ในประกัน</div>
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'Image',
            // dataIndex: 'pic',
            key: 'pic',
            render: (value:any, record:any) => {
                return (
                    <>
                        {
                            record?.pic ?
                                <>
                                    <Row>
                                        <Image 
                                            width={50} 
                                            height={50}
                                            // src={record.pic[0].url} 
                                            src={record.pic[0] ? record.pic[0].url : ''} 
                                            style={{marginRight:'50px'}}
                                        />
                                        {
                                            record?.pic.length > 1 &&
                                                <div 
                                                    className="div_img_checklist"
                                                    style={{
                                                        // backgroundImage: `url("${record.pic[0].url}")`,
                                                        backgroundImage: `url("${record.pic[1] ? record.pic[1].url : ''}")`,
                                                        width: '50px',
                                                        height: '50px',
                                                        backgroundSize:'cover',
                                                        backgroundColor:'#616161'
                                                        // backdropFilter: blur(0px')
                                                    }}
                                                    onClick={()=> {modalImg(record?.pic)}}
                                                >
                                                    <div
                                                        style={{
                                                            // alignItems:'center',
                                                            // justifyItems:'center',
                                                            // justifyContent:'center',
                                                            textAlign: 'center',
                                                            verticalAlign: 'center',
                                                            lineHeight: '50px',
                                                            fontSize:'25px',
                                                            color:'#ffffff',
                                                            width: '50px',
                                                            height: '50px',
                                                        }}
                                                    >
                                                        {/* {record?.pic.length -1}{'+'} */}
                                                        {'+'}{record?.pic.length -1}
                                                    </div>
                                                </div>
                                        }
                                    </Row>
                                </>
                            :
                                <>-</>
                        }
                    </>
                );
            },
        },
        {
            title: 'Action',
            // dataIndex: 'pic',
            key: 'action',
            render: (value:any, record:any) => {
                // console.log('record', record)
                return (
                    <>
                        <EditButton
                            hideText
                            size="small"
                            onClick={() => edit("mjc-inspec-checklist-todayjob-edit", record._id + "_" + item_id + "_" + record.structureName + "_" + record.zoneName )}
                            // disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                        />
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    // Prepare table data source
    const dataSourceX = dataChecklist.reduce((acc:any, item:any) => {
        // console.log('reduce item', item)
        let zoneName = '';
        let structureName = '';
        let checklistName = '';

        let is_status:any
        let is_warantee:any
        let pic:any
        let remark:any
        let _id:any
        let item_id:any
        // let zone_name:any
        // let structure_name:any

        if (item.type === 'CHECKLIST') {
            const structure = dataChecklist.find((data:any) => data.template_check_list_id === item.parent && data.type === 'STRUCTURE');
            // console.log('structure', structure)
            if (structure) {
                structureName = structure.item_detail[0] ? structure.item_detail[0].item_name : '-';
                const zone = dataChecklist.find((data:any) => data.template_check_list_id === structure.parent && data.type === 'ZONE');
                if (zone) {
                    // console.log('zone zzzzzzzzzzzzzzzzzzzz', zone)
                    zoneName = zone.item_detail[0] ? zone.item_detail[0].item_name : '-';
                }
            }
            checklistName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
            is_warantee = item.is_warantee;
            is_status = item.is_status;
            pic = item.pic;
            remark = item.remark;
            _id = item._id;
            item_id = item.item;
        } else if (item.type === 'STRUCTURE') {
            const zone = dataChecklist.find((data:any) => data.template_check_list_id === item.parent && data.type === 'ZONE');
            if (zone) {
                zoneName = zone.item_detail[0] ? zone.item_detail[0].item_name : '-';
            }
            structureName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
        } else if (item.type === 'ZONE') {
            zoneName = item.item_detail[0] ? item.item_detail[0].item_name : '-';
        }

        if (item.type === 'CHECKLIST') {
            acc.push({
                key: item._id,
                zoneName,
                structureName,
                checklistName,

                is_warantee,
                is_status,
                pic,
                remark,
                _id,
                item_id
            });
        }

        return acc;
    }, []);
    // ========================= NEW CHECKLIST MAP DATA ===============================


    useEffect(() => {
        if (countInterval < 3) {
            const interval = setInterval(() => {
                setCountInterval(prev => prev + 1);
                if(countInterval === 1){
                    getDataTrail();
                }
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [countInterval])

    useEffect(() => {
        getDataK();
    }, []);

    const [form] = Form.useForm();
    // console.log('checkedSms', checkedSms);

    async function createAssignInspec(values:any) {

        // console.log('Inspector', Inspector)
        // console.log('InspectorId', InspectorId)
        // console.log('IdFilter', IdFilter)
        // console.log('values', values)

        console.log('LastTrailStat',LastTrailStat)

        let dataTrail = {
            "home_check_job" : item_id,
            "status": LastTrailStat,
            // "appoint_start": AppointStart ? AppointStart : null,
            // "appoint_end": AppointEnd ? AppointEnd : null ,
            // "assign": InspectorProfile ? InspectorProfile[0] : null,
            "sla": {
                "sla": "-",
                "sla_time": "-"
            },
            "remark": Remark !== undefined ? Remark : "",
            "create_by": Identity_data?.user_profile,
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
        // console.log('res_trail', res_trail)

        // setCountInterval(0)
        successNoti('200', 'success');
        replace("/mjc-inspec")
    }

    const onChangeInspecStat = async (id:any, val:any) => {
        console.log('id', id)
        console.log('val', val)
        // SetStatCheck(val);
        let data = {
            "is_status": val,
        }

        let res_update = await axios.put(
        majorCareUrl + `/majorcare/updateHomecheckjobChecklist?id=` + id , data ,// 6485ba66049e59df2850eb9e = รอดำเนินการ
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                //console.log('dataLoad Check', dataLoad)
            }
        );
    };

    const handleModalClose = () => {
        // Handle modal close event if needed
    };
    
    const renderModalContent = (val:any) => (
        <Carousel
            autoplay
            style={{
            height: '80%',
            // width: '50%',
            margin: 0,
            padding: 0,
            background: 'rgba(0, 0, 0, 0.8)',
            }}
            arrows 
            nextArrow={<RightOutlined />} 
            prevArrow={<LeftOutlined/>}
        >
            {val.map((item: { url: string | undefined; }, key: Key | null | undefined) => (
            <div key={key} style={{ height: '80%', display: 'flex', justifyContent: 'center' }}>
                <Image
                preview={false}
                src={item.url}
                alt="carousel-image"
                style={{ height: '80%', objectFit: 'cover', borderRadius: '30px' }}
                />
            </div>
            ))}
        </Carousel>
    );

    const modalImg = (val:any) => {
      Modal.info({
        icon: false,
        content: renderModalContent(val),
        width: '50%',
        style: { top: 0, left: 0 },
        maskClosable: true,
        onOk: handleModalClose,
        onCancel: handleModalClose,
      });
    }
  
    const colTrail:any = [
        {
            title: 'วันที่ดำเนินงาน',
            dataIndex: 'item_name',
            key: 'item_name',
            // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
            render: (value:any, record:any) => {
                console.log('record',record)
                return (
                    <>
                        <div>{record ? record.create_date !== null ? dayjs(record.create_date).format("DD/MM/YY HH:mm") : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'ผู้ทำรายการ',
            // dataIndex: 'name',
            key: 'name',
            // sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
            render: (value:any, record:any) => {
                // console.log('record', record)
                // console.log('value', value)
                return (
                    <>
                        <div>{record ? record.create_by ? record.create_by.first_name + ' ' + record.create_by.last_name : '-': '-'}</div>
                    </>
                );
            },
        },
        {
            title: 'สถานะ',
            dataIndex: 'createBy',
            key: 'createBy',
            render: (val:any, record:any) => {
                console.log('record', record)
                return (
                    <>
                        {/* 
                            6485ba66049e59df2850eb9e = รอดำเนินการ
                            6485bcda049e59df2850eba0 = รับเรื่อง
                            6485bcf9049e59df2850eba2 = รอตรวจ
                            6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
                            6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
                            6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
                            6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
                            6485bd8b681aef755a4f279f = รอนัดหมาย
                            6485bd96681aef755a4f27a1 = หมดอายุ
                        */}
                        { 
                            record ?
                                record.status == "6485ba66049e59df2850eb9e" ? 
                                        <Tag>รอดำเนินการ</Tag>
                                    : 
                                        record.status == "6485bcda049e59df2850eba0" ? 
                                            <Tag>รับเรื่อง</Tag>
                                        :
                                            record.status == "6485bcf9049e59df2850eba2" ? 
                                                <Tag>รอตรวจ</Tag>
                                            :
                                                record.status == "6485bd3a681aef755a4f2797" ? 
                                                    <Tag>เข้าตรวจหน้างาน</Tag>
                                                :
                                                    record.status == "6485bd56681aef755a4f2799" ? 
                                                        <Tag>เข้าตรวจหน้างาน - เกินกำหนด</Tag>
                                                    :
                                                        record.status == "6485bd6e681aef755a4f279b" ? 
                                                            <Tag>รออนุมัติผลการตรวจ</Tag>
                                                        :
                                                            record.status == "6485bd7d681aef755a4f279d" ? 
                                                                <Tag>เสร็จสมบูรณ์</Tag>
                                                            :
                                                                record.status == "6485bd8b681aef755a4f279f" ? 
                                                                    <Tag>รอนัดหมาย</Tag>
                                                                :
                                                                    record.status == "6485bd96681aef755a4f27a1" ? 
                                                                    <Tag>หมดอายุ</Tag>
                                                                : '-'
                            :
                                '-'
                        }
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
        },
        {
            title: 'SLA',
            dataIndex: 'sla',
            key: 'sla',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.sla ? record.sla.sla_time !== '-' && record.sla.sla_time !== '' ? record.sla.sla_time  + ' นาที' : '-' : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
        {
            title: 'หมายเหตุ',
            dataIndex: 'remark',
            key: 'remark',
            render: (value:any, record:any) => {
                return (
                    <>
                        <div>{record ? record.remark ? record.remark : '-': '-'}</div>
                    </>
                );
            },
            // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
        },
    ];

    // const colChecklist:any = [
    //     {
    //         title: 'No.',
    //         dataIndex: 'item_name',
    //         key: 'item_name',
    //         // sorter: (a:any, b:any) => a.property.localeCompare(b.property),
    //         render: (value:any, record:any, index:any) => {
    //             return (
    //                 <>
    //                     <div>{index + 1}</div>
    //                     {/* <div>{record ? record.job_id !== null ? record.job_id : '-': '-'}</div> */}
    //                 </>
    //             );
    //         },
    //     },
    //     // {
    //     //     title: 'วันที่ตรวจ',
    //     //     // dataIndex: 'name',
    //     //     key: 'name',
    //     //     // sorter: (a:any, b:any) => a.doc_name.localeCompare(b.doc_name),
    //     //     render: (value:any, record:any) => {
    //     //         // console.log('record', record)
    //     //         // console.log('value', value)
    //     //         return (
    //     //             <>
    //     //                 <div>{record ? record.property_obj.length > 0 ? record.property_obj[0].property_name : '-': '-'}</div>
    //     //             </>
    //     //         );
    //     //     },
    //     // },
    //     {
    //         title: 'Zone',
    //         dataIndex: 'createBy',
    //         key: 'createBy',
    //         render: (val:any, record:any) => {
    //             // console.log('record', record)
    //             return (
    //                 <>
    //                     <div>ห้องนอน</div>
    //                 </>
    //             );
    //         },
    //         // sorter: (a:any, b:any) => a.createBy.first_name.localeCompare(b.createBy.first_name),
    //     },
    //     {
    //         title: 'Structure',
    //         dataIndex: 'updateBy',
    //         key: 'updateBy',
    //         render: (value:any) => {
    //             return (
    //                 <>
    //                     <div>ประตูทางเข้า</div>
    //                 </>
    //             );
    //         },
    //         // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
    //     },
    //     {
    //         title: 'Checklist',
    //         dataIndex: 'updateBy',
    //         key: 'updateBy',
    //         render: (value:any, record:any) => {
    //             return (
    //                 <>
    //                     <div>{record ? record.item_detail.length > 0 ? record.item_detail[0].item_name : '-' : '-'}</div>

    //                 </>
    //             );
    //         },
    //         // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
    //     },
    //     {
    //         title: 'สถานะการตรวจ',
    //         // dataIndex: 'inspec_stat',
    //         key: 'inspec_stat',
    //         render: (value:any, record:any, index:any) => {
    //             console.log('record xxxx', record)
    //             return (
    //                 <>
    //                     <Radio.Group 
    //                         // onChange={(val:any)=>(onChangeInspecStat(record._id))}
    //                         // onChange={(val:any)=>(console.log(val.target.value))}
    //                         onChange={(val:any)=>(onChangeInspecStat(record._id, val.target.value)) }
    //                         // name="radiogroup"
    //                         // rules={[{required: true, message: 'Please Select Type.'},]}
    //                         // value={record.is_status !== null ? record.is_status : false}
    //                         defaultValue={record.is_status !== null ? record.is_status : false}
    //                         // disabled

    //                     >
    //                         <Radio value={true}>ปกติ</Radio>
    //                         <Radio value={false}>ไม่ปกติ</Radio>
    //                     </Radio.Group>
    //                 </>
    //             );
    //         },
    //         // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
    //     },
    //     {
    //         title: 'เงื่อนไขการรับประกัน',
    //         dataIndex: 'waranty',
    //         key: 'waranty',
    //         render: (value:any, record:any) => {
    //             return (
    //                 <>
    //                     {
    //                         record?.is_warantee ?
    //                             <div>อยู่ในประกัน</div>
    //                         :
    //                             <div>ไม่อยู่ในประกัน</div>
    //                     }
    //                 </>
    //             );
    //         },
    //         // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
    //     },
    //     {
    //         title: 'pic',
    //         // dataIndex: 'pic',
    //         key: 'pic',
    //         render: (value:any, record:any) => {
    //             return (
    //                 <>
    //                     {
    //                         record?.pic ?
    //                             <>
    //                                 <Row>
    //                                     <Image 
    //                                         width={50} 
    //                                         height={50}
    //                                         src={record.pic[0].url} 
    //                                         style={{marginRight:'50px'}}
    //                                     />
    //                                     {
    //                                         record?.pic.length > 1 &&
    //                                             <div 
    //                                                 className="div_img_checklist"
    //                                                 style={{
    //                                                     backgroundImage: `url("${record.pic[1].url}")`,
    //                                                     width: '50px',
    //                                                     height: '50px',
    //                                                     backgroundSize:'cover',
    //                                                     backgroundColor:'#616161'
    //                                                     // backdropFilter: blur(0px')
    //                                                 }}
    //                                                 onClick={()=> {modalImg(record?.pic)}}
    //                                             >
    //                                                 <div
    //                                                     style={{
    //                                                         // alignItems:'center',
    //                                                         // justifyItems:'center',
    //                                                         // justifyContent:'center',
    //                                                         textAlign: 'center',
    //                                                         verticalAlign: 'center',
    //                                                         lineHeight: '50px',
    //                                                         fontSize:'25px',
    //                                                         color:'#ffffff',
    //                                                         width: '50px',
    //                                                         height: '50px',
    //                                                     }}
    //                                                 >
    //                                                     {/* {record?.pic.length -1}{'+'} */}
    //                                                     {'+'}{record?.pic.length -1}
    //                                                 </div>
    //                                             </div>
    //                                     }
    //                                 </Row>
    //                             </>
    //                         :
    //                             <>-</>
    //                     }
    //                 </>
    //             );
    //         },
    //     },
    //     {
    //         title: 'Action',
    //         // dataIndex: 'pic',
    //         key: 'action',
    //         render: (value:any, record:any) => {
    //             // console.log('record', record)
    //             return (
    //                 <>
    //                     <EditButton
    //                         hideText
    //                         size="small"
    //                         onClick={() => edit("mjc-inspec-checklist-todayjob-edit", record._id + "_" + item_id )}
    //                         disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
    //                     />
    //                 </>
    //             );
    //         },
    //         // sorter: (a:any, b:any) => a.updateBy.first_name.localeCompare(b.updateBy.first_name),
    //     },
    // ];

    async function sendToCusApprove(){
        // console.log('id : ', id)
        let data = {
            "status": "6485bd6e681aef755a4f279b",  // รออนุมัติผลการตรวจ
        };
        let res = await axios.put(majorCareUrl + "/majorcare/updateHomecheckjob?id=" + item_id, data, { headers: { } });
        // console.log('res', res)

        {/* 
            6485ba66049e59df2850eb9e = รอดำเนินการ
            6485bcda049e59df2850eba0 = รับเรื่อง
            6485bcf9049e59df2850eba2 = รอตรวจ
            6485bd3a681aef755a4f2797 = เข้าตรวจหน้างาน
            6485bd56681aef755a4f2799 = เข้าตรวจหน้างาน - เกินกำหนด
            6485bd6e681aef755a4f279b = รออนุมัติผลการตรวจ
            6485bd7d681aef755a4f279d = เสร็จสมบูรณ์
            6485bd8b681aef755a4f279f = รอนัดหมาย
            6485bd96681aef755a4f27a1 = หมดอายุ
        */}

        let dataTrail = {
            "home_check_job" : item_id,
            "status": "6485bd6e681aef755a4f279b", // รออนุมัติผลการตรวจ
            "appoint_start": "",
            "appoint_end": "",
            "assign": {
                "name":"",
                "lastname":""
            },
            "sla": {
                "sla": "",
                "sla_time": ""
            },
            "remark": Remark,
            "create_by": Identity_data?.user_profile,
            // "create_date": dayjs()
        }
        let res_trail = await axios.post(majorCareUrl + "/majorcare/createHomecheckjobTrail", dataTrail, { headers: { } });
        // console.log('res_trail', res_trail)
        setCountInterval(0)
        // setDataList([])
        successNoti('200', 'success');
        replace("/mjc-inspec")
    }
    
    const onChangeRemark = (e: any) => {
        SetRemark(e.target.value);
    }

    const successNoti = (stat:any, msg:any) => {
        notification.success({
            // message: stat + ' : ' + msg.TH,
            message: msg,
            // description: msg,
            duration: 3,
        });
    };

    return (
    <Edit 
        saveButtonProps={{ hidden: true }}
        pageHeaderProps={{extra: <></>}}
    >
        <List 
            title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("hcusetup.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>Service &amp; Repair</Breadcrumb.Item>
                    {/* <Breadcrumb.Item><span className="last_breadcrumb">{t("hcu-assign.edit")}</span></Breadcrumb.Item> */}
                    <Breadcrumb.Item><span className="last_breadcrumb">รายการแจ้ง Home Checkup</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        />
            <Form  
                form = {form} 
                // layout="horizontal"
                layout="vertical"
                onFinish={(values:any) => {
                    createAssignInspec(values);
                }}
            >

                <Row gutter={82}>
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายละเอียดการแจ้ง
                        </div>
                    </Col>
                </Row>
                <Row gutter={82} >
                    <Col span={24}>
                        <Row>
                            <Col span={8}>

                                <Form.Item 
                                    label="Job no."
                                    // name="job_no"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={JobNo} defaultValue={JobNo} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Property"
                                    // name="property"
                                    style={{width:"90%", marginTop:'-3%'}} 
                                >
                                    <Input value={Property} defaultValue={Property} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="ผู้แจ้ง"
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Customer} defaultValue={Customer} disabled/>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่แจ้ง"
                                    // name="date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={dayjs(CreateDate).format("DD/MM/YY HH:mm")} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="Unit" 
                                    // name="unit"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Unit} defaultValue={Unit} disabled/>
                                </Form.Item>

                                <Form.Item 
                                    label="ประเภท" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={Type} defaultValue={Type} disabled/>
                                </Form.Item>

                            </Col>

                            <Col span={8}>
                                <Form.Item 
                                    label="วันที่โอน" 
                                    // name="transfer_date"
                                    style={{width:"90%", marginTop:'-3%'}}
                                >
                                    <Input value={TransferDate} defaultValue={TransferDate} disabled/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            การนัดหมาย
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={8}>
                        <Form.Item 
                            label="วันที่นัด" 
                            // name="transfer_date"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input defaultValue={AppointStart} value={AppointStart} disabled />
                        </Form.Item>

                        <Form.Item 
                            label="ช่างผู้รับผิดชอบ" 
                            // name="inspector"
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input defaultValue={InpectorName} value={InpectorName} disabled/>
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                        <Form.Item 
                            label="ถึงวันที่" 
                            // name=""
                            style={{width:"90%", marginTop:'-3%'}}
                        >
                            <Input defaultValue={AppointEnd} value={AppointEnd} disabled />
                        </Form.Item>
                    </Col>

                    <Col span={8}>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            รายการตรวจ
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        {/* <Table 
                            columns={colChecklist}
                            // dataSource={dataChecklist}
                            dataSource={dataChecklist.length > 0 ? dataChecklist : null}
                        /> */}
                        <Table columns={columnsx} dataSource={dataSourceX} />
                    </Col>
                </Row>
                
                <Row>
                    <Col span={18}>
                        <Form.Item 
                            label="หมายเหตุ" 
                            // name="remark"
                            style={{width:"90%"}}
                        >
                            <TextArea style={{ height: "30px", maxHeight: "80px" }}
                                onChange={(e) => onChangeRemark(e)}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Button 
                            // style={{color:'Background'}}
                            style={{ color:"#ffffff",backgroundColor: "#167AD8",borderRadius: "10px"}}
                            onClick={()=>{sendToCusApprove()}}
                        >
                            <SendOutlined /> ส่งให้ลูกค้าอนุมัติ
                        </Button>
                        <Row style={{float: 'right'}}>
                            <Button type="primary" onClick={() => form.submit()} icon={<Icons.SaveOutlined />}>Save</Button>
                        </Row>
                    </Col>
                </Row>

                <Divider />

                <Row gutter={82} >
                    <Col span={24}>
                        <div style={{marginBottom:'3%', fontSize:'2vh'}}>
                            ประวัติการดำเนินงาน
                        </div>
                    </Col>
                </Row>

                <Row>
                <Col span={24}>
                        <Table 
                            columns={colTrail} 
                            dataSource={dataTrail.length > 0 ? dataTrail : null}
                        />
                    </Col>
                </Row>
            </Form>
    </Edit>   
    );
};