/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useCustom, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { MjcRepairMdpcDoneJob } from "./done_job";
import { MjcRepairMdpcInprogressJob } from "./inprogress_job";
import { MjcRepairMdpcNewJob } from "./new_job";
import { MjcRepairMdpcTodayJob } from "./today_job";
import { MjcRepairMdpcWaitAppointmentJobList } from "./waiting_appointment_job";
import { MjcRepairMdpcWaitingApproveJob } from "./waiting_approv_job";
import { MjcRepairMdpcWaitingCustApproveJob } from "./wait_cust_approve";
import axios from "axios";
import { majorCareUrl } from "../../constants";
import dayjs from "dayjs";
// import { MjcDone } from "./done";
// import { MjcInProgress } from "./in_progress";
// import { MjcNewJob } from "./new_job";
// import { MjcWaiting } from "./waiting";
// import { HcuChecklistList } from "./checklist";
// import { HcuStructureList } from "./structure";
// import { HcuZoneList } from "./zone";
// import { HcuNotiMsgList } from "./notification";
// import { HcuTermConditionList } from "./term_and_condition";
// import { HcuTemplateList } from "./template_master";
// import { HcuDaysList } from "./setup_days";
// import { HcuAssignList } from "./assign_inspec";


export const RepairMdpcAdmin: React.FC<IResourceComponentsProps> = () => {
    // const tabActive = localStorage.getItem("keyTabHCUMaster");
    const [ Tab, SetTab] = useState<any>();
    const prop_id = localStorage.getItem("PROPERTY_ID");
    const prop_list = localStorage.getItem("PROPERTY_MULTI_ID")?.split(',')?.map(Number);

    // console.log('fetch list')

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null
    const { TabPane } = Tabs;
    const tabJobActive = localStorage.getItem("keyTabJob");

    function onChangeTabTerm(v:any){
        SetTab(v)
        localStorage.setItem("keyTabJob", v)
    }

    const [countNewJob, setCountNewJob] = useState<any>(undefined);
    const [countWaitCustApproveBaiJob, setCountWaitCustApproveBaiJob] = useState<any>(undefined);
    const [countWaitAppJob, setCountWaitAppJob] = useState<any>(undefined);
    const [countTodayJob, setCountTodayJob] = useState<any>(undefined);
    const [countInprogressJob, setCountInProgressJob] = useState<any>(undefined);
    const [countWaitCustApproveJob, setCountWaitCustApproveJob] = useState<any>(undefined);
    const [countDoneJob, setCountDoneJob] = useState<any>(undefined);


    // NEW JOB
    const { data } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount`,
        method: "get",
        config: {
            query: {
                stat: '649859ca515b80b78fe13b84',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;

                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }
                //console.log("firstdataLoad",dataLoad)
                let xx = dataLoad.filter((e:any)=> e.job_obj.length > 0 && e.job_obj[0].is_delegate === false )
                //console.log("firstXX",xx)
                setCountNewJob(xx?.length)
            },
        },
    });

    // WAIT CUST APPROVE
    const { data: data2 } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount`,
        method: "get",
        config: {
            query: {
                stat: '649859ca515b80b78fe13b84,64ab86a5785d4236922eaff7,',
                page: '1',
                limit: '500',
                mode: '2'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }
                setCountWaitCustApproveBaiJob(dataLoad?.length)
            },
        },
    });

    // WAIT APPOINTMENT
    const { data: data3 } = useCustom<any>({
        url: `${majorCareUrl}/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount`,
        method: "get",
        config: {
            query: {
                stat: '649859e7515b80b78fe13b86,64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64a7bf23785d4236922eaff6,',
                page: '1',
                limit: '500',
                mode: '1'
            }
        },
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                if(prop_list !== undefined){
                    dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
                }else if(prop_id !== null){
                    dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
                }
                let xx = dataLoad.filter((e:any)=> e.job_obj[0].hod_approve == false) 
                
                setCountWaitAppJob(xx?.length)
            },
        },
    });

    const getDataK = async ()=>{
        
        // NEW JOB
        // await axios.get(
        //     majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=649859ca515b80b78fe13b84&page=1&limit=400&mode=1`, { headers: {  } })
        // .then((res) => {
        //     let dataLoad: any = res?.data;
        //     setCountNewJob(dataLoad?.length)
        // }).catch((error) => {
        //     console.log('error', error)
        // });


        // WAIT CUST APPROVE
        // await axios.get(
        //     majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=649859ca515b80b78fe13b84,64ab86a5785d4236922eaff7&page=1&limit=400&mode=2`, { headers: {  } })
        // .then((res) => {
        //     let dataLoad: any = res?.data;
        //     // console.log('dataLoad', dataLoad)
        //     setCountWaitCustApproveBaiJob(dataLoad?.length)
        // }).catch((error) => {
        //     console.log('error', error)
        // });

        // WAIT APPOINTMENT
        // await axios.get(
        //     majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=649859e7515b80b78fe13b86,64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64a7bf23785d4236922eaff6&page=1&limit=400&mode=1`, { headers: {  } })
        // .then((res) => {
        //     let dataLoad: any = res?.data;
        //     setCountWaitAppJob(dataLoad?.length)
        // }).catch((error) => {
        //     console.log('error', error)
        // });

        // TODAY JOB
        await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=649859f8515b80b78fe13b88,64985a35515b80b78fe13b90,64985a3c515b80b78fe13b92,64985a01515b80b78fe13b8a,64985a01515b80b78fe13b8a&page=1&limit=500&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;

            const today = dayjs().format("YYYY-MM-DD");
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }
            // const xx = dataLoad.filter((e:any) => (e.job_obj[0]?.status_id === "649859f8515b80b78fe13b88" || e.job_obj[0]?.status_id === "64985a35515b80b78fe13b90" || e.job_obj[0]?.status_id === "64985a3c515b80b78fe13b92" || e.job_obj[0]?.status_id === "64985a01515b80b78fe13b8a" ) && e.job_obj[0]?.appoint_start?.startsWith(today));
            const xx = dataLoad.filter((e:any) => e.job_obj[0]?.appoint_start?.startsWith(today));

            setCountTodayJob(xx?.length)
        }).catch((error) => {
            console.log('error', error)
        });

        // INPROGRESS JOB
        await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=64985a01515b80b78fe13b8a,64985a0f515b80b78fe13b8c,64985a29515b80b78fe13b8e,64985a3c515b80b78fe13b92,64985a47515b80b78fe13b94,64985a67515b80b78fe13b96,64985ae5515b80b78fe13ba2,64985b3b515b80b78fe13bac,64ad1085f3440e02a3156fa6,64ad1075f3440e02a3156fa5,64985a35515b80b78fe13b90,649859f8515b80b78fe13b88,64d0a43db0a6b7718d211832,64d0a44fb0a6b7718d211833,64ad1091f3440e02a3156fa7,64a7bf07785d4236922eaff5&page=1&limit=500&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }
            setCountInProgressJob(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });

        // WAIT APPROVE JOB
        await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=64985bbe515b80b78fe13bb6,64985bc6515b80b78fe13bb8&page=1&limit=500&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }

            setCountWaitCustApproveJob(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });

        // DONE JOB
        await axios.get(
            majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByStatAndCount?stat=64985bcf515b80b78fe13bba&page=1&limit=5000&mode=1`, { headers: {  } })
        .then((res) => {
            let dataLoad: any = res?.data;
            if(prop_list !== undefined){
                dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
            }else if(prop_id !== null){
                dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
            }
            setCountDoneJob(dataLoad?.length)
        }).catch((error) => {
            console.log('error', error)
        });


        // let res_data = await axios.get(
        //     majorCareUrl + `/majorcare/repairJobDetailMdpc/findRepairJobDetailByJobId`, 
        //     { headers: {  } }).then((res) => {
        //         let dataLoad: any = res?.data;
        //         //console.log('dataLoad', dataLoad)

        //         // FILTER เอาแค่ชื่อที่โดน assign
        //         // dataLoad = res?.data.filter((e:any) => (
        //         //     e.job_obj[0].assign?.attributes?.user_profile?.data.id === Identity_data?.user_profile?.id
        //         // ))

        //         if(prop_list !== undefined){
        //             console.log('case 1')
        //             dataLoad = dataLoad.filter((item:any) => prop_list?.includes(item.job_obj[0]?.request_by?.unitDetail?.property_id));
        //         }else if(prop_id !== null){
        //             console.log('case 2')
        //             dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0]?.request_by?.unitDetail?.property_id == Number(prop_id))
        //         }

        //         // dataLoad = dataLoad?.filter((e:any)=> e.job_obj[0].request_by?.unitDetail?.property_id == Number(prop_id))
        //         setCountNewJob(dataLoad.filter((e:any) => (e.job_obj[0]?.status_id === "649859ca515b80b78fe13b84" )));
                
        //         const newDataCustApproveBaiJob = dataLoad.filter((e:any) => (
        //             (e.job_obj[0]?.status_id === "649859ca515b80b78fe13b84" ||
        //              e.job_obj[0]?.status_id === "64ab86a5785d4236922eaff7") &&
        //              e.job_obj[0]?.is_delegate === true
        //          ));
        //          setCountWaitCustApproveBaiJob(newDataCustApproveBaiJob)
        //         // setCountWaitCustApproveBaiJob(dataLoad.filter((e:any) => (e.job_obj[0].status_id === "649859ca515b80b78fe13b84" || e.job_obj[0].status_id === "64ab86a5785d4236922eaff7" && e.job_obj[0].is_delegate === true )));
                
        //         setCountWaitAppJob(dataLoad.filter((e:any) => (e.job_obj[0]?.status_id === "649859e7515b80b78fe13b86" || e.job_obj[0]?.status_id === "64a7bf23785d4236922eaff6" )));
        //         const today = dayjs().format("YYYY-MM-DD");
        //         const xxTodayJob = dataLoad.filter((e:any) => e.job_obj[0]?.appoint_start?.startsWith(today));
        //         setCountTodayJob(xxTodayJob)
        //         // setCountTodayJob(dataLoad.filter((e:any) => (
        //         //     e.job_obj[0]?.status_id === "649859f8515b80b78fe13b88" ||
        //         //     e.job_obj[0]?.status_id === "64985a35515b80b78fe13b90" ||
        //         //     e.job_obj[0]?.status_id === "64985a3c515b80b78fe13b92" ||
        //         //     e.job_obj[0]?.status_id === "64985a01515b80b78fe13b8a"
        //         // )));
                
        //         setCountInProgressJob(dataLoad.filter((e:any) => (
        //             e.job_obj[0]?.status_id === "64985a01515b80b78fe13b8a" || 
        //             e.job_obj[0]?.status_id === "64985a0f515b80b78fe13b8c" ||
        //             e.job_obj[0]?.status_id === "64985a29515b80b78fe13b8e" ||
        //             e.job_obj[0]?.status_id === "64985a3c515b80b78fe13b92" ||
        //             e.job_obj[0]?.status_id === "64985a47515b80b78fe13b94" ||
        //             e.job_obj[0]?.status_id === "64985a67515b80b78fe13b96" ||
        //             e.job_obj[0]?.status_id === "64985ae5515b80b78fe13ba2" ||
        //             e.job_obj[0]?.status_id === "64985b3b515b80b78fe13bac" ||
        //             e.job_obj[0]?.status_id === "64985a35515b80b78fe13b90" ||
        //             e.job_obj[0]?.status_id === "649859f8515b80b78fe13b88" ||
        //             e.job_obj[0]?.status_id === "64a7bf07785d4236922eaff5" 
        //         )));
        //         setCountWaitCustApproveJob(dataLoad.filter((e:any) => (
        //             e.job_obj[0]?.status_id === "64985bbe515b80b78fe13bb6" || 
        //             e.job_obj[0]?.status_id === "64985bc6515b80b78fe13bb8" 
        //         )));
        //         setCountDoneJob(dataLoad.filter((e:any) => (
        //             e.job_obj[0]?.status_id === "64985bcf515b80b78fe13bba"
        //         )));
                


        //     });
    }


    useEffect(() => {
        getDataK();
    }, []);

    
    return <>
        <List 
            // title={t("hcujob.title")}
            title="MDPC รายการแจ้งซ่อม"
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    {/* <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item> */}
                    <Breadcrumb.Item>MDPC แจ้งซ่อม</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcujob.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Form 
                // {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        type="card"
                        defaultActiveKey={tabJobActive ? tabJobActive + "" : "NewJob"}
                        style={{ marginTop: 10, marginBottom: -40 }} 
                        // onChange={()=> searchFormProps.form?.submit()} 
                        onChange={onChangeTabTerm}
                    >

                        <TabPane
                            key={"NewJob"}
                            tab={
                                <span>
                                    {"รายการใหม่"}
                                    <Badge showZero count={countNewJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcRepairMdpcNewJob />
                        </TabPane>

                        <TabPane
                            key={"WaitCustApprove"}
                            tab={
                                <span>
                                    {"รอลูกบ้านอนุมัติงานซ่อม"}
                                    <Badge showZero count={countWaitCustApproveBaiJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            {/* รอลูกบ้านอนุมัติงานซ่อม */}
                            {/* <MjcRepairNewJob /> */}
                            <MjcRepairMdpcWaitingCustApproveJob />
                        </TabPane>

                        <TabPane
                            key={"Waiting"}
                            tab={
                                <span>
                                    {"รอนัดหมาย"}
                                    <Badge showZero count={countWaitAppJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcRepairMdpcWaitAppointmentJobList />
                        </TabPane>

                        <TabPane
                            key={"Today"}
                            tab={
                                <span>
                                    {"ตารางงานวันนี้"}
                                    <Badge showZero count={countTodayJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcRepairMdpcTodayJob/>
                        </TabPane>

                        <TabPane
                            key={"InProgress"}
                            tab={
                                <span>
                                    {"ระหว่างดำเนินการ"}
                                    <Badge showZero count={countInprogressJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcRepairMdpcInprogressJob />
                        </TabPane>

                        <TabPane
                            key={"WaitApprove"}
                            tab={
                                <span>
                                    {"รออนุมัติงานซ่อม"}
                                    <Badge showZero count={countWaitCustApproveJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcRepairMdpcWaitingApproveJob />
                        </TabPane>

                        <TabPane
                            key={"Done"}
                            tab={
                                <span>
                                    {"เสร็จสิ้น"}
                                    <Badge showZero count={countDoneJob} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        >
                            <MjcRepairMdpcDoneJob />
                        </TabPane>
                    </Tabs>
                </Form.Item>
            </Form>
        </List>
    </>
}