import { Breadcrumb, Col, Create, Form, Input, List, Radio, RcFile, Row, Select, Upload, message, notification, useForm } from "@pankod/refine-antd"
import { HttpError, IResourceComponentsProps, useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine-core"
import { getValueProps, mediaUploadMapper } from "@pankod/refine-strapi-v4";
import axios from "axios";
import { TOKEN_KEY, petURL, rentSaleURL } from "../../../constants";
import { useState } from "react";

let optionpettype: any = undefined;
export const RentPropertyTypeCreate: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const API_URL = useApiUrl();
    const { TextArea } = Input;
    const { replace, list} = useNavigation();
    const {data: user_dt} = useGetIdentity<any>();
    const { formProps, saveButtonProps } = useForm<any,HttpError,{}>();

    const [valuepetType, setpetType] = useState<any>();
    const [vacinject, setvacinject] = useState<any>(0);
    const [injectRound, setInjectRound] = useState<boolean>(true);


    const { data: datapettype } = useCustom<any>({
        // url: `${petURL}/api/lscpet/findAllPetType`,
        url: `${petURL}/api/lscpet/findActivePetType`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                let newDT: any = [];

                for (let index = 0; index < dataLoad?.length; index++) {
                    newDT?.push({
                        label: dataLoad[index]?.pet_type_name,
                        value: dataLoad[index]?._id
                    })
                }

                if(newDT?.length > 0){
                    optionpettype = newDT;
                }
            },
        },
    });

    async function submitDT(values:any){
        let body: any = {
            "name": values?.name_th,
            "name_en": values?.name_en,
            "create_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
        }
        
        let submit = await axios.post(rentSaleURL + "/api/rentsale/createPropertyType", body, { headers: { } })
        .then(response => {
            successNoti('Create is Success');
            replace("/rent-sale/rentsale-masters");
        })
        .catch(error => {
            console.error('error ',error);
            faildNoti('Create is Failed');
        });
    }

    const successNoti = (msg:any) => {
        // notification.success({
        //     message: msg,
        //     duration: 3,
        // });
        notification['success']({
            message: "Successful",
            description: msg,
            duration: 3
        });
    };

    const faildNoti = (msg:any) => {
        notification.error({
            message: "Something's wrong",
            description: msg,
            duration: 3,
        });
    };

    return (
        <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>, onBack(e?) {replace("/rent-sale/rentsale-masters")}}}>
            <List 
                title=""
                pageHeaderProps={{
                    // breadcrumb: <Breadcrumb>
                    //     <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    //     <Breadcrumb.Item>{t("pet-master.title")}</Breadcrumb.Item>
                    //     <Breadcrumb.Item>{t("pet-vaccine.title")}</Breadcrumb.Item>
                    //     <Breadcrumb.Item><span className="last_breadcrumb">{t("pet-vaccine.title-create")}</span></Breadcrumb.Item>
                    // </Breadcrumb>,
                    extra : <></>
                }}
            />

            <div style={{width: "50%"}}>
                <Form 
                    {...formProps} 
                    layout="vertical"
                    onFinish={(values:any) => {
                        submitDT(values);
                    }}
                >   
                    <Row gutter={[24, 16]}>
                        <Col className="gutter-row" span={24}>
                            <Form.Item 
                                label={t("Property Type Name (TH)")}
                                name="name_th"
                                rules={[
                                    {
                                        required: true, message: 'please enter name th.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={24}>
                            <Form.Item 
                                label={t("Property Type Name (EN)")}
                                name="name_en"
                                rules={[
                                    {
                                        required: true, message: 'please enter name en.'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>

                        {/* <Col className="gutter-row" span={24}>
                            <Form.Item 
                                label={t("Link")}
                                name="link"
                                rules={[
                                    {
                                        required: true, message: 'please enter link.'
                                    },
                                ]}
                            >
                                <TextArea/>
                            </Form.Item>
                        </Col> */}

                    </Row>
                </Form>
            </div>
        </Create>
    )
}