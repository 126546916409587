/* eslint-disable */
import { Avatar, Badge, Breadcrumb, Button, Col, CreateButton,  EditButton, ExportButton, Form, Icons, Input, List, Modal, Row, ShowButton, Space, Table,  Tabs,  Tag,  TextField, useForm, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useExport, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { IEventlog, IProjectinfo } from "interfaces";
import { useEffect, useState } from "react";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import Moment from 'moment';
import { TOKEN_KEY } from "../../constants";
import axios from "axios";

const { TabPane } = Tabs;

export const ProjectinfoList: React.FC<IResourceComponentsProps> = () => {

    const tabProInfo = localStorage.getItem("keyTabStatusProInfo");
    const [keepDataStatus, setKeepDataStatus] = useState<any>('N')

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Project-info");
    const { confirm }                       = Modal;
    const { mutate }                        = useUpdate<IProjectinfo>();
    const ed_point                          = "project-infos";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];
    const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const [inputName, setInputName] = useState<any>();
    const [inputType, setInputType] = useState<any>();
    const [listData, setListdata] = useState<any>([]);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(null);
    const [loadingTable, setLoadingTable] = useState<boolean>(true);
    const [activeBadgeNumber, setActiveBadgeNumber] = useState<any>(null)
    const [trashBadgeNumber, setTrashBadgeNumber] = useState<any>(null)

    const { formProps } = useForm<any,HttpError,{}>() 

    const { triggerExport, isLoading: exportLoading } = useExport<IProjectinfo>({
        mapData: (item) => {
            console.log(item)
            if(item){
                return { // เพิ่มฟิลที่ต้องการ Export
                    id  : item.id, 
                    Project_Name : item.Project_Name,
                    Project_Status : item.Project_Tag,
                    Project_Description : item.Project_Description ? item.Project_Description : '-',
                    project_phone : item.project_phone ? item.project_phone : '-',
                    // project_line : item.project_line,
                    project_line : item.project_line ? item.project_line : '-',
                    project_location : item.project_location ? item.project_location : '-',
                    Coordinate : item.lat ? item.lat + ', ' + item.lng : '-'
                };
            }
        },
    });

    function updateStatus(id:any, event:any){
        // console.log('status : ', vCurDelStatus)
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
        },{
            onSuccess(data, variables, context) {
                getData()
            },
            onError(error, variables, context) {
                getData()
            },
        });

        CreateLogDelete({
            resource    : log_endpoint,
            values      : {
                user_profile    : user_data.user_profile.id,
                log_time_stamp  : Date.now(), 
                log_event       : event,
                menu_event      : 'project_info',
                SysPkID         : id+""
            }
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            // title   : 'Deactivate Confirm ?',
            title   : 'Inactive Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onStatusChange(v:any){
        // formProps.form?.submit();
        setKeepDataStatus(v)
        localStorage.setItem("keyTabStatusProInfo",v)
        //localStorage.setItem("keyTabUserPortal", v)
        
    }

    const onReset = () => {
        setInputName(undefined)
        setInputType(undefined)
        formProps.form?.resetFields()
        // formProps.form?.submit()
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : sorterOrder
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }

        if(pagination?.current){
            setPage(pagination?.current)
        }
        if(pagination?.pageSize){
            setPageSizeChange(pagination?.pageSize)
        }
    };
    
    const API_URL = useApiUrl();
    const getData = async ()=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(inputType){
                apiEndPoint += `&filters[project_type][pt_name]][$contains]=${inputType}`
            }
            if(inputName){
                apiEndPoint += `&filters[Project_Name][$contains]=${inputName}`
            }
            if(keepDataStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${keepDataStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${keepDataStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const resultList = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let project_type = null
                        let Project_Image = []
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.project_type?.data){
                            project_type = {id: attributes?.project_type?.data?.id , ...attributes?.project_type.data.attributes}
                        }
                        if(attributes?.Project_Image?.data && attributes?.Project_Image?.data?.length > 0){
                            Project_Image = attributes?.Project_Image?.data.map((item : any) => {
                                return {id: item?.id , ...item?.attributes}
                            })
                        }
                        const result = {id: item?.id , ...attributes}
                        result.createBy = createBy
                        result.updateBy = updateBy
                        result.project_type = project_type
                        result.Project_Image = Project_Image
                        return result
                    })
                    setListdata(resultList || [])
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setLoadingTable(false)
                }).catch((e) => {
                    console.log(e)
                    setLoadingTable(false)
                });
        } catch (error) {
            setLoadingTable(false)
        }
    }
    const getBadgeNumber = async (deleteStatus:any)=>{
        setLoadingTable(true)
        try {
            let apiEndPoint = API_URL + `/${ed_point}?populate=*&locale=th&pagination[pageSize]=${1}&pagination[page]=${1}`
            if(deleteStatus.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${deleteStatus}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${deleteStatus}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            apiEndPoint += `&sort[id]=desc`
    
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data
                    if(dataLoad?.meta?.pagination?.total){
                        const total = dataLoad?.meta?.pagination?.total > 0 ? dataLoad?.meta?.pagination?.total : null
                        if(deleteStatus.toLowerCase() == 'y'){
                            setTrashBadgeNumber(total)
                        }
                        else{
                            setActiveBadgeNumber(total)
                        }
                    }
                }).catch((e) => {
                    console.log(e)
                });
        } catch (error) {
        }
    }

    useEffect(() => {
        if (!inputType && !inputName && Identity_data) {
            getData()
        }
      }, [inputType, inputName]);
    useEffect(() => {
        if(Identity_data){
            getData()
        }
    }, [page,pageSizeChange,tableSorter,keepDataStatus,Identity_data])

    useEffect(() => {
        if(Identity_data){
            getBadgeNumber('Y')
            getBadgeNumber('N')
        }
    }, [Identity_data])

    return <>
            
            <List 
                title={t("project-info.title")}
                pageHeaderProps={{
                    breadcrumb: <Breadcrumb>
                        <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item>{t("project.title")}</Breadcrumb.Item>
                        <Breadcrumb.Item><span className="last_breadcrumb">{t("bc_project-info.title")}</span></Breadcrumb.Item>
                    </Breadcrumb>,
                    extra : <></>
                }}
            >

                <Row>
                    <Col span={18}>
                        <Form
                            {...formProps}
                            layout = "inline"
                            style={{marginBottom:"2%"}}
                        >
                            {/* <Form.Item name="q">
                                <Input style={{borderRadius: 20,border:"1px soLid #d9d9d9", height: 30,paddingLeft: 20}} placeholder="Search" prefix={<Icons.SearchOutlined />} allowClear/>
                            </Form.Item> */}

                            <Form.Item name="Project_Name" >
                                <Input
                                    onChange={(e) => setInputName(e.target.value)}
                                    prefix={<Icons.SearchOutlined />}
                                    style={{borderRadius: 4}} 
                                    placeholder="Search project name" size="middle" allowClear
                                />
                            </Form.Item>

                            <Form.Item name="Search type" >
                                <Input
                                    onChange={(e) => setInputType(e.target.value)}
                                    prefix={<Icons.SearchOutlined />}
                                    style={{borderRadius: 4}} 
                                    placeholder="Search project type" size="middle" allowClear
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={getData} htmlType="button" type="primary" style={{borderRadius: "5px"}}>
                                    <Icons.SearchOutlined /> Search
                                </Button>
                            </Form.Item>

                            <Form.Item >
                                <Button onClick={onReset} htmlType="reset" style={{borderRadius: "5px"}}>
                                    <Icons.UndoOutlined /> Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                        

                    <Col span={6}>
                        <Form
                            {...formProps}
                            layout = "inline"
                            style={{marginBottom:"2%",float: "right"}}
                        >
                            <Form.Item>
                                <ExportButton style={{borderRadius:5}}
                                    onClick={triggerExport}
                                    loading={exportLoading}
                                />
                            </Form.Item>

                            <Form.Item style={{marginRight: "0",}}>
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Form {...formProps}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            // defaultActiveKey={!tabProInfo ? keepDataStatus : `${tabProInfo}`}
                            defaultActiveKey={keepDataStatus}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={onStatusChange} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={activeBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={trashBadgeNumber} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Form>

                <Table 
                    dataSource={listData}
                    onChange={handleTableChange}
                    loading = {loadingTable}
                    bordered
                    size="middle"
                    rowKey = "id"
                    pagination={{ 
                        defaultPageSize:10, 
                        showSizeChanger: true, 
                        locale:{ items_per_page: "/ Pages"},
                        pageSizeOptions: pageSizeState, 
                        hideOnSinglePage: false,
                        current: metaPagination?.page || page,
                        pageSize: metaPagination?.pageSize || pageSizeChange,
                        total: metaPagination?.total,
                        // metaPagination?.pageCount
                        position:["bottomRight"]
                    }}
                >

                    {/* <Table.Column width={40} title="No." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}
                    {/* <Table.Column dataIndex="id" title={t("Lid")} align="center" width={150} sorter
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField value={value}  />
                            </>
                        }}
                    /> */}

                    {/* <Table.Column align="center" width={35} dataIndex="Project_Image" title="  " 
                    
                      render={(value:any,record:any)=>{
                            return <>
                                    {
                                        value? 
                                       
                                        <Avatar.Group>
                                             { value?.map((item:any,index:any)=> {
                                                return <>
                                               
                                        <Avatar src={item.url}/>
                                        </>
                                            })}
                                        </Avatar.Group>
                                        
                                        :<>
                                            <Avatar size={32} icon={<Icons.UserOutlined />} />
                                        </>
                                    }
                                </>
                        }}
                    /> */}

                    <Table.Column key={'[Project_Name]'} title="PROJECT NAME" align="center" width={300} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.Project_Name < b?.Project_Name) {return -1}
                            if (a?.Project_Name > b?.Project_Name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record? record.Project_Name:"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[project_type]'} title="PROJECT TYPE" align="center" width={300} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.project_type?.pt_name < b?.project_type?.pt_name) {return -1}
                            if (a?.project_type?.pt_name > b?.project_type?.pt_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                            {record.project_type ? 
                            <TextField style={{fontWeight: 600}} value={record.project_type !== undefined && record.project_type !== null ? record.project_type.pt_name:'-'}  />
                            :
                            <>-</>
                            }              
                            </>
                        }}
                    />

                    <Table.Column key={'[Project_Tag]'} title="PROJECT STATUS" align="center" width={300} className="table_colmn_custom_left_value"
                        sorter={(a:any, b:any) => {
                            if (a?.Project_Tag < b?.Project_Tag) {return -1}
                            if (a?.Project_Tag > b?.Project_Tag) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                            <TextField style={{fontWeight: 600}} value={record.Project_Tag !== undefined && record.Project_Tag !== null ? record.Project_Tag:'-'}  />
                            </>
                        }}
                    />

                    {/* <Table.Column dataIndex="Project_Tag" title="PROJECT TAG" 
                        render={(value:any,record:any)=>{
                            return <>
                                <Tag id="post_type_list"><TextField value={value}  /></Tag>
                            </>
                        }}
                    /> */}

                    {/* <Table.Column dataIndex="createdAt" title="Created At" 
                        render={(value:any,record:any)=>{
                            return <>
                                <span> DATE: </span>
                                <TextField value={Moment(value).format('MM/d/yyyy')}  />
                                <span> TIME: </span>
                                <TextField value={Moment(value).format('HH:mm a')}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="updatedAt" title="Updated At" 
                        render={(value:any,record:any)=>{
                            return <>
                                <span> DATE: </span>
                                <TextField value={Moment(value).format('MM/d/yyyy')}  />
                                <span> TIME: </span>
                                <TextField value={Moment(value).format('HH:mm a')}  />
                            </>
                        }}
                    />

                    <Table.Column dataIndex="Project_Tag" title="Project_Tag" 
                        render={(value:any,record:any)=>{
                            return <>

                                {
                                    record?.content_post_types.length !== 0 ?
                                        record?.content_post_types.map((data:any,index:any) => {
                                    return <>
                                        <Tag id="post_type_list"><TextField value={data? data.type_name : <></> }  /></Tag>
                                    </>
                                })
                            : <>-</>
                                }
                            </>
                        }}
                    />

                    <Table.Column dataIndex="status_show" title="State" width={50} align= "center"
                        render={(value:any,record:any)=>{
                            return <>
                                <Tag id="post_state_list"><TextField value={value ? 'public':'private'}  /></Tag>
                            </>
                        }}
                    /> */}
                    {/* merge */}
                    <Table.Column key={'[status]'} align="center" width={150} title="STATUS" 
                        sorter={(a:any, b:any) => {
                            if (a?.status < b?.status) {return -1}
                            if (a?.status > b?.status) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            // console.log(value);
                            // console.log(record);
                            let c = ""
                            c = (record.status === "ACTIVE") ?'green':(record.status === "INACTIVE") ?'processing':(record.status === "PENDING") ?'yellow':''
                            return <>
                                {record ?
                                    <Tag style={{borderRadius: "30px"}}
                                        color={c}
                                    >
                                        {
                                            record.status === "ACTIVE" ?
                                                "public"
                                            :
                                                "draft"
                                        }
                                    </Tag> 
                                :<></>
                                }
                            </>
                        }}
                    />

                    <Table.Column key={'[createBy][first_name]'} title="CREATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                            if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[createdAt]'} title="CREATE DATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.createdAt < b?.createdAt) {return -1}
                            if (a?.createdAt > b?.createdAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {
                                    record?.createdAt ?
                                        <TextField value={Moment(record?.createdAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />

                    <Table.Column key={'[updateBy][first_name]'} title="UPDATE BY" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                            if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"}  />
                            </>
                        }}
                    />

                    <Table.Column key={'[updatedAt]'} title="LAST UPDATE" align="center" width={100}
                        sorter={(a:any, b:any) => {
                            if (a?.updatedAt < b?.updatedAt) {return -1}
                            if (a?.updatedAt > b?.updatedAt) {return 1}
                            return (0)
                        }}
                        render={(value:any,record:any)=>{
                            return <>
                                {/* <TextField style={{fontWeight: 600}} value={record?.updatedAt? record?.updatedAt :"-"}  /> */}
                                {/* <TextField value={Moment(value).format('D/MM/yyyy HH:mm')}  /> */}
                                {/* <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  /> */}
                                {
                                    record?.updatedAt ?
                                        <TextField value={Moment(record?.updatedAt).format('D/MM/yyyy HH:mm')}  />
                                    :
                                        <TextField value={'-'}  />
                                }
                            </>
                        }}
                        
                    />
                  
                    <Table.Column<IProjectinfo>
                        width={50}
                        title    = "ACTIONS"
                        align    = "center"
                        dataIndex= "actions"
                        fixed = "right"
                        render={(_, record) => (
                            <Space>

                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}

                                >รายละเอียด</ShowButton>

                                {/* <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />

                                <DeleteButton
                                hideText
                                    size="small"
                                    recordItemId={record.id}
                                /> */}

                                {
                                    record.deleteStatus === "N" ? 
                                        <>
                                            <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                            />
                                            <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                    :
                                        <>
                                            {/* <EditButton
                                                hideText
                                                size="small"
                                                recordItemId={record.id}
                                                disabled
                                            /> */}
                                            <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                        </>
                                }
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </>
}