import { IResourceComponentsProps, useCustom, useGetIdentity, useList, useNavigation, useTranslate } from "@pankod/refine-core"
import axios from "axios";
import { petURL } from "../../../constants";
import { Button, Col, EditButton, Form, Icons, Input, Modal, Row, Select, Spin, Table, Tag, TextField } from "@pankod/refine-antd";
import { useState } from "react";
import dayjs from "dayjs";
import { WarningOutlined, QuestionCircleOutlined } from "@ant-design/icons";

let dataTBLOCAL: any = undefined;
let optionpettype: any = undefined;
export const PetSizeList: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { replace, list} = useNavigation();
    const { confirm } = Modal;
    const [pageSize, setPageSize] = useState<any>(10);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [valuepetType, setpetType] = useState<any>();
    const [dataTB, setdataTB] = useState<any>([]);
    const [SearchSpecis, setSearchSpecies] = useState<any>(undefined);

    const {data: user_dt} = useGetIdentity<any>();
    const [formSearch] = Form.useForm();

    const { data: dataLoad, refetch } = useCustom<any>({
        url: `${petURL}/api/lscpet/findAllPetSize`,
        method: "get",
        queryOptions:{
            onSuccess(data) {
                let dataLoad: any = data?.data;
                setdataTB(dataLoad);
                dataTBLOCAL = dataLoad;
                setLoading(false);

            },
        },
    });

    async function updateStatus(id:any, val:any){
        // console.log('id', id)
        let data = {
            "is_active": val,
            "update_by": {
                "user_id": user_dt?.user_profile?.id,
                "user_name": user_dt?.user_profile?.first_name + " " + user_dt?.user_profile?.last_name
            },
            "update_date": new Date().toISOString()
        }
        let res_update = await axios.put(
            `${petURL}/api/lscpet/updatePetSpecies?id=` + id , data ,
            { headers: {  } }).then((res) => {
                let dataLoad: any = res?.data;
                // //console.log('dataLoad Check', dataLoad)
            }
        );
        refetch();
    }
    
    function showDelConfirm(id:any) {
        confirm({
            title   : 'Delete Confirm ?', 
            icon    : <WarningOutlined />,
            content : 'Click Confirm to Delete this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, false);
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Restore Confirm ?',
            icon    : <QuestionCircleOutlined />,
            content : 'Click Confirm to restore this data.',
            okText  : 'Confirm',
            onOk() {
                updateStatus(id, true);
            },
            onCancel() { },
        });
    }

    const columns:any = [
        {
            title: `${t("pet-size.name-th")}`,
            dataIndex: 'pet_type_name',
            key: 'pet_type_name',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_size_name < b?.pet_size_name) {return -1}
                if (a?.pet_size_name > b?.pet_size_name) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                // return (<span>{record?.pet_type_name ? record?.pet_type_name : '-'}</span>)
                return (<span>{record?.pet_size_name ? record?.pet_size_name : '-'}</span>)
            },
        },
        {
            // title: 'ขนาด (EN)',
            title: `${t("pet-size.name-en")}`,
            dataIndex: 'pet_species_name',
            key: 'pet_species_name',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => {
                if (a?.pet_size_name_en < b?.pet_size_name_en) {return -1}
                if (a?.pet_size_name_en > b?.pet_size_name_en) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.pet_size_name_en ? record?.pet_size_name_en : '-'}</span>)
            },
        },
        {
            title: `${t("pet-species.status")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.is_active < b?.is_active) {return -1}
                if (a?.is_active > b?.is_active) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (
                    <span>{record?.is_active ? <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"success"}>Active</Tag> : <Tag style={{borderRadius: "30px",padding: "1% 7% 1%"}} color={"error"}>Inactive</Tag>}</span>
                )
            },
        },
        {
            title: `${t("pet-species.create")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.create_by?.user_name.localeCompare(b?.create_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.create_by ? record?.create_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-species.created-date")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.c < b?.create_date) {return -1}
                if (a?.create_date > b?.create_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.create_date ? dayjs(record?.create_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-species.edit")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a?.update_by?.user_name.localeCompare(b?.update_by?.user_name),
            sortDirections : ['ascend', 'descend'],
            render: (value:any, record:any) => {
                return (<span>{record?.update_by ? record?.update_by?.user_name : '-'}</span>)
            },
        },
        {
            title: `${t("pet-species.last-update")}`,
            //dataIndex: '',
            //key: '',
            width: 140,
            align: 'center',
            sorter: (a:any, b:any) => {
                if (a?.update_date < b?.update_date) {return -1}
                if (a?.update_date > b?.update_date) {return 1}
                return (0)
            },
            render: (value:any, record:any) => {
                return (<span>{record?.update_date ? dayjs(record?.update_date).format("DD-MM-YYYY HH:mm") : '-'}</span>)
            },
        },
        {
            title: `${t("pet-species.action")}`,
            dataIndex: '',
            key: 'action',
            fixed: 'right',
            width: 90,
            align: 'center',
            render: (value:any, record:any) => {
                // return (<EditButton hideText size="small" onClick={()=> replace("/pet-species/edit/" + record?._id)}/>);
                return (
                    <>
                    <EditButton style={{marginRight: '2px'}} hideText size="small" onClick={()=> replace("/pet-size/edit/" + record?._id)}/>
                    {/* {
                        record.is_active ?  
                            <span>
                                <Button 
                                    size="small" 
                                    title="Delete" 
                                    icon={<Icons.DeleteOutlined />} 
                                    onClick={() => { showDelConfirm(record._id) }} 
                                    danger
                                ></Button>
                            </span>
                        :
                            <Button 
                                size="small" 
                                title="Active" 
                                icon={<Icons.RetweetOutlined />} 
                                onClick={() => { showUnlockConfirm(record._id) }} 
                            ></Button>
                    } */}
                    </>
                );
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onsearchTB (data: any, mode: any){
        if(mode == "S"){
            // let newDT: any = data;    

            let newDT: any = dataTBLOCAL;    

            if(SearchSpecis !== undefined){
                newDT = newDT?.filter((e:any)=> e?.pet_species_name?.includes(String(SearchSpecis))) ;
                setdataTB(newDT);
            }

            if(valuepetType){
                // newDT = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType));
                newDT = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType?.key));

                setdataTB(newDT);
            }

            // if(valuepetType){
            //     let dtFilter: any = newDT?.filter((f: any)=> f?.pet_type == String(valuepetType));
            //     setdataTB(dtFilter);
            // }else{ //กรณีกดลบ select แล้วกดปุ่ม search แทนปุ่ม reset
            //     setdataTB(data)
            // }
        }else if(mode == "R"){
            let newDT: any = data;
            
            setdataTB(newDT);
            setpetType(undefined);
            setSearchSpecies(undefined)
            // formSearch?.resetFields(['pet-type']);
            formSearch?.resetFields();
        }
    }

    return (
        <div className="layout_panel">
            <Spin size="large" spinning={isLoading}>

                {/* <Form 
                    form={formSearch}
                    layout="vertical"
                    style={{marginBottom: "1rem"}}
                >
                    <div className="layout_custom_search">
                        <Form.Item 
                            label=" "
                            name="pet-type"
                            className="visible-label"
                            style={{marginBottom: 0, display: "inline-block"}}
                        >
                            <Select
                                options={optionpettype}
                                placeholder={t("Pet type")}
                                labelInValue
                                showSearch
                                allowClear
                                onChange={(e: any)=> setpetType(e)}
                                onClear={()=> setpetType(undefined)}
                                style={{width: "250px", marginRight: "15px"}}
                            />
                        </Form.Item>

                        <Form.Item 
                            label=" "
                            name="pet-species"
                            className="visible-label"
                            style={{marginBottom: 0, display: "inline-block"}}
                        >
                            <Input 
                                placeholder="Pet species"
                                style={{borderRadius: "5px", width: "250px", marginRight: "15px"}}
                                onChange={(e) => { if(e.target.value.length > 0){ setSearchSpecies(e?.target?.value)}else{setSearchSpecies(undefined)}}}
                                allowClear
                            />
                        </Form.Item>

                        <Button 
                            icon={<Icons.SearchOutlined />} 
                            style={{borderRadius: "5px", marginRight: "15px"}} 
                            type="primary" 
                            onClick={()=> {
                                onsearchTB(dataTBLOCAL, "S")
                            }}
                        >
                            {t("buttons.search")}
                        </Button>

                        <Button 
                            icon={<Icons.ReloadOutlined />} 
                            style={{borderRadius: "5px"}} 
                            onClick={()=> {
                                onsearchTB(dataTBLOCAL, "R")
                            }}
                        >Reset</Button>
                    </div>
                </Form> */}

                <Table 
                    dataSource={dataTB ? dataTB : []} 
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                        size: "small",
                        position: ['bottomRight'],
                        pageSizeOptions: ['10', '30', '50', '100'], 
                        showSizeChanger: true, 
                        pageSize: pageSize,
                        onChange: handlePageSizeChange,
                    }}
                    scroll={{ x: 900 }}
                />
            </Spin>
        </div>
    )
}