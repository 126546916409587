/* eslint-disable */
import { Badge, Breadcrumb, Button, Col, CreateButton, DatePicker, EditButton, Form, Icons, ImportButton, Input, InputNumber, List, Modal, Radio, Row, ShowButton, Space, Table, Tabs, Tag, TextField, useImport, useModalForm, useTable } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { ICoupon, ICouponGroup } from "interfaces";
import { useEffect, useState } from "react";
// import { Dayjs } from "dayjs";
import axios from "axios";
import dayjs from "dayjs";
import { TOKEN_KEY } from "../../constants";

let coupon_data:any = [];

export const CouponGroupList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Coupon Group");
    const { TabPane } = Tabs;
    const [disabled, setdisabled] = useState<boolean>(true);
    const [vStartDate, setStartDate] = useState<any>(undefined);
    const [vExpireDate, setExpireDate] = useState<any>(undefined);
    const [vArrCoupon, setArrCoupon] = useState<any>([]);
    const [vStatUpload, setStatUpload] = useState<any>(false);
    const [vTotal, setTotal] = useState<any>(0);
    const [vCouponType, setCouponType] = useState<any>();
    const [ vCouponDataSource, setCouponDataSource ] = useState<any>();

    const { mutate: createCoupon } = useCreate<any>();
    const { confirm } = Modal;
    const { mutate } = useUpdate<ICoupon>();
    const ed_point = "coupon-groups";

    const [page, setPage] = useState(1);
    const [pageSizeChange, setPageSizeChange] = useState(10);
    const pageSizeState = [ '10','30', '50', '100'];

    const [keepInput, setKeepInput] = useState<any>()
    const [keepInputClick, setKeepInputClick] = useState<any>()
    const [table, setTable] = useState<any>()
    const [statusData, setStatusData] = useState<any>("N")
    const [ count, setCount] = useState(0);
    const [ isLoadingK, setIsLoadingK] = useState(true);
    const [metaPagination, setMetaPagination] = useState<any>(null);
    const [tableSorter, setTableSorter] = useState<any>(undefined);


    // const { mutate: CreateLogDelete }       = useCreate<IEventlog>();
    // const log_endpoint                      = "even-logs";
    const userQuery                         = useGetIdentity();
    const {data : user_data , isLoading}    = userQuery;
    const vCurDelStatus                     = {deleteStatus : 'Y'}

    const { tableProps,searchFormProps,tableQueryResult } = useTable<ICouponGroup,HttpError, ICouponGroup>({
        metaData: {
            // populate: '*'
            populate: ['coupons', 'createBy', 'module_master.child']
        },
        onSearch: (params) => {
            const filters: CrudFilters = [];
            const { group_name,deleteStatus } = params;
            filters.push(
                {
                    field       : "group_name",
                    operator    : "contains",
                    value       : group_name
                },
                {
                    field       : "deleteStatus",
                    operator    : "eq",
                    value       : deleteStatus
                },
            );
            return filters;
        },
        initialFilter: [
            {
                field       : "deleteStatus",
                operator    : "eq",
                value       : "N"
            },
        ],
        queryOptions: {
            onSuccess(data:any) {
                
            },
            // refetchInterval:2000
        },
    });

//    const tablegroup = useList<any>({
//         resource: "coupon-groups",
//         liveMode: "auto",
//         metaData: {
//         //   populate: ["*","group_name"],
//           populate: ['coupons', 'group_name', 'createBy', 'module_master.child']

//         },
//         config: { 
//           sort: [{ order: "asc", field: "id" }],
//           filters: [
//             {
//               field: "group_name",
//               operator: keepInput ? "contains": "nin", 
//               value: keepInput,
//             },
//             {
//                 field     : "deleteStatus",
//                 operator  : "eq",
//                 value     : "N"
//             },
//           ],
//         },
        
//         queryOptions: {
//           onSuccess(data:any) {
//             // console.log("tark-config",data?.data)
//             setTable(data?.data)
//           },
//         },
//       });

    // const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
    //     resource: "coupon-groups",
    //     metaData: {
    //         populate: ['coupons', 'group_name', 'createBy', 'module_master.child']
    //     },
    //     permanentFilter: [
    //         {
    //             field: "group_name",
    //             operator: keepInputClick ? "contains": "nin", 
    //             value: keepInputClick,
    //         },
    //         {
    //             field     : "deleteStatus",
    //             operator  : "eq",
    //             // value     : "N"
    //             value     : !!statusData ? statusData : "N"
    //         },
    //     ],
    //     queryOptions: {
    //         onSuccess(data:any) {
    //           // console.log("tark-config",data?.data)
    //           setTable(data?.data)
    //           setIsLoadingK(false)
    //         },
    //       },

    // })

    // useEffect(() => {
    //     if (count < 3) { 
    //         const interval = setInterval(() => {
    //             setCount(prev => prev + 1)
    //             // console.log('k', count)
    //             refetch();
    //         }, 1000)
    //         return () => clearInterval(interval)
    //     }
    // }, [count])
    useEffect(() => {
        getData()
    }, [page,pageSizeChange,tableSorter,keepInputClick,statusData])

    // const dataCoupons = useList<any>({ 
    //     metaData: {
    //         populate: '*'
    //     },  
    //     resource: "coupons" ,
    //     config:{
    //         sort: [{ order: "desc", field: "id" }],
    //         filters: [
    //             {
    //                 field       : "deleteStatus",
    //                 operator    : "in",
    //                 value       : ["N","Y"]
    //             },
    //         ],
    //         pagination:{current: 1, pageSize: 10000000}
    //     }
    // });
    // const {data:dataCouponss} = dataCoupons
    // console.log('dataCouponss', dataCouponss)

    const datacpg = useList<ICouponGroup>({ 
        resource: "coupon-groups" ,
        config:{
            sort: [{ order: "desc", field: "id" }],
            filters: [
                {
                    field       : "deleteStatus",
                    operator    : "in",
                    value       : ["N","Y"]
                },
            ],
            pagination:{current: 1, pageSize: 10000000}
        },
        // queryOptions: {
        //     onSuccess(data:any) {
        //         console.log("ดูdatacpg",data?.data)
                
        //     },
        // },
    });
    const {data:dataCPG} = datacpg

    const importProps = useImport<any>({
        mapData: async (item) => {
            // let coupon_name:any;
            // let coupon_code:any;
            let coupon_active:any;

            if(item.coupon_active !== ""){
                coupon_active = item.coupon_active.split("-");
            }

            if(item.coupon_name === ""){
                console.log('coupon_name data is empty')
            }else if(item.coupon_code === ""){
                console.log('coupon_code data is empty')
            }else{
                coupon_data.push(
                    {
                        coupon_name : item.coupon_name,
                        coupon_code : item.coupon_code,
                        coupon_active : coupon_active[0] == "1" ? true : false,
                        coupon_group : null
                    }
                );
                setArrCoupon(coupon_data)
                vArrCoupon.push(vArrCoupon)
            }
            await sleep(2000);
        },
        onProgress(onProgressParams) {
            setTotal(onProgressParams.totalAmount);
            createProps?.form?.setFieldsValue({quantity: onProgressParams.totalAmount});
        },
        onFinish(results) {
            setStatUpload(true);
            setdisabled(false);
        },
        resourceName: 'coupons'
    });

    const columns = [
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
    ];

    const warning = () => {
        Modal.warning({
        //   title: <span>{t("msg-facility-booking.title-warning")}</span>,
        //   content: <span>{t("msg-facility-booking.content-warning")}</span>,
          title: <span>Warning</span>,
          content: <span>Please import coupon file</span>,
        });
    };

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    function updateStatus(id:any, event:any){
        mutate({
            resource    : ed_point,
            id          : id+"",
            values      : vCurDelStatus,
            successNotification:false,
            errorNotification:false
        });
    }

    function showDelConfirm(id:any) {
        confirm({
            title   : 'Inactive Confirm ?', 
            icon    : <Icons.WarningOutlined />,
            content : 'Click Confirm to deactivate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'Y'
                updateStatus(id, "Inactive");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function showUnlockConfirm(id:any) {
        confirm({
            title   : 'Activate Confirm ?',
            icon    : <Icons.QuestionCircleOutlined />,
            content : 'Click Confirm to activate this data.',
            okText  : 'Confirm',
            onOk() {
                vCurDelStatus.deleteStatus = 'N'
                updateStatus(id, "Active");
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                }).catch(() => console.log('something wrong.'));
            },
            onCancel() { },
        });
    }

    function onShowSizeChange(current:any, pageSize:any) {
        setPageSizeChange(pageSize)
    }
    
    function onStatusChange(v:any){
        searchFormProps.form?.submit();
        localStorage.setItem("keyU",v)
        localStorage.setItem("keyTabUserPortal", v)
    }

    const {
        modalProps: createModalProps,
        formProps: createProps,
        show: createModalShow,
        close: createClose
    } = useModalForm<any>({
        resource: "coupon-groups",
        metaData: {
            populate: '*',
        },
        action: "create",
        redirect: false,
        async onMutationSuccess(data, variables, context) {
            let recordData:any = data?.data? data?.data : [];
            for(let i=0; i<= vArrCoupon.length -1; i++){
                createCoupon({
                    resource    : 'coupons',
                    values      : {
                        coupon_code     : vArrCoupon[i].coupon_code,
                        coupon_name     : vArrCoupon[i].coupon_name, 
                        coupon_active   : vArrCoupon[i].coupon_active,
                        coupon_group    : recordData.data.id + "",
                        createBy        : user_data.user_profile.id
                    },
                    successNotification : false
                });
                await sleep(1000);
            }
        }
    });

    const {
        modalProps: editModalProps,
        formProps: editProps,
        formLoading ,
        show: editModalShow,
        close: editClose
    } = useModalForm<any>({
        resource: "coupon-groups",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data
            console.log("dataLoad",dataLoad)
            setCouponDataSource(dataLoad?.coupons ? dataLoad?.coupons : [])

            if(dataLoad?.couponType == "FIXED"){
                setCouponType(1);
            }else if(dataLoad?.couponType == "RUNNING"){
                setCouponType(2);
            }else if(dataLoad?.couponType == "THIRDPARTY"){
                setCouponType(3);
            }
        }},
    });

    const {
        modalProps: showModalProps,
        formProps: showProps,
        formLoading : showformLoading ,
        show: showModalShow,
        close : showClose
    } = useModalForm<any>({
        resource: "coupon-groups",
        metaData: {
            populate: '*',
        },
        action: "edit",
        redirect: false,
        queryOptions:{onSuccess(data){
            let dataLoad = data?.data

            setCouponDataSource(dataLoad?.coupons ? dataLoad?.coupons : [])

            if(dataLoad?.couponType == "FIXED"){
                setCouponType(1);
            }else if(dataLoad?.couponType == "RUNNING"){
                setCouponType(2);
            }else if(dataLoad?.couponType == "THIRDPARTY"){
                setCouponType(3);
            }
        }},
    });

    const API_URL = useApiUrl();
    const getData = async ()=>{
        try {
            setIsLoadingK(true)
            const AuthStr = 'Bearer '.concat(TOKEN_KEY);
            let apiEndPoint = API_URL + `/coupon-groups?populate=coupons&populate=createBy&populate=updateBy&populate=module_master.child'&pagination[pageSize]=${pageSizeChange}&pagination[page]=${page}`
            if(keepInputClick){
                apiEndPoint += `&filters[group_name][$contains]=${keepInputClick}`
            }
            if(statusData.toLowerCase() == 'y'){
                apiEndPoint += `&filters[deleteStatus]=${statusData}`
            }
            else{
                apiEndPoint += `&filters[\$or][0][deleteStatus]=${statusData}`
                apiEndPoint += `&filters[\$or][1][deleteStatus][$null]=true`
            }
            if(tableSorter?.title && tableSorter?.order){
                apiEndPoint += `&sort${tableSorter?.title}=${tableSorter?.order}`
            }
            else{
                apiEndPoint += `&sort[id]=desc`
            }
            await axios.get(
                apiEndPoint, 
                { headers: { Authorization: AuthStr } }).then((res) => {
                    const dataLoad: any = res?.data;
                    const couponGroups = dataLoad?.data?.map((item: any) => {
                        const attributes = item?.attributes
                        let createBy = null
                        let updateBy = null
                        let coupons = []
                        if(attributes?.createBy?.data){
                            createBy = {id: attributes?.createBy?.data?.id , ...attributes?.createBy.data.attributes}
                        }
                        if(attributes?.updateBy?.data){
                            updateBy = {id: attributes?.updateBy?.data?.id , ...attributes?.updateBy.data.attributes}
                        }
                        if(attributes?.coupons?.data){
                            coupons = attributes?.coupons?.data?.map((couponData: any) => {
                                const couponAttributes = couponData?.attributes
                                if(couponAttributes){
                                    return {id: couponData.id , ...couponAttributes}
                                }
                                else{
                                    return couponData
                                }
                            })
                        }
                        const result = {
                            id: item.id,
                            group_name: attributes?.group_name,
                            deleteStatus: attributes?.deleteStatus,
                            shortDetail: attributes?.shortDetail,
                            quantity: attributes?.quantity,
                            couponType: attributes?.couponType,
                            start_date: attributes?.start_date,
                            expire_date: attributes?.expire_date,
                            createBy: createBy,
                            updateBy: updateBy,
                            coupons: coupons
                        }
                        return result
                    })
                    setTable(couponGroups)
                    setMetaPagination(dataLoad?.meta?.pagination)
                    setIsLoadingK(false)
                }).catch((e) => {
                    console.log(e)
                    setIsLoadingK(false)
                });
        } catch (error) {
            setIsLoadingK(false)
        }
    }

    const handleTableChange = (pagination:any, filters:any, sorter:any) => {
        // Handle sorting event here
        if((sorter?.column?.key || sorter?.column?.title) && sorter?.order){
            const sorterOrder = `${sorter?.order}`.toLowerCase()
            const order = sorterOrder == 'ascend' ? 'asc' :sorterOrder == 'descend' ? 'desc' : undefined
            setTableSorter({
                title: sorter?.column?.key || `[${sorter?.column?.title}]`,
                order: order ,
            })
        }
        else{
            setTableSorter(undefined)
        }
    };

    const onReset = () => {
        setKeepInputClick(undefined);
        searchFormProps.form?.resetFields()
        searchFormProps.form?.submit()
    };

    return <>
        <List 
            title={t("coupon-group.title")}
            // title=""
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("content-post.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("coupon-group.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            
            <Form
                {...searchFormProps}
            >
                <Row>
                    <Col className="gutter-row" span={4} style={{marginRight:'10px'}}>
                        <Form.Item name="in">
                            <Input placeholder="Search Coupon Group Name"
                                style={{borderRadius: "5px"}}
                                onChange={(e) => setKeepInput(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row">
                        <Form.Item>
                            <Button type="primary" style={{borderRadius: "5px", marginRight:'10px'}} 
                                onClick={()=> setKeepInputClick(keepInput)}
                            >
                                <Icons.SearchOutlined /> Search
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col className="gutter-row">
                        <Form.Item>
                        <Button 
                            onClick={() => {
                                onReset()
                            }} 
                            style={{borderRadius: "5px", marginRight:'10px'}}
                            icon={<Icons.UndoOutlined />}
                        >
                            Reset
                        </Button>
                        </Form.Item>
                    </Col>

                    {/* <SearchOutlined style={{fontSize: '20px'}}/> */}
                    <Col span={10}/>
                    <Col span={6}>
                        <Form.Item style={{marginRight: "0", float: "right"}}>
                            {/* <Form.Item> */}
                                <Button style={{borderRadius:5, marginRight: "8px"}} 
                                    icon={<Icons.DownloadOutlined />}
                                >
                                    {/* <a style={{color:'#595959'}} href="https://lscmain.s3.ap-southeast-1.amazonaws.com/coupon_template_1_dacc2d37fe.xlsx?updated_at=2023-01-18T10:53:50.933Z"> Download Template</a> */}
                                    <a style={{color:'#595959'}} href="https://lscmain.s3.ap-southeast-1.amazonaws.com/coupon_template_1_dacc2d37fe_1_7ea7f83582.xlsx?updated_at=2023-05-08T06:24:07.199Z"> Download Template</a>
                                </Button> 
                            {/* </Form.Item> */}


                            {/* <Form.Item style={{marginRight: "0",}}> */}
                                <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/>
                                {/* <CreateButton id="create_btn" disabled={data_permission?.create ? data_permission?.create == false ? true : false : true }/> */}
                            {/* </Form.Item> */}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Form
                {...searchFormProps}
            >
                <Form.Item name="deleteStatus" >
                    <Tabs 
                        defaultActiveKey={"N"}
                        style={{ marginTop: 10, marginBottom: 10 }} 
                        onChange={(e) => {
                                setStatusData(e);
                                onStatusChange;
                            }
                        } 
                    >
                        <TabPane
                            key={"N"}
                            tab={
                                <span>
                                    {"ACTIVE"}
                                    <Badge count={dataCPG?.data.filter((e:any)=> e.deleteStatus == "N").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        />

                        <TabPane
                            key={"Y"}
                            tab={
                                <span>
                                    {"TRASH"}
                                    <Badge count={dataCPG?.data.filter((e:any)=> e.deleteStatus == "Y").length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                </span>
                            }
                        />
                    </Tabs>
                </Form.Item>
            </Form>

            {/* <Table {...tableProps}  */}
            <Table
                dataSource={table}
                bordered
                size="middle"
                rowKey = "id"
                loading={isLoadingK}
                onChange={handleTableChange}
                pagination={{ 
                    defaultPageSize:10, 
                    showSizeChanger: true, 
                    locale:{ items_per_page: "/ Pages"},
                    pageSizeOptions: pageSizeState, 
                    onShowSizeChange , 
                    onChange(Current){ setPage(Current)},
                    hideOnSinglePage: false,
                    current: metaPagination?.page || page,
                    pageSize: metaPagination?.pageSize || pageSizeChange,
                    total: metaPagination?.total,
                    // metaPagination?.pageCount
                    position:["bottomRight"]
                }}
            >

                {/* <Table.Column width={30} title="NO." key='index' render={(value, item, index) => (page - 1) * pageSizeChange + index + 1}/> */}

                <Table.Column key={'[group_name]'} title={t("coupon-group.col-group-name")} align="left" width={200}
                    sorter={(a:any, b:any) => {
                        if (a?.group_name < b?.group_name) {return -1}
                        if (a?.group_name > b?.group_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        //console.log("record",record)
                        return <>
                            <TextField value={record? record.group_name:"-"}  />
                        </>
                    }}
                />

                <Table.Column dataIndex="coupons" title={t("coupon-group.col-coupons")} align="left" width={800}
                    render={(value:any,record:any)=>{
                        // console.log('value coupon', value)
                        // console.log('record coupon', record)
                        return <>
                            {/* {
                                record.coupons &&
                                    record?.coupons?.length > 0 ?
                                        record?.coupons?.map((data:any,index:any) => {
                                            return <>
                                                <Tag><TextField value={data.coupon_name}/></Tag>
                                            </>
                                        })
                                    : <Tag>creating coupon...</Tag>
                            } */} 

                            {
                                record.coupons && record.coupons.length > 0 ?
                                    record.coupons.slice(0, 3).map((data:any, index:any) => {
                                        return (
                                            <Tag key={index}>
                                                <TextField value={data.coupon_name} />
                                            </Tag>
                                        );
                                    })
                                    : <Tag>creating coupon...</Tag>
                            }
                            {record.coupons && record.coupons.length > 3 && '...'}


                            {/* {
                                record.coupons &&
                                    record?.coupons.length !== 0 ?
                                        dataCouponss?.data.map((value:any, index:any) => {
                                            if(value.coupon_group !== null){
                                                if(value.coupon_group.group_name == record.group_name){
                                                    return <>
                                                        <Tag><TextField value={value.coupon_name}/></Tag>
                                                    </>
                                                }
                                            }
                                        })
                                    : <Tag>ไม่ระบุ</Tag>
                            } */}

                            {/* {
                                dataCouponss?.data.map((value:any, index:any) => {
                                    if(value.coupon_group !== null){
                                        if(value.coupon_group.group_name == record.group_name){
                                            return <>
                                                <Tag><TextField value={value.coupon_name}/></Tag>
                                            </>
                                        }
                                    }
                                })
                            } */}

                        </>
                    }}
                />

                <Table.Column key={'[quantity]'} title="Quantity" align="center" width={80}
                    sorter={(a:any, b:any) => {
                        if (a?.quantity < b?.quantity) {return -1}
                        if (a?.quantity > b?.quantity) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.quantity? record?.quantity : "-"}  />
                        </>
                    }}
                />

                <Table.Column key={'[createBy][first_name]'} title={t("coupon-group.col-createby")} align="center" width={80} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.createBy?.first_name < b?.createBy?.first_name) {return -1}
                        if (a?.createBy?.first_name > b?.createBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.createBy? record?.createBy.first_name :"-"}  />
                        </>
                    }}
                />

                <Table.Column title={"CREATED DATE"}  align="center" width={120}
                   sorter={(a:any, b:any) => {
                       if (a?.createdAt < b?.createdAt) {return -1}
                       if (a?.createdAt > b?.createdAt) {return 1}
                       return (0)
                   }}
                   render={(value:any,record:any)=>{
                       return <>
                          
                           <TextField style={{fontWeight: 600}} value={record? dayjs(record?.createdAt).format("DD-MM-YYYY HH:mm") :"-"} />
                       </>
                   }}
               />

                <Table.Column key={'[updateBy][first_name]'} title={t("coupon-group.col-updateby")} align="center" width={80} className="table_colmn_custom_left_value"
                    sorter={(a:any, b:any) => {
                        if (a?.updateBy?.first_name < b?.updateBy?.first_name) {return -1}
                        if (a?.updateBy?.first_name > b?.updateBy?.first_name) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.updateBy? record?.updateBy.first_name :"-"} />
                        </>
                    }}
                />

                <Table.Column dataIndex="updatedAt" title="UPDATE DATE"  align="center" width={120}
                    sorter={(a:any, b:any) => {
                        if (a?.updatedAt < b?.updatedAt) {return -1}
                        if (a?.updatedAt > b?.updatedAt) {return 1}
                        return (0)
                    }}
                    render={(value:any,record:any)=>{
                        return <>
                            <TextField style={{fontWeight: 600}} value={record?.updatedAt? dayjs(record?.updatedAt).format("DD-MM-YYYY HH:mm") :"-"}  />
                        </>
                    }}
                />

                <Table.Column<ICoupon>
                    width={50}
                    title    = "ACTIONS"
                    align    = "center"
                    dataIndex= "actions"
                    fixed = "right"
                    render={(_, record) => (
                        <Space>

                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => showModalShow(record.id)}
                            />

                            {
                                record.deleteStatus === "N" ? 
                                    <>
                                        <EditButton
                                            hideText
                                            size="small" 
                                            recordItemId={record.id}
                                            // onClick={()=> editModalShow(record.id)}
                                            disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }
                                        />
                                        <Button size="small" title="Delete" icon={<Icons.DeleteOutlined />} onClick={() => { showDelConfirm(record.id) }} danger disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                                    </>
                                :
                                    <Button size="small" title="Active" icon={<Icons.RetweetOutlined />} onClick={() => { showUnlockConfirm(record.id) }} disabled={data_permission?.update ? data_permission?.update == false ? true : false : true }/>
                            }
                        </Space>
                    )}
                />
            </Table>
        </List>

        <Modal 
            {...createModalProps}
            title={"Coupon Group Create"}
            okText = {"Create"}
            footer={<>
                <Button onClick={()=> createClose()}>Cancle</Button>
                <Button type="primary" disabled={disabled} onClick={() => createProps?.form?.submit()}>Create</Button>
                </>
            }
            maskClosable={false}
            width={500}
            afterClose={()=> {
                createProps?.form?.resetFields();
                setdisabled(true);
                setTotal(0);
                setStartDate(undefined);
                setExpireDate(undefined)
                setArrCoupon([])
                setStatUpload(false)
            }}
        >
            <Form 
                {...createProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    values.group_name = values?.group_name;
                    values.quantity = values?.quantity;
                    values.shortDetail = values?.shortDetail;

                    if(values?.couponType == 1){
                        values.couponType = "FIXED"
                    }else if(values?.couponType == 2){
                        values.couponType = "RUNNING"
                    }else if(values?.couponType == 3){
                        values.couponType = "THIRDPARTY"
                    }else{
                        values.couponType = null
                    }

                    values.start_date = vStartDate
                    values.expire_date = vExpireDate

                    values.deleteStatus = "N"
                    values.createBy = user_data.user_profile.id

                    if(vStatUpload){
                        return (
                            createProps.onFinish &&
                            createProps.onFinish(values)
                        );
                    }else if(vStatUpload == false){
                        warning();
                    }
                }}
            >
                <Row gutter={8}>
                    {disabled == true &&
                        <Col span={24} className="gutter-row">
                            <Form.Item>
                                <ImportButton
                                    {...importProps}
                                />
                            </Form.Item>
                        </Col>
                    }

                    <Col span={18} className="gutter-row">
                        <Form.Item  
                            label={t("coupon-group.f-group-name")}
                            name="group_name"
                            rules={[
                                {
                                    required: true, message: 'Please enter coupon group name.'
                                },
                            ]}
                        >
                            <Input disabled={disabled}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item  
                            label="Quantity"
                            name="quantity"
                            className={disabled == false ? "input_disable_onshow" : ""}
                        >
                            <InputNumber 
                                defaultValue={vTotal}
                                placeholder="จำนวนสิทธิ"
                                min={0}
                                style={{width: "100%"}}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label="Short Detail"
                            name="shortDetail"
                        >
                            <Input disabled={disabled}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Start Date"
                            name="start_date"
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setStartDate(dateString)}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Expire Date"
                            name="expire_date"
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setExpireDate(dateString)}
                                disabled={disabled}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item label="Coupon Type" name="couponType">
                            {/* <Radio.Group options={optRadioCouponType} onChange={onChangeCouponType} value={vCouponType} />   */}
                            <Radio.Group disabled={disabled}>
                                <Radio value={1}>{t("coupon.ra-fixed")}</Radio>
                                <Radio value={2}>{t("coupon.ra-running")}</Radio>
                                <Radio value={3}>{t("coupon.ra-third")}</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal 
            {...editModalProps}
            title={"Coupon Group Edit"}
            okText = {"Save"}
            maskClosable={false}
            width={500}
            afterClose={()=> {
                editProps?.form?.resetFields();
                setdisabled(true);
                setTotal(0);
                setStartDate(undefined);
                setExpireDate(undefined);
                setArrCoupon([]);
                setStatUpload(false);
            }}
        >
            <Form 
                {...editProps} 
                layout="vertical"
                onFinish={(values:any) => {
                    values.group_name = values?.group_name;
                    values.shortDetail = values?.shortDetail;
                    if(vCouponType == 1){
                        values.couponType = "FIXED"
                    }else if(vCouponType == 2){
                        values.couponType = "RUNNING"
                    }else if(vCouponType == 3){
                        values.couponType = "THIRDPARTY"
                    }else{
                        values.couponType = null
                    }

                    values.editProps = vStartDate
                    values.editProps = vExpireDate

                    values.deleteStatus = "N"
                    values.updateBy = user_data.user_profile.id

                    return (
                        editProps.onFinish &&
                        editProps.onFinish(values)
                    );
                }}
            >
                <Row gutter={8}>
                    <Col span={18} className="gutter-row">
                        <Form.Item  
                            label={t("coupon-group.f-group-name")}
                            name="group_name"
                            rules={[
                                {
                                    required: true, message: 'Please enter coupon group name.'
                                },
                            ]}
                        >
                            <Input disabled={false}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item  
                            label="Quantity"
                            name="quantity"
                            // className="input_disable_onshow"
                        >
                            <InputNumber 
                                defaultValue={vTotal}
                                placeholder="จำนวนสิทธิ"
                                min={0}
                                style={{width: "100%"}}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label="Short Detail"
                            name="shortDetail"
                        >
                            <Input disabled={false}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Start Date"
                            name="start_date"
                            className="input_disable_onshow"
                            getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setStartDate(dateString)}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Expire Date"
                            name="expire_date"
                            className="input_disable_onshow"
                            getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setExpireDate(dateString)}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item label="Coupon Type">
                            {/* <Radio.Group options={optRadioCouponType} onChange={onChangeCouponType} value={vCouponType} />   */}
                            <Radio.Group disabled={false} value={vCouponType} onChange={(e:any) => setCouponType(e.target.value)}>
                                <Space direction="horizontal">
                                    <Radio value={1}>{t("coupon.ra-fixed")}</Radio>
                                    <Radio value={2}>{t("coupon.ra-running")}</Radio>
                                    <Radio value={3}>{t("coupon.ra-third")}</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    <Col span={24} className="gutter-row">
                    <Table
                            size="middle"
                            dataSource={vCouponDataSource}
                            columns={columns}
                            bordered
                            pagination={{hideOnSinglePage: false}}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>

        <Modal 
            {...showModalProps}
            title={"Coupon Group"}
            // okText = {"Save"}
            footer={
                <Button type="primary" onClick={()=> showClose()}>Close</Button>
            }
            maskClosable={false}
            width={500}
            afterClose={()=> {
                showProps?.form?.resetFields();
                setdisabled(true);
                setTotal(0);
                setStartDate(undefined);
                setExpireDate(undefined);
                setArrCoupon([]);
                setStatUpload(false);
            }}
        >
            <Form 
                {...showProps} 
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={18} className="gutter-row">
                        <Form.Item  
                            label={t("coupon-group.f-group-name")}
                            name="group_name"
                            className="input_disable_onshow"
                            rules={[
                                {
                                    required: true, message: 'Please enter coupon group name.'
                                },
                            ]}
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={6} className="gutter-row">
                        <Form.Item  
                            label="Quantity"
                            name="quantity"
                            className="input_disable_onshow"
                        >
                            <InputNumber 
                                defaultValue={vTotal}
                                placeholder="จำนวนสิทธิ"
                                min={0}
                                style={{width: "100%"}}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item
                            label="Short Detail"
                            name="shortDetail"
                            className="input_disable_onshow"
                        >
                            <Input disabled={true}/>
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Start Date"
                            name="start_date"
                            className="input_disable_onshow"
                            getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setStartDate(dateString)}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12} className="gutter-row">
                        <Form.Item
                            label="Expire Date"
                            name="expire_date"
                            className="input_disable_onshow"
                            getValueProps={(value) => ({value: value ? dayjs(value) : "",})}
                        >
                            <DatePicker 
                                style={{width:'100%'}}
                                onChange={(date, dateString) => setExpireDate(dateString)}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                        <Form.Item label="Coupon Type" className="input_disable_onshow">
                            {/* <Radio.Group options={optRadioCouponType} onChange={onChangeCouponType} value={vCouponType} />   */}
                            <Radio.Group disabled={true} value={vCouponType} onChange={(e:any) => setCouponType(e.target.value)}>
                                <Space direction="horizontal">
                                    <Radio value={1}>{t("coupon.ra-fixed")}</Radio>
                                    <Radio value={2}>{t("coupon.ra-running")}</Radio>
                                    <Radio value={3}>{t("coupon.ra-third")}</Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="gutter-row">
                    <Table
                            size="middle"
                            dataSource={vCouponDataSource}
                            columns={columns}
                            bordered
                            pagination={{hideOnSinglePage: false}}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </>
}