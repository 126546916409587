/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import { HcuNotiMsgList } from "./notification";
import { HcuTermConditionList } from "./term_and_condition";
import { HcuTemplateList } from "./template_master";
import { HcuDaysList } from "./setup_days";
import { HcuAssignList } from "./assign_inspec";
import { HcuEmailGroupList } from "./email_group";


export const HcuSetup: React.FC<IResourceComponentsProps> = () => {
    const tabActive = localStorage.getItem("keyTabHCU");

    const t = useTranslate();
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null

    function onStatusChange(v:any){
        // if(v == "Y"){
        //     // setdeleteStatus("Y")
        //     // setappointmentType(null) 
        // }else{
        //     // setdeleteStatus("N")
        //     // setappointmentType(v)
        // }
        // searchFormProps.form?.submit();
        localStorage.setItem("keyTabHCU", v)
    }

    return <>
        <List 
            title={t("hcusetup.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                    <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item>{t("mjc.title")}</Breadcrumb.Item>
                    <Breadcrumb.Item><span className="last_breadcrumb">{t("hcusetup.title")}</span></Breadcrumb.Item>
                </Breadcrumb>,
                extra : <></>
            }}
        >
            <Tabs
                defaultActiveKey={tabActive? tabActive + "" : "1"}
                onChange={onStatusChange}
            >
                <Tabs.TabPane tab={t("table-header.setup-hcu.template.title")} key="1">
                    <HcuTemplateList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("table-header.setup-hcu.notification.title")} key="2">
                    <HcuNotiMsgList />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("table-header.setup-hcu.home-checkup-day.title")} key="3">
                    <HcuDaysList />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("table-header.setup-hcu.term&condition.title")} key="4">
                    <HcuTermConditionList/>
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("table-header.setup-hcu.assign-technician.title")} key="5">
                    <HcuAssignList />
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("table-header.setup-hcu.email-group.title")} key="6">
                    <HcuEmailGroupList />
                </Tabs.TabPane>
            </Tabs>
        </List>

        
    </>
}