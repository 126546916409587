/* eslint-disable */
import { Breadcrumb, Col, Form, Icons, List, Modal,  Row, Space, Table, TextField,Upload, useTable, Input, Button, CreateButton, Badge, EditButton, Tag, Select, useSelect, useModalForm, Radio, message, RcFile, UploadFile, useForm, useSimpleList, notification } from "@pankod/refine-antd";
import { CrudFilters, HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useList, useNavigation, useTranslate, useUpdate } from "@pankod/refine-core";
import { Tabs } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";

import { majorCareUrl } from "../../../constants";
import type { ColumnsType } from 'antd/es/table';
import { IUserProfile } from "interfaces";
import Moment from 'moment';

let dataUser: any = [];
let dataselProp: any = [];
let keepLoaddataProperty:any
export const HcuTemplateList: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    const { TabPane } = Tabs;
    const {data: Identity_data} = useGetIdentity<any>();
    let data_permission:any = Identity_data?.menu_access.find((item:any) => item?.module_master?.module_name == "Role Management");
    let propList: any = Identity_data?.prop_list !== null ? Identity_data?.prop_list : undefined;
    let data_property = Identity_data?.property !== null ? Identity_data?.property.id : null;

    // console.log(">>> Identity_data", Identity_data)

    const [datafield, setDatafield] = useState<any>()
    const [dataorder, setDataorder] = useState<any>()

    // const { tableProps,searchFormProps,tableQueryResult } = useTable<any,HttpError, any>({})
    const { confirm } = Modal;
    const { formProps } = useForm<any,HttpError,{}>()

    const { Option } = Select;
    const { create, edit } = useNavigation();

    const [dataTemplate, setdataTemplate] = useState<any>([]);
    const [dataProperty, setdataProperty] = useState<any>([]);
    const [dataUnitType, setdataUnitType] = useState<any>([]);
    const [dataUnitTypeBASE, setdataUnitTypeBASE] = useState<any>([]);
    const [pageSize, setPageSize] = useState(10);

    const [localData, setlocalData] = useState<any>([]);

    const [nameSearch, setnameSearch] = useState<any>("");
    const [selProp, setselProp] = useState<any>();
    const [selUnitType, setselUnitType] = useState<any>();
    const [status, setstatus] = useState<any>("N");

    const { listProps: listPropsData, queryResult: { refetch } } = useSimpleList({
        resource: "user-has-units", 
        metaData: {populate: ['unit','unit.property'],},
        queryOptions: {
          onSuccess: (data) => {
            if(dataTemplate?.length == 0 && localData?.length == 0){
                // getdataTemplateList();
            }
          },
        },
    });

    // useEffect(() => {
    //     getdataTemplateList();
    // }, [])

    // type NotificationType = 'success' | 'info' | 'warning' | 'error';

    const SuccessNoti = () => {
        notification['success']({
          message: "Successful",
          description:
            'Successfully update template',
        });
    };

    // if(data_property !== undefined && dataTemplate?.length == 0 && localData?.length == 0 || propList !== undefined && dataTemplate?.length == 0 && localData?.length == 0 && dataProperty?.length == 0){
    //     getdataTemplateList();
    // }

    if(dataProperty?.length == 0 && data_property !== undefined && dataTemplate){
        getdataTemplateList();
    }

    // console.log(">>> dataProperty", dataProperty)

    // selProp
    
    
    async function getdataTemplateList(){
        let loaddataTemplate = await axios.get(majorCareUrl + "/majorcare/findAllTemplate" , { headers: { } });
        let loaddataProperty = await axios.get(majorCareUrl + "/majorcare/findAllProperty" , { headers: { } });
        keepLoaddataProperty = loaddataProperty?.data
        //console.log("keepLoaddataProperty",keepLoaddataProperty)
        let loaddataUnitType = await axios.get(majorCareUrl + "/majorcare/findAllUnitType" , { headers: { } });
        // item?.property?.length > 0 ? dataProperty?.find((item:any) => item?._id == item?.property[0]) !== undefined ? dataProperty?.find((item:any) => item?._id == item?.property[0]).property_name : '-' : '-',

        // console.log(">>> loaddataProperty", loaddataProperty)
        // console.log(">>> data_property", data_property)
        // console.log(">>> propList", propList)

        if(propList){
            let newProp: any = [];
            let dataTemplateActive = loaddataTemplate?.data?.filter((e:any)=> e?.is_active == true)?.reverse();
            let dataTemplatereverse = loaddataTemplate?.data?.reverse();
            let newDataforTemplate:any = [];
            let newDataforLocal:any = [];

            for (let i = 0; i < propList?.length; i++) {
                let findProp = loaddataProperty?.data?.findIndex((f: any)=> f?.property_id == propList[i]?.id);

                if(findProp !== -1){
                    newProp.push({
                        id: loaddataProperty?.data[findProp]?._id,
                        property_id: loaddataProperty?.data[findProp]?.property_id,
                        property_name: loaddataProperty?.data[findProp]?.property_name,
                        property_name_en: loaddataProperty?.data[findProp]?.property_name_en,
                        is_active: loaddataProperty?.data[findProp]?.is_active,
                    });
                }
            }

            for (let index = 0; index < dataTemplateActive?.length; index++) {
                if(dataTemplateActive[index]?.property?.length > 0){
                    for (let i = 0; i < newProp?.length; i++) {
                        let findDT = dataTemplateActive[index]?.property?.find((f: any) => f == newProp[i]?.id);
                        if(findDT){
                            let findC = newDataforTemplate?.find((f: any) => f?.id == dataTemplateActive[index]?._id);
                            if(findC == undefined){
                                newDataforTemplate.push({
                                    id: dataTemplateActive[index]?._id,
                                    template_id: dataTemplateActive[index]?.template_id,
                                    template_name: dataTemplateActive[index]?.template_name,
                                    property: dataTemplateActive[index]?.property,
                                    unit_type: dataTemplateActive[index]?.unit_type,
                                    is_active: dataTemplateActive[index]?.is_active,
                                    create_by: dataTemplateActive[index]?.create_by,
                                    create_date: dataTemplateActive[index]?.create_date,
                                    update_by: dataTemplateActive[index]?.update_by,
                                    update_date: dataTemplateActive[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            for (let index = 0; index < dataTemplatereverse?.length; index++) {
                if(dataTemplatereverse[index]?.property?.length > 0){
                    for (let i = 0; i < newProp?.length; i++) {
                        let findDT = dataTemplatereverse[index]?.property?.find((f: any) => f == newProp[i]?.id);
                        if(findDT){
                            let findC = newDataforLocal?.find((f: any) => f?.id == dataTemplatereverse[index]?._id);
                            if(findC == undefined){
                                newDataforLocal.push({
                                    id: dataTemplatereverse[index]?._id,
                                    template_id: dataTemplatereverse[index]?.template_id,
                                    template_name: dataTemplatereverse[index]?.template_name,
                                    property: dataTemplatereverse[index]?.property,
                                    unit_type: dataTemplatereverse[index]?.unit_type,
                                    is_active: dataTemplatereverse[index]?.is_active,
                                    create_by: dataTemplatereverse[index]?.create_by,
                                    create_date: dataTemplatereverse[index]?.create_date,
                                    update_by: dataTemplatereverse[index]?.update_by,
                                    update_date: dataTemplatereverse[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            setdataProperty((pre: any) => newProp)
            setdataTemplate((pre: any) => newDataforTemplate)
            setlocalData((pre: any) => newDataforLocal)
        }else if(data_property && propList == null){
            let newProp: any = [];

            let dataTemplateActive = loaddataTemplate?.data?.filter((e:any)=> e?.is_active == true)?.reverse();
            let dataTemplatereverse = loaddataTemplate?.data?.reverse();
            let newDataforTemplate:any = [];
            let newDataforLocal:any = [];

            let findProp = loaddataProperty?.data?.findIndex((f: any)=> f?.property_id == data_property);

            if(findProp !== -1){
                newProp.push({
                    id: loaddataProperty?.data[findProp]?._id,
                    property_id: loaddataProperty?.data[findProp]?.property_id,
                    property_name: loaddataProperty?.data[findProp]?.property_name,
                    property_name_en: loaddataProperty?.data[findProp]?.property_name_en,
                    is_active: loaddataProperty?.data[findProp]?.is_active,
                });
            }

            for (let index = 0; index < dataTemplateActive?.length; index++) {
                if(dataTemplateActive[index]?.property?.length > 0){
                    for (let i = 0; i < newProp?.length; i++) {
                        let findDT = dataTemplateActive[index]?.property?.find((f: any) => f == newProp[i]?.id);
                        if(findDT){
                            let findC = newDataforTemplate?.find((f: any) => f?.id == dataTemplateActive[index]?._id);
                            if(findC == undefined){
                                newDataforTemplate.push({
                                    id: dataTemplateActive[index]?._id,
                                    template_id: dataTemplateActive[index]?.template_id,
                                    template_name: dataTemplateActive[index]?.template_name,
                                    property: dataTemplateActive[index]?.property,
                                    unit_type: dataTemplateActive[index]?.unit_type,
                                    is_active: dataTemplateActive[index]?.is_active,
                                    create_by: dataTemplateActive[index]?.create_by,
                                    create_date: dataTemplateActive[index]?.create_date,
                                    update_by: dataTemplateActive[index]?.update_by,
                                    update_date: dataTemplateActive[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            for (let index = 0; index < dataTemplatereverse?.length; index++) {
                if(dataTemplatereverse[index]?.property?.length > 0){
                    for (let i = 0; i < newProp?.length; i++) {
                        let findDT = dataTemplatereverse[index]?.property?.find((f: any) => f == newProp[i]?.id);
                        if(findDT){
                            let findC = newDataforLocal?.find((f: any) => f?.id == dataTemplatereverse[index]?._id);
                            if(findC == undefined){
                                newDataforLocal.push({
                                    id: dataTemplatereverse[index]?._id,
                                    template_id: dataTemplatereverse[index]?.template_id,
                                    template_name: dataTemplatereverse[index]?.template_name,
                                    property: dataTemplatereverse[index]?.property,
                                    unit_type: dataTemplatereverse[index]?.unit_type,
                                    is_active: dataTemplatereverse[index]?.is_active,
                                    create_by: dataTemplatereverse[index]?.create_by,
                                    create_date: dataTemplatereverse[index]?.create_date,
                                    update_by: dataTemplatereverse[index]?.update_by,
                                    update_date: dataTemplatereverse[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            setdataProperty((pre: any) => newProp)
            setdataTemplate((pre: any) => newDataforTemplate)
            setlocalData((pre: any) => newDataforLocal)
        }else{
            let newProp: any = [];
            let dataTemplateActive = loaddataTemplate?.data?.filter((e:any)=> e?.is_active == true)?.reverse();
            let dataTemplatereverse = loaddataTemplate?.data?.reverse();
            let newDataforTemplate:any = [];
            let newDataforLocal:any = [];

            for (let index = 0; index < loaddataProperty?.data.length; index++) {
                newProp.push({
                    id: loaddataProperty?.data[index]?._id,
                    property_id: loaddataProperty?.data[index]?.property_id,
                    property_name: loaddataProperty?.data[index]?.property_name,
                    property_name_en: loaddataProperty?.data[index]?.property_name_en,
                    is_active: loaddataProperty?.data[index]?.is_active,
                })
            }

            for (let index = 0; index < dataTemplateActive?.length; index++) {
                newDataforTemplate.push({
                    id: dataTemplateActive[index]?._id,
                    template_id: dataTemplateActive[index]?.template_id,
                    template_name: dataTemplateActive[index]?.template_name,
                    property: dataTemplateActive[index]?.property,
                    unit_type: dataTemplateActive[index]?.unit_type,
                    is_active: dataTemplateActive[index]?.is_active,
                    create_by: dataTemplateActive[index]?.create_by,
                    create_date: dataTemplateActive[index]?.create_date,
                    update_by: dataTemplateActive[index]?.update_by,
                    update_date: dataTemplateActive[index]?.update_date,
                })
            }
    
            for (let index = 0; index < dataTemplatereverse?.length; index++) {
                newDataforLocal.push({
                    id: dataTemplatereverse[index]?._id,
                    template_id: dataTemplatereverse[index]?.template_id,
                    template_name: dataTemplatereverse[index]?.template_name,
                    property: dataTemplatereverse[index]?.property,
                    unit_type: dataTemplatereverse[index]?.unit_type,
                    is_active: dataTemplatereverse[index]?.is_active,
                    create_by: dataTemplatereverse[index]?.create_by,
                    create_date: dataTemplatereverse[index]?.create_date,
                    update_by: dataTemplatereverse[index]?.update_by,
                    update_date: dataTemplatereverse[index]?.update_date,
                })
            }

            setdataProperty((pre: any) => newProp)
            setdataTemplate((pre: any) => newDataforTemplate)
            setlocalData((pre: any) => newDataforLocal)
        }

        for (let index = 0; index < loaddataUnitType?.data.length; index++) {
            dataUnitType.push({
                id: loaddataUnitType?.data[index]?._id,
                unit_type_id: loaddataUnitType?.data[index]?.unit_type_id,
                unit_type_name: loaddataUnitType?.data[index]?.unit_type_name,
                unit_type_name_en: loaddataUnitType?.data[index]?.unit_type_name_en,
                in_prop: loaddataUnitType?.data[index]?.property,
                is_active: loaddataUnitType?.data[index]?.is_active,
            })
        }

        // console.log(">>> dataUnitType", dataUnitType)
        setdataUnitType((pre: any) => dataUnitType)
        setdataUnitTypeBASE((pre: any) => dataUnitType)

        // //=============================================================================================================//
    }

    //console.log(">>> dataProperty", dataProperty)

    async function fetchdataTemplateList(){
        let loaddataTemplate = await axios.get(majorCareUrl + "/majorcare/findAllTemplate" , { headers: { } });

        if(propList){
            let dataTemplateActive = loaddataTemplate?.data?.filter((e:any)=> e?.is_active == true)?.reverse();
            let dataTemplatereverse = loaddataTemplate?.data?.reverse();
            let newDataforTemplate:any = [];
            let newDataforLocal:any = [];

            for (let index = 0; index < dataTemplateActive?.length; index++) {
                if(dataTemplateActive[index]?.property?.length > 0){
                    for (let i = 0; i < dataProperty?.length; i++) {
                        let findDT = dataTemplateActive[index]?.property?.find((f: any) => f == dataProperty[i]?.id);
                        if(findDT){
                            let findC = newDataforTemplate?.find((f: any) => f?.id == dataTemplateActive[index]?._id);
                            if(findC == undefined){
                                newDataforTemplate.push({
                                    id: dataTemplateActive[index]?._id,
                                    template_id: dataTemplateActive[index]?.template_id,
                                    template_name: dataTemplateActive[index]?.template_name,
                                    property: dataTemplateActive[index]?.property,
                                    unit_type: dataTemplateActive[index]?.unit_type,
                                    is_active: dataTemplateActive[index]?.is_active,
                                    create_by: dataTemplateActive[index]?.create_by,
                                    create_date: dataTemplateActive[index]?.create_date,
                                    update_by: dataTemplateActive[index]?.update_by,
                                    update_date: dataTemplateActive[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            for (let index = 0; index < dataTemplatereverse?.length; index++) {
                if(dataTemplatereverse[index]?.property?.length > 0){
                    for (let i = 0; i < dataProperty?.length; i++) {
                        let findDT = dataTemplatereverse[index]?.property?.find((f: any) => f == dataProperty[i]?.id);
                        if(findDT){
                            let findC = newDataforLocal?.find((f: any) => f?.id == dataTemplatereverse[index]?._id);
                            if(findC == undefined){
                                newDataforLocal.push({
                                    id: dataTemplatereverse[index]?._id,
                                    template_id: dataTemplatereverse[index]?.template_id,
                                    template_name: dataTemplatereverse[index]?.template_name,
                                    property: dataTemplatereverse[index]?.property,
                                    unit_type: dataTemplatereverse[index]?.unit_type,
                                    is_active: dataTemplatereverse[index]?.is_active,
                                    create_by: dataTemplatereverse[index]?.create_by,
                                    create_date: dataTemplatereverse[index]?.create_date,
                                    update_by: dataTemplatereverse[index]?.update_by,
                                    update_date: dataTemplatereverse[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            setdataTemplate((pre: any) => newDataforTemplate)
            setlocalData((pre: any) => newDataforLocal)
        }else if(data_property && propList == null){
            let dataTemplateActive = loaddataTemplate?.data?.filter((e:any)=> e?.is_active == true)?.reverse();
            let dataTemplatereverse = loaddataTemplate?.data?.reverse();
            let newDataforTemplate:any = [];
            let newDataforLocal:any = [];

            for (let index = 0; index < dataTemplateActive?.length; index++) {
                if(dataTemplateActive[index]?.property?.length > 0){
                    for (let i = 0; i < dataProperty?.length; i++) {
                        let findDT = dataTemplateActive[index]?.property?.find((f: any) => f == dataProperty[i]?.id);
                        if(findDT){
                            let findC = newDataforTemplate?.find((f: any) => f?.id == dataTemplateActive[index]?._id);
                            if(findC == undefined){
                                newDataforTemplate.push({
                                    id: dataTemplateActive[index]?._id,
                                    template_id: dataTemplateActive[index]?.template_id,
                                    template_name: dataTemplateActive[index]?.template_name,
                                    property: dataTemplateActive[index]?.property,
                                    unit_type: dataTemplateActive[index]?.unit_type,
                                    is_active: dataTemplateActive[index]?.is_active,
                                    create_by: dataTemplateActive[index]?.create_by,
                                    create_date: dataTemplateActive[index]?.create_date,
                                    update_by: dataTemplateActive[index]?.update_by,
                                    update_date: dataTemplateActive[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            for (let index = 0; index < dataTemplatereverse?.length; index++) {
                if(dataTemplatereverse[index]?.property?.length > 0){
                    for (let i = 0; i < dataProperty?.length; i++) {
                        let findDT = dataTemplatereverse[index]?.property?.find((f: any) => f == dataProperty[i]?.id);
                        if(findDT){
                            let findC = newDataforLocal?.find((f: any) => f?.id == dataTemplatereverse[index]?._id);
                            if(findC == undefined){
                                newDataforLocal.push({
                                    id: dataTemplatereverse[index]?._id,
                                    template_id: dataTemplatereverse[index]?.template_id,
                                    template_name: dataTemplatereverse[index]?.template_name,
                                    property: dataTemplatereverse[index]?.property,
                                    unit_type: dataTemplatereverse[index]?.unit_type,
                                    is_active: dataTemplatereverse[index]?.is_active,
                                    create_by: dataTemplatereverse[index]?.create_by,
                                    create_date: dataTemplatereverse[index]?.create_date,
                                    update_by: dataTemplatereverse[index]?.update_by,
                                    update_date: dataTemplatereverse[index]?.update_date,
                                })
                            }
                        }
                    }
                }
            }

            setdataTemplate((pre: any) => newDataforTemplate)
            setlocalData((pre: any) => newDataforLocal)
        }else{
            let dataTemplateActive = loaddataTemplate?.data?.filter((e:any)=> e?.is_active == true)?.reverse();
            let dataTemplatereverse = loaddataTemplate?.data?.reverse();
            let newDataforTemplate:any = [];
            let newDataforLocal:any = [];

            for (let index = 0; index < dataTemplateActive?.length; index++) {
                newDataforTemplate.push({
                    id: dataTemplateActive[index]?._id,
                    template_id: dataTemplateActive[index]?.template_id,
                    template_name: dataTemplateActive[index]?.template_name,
                    property: dataTemplateActive[index]?.property,
                    unit_type: dataTemplateActive[index]?.unit_type,
                    is_active: dataTemplateActive[index]?.is_active,
                    create_by: dataTemplateActive[index]?.create_by,
                    create_date: dataTemplateActive[index]?.create_date,
                    update_by: dataTemplateActive[index]?.update_by,
                    update_date: dataTemplateActive[index]?.update_date,
                })
            }
    
            for (let index = 0; index < dataTemplatereverse?.length; index++) {
                newDataforLocal.push({
                    id: dataTemplatereverse[index]?._id,
                    template_id: dataTemplatereverse[index]?.template_id,
                    template_name: dataTemplatereverse[index]?.template_name,
                    property: dataTemplatereverse[index]?.property,
                    unit_type: dataTemplatereverse[index]?.unit_type,
                    is_active: dataTemplatereverse[index]?.is_active,
                    create_by: dataTemplatereverse[index]?.create_by,
                    create_date: dataTemplatereverse[index]?.create_date,
                    update_by: dataTemplatereverse[index]?.update_by,
                    update_date: dataTemplatereverse[index]?.update_date,
                })
            }

            setdataTemplate((pre: any) => newDataforTemplate)
            setlocalData((pre: any) => newDataforLocal)
        }
    }

    interface DataType {
        key: React.ReactNode;
        id: Number,
        template_id: string,
        template_name: string;
        property: any;
        unit_type: any;
        is_active: any;
        create_by: string;
        create_date: string;
        update_by: string;
        update_date: string;
        custom_status: any;
        action: any;
    }

    const dataSource = 
        dataTemplate?.map((item:any,key:any) => {
            return {
                key: key,
                // id: item?._id,
                id: item?.id,
                template_id: item?.template_id,
                template_name: item?.template_name,
                property: item?.property,
                unit_type: item?.unit_type,
                is_active: item?.is_active,
                create_by: item?.create_by,
                create_date: item?.create_date,
                update_by: item?.update_by,
                update_date: item?.update_date,
            }
        })
    ;

    const showDelConfirm =(id: any, data: any) => {
        confirm({
            // title: 'คุณต้องการลบ ' + data?.template_name,
            title: <div><span style={{marginRight: "5px"}}>คุณต้องการลบ</span><span style={{color: "#4B78AF"}}>{data?.template_name}</span></div>,
            icon: <Icons.ExclamationCircleFilled />,
            content: 'ข้อมูล TEMPLATE จะถูกย้ายไปที่ถังขยะ',
            okText: 'ลบ',
            okType: 'danger',
            cancelText: 'ยกเลิก',
            onOk() {
                updateActice(id ,"Y")
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const showActiveConfirm =(id: any, data: any) => {
        confirm({
            // title: 'คุณต้องการลบ ' + data?.template_name,
            title: <div><span style={{marginRight: "5px"}}>คุณต้องการกู้</span><span style={{color: "#96CB82"}}>{data?.template_name}</span></div>,
            icon: <Icons.ExclamationCircleFilled />,
            content: 'ข้อมูล TEMPLATE จะถูกกู้คืน',
            okText: 'กู้คืน',
            cancelText: 'ยกเลิก',
            onOk() {
                updateActice(id ,"N")
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    const columns: ColumnsType<DataType> = [
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.template.title")}</span>,
            dataIndex: 'template_name',
            key: 'template_name',
            align: 'center',
            width: '300px',
            className: "table_colmn_custom_left_value",
            sorter: (a:any, b:any) => a.template_name.localeCompare(b.template_name),
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.template.property")}</span>,
            dataIndex: 'property',
            key: 'property',
            align: 'center',
            width: '300px',
            sorter:(a:any, b:any) => {
                if (a.property < b.property) {return -1}
                if (a.property > b.property) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return (
                <>
                    {/* <div>
                        <span>{dataProperty?.find((item:any) => item?.id == (record?.property ? (record?.property[0] || 
                            record?.property[0]?._id) : null)) !== undefined ? 
                            dataProperty?.find((item:any) => item?.id == (record?.property ? (record?.property[0] || 
                            record?.property[0]?._id) : null)).property_name : '-'}
                        </span>
                    </div> */}
                        {
                            record?.property.length !== 0 ?
                            record?.property.map((data:any,index:any) => {
                                // console.log('data : ', data);
                                return <>
                                    <Tag className="tag_post">
                                        <TextField value={ dataProperty?.find((e:any) => e?.id == data)?.property_name }/>
                                    </Tag>
                                </>
                            })
                            : <p>-</p>
                        }
                </>
                );
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.template.create-by")}</span>,
            key: 'createBy',
            align: 'center',
            width: '150px',
            className: "table_colmn_custom_left_value",
            sorter:(a:any, b:any) => {
                if (a.create_by.user_name < b.create_by.user_name) {return -1}
                if (a.create_by.user_name > b.create_by.user_name) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return <div>{record.create_by ? record.create_by?.user_name : "-"} </div>
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.template.created-date")}</span>,
            dataIndex: 'create_date',
            key: 'create_date',
            align: 'center',
            width: '150px',
            sorter:(a:any, b:any) => {
                if (a.create_date < b.create_date) {return -1}
                if (a.create_date > b.create_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.template.update-by")}</span>,
            //dataIndex: 'update_by',
            key: 'updateBy',
            align: 'center',
            width: '150px',
            className: "table_colmn_custom_left_value",
            sorter:(a:any, b:any) => {
                // if (a.user_name < b.user_name) {return -1}
                // if (a.user_name > b.user_name) {return 1}
                return 1
            },
            render: (value:any, record:any) => {
                return <div>{record.update_by ? record.update_by?.user_name : "-"} </div>
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.template.last-update")}</span>,
            dataIndex: 'update_date',
            key: 'update_date',
            align: 'center',
            width: '150px',
            sorter:(a:any, b:any) => {
                if (a.update_date < b.update_date) {return -1}
                if (a.update_date > b.update_date) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return value ? Moment(value).format('D/MM/yyyy HH:mm') : '-';
            },
        },
        {
            title: <span style={{textTransform: "uppercase"}}>{t("table-header.setup-hcu.template.status")}</span>,
            dataIndex: 'custom_status',
            key: 'custom_status',
            align: 'center',
            fixed: 'right',
            width: '150px',
            sorter:(a:any, b:any) => {
                if (a.is_active < b.is_active) {return -1}
                if (a.is_active > b.is_active) {return 1}
                return 0
            },
            render: (value:any, record:any) => {
                return (<Tag style={{borderRadius: "50px"}} color={record?.is_active == true ? "success" : "default"}>{ record?.is_active == true ? <><div style={{fontSize: "8px",marginRight: "5px",position: "absolute",transform: "translate(0, -1px)"}}>●</div><div style={{marginLeft: "7px"}}>{t("status.active")}</div></>:<><div style={{fontSize: "8px",marginRight: "5px",position: "absolute",transform: "translate(0, -1px)"}}>●</div><div style={{marginLeft: "7px"}}>{t("status.inactive")}</div></>}</Tag>);
            },
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            key: 'action',
            align: 'center',
            width: '80px',
            fixed: 'right',
            render: (value:any, record:any) => {
                return (<>
                    {record?.is_active == true ?
                    <div>
                        <Button 
                            size="small" 
                            title="edit" 
                            icon={<Icons.EditOutlined/>} 
                            onClick={() => edit("template", record.id)}
                            style={{marginRight: "5px"}} 
                        />
                        <Button 
                            size="small" 
                            title="Delete"
                            // onClick={() => updateActice(record?.id ,"Y")} 
                            onClick={() => showDelConfirm(record?.id ,record)} 
                            icon={<Icons.LockOutlined style={{color: "#DE4C4C"}}/>} 
                            style={{border: "1px solid #DE4C4C"}} 
                        />
                    </div>
                    :record?.is_active == false &&
                    <div>
                        <Button 
                            size="small" 
                            title="Active"
                            // onClick={() => updateActice(record?.id ,"N")} 
                            onClick={() => showActiveConfirm(record?.id ,record)} 
                            icon={<Icons.RetweetOutlined style={{color: "#96CB82"}}/>} 
                            style={{border: "1px solid #96CB82"}} 
                        />
                    </div>
                    }
                </>);
            },
        },
    ];

    const handlePageSizeChange = (current:any, size:any) => {
        setPageSize(size);
    };

    function onSearchtable(data: any, mode:any){
        let newData:any = data;

        if(mode == "S"){
            newData = newData?.filter((e:any)=> e?.is_active == (status == "N" ? true : status == "Y" && false));
            newData = nameSearch !== "" ? newData?.filter((e:any)=> e?.template_name?.toLowerCase().includes(String(nameSearch).toLowerCase())):newData;
            newData = selProp !== undefined ? newData?.filter((e:any)=> {return e?.property.includes(String(selProp?.id))}):newData;

            console.log("new",newData)
            // newData = selUnitType !== undefined ? newData?.filter((e:any)=> {return e?.unit_type.includes(String(selUnitType?.id))}):newData;
            console.log("selUnitType",selUnitType)
            if(selUnitType){
                let filterUt: any = [];
                for (let f = 0; f < newData?.length; f++) {
                    if(newData[f]?.unit_type?.length > 0){
                        let ofilter:any = newData[f]?.unit_type?.filter((e:any)=> {return e == String(selUnitType?.id)});

                        if(ofilter?.length > 0){
                            filterUt.push(newData[f])
                        }
                    }
                }

                newData = filterUt;
            }

            setdataTemplate((pre: any) => newData);
        }else if(mode == "R"){
            newData = newData?.filter((e:any)=> e?.is_active == (status == "N" ? true : status == "Y" && false));
            
            setdataTemplate((pre: any) => newData);
            setdataUnitType((pre: any) => dataUnitTypeBASE)

            formProps?.form?.resetFields();
            setselProp(undefined);
            setselUnitType(undefined);
            setnameSearch("");
        }
    }

    function onActiveChange(key: any, data: any){
        let newData:any = data;
        if(key == "N"){
            setstatus("N")
            newData = newData?.filter((e:any)=> e?.is_active == true);
            setdataTemplate((pre: any) => newData);
        }else if(key == "Y"){
            setstatus("Y")
            newData = newData?.filter((e:any)=> e?.is_active == false);
            setdataTemplate((pre: any) => newData);
        }
    }

    function onchangeProp(id: any, data: any){
        let newData: any = data?.filter((e:any)=> {return String(e?.in_prop) == String(id)});
        setdataUnitType((pre: any) => newData)
    }

    async function updateActice(templateID: any, key: any){
        if(key == "N"){
            let data = {"is_active": true}
            let res = await axios.put(majorCareUrl + "/majorcare/updateTemplate?id=" + templateID, data , { headers: { } });
            if(res?.status == 200){
                SuccessNoti();
                fetchdataTemplateList();
            }
        }else if(key == "Y"){
            let data = {"is_active": false}
            let res = await axios.put(majorCareUrl + "/majorcare/updateTemplate?id=" + templateID, data , { headers: { } });
            if(res?.status == 200){
                SuccessNoti();
                fetchdataTemplateList();
            }
        }
    }
    
    return <>  
        <List title="">
            <Row gutter={12}>
                <Col span={22}>
                    <Form layout="vertical" style={{marginBottom:"1%"}} {...formProps} onFinish={(values:any) => {}}>
                        <Row gutter={20} style={{width: "100%"}}>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="template_name" label="Search">
                                    <Input placeholder="Search template" onChange={(e) => { if(e.target.value.length > 0){ setnameSearch(e?.target?.value)}else{setnameSearch("")}}} suffix={<Icons.SearchOutlined/>} allowClear/>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="property" label="Property">
                                    <Select 
                                        placeholder={t("select.sel")} 
                                        onChange={(value: any, label: any) => {
                                            if(value!== undefined){
                                                setselProp({id:value, label: label?.children})
                                                onchangeProp(value, dataUnitTypeBASE)
                                            }else{
                                                setselProp(undefined)
                                            }
                                        }} 
                                        allowClear
                                    >
                                        {
                                            dataProperty?.map((item:any,key:any) => {
                                                return <Option value={item?.id} key={item.id}>{item?.property_name}</Option>      
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Form.Item name="unit_type" label="Unit Type">
                                    <Select placeholder={t("select.sel")} onChange={(value: any, label: any) => {if(value!== undefined){setselUnitType({id:value, label: label?.children})}else{setselUnitType(undefined)}}} allowClear>
                                        {
                                            dataUnitType?.map((item:any,key:any) => {
                                                return <Option value={item?.id} key={item.id}>{item?.unit_type_name_en}</Option>      
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4} className="gutter-row">
                                <Row gutter={8}>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button type="primary" style={{borderRadius: "5px"}} onClick={()=> onSearchtable(localData,"S")}>
                                                <Icons.SearchOutlined /> Search
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} className="gutter-row">
                                        <Form.Item label=" ">
                                            <Button 
                                                onClick={() => {
                                                    onSearchtable(localData,"R")
                                                }} 
                                                style={{borderRadius: "5px"}}
                                                icon={<Icons.UndoOutlined />}
                                            >
                                                Reset
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
                <Col span={2}>
                    <Form layout="vertical" style={{marginBottom:"1%",float: "right"}} {...formProps} onFinish={(values:any) => {}}>
                        <Form.Item style={{marginRight: "0",}} label=" ">
                            <CreateButton icon={<Icons.PlusCircleOutlined/>} style={{backgroundColor: "#1D304C", color: "#fff", borderRadius: "5px"}} onClick={()=>create("template")}/>
                        </Form.Item>
                    </Form>
                </Col>

                <Col span={24}>
                    <Form.Item name="deleteStatus" >
                        <Tabs 
                            defaultActiveKey={"N"}
                            style={{ marginTop: 10, marginBottom: 10 }} 
                            onChange={(e:any) => onActiveChange(e,localData)} 
                        >
                            <TabPane
                                key={"N"}
                                tab={
                                    <span>
                                        {"ACTIVE"}
                                        <Badge count={localData.filter((e:any)=> e.is_active == true).length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />

                            <TabPane
                                key={"Y"}
                                tab={
                                    <span>
                                        {"TRASH"}
                                        <Badge count={localData.filter((e:any)=> e.is_active == false).length} style={{ backgroundColor: '#dddddd',marginLeft: "0.3rem" }}/>
                                    </span>
                                }
                            />
                        </Tabs>
                    </Form.Item>
                </Col>
            </Row>

            <Table
                columns={columns}
                scroll={{x: 1300}}
                // pagination={{
                //     defaultPageSize: 10
                // }}
                pagination={{
                    size: "small",
                    position: ['bottomRight'],
                    pageSizeOptions: ['10','100', '500', '1000'], 
                    showSizeChanger: true, 
                    pageSize: pageSize,
                    onChange: handlePageSizeChange,
                }}
                // rowSelection={{ ...rowSelection }}
                // (searchdupStructure !== "" ? dataStructurefordup?.filter((a:any) =>a?.item_name?.includes(String(searchdupStructure))) : dataStructurefordup)
                dataSource={dataSource}
                // dataSource={nameSearch !== "" ? dataSource?.filter((item:any) => item?.template_name?.includes(String(nameSearch))):dataSource}
            />
        </List>

    </>
}