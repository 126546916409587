/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Divider, Form, Input, List, Row, useForm } from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useApiUrl, useCreate, useGetIdentity, useTranslate } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
// import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, ITerm } from "interfaces";
import { useState } from "react";
// import ReactMarkdown from "react-markdown";
// import ReactMde from "react-mde";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const TermCreate: React.FC<IResourceComponentsProps> = () => {
    const API_URL = useApiUrl();
    const t = useTranslate();
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const [ vsetdetail, setdetail] = useState<any>();
    const [ vsetdetailEng, setdetailEng] = useState<any>();
    const log_endpoint          = "even-logs";
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps } = useForm<ITerm,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                // console.log(data?.data);
                let recordData:any = []
                recordData = data?.data? data?.data : [];
                // recordData.data.id + ""
                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'term-and-conditions',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );

    function uploadAdapter(loader: { file: Promise<string | Blob>; }) {
        return {
            upload: () => {
                return new Promise((resolve, reject) => {
                const body = new FormData();
                loader.file.then((file: string | Blob) => {
                    // console.log('fileeeeeeeeeeeee', file)
                    body.append("files", file);
                    // let headers = new Headers();
                    // headers.append("Origin", "http://localhost:3000");
                    fetch(`${API_URL}/upload`, { // {`${API_URL}/upload`}
                    method: "post",
                    body: body
                    // mode: "no-cors"
                    })
                    .then((res) => res.json())
                    .then((res) => {
                        // console.log('resssssssssssssssss', res)
                        resolve({
                        //   default: `${API_URL}/${res.filename}`
                        default: res[0].url
                        });
                    })
                    .catch((err) => {
                        reject(err);
                    });
                });
                });
            }
        };
    }

    function uploadPlugin(editor: { plugins: { get: (arg0: string) => { (): any; new(): any; createUploadAdapter: (loader: any) => { upload: () => Promise<unknown>; }; }; }; }) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
          return uploadAdapter(loader);
        };
    }

    const [selectedTab, setSelectedTab] = useState<"write" | "preview">(
        "write",
    );
    //const API_URL = useApiUrl();

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>
        <List 
            title={t("term.bc_title-c")}
                
            pageHeaderProps={{ 
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("term.bc_title-c")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
         onFinish={(values:any) => {
            // console.log(user_data.user_profile.id)
            values.createBy = user_data.user_profile.id
            values.tac_content = vsetdetail
            values.tac_content_en = vsetdetailEng
            return ( 
                formProps.onFinish &&
                formProps.onFinish(mediaUploadMapper(values))
            );
         }}>
            <Row gutter={[16, 24]}>
                <Col id="Col_w" className="gutter-row" span={18}>
                    <Card style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={6}>
                                <Form.Item  label={t("term.code")} name="tac_code"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition code.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={9}>
                                <Form.Item  label={t("term.name")} name="tac_name"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>

                                
                            </Col>

                            <Col span={9}>
                                <Form.Item  label={t("term.name_en")} name="tac_name_en"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition name.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item label={t("term.content")} name="tac_content"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>

                                    {/* <ReactMde
                                        selectedTab={selectedTab}
                                        onTabChange={setSelectedTab}
                                        generateMarkdownPreview={(markdown: string) =>
                                            Promise.resolve(
                                                <ReactMarkdown>{markdown}</ReactMarkdown>,
                                            )
                                        }
                                    /> */}

                                    <CKEditor
                                        // name="detail"
                                        editor={ClassicEditor}
                                        // data={recordData.detail}
                                        config={{
                                            toolbar: [
                                                "heading",
                                                "|",
                                                "bold",
                                                "italic",
                                                "link",
                                                "bulletedList",
                                                "numberedList",
                                                "blockQuote",
                                                "|",
                                                "imageTextAlternative",
                                                "imageUpload",
                                                "imageStyle:full",
                                                "imageStyle:side",
                                            ],
                                            extraPlugins: [uploadPlugin],
                                            mediaEmbed: {
                                                // configuration...
                                                removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                            },
                                            image: {
                                                toolbar: ['']
                                            }
                                        }}
                                        
                                        onInit={(editor:any) => {
                                        }}
                                        onChange={(event:any, editor:any) => {
                                            const data = editor.getData();
                                            setdetail(data);
                                        }}
                                        onBlur={(editor:any) => {
                                            console.log("Blur.", editor);
                                        }}
                                        onFocus={(editor:any) => {
                                            console.log("Focus.", editor);
                                        }}
                                    />

                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={24}>
                                <Form.Item label={t("term.content_en")} name="tac_content_en"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}>

                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={{
                                            toolbar: [
                                                "heading",
                                                "|",
                                                "bold",
                                                "italic",
                                                "link",
                                                "bulletedList",
                                                "numberedList",
                                                "blockQuote",
                                                "|",
                                                "imageTextAlternative",
                                                "imageUpload",
                                                "imageStyle:full",
                                                "imageStyle:side",
                                            ],
                                            extraPlugins: [uploadPlugin],
                                            mediaEmbed: {
                                                removeProviders: [ 'instagram', 'twitter', 'googleMaps', 'flickr', 'facebook', 'youtube' ]
                                            },
                                            image: {
                                                toolbar: ['']
                                            }
                                        }}
                                        
                                        onInit={(editor:any) => {
                                        }}
                                        onChange={(event:any, editor:any) => {
                                            const data = editor.getData();
                                            setdetailEng(data);
                                        }}
                                        onBlur={(editor:any) => {
                                            // console.log("Blur.", editor);
                                        }}
                                        onFocus={(editor:any) => {
                                            // console.log("Focus.", editor);
                                        }}
                                    />

                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("term.link")} name="tac_link"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition link.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" span={12}>
                                <Form.Item  label={t("term.link_en")} name="tac_link_en"
                                    rules={[
                                        {
                                            required: true, message: 'Please enter term and condition link.'
                                        },
                                    ]}>
                                        <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Col>

                {/* <Col id="Col_w" className="gutter-row" span={6}>
                    <Card style={{backgroundColor: "#fff",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                        <div style={{ marginBottom: 20 }}><span className="title_information">{t("information.txt")}</span></div>
                        <Divider></Divider>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col className="gutter-row" span={16}>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("created")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("by")}</div>
                                <div style={{marginBottom: "8%",fontSize: "0.7vw"}}>{t("lupdate")}</div>
                                <div style={{fontSize: "0.7vw"}}>{t("by")}</div>
                            </Col>
                                                    
                            <Col className="gutter-row" span={8}>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                                <div style={{marginBottom: "5%"}}>-</div>
                            </Col>
                        </Row>
                    </Card>
                </Col> */}
            </Row>
        </Form>
    </Create>   
    );
};