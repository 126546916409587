/* eslint-disable */
import { Breadcrumb, Card, Col, Create, Form, Input, List, Radio, Row, Select, useForm , useSelect} from "@pankod/refine-antd";
import { HttpError, IResourceComponentsProps, useCreate, useGetIdentity, useTranslate  } from "@pankod/refine-core";
import { mediaUploadMapper } from "@pankod/refine-strapi-v4";
import { IEventlog, IUnit, IUserhunit, IUserProfile, IUserSubType } from "interfaces";
// import { TOKEN_KEY } from "../../constants";
import "react-mde/lib/styles/css/react-mde-all.css";

import { PlusOutlined } from '@ant-design/icons';
import type { RcFile, UploadProps } from 'antd/es/upload';
import type { UploadFile } from 'antd/es/upload/interface';
import { useState } from "react";

export const UserhunitCreate: React.FC<IResourceComponentsProps> = () => {

    const t = useTranslate();
    // const { data: user }        = useGetIdentity();
    const { mutate: CreateLog } = useCreate<IEventlog>();
    const log_endpoint          = "even-logs";
    // const { formProps, saveButtonProps } = useForm<IContentPostType>({redirect:"list"});
    const userQuery             = useGetIdentity(); // Get User Profile
    const {data : user_data ,isLoading}     = userQuery;
    const { formProps, saveButtonProps } = useForm<IUserhunit,HttpError,{}>(
        {
            onMutationSuccess(data, variables, context) {
                let recordData:any = []
                recordData = data?.data? data?.data : [];

                CreateLog({
                    resource    : log_endpoint,
                    values      : {
                        // user_profile            : user_data.id,
                        user_profile            : user_data.user_profile.id,
                        log_time_stamp          : Date.now(), 
                        log_event               : "Create",
                        menu_event              : 'unit',
                        SysPkID                 : recordData.data.id + ""
                    },
                    successNotification : false
                });
            },
        }
    );
    // const API_URL = useApiUrl();
    // const { ...uploadProps_coverpix} = useStrapiUpload({ maxCount: 1, });
//     const { queryResult } = useShow<IUnit>({  
//         metaData: {
//         populate: '*'
//     }, 
// });
    // const { data } = queryResult;
    // const record = data?.data;

    // const { Option } = Select;

    const { selectProps: SelUser} = useSelect<IUserProfile>({
        resource: "user-profiles",
        optionLabel: "identity_no",
        optionValue: "id",
        fetchSize: 100,
        // filters: [initF]
    });

    const { selectProps: SelUnit} = useSelect<IUnit>({
        resource: "units",
        optionLabel: "unit_name",
        optionValue: "id",
        fetchSize: 100,
        // filters: [initF]
    });

    const { selectProps: SelUsersubtype } = useSelect<IUserSubType>({
        resource: "user-sub-types",
        optionLabel: "sub_type_name",
        optionValue: "id",
        filters: [
            {
                field       : 'id',
                operator    : 'in',
                value       : [1,3,4,7]
            },
        ]
    });

    // const { selectProps: SelTypeV} = useSelect<IvehicleType>({
    //     resource: "vehicle-types",
    //     optionLabel: "type_nameEN",
    //     optionValue: "id",
    //     fetchSize: 100,
    // });

    // const { selectProps: SelBrandV} = useSelect<IvehicleBrand>({
    //     resource: "vehicle-brands",
    //     optionLabel: "brand_nameEN",
    //     optionValue: "id",
    //     fetchSize: 100,
    // });

    // const { selectProps: SelModelV} = useSelect<IvehicleModel>({
    //     resource: "vehicle-models",
    //     optionLabel: "model_nameEN",
    //     optionValue: "id",
    //     fetchSize: 100,
    // });

    // const { selectProps: SelColorV} = useSelect<IvehicleColor>({
    //     resource: "vehicle-colors",
    //     optionLabel: "color_nameEN",
    //     optionValue: "id",
    //     fetchSize: 100,
    // });

    // const { selectProps: Selpptype } = useSelect<IPPType>({
    //     resource: "pp-types",
    //     optionLabel: "pp_name",
    //     optionValue: "id",
    //     // filters: [initF]
    // });

    // function fileChange(e:any){
    //     console.log(e);
    //     if(e.file.response){
    //         let res = e.file.response;
    //         console.log(res[0]);
    //         //setPhotoList(res[0]);
            
    //     }
    // }

    const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = error => reject(error);
    });

    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>([]);

    // const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file: UploadFile) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile);
      }
  
      setPreviewImage(file.url || (file.preview as string));
      setPreviewVisible(true);
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
    };
  
    const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
      setFileList(newFileList);

    // const uploadButton = (
    //     <div>
    //       <PlusOutlined />
    //       <div style={{ marginTop: 8 }}>Upload</div>
    //     </div>
    //   );

    return (
     <Create saveButtonProps={saveButtonProps} pageHeaderProps={{extra: <></>}}>

        <List 
            title={t("unit-create.title")}
            pageHeaderProps={{
                breadcrumb: <Breadcrumb>
                                <Breadcrumb.Item>{t("home.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item>{t("project-member.title")}</Breadcrumb.Item>
                                <Breadcrumb.Item><span className="last_breadcrumb">{t("project-member.create")}</span></Breadcrumb.Item>
                            </Breadcrumb>,
                extra : <></>
            }}
        />
        <Form {...formProps} layout="vertical"
            onFinish={(values:any) => {
                values.createBy = user_data.user_profile.id
                return (
                    formProps.onFinish &&
                    formProps.onFinish(mediaUploadMapper(values))
                );
            }}
    >
       <Row gutter={[16, 24]}>
            <Col id="Col_w" className="gutter-row" span={18}>
                <Card style={{ borderRadius: 5, marginBottom: "2%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px" }}>
                    <div style={{ marginBottom: 20 }}><span className="title_panel">{t("unit-create.unit-zone")}</span></div>
                    <Input.Group compact>
                        <Form.Item  label={t("unit.id")} name="user_profile" style={{width: "25%"}}
                            rules={[
                                {
                                    required: true, message: 'Please enter unit id.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelUser} style={{width: "95%"}}/>
                        </Form.Item>

                        <Form.Item  label={t("unit.title")} name="unit" style={{width: "25%"}}
                            rules={[
                                {
                                    required: true, message: 'Please enter unit code.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelUnit} style={{width: "95%"}}/>
                        </Form.Item>

                        <Form.Item  label={t("unit.number")} name="user_sub_type" style={{width: "25%"}}
                            rules={[
                                {
                                    required: true, message: 'Please enter unit number.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelUsersubtype} style={{width: "95%"}}/>
                        </Form.Item>

                        <Form.Item  label={t("unit.number")} name="requestBy" style={{width: "25%"}}
                            rules={[
                                {
                                    required: true, message: 'Please enter unit number.'
                                },
                            ]}>
                                <Select placeholder={t("select.sel")} {...SelUser} style={{width: "100%"}}/>
                        </Form.Item>
                    </Input.Group>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col className="gutter-row" span={12}>
                            <Form.Item  label={t("project-member.ad_approve")} name="isAdminConfirmed">
                                <Radio.Group style={{width: "35%"}}>
                                    <Radio.Button value={false}>{t("project-member-edit.false")}</Radio.Button>
                                    <Radio.Button value={true}>{t("project-member-edit.true")}</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" span={12}>
                        <Form.Item  label={t("project-member.ow_approve")} name="isOwnerConfirmed">
                            <Radio.Group style={{width: "35%"}}>
                                <Radio.Button value={false}>{t("project-member-edit.false")}</Radio.Button>
                                <Radio.Button value={true}>{t("project-member-edit.true")}</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Col>

            {/* <Col id="Col_w" className="gutter-row" span={6}>
                <Card style={{backgroundColor: "#fff",borderRadius: 5, marginBottom: "5%", boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}}>
                    <div style={{ marginBottom: 20 }}><span className="title_panel">{t("relation.txt")}</span></div>
                    <Divider></Divider>

                    <Form.Item  label={t("projects.title")} name="project">
                        <Select placeholder={t("select.sel")}
                            {...SelProjectType}
                        />
                    </Form.Item>

                    <Form.Item  label={t("property.title")} name="property">
                        <Select placeholder={t("select.sel")}
                            {...SelPropertyType}
                        />
                    </Form.Item>        
                </Card>
            </Col> */}
        </Row>
    </Form>
</Create>
    );
};
